<div class="container" *ngrxLet="file$ as file">
    <span *ngIf="!file">{{ "dtmUi.genericFileDisplay.noFileMessage" | transloco }}</span>
    <ng-container *ngIf="file">
        <span class="file-name" *ngIf="isDownloadable$ | ngrxPush; else notDownloadableFileTemplate">
            <a href="javascript:void(0);" (click)="startFileDownload(file)">{{ file.name }}</a>
        </span>
        <ng-template #notDownloadableFileTemplate>
            <span class="file-name">{{ file.name }}</span>
        </ng-template>

        <span class="file-size">({{ file.size | formatFileSize }})</span>
    </ng-container>
</div>
