<ng-container *ngIf="messages$ | ngrxPush as messages">
    <dtm-ui-expandable-panel *ngFor="let message of messages; let isFirst = first" [isExpanded]="isFirst">
        <div class="header" headerSlot *ngrxLet="storedAvatarPictures$ as storedAvatarPictures">
            <dtm-ui-avatar
                [picture]="storedAvatarPictures?.[message.sender.avatarUrl]"
                [fullName]="message.sender.firstName + ' ' + message.sender.lastName"
            ></dtm-ui-avatar>
            <div class="additional-info">
                <span class="attachment" *ngIf="message.attachments?.length">
                    <span class="attachments-number">{{ message.attachments?.length }}</span
                    ><dtm-ui-icon name="attachment"></dtm-ui-icon>
                </span>
                <span class="date">{{ message.createdAt | messageDateDisplay }}</span>
            </div>
        </div>
        <quill-view-html [content]="message.content" [sanitize]="true" class="quill-view"></quill-view-html>
        <dtm-ui-uploaded-files-display
            *ngIf="message.attachments?.length"
            [uploadedFiles]="message.attachments"
            [isDownloadAllButtonVisible]="true"
        >
            <label>{{ "dtmUi.conversations.attachmentLabel" | transloco }} </label>
        </dtm-ui-uploaded-files-display>
    </dtm-ui-expandable-panel>
</ng-container>
