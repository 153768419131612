import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { combineLatest, map } from "rxjs";
import { MissionPlanAirspaceAnalysisOption, SoraResult } from "../../models/mission-plan-verification.models";
import { AirspaceCharacteristics, MissionUAVSetup } from "../../models/mission.models";

interface AdjacentAreaAndSpaceComponentState {
    soraResult: SoraResult | undefined;
    uavSetup: MissionUAVSetup | undefined;
    airspaceAnalysis: MissionPlanAirspaceAnalysisOption | undefined;
    isExpanded: boolean;
}

@Component({
    selector: "dtm-mission-adjacent-area-and-space",
    templateUrl: "./adjacent-area-and-space.component.html",
    styleUrls: ["./adjacent-area-and-space.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AdjacentAreaAndSpaceComponent {
    @Input({ required: true }) public set soraResult(value: SoraResult | undefined) {
        this.localStore.patchState({ soraResult: value });
    }

    @Input() public set setup(value: MissionUAVSetup | undefined) {
        this.localStore.patchState({ uavSetup: value });
    }

    @Input({ required: true }) public set airspaceAnalysis(value: MissionPlanAirspaceAnalysisOption | undefined) {
        this.localStore.patchState({ airspaceAnalysis: value });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly soraResult$ = this.localStore.selectByKey("soraResult");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly technicalRequirementsText$ = combineLatest([this.localStore.selectByKey("uavSetup"), this.soraResult$]).pipe(
        map(([uavSetup, soraResult]) => this.getTechnicalRequirementsText(uavSetup, soraResult))
    );
    private readonly airspaceAnalysis$ = this.localStore.selectByKey("airspaceAnalysis");
    protected readonly airspaceCharacteristics$ = this.airspaceAnalysis$.pipe(map((airspace) => this.getAirspaceCharacteristics(airspace)));

    constructor(
        private readonly localStore: LocalComponentStore<AdjacentAreaAndSpaceComponentState>,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            soraResult: undefined,
            uavSetup: undefined,
            airspaceAnalysis: undefined,
            isExpanded: true,
        });
    }

    private getTechnicalRequirementsText(data: MissionUAVSetup | undefined, soraResult: SoraResult | undefined): string | undefined {
        const technicalSpecification = data?.technicalSpecification;
        if (!technicalSpecification) {
            return undefined;
        }

        const text: string[] = [];

        if (technicalSpecification.failSafe.some((value) => !!value)) {
            text.push(this.transloco.translate("dtmSharedMission.adjacentAreaAndSpace.failsafeLabel"));
        }

        if (technicalSpecification.hasGeofencing) {
            text.push(this.transloco.translate("dtmSharedMission.adjacentAreaAndSpace.geofencingLabel"));
        }

        if (technicalSpecification.hasGeocage) {
            text.push(this.transloco.translate("dtmSharedMission.adjacentAreaAndSpace.geocageLabel"));
        }

        if (soraResult?.flightTerminationSystem.isAvailable) {
            text.push(this.transloco.translate("dtmSharedMission.adjacentAreaAndSpace.FTSSystemLabel"));
        }

        return text.join(", ") || undefined;
    }

    private getAirspaceCharacteristics(airspace: MissionPlanAirspaceAnalysisOption | undefined): AirspaceCharacteristics {
        if (!airspace) {
            return AirspaceCharacteristics.Unknown;
        }

        if (airspace.isAdjacentAreaControlled && airspace.isAdjacentAreaUncontrolled) {
            return AirspaceCharacteristics.Both;
        }

        if (airspace.isAdjacentAreaControlled && !airspace.isAdjacentAreaUncontrolled) {
            return AirspaceCharacteristics.Controlled;
        }

        if (!airspace.isAdjacentAreaControlled && airspace.isAdjacentAreaUncontrolled) {
            return AirspaceCharacteristics.Uncontrolled;
        }

        return AirspaceCharacteristics.Unknown;
    }
}
