import { StringUtils } from "@dtm-frontend/shared/utils";
import {
    DEFAULT_AZURE_MAPS_OPTIONS,
    DEFAULT_MAX_ZOOM_LEVEL,
    RENDER_V2_TILE_URL,
    TRAFFIC_FLOW_TILE_URL,
    TRAFFIC_INCIDENT_TILE_URL,
} from "../../../shared/defaults/azure-maps.defaults";
import { AzureMapsOptions, AzureMapsTilesetOptions } from "../../../shared/models/azure-maps.models";

/* eslint-disable @typescript-eslint/no-explicit-any */
declare const Cesium: any; // TODO: DTM-966

const microsoftCredit = new Cesium.Credit("Microsoft", true);

export class AzureMapsImageryProvider extends Cesium.UrlTemplateImageryProvider {
    private baseUrl: string;
    private tilesetId: string;
    private options: AzureMapsOptions;

    constructor(options: Partial<AzureMapsOptions>) {
        const newOptions = {
            ...DEFAULT_AZURE_MAPS_OPTIONS,
            ...options,
        };

        const tileset = AzureMapsTilesetOptions[newOptions.tilesetId];
        let baseUrl: string;

        if (tileset.tilesetId.startsWith("microsoft.traffic.flow")) {
            baseUrl = TRAFFIC_FLOW_TILE_URL;
        } else if (tileset.tilesetId.startsWith("microsoft.traffic.incident")) {
            baseUrl = TRAFFIC_INCIDENT_TILE_URL;
        } else {
            baseUrl = RENDER_V2_TILE_URL;
        }

        super({
            url: AzureMapsImageryProvider.getFormattedUrl(tileset.tilesetId, newOptions, baseUrl),
            enablePickFeatures: false,
            tileWidth: newOptions.tileSize,
            tileHeight: newOptions.tileSize,
            maximumLevel: tileset.maxZoomLevel ?? DEFAULT_MAX_ZOOM_LEVEL,
            minimumLevel: tileset.minZoomLevel ?? 0,
            hasAlphaChannel: tileset.format !== "jpeg",
        });

        this.options = newOptions;
        this.tilesetId = tileset.tilesetId;
        if (this.tilesetId.startsWith("microsoft.traffic.flow")) {
            this.baseUrl = TRAFFIC_FLOW_TILE_URL;
        } else if (this.tilesetId.startsWith("microsoft.traffic.incident")) {
            this.baseUrl = TRAFFIC_INCIDENT_TILE_URL;
        } else {
            this.baseUrl = RENDER_V2_TILE_URL;
        }
    }

    private static getFormattedUrl(tilesetId: string, options: AzureMapsOptions, baseUrl: string): string {
        let url = StringUtils.replaceInTemplate(baseUrl, {
            tileSize: options.tileSize.toFixed(0),
            language: options.language,
            view: options.view,
            tilesetId,
        });

        if (tilesetId.startsWith("microsoft.traffic")) {
            url = StringUtils.replaceInTemplate(url, { style: AzureMapsImageryProvider.getTrafficStyle(tilesetId) });

            if (tilesetId.indexOf("flow") > 0) {
                url += "&thickness=" + options.trafficFlowThickness;
            }
        }

        if (options.timeStamp) {
            // NOTE: JavaScripts format for ISO string includes decimal seconds and the letter "Z" at the end that is not supported.
            const timeStamp = options.timeStamp.toISOString().split("Z")[0];
            url = StringUtils.replaceInTemplate(url, { timeStamp });
        }

        return url;
    }

    private static getTrafficStyle(tilesetId: string) {
        if (tilesetId.indexOf("microsoft.traffic.") > -1) {
            return tilesetId.replace("microsoft.traffic.incident.", "").replace("microsoft.traffic.flow.", "");
        }

        return "";
    }

    public getTileCredits(): any[] {
        if (AzureMapsTilesetOptions[this.options.tilesetId].partnerCredits) {
            return [
                new Cesium.Credit(
                    `&copy; ${this.options.timeStamp?.getFullYear() ?? ""} ${
                        AzureMapsTilesetOptions[this.options.tilesetId].partnerCredits
                    }`,
                    true
                ),
                microsoftCredit,
            ];
        }

        return [microsoftCredit];
    }

    public requestImage(x: number, y: number, level: number): Promise<HTMLImageElement | HTMLCanvasElement> | undefined {
        return new Promise<HTMLImageElement | HTMLCanvasElement>((resolve, reject) => {
            this.getRequest(this.getTileUrl(x, y, level)).then(
                (response) => {
                    response.blob().then((blobResponse) => {
                        const reader = new FileReader();
                        reader.onload = () => {
                            const img = document.createElement("img");
                            img.setAttribute("role", "presentation");
                            img.src = reader.result as string;
                            img.style.visibility = "visible";
                            resolve(img);
                        };
                        reader.onerror = () => {
                            reject("Unable to load tile.");
                        };
                        reader.readAsDataURL(blobResponse);
                    });
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    private async getRequest(url: string) {
        url = StringUtils.replaceInTemplate(url, { azMapsDomain: this.options.url });

        let prefix = "?";

        if (url.indexOf("?") !== -1) {
            prefix = "&";
        }

        url += `${prefix}subscription-key=${this.options.subscriptionKey}`;

        return fetch(url, {
            method: "GET",
            mode: "cors",
        });
    }

    private getTileUrl(x: number, y: number, level: number): string {
        return StringUtils.replaceInTemplate(AzureMapsImageryProvider.getFormattedUrl(this.tilesetId, this.options, this.baseUrl), {
            x: x.toString(),
            y: y.toString(),
            z: level.toString(),
        });
    }
}
