import turfCircle from "@turf/circle";
import { Feature as GeoJSONFeature } from "@turf/helpers";
import { CylinderEntity, MapEntityType } from "../services/entity-editors/map-entities-editor.service";
import { convertCartesian3ToSerializableCartographic } from "./convert-cartesian3-to-serializable-cartographic";

const APPROXIMATED_CYLINDER_SIDES = 16;

export interface CylinderGeoJSONProperties {
    type: MapEntityType.Cylinder;
    radius: number;
    center: [number, number];
}

export function convertCylinderEntityToGeoJSONFeature(entity: CylinderEntity): GeoJSONFeature {
    const { longitude, latitude } = convertCartesian3ToSerializableCartographic(entity.center);

    const properties: CylinderGeoJSONProperties = { type: MapEntityType.Cylinder, radius: entity.radius, center: [longitude, latitude] };

    return turfCircle([longitude, latitude], entity.radius, {
        steps: APPROXIMATED_CYLINDER_SIDES,
        units: "meters",
        properties,
    });
}
