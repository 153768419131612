import {
    DmsCoordinates,
    DmsDirectionCoordinates,
    GeographicCoordinatesDirection,
    GeographicCoordinatesType,
    HemisphereSign,
} from "../models/dms-coordinates-input.models";

export function addDirectionToDmsCoordinates(
    coordinates: DmsCoordinates,
    coordinatesType: GeographicCoordinatesType
): DmsDirectionCoordinates {
    // NOTE: Degrees could be -0 for values lower than 1° in southern and western hemisphere, example:  0° 30' 15" S
    const isHemisphereSignNegative = coordinates.degrees < 0 || Object.is(coordinates.degrees, -0);

    if (isHemisphereSignNegative) {
        return {
            ...coordinates,
            degrees: coordinates.degrees * HemisphereSign.Negative,
            direction:
                coordinatesType === GeographicCoordinatesType.Latitude
                    ? GeographicCoordinatesDirection.South
                    : GeographicCoordinatesDirection.West,
        };
    }

    return {
        ...coordinates,
        direction:
            coordinatesType === GeographicCoordinatesType.Latitude
                ? GeographicCoordinatesDirection.North
                : GeographicCoordinatesDirection.East,
    };
}
