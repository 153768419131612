import { Pipe, PipeTransform } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { DateUtils } from "./";

@Pipe({
    name: "datesDiff",
})
export class DatesDiffPipe implements PipeTransform {
    constructor(private readonly transloco: TranslocoService) {}

    public transform(firstDate: Date | string, secondDate: Date | string): string {
        const remainingTime = DateUtils.calculateDiffDate(firstDate, secondDate);

        let remainingTimeText = "";
        if (remainingTime.days) {
            remainingTimeText += ` ${this.transloco.translate("dtmUi.timeLabels.days", { days: remainingTime.days })}`;
        }
        if (remainingTime.hours) {
            remainingTimeText += ` ${this.transloco.translate("dtmUi.timeLabels.hours", { hours: remainingTime.hours })}`;
        }
        if (remainingTime.minutes) {
            remainingTimeText += ` ${this.transloco.translate("dtmUi.timeLabels.minutes", { minutes: remainingTime.minutes })}`;
        }
        if (remainingTime.seconds || !remainingTimeText) {
            remainingTimeText += ` ${this.transloco.translate("dtmUi.timeLabels.seconds", { seconds: remainingTime.seconds })}`;
        }

        return remainingTimeText;
    }
}
