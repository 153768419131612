import { ComponentPortal } from "@angular/cdk/portal";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface HeaderComponentState {
    routeTitle: string | undefined;
    componentPortalItem: ComponentPortal<unknown> | undefined;
}

@Component({
    selector: "dtm-ui-header[routeTitle]",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HeaderComponent {
    @Input() public set routeTitle(value: string | undefined) {
        this.localStore.patchState({ routeTitle: value });
    }
    @Input() public set componentPortalItem(value: ComponentPortal<unknown> | undefined) {
        this.localStore.patchState({ componentPortalItem: value });
    }

    public readonly routeTitle$ = this.localStore.selectByKey("routeTitle");
    public readonly componentPortalItem$ = this.localStore.selectByKey("componentPortalItem");

    constructor(private readonly localStore: LocalComponentStore<HeaderComponentState>) {
        localStore.setState({
            routeTitle: undefined,
            componentPortalItem: undefined,
        });
    }
}
