<ng-container *ngIf="verificationCodeFormControl">
    <dtm-ui-input-field>
        <label>{{ "dtmUi.verificationCode.verificationCodeLabel" | transloco }}</label>
        <input
            matInput
            [formControl]="verificationCodeFormControl"
            type="text"
            [mask]="verificationCodeMask$ | ngrxPush"
            [showMaskTyped]="true"
            inputmode="numeric"
        />
        <div class="field-error" *dtmUiFieldHasError="verificationCodeFormControl; name: 'required'">
            {{ "dtmUi.verificationCode.fieldRequiredError" | transloco }}
        </div>
    </dtm-ui-input-field>
    <div class="resend-code" *ngrxLet="isResendDisabled$ as isResendDisabled">
        <button type="button" class="button-tertiary" (click)="onSubmit()" [disabled]="isResendDisabled">
            {{ "dtmUi.verificationCode.sendAgain" | transloco }}
            <span *ngIf="isResendDisabled" class="countdown">
                ({{ countdown$ | async }}{{ "dtmUi.verificationCode.unitLabel" | transloco }})
            </span>
        </button>
    </div>
</ng-container>
