import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Notification, NotificationsErrorType, NotificationsListWithPages } from "../utils/notifications.model";
import { NOTIFICATIONS_ENDPOINTS, NotificationEndpoints } from "../utils/notifications.tokens";
import {
    GetNotificationsListResponseBody,
    convertGetNotificationsListResponseBodyToNotificationsListWithPageable,
} from "./notifications-api.converters";

@Injectable()
export class NotificationsApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly websocketService: WebsocketService,
        @Inject(NOTIFICATIONS_ENDPOINTS) private readonly notificationsEndpoints: NotificationEndpoints
    ) {
        if (websocketService === undefined) {
            throw new Error("Initialize MissionModule with .forRoot()");
        }
    }

    public startIncomingNotificationsWatch<Type = string, Payload = unknown>(
        userId: string,
        allowedNotificationTypes?: string[]
    ): Observable<Notification<Type, Payload> | undefined> {
        return this.websocketService
            .watchTopic(StringUtils.replaceInTemplate(this.notificationsEndpoints.getNotifications, { userId }), allowedNotificationTypes)
            .pipe(
                map((message) => {
                    try {
                        return JSON.parse(message.body) as Notification<Type, Payload>;
                    } catch (error) {
                        return;
                    }
                })
            );
    }

    public getNotificationsList(page: number): Observable<NotificationsListWithPages> {
        const params = new HttpParams().set("page", page);

        return this.httpClient.get<GetNotificationsListResponseBody>(this.notificationsEndpoints.notificationsList, { params }).pipe(
            map((result) => convertGetNotificationsListResponseBodyToNotificationsListWithPageable(result)),
            catchError(() => throwError({ type: NotificationsErrorType.Unknown }))
        );
    }

    public markNotificationAsRead(notificationId: string) {
        return this.httpClient.put(
            StringUtils.replaceInTemplate(this.notificationsEndpoints.markNotificationAsRead, { id: notificationId }),
            {}
        );
    }

    public markAllAsRead() {
        return this.httpClient.put(this.notificationsEndpoints.markAllAsRead, {});
    }

    public getInitialNotificationsCount(): Observable<{ counter: number }> {
        return this.httpClient.get<{ counter: number }>(this.notificationsEndpoints.notificationsCount);
    }
}
