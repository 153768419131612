import { Pipe, PipeTransform } from "@angular/core";
import { CENTIMETERS_IN_METER, METERS_IN_KILOMETER, MILLIMETERS_IN_METER } from "@dtm-frontend/shared/utils";

@Pipe({
    name: "convertToKilometers",
})
export class ConvertToKilometersPipe implements PipeTransform {
    public transform(value: number | undefined | null, from: "meters" | "centimeters" | "millimeters" = "meters"): number | undefined {
        if (value === undefined || value === null) {
            return undefined;
        }

        if (from === "millimeters") {
            return value / (METERS_IN_KILOMETER * MILLIMETERS_IN_METER);
        } else if (from === "centimeters") {
            return value / (METERS_IN_KILOMETER * CENTIMETERS_IN_METER);
        }

        return value / METERS_IN_KILOMETER;
    }
}
