/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from "@angular/core";

type FnRest<FunctionType extends (...args: any) => any> = FunctionType extends (first: any, ...args: infer ParametersType) => any
    ? ParametersType
    : never;

@Pipe({
    name: "invokeThis",
})
export class InvokeThisPipe implements PipeTransform {
    public transform<FirstArgumentType, FunctionType extends (first: FirstArgumentType, ...args: any[]) => any>(
        value: FirstArgumentType,
        fn: FunctionType,
        that: { [key: string]: any },
        ...rest: FnRest<FunctionType>
    ): ReturnType<FunctionType> {
        return fn.call(that, value, ...rest);
    }
}
