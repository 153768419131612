import { Pipe, PipeTransform } from "@angular/core";
import { METERS_IN_KILOMETER } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";

const KILOMETERS_FRACTION_DIGITS = 2;

@Pipe({
    name: "distance",
})
export class DistancePipe implements PipeTransform {
    constructor(private readonly translocoService: TranslocoService) {}

    public transform(valueInMeters: number | string | undefined | null): string | undefined {
        if (valueInMeters === undefined || valueInMeters === null) {
            return undefined;
        }

        const roundedValue = Math.round(+valueInMeters);

        if (+valueInMeters < METERS_IN_KILOMETER) {
            return this.translocoService.translate("dtmUi.distanceInMeters", { value: roundedValue });
        }

        return this.translocoService.translate("dtmUi.distanceInKilometers", {
            value: (roundedValue / METERS_IN_KILOMETER).toFixed(KILOMETERS_FRACTION_DIGITS),
        });
    }
}
