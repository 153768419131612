import { HttpClient, HttpContext, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Logger } from "@dtm-frontend/shared/utils";
import { KeycloakConfig } from "keycloak-js";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { KeycloakTokenResponseBody } from "../../models/keycloak-response.model";
import { SKIP_AUTHENTICATION_OFFLINE_CAPABLE_HTTP_INTERCEPTOR } from "../interceptors/authentication-offline-capable.interceptor";
import { KEYCLOAK_CONFIG } from "../shared-auth.module";

@Injectable({
    providedIn: "root",
})
export class AuthApiService {
    private get keycloakTokenUri(): string {
        return `${this.keycloakConfig.url}/realms/${this.keycloakConfig.realm}/protocol/openid-connect/token`;
    }

    constructor(private readonly httpClient: HttpClient, @Inject(KEYCLOAK_CONFIG) private keycloakConfig: KeycloakConfig) {}

    public logIn(username: string, password: string): Observable<KeycloakTokenResponseBody> {
        const headers = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
        const context = new HttpContext().set(SKIP_AUTHENTICATION_OFFLINE_CAPABLE_HTTP_INTERCEPTOR, true);
        const body = new URLSearchParams({
            username,
            password,
            client_id: this.keycloakConfig.clientId,
            grant_type: "password",
            scope: "openid offline_access",
        });

        return this.httpClient.post<KeycloakTokenResponseBody>(this.keycloakTokenUri, body, { headers, context }).pipe(
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => error);
            })
        );
    }

    public updateToken(refreshToken: string): Observable<KeycloakTokenResponseBody> {
        const headers = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
        const body = new URLSearchParams({
            refresh_token: refreshToken,
            client_id: this.keycloakConfig.clientId,
            grant_type: "refresh_token",
            scope: "openid offline_access",
        });

        return this.httpClient.post<KeycloakTokenResponseBody>(this.keycloakTokenUri, body, { headers });
    }
}
