import { HttpContextToken, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpErrorResponseBody } from "../models/http-error.models";

export const NOT_FOUND_ROUTE = "not-found";
export const SKIP_NOT_FOUND_HTTP_INTERCEPTOR = new HttpContextToken(() => true);

@Injectable()
export class NotFoundHttpInterceptor implements HttpInterceptor {
    constructor(private readonly store: Store, private readonly router: Router) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler) {
        if (request.context.has(SKIP_NOT_FOUND_HTTP_INTERCEPTOR)) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponseBody) => {
                switch (error.status) {
                    case HttpStatusCode.NotFound:
                        this.router.navigate([NOT_FOUND_ROUTE], { state: { message: error.error.generalMessage } });
                        break;
                    default:
                }

                return throwError(() => error);
            })
        );
    }
}
