export namespace NotificationsActions {
    export class GetNotificationsList {
        public static readonly type = "[Notifications] Get notifications";

        constructor(public page: number) {}
    }

    export class StartIncomingNotificationsWatch {
        public static readonly type = "[Notifications] Start incoming notifications watch";

        constructor(public userId: string, public allowedNotificationTypes: string[]) {}
    }

    export class MarkNotificationAsRead {
        public static readonly type = "[Notifications] Mark notification as read";

        constructor(public notificationId: string) {}
    }

    export class MarkAllAsRead {
        public static readonly type = "[Notifications] Mark all as read";
    }

    export class GetInitialNotificationsCount {
        public static readonly type = "[Notifications] Get initial notifications count";
    }

    export class ClearNotificationsList {
        public static readonly type = "[Notifications] Clear notifications list";
    }
}
