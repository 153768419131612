<ng-container *ngrxLet="isSingle$ as isSingle">
    <dtm-ui-input-field *ngIf="isSingle">
        <label class="label-with-popover">
            {{ "dtmUi.serialNumbersControl.serialNumberLabel" | transloco }}
            <dtm-ui-popover [popoverText]="'dtmUi.serialNumbersControl.serialNumberPopover' | transloco"></dtm-ui-popover>
        </label>
        <input matInput #singleSerialNumberInput type="text" [formControl]="singleSerialNumberControl" autocomplete="off" />
        <div class="field-error" *dtmUiFieldHasError="singleSerialNumberControl; name: 'maxlength'; error as error">
            {{ "dtmUi.serialNumbersControl.serialNumberMaxLengthValueError" | transloco : { maxLength: error.requiredLength } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="singleSerialNumberControl; name: ['required', 'pattern']">
            {{ "dtmUi.serialNumbersControl.requiredFieldErrorHint" | transloco }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-textarea-field *ngIf="!isSingle">
        <label>{{ "dtmUi.serialNumbersControl.serialNumbersLabel" | transloco }}</label>
        <textarea
            matInput
            #serialNumbersTextarea
            [formControl]="serialNumbersControl"
            [placeholder]="'dtmUi.serialNumbersControl.serialNumbersPlaceholder' | transloco"
        ></textarea>
        <div class="field-hint">
            {{ "dtmUi.serialNumbersControl.serialNumbersHint" | transloco : { amount: amount$ | ngrxPush } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="serialNumbersControl; name: ['required', 'pattern']">
            {{ "dtmUi.serialNumbersControl.requiredFieldErrorHint" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="serialNumbersControl; name: ['serialNumberMaxLength']; error as error">
            {{
                "dtmUi.serialNumbersControl.invalidSerialNumberLengthInCollectionError"
                    | transloco : { maxLength: error.serialNumberMaxLength.requiredLength }
            }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="serialNumbersControl; name: ['serialNumberDuplicated']; error as error">
            {{
                "dtmUi.serialNumbersControl.duplicatedSerialNumberInCollectionError"
                    | transloco : { serialNumber: error.serialNumberDuplicated.serialNumber }
            }}
        </div>
    </dtm-ui-textarea-field>
</ng-container>
