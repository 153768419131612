<dtm-ui-expandable-panel
    *ngrxLet="{
        isExpanded: isExpanded$,
        isLoading: isLoading$,
        scrollButtonsVisibility: scrollButtonsVisibility$,
        isSimpleMode: isSimpleMode$,
        areBuffersVisible: areBuffersVisible$,
    } as vm"
    [isExpanded]="vm.isExpanded"
    [hasHeaderSeparator]="false"
    (expandedChange)="changeExpansionState($event)"
>
    <ng-content headerSlot select="[headerSlot]"></ng-content>
    <div class="drawing-container" #drawingContainer [class.loading]="vm.isLoading">
        <div
            class="scrollable-area"
            #scrollableAreaContainer
            [dtmUiHorizontalScroll]="vm.scrollButtonsVisibility.isLeftVisible || vm.scrollButtonsVisibility.isRightVisible"
        >
            <svg
                [attr.viewBox]="drawingData | invoke : getScrollableViewBox"
                xmlns="http://www.w3.org/2000/svg"
                [attr.height]="VERTICAL_SCALE + AMSL_BUFFER + drawingData.topOffset + DISTANCE_MEASURE_HEIGHT"
                *ngIf="drawingData$ | ngrxPush as drawingData; else sideViewEmptyStateTemplate"
            >
                <g [attr.transform]="'translate(' + drawingData.xOffset + ',' + drawingData.topOffset + ')'">
                    <defs>
                        <marker
                            id="arrow"
                            viewBox="0 0 10 10"
                            refX="10"
                            refY="5"
                            markerWidth="6"
                            markerHeight="6"
                            orient="auto-start-reverse"
                            class="arrow-head"
                        >
                            <path d="M 5 0 L 10 5 L 5 10" />
                        </marker>
                    </defs>
                    <ng-container *ngFor="let section of vm.areBuffersVisible ? drawingData.sections : []">
                        <rect
                            *ngIf="section.segmentData?.safetyArea as safetyArea"
                            [attr.y]="safetyArea.ceiling * -1"
                            [attr.x]="section.xOffset - (safetyArea.horizontalBuffer ?? 0)"
                            [attr.height]="safetyArea.ceiling - safetyArea.floor"
                            [attr.width]="section.width + 2 * (safetyArea.horizontalBuffer ?? 0)"
                            class="safety-area"
                        ></rect>
                        <rect
                            *ngIf="section.zoneData?.safetyArea as safetyArea"
                            [attr.y]="safetyArea.ceiling * -1"
                            [attr.x]="section.xOffset - (safetyArea.horizontalBuffer ?? 0)"
                            [attr.height]="safetyArea.ceiling - safetyArea.floor"
                            [attr.width]="section.width + 2 * (safetyArea.horizontalBuffer ?? 0)"
                            class="safety-area"
                        ></rect>
                    </ng-container>
                    <ng-container *ngFor="let section of drawingData.sections">
                        <rect
                            *ngIf="section.segmentData as segment"
                            [attr.y]="segment.ceiling * -1"
                            [attr.x]="section.xOffset"
                            [attr.height]="segment.ceiling - segment.floor"
                            [attr.width]="section.width"
                            class="segment"
                        ></rect>
                    </ng-container>
                    <path class="elevation-profile" *ngFor="let path of drawingData.elevation" [attr.d]="path"></path>
                    <path class="max-flight-area-profile" *ngFor="let path of drawingData.maxFlightAreaPath" [attr.d]="path"></path>
                    <path class="max-safety-area-profile" *ngFor="let path of drawingData.maxSafetyAreaPath" [attr.d]="path"></path>
                    <path class="height-error" *ngFor="let path of drawingData.heightErrorPath" [attr.d]="path"></path>
                    <pattern id="diagonal-hatch" patternUnits="userSpaceOnUse" width="4" height="4">
                        <path
                            d="M-1,1 l2,-2
                                 M0,4 l4,-4
                                 M3,5 l2,-2"
                        />
                    </pattern>
                    <ng-container *ngFor="let section of drawingData.sections">
                        <ng-container *ngIf="section.zoneData as zone">
                            <rect
                                [attr.y]="zone.ceiling * -1"
                                [attr.x]="section.xOffset"
                                [attr.height]="zone.ceiling - zone.floor"
                                [attr.width]="zone.width"
                                class="zone"
                            ></rect>
                            <rect
                                [attr.y]="zone.elevationMax * -1"
                                [attr.x]="section.xOffset"
                                [attr.height]="zone.elevationMax - zone.elevationMin"
                                [attr.width]="zone.width"
                                class="zone-elevation"
                            ></rect>
                            <rect
                                [attr.y]="zone.elevationMin * -1"
                                [attr.x]="section.xOffset - 2 * (zone.safetyArea.horizontalBuffer ?? 0)"
                                [attr.height]="zone.elevationMin + AMSL_BUFFER"
                                [attr.width]="zone.width + 4 * (zone.safetyArea.horizontalBuffer ?? 0)"
                                class="zone-elevation runway"
                            ></rect>
                            <text
                                *ngIf="!vm.isSimpleMode"
                                [attr.y]="section.zoneData.ceilingDrawingPosition * -1"
                                [attr.x]="section.xOffset + zone.width / 2"
                                class="segment-height-label label"
                            >
                                {{
                                    "dtmSharedMission.routeSideView.maximumAglLabel"
                                        | transloco : { value: zone.ceilingValue | localizeNumber : { maximumFractionDigits: 0 } }
                                }}
                            </text>
                            <text
                                *ngIf="vm.areBuffersVisible"
                                [attr.y]="section.zoneData.bufferCeilingDrawingPosition * -1"
                                [attr.x]="section.xOffset + zone.width / 2"
                                class="segment-height-label label"
                            >
                                {{
                                    "dtmSharedMission.routeSideView.maximumAglLabel"
                                        | transloco
                                            : { value: zone.safetyArea.ceilingValue | localizeNumber : { maximumFractionDigits: 0 } }
                                }}
                            </text>
                            <text
                                [attr.y]="zone.elevationMaxPointPosition * -1"
                                [attr.x]="section.xOffset + zone.width / 2"
                                class="segment-height-label label"
                                *ngIf="zone.elevationMaxValue"
                            >
                                {{
                                    "dtmSharedMission.routeSideView.maximumAmslLabel"
                                        | transloco : { value: zone.elevationMaxValue | localizeNumber : { maximumFractionDigits: 0 } }
                                }}
                            </text>
                            <text
                                [attr.y]="zone.elevationMinPointPosition * -1"
                                [attr.x]="section.xOffset + zone.width / 2"
                                class="segment-height-label label"
                                *ngIf="zone.elevationMinValue"
                            >
                                {{
                                    "dtmSharedMission.routeSideView.minimumAmslLabel"
                                        | transloco : { value: zone.elevationMinValue | localizeNumber : { maximumFractionDigits: 0 } }
                                }}
                            </text>
                            <ng-container *ngIf="vm.isSimpleMode">
                                <text
                                    #maxHeightBracketLabel
                                    [attr.y]="(zone.elevationMin + (zone.ceiling - zone.elevationMin) / 2) * -1"
                                    [attr.x]="section.xOffset - 20"
                                    class="segment-height-label bracket-label warning"
                                >
                                    {{ zone.ceilingValue | localizeNumber : { maximumFractionDigits: 0 } }}
                                    {{ "dtmSharedMission.routeSideView.aboveMeanSeaLevelUnitLabel" | transloco }}
                                </text>
                                <rect
                                    class="bracket-label-border warning"
                                    *ngIf="maxHeightBracketLabel | invoke : getElementBBox | ngrxPush as box"
                                    [attr.y]="box.y"
                                    [attr.x]="box.x - 3"
                                    [attr.height]="box.height"
                                    [attr.width]="box.width + 6"
                                ></rect>
                                <path
                                    [attr.d]="section.xOffset | invoke : getBracketPath : zone.elevationMin : zone.ceiling"
                                    class="bracket"
                                ></path>
                                <text
                                    #maxFlightHeightBracketLabel
                                    [attr.y]="(zone.elevationMax + (zone.ceiling - zone.elevationMax) / 2) * -1"
                                    [attr.x]="section.xOffset + zone.width + 20"
                                    class="segment-height-label bracket-label right-label"
                                >
                                    {{
                                        zone.ceilingAmslValue - (zone.elevationMaxValue ?? 0)
                                            | localizeNumber : { maximumFractionDigits: 0 }
                                    }}
                                    {{ "dtmSharedMission.routeSideView.aboveMeanSeaLevelUnitLabel" | transloco }}
                                </text>
                                <path
                                    [attr.d]="
                                        section.xOffset + zone.width | invoke : getBracketPath : zone.elevationMax : zone.ceiling : true
                                    "
                                    class="bracket"
                                ></path>
                                <rect
                                    class="bracket-label-border"
                                    *ngIf="maxFlightHeightBracketLabel | invoke : getElementBBox | ngrxPush as box"
                                    [attr.y]="box.y"
                                    [attr.x]="box.x - 3"
                                    [attr.height]="box.height"
                                    [attr.width]="box.width + 6"
                                ></rect>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngFor="let section of drawingData.sections">
                        <ng-container *ngIf="section.maxElevation as maxElevation">
                            <ng-container *ngIf="section.segmentData">
                                <line
                                    [attr.x1]="maxElevation.startOffset + section.xOffset"
                                    [attr.x2]="maxElevation.startOffset + section.xOffset"
                                    [attr.y1]="maxElevation.height * -1"
                                    [attr.y2]="section.segmentData.floor * -1"
                                    marker-end="url(#arrow)"
                                    marker-start="url(#arrow)"
                                    class="height-line"
                                ></line>
                                <text
                                    [attr.y]="((section.segmentData.floor + maxElevation.height) / 2) * -1"
                                    [attr.x]="maxElevation.startOffset + section.xOffset"
                                    dx="4"
                                    dy="4"
                                    class="height-line-label"
                                >
                                    {{ section.segmentData.minHeight - maxElevation.value | localizeNumber : { maximumFractionDigits: 0 } }}
                                    {{ "dtmSharedMission.routeSideView.aboveMeanSeaLevelUnitLabel" | transloco }}
                                </text>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="section.minElevation as minElevation">
                            <ng-container *ngIf="section.segmentData">
                                <circle
                                    [attr.cy]="section.segmentData.safetyArea.ceiling * -1"
                                    [attr.cx]="minElevation.startOffset + section.xOffset"
                                    r="3"
                                    class="height-point"
                                ></circle>
                                <circle
                                    [attr.cy]="section.segmentData.ceiling * -1"
                                    [attr.cx]="minElevation.startOffset + section.xOffset"
                                    r="3"
                                    class="height-point"
                                ></circle>
                                <line
                                    [attr.x1]="minElevation.startOffset + section.xOffset"
                                    [attr.x2]="minElevation.startOffset + section.xOffset"
                                    [attr.y1]="minElevation.height * -1"
                                    [attr.y2]="section.segmentData.safetyArea.ceiling * -1 + 3"
                                    marker-end="url(#arrow)"
                                    marker-start="url(#arrow)"
                                    class="height-line"
                                ></line>
                                <text
                                    [attr.y]="section.segmentData.ceiling * -1 - 4"
                                    [attr.x]="minElevation.startOffset + section.xOffset"
                                    dx="4"
                                    dy="4"
                                    class="height-line-label height-point-label"
                                    dominant-baseline="text-after-edge"
                                    [class.anchor-end]="minElevation.startOffset | invoke : shouldAnchorLabelAtEnd"
                                >
                                    {{
                                        section.segmentData.ceilingValue - minElevation.value
                                            | localizeNumber : { maximumFractionDigits: 0 }
                                    }}
                                    {{ "dtmSharedMission.routeSideView.aboveGroundLevelUnitLabel" | transloco }}
                                </text>
                                <text
                                    [attr.y]="section.segmentData.safetyArea.ceiling * -1"
                                    [attr.x]="minElevation.startOffset + section.xOffset"
                                    dx="4"
                                    dy="4"
                                    class="height-line-label height-point-label"
                                    dominant-baseline="text-after-edge"
                                    [class.anchor-end]="minElevation.startOffset | invoke : shouldAnchorLabelAtEnd"
                                >
                                    {{
                                        section.segmentData.safetyArea.ceilingValue - minElevation.value
                                            | localizeNumber : { maximumFractionDigits: 0 }
                                    }}
                                    {{ "dtmSharedMission.routeSideView.aboveGroundLevelUnitLabel" | transloco }}
                                </text>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngFor="let waypoint of drawingData.waypoints; let index = index">
                        <ng-container *ngIf="!vm.isSimpleMode; else simpleModeZoneInfoTemplate">
                            <circle
                                [attr.cy]="waypoint.height * -1"
                                [attr.cx]="waypoint.xOffset"
                                [class.zone-point]="waypoint.isZone"
                                r="3"
                                class="waypoint"
                            ></circle>
                            <text
                                [attr.y]="(waypoint.height + (waypoint.isZone ? 16 : 0)) * -1"
                                [attr.x]="waypoint.xOffset + (waypoint.isZone ? 16 : 0)"
                                dy="16"
                                class="label"
                            >
                                {{ waypoint.label }}
                            </text>
                        </ng-container>
                        <ng-template #simpleModeZoneInfoTemplate>
                            <text
                                *ngIf="drawingData.sections[index] as section"
                                [attr.y]="
                                    ((vm.areBuffersVisible ? section.zoneData?.safetyArea?.ceiling : section.zoneData?.ceiling) ?? 0) * -1
                                "
                                [attr.x]="section.xOffset + section.width / 2"
                                [attr.dy]="vm.areBuffersVisible ? -20 : -10"
                                class="label zone-info"
                                [ngSwitch]="drawingData.routeData?.sections?.[index]?.flightZone?.flightArea?.properties?.type"
                            >
                                <tspan class="icon iconfont" *ngSwitchCase="'cylinder'">&#xe951;</tspan>
                                <tspan class="icon iconfont" *ngSwitchCase="'prism'">&#xe958;</tspan>
                                <tspan class="icon iconfont" *ngSwitchCase="'polyline3D'">&#xe957;</tspan>
                                <tspan dy="5">
                                    {{ "dtmSharedMission.routeSideView.areaLabel" | transloco : { name: waypoint.label } }}
                                </tspan>
                            </text>
                        </ng-template>
                    </ng-container>

                    <rect
                        class="distance-background"
                        [attr.y]="VERTICAL_BUFFER"
                        [attr.x]="-HORIZONTAL_DRAWING_MARGIN"
                        [attr.height]="DISTANCE_MEASURE_HEIGHT"
                        [attr.width]="drawingData.width + HORIZONTAL_DRAWING_MARGIN"
                    ></rect>
                    <ng-container *ngIf="drawingData.distanceMeasures">
                        <path class="distance-line" [attr.d]="drawingData.distanceMeasures.path"></path>
                        <ng-container *ngFor="let point of drawingData.distanceMeasures.points">
                            <line
                                class="distance-line"
                                [attr.x1]="point.offset"
                                [attr.x2]="point.offset"
                                [attr.y1]="VERTICAL_BUFFER + DISTANCE_MEASURE_HEIGHT_TOP_OFFSET - 6"
                                [attr.y2]="VERTICAL_BUFFER + DISTANCE_MEASURE_HEIGHT_TOP_OFFSET + 6"
                            ></line>
                            <text
                                [attr.y]="VERTICAL_BUFFER + DISTANCE_MEASURE_HEIGHT_TOP_OFFSET + 6"
                                [attr.x]="point.offset"
                                class="distance-label"
                            >
                                {{ point.value | distance }}
                            </text>
                        </ng-container>
                    </ng-container>
                </g>
                <g [attr.transform]="'translate(0,' + drawingData.topOffset + ')'">
                    <line
                        x1="0"
                        [attr.x2]="drawingData.width"
                        [attr.y1]="(drawingData.aglMaxHeightScaled - line.y) * -1"
                        [attr.y2]="(drawingData.aglMaxHeightScaled - line.y) * -1"
                        class="graduation-line"
                        *ngFor="let line of drawingData | invoke : getHorizontalGridLines : 7"
                    ></line>
                </g>
            </svg>
        </div>
        <div class="graduation-lines-info">
            <div
                class="graduation-lines-info-container"
                *ngIf="drawingData$ | ngrxPush as drawingData"
                [style.top.px]="drawingData.topOffset"
            >
                <p [style.top.px]="line.y" *ngFor="let line of drawingData | invoke : getHorizontalGridLines : 7; first as isFirst">
                    <ng-container *ngIf="line.y < VERTICAL_SCALE">
                        {{ line.value }}
                        <span *ngIf="isFirst">
                            {{ "dtmSharedMission.routeSideView.aboveMeanSeaLevelUnitLabel" | transloco }}
                        </span>
                    </ng-container>
                </p>
                <p class="description">{{ "dtmSharedMission.routeSideView.aboveMeanSeaLevelLabel" | transloco }}</p>
            </div>
        </div>
        <button
            type="button"
            class="button-icon scroll-button left"
            [disabled]="!vm.scrollButtonsVisibility.isLeftVisible"
            (click)="scrollView(-1)"
        >
            <dtm-ui-icon name="arrow-left"></dtm-ui-icon>
        </button>
        <button
            type="button"
            class="button-icon scroll-button right"
            [disabled]="!vm.scrollButtonsVisibility.isRightVisible"
            (click)="scrollView()"
        >
            <dtm-ui-icon name="arrow-right"></dtm-ui-icon>
        </button>
    </div>
    <div class="controls" *ngIf="drawingData$ | ngrxPush">
        <mat-slide-toggle labelPosition="before" [formControl]="heightProfileFormControl" *ngIf="!vm.isSimpleMode">
            {{ "dtmSharedMission.routeSideView.heightProfile" | transloco }}
        </mat-slide-toggle>
        <ng-container *ngIf="!vm.isSimpleMode && heightProfileFormControl.value">
            <span>
                <dtm-ui-icon name="chart" class="safety-area-label"></dtm-ui-icon>
                {{ SAFETY_ARE_MAX_HEIGHT }} {{ "dtmSharedMission.routeSideView.aboveGroundLevelUnitLabel" | transloco }}
                {{ "dtmSharedMission.routeSideView.maximumSafetyAreaLabel" | transloco }}
                <dtm-ui-popover [popoverText]="'dtmSharedMission.routeSideView.maximumSafetyAreaTooltip' | transloco"></dtm-ui-popover>
            </span>
            <span>
                <dtm-ui-icon name="chart" class="flight-area-label"></dtm-ui-icon>
                {{ FLIGHT_ARE_MAX_HEIGHT }} {{ "dtmSharedMission.routeSideView.aboveGroundLevelUnitLabel" | transloco }}
                {{ "dtmSharedMission.routeSideView.maximumFlightAreaLabel" | transloco }}
                <dtm-ui-popover [popoverText]="'dtmSharedMission.routeSideView.maximumFlightAreaTooltip' | transloco"></dtm-ui-popover>
            </span>
        </ng-container>
        <div class="simple-mode-legends" *ngIf="vm.isSimpleMode">
            <ul>
                <li>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14" width="20" height="14">
                        <rect x="0" y="0" width="20" height="14" fill="url(#diagonal-hatch)" />
                    </svg>
                    {{ "dtmSharedMission.routeSideView.elevationDifferenceLabel" | transloco }}
                </li>
                <li *ngIf="vm.areBuffersVisible">
                    <div class="item-graphic safety-area"></div>
                    {{ "dtmSharedMission.routeSideView.safeAreaLabel" | transloco }}
                </li>
                <li>
                    <div class="item-graphic max-flight-height"></div>
                    {{ "dtmSharedMission.routeSideView.maxFlightHeightLabel" | transloco }}
                </li>
                <li>
                    <div class="item-graphic max-difference"></div>
                    {{ "dtmSharedMission.routeSideView.maxDifferenceBetweenMaxFlightHeightAndGroundLabel" | transloco }}
                </li>
            </ul>
        </div>
        <dtm-ui-zoom-input
            [formControl]="zoomFormControl"
            [min]="ZOOM_SETTINGS.min"
            [max]="ZOOM_SETTINGS.max"
            [step]="ZOOM_SETTINGS.step"
        ></dtm-ui-zoom-input>
    </div>
    <div class="loader" [class.loading]="vm.isLoading">
        <mat-spinner class="spinner" diameter="32" color="accent" *ngIf="vm.isLoading"></mat-spinner>
    </div>
</dtm-ui-expandable-panel>

<ng-template #sideViewEmptyStateTemplate>
    <dtm-ui-empty-state [mode]="EmptyStateMode.Tertiary">
        <ng-container titleSlot>
            {{ "dtmSharedMission.routeSideView.emptyStateTitle" | transloco }}
        </ng-container>
        <ng-container messageSlot>
            <span #emptyMessageContent><ng-content select="[emptyMessageSlot]"></ng-content></span>
            <ng-container *ngIf="emptyMessageContent | isEmptyElement">
                {{ "dtmSharedMission.routeSideView.emptyStateMessage" | transloco }}
            </ng-container>
        </ng-container>
        <img imageSlot src="assets/images/route-side-view-empty-icon.svg" />
    </dtm-ui-empty-state>
</ng-template>
