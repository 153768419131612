<ng-container *ngrxLet="notification$; let notification">
    <div class="image" [class.unread]="!notification.isRead">
        <img *ngIf="notification.image; else notificationIconTemplate" [src]="notification.image" alt="" />
    </div>
    <div class="text">
        <h4 class="title">{{ notification.title }}</h4>
        <p class="details">{{ notification.text }}</p>
    </div>
    <div class="date">
        <span class="date-text">{{ notification.date | messageDateDisplay }}</span>
        <span class="redirect-icon"><dtm-ui-icon name="arrow-go"></dtm-ui-icon></span>
    </div>

    <ng-template #notificationIconTemplate>
        <dtm-ui-icon *ngIf="notification.icon; else defaultNotificationIconTemplate" [name]="notification.icon"></dtm-ui-icon>
    </ng-template>

    <ng-template #defaultNotificationIconTemplate>
        <dtm-ui-icon name="notification"></dtm-ui-icon>
    </ng-template>
</ng-container>
