import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { FlightViolationEvent, FlightViolationUpdate } from "../../models/tactical.models";

export interface ViolationNotification {
    type: FlightViolationEvent;
    payload: FlightViolationUpdate;
}

@Injectable({
    providedIn: "root",
})
export class FlightViolationUpdaterService {
    private flightViolationUpdateSubject: Subject<ViolationNotification> = new Subject();
    public flightViolationUpdate$ = this.flightViolationUpdateSubject.asObservable();

    public addFlightViolationUpdate(flightViolationUpdate: ViolationNotification): void {
        this.flightViolationUpdateSubject.next(flightViolationUpdate);
    }
}
