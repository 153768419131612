import { CdkAccordion } from "@angular/cdk/accordion";
import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";

@Component({
    selector: "dtm-ui-accordion",
    templateUrl: "./accordion.component.html",
    styleUrls: ["./accordion.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: CdkAccordion, useExisting: forwardRef(() => AccordionComponent) }],
})
export class AccordionComponent extends CdkAccordion {}
