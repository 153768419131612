<ng-container *ngrxLet="isDisabled$; let isDisabled">
    <div
        class="dropzone"
        *ngrxLet="isDraggedOver$; let isDraggedOver"
        [class.highlighted]="isDraggedOver"
        dtmUiFileDropzone
        (filesDrop)="addProcessedFiles($event)"
        (isFileDraggedOverChange)="draggedOverStatusChanged($event)"
    >
        <ng-container *ngrxLet="processedFiles$; let processedFiles">
            <div class="processed-files" *ngIf="processedFiles.length">
                <h6>{{ "dtmUi.filesUpload.filesAdditionHeader" | transloco }}</h6>
                <ul>
                    <li *ngFor="let processedFile of processedFiles">
                        <div class="processed-file" [class.error]="processedFile.error">
                            <span class="file-item">
                                {{ processedFile.file.name }}
                                <span *ngIf="processedFile.file.size" class="file-size">
                                    ({{ processedFile.file.size | formatFileSize }})
                                </span>
                            </span>
                            <mat-progress-spinner
                                *ngIf="!processedFile.error"
                                diameter="20"
                                color="accent"
                                mode="determinate"
                                [value]="processedFile.progress"
                            >
                            </mat-progress-spinner>
                            <button
                                *ngIf="processedFile.error"
                                type="button"
                                class="remove-button"
                                (click)="removeProcessedFile(processedFile)"
                                [disabled]="isDisabled"
                            >
                                <dtm-ui-icon name="close-circle-fill"></dtm-ui-icon>
                            </button>
                        </div>
                        <dtm-ui-form-errors>
                            <div class="field-error" *ngIf="processedFile.error">
                                {{ processedFile.error }}
                            </div>
                        </dtm-ui-form-errors>
                    </li>
                </ul>
            </div>
        </ng-container>
        <p class="prompt" *ngIf="!isDraggedOver">
            {{ "dtmUi.filesUpload.dragAndDropOrLabel" | transloco }}
            <button type="button" (click)="fileInput.click()" class="button-link" [class.disabled]="isDisabled">
                {{ "dtmUi.filesUpload.browseLabel" | transloco }}
            </button>
        </p>
        <p class="prompt" *ngIf="isDraggedOver">
            {{ "dtmUi.filesUpload.dropHereLabel" | transloco }}
        </p>
        <input
            hidden
            [multiple]="isMultiple$ | ngrxPush"
            [disabled]="isDisabled"
            (change)="fileInput.files ? addProcessedFiles(fileInput.files) : undefined"
            #fileInput
            type="file"
            [attr.accept]="allowedTypes$ | ngrxPush | join : ','"
        />
    </div>
</ng-container>
