import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { first } from "rxjs/operators";
import { ConversationCategoryCode } from "../../../models/conversations.models";

interface CategoryFilterComponentState {
    categories: ConversationCategoryCode[] | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-conversations-category-multi-select[categories]",
    templateUrl: "./conversations-category-multi-select.component.html",
    styleUrls: ["./conversations-category-multi-select.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ConversationsCategoryMultiSelectComponent), multi: true },
    ],
})
export class ConversationsCategoryMultiSelectComponent implements OnInit, ControlValueAccessor {
    @Input() public set categories(value: ConversationCategoryCode[] | undefined) {
        this.localStore.patchState({ categories: value ?? [] });
    }

    public readonly categories$ = this.localStore.selectByKey("categories").pipe(RxjsUtils.filterFalsy());
    public readonly category = new FormControl<ConversationCategoryCode[]>([], { nonNullable: true });
    public onTouched = () => {};

    public registerOnChange(fn: (value: ConversationCategoryCode[] | undefined) => void): void {
        this.category.valueChanges.subscribe(fn);
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: ConversationCategoryCode[] | undefined): void {
        this.category.setValue(value ?? []);
    }

    constructor(private localStore: LocalComponentStore<CategoryFilterComponentState>) {
        localStore.setState({
            categories: undefined,
        });
    }

    public ngOnInit() {
        this.category.valueChanges.pipe(first(), untilDestroyed(this)).subscribe(() => this.markAsTouched());
    }

    private markAsTouched() {
        if (!this.category.touched) {
            this.onTouched();
        }
    }
}
