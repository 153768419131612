import { ISO8601TimeDuration } from "@dtm-frontend/shared/utils";
import { Polygon, Properties } from "@turf/helpers";

export interface Area {
    elevationMax?: number;
    elevationMin?: number;
    volume: {
        area: Polygon;
        ceiling: number;
        floor: number;
        coreCeiling?: number;
        coreFloor?: number;
        size?: number;
    };
}

export interface FlightArea extends Area {
    safetyBuffer?: {
        horizontal: number;
        vertical: number;
    };
    properties: Properties;
}

export interface MissionPlanRouteFlightZone {
    center: Waypoint;
    flightArea: FlightArea;
    safetyArea: Area;
    groundArea?: Polygon;
    groundAdjacentArea?: Polygon;
    stopover: {
        min: ISO8601TimeDuration;
        max: ISO8601TimeDuration;
    };
    isRunway?: boolean;
}

export interface Point {
    longitude: number;
    latitude: number;
    height: number;
    altitude: number;
}

export interface TimeRange {
    min: Date;
    max: Date;
}

export interface Waypoint {
    name: string;
    point: Point;
    estimatedArriveAt: TimeRange;
}

export interface MissionPlanRouteSegment {
    fromWaypoint: Waypoint;
    toWaypoint: Waypoint;
    flightArea: FlightArea;
    safetyArea: Area;
    groundArea?: Polygon;
    groundAdjacentArea?: Polygon;
    distance?: number;
    elevationProfile?: {
        elevations: Elevation[];
    };
    speed?: {
        min: number;
        max: number;
    };
    duration?: {
        min: ISO8601TimeDuration;
        max: ISO8601TimeDuration;
    };
}

export interface MissionPlanRouteSection {
    name?: string;
    isActive?: boolean;
    flightZone?: MissionPlanRouteFlightZone;
    segment?: MissionPlanRouteSegment;
}

export interface Elevation {
    latitude: number;
    longitude: number;
    startOffset: number;
    value: number;
}

export interface MissionPlanRoute {
    routeId: string;
    planId?: string;
    missionId?: string;
    elevations?: Elevation[];
    sections: MissionPlanRouteSection[];
    estimatedDistance?: number;
    isPathBased?: boolean;
}
