import { Pipe, PipeTransform } from "@angular/core";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { TranslocoService } from "@jsverse/transloco";
import { TranslocoLocaleService } from "@jsverse/transloco-locale";
import { MissionPlanAnalysisIssueBase } from "../models/mission-plan-verification.models";

const IS_DATE_8601_REGEXP = /^(?:\d{4})-(?:\d{2})-(?:\d{2})T(?:\d{2}):(?:\d{2}):(?:\d{2}(?:\.\d*)?)(?:(?:[+-](?:\d{2}):(?:\d{2})|Z)?)$/;

@Pipe({
    name: "translateAnalysisIssue",
})
export class TranslateAnalysisIssuePipe implements PipeTransform {
    constructor(
        private readonly translocoService: TranslocoService,
        private readonly translocoLocaleService: TranslocoLocaleService,
        private readonly translationHelper: TranslationHelperService
    ) {}

    public transform(issue: MissionPlanAnalysisIssueBase) {
        const args = this.convertArgsFields(issue.translationArgs);

        return this.translationHelper.selectSystemTranslation(issue.translationId, args ?? undefined);
    }

    private convertArgsFields(args: Record<string, string> | null): Record<string, string | Date | number> | undefined {
        if (!args) {
            return;
        }

        const convertedArgs: Record<string, string | Date | number> = {};
        const { end, start } = args;

        let areSameDay = false;

        if (IS_DATE_8601_REGEXP.test(start) && IS_DATE_8601_REGEXP.test(end)) {
            const endDate = new Date(end);
            const startDate = new Date(start);

            areSameDay = this.areSameDay(startDate, endDate);
        }

        Object.entries(args).forEach(([key, value]) => {
            if (value && IS_DATE_8601_REGEXP.test(value)) {
                convertedArgs[key] = this.translocoLocaleService.localizeDate(value, this.translocoService.getActiveLang(), {
                    dateStyle: areSameDay ? undefined : "short",
                    timeStyle: "short",
                });

                return;
            }

            convertedArgs[key] = value;
        });

        return convertedArgs;
    }

    private areSameDay(date1: Date, date2: Date): boolean {
        return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
    }
}
