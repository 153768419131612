import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import {
    FlightZoneEditorType,
    FlightZoneGeometryConstraints,
    FlightZoneMapBaseGeometry,
    FlightZoneUtils,
    RestrictionArea,
    RestrictionAreaUnits,
    areRestrictionAreaCylinderUnits,
} from "@dtm-frontend/dss-shared-lib";
import { MapEntitiesEditorContent, MapEntity, MapEntityType, SerializableCartographic } from "@dtm-frontend/shared/map/cesium";
import { ConfirmationDialogComponent } from "@dtm-frontend/shared/ui";
import { ArrayUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { lastValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { GeometryUnits } from "../../../../models/flight-zone.models";

interface ZoneGeometryStepComponentState extends FlightZoneMapBaseGeometry {
    availableEditors: FlightZoneEditorType[];
    isProcessing: boolean;
    geometryContent: MapEntitiesEditorContent;
    selectedEditor: FlightZoneEditorType | undefined;
    activeGeometryUnits: RestrictionAreaUnits | undefined;
    prismPointsLimit: number | undefined;
    isGeometryValid: boolean;
    isCircularBoundaryValid: boolean;
}

@UntilDestroy()
@Component({
    selector: "dss-client-lib-zone-geometry-step",
    templateUrl: "./zone-geometry-step.component.html",
    styleUrls: ["./zone-geometry-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ZoneGeometryStepComponent {
    public readonly FlightZoneEditorType = FlightZoneEditorType;
    public readonly MapEntityType = MapEntityType;

    public readonly editorTypeFormControl = new UntypedFormControl();

    public readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    public readonly availableEditors$ = this.localStore.selectByKey("availableEditors");
    public readonly constraints$ = this.localStore.selectByKey("constraints");
    public readonly geometryContent$ = this.localStore.selectByKey("geometryContent");
    public readonly horizontalMeasureUnits$ = this.localStore.selectByKey("horizontalMeasureUnits");
    public readonly verticalMeasureUnits$ = this.localStore.selectByKey("verticalMeasureUnits");
    public readonly heightReferences$ = this.localStore.selectByKey("heightReferences");
    public readonly activeGeometryUnits$ = this.localStore.selectByKey("activeGeometryUnits");
    public readonly prismPointsLimit$ = this.localStore.selectByKey("prismPointsLimit");
    public readonly isGeometryValid$ = this.localStore.selectByKey("isGeometryValid");
    public readonly isCircularBoundaryValid$ = this.localStore.selectByKey("isCircularBoundaryValid");

    @Input() public set isProcessing(value: boolean) {
        this.localStore.patchState({ isProcessing: value });
    }

    @Input() public set availableEditors(value: FlightZoneEditorType[] | undefined) {
        this.localStore.patchState({ availableEditors: value });
    }

    @Input() public set selectedEditor(value: FlightZoneEditorType | undefined) {
        this.localStore.patchState({ selectedEditor: value });
        this.editorTypeFormControl.setValue(value);
    }

    @Input() public set constraints(value: FlightZoneGeometryConstraints | undefined) {
        this.localStore.patchState({ constraints: value });
    }

    @Input() public set geometryContent(value: MapEntitiesEditorContent | undefined) {
        this.localStore.patchState({ geometryContent: value ?? [] });
    }

    @Input() public set availableGeometryUnits(value: GeometryUnits | undefined) {
        this.localStore.patchState({
            horizontalMeasureUnits: value?.horizontalMeasureUnits ?? [],
            verticalMeasureUnits: value?.verticalMeasureUnits ?? [],
            heightReferences: value?.heightReferences ?? [],
        });
    }

    @Input() public set activeGeometryUnits(value: RestrictionAreaUnits) {
        this.localStore.patchState({ activeGeometryUnits: value });
    }

    @Input() public set prismPointsLimit(value: number | undefined) {
        this.localStore.patchState({ prismPointsLimit: value });
    }

    @Input() public set isCircularBoundaryValid(value: BooleanInput) {
        this.localStore.patchState({ isCircularBoundaryValid: coerceBooleanProperty(value) });
    }

    @Output() public zoneGeometryUpdate = new EventEmitter<{ editorContent: MapEntitiesEditorContent; units: RestrictionAreaUnits }>();
    @Output() public zoneGeometrySubmit = new EventEmitter();
    @Output() public editorTypeChange = new EventEmitter<FlightZoneEditorType>();
    @Output() public back = new EventEmitter<void>();
    @Output() public zoomToGeometry = new EventEmitter<void>();
    @Output() public clearGeometry = new EventEmitter<void>();
    @Output() public submitPasteAreaCoordinates = new EventEmitter<RestrictionArea>();

    constructor(
        private readonly localStore: LocalComponentStore<ZoneGeometryStepComponentState>,
        private readonly matDialog: MatDialog,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({
            availableEditors: [],
            isProcessing: false,
            geometryContent: [],
            constraints: undefined,
            selectedEditor: undefined,
            horizontalMeasureUnits: [],
            verticalMeasureUnits: [],
            heightReferences: [],
            activeGeometryUnits: undefined,
            prismPointsLimit: Number.MAX_VALUE,
            isGeometryValid: false,
            isCircularBoundaryValid: false,
        });
    }

    public async changeEditorType(newEditorType: FlightZoneEditorType): Promise<void> {
        const geometryContentLength = this.localStore.selectSnapshotByKey("geometryContent").length;

        if (geometryContentLength > 0 && !(await this.getClearGeometryConfirmationFromDialog())) {
            const previousEditor = this.localStore.selectSnapshotByKey("selectedEditor");

            this.editorTypeFormControl.setValue(previousEditor, { emitEvent: false });

            return;
        }

        this.localStore.patchState({ selectedEditor: newEditorType });
        this.editorTypeChange.emit(newEditorType);
    }

    public entityUpdate(
        { entity, units }: { entity: MapEntity; units: RestrictionAreaUnits },
        currentGeometry: MapEntitiesEditorContent
    ): void {
        this.zoneGeometryUpdate.emit({
            editorContent: ArrayUtils.addOrReplace(currentGeometry, entity, (lookup) => lookup.id === entity.id),
            units,
        });
    }

    protected submitPasteAreaCylinderCoordinates(positions: SerializableCartographic[]): void {
        const areaUnits = this.localStore.selectSnapshotByKey("activeGeometryUnits");
        const defaultConstraints = this.localStore.selectSnapshotByKey("constraints")?.default;

        if (!defaultConstraints || !areaUnits || !areRestrictionAreaCylinderUnits(areaUnits)) {
            return;
        }

        this.submitPasteAreaCoordinates.emit({
            areaUnits,
            editorType: FlightZoneEditorType.Cylinder,
            areaValues: {
                lowerHeight: FlightZoneUtils.metersToOtherUnitsOfMeasure(defaultConstraints.lowerHeight, areaUnits.lowerHeightUnit),
                upperHeight: FlightZoneUtils.metersToOtherUnitsOfMeasure(defaultConstraints.upperHeight, areaUnits.upperHeightUnit),
                radius: FlightZoneUtils.metersToOtherUnitsOfMeasure(defaultConstraints.size, areaUnits.radiusUnit),
                centerPointLatitude: positions[0].latitude,
                centerPointLongitude: positions[0].longitude,
            },
        });
    }

    protected submitPasteAreaPrismCoordinates(positions: SerializableCartographic[]): void {
        const areaUnits = this.localStore.selectSnapshotByKey("activeGeometryUnits");
        const defaultConstraints = this.localStore.selectSnapshotByKey("constraints")?.default;

        if (!areaUnits || !defaultConstraints) {
            return;
        }

        this.submitPasteAreaCoordinates.emit({
            areaUnits,
            editorType: FlightZoneEditorType.Prism,
            areaValues: {
                lowerHeight: FlightZoneUtils.metersToOtherUnitsOfMeasure(defaultConstraints.lowerHeight, areaUnits.lowerHeightUnit),
                upperHeight: FlightZoneUtils.metersToOtherUnitsOfMeasure(defaultConstraints.upperHeight, areaUnits.upperHeightUnit),
                positions,
            },
        });
    }

    public async removeGeometry(): Promise<void> {
        if (await this.getClearGeometryConfirmationFromDialog()) {
            this.clearGeometry.emit();
        }
    }

    public updateGeometryValidity(isGeometryValid: boolean): void {
        this.localStore.patchState({ isGeometryValid });
    }

    private async getClearGeometryConfirmationFromDialog(): Promise<boolean> {
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                confirmationText: this.translocoService.translate(
                    "dssClientLibFlightZone.zoneGeometryStep.clearGeometryConfirmDialog.dialogText"
                ),
                declineButtonLabel: this.translocoService.translate(
                    "dssClientLibFlightZone.zoneGeometryStep.clearGeometryConfirmDialog.cancelButtonLabel"
                ),
                confirmButtonLabel: this.translocoService.translate(
                    "dssClientLibFlightZone.zoneGeometryStep.clearGeometryConfirmDialog.confirmButtonLabel"
                ),
            },
        });

        return lastValueFrom(dialogRef.afterClosed().pipe(map(Boolean), untilDestroyed(this)));
    }
}
