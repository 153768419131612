export interface OperatorsThread {
    id: string;
    modifiedAt: string;
    isRead: boolean;
    isClosed: boolean;
    name: string;
    assignee: Assignee | null;
    stakeholderId: string;
    category: ConversationCategoryCode;
}

export interface Assignee {
    userId: string;
    firstName: string;
    lastName: string;
}

export interface MessageRecipient {
    id: string;
    owner: {
        userId: string;
        firstName: string;
        lastName: string;
    };
    name: string;
}

export interface AttachmentFile {
    id: string;
    name: string;
}

export interface ReceivedMessage {
    id: string;
    subject: string;
    content: string;
    createdAt: string;
    sender: {
        userId: string;
        firstName: string;
        lastName: string;
        avatarUrl: string;
    };
    recipient: MessageRecipient | null;
    threadId: string;
    attachments?: AttachmentFile[];
}

export interface MessagesError {
    type: MessagesErrorType;
}

export enum MessagesErrorType {
    Unknown = "Unknown",
    NotFound = "NotFound",
}

export enum ConversationCategoryCode {
    MissionPermit = "MISSION_PERMIT",
    OperatorRegistration = "OPERATOR_REGISTRATION",
    TrainingAndCompetencies = "TRAINING_AND_COMPETENCIES",
    Statements = "STATEMENTS",
    DataChange = "DATA_CHANGE",
    Other = "OTHER",
}

export interface NewMessage {
    content: string;
    attachmentIdsList?: string[];
}

export const MAX_THREAD_NAME_LENGTH = 200;

export const MIN_THREAD_NAME_LENGTH = 3;

export interface StoredAvatarPictures {
    [key: string]: string;
}
