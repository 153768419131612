import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { Observable, catchError, first, map, of, switchMap } from "rxjs";

export function uniqueValidator<T>(checkFn: (value: T) => Observable<{ isUnique: boolean }>): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
        of(control.value).pipe(
            switchMap((value) => {
                if (!value) {
                    return of(null);
                }

                return checkFn(value).pipe(
                    map((valueCheck) => (valueCheck.isUnique ? null : { unique: true })),
                    catchError(() => of({ uniqueServerError: true }))
                );
            }),
            first()
        );
}
