import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from "@angular/core";

/**
 * @deprecated Use WizardStepContentDirective instead
 */
@Component({
    selector: "dtm-ui-lib-wizard-step-content[stepId]",
    template: "<ng-template #stepContentTemplate><ng-content></ng-content></ng-template>",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardStepContentComponent {
    @ViewChild("stepContentTemplate", { static: true }) public template!: TemplateRef<unknown>;
    @Input() public stepId!: string;
}
