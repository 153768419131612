import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import {
    FlightZoneApplicationPurpose,
    FlightZoneCapabilities,
    FlightZoneExclusionsConstraints,
    RestrictionExclusions,
    UavRestrictionExclusions,
} from "@dtm-frontend/dss-shared-lib";
import { phoneNumberAndCountryCodeRequired, validPhoneNumberWithCountryCode } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

interface UavRestrictionExclusionsComponentState {
    isProcessing: boolean;
    capabilities: FlightZoneCapabilities | undefined;
    constraints: FlightZoneExclusionsConstraints | undefined;
}

@Component({
    selector: "dss-client-lib-uav-restriction-exclusions[capabilities][constraints][exclusions]",
    templateUrl: "./uav-restriction-exclusions.component.html",
    styleUrls: ["../zone-restriction-exclusions-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
    providers: [LocalComponentStore],
})
export class UavRestrictionExclusionsComponent {
    public readonly agreedExclusionEnabledFormControl = new UntypedFormControl(false);
    public readonly uavMtomExclusionEnabledFormControl = new UntypedFormControl(false);
    public readonly coursesEnabledFormControl = new UntypedFormControl(false);
    public readonly operatorAuthorizationsEnabledFormControl = new UntypedFormControl(false);
    public readonly otherExclusionEnabledFormControl = new UntypedFormControl(false);

    public readonly isManagedByGovernmentFormControl = new UntypedFormControl(false);
    public readonly areOwnedFlightsFormControl = new UntypedFormControl(false);
    public readonly agreedExclusionPhoneFormControl = new UntypedFormControl({ value: null, disabled: true }, [
        phoneNumberAndCountryCodeRequired,
        validPhoneNumberWithCountryCode,
    ]);
    public readonly agreedExclusionEmailFormControl = new UntypedFormControl({ value: "", disabled: true }, [
        Validators.required,
        Validators.email,
    ]);
    public readonly coursesFormControl = new UntypedFormControl({ value: null, disabled: true }, [Validators.required]);
    public readonly operatorAuthorizationsFormControl = new UntypedFormControl(
        {
            value: null,
            disabled: true,
        },
        [Validators.required]
    );
    public readonly otherExclusionFormControl = new UntypedFormControl({ value: "", disabled: true }, [Validators.required]);
    public readonly agreedExclusionFormGroup = new UntypedFormGroup({
        phoneNumber: this.agreedExclusionPhoneFormControl,
        email: this.agreedExclusionEmailFormControl,
    });
    public readonly uavMtomExclusionFormControl = new UntypedFormControl({ value: null, disabled: true });
    public readonly exclusionsForm = this.initForm();

    public readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    public readonly capabilities$ = this.localStore.selectByKey("capabilities");
    public readonly constraints$ = this.localStore.selectByKey("constraints");

    @Input()
    public set isProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isProcessing: value });
    }

    @Input()
    public set capabilities(value: FlightZoneCapabilities | undefined) {
        this.localStore.patchState({ capabilities: value });

        if (value) {
            this.agreedExclusionFormGroup.patchValue(value.defaultInstitutionContact, { emitEvent: false });
        }
    }

    @Input()
    public set constraints(value: FlightZoneExclusionsConstraints | undefined) {
        this.localStore.patchState({ constraints: value });

        if (!value) {
            return;
        }

        this.otherExclusionFormControl.addValidators(Validators.maxLength(value.otherExclusionMaxFieldLength));
    }

    @Input()
    public set exclusions(value: RestrictionExclusions | undefined) {
        if (!value || value.purpose !== FlightZoneApplicationPurpose.ForbiddingUAVFlights) {
            return;
        }

        this.updateHiddenControlsStatus(value);
        this.exclusionsForm.patchValue(value);
        this.exclusionsForm.markAllAsTouched();
    }

    @Output() public readonly restrictionExclusionsUpdate = new EventEmitter<UavRestrictionExclusions>();
    @Output() public readonly back = new EventEmitter<void>();

    constructor(private readonly localStore: LocalComponentStore<UavRestrictionExclusionsComponentState>) {
        this.localStore.setState({
            isProcessing: false,
            capabilities: undefined,
            constraints: undefined,
        });
    }

    public changeControlValidation(checkboxValue: boolean, control: AbstractControl): void {
        if (checkboxValue) {
            control.enable();
        } else {
            control.disable();
            control.updateValueAndValidity();
        }
    }

    public submitExclusions(): void {
        const disabledControlsDefaultValues: Partial<UavRestrictionExclusions> = {
            courses: [],
            operatorAuthorizations: [],
            uavMtomExclusion: null,
            agreedExclusion: null,
            otherExclusion: "",
        };

        this.restrictionExclusionsUpdate.emit({ ...disabledControlsDefaultValues, ...this.exclusionsForm.value });
    }

    private initForm(): UntypedFormGroup {
        const formBody: Record<keyof UavRestrictionExclusions, AbstractControl> = {
            purpose: new UntypedFormControl(FlightZoneApplicationPurpose.ForbiddingUAVFlights),
            isManagedByGovernment: this.isManagedByGovernmentFormControl,
            agreedExclusion: this.agreedExclusionFormGroup,
            areOwnedFlights: this.areOwnedFlightsFormControl,
            uavMtomExclusion: this.uavMtomExclusionFormControl,
            courses: this.coursesFormControl,
            operatorAuthorizations: this.operatorAuthorizationsFormControl,
            otherExclusion: this.otherExclusionFormControl,
        };

        return new UntypedFormGroup(formBody);
    }

    private updateHiddenControlsStatus(value: UavRestrictionExclusions): void {
        this.agreedExclusionEnabledFormControl.setValue(!!value.agreedExclusion);
        this.changeControlValidation(!!value.agreedExclusion, this.agreedExclusionFormGroup);

        this.uavMtomExclusionEnabledFormControl.setValue(!!value.uavMtomExclusion);
        this.changeControlValidation(!!value.uavMtomExclusion, this.uavMtomExclusionFormControl);

        this.coursesEnabledFormControl.setValue(!!value.courses?.length);
        this.changeControlValidation(!!value.courses?.length, this.coursesFormControl);

        this.operatorAuthorizationsEnabledFormControl.setValue(!!value.operatorAuthorizations?.length);
        this.changeControlValidation(!!value.operatorAuthorizations?.length, this.operatorAuthorizationsFormControl);

        this.otherExclusionEnabledFormControl.setValue(!!value.otherExclusion?.length);
        this.changeControlValidation(!!value.otherExclusion?.length, this.otherExclusionFormControl);
    }
}
