import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { ContactPerson } from "../../../models/permits.models";

interface PermitContactPersonPreviewComponentState {
    contactPerson: ContactPerson | undefined;
}

@Component({
    selector: "dtm-ui-permit-contact-person-preview[contactPerson]",
    templateUrl: "./permit-contact-person-preview.component.html",
    styleUrls: ["./permit-contact-person-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PermitContactPersonPreviewComponent {
    @Input() public set contactPerson(value: ContactPerson | undefined) {
        this.localStore.patchState({ contactPerson: value });
    }

    protected readonly contactPerson$ = this.localStore.selectByKey("contactPerson").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<PermitContactPersonPreviewComponentState>) {
        localStore.setState({ contactPerson: undefined });
    }
}
