import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "math",
})
export class MathPipe implements PipeTransform {
    public transform(
        value: number | undefined,
        operation: "abs" | "max" | "min" | "round" | "floor" | "ceil",
        ...args: number[]
    ): number | undefined {
        const areValidArgs = args.every((arg) => !isNaN(arg));

        if (value === undefined || value === null || isNaN(value) || !operation || !areValidArgs) {
            return undefined;
        }

        try {
            return (Math as any)[operation](value, ...args);
        } catch (error) {
            return undefined;
        }
    }
}
