<ng-container *ngrxLet="{ isShortMode: isShortMode$, userName: userName$, avatarUrl: avatarUrl$ } as vm">
    <ng-container *ngIf="isUserLoggedIn$ | ngrxPush; else loginButton">
        <button type="button" class="panel-button" [matMenuTriggerFor]="userDropdownMenu" [class.short-mode]="vm.isShortMode">
            <dtm-ui-avatar [fullName]="vm.isShortMode ? undefined : vm.userName" [sourceUrl]="vm.avatarUrl"></dtm-ui-avatar>
            <dtm-ui-icon *ngIf="!vm.isShortMode" name="arrow-down" class="arrow-icon"></dtm-ui-icon>
        </button>
    </ng-container>
    <ng-template #loginButton>
        <button type="button" class="panel-button login-button" [class.short-mode]="vm.isShortMode" (click)="login.emit()">
            <dtm-ui-avatar></dtm-ui-avatar>
            <span class="login-label">{{ "dtmUi.header.loginMenuItemLabel" | transloco }}</span>
        </button>
    </ng-template>

    <mat-menu #userDropdownMenu="matMenu" xPosition="before">
        <dtm-ui-avatar *ngIf="vm.isShortMode" class="short-mode-user" [fullName]="vm.userName" [sourceUrl]="vm.avatarUrl"></dtm-ui-avatar>
        <ng-content></ng-content>
        <button *ngIf="goToUserProfilePage.observed" mat-menu-item type="button" (click)="goToUserProfilePage.emit()">
            <dtm-ui-icon name="settings"></dtm-ui-icon>
            <span>{{ "dtmUi.header.userProfileMenuItemLabel" | transloco }}</span>
        </button>
        <button mat-menu-item type="button" (click)="logout.emit()">
            <dtm-ui-icon name="logout-circle"></dtm-ui-icon>
            <span>{{ "dtmUi.header.logoutMenuItemLabel" | transloco }}</span>
        </button>
    </mat-menu>
</ng-container>
