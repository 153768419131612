<ng-container *ngrxLet="{ isOnlyHeightEnabled: isOnlyHeightEnabled$, isPathBased: isPathBased$, route: route$ } as vm">
    <ng-container *ngIf="sections$ | ngrxPush as sections; else emptyDataTemplate">
        <mat-slide-toggle labelPosition="before" class="spread allow-wrap" (change)="toggleOnlyHeight()" *ngIf="vm.isPathBased">
            {{ "dtmUi.routeDetails.onlyPointHeightLabel" | transloco }}
        </mat-slide-toggle>
        <div class="container">
            <ng-container *ngFor="let section of sections">
                <ng-container *ngIf="section.type === SectionType.RouteZone">
                    <ng-container *ngIf="!vm.isOnlyHeightEnabled && (section.isTakeoff || section.isLanding)">
                        <div class="zone">
                            <dtm-ui-icon name="shape-cylinder-3d"></dtm-ui-icon>
                            <h6 *ngIf="section.isTakeoff">{{ "dtmUi.routeDetails.takeOffLabel" | transloco }}</h6>
                            <h6 *ngIf="section.isLanding">{{ "dtmUi.routeDetails.landingLabel" | transloco }}</h6>
                            <ng-container [ngTemplateOutlet]="zoneInfo"></ng-container>
                        </div>
                        <div class="time">
                            {{ section.minTime | localizeDate : { timeStyle: "short" } }}
                            - {{ section.maxTime | localizeDate : { timeStyle: "short" } }}
                            <span class="state-indicator" [ngClass]="section.status | invoke : mapSegmentStatusToClassConfig"></span>
                        </div>
                    </ng-container>
                    <div class="name">
                        <dtm-ui-icon name="flight-takeoff" *ngIf="section.isTakeoff"></dtm-ui-icon>
                        <dtm-ui-icon name="flight-land" *ngIf="section.isLanding"></dtm-ui-icon>
                        <dtm-ui-icon name="shape-cylinder-3d" *ngIf="!section.isLanding && !section.isTakeoff"></dtm-ui-icon>
                        <h6>{{ section.name }}</h6>
                    </div>
                    <div class="info">
                        <ng-container *ngIf="section.isLanding || section.isTakeoff; else zoneInfo">
                            {{
                                "dtmUi.routeDetails.pointAltitudeLabel"
                                    | transloco
                                        : {
                                              altitude: section.center.point.altitude | number : "1.0-0",
                                              height: section.center.point.height | number : "1.0-0"
                                          }
                            }}
                        </ng-container>
                    </div>
                    <ng-template #zoneInfo>
                        <ng-container *ngIf="vm.isPathBased">
                            {{
                                "dtmUi.routeDetails.heightRangeAmslLabel" | transloco : (section.flightArea | invoke : getHeightRangeValues)
                            }}
                            {{
                                "dtmUi.routeDetails.heightAglLabel"
                                    | transloco
                                        : {
                                              height: section.flightArea | invoke : getZoneHeightValue | number : "1.0-0"
                                          }
                            }}
                        </ng-container>
                        <p *ngIf="!vm.isPathBased">
                            <strong>
                                {{
                                    "dtmUi.routeDetails.heightRangeAglLabel"
                                        | transloco : (section.flightArea | invoke : getHeightRangeAglValues)
                                }}
                            </strong>
                            {{ "dtmUi.routeDetails.heightAmslLabel" | transloco : (section.flightArea | invoke : getHeightRangeValues) }}
                        </p>
                    </ng-template>
                </ng-container>
                <ng-container *ngIf="section.type === SectionType.RoutePoint">
                    <div class="name">
                        <span class="point-mark"></span>
                        <h6>{{ section.name }}</h6>
                    </div>
                    <div class="info">
                        {{ section.point.altitude | number : "1.0-0" }} m
                        {{
                            "dtmUi.routeDetails.heightAglLabel"
                                | transloco
                                    : {
                                          height: section.point.height | number : "1.0-0"
                                      }
                        }}
                        <span class="hint" *ngIf="section.isExitPoint">
                            {{ "dtmUi.routeDetails.exitPointLabel" | transloco }}
                        </span>
                        <span class="hint" *ngIf="section.isEntryPoint">
                            {{ "dtmUi.routeDetails.entryPointLabel" | transloco }}
                        </span>
                    </div>
                </ng-container>
                <ng-container
                    *ngIf="
                        !vm.isOnlyHeightEnabled &&
                        section.type === SectionType.RouteSegment &&
                        section.flightArea.elevationMin &&
                        section.flightArea.elevationMax
                    "
                >
                    <div class="name segment">
                        <h6>{{ section.name }}</h6>
                    </div>
                    <div class="info segment">
                        {{
                            "dtmUi.routeDetails.heightRangeAmslLabel"
                                | transloco
                                    : {
                                          minHeight: section.flightArea.volume.floor | number : "1.0-0",
                                          maxHeight: section.flightArea.volume.ceiling | number : "1.0-0"
                                      }
                        }}
                        <ng-container *ngIf="section.speed">
                            ,
                            {{
                                "dtmUi.routeDetails.speedValueLabel"
                                    | transloco : { speed: (section.speed.max + section.speed.min) / 2 | number : "1.0-0" }
                            }}
                        </ng-container>
                        <div class="segment-time">
                            {{ section.fromWaypoint.estimatedArriveAt.min | localizeDate : { timeStyle: "short" } }}
                            - {{ section.toWaypoint.estimatedArriveAt.max | localizeDate : { timeStyle: "short" } }}
                            <span class="state-indicator" [ngClass]="section.status | invoke : mapSegmentStatusToClassConfig"></span>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</ng-container>
<ng-template #emptyDataTemplate>
    <dtm-ui-empty-state [mode]="EmptyStateMode.Tertiary">
        <p messageSlot>{{ "dtmUi.routeDetails.emptyData" | transloco }}</p>
    </dtm-ui-empty-state>
</ng-template>
