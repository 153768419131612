export namespace WizardActions {
    export class SetActiveStep {
        public static readonly type = "[Wizard] Set active id";
        constructor(public wizardId: string, public stepId: string) {}
    }

    export class EnableSteps {
        public static readonly type = "[Wizard] Enable steps";
        constructor(public wizardId: string, public stepIds: string[]) {}
    }

    export class DisableSteps {
        public static readonly type = "[Wizard] Disable steps";
        constructor(public wizardId: string, public stepIds: string[]) {}
    }

    export class CleanupWizard {
        public static readonly type = "[Wizard] Clean up wizard";
        constructor(public wizardId: string) {}
    }

    export class UpdateDirtyStep {
        public static readonly type = "[Wizard] Update dirty step";

        constructor(public wizardId: string, public dirtyStep: string | undefined) {}
    }

    export class ResetStepForms {
        public static readonly type = "[Wizard] Reset step forms";
        constructor(public wizardId: string) {}
    }

    export class TryChangeDirtyStep {
        public static readonly type = "[Wizard] Try change dirty step";
        constructor(public wizardId: string, public stepId: string) {}
    }
}
