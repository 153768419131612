<ng-container *ngIf="constraints$ | ngrxPush; let constraints; else: cannotGetDataErrorTemplate">
    <ng-container *ngrxLet="geometryContent$; let geometryContent">
        <dss-client-lib-wizard-step-wrapper
            [stepNumber]="2"
            [stepsAmount]="4"
            [title]="'dssClientLibFlightZone.flightZoneWizardSteps.zoneGeometry.header' | transloco"
            *ngrxLet="isProcessing$; let isProcessing"
        >
            <ng-container *ngIf="availableEditors$ | ngrxPush; let availableEditors; else: cannotGetDataErrorTemplate">
                <dtm-ui-select-field
                    id="editorTypeControl"
                    [formControl]="editorTypeFormControl"
                    (valueChange)="changeEditorType($event)"
                    [placeholder]="'dssClientLibFlightZone.zoneGeometryStep.noEditorSelectedPlaceholder' | transloco"
                    [isClearable]="false"
                >
                    <label for="editorTypeControl">{{ "dssClientLibFlightZone.zoneGeometryStep.editorTypeLabel" | transloco }}</label>
                    <dtm-ui-select-option *ngFor="let type of availableEditors" [value]="type">
                        <ng-template [ngTemplateOutlet]="editorIconTemplate" [ngTemplateOutletContext]="{ type: type }"></ng-template>
                    </dtm-ui-select-option>
                    <mat-select-trigger>
                        <ng-container *ngIf="editorTypeFormControl.value">
                            <ng-template
                                [ngTemplateOutlet]="editorIconTemplate"
                                [ngTemplateOutletContext]="{ type: editorTypeFormControl.value }"
                            ></ng-template>
                        </ng-container>
                    </mat-select-trigger>
                </dtm-ui-select-field>

                <ng-container [ngSwitch]="editorTypeFormControl.value">
                    <ng-container *ngSwitchCase="FlightZoneEditorType.Cylinder">
                        <dtm-map-coordinates-paste-area
                            *ngIf="!geometryContent.length"
                            [label]="'dssClientLibFlightZone.coordinatesPasteArea.cylinderLabel' | transloco"
                            [tooltipText]="'dssClientLibFlightZone.coordinatesPasteArea.cylinderTooltip' | transloco"
                            [editorType]="MapEntityType.Cylinder"
                            (coordinatesSubmit)="submitPasteAreaCylinderCoordinates($event)"
                        ></dtm-map-coordinates-paste-area>

                        <dss-client-lib-zone-geometry-step-cylinder-editor
                            [isProcessing]="isProcessing"
                            [constraints]="constraints"
                            [cylinderEntity]="geometryContent | extractMapEntity : MapEntityType.Cylinder"
                            [horizontalMeasureUnits]="horizontalMeasureUnits$ | ngrxPush"
                            [verticalMeasureUnits]="verticalMeasureUnits$ | ngrxPush"
                            [heightReferences]="heightReferences$ | ngrxPush"
                            [activeGeometryUnits]="activeGeometryUnits$ | ngrxPush"
                            (cylinderEntityUpdate)="entityUpdate($event, geometryContent)"
                            (statusChange)="updateGeometryValidity($event)"
                        ></dss-client-lib-zone-geometry-step-cylinder-editor>
                    </ng-container>

                    <ng-container *ngSwitchCase="FlightZoneEditorType.Prism">
                        <ng-container *ngrxLet="prismPointsLimit$ as prismPointsLimit">
                            <dtm-map-coordinates-paste-area
                                *ngIf="!geometryContent.length"
                                [label]="
                                    'dssClientLibFlightZone.coordinatesPasteArea.prismLabel' | transloco : { pointsLimit: prismPointsLimit }
                                "
                                [tooltipText]="
                                    'dssClientLibFlightZone.coordinatesPasteArea.prismTooltip'
                                        | transloco : { pointsLimit: prismPointsLimit }
                                "
                                [editorType]="MapEntityType.Prism"
                                [prismPointLimit]="prismPointsLimit"
                                (coordinatesSubmit)="submitPasteAreaPrismCoordinates($event)"
                            ></dtm-map-coordinates-paste-area>

                            <dss-client-lib-zone-geometry-step-prism-editor
                                [isProcessing]="isProcessing"
                                [prismEntity]="geometryContent | extractMapEntity : MapEntityType.Prism"
                                [constraints]="constraints"
                                [verticalMeasureUnits]="verticalMeasureUnits$ | ngrxPush"
                                [heightReferences]="heightReferences$ | ngrxPush"
                                [activeGeometryUnits]="activeGeometryUnits$ | ngrxPush"
                                [prismPointLimit]="prismPointsLimit"
                                (prismEntityUpdate)="entityUpdate($event, geometryContent)"
                                (statusChange)="updateGeometryValidity($event)"
                            ></dss-client-lib-zone-geometry-step-prism-editor>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container actions>
                <button class="button-secondary" (click)="back.emit()" [disabled]="isProcessing" type="button">
                    <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                    {{ "dssClientLibFlightZone.zoneGeometryStep.backButtonLabel" | transloco }}
                </button>

                <button
                    class="button-secondary"
                    type="button"
                    [disabled]="isProcessing || !geometryContent.length"
                    (click)="removeGeometry()"
                >
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    {{ "dssClientLibFlightZone.zoneGeometryStep.clearZoneButtonLabel" | transloco }}
                </button>
                <button class="button-secondary" type="button" [disabled]="!geometryContent.length" (click)="zoomToGeometry.emit()">
                    <dtm-ui-icon name="focus-2"></dtm-ui-icon>
                    {{ "dssClientLibFlightZone.zoneGeometryStep.zoomToZoneButtonLabel" | transloco }}
                </button>

                <button
                    *ngrxLet="{ isGeometryValid: isGeometryValid$, isCircularBoundaryValid: isCircularBoundaryValid$ } as vm"
                    class="button-primary"
                    [disabled]="isProcessing || !vm.isGeometryValid || !vm.isCircularBoundaryValid"
                    type="button"
                    (click)="zoneGeometrySubmit.emit()"
                >
                    {{ "dssClientLibFlightZone.zoneGeometryStep.nextButtonLabel" | transloco }}
                </button>
            </ng-container>
        </dss-client-lib-wizard-step-wrapper>
    </ng-container>
</ng-container>

<ng-template #editorIconTemplate let-type="type">
    <div class="editor-type-option">
        <ng-container [ngSwitch]="type">
            <ng-container *ngSwitchCase="FlightZoneEditorType.Cylinder">
                <dtm-ui-icon name="shape-cylinder"></dtm-ui-icon>
                {{ "dssClientLibFlightZone.zoneGeometryStep.cylinderEditorLabel" | transloco }}
            </ng-container>
            <ng-container *ngSwitchCase="FlightZoneEditorType.Prism">
                <dtm-ui-icon name="shape-prism"></dtm-ui-icon>
                {{ "dssClientLibFlightZone.zoneGeometryStep.prismEditorLabel" | transloco }}
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #cannotGetDataErrorTemplate>
    <div>{{ "dssClientLibFlightZone.cannotGetDataErrorMessage" | transloco }}</div>
</ng-template>
