import { Injectable } from "@angular/core";
import {
    BaseNotificationEventsService,
    DssNotification,
    DssNotificationsService,
    DssRestrictionModificationNotificationType,
} from "@dtm-frontend/dss-shared-lib";
import { Notification, NotificationDisplay, SharedNotificationDisplayProps } from "@dtm-frontend/shared/notifications";
import { IconName } from "@dtm-frontend/shared/ui";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";

@Injectable({
    providedIn: "root",
})
export class InstitutionModificationNotificationEventsService implements BaseNotificationEventsService {
    constructor(private readonly translocoService: TranslocoService, private readonly dssNotificationsService: DssNotificationsService) {}

    public getEventsList(notificationsToFormat: DssNotification[]): NotificationDisplay[] {
        return notificationsToFormat
            .filter((notification) =>
                this.dssNotificationsService
                    .getDssRestrictionModificationNotificationTypes()
                    .some((type) => notification.payload.type === type)
            )
            .map((notification) => {
                const sharedProps = this.getSharedProps(notification, "file-edit");

                switch (notification.payload.type) {
                    case DssRestrictionModificationNotificationType.AnspPublishedRestrictionModificationApplicationEvent:
                        return this.getAnspPublishedRestrictionModificationApplicationEvent(sharedProps, notification);

                    case DssRestrictionModificationNotificationType.RejectedRestrictionModificationApplicationEvent:
                        return this.getRejectedRestrictionModificationApplicationEvent(sharedProps, notification);

                    case DssRestrictionModificationNotificationType.InstitutionReAcceptedEvent:
                        return this.getInstitutionReAcceptedEvent(sharedProps, notification);

                    case DssRestrictionModificationNotificationType.LockedRestrictionModificationApplicationEvent:
                        return this.getLockedRestrictionModificationApplicationEvent(sharedProps, notification);

                    case DssRestrictionModificationNotificationType.UnlockedRestrictionModificationApplicationEvent:
                        return this.getUnlockedRestrictionModificationApplicationEvent(sharedProps, notification);

                    default:
                        return null;
                }
            })
            .filter(FunctionUtils.isTruthy);
    }

    private getAnspPublishedRestrictionModificationApplicationEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: DssNotification
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssClient.notifications.restrictionModificationLabel"),
            text: this.translocoService.translate("dssClient.notifications.anspPublishedRestrictionModificationApplicationText"),
            redirectUrl: `restriction/${notification.payload.id}`,
        };
    }

    private getRejectedRestrictionModificationApplicationEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: DssNotification
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssClient.notifications.restrictionModificationLabel"),
            text: this.translocoService.translate("dssClient.notifications.rejectedRestrictionModificationApplicationText"),
            redirectUrl: `modification/preview/${notification.payload.id}`,
        };
    }

    private getInstitutionReAcceptedEvent(sharedProps: SharedNotificationDisplayProps, notification: DssNotification): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssClient.notifications.restrictionModificationLabel"),
            text: this.translocoService.translate("dssClient.notifications.institutionReAcceptedText"),
            redirectUrl: `modification/${notification.payload.id}`,
        };
    }

    private getLockedRestrictionModificationApplicationEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: DssNotification
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssClient.notifications.restrictionModificationLabel"),
            text: this.translocoService.translate("dssClient.notifications.modificationLockedText"),
            redirectUrl: `modification/${notification.payload.id}`,
        };
    }

    private getUnlockedRestrictionModificationApplicationEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: DssNotification
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssClient.notifications.restrictionModificationLabel"),
            text: this.translocoService.translate("dssClient.notifications.modificationUnlockedText"),
            redirectUrl: `modification/${notification.payload.id}`,
        };
    }

    private getSharedProps({ createdAt, id, read }: Notification, icon: IconName): SharedNotificationDisplayProps {
        return { icon, date: createdAt, id: id, isRead: read };
    }
}
