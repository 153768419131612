import { Directive, ElementRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Directive({ selector: "[dtmUiDisablableInputField][disabled]" })
export class DisablableInputFieldDirective {
    private readonly isDisabledSubject;
    public readonly isDisabled$;

    constructor(element: ElementRef) {
        this.isDisabledSubject = new BehaviorSubject<boolean>(!!element.nativeElement.disabled);
        this.isDisabled$ = this.isDisabledSubject.asObservable();

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === "disabled") {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    this.isDisabledSubject.next(!!(mutation.target as any).disabled);
                }
            });
        });

        observer.observe(element.nativeElement, { attributes: true });
    }
}
