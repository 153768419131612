import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject } from "rxjs";
import { ViewControl } from "../map-controls.models";

interface MissionViewSettingsComponentState {
    viewControls: ViewControl[];
}

@UntilDestroy()
@Component({
    selector: "dtm-map-mission-view-settings[viewControls]]",
    templateUrl: "./mission-view-settings.component.html",
    styleUrls: ["./mission-view-settings.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionViewSettingsComponent implements OnInit {
    @Input() public set viewControls(value: ViewControl[]) {
        this.localStore.patchState({ viewControls: value });
        this.updateControlsState(value);
    }

    @Input() public set viewControlsValue(value: Partial<Record<ViewControl, boolean>>) {
        this.areaVisibilityForm.patchValue(value, { emitEvent: false });
    }

    protected readonly areaVisibilityForm = new FormGroup({
        [ViewControl.SafetyArea]: new FormControl(true, { nonNullable: true }),
        [ViewControl.GroundRiskBuffer]: new FormControl(true, { nonNullable: true }),
        [ViewControl.Path]: new FormControl(true, { nonNullable: true }),
    });

    @Output() public readonly viewControlsValueChange = new Subject<Partial<Record<ViewControl, boolean>>>();

    protected readonly viewControls$ = this.localStore.selectByKey("viewControls");

    constructor(private readonly localStore: LocalComponentStore<MissionViewSettingsComponentState>) {
        localStore.setState({ viewControls: [] });
        this.watchAndUpdateFormState();
    }

    public ngOnInit(): void {
        this.areaVisibilityForm.updateValueAndValidity();
    }

    private watchAndUpdateFormState() {
        this.areaVisibilityForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            if (
                value[ViewControl.SafetyArea] &&
                this.localStore.selectSnapshotByKey("viewControls").includes(ViewControl.GroundRiskBuffer)
            ) {
                this.areaVisibilityForm.controls[ViewControl.GroundRiskBuffer].enable({ emitEvent: false });
            } else {
                this.areaVisibilityForm.controls[ViewControl.GroundRiskBuffer].disable({ emitEvent: false });
            }

            this.viewControlsValueChange.next(this.areaVisibilityForm.value);
        });
    }

    private updateControlsState(value: ViewControl[]) {
        Object.keys(ViewControl).forEach((control) => {
            const typedControlName = control as ViewControl;
            if (!value.includes(typedControlName)) {
                this.areaVisibilityForm.controls[typedControlName]?.disable({ emitEvent: false });
            } else {
                this.areaVisibilityForm.controls[typedControlName]?.enable({ emitEvent: false });
            }
        });

        this.areaVisibilityForm.updateValueAndValidity();
    }
}
