<ng-container *ngrxLet="error$ as error">
    <ng-container [ngSwitch]="error?.type">
        <div *ngSwitchCase="FlightZoneErrorType.CannotGetApplication" class="error">
            {{ "dssClientLibFlightZone.cannotGetDataErrorMessage" | transloco }}
        </div>
        <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="noErrorTemplate"></ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #noErrorTemplate>
    <div class="application-preview" *ngIf="flightZoneRestrictionData$ | ngrxPush as flightZoneRestrictionData">
        <div class="card-box">
            <div class="overflow-container">
                <dss-shared-lib-restriction-preview-header
                    [headerTitle]="'dssClientLibFlightZone.applicationPreview.title' | transloco"
                    [isProcessing]="isProcessing$ | ngrxPush"
                    (pdfDownload)="downloadPdf(flightZoneRestrictionData)"
                    (confirmationOfApplicationSubmissionPdfDownload)="
                        downloadConfirmationOfApplicationSubmissionPdf(flightZoneRestrictionData)
                    "
                ></dss-shared-lib-restriction-preview-header>
                <dss-shared-lib-application-summary
                    [basicDataForm]="flightZoneRestrictionData.basicData"
                    [restrictionAreaGeometry]="flightZoneRestrictionData.restrictionAreaGeometry"
                    [restrictionExclusions]="flightZoneRestrictionData.restrictionExclusions"
                    [analysisStatus]="flightZoneRestrictionData.analysisStatus"
                    [capabilities]="capabilities$ | ngrxPush"
                >
                </dss-shared-lib-application-summary>
                <dss-shared-lib-application-note
                    [note]="flightZoneRestrictionData.note"
                    [isReadOnly]="true"
                ></dss-shared-lib-application-note>
            </div>
            <div class="actions">
                <button
                    type="button"
                    class="button-primary full-width-button"
                    [disabled]="flightZoneRestrictionData.basicData.isBeingCancelled || flightZoneRestrictionData.basicData.isBeingModified"
                    (click)="
                        changeZoneDuration({
                            id: flightZoneRestrictionData.id,
                            startAt: flightZoneRestrictionData.basicData.startTime,
                            endAt: flightZoneRestrictionData.basicData.endTime,
                            isStateSecurityRestriction: flightZoneRestrictionData.basicData.hasStateSecurityRestriction
                        })
                    "
                >
                    <dtm-ui-icon name="time"></dtm-ui-icon>
                    <span>{{ "dssClientLibFlightZone.summaryStep.changeZoneDurationActionLabel" | transloco }}</span>
                </button>
                <button
                    type="button"
                    *ngIf="flightZoneRestrictionData.analysisStatus.notamNumber"
                    class="button-secondary"
                    (click)="openNotamPreview(flightZoneRestrictionData.lastApplicationId)"
                >
                    {{ "dssClientLibFlightZone.summaryStep.openNotamButtonLabel" | transloco }}
                </button>
                <button class="button-secondary" (click)="zoomToGeometry()" type="button">
                    <dtm-ui-icon name="focus-2"></dtm-ui-icon>
                    {{ "dssClientLibFlightZone.summaryStep.zoomToZoneButtonLabel" | transloco }}
                </button>
            </div>
        </div>
        <dss-shared-lib-map-container [shouldShowActiveRestrictionsLayer]="true"></dss-shared-lib-map-container>
    </div>
</ng-template>
