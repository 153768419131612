/**
 * Partitions an array into two arrays based on a predicate function.
 * @param iterable
 * @param predicate
 * @returns [pass, fail]
 */
export function partition<T>(iterable: T[], predicate: (item: T) => boolean): T[][] {
    return iterable.reduce<T[][]>(
        ([pass, fail], element) => {
            (predicate(element) ? pass : fail).push(element);

            return [pass, fail];
        },
        [[], []]
    );
}
