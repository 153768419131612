import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    Input,
    ViewChild,
} from "@angular/core";
import { FormControlStatus, NgControl, UntypedFormControl } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { first, map } from "rxjs";
import { startWith } from "rxjs/operators";
import { FormFieldComponent } from "../form-field/form-field.component";

interface TextareaFieldComponentState {
    isDisabled: boolean;
    isActivated: boolean;
    maxLength: number | null;
    currentLength: number;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-textarea-field",
    templateUrl: "./textarea-field.component.html",
    styleUrls: ["./textarea-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TextareaFieldComponent implements AfterContentInit, AfterViewInit {
    @ContentChild(NgControl) private input!: NgControl;
    @ViewChild(FormFieldComponent, { read: ElementRef }) private formField: ElementRef<HTMLHtmlElement> | undefined;
    @Input() public set maxLength(value: number | undefined) {
        this.localStore.patchState({ maxLength: value ?? null });
    }

    public isDisabled$ = this.localStore.selectByKey("isDisabled");
    public isActivated$ = this.localStore.selectByKey("isActivated");
    public maxLength$ = this.localStore.selectByKey("maxLength");
    public currentLength$ = this.localStore.selectByKey("currentLength");

    constructor(private readonly localStore: LocalComponentStore<TextareaFieldComponentState>) {
        this.localStore.setState({
            isDisabled: false,
            isActivated: false,
            maxLength: null,
            currentLength: 0,
        });
    }

    public ngAfterContentInit() {
        this.input?.valueChanges?.pipe(first(), untilDestroyed(this)).subscribe(() => {
            this.localStore.patchState({
                isActivated: true,
            });
        });

        this.input?.valueChanges?.pipe(startWith(this.input?.value), untilDestroyed(this)).subscribe((value) => {
            this.localStore.patchState({
                currentLength: value?.length ?? 0,
            });
        });

        this.input?.statusChanges
            ?.pipe(
                map((status: FormControlStatus) => status === "DISABLED"),
                startWith(!!this.input?.disabled),
                untilDestroyed(this)
            )
            .subscribe((isDisabled: boolean) => {
                this.localStore.patchState({ isDisabled });
            });

        if (this.localStore.selectSnapshotByKey("maxLength") === null) {
            this.checkMaxLengthValidator(this.input);
        }
    }

    public ngAfterViewInit(): void {
        this.createAriaLabelForTextarea();
    }

    private checkMaxLengthValidator(input: NgControl): void {
        const maxLength = this.getMaxLengthValidatorValue(input);

        this.localStore.patchState({
            maxLength,
        });
    }

    // NOTE: There is no other way to check if FormControl has maxlength validator and get max length value
    private getMaxLengthValidatorValue(input: NgControl): number | null {
        // eslint-disable-next-line no-magic-numbers
        const validation = input.control?.validator && input.control?.validator(new UntypedFormControl(".".repeat(10e6)));

        return validation?.maxlength?.requiredLength ?? null;
    }

    private createAriaLabelForTextarea() {
        const inputElement = this.formField?.nativeElement?.querySelector(".textarea-field > textarea");
        const label = this.formField?.nativeElement?.querySelector("label");
        const placeholder = inputElement?.getAttribute("placeholder");

        if (inputElement && label) {
            inputElement.setAttribute("aria-label", label.textContent ?? "");
        } else if (inputElement && placeholder) {
            inputElement.setAttribute("aria-label", placeholder);
        }
    }
}
