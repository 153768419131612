import { Pipe, PipeTransform } from "@angular/core";
import { AirRiskMitigationsArc } from "../models/mission.models";

@Pipe({
    name: "airRiskMitigationsArcAsNumber",
})
export class AirRiskMitigationsArcAsNumberPipe implements PipeTransform {
    public transform(arc: AirRiskMitigationsArc | undefined): number | undefined {
        if (arc === undefined) {
            return undefined;
        }

        /* eslint-disable no-magic-numbers*/
        switch (arc) {
            case AirRiskMitigationsArc.B:
                return 2;
            case AirRiskMitigationsArc.C:
                return 3;
            case AirRiskMitigationsArc.D:
                return 4;
            default:
                return 0; // NOTE: invalid value
        }
        /* eslint-enable no-magic-numbers*/
    }
}
