import { OverlayRef } from "@angular/cdk/overlay";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ZoomImageOverlayService {
    private readonly createdOverlaySubject = new BehaviorSubject<OverlayRef | undefined>(undefined);
    public readonly createdOverlay$ = this.createdOverlaySubject.asObservable();

    public addCreatedOverlaySubject(overlayRef: OverlayRef) {
        this.createdOverlaySubject.next(overlayRef);
    }

    public removeCreatedOverlay() {
        this.createdOverlaySubject.next(undefined);
    }
}
