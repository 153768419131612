<ng-container
    *ngrxLet="{
        canRemoveFile: canRemoveFile$,
        isDisabled: isDisabled$,
        isDisplayFileVisible: isDisplayFileVisible$,
        isDownloadAllButtonVisible: isDownloadAllButtonVisible$,
        shouldHideDownloadButtons: shouldHideDownloadButtons$,
        uploadedFiles: uploadedFiles$,
    } as vm"
>
    <div class="header">
        <div class="label" #labelContent>
            <ng-content></ng-content>
        </div>
        <button
            *ngIf="!(labelContent | isEmptyElement) || vm.isDownloadAllButtonVisible"
            [class.hidden]="!vm.isDownloadAllButtonVisible"
            type="button"
            class="button-secondary"
            (click)="downloadAll()"
        >
            <dtm-ui-icon name="download"></dtm-ui-icon>
            {{ "dtmUi.filesUpload.downloadAll" | transloco }}
        </button>
    </div>

    <ul class="uploaded-files">
        <li *ngFor="let file of vm.uploadedFiles">
            <div class="name-with-icon">
                <dtm-ui-icon name="attachment"></dtm-ui-icon>
                <span class="file-name">
                    {{ file.name }}
                    <span *ngIf="file.size" class="file-size">({{ file.size | formatFileSize }})</span>
                </span>
            </div>
            <div class="actions">
                <button *ngIf="!vm.shouldHideDownloadButtons" type="button" class="button-icon" (click)="downloadFile(file)">
                    <dtm-ui-icon name="download"></dtm-ui-icon>
                </button>
                <button
                    *ngIf="vm.canRemoveFile"
                    type="button"
                    class="button-icon"
                    (click)="tryRemoveFile.emit(file)"
                    [disabled]="vm.isDisabled"
                >
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                </button>
                <button type="button" *ngIf="vm.isDisplayFileVisible" class="button-icon" (click)="displayFile.emit()">
                    <dtm-ui-icon name="eye"></dtm-ui-icon>
                </button>
            </div>
        </li>
    </ul>
</ng-container>
