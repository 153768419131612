import { Pipe, PipeTransform } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";

@Pipe({
    name: "sailLevel",
})
export class SailLevelPipe implements PipeTransform {
    constructor(private readonly translocoService: TranslocoService) {}

    public transform(sail: number | undefined): string | undefined {
        if (sail === undefined || sail === null) {
            return undefined;
        }

        return this.translocoService.translate("dtmUi.sailLabel", { value: sail });
    }
}
