import { Cartesian3, EditPoint, PointProps } from "@pansa/ngx-cesium";
import { convertCartesian3ToSerializableCartographic } from "../utils/convert-cartesian3-to-serializable-cartographic";
import { SerializableCartographic } from "./serializable-cartographic";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export interface CesiumPointWithProps {
    position: Cartesian3;
    pointProps: PointProps;
}

export interface CartographicEditPointParentEntity {
    getId(): string;
    isStaticEntity: boolean;
}

export class CartographicEditPoint extends EditPoint {
    private cartographic: SerializableCartographic;
    private isCartographicValid = true;

    constructor(
        private readonly entity: CartographicEditPointParentEntity,
        position: Cartesian3,
        pointProps?: PointProps,
        isVirtualPoint?: boolean
    ) {
        super(entity.getId(), position.clone(), pointProps, isVirtualPoint);

        this.cartographic = convertCartesian3ToSerializableCartographic(position);
    }

    public setPosition(position: Cartesian3) {
        super.setPosition(position);

        this.isCartographicValid = false;
    }

    public getCartographic() {
        if (!this.isCartographicValid) {
            this.cartographic = convertCartesian3ToSerializableCartographic(this.getPosition());
            this.isCartographicValid = true;
        }

        return this.cartographic;
    }

    public getPositionCallbackProperty() {
        return new Cesium.CallbackProperty(this.getPosition.bind(this), this.entity.isStaticEntity);
    }
}
