import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

interface RiskLegendComponentState {
    isLegendVisible: boolean;
}

@Component({
    selector: "dtm-map-risk-legend",
    templateUrl: "./risk-legend.component.html",
    styleUrls: ["./risk-legend.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class RiskLegendComponent {
    protected readonly isLegendVisible$ = this.localStore.selectByKey("isLegendVisible");

    constructor(private readonly localStore: LocalComponentStore<RiskLegendComponentState>) {
        this.localStore.setState({
            isLegendVisible: false,
        });
    }

    protected changeLegendVisibility(): void {
        this.localStore.patchState((state) => ({ isLegendVisible: !state.isLegendVisible }));
    }
}
