import { GeoJSON, TimeRange } from "@dtm-frontend/shared/ui";
import { ArrayElementType } from "@dtm-frontend/shared/utils";
import { Locale } from "@jsverse/transloco-locale/lib/transloco-locale.types";
import { Polygon } from "@turf/helpers";

export interface AirspaceElementResponseBody {
    airspaceElements: {
        airspaceElementId?: string;
        airspaceElementIds: string[];
        color: string;
        designator: string;
        geometry: Polygon;
        geozone: GeoZoneType;
        parent?: string | null;
        reservations: ReservationResponseElement[];
        scope: ScopeResponseElement;
        staticElement: boolean;
        status: AirspaceElementStatus;
        tag?: string;
        type: string;
        information?: {
            contact?: string;
            flightConditions?: string;
            description: string;
            languageTag: string;
        }[];
        h24?: boolean;
        supervisor?: string;
        zoneAttributes?: {
            adsbForBvlosRequired: boolean;
            bvlosFlightsForbidden: boolean;
            bvlosPathFlightsForbidden: boolean;
            localInformationType?: InfoZoneType;
            name?: string;
            vlosFlightsForbidden: boolean;
            preTacticalOwnerApprovalRequired?: boolean;
        };
    }[];
    aupEndTime?: string;
    airacEndTime?: string;
}

export interface ScopeResponseElement {
    endTime: string;
    lowerLimit: number;
    startTime: string;
    upperLimit: number;
}

export interface ReservationResponseElement {
    id: string;
    reservedBy?: string;
    scope: ScopeResponseElement;
    status: AirspaceElementStatus;
}

export enum GeoZoneType {
    DroneAirspaceProhibited = "DRA-P",
    DroneAirspaceRestricted = "DRA-R",
    DroneAirspaceRestrictedHigh = "DRA-RH",
    DroneAirspaceRestrictedHigh6Km = "DRA-RH-6KM",
    DroneAirspaceRestrictedMedium = "DRA-RM",
    DroneAirspaceRestrictedLow = "DRA-RL",
    DroneAirspaceInformation = "DRA-I",
    DroneTrafficManagement = "DTM",
    Local = "LOCAL",
}

export interface SearchAirspaceElementsRequestBody {
    geometry?: GeoJSON;
    geozones?: string[] | null;
    scope: {
        endTime?: Date | null;
        lowerLimit?: number;
        startTime?: Date | null;
        upperLimit?: number;
    };
    type?: string | null;
    designators?: string[];
    includeInformation?: boolean;
    includeLocal?: boolean;
    localInformationTypes?: string[];
    flatten?: boolean;
    includeTemporary?: boolean;
}

export type SearchAirspaceElementsOptions = SearchAirspaceElementsRequestBody;

export enum AirspaceElementStatus {
    Active = "ACTIVE",
    Inactive = "INACTIVE",
    Waiting = "WAITING",
}

export interface Reservation {
    id: string;
    reservedBy?: string;
    scope: AirspaceScope;
    status: AirspaceElementStatus;
}

export interface AirspaceScope {
    endTime: Date;
    lowerLimit: number;
    startTime: Date;
    upperLimit: number;
}

export interface AirspaceElement {
    activeTime?: {
        from: Date;
        to: Date;
    };
    geometry: Polygon;
    id: string;
    ids: string[];
    isActive: boolean;
    isStatic: boolean;
    geoZoneType: GeoZoneType;
    type: string;
    data?: ArrayElementType<AirspaceElementResponseBody["airspaceElements"]>; // NOTE: Debugging purpose
    designator?: string;
    reservations: Reservation[];
    scope?: AirspaceScope;
    information?: {
        [languageCode: Locale]: {
            contact?: string;
            flightConditions?: TextWithTooltip[];
            description: TextWithTooltip;
        };
    };
    isSelected?: boolean;
    isH24?: boolean;
    parent?: string | null;
    supervisor?: string;
    isOrdered?: boolean;
    infoZoneType?: InfoZoneType;
    zoneName?: string;
    zoneAttributes?: {
        isAdsbForBvlosRequired: boolean;
        isBvlosFlightsForbidden: boolean;
        isBvlosPathFlightsForbidden: boolean;
        localInformationType?: InfoZoneType;
        name?: string;
        isVlosFlightsForbidden: boolean;
    };
}

export interface TextWithTooltip {
    text: string;
    tooltip?: string;
}

export interface AirspaceElementsInfo {
    airspaceElements: AirspaceElement[];
    doesAupExist: boolean;
    aupEndTime?: Date;
    scopeTimeRange?: TimeRange;
    airacEndTime?: Date;
}

export enum GesZonesError {
    DataLoadingError = "DataLoadingError",
    ZonesWithInfoLoadingError = "ZonesWithInfoLoadingError",
}

export enum ZoneTimesSetting {
    MissionTime = "MissionTime",
    Soon = "Soon",
    CustomTimeRange = "CustomTimeRange",
    Current = "Current",
}

export interface TimeSettingOptions {
    available: ZoneTimesSetting[];
    default?: ZoneTimesSetting;
}

export enum InfoZoneType {
    AdsbCoverMap = "ADSB_COVER_MAP",
    AdsbReceivers = "ADSB_RECEIVERS",
    Landings = "LANDINGS",
    Obstacles = "OBSTACLES",
    OtherInformation = "OTHER_INFORMATION",
    PermanentGathering = "PERMANENT_GATHERING",
    TemporaryGathering = "TEMPORARY_GATHERING",
}

export const InfoZoneIcons: { [key in InfoZoneType]?: string } = {
    [InfoZoneType.AdsbCoverMap]: "signal-tower-fill",
    [InfoZoneType.AdsbReceivers]: "base-station-fill",
    [InfoZoneType.Landings]: "hems-emr",
    [InfoZoneType.Obstacles]: "barricade-fill",
    [InfoZoneType.OtherInformation]: "stack-fill",
    [InfoZoneType.PermanentGathering]: "complex-team-fill",
    [InfoZoneType.TemporaryGathering]: "complex-team-fill-less",
};

export const AUP_START_TIME_HOUR = 6;
export const CTR_EP_PATTERN = /^CTR EP\w+$/;
export const CTR_EP_1KM_PATTERN = /^CTREP\w+1KM$/;
export const CTR_PATTERN = /^CTRE\w+$/;
export const MCTR_PATTERN = /^MCTR\w+$/;
export const RPA_ZONE_TYPE = "RPA";
