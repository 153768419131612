import { Pipe, PipeTransform } from "@angular/core";
import { Locale, NumberFormatOptions, TranslocoDecimalPipe } from "@jsverse/transloco-locale";

@Pipe({
    name: "localizeNumber",
    pure: false,
})
export class LocalizeNumberPipe extends TranslocoDecimalPipe implements PipeTransform {
    public transform(value?: string | number | null, numberFormatOptions?: NumberFormatOptions, locale?: Locale): string {
        if (value === undefined || value === null) {
            return "";
        }

        return super.transform(value, numberFormatOptions, locale);
    }
}
