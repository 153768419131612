import { NarrowIndexable } from "@dtm-frontend/shared/utils";
import { defaultLabelProps, LabelProps } from "@pansa/ngx-cesium";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export const DEFAULT_LABEL_PROPS: LabelProps = {
    ...defaultLabelProps,
    font: "18px Roboto",
    outlineWidth: 4,
    fillColor: Cesium.Color.WHITE,
    outlineColor: Cesium.Color.fromCssColorString("rgb(6, 22, 54)"), // $color-gray-900
    style: Cesium.LabelStyle.FILL_AND_OUTLINE,
    disableDepthTestDistance: Number.POSITIVE_INFINITY,
};

export type LabelFn<T, R = string> = (value: T) => R | undefined;
export interface LabelProviders {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [providerKey: string]: LabelFn<any, any> | undefined;
}

export interface EntityLabel<T extends LabelProviders> extends LabelProps {
    id: keyof NarrowIndexable<T>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    text: any;
    entityId?: string;
}
