<ng-container *ngrxLet="contactPerson$ as contactPerson">
    <div class="column">
        <dtm-ui-label-value
            [label]="'dtmUi.permitContactPerson.nameLabel' | transloco"
            [value]="contactPerson.firstName + ' ' + contactPerson.lastName"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="contactPerson.position"
            [label]="'dtmUi.permitContactPerson.positionLabel' | transloco"
            [value]="contactPerson.position"
        ></dtm-ui-label-value>
    </div>
    <div class="column">
        <dtm-ui-label-value [label]="'dtmUi.permitContactPerson.emailLabel' | transloco" [value]="contactPerson.email"></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmUi.permitContactPerson.phoneNumberLabel' | transloco"
            [value]="contactPerson.phoneNumber | formatPhoneNumber"
        ></dtm-ui-label-value>
    </div>
</ng-container>
