import { ModuleWithProviders, NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { GeoZonesApiService } from "./services/geo-zones-api.service";
import { GeoZonesState } from "./state/geo-zones.state";

@NgModule({
    declarations: [],
    imports: [NgxsModule.forFeature([GeoZonesState])],
    exports: [],
})
export class GeoZonesModule {
    public static forRoot(): ModuleWithProviders<GeoZonesModule> {
        return {
            ngModule: GeoZonesModule,
            providers: [
                {
                    provide: GeoZonesApiService,
                    useClass: GeoZonesApiService,
                },
            ],
        };
    }
    public static forTest(apiService: unknown = null): ModuleWithProviders<GeoZonesModule> {
        return {
            ngModule: GeoZonesModule,
            providers: [
                {
                    provide: GeoZonesApiService,
                    useValue: apiService,
                },
            ],
        };
    }
}
