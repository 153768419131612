import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import {
    FlightZoneApiService,
    FlightZoneCapabilitiesState,
    FlightZoneGeometryTranslationProvider,
    FlightZoneRestrictionState,
    FlightZoneSharedModule,
    ListStatusTranslationService,
} from "@dtm-frontend/dss-shared-lib";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { DmsCoordinatesModule } from "@dtm-frontend/shared/ui/dms-coordinates";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { SharedToastModule } from "@dtm-frontend/shared/ui/toast";
import { WizardModule } from "@dtm-frontend/shared/ui/wizard";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { ToastContainerModule } from "ngx-toastr";
import { ApplicationDraftListContainerComponent } from "./components/application-draft-list/container/application-draft-list-container.component";
import { ApplicationDraftListComponent } from "./components/application-draft-list/list/application-draft-list.component";
import { ApplicationPreviewComponent } from "./components/application-preview/application-preview.component";
import { ApplicationStatusBadgeComponent } from "./components/application-status-badge/application-status-badge.component";
import { CircularBoundaryViolationToastComponent } from "./components/flight-zone-wizard/flight-zone-wizard-content/circular-boundary-violation/circular-boundary-violation-toast/circular-boundary-violation-toast.component";
import { CircularBoundaryViolationComponent } from "./components/flight-zone-wizard/flight-zone-wizard-content/circular-boundary-violation/circular-boundary-violation.component";
import { FlightZoneWizardContentComponent } from "./components/flight-zone-wizard/flight-zone-wizard-content/flight-zone-wizard-content.component";
import { FlightZoneWizardHeaderComponent } from "./components/flight-zone-wizard/flight-zone-wizard-header/flight-zone-wizard-header.component";
import { BasicZoneInfoStepComponent } from "./components/flight-zone-wizard/steps/basic-zone-info-step/basic-zone-info-step.component";
import { ZoneGeometryStepCylinderEditorComponent } from "./components/flight-zone-wizard/steps/zone-geometry-step-cylinder-editor/zone-geometry-step-cylinder-editor.component";
import { ZoneGeometryStepPrismEditorComponent } from "./components/flight-zone-wizard/steps/zone-geometry-step-prism-editor/zone-geometry-step-prism-editor.component";
import { ZoneGeometryStepComponent } from "./components/flight-zone-wizard/steps/zone-geometry-step/zone-geometry-step.component";
import { GeneralAviationRestrictionExclusionsComponent } from "./components/flight-zone-wizard/steps/zone-restriction-exclusions-step/general-aviation-restriction-exclusions/general-aviation-restriction-exclusions.component";
import { MtomFieldComponent } from "./components/flight-zone-wizard/steps/zone-restriction-exclusions-step/mtom-field/mtom-field.component";
import { SecuringOwnFlightsRestrictionExclusionsComponent } from "./components/flight-zone-wizard/steps/zone-restriction-exclusions-step/securing-own-flights-restriction-exclusions/securing-own-flights-restriction-exclusions.component";
import { UavRestrictionExclusionsComponent } from "./components/flight-zone-wizard/steps/zone-restriction-exclusions-step/uav-restriction-exclusions/uav-restriction-exclusions.component";
import { ZoneRestrictionExclusionsStepComponent } from "./components/flight-zone-wizard/steps/zone-restriction-exclusions-step/zone-restriction-exclusions-step.component";
import { ZoneSummaryStepComponent } from "./components/flight-zone-wizard/steps/zone-summary-step/zone-summary-step.component";
import { WizardStepWrapperComponent } from "./components/flight-zone-wizard/utils/step-wrapper/step-wrapper.component";
import { ModificationStatusBadgeComponent } from "./components/modification-status-badge/modification-status-badge.component";
import { RestrictionPreviewComponent } from "./components/restriction-preview/restriction-preview.component";
import { RestrictionsListContainerComponent } from "./components/restrictions-list/container/restrictions-list-container.component";
import { RestrictionsListComponent } from "./components/restrictions-list/list/restrictions-list.component";
import { SubmittedListComponent } from "./components/submitted-list/list/submitted-list.component";
import { SubmittedApplicationsListContainerComponent } from "./components/submitted-list/submitted-applications-container/submitted-applications-list-container.component";
import { SubmittedModificationsListContainerComponent } from "./components/submitted-list/submitted-modifications-container/submitted-modifications-list-container.component";
import { ZoneDurationChangeComponent } from "./components/zone-duration-change/zone-duration-change.component";
import { IS_PRE_TACTICAL_APPROVAL_ENABLED } from "./flight-zone.tokens";
import { MetersToOtherUnitsOfMeasureConverterPipe } from "./pipes/meters-to-other-units-of-measure-converer/meters-to-other-units-of-measure-converter.pipe";
import { ApplicationStatusTranslationService } from "./services/application-status-translation.service";
import { FlightZoneGeometryTranslatorService } from "./services/flight-zone-geometry-translator.service";
import { FlightZoneListsState } from "./state/flight-zone-lists.state";
import { FlightZoneState } from "./state/flight-zone.state";

@NgModule({
    declarations: [
        FlightZoneWizardContentComponent,
        BasicZoneInfoStepComponent,
        FlightZoneWizardHeaderComponent,
        ZoneGeometryStepComponent,
        ZoneGeometryStepCylinderEditorComponent,
        MetersToOtherUnitsOfMeasureConverterPipe,
        ZoneRestrictionExclusionsStepComponent,
        ZoneSummaryStepComponent,
        ApplicationDraftListComponent,
        ApplicationDraftListContainerComponent,
        ApplicationPreviewComponent,
        GeneralAviationRestrictionExclusionsComponent,
        UavRestrictionExclusionsComponent,
        SecuringOwnFlightsRestrictionExclusionsComponent,
        ZoneGeometryStepPrismEditorComponent,
        MtomFieldComponent,
        WizardStepWrapperComponent,
        SubmittedApplicationsListContainerComponent,
        SubmittedListComponent,
        SubmittedModificationsListContainerComponent,
        RestrictionsListContainerComponent,
        RestrictionsListComponent,
        ApplicationStatusBadgeComponent,
        ZoneDurationChangeComponent,
        RestrictionPreviewComponent,
        ModificationStatusBadgeComponent,
        CircularBoundaryViolationComponent,
        CircularBoundaryViolationToastComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatDialogModule,
        MatTableModule,
        MatMenuModule,
        NgxSliderModule,
        MatNativeDateModule,
        NgxsModule.forFeature([FlightZoneState, FlightZoneRestrictionState, FlightZoneListsState, FlightZoneCapabilitiesState]),
        LetModule,
        PushModule,
        ReactiveFormsModule,
        RouterModule,
        SharedToastModule.forRoot(),
        SharedI18nModule,
        MatCardModule,
        WizardModule,
        CesiumMapModule,
        SharedUiModule,
        DmsCoordinatesModule,
        FlightZoneSharedModule,
        MatSlideToggleModule,
        ToastContainerModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dssClientLibFlightZone",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: FlightZoneApiService,
            useValue: undefined,
        },
        {
            provide: FlightZoneGeometryTranslationProvider,
            useClass: FlightZoneGeometryTranslatorService,
        },
        {
            provide: ListStatusTranslationService,
            useClass: ApplicationStatusTranslationService,
        },
    ],
    exports: [
        FlightZoneWizardContentComponent,
        BasicZoneInfoStepComponent,
        FlightZoneWizardHeaderComponent,
        ZoneGeometryStepComponent,
        ZoneGeometryStepCylinderEditorComponent,
        ZoneRestrictionExclusionsStepComponent,
        ZoneSummaryStepComponent,
        ApplicationDraftListComponent,
        ApplicationDraftListContainerComponent,
        ApplicationPreviewComponent,
        ZoneGeometryStepPrismEditorComponent,
        SubmittedListComponent,
        RestrictionsListComponent,
        MtomFieldComponent,
        ApplicationStatusBadgeComponent,
        ModificationStatusBadgeComponent,
    ],
})
export class FlightZoneModule {
    public static forRoot(): ModuleWithProviders<FlightZoneModule> {
        return {
            ngModule: FlightZoneModule,
            providers: [
                {
                    provide: FlightZoneApiService,
                    useClass: FlightZoneApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<FlightZoneModule> {
        return {
            ngModule: FlightZoneModule,
            providers: [
                {
                    provide: FlightZoneApiService,
                    useValue: null,
                },
                {
                    provide: IS_PRE_TACTICAL_APPROVAL_ENABLED,
                    useValue: true,
                },
            ],
        };
    }
}
