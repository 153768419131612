import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FlightZoneCapabilitiesResolver } from "@dtm-frontend/dss-shared-lib";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { PlannedMissionContainerComponent } from "./components/planned-mission-container/planned-mission-container.component";

const routes: Routes = [
    {
        path: "planned-missions",
        canActivate: [DtmRoleGuard],
        component: PlannedMissionContainerComponent,
        data: {
            titleTranslationKey: "dssClientLibPlannedMissions.routeTitles.plannedMissions",
            className: "no-padding",
        },
        resolve: {
            initCapabilities: FlightZoneCapabilitiesResolver,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PlannedMissionsRoutingModule {}
