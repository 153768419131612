<div class="dialog-header">
    <h2 mat-dialog-title>
        {{
            data.type === MapEntityType.Cylinder
                ? ("dtmMapCesium.manualCoordinatesInput.cylinderDialogHeader" | transloco)
                : ("dtmMapCesium.manualCoordinatesInput.corridorDialogHeader" | transloco)
        }}
    </h2>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<form [formGroup]="formGroup">
    <ng-container [ngSwitch]="data.type">
        <dtm-map-coordinates-paste-area
            *ngSwitchCase="MapEntityType.Cylinder"
            [label]="'dtmMapCesium.manualCoordinatesInput.centerCoordinates' | transloco"
            [hintText]="'dtmMapCesium.manualCoordinatesInput.hintCorridorText' | transloco"
            [placeholderText]="'dtmMapCesium.manualCoordinatesInput.placeholderText' | transloco"
            [editorType]="MapEntityType.Cylinder"
            [cancelText]="'dtmMapCesium.manualCoordinatesInput.cancelButtonLabel' | transloco"
            [saveText]="'dtmMapCesium.manualCoordinatesInput.saveButtonLabel' | transloco"
            [coordinatesMaxLength]="COORDINATES_MAX_LENGTH"
            (coordinatesSubmit)="closeDialog($event)"
            (coordinatesCancel)="closeDialog()"
        >
            <dtm-ui-input-field *ngIf="data.maxRadius !== undefined" class="cylinder-radius" [isClearable]="false">
                <label>
                    {{ "dtmMapCesium.manualCoordinatesInput.cylinderRadiusLabel" | transloco }}
                </label>
                <dtm-ui-icon name="complex-radius" class="field-prefix"></dtm-ui-icon>
                <input
                    matInput
                    dtmUiClampInput
                    type="number"
                    inputmode="numeric"
                    step="1"
                    required
                    [min]="1"
                    [max]="data.maxRadius"
                    formControlName="cylinderRadius"
                />
                <div class="field-suffix">
                    {{ "dtmMapCesium.manualCoordinatesInput.radiusUnitLabel" | transloco }}
                </div>
            </dtm-ui-input-field>
        </dtm-map-coordinates-paste-area>

        <dtm-map-coordinates-paste-area
            *ngSwitchCase="MapEntityType.Polyline3D"
            [label]="'dtmMapCesium.manualCoordinatesInput.pointsCoordinates' | transloco"
            [hintText]="'dtmMapCesium.manualCoordinatesInput.hintPolylineText' | transloco"
            [placeholderText]="'dtmMapCesium.manualCoordinatesInput.placeholderText' | transloco"
            [editorType]="MapEntityType.Polyline3D"
            [cancelText]="'dtmMapCesium.manualCoordinatesInput.cancelButtonLabel' | transloco"
            [saveText]="'dtmMapCesium.manualCoordinatesInput.saveButtonLabel' | transloco"
            [coordinatesMaxLength]="COORDINATES_MAX_LENGTH"
            (coordinatesSubmit)="closeDialog($event)"
            (coordinatesCancel)="closeDialog()"
        >
            <dtm-ui-input-field *ngIf="data.maxHorizontalAccuracy !== undefined" class="corridor-width" [isClearable]="false">
                <label>
                    {{ "dtmMapCesium.manualCoordinatesInput.corridorWidthLabel" | transloco }}
                </label>
                <dtm-ui-icon name="arrow-left-right-2" class="field-prefix"></dtm-ui-icon>
                <input
                    matInput
                    dtmUiClampInput
                    type="number"
                    inputmode="numeric"
                    step="1"
                    required
                    [min]="1"
                    [max]="data.maxHorizontalAccuracy"
                    formControlName="corridorWidth"
                />
                <div class="field-suffix">
                    {{ "dtmMapCesium.manualCoordinatesInput.widthUnitLabel" | transloco }}
                </div>
            </dtm-ui-input-field>
        </dtm-map-coordinates-paste-area>
    </ng-container>
</form>
