import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Route, Router } from "@angular/router";

const RELOAD_PATH = "reload";

export async function reloadComponent(router: Router, url?: string) {
    const currentUrl = url ?? router.url;
    if (!currentUrl) {
        return;
    }
    await router.navigate([`/${RELOAD_PATH}`], { skipLocationChange: true });
    router.navigateByUrl(currentUrl, { skipLocationChange: true });
}

@Component({
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
class ReloadComponent {}

export const RELOAD_COMPONENT_ROUTE_CONFIG: Route = {
    path: RELOAD_PATH,
    component: ReloadComponent,
    pathMatch: "full",
};
