<ng-container *ngrxLet="missionTimeRange$ as mission">
    <div class="mission-start-date" *ngIf="shouldDisplayDate$ | ngrxPush">
        <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
        {{ mission.flightStartAt | localizeDate : { dateStyle: "short" } }}
    </div>
    <div class="date-from">
        <dtm-ui-icon name="flight-takeoff"></dtm-ui-icon>
        <div *ngIf="mission.isPathBased">
            <ng-container
                [ngTemplateOutlet]="dateTemplate"
                [ngTemplateOutletContext]="{ $implicit: mission.flightStartAtMin }"
            ></ng-container>
            -
            <ng-container
                [ngTemplateOutlet]="dateTemplate"
                [ngTemplateOutletContext]="{ $implicit: mission.flightStartAtMax }"
            ></ng-container>
        </div>
        <div *ngIf="!mission.isPathBased">
            {{ "dtmSharedMission.missionTimeRange.flightDirectionFromPrefix" | transloco }}
            <ng-container [ngTemplateOutlet]="dateTemplate" [ngTemplateOutletContext]="{ $implicit: mission.flightStartAt }"></ng-container>
            <span
                class="planned-time"
                *ngIf="!(mission.flightStartAt | invoke : compareDatesWithMinutesPrecision : mission.plannedStartAt)"
            >
                <ng-container
                    [ngTemplateOutlet]="dateTemplate"
                    [ngTemplateOutletContext]="{ $implicit: mission.plannedStartAt }"
                ></ng-container>
            </span>
        </div>
    </div>
    <div class="date-to">
        <dtm-ui-icon name="flight-land"></dtm-ui-icon>
        <div *ngIf="mission.isPathBased">
            <ng-container
                [ngTemplateOutlet]="dateTemplate"
                [ngTemplateOutletContext]="{ $implicit: mission.flightFinishAtMin }"
            ></ng-container>
            -
            <ng-container
                [ngTemplateOutlet]="dateTemplate"
                [ngTemplateOutletContext]="{ $implicit: mission.flightFinishAtMax }"
            ></ng-container>
        </div>
        <div *ngIf="!mission.isPathBased">
            {{ "dtmSharedMission.missionTimeRange.flightDirectionToPrefix" | transloco }}
            <ng-container
                [ngTemplateOutlet]="dateTemplate"
                [ngTemplateOutletContext]="{ $implicit: mission.flightFinishAt }"
            ></ng-container>
            <span
                class="planned-time"
                *ngIf="!(mission.flightFinishAt | invoke : compareDatesWithMinutesPrecision : mission.plannedFinishAt)"
            >
                <ng-container
                    [ngTemplateOutlet]="dateTemplate"
                    [ngTemplateOutletContext]="{ $implicit: mission.plannedFinishAt }"
                ></ng-container>
            </span>
        </div>
    </div>
</ng-container>
<ng-template let-date #dateTemplate>
    {{ date | localizeDate : { hour: "numeric", minute: "numeric" } }}
</ng-template>
