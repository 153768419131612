import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { AssociationOperationType, AssociationPermit } from "../../../models/permits.models";

interface AssociationPermitDetailsPreviewComponentState {
    permit: AssociationPermit | undefined;
    canEdit: boolean;
    isPermitDetailsTileExpanded: boolean;
}
@Component({
    selector: "dtm-ui-association-permit-details-preview[permit]",
    templateUrl: "./association-permit-details-preview.component.html",
    styleUrls: ["./association-permit-details-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AssociationPermitDetailsPreviewComponent {
    @Input() public set permit(value: AssociationPermit | undefined) {
        this.localStore.patchState({ permit: value });
    }
    @Input() public set canEdit(value: BooleanInput) {
        this.localStore.patchState({ canEdit: coerceBooleanProperty(value) });
    }
    @Input() public set isPermitDetailsTileExpanded(value: BooleanInput) {
        this.localStore.patchState({ isPermitDetailsTileExpanded: coerceBooleanProperty(value) });
    }

    @Output() public readonly edit = new EventEmitter<void>();

    protected readonly permit$ = this.localStore.selectByKey("permit").pipe(RxjsUtils.filterFalsy());
    protected readonly canEdit$ = this.localStore.selectByKey("canEdit");
    protected readonly isPermitDetailsTileExpanded$ = this.localStore.selectByKey("isPermitDetailsTileExpanded");
    protected readonly AssociationOperationType = AssociationOperationType;

    constructor(private readonly localStore: LocalComponentStore<AssociationPermitDetailsPreviewComponentState>) {
        localStore.setState({ permit: undefined, canEdit: false, isPermitDetailsTileExpanded: false });
    }
}
