<ng-container *ngrxLet="error$; let error">
    <ng-container [ngSwitch]="error?.type">
        <div *ngSwitchCase="FlightZoneErrorType.CannotGetCapabilities" class="error">
            {{ "dssClientLibFlightZone.cannotGetCapabilitiesErrorMessage" | transloco }}
        </div>
        <div *ngSwitchCase="FlightZoneErrorType.CannotGetApplication" class="error">
            {{ "dssClientLibFlightZone.cannotGetDataErrorMessage" | transloco }}
        </div>
        <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="noErrorTemplate"></ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #noErrorTemplate>
    <ng-container
        *ngrxLet="{
            isProcessing: isProcessing$,
            flightZoneApplicationData: flightZoneApplicationData$,
            geometryContent: geometryContent$,
            flightZoneCapabilities: flightZoneCapabilities$
        } as vm"
    >
        <div class="flight-zone-wizard-container">
            <div class="card-box">
                <dtm-ui-lib-wizard-content [wizardId]="FLIGHT_ZONE_WIZARD_ID" (stepChangeActivate)="handleActiveStepChanged($event)">
                    <dtm-ui-lib-wizard-step-content [stepId]="FlightZoneWizardSteps.BasicZoneInfo">
                        <dss-client-lib-basic-zone-info-step
                            *ngIf="vm.flightZoneCapabilities"
                            [isProcessing]="vm.isProcessing"
                            [flightZonePurposes]="vm.flightZoneCapabilities.flightZonePurposes"
                            [availableMaxZoneDurations]="vm.flightZoneCapabilities.maxZoneDurations"
                            [basicZoneInfoValue]="vm.flightZoneApplicationData.basicDataForm"
                            [isEditMode]="isWizardInEditMode$ | ngrxPush"
                            [isGeometryCreated]="isGeometryCreated$ | ngrxPush"
                            (basicDataUpdate)="updateBasicData($event)"
                            (geometryClear)="clearGeometry()"
                        ></dss-client-lib-basic-zone-info-step>
                    </dtm-ui-lib-wizard-step-content>

                    <dtm-ui-lib-wizard-step-content [stepId]="FlightZoneWizardSteps.ZoneGeometry">
                        <dss-client-lib-zone-geometry-step
                            *ngIf="vm.flightZoneCapabilities"
                            [isProcessing]="vm.isProcessing"
                            [availableEditors]="vm.flightZoneCapabilities.availableEditors"
                            [constraints]="vm.flightZoneCapabilities.geometryConstraints"
                            [geometryContent]="vm.geometryContent"
                            [selectedEditor]="selectedEditor$ | ngrxPush"
                            [availableGeometryUnits]="geometryUnits$ | ngrxPush"
                            [activeGeometryUnits]="vm.flightZoneApplicationData.restrictionAreaGeometry.areaUnits"
                            [prismPointsLimit]="prismPointsLimit$ | ngrxPush"
                            [isCircularBoundaryValid]="isCircularBoundaryValid$ | ngrxPush"
                            (editorTypeChange)="changeEditorType($event)"
                            (zoneGeometryUpdate)="updateZoneGeometryContent($event)"
                            (zoneGeometrySubmit)="handleGeometryCompleted()"
                            (back)="changeActiveStep(FlightZoneWizardSteps.BasicZoneInfo)"
                            (clearGeometry)="clearGeometry(true)"
                            (zoomToGeometry)="zoomToGeometry()"
                            (submitPasteAreaCoordinates)="submitPasteAreaCoordinates($event)"
                        ></dss-client-lib-zone-geometry-step>
                    </dtm-ui-lib-wizard-step-content>

                    <dtm-ui-lib-wizard-step-content [stepId]="FlightZoneWizardSteps.ExclusionsFromRestrictions">
                        <dss-client-lib-zone-restriction-exclusions-step
                            *ngIf="vm.flightZoneCapabilities"
                            [isProcessing]="vm.isProcessing"
                            [capabilities]="vm.flightZoneCapabilities"
                            [constraints]="vm.flightZoneCapabilities.exclusionsConstraints"
                            [exclusions]="vm.flightZoneApplicationData.restrictionExclusions"
                            [applicationPurpose]="vm.flightZoneApplicationData.basicDataForm?.flightZonePurpose"
                            (restrictionExclusionsUpdate)="updateRestrictionExclusions($event)"
                            (back)="changeActiveStep(FlightZoneWizardSteps.ZoneGeometry)"
                        ></dss-client-lib-zone-restriction-exclusions-step>
                    </dtm-ui-lib-wizard-step-content>

                    <dtm-ui-lib-wizard-step-content [stepId]="FlightZoneWizardSteps.Summary">
                        <dss-client-lib-zone-summary-step
                            *ngIf="vm.flightZoneApplicationData.analysisStatus as analysisStatus"
                            [flightZoneApplicationData]="vm.flightZoneApplicationData"
                            [isProcessing]="vm.isProcessing"
                            [hasSupervisorRole]="hasInstitutionSupervisorRole$ | ngrxPush"
                            [capabilities]="vm.flightZoneCapabilities"
                            (backToStep)="changeActiveStep($event)"
                            (zoomToGeometry)="zoomToGeometry()"
                            (applicationRejection)="rejectApplication()"
                            (submitApplication)="submitApplication($event)"
                            (applicationDelete)="deleteApplication($event)"
                            (confirmCorrections)="confirmCorrections($event)"
                        >
                        </dss-client-lib-zone-summary-step>
                    </dtm-ui-lib-wizard-step-content>
                </dtm-ui-lib-wizard-content>
            </div>

            <div *ngIf="initialViewbox$ | ngrxPush" class="map-container">
                <ac-map dtmMapPointerManager dtmMapCesiumCleanupOnDestroy>
                    <dtm-map-azure-maps-layer [options]="AZURE_MAPS_LAYER_OPTIONS"></dtm-map-azure-maps-layer>
                    <dss-shared-lib-active-restrictions-map-layer></dss-shared-lib-active-restrictions-map-layer>
                    <dtm-map-cylinders-editor [labelsTemplate]="labelsTemplate" [show]="true"></dtm-map-cylinders-editor>
                    <dtm-map-prisms-editor
                        [labelsTemplate]="labelsTemplate"
                        [show]="true"
                        (pointLimitReached)="prismPointLimitReached($event)"
                    ></dtm-map-prisms-editor>
                    <dss-client-lib-circular-boundary-violation
                        [isShown]="isForbiddingUavFlightsPurposeSelected$ | ngrxPush"
                        [maxRadius]="maxRadius$ | ngrxPush"
                        (violationChange)="updateCircularBoundary($event)"
                    ></dss-client-lib-circular-boundary-violation>
                </ac-map>

                <ng-template #labelsTemplate let-label>
                    <div class="default-label" *ngIf="label.show && label.text">
                        {{ label.text }}
                    </div>
                </ng-template>
            </div>

            <ng-container *ngrxLet="chatMessages$; let chatMessages">
                <dss-shared-lib-application-changes-chat
                    *ngIf="vm.flightZoneApplicationData.flightZoneId && (isChatEnabled$ | ngrxPush)"
                    [messages]="chatMessages"
                    [userId]="userId$ | ngrxPush"
                    [isProcessing]="vm.isProcessing"
                    #chatComponent
                    (messageSubmit)="submitMessage(vm.flightZoneApplicationData.flightZoneId, $event, chatComponent)"
                ></dss-shared-lib-application-changes-chat>
            </ng-container>
        </div>
    </ng-container>
</ng-template>
