<ng-container *ngrxLet="permit$ as permit">
    <dtm-ui-association-permit-basic-info-preview
        [permit]="permit"
        [isCaaContactTileExpanded]="isCaaContactTileExpanded$ | ngrxPush"
        [isOperatorInfoTileExpanded]="isOperatorInfoTileExpanded$ | ngrxPush"
        [isOperatorContactPersonVisible]="isOperatorContactPersonVisible$ | ngrxPush"
    ></dtm-ui-association-permit-basic-info-preview>
    <dtm-ui-association-permit-details-preview
        [permit]="permit"
        [isPermitDetailsTileExpanded]="isPermitDetailsTileExpanded$ | ngrxPush"
    ></dtm-ui-association-permit-details-preview>
</ng-container>
