import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DialogService } from "../../services/dialog/dialog.service";
import { ALLOWED_IMAGE_UPLOAD_MIME_TYPES } from "../../utils/shared.constants";
import { ButtonTheme, ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";

interface ImagePickerFieldComponentState {
    hasErrors: boolean;
    isDisabled: boolean;
    isDraggedOver: boolean;
    maxFileSize: number;
    previewImageUrl: string | undefined;
    processingProgress: number | undefined;
    isPreviewPortraitOrientation: boolean;
    error: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-image-picker",
    templateUrl: "./image-picker.component.html",
    styleUrls: ["./image-picker.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ImagePickerComponent {
    @Input() public set initialPreviewImageUrl(value: string | undefined) {
        this.localStore.patchState({ previewImageUrl: value });
    }

    @Input() public set maxFileSize(value: number | undefined) {
        this.localStore.patchState({ maxFileSize: value ?? 0 });
    }

    @Input() public set isDisabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }

    @Input() public set processingProgress(value: number | null | undefined) {
        this.localStore.patchState({
            processingProgress: value !== null && value !== undefined && value >= 0 && value < 100 ? value : undefined,
        });
    }

    @Output() public file = new EventEmitter<File | null>();

    @ViewChild("imagePreview") private set imagePreviewElement(value: ElementRef<HTMLImageElement> | undefined) {
        if (!value) {
            return;
        }

        value.nativeElement.addEventListener("load", () => {
            this.localStore.patchState({
                isPreviewPortraitOrientation: value.nativeElement.clientHeight > value.nativeElement.clientWidth,
            });
        });
    }

    @ViewChild("fileInput") private fileInput!: ElementRef<HTMLInputElement>;

    protected readonly ALLOWED_IMAGE_UPLOAD_MIME_TYPES = ALLOWED_IMAGE_UPLOAD_MIME_TYPES;
    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");
    protected readonly hasErrors$ = this.localStore.selectByKey("hasErrors");
    protected readonly isDraggedOver$ = this.localStore.selectByKey("isDraggedOver");
    protected readonly previewImageUrl$ = this.localStore.selectByKey("previewImageUrl");
    protected readonly isFileSelected$ = this.previewImageUrl$.pipe(map((url) => !!url));
    protected readonly processingProgress$ = this.localStore.selectByKey("processingProgress");
    protected readonly isPreviewPortraitOrientation$ = this.localStore.selectByKey("isPreviewPortraitOrientation");
    protected readonly error$ = this.localStore.selectByKey("error");

    constructor(
        private readonly transloco: TranslocoService,
        private readonly localStore: LocalComponentStore<ImagePickerFieldComponentState>,
        private dialogService: DialogService
    ) {
        this.localStore.setState({
            hasErrors: false,
            isDisabled: false,
            isDraggedOver: false,
            maxFileSize: 0,
            previewImageUrl: undefined,
            processingProgress: undefined,
            isPreviewPortraitOrientation: false,
            error: undefined,
        });
    }

    protected setHasErrors(hasErrors: boolean): void {
        this.localStore.patchState({ hasErrors });
    }

    protected draggedOverStatusChanged(isDraggedOver: boolean): void {
        if (this.localStore.selectSnapshotByKey("isDisabled")) {
            return;
        }

        this.localStore.patchState({ isDraggedOver });
    }

    protected addFile(file: File | null): void {
        if (!file || this.localStore.selectSnapshotByKey("isDisabled") || this.runEarlyValidation(file)) {
            return;
        }

        this.file.emit(file);
        this.setImagePreview(file);
        this.fileInput.nativeElement.value = "";
    }

    protected removeFile(): void {
        if (this.localStore.selectSnapshotByKey("isDisabled")) {
            return;
        }

        this.confirmFileRemoval()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.file.emit(null);
                this.setImagePreview(null);
            });
    }

    private isImage(file: File): boolean {
        return ALLOWED_IMAGE_UPLOAD_MIME_TYPES.includes(file.type);
    }

    private setImagePreview(file: File | null): void {
        if (file) {
            this.localStore.patchState({ previewImageUrl: URL.createObjectURL(file) });
        } else {
            this.localStore.patchState({ previewImageUrl: undefined });
        }
    }

    private runEarlyValidation(file: File): boolean {
        if (!this.isImage(file)) {
            this.localStore.patchState({ error: this.transloco.translate("dtmUi.imagePickerField.invalidFileTypeError") });

            return true;
        }

        const maxFileSize = this.localStore.selectSnapshotByKey("maxFileSize");
        if (maxFileSize && file.size > maxFileSize) {
            this.localStore.patchState({ error: this.transloco.translate("dtmUi.imagePickerField.invalidFileSizeError") });

            return true;
        }

        return false;
    }

    private confirmFileRemoval(): Observable<boolean> {
        return this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmUi.imagePickerField.confirmFileRemovalDialog.titleText"),
                    confirmationText: this.transloco.translate("dtmUi.imagePickerField.confirmFileRemovalDialog.contentText"),
                    declineButtonLabel: this.transloco.translate("dtmUi.imagePickerField.confirmFileRemovalDialog.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmUi.imagePickerField.confirmFileRemovalDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }
}
