<ng-container
    *ngrxLet="{
        additionalZones: additionalZones$,
        selectedZoneId: selectedZoneId$,
        evaluationIssues: evaluationIssues$,
        flightRules: flightRules$,
        missionPhase: missionPhase$,
    } as vm"
>
    <ng-container *ngIf="vm.additionalZones.length || vm.evaluationIssues.length">
        <h2 class="section-title" [innerHTML]="'dtmSharedMission.analysis.additionalInformationLabel' | transloco"></h2>

        <div class="additional-info-container">
            <ul class="issues">
                <li *ngFor="let issue of vm.evaluationIssues" [ngSwitch]="issue.status">
                    <dtm-ui-icon class="warning" *ngSwitchCase="EvaluationIssueStatus.Open" name="error-warning-fill"></dtm-ui-icon>
                    <dtm-ui-icon class="error" *ngSwitchCase="EvaluationIssueStatus.Rejected" name="sign-prohibition"></dtm-ui-icon>
                    <dtm-ui-icon class="success" *ngSwitchCase="EvaluationIssueStatus.Suppressed" name="checkbox-circle-fill"></dtm-ui-icon>
                    <div>
                        {{ issue | translateAnalysisIssue }}
                        <p class="manual-verification" *ngIf="issue.status === EvaluationIssueStatus.Open">
                            {{ "dtmSharedMission.analysis.manualVerificationRequiredLabel" | transloco }}
                        </p>
                    </div>
                </li>
            </ul>

            <dtm-mission-zone-info
                *ngFor="let zone of vm.additionalZones"
                [zone]="zone"
                [flightRules]="vm.flightRules"
                [isZoneSelected]="vm.selectedZoneId === zone.id"
                [missionPhase]="vm.missionPhase"
                (zoneSelectionChange)="zoneSelect.emit($event)"
            ></dtm-mission-zone-info>
        </div>
    </ng-container>
</ng-container>
