import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from "@angular/animations";

const DEFAULT_ANIMATION_TIME_MS = 300;
const DEFAULT_ANIMATION_DELAY_MS = 0;

export function foldAnimation(
    duration: number = DEFAULT_ANIMATION_TIME_MS,
    delay: number = DEFAULT_ANIMATION_DELAY_MS,
    offset?: string
): AnimationTriggerMetadata[] {
    return [
        trigger("foldRight", [
            state(
                "open",
                style({
                    transform: "translateX(0)",
                })
            ),
            state(
                "closed",
                style({
                    transform: `translateX(${offset ?? "100%"})`,
                })
            ),
            transition("void => *", [animate(0)]),
            transition("* => *", [animate(`${duration}ms ${delay}ms`)]),
        ]),
        trigger("foldFarRight", [
            state(
                "open",
                style({
                    transform: "translateX(0)",
                })
            ),
            state(
                "closed",
                style({
                    transform: "translateX(130%)",
                })
            ),
            transition("void => *", [animate(0)]),
            transition("* => *", [animate(`${duration}ms ${delay}ms`)]),
        ]),
        trigger("foldDown", [
            state(
                "open",
                style({
                    transform: "translateY(0)",
                })
            ),
            state(
                "closed",
                style({
                    transform: `translateY(-${offset ?? "100%"})`,
                })
            ),
            transition("void => *", [animate(0)]),
            transition("* => *", [animate(`${duration}ms ${delay}ms`)]),
        ]),
        trigger("foldLeft", [
            state(
                "open",
                style({
                    transform: "translateX(0)",
                })
            ),
            state(
                "closed",
                style({
                    transform: `translateX(-${offset ?? "100%"})`,
                })
            ),
            transition("void => *", [animate(0)]),
            transition("* => *", [animate(`${duration}ms ${delay}ms`)]),
        ]),
    ];
}
