import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject } from "rxjs";
import { MapLayer } from "../../../../shared/shared-map.tokens";

interface MapLayersSelectorComponentState {
    mapLayers: MapLayer[];
}

const mapLayerIconsMap: Record<string, string> = {
    [MapLayer.SoraBoxes]: "building",
    [MapLayer.Obstacles]: "barricade-fill",
};

@UntilDestroy()
@Component({
    selector: "dtm-map-layers-selector[mapLayers]]",
    templateUrl: "./map-layers-selector.component.html",
    styleUrls: ["./map-layers-selector.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MapLayersSelectorComponent implements OnInit {
    @Input() public set mapLayers(value: MapLayer[]) {
        this.localStore.patchState({ mapLayers: value });
        this.updateControlsState(value);
    }

    @Input() public set mapLayersValue(value: Partial<Record<MapLayer, boolean>>) {
        this.layersVisibilityForm.patchValue(value, { emitEvent: false });
    }

    protected readonly layersVisibilityForm = new FormGroup({
        [MapLayer.SoraBoxes]: new FormControl(false, { nonNullable: true }),
        [MapLayer.Obstacles]: new FormControl(false, { nonNullable: true }),
        [MapLayer.Default]: new FormControl(false, { nonNullable: true }),
    });

    @Output() public readonly mapLayersValueChange = new Subject<Partial<Record<MapLayer, boolean>>>();

    protected readonly mapLayers$ = this.localStore.selectByKey("mapLayers");

    protected readonly MapLayer = MapLayer;

    constructor(private readonly localStore: LocalComponentStore<MapLayersSelectorComponentState>) {
        localStore.setState({ mapLayers: [] });
        this.watchAndUpdateFormState();
    }

    public ngOnInit(): void {
        this.layersVisibilityForm.updateValueAndValidity();
    }

    protected getIconForMapLayerType(layerName: string): string {
        return mapLayerIconsMap[layerName] ?? "map";
    }

    protected turnOffControl(value: boolean, control: MapLayer) {
        if (value) {
            this.layersVisibilityForm.controls[control].setValue(false);
        }
    }

    private watchAndUpdateFormState() {
        this.layersVisibilityForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            if (!value[MapLayer.SoraBoxes] && !value[MapLayer.Obstacles]) {
                this.layersVisibilityForm.controls[MapLayer.Default].setValue(true, { emitEvent: false });
            }

            this.mapLayersValueChange.next(this.layersVisibilityForm.value);
        });
    }

    private updateControlsState(value: MapLayer[]) {
        Object.keys(MapLayer).forEach((layer) => {
            const typedControlName = layer as MapLayer;
            if (!value.includes(typedControlName)) {
                this.layersVisibilityForm.controls[typedControlName]?.disable({ emitEvent: false });
            } else {
                this.layersVisibilityForm.controls[typedControlName]?.enable({ emitEvent: false });
            }
        });

        this.layersVisibilityForm.updateValueAndValidity();
    }
}
