<div class="header">
    <h5 class="title" *ngrxLet="titleTile$ as titleTile">
        <span #headerContent>
            <ng-content select="[headerSlot]"></ng-content>
        </span>
        <ng-container *ngIf="headerContent | isEmptyElement">
            {{ titleTile }}
        </ng-container>
    </h5>
    <ng-content select="[actionButtonSlot]"></ng-content>
</div>
<ng-content></ng-content>
