import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface ExpandableExtendedHeaderComponentState {
    label: string | undefined;
}

@Component({
    selector: "dtm-ui-expandable-extended-header[label]",
    templateUrl: "./expandable-extended-header.component.html",
    styleUrls: ["./expandable-extended-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ExpandableExtendedHeaderComponent {
    @Input() public set label(value: string | undefined) {
        this.localStore.patchState({ label: value });
    }

    @Output() protected labelClick = new EventEmitter<MouseEvent>();

    protected readonly label$ = this.localStore.selectByKey("label");

    constructor(private readonly localStore: LocalComponentStore<ExpandableExtendedHeaderComponentState>) {
        localStore.setState({ label: undefined });
    }
}
