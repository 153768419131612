<ng-template #stepHeaderTemplate let-active="active" let-enabledByState="enabledByState">
    <li
        class="container"
        #stepHeader
        (click)="stepActivate.emit()"
        [ngClass]="{
            disabled: !(vm.isEnabledByAttribute ?? enabledByState),
            active: active,
            checked: !active && (vm.isEnabledByAttribute ?? enabledByState),
            clickable: vm.isManualChangeAllowed
        }"
        *ngrxLet="{ isEnabledByAttribute: isEnabledByAttribute$, isManualChangeAllowed: isManualChangeAllowed$ } as vm"
    >
        <span class="step-number" [class.step-checked]="!active && (vm.isEnabledByAttribute ?? enabledByState)">
            <dtm-ui-icon name="check"></dtm-ui-icon>
        </span>
        <span class="step-title">
            <ng-content></ng-content>
        </span>
    </li>
</ng-template>
