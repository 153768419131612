/**
 * Returns NEW array with replaced item
 * @param iterable array to replace item in
 * @param predicate function used to find (first) item to replace
 * @returns New array with replaced item or same array if nothing to replace
 */
export function replace<T>(iterable: T[], item: T, predicate: (item: T) => boolean): T[] {
    const updatedItemIndex = iterable.findIndex((lookupItem) => predicate(lookupItem));

    if (updatedItemIndex === -1) {
        return iterable;
    }

    return [...iterable.slice(0, updatedItemIndex), item, ...iterable.slice(updatedItemIndex + 1)];
}

/**
 * Returns NEW array with replaced (if exists) or added (at the end) item
 * @param iterable array to replace/add item in
 * @param predicate function used to find (first) item to replace
 */
export function addOrReplace<T>(iterable: T[], item: T, predicate: (item: T) => boolean): T[] {
    const updatedItemIndex = iterable.findIndex((lookupItem) => predicate(lookupItem));

    if (updatedItemIndex === -1) {
        return [...iterable, item];
    }

    return [...iterable.slice(0, updatedItemIndex), item, ...iterable.slice(updatedItemIndex + 1)];
}
