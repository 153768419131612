import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "join",
})
export class JoinPipe implements PipeTransform {
    public transform(items: string[] | undefined | null, separator: string = ", ", emptyResult: string = ""): string {
        return items && items.length ? items.join(separator) : emptyResult;
    }
}
