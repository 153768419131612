<dtm-ui-textarea-field [maxLength]="coordinatesMaxLength$ | ngrxPush">
    <label>
        {{ label$ | ngrxPush }}
        <dtm-ui-popover *ngIf="tooltipText$ | ngrxPush as tooltipText" [popoverText]="tooltipText"></dtm-ui-popover>
    </label>
    <textarea [rows]="5" matInput [formControl]="coordinatesPasteArea" [placeholder]="(placeholderText$ | ngrxPush) || ''"></textarea>
    <div class="field-error" *dtmUiFieldHasError="coordinatesPasteArea; name: 'invalidFormat'">
        {{ "dtmMapCesium.coordinatesPasteArea.invalidFormatErrorHint" | transloco }}
    </div>
    <div class="field-error" *dtmUiFieldHasError="coordinatesPasteArea; name: 'minLength'; error as error">
        {{ "dtmMapCesium.coordinatesPasteArea.minPointsLengthErrorHint" | transloco : { value: error } }}
    </div>
    <div class="field-error" *dtmUiFieldHasError="coordinatesPasteArea; name: 'maxLength'; error as error">
        {{ "dtmMapCesium.coordinatesPasteArea.maxPointsLengthErrorHint" | transloco : { value: error } }}
    </div>
    <div class="field-error" *dtmUiFieldHasError="coordinatesPasteArea; name: 'centerPointFormat'">
        {{ "dtmMapCesium.coordinatesPasteArea.centerPointFormatErrorHint" | transloco }}
    </div>
    <div class="field-error" *dtmUiFieldHasError="coordinatesPasteArea; name: 'intersections'">
        {{ "dtmMapCesium.coordinatesPasteArea.prismPointSelfIntersectionErrorHint" | transloco }}
    </div>
    <span class="field-hint" *ngIf="hintText$ | ngrxPush as hintText">
        {{ hintText }}
    </span>
</dtm-ui-textarea-field>

<ng-content></ng-content>

<div class="actions">
    <button *ngIf="coordinatesCancel.observed" (click)="coordinatesCancel.next()" type="button" class="button-secondary">
        {{ cancelText$ | ngrxPush }}
    </button>

    <button type="button" class="button-primary" (click)="submitPastedCoordinates()">
        {{ (saveText$ | ngrxPush) ?? ("dtmMapCesium.coordinatesPasteArea.submitButtonLabel" | transloco) }}
    </button>
</div>
