import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { DOCUMENT } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Inject,
    Input,
    Output,
    ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
    DeviceSizeService,
    Elevation,
    EmptyStateMode,
    MissionPlanRoute,
    MissionPlanRouteFlightZone,
    MissionPlanRouteSegment,
} from "@dtm-frontend/shared/ui";
import { AnimationUtils, DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore, ObjectUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject, firstValueFrom, fromEvent, share, tap, timer } from "rxjs";
import { combineLatestWith, debounceTime, distinctUntilChanged, map, startWith } from "rxjs/operators";

interface RouteSideViewComponentState {
    route: MissionPlanRoute | undefined;
    scrollButtonsVisibility: {
        isLeftVisible: boolean;
        isRightVisible: boolean;
    };
    isExpanded: boolean;
    isLoading: boolean;
    isSimpleMode: boolean;
    areBuffersVisible: boolean;
}

interface DrawingData {
    aglMaxHeightScaled: number;
    aglMaxHeight: number;
    amslMinHeightScaled: number;
    amslMinHeight: number;
    sections: Section[];
    waypoints: Waypoint[];
    width: number;
    elevation: string[];
    maxFlightAreaPath: string[];
    maxSafetyAreaPath: string[];
    heightErrorPath: string[];
    verticalScale: number;
    xOffset: number;
    topOffset: number;
    isPathBased: boolean;
    routeData: MissionPlanRoute | undefined;
    distanceMeasures?: {
        points: { value: number; offset: number }[];
        path: string;
    };
}

interface SafetyArea {
    ceiling: number;
    ceilingValue: number;
    floor: number;
    verticalBuffer?: number;
    horizontalBuffer?: number;
}

interface Section {
    zoneData?: {
        ceiling: number;
        ceilingValue: number;
        ceilingAmslValue: number;
        ceilingDrawingPosition: number;
        bufferCeilingDrawingPosition: number;
        floor: number;
        floorAmslValue: number;
        isRunway: boolean;
        elevationMin: number;
        elevationMax: number;
        elevationMaxValue?: number;
        elevationMinValue?: number;
        elevationMaxPointPosition: number;
        elevationMinPointPosition: number;
        safetyArea: SafetyArea;
        width: number;
        radius?: number;
    };
    segmentData?: {
        ceiling: number;
        ceilingValue: number;
        floor: number;
        maxHeight: number;
        minHeight: number;
        safetyArea: SafetyArea;
        distance?: number;
    };
    width: number;
    xOffset: number;
    maxElevation?: {
        startOffset: number;
        value: number;
        height: number;
    };
    minElevation?: {
        startOffset: number;
        value: number;
        height: number;
    };
}

interface Waypoint {
    height: number;
    xOffset: number;
    label?: string;
    isZone?: boolean;
}

const ZONE_WIDTH = 180;
const VERTICAL_SCALE = 180;
const VERTICAL_BUFFER = 40;
const MAX_HEIGHT_PRECISION = 10;
const PRECISION_STEPS = {
    default: 5,
    high: { height: 100, value: 10 },
    low: { height: 350, value: 20 },
};

const MIN_HORIZONTAL_SCALE = 0.1;
const MIN_CALCULATED_SEGMENT_WIDTH = 100;
const HORIZONTAL_DRAWING_MARGIN = 0;
const SCROLLING_SCALE = 0.5;
const ZOOM_SETTINGS = {
    defaultValue: 100,
    min: 100,
    max: 950,
    step: 50,
};
const FLIGHT_ARE_MAX_HEIGHT = 120;
const SAFETY_ARE_MAX_HEIGHT = 150;

const DISTANCE_BETWEEN_SECTIONS_IN_SIMPLE_MODE = 100;
const SIMPLE_MODE_HORIZONTAL_BUFFER_VALUE = 20;

const DISTANCE_MEASURE_HEIGHT = 40;
const DISTANCE_MEASURE_HEIGHT_TOP_OFFSET = 16;

@UntilDestroy()
@Component({
    selector: "dtm-mission-route-side-view[route]",
    templateUrl: "./route-side-view.component.html",
    styleUrls: ["./route-side-view.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
    providers: [LocalComponentStore],
})
export class RouteSideViewComponent implements AfterViewInit {
    @HostBinding("@slideIn") public readonly foldUp = true;
    @ViewChild("drawingContainer") private readonly drawingContainer: ElementRef | undefined;
    @ViewChild("scrollableAreaContainer") private readonly scrollableAreaContainer: ElementRef | undefined;

    @Input() public set route(value: MissionPlanRoute | undefined) {
        this.resetScroll();
        this.localStore.patchState({ route: value });
    }
    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set isLoading(value: BooleanInput) {
        this.localStore.patchState({ isLoading: coerceBooleanProperty(value) });
    }
    @Input() public set isSimpleMode(value: BooleanInput) {
        this.localStore.patchState({ isSimpleMode: coerceBooleanProperty(value) });
    }
    @Input() public set areBuffersVisible(value: BooleanInput) {
        this.localStore.patchState({ areBuffersVisible: coerceBooleanProperty(value) });
    }

    @Output() public readonly expandedChange = new EventEmitter<boolean>();

    protected readonly EmptyStateMode = EmptyStateMode;

    private readonly resize$ = new Subject<void>();
    protected readonly scrollButtonsVisibility$ = this.localStore.selectByKey("scrollButtonsVisibility");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isLoading$ = this.localStore.selectByKey("isLoading");
    protected readonly isSimpleMode$ = this.localStore.selectByKey("isSimpleMode");
    protected readonly areBuffersVisible$ = this.localStore.selectByKey("areBuffersVisible");

    protected readonly zoomFormControl = new FormControl(ZOOM_SETTINGS.defaultValue, { nonNullable: true });
    protected readonly heightProfileFormControl = new FormControl(false, { nonNullable: true });

    protected readonly drawingData$ = this.localStore.selectByKey("route").pipe(
        combineLatestWith(
            this.resize$.pipe(
                map(() => this.scrollableAreaContainer?.nativeElement.clientWidth - 2 * HORIZONTAL_DRAWING_MARGIN),
                distinctUntilChanged()
            ),
            this.zoomFormControl.valueChanges.pipe(
                startWith(this.zoomFormControl.value),
                map((value) => value / 100)
            ),
            this.heightProfileFormControl.valueChanges.pipe(startWith(this.heightProfileFormControl.value)),
            this.localStore.selectByKey("isSimpleMode")
        ),
        map(([route, elementWidth, zoomLevel, isHeightProfileVisible, isSimpleMode]) =>
            this.convertRouteToDrawingData(route, { elementWidth, zoomLevel, isHeightProfileVisible, isSimpleMode })
        ),
        tap(() => {
            // NOTE: setTimeout to call it after view updates
            setTimeout(() => {
                this.updateScrollingButtonsVisibility();
                this.tryToRemoveOverlappingTexts();
            });
        }),
        // NOTE: drawingData$ depends on DOM elements (width), so we can't wrap those elements with *ngIf or *ngrxLet.
        // Instead we use share() with | ngrxPush to prevent multiple subscriptions to drawingData$ observable in template.
        share()
    );

    protected readonly VERTICAL_SCALE = VERTICAL_SCALE;
    protected readonly VERTICAL_BUFFER = VERTICAL_BUFFER;
    protected readonly AMSL_BUFFER = VERTICAL_BUFFER;
    protected readonly ZOOM_SETTINGS = ZOOM_SETTINGS;
    protected readonly FLIGHT_ARE_MAX_HEIGHT = FLIGHT_ARE_MAX_HEIGHT;
    protected readonly SAFETY_ARE_MAX_HEIGHT = SAFETY_ARE_MAX_HEIGHT;
    protected readonly DISTANCE_MEASURE_HEIGHT = DISTANCE_MEASURE_HEIGHT;
    protected readonly DISTANCE_MEASURE_HEIGHT_TOP_OFFSET = DISTANCE_MEASURE_HEIGHT_TOP_OFFSET;
    protected readonly HORIZONTAL_DRAWING_MARGIN = HORIZONTAL_DRAWING_MARGIN;
    protected readonly MIN_CALCULATED_SEGMENT_WIDTH = MIN_CALCULATED_SEGMENT_WIDTH;

    constructor(
        private readonly localStore: LocalComponentStore<RouteSideViewComponentState>,
        private readonly deviceSizeService: DeviceSizeService,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly hostElementRef: ElementRef
    ) {
        localStore.setState({
            route: undefined,
            scrollButtonsVisibility: {
                isLeftVisible: false,
                isRightVisible: false,
            },
            isExpanded: true,
            isLoading: false,
            isSimpleMode: false,
            areBuffersVisible: true,
        });
    }

    public ngAfterViewInit(): void {
        this.resize$.next();

        this.deviceSizeService
            .observeElementResize(this.hostElementRef, 0)
            .pipe(untilDestroyed(this))
            .subscribe(() => this.resize$.next());

        if (this.scrollableAreaContainer?.nativeElement) {
            fromEvent(this.scrollableAreaContainer.nativeElement, "scroll")
                .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), untilDestroyed(this))
                .subscribe(() => {
                    this.updateScrollingButtonsVisibility();
                    this.tryToRemoveOverlappingTexts();
                });
        }
    }

    protected getHorizontalGridLines(
        { verticalScale, amslMinHeight, aglMaxHeight }: DrawingData,
        count: number
    ): { y: number; value: number }[] {
        const amslMaxHeight = aglMaxHeight + amslMinHeight;
        let precision = PRECISION_STEPS.default;

        if (aglMaxHeight > PRECISION_STEPS.low.height) {
            precision = PRECISION_STEPS.low.value;
        } else if (aglMaxHeight > PRECISION_STEPS.high.height) {
            precision = PRECISION_STEPS.high.value;
        }
        const verticalDistance = Math.ceil(aglMaxHeight / count / precision) * precision;

        return new Array(count).fill("").map((value, index) => ({
            y: index * verticalDistance * verticalScale,
            value: amslMaxHeight - index * verticalDistance,
        }));
    }

    protected getScrollableViewBox(data: DrawingData) {
        return `-${HORIZONTAL_DRAWING_MARGIN} -${VERTICAL_SCALE} ${data.width + 2 * HORIZONTAL_DRAWING_MARGIN} ${
            VERTICAL_SCALE + VERTICAL_BUFFER + data.topOffset + DISTANCE_MEASURE_HEIGHT
        }`;
    }

    protected scrollView(direction = 1) {
        if (!this.scrollableAreaContainer) {
            return;
        }

        const scrollValue = this.scrollableAreaContainer.nativeElement.clientWidth * SCROLLING_SCALE * direction;
        this.scrollableAreaContainer.nativeElement.scrollBy({
            left: scrollValue,
            top: 0,
            behavior: "smooth",
        });
        this.updateScrollingButtonsVisibility();
    }

    private resetScroll() {
        this.scrollableAreaContainer?.nativeElement.scroll(0, 0);
    }

    private calculateHorizontalScale(route: MissionPlanRoute, elementWidth: number) {
        let scalableSectionsWidth = route.sections.reduce((previousValue, currentValue) => {
            if (!currentValue.segment?.elevationProfile?.elevations.length || currentValue.flightZone) {
                return previousValue;
            }

            return (
                previousValue +
                (currentValue.segment?.elevationProfile?.elevations[currentValue.segment?.elevationProfile.elevations.length - 1]
                    ?.startOffset ?? 0)
            );
        }, 0);
        const nonScalableSectionsWidth =
            route.sections.reduce((previousValue, currentValue) => {
                if (!currentValue.segment?.elevationProfile?.elevations.length || currentValue.flightZone) {
                    return previousValue + ZONE_WIDTH;
                }

                return previousValue;
            }, 0) +
            2 * HORIZONTAL_DRAWING_MARGIN;

        const firstElementBuffer = route.sections[0].flightZone?.flightArea?.safetyBuffer?.horizontal ?? 0;
        const lastElementBuffer = route.sections[route.sections.length - 1].flightZone?.flightArea?.safetyBuffer?.horizontal ?? 0;

        scalableSectionsWidth += firstElementBuffer + lastElementBuffer;

        const scale = elementWidth && scalableSectionsWidth ? (elementWidth - nonScalableSectionsWidth) / scalableSectionsWidth : 1;
        const calculatedScale = scale > 1 ? 1 : scale;

        const thinnestSegmentWidth = route.sections.reduce((previousValue, currentValue) => {
            if (!currentValue.segment?.distance) {
                return previousValue;
            }

            return Math.min(previousValue, currentValue.segment.distance);
        }, Infinity);

        if (thinnestSegmentWidth * calculatedScale < MIN_CALCULATED_SEGMENT_WIDTH) {
            return MIN_CALCULATED_SEGMENT_WIDTH / thinnestSegmentWidth;
        }

        return scale;
    }

    private convertRouteToDrawingData(
        route: MissionPlanRoute | undefined,
        {
            elementWidth,
            zoomLevel,
            isSimpleMode,
            isHeightProfileVisible,
        }: {
            elementWidth: number;
            zoomLevel: number;
            isHeightProfileVisible: boolean;
            isSimpleMode: boolean;
        }
    ): DrawingData | undefined {
        if (!route) {
            return;
        }

        const scale = this.calculateHorizontalScale(route, elementWidth);
        const horizontalScale = Math.max(scale, MIN_HORIZONTAL_SCALE) * zoomLevel;

        const scaledZoneWidth = ZONE_WIDTH * zoomLevel;

        const scaledSection = ObjectUtils.cloneDeep(route.sections)
            .filter(({ flightZone, segment }) => flightZone || segment)
            .map((section) => {
                section.segment?.elevationProfile?.elevations.map((elevation) => {
                    elevation.startOffset *= horizontalScale;

                    return elevation;
                });

                return section;
            });

        const sortedElevations = scaledSection
            .flatMap((section) => section.segment?.elevationProfile?.elevations)
            .filter(FunctionUtils.isTruthy)
            .sort((left, right) => left.value - right.value);

        const minZoneElevation = scaledSection.reduce((previousValue, currentValue) => {
            if (!currentValue.flightZone) {
                return previousValue;
            }

            return Math.min(previousValue, currentValue.flightZone.flightArea.elevationMin ?? Infinity);
        }, Infinity);

        const amslMinHeight = sortedElevations[0]?.value ?? (minZoneElevation !== Infinity ? minZoneElevation : 0);
        const safetyAreaBuffer = (route.sections[0].segment ?? route.sections[0].flightZone)?.flightArea.safetyBuffer?.vertical ?? 0;

        const amslMaxHeight =
            route.sections.reduce(
                (previousValue, currentValue) =>
                    Math.max(
                        currentValue.segment?.flightArea.volume.ceiling ?? 0,
                        currentValue.flightZone?.flightArea.volume.ceiling ?? 0,
                        previousValue
                    ),
                0
            ) +
            VERTICAL_BUFFER +
            safetyAreaBuffer;

        const aglMaxHeight = Math.ceil((amslMaxHeight + 1) / MAX_HEIGHT_PRECISION) * MAX_HEIGHT_PRECISION - amslMinHeight;
        const verticalScale = VERTICAL_SCALE / aglMaxHeight;

        const sections = this.getDrawingSections({
            scaledSection,
            amslMinHeight,
            verticalScale,
            horizontalScale,
            scaledZoneWidth,
            isSimpleMode,
        });
        const elevationPath = this.getElevationPath({ scaledSection, amslMinHeight, verticalScale, scaledZoneWidth }).path;
        let maxFlightAreaPath;
        let maxSafetyAreaPath;

        if (isHeightProfileVisible) {
            maxFlightAreaPath = this.getElevationPath({
                scaledSection,
                amslMinHeight,
                verticalScale,
                scaledZoneWidth,
                isPathOnly: true,
                verticalOffset: FLIGHT_ARE_MAX_HEIGHT,
                errorPathArea: "flightArea",
            });
            maxSafetyAreaPath = this.getElevationPath({
                scaledSection,
                amslMinHeight,
                verticalScale,
                scaledZoneWidth,
                isPathOnly: true,
                verticalOffset: SAFETY_ARE_MAX_HEIGHT,
                errorPathArea: "safetyArea",
            });
        }

        this.updateSectionsOffset(sections, isSimpleMode);

        const waypoints = this.getWaypointData({ scaledSection, amslMinHeight, verticalScale, scaledZoneWidth });
        const topOffset =
            isHeightProfileVisible && sortedElevations.length
                ? (SAFETY_ARE_MAX_HEIGHT - (amslMaxHeight - sortedElevations[sortedElevations.length - 1].value)) * verticalScale
                : 0;

        const distanceMeasures = this.getDistanceMeasures(sections, isSimpleMode);

        return {
            aglMaxHeightScaled: aglMaxHeight * verticalScale,
            aglMaxHeight,
            sections,
            amslMinHeightScaled: amslMinHeight * verticalScale,
            amslMinHeight,
            width: Math.max(this.calculateWidth(sections, isSimpleMode), elementWidth),
            waypoints: [...waypoints.values()],
            elevation: elevationPath,
            maxFlightAreaPath: maxFlightAreaPath?.path ?? [],
            maxSafetyAreaPath: maxSafetyAreaPath?.path ?? [],
            verticalScale,
            xOffset: sections[0].zoneData?.safetyArea.horizontalBuffer ?? 0,
            topOffset: Math.max(topOffset, 0),
            heightErrorPath: [...(maxFlightAreaPath?.errorPath ?? []), ...(maxSafetyAreaPath?.errorPath ?? [])],
            isPathBased: !isSimpleMode,
            routeData: route,
            distanceMeasures,
        };
    }

    private calculateWidth(sections: Section[], isSimpleMode: boolean) {
        const lastSection = sections[sections.length - 1];
        const firstSection = sections[0];

        return (
            (!isSimpleMode ? firstSection.zoneData?.safetyArea.horizontalBuffer ?? 0 : 0) +
            lastSection.xOffset +
            lastSection.width +
            (isSimpleMode
                ? SIMPLE_MODE_HORIZONTAL_BUFFER_VALUE + DISTANCE_BETWEEN_SECTIONS_IN_SIMPLE_MODE / 2
                : lastSection.zoneData?.safetyArea.horizontalBuffer ?? 0)
        );
    }

    private getDrawingSections({
        scaledSection,
        amslMinHeight,
        verticalScale,
        horizontalScale,
        scaledZoneWidth,
        isSimpleMode,
    }: {
        scaledSection: {
            flightZone?: MissionPlanRouteFlightZone;
            segment?: MissionPlanRouteSegment;
        }[];
        amslMinHeight: number;
        verticalScale: number;
        horizontalScale: number;
        scaledZoneWidth: number;
        isSimpleMode: boolean;
    }): Section[] {
        const horizontalBuffer =
            (scaledSection.find(({ flightZone }) => flightZone)?.flightZone?.flightArea.safetyBuffer?.horizontal ?? 0) * horizontalScale;

        return scaledSection.map((section, index) => {
            const elevations = section.segment?.elevationProfile?.elevations;
            const segmentWidth = (elevations?.[elevations.length - 1]?.startOffset ?? 0) - (elevations?.[0]?.startOffset ?? 0);

            const segmentData: Section["segmentData"] = section.segment
                ? {
                      ceiling: (section.segment.flightArea.volume.ceiling - amslMinHeight) * verticalScale,
                      ceilingValue: section.segment.flightArea.volume.ceiling,
                      floor: (section.segment.flightArea.volume.floor - amslMinHeight) * verticalScale,
                      maxHeight: section.segment.flightArea.volume.ceiling,
                      minHeight: section.segment.flightArea.volume.floor,
                      safetyArea: {
                          horizontalBuffer,
                          ceiling: (section.segment.safetyArea.volume.ceiling - amslMinHeight) * verticalScale,
                          ceilingValue: section.segment.safetyArea.volume.ceiling,
                          floor: (section.segment.safetyArea.volume.floor - amslMinHeight) * verticalScale,
                      },
                      distance: section.segment.distance,
                  }
                : undefined;
            const { elevationMaxPointPosition, elevationMinPointPosition } = this.getPointDrawingPositions(
                amslMinHeight,
                verticalScale,
                section.flightZone
            );

            const { bufferCeilingDrawingPosition, ceilingDrawingPosition } = this.getCeilingDrawingPositions(
                amslMinHeight,
                verticalScale,
                section.flightZone
            );

            const zoneData: Section["zoneData"] = section.flightZone
                ? {
                      ceiling: (section.flightZone.flightArea.volume.ceiling - amslMinHeight) * verticalScale,
                      ceilingValue: section.flightZone.flightArea.volume.ceiling - (section.flightZone.flightArea.elevationMin ?? 0),
                      ceilingAmslValue: section.flightZone.flightArea.volume.ceiling,
                      ceilingDrawingPosition,
                      bufferCeilingDrawingPosition,
                      floor: (section.flightZone.flightArea.volume.floor - amslMinHeight) * verticalScale,
                      floorAmslValue: section.flightZone.flightArea.volume.floor,
                      elevationMaxPointPosition,
                      elevationMinPointPosition,
                      elevationMin: section.flightZone?.flightArea.elevationMin
                          ? (section.flightZone.flightArea.elevationMin - amslMinHeight) * verticalScale
                          : 0,
                      elevationMax: section.flightZone?.flightArea.elevationMax
                          ? (section.flightZone.flightArea.elevationMax - amslMinHeight) * verticalScale
                          : 0,
                      isRunway:
                          index === 0 ||
                          index === scaledSection.length - 1 ||
                          !!scaledSection[index - 1]?.flightZone ||
                          !!scaledSection[index + 1]?.flightZone,
                      elevationMaxValue: section.flightZone.flightArea.elevationMax,
                      elevationMinValue: section.flightZone.flightArea.elevationMin,
                      width: scaledZoneWidth,
                      safetyArea: {
                          horizontalBuffer: isSimpleMode
                              ? SIMPLE_MODE_HORIZONTAL_BUFFER_VALUE
                              : (section.flightZone.flightArea.safetyBuffer?.horizontal ?? 0) * horizontalScale,
                          verticalBuffer: (section.flightZone.flightArea.safetyBuffer?.vertical ?? 0) * verticalScale,
                          ceiling:
                              (section.flightZone.flightArea.volume.ceiling -
                                  amslMinHeight +
                                  (section.flightZone.flightArea.safetyBuffer?.vertical ?? 0)) *
                              verticalScale,
                          ceilingValue: section.flightZone.safetyArea.volume.ceiling - (section.flightZone.flightArea.elevationMin ?? 0),
                          floor:
                              (section.flightZone.flightArea.volume.floor -
                                  amslMinHeight -
                                  (section.flightZone.flightArea.safetyBuffer?.vertical ?? 0)) *
                              verticalScale,
                      },
                      radius: section.flightZone.flightArea.properties?.radius ?? 0,
                  }
                : undefined;

            const maxElevation = section.segment?.elevationProfile?.elevations.reduce<Elevation | undefined>(
                (previous, current) => (current.value > (previous?.value ?? 0) ? current : previous),
                undefined
            );

            const minElevation = section.segment?.elevationProfile?.elevations.reduce<Elevation | undefined>(
                (previous, current) => (current.value < (previous?.value ?? Infinity) ? current : previous),
                maxElevation
            );

            const maxElevationData: Section["maxElevation"] = maxElevation
                ? {
                      startOffset: maxElevation.startOffset,
                      value: maxElevation.value,
                      height: (maxElevation.value - amslMinHeight) * verticalScale,
                  }
                : undefined;
            const minxElevationData: Section["minElevation"] = minElevation
                ? {
                      startOffset: minElevation.startOffset,
                      value: minElevation.value,
                      height: (minElevation.value - amslMinHeight) * verticalScale,
                  }
                : undefined;

            const shouldShowArrow =
                section.segment?.flightArea.volume.floor && maxElevation && section.segment?.flightArea.volume.floor > maxElevation.value;

            return {
                zoneData,
                width: segmentWidth || scaledZoneWidth,
                segmentData,
                xOffset: 0,
                maxElevation: shouldShowArrow ? maxElevationData : undefined,
                minElevation: minxElevationData,
            };
        });
    }

    private getPointDrawingPositions(amslMinHeight: number, scale: number, flightZone?: MissionPlanRouteFlightZone) {
        const overlappingBuffer = 16;
        let elevationMaxPointPosition = flightZone?.flightArea.elevationMax
            ? (flightZone.flightArea.elevationMax - amslMinHeight) * scale
            : 0;
        let elevationMinPointPosition = flightZone?.flightArea.elevationMin
            ? (flightZone.flightArea.elevationMin - amslMinHeight) * scale
            : 0;
        const difference = elevationMaxPointPosition - elevationMinPointPosition;

        if (difference < overlappingBuffer) {
            const elevationAdjustment = (overlappingBuffer - difference) / 2;
            elevationMaxPointPosition += elevationAdjustment;
            elevationMinPointPosition -= elevationAdjustment;
        }

        return { elevationMaxPointPosition, elevationMinPointPosition };
    }

    protected getCeilingDrawingPositions(amslMinHeight: number, scale: number, flightZone?: MissionPlanRouteFlightZone) {
        const overlappingBuffer = 16;
        let bufferCeilingDrawingPosition = flightZone?.safetyArea.volume.ceiling
            ? (flightZone.safetyArea.volume.ceiling - amslMinHeight) * scale
            : 0;
        let ceilingDrawingPosition = flightZone?.flightArea.volume.ceiling
            ? (flightZone.flightArea.volume.ceiling - amslMinHeight) * scale
            : 0;
        const difference = bufferCeilingDrawingPosition - ceilingDrawingPosition;

        if (difference < overlappingBuffer) {
            const elevationAdjustment = (overlappingBuffer - difference) / 2;
            bufferCeilingDrawingPosition += elevationAdjustment;
            ceilingDrawingPosition -= elevationAdjustment;
        }

        return { bufferCeilingDrawingPosition, ceilingDrawingPosition };
    }

    protected shouldAnchorLabelAtEnd(startOffset: number): boolean {
        return startOffset > MIN_CALCULATED_SEGMENT_WIDTH / 2;
    }

    private getElevationPath({
        amslMinHeight,
        scaledSection,
        scaledZoneWidth,
        verticalScale,
        isPathOnly,
        verticalOffset,
        errorPathArea,
    }: {
        amslMinHeight: number;
        scaledSection: {
            flightZone?: MissionPlanRouteFlightZone;
            segment?: MissionPlanRouteSegment;
        }[];
        scaledZoneWidth: number;
        verticalScale: number;
        isPathOnly?: boolean;
        verticalOffset?: number;
        errorPathArea?: "safetyArea" | "flightArea";
    }) {
        return scaledSection.reduce<{
            lastOffset: number;
            path: string[];
            errorPath: string[];
        }>(
            (previousValue, currentValue) => {
                const elevations = currentValue.segment?.elevationProfile?.elevations;
                if (!elevations?.length || elevations.length === 1) {
                    return {
                        lastOffset: previousValue.lastOffset + scaledZoneWidth,
                        path: [...previousValue.path],
                        errorPath: [...previousValue.errorPath],
                    };
                }

                let errorPaths: string[] = [];
                if (errorPathArea && verticalOffset) {
                    const segmentCeiling = (currentValue.segment?.[errorPathArea].volume.ceiling ?? Infinity) - verticalOffset;
                    errorPaths = this.getErrorPaths(elevations, {
                        segmentCeiling,
                        horizontalOffset: previousValue.lastOffset,
                        amslMinHeight,
                        verticalOffset,
                        verticalScale,
                    });
                }

                const elevationLinePath =
                    elevations
                        ?.map(
                            ({ startOffset, value }) =>
                                ` L ${previousValue.lastOffset + startOffset} -${
                                    (value - amslMinHeight + (verticalOffset ?? 0)) * verticalScale
                                }`
                        )
                        .join(" ") ?? "";

                const completePath = isPathOnly
                    ? `M ${previousValue.lastOffset} -${
                          (elevations[0].value - amslMinHeight + (verticalOffset ?? 0)) * verticalScale
                      }${elevationLinePath}`
                    : `M ${previousValue.lastOffset} ${VERTICAL_BUFFER}${elevationLinePath} V ${VERTICAL_BUFFER} Z`;

                return {
                    lastOffset: previousValue.lastOffset + (elevations[elevations.length - 1]?.startOffset ?? 0),
                    path: [...previousValue.path, completePath],
                    errorPath: [...previousValue.errorPath, ...errorPaths],
                };
            },
            {
                lastOffset: 0,
                path: [],
                errorPath: [],
            }
        );
    }

    private getErrorPaths(
        elevations: Elevation[],
        {
            amslMinHeight,
            horizontalOffset,
            segmentCeiling,
            verticalOffset,
            verticalScale,
        }: {
            amslMinHeight: number;
            horizontalOffset: number;
            segmentCeiling: number;
            verticalOffset: number;
            verticalScale: number;
        }
    ) {
        return elevations
            .reduce((pairs, elevation, index) => {
                pairs.push([elevation, elevations[index + 1]]);

                return pairs;
            }, [] as Elevation[][])
            .filter(
                ([firstElevation, second]) =>
                    firstElevation && second && (firstElevation.value < segmentCeiling || second.value < segmentCeiling)
            )
            .map(
                (pairs) =>
                    `M ${pairs[0].startOffset + horizontalOffset} ${-(pairs[0].value - amslMinHeight + verticalOffset) * verticalScale} L ${
                        pairs[1].startOffset + horizontalOffset
                    } ${-(pairs[1].value - amslMinHeight + verticalOffset) * verticalScale}`
            );
    }

    private getWaypointData({
        amslMinHeight,
        verticalScale,
        scaledSection,
        scaledZoneWidth,
    }: {
        amslMinHeight: number;
        verticalScale: number;
        scaledSection: {
            flightZone?: MissionPlanRouteFlightZone;
            segment?: MissionPlanRouteSegment;
            endXOffset?: number;
            width?: number;
        }[];
        scaledZoneWidth: number;
    }) {
        const waypoints: Map<string, Waypoint> = new Map();

        scaledSection.forEach((section, sectionIndex) => {
            const fromWaypoint = section.segment?.fromWaypoint;
            const toWaypoint = section.segment?.toWaypoint;
            const zoneWaypoint = section.flightZone?.center;
            const elevations =
                section.segment?.elevationProfile?.elevations && section.segment.elevationProfile.elevations.length > 1
                    ? section.segment?.elevationProfile.elevations
                    : undefined;
            const previousSectionOffset = scaledSection[sectionIndex - 1]?.endXOffset ?? 0;
            const currentSectionWidth = elevations?.[elevations?.length - 1]?.startOffset ?? scaledZoneWidth;

            section.endXOffset = previousSectionOffset + (section.flightZone && !section.segment ? scaledZoneWidth : currentSectionWidth);
            section.width = currentSectionWidth;

            if (zoneWaypoint) {
                waypoints.set(zoneWaypoint.name, {
                    height: (zoneWaypoint.point.altitude - amslMinHeight) * verticalScale,
                    label: zoneWaypoint.name,
                    xOffset: section.endXOffset - scaledZoneWidth / 2,
                    isZone: true,
                });
            }
            if (fromWaypoint) {
                waypoints.set(fromWaypoint.name, {
                    height: (fromWaypoint.point.altitude - amslMinHeight) * verticalScale,
                    label: fromWaypoint.name,
                    xOffset: section.endXOffset - section.width,
                });
            }
            if (toWaypoint && !waypoints.has(toWaypoint.name)) {
                waypoints.set(toWaypoint.name, {
                    height: (toWaypoint.point.altitude - amslMinHeight) * verticalScale,
                    label: toWaypoint.name,
                    xOffset: section.endXOffset,
                });
            }
        });

        return waypoints;
    }

    private updateSectionsOffset(sections: Section[], isSimpleMode?: boolean) {
        sections.forEach((sectionElement, sectionElementIndex) => {
            if (sectionElementIndex === 0 && !isSimpleMode) {
                return;
            }

            let distanceBetweenSections = 0;
            if (isSimpleMode && sections[sectionElementIndex - 1]?.zoneData) {
                distanceBetweenSections = DISTANCE_BETWEEN_SECTIONS_IN_SIMPLE_MODE;
            }

            if (isSimpleMode && sectionElementIndex === 0) {
                sectionElement.xOffset = DISTANCE_BETWEEN_SECTIONS_IN_SIMPLE_MODE / 2;

                return;
            }

            if (sectionElementIndex === 1) {
                sectionElement.xOffset = (sections[0]?.xOffset ?? 0) + (sections[0]?.width ?? 0) + distanceBetweenSections;

                return;
            }

            sectionElement.xOffset +=
                sections[sectionElementIndex - 1].xOffset + sections[sectionElementIndex - 1].width + distanceBetweenSections;
        });
    }

    private updateScrollingButtonsVisibility() {
        const scrollArea: HTMLElement = this.scrollableAreaContainer?.nativeElement;
        if (!scrollArea) {
            return;
        }

        this.localStore.patchState({
            scrollButtonsVisibility: {
                isLeftVisible: scrollArea.scrollLeft !== 0,
                isRightVisible: scrollArea.scrollWidth > scrollArea.clientWidth + scrollArea.scrollLeft,
            },
        });
    }

    private tryToRemoveOverlappingTexts() {
        const heightLineLabelSelector = "text.height-line-label, text.segment-height-label";
        const labelElements = [...(this.document?.querySelectorAll(heightLineLabelSelector) ?? [])] as SVGTextElement[];

        labelElements.forEach((currentElement, index) => {
            const nextSiblingElement = labelElements[index + 1];
            if (!nextSiblingElement) {
                return;
            }
            const { height, width, x, y } = currentElement.getBBox();
            const { height: siblingHeight, width: siblingWidth, x: siblingX, y: siblingY } = nextSiblingElement.getBBox();

            if (x < siblingX + siblingWidth && x + width > siblingX && y > siblingY - siblingHeight && y - height < siblingY) {
                const xBuffer = 8;
                const xPosition = siblingX - +(currentElement.getAttribute("dx") ?? 0) - width - xBuffer;
                currentElement.setAttribute("x", xPosition.toString());
            }
        });
    }

    protected getBracketPath(startXPoint: number, startYPoint: number, endYPosition: number, reverse?: boolean) {
        // eslint-disable-next-line no-magic-numbers
        const mainLinesLength = (endYPosition - startYPoint) / 2 - 6;

        if (reverse) {
            return `M ${startXPoint} ${-startYPoint} q 8 0 8 -3 l 0 ${-mainLinesLength}
        q 0 -3 8 -3 q -8 0 -8 -3 l 0 -${mainLinesLength} q 0 -3 -8 -3`;
        }

        return `M ${startXPoint} ${-startYPoint} q -8 0 -8 -3 l 0 ${-mainLinesLength}
        q 0 -3 -8 -3 q 8 0 8 -3 l 0 -${mainLinesLength} q 0 -3 8 -3`;
    }

    protected async getElementBBox(element: HTMLElement) {
        // NOTE: we need to wait for element to be rendered and updated fully, so we use timer(0) to wait for next tick
        await firstValueFrom(timer(0));

        return (element as unknown as SVGTextElement).getBBox();
    }

    protected changeExpansionState(isExpanded: boolean) {
        this.expandedChange.emit(isExpanded);

        if (isExpanded) {
            // NOTE: timeout needed to ensure that scrollableAreaContainer is available and calculated size
            setTimeout(() => {
                this.updateScrollingButtonsVisibility();
            });
        }
    }

    private getDistanceMeasures(sections: Section[], isSimpleMode: boolean) {
        if (isSimpleMode) {
            return;
        }

        const points = sections.reduce<
            {
                offset: number;
                value: number;
            }[]
        >((previousValue, currentValue, index) => {
            const value = previousValue[previousValue.length - 1]?.value ?? 0;

            if (index === 0) {
                return [
                    ...previousValue,
                    {
                        offset: currentValue.xOffset + currentValue.width / 2,
                        value: 0,
                    },
                    {
                        offset: currentValue.xOffset + currentValue.width,
                        value: currentValue.zoneData?.radius ?? 0,
                    },
                ];
            }

            if (currentValue.zoneData) {
                const zoneRadius = currentValue.zoneData.radius;
                const isLast = index === sections.length - 1;
                const distance = zoneRadius ? zoneRadius * 2 : 0;

                return [
                    ...previousValue,
                    {
                        offset: currentValue.xOffset + (isLast ? currentValue.width / 2 : currentValue.width),
                        value: value + (isLast ? distance : zoneRadius ?? 0),
                    },
                ];
            }

            if (currentValue.segmentData) {
                const distance = currentValue.segmentData.distance ?? 0;

                return [
                    ...previousValue,
                    {
                        offset: currentValue.xOffset + currentValue.width,
                        value: value + distance,
                    },
                ];
            }

            return previousValue;
        }, []);

        const firstSection = sections[0];
        const lastSection = sections[sections.length - 1];
        const startPoint = firstSection.xOffset + firstSection.width / 2;
        const endPoint = lastSection.xOffset + lastSection.width / 2;

        const path = `M ${startPoint} ${VERTICAL_BUFFER + DISTANCE_MEASURE_HEIGHT_TOP_OFFSET} L ${endPoint} ${
            VERTICAL_BUFFER + DISTANCE_MEASURE_HEIGHT_TOP_OFFSET
        }`;

        return {
            points,
            path,
        };
    }
}
