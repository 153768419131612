import { ChangeDetectionStrategy, Component, ElementRef, Input } from "@angular/core";
import { MatMenuItem } from "@angular/material/menu";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { NotificationDisplay } from "../../utils/notifications.model";

interface ListItemComponentState {
    notification: NotificationDisplay | undefined;
}

@Component({
    selector: "dtm-notifications-list-item[notification]",
    templateUrl: "./list-item.component.html",
    styleUrls: ["./list-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ListItemComponent extends MatMenuItem {
    @Input() public set notification(value: NotificationDisplay | undefined) {
        this.localStore.patchState({ notification: value });
    }

    public readonly notification$ = this.localStore.selectByKey("notification").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<ListItemComponentState>, private readonly elementRef: ElementRef) {
        localStore.setState({ notification: undefined });
        super(elementRef);
    }
}
