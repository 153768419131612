import { ChangeDetectionStrategy, Component, ElementRef, Input } from "@angular/core";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

interface LoaderComponentState {
    isShown: boolean;
    diameter: number;
}

@Component({
    selector: "dtm-ui-loader",
    templateUrl: "./loader.component.html",
    styleUrls: ["./loader.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.fadeAnimation()],
})
export class LoaderComponent {
    public readonly isShown$ = this.localStore.selectByKey("isShown");
    public readonly diameter$ = this.localStore.selectByKey("diameter");

    @Input()
    public set isShown(value: boolean | undefined) {
        this.localStore.patchState({ isShown: !!value });
    }

    @Input()
    public set shouldSetParentPositionRelative(value: boolean) {
        if (!value) {
            return;
        }

        const parentElement: HTMLElement = this.element.nativeElement.parentElement;

        parentElement.style.position = "relative";
    }

    @Input()
    public set diameter(value: number) {
        this.localStore.patchState({ diameter: value });
    }

    constructor(private readonly localStore: LocalComponentStore<LoaderComponentState>, private element: ElementRef) {
        localStore.setState({
            isShown: true,
            diameter: 60,
        });
    }
}
