import { Pipe, PipeTransform } from "@angular/core";
import { FlightZoneUtils, MapUnitsOfMeasure } from "@dtm-frontend/dss-shared-lib";

@Pipe({
    name: "metersToOtherUnitsOfMeasureConverter",
})
export class MetersToOtherUnitsOfMeasureConverterPipe implements PipeTransform {
    public transform(value: number, unit: MapUnitsOfMeasure, decimalPlaces: number): number {
        return FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(value, unit, decimalPlaces);
    }
}
