import { Pipe, PipeTransform } from "@angular/core";
import { METERS_IN_KILOMETER } from "./length";
import { SECONDS_IN_HOUR } from "./time";

@Pipe({
    name: "convertToKmPerH",
})
export class ConvertToKmPerHPipe implements PipeTransform {
    public transform(value: number | undefined | null): number | undefined {
        if (value === undefined || value === null) {
            return undefined;
        }

        return (value * SECONDS_IN_HOUR) / METERS_IN_KILOMETER;
    }
}
