import { Injectable } from "@angular/core";
import { Data, GuardsCheckEnd, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, firstValueFrom, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { TranslationHelperService } from "../../modules/i18n/services/translation-helper.service";

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class RouteDataService {
    private readonly routeDataSubject = new BehaviorSubject<Data | undefined>({});
    private readonly routeOutletSubject = new BehaviorSubject<string | undefined>(undefined);

    public readonly routeData$ = this.routeDataSubject.asObservable();
    public readonly currentRouteOutlet$ = this.routeOutletSubject.asObservable();
    public readonly routeTitle$ = this.routeDataSubject.pipe(
        switchMap((data) => {
            const titleTranslationKey = data?.titleTranslationKey;
            const title = data?.title;
            if (title) {
                return of(title);
            }

            if (!titleTranslationKey) {
                return of("");
            }

            return this.translocoHelper.selectTranslation(titleTranslationKey, "");
        })
    );

    constructor(private readonly router: Router, private readonly translocoHelper: TranslationHelperService) {
        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
            if (event instanceof GuardsCheckEnd && event.shouldActivate) {
                this.routeDataSubject.next(event.state.root.firstChild?.data);
                this.routeOutletSubject.next(event.state.root.firstChild?.outlet);
            }
        });
    }

    public async updateTitle(title: string) {
        const previousData = await firstValueFrom(this.routeDataSubject);

        this.routeDataSubject.next({ ...previousData, title });
    }
}
