/* eslint-disable @typescript-eslint/no-explicit-any */

import { Directive, forwardRef, OnDestroy } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject, Subject } from "rxjs";
import { FunctionUtils } from "../function-utils";

@Directive({
    standalone: true,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ValueAccessorDirective),
            multi: true,
        },
    ],
})
export class ValueAccessorDirective<T> implements ControlValueAccessor, OnDestroy {
    private onChange: (value: T) => void;
    private onTouched: (value: boolean) => void;

    private readonly valueSubject = new BehaviorSubject<T | null>(null);
    private readonly disabledSubject = new Subject<boolean>();

    public readonly value$ = this.valueSubject.asObservable();
    public readonly disabled$ = this.disabledSubject.asObservable();

    constructor() {
        this.onChange = FunctionUtils.noop;
        this.onTouched = FunctionUtils.noop;
    }

    public ngOnDestroy() {
        this.valueSubject.complete();
        this.disabledSubject.complete();
    }

    public valueChange(value: T) {
        this.onChange(value);
    }

    public touchedChange(value: boolean) {
        this.onTouched(value);
    }

    public writeValue(object: T) {
        this.valueSubject.next(object);
        this.valueChange(object);
    }

    public getValue(): T | null {
        return this.valueSubject.value;
    }

    public registerOnChange(onChangeFn: (value: T) => void) {
        this.onChange = onChangeFn;
    }

    public registerOnTouched(onTouchedFn: (value: boolean) => void) {
        this.onTouched = onTouchedFn;
    }

    public setDisabledState(isDisabled: boolean) {
        this.disabledSubject.next(isDisabled);
    }
}
