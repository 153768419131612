import { mustMatchValidator } from "./must-match.validator";
import { trimmedMaxLengthValidator } from "./trimmed-max-length.validator";
import { trimmedRequiredValidator } from "./trimmed-required.validator";
import { uniqueValidator } from "./unique.validator";

export const FormUtils = {
    trimmedRequiredValidator,
    trimmedMaxLengthValidator,
    mustMatchValidator,
    uniqueValidator,
};
