import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "phoneNumbersToClickable",
    standalone: true,
})
export class PhoneNumbersToClickablePipe implements PipeTransform {
    public transform(value: string, linkClass = "link"): string {
        if (!value) {
            return "";
        }
        // NOTE: regex matching example: +48123123123
        const standardRegex = new RegExp(/(\+\d{11})/);
        // NOTE: regex matching example: +48 123 123 123
        const splitRegex = new RegExp(/(\+\d{2}\s\d{3}\s\d{3}\s\d{3})/);
        // NOTE: regex matching example: (12) 123 12 12
        const landlineRegex = new RegExp(/(\(?\d{2}\)\s\d{3}\s\d{2}\s\d{2})+/);

        const phoneRegex = new RegExp(`${standardRegex.source}|${splitRegex.source}|${landlineRegex.source}`, "g");

        return value.replace(phoneRegex, (number) => `<a class="${linkClass}" href="tel:${number}">${number}</a>`);
    }
}
