import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardRoutingModule, PlannedMissionsRoutingModule, UserProfileRoutingModule } from "@dtm-frontend/dss-client-lib";
import { FlightZoneRoutingModule } from "@dtm-frontend/dss-client-lib/flight-zone";
import { DTM_AUTH_GUARD_REQUIRED_ROLES, DtmAuthGuard, NOT_AUTHORIZED_ROUTE } from "@dtm-frontend/shared/auth";
import { NotAuthorizedComponent } from "@dtm-frontend/shared/ui";

const routes: Routes = [
    {
        path: NOT_AUTHORIZED_ROUTE,
        component: NotAuthorizedComponent,
        canActivate: [DtmAuthGuard],
    },
    { path: "**", redirectTo: "/dashboard" },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        FlightZoneRoutingModule,
        PlannedMissionsRoutingModule,
        DashboardRoutingModule,
        UserProfileRoutingModule,
    ],
    exports: [RouterModule],
    providers: [
        {
            provide: DTM_AUTH_GUARD_REQUIRED_ROLES,
            useValue: ["dss-user"],
        },
    ],
})
export class AppRoutingModule {}
