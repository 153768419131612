<input
    #input
    class="cdk-visually-hidden"
    type="radio"
    [id]="inputId"
    [checked]="checked"
    [disabled]="disabled"
    [attr.name]="name"
    [attr.value]="value"
    [required]="required"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby"
    (change)="_onInputInteraction($event)"
/>
<label [for]="inputId">
    <span class="icon" [class.selected]="checked">
        <dtm-ui-icon *ngIf="!checked" name="radio-button-blank"></dtm-ui-icon>
        <dtm-ui-icon *ngIf="checked" name="radio-button"></dtm-ui-icon>
    </span>
    <span class="label"><ng-content></ng-content></span>
    <span class="radio-field-hint"><ng-content select="[radioFieldHint]"></ng-content></span>
</label>
