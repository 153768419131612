import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface NoResultsTemplateComponentState {
    areFiltersApplied: boolean;
}

@Component({
    selector: "dtm-ui-no-results",
    templateUrl: "./no-results.component.html",
    styleUrls: ["./no-results.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NoResultsComponent {
    @Input() public set areFiltersApplied(value: boolean | undefined) {
        this.localStore.patchState({ areFiltersApplied: value });
    }

    public readonly areFiltersApplied$ = this.localStore.selectByKey("areFiltersApplied");

    constructor(private readonly localStore: LocalComponentStore<NoResultsTemplateComponentState>) {
        localStore.setState({ areFiltersApplied: false });
    }
}
