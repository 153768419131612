<dtm-ui-expandable-panel [isExpanded]="isDetailsExpanded$ | ngrxPush" [hasHeaderSeparator]="false">
    <div class="header" headerSlot>
        {{ "dtmUi.permitUavDetailsPreview.header" | transloco }}

        <button
            (click)="$event.stopPropagation(); editStep.emit(SpecificPermitFLightWizardSteps.UavDetails)"
            *ngIf="isActionButtonVisible$ | ngrxPush"
            type="button"
            class="button-secondary edit-button"
        >
            {{ "dtmUi.permitBasicDataPreview.editLabel" | transloco }}
        </button>
    </div>
    <div class="data-preview" *ngrxLet="uavDetails$ as uavDetails">
        <dtm-ui-label-value
            [label]="'dtmUi.permitUavDetailsPreview.manufacturerLabel' | transloco"
            [value]="uavDetails.uavModel.manufacturer"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmUi.permitUavDetailsPreview.modelLabel' | transloco"
            [value]="uavDetails.uavModel.name"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmUi.permitUavDetailsPreview.uavTypeLabel' | transloco"
            [value]="'dtmUi.permitUavDetailsPreview.uavTypeValue' | transloco : { value: uavDetails.uavModel.type }"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmUi.permitUavDetailsPreview.operatedNumberLabel' | transloco"
            [value]="'dtmUi.permitUavDetailsPreview.operatedUavValue' | transloco : { value: uavDetails.operatedNumber }"
        ></dtm-ui-label-value>
        <dtm-ui-label-value [label]="'dtmUi.permitUavDetailsPreview.droneSwarmLabel' | transloco">
            <ng-container *ngIf="uavDetails.isDroneSwarm">
                <div class="icon-label">
                    <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                    {{ "dtmUi.permitUavDetailsPreview.yesLabel" | transloco }}
                </div>
            </ng-container>
            <ng-container *ngIf="!uavDetails.isDroneSwarm">
                <div class="icon-label">
                    <dtm-ui-icon name="close-circle"></dtm-ui-icon>
                    {{ "dtmUi.permitUavDetailsPreview.noLabel" | transloco }}
                </div>
            </ng-container>
        </dtm-ui-label-value>
        <dtm-ui-label-value [label]="'dtmUi.permitUavDetailsPreview.serialNumbersLabel' | transloco">
            <span class="break-word">{{ uavDetails.serialNumbers }}</span>
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmUi.permitUavDetailsPreview.maxUavWidthLabel' | transloco"
            [value]="'dtmUi.permitUavDetailsPreview.uavWidthValue' | transloco : { value: uavDetails.maxUavWidth }"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmUi.permitUavDetailsPreview.takeOffWeightLabel' | transloco"
            [value]="'dtmUi.permitUavDetailsPreview.takeOffWeightValue' | transloco : { value: uavDetails.takeOffWeight }"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmUi.permitUavDetailsPreview.maxFlightSpeedLabel' | transloco"
            [value]="'dtmUi.permitUavDetailsPreview.maxFlightSpeedValue' | transloco : { value: uavDetails.maxFlightSpeed }"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="uavDetails.additionalTechRequirements"
            [label]="'dtmUi.permitUavDetailsPreview.additionalTechRequirementsLabel' | transloco"
            [value]="uavDetails.additionalTechRequirements"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="uavDetails.designVerificationReport"
            [label]="'dtmUi.permitUavDetailsPreview.dvrReportLabel' | transloco"
            [value]="uavDetails.designVerificationReport"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="uavDetails.certificateOfAirworthiness"
            [label]="'dtmUi.permitUavDetailsPreview.certificateOfAirworthinessLabel' | transloco"
            [value]="uavDetails.certificateOfAirworthiness"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="uavDetails.noiseCertificate"
            [label]="'dtmUi.permitUavDetailsPreview.noiseCertificate' | transloco"
            [value]="uavDetails.noiseCertificate"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmUi.permitUavDetailsPreview.riskMitigationMeasureLabel' | transloco"
            [value]="
                'dtmUi.permitUavDetailsPreview.groundRiskMitigationMeasureValueLabel' | transloco : { value: uavDetails.riskMitigation }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value *ngIf="uavDetails.comments" [label]="'dtmUi.permitUavDetailsPreview.commentsSection' | transloco">
            <span class="break-word">{{ uavDetails.comments }}</span>
        </dtm-ui-label-value>
    </div>
</dtm-ui-expandable-panel>
