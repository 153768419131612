<ng-container
    *ngrxLet="{
        basicData: basicData$,
        areActionButtonsVisible: areActionButtonsVisible$,
        isRestrictionsTileExpanded: isRestrictionsTileExpanded$,
        isContactPersonTileExpanded: isContactPersonTileExpanded$,
        isOperatorDetailsTileExpanded: isOperatorDetailsTileExpanded$,

    } as vm"
>
    <dtm-ui-expandable-panel [isExpanded]="vm.isContactPersonTileExpanded" [hasHeaderSeparator]="false">
        <div class="header" headerSlot>
            {{ "dtmUi.permitBasicDataPreview.caaContactPersonTileHeader" | transloco }}

            <button
                *ngIf="vm.areActionButtonsVisible"
                (click)="editStep.emit(SpecificPermitFlightWizardSteps.BasicInfo)"
                type="button"
                class="button-secondary edit-button"
            >
                {{ "dtmUi.permitBasicDataPreview.editLabel" | transloco }}
            </button>
        </div>
        <div class="data-preview">
            <dtm-ui-permit-contact-person-preview [contactPerson]="vm.basicData.caaContactUser"></dtm-ui-permit-contact-person-preview>
        </div>
    </dtm-ui-expandable-panel>

    <dtm-ui-expandable-panel [isExpanded]="vm.isOperatorDetailsTileExpanded" [hasHeaderSeparator]="false">
        <div class="header" headerSlot>
            {{ "dtmUi.permitBasicDataPreview.operatorDetailsTileHeader" | transloco }}

            <button
                *ngIf="vm.areActionButtonsVisible"
                (click)="editStep.emit(SpecificPermitFlightWizardSteps.BasicInfo)"
                type="button"
                class="button-secondary edit-button"
            >
                {{ "dtmUi.permitBasicDataPreview.editLabel" | transloco }}
            </button>
        </div>
        <div class="data-preview">
            <div class="operator-details">
                <dtm-ui-label-value
                    [label]="'dtmUi.permitBasicDataPreview.operatorNumberLabel' | transloco"
                    [value]="vm.basicData.operator.number"
                ></dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmUi.permitBasicDataPreview.operatorNameLabel' | transloco"
                    [value]="vm.basicData.operator.name"
                >
                </dtm-ui-label-value>
            </div>
            <ng-container *ngIf="isOperatorContactPersonVisible$ | ngrxPush">
                <h2 class="operator-contact-person">
                    {{ "dtmUi.permitBasicDataPreview.contactPersonTileHeader" | transloco }}
                </h2>
                <dtm-ui-permit-contact-person-preview [contactPerson]="vm.basicData.operatorContact"></dtm-ui-permit-contact-person-preview>
            </ng-container>
        </div>
    </dtm-ui-expandable-panel>

    <dtm-ui-expandable-panel [isExpanded]="vm.isRestrictionsTileExpanded" [hasHeaderSeparator]="false">
        <div class="header" headerSlot>
            {{ "dtmUi.permitBasicDataPreview.restrictionsSectionTitle" | transloco }}

            <button
                *ngIf="vm.areActionButtonsVisible"
                (click)="editStep.emit(SpecificPermitFlightWizardSteps.BasicInfo)"
                type="button"
                class="button-secondary edit-button"
            >
                {{ "dtmUi.permitBasicDataPreview.editLabel" | transloco }}
            </button>
        </div>

        <div class="data-preview">
            <dtm-ui-label-value
                [label]="'dtmUi.permitBasicDataPreview.permitNumberLabel' | transloco"
                [value]="vm.basicData.operationRestrictions.permitNumber"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.basicData.operationRestrictions.permitConfirmationNumber"
                [label]="'dtmUi.permitBasicDataPreview.permitConfirmationNumberLabel' | transloco"
                [value]="vm.basicData.operationRestrictions.permitConfirmationNumber"
            ></dtm-ui-label-value>
            <dtm-ui-label-value [label]="'dtmUi.permitBasicDataPreview.expirationDateLabel' | transloco">
                {{ vm.basicData.operationRestrictions.expirationDate.dateFrom | localizeDate : { dateStyle: "short" } }} -
                {{ vm.basicData.operationRestrictions.expirationDate.dateTo | localizeDate : { dateStyle: "short" } }}
            </dtm-ui-label-value>
            <dtm-ui-label-value [label]="'dtmUi.permitBasicDataPreview.operationTargetLabel' | transloco">
                <span class="break-word">{{ vm.basicData.operationRestrictions.operationTarget }}</span>
            </dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.basicData.operationRestrictions.dtm"
                [label]="'dtmUi.permitBasicDataPreview.definedAreaLabel' | transloco"
                [value]="vm.basicData.operationRestrictions.dtm.name"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.basicData.operationRestrictions.kmlFile && vm.basicData.operationRestrictions.kmlFile.length"
                [label]="'dtmUi.permitBasicDataPreview.kmlFileName' | transloco"
            >
                <dtm-ui-uploaded-files-display
                    isDisplayFileVisible
                    [uploadedFiles]="vm.basicData.operationRestrictions.kmlFile"
                    (displayFile)="kmlFilePreview.emit(vm.basicData.operationRestrictions.kmlFile[0])"
                >
                </dtm-ui-uploaded-files-display>
            </dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmUi.permitBasicDataPreview.riskMethodologyLabel' | transloco"
                [value]="
                    'dtmUi.permitBasicDataPreview.riskMitigationValueLabel'
                        | transloco
                            : {
                                  value: vm.basicData.operationRestrictions.riskMethodology,
                                  pdraNumber: vm.basicData.operationRestrictions.pdraNumber
                              }
                "
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmUi.permitBasicDataPreview.integrityLabel' | transloco"
                [value]="vm.basicData.operationRestrictions.sail | sailLevel"
            ></dtm-ui-label-value>
            <dtm-ui-label-value [label]="'dtmUi.permitBasicDataPreview.operationTypeLabel' | transloco">
                <div class="icon-label">
                    <dtm-ui-icon *ngIf="vm.basicData.operationRestrictions.operationType === MissionType.VLOS" name="eye"></dtm-ui-icon>
                    <dtm-ui-icon
                        *ngIf="vm.basicData.operationRestrictions.operationType === MissionType.BVLOS"
                        name="eye-off"
                    ></dtm-ui-icon>
                    {{ vm.basicData.operationRestrictions.operationType }}
                </div>
            </dtm-ui-label-value>
            <dtm-ui-label-value [label]="'dtmUi.permitBasicDataPreview.isDangerousGoodsTransportLabel' | transloco">
                <ng-container *ngIf="vm.basicData.operationRestrictions.isDangerousGoodsTransport">
                    <div class="icon-label">
                        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                        {{ "dtmUi.permitBasicDataPreview.yesLabel" | transloco }}
                    </div>
                </ng-container>
                <ng-container *ngIf="!vm.basicData.operationRestrictions.isDangerousGoodsTransport">
                    <div class="icon-label">
                        <dtm-ui-icon name="close-circle"></dtm-ui-icon>
                        {{ "dtmUi.permitBasicDataPreview.noLabel" | transloco }}
                    </div>
                </ng-container>
            </dtm-ui-label-value>
            <h5 class="sub-header">{{ "dtmUi.permitBasicDataPreview.groundRiskCharacteristicSubtitle" | transloco }}</h5>
            <dtm-ui-label-value
                [label]="'dtmUi.permitBasicDataPreview.groundAreaCharacteristicLabel' | transloco"
                [value]="
                    'dtmUi.permitBasicDataPreview.groundAreaSelectValue'
                        | transloco : { value: vm.basicData.groundRiskCharacteristic.groundAreaCharacteristic }
                "
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmUi.permitBasicDataPreview.adjacentGroundAreaLabel' | transloco"
                [value]="
                    'dtmUi.permitBasicDataPreview.groundAreaSelectValue'
                        | transloco : { value: vm.basicData.groundRiskCharacteristic.adjacentGroundAreaCharacteristic }
                "
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmUi.permitBasicDataPreview.adjacentGroundAreaExtentLabel' | transloco"
                [value]="
                    'dtmUi.permitBasicDataPreview.adjacentGroundAreaExtentValue'
                        | transloco
                            : {
                                  value: vm.basicData.groundRiskCharacteristic.adjacentGroundAreaExtent,
                                  unit: 'dtmUi.permitBasicDataPreview.adjacentGroundAreaExtentInputSuffix' | transloco
                              }
                "
            ></dtm-ui-label-value>
            <h4 class="sub-header">{{ "dtmUi.permitBasicDataPreview.finalRiskClassSubtitle" | transloco }}</h4>
            <dtm-ui-label-value
                [label]="'dtmUi.permitBasicDataPreview.finalRiskGroundAreaCharacteristicLabel' | transloco"
                [value]="vm.basicData.finalRiskArea.operationArea | arcLabel"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmUi.permitBasicDataPreview.finalRiskAdjacentGroundAreaLabel' | transloco"
                [value]="vm.basicData.finalRiskArea.adjacentArea | arcLabel"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmUi.permitBasicDataPreview.operationAreaHeightLabel' | transloco"
                [value]="
                    'dtmUi.permitBasicDataPreview.operationArea'
                        | transloco : { value: vm.basicData.finalRiskArea.operationalVolumeHeightLimit }
                "
            ></dtm-ui-label-value>

            <dtm-ui-label-value [label]="'dtmUi.permitBasicDataPreview.operationalAirSpaceClassLabel' | transloco">
                <span class="inline-list-item" *ngFor="let operationalAirSpace of vm.basicData.finalRiskArea.operationalAirSpaces">{{
                    "dtmUi.permitBasicDataPreview.operationalAirSpaceClassSelectValue"
                        | transloco
                            : {
                                  value: operationalAirSpace
                              }
                }}</span>
            </dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.basicData.finalRiskArea.otherAirspace"
                [label]="'dtmUi.permitBasicDataPreview.otherSpaceNameLabel' | transloco"
                [value]="vm.basicData.finalRiskArea.otherAirspace"
            ></dtm-ui-label-value>
            <h4 class="sub-header">{{ "dtmUi.permitBasicDataPreview.groundRiskMitigationMeasureSubtitle" | transloco }}</h4>
            <dtm-ui-label-value
                [label]="
                    'dtmUi.permitBasicDataPreview.strategicMitigationMeasuresLabel'
                        | transloco : { riskMethodology: vm.basicData.operationRestrictions.riskMethodology }
                "
                [value]="
                    'dtmUi.permitBasicDataPreview.finalRisk'
                        | transloco : { value: vm.basicData.groundRiskMitigation.strategicMitigationMeasure }
                "
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.basicData.groundRiskMitigation.avoidingFlightsOverPeople"
                [label]="'dtmUi.permitBasicDataPreview.avoidingFlightsOverPeopleLabel' | transloco"
                [value]="
                    'dtmUi.permitBasicDataPreview.avoidingFlightsOverPeopleValueLabel'
                        | transloco : { value: vm.basicData.groundRiskMitigation.avoidingFlightsOverPeople }
                "
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.basicData.groundRiskMitigation.emergencyResponsePlan"
                [label]="'dtmUi.permitBasicDataPreview.emergencyResponsePlanLabel' | transloco"
                [value]="
                    'dtmUi.permitBasicDataPreview.emergencyResponsePlanValueLabel'
                        | transloco : { value: vm.basicData.groundRiskMitigation.emergencyResponsePlan }
                "
            ></dtm-ui-label-value>

            <h4 class="sub-header">{{ "dtmUi.permitBasicDataPreview.airRiskMitigationMeasureSubtitle" | transloco }}</h4>
            <dtm-ui-label-value
                [label]="'dtmUi.permitBasicDataPreview.airRiskStrategicMitigationMeasuresLabel' | transloco"
                [value]="
                    'dtmUi.permitBasicDataPreview.airRiskMitigationMeasureValueLabel'
                        | transloco : { value: vm.basicData.airRiskMitigation.strategicMitigationMeasure }
                "
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmUi.permitBasicDataPreview.tacticalAirRiskMitigationMeasuresLabel' | transloco"
                [value]="
                    'dtmUi.permitBasicDataPreview.tacticalRiskMitigationMeasureValueLabel'
                        | transloco : { value: vm.basicData.airRiskMitigation.tacticalAirRiskMitigationMeasure }
                "
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmUi.permitBasicDataPreview.reachedLevelLabel' | transloco"
                [value]="
                    'dtmUi.permitBasicDataPreview.containmentReachedLevelValue'
                        | transloco : { value: vm.basicData.airRiskMitigation.containmentReachedLevel }
                "
            ></dtm-ui-label-value>
            <dtm-ui-label-value [label]="'dtmUi.permitBasicDataPreview.pilotCompetenciesLabel' | transloco">
                <div *ngFor="let competence of vm.basicData.airRiskMitigation.competencies">
                    <div class="competence">
                        <dtm-ui-icon name="check"></dtm-ui-icon>
                        <span>{{ competence.name }}</span>
                    </div>
                </div>
            </dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.basicData.airRiskMitigation.additionalCompetencies.length"
                [label]="'dtmUi.permitBasicDataPreview.additionalCompetenciesLabel' | transloco"
            >
                <div class="competence" *ngFor="let competence of vm.basicData.airRiskMitigation.additionalCompetencies">
                    <dtm-ui-icon name="check"></dtm-ui-icon>
                    <span>{{ competence }}</span>
                </div>
            </dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.basicData.airRiskMitigation.staffCompetencies.length"
                [label]="'dtmUi.permitBasicDataPreview.staffCompetenciesLabel' | transloco"
            >
                <div class="competence" *ngFor="let competence of vm.basicData.airRiskMitigation.staffCompetencies">
                    <dtm-ui-icon name="check"></dtm-ui-icon>
                    <span>{{ competence }}</span>
                </div>
            </dtm-ui-label-value>
            <div class="hint">
                <h4 class="sub-header">{{ "dtmUi.permitBasicDataPreview.additionalAccidentTypeSubtitle" | transloco }}</h4>
            </div>
            <div>
                {{ "dtmUi.permitBasicDataPreview.additionalAccidentDescription" | transloco }}
                <a [href]="AVIATION_REPORTING_URL" class="link">{{ AVIATION_REPORTING_URL }}</a>
            </div>
            <dtm-ui-label-value [label]="'dtmUi.permitBasicDataPreview.insuranceLabel' | transloco">
                <ng-container *ngIf="vm.basicData.additionalAccidentType.hasInsurance">
                    <div class="icon-label">
                        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                        {{ "dtmUi.permitBasicDataPreview.yesLabel" | transloco }}
                    </div>
                </ng-container>
                <ng-container *ngIf="!vm.basicData.additionalAccidentType.hasInsurance">
                    <div class="icon-label">
                        <dtm-ui-icon name="close-circle"></dtm-ui-icon>
                        {{ "dtmUi.permitBasicDataPreview.noLabel" | transloco }}
                    </div>
                </ng-container>
            </dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmUi.permitBasicDataPreview.operationsManualReferenceLabel' | transloco"
                [value]="vm.basicData.additionalAccidentType.operationsManualReference"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.basicData.additionalAccidentType.additionalLimitations"
                [label]="'dtmUi.permitBasicDataPreview.additionalLimitationsLabel' | transloco"
            >
                <span class="break-word">{{ vm.basicData.additionalAccidentType.additionalLimitations }}</span>
            </dtm-ui-label-value>
        </div>
    </dtm-ui-expandable-panel>
</ng-container>
