import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "roundNumber",
})
export class RoundNumberPipe implements PipeTransform {
    public transform(value: number | undefined) {
        return value && Math.round(value).toFixed(0);
    }
}
