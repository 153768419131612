import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, FormControlStatus, NgControl, UntypedFormGroup } from "@angular/forms";
import { MatDateRangePicker, MatDatepicker } from "@angular/material/datepicker";
import { MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS } from "@angular/material/legacy-core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatestWith } from "rxjs";
import { first, map, startWith } from "rxjs/operators";

const APP_DATE_FORMATS = {
    parse: {
        dateInput: null,
    },
    display: {
        dateInput: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        monthYearLabel: {
            year: "numeric",
            month: "long",
        },
        dateA11yLabel: {
            year: "numeric",
            month: "long",
            day: "numeric",
        },
        monthYearA11yLabel: {
            year: "numeric",
            month: "long",
        },
    },
};

interface DateFieldComponentState {
    isClearButtonVisible: boolean;
    isClearable: boolean;
    isDisabled: boolean;
    isActivated: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-date-field",
    templateUrl: "./date-field.component.html",
    styleUrls: ["./date-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }],
})
export class DateFieldComponent implements AfterContentInit {
    @ContentChild(NgControl) private input!: NgControl;
    @ContentChild(MatDatepicker) private simpleDatepicker!: MatDatepicker<Date>;
    @ContentChild(MatDateRangePicker) private rangeDatepicker!: MatDateRangePicker<Date>;

    @Input() public set isClearable(value: boolean) {
        this.localStore.patchState({ isClearable: value });
    }

    @Output() public readonly rangeEmpty = new EventEmitter<void>();
    @Output() public readonly valueClear = new EventEmitter<void>();

    public isClearButtonVisible$ = this.localStore.selectByKey("isClearable").pipe(
        combineLatestWith(this.localStore.selectByKey("isClearButtonVisible")),
        map(([isClearable, isClearButtonVisible]) => isClearable && isClearButtonVisible)
    );
    public isDisabled$ = this.localStore.selectByKey("isDisabled");
    public isActivated$ = this.localStore.selectByKey("isActivated");

    public get datepicker() {
        return this.simpleDatepicker || this.rangeDatepicker;
    }

    public get control(): AbstractControl | undefined {
        if (this.isRangeMode()) {
            return this.input?.control?.parent ?? undefined;
        }

        return this.input?.control ?? undefined;
    }

    constructor(private readonly localStore: LocalComponentStore<DateFieldComponentState>) {
        this.localStore.setState({
            isClearButtonVisible: false,
            isClearable: true,
            isDisabled: false,
            isActivated: false,
        });
    }

    public ngAfterContentInit() {
        this.adjustDatepicker();
        this.watchActivation();
        this.watchClearButtonVisibility();
        this.watchDisability();
    }

    public clearValue(): void {
        if (this.isRangeMode()) {
            const controls = Object.values((this.control as UntypedFormGroup)?.controls ?? {});
            for (const control of controls) {
                control.setValue(null);
            }

            this.valueClear.emit();

            return;
        }

        this.control?.setValue(null);
        this.valueClear.emit();
    }

    private adjustDatepicker(): void {
        this.datepicker.xPosition = "end";
        this.datepicker.panelClass = [this.datepicker.panelClass, "date-field-picker"].flat().filter(Boolean);
    }

    private watchActivation(): void {
        this.control?.valueChanges.pipe(first(), untilDestroyed(this)).subscribe(() => {
            this.localStore.patchState({
                isActivated: true,
            });
        });
    }

    private watchClearButtonVisibility(): void {
        this.control?.valueChanges.pipe(startWith(this.control.value), untilDestroyed(this)).subscribe((value) => {
            this.localStore.patchState({
                isClearButtonVisible: !this.isEmptyValue(value),
            });
        });
    }

    private watchDisability(): void {
        this.control?.statusChanges
            .pipe(
                map((status: FormControlStatus) => status === "DISABLED"),
                startWith(this.control?.disabled),
                untilDestroyed(this)
            )
            .subscribe((isDisabled: boolean) => {
                this.localStore.patchState({ isDisabled });
            });
    }

    private isEmptyValue(value: unknown): boolean {
        if (this.isRangeMode()) {
            return value && typeof value === "object" ? !Object.values(value).some(Boolean) : true;
        }

        return !value;
    }

    private isRangeMode() {
        return !!this.rangeDatepicker;
    }
}
