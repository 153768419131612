import { Directive, ElementRef, EventEmitter, HostListener, OnDestroy, Output } from "@angular/core";
import { ScrollPosition } from "../models/scroll-tracker.models";

@Directive({
    selector: "[dtmUiScrollTracker]",
})
export class ScrollTrackerDirective implements OnDestroy {
    private hostObserver: ResizeObserver | undefined;

    @Output() public scrollTrack = new EventEmitter<ScrollPosition>();
    @HostListener("scroll", ["$event"]) private trackScroll(event: Event) {
        if (!event.target) {
            return;
        }

        this.scrollTrack.emit(this.prepareScrollPosition(event.target as HTMLElement));
    }

    constructor(private host: ElementRef) {
        this.hostObserver = new ResizeObserver(() => {
            this.scrollTrack.emit(this.prepareScrollPosition(this.host.nativeElement));
        });
        this.hostObserver.observe(this.host.nativeElement);
    }

    public ngOnDestroy(): void {
        this.hostObserver?.disconnect();
    }

    private prepareScrollPosition(target: HTMLElement): ScrollPosition {
        return {
            scrollLeft: target.scrollLeft,
            offsetWidth: target.offsetWidth,
            scrollWidth: target.scrollWidth,
        };
    }
}
