import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

export interface ConfirmationDialogConfig {
    confirmationText?: string;
    confirmationHtml?: string;
    declineButtonLabel?: string;
    confirmButtonLabel?: string;
    titleText?: string;
    theme?: ButtonTheme;
    shouldHideBody?: boolean;
}

export enum ButtonTheme {
    Warn = "Warn",
    Primary = "Primary",
}

@Component({
    selector: "dtm-ui-confirmation-dialog",
    templateUrl: "./confirmation-dialog.component.html",
    styleUrls: ["./confirmation-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
    public readonly ButtonTheme = ButtonTheme;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: ConfirmationDialogConfig | undefined
    ) {}
}
