<div
    class="zone-info"
    *ngrxLet="{
        zone: zone$,
        isZoneSelected: isZoneSelected$,
        activeLanguageCode: activeLanguageCode$,
        analysisIssues: analysisIssues$,
        evaluationIssues: evaluationIssues$,
        doesAupExist: doesAupExist$,
        shouldHideRequirements: shouldHideRequirements$,
        additionalRequirements: additionalRequirements$,
        flightRules: flightRules$,
        missionPhase: missionPhase$,
        airacEndTime: airacEndTime$
    } as vm"
>
    <dtm-ui-expandable-panel #panel isDisabled [hasHeaderSeparator]="false" class="zone-container" *ngIf="vm.zone as zone">
        <div class="zone-header" headerSlot>
            <dtm-ui-icon [name]="zone | invoke : getZoneIconName" class="zone-icon {{ zone.geoZoneType | lowercase }}"></dtm-ui-icon>
            <h2 class="header">
                <ng-container *ngIf="zone.geoZoneType !== GeoZoneType.Local; else localZoneHeaderTemplate">
                    {{ zone.geoZoneType }} ({{ zone.designator }})
                </ng-container>
                <ng-template #localZoneHeaderTemplate>
                    {{ zone.zoneAttributes?.name }}
                    <span>
                        {{
                            "dtmSharedMission.analysis.zone.infoZoneType" | transloco : { value: zone.zoneAttributes?.localInformationType }
                        }}
                    </span>
                </ng-template>
            </h2>
            <button type="button" class="button-icon map-icon" [class.active]="vm.isZoneSelected" (click)="zoneSelectionChange.emit(zone)">
                <dtm-ui-icon name="road-map"></dtm-ui-icon>
            </button>
            <button
                type="button"
                class="button-icon arrow-icon"
                (click)="panel.isExpanded = !panel.expanded"
                *ngIf="zone.information?.[vm.activeLanguageCode]?.description || zone.information?.[vm.activeLanguageCode]?.contact"
            >
                <dtm-ui-icon [name]="panel.expanded ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
            </button>
            <div class="zone-data">
                <span *ngIf="zone.isH24 && zone.scope as scope">
                    {{ "dtmSharedMission.analysis.zone.heightLimitsLabel" | transloco : { min: scope.lowerLimit, max: scope.upperLimit } }}
                    {{ "dtmSharedMission.analysis.zone.24hoursLabel" | transloco }}
                </span>
                <ng-container
                    *ngIf="
                        !zone.isH24 &&
                        zone.reservations.length &&
                        (zone.reservations | invoke : partitionAndGroupReservationsByScope) as reservations
                    "
                >
                    <ng-container *ngTemplateOutlet="reservationTemplate; context: { $implicit: reservations[0] }"></ng-container>

                    <ng-container *ngIf="reservations[1].length && reservations[1] as waitingReservations">
                        <p class="reservation-title">
                            {{
                                (vm.doesAupExist
                                    ? "dtmSharedMission.analysis.zone.zoneOrderedAndActivationPossibleLabel"
                                    : "dtmSharedMission.analysis.zone.zoneOrderAndActivationPossibleLabel"
                                ) | transloco
                            }}
                        </p>
                        <ng-container *ngTemplateOutlet="reservationTemplate; context: { $implicit: waitingReservations }"></ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="information" *ngIf="zone.information?.[vm.activeLanguageCode]?.description as description">
            <p class="label">
                {{ "dtmSharedMission.analysis.zone.descriptionLabel" | transloco }}
            </p>
            <p>
                <span [innerHTML]="description.text | safeHtml"></span>
                <dtm-ui-popover *ngIf="description.tooltip">
                    <p [innerHTML]="description.tooltip | safeHtml"></p>
                </dtm-ui-popover>
            </p>
        </div>
        <div class="information" *ngIf="zone.information?.[vm.activeLanguageCode]?.contact as contact">
            <p class="label">
                {{ "dtmSharedMission.analysis.zone.contactLabel" | transloco }}
            </p>
            <p>{{ contact }}</p>
        </div>
    </dtm-ui-expandable-panel>
    <dtm-mission-requirements-panel
        *ngIf="!vm.shouldHideRequirements"
        [flightConditions]="vm.zone?.information?.[vm.activeLanguageCode]?.flightConditions"
        [additionalRequirements]="vm.additionalRequirements"
        [analysisIssues]="vm.analysisIssues"
        [evaluationIssues]="vm.evaluationIssues"
        [flightRules]="vm.flightRules"
        [missionPhase]="vm.missionPhase"
    ></dtm-mission-requirements-panel>

    <ng-content></ng-content>

    <ng-template #reservationTemplate let-reservations>
        <ul class="zone-reservations">
            <li *ngFor="let reservation of reservations">
                <span>
                    {{
                        "dtmSharedMission.analysis.zone.heightLimitsLabel"
                            | transloco : { min: reservation[0].scope.lowerLimit, max: reservation[0].scope.upperLimit }
                    }}
                </span>
                <ul>
                    <li *ngFor="let reservationData of reservation">
                        <dtm-ui-date-range-display
                            [startDate]="reservationData.scope.startTime"
                            [endDate]="reservationData.scope.endTime"
                            dateStyle="short"
                        ></dtm-ui-date-range-display>
                        <dtm-ui-popover
                            *ngIf="reservationData | invoke : isPredictedReservation : vm.airacEndTime"
                            [popoverText]="'dtmSharedMission.analysis.zone.predictedActivityHint' | transloco"
                        ></dtm-ui-popover>
                    </li>
                </ul>
            </li>
        </ul>
    </ng-template>
</div>
