import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ButtonTheme, ConfirmationDialogComponent } from "@dtm-frontend/shared/ui";
import { DtmToastComponent } from "@dtm-frontend/shared/ui/toast";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DefaultGlobalConfig, ToastContainerDirective, ToastrService } from "ngx-toastr";
import { combineLatest, lastValueFrom, map } from "rxjs";
import { EntityStatus } from "../../models/entity-status.model";
import { DRAW_ACTIONS_PREFIX, MapActionType } from "../../services/entity-editors/map-entities-editor.service";

const TRANSLATION_MAPPING: { [key in MapActionType]?: { guide: string; guideTitle: string; finishDrawing?: string } } = {
    [MapActionType.DrawCylinder]: {
        guide: "dtmMapCesium.mapActionsPanel.guides.drawCylinder",
        guideTitle: "dtmMapCesium.mapActionsPanel.guideTitles.drawCylinder",
    },
    [MapActionType.DrawTakeoffRunway]: {
        guide: "dtmMapCesium.mapActionsPanel.guides.drawTakeoffRunway",
        guideTitle: "dtmMapCesium.mapActionsPanel.guideTitles.drawTakeoffRunway",
    },
    [MapActionType.DrawPolyline]: {
        guide: "dtmMapCesium.mapActionsPanel.guides.drawPolyline",
        guideTitle: "dtmMapCesium.mapActionsPanel.guideTitles.drawPolyline",
        finishDrawing: "dtmMapCesium.mapActionsPanel.finishPolylineLabel",
    },
    [MapActionType.DrawPolylineCorridor]: {
        guide: "dtmMapCesium.mapActionsPanel.guides.drawPolylineCorridor",
        guideTitle: "dtmMapCesium.mapActionsPanel.guideTitles.drawPolylineCorridor",
        finishDrawing: "dtmMapCesium.mapActionsPanel.finishCorridorLabel",
    },
    [MapActionType.DrawLandingRunway]: {
        guide: "dtmMapCesium.mapActionsPanel.guides.drawLandingRunway",
        guideTitle: "dtmMapCesium.mapActionsPanel.guideTitles.drawLandingRunway",
    },
    [MapActionType.DrawPrism]: {
        guide: "dtmMapCesium.mapActionsPanel.guides.drawPrism",
        guideTitle: "dtmMapCesium.mapActionsPanel.guideTitles.drawPrism",
        finishDrawing: "dtmMapCesium.mapActionsPanel.finishPrismLabel",
    },
    [MapActionType.DrawAssistedTakeoffRunway]: {
        guide: "dtmMapCesium.mapActionsPanel.guides.drawAssistedTakeoffRunway",
        guideTitle: "dtmMapCesium.mapActionsPanel.guideTitles.drawAssistedTakeoffRunway",
    },
    [MapActionType.DrawAssistedLandingRunway]: {
        guide: "dtmMapCesium.mapActionsPanel.guides.drawAssistedLandingRunway",
        guideTitle: "dtmMapCesium.mapActionsPanel.guideTitles.drawAssistedLandingRunway",
    },
};

const FADE_ANIMATION_DURATION = 200;

export type MapActionWithPayload =
    | { type: MapActionType; payload?: never }
    | { type: MapActionType.DrawPolylineCorridor; payload: { width: number } }
    | { type: MapActionType.ManualWidthInputPolylineCorridor; payload: { min: number; max: number; step: number } };

interface MapActionsPanelComponentState {
    mode: MapActionsPanelMode | undefined;
    activeMapAction: MapActionType;
    entitiesCount: number;
    activeEntityStatus: EntityStatus | undefined;
    maxEntities: number | undefined;
    isDisabled: boolean;
}

export enum MapActionsPanelMode {
    None = "none",
    AssistedEditor = "assistedEditor",
    CustomEditor = "customEditor",
    StandardEditor = "standardEditor",
    SupervisorTemporaryZone = "supervisorTemporaryZone",
    CylinderAndPrism = "cylinderAndPrism",
}

@UntilDestroy()
@Component({
    selector: "dtm-map-actions-panel[mode][activeMapAction][activeEntityStatus]",
    templateUrl: "./map-actions-panel.component.html",
    styleUrls: ["./map-actions-panel.component.scss"],
    providers: [LocalComponentStore, ToastrService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.fadeAnimation(FADE_ANIMATION_DURATION), AnimationUtils.slideInAnimation(FADE_ANIMATION_DURATION)],
})
export class MapActionsPanelComponent {
    protected readonly MapActionType = MapActionType;
    protected readonly MapActionsPanelMode = MapActionsPanelMode;
    protected readonly TRANSLATION_MAPPING = TRANSLATION_MAPPING;

    protected readonly mode$ = this.localStore.selectByKey("mode");
    protected readonly activeMapAction$ = this.localStore.selectByKey("activeMapAction");
    protected readonly areEntitiesDrawn$ = this.localStore.selectByKey("entitiesCount").pipe(map((length) => length !== 0));
    protected readonly activeEntityStatus$ = this.localStore.selectByKey("activeEntityStatus");
    protected readonly maxEntities$ = this.localStore.selectByKey("maxEntities");
    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");
    protected readonly canDraw$ = combineLatest([this.localStore.selectByKey("entitiesCount"), this.maxEntities$]).pipe(
        map(([entitiesCount, maxEntities]) => !maxEntities || entitiesCount < maxEntities)
    );

    @Input() public set mode(value: MapActionsPanelMode | undefined) {
        this.localStore.patchState({ mode: value });
    }
    @Input() public set activeMapAction(value: MapActionType | undefined) {
        this.localStore.patchState({ activeMapAction: value ?? MapActionType.None });
    }
    @Input() public set entitiesCount(value: number | undefined) {
        this.localStore.patchState({ entitiesCount: value ?? 0 });
    }
    @Input() public set activeEntityStatus(value: EntityStatus | undefined) {
        this.localStore.patchState({ activeEntityStatus: value });
    }
    @Input() public set maxEntities(value: number | undefined) {
        this.localStore.patchState({ maxEntities: value });
    }
    @Input() public set isDisabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }

    @Output() public selectMapAction = new EventEmitter<MapActionWithPayload>();

    @ViewChild(ToastContainerDirective, { static: false }) protected set mapActionsToastContainer(value: ToastContainerDirective) {
        this.watchForMapActionChanges(value);
    }

    constructor(
        private readonly localStore: LocalComponentStore<MapActionsPanelComponentState>,
        private readonly mapActionsToastService: ToastrService,
        private readonly translocoService: TranslocoService,
        private readonly matDialog: MatDialog
    ) {
        this.localStore.setState({
            mode: undefined,
            activeMapAction: MapActionType.None,
            entitiesCount: 0,
            activeEntityStatus: undefined,
            maxEntities: undefined,
            isDisabled: false,
        });
    }

    protected getActiveDrawActionIcon(activeMapAction: MapActionType) {
        switch (activeMapAction) {
            case MapActionType.DrawTakeoffRunway:
            case MapActionType.DrawAssistedTakeoffRunway:
                return "flight-takeoff";
            case MapActionType.DrawLandingRunway:
            case MapActionType.DrawAssistedLandingRunway:
                return "flight-land";
            case MapActionType.DrawCylinder:
                return "shape-cylinder";
            case MapActionType.DrawPolyline:
                return "shape-line";
            case MapActionType.DrawPrism:
                return "shape-prism";

            default:
                return "subtract";
        }
    }

    protected async clearEntities() {
        if (await this.getClearEntitiesConfirmationFromDialog()) {
            this.selectMapAction.emit({ type: MapActionType.RemoveContent });
        }
    }

    private watchForMapActionChanges(mapActionsToastContainer: ToastContainerDirective) {
        if (!mapActionsToastContainer || this.mapActionsToastService.overlayContainer === mapActionsToastContainer) {
            return;
        }

        this.mapActionsToastService.overlayContainer = mapActionsToastContainer;
        this.mapActionsToastService.toastrConfig = {
            ...DefaultGlobalConfig,
            autoDismiss: true,
            closeButton: true,
            disableTimeOut: true,
            maxOpened: 1,
            toastComponent: DtmToastComponent,
        };

        this.activeMapAction$.pipe(untilDestroyed(this)).subscribe((mapAction) => {
            const translation = TRANSLATION_MAPPING[mapAction];

            if (mapAction === MapActionType.None || !translation) {
                this.mapActionsToastService.clear();

                return;
            }

            this.mapActionsToastService.info(
                this.translocoService.translate(translation.guide),
                this.translocoService.translate(translation.guideTitle)
            );
        });
    }

    private async getClearEntitiesConfirmationFromDialog(): Promise<boolean> {
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                confirmationText: this.translocoService.translate("dtmMapCesium.mapActionsPanel.clearEntitiesConfirmDialog.dialogText"),
                declineButtonLabel: this.translocoService.translate(
                    "dtmMapCesium.mapActionsPanel.clearEntitiesConfirmDialog.cancelButtonLabel"
                ),
                confirmButtonLabel: this.translocoService.translate(
                    "dtmMapCesium.mapActionsPanel.clearEntitiesConfirmDialog.confirmButtonLabel"
                ),
                theme: ButtonTheme.Warn,
            },
        });

        return lastValueFrom(dialogRef.afterClosed().pipe(map(Boolean), untilDestroyed(this)));
    }

    protected emitSelectMapAction(mapActionWithPayload: MapActionWithPayload) {
        const currentAction = this.localStore.selectSnapshotByKey("activeMapAction");

        if (currentAction !== mapActionWithPayload.type) {
            this.selectMapAction.emit(mapActionWithPayload);
        }
    }

    protected isDrawAction(mapAction: MapActionType) {
        return mapAction.startsWith(DRAW_ACTIONS_PREFIX);
    }
}
