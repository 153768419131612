<p class="header">
    {{ "dtmUi.outsideDtmInfoPanel.limitsHeader" | transloco }}
</p>
<p class="value">
    {{
        "dtmUi.outsideDtmInfoPanel.limitsValue"
            | transloco : { maxHeight: maxHeight$ | ngrxPush | localizeNumber : { maximumFractionDigits: 0 } }
    }}
</p>
<p class="description">
    {{ "dtmUi.outsideDtmInfoPanel.limitsHint" | transloco }}
    <dtm-ui-popover>
        {{ "dtmUi.outsideDtmInfoPanel.limitsHintPopoverText" | transloco }}
    </dtm-ui-popover>
</p>
<p class="header">{{ "dtmUi.outsideDtmInfoPanel.heightsPreviewHeader" | transloco }}</p>
<p class="description">
    {{ "dtmUi.outsideDtmInfoPanel.heightsPreviewHint" | transloco }}
    <dtm-ui-popover>
        {{ "dtmUi.outsideDtmInfoPanel.heightsPreviewHintPopoverText" | transloco }}
    </dtm-ui-popover>
</p>
