import { MissionFilters, MissionProcessingPhase, PhasePayloadData, PlannedMission } from "../models/planned-missions.models";

export namespace PlannedMissionsActions {
    export class PlannedMissionsWatch {
        public static readonly type = "[Planned Missions] Start missions watch";
    }

    export class StopPlannedMissionsWatch {
        public static readonly type = "[Planned Missions] Stop planned missions watch";
    }

    export class GetMissionList {
        public static readonly type = "[Planned Missions] Get missions list";

        constructor(public missionFilters?: MissionFilters, public shouldFilterMission: boolean = false) {}
    }

    export class ClearMissionData {
        public static readonly type = "[Planned Missions] Clear mission data";

        constructor(public routeId: string) {}
    }

    export class UpdateMissionsByPhase {
        public static readonly type = "[Planned Missions] Update missions by phase";

        constructor(public missionList: PlannedMission[], public phase: MissionProcessingPhase) {}
    }

    export class GetMissionRoute {
        public static readonly type = "[Planned Missions] Get mission route";

        constructor(public routeId: string) {}
    }

    export class ChangeMissionPhase {
        public static readonly type = "[Planned Missions] Change mission phase";

        constructor(public phasePayloadData: PhasePayloadData) {}
    }
}
