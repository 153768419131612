import { AbstractControl } from "@angular/forms";
import equal from "fast-deep-equal";
import { BehaviorSubject, combineLatest, defer, Observable } from "rxjs";
import { distinctUntilChanged, map, startWith } from "rxjs/operators";
import { Logger } from "../logger";
import { ObjectUtils } from "../object-utils/index";

export class FormStateController {
    private savedFormValueSubject = new BehaviorSubject<undefined | AbstractControl["value"]>(undefined);

    public readonly isEditing$: Observable<boolean>;
    public get hasChanged(): boolean {
        return (
            this.savedFormValueSubject.value === undefined ||
            !equal(this.savedFormValueSubject.value, ObjectUtils.cloneDeep(this.formGroupOrControl.getRawValue()))
        );
    }
    public get savedValue() {
        return this.savedFormValueSubject.value;
    }
    public get isValid() {
        return this.formGroupOrControl.valid;
    }

    constructor(
        private readonly formGroupOrControl: AbstractControl,
        options: { saveInitialValue: boolean } = { saveInitialValue: false }
    ) {
        if (options.saveInitialValue) {
            this.save();
        }

        this.isEditing$ = defer(() =>
            combineLatest([
                formGroupOrControl.valueChanges.pipe(
                    startWith(formGroupOrControl.getRawValue()),
                    map(() => formGroupOrControl.getRawValue())
                ),
                this.savedFormValueSubject,
            ]).pipe(
                map(([value, savedFormValue]) => savedFormValue !== undefined && !equal(savedFormValue, ObjectUtils.cloneDeep(value))),
                distinctUntilChanged()
            )
        );
    }

    public save() {
        this.savedFormValueSubject.next(ObjectUtils.cloneDeep(this.formGroupOrControl.getRawValue()));
    }

    public clear() {
        this.savedFormValueSubject.next(undefined);
    }

    public restore() {
        if (this.savedFormValueSubject.value) {
            this.formGroupOrControl.setValue(this.savedFormValueSubject.value);

            if (this.hasChanged) {
                const newValue = this.formGroupOrControl.getRawValue();

                Logger.captureMessage("FormStateController: FormState is 'changed' even after restore", {
                    extra: {
                        formState: {
                            savedValue: this.savedFormValueSubject.value,
                            currentValue: newValue,
                            shouldBeValue: this.savedFormValueSubject.value,
                        },
                    },
                    level: "warning",
                });

                this.savedFormValueSubject.next(newValue); // NOTE: this prevents blocking user interactions, but the warning is still there
            }
        } else {
            console.warn("FormStateController: Cannot restore form state, no saved value found");
        }
    }
}
