import { Pipe, PipeTransform } from "@angular/core";
import { GRAMS_IN_KILOGRAM, KILOGRAMS_IN_TON } from "./weight";

@Pipe({
    name: "convertToGrams",
})
export class ConvertToGramsPipe implements PipeTransform {
    public transform(value: number | undefined | null, from: "kilograms" | "tons" = "kilograms"): number | undefined {
        if (value === undefined || value === null) {
            return undefined;
        }

        if (from === "tons") {
            return value * GRAMS_IN_KILOGRAM * KILOGRAMS_IN_TON;
        }

        return value * GRAMS_IN_KILOGRAM;
    }
}
