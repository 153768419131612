import { Cartesian3 } from "@pansa/ngx-cesium";
import { Geometry, GeometryCollection } from "@turf/helpers";
import { MapUtils } from "../index";

export function convertGeoJsonPolygonGeometryToCartesian3(geometry: Geometry | GeometryCollection) {
    return [geometry.type === "Polygon" ? geometry.coordinates : []]
        .flat(Number.MAX_SAFE_INTEGER)
        .reduce<Cartesian3[]>((result, coordinate, coordinateIndex, array) => {
            if (coordinateIndex % 2 === 1) {
                result.push(
                    MapUtils.convertSerializableCartographicToCartesian3({
                        height: 0,
                        latitude: coordinate as number,
                        longitude: array[coordinateIndex - 1] as number,
                    })
                );
            }

            return result;
        }, [])
        .slice(0, -1);
}
