import { ChangeDetectionStrategy, Component, ContentChildren, QueryList } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { CarouselSlidesDirective } from "./carousel-slides.directive";

interface CarouselComponentState {
    currentSlide: number;
    slides: CarouselSlidesDirective[];
}

@Component({
    selector: "dtm-ui-carousel",
    templateUrl: "./carousel.component.html",
    styleUrls: ["./carousel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CarouselComponent {
    @ContentChildren(CarouselSlidesDirective) protected set slides(value: QueryList<CarouselSlidesDirective>) {
        this.localStore.patchState(({ currentSlide }) => ({
            slides: value.toArray(),
            currentSlide: Math.max(Math.min(currentSlide, value.length - 1), 0),
        }));
    }

    protected readonly currentSlide$ = this.localStore.selectByKey("currentSlide");
    protected readonly slides$ = this.localStore.selectByKey("slides");

    constructor(private readonly localStore: LocalComponentStore<CarouselComponentState>) {
        localStore.setState({ currentSlide: 0, slides: [] });
    }

    public previousSlide(): void {
        this.localStore.patchState(({ currentSlide, slides }) => ({
            currentSlide: currentSlide <= 0 ? slides.length - 1 : currentSlide - 1,
        }));
    }

    public nextSlide(): void {
        this.localStore.patchState(({ currentSlide, slides }) => ({
            currentSlide: currentSlide >= slides.length - 1 ? 0 : currentSlide + 1,
        }));
    }
}
