import { HttpParams } from "@angular/common/http";
import { ApplicationAssignee } from "@dtm-frontend/dss-shared-lib";
import { MissionType } from "@dtm-frontend/shared/mission";
import {
    MissionPlanRoute,
    MissionPlanRouteFlightZone,
    MissionPlanRouteSection,
    MissionPlanRouteSegment,
    SectionElementResponseBody,
    Waypoint,
} from "@dtm-frontend/shared/ui";
import { DateUtils } from "@dtm-frontend/shared/utils";
import { MissionFilters, MissionProcessingPhase, PlannedMission } from "../models/planned-missions.models";

const DEFAULT_MISSION_PHASES = [MissionProcessingPhase.Waiting, MissionProcessingPhase.Rejected, MissionProcessingPhase.Accepted];
const DEFAULT_MISSION_SORT = "missionPlan.mission.startAtMin,desc";
const MAXIMUM_MISSION_LIST_SIZE = 2000;

export interface MissionPlanListResponseBody {
    content: {
        id: string;
        status: MissionProcessingPhase;
        mission: MissionResponseBody;
        restriction: MissionRestrictionResponseBody;
        applicant: ApplicationAssignee;
        uavName: string;
    }[];
}

interface MissionResponseBody {
    endAt: Date;
    endAtMin: Date;
    endAtMax: Date;
    id: string;
    name: string;
    startAt: Date;
    startAtMin: Date;
    startAtMax: Date;
    type: MissionType;
}

interface MissionRestrictionResponseBody {
    designator: string;
    endAt: Date;
    id: string;
    startAt: Date;
}

function parseWaypointResponseDates(waypoint: Waypoint): Waypoint {
    return {
        ...waypoint,
        estimatedArriveAt: {
            min: new Date(waypoint.estimatedArriveAt.min),
            max: new Date(waypoint.estimatedArriveAt.max),
        },
    };
}

function convertSectionElementResponseBodyToMissionPlanRouteSection(sections?: SectionElementResponseBody[]): MissionPlanRouteSection[] {
    return (
        sections?.map((section) => {
            const flightZone: MissionPlanRouteFlightZone | undefined = !section.flightZone
                ? undefined
                : {
                      center: parseWaypointResponseDates(section.flightZone.center),
                      flightArea: section.flightZone.flightArea,
                      safetyArea: section.flightZone.safetyArea,
                      groundArea: section.flightZone.ground?.area,
                      groundAdjacentArea: section.flightZone.groundAdjacent?.area,
                      stopover: section.flightZone.stopover,
                      isRunway: section.flightZone.runway,
                  };

            const segment: MissionPlanRouteSegment | undefined = !section.segment
                ? undefined
                : {
                      fromWaypoint: parseWaypointResponseDates(section.segment.from),
                      toWaypoint: parseWaypointResponseDates(section.segment.to),
                      flightArea: section.segment.flightArea,
                      safetyArea: section.segment.safetyArea,
                      groundArea: section.segment.ground?.area,
                      groundAdjacentArea: section.segment.groundAdjacent?.area,
                      elevationProfile: section.segment.elevationProfile,
                      distance: section.segment.distance,
                      speed: section.segment.speed,
                      duration: section.segment.duration,
                  };

            return { flightZone, segment, isActive: section.active };
        }) ?? []
    );
}

export const convertMissionPlanResponseBodyToPlannedMissionList = ({ content }: MissionPlanListResponseBody): PlannedMission[] => [
    ...content.map(({ mission, applicant, restriction, id, status, uavName }) => ({
        id: id,
        name: mission.name,
        flightStartAt: mission.startAt,
        flightStartAtMin: mission.startAtMin,
        flightStartAtMax: mission.startAtMax,
        flightFinishAt: mission.endAt,
        flightFinishAtMin: mission.endAtMin,
        flightFinishAtMax: mission.endAtMax,
        flightType: mission.type,
        phase: status,
        designator: restriction.designator,
        routeId: id,
        uavName,
        pilotName: `${applicant.firstName} ${applicant.lastName}`,
    })),
];

export interface MissionPlanRouteResponseBody {
    mission: {
        id: string;
        planId: string;
        route: {
            routeId: string;
            sections: SectionElementResponseBody[];
        };
    };
}

export function convertMissionPlanRouteResponseBodyToMissionPlanRoute({ mission }: MissionPlanRouteResponseBody): MissionPlanRoute {
    return {
        routeId: mission.route.routeId,
        planId: mission.planId,
        missionId: mission.id,
        sections: convertSectionElementResponseBodyToMissionPlanRouteSection(mission.route.sections),
    };
}

export const convertMissionFiltersToHttpParams = (query: MissionFilters | undefined): HttpParams => {
    let params = new HttpParams();

    params = params.set("processingPhase", `${DEFAULT_MISSION_PHASES}`);
    params = params.set("size", MAXIMUM_MISSION_LIST_SIZE);
    params = params.set("sort", DEFAULT_MISSION_SORT);

    if (query?.flightDateFrom) {
        params = params.set("startDate", DateUtils.getISOStringDate(query.flightDateFrom.toISOString()));
    }

    if (query?.flightDateTo) {
        params = params.set("endDate", DateUtils.getISOStringDate(query.flightDateTo.toISOString()));
    }

    if (query?.sort) {
        params = params.set("sort", query.sort);
    }

    if (query?.designator) {
        params = params.set("designator", query.designator);
    }

    return params;
};
