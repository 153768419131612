import { EntityStatus } from "./../entity-status.model";
/* eslint-disable no-underscore-dangle */
// NOTE: this file is based on https://github.com/pansa-dev/ngx-cesium/blob/HEAD/projects/@pansa/ngx-cesium/src/lib/@pansa/ngx-cesium-widgets/models/editable-polygon.ts

import { ArrayUtils, MILLISECONDS_IN_SECOND, NarrowIndexable } from "@dtm-frontend/shared/utils";
import { AcEntity, AcLayerComponent, Cartesian3, EditPolyline, GeoUtilsService, PointProps, PolylineProps } from "@pansa/ngx-cesium";
import { Position } from "@turf/helpers";
import { DraggableHeightEntity, HeightEditable, HeightEntityType, HeightPointsProvider } from "../../services/height-helper.service";
import { checkPolygonEdgesIntersections } from "../../utils/check-polygon-edges-intersections";
import { convertSerializableCartographicToCartesian3 } from "../../utils/convert-serializable-cartographic-to-cartesian3";
import { getElevatedCesiumPoint } from "../../utils/get-elevated-cesium-point";
import { getPolygonVisualCenter } from "../../utils/get-polygon-visual-center";
import { CartographicEditPoint, CartographicEditPointParentEntity, CesiumPointWithProps } from "../cartographic-edit-point";
import { DEFAULT_LABEL_PROPS, EntityLabel, LabelFn, LabelProviders } from "../entity-label.model";
import { PrismEditOptions, PrismProps } from "./prism-edit-options";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export const MINIMUM_VERTEX_NUMBER = 3;

export interface PrismEditorLabelProviders extends LabelProviders {
    topHeight?: LabelFn<number>;
    bottomHeight?: LabelFn<number>;
    waypoint?: LabelFn<string>;
}

export type PrismLabel = EntityLabel<PrismEditorLabelProviders>;
type PrismLabelKey = keyof NarrowIndexable<PrismEditorLabelProviders>;

export class PrismHeightPolyline extends EditPolyline {
    private _elevatedPositions: [Cartesian3, Cartesian3] | undefined;
    private _heightForElevatedPositions: number | undefined;

    constructor(
        private readonly parent: CartographicEditPointParentEntity,
        public readonly heightEntity: DraggableHeightEntity,
        startPosition: Cartesian3,
        endPosition: Cartesian3,
        polylineProps?: PolylineProps
    ) {
        super(heightEntity.parentEntityId, startPosition, endPosition, polylineProps);

        this._heightForElevatedPositions = heightEntity.height;
        this._elevatedPositions = [
            getElevatedCesiumPoint(startPosition, this._heightForElevatedPositions),
            getElevatedCesiumPoint(endPosition, this._heightForElevatedPositions),
        ];
    }

    public get entityId() {
        return this.heightEntity.parentEntityId;
    }

    public get props() {
        return (this.heightEntity.parent as EditablePrism).defaultPolylineProps;
    }

    public setStartPosition(position: Cartesian3): void {
        super.setStartPosition(position);

        this._heightForElevatedPositions = this.heightEntity.height;
        this._elevatedPositions = [
            getElevatedCesiumPoint(position, this._heightForElevatedPositions),
            getElevatedCesiumPoint(this.getEndPosition(), this._heightForElevatedPositions),
        ];
    }

    public setEndPosition(position: Cartesian3): void {
        super.setEndPosition(position);

        this._heightForElevatedPositions = this.heightEntity.height;
        this._elevatedPositions = [
            getElevatedCesiumPoint(this.getStartPosition(), this._heightForElevatedPositions),
            getElevatedCesiumPoint(position, this._heightForElevatedPositions),
        ];
    }

    public getElevatedPositionsCallbackProperty() {
        return new Cesium.CallbackProperty(() => {
            if (!this._elevatedPositions || this._heightForElevatedPositions !== this.heightEntity.height) {
                this._heightForElevatedPositions = this.heightEntity.height;
                this._elevatedPositions = [
                    getElevatedCesiumPoint(this.getStartPosition(), this._heightForElevatedPositions),
                    getElevatedCesiumPoint(this.getEndPosition(), this._heightForElevatedPositions),
                ];
            }

            return this._elevatedPositions;
        }, this.parent.isStaticEntity);
    }
}

export class EditablePrism extends AcEntity implements HeightEditable, HeightPointsProvider, CartographicEditPointParentEntity {
    private positions: CartographicEditPoint[] = [];
    private _polygonWaypoint: CartographicEditPoint | undefined;
    private polylines: PrismHeightPolyline[] = [];
    private _topHeightOutline: DraggableHeightEntity;
    private _bottomHeightOutline: DraggableHeightEntity;
    private movingPoint: CartographicEditPoint | undefined;
    private doneCreation = false;
    private isDisposed = false;
    private _enableEdit = true;
    private _prismProps!: PrismProps;
    private _defaultPointProps!: PointProps;
    private _defaultPolylineProps!: PolylineProps;
    private lastDraggedToPosition: Cartesian3 | undefined;
    private _labels: PrismLabel[] = [];
    private _hoverLabelId: PrismLabelKey | undefined = "waypoint";
    private _isValid = true;
    private readonly positionsHierarchy = new Cesium.PolygonHierarchy([]);
    private isPositionHierarchyValid = false;
    private _dynamicEntityTimeoutHandler?: ReturnType<typeof setTimeout>;

    public get isValid() {
        return this._isValid;
    }

    constructor(
        private id: string,
        private prismsLayer: AcLayerComponent,
        private pointsLayer: AcLayerComponent,
        private polygonWaypointLayer: AcLayerComponent,
        private heightOutlinesLayer: AcLayerComponent,
        private prismLabelsLayer: AcLayerComponent,
        private prismOptions: PrismEditOptions,
        topHeight: number,
        bottomHeight: number,
        positions?: Cartesian3[]
    ) {
        super();
        this.prismProps = { ...prismOptions.prismProps };
        this.defaultPointProps = { ...prismOptions.pointProps };
        this.defaultPolylineProps = { ...prismOptions.polylineProps };
        this._topHeightOutline = new DraggableHeightEntity(
            this,
            this,
            HeightEntityType.Top,
            topHeight,
            this.prismProps.areHeightsDraggable
        );
        this._bottomHeightOutline = new DraggableHeightEntity(
            this,
            this,
            HeightEntityType.Bottom,
            bottomHeight,
            this.prismProps.areHeightsDraggable
        );

        if (positions && positions.length >= MINIMUM_VERTEX_NUMBER) {
            this.createFromExisting(positions);
        }
    }

    public get isStaticEntity() {
        return this._dynamicEntityTimeoutHandler === undefined && this.doneCreation;
    }

    /**
     * Marks this entity as dynamic for up to one second since latest update for rendering performance reasons
     */
    public markAsDynamicEntityWhileUpdate() {
        if (this._dynamicEntityTimeoutHandler !== undefined) {
            clearTimeout(this._dynamicEntityTimeoutHandler);
        }

        this._dynamicEntityTimeoutHandler = setTimeout(() => {
            this._dynamicEntityTimeoutHandler = undefined;

            if (this.isDisposed) {
                return;
            }

            this.updatePointsLayer(true, ...this.positions);

            // NOTE: removing and adding is needed because simple update ignores CallbackProperty.isConstant change for <ac-polygon-desc>
            this.prismsLayer.remove(this.id);
            this.prismsLayer.update(this, this.id);
        }, MILLISECONDS_IN_SECOND);

        this.prismsLayer.remove(this.id);
        this.prismsLayer.update(this, this.id);
    }

    public get heightConstraints() {
        return this.prismProps;
    }

    public shouldShowLabel(label: PrismLabel) {
        return label.show && (this._hoverLabelId === label.id || (!this.doneCreation && label.id === "waypoint"));
    }

    public set hoverLabelId(value: PrismLabelKey | undefined) {
        this._hoverLabelId = value;
    }

    public get hoverLabelId() {
        return this._hoverLabelId;
    }

    public get labels(): PrismLabel[] {
        return this._labels;
    }

    public set labels(value: PrismLabel[]) {
        if (value && value.length === 0) {
            this._labels = [];

            return;
        }

        const positions = this.getRealPositions();

        if (!value || !positions?.length) {
            return;
        }

        this._labels = value.map((label) => {
            switch (label.id) {
                case "topHeight":
                    label.position = getElevatedCesiumPoint(positions[0], this._topHeightOutline.height);
                    break;
                case "bottomHeight":
                    label.position = getElevatedCesiumPoint(positions[0], this._bottomHeightOutline.height);
                    break;
                case "waypoint":
                    label.position = this._polygonWaypoint?.getPosition() ?? positions[0];
                    break;
            }

            label.show = this.shouldShowLabel(label);
            label.position ??= Cesium.Cartesian3.ZERO;

            return { ...DEFAULT_LABEL_PROPS, ...label };
        });
    }

    public get defaultPolylineProps(): PolylineProps {
        return this._defaultPolylineProps;
    }

    public set defaultPolylineProps(value: PolylineProps) {
        this._defaultPolylineProps = value;
    }

    public get prismProps(): PrismProps {
        return this._prismProps;
    }

    public set prismProps(value: PrismProps) {
        this._prismProps = value;
    }

    public get defaultPointProps(): PointProps {
        return this._defaultPointProps;
    }

    public set defaultPointProps(value: PointProps) {
        this._defaultPointProps = value;
    }

    public get enableEdit() {
        return this._enableEdit;
    }

    public set enableEdit(value: boolean) {
        this._enableEdit = value;
        this.positions.forEach((point) => {
            point.show = value;
        });
        this.updatePointsLayer(false, ...this.positions);
    }

    private createFromExisting(positions: Cartesian3[]) {
        positions.forEach((position) => {
            this.addPointFromExisting(position);
        });
        this.addAllVirtualEditPoints();
        this.updatePrismsLayer();
        this.updateHeightOutlinesLayer();
        this.doneCreation = true;
        this.markAsDynamicEntityWhileUpdate();
    }

    private isPoint(value: Cartesian3 | CesiumPointWithProps): value is CesiumPointWithProps {
        return !!(
            value as {
                pointProps: PointProps;
            }
        ).pointProps;
    }

    public setManually(
        points: CesiumPointWithProps[] | Cartesian3[],
        topHeight = 0,
        bottomHeight = 0,
        center?: Cartesian3,
        prismProps: PrismProps = this.prismProps
    ) {
        if (!this.doneCreation) {
            throw new Error("Update manually only in edit mode, after prism is created");
        }

        this.markAsDynamicEntityWhileUpdate();

        this.prismProps = { ...this.prismProps, ...prismProps };

        this.positions.forEach((point) => this.pointsLayer.remove(point.getId()));
        const newPoints: CartographicEditPoint[] = [];

        for (let i = 0; i < points.length; i++) {
            const pointOrCartesian = points[i];
            let newPoint = null;

            if (this.isPoint(pointOrCartesian)) {
                newPoint = new CartographicEditPoint(this, pointOrCartesian.position, pointOrCartesian.pointProps);
            } else {
                newPoint = new CartographicEditPoint(this, pointOrCartesian, this.defaultPointProps);
            }

            newPoints.push(newPoint);
        }

        this._topHeightOutline.setConstrainedHeight(topHeight);
        this._bottomHeightOutline.setConstrainedHeight(bottomHeight);

        if (center && this._polygonWaypoint) {
            const delta = GeoUtilsService.getPositionsDelta(this._polygonWaypoint.getPosition(), center);
            newPoints.forEach((point) => {
                const newPosition = GeoUtilsService.addDeltaToPosition(point.getPosition(), delta, true);
                point.setPosition(newPosition);
            });

            this._polygonWaypoint.setPosition(center);
        }

        this.positions = newPoints;
        this.updatePointsLayer(true, ...this.positions);
        this.addAllVirtualEditPoints();
        this.updatePrismsLayer();
        this.updateHeightOutlinesLayer();
    }

    private addAllVirtualEditPoints() {
        const currentPoints = [...this.positions];
        const midPoints: CartographicEditPoint[] = [];

        currentPoints.forEach((point, index) => {
            const currentPoint = point;
            const nextIndex = (index + 1) % currentPoints.length;
            const nextPoint = currentPoints[nextIndex];
            const midPoint = this.setMiddleVirtualPoint(currentPoint, nextPoint);
            midPoints.push(midPoint);
        });

        this.updatePointsLayer(false, ...midPoints);
    }

    private setMiddleVirtualPoint(firstPoint: CartographicEditPoint, secondPoint: CartographicEditPoint): CartographicEditPoint {
        const midPointCartesian3 = Cesium.Cartesian3.lerp(
            firstPoint.getPosition(),
            secondPoint.getPosition(),
            // eslint-disable-next-line no-magic-numbers
            0.5,
            new Cesium.Cartesian3()
        );

        const midPoint = new CartographicEditPoint(this, midPointCartesian3, this.defaultPointProps);
        midPoint.setVirtualEditPoint(true);

        const firstIndex = this.positions.indexOf(firstPoint);

        this.positions.splice(firstIndex + 1, 0, midPoint);

        return midPoint;
    }

    public getGroundPointForHeight() {
        return this.positions[0].getPosition();
    }

    private updateMiddleVirtualPoint(
        virtualEditPoint: CartographicEditPoint,
        prevPoint: CartographicEditPoint,
        nextPoint: CartographicEditPoint
    ) {
        // eslint-disable-next-line no-magic-numbers
        const midPointCartesian3 = Cesium.Cartesian3.lerp(prevPoint.getPosition(), nextPoint.getPosition(), 0.5, new Cesium.Cartesian3());
        virtualEditPoint.setPosition(midPointCartesian3);
    }

    public changeVirtualPointToRealPoint(point: CartographicEditPoint) {
        point.setVirtualEditPoint(false); // virtual point becomes a real point

        const pointsCount = this.positions.length;
        const pointIndex = this.positions.indexOf(point);
        const nextIndex = (pointIndex + 1) % pointsCount;
        const preIndex = (pointIndex - 1 + pointsCount) % pointsCount;

        const nextPoint = this.positions[nextIndex];
        const prePoint = this.positions[preIndex];

        const firstMidPoint = this.setMiddleVirtualPoint(prePoint, point);
        const secondMidPoint = this.setMiddleVirtualPoint(point, nextPoint);

        this.updatePointsLayer(true, firstMidPoint, secondMidPoint, point);
        this.updatePrismsLayer();
    }

    private updateHeightOutlinesLayer(points?: CartographicEditPoint[]) {
        this.isPositionHierarchyValid = false;

        this.polylines.forEach((polyline) => this.heightOutlinesLayer.remove(polyline.getId()));
        this.polylines = [];

        const realPoints = points ?? this.positions.filter((position) => !position.isVirtualEditPoint());

        realPoints.forEach((point, index) => {
            const nextIndex = (index + 1) % realPoints.length;
            const nextPoint = realPoints[nextIndex];
            const bottomPolyline = new PrismHeightPolyline(
                this,
                this._bottomHeightOutline,
                point.getPosition(),
                nextPoint.getPosition(),
                this.defaultPolylineProps
            );
            const topPolyline = new PrismHeightPolyline(
                this,
                this._topHeightOutline,
                point.getPosition(),
                nextPoint.getPosition(),
                this.defaultPolylineProps
            );

            this.polylines.push(bottomPolyline, topPolyline);
            this.heightOutlinesLayer.update(bottomPolyline, bottomPolyline.getId());
            this.heightOutlinesLayer.update(topPolyline, topPolyline.getId());
        });
    }

    public addPointFromExisting(position: Cartesian3) {
        const newPoint = new CartographicEditPoint(this, position, this.defaultPointProps);

        this.positions.push(newPoint);
        this.updatePointsLayer(true, newPoint);
    }

    public addPoint(position: Cartesian3) {
        if (this.doneCreation) {
            return;
        }

        const isFirstPoint = !this.positions.length;

        if (isFirstPoint) {
            const firstPoint = new CartographicEditPoint(this, position, this.defaultPointProps);
            this.positions.push(firstPoint);
            this.updatePointsLayer(true, firstPoint);
        }

        this.movingPoint = new CartographicEditPoint(this, position, this.defaultPointProps);
        this.positions.push(this.movingPoint);

        this.updatePointsLayer(true, this.movingPoint);
        this.updatePrismsLayer();
        this.updateHeightOutlinesLayer();
    }

    public movePointFinish(editPoint: CartographicEditPoint) {
        if (this.prismOptions.clampHeightTo3D) {
            editPoint.props.disableDepthTestDistance = Number.POSITIVE_INFINITY;
            this.updatePointsLayer(false, editPoint);
        }
    }

    public updateSelfIntersectionsValidity(): void {
        this.checkSelfIntersections();
    }

    private checkSelfIntersections() {
        const truePositions = this.positions
            .filter((editPoint) => !editPoint.isVirtualEditPoint())
            .map((editPoint): Cartesian3 => editPoint.getPosition())
            .filter((editPosition, index, allPoints) => {
                for (let pointIndex = index; pointIndex > 0; pointIndex--) {
                    if (editPosition.equals(allPoints[pointIndex - 1])) {
                        return false;
                    }
                }

                return true;
            })
            .map((editPosition): Position => {
                const { x, y, z } = editPosition;

                return [x, y, z];
            });

        this._isValid = checkPolygonEdgesIntersections(truePositions);
    }

    private checkSelfIntersectionsWithoutSpecficPoint(pointToRemove: CartographicEditPoint) {
        const truePositions = this.positions
            .filter((editPoint) => !editPoint.isVirtualEditPoint() && editPoint !== pointToRemove)
            .map((editPoint): Position => {
                const { x, y, z } = editPoint.getPosition();

                return [x, y, z];
            });

        return checkPolygonEdgesIntersections(truePositions);
    }

    public movePoint(toPosition: Cartesian3, editPoint: CartographicEditPoint) {
        editPoint.setPosition(toPosition);
        this.checkSelfIntersections();

        if (!this.doneCreation) {
            this.updatePrismsLayer();
            this.updatePointsLayer(true, editPoint);

            return;
        }

        if (editPoint.props.disableDepthTestDistance && this.prismOptions.clampHeightTo3D) {
            editPoint.props.disableDepthTestDistance = undefined;

            return;
        }

        if (editPoint.isVirtualEditPoint()) {
            this.changeVirtualPointToRealPoint(editPoint);
        }

        const pointsCount = this.positions.length;
        const pointIndex = this.positions.indexOf(editPoint);
        const nextVirtualPoint = this.positions[(pointIndex + 1) % pointsCount];
        const nextRealPoint = this.positions[(pointIndex + 2) % pointsCount];
        const prevVirtualPoint = this.positions[(pointIndex - 1 + pointsCount) % pointsCount];
        const prevRealPoint = this.positions[(pointIndex - 2 + pointsCount) % pointsCount];
        this.updateMiddleVirtualPoint(nextVirtualPoint, editPoint, nextRealPoint);
        this.updateMiddleVirtualPoint(prevVirtualPoint, editPoint, prevRealPoint);

        this.updatePrismsLayer();
        this.updatePointsLayer(true, editPoint);
    }

    public moveTempMovingPoint(toPosition: Cartesian3) {
        if (this.movingPoint) {
            this.movePoint(toPosition, this.movingPoint);
        } else {
            this.movingPoint = new CartographicEditPoint(this, toPosition, this.defaultPointProps);
            this.positions.push(this.movingPoint);
        }
    }

    public removeTempMovingPoint() {
        if (this.movingPoint) {
            this.removePosition(this.movingPoint);
            this.movingPoint = undefined;
            this.updatePrismsLayer();
            this.updatePointsLayer(true);
        }
    }

    public movePrism(startMovingPosition: Cartesian3, draggedToPosition: Cartesian3) {
        if (!this.doneCreation) {
            return;
        }

        if (!this.lastDraggedToPosition) {
            this.lastDraggedToPosition = startMovingPosition;
        }

        const delta = GeoUtilsService.getPositionsDelta(this.lastDraggedToPosition, draggedToPosition);
        this.positions.forEach((point) => {
            const newPosition = GeoUtilsService.addDeltaToPosition(point.getPosition(), delta, true);
            point.setPosition(newPosition);
        });

        if (this._polygonWaypoint) {
            const newPosition = GeoUtilsService.addDeltaToPosition(this._polygonWaypoint.getPosition(), delta, true);
            this._polygonWaypoint.setPosition(newPosition);
        }

        this.lastDraggedToPosition = draggedToPosition;
        this.updatePointsLayer(true, ...this.positions);
    }

    public endMovePrism() {
        this.lastDraggedToPosition = undefined;
        this.updatePointsLayer(true, ...this.positions);
    }

    public moveTopHeight(newHeight: number) {
        this._topHeightOutline.setConstrainedHeight(newHeight);

        this.updatePointsLayer(true, ...this.positions);
        this.updateHeightOutlinesLayer();
    }

    public moveBottomHeight(newHeight: number) {
        this._bottomHeightOutline.setConstrainedHeight(newHeight);

        this.updatePointsLayer(true, ...this.positions);
        this.updateHeightOutlinesLayer();
    }

    public tryRemovePoint(pointToRemove: CartographicEditPoint, shouldRefreshVirtualPoints: boolean) {
        if (!this.checkSelfIntersectionsWithoutSpecficPoint(pointToRemove)) {
            return;
        }

        this.removePosition(pointToRemove);
        this.positions.filter((point) => point.isVirtualEditPoint()).forEach((point) => this.removePosition(point));

        if (shouldRefreshVirtualPoints) {
            this.addAllVirtualEditPoints();
        }

        this.updateHeightOutlinesLayer();
        this.updatePrismsLayer();
        this.refreshPolygonWaypoint(this.getRealPoints());
    }

    public finishCreation() {
        this.doneCreation = true;
        this.removePosition(this.movingPoint);
        this.movingPoint = undefined;
        this.updatePrismsLayer();

        this.addAllVirtualEditPoints();
        this.updateHeightOutlinesLayer();
        this.markAsDynamicEntityWhileUpdate();
    }

    public getRealPositions(): Cartesian3[] {
        return this.getRealPoints().map((position) => position.getPosition());
    }

    public getRealPoints(): CartographicEditPoint[] {
        return this.positions.filter((position) => !position.isVirtualEditPoint() && position !== this.movingPoint);
    }

    public getPoints(): CartographicEditPoint[] {
        return this.positions.filter((position) => position !== this.movingPoint);
    }

    public getTopHeight(): number {
        return this._topHeightOutline.height;
    }

    public getBottomHeight(): number {
        return this._bottomHeightOutline.height;
    }

    public getPolygonWaypoint() {
        return this._polygonWaypoint;
    }

    public getTopHeightCallbackProperty() {
        return new Cesium.CallbackProperty(this.getTopHeight.bind(this), this.isStaticEntity);
    }

    public getBottomHeightCallbackProperty() {
        return new Cesium.CallbackProperty(this.getBottomHeight.bind(this), this.isStaticEntity);
    }

    private recalculatePositionsHierarchy() {
        const positions = this.positions
            .filter((position) => !position.isVirtualEditPoint())
            .map((position) => position.getPosition().clone());

        this.positionsHierarchy.positions = positions;
    }

    public getPositionsHierarchyCallbackProperty() {
        return new Cesium.CallbackProperty(() => {
            if (!this.isPositionHierarchyValid) {
                this.recalculatePositionsHierarchy();
                this.isPositionHierarchyValid = true;
            }

            return this.positionsHierarchy;
        }, this.isStaticEntity);
    }

    public getStatus(): EntityStatus {
        const realPoints = this.getRealPoints();

        return {
            isFinished: this.doneCreation,
            canFinish: realPoints.length >= MINIMUM_VERTEX_NUMBER,
            canRemovePreviousPoint: realPoints.length > 1,
        };
    }

    public get isValidPolygon() {
        // NOTE: this workaround is needed because of https://github.com/CesiumGS/cesium/issues/7950 issue
        // we allow drawing polygon graphics only when there are minimum 3 vertices (valid polygons)
        return this.getRealPointsCount() >= MINIMUM_VERTEX_NUMBER;
    }

    private getMaterialCallbackProperty() {
        return this._isValid ? this.prismProps.material : this.prismProps.invalidMaterial;
    }

    public getMaterial(): unknown {
        const materialProperty = new Cesium.ColorMaterialProperty();
        materialProperty.color = new Cesium.CallbackProperty(this.getMaterialCallbackProperty.bind(this), this.isStaticEntity);

        return materialProperty;
    }

    private removePosition(point: CartographicEditPoint | undefined) {
        const index = this.positions.findIndex((position) => position === point);
        if (!point || index < 0) {
            return;
        }

        this.positions.splice(index, 1);
        this.pointsLayer.remove(point.getId());
    }

    private updatePrismsLayer() {
        this.isPositionHierarchyValid = false;

        if (this.getRealPointsCount() >= MINIMUM_VERTEX_NUMBER) {
            this.prismsLayer.update(this, this.id);
        }
    }

    private updatePointsLayer(renderPolylines: boolean, ...points: CartographicEditPoint[]) {
        this.isPositionHierarchyValid = false;

        points.forEach((point) => {
            this.pointsLayer.update(point, point.getId());
        });

        const polygonPoints = this.getRealPoints();

        if (!this.doneCreation && this.movingPoint) {
            polygonPoints.push(this.movingPoint);
        }

        if (renderPolylines) {
            this.updateHeightOutlinesLayer(polygonPoints);
        }

        if (
            polygonPoints.length < MINIMUM_VERTEX_NUMBER ||
            polygonPoints[polygonPoints.length - 2].getPosition().equals(polygonPoints[polygonPoints.length - 1].getPosition())
        ) {
            return;
        }

        this.refreshPolygonWaypoint(polygonPoints);
    }

    private refreshPolygonWaypoint(points: CartographicEditPoint[]) {
        this.isPositionHierarchyValid = false;

        const visualCenter = getPolygonVisualCenter(points.map((point) => point.getCartographic()));
        const position = convertSerializableCartographicToCartesian3(visualCenter);

        if (this._polygonWaypoint) {
            this._polygonWaypoint.setPosition(position);
        } else {
            this._polygonWaypoint = new CartographicEditPoint(this, position, this.defaultPointProps);
        }

        this.polygonWaypointLayer.update(this._polygonWaypoint, this._polygonWaypoint.getId());
    }

    public dispose() {
        this.isDisposed = true;

        this.positions.forEach((editPoint) => {
            this.pointsLayer.remove(editPoint.getId());
        });
        this.polylines.forEach((line) => this.heightOutlinesLayer.remove(line.getId()));

        if (this.movingPoint) {
            this.pointsLayer.remove(this.movingPoint.getId());
            this.movingPoint = undefined;
        }

        if (this._polygonWaypoint) {
            this.polygonWaypointLayer.remove(this._polygonWaypoint.getId());
            this._polygonWaypoint = undefined;
        }

        this.prismsLayer.remove(this.id);
        this.prismLabelsLayer.remove(this.id);
        this.positions.length = 0;
        this.isPositionHierarchyValid = false;
    }

    public getRealPointsCount(): number {
        return ArrayUtils.unique(
            this.positions.filter((position) => !position.isVirtualEditPoint()),
            (item) => item.getPosition().toString()
        ).length;
    }

    public getId() {
        return this.id;
    }
}
