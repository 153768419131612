<ng-container *ngIf="restrictionExclusions$ | ngrxPush; let restrictionExclusions; else: cannotGetSummaryErrorTemplate">
    <ng-container *ngIf="restrictionAreaGeometry$ | ngrxPush; let restrictionAreaGeometry">
        <ng-container *ngIf="basicDataForm$ | ngrxPush; let basicData; else: cannotGetSummaryErrorTemplate">
            <ng-container *ngIf="analysisStatus$ | ngrxPush; let analysisStatus; else: cannotGetSummaryErrorTemplate">
                <section class="summary-section">
                    <h4 class="summary-section-header">
                        {{ "dssSharedLibFlightZone.applicationSummary.basicDataHeader" | transloco }}
                        <button
                            *ngIf="isEditBasicDataButtonVisible$ | ngrxPush"
                            type="button"
                            class="button-secondary"
                            (click)="editBasicDataSection.emit()"
                        >
                            <dtm-ui-icon name="edit"></dtm-ui-icon>
                            {{ "dssSharedLibFlightZone.applicationSummary.editSectionButtonLabel" | transloco }}
                        </button>
                        <button
                            *ngIf="shouldShowModificationEditButton$ | ngrxPush"
                            type="button"
                            class="button-secondary edit-modification"
                            (click)="editRestrictionModification.emit()"
                        >
                            <dtm-ui-icon name="edit"></dtm-ui-icon>
                            {{ "dssSharedLibFlightZone.applicationSummary.editRestrictionModificationButtonLabel" | transloco }}
                        </button>
                    </h4>
                    <dtm-ui-label-value
                        class="summary-section-item"
                        [label]="'dssSharedLibFlightZone.applicationSummary.titleLabel' | transloco"
                        [value]="basicData.title"
                    >
                    </dtm-ui-label-value>

                    <dtm-ui-label-value
                        class="summary-section-item"
                        [label]="'dssSharedLibFlightZone.applicationSummary.flightZonePurposeLabel' | transloco"
                        [value]="
                            'dssSharedLibFlightZone.flightZoneApplicationPurposes.purposeLabel'
                                | transloco : { value: basicData.flightZonePurpose }
                        "
                    >
                    </dtm-ui-label-value>

                    <dtm-ui-label-value
                        *ngIf="basicData.caseNumber"
                        class="summary-section-item"
                        [label]="'dssSharedLibFlightZone.applicationSummary.caseNumberLabel' | transloco"
                        [value]="basicData.caseNumber"
                    >
                    </dtm-ui-label-value>

                    <dtm-ui-label-value
                        *ngIf="basicData.hasStateSecurityRestriction"
                        class="summary-section-item"
                        [label]="'dssSharedLibFlightZone.applicationSummary.stateSecurityRestrictionLabel' | transloco"
                        [value]="'dssSharedLibFlightZone.applicationSummary.booleanValue' | transloco : { value: true }"
                    >
                    </dtm-ui-label-value>

                    <ng-container *ngrxLet="restrictionModificationData$ as restrictionModificationData">
                        <dtm-ui-label-value
                            class="summary-section-item"
                            [label]="'dssSharedLibFlightZone.applicationSummary.startTimeLabel' | transloco"
                        >
                            <div class="item-content">
                                <dss-shared-lib-utc-date-display [date]="basicData.startTime"></dss-shared-lib-utc-date-display>

                                <dss-shared-lib-utc-date-display
                                    *ngIf="restrictionModificationData?.startAt | invoke : hasModificationDateChanged : basicData.startTime"
                                    [date]="restrictionModificationData?.startAt"
                                ></dss-shared-lib-utc-date-display>
                            </div>
                        </dtm-ui-label-value>
                        <dtm-ui-label-value
                            class="summary-section-item"
                            [label]="'dssSharedLibFlightZone.applicationSummary.endTimeLabel' | transloco"
                        >
                            <div class="item-content">
                                <dss-shared-lib-utc-date-display [date]="basicData.endTime"></dss-shared-lib-utc-date-display>

                                <dss-shared-lib-utc-date-display
                                    *ngIf="restrictionModificationData?.endAt | invoke : hasModificationDateChanged : basicData.endTime"
                                    [date]="restrictionModificationData?.endAt"
                                ></dss-shared-lib-utc-date-display>
                            </div>
                        </dtm-ui-label-value>
                    </ng-container>

                    <dtm-ui-label-value
                        class="summary-section-item"
                        [label]="'dssSharedLibFlightZone.applicationSummary.preTacticalApprovalLabel' | transloco"
                        [value]="
                            'dssSharedLibFlightZone.applicationSummary.booleanValue'
                                | transloco : { value: basicData.isPreTacticalApproval }
                        "
                    ></dtm-ui-label-value>
                </section>

                <dss-shared-lib-text-editor-section
                    isReadonly
                    class="description-section"
                    [text]="basicData.description"
                    (dialogOpen)="openDescriptionPreviewDialog($event)"
                >
                    <h4 sectionTitle>{{ "dssSharedLibFlightZone.applicationSummary.additionalInfoHeader" | transloco }}</h4>
                    <button
                        sectionActions
                        *ngIf="isEditBasicDataButtonVisible$ | ngrxPush"
                        type="button"
                        class="button-secondary"
                        (click)="editBasicDataSection.emit()"
                    >
                        <dtm-ui-icon name="edit"></dtm-ui-icon>
                        {{ "dssSharedLibFlightZone.applicationSummary.editSectionButtonLabel" | transloco }}
                    </button>
                </dss-shared-lib-text-editor-section>

                <section class="summary-section">
                    <h4 class="summary-section-header">
                        {{ "dssSharedLibFlightZone.applicationSummary.zoneGeometryHeader" | transloco }}
                        <button
                            *ngIf="isEditGeometryButtonVisible$ | ngrxPush"
                            type="button"
                            class="button-secondary"
                            (click)="editGeometrySection.emit()"
                        >
                            <dtm-ui-icon name="edit"></dtm-ui-icon>
                            {{ "dssSharedLibFlightZone.applicationSummary.editSectionButtonLabel" | transloco }}
                        </button>
                    </h4>

                    <ng-container *ngIf="restrictionAreaGeometry | invoke : getCylinderGeometry; let cylinderGeometry">
                        <dtm-ui-label-value
                            class="summary-section-item"
                            [label]="'dssSharedLibFlightZone.applicationSummary.centerPointLabel' | transloco"
                        >
                            <dtm-ui-dms-display
                                [decimalLatitude]="cylinderGeometry.areaValues?.centerPointLatitude"
                                [decimalLongitude]="cylinderGeometry.areaValues?.centerPointLongitude"
                            ></dtm-ui-dms-display>
                        </dtm-ui-label-value>

                        <dtm-ui-label-value
                            class="summary-section-item"
                            [label]="'dssSharedLibFlightZone.applicationSummary.radiusLabel' | transloco"
                        >
                            {{ cylinderGeometry.areaValues?.radius }} {{ cylinderGeometry.areaUnits.radiusUnit }}
                        </dtm-ui-label-value>
                    </ng-container>

                    <ng-container *ngIf="restrictionAreaGeometry | invoke : getPrismGeometry; let prismGeometry">
                        <dtm-ui-label-value
                            *ngIf="prismGeometry.areaValues?.positions; let positions"
                            class="summary-section-item"
                            [label]="'dssSharedLibFlightZone.applicationSummary.positionsLabel' | transloco"
                        >
                            <ol class="item-content ordered-content">
                                <li *ngFor="let position of positions">
                                    <dtm-ui-dms-display
                                        [decimalLatitude]="position.latitude"
                                        [decimalLongitude]="position.longitude"
                                    ></dtm-ui-dms-display>
                                </li>
                            </ol>
                            <ol class="item-content ordered-content">
                                <li>
                                    <dtm-ui-dms-display
                                        [decimalLatitude]="positions[0].latitude"
                                        [decimalLongitude]="positions[0].longitude"
                                    ></dtm-ui-dms-display>
                                </li>
                            </ol>
                        </dtm-ui-label-value>
                    </ng-container>

                    <dtm-ui-label-value
                        class="summary-section-item"
                        [label]="'dssSharedLibFlightZone.applicationSummary.lowerHeightLabel' | transloco"
                        [ngSwitch]="true"
                    >
                        <div
                            class="item-content"
                            *ngSwitchCase="
                                basicData.flightZonePurpose === FlightZoneApplicationPurpose.ForbiddingUAVAndGeneralAviationFlights ||
                                basicData.flightZonePurpose === FlightZoneApplicationPurpose.SecuringOwnFlights
                            "
                        >
                            <ng-template
                                [ngTemplateOutlet]="applicationAndNotamHeightsTemplate"
                                [ngTemplateOutletContext]="{
                                    value: restrictionAreaGeometry.areaValues?.lowerHeight,
                                    unit: restrictionAreaGeometry.areaUnits.lowerHeightUnit,
                                    referenceLevel: restrictionAreaGeometry.areaUnits.lowerHeightReference,
                                    altitude:
                                        analysisStatus.altitudesConvertedToAMSLFeet.lowerAltitude ??
                                        restrictionAreaGeometry.areaValues?.lowerHeight
                                }"
                            ></ng-template>
                        </div>
                        <div class="item-content" *ngSwitchDefault>
                            {{
                                restrictionAreaGeometry.areaValues?.lowerHeight
                                    ? ("dssSharedLibFlightZone.applicationSummary.altitudeValue"
                                      | transloco
                                          : {
                                                value: restrictionAreaGeometry.areaValues?.lowerHeight,
                                                unit: restrictionAreaGeometry.areaUnits.lowerHeightUnit,
                                                heightReference: restrictionAreaGeometry.areaUnits.lowerHeightReference
                                            })
                                    : ("dssSharedLibFlightZone.applicationSummary.groundAltitudeLabel" | transloco)
                            }}
                            <span
                                *ngIf="
                                    (restrictionAreaGeometry.areaUnits.lowerHeightUnit !== VerticalMeasureUnits.Feet ||
                                        restrictionAreaGeometry.areaUnits.lowerHeightReference !== HeightReferences.AboveMeanSeaLevel) &&
                                    analysisStatus.altitudesConvertedToAMSLFeet.lowerAltitude
                                "
                            >
                                /
                                {{
                                    "dssSharedLibFlightZone.applicationSummary.altitudeValue"
                                        | transloco
                                            : {
                                                  value: analysisStatus.altitudesConvertedToAMSLFeet.lowerAltitude,
                                                  unit: VerticalMeasureUnits.Feet,
                                                  heightReference: HeightReferences.AboveMeanSeaLevel
                                              }
                                }}
                            </span>
                        </div>
                    </dtm-ui-label-value>

                    <dtm-ui-label-value
                        class="summary-section-item"
                        [label]="'dssSharedLibFlightZone.applicationSummary.upperHeightLabel' | transloco"
                        [ngSwitch]="true"
                    >
                        <div
                            class="item-content"
                            *ngSwitchCase="
                                basicData.flightZonePurpose === FlightZoneApplicationPurpose.ForbiddingUAVAndGeneralAviationFlights ||
                                basicData.flightZonePurpose === FlightZoneApplicationPurpose.SecuringOwnFlights
                            "
                        >
                            <ng-template
                                [ngTemplateOutlet]="applicationAndNotamHeightsTemplate"
                                [ngTemplateOutletContext]="{
                                    value: restrictionAreaGeometry.areaValues?.upperHeight,
                                    unit: restrictionAreaGeometry.areaUnits.upperHeightUnit,
                                    referenceLevel: restrictionAreaGeometry.areaUnits.upperHeightReference,
                                    altitude:
                                        analysisStatus.altitudesConvertedToAMSLFeet.upperAltitude ??
                                        restrictionAreaGeometry.areaValues?.upperHeight
                                }"
                            ></ng-template>
                        </div>
                        <div class="item-content" *ngSwitchDefault>
                            {{
                                restrictionAreaGeometry.areaValues?.upperHeight
                                    ? ("dssSharedLibFlightZone.applicationSummary.altitudeValue"
                                      | transloco
                                          : {
                                                value: restrictionAreaGeometry.areaValues?.upperHeight,
                                                unit: restrictionAreaGeometry.areaUnits.upperHeightUnit,
                                                heightReference: restrictionAreaGeometry.areaUnits.upperHeightReference
                                            })
                                    : ("dssSharedLibFlightZone.applicationSummary.groundAltitudeLabel" | transloco)
                            }}
                            <span *ngIf="analysisStatus.altitudesConvertedToAMSLFeet.upperAltitude as upperAltitude">
                                /
                                {{
                                    "dssSharedLibFlightZone.applicationSummary.altitudeValue"
                                        | transloco
                                            : {
                                                  value: upperAltitude,
                                                  unit: VerticalMeasureUnits.Feet,
                                                  heightReference: HeightReferences.AboveMeanSeaLevel
                                              }
                                }}
                            </span>
                        </div>
                    </dtm-ui-label-value>

                    <ng-template
                        #applicationAndNotamHeightsTemplate
                        let-value="value"
                        let-unit="unit"
                        let-heightReference="referenceLevel"
                        let-altitude="altitude"
                    >
                        <div class="item-content">
                            {{ "dssSharedLibFlightZone.applicationSummary.heightFromApplicationLabel" | transloco }}
                            {{
                                value
                                    ? ("dssSharedLibFlightZone.applicationSummary.altitudeValue"
                                      | transloco : { value, unit, heightReference })
                                    : ("dssSharedLibFlightZone.applicationSummary.groundAltitudeLabel" | transloco)
                            }}
                            <br />
                            {{ "dssSharedLibFlightZone.applicationSummary.heightToNotamLabel" | transloco }}
                            {{
                                value
                                    ? ("dssSharedLibFlightZone.applicationSummary.altitudeValue"
                                      | transloco
                                          : {
                                                value: altitude,
                                                unit: VerticalMeasureUnits.Feet,
                                                heightReference: HeightReferences.AboveMeanSeaLevel
                                            })
                                    : ("dssSharedLibFlightZone.applicationSummary.groundAltitudeLabel" | transloco)
                            }}
                        </div>
                    </ng-template>

                    <dtm-ui-label-value
                        *ngIf="analysisStatus.designator; else suggestedRestrictionTypeTemplate"
                        class="summary-section-item"
                        [label]="'dssSharedLibFlightZone.applicationSummary.designatorLabel' | transloco"
                        [value]="analysisStatus.designator"
                    >
                    </dtm-ui-label-value>

                    <ng-template #suggestedRestrictionTypeTemplate>
                        <div class="summary-section-item item-with-button">
                            <dtm-ui-label-value
                                *ngIf="!!basicData?.customDesignator; else suggestedTypeTemplate"
                                [label]="'dssSharedLibFlightZone.applicationSummary.customDesignatorLabel' | transloco"
                                [labelHint]="'dssSharedLibFlightZone.applicationSummary.customDesignatorLabelHint' | transloco"
                            >
                                <div>{{ basicData.customDesignator }}</div>
                                <ng-container *ngTemplateOutlet="designatorActionsTemplate"></ng-container>
                            </dtm-ui-label-value>

                            <ng-template #suggestedTypeTemplate>
                                <dtm-ui-label-value
                                    [label]="'dssSharedLibFlightZone.applicationSummary.suggestedRestrictionTypeLabel' | transloco"
                                >
                                    <div>
                                        {{
                                            "dssSharedLibFlightZone.applicationSummary.designatorTypeValueLabel"
                                                | transloco : { value: analysisStatus.suggestedRestrictionType }
                                        }}
                                    </div>
                                    <ng-container *ngTemplateOutlet="designatorActionsTemplate"></ng-container>
                                </dtm-ui-label-value>
                            </ng-template>

                            <ng-template #designatorActionsTemplate>
                                <div class="designator-actions">
                                    <button
                                        *ngIf="isEditSuggestedRestrictionTypeButtonVisible$ | ngrxPush"
                                        type="button"
                                        class="button-secondary"
                                        (click)="editSuggestedRestrictionType.emit()"
                                    >
                                        {{
                                            "dssSharedLibFlightZone.applicationSummary.editSuggestedRestrictionTypeButtonLabel" | transloco
                                        }}
                                    </button>
                                    <button
                                        *ngIf="isSetCustomDesignatorButtonVisible$ | ngrxPush"
                                        type="button"
                                        class="button-secondary"
                                        (click)="customDesignatorSet.emit()"
                                    >
                                        {{ "dssSharedLibFlightZone.applicationSummary.setCustomDesignatorButtonLabel" | transloco }}
                                    </button>
                                    <button
                                        *ngIf="isRemoveCustomDesignatorButtonVisible$ | ngrxPush"
                                        type="button"
                                        class="button-secondary"
                                        (click)="customDesignatorRemove.emit()"
                                    >
                                        {{ "dssSharedLibFlightZone.applicationSummary.removeCustomDesignatorButtonLabel" | transloco }}
                                    </button>
                                </div>
                            </ng-template>
                        </div>
                    </ng-template>

                    <dtm-ui-label-value
                        *ngIf="analysisStatus.notamNumber"
                        class="summary-section-item"
                        [label]="'dssSharedLibFlightZone.applicationSummary.notamNumberLabel' | transloco"
                        [value]="analysisStatus.notamNumber"
                    >
                    </dtm-ui-label-value>
                </section>

                <section class="summary-section">
                    <h4 class="summary-section-header">
                        {{ "dssSharedLibFlightZone.applicationSummary.zoneRestrictionExecutionsHeader" | transloco }}
                        <button
                            *ngIf="isEditExclusionsButtonVisible$ | ngrxPush"
                            type="button"
                            class="button-secondary"
                            (click)="editExclusionsSection.emit()"
                        >
                            <dtm-ui-icon name="edit"></dtm-ui-icon>
                            {{ "dssSharedLibFlightZone.applicationSummary.editSectionButtonLabel" | transloco }}
                        </button>
                    </h4>
                    <dtm-ui-label-value
                        *ngIf="restrictionExclusions | invoke : areRestrictionExclusionsDefined; else emptyRestrictionsExclusionsTemplate"
                        class="summary-section-item"
                        [label]="'dssSharedLibFlightZone.applicationSummary.zoneRestrictionExecutionsLabel' | transloco"
                    >
                        <ng-container [ngSwitch]="restrictionExclusions.purpose">
                            <dss-shared-lib-uav-exclusions-summary
                                *ngSwitchCase="FlightZoneApplicationPurpose.ForbiddingUAVFlights"
                                [restrictionExclusions]="restrictionExclusions"
                            ></dss-shared-lib-uav-exclusions-summary>
                            <dss-shared-lib-general-aviation-exclusions-summary
                                *ngSwitchCase="FlightZoneApplicationPurpose.ForbiddingUAVAndGeneralAviationFlights"
                                [restrictionExclusions]="restrictionExclusions"
                            ></dss-shared-lib-general-aviation-exclusions-summary>
                            <dss-shared-lib-securing-own-flights-exclusions-summary
                                *ngSwitchCase="FlightZoneApplicationPurpose.SecuringOwnFlights"
                                [restrictionExclusions]="restrictionExclusions"
                            ></dss-shared-lib-securing-own-flights-exclusions-summary>
                        </ng-container>
                    </dtm-ui-label-value>

                    <ng-template #emptyRestrictionsExclusionsTemplate>
                        <dtm-ui-label-value
                            class="summary-section-item"
                            [label]="'dssSharedLibFlightZone.applicationSummary.emptyRestrictionsExclusionsLabel' | transloco"
                        >
                        </dtm-ui-label-value>
                    </ng-template>
                </section>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #cannotGetSummaryErrorTemplate>
    <div class="summary-section">
        <div class="summary-section-item">
            {{ "dssSharedLibFlightZone.applicationSummary.cannotGetSummaryErrorMessage" | transloco }}
        </div>
    </div>
</ng-template>
