<ng-container *ngrxLet="restrictions$ as restrictions">
    <ng-container *ngrxLet="displayedColumns$ as displayedColumns">
        <dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
            <table
                mat-table
                multiTemplateDataRows
                [dataSource]="restrictions"
                *ngrxLet="expandedElement$ as expandedElement"
                class="dtm-table"
            >
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssClientLibFlightZone.applicationListShared.titleHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.title }}</td>
                </ng-container>

                <ng-container matColumnDef="notam">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssClientLibFlightZone.applicationListShared.notamHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.notam }}</td>
                </ng-container>

                <ng-container matColumnDef="zoneNumber">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssClientLibFlightZone.applicationListShared.zoneNumberHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.zoneNumber }}</td>
                </ng-container>

                <ng-container matColumnDef="applicationPurpose">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssClientLibFlightZone.applicationListShared.purposeHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <dtm-ui-popover
                            *ngIf="element.isStateSecurityRestriction"
                            [popoverText]="'dssClientLibFlightZone.applicationListShared.stateSecurityRestrictionHint' | transloco"
                            (click)="$event.stopPropagation()"
                        ></dtm-ui-popover>
                        {{
                            "dssSharedLibFlightZone.flightZoneApplicationPurposes.purposeLabel"
                                | transloco : { value: element.applicationPurpose }
                        }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="startAt">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssClientLibFlightZone.applicationListShared.startAtHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <dss-shared-lib-utc-date-display [date]="element.startAt"></dss-shared-lib-utc-date-display>

                        <ng-container *ngIf="element.modifyingStartAt | localizeDate : localizeDateConfig as modifyingStartAt">
                            <dtm-ui-popover
                                *ngIf="modifyingStartAt !== (element.startAt | localizeDate : localizeDateConfig)"
                                popoverIcon="refresh"
                                [popoverText]="
                                    'dssClientLibFlightZone.applicationListShared.isBeingModifiedHint'
                                        | transloco : { value: modifyingStartAt }
                                "
                                (click)="$event.stopPropagation()"
                            ></dtm-ui-popover>
                        </ng-container>

                        <dtm-ui-popover
                            *ngIf="element.isBeingCancelled"
                            popoverIcon="forbid"
                            [popoverText]="'dssClientLibFlightZone.applicationListShared.isBeingCancelledHint' | transloco"
                            (click)="$event.stopPropagation()"
                        ></dtm-ui-popover>
                    </td>
                </ng-container>

                <ng-container matColumnDef="endAt">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssClientLibFlightZone.applicationListShared.endAtHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <dss-shared-lib-utc-date-display [date]="element.endAt"></dss-shared-lib-utc-date-display>

                        <ng-container *ngIf="element.modifyingEndAt | localizeDate : localizeDateConfig as modifyingEndAt">
                            <dtm-ui-popover
                                *ngIf="modifyingEndAt !== (element.endAt | localizeDate : localizeDateConfig)"
                                popoverIcon="refresh"
                                [popoverText]="
                                    'dssClientLibFlightZone.applicationListShared.isBeingModifiedHint'
                                        | transloco : { value: modifyingEndAt }
                                "
                                (click)="$event.stopPropagation()"
                            ></dtm-ui-popover>
                        </ng-container>

                        <dtm-ui-popover
                            *ngIf="element.isBeingCancelled"
                            popoverIcon="forbid"
                            [popoverText]="'dssClientLibFlightZone.applicationListShared.isBeingCancelledHint' | transloco"
                            (click)="$event.stopPropagation()"
                        ></dtm-ui-popover>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandHandle">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button type="button" class="button-icon" (click)="$event.stopPropagation(); toggleExpandableRow(element)">
                            <dtm-ui-icon
                                name="arrow-right"
                                class="expand-handle"
                                [class.expanded]="element === expandedElement"
                            ></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div class="expanded-row-content" *ngIf="element === expandedElement" @slideIn>
                            <dtm-ui-label-value
                                class="sub-cell"
                                *ngIf="!!element.caseNumber"
                                [label]="'dssClientLibFlightZone.applicationListShared.caseNumberHeader' | transloco"
                                [value]="element.caseNumber"
                            ></dtm-ui-label-value>
                            <dtm-ui-label-value
                                class="sub-cell"
                                *ngIf="!!element.author.fullName"
                                [label]="'dssClientLibFlightZone.applicationListShared.authorHeader' | transloco"
                                [value]="element.author.fullName"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                canCopy
                                class="sub-cell"
                                *ngIf="!!element.author.email"
                                [label]="'dssClientLibFlightZone.applicationListShared.authorEmailHeader' | transloco"
                                [value]="element.author.email"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                canCopy
                                class="sub-cell"
                                *ngIf="!!element.author.phoneNumber"
                                [label]="'dssClientLibFlightZone.applicationListShared.authorPhoneHeader' | transloco"
                                [value]="element.author.phoneNumber | formatPhoneNumber"
                            >
                            </dtm-ui-label-value>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dssClientLibFlightZone.applicationListShared.actionsHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            type="button"
                            [matMenuTriggerFor]="rowActionsMenu"
                            [matMenuTriggerData]="{ $implicit: element }"
                            (click)="$event.stopPropagation()"
                            class="button-icon"
                        >
                            <dtm-ui-icon name="more"></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="noResults">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        <dtm-ui-error
                            *ngIf="hasDataRetrievalError$ | ngrxPush; else emptyListTemplate"
                            (reload)="listRefresh.emit()"
                        ></dtm-ui-error>

                        <ng-template #emptyListTemplate>
                            <dtm-ui-no-results></dtm-ui-no-results>
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="pagination">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        <dtm-ui-pagination
                            *ngIf="applicationsPage$ | ngrxPush as page"
                            [pagination]="page"
                            (page)="pageChange.emit($event)"
                        >
                        </dtm-ui-pagination>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [class.expanded]="expandedElement === row"
                    (click)="toggleExpandableRow(row)"
                ></tr>
                <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
                <tr
                    mat-footer-row
                    *matFooterRowDef="['noResults']"
                    [class.hide-footer]="restrictions.length || isProcessing"
                    class="no-results-row"
                ></tr>
                <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!restrictions.length" class="pagination-row"></tr>
            </table>
        </dtm-ui-loader-container>
    </ng-container>
</ng-container>

<mat-menu #rowActionsMenu="matMenu">
    <ng-template matMenuContent let-element>
        <a [routerLink]="['/restriction', element.id]" mat-menu-item>
            <dtm-ui-icon name="file-text"></dtm-ui-icon>
            <span>{{ "dssClientLibFlightZone.applicationListShared.previewRestrictionActionLabel" | transloco }}</span>
        </a>

        <button *ngIf="element.notam" type="button" (click)="openNotamPreview.emit(element.lastApplicationId)" mat-menu-item>
            <dtm-ui-icon name="eye"></dtm-ui-icon>
            <span>{{ "dssClientLibFlightZone.applicationListShared.previewNotamActionLabel" | transloco }}</span>
        </button>

        <button
            type="button"
            [disabled]="element.isBeingModified || element.isBeingCancelled"
            (click)="
                changeZoneDuration.emit({
                    id: element.id,
                    startAt: element.startAt,
                    endAt: element.endAt,
                    isStateSecurityRestriction: element.isStateSecurityRestriction
                })
            "
            mat-menu-item
        >
            <dtm-ui-icon name="time"></dtm-ui-icon>
            <span>{{ "dssClientLibFlightZone.applicationListShared.changeZoneDurationActionLabel" | transloco }}</span>
        </button>

        <button mat-menu-item type="button" (click)="kmlDownload.emit(element)">
            <dtm-ui-icon name="download"></dtm-ui-icon>
            <span>{{ "dssClientLibFlightZone.applicationListShared.downloadKMLActionLabel" | transloco }}</span>
        </button>
    </ng-template>
</mat-menu>
