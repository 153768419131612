<form [formGroup]="layersVisibilityForm" *ngrxLet="{ mapLayers: mapLayers$ } as vm">
    <h2 class="title">{{ "dtmMapCesium.mapLayersSelector.title" | transloco }}</h2>
    <ng-container *ngFor="let controlName of vm.mapLayers">
        <ng-container
            *ngIf="controlName === MapLayer.SoraBoxes"
            [ngTemplateOutlet]="soraBoxesTemplate"
            [ngTemplateOutletContext]="{ controlName }"
        ></ng-container>
        <ng-container
            *ngIf="controlName === MapLayer.Obstacles"
            [ngTemplateOutlet]="obstaclesTemplate"
            [ngTemplateOutletContext]="{ controlName }"
        ></ng-container>
    </ng-container>

    <ng-template let-controlName="controlName" #soraBoxesTemplate>
        <div class="map-layer-toggle-container">
            <div class="header">
                <dtm-ui-icon [name]="controlName | invoke : getIconForMapLayerType"></dtm-ui-icon>
                <div>
                    <mat-slide-toggle
                        (change)="turnOffControl($event.checked, MapLayer.Obstacles)"
                        [formControlName]="controlName"
                        labelPosition="before"
                        class="spread"
                    >
                        {{ "dtmMapCesium.mapLayersSelector.soraRiskControlLabel" | transloco : { type: controlName } }}
                    </mat-slide-toggle>
                    <p class="control-description">
                        {{ "dtmMapCesium.mapLayersSelector.soraRiskControlDescription" | transloco : { type: controlName } }}
                    </p>
                </div>
            </div>
            <dtm-map-risk-legend *ngIf="layersVisibilityForm.controls.soraBoxes.value"></dtm-map-risk-legend>
        </div>
    </ng-template>
    <ng-template let-controlName="controlName" #obstaclesTemplate>
        <div class="map-layer-toggle-container">
            <div class="header">
                <dtm-ui-icon [name]="controlName | invoke : getIconForMapLayerType"></dtm-ui-icon>
                <div>
                    <mat-slide-toggle
                        (change)="turnOffControl($event.checked, MapLayer.SoraBoxes)"
                        [formControlName]="controlName"
                        labelPosition="before"
                        class="spread"
                    >
                        {{ "dtmMapCesium.mapLayersSelector.obstaclesControlLabel" | transloco }}
                    </mat-slide-toggle>
                    <p class="control-description">
                        {{ "dtmMapCesium.mapLayersSelector.obstaclesControlDescription" | transloco }}
                    </p>
                </div>
            </div>
            <dtm-map-obstacles-dtm-legend *ngIf="layersVisibilityForm.controls.obstacles.value"></dtm-map-obstacles-dtm-legend>
        </div>
    </ng-template>
</form>
