import { MILLISECONDS_IN_DAY } from "@dtm-frontend/shared/utils";
import { AUP_START_TIME_HOUR } from "../../../geo-zones/models/geo-zones.models";

export function convertActivityTimeRange(timeFrom: Date, timeTo: Date): { timeFrom: Date; timeTo: Date } {
    // NOTE: if time range is more than 24 hours return as it is
    if (timeTo.getTime() - timeFrom.getTime() > MILLISECONDS_IN_DAY) {
        return { timeFrom, timeTo };
    }
    const aupStartDate = new Date(timeFrom);
    aupStartDate.setUTCHours(AUP_START_TIME_HOUR, 0, 0, 0);

    // NOTE: if time start is before AUP hour it means it's previous day AUP
    if (timeFrom.getUTCHours() < AUP_START_TIME_HOUR) {
        aupStartDate.setTime(aupStartDate.getTime() - MILLISECONDS_IN_DAY);
    }
    // AUP end date is always 24 hours after AUP start
    const aupEndDate = new Date(aupStartDate);
    aupEndDate.setTime(aupStartDate.getTime() + MILLISECONDS_IN_DAY);

    // NOTE: if start and end time are on different AUP it means that it will use second AUP which ends 48 hours after first AUP starts
    if (timeFrom.getUTCHours() < AUP_START_TIME_HOUR && timeTo.getUTCHours() > AUP_START_TIME_HOUR) {
        aupEndDate.setTime(aupEndDate.getTime() + MILLISECONDS_IN_DAY);
    }

    return {
        timeFrom: aupStartDate,
        timeTo: aupEndDate,
    };
}
