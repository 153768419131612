import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import {
    ErrorMessageCode,
    FlightZoneCapabilitiesState,
    FlightZoneError,
    FlightZoneErrorType,
    FlightZoneGeometryService,
    FlightZoneRestriction,
    FlightZoneRestrictionActions,
    FlightZoneRestrictionState,
    GEOMETRY_READ_ONLY_CONSTRAINTS,
} from "@dtm-frontend/dss-shared-lib";
import { CameraHelperService } from "@dtm-frontend/shared/map/cesium";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { finalize, switchMap } from "rxjs";
import { ZoneDurationChangeDialogData } from "../../models/flight-zone.models";
import { NotamModalService } from "../../services/notam-modal.service";
import { FlightZoneActions } from "../../state/flight-zone.actions";
import { FlightZoneState } from "../../state/flight-zone.state";
import { ZoneDurationChangeComponent } from "../zone-duration-change/zone-duration-change.component";

@UntilDestroy()
@Component({
    selector: "dss-client-lib-restriction-preview",
    templateUrl: "./restriction-preview.component.html",
    styleUrls: ["./restriction-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, FlightZoneGeometryService],
})
export class RestrictionPreviewComponent implements AfterViewInit, OnDestroy {
    protected readonly FlightZoneErrorType = FlightZoneErrorType;

    protected readonly error$ = this.store.select(FlightZoneRestrictionState.error);
    protected readonly flightZoneRestrictionData$ = this.store.select(FlightZoneRestrictionState.restrictionData);
    protected readonly isProcessing$ = this.store.select(FlightZoneRestrictionState.isProcessing);
    protected readonly capabilities$ = this.store.select(FlightZoneCapabilitiesState.capabilities);

    constructor(
        private readonly store: Store,
        private readonly flightZoneGeometryService: FlightZoneGeometryService,
        private readonly notamModalService: NotamModalService,
        private readonly matDialog: MatDialog,
        private readonly transloco: TranslocoService,
        private readonly toastService: ToastrService,
        private readonly cameraHelperService: CameraHelperService
    ) {}

    public ngAfterViewInit(): void {
        this.initMapWithEntity();
    }

    public ngOnDestroy(): void {
        this.flightZoneGeometryService.clearGeometry();
    }

    protected zoomToGeometry(): void {
        this.flightZoneGeometryService.showEntireContent();
    }

    protected openNotamPreview(restrictionId: string): void {
        this.notamModalService.openNotamPreview(restrictionId);
    }

    protected changeZoneDuration(data: ZoneDurationChangeDialogData): void {
        this.matDialog
            .open(ZoneDurationChangeComponent, { data })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap((zoneDurationData) => this.store.dispatch(new FlightZoneActions.ChangeZoneDuration(zoneDurationData))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(this.transloco.translate("dssClientLibFlightZone.changeZoneDurationSuccessMessage"));
            });
    }

    protected downloadPdf(restriction: FlightZoneRestriction): void {
        const cameraViewSettingsCache = this.cameraHelperService.freezeView();

        this.cameraHelperService
            .takeScreenshotWhenStable()
            .pipe(
                switchMap((file) => this.store.dispatch(new FlightZoneRestrictionActions.DownloadPdf(restriction, file))),
                finalize(() => this.cameraHelperService.restoreView(cameraViewSettingsCache)),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneRestrictionState.error);

                if (error) {
                    this.displayErrorMessage(error);
                }
            });
    }

    protected downloadConfirmationOfApplicationSubmissionPdf(restriction: FlightZoneRestriction): void {
        this.store
            .dispatch(
                new FlightZoneRestrictionActions.DownloadConfirmationOfApplicationSubmissionPdf(
                    restriction.applicationId,
                    restriction.basicData.title
                )
            )
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneRestrictionState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }
            });
    }

    private initMapWithEntity(): void {
        const restrictionAreaGeometry = this.store.selectSnapshot(FlightZoneRestrictionState.restrictionData)?.restrictionAreaGeometry;

        if (!restrictionAreaGeometry) {
            return;
        }

        this.flightZoneGeometryService.initMapWithEntity(restrictionAreaGeometry, GEOMETRY_READ_ONLY_CONSTRAINTS);
    }

    private displayErrorMessage(error: FlightZoneError): void {
        let errorMessage: string | undefined;

        switch (error.type) {
            case FlightZoneErrorType.NotAuthorized:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.notAuthorizedErrorMessage");
                break;
            case FlightZoneErrorType.InvalidApplicationVersion:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.invalidVersionErrorMessage");
                break;
            case FlightZoneErrorType.CannotChangeZoneDuration:
                errorMessage = this.transloco.translate(
                    error.messageCode === ErrorMessageCode.AlreadyExistRestrictionModificationApplication
                        ? "dssClientLibFlightZone.restrictionModificationAlreadyExistsErrorMessage"
                        : "dssClientLibFlightZone.cannotChangeZoneDurationErrorMessage"
                );
                break;
            case FlightZoneErrorType.CannotDownloadRestrictionPdf:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.cannotDownloadRestrictionPdfErrorMessage");
                break;
            case FlightZoneErrorType.CannotDownloadConfirmationOfApplicationSubmissionPdf:
                errorMessage = this.transloco.translate(
                    "dssClientLibFlightZone.cannotDownloadConfirmationOfApplicationSubmissionPdfErrorMessage"
                );
                break;
            default:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.genericErrorMessage");
        }

        this.toastService.error(errorMessage);
    }
}
