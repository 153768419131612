import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from "@angular/common/http";
import { FileUploadState, Upload, UploadedFile } from "../models/upload.models";

export function isInstanceOfUploadedFile(uploadedFile?: unknown): uploadedFile is UploadedFile {
    return !!(uploadedFile as UploadedFile)?.id && !!(uploadedFile as UploadedFile)?.name;
}

export function isHttpResponse<T>(event: HttpEvent<unknown>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response && !!event.body;
}

export function isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
    return event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;
}

export const calculateUploadState = (upload: Upload, event: HttpEvent<unknown>): Upload => {
    if (isHttpProgressEvent(event)) {
        return {
            progress: event.total ? Math.round((100 * event.loaded) / event.total) : upload.progress,
            state: FileUploadState.InProgress,
            name: upload.name,
        };
    }

    if (isHttpResponse<UploadedFile>(event)) {
        return {
            state: FileUploadState.Done,
            name: upload.name,
            id: event.body?.id,
        };
    }

    return upload;
};
