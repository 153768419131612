<section class="container scroll-shadows">
    <span class="active-step">
        {{
            "dtmWebAppLibWizard.wizardSteps.activeStepLabel"
                | transloco : { stepNumber: stepNumber$ | ngrxPush, stepsAmount: stepsAmount$ | ngrxPush }
        }}
    </span>
    <h3 class="step-title">{{ title$ | ngrxPush }}</h3>
    <ng-content></ng-content>
</section>
<footer class="actions">
    <ng-content select="[actions]"></ng-content>
</footer>
