import { Alpha3CountryCode } from "./country.models";

export type BasicAddress = Omit<Address, "country">;
export interface Address {
    streetName: string;
    houseNumber: string;
    apartmentNumber: string | null;
    city: string;
    postCode: string;
    country: Alpha3CountryCode;
}
