import { Pipe, PipeTransform } from "@angular/core";
import { BYTES_IN_KILOBYTES, FILE_SIZE_UNITS } from "./filesize";

@Pipe({
    name: "formatFileSize",
})
export class FormatFileSizePipe implements PipeTransform {
    public transform(sizeInBytes: number | undefined): string {
        if (sizeInBytes === undefined) {
            return "";
        }

        let power = Math.round(Math.log(sizeInBytes) / Math.log(BYTES_IN_KILOBYTES));
        power = Math.min(power, FILE_SIZE_UNITS.length - 1);

        const size = sizeInBytes / Math.pow(BYTES_IN_KILOBYTES, power);
        const formattedSize = Math.round(size * 100) / 100;
        const unit = FILE_SIZE_UNITS[power];

        return `${formattedSize} ${unit}`;
    }
}
