<ng-container *ngrxLet="{ ticks: ticks$, isDisabled: isDisabled$, isRangeSlider: isRangeSlider$, currentValue: currentValue$ } as vm">
    <div class="slider">
        <div class="ticks-container" [class.disabled]="vm.isDisabled" *ngIf="vm.ticks">
            <span *ngFor="let tick of vm.ticks">
                &ZeroWidthSpace;<span>{{ tick.label }}</span>
            </span>
        </div>
        <div class="subticks-container" [class.disabled]="vm.isDisabled" *ngIf="subTicks$ | ngrxPush as subTicks">
            <div *ngFor="let subTick of subTicks">
                &ZeroWidthSpace;
                <div
                    class="subtick"
                    [ngClass]="{
                        large: subTick.type === TickType.Large,
                        small: subTick.type === TickType.Small,
                        filled: subTick.value >= vm.currentValue[0] && subTick.value <= vm.currentValue[1]
                    }"
                ></div>
            </div>
        </div>
        <mat-slider
            [class.with-ticks]="!!vm.ticks"
            [disabled]="vm.isDisabled"
            [displayWith]="(thumbLabelFormatter$ | ngrxPush)!"
            [min]="minValue$ | ngrxPush"
            [max]="maxValue$ | ngrxPush"
            [step]="step$ | ngrxPush"
            [discrete]="showThumbLabel$ | ngrxPush"
            [disableRipple]="true"
        >
            <input
                #sliderThumb
                matSliderThumb
                (valueChange)="valueChange.emit($event)"
                (input)="valueInput.emit(sliderThumb.valueAsNumber)"
                [value]="value$ | ngrxPush"
                *ngIf="!vm.isRangeSlider; else rangeSliderInputsTemplate"
            />
            <ng-template #rangeSliderInputsTemplate>
                <input
                    #sliderLeftThumb
                    matSliderStartThumb
                    (valueChange)="rangeChange.emit([$event, sliderRightThumb.valueAsNumber])"
                    (input)="rangeInput.emit([sliderLeftThumb.valueAsNumber, sliderRightThumb.valueAsNumber])"
                    [value]="leftValue$ | ngrxPush"
                />
                <input
                    #sliderRightThumb
                    matSliderEndThumb
                    (valueChange)="rangeChange.emit([sliderLeftThumb.valueAsNumber, $event])"
                    (input)="rangeInput.emit([sliderLeftThumb.valueAsNumber, sliderRightThumb.valueAsNumber])"
                    [value]="rightValue$ | ngrxPush"
                />
            </ng-template>
        </mat-slider>
    </div>
    <div class="suffix" *ngIf="suffix$ | ngrxPush as suffix" [class.disabled]="vm.isDisabled">{{ suffix }}</div>
</ng-container>
