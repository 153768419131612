import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AVIATION_REPORTING_URL, AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import {
    BasicDataModel,
    GroundAreaCharacteristicType,
    OperationalAirspaceClassType,
    SpecificPermitFlightWizardSteps,
} from "../../../models/permits.models";
import { MissionType } from "../../../models/tactical.models";
import { UploadedFile } from "../../files-upload-field/files-upload.models";

interface BasicDataPreviewComponentState {
    basicData: BasicDataModel | undefined;
    areActionButtonsVisible: boolean;
    isContactPersonTileExpanded: boolean;
    isOperatorDetailsTileExpanded: boolean;
    isRestrictionsTileExpanded: boolean;
    isOperatorContactPersonVisible: boolean;
}

@Component({
    selector: "dtm-ui-permit-basic-data-preview[basicData]",
    templateUrl: "./permit-basic-data-preview.component.html",
    styleUrls: ["./permit-basic-data-preview.component.scss", "../permit-details-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimationWithMargin()],
})
export class PermitBasicDataPreviewComponent {
    @Input() public set basicData(value: BasicDataModel | undefined) {
        this.localStore.patchState({ basicData: value });
    }
    @Input() public set areActionButtonsVisible(value: BooleanInput) {
        this.localStore.patchState({ areActionButtonsVisible: coerceBooleanProperty(value) });
    }
    @Input() public set isOperatorDetailsTileExpanded(value: BooleanInput) {
        this.localStore.patchState({
            isOperatorDetailsTileExpanded: coerceBooleanProperty(value),
        });
    }
    @Input() public set isContactPersonTileExpanded(value: BooleanInput) {
        this.localStore.patchState({
            isContactPersonTileExpanded: coerceBooleanProperty(value),
        });
    }
    @Input() public set isRestrictionsTileExpanded(value: BooleanInput) {
        this.localStore.patchState({
            isRestrictionsTileExpanded: coerceBooleanProperty(value),
        });
    }
    @Input() public set isOperatorContactPersonVisible(value: BooleanInput) {
        this.localStore.patchState({
            isOperatorContactPersonVisible: coerceBooleanProperty(value),
        });
    }

    @Output() public readonly editStep = new EventEmitter<SpecificPermitFlightWizardSteps>();
    @Output() public readonly kmlFilePreview = new EventEmitter<UploadedFile>();

    protected readonly basicData$ = this.localStore.selectByKey("basicData").pipe(RxjsUtils.filterFalsy());
    protected readonly isOperatorContactPersonVisible$ = this.localStore.selectByKey("isOperatorContactPersonVisible");
    protected readonly areActionButtonsVisible$ = this.localStore.selectByKey("areActionButtonsVisible");
    protected readonly isOperatorDetailsTileExpanded$ = this.localStore.selectByKey("isOperatorDetailsTileExpanded");
    protected readonly isContactPersonTileExpanded$ = this.localStore.selectByKey("isContactPersonTileExpanded");
    protected readonly isRestrictionsTileExpanded$ = this.localStore.selectByKey("isRestrictionsTileExpanded");
    protected readonly MissionType = MissionType;
    protected readonly OperationalAirspaceClassType = OperationalAirspaceClassType;
    protected readonly AVIATION_REPORTING_URL = AVIATION_REPORTING_URL;
    protected readonly SpecificPermitFlightWizardSteps = SpecificPermitFlightWizardSteps;
    protected readonly GroundAreaCharacteristicType = GroundAreaCharacteristicType;

    constructor(private readonly localStore: LocalComponentStore<BasicDataPreviewComponentState>) {
        this.localStore.setState({
            basicData: undefined,
            areActionButtonsVisible: false,
            isContactPersonTileExpanded: false,
            isOperatorDetailsTileExpanded: false,
            isRestrictionsTileExpanded: false,
            isOperatorContactPersonVisible: false,
        });
    }
}
