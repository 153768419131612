import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils, SQUARE_METERS_IN_SQUARE_KILOMETER } from "@dtm-frontend/shared/utils";
import { OperationalGeometryData } from "../../models/mission-plan-verification.models";

interface OperationalAreaGeometryComponentState {
    operationalGeometryData: OperationalGeometryData | undefined;
    isExpanded: boolean;
}

@Component({
    selector: "dtm-mission-operational-area-geometry[operationalGeometryData]",
    templateUrl: "./operational-area-geometry.component.html",
    styleUrls: ["./operational-area-geometry.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperationalAreaGeometryComponent {
    @Input() public set operationalGeometryData(value: OperationalGeometryData | undefined) {
        this.localStore.patchState({ operationalGeometryData: value });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly operationalGeometryData$ = this.localStore.selectByKey("operationalGeometryData").pipe(RxjsUtils.filterFalsy());
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    constructor(private readonly localStore: LocalComponentStore<OperationalAreaGeometryComponentState>) {
        this.localStore.setState({
            operationalGeometryData: undefined,
            isExpanded: true,
        });
    }

    protected convertSquareMetersToSquareKilometers(value: number | undefined): number | undefined {
        if (!value) {
            return;
        }

        return value / SQUARE_METERS_IN_SQUARE_KILOMETER;
    }
}
