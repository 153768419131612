import { LanguageCode } from "../modules/i18n";

export namespace UIActions {
    export class SetMenuCollapsedState {
        public static readonly type = "[UI] Set menu collapsed state";
        constructor(public isCollapsed: boolean) {}
    }

    export class SetActiveLanguage {
        public static readonly type = "[UI] Set active language";
        constructor(public languageCode: LanguageCode) {}
    }
}
