import { ISO8601TimeDuration } from "@dtm-frontend/shared/utils";
import { AcEntity, Cartesian3 } from "@pansa/ngx-cesium";
import { Polygon, Properties } from "@turf/helpers";
import { EmergencyType } from "./tactical.models";

export type RouteAreaTypeId = "flightArea" | "safetyArea" | "groundRiskArea" | "path" | "waypoint" | "blockers" | "trajectories";

export interface MissionPlanRoute {
    routeId: string;
    planId?: string;
    missionId?: string;
    elevations?: Elevation[];
    sections: MissionPlanRouteSection[];
    isPathBased?: boolean;
    estimatedDistance?: number;
    stats?: RouteStats;
}

export interface RouteStats {
    adjacent: {
        bufferHorizontal: number;
        height: number;
        size: number;
    };
    flight: {
        dtmNames: string[];
        estimatedDistance: number;
        maxHeight: number;
        maxRadius: number;
        size: number;
    };
    operation: {
        maxHeight: number;
        size: number;
        maxDeclaredHeight: number;
    };
    safety: {
        height: number;
    };
}

export interface WaypointWithSection {
    waypoint: Waypoint;
    parentSection: MissionPlanRouteSection;
}

export interface MissionPlanRouteSection {
    name?: string;
    isActive?: boolean;
    flightZone?: MissionPlanRouteFlightZone;
    segment?: MissionPlanRouteSegment;
}

export interface MissionPlanRouteFlightZone {
    center: Waypoint;
    flightArea: FlightArea;
    safetyArea: Area;
    groundArea?: Polygon;
    groundAdjacentArea?: Polygon;
    stopover: {
        min: ISO8601TimeDuration;
        max: ISO8601TimeDuration;
    };
    isRunway?: boolean;
}

export interface MissionPlanRouteSegment {
    fromWaypoint: Waypoint;
    toWaypoint: Waypoint;
    flightArea: FlightArea;
    safetyArea: Area;
    groundArea?: Polygon;
    groundAdjacentArea?: Polygon;
    distance?: number;
    elevationProfile?: {
        elevations: Elevation[];
    };
    speed?: {
        min: number;
        max: number;
    };
    duration?: {
        min: ISO8601TimeDuration;
        max: ISO8601TimeDuration;
    };
}

export interface RouteData<T> {
    data?: T;
    route: MissionPlanRoute;
    isPathBased?: boolean;
    sectionStatuses?: MissionSegmentStatus[];
    uniqueRouteId?: number;
    uniqueNearbyRoutesId?: number;
    nearbyMissionsData?: RouteData<T>[];
    selectedMission?: T;
    isSelected?: boolean;
    isCollision?: boolean;
    isMain?: boolean;
    isOutsideDtm?: boolean;
    emergency?: EmergencyType;
}

export interface Elevation {
    latitude: number;
    longitude: number;
    startOffset: number;
    value: number;
}

export interface Waypoint {
    name: string;
    point: Point;
    estimatedArriveAt: TimeRange;
}

export interface TimeRange {
    min: Date;
    max: Date;
}

export interface Point {
    longitude: number;
    latitude: number;
    height: number;
    altitude: number;
}

export interface Area {
    elevationMax?: number;
    elevationMin?: number;
    volume: {
        area: Polygon;
        ceiling: number;
        floor: number;
        coreCeiling?: number;
        coreFloor?: number;
        size?: number;
    };
}

export interface FlightArea extends Area {
    safetyBuffer?: {
        horizontal: number;
        vertical: number;
    };
    properties: Properties;
}

export enum MissionSegmentStatus {
    ACTIVE_SEGMENT = "ACTIVE_SEGMENT",
    DEFAULT = "DEFAULT",
    MISSION_ACTIVATED = "MISSION_ACTIVATED",
    MISSION_NOT_ACTIVATED = "MISSION_NOT_ACTIVATED",
    NOT_ACTIVE_SEGMENT = "NOT_ACTIVE_SEGMENT",
    DEACTIVATED_SEGMENT = "DEACTIVATED_SEGMENT",
}

export interface PinAcEntity<T> extends AcEntity {
    id: string | number | undefined;
    show?: boolean;
    data?: PinData<T>;
    position: Cartesian3;
}

export interface PinData<T> {
    flightArea: FlightArea;
    waypoints: Waypoint[];
    isSelected?: boolean;
    isMain?: boolean;
    isCollision?: boolean;
    data?: T;
    status?: MissionSegmentStatus;
    index?: number;
}

export interface AircraftEntity {
    position: Cartesian3;
    uri?: string;
    orientation?: number;
    name?: string;
    altitude?: number | null;
    originalAltitudeBaro?: number | null;
    originalAltitudeGeo?: number | null;
    height?: number | null;
    heightGeo?: number | null;
    aircraftType: AircraftType;
    updateTime?: number;
    isConnectionLost?: boolean;
    isFaradaSource?: boolean;
    trackerIdentifier?: string;
}

export interface FlightPositionUpdate {
    updateType: FlightPositionUpdateType;
    aircraftType: AircraftType;
    id: string;
    trackerIdentifier: string;
    callSign?: string;
    name?: string;
    track?: number;
    latitude?: number;
    longitude?: number;
    altitude?: number | null;
    originalAltitudeBaro?: number | null;
    originalAltitudeGeo?: number | null;
    height?: number | null;
    heightGeo?: number | null;
    isConnectionLost?: boolean;
    isFaradaSource?: boolean;
    orientation?: number;
}

export enum FlightPositionUpdateType {
    Start = "Start",
    Update = "Update",
    End = "End",
    ConnectionLost = "ConnectionLost",
}

export enum AircraftType {
    Uav = "Uav",
    Airplane = "Airplane",
    Other = "Other",
}

export interface Trajectory {
    id?: string;
    flightId?: string;
    trackerIdentifier?: string;
    startTime?: Date;
    endTime?: Date;
    positions: TrajectoryPosition[];
    isActive?: boolean;
}

export interface TrajectoryPosition extends Omit<TrajectoryPositionBodyResponse, "timestamp"> {
    timestamp?: Date;
}

export interface TrajectoryPositionBodyResponse {
    timestamp?: string;
    latitude: number;
    longitude: number;
    altitude?: number;
    height?: number;
}

export interface HemsDataResponseBody {
    content: HemsContent[];
    pageable: {
        page: number;
        size: number;
        sort: {
            orders: [];
            empty: boolean;
            unsorted: boolean;
            sorted: boolean;
        };
        offset: number;
        pageNumber: number;
        pageSize: number;
        paged: boolean;
        unpaged: boolean;
    };
    total: number;
    totalPages: number;
    totalElements: number;
    last: boolean;
    size: number;
    number: number;
    sort: {
        orders: [];
        empty: boolean;
        unsorted: boolean;
        sorted: boolean;
    };
    numberOfElements: number;
    first: boolean;
    empty: boolean;
}

interface HemsContent {
    name: HemsEvents;
    body: string;
}

export interface HemsBasicEventData {
    name: HemsEvents;
    content: HemsData;
}

export interface HemsData {
    identifier: string;
    lastPosition: {
        longitude: number;
        latitude: number;
        altitude: number;
    };
    lastPositionChangeTime?: Date | null;
    associatedTeam: string;
    landing?: {
        position: {
            longitude: number;
            latitude: number;
            altitude: number | null;
        };
        areaRadius: number;
        distanceToCenter: number;
        activatedAt: Date | null;
        estimatedArrivalTime: Date | null;
        id: string;
    };
    departure?: {
        position: {
            longitude: number;
            latitude: number;
            altitude: number | null;
        };
        areaRadius: number;
        distanceToCenter: number;
        activatedAt: Date | null;
        estimatedArrivalTime: Date | null;
        id: string;
    };
    createdAt: Date;
    predictedPosition?: {
        arrivalTime?: Date;
        position: {
            longitude: number;
            latitude: number;
            altitude: number | null;
        };
    };
}

export interface HemsAreaData extends HemsData {
    areaType: string;
    areaId: string;
}

export interface HemsAreaActivationEventData extends HemsBasicEventData {
    name: HemsEvents.AreaActivatedEvent | HemsEvents.AreaDeactivatedEvent;
    content: HemsAreaData;
}

export enum HemsEvents {
    OperationStartedEvent = "OperationStartedEvent",
    AreaActivatedEvent = "AreaActivatedEvent",
    AreaDeactivatedEvent = "AreaDeactivatedEvent",
    AircraftStatusChangedEvent = "AircraftStatusChangedEvent",
    AircraftPositionChangedEvent = "AircraftPositionChangedEvent",
}

export type HemsEventData = HemsBasicEventData | HemsAreaActivationEventData;

export interface SectionElementResponseBody {
    active: boolean;
    flightZone: {
        center: Waypoint;
        flightArea: FlightArea;
        safetyArea: Area;
        ground?: {
            area: Polygon;
        };
        groundAdjacent?: {
            area: Polygon;
        };
        stopover: {
            min: ISO8601TimeDuration;
            max: ISO8601TimeDuration;
        };
        runway: boolean;
    };
    segment: {
        from: Waypoint;
        to: Waypoint;
        flightArea: FlightArea;
        safetyArea: Area;
        ground?: {
            area: Polygon;
        };
        groundAdjacent?: {
            area: Polygon;
        };
        elevationProfile: {
            elevations: Elevation[];
        };
        distance?: number;
        speed?: {
            min: number;
            max: number;
        };
        duration?: {
            min: ISO8601TimeDuration;
            max: ISO8601TimeDuration;
        };
    };
}

export interface FlightPositionUpdateResponseBody {
    flightId: string;
    trackerIdentifier: string;
    operatorId?: string | null;
    uav?: {
        name: string;
    };
    position?: {
        track: number;
        latitude: number;
        longitude: number;
        altitude: number | null;
        height: number | null;
        originalAltitudeBaro: number;
        originalAltitudeGeo: number;
        originalHeight: number;
        originalMslAltitude: number;
        heightGeo: number | null;
    };
    lastSource: string;
    sources: string[];
    createdAt: string;
}
