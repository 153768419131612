<ng-container *ngrxLet="{ isExpanded: isExpanded$, isArrowIconReversed: isArrowIconReversed$ } as vm">
    <div
        class="header-container"
        [class.expanded]="vm.isExpanded"
        [class.disabled]="isDisabled"
        (click)="!isDisabled && togglePanel(vm.isExpanded)"
        *ngrxLet="isDisabled$ as isDisabled"
    >
        <div class="header">
            <ng-content select="[headerSlot]"></ng-content>
        </div>
        <button
            class="button-icon small"
            type="button"
            *ngIf="!isDisabled"
            [attr.aria-label]="'dtmUi.expandButtonArialLabel' | transloco : { isExpanded: vm.isExpanded }"
            [attr.aria-expanded]="vm.isExpanded"
        >
            <dtm-ui-icon
                [name]="
                    vm.isExpanded
                        ? vm.isArrowIconReversed
                            ? 'arrow-down'
                            : 'arrow-up'
                        : vm.isArrowIconReversed
                        ? 'arrow-up'
                        : 'arrow-down'
                "
            ></dtm-ui-icon>
        </button>
    </div>
    <div class="content" [class.with-header-separator]="hasHeaderSeparator$ | ngrxPush" *ngIf="vm.isExpanded" @slideInWithMargin>
        <ng-content></ng-content>
    </div>
</ng-container>
