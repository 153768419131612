import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs/operators";
import { MissionPlanRoute } from "../../models/route-area.model";

interface OutsideDtmPanelComponentState {
    route: MissionPlanRoute | undefined;
}

@Component({
    selector: "dtm-ui-outside-dtm-info-panel[route]",
    templateUrl: "./outside-dtm-info-panel.component.html",
    styleUrls: ["./outside-dtm-info-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OutsideDtmPanelComponent {
    @Input() public set route(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ route: value });
    }

    protected readonly maxHeight$ = this.localStore.selectByKey("route").pipe(
        RxjsUtils.filterFalsy(),
        map((route) => this.initMaxHeightForRoute(route))
    );

    constructor(private readonly localStore: LocalComponentStore<OutsideDtmPanelComponentState>) {
        localStore.setState({
            route: undefined,
        });
    }

    private initMaxHeightForRoute(route: MissionPlanRoute): number {
        return route.sections.reduce<number>((result, section) => {
            if (section.flightZone) {
                return Math.max(result, section.flightZone.flightArea.volume.ceiling ?? 0);
            }

            return result;
        }, 0);
    }
}
