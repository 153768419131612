import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { FunctionUtils, LocalComponentStore, VERIFICATION_CODE_MASK } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EmailAddressFormValues } from "../../models/user-contact.models";
import { isNotSameValueValidator } from "../../validators/is-value-differ.validator";

interface EditEmailAddressFormComponentState {
    isRequestedEmailChange: boolean;
    email: string | undefined;
    isVerificationRequired: boolean;
    emailConfirmationDescription: string | undefined;
}

interface EmailEditForm {
    email: FormControl<string>;
    verificationCode: FormControl<string>;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-edit-email-address-form",
    templateUrl: "./edit-email-address-form.component.html",
    styleUrls: ["./edit-email-address-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EditEmailAddressFormComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EditEmailAddressFormComponent), multi: true },
    ],
})
export class EditEmailAddressFormComponent implements ControlValueAccessor, Validator {
    @Input() public set currentEmail(value: string | undefined) {
        this.localStore.patchState({ email: value });
        this.emailChangeFormGroup.controls.email.setValidators([Validators.required, Validators.email, isNotSameValueValidator(value)]);
    }
    @Input() public set isRequestedEmailChange(value: BooleanInput) {
        if (!value) {
            this.emailChangeFormGroup.reset();
        }

        this.localStore.patchState({ isRequestedEmailChange: coerceBooleanProperty(value) });
    }
    @Input() public set hasEmailAddressConflictError(value: BooleanInput) {
        if (value) {
            this.emailChangeFormGroup.controls.email.setErrors({
                emailAddressConflict: true,
            });
        }
    }
    @Input() public set isVerificationRequired(value: BooleanInput) {
        this.localStore.patchState({ isVerificationRequired: coerceBooleanProperty(value) });
    }
    @Input() public set emailConfirmationDescription(value: string | undefined) {
        this.localStore.patchState({ emailConfirmationDescription: value });
    }

    @Output() public resendVerificationCode = new EventEmitter<void>();

    protected readonly isRequestedEmailChange$ = this.localStore.selectByKey("isRequestedEmailChange");
    protected readonly email$ = this.localStore.selectByKey("email");
    protected readonly isVerificationRequired$ = this.localStore.selectByKey("isVerificationRequired");
    protected readonly emailConfirmationDescription$ = this.localStore.selectByKey("emailConfirmationDescription");
    protected readonly emailChangeFormGroup = new FormGroup<EmailEditForm>({
        email: new FormControl("", {
            validators: [Validators.required, Validators.email],
            nonNullable: true,
        }),
        verificationCode: new FormControl("", { validators: [Validators.required], nonNullable: true }),
    });

    protected readonly VERIFICATION_CODE_MASK = VERIFICATION_CODE_MASK;

    private propagateChange: (value: EmailAddressFormValues) => void = FunctionUtils.noop;
    private propagateTouch = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<EditEmailAddressFormComponentState>) {
        this.localStore.setState({
            email: undefined,
            isRequestedEmailChange: false,
            isVerificationRequired: true,
            emailConfirmationDescription: undefined,
        });

        this.emailChangeFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            const emailControl = this.emailChangeFormGroup.controls.email;
            const email = emailControl.valid ? emailControl.value : "";

            const verificationCodeControl = this.emailChangeFormGroup.controls.verificationCode;
            const verificationCode = verificationCodeControl.valid ? verificationCodeControl.value : "";

            this.propagateChange({ email, verificationCode });
        });
    }

    public registerOnChange(fn: (value: EmailAddressFormValues) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: EmailAddressFormValues): void {
        if (value) {
            this.emailChangeFormGroup.reset({
                email: value.email,
                verificationCode: value.verificationCode,
            });
        } else {
            this.emailChangeFormGroup.reset();
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.emailChangeFormGroup.disable();

            return;
        }
        this.emailChangeFormGroup.enable();
    }

    public validate(): ValidationErrors | null {
        return this.emailChangeFormGroup.invalid ? this.emailChangeFormGroup.errors : null;
    }
}
