import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";
import { MissionSearchActions } from "../state/mission-search.actions";

export const MISSION_ID_ROUTE_PARAM_NAME = "missionId";

@Injectable()
export class MissionResolver {
    constructor(private readonly store: Store) {}

    public async resolve(route: ActivatedRouteSnapshot): Promise<string> {
        const missionId = route.paramMap.get(MISSION_ID_ROUTE_PARAM_NAME) ?? "";

        await lastValueFrom(this.store.dispatch(new MissionSearchActions.GetMission(missionId)));

        return missionId;
    }
}
