<button
    type="button"
    #button
    class="button-icon close-button"
    (click)="closeZoom.emit()"
    [attr.aria-label]="'dtmUi.closeButtonAltText' | transloco"
>
    <dtm-ui-icon name="close"></dtm-ui-icon>
</button>

<img [src]="imgSrc$ | ngrxPush" [alt]="altText$ | ngrxPush" />
