<dtm-ui-editable-box
    *ngrxLet="{
        email: email$,
        isEditMode: isEditMode$,
        isRequestedEmailChange: isRequestedEmailChange$,
        saveButtonLabel: saveButtonLabel$
    } as vm"
    [saveButtonLabel]="vm.saveButtonLabel"
    [label]="'dtmUi.editEmailAddress.addressEmailLabel' | transloco"
    [canLeaveEditModeOnSave]="false"
    [isEditModeOpen]="vm.isEditMode"
    (save)="requestUserEmailChange(vm.isRequestedEmailChange)"
    (closeEdition)="closeModifyEmailPanel()"
>
    <span valueLabelSlot class="email-value">{{ vm.email }}</span>
    <ng-container editableContentSlot>
        <dtm-ui-edit-email-address-form
            [isVerificationRequired]="isVerificationRequired$ | ngrxPush"
            [currentEmail]="vm.email"
            [hasEmailAddressConflictError]="hasEmailAddressConflictError$ | ngrxPush"
            [isRequestedEmailChange]="vm.isRequestedEmailChange"
            [emailConfirmationDescription]="'dtmUi.editEmailAddress.emailConfirmationDescription' | transloco"
            [formControl]="emailAddressFormControl"
            (resendVerificationCode)="sendEmailVerifyCode()"
            dtmUiMarkValueAccessorControlsAsTouched
        ></dtm-ui-edit-email-address-form>
    </ng-container>
</dtm-ui-editable-box>
