import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface ExpandableSimpleHeaderComponentState {
    label: string | undefined;
    value: string | undefined;
}

@Component({
    selector: "dtm-ui-expandable-simple-header[label]",
    templateUrl: "./expandable-simple-header.component.html",
    styleUrls: ["./expandable-simple-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ExpandableSimpleHeaderComponent {
    @Input() public set label(value: string | undefined) {
        this.localStore.patchState({ label: value });
    }

    @Input() public set value(value: string | undefined) {
        this.localStore.patchState({ value });
    }

    protected readonly label$ = this.localStore.selectByKey("label");
    protected readonly value$ = this.localStore.selectByKey("value");

    constructor(private readonly localStore: LocalComponentStore<ExpandableSimpleHeaderComponentState>) {
        localStore.setState({ label: undefined, value: undefined });
    }
}
