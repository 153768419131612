import { ChangeDetectionStrategy, Component, ContentChild, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { DeviceSize, DeviceSizeService } from "../../services/device-size/device-size.service";
import { MobileTableDirective } from "./mobile-table.directive";

interface ResponsiveTableComponentState {
    data: unknown[] | undefined;
}

@Component({
    selector: "dtm-ui-responsive-table[data]",
    templateUrl: "responsive-table.component.html",
    styleUrls: ["responsive-table.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ResponsiveTableComponent {
    @ContentChild(MobileTableDirective) protected mobileTableTemplate!: MobileTableDirective;

    @Input() public set data(value: unknown[] | undefined) {
        this.localStore.patchState({ data: value });
    }

    protected readonly data$ = this.localStore.selectByKey("data");
    protected readonly isDesktop$ = this.deviceSizeService.getSizeObservable(DeviceSize.Desktop, DeviceSize.DesktopWide);

    constructor(
        private readonly deviceSizeService: DeviceSizeService,
        private readonly localStore: LocalComponentStore<ResponsiveTableComponentState>
    ) {
        this.localStore.setState({
            data: undefined,
        });
    }
}
