import { Pipe, PipeTransform } from "@angular/core";
import { CountryCode, formatNumber, parse } from "libphonenumber-js";
import { PhoneNumber } from "../models/phone-number.models";

@Pipe({
    name: "formatPhoneNumber",
})
export class FormatPhoneNumberPipe implements PipeTransform {
    public transform(phone: PhoneNumber | undefined): string | undefined {
        if (!phone) {
            return undefined;
        }

        const countryCode: CountryCode | undefined = phone.countryCode ?? undefined;

        return formatNumber(parse(`${phone.number}`, countryCode), "INTERNATIONAL");
    }
}
