<form [formGroup]="areaVisibilityForm" *ngrxLet="{ viewControls: viewControls$ } as vm">
    <h2 class="title">{{ "dtmMapCesium.missionViewControls.title" | transloco }}</h2>
    <ng-container *ngFor="let controlName of vm.viewControls">
        <ng-container *ngTemplateOutlet="areaVisibilityToggleTemplate; context: { $implicit: controlName }"></ng-container>
    </ng-container>

    <ng-template let-controlName #areaVisibilityToggleTemplate>
        <mat-slide-toggle [formControlName]="controlName" labelPosition="before" class="spread">
            {{ "dtmMapCesium.missionViewControls.controlsLabels" | transloco : { type: controlName } }}
        </mat-slide-toggle>
    </ng-template>
</form>
