import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TacticalMitigationPerformanceRequirementProperty } from "../../models/mission-plan-verification.models";
import { TacticalMitigationPerformanceRequirements, TacticalRiskMitigationMeasuresCategory } from "../../models/mission.models";

const CATEGORY_KEYS = [
    TacticalRiskMitigationMeasuresCategory.DETECT,
    TacticalRiskMitigationMeasuresCategory.DECIDE,
    TacticalRiskMitigationMeasuresCategory.COMMAND,
    TacticalRiskMitigationMeasuresCategory.EXECUTE,
    TacticalRiskMitigationMeasuresCategory.FEEDBACK_LOOP,
];

type TmprRequirements = {
    [category in TacticalRiskMitigationMeasuresCategory]?: string;
};

export interface TacticalRiskMitigationMeasuresComponentState {
    tacticalMitigationPerformanceRequirementProperty: TacticalMitigationPerformanceRequirementProperty | undefined;
    tacticalMitigationPerformanceRequirements: TmprRequirements | undefined;
    isExpanded: boolean;
}

@Component({
    selector: "dtm-mission-tactical-risk-mitigation-measures",
    templateUrl: "./tactical-risk-mitigation-measures.component.html",
    styleUrls: ["./tactical-risk-mitigation-measures.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticalRiskMitigationMeasuresComponent {
    @Input({ required: true }) public set tacticalMitigationPerformanceRequirementProperty(
        value: TacticalMitigationPerformanceRequirementProperty | undefined
    ) {
        this.localStore.patchState({
            tacticalMitigationPerformanceRequirementProperty: value ?? TacticalMitigationPerformanceRequirementProperty.B,
        });
    }

    @Input({ required: true }) public set tacticalMitigationPerformanceRequirements(
        value: TacticalMitigationPerformanceRequirements | undefined
    ) {
        let tacticalMitigationPerformanceRequirements: TmprRequirements | undefined;

        if (value) {
            tacticalMitigationPerformanceRequirements = CATEGORY_KEYS.reduce((result, categoryKey) => {
                if (value?.[categoryKey]?.comment) {
                    return { ...result, [categoryKey]: value?.[categoryKey]?.comment };
                }

                return result;
            }, {});
        }

        this.localStore.patchState({
            tacticalMitigationPerformanceRequirements,
        });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly tacticalMitigationPerformanceRequirementProperty$ = this.localStore.selectByKey(
        "tacticalMitigationPerformanceRequirementProperty"
    );
    protected readonly tacticalMitigationPerformanceRequirements$ = this.localStore.selectByKey(
        "tacticalMitigationPerformanceRequirements"
    );
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    constructor(private readonly localStore: LocalComponentStore<TacticalRiskMitigationMeasuresComponentState>) {
        localStore.setState({
            tacticalMitigationPerformanceRequirementProperty: undefined,
            tacticalMitigationPerformanceRequirements: undefined,
            isExpanded: true,
        });
    }

    protected keepOrder(): number {
        return 0;
    }
}
