import { CylinderEntity, PrismEntity } from "../services/entity-editors/map-entities-editor.service";
import { CartographicEditPoint } from "./cartographic-edit-point";

export type ChildEntitySubtype = PrismEntity | CylinderEntity;

export interface ChildEntityCollection {
    [entityId: string]: ChildEntity;
}

export interface ChildEntity {
    entity: ChildEntitySubtype;
    waypointIndex: number;
}

export enum EntityParentCartographicEditPointType {
    Waypoint = "Waypoint",
    Inlet = "Inlet",
    Outlet = "Outlet",
    EntityParent = "EntityParent",
}

export class EntityParentCartographicEditPoint extends CartographicEditPoint {
    private child: ChildEntitySubtype | undefined;
    private parent: EntityParentCartographicEditPoint | undefined;

    public get childEntity(): ChildEntitySubtype | undefined {
        return this.child;
    }

    public set childEntity(value: ChildEntitySubtype | undefined) {
        this.child = value;
        this.type = value ? EntityParentCartographicEditPointType.EntityParent : EntityParentCartographicEditPointType.Waypoint;
    }

    public get entityParent(): EntityParentCartographicEditPoint | undefined {
        return this.parent;
    }

    public set entityParent(value: EntityParentCartographicEditPoint | undefined) {
        if (this.type === EntityParentCartographicEditPointType.Inlet || this.type === EntityParentCartographicEditPointType.Outlet) {
            this.parent = value;
        }
    }

    public type: EntityParentCartographicEditPointType = EntityParentCartographicEditPointType.Waypoint;
}
