import { Injectable } from "@angular/core";
import {
    BaseNotificationEventsService,
    DssNotification,
    DssNotificationsService,
    DssRestrictionApplicationNotificationType,
} from "@dtm-frontend/dss-shared-lib";
import { Notification, NotificationDisplay, SharedNotificationDisplayProps } from "@dtm-frontend/shared/notifications";
import { IconName } from "@dtm-frontend/shared/ui";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";

@Injectable({
    providedIn: "root",
})
export class InstitutionApplicationNotificationEventsService implements BaseNotificationEventsService {
    constructor(private readonly translocoService: TranslocoService, private readonly dssNotificationsService: DssNotificationsService) {}

    public getEventsList(notificationsToFormat: DssNotification[]): NotificationDisplay[] {
        return notificationsToFormat
            .filter((notification) =>
                this.dssNotificationsService
                    .getDssRestrictionApplicationNotificationTypes()
                    .some((type) => notification.payload.type === type)
            )
            .map((notification) => {
                const sharedProps = this.getSharedProps(notification, "file-text");

                switch (notification.payload.type) {
                    case DssRestrictionApplicationNotificationType.AutomaticallyPublishedEvent:
                        return this.getAutomaticallyPublishedEvent(sharedProps, notification);

                    case DssRestrictionApplicationNotificationType.RejectedRestrictionApplicationEvent:
                        return this.getRejectedRestrictionApplicationEvent(sharedProps, notification);

                    case DssRestrictionApplicationNotificationType.AnspPublishedRestrictionApplicationEvent:
                        return this.getAnspPublishedRestrictionApplicationEvent(sharedProps, notification);

                    case DssRestrictionApplicationNotificationType.UnlockedRestrictionApplicationEvent:
                        return this.getUnlockedRestrictionApplicationEvent(sharedProps, notification);

                    case DssRestrictionApplicationNotificationType.LockedRestrictionApplicationEvent:
                        return this.getLockedRestrictionApplicationEvent(sharedProps, notification);

                    case DssRestrictionApplicationNotificationType.AppliedForInstitutionAcceptEvent:
                        return this.getAppliedForInstitutionAcceptEvent(sharedProps, notification);

                    default:
                        return null;
                }
            })
            .filter(FunctionUtils.isTruthy);
    }

    private getAutomaticallyPublishedEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: DssNotification
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssClient.notifications.restrictionApplicationLabel"),
            text: this.translocoService.translate("dssClient.notifications.automaticallyPublishedText"),
            redirectUrl: `restriction/${notification.payload.id}`,
        };
    }

    private getRejectedRestrictionApplicationEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: DssNotification
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssClient.notifications.restrictionApplicationLabel"),
            text: this.translocoService.translate("dssClient.notifications.rejectedRestrictionApplicationText"),
            redirectUrl: `flight-zone/preview/${notification.payload.id}`,
        };
    }

    private getAnspPublishedRestrictionApplicationEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: DssNotification
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssClient.notifications.restrictionApplicationLabel"),
            text: this.translocoService.translate("dssClient.notifications.anspPublishedRestrictionApplicationText"),
            redirectUrl: `restriction/${notification.payload.id}`,
        };
    }

    private getUnlockedRestrictionApplicationEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: DssNotification
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssClient.notifications.restrictionApplicationLabel"),
            text: this.translocoService.translate("dssClient.notifications.applicationUnlockedText"),
            redirectUrl: `flight-zone/${notification.payload.id}`,
        };
    }

    private getLockedRestrictionApplicationEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: DssNotification
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssClient.notifications.restrictionApplicationLabel"),
            text: this.translocoService.translate("dssClient.notifications.applicationLockedText"),
            redirectUrl: `flight-zone/preview/${notification.payload.id}`,
        };
    }

    private getAppliedForInstitutionAcceptEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: DssNotification
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssClient.notifications.restrictionApplicationLabel"),
            text: this.translocoService.translate("dssClient.notifications.appliedForAcceptText"),
            redirectUrl: `flight-zone/${notification.payload.id}`,
        };
    }

    private getSharedProps(notification: Notification, icon: IconName): SharedNotificationDisplayProps {
        return { icon, date: notification.createdAt, id: notification.id, isRead: notification.read };
    }
}
