import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { IS_PRE_TACTICAL_APPROVAL_ENABLED } from "@dtm-frontend/dss-client-lib/flight-zone";
import { DeviceSize, DeviceSizeService, UIActions, UIState } from "@dtm-frontend/shared/ui";
import { Store } from "@ngxs/store";

@Component({
    selector: "dss-client-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
    public readonly isCollapsed$ = this.store.select(UIState.isMenuCollapsed);

    constructor(
        @Inject(IS_PRE_TACTICAL_APPROVAL_ENABLED) protected isPreTacticalApprovalEnabled: boolean,
        private readonly store: Store,
        private readonly deviceSizeService: DeviceSizeService
    ) {}

    public collapseMenu(): void {
        if (this.deviceSizeService.isSize(DeviceSize.SmartphoneWide, DeviceSize.Smartphone)) {
            this.store.dispatch(new UIActions.SetMenuCollapsedState(true));
        }
    }
}
