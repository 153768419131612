<dtm-ui-form-field
    [isDisabled]="isDisabled$ | ngrxPush"
    [hasErrors]="hasErrors$ | ngrxPush"
    [isActivated]="isActivated"
    *ngrxLet="isActivated$; let isActivated"
>
    <ng-content select="label" ngProjectAs="label"></ng-content>
    <ng-content select=".input-prefix" ngProjectAs="input-prefix"></ng-content>
    <div class="input-field-wrapper">
        <ng-content select=".field-prefix"></ng-content>
        <div class="input-field" [class.activated]="isActivated">
            <ng-content></ng-content>
        </div>
        <button
            type="button"
            tabindex="-1"
            class="field-suffix clear-button"
            *ngIf="isClearButtonVisible$ | ngrxPush"
            (click)="clearValue()"
        >
            <dtm-ui-icon name="close-circle-fill"></dtm-ui-icon>
        </button>
        <ng-content select=".field-suffix"></ng-content>
    </div>
    <ng-content select=".input-suffix" ngProjectAs="input-suffix"></ng-content>
    <ng-content select=".field-hint" ngProjectAs=".field-hint"></ng-content>
    <ng-content select=".field-error" ngProjectAs=".field-error"></ng-content>
</dtm-ui-form-field>
