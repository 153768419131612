<ng-container *ngIf="constraints$ | ngrxPush as constraints; else cannotGetDataErrorTemplate">
    <form [formGroup]="cylinderEditorForm" *ngrxLet="verticalMeasureUnits$; let verticalMeasureUnits">
        <ng-container *ngIf="cylinderEntity$ | ngrxPush">
            <ng-container *ngrxLet="heightReferences$; let heightReferences">
                <div class="multi-input-field">
                    <label for="upperHeightControl">{{ "dssSharedLibFlightZone.applicationSummary.upperHeightLabel" | transloco }}</label>
                    <dtm-ui-input-field>
                        <input
                            *ngrxLet="minUpperHeight$ as minUpperHeight"
                            id="upperHeightControl"
                            matInput
                            [dtmUiDecimalDigitsLimiter]="0"
                            type="number"
                            inputmode="numeric"
                            autocomplete="off"
                            formControlName="upperHeight"
                            required
                            [min]="minUpperHeight"
                            [max]="
                                constraints.max.upperHeight | metersToOtherUnitsOfMeasureConverter : upperHeightUnitFormControl.value : 0
                            "
                        />
                        <div class="field-error">
                            <ng-container
                                [ngTemplateOutlet]="formErrorsTemplate"
                                [ngTemplateOutletContext]="{ formControl: upperHeightFormControl, unit: upperHeightUnitFormControl.value }"
                            ></ng-container>
                        </div>
                    </dtm-ui-input-field>
                    <dtm-ui-select-field
                        formControlName="upperHeightUnit"
                        (selectionChange)="updateHeightValue($event, upperHeightFormControl)"
                        [isClearable]="false"
                    >
                        <dtm-ui-select-option *ngFor="let unit of verticalMeasureUnits" [value]="unit">
                            {{ unit }}
                        </dtm-ui-select-option>
                    </dtm-ui-select-field>
                    <dtm-ui-select-field formControlName="upperHeightReference" [isClearable]="false">
                        <dtm-ui-select-option *ngFor="let reference of heightReferences" [value]="reference">
                            {{ reference }}
                        </dtm-ui-select-option>
                    </dtm-ui-select-field>
                </div>

                <div class="multi-input-field">
                    <label for="lowerHeightControl">
                        {{ "dssSharedLibFlightZone.applicationSummary.lowerHeightLabel" | transloco }}
                        <dtm-ui-popover (click)="$event.preventDefault()">
                            {{ "dssClientLibFlightZone.zoneGeometryStep.groundLevelInfoMessage" | transloco }}
                        </dtm-ui-popover>
                    </label>

                    <dtm-ui-input-field>
                        <input
                            *ngrxLet="maxLowerHeight$ as maxLowerHeight"
                            id="lowerHeightControl"
                            matInput
                            [dtmUiDecimalDigitsLimiter]="0"
                            type="number"
                            inputmode="numeric"
                            autocomplete="off"
                            formControlName="lowerHeight"
                            required
                            [min]="
                                constraints.min.lowerHeight | metersToOtherUnitsOfMeasureConverter : lowerHeightUnitFormControl.value : 0
                            "
                            [max]="maxLowerHeight"
                        />
                        <div class="field-error">
                            <ng-container
                                [ngTemplateOutlet]="formErrorsTemplate"
                                [ngTemplateOutletContext]="{ formControl: lowerHeightFormControl, unit: lowerHeightUnitFormControl.value }"
                            ></ng-container>
                        </div>
                    </dtm-ui-input-field>
                    <dtm-ui-select-field
                        formControlName="lowerHeightUnit"
                        (selectionChange)="updateHeightValue($event, lowerHeightFormControl)"
                        [isClearable]="false"
                    >
                        <dtm-ui-select-option *ngFor="let unit of verticalMeasureUnits" [value]="unit">
                            {{ unit }}
                        </dtm-ui-select-option>
                    </dtm-ui-select-field>
                    <dtm-ui-select-field formControlName="lowerHeightReference" [isClearable]="false">
                        <dtm-ui-select-option *ngFor="let reference of heightReferences" [value]="reference">
                            {{ reference }}
                        </dtm-ui-select-option>
                    </dtm-ui-select-field>
                </div>

                <dtm-ui-dms-coordinates-input
                    [inputLabel]="'dssClientLibFlightZone.zoneGeometryStep.centerPointLatitude' | transloco"
                    formControlName="centerPointLatitude"
                    [geographicCoordinatesType]="GeographicCoordinatesType.Latitude"
                    [isClearable]="true"
                ></dtm-ui-dms-coordinates-input>

                <dtm-ui-dms-coordinates-input
                    [inputLabel]="'dssClientLibFlightZone.zoneGeometryStep.centerPointLongitude' | transloco"
                    formControlName="centerPointLongitude"
                    [geographicCoordinatesType]="GeographicCoordinatesType.Longitude"
                    [isClearable]="true"
                ></dtm-ui-dms-coordinates-input>

                <div class="multi-input-field">
                    <label for="radiusControl">
                        {{ "dssClientLibFlightZone.zoneGeometryStep.cylinderRadiusLabel" | transloco }}
                    </label>
                    <dtm-ui-input-field>
                        <input
                            id="radiusControl"
                            matInput
                            [dtmUiDecimalDigitsLimiter]="1"
                            type="number"
                            inputmode="decimal"
                            autocomplete="off"
                            formControlName="radius"
                            required
                            [min]="constraints.min.size | metersToOtherUnitsOfMeasureConverter : radiusUnitFormControl.value : 1"
                            [max]="constraints.max.size | metersToOtherUnitsOfMeasureConverter : radiusUnitFormControl.value : 1"
                        />
                        <div class="field-error">
                            <ng-container
                                [ngTemplateOutlet]="formErrorsTemplate"
                                [ngTemplateOutletContext]="{ formControl: radiusFormControl, unit: radiusUnitFormControl.value }"
                            ></ng-container>
                        </div>
                    </dtm-ui-input-field>
                    <dtm-ui-select-field formControlName="radiusUnit" (selectionChange)="updateRadiusValue($event)" [isClearable]="false">
                        <dtm-ui-select-option *ngFor="let unit of horizontalMeasureUnits$ | ngrxPush" [value]="unit">
                            {{ unit }}
                        </dtm-ui-select-option>
                    </dtm-ui-select-field>
                </div>
            </ng-container>
        </ng-container>
    </form>
</ng-container>

<ng-template #formErrorsTemplate let-formControl="formControl" let-unit="unit">
    <ng-container *dtmUiFieldHasError="formControl; name: 'required'">
        {{ "dssClientLibFlightZone.requiredFieldErrorHint" | transloco }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="formControl; name: 'min'; error as error">
        {{
            "dssClientLibFlightZone.minValueErrorHint"
                | transloco
                    : {
                          min: error.min | localizeNumber,
                          unit: unit
                      }
        }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="formControl; name: 'max'; error as error">
        {{
            "dssClientLibFlightZone.maxValueErrorHint"
                | transloco
                    : {
                          max: error.max | localizeNumber,
                          unit: unit
                      }
        }}
    </ng-container>
</ng-template>

<ng-template #cannotGetDataErrorTemplate>
    <div>
        {{ "dssClientLibFlightZone.cannotGetDataErrorMessage" | transloco }}
    </div>
</ng-template>
