import { ComponentType } from "@angular/cdk/overlay";
import { Injectable, TemplateRef } from "@angular/core";
import { MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";

@Injectable({ providedIn: "root" })
export class DialogService extends MatLegacyDialog {
    public open<T, D = any, R = any>(component: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>): MatDialogRef<T, R> {
        const panelClass = ["wide-mobile-dialog", config?.panelClass ?? []].flat();

        const modifiedConfig = {
            ...config,
            panelClass,
        };

        return super.open(component, modifiedConfig);
    }
}
