import { Position } from "../services/geolocation.service";

const DECIMAL_PLACES = 8;

function shortenNumber(value: number | string, decimalPlaces = DECIMAL_PLACES): number | undefined {
    if (value === undefined) {
        return undefined;
    }

    if (typeof value === "string") {
        value = parseFloat(value);
    }

    return parseFloat(value.toFixed(decimalPlaces));
}

export function arePositionsEqual(position1: Position, position2: Position): boolean {
    return (
        shortenNumber(position1.latitude) === shortenNumber(position2.latitude) &&
        shortenNumber(position1.longitude) === shortenNumber(position2.longitude)
    );
}
