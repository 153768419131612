import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { FilterType, FiltersMap } from "../../models/filters-map.models";

interface FilterChipsDisplayComponentState {
    filtersMap: FiltersMap[];
    filtersCount: number;
    dictionaryMap: Record<string, string>;
    valueMap: Record<string, string>;
    filtersValue: { [key: string]: unknown } | undefined;
}

@Component({
    selector: "dtm-ui-filter-chips-display[filtersMap][filtersCount][formGroup][filtersValue]",
    templateUrl: "./filter-chips-display.component.html",
    styleUrls: ["./filter-chips-display.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FilterChipsDisplayComponent {
    @Input() public set filtersMap(value: FiltersMap[] | undefined) {
        this.localStore.patchState({ filtersMap: value ?? [] });
    }
    @Input() public set filtersCount(value: number | undefined) {
        this.localStore.patchState({ filtersCount: value ?? 0 });
    }
    @Input() public set dictionaryMap(value: Record<string, string> | undefined) {
        this.localStore.patchState({ dictionaryMap: value });
    }
    @Input() public set valueMap(value: Record<string, string> | undefined) {
        this.localStore.patchState({ valueMap: value });
    }
    @Input() public set filtersValue(value: { [key: string]: unknown }) {
        this.localStore.patchState({ filtersValue: value });
    }
    @Input() public formGroup!: UntypedFormGroup;

    @Output() public allFiltersReset = new EventEmitter<void>();

    public readonly FilterType = FilterType;
    public readonly filtersMap$ = this.localStore.selectByKey("filtersMap");
    public readonly filtersCount$ = this.localStore.selectByKey("filtersCount");
    public readonly dictionaryMap$ = this.localStore.selectByKey("dictionaryMap");
    public readonly valueMap$ = this.localStore.selectByKey("valueMap");
    public readonly filtersValue$ = this.localStore.selectByKey("filtersValue");

    constructor(private readonly localStore: LocalComponentStore<FilterChipsDisplayComponentState>) {
        localStore.setState({ filtersMap: [], filtersCount: 0, dictionaryMap: {}, valueMap: {}, filtersValue: {} });
    }

    public getFiltersChips(value: { [key: string]: unknown } | undefined, key: string): string[] {
        const keys = key.split(".");
        const result = keys.reduce<Record<string, unknown> | unknown>(
            (currentValue, currentKey) => (currentValue as Record<string, unknown>)?.[currentKey],
            { ...value }
        );

        return [result].flat().filter(Boolean).map(String);
    }

    public removeFilterValue<T>(key: string, value: T) {
        const formControl = this.formGroup.get(key);
        const currentValue = formControl?.value;
        if (Array.isArray(currentValue)) {
            formControl?.setValue(currentValue?.filter((itemValue: T) => itemValue !== value));

            return;
        }

        formControl?.setValue(null);
    }
}
