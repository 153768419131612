<ng-container *ngrxLet="{ kmlDataSet: kmlDataSet$ } as vm">
    <h2>
        {{ "dtmMapCesium.kmlViewSettings.title" | transloco }}
        <button type="button" class="button-icon" (click)="kmlImportModalOpen.emit()">
            <dtm-ui-icon name="edit"></dtm-ui-icon>
        </button>
        <button type="button" class="button-icon" (click)="openDeletePrompt()">
            <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
        </button>
    </h2>
    <ul class="file-list">
        <li *ngFor="let kml of vm.kmlDataSet">
            <span>{{ kml.fileInfo.file.name }}</span>
            <button type="button" class="button-icon" (click)="kmlDataZoom.emit(kml)" [disabled]="!kml.data.show">
                <dtm-ui-icon name="road-map"></dtm-ui-icon>
            </button>
            <mat-slide-toggle (change)="handleKmlVisibilityChange(vm.kmlDataSet)" [(ngModel)]="kml.data.show" labelPosition="before">
            </mat-slide-toggle>
        </li>
    </ul>
</ng-container>
