import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UavClass } from "../../models/index";

enum UavCategories {
    A1 = "A1",
    A2 = "A2",
    A3 = "A3",
    STS01 = "STS-01",
    STS02 = "STS-02",
}

interface CategoryGroup {
    tileKey: string;
    descriptionKey: string;
    categories: UavCategories[];
}

interface UavClassWithCategories {
    className?: UavClass;
    categories: UavCategories[];
}

const UAV_CATEGORIES_GROUPS: CategoryGroup[] = [
    {
        tileKey: "dtmSharedMission.uavClassesAndCategoriesInfo.openCategory.title",
        descriptionKey: "dtmSharedMission.uavClassesAndCategoriesInfo.openCategory.description",
        categories: [UavCategories.A1, UavCategories.A2, UavCategories.A3],
    },
    {
        tileKey: "dtmSharedMission.uavClassesAndCategoriesInfo.specificCategory.title",
        descriptionKey: "dtmSharedMission.uavClassesAndCategoriesInfo.specificCategory.description",
        categories: [UavCategories.STS01, UavCategories.STS02],
    },
];

const UAV_CLASSES_AND_CATEGORIES: UavClassWithCategories[] = [
    {
        className: UavClass.C0,
        categories: [UavCategories.A1, UavCategories.A3],
    },
    {
        className: UavClass.C1,
        categories: [UavCategories.A1, UavCategories.A3],
    },
    {
        className: UavClass.C2,
        categories: [UavCategories.A2, UavCategories.A3],
    },
    {
        className: UavClass.C3,
        categories: [UavCategories.A3],
    },
    {
        className: UavClass.C4,
        categories: [UavCategories.A3],
    },
    {
        className: UavClass.C5,
        categories: [UavCategories.STS01],
    },
    {
        className: UavClass.C6,
        categories: [UavCategories.STS02],
    },
    {
        categories: [UavCategories.A1, UavCategories.A3],
    },
];

const TOP_PADDING = 16;

@Component({
    selector: "dtm-mission-uav-classes-and-categories-info",
    templateUrl: "./uav-classes-and-categories-info.component.html",
    styleUrls: ["./uav-classes-and-categories-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UavClassesAndCategoriesInfoComponent {
    protected readonly UAV_CLASSES_AND_CATEGORIES = UAV_CLASSES_AND_CATEGORIES;
    protected readonly UAV_CATEGORIES_GROUPS = UAV_CATEGORIES_GROUPS;
    protected readonly UavCategories = UavCategories;

    protected getUavClassInfo(uavClass: UavClassWithCategories): { isChecked: boolean; isFirstInCategory: boolean }[] {
        return Object.values(UavCategories).map((category) => ({
            isChecked: uavClass.categories.includes(category),
            isFirstInCategory: this.isFirstInCategory(category),
        }));
    }

    protected getShadowHeight(tableHeight: number): string {
        return `calc(${TOP_PADDING}px + ${tableHeight}px)`;
    }

    protected isFirstInCategory(category: UavCategories): boolean {
        return UAV_CATEGORIES_GROUPS.some((group) => group.categories.includes(category) && group.categories[0] === category);
    }
}
