import { FunctionUtils } from "../function-utils/index";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toObject<T extends { [key: string]: any }>(iterable: T[], keyExtractor: string | ((t: T) => string)): { [key: string]: T } {
    return iterable.reduce<{ [key: string]: T }>((result, value) => {
        result[FunctionUtils.isFunction(keyExtractor) ? keyExtractor(value) : value[keyExtractor]] = value;

        return result;
    }, {});
}
