import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { MissionWithRoute } from "../../../models/mission-search.models";

interface ResultMapComponentState {
    missions: MissionWithRoute[];
    isProcessing: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-mission-mission-search-result-map",
    templateUrl: "./result-map.component.html",
    styleUrls: ["./result-map.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ResultMapComponent {
    @Input() public set missions(value: MissionWithRoute[] | undefined) {
        this.localStore.patchState({ missions: value ?? [] });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    protected readonly missions$ = this.localStore.selectByKey("missions");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    constructor(private readonly localStore: LocalComponentStore<ResultMapComponentState>) {
        this.localStore.setState({
            missions: [],
            isProcessing: false,
        });
    }
}
