import { Pipe, PipeTransform } from "@angular/core";
import { MapEntitiesEditorContent, MapEntity } from "../services/entity-editors/map-entities-editor.service";

export function extractMapEntity<R extends MapEntity, T extends R["type"]>(
    content: MapEntitiesEditorContent | undefined,
    type: T
): R | undefined {
    if (content === undefined) {
        return undefined;
    }

    return content.find((entity): entity is R => entity.type === type);
}

@Pipe({
    name: "extractMapEntity",
})
export class ExtractMapEntityPipe implements PipeTransform {
    public transform = extractMapEntity;
}
