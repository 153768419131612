<div aria-live="polite" toastContainer #mapActionsToastContainer class="map-actions-toast-container-wrapper"></div>

<ng-container
    *ngrxLet="{
        activeMapAction: activeMapAction$,
        mode: mode$,
        areEntitiesDrawn: areEntitiesDrawn$,
        activeEntityStatus: activeEntityStatus$,
        maxEntities: maxEntities$,
        canDraw: canDraw$,
        isDisabled: isDisabled$,
    } as vm"
>
    <ng-container [ngSwitch]="vm.mode" *ngIf="!(vm.activeMapAction | invoke : isDrawAction); else drawActionsTemplate">
        <div class="map-actions-container" *ngSwitchCase="MapActionsPanelMode.AssistedEditor" @slideInSide @fade>
            <ng-template [ngTemplateOutlet]="assistedEditorActionsTemplate"></ng-template>
        </div>
        <div class="map-actions-container" *ngSwitchCase="MapActionsPanelMode.CustomEditor" @slideInSide @fade>
            <ng-template [ngTemplateOutlet]="customEditorActionsTemplate"></ng-template>
        </div>
        <div class="map-actions-container" *ngSwitchCase="MapActionsPanelMode.StandardEditor" @slideInSide @fade>
            <ng-template
                [ngTemplateOutlet]="standardEditorActionsTemplate"
                [ngTemplateOutletContext]="{ corridorWidths: [30, 50, 100, 200] }"
            ></ng-template>
        </div>
        <div class="map-actions-container" *ngSwitchCase="MapActionsPanelMode.SupervisorTemporaryZone" @slideInSide @fade>
            <ng-template [ngTemplateOutlet]="supervisorTemporaryZoneActionsTemplate"></ng-template>
        </div>
        <div class="map-actions-container" *ngSwitchCase="MapActionsPanelMode.CylinderAndPrism" @slideInSide @fade>
            <ng-template [ngTemplateOutlet]="specificPermitActionsTemplate"></ng-template>
        </div>
    </ng-container>

    <ng-template #drawActionsTemplate>
        <div class="map-actions-container" @slideInSide @fade>
            <button
                type="button"
                class="map-action-button"
                (click)="emitSelectMapAction({ type: MapActionType.RemoveLastPoint })"
                [disabled]="vm.isDisabled || vm.activeEntityStatus?.canRemovePreviousPoint !== true"
                *ngIf="vm.activeEntityStatus?.canRemovePreviousPoint !== undefined"
            >
                <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                {{ "dtmMapCesium.mapActionsPanel.removeLastPointLabel" | transloco }}
            </button>
            <button
                type="button"
                class="map-action-button destructive-map-action"
                [disabled]="vm.isDisabled"
                (click)="emitSelectMapAction({ type: MapActionType.CancelDrawing })"
            >
                <dtm-ui-icon name="close-circle"></dtm-ui-icon>
                {{ "dtmMapCesium.mapActionsPanel.cancelDrawingLabel" | transloco }}
            </button>
            <button
                type="button"
                class="map-action-button"
                (click)="emitSelectMapAction({ type: MapActionType.FinishDrawing })"
                [disabled]="vm.isDisabled || vm.activeEntityStatus?.canFinish !== true"
                *ngIf="vm.activeEntityStatus?.canFinish !== undefined"
            >
                <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                {{ TRANSLATION_MAPPING[vm.activeMapAction]?.finishDrawing | transloco }}
            </button>
        </div>
    </ng-template>

    <ng-template #customEditorActionsTemplate>
        <button
            type="button"
            class="map-action-button"
            [disabled]="vm.isDisabled || !vm.canDraw"
            (click)="emitSelectMapAction({ type: MapActionType.DrawPolyline })"
        >
            <dtm-ui-icon name="pen-nib-line"></dtm-ui-icon>
            {{ "dtmMapCesium.mapActionsPanel.drawPolylineLabel" | transloco }}
        </button>

        <ng-template [ngTemplateOutlet]="commonEntityActionsTemplate"></ng-template>
    </ng-template>

    <ng-template #assistedEditorActionsTemplate>
        <button type="button" class="map-action-button" [matMenuTriggerFor]="drawMenu" [disabled]="vm.isDisabled">
            <div class="dropdown-button">
                <dtm-ui-icon name="pen-nib"></dtm-ui-icon>
                <dtm-ui-icon [name]="vm.activeMapAction | invoke : getActiveDrawActionIcon" class="sub-icon"></dtm-ui-icon>
                <dtm-ui-icon name="arrow-down" class="dropdown-icon"></dtm-ui-icon>
            </div>
            {{ "dtmMapCesium.mapActionsPanel.drawDropdownLabel" | transloco }}
        </button>
        <mat-menu #drawMenu="matMenu" xPosition="after" yPosition="below" class="map-action-menu">
            <button
                type="button"
                class="map-action-menu-button"
                [disabled]="vm.isDisabled"
                (click)="emitSelectMapAction({ type: MapActionType.DrawAssistedTakeoffRunway })"
            >
                <dtm-ui-icon name="pen-nib"></dtm-ui-icon>
                <dtm-ui-icon name="flight-takeoff" class="sub-icon"></dtm-ui-icon>
                {{ "dtmMapCesium.mapActionsPanel.drawTakeoffRunwayLabel" | transloco }}
            </button>
            <button
                type="button"
                class="map-action-menu-button"
                [disabled]="vm.isDisabled"
                (click)="emitSelectMapAction({ type: MapActionType.DrawAssistedLandingRunway })"
            >
                <dtm-ui-icon name="pen-nib"></dtm-ui-icon>
                <dtm-ui-icon name="flight-land" class="sub-icon"></dtm-ui-icon>
                {{ "dtmMapCesium.mapActionsPanel.drawLandingRunwayLabel" | transloco }}
            </button>
        </mat-menu>

        <ng-template [ngTemplateOutlet]="commonEntityActionsTemplate"></ng-template>
    </ng-template>

    <ng-template #standardEditorActionsTemplate let-corridorWidths="corridorWidths">
        <button
            type="button"
            class="map-action-button"
            (click)="emitSelectMapAction({ type: MapActionType.DrawCylinder })"
            [disabled]="vm.isDisabled || !vm.canDraw"
            [matTooltip]="'dtmMapCesium.mapActionsPanel.maxEntitiesExceededTooltip' | transloco : { maxEntities: vm.maxEntities }"
            [matTooltipDisabled]="vm.canDraw"
        >
            <dtm-ui-icon name="pen-nib-oval"></dtm-ui-icon>
            {{ "dtmMapCesium.mapActionsPanel.drawCylinderLabel" | transloco }}
        </button>
        <button
            type="button"
            class="map-action-button"
            (click)="emitSelectMapAction({ type: MapActionType.DrawPrism })"
            [disabled]="vm.isDisabled || !vm.canDraw"
            [matTooltip]="'dtmMapCesium.mapActionsPanel.maxEntitiesExceededTooltip' | transloco : { maxEntities: vm.maxEntities }"
            [matTooltipDisabled]="vm.canDraw"
        >
            <dtm-ui-icon name="pen-nib-prism"></dtm-ui-icon>
            {{ "dtmMapCesium.mapActionsPanel.drawPrismLabel" | transloco }}
        </button>

        <button
            type="button"
            class="map-action-button"
            [matMenuTriggerFor]="drawMenu"
            [disabled]="vm.isDisabled || !vm.canDraw"
            [matTooltip]="'dtmMapCesium.mapActionsPanel.maxEntitiesExceededTooltip' | transloco : { maxEntities: vm.maxEntities }"
            [matTooltipDisabled]="vm.canDraw"
        >
            <div class="dropdown-button">
                <dtm-ui-icon name="pen-nib"></dtm-ui-icon>
                <dtm-ui-icon name="shape-line" class="sub-icon"></dtm-ui-icon>
                <dtm-ui-icon name="arrow-down" class="dropdown-icon"></dtm-ui-icon>
            </div>
            {{ "dtmMapCesium.mapActionsPanel.drawCorridorLabel" | transloco }}
        </button>
        <mat-menu #drawMenu="matMenu" xPosition="after" yPosition="below" class="map-action-menu">
            <p class="map-action-menu-title">
                {{ "dtmMapCesium.mapActionsPanel.corridorWidthTitleLabel" | transloco }}
            </p>
            <button
                *ngFor="let width of corridorWidths"
                type="button"
                class="map-action-menu-button"
                [disabled]="vm.isDisabled"
                (click)="emitSelectMapAction({ type: MapActionType.DrawPolylineCorridor, payload: { width: width } })"
            >
                {{ "dtmMapCesium.mapActionsPanel.corridorWidthLabel" | transloco : { value: width } }}
            </button>
            <button
                type="button"
                class="map-action-menu-button"
                [disabled]="vm.isDisabled"
                (click)="
                    emitSelectMapAction({
                        type: MapActionType.ManualWidthInputPolylineCorridor,
                        payload: {
                            min: corridorWidths[0],
                            max: corridorWidths[corridorWidths.length - 1],
                            step: 5,
                        }
                    })
                "
            >
                {{ "dtmMapCesium.mapActionsPanel.customCorridorWidthLabel" | transloco }}
            </button>
        </mat-menu>

        <button
            type="button"
            class="map-action-button"
            [matMenuTriggerFor]="manualCoordinatesInputMenu"
            [disabled]="vm.isDisabled || !vm.canDraw"
            [matTooltip]="'dtmMapCesium.mapActionsPanel.maxEntitiesExceededTooltip' | transloco : { maxEntities: vm.maxEntities }"
            [matTooltipDisabled]="vm.canDraw"
        >
            <div class="dropdown-button">
                <dtm-ui-icon name="draft"></dtm-ui-icon>
                <dtm-ui-icon name="arrow-down" class="dropdown-icon"></dtm-ui-icon>
            </div>
            {{ "dtmMapCesium.mapActionsPanel.manualCoordinatesInput" | transloco }}
        </button>
        <mat-menu #manualCoordinatesInputMenu="matMenu" xPosition="after" yPosition="below" class="map-action-menu">
            <button
                type="button"
                class="map-action-menu-button"
                [disabled]="vm.isDisabled"
                (click)="emitSelectMapAction({ type: MapActionType.ManualCoordinatesInputCylinder })"
            >
                <dtm-ui-icon name="shape-cylinder"></dtm-ui-icon>

                {{ "dtmMapCesium.mapActionsPanel.drawCylinderLabel" | transloco }}
            </button>
            <button
                type="button"
                class="map-action-menu-button"
                [disabled]="vm.isDisabled"
                (click)="emitSelectMapAction({ type: MapActionType.ManualCoordinatesInputPolylineCorridor })"
            >
                <dtm-ui-icon name="shape-line"></dtm-ui-icon>
                {{ "dtmMapCesium.mapActionsPanel.drawCorridorLabel" | transloco }}
            </button>
        </mat-menu>

        <ng-template [ngTemplateOutlet]="commonEntityActionsTemplate"></ng-template>
    </ng-template>

    <ng-template #supervisorTemporaryZoneActionsTemplate>
        <ng-template
            [ngTemplateOutlet]="standardEditorActionsTemplate"
            [ngTemplateOutletContext]="{ corridorWidths: [100, 200, 500, 1000, 2000] }"
        ></ng-template>
    </ng-template>

    <ng-template #specificPermitActionsTemplate>
        <button
            type="button"
            class="map-action-button"
            (click)="emitSelectMapAction({ type: MapActionType.DrawCylinder })"
            [disabled]="vm.isDisabled || !vm.canDraw"
            [matTooltip]="'dtmMapCesium.mapActionsPanel.maxEntitiesExceededTooltip' | transloco : { maxEntities: vm.maxEntities }"
            [matTooltipDisabled]="vm.canDraw"
        >
            <dtm-ui-icon name="pen-nib-oval"></dtm-ui-icon>
            {{ "dtmMapCesium.mapActionsPanel.drawCircleLabel" | transloco }}
        </button>
        <button
            type="button"
            class="map-action-button"
            (click)="emitSelectMapAction({ type: MapActionType.DrawPrism })"
            [disabled]="vm.isDisabled || !vm.canDraw"
            [matTooltip]="'dtmMapCesium.mapActionsPanel.maxEntitiesExceededTooltip' | transloco : { maxEntities: vm.maxEntities }"
            [matTooltipDisabled]="vm.canDraw"
        >
            <dtm-ui-icon name="pen-nib-prism"></dtm-ui-icon>
            {{ "dtmMapCesium.mapActionsPanel.drawPolygonLabel" | transloco }}
        </button>
        <ng-template [ngTemplateOutlet]="commonEntityActionsTemplate"></ng-template>
    </ng-template>

    <ng-template #commonEntityActionsTemplate>
        <div class="map-action-spacing"></div>
        <button
            type="button"
            class="map-action-button destructive-map-action"
            (click)="clearEntities()"
            [disabled]="vm.isDisabled || !vm.areEntitiesDrawn"
        >
            <dtm-ui-icon name="close"></dtm-ui-icon>
            {{ "dtmMapCesium.mapActionsPanel.removeContentLabel" | transloco }}
        </button>
        <button
            type="button"
            class="map-action-button"
            (click)="emitSelectMapAction({ type: MapActionType.ShowEntireContent })"
            [disabled]="vm.isDisabled || !vm.areEntitiesDrawn"
        >
            <dtm-ui-icon name="eye"></dtm-ui-icon>
            {{ "dtmMapCesium.mapActionsPanel.showEntireContentLabel" | transloco }}
        </button>
    </ng-template>
</ng-container>
