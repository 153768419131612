import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ApplicationType, FlightZoneCapabilitiesResolver } from "@dtm-frontend/dss-shared-lib";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { ApplicationDraftListContainerComponent } from "./components/application-draft-list/container/application-draft-list-container.component";
import { ApplicationPreviewComponent } from "./components/application-preview/application-preview.component";
import { FlightZoneWizardContentComponent } from "./components/flight-zone-wizard/flight-zone-wizard-content/flight-zone-wizard-content.component";
import { FlightZoneWizardHeaderComponent } from "./components/flight-zone-wizard/flight-zone-wizard-header/flight-zone-wizard-header.component";
import { RestrictionPreviewComponent } from "./components/restriction-preview/restriction-preview.component";
import { RestrictionsListContainerComponent } from "./components/restrictions-list/container/restrictions-list-container.component";
import { SubmittedApplicationsListContainerComponent } from "./components/submitted-list/submitted-applications-container/submitted-applications-list-container.component";
import { SubmittedModificationsListContainerComponent } from "./components/submitted-list/submitted-modifications-container/submitted-modifications-list-container.component";
import { RestrictionListRoute } from "./models/flight-zone.models";
import {
    FLIGHT_ZONE_APPLICATION_ROUTE_SEGMENT,
    FLIGHT_ZONE_ID_ROUTE_PARAM_NAME,
    FLIGHT_ZONE_MODIFICATION_ROUTE_SEGMENT,
    FLIGHT_ZONE_PREVIEW_ROUTE_SEGMENT,
    FlightZoneApplicationDataClearResolver,
    FlightZoneApplicationDataResolver,
    FlightZoneRestrictionDataResolver,
} from "./services/flight-zone.resolvers";
import { FlightZoneListsActions } from "./state/flight-zone-lists.actions";
import { FlightZoneListsState } from "./state/flight-zone-lists.state";

const restrictionListRoutes: RestrictionListRoute[] = [
    {
        path: "flight-zone/active-restrictions",
        component: RestrictionsListContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssClientLibFlightZone.routeTitles.activeRestrictionsList",
            selector: FlightZoneListsState.activeRestrictionsList,
            action: FlightZoneListsActions.GetActiveRestrictionsList,
        },
    },
    {
        path: "flight-zone/ended-restrictions",
        component: RestrictionsListContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssClientLibFlightZone.routeTitles.endedRestrictionsList",
            selector: FlightZoneListsState.endedRestrictionsList,
            action: FlightZoneListsActions.GetEndedRestrictionsList,
        },
    },
];

const routes: Routes = [
    {
        path: "flight-zone/submitted-applications",
        component: SubmittedApplicationsListContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssClientLibFlightZone.routeTitles.submittedApplicationsList",
        },
    },
    {
        path: "modifications/submitted-list",
        component: SubmittedModificationsListContainerComponent,
        data: {
            titleTranslationKey: "dssClientLibFlightZone.routeTitles.submittedModificationsList",
        },
    },
    ...restrictionListRoutes,
    {
        path: "flight-zone/application-draft-list",
        component: ApplicationDraftListContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dssClientLibFlightZone.routeTitles.flightZoneApplicationDraftList",
        },
    },
    {
        path: "flight-zone/new",
        component: FlightZoneWizardContentComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            initCapabilities: FlightZoneCapabilitiesResolver,
            clearData: FlightZoneApplicationDataClearResolver,
        },
        data: {
            titleTranslationKey: "dssClientLibFlightZone.routeTitles.newFlightZone",
            headerComponent: FlightZoneWizardHeaderComponent,
        },
    },
    {
        path: `${FLIGHT_ZONE_APPLICATION_ROUTE_SEGMENT}/${FLIGHT_ZONE_PREVIEW_ROUTE_SEGMENT}/:${FLIGHT_ZONE_ID_ROUTE_PARAM_NAME}`,
        component: ApplicationPreviewComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            initData: FlightZoneApplicationDataResolver,
            initCapabilities: FlightZoneCapabilitiesResolver,
        },
        data: {
            titleTranslationKey: "dssClientLibFlightZone.routeTitles.newFlightZone",
            applicationType: ApplicationType.RestrictionApplication,
        },
    },
    {
        path: `${FLIGHT_ZONE_APPLICATION_ROUTE_SEGMENT}/:${FLIGHT_ZONE_ID_ROUTE_PARAM_NAME}`,
        component: FlightZoneWizardContentComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            initData: FlightZoneApplicationDataResolver,
            initCapabilities: FlightZoneCapabilitiesResolver,
        },
        data: {
            titleTranslationKey: "dssClientLibFlightZone.routeTitles.newFlightZone",
            headerComponent: FlightZoneWizardHeaderComponent,
            applicationType: ApplicationType.RestrictionApplication,
        },
    },
    {
        path: `restriction/:${FLIGHT_ZONE_ID_ROUTE_PARAM_NAME}`,
        component: RestrictionPreviewComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            initData: FlightZoneRestrictionDataResolver,
            initCapabilities: FlightZoneCapabilitiesResolver,
        },
        data: {
            titleTranslationKey: "dssClientLibFlightZone.routeTitles.restrictionPreview",
        },
    },
    {
        path: `${FLIGHT_ZONE_MODIFICATION_ROUTE_SEGMENT}/${FLIGHT_ZONE_PREVIEW_ROUTE_SEGMENT}/:${FLIGHT_ZONE_ID_ROUTE_PARAM_NAME}`,
        component: ApplicationPreviewComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            initData: FlightZoneApplicationDataResolver,
            initCapabilities: FlightZoneCapabilitiesResolver,
        },
        data: {
            titleTranslationKey: "dssClientLibFlightZone.routeTitles.restrictionModificationPreview",
            applicationType: ApplicationType.RestrictionModificationApplication,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        FlightZoneCapabilitiesResolver,
        FlightZoneApplicationDataResolver,
        FlightZoneApplicationDataClearResolver,
        FlightZoneRestrictionDataResolver,
    ],
})
export class FlightZoneRoutingModule {}
