import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MissionPlanRoute, ProcessedFile, TimeRange } from "@dtm-frontend/shared/ui";
import {
    AnimationUtils,
    ArrayUtils,
    FunctionUtils,
    LocalComponentStore,
    MILLISECONDS_IN_HOUR,
    RxjsUtils,
} from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { AcMapComponent, AcMapLayerProviderComponent, MapLayerProviderOptions } from "@pansa/ngx-cesium";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatestWith, map, tap } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import {
    AirspaceElement,
    AirspaceElementStatus,
    GeoZoneType,
    GesZonesError,
    TimeSettingOptions,
    ZoneTimesSetting,
} from "../../../geo-zones/models/geo-zones.models";
import { AirspaceElementsSearchOptions } from "../../../geo-zones/services/geo-zones-api.service";
import { GeoZonesActions } from "../../../geo-zones/state/geo-zones.actions";
import { GeoZonesState } from "../../../geo-zones/state/geo-zones.state";
import { MapLayer, SHARED_MAP_ENDPOINTS, SharedMapEndpoints } from "../../../shared/shared-map.tokens";
import { WeatherState, WeatherViewMode } from "../../../weather/state";
import { WeatherStatus } from "../../models/weather.models";
import { CesiumPointerManagerService, CesiumPointerType } from "../../services/pointer-manager/cesium-pointer-manager.service";
import { GeoZonesFilters } from "./controls/filters/geographical-zones-filters.component";
import { LocalSpatialInfoForDtmSettings } from "./controls/local-spatial-info-for-dtm/local-spatial-info-for-dtm-settings.component";
import { GeoZonesSettings } from "./controls/zone-display-settings/geographical-zones-settings.component";
import { convertActivityTimeRange } from "./geographical-zone.uitls";
import { KmlData, KmlDataControlService } from "./kml/kml-data-control.service";
import { KmlImportModalComponent } from "./kml/kml-import-modal/kml-import-modal.component";
import { ViewControl } from "./map-controls.models";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

enum ControlPanel {
    Weather = "Weather",
    GeoZones = "GeoZones",
    MissionViewSettings = "MissionViewSettings",
    MapLayers = "MapLayers",
    KmlControls = "KmlControls",
}

interface GeographicalZonesComponentState {
    geoZonesControls: GeoZonesControls;
    missionTimeRange: TimeRange | undefined;
    openedPanel: ControlPanel | undefined;
    routes: MissionPlanRoute[];
    nearbyMissionRoutes: MissionPlanRoute[];
    shouldShowAreaBuffers: boolean;
    areAreaBuffersProcessing: boolean;
    viewControls: ViewControl[];
    viewControlsValue: Partial<Record<ViewControl, boolean>>;
    mapLayers: MapLayer[];
    mapLayersValue: Partial<Record<MapLayer, boolean>>;
    shouldWatchZonesUpdates: boolean;
    timeSettingsOptions: TimeSettingOptions | undefined;
    isKmlImportEnabled: boolean;
    kmlFiles: ProcessedFile[];
    globalOpacity: number;
    selectedZoneTimesSetting: ZoneTimesSetting | null;
}

export interface GeoZonesControls {
    geoZonesFilters?: GeoZonesFilters | null;
    geoZonesSettings?: GeoZonesSettings | null;
    localSpatialInfoForDtmSettings?: LocalSpatialInfoForDtmSettings | null;
}

const DEFAULT_CONTROLS = [ViewControl.SafetyArea, ViewControl.GroundRiskBuffer];
const DEFAULT_LAYERS = [MapLayer.SoraBoxes, MapLayer.Obstacles];
const MAXIMUM_UPDATE_TIME_INTERVAL = MILLISECONDS_IN_HOUR / 2;

const MAP_INFO_ID = "MAP_INFO";

@UntilDestroy()
@Component({
    selector: "dtm-map-layers-with-controls[timeSettingOptions]",
    templateUrl: "./map-layers-with-controls.component.html",
    styleUrls: ["./map-layers-with-controls.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, KmlDataControlService],
    animations: [AnimationUtils.slideInAnimation()],
})
export class MapLayersWithControlsComponent implements OnDestroy, AfterViewInit {
    @Input() public set missionTimeRange(value: TimeRange | undefined) {
        this.localStore.patchState({ missionTimeRange: value });
    }
    @Input() public set routes(value: MissionPlanRoute[] | undefined) {
        this.localStore.patchState({ routes: value ?? [] });
    }
    @Input() public set shouldShowAreaBuffers(value: BooleanInput) {
        this.localStore.patchState({ shouldShowAreaBuffers: coerceBooleanProperty(value) });
    }
    @Input() public set areAreaBuffersProcessing(value: BooleanInput) {
        this.localStore.patchState({ areAreaBuffersProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set viewControls(value: ViewControl[] | undefined) {
        this.localStore.patchState({ viewControls: value ?? [] });
    }
    @Input() public set viewControlsValue(value: Partial<Record<ViewControl, boolean>> | undefined) {
        this.localStore.patchState({ viewControlsValue: value ?? {} });
    }
    @Input() public set mapLayers(value: MapLayer[] | undefined) {
        this.localStore.patchState({ mapLayers: value ?? [] });
    }
    @Input() public set mapLayersValue(value: Partial<Record<MapLayer, boolean>> | undefined) {
        this.localStore.patchState({ mapLayersValue: value ?? {} });
    }
    @Input() public set timeSettingOptions(value: TimeSettingOptions | undefined) {
        this.localStore.patchState({ timeSettingsOptions: value });
    }
    @Input() public set isKmlImportEnabled(value: BooleanInput) {
        this.localStore.patchState({ isKmlImportEnabled: coerceBooleanProperty(value) });
    }

    @Output() public readonly viewControlsValueChange = new EventEmitter<Partial<Record<ViewControl, boolean>>>();
    @Output() public readonly mapLayersValueChange = new EventEmitter<Partial<Record<MapLayer, boolean>>>();

    @ViewChild("geoZonesControls", { read: ElementRef }) public geoZonesControls: ElementRef<HTMLElement> | undefined;
    @ViewChild("wmsCesiumProvider", { read: AcMapLayerProviderComponent }) public wmsCesiumProvider:
        | AcMapLayerProviderComponent
        | undefined;

    protected readonly isGeoZonesDataLoading$ = this.store.select(GeoZonesState.isGeoZonesDataLoading);
    protected readonly weatherRangeList$ = this.store.select(WeatherState.weatherRangeList);
    protected readonly weatherByMissionTime$ = this.store.select(WeatherState.weatherByMissionTime);
    protected readonly selectedWeatherRangeIndex$ = this.store.select(WeatherState.selectedWeatherRangeIndex);
    protected readonly isWeatherProcessing$ = this.store.select(WeatherState.isProcessing);
    protected readonly isWeatherPanelOpen$ = this.store.select(WeatherState.isWeatherPanelOpen);
    protected readonly isWithinDtm$ = this.store.select(WeatherState.isWithinDtm);
    protected readonly forecastRefTime$ = this.store.select(WeatherState.forecastRefTime);
    protected readonly weatherViewMode$ = this.store.select(WeatherState.weatherViewMode);
    protected readonly weatherMissionStatus$ = this.store.select(WeatherState.weatherMissionStatus);
    protected readonly geoZonesControls$ = this.localStore.selectByKey("geoZonesControls");
    private readonly refreshGeoZonesSubject = new BehaviorSubject<void>(undefined);
    protected readonly geoZones$ = this.prepareGeoZones();
    protected readonly customZonesLayersData$ = this.store
        .select(GeoZonesState.customZonesLayersData)
        .pipe(map((zoneData) => zoneData?.airspaceElements));
    protected readonly localSpatialInfoData$ = this.store
        .select(GeoZonesState.geoZonesLayersData)
        .pipe(map((geoZones) => geoZones.filter((zone) => zone.geoZoneType === GeoZoneType.Local)));
    protected readonly missionTimeRange$ = this.localStore.selectByKey("missionTimeRange");
    protected readonly selectedZoneId$ = this.store.select(GeoZonesState.selectedZoneId);
    protected readonly openedPanel$ = this.localStore.selectByKey("openedPanel");
    protected readonly routes$ = this.localStore.selectByKey("routes");
    protected readonly shouldShowAreaBuffers$ = this.localStore.selectByKey("shouldShowAreaBuffers");
    protected readonly areAreaBuffersProcessing$ = this.localStore.selectByKey("areAreaBuffersProcessing");
    protected readonly viewControls$ = this.localStore.selectByKey("viewControls");
    protected readonly viewControlsValue$ = this.localStore.selectByKey("viewControlsValue");
    protected readonly mapLayers$ = this.localStore.selectByKey("mapLayers");
    protected readonly mapLayersValue$ = this.localStore.selectByKey("mapLayersValue");
    protected readonly isGeoZonesInfoEnabled$ = this.store.select(GeoZonesState.isGeoZonesInfoEnabled);
    protected readonly timeSettingsOptions$ = this.localStore.selectByKey("timeSettingsOptions");
    protected readonly geoZonesWithInfo$ = this.store.select(GeoZonesState.zonesWithInfo);
    protected readonly aupEndTime$ = this.store.select(GeoZonesState.aupEndTime);
    protected readonly isZoneInfoError$ = this.store
        .select(GeoZonesState.geoZonesError)
        .pipe(map((error) => error === GesZonesError.ZonesWithInfoLoadingError));
    protected readonly isKmlImportEnabled$ = this.localStore.selectByKey("isKmlImportEnabled");
    protected readonly kmlFiles$ = this.kmlControlService.kmlDataSet$;
    protected readonly globalOpacity$ = this.localStore.selectByKey("globalOpacity");
    protected readonly selectedZoneTimesSetting$ = this.localStore.selectByKey("selectedZoneTimesSetting");

    protected readonly WeatherViewMode = WeatherViewMode;
    protected readonly WeatherStatus = WeatherStatus;
    protected readonly ControlPanel = ControlPanel;
    protected readonly MapLayerProviderOptions = MapLayerProviderOptions;
    protected readonly ZoneTimesSetting = ZoneTimesSetting;

    private refreshTimer: ReturnType<typeof setTimeout> | undefined;

    @HostListener("document:click", ["$event"])
    protected onClickOutside(event: MouseEvent): void {
        if (!this.acMap?.getMapContainer()?.contains(event.target as HTMLElement)) {
            return;
        }

        const openedPanel = this.localStore.selectSnapshotByKey("openedPanel");
        if (openedPanel && !this.element?.nativeElement?.contains(event.target)) {
            this.localStore.patchState({ openedPanel: undefined });
        }
    }

    constructor(
        protected readonly kmlControlService: KmlDataControlService,
        private readonly localStore: LocalComponentStore<GeographicalZonesComponentState>,
        private readonly store: Store,
        private readonly element: ElementRef,
        private readonly acMap: AcMapComponent,
        @Inject(SHARED_MAP_ENDPOINTS) protected readonly sharedMapEndpoints: SharedMapEndpoints,
        private readonly dialog: MatDialog,
        private readonly cesiumPointerManagerService: CesiumPointerManagerService,
        private readonly toastrService: ToastrService,
        private readonly transloco: TranslocoService
    ) {
        localStore.setState({
            geoZonesControls: {},
            missionTimeRange: undefined,
            openedPanel: undefined,
            routes: [],
            shouldShowAreaBuffers: false,
            areAreaBuffersProcessing: false,
            viewControls: DEFAULT_CONTROLS,
            viewControlsValue: {},
            mapLayers: DEFAULT_LAYERS,
            mapLayersValue: { [MapLayer.SoraBoxes]: true, [MapLayer.Obstacles]: false },
            nearbyMissionRoutes: [],
            shouldWatchZonesUpdates: false,
            timeSettingsOptions: undefined,
            kmlFiles: [],
            isKmlImportEnabled: false,
            globalOpacity: 1,
            selectedZoneTimesSetting: null,
        });

        this.store
            .select(WeatherState.weatherViewMode)
            .pipe(
                filter((value) => value === WeatherViewMode.Hide),
                tap(() => this.localStore.patchState({ openedPanel: undefined })),
                untilDestroyed(this)
            )
            .subscribe();

        this.geoZonesControls$
            .pipe(untilDestroyed(this))
            .subscribe(({ geoZonesSettings, geoZonesFilters, localSpatialInfoForDtmSettings }) => {
                const options: AirspaceElementsSearchOptions = {
                    scope: {
                        upperLimit: geoZonesFilters?.zoneHeight ?? undefined,
                        startTime: geoZonesFilters?.timeFrom,
                        endTime: geoZonesFilters?.timeTo,
                    },
                    includeTemporary: geoZonesFilters?.shouldIncludeTemporaryZones,
                };

                if (geoZonesSettings?.areGeoZonesEnabled) {
                    options.geozones = geoZonesSettings ? this.convertGeoZoneSettingsToZoneList(geoZonesSettings) : [];
                }

                if (localSpatialInfoForDtmSettings?.isLocalSpatialInfoForDtmEnabled) {
                    options.includeLocal = true;
                    options.geozones = [...(options.geozones ?? []), GeoZoneType.Local];
                    const { isLocalSpatialInfoForDtmEnabled, ...localInformationTypesDate } = { ...localSpatialInfoForDtmSettings };
                    options.localInformationTypes = Object.entries(localInformationTypesDate)
                        .map(([key, value]) => (value ? key : undefined))
                        .filter(FunctionUtils.isTruthy);
                }

                this.store.dispatch(new GeoZonesActions.SearchAirspaceElements(options, true)).subscribe(() => {
                    if (this.store.selectSnapshot(GeoZonesState.geoZonesError) === GesZonesError.DataLoadingError) {
                        const errorText = this.transloco.translate("dtmMapCesium.zonesLoadingError");
                        this.toastrService.error(errorText);
                    }
                });
            });

        this.isWeatherPanelOpen$.pipe(untilDestroyed(this)).subscribe((shouldOpen) => {
            const openedPanel = this.localStore.selectSnapshotByKey("openedPanel");

            if (openedPanel !== ControlPanel.Weather && shouldOpen) {
                this.localStore.patchState({ openedPanel: ControlPanel.Weather });

                return;
            }

            if (openedPanel === ControlPanel.Weather && !shouldOpen) {
                this.localStore.patchState({ openedPanel: undefined });
            }
        });

        this.store.dispatch(GeoZonesActions.StartAirspaceUpdatesWatch);

        this.isGeoZonesInfoEnabled$.pipe(untilDestroyed(this)).subscribe((isInfoEnabled) => {
            if (isInfoEnabled) {
                this.cesiumPointerManagerService.setPointerType(MAP_INFO_ID, CesiumPointerType.Info);
            } else {
                this.cesiumPointerManagerService.removePointer(MAP_INFO_ID);
            }
        });
    }

    public ngAfterViewInit(): void {
        if (this.wmsCesiumProvider) {
            this.watchForMapLayersChange(this.wmsCesiumProvider);
        }
    }

    private watchForMapLayersChange(wmsCesiumProvider: AcMapLayerProviderComponent) {
        this.mapLayersValue$
            .pipe(
                map((mapLayersValue) => this.getVisibleMapLayersQueryParameter(mapLayersValue)),
                distinctUntilChanged(),
                untilDestroyed(this)
            )
            .subscribe((mapLayersValue) => {
                wmsCesiumProvider.imageryLayersCollection.remove(wmsCesiumProvider.imageryLayer, false);

                if (!mapLayersValue) {
                    return;
                }

                wmsCesiumProvider.imageryLayer = wmsCesiumProvider.imageryLayersCollection.addImageryProvider(
                    new Cesium.WebMapServiceImageryProvider({
                        ...wmsCesiumProvider.options,
                        layers: mapLayersValue,
                    })
                );
            });
    }

    protected togglePanel(controlPanel: ControlPanel) {
        this.localStore.patchState(({ openedPanel }) => ({
            openedPanel: openedPanel === controlPanel ? undefined : controlPanel,
        }));
    }

    protected handeGeographicalZonesFilterSettingsUpdate(geoZonesSettings: GeoZonesControls) {
        this.localStore.patchState({ geoZonesControls: geoZonesSettings });
    }

    private convertGeoZoneSettingsToZoneList({ draR, draP, draRAirport, shouldShowDraI }: GeoZonesSettings) {
        const geozones: GeoZoneType[] = [];
        if (draP.isEnabled && (draP.settings.shouldShowStatic || draP.settings.shouldShowElastic)) {
            geozones.push(GeoZoneType.DroneAirspaceProhibited);
        }

        if (draR.isEnabled && (draR.settings.shouldShowStatic || draR.settings.shouldShowElastic)) {
            geozones.push(GeoZoneType.DroneAirspaceRestricted);
        }

        const draRAirportSettings = draRAirport.settings;
        if (draRAirport.isEnabled) {
            if (draRAirportSettings.shouldShowDraRh) {
                geozones.push(GeoZoneType.DroneAirspaceRestrictedHigh);
            }

            if (draRAirportSettings.shouldShowDraRh6km) {
                geozones.push(GeoZoneType.DroneAirspaceRestrictedHigh6Km);
            }

            if (draRAirportSettings.shouldShowDraRh) {
                geozones.push(GeoZoneType.DroneAirspaceRestrictedHigh);
            }

            if (draRAirportSettings.shouldShowDraRl) {
                geozones.push(GeoZoneType.DroneAirspaceRestrictedLow);
            }

            if (draRAirportSettings.shouldShowDraRm) {
                geozones.push(GeoZoneType.DroneAirspaceRestrictedMedium);
            }
        }

        if (shouldShowDraI) {
            geozones.push(GeoZoneType.DroneAirspaceInformation);
        }

        return geozones;
    }

    private filterGeoZones(geoZones: AirspaceElement[]): AirspaceElement[] {
        const geoZonesSettings = this.localStore.selectSnapshotByKey("geoZonesControls")?.geoZonesSettings;
        if (!geoZonesSettings) {
            return geoZones;
        }

        const { draR, draP, draRAirport } = geoZonesSettings;

        return geoZones.filter((zone) => {
            if (zone.geoZoneType === GeoZoneType.DroneAirspaceProhibited && !draP.isEnabled) {
                return false;
            }

            if (zone.geoZoneType === GeoZoneType.DroneAirspaceRestricted && !draR.isEnabled) {
                return false;
            }

            if (
                [
                    GeoZoneType.DroneAirspaceRestrictedHigh6Km,
                    GeoZoneType.DroneAirspaceRestrictedHigh,
                    GeoZoneType.DroneAirspaceRestrictedMedium,
                    GeoZoneType.DroneAirspaceRestrictedLow,
                ].includes(zone.geoZoneType) &&
                !draRAirport.isEnabled
            ) {
                return false;
            }

            if (zone.geoZoneType === GeoZoneType.DroneAirspaceProhibited) {
                return (draP.settings.shouldShowStatic && zone.isStatic) || (draP.settings.shouldShowElastic && !zone.isStatic);
            }

            if (zone.geoZoneType === GeoZoneType.DroneAirspaceRestricted) {
                return (draR.settings.shouldShowStatic && zone.isStatic) || (draR.settings.shouldShowElastic && !zone.isStatic);
            }

            if (zone.geoZoneType === GeoZoneType.DroneAirspaceRestrictedHigh6Km && !draRAirport.settings.shouldShowDraRh6km) {
                return false;
            }

            if (zone.geoZoneType === GeoZoneType.DroneAirspaceRestrictedHigh && !draRAirport.settings.shouldShowDraRh) {
                return false;
            }

            if (zone.geoZoneType === GeoZoneType.DroneAirspaceRestrictedLow && !draRAirport.settings.shouldShowDraRl) {
                return false;
            }

            if (zone.geoZoneType === GeoZoneType.DroneAirspaceRestrictedMedium && !draRAirport.settings.shouldShowDraRm) {
                return false;
            }

            return true;
        });
    }

    public ngOnDestroy(): void {
        this.store.dispatch(GeoZonesActions.Cleanup);
        if (this.refreshTimer) {
            clearTimeout(this.refreshTimer);
            this.refreshTimer = undefined;
        }
    }

    protected updateViewControlsValue(viewControlsValue: Partial<Record<ViewControl, boolean>>) {
        this.localStore.patchState({ viewControlsValue });
        this.viewControlsValueChange.emit(viewControlsValue);
    }

    protected updateMapLayersValue(mapLayersValue: Partial<Record<MapLayer, boolean>>) {
        this.localStore.patchState({ mapLayersValue });
        this.mapLayersValueChange.emit(mapLayersValue);
    }

    protected selectZoneById(zone: AirspaceElement | undefined) {
        this.store.dispatch(new GeoZonesActions.SetSelectedZoneId(zone?.id));
    }

    protected getZonesWithInfo(designators: string[]) {
        const geoZonesFilters = this.localStore.selectSnapshotByKey("geoZonesControls")?.geoZonesFilters;
        const options: AirspaceElementsSearchOptions = {
            scope: {
                upperLimit: geoZonesFilters?.zoneHeight ?? undefined,
                startTime: geoZonesFilters?.timeFrom,
                endTime: geoZonesFilters?.timeTo,
            },
            designators,
        };

        if (geoZonesFilters?.timeFrom && geoZonesFilters?.timeTo && options.scope) {
            const { timeFrom, timeTo } = convertActivityTimeRange(geoZonesFilters.timeFrom, geoZonesFilters.timeTo);
            options.scope.startTime = timeFrom;
            options.scope.endTime = timeTo;
        }

        this.store.dispatch(new GeoZonesActions.GetAirspaceElementsWithInfo(options)).subscribe(() => {
            if (this.store.selectSnapshot(GeoZonesState.geoZonesError) === GesZonesError.ZonesWithInfoLoadingError) {
                const errorText = this.transloco.translate("dtmMapCesium.zoneDetailsLoadingError");
                this.toastrService.error(errorText);
            }
        });
    }

    protected onWatchZonesSettingUpdate(shouldWatch: boolean) {
        this.localStore.patchState({ shouldWatchZonesUpdates: shouldWatch });
    }

    protected openKmlImportModal() {
        this.dialog
            .open(KmlImportModalComponent, { data: this.kmlControlService.kmlFiles })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((files) => {
                this.kmlControlService.setKmlData(files);
            });
    }

    protected isKmlIconDisabled(kmlDataSet: KmlData[]): boolean {
        return !kmlDataSet.some(({ data }) => data.show);
    }

    protected setGlobalOpacity(opacity: number) {
        this.localStore.patchState({ globalOpacity: opacity });
    }

    protected setZoneTimesSetting(zoneTimesSetting: ZoneTimesSetting | null) {
        this.localStore.patchState({ selectedZoneTimesSetting: zoneTimesSetting });
    }

    private getVisibleMapLayersQueryParameter(layers: Partial<Record<MapLayer, boolean>>): string | undefined {
        const uniqueLayers = ArrayUtils.unique(
            Object.entries(layers)
                .filter(([, isVisible]) => isVisible)
                .map(([layer]) => layer)
                .reduce<string[]>((result, layer) => {
                    if (this.sharedMapEndpoints.layers?.[layer as MapLayer]) {
                        result.push(...this.sharedMapEndpoints.layers[layer as MapLayer]);
                    }

                    return result;
                }, [])
        ).join(",");

        if (!uniqueLayers || !this.sharedMapEndpoints.layers) {
            return;
        }

        return `${this.sharedMapEndpoints.layersPrefix ?? ""}${uniqueLayers}`;
    }

    private prepareGeoZones() {
        return this.store.select(GeoZonesState.geoZonesLayersData).pipe(
            combineLatestWith(this.localStore.selectByKey("shouldWatchZonesUpdates"), this.refreshGeoZonesSubject),
            tap(([geoZones, shouldWatchZonesUpdates]) => this.scheduleNextZonesUpdate(geoZones, shouldWatchZonesUpdates)),
            map(([geoZones, shouldWatchZonesUpdates]) => this.updateZones(geoZones, shouldWatchZonesUpdates)),
            map((geoZones) => this.filterGeoZones(geoZones))
        );
    }

    private scheduleNextZonesUpdate(zones: AirspaceElement[], shouldWatchZonesUpdates: boolean) {
        if (this.refreshTimer) {
            clearTimeout(this.refreshTimer);
            this.refreshTimer = undefined;
        }

        if (!shouldWatchZonesUpdates || !zones.length) {
            return;
        }

        const currentDateTime = this.getDateTime();
        let nextUpdateTime = currentDateTime + MAXIMUM_UPDATE_TIME_INTERVAL;

        for (const zone of zones) {
            const scopeUpdateTime =
                zone.scope && zone.scope.endTime.getTime() > currentDateTime ? zone.scope?.endTime.getTime() : nextUpdateTime;
            const reservationUpdateTime = zone.reservations
                .filter(({ scope: { endTime } }) => endTime.getTime() > currentDateTime)
                .reduce((updateTime, reservation) => Math.min(updateTime, reservation.scope.endTime.getTime()), nextUpdateTime);

            const closestUpdateTime = Math.min(scopeUpdateTime, reservationUpdateTime);

            if (closestUpdateTime < nextUpdateTime) {
                nextUpdateTime = closestUpdateTime;
            }
        }

        this.refreshTimer = setTimeout(() => this.refreshGeoZonesSubject.next(), nextUpdateTime - currentDateTime);
    }

    private updateZones(zones: AirspaceElement[], shouldWatchZonesUpdates: boolean): AirspaceElement[] {
        if (!shouldWatchZonesUpdates) {
            return zones;
        }

        return zones
            .map((zone) => {
                const currentDateTime = this.getDateTime();
                if (!zone.scope || zone.scope.endTime.getTime() < currentDateTime) {
                    return;
                }

                return {
                    ...zone,
                    isActive: zone.reservations.some(
                        (reservation) =>
                            reservation.status === AirspaceElementStatus.Active &&
                            reservation.scope.startTime.getTime() <= currentDateTime &&
                            reservation.scope.endTime.getTime() >= currentDateTime
                    ),
                };
            })
            .filter(FunctionUtils.isTruthy);
    }

    private getDateTime() {
        // TODO: DTM-1659 - time should be synchronized with backend
        return Date.now();
    }
}
