import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HemsAreaActivationEventData, HemsData, HemsEventData, HemsEvents } from "@dtm-frontend/shared/ui";
import { LocalizeDatePipe } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils, StringUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { AcEntity, AcNotification, ActionType } from "@pansa/ngx-cesium";
import buffer from "@turf/buffer";
import { lineString, point as turfPoint } from "@turf/helpers";
import lineIntersect from "@turf/line-intersect";
import transformTranslate from "@turf/transform-translate";
import { AsyncSubject, Observable, filter, from, map, mergeMap, of, partition, share } from "rxjs";
import { MapUtils } from "../../index";
import { createCesiumDashPattern } from "../../utils/create-cesium-dash-pattern";

/* eslint-disable @typescript-eslint/no-explicit-any*/
declare const Cesium: any; // TODO: DTM-966

declare global {
    interface CanvasRenderingContext2D {
        roundRect(x: number, y: number, width: number, height: number, radii: [number, number, number, number]): void;
    }
}

interface HemsLayerComponentState {
    hemsEventData: HemsEventData[];
}

interface HemAcEntity extends AcNotification {
    type: EntityType;
    entity?: AcEntity & { type?: EntityType };
}

enum EntityType {
    Area = "Area",
    Line = "Line",
    Position = "Position",
    PredictedPosition = "PredictedPosition",
    AreaTimeRange = "AreaTimeRange",
}

interface AreaInfo {
    id: string;
    activationTime?: Date | null;
    deactivationTime?: Date | null;
    position: {
        longitude: number;
        latitude: number;
    };
    radius: number;
}

const DEFAULT_OUTLINE_DASH_LENGTH = 15;
const DEFAULT_OUTLINE_DASH_PATTERN = createCesiumDashPattern("------------");
const LABELS_SHOWING_DISTANCE = 100000;
const HEMS_ICON_SRC = "assets/images/hems-white-bg.svg";
const LINE_COLOR = Cesium.Color.fromCssColorString("#223d6b"); // $color-gray-500
const BUFFER_LINE_COLOR = Cesium.Color.fromCssColorString("#637292"); // $color-gray-300
const DEFAULT_ALPHA = 0.4;
const START_AREA_COLOR = Cesium.Color.fromCssColorString("#b00020"); // $color-error-400
const DEFAULT_LABEL_COLOR = "#FFFFFF"; // $color-white
const LABEL_COLOR = "#FFD54F"; // $color-primary-300
const LABEL_TEXT_COLOR = "#142E59"; // $color-gray-700
const AREA_LABEL_TITLE_COLOR = "#637292"; // $color-gray-300
const AREA_LABEL_TEXT_COLOR = "#142E59"; // $color-gray-700
const DEFAULT_FONT = "12px Manrope, Arial, sans-serif"; // $typography-font-family
const LABEL_BORDER_RADIUS = 4;
const LABEL_HEIGHT = 24;
const LABEL_PADDING = 8;

const LABEL_STYLES = {
    textColor: Cesium.Color.fromCssColorString(LABEL_TEXT_COLOR),
    labelBackgroundColor: Cesium.Color.fromCssColorString(LABEL_COLOR),
    predictedPositionLabelBackgroundColor: Cesium.Color.fromCssColorString(DEFAULT_LABEL_COLOR),
};

const FLIGHT_PATH_BUFFER_RADIUS_IN_METERS = 900;
const LANDING_AREA_BUFFER_RADIUS_IN_METERS = 10000;

@Component({
    selector: "dtm-map-hems-layer[hemsEventData]",
    templateUrl: "./hems-layer.component.html",
    styleUrls: ["./hems-layer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HemsLayerComponent {
    @Input()
    public set hemsEventData(value: HemsEventData[] | undefined) {
        this.localStore.patchState({ hemsEventData: value ?? [] });
    }

    private readonly entities$ = this.localStore.selectByKey("hemsEventData").pipe(
        mergeMap((data) => this.transformHemsDataToAreaEntities(data)),
        share()
    );
    protected readonly hemsAreaEntities$ = this.entities$.pipe(filter((entity) => entity?.type === EntityType.Area));
    protected readonly hemsLineEntities$ = this.entities$.pipe(filter((entity) => entity?.type === EntityType.Line));
    protected readonly positions$ = partition(
        this.localStore.selectByKey("hemsEventData").pipe(
            mergeMap((data) => this.transformHemsDataToFlightPositionsEntities(data)),
            RxjsUtils.filterFalsy(),
            share()
        ),
        (entity) => !!entity.entity && entity.entity.type === EntityType.PredictedPosition
    );
    protected readonly predictedPositions$ = this.positions$[0];
    protected readonly lastPositions$ = this.positions$[1];
    protected readonly imageLoaded$ = new AsyncSubject();
    protected readonly areaActivationEntities$ = this.localStore.selectByKey("hemsEventData").pipe(
        mergeMap((data) => this.transformHemsDataToAreaActivationEntities(data)),
        RxjsUtils.filterFalsy()
    );

    protected Cesium = Cesium;
    protected EntityType = EntityType;
    protected labelDistanceCondition = new Cesium.DistanceDisplayCondition(0, LABELS_SHOWING_DISTANCE);
    protected activationLabelDistanceCondition = new Cesium.DistanceDisplayCondition(0, LABELS_SHOWING_DISTANCE * 2);
    protected LABEL_STYLES = LABEL_STYLES;
    protected routeLineMaterial = new Cesium.PolylineDashMaterialProperty({
        color: LINE_COLOR,
        dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
        dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
    });
    protected landingBufferAreaLineMaterial = new Cesium.PolylineDashMaterialProperty({
        color: BUFFER_LINE_COLOR,
        dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
        dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
    });

    private readonly timePipe: LocalizeDatePipe;
    private readonly hemsImageIcon;

    constructor(private readonly localStore: LocalComponentStore<HemsLayerComponentState>, private readonly transloco: TranslocoService) {
        localStore.setState({
            hemsEventData: [],
        });

        this.timePipe = new LocalizeDatePipe();

        this.hemsImageIcon = new Image();
        this.hemsImageIcon.src = HEMS_ICON_SRC;
        this.hemsImageIcon.addEventListener("load", () => {
            this.imageLoaded$.next(true);
            this.imageLoaded$.complete();
        });
    }

    protected getStartAreaMaterial(type: "takeOff" | "landing") {
        if (type === "takeOff") {
            return START_AREA_COLOR.withAlpha(DEFAULT_ALPHA);
        }

        return LINE_COLOR.withAlpha(DEFAULT_ALPHA);
    }

    private transformHemsDataToAreaEntities(hemsData: HemsEventData[]): Observable<HemAcEntity | undefined> {
        const filterData = hemsData.filter((data) => data.name === HemsEvents.OperationStartedEvent);

        return from(filterData).pipe(
            mergeMap(({ content: data }) => {
                if (!data.departure || !data.landing) {
                    return of(undefined);
                }

                const entities = [];
                const areaEntities = this.prepareAreaEntities(data);

                if (!areaEntities) {
                    return of(undefined);
                }

                const { takeOffEntity, landingEntity } = areaEntities;

                const turfLine = lineString([
                    [data.departure.position.longitude, data.departure.position.latitude],
                    [data.landing.position.longitude, data.landing.position.latitude],
                ]);

                const newLine = buffer(turfLine, FLIGHT_PATH_BUFFER_RADIUS_IN_METERS, { units: "meters" });
                const takeOffPoint = turfPoint([data.departure.position.longitude, data.departure.position.latitude]);
                const landingPoint = turfPoint([data.landing.position.longitude, data.landing.position.latitude]);
                const bufferPolygon = Cesium.Cartesian3.fromDegreesArray(newLine.geometry.coordinates.flat(2));
                const bufferedTakeOffArea = buffer(takeOffPoint, FLIGHT_PATH_BUFFER_RADIUS_IN_METERS, { units: "meters" });
                const bufferedLandingArea = buffer(landingPoint, FLIGHT_PATH_BUFFER_RADIUS_IN_METERS, { units: "meters" });

                const takeOffIntersection = lineIntersect(bufferedTakeOffArea, turfLine).features[0]?.geometry.coordinates.flat() ?? [];
                const landingIntersection = lineIntersect(bufferedLandingArea, turfLine).features[0]?.geometry.coordinates.flat() ?? [];
                if (takeOffIntersection.length && landingIntersection.length) {
                    const lineEntity: AcEntity = {
                        positions: [
                            Cesium.Cartesian3.fromDegreesArray(takeOffIntersection),
                            Cesium.Cartesian3.fromDegreesArray(landingIntersection),
                        ].flat(),
                    };

                    entities.push({
                        actionType: ActionType.ADD_UPDATE,
                        entity: lineEntity,
                        type: EntityType.Line,
                        id: StringUtils.generateId(),
                    });
                }

                const bufferEntity: AcEntity = {
                    positions: bufferPolygon,
                    takeOff: Cesium.Cartesian3.fromDegreesArray(bufferedTakeOffArea.geometry.coordinates.flat(2)),
                    landing: Cesium.Cartesian3.fromDegreesArray(bufferedLandingArea.geometry.coordinates.flat(2)),
                };

                entities.push(
                    {
                        actionType: ActionType.ADD_UPDATE,
                        entity: takeOffEntity,
                        type: EntityType.Area,
                        id: StringUtils.generateId(),
                    },
                    {
                        actionType: ActionType.ADD_UPDATE,
                        entity: landingEntity,
                        type: EntityType.Area,
                        id: StringUtils.generateId(),
                    },
                    {
                        actionType: ActionType.ADD_UPDATE,
                        entity: bufferEntity,
                        type: EntityType.Line,
                        id: StringUtils.generateId(),
                    }
                );

                return from(entities);
            })
        );
    }

    private prepareAreaEntities(data: HemsData) {
        // NOTE: latitude or longitude 0 is considered as wrong data also
        if (
            !data.landing?.position.latitude ||
            !data.landing?.position.longitude ||
            !data.departure?.position.latitude ||
            !data.departure?.position.longitude
        ) {
            return;
        }

        const takeOffPosition = MapUtils.convertSerializableCartographicToCartesian3({
            longitude: data.departure.position.longitude,
            latitude: data.departure.position.latitude,
            height: 0,
        });
        const landingPosition = MapUtils.convertSerializableCartographicToCartesian3({
            longitude: data.landing.position.longitude,
            latitude: data.landing.position.latitude,
            height: 0,
        });

        const areaBuffer = buffer(
            turfPoint([data.landing.position.longitude, data.landing.position.latitude]),
            LANDING_AREA_BUFFER_RADIUS_IN_METERS,
            {
                units: "meters",
            }
        );
        const takeOffEntity: AcEntity = {
            position: takeOffPosition,
            radius: data.departure.areaRadius,
            type: "takeOff",
        };

        const landingEntity: AcEntity = {
            position: landingPosition,
            radius: data.landing.areaRadius,
            type: "landing",
            areaBuffer: Cesium.Cartesian3.fromDegreesArray(areaBuffer.geometry.coordinates.flat(2)),
        };

        return { takeOffEntity, landingEntity };
    }

    private transformHemsDataToFlightPositionsEntities(hemsData: HemsEventData[]): Observable<HemAcEntity | undefined> {
        const filteredData = hemsData.filter((data) => data.name === HemsEvents.AircraftPositionChangedEvent);

        return this.imageLoaded$.pipe(
            mergeMap(() => from(filteredData)),
            mergeMap(({ content: data }) => {
                const position = MapUtils.convertSerializableCartographicToCartesian3({
                    longitude: data.lastPosition.longitude,
                    latitude: data.lastPosition.latitude,
                    height: 0,
                });

                const realLabelImage = this.prepareLabelForRealPosition(data);

                const positionEntity: AcEntity = {
                    position,
                    type: EntityType.Position,
                    realLabelImage,
                };

                const entities: HemAcEntity[] = [
                    {
                        actionType: ActionType.ADD_UPDATE,
                        entity: positionEntity,
                        type: EntityType.Position,
                        id: StringUtils.generateId(),
                    },
                ];

                if (data.predictedPosition) {
                    const predictedPosition = MapUtils.convertSerializableCartographicToCartesian3({
                        longitude: data.predictedPosition.position.longitude,
                        latitude: data.predictedPosition.position.latitude,
                        height: 0,
                    });
                    const predictedLineImage = this.prepareLabelForPredictedPosition(data);

                    const predictedPositionEntity: AcEntity = {
                        position: predictedPosition,
                        type: EntityType.PredictedPosition,
                        lineImage: predictedLineImage,
                    };

                    entities.push({
                        id: StringUtils.generateId(),
                        actionType: ActionType.ADD_UPDATE,
                        entity: predictedPositionEntity,
                        type: EntityType.PredictedPosition,
                    });
                }

                return from(entities);
            })
        );
    }

    private prepareLabelForPredictedPosition(data: HemsData) {
        const canvas = document.createElement("canvas");
        const leftTextMargin = 4;
        const lineLength = 40;
        canvas.width = 100;
        canvas.height = 64;

        const context2D = canvas.getContext("2d");

        const predictedTimeText = data.predictedPosition?.arrivalTime
            ? "~" + this.timePipe.transform(data.predictedPosition.arrivalTime, { timeStyle: "medium" })
            : "";
        if (context2D) {
            context2D.beginPath();
            context2D.moveTo(canvas.width / 2, 0);
            context2D.lineTo(canvas.width / 2, lineLength);
            context2D.strokeStyle = DEFAULT_LABEL_COLOR;
            context2D.stroke();
            context2D.fillStyle = DEFAULT_LABEL_COLOR;
            context2D.font = DEFAULT_FONT;
            const { actualBoundingBoxLeft, actualBoundingBoxRight } = context2D.measureText(predictedTimeText);
            const rectWidth =
                Math.floor(Math.abs(actualBoundingBoxLeft)) +
                Math.floor(Math.abs(actualBoundingBoxRight)) +
                this.hemsImageIcon.width +
                leftTextMargin +
                LABEL_PADDING * 2;

            const xPosition = 100 / 2 - rectWidth / 2;
            context2D.roundRect(xPosition, lineLength, rectWidth, LABEL_HEIGHT, [
                LABEL_BORDER_RADIUS,
                LABEL_BORDER_RADIUS,
                LABEL_BORDER_RADIUS,
                LABEL_BORDER_RADIUS,
            ]);
            context2D.fill();
            context2D.textAlign = "left";
            context2D.textBaseline = "bottom";

            context2D.fillStyle = LABEL_TEXT_COLOR;
            context2D.fillText(
                predictedTimeText,
                xPosition + this.hemsImageIcon.width + LABEL_BORDER_RADIUS + LABEL_PADDING,
                canvas.height - LABEL_BORDER_RADIUS
            );
            context2D.drawImage(this.hemsImageIcon, xPosition + LABEL_PADDING, lineLength);
        }

        return canvas;
    }

    private prepareLabelForRealPosition(data: HemsData) {
        const canvas = document.createElement("canvas");
        canvas.width = 200;
        canvas.height = LABEL_HEIGHT;
        const context2D = canvas.getContext("2d");

        const timeText = data.lastPositionChangeTime ? this.timePipe.transform(data.lastPositionChangeTime, { timeStyle: "medium" }) : "";

        if (context2D) {
            context2D.beginPath();
            context2D.fillStyle = LABEL_COLOR;
            context2D.font = DEFAULT_FONT;
            const { actualBoundingBoxLeft, actualBoundingBoxRight } = context2D.measureText(timeText);
            const rectWidth = Math.floor(Math.abs(actualBoundingBoxLeft) + Math.abs(actualBoundingBoxRight) + LABEL_PADDING * 2);
            context2D.roundRect(0, 0, rectWidth, LABEL_HEIGHT, [
                LABEL_BORDER_RADIUS,
                LABEL_BORDER_RADIUS,
                LABEL_BORDER_RADIUS,
                LABEL_BORDER_RADIUS,
            ]);
            context2D.fill();
            context2D.textAlign = "left";
            context2D.textBaseline = "bottom";
            context2D.fillStyle = LABEL_TEXT_COLOR;
            context2D.fillText(timeText, LABEL_PADDING, LABEL_HEIGHT - LABEL_PADDING / 2);
        }

        return canvas;
    }

    private isAreaEvent(hemsEvent: HemsEventData): hemsEvent is HemsAreaActivationEventData {
        return hemsEvent.name === HemsEvents.AreaActivatedEvent || hemsEvent.name === HemsEvents.AreaDeactivatedEvent;
    }

    private transformHemsDataToAreaActivationEntities(hemsData: HemsEventData[]): Observable<HemAcEntity | undefined> {
        const areas = hemsData
            .filter(({ name }) => name === HemsEvents.OperationStartedEvent)
            .reduce((areasMap, data) => {
                const { departure, landing } = data.content;
                if (departure) {
                    areasMap.set(departure.id, {
                        id: departure.id,
                        position: departure.position,
                        activationTime: departure.activatedAt,
                        radius: departure.areaRadius,
                    });
                }

                if (landing) {
                    areasMap.set(landing.id, { id: landing.id, position: landing.position, radius: landing.areaRadius });
                }

                return areasMap;
            }, new Map<string, AreaInfo>());

        hemsData.filter(this.isAreaEvent).forEach((data: HemsAreaActivationEventData) => {
            const content = data.content;
            const existedData = areas.get(content.areaId);

            if (!existedData) {
                return;
            }

            if (data.name === HemsEvents.AreaActivatedEvent && !areas.get(content.areaId)) {
                areas.set(content.areaId, { ...existedData, activationTime: data.content.createdAt });
            }
            if (data.name === HemsEvents.AreaDeactivatedEvent) {
                areas.set(content.areaId, { ...existedData, deactivationTime: data.content.createdAt });
            }
        });

        return from([...areas.values()]).pipe(
            map((area) => {
                if (!area.activationTime && !area.deactivationTime) {
                    return;
                }

                const point = turfPoint([area.position.longitude, area.position.latitude]);
                const rightRotationAngle = 90;
                const translatedPoint = transformTranslate(point, area.radius, rightRotationAngle, { units: "meters" });

                const position = MapUtils.convertSerializableCartographicToCartesian3({
                    longitude: translatedPoint.geometry.coordinates[0],
                    latitude: translatedPoint.geometry.coordinates[1],
                    height: 0,
                });

                const content = `${area.activationTime ? this.timePipe.transform(area.activationTime, { timeStyle: "medium" }) : ""} - ${
                    area.deactivationTime ? this.timePipe.transform(area.deactivationTime, { timeStyle: "medium" }) : ""
                }`;

                const entity: AcEntity = {
                    position,
                    image: this.prepareLabelForAreaActivationInfo(content),
                };

                return {
                    type: EntityType.AreaTimeRange,
                    id: StringUtils.generateId(),
                    entity,
                    actionType: ActionType.ADD_UPDATE,
                };
            })
        );
    }

    private prepareLabelForAreaActivationInfo(content: string) {
        const canvas = document.createElement("canvas");
        canvas.width = 200;
        canvas.height = LABEL_HEIGHT * 2;
        const context2D = canvas.getContext("2d");

        const titleLabel = this.transloco.translate("dtmWebAppLibShared.hemsMapLayer.activeZoneTimeLabel");

        if (context2D) {
            context2D.imageSmoothingEnabled = false;
            context2D.beginPath();
            context2D.fillStyle = DEFAULT_LABEL_COLOR;
            context2D.font = DEFAULT_FONT;
            const { actualBoundingBoxLeft, actualBoundingBoxRight } = context2D.measureText(content);
            const { actualBoundingBoxLeft: titleActualBoundingBoxLeft, actualBoundingBoxRight: titleActualBoundingBoxRight } =
                context2D.measureText(titleLabel);
            const contentRectWidth = Math.abs(actualBoundingBoxLeft) + Math.abs(actualBoundingBoxRight) + LABEL_PADDING * 2;
            const titleRectWidth = Math.abs(titleActualBoundingBoxLeft) + Math.abs(titleActualBoundingBoxRight) + LABEL_PADDING * 2;
            context2D.roundRect(0, 0, Math.max(contentRectWidth, titleRectWidth), LABEL_HEIGHT * 2, [
                LABEL_BORDER_RADIUS,
                LABEL_BORDER_RADIUS,
                LABEL_BORDER_RADIUS,
                LABEL_BORDER_RADIUS,
            ]);
            context2D.fill();
            context2D.textAlign = "left";
            context2D.textBaseline = "bottom";
            context2D.fillStyle = AREA_LABEL_TITLE_COLOR;
            context2D.fillText(
                titleLabel,
                Math.floor(Math.max(contentRectWidth, titleRectWidth) / 2 - titleRectWidth / 2 + LABEL_PADDING),
                LABEL_HEIGHT - LABEL_PADDING / 2
            );
            context2D.fillStyle = AREA_LABEL_TEXT_COLOR;
            context2D.fillText(content, LABEL_PADDING, LABEL_HEIGHT * 2 - LABEL_PADDING);
        }

        return canvas;
    }
}
