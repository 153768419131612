import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { CERTIFIED_CATEGORY_FINAL_GRC, SoraResult } from "../../models/mission-plan-verification.models";

interface FinalSoraIssuesComponentState {
    soraResult: SoraResult | undefined;
    isExpanded: boolean;
}

@Component({
    selector: "dtm-mission-final-sora-issues",
    templateUrl: "./final-sora-issues.component.html",
    styleUrls: ["./final-sora-issues.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FinalSoraIssuesComponent {
    @Input({ required: true }) public set soraResult(value: SoraResult | undefined) {
        this.localStore.patchState({ soraResult: value });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly soraResult$ = this.localStore.selectByKey("soraResult");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    constructor(private readonly localStore: LocalComponentStore<FinalSoraIssuesComponentState>) {
        this.localStore.setState({
            soraResult: undefined,
            isExpanded: true,
        });
    }

    protected isCertifiedCategory(finalGrc: number | undefined): boolean {
        return !!finalGrc && finalGrc >= CERTIFIED_CATEGORY_FINAL_GRC;
    }
}
