<form [formGroup]="phoneNumberForm" *ngrxLet="{ hasErrors: hasErrors$, id: id$ } as vm">
    <label class="phone-number-label" [for]="vm.id">{{ "dtmUi.phoneNumberField.phoneNumberLabel" | transloco }}</label>
    <div class="phone-number-fields-container" #formContainer [attr.aria-labelledby]="vm.id">
        <dtm-ui-select-field
            maxPanelHeight="320px"
            [isClearable]="false"
            [formControl]="countryFormControl"
            (panelClose)="searchControl.reset('')"
            [class.error]="vm.hasErrors"
            (keydown)="phoneSelectKeyDown($event)"
            (openedChange)="search?.focus()"
            [shouldApplyOffsetTop]="true"
            #selectTemplate
        >
            <mat-select-trigger *ngIf="selectedCountry$ | ngrxPush as selectedCountry">
                <dtm-ui-country-flag-display [country]="selectedCountry.alpha3Code"></dtm-ui-country-flag-display>
                <span>+{{ selectedCountry.countryNumber }}</span>
            </mat-select-trigger>
            <div class="container" [style.width]="(dropdownWidth$ | ngrxPush) + 'px'">
                <dtm-ui-input-field class="search">
                    <input
                        #search
                        matInput
                        [formControl]="searchControl"
                        [placeholder]="'dtmUi.countrySelect.search' | transloco"
                        (keydown.space)="$event.stopPropagation()"
                        (keydown.enter)="assignSelectedCountryCode()"
                    />
                </dtm-ui-input-field>
                <dtm-ui-custom-option
                    [value]="country.alpha2Code"
                    *ngFor="let country of listOfCountriesDisplay; let i = index; trackBy: countryListItemTrack"
                >
                    <span class="option">
                        <dtm-ui-country-flag-display [country]="country.alpha3Code"></dtm-ui-country-flag-display>
                        <span>{{ country.displayName }}</span>
                    </span>
                </dtm-ui-custom-option>
                <p class="empty-list" *ngIf="!listOfCountriesDisplay.length">{{ "dtmUi.countrySelect.emptyListInfo" | transloco }}</p>
            </div>
            <ng-content select=".country-code-hint" ngProjectAs=".field-hint"></ng-content>
            <ng-content select=".invalid-country-code" ngProjectAs=".field-error"></ng-content>
        </dtm-ui-select-field>
        <dtm-ui-input-field>
            <input matInput [formControl]="phoneNumberControl" (blur)="propagateTouch()" inputmode="numeric" [id]="vm.id" />
            <ng-content select=".phone-number-hint" ngProjectAs=".field-hint"></ng-content>
            <ng-content select=".field-error" ngProjectAs=".field-error"></ng-content>
        </dtm-ui-input-field>
    </div>
</form>
