import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: "dtm-ui-multiple-actions",
    templateUrl: "./multiple-actions.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleActionsComponent {
    @Output() public edit = new EventEmitter<void>();
    @Output() public delete = new EventEmitter<void>();
}
