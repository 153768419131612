import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { DateFormatOptions } from "@jsverse/transloco-locale";
import { combineLatest, of, switchMap } from "rxjs";
import { map } from "rxjs/operators";

interface TimeRangeDisplayComponentState {
    startTime: Date | undefined;
    endTime: Date | undefined;
    timeZone: string | undefined;
}

const DATE_FORMAT_OPTIONS: DateFormatOptions = {
    timeStyle: "short",
};

@Component({
    selector: "dtm-ui-time-range-display[startTime][endTime]",
    templateUrl: "./time-range-display.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TimeRangeDisplayComponent {
    public readonly startTime$ = this.localStore.selectByKey("startTime");
    public readonly endTime$ = this.localStore.selectByKey("endTime");
    public readonly isSameTime$ = combineLatest([this.startTime$, this.endTime$]).pipe(
        map(([startTime, endTime]) => startTime?.getTime() === endTime?.getTime())
    );
    public readonly dateFormatOptions$ = this.localStore.selectByKey("timeZone").pipe(
        switchMap((value) => {
            if (value) {
                return of({
                    ...DATE_FORMAT_OPTIONS,
                    timeZone: value,
                });
            }

            return of(DATE_FORMAT_OPTIONS);
        })
    );

    @Input()
    public set startTime(value: Date) {
        this.localStore.patchState({ startTime: value });
    }

    @Input()
    public set endTime(value: Date) {
        this.localStore.patchState({ endTime: value });
    }

    @Input()
    public set timeZone(value: string | undefined) {
        this.localStore.patchState({ timeZone: value });
    }

    constructor(private readonly localStore: LocalComponentStore<TimeRangeDisplayComponentState>) {
        this.localStore.setState({
            startTime: undefined,
            endTime: undefined,
            timeZone: undefined,
        });
    }
}
