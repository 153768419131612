import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { ACCESSIBILITY_STATEMENT_URL, LocalComponentStore, TERMS_OF_USE_URL } from "@dtm-frontend/shared/utils";
import { map } from "rxjs/operators";

interface FooterComponentState {
    activeLanguage: string | undefined;
    isCookiesInfoAvailable: boolean;
}

const DEFAULT_LANGUAGE = "pl";
const CONTACT_MAIL = "uav-support@pansa.pl";

@Component({
    selector: "dtm-ui-webpage-footer[activeLanguage]",
    templateUrl: "./webpage-footer.component.html",
    styleUrls: ["./webpage-footer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class WebpageFooterComponent {
    @Input() public set activeLanguage(value: string | undefined) {
        this.localStore.patchState({ activeLanguage: value });
    }
    @Input() public set isCookiesInfoAvailable(value: BooleanInput) {
        this.localStore.patchState({ isCookiesInfoAvailable: coerceBooleanProperty(value) });
    }

    @Output() public readonly cookiesInfoSelect = new EventEmitter<void>();

    protected readonly CONTACT_MAIL = CONTACT_MAIL;
    protected readonly popcLogoUrl$ = this.localStore
        .selectByKey("activeLanguage")
        .pipe(map((language) => (language === DEFAULT_LANGUAGE ? "assets/images/popc-pl.svg" : "assets/images/popc-en.svg")));
    protected readonly isCookiesInfoAvailable$ = this.localStore.selectByKey("isCookiesInfoAvailable");

    constructor(
        @Inject(TERMS_OF_USE_URL) protected readonly termsOfUseUrl: string,
        @Inject(ACCESSIBILITY_STATEMENT_URL) protected readonly accessibilityStatementUrl: string,
        private readonly localStore: LocalComponentStore<FooterComponentState>
    ) {
        this.localStore.setState({
            activeLanguage: undefined,
            isCookiesInfoAvailable: true,
        });
    }
}
