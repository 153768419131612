export type FakeUTCDate = Date;

/**
 * There is no UTC Date object in JS. Date type is always in local timezone, so to use UTC time in datepicker we need this date conversion hack.
 * For example '4.10.2022 11:54 GMT+0200' will become '4.10.2022 09:54 GMT+0200'
 */
export function convertDateToFakeUTC(date: Date): FakeUTCDate {
    return new Date(new Date(date).toUTCString().replace(/GMT.*$/, ""));
}

/**
 * This method will convert FakeUTCDate object back to normal Date type, so it can be sent to backend as JSON
 */
export function convertFakeUTCToDate(localDate: FakeUTCDate, localTime: FakeUTCDate): Date {
    return new Date(
        Date.UTC(
            localDate.getFullYear(),
            localDate.getMonth(),
            localDate.getDate(),
            localTime.getHours(),
            localTime.getMinutes(),
            localTime.getSeconds(),
            localTime.getMilliseconds()
        )
    );
}
