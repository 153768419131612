<ng-container *ngrxLet="{ permit: permit$, canEdit: canEdit$ } as vm">
    <dtm-ui-expandable-panel [isExpanded]="isCaaContactTileExpanded$ | ngrxPush" [hasHeaderSeparator]="false">
        <div class="header" headerSlot>
            {{ "dtmUi.associationPermitPreview.caaUserContactHeader" | transloco }}
            <button (click)="$event.stopPropagation(); edit.emit()" *ngIf="vm.canEdit" type="button" class="button-secondary edit-button">
                {{ "dtmUi.associationPermitPreview.editButtonLabel" | transloco }}
            </button>
        </div>
        <div class="data-preview">
            <dtm-ui-permit-contact-person-preview [contactPerson]="vm.permit.caaContact"></dtm-ui-permit-contact-person-preview>
        </div>
    </dtm-ui-expandable-panel>

    <dtm-ui-expandable-panel [isExpanded]="isOperatorInfoTileExpanded$ | ngrxPush" [hasHeaderSeparator]="false">
        <div class="header" headerSlot>
            {{ "dtmUi.associationPermitPreview.operatorInfoHeader" | transloco }}
            <button (click)="$event.stopPropagation(); edit.emit()" *ngIf="vm.canEdit" type="button" class="button-secondary edit-button">
                {{ "dtmUi.associationPermitPreview.editButtonLabel" | transloco }}
            </button>
        </div>
        <div class="data-preview">
            <div class="operator-details">
                <dtm-ui-label-value
                    [label]="'dtmUi.associationPermitPreview.operatorNumberLabel' | transloco"
                    [value]="vm.permit.associationInfo.number"
                ></dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmUi.associationPermitPreview.operatorNameLabel' | transloco"
                    [value]="vm.permit.associationInfo.name"
                >
                </dtm-ui-label-value>
            </div>
            <ng-container *ngIf="isOperatorContactPersonVisible$ | ngrxPush">
                <h2 class="contact-person-header">
                    {{ "dtmUi.associationPermitPreview.contactPersonTileHeader" | transloco }}
                </h2>
                <dtm-ui-permit-contact-person-preview [contactPerson]="vm.permit.operatorContact"></dtm-ui-permit-contact-person-preview>
            </ng-container>
        </div>
    </dtm-ui-expandable-panel>
</ng-container>
