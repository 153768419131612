<dtm-map-route-viewer-layer
    [routeData]="routeData$ | ngrxPush"
    [drawableFeatures]="drawableFeatures$ | ngrxPush"
    [pinTemplate]="pinTemplate$ | ngrxPush"
    [nearbyMissionsDrawableFeatures]="nearbyMissionsDrawableFeatures$ | ngrxPush"
    [isShown]="true"
    (entityZoom)="zoomTo = $event"
></dtm-map-route-viewer-layer>

<ac-layer acFor="let entity of markedTrajectoryEntities$" [context]="this">
    <ac-polyline-desc
        props="{
                    positions: entity.positions,
                    width: TRAJECTORY_WARNING_VISUAL_DATA.outlineWidth,
                    material: TRAJECTORY_WARNING_VISUAL_DATA.outlineMaterial,
                    zIndex: 1
                }"
    >
    </ac-polyline-desc>
</ac-layer>
<ac-layer acFor="let entity of trajectoryEntities$" [context]="this">
    <ac-polyline-desc
        props="{
                    positions: entity.positions,
                    width: TRAJECTORY_VISUAL_DATA.outlineWidth,
                    material: TRAJECTORY_VISUAL_DATA.outlineMaterial,
                    zIndex: 0
                }"
    >
    </ac-polyline-desc>
</ac-layer>
<ac-layer acFor="let flightData of flightData$" [context]="this" *ngrxLet="flightPinTemplate$; let flightPinTemplate">
    <ac-html-desc props="{position: flightData.position}">
        <ng-template let-pin>
            <ng-container *ngTemplateOutlet="flightPinTemplate; context: { $implicit: pin }"></ng-container>
        </ng-template>
    </ac-html-desc>
</ac-layer>
