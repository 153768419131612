import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Optional } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { getDefaultLang } from "@dtm-frontend/shared/ui/i18n";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { StateContext } from "@ngxs/store";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { EMPTY } from "rxjs";
import { tap } from "rxjs/operators";
import { AuthActions } from "../state/auth.actions";
import { AuthStateModel } from "../state/auth.state";
import { AuthService } from "./auth.service";

@UntilDestroy()
@Injectable()
export class KeycloakAuthService implements AuthService {
    constructor(
        @Optional() private readonly keycloakService: KeycloakService,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly jwtHelperService: JwtHelperService,
        private readonly router: Router
    ) {}

    public init(context: StateContext<AuthStateModel>) {
        if (!this.keycloakService) {
            console.warn("KeycloakService is not provided");

            return;
        }

        this.keycloakService.keycloakEvents$
            .pipe(
                RxjsUtils.filterFalsy(),
                tap((event) => this.handleKeycloakEventTypes(event.type, context)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public goToLoginPage(redirectPath?: string) {
        this.keycloakService.login({
            locale: getDefaultLang(),
            redirectUri: this.document.location.origin + (redirectPath ?? this.router.routerState.snapshot.url),
        });
    }

    public logIn() {
        return EMPTY;
    }

    public updateToken() {
        return EMPTY;
    }

    public logOut(context: StateContext<AuthStateModel>) {
        const routerStateUrl = this.router.routerState.snapshot.url;

        context.patchState({ isLoggedIn: undefined });
        this.keycloakService.logout(this.document.location.origin + routerStateUrl);
    }

    private handleKeycloakEventTypes(eventType: KeycloakEventType, context: StateContext<AuthStateModel>) {
        switch (eventType) {
            case KeycloakEventType.OnAuthLogout:
            case KeycloakEventType.OnAuthRefreshError:
                context.dispatch(new AuthActions.Logout());
                break;
            case KeycloakEventType.OnAuthSuccess:
                this.handleAuthSuccess(context);
                break;
            case KeycloakEventType.OnAuthRefreshSuccess:
                this.handleAuthRefreshSuccess(context);
                break;
            default:
                break;
        }
    }

    private async handleAuthSuccess(context: StateContext<AuthStateModel>) {
        const isLoggedIn = await this.keycloakService.isLoggedIn();
        context.patchState({ isLoggedIn });

        if (isLoggedIn) {
            const token = await this.keycloakService.getToken();
            const decodedToken = this.jwtHelperService.decodeToken(token);

            context.patchState({
                userFirstName: decodedToken["given_name"],
                userLastName: decodedToken["family_name"],
                userId: decodedToken["preferred_username"],
                roles: decodedToken["realm_access"]?.roles ?? [],
                token,
            });
        }
    }

    private async handleAuthRefreshSuccess(context: StateContext<AuthStateModel>) {
        const token = await this.keycloakService.getToken();

        context.patchState({
            token,
        });
    }
}
