<ng-container *ngrxLet="{ uploadedFiles: uploadedFiles$, kmlFiles: kmlFiles$ } as vm">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{ "dtmMapCesium.kmlImport.modalTile" | transloco }}</h2>
        <button type="button" class="button-icon" [matDialogClose]="false">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <p class="info">{{ "dtmMapCesium.kmlImport.modalDescription" | transloco }}</p>
        <dtm-ui-uploaded-files-display
            canRemoveFile
            shouldHideDownloadButtons
            [uploadedFiles]="vm.uploadedFiles"
            (tryRemoveFile)="removeKmlFile($event)"
        >
            <label *ngIf="vm.uploadedFiles.length">{{ "dtmMapCesium.kmlImport.uploadedFieldLabel" | transloco }}</label>
        </dtm-ui-uploaded-files-display>
        <ng-container *ngIf="vm.uploadedFiles.length < 3">
            <label>{{ "dtmMapCesium.kmlImport.uploadFieldLabel" | transloco }}</label>
            <dtm-ui-files-upload
                [isMultiple]="false"
                [allowedTypes]="ALLOWED_EXTENSIONS"
                [processedFiles]="filesWithError$ | ngrxPush"
                (processedFilesAdd)="addKmlFiles($event)"
                (processedFileRemove)="removeProcessedFile($event)"
            >
            </dtm-ui-files-upload>
            <span class="hint">
                {{ "dtmMapCesium.kmlImport.fileFormatHint" | transloco : { format: ALLOWED_EXTENSIONS | join } }}
            </span>
        </ng-container>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" mat-dialog-close>
            {{ "dtmMapCesium.kmlImport.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" cdkFocusInitial [mat-dialog-close]="vm.kmlFiles">
            {{ "dtmMapCesium.kmlImport.saveButtonLabel" | transloco }}
        </button>
    </div>
</ng-container>
