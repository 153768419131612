import turfBbox from "@turf/bbox";
import turfBooleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { Position, polygon as turfPolygon } from "@turf/helpers";
import tessellate from "earcut";
import { SerializableCartographic } from "../models/serializable-cartographic";

const VERTICES_IN_TRIANGLE = 3;

function exportCoordinates(cartographic: SerializableCartographic): Position {
    return [cartographic.latitude, cartographic.longitude];
}

/**
 * Calculates centroid of provided points
 * @param positions array of [x, y] coordinates for points
 */
function calculateCentroid(positions: Position[]): Position {
    return positions.reduce(
        (result, position, index) => {
            result[0] += position[0];
            result[1] += position[1];

            if (index === positions.length - 1) {
                result[0] = result[0] / positions.length;
                result[1] = result[1] / positions.length;
            }

            return result;
        },
        [0, 0]
    );
}

/**
 * Calculates center point of a polygon by finding centroid of triangles (by tesselation) nearest to polygon's bounding box center
 * @param polygonPoints polygon vertices
 */
export function getPolygonVisualCenter(polygonPoints: SerializableCartographic[]): SerializableCartographic {
    if (polygonPoints.length < VERTICES_IN_TRIANGLE) {
        return {
            latitude: polygonPoints[0].latitude,
            longitude: polygonPoints[0].longitude,
        };
    }

    const coordinates = polygonPoints.map(exportCoordinates);
    const polygon = turfPolygon([[...coordinates, exportCoordinates(polygonPoints[0])]]);
    const [bboxMinX, bboxMinY, bboxMaxX, bboxMaxY] = turfBbox(polygon);

    // NOTE: calculates center point of polygon's bounding box
    const bboxCentroid = calculateCentroid([
        [bboxMinX, bboxMinY],
        [bboxMaxX, bboxMaxY],
    ]);

    // NOTE: return bounding box' center point as the result if it lays inside the polygon and the polygon is not a simple triangle
    if (turfBooleanPointInPolygon(bboxCentroid, polygon)) {
        return {
            latitude: bboxCentroid[0],
            longitude: bboxCentroid[1],
        };
    }

    // NOTE: tessellate the polygon to triangles using earcut algorithm
    const triangleVertices = tessellate(coordinates.flat());

    // NOTE: repacks flattened vertices array to array of triangles represented by 3 vertices
    const triangles = triangleVertices.reduce<number[][]>((result, value, index) => {
        if (index % VERTICES_IN_TRIANGLE === 0) {
            result.push([value]);
        } else {
            result[Math.floor(index / VERTICES_IN_TRIANGLE)].push(value);
        }

        return result;
    }, []);

    const centroids = triangles.map((vertices) => {
        const centroidX = (coordinates[vertices[0]][0] + coordinates[vertices[1]][0] + coordinates[vertices[2]][0]) / VERTICES_IN_TRIANGLE;
        const centroidY = (coordinates[vertices[0]][1] + coordinates[vertices[1]][1] + coordinates[vertices[2]][1]) / VERTICES_IN_TRIANGLE;

        const deltaX = centroidX - bboxCentroid[0];
        const deltaY = centroidY - bboxCentroid[1];
        const squaredDistanceToBboxCenter = deltaX ** 2 + deltaY ** 2;

        // NOTE: calculates centroid of a triangle and its distance (squared for performance reasons) to bounding box' center point
        return [centroidX, centroidY, squaredDistanceToBboxCenter];
    });

    // NOTE: looks for triangle centroid that lays neareast to bounding box' center point
    let nearestCentroid = centroids[0];
    for (let index = 1; index < centroids.length; index++) {
        const centroid = centroids[index];

        if (centroid[2] < nearestCentroid[2]) {
            nearestCentroid = centroid;
        }
    }

    return {
        latitude: nearestCentroid[0],
        longitude: nearestCentroid[1],
    };
}
