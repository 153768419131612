import { AfterViewInit, Directive, ElementRef } from "@angular/core";

@Directive({
    selector: "[dtmUiScrollToAfterInit]",
})
export class ScrollToAfterInitDirective implements AfterViewInit {
    constructor(private el: ElementRef) {}

    public ngAfterViewInit() {
        this.el.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
}
