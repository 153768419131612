<div class="wrapper" *ngrxLet="{ isLegendVisible: isLegendVisible$ } as vm">
    <div class="obstacle-levels" *ngIf="vm.isLegendVisible" @slideIn>
        <ng-container
            *ngFor="let obstacle of OBSTACLE_LEVELS"
            [ngTemplateOutlet]="obstacleElementTemplate"
            [ngTemplateOutletContext]="{
                text:
                    obstacle.level === 'level-8'
                        ? ('dtmMapCesium.obstaclesDtmLegend.aboveHeightValue' | transloco : { value: obstacle.height | localizeNumber })
                        : ('dtmMapCesium.obstaclesDtmLegend.belowHeightValue' | transloco : { value: obstacle.height | localizeNumber }),
                level: obstacle.level
            }"
        ></ng-container>
    </div>
    <button class="button-tertiary redirect-button" type="button" (click)="changeLegendVisibility()">
        {{ "dtmMapCesium.riskLegend.toggleButtonValueLabel" | transloco : { value: vm.isLegendVisible } }}
        <dtm-ui-icon [name]="vm.isLegendVisible ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
    </button>
</div>
<ng-template #obstacleElementTemplate let-text="text" let-level="level">
    <div class="obstacle-level-element">
        <div class="obstacle-level" [ngClass]="level"></div>
        {{ text }}
    </div>
</ng-template>
