<div
    class="condition-box"
    *ngrxLet="analysisState$ as analysisState"
    [class.approved]="analysisState === AnalysisResultState.Ok"
    [class.warning]="analysisState === AnalysisResultState.Warning"
    [class.danger]="analysisState === AnalysisResultState.Danger"
    [class.lack-weather-conditions]="!analysisState"
>
    <div>{{ title$ | ngrxPush }}</div>
    <div *ngIf="analysisState; else noDataTemplate">
        <ng-content></ng-content>
    </div>
</div>
<ng-template #noDataTemplate>
    <div class="lack-weather-conditions">
        {{ "dtmMapCesium.weatherConditionBox.noDataLabel" | transloco }}
    </div>
</ng-template>
