import { addDirectionToDmsCoordinates } from "./utils/add-direction-to-dms-coordinates";
import {
    convertDecimalDegreesToDmsCoordinates,
    convertDecimalDegreesToDmsCoordinatesWithDirection,
} from "./utils/convert-decimal-degrees-to-dms-coordinates";
import { convertDmsCoordinatesToDecimalDegrees } from "./utils/convert-dms-coordinates-to-decimal-degrees";
import { convertDmsWithDirectionToString, convertDmsWithDirectionToStringWithoutSeconds } from "./utils/convert-dms-to-string";

export * from "./components/dms-coordinates-input/dms-coordinates-input.component";
export * from "./dms-coordinates.module";
export * from "./models/dms-coordinates-input.models";

export const DmsCoordinatesUtils = {
    addDirectionToDmsCoordinates,
    convertDecimalDegreesToDmsCoordinates,
    convertDecimalDegreesToDmsCoordinatesWithDirection,
    convertDmsCoordinatesToDecimalDegrees,
    convertDmsWithDirectionToString,
    convertDmsWithDirectionToStringWithoutSeconds,
};
