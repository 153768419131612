import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface AvatarComponentState {
    picture: string | undefined;
    sourceUrl: string | undefined;
    fullName: string | undefined;
    label: string | undefined;
}

const DEFAULT_AVATAR_URL = "assets/images/default-avatar.svg";

@Component({
    selector: "dtm-ui-avatar",
    templateUrl: "./avatar.component.html",
    styleUrls: ["./avatar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AvatarComponent {
    @Input() public set picture(value: string | undefined) {
        this.localStore.patchState({ picture: value });
    }
    @Input() public set fullName(value: string | undefined) {
        this.localStore.patchState({ fullName: value });
    }
    @Input() public set label(value: string | undefined) {
        this.localStore.patchState({ label: value });
    }
    @Input() public set sourceUrl(value: string | undefined) {
        this.localStore.patchState({ sourceUrl: value });
    }

    protected readonly picture$ = this.localStore.selectByKey("picture");
    protected readonly sourceUrl$ = this.localStore.selectByKey("sourceUrl");
    protected readonly fullName$ = this.localStore.selectByKey("fullName");
    protected readonly label$ = this.localStore.selectByKey("label");

    constructor(private readonly localStore: LocalComponentStore<AvatarComponentState>) {
        this.localStore.setState({
            picture: undefined,
            sourceUrl: undefined,
            fullName: undefined,
            label: undefined,
        });
    }

    protected setDefaultPicture(url: string) {
        if (url !== DEFAULT_AVATAR_URL) {
            this.localStore.patchState({
                picture: DEFAULT_AVATAR_URL,
            });
        }
    }

    protected setDefaultSourceUrl(url: string) {
        if (url !== DEFAULT_AVATAR_URL) {
            this.localStore.patchState({
                sourceUrl: DEFAULT_AVATAR_URL,
            });
        }
    }
}
