import { InjectionToken } from "@angular/core";

export interface PlannedMissionEndpoints {
    missionPlansWatch: string;
    getPlannedMission: string;
    getMissionRoute: string;
    missionAcceptance: string;
    missionRejection: string;
}

export const PLANNED_MISSION_ENDPOINTS = new InjectionToken<PlannedMissionEndpoints>("Planned mission endpoints");
