<p class="label">{{ (label$ | ngrxPush) ?? ("dtmUi.addressLabelValue.address" | transloco) }}</p>
<p *ngIf="address$ | ngrxPush as address" class="value-container">
    {{ address.streetName }}
    <ng-container *ngIf="address.apartmentNumber; else houseNumberTemplate">
        {{ address.houseNumber }}/{{ address.apartmentNumber }}
    </ng-container>
    <ng-template #houseNumberTemplate>{{ address.houseNumber }}</ng-template>
    <br />
    {{ address.postCode | postCode : address.country }} {{ address.city }}<br />
    <span class="country" *ngIf="country$ | ngrxPush as country">
        <dtm-ui-country-flag-display *ngIf="isCountryFlagVisible$ | ngrxPush" [country]="country.alpha3Code"></dtm-ui-country-flag-display>
        {{ country.displayName }}
    </span>
</p>
