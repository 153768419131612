import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface WizardStepHeaderComponentState {
    isEnabledByAttribute: boolean | undefined;
    isManualChangeAllowed: boolean;
}

@Component({
    selector: "dtm-ui-lib-wizard-step-header[stepId]",
    templateUrl: "./wizard-step-header.component.html",
    styleUrls: ["./wizard-step-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class WizardStepHeaderComponent {
    protected readonly isEnabledByAttribute$ = this.localStore.selectByKey("isEnabledByAttribute");
    protected readonly isManualChangeAllowed$ = this.localStore.selectByKey("isManualChangeAllowed");

    @ViewChild("stepHeaderTemplate", { static: true }) public template!: TemplateRef<unknown>;
    @ViewChild("stepHeader") public readonly stepHeaderElement: ElementRef<HTMLElement> | undefined;
    @Input() public stepId!: string;
    @Input() public set isEnabled(value: boolean | undefined) {
        this.localStore.patchState({ isEnabledByAttribute: value });
    }
    @Input() public set isManualChangeAllowed(value: BooleanInput) {
        this.localStore.patchState({ isManualChangeAllowed: coerceBooleanProperty(value) });
    }
    @Output() public stepActivate = new EventEmitter<void>();

    constructor(private readonly localStore: LocalComponentStore<WizardStepHeaderComponentState>) {
        this.localStore.setState({
            isEnabledByAttribute: undefined,
            isManualChangeAllowed: true,
        });
    }
}
