import { BasicContextMenu } from "@pansa/ngx-cesium";

export interface PrismContextMenuData {
    positionsCount: number;
    isMinimumVertexNumber: boolean;
    onFinishCreate: () => void;
    onRemoveLastPoint: () => void;
    onRemoveArea: () => void;
}

export interface PrismContextMenu extends BasicContextMenu {
    data: PrismContextMenuData;
}
