import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DateUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

interface TimeRangeDisplayComponentState {
    firstDate: Date | string | undefined;
    secondDate: Date | string | undefined;
}

@Component({
    selector: "dtm-ui-time-diff-display[firstDate][secondDate]",
    templateUrl: "./time-diff-display.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TimeDiffDisplayComponent {
    public readonly startDate$ = this.localStore.selectByKey("firstDate").pipe(RxjsUtils.filterFalsy());
    public readonly endDate$ = this.localStore.selectByKey("secondDate").pipe(RxjsUtils.filterFalsy());

    public readonly timeDiffData$ = combineLatest([this.startDate$, this.endDate$]).pipe(
        map(([firstDate, secondDate]) => DateUtils.calculateDiffDate(firstDate, secondDate))
    );

    @Input() public set firstDate(value: Date | string) {
        this.localStore.patchState({ firstDate: value });
    }
    @Input() public set secondDate(value: Date | string) {
        this.localStore.patchState({ secondDate: value });
    }

    constructor(private readonly localStore: LocalComponentStore<TimeRangeDisplayComponentState>) {
        this.localStore.setState({
            firstDate: undefined,
            secondDate: undefined,
        });
    }
}
