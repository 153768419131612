<ng-container *ngrxLet="{ startDate: startDate$, finishDate: finishDate$, isWholeDayMode: isWholeDayMode$ } as vm">
    <div class="column" *ngIf="vm.isWholeDayMode">
        <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
        <p class="time">
            {{ vm.startDate | localizeDate : { dateStyle: "short" } }}
            -
            {{ vm.finishDate | localizeDate : { dateStyle: "short" } }}
        </p>
        <p class="additional-info">
            {{
                "dtmSharedMission.missionDates.missionDateRangeDays"
                    | transloco : { days: vm.startDate | invoke : getDaysBetween : vm.finishDate }
            }}
        </p>
    </div>
    <ng-container *ngIf="!vm.isWholeDayMode">
        <div class="column">
            <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
            <p class="time">
                {{ vm.startDate | localizeDate : { day: "numeric", month: "numeric", year: "numeric" } }}
            </p>
            <p class="additional-info">{{ vm.startDate | localizeDate : { weekday: "long" } }}</p>
        </div>
        <div class="column">
            <dtm-ui-icon name="time"></dtm-ui-icon>
            <p class="time">
                {{ vm.startDate | localizeDate : { timeStyle: "short" } }}
                -
                {{ vm.finishDate | localizeDate : { timeStyle: "short" } }}
            </p>
            <p class="additional-info">
                <dtm-ui-time-diff-display [firstDate]="vm.startDate" [secondDate]="vm.finishDate"></dtm-ui-time-diff-display>
            </p>
        </div>
    </ng-container>
</ng-container>
