import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FlightZoneGeometryConstraints, FlightZoneUtils, HeightReferences, MapUnitsOfMeasure } from "@dtm-frontend/dss-shared-lib";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { Observable } from "rxjs";
import { map, startWith, withLatestFrom } from "rxjs/operators";

interface GeometryHeightForm {
    lowerHeight: FormControl<number>;
    lowerHeightUnit: FormControl<MapUnitsOfMeasure>;
    lowerHeightReference: FormControl<HeightReferences>;
    upperHeight: FormControl<number>;
    upperHeightUnit: FormControl<MapUnitsOfMeasure>;
    upperHeightReference: FormControl<HeightReferences>;
}

const MINIMUM_GEOMETRY_HEIGHT_METERS = 1;

@Injectable({
    providedIn: "root",
})
export class GeometryHeightLimiterService {
    constructor() {}

    public getMinUpperHeightObservable(
        form: FormGroup<GeometryHeightForm>,
        constraints: Observable<FlightZoneGeometryConstraints | undefined>
    ): Observable<number> {
        return form.valueChanges.pipe(
            startWith(null),
            withLatestFrom(
                constraints.pipe(
                    map((geometryConstraints) => geometryConstraints?.min.upperHeight),
                    RxjsUtils.filterFalsy()
                )
            ),
            map(([value, minUpperHeightConstraint]) => {
                const formValue = form.getRawValue();
                const lowerHeightInMeters = FlightZoneUtils.otherUnitsOfMeasureToMeters(formValue.lowerHeight, formValue.lowerHeightUnit);
                const minUpperHeight = Math.max(lowerHeightInMeters + MINIMUM_GEOMETRY_HEIGHT_METERS, minUpperHeightConstraint);

                return FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(minUpperHeight, formValue.upperHeightUnit, 0);
            })
        );
    }

    public getMaxLowerHeightObservable(
        form: FormGroup<GeometryHeightForm>,
        constraints: Observable<FlightZoneGeometryConstraints | undefined>
    ): Observable<number> {
        return form.valueChanges.pipe(
            startWith(null),
            withLatestFrom(
                constraints.pipe(
                    map((geometryConstraints) => geometryConstraints?.max.lowerHeight),
                    RxjsUtils.filterFalsy()
                )
            ),
            map(([value, maxLowerHeightConstraint]) => {
                const formValue = form.getRawValue();
                const upperHeightInMeters = FlightZoneUtils.otherUnitsOfMeasureToMeters(formValue.upperHeight, formValue.upperHeightUnit);
                const maxLowerHeight = Math.min(upperHeightInMeters, maxLowerHeightConstraint);

                return FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(maxLowerHeight, formValue.lowerHeightUnit, 0);
            })
        );
    }
}
