import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface NewMessageActionsComponentState {
    isAttachmentsButtonVisible: boolean;
}

@Component({
    selector: "dtm-ui-new-message-actions",
    templateUrl: "./new-message-actions.component.html",
    styleUrls: ["./new-message-actions.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NewMessageActionsComponent {
    @Input() public set isAttachmentsButtonVisible(value: BooleanInput) {
        this.localStore.patchState({ isAttachmentsButtonVisible: coerceBooleanProperty(value) });
    }

    @Output() protected readonly attachmentsButtonVisibilityChange = new EventEmitter<void>();
    @Output() protected readonly messageDecline = new EventEmitter<void>();
    @Output() protected readonly messageSend = new EventEmitter<void>();
    protected readonly isAttachmentsButtonVisible$ = this.localStore.selectByKey("isAttachmentsButtonVisible");

    constructor(private readonly localStore: LocalComponentStore<NewMessageActionsComponentState>) {
        localStore.setState({
            isAttachmentsButtonVisible: false,
        });
    }
}
