import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, OnInit, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { BYTES_IN_MEGABYTE, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { first } from "rxjs/operators";

interface NewMessageFormValues {
    content: string;
    attachments: string[];
}

// eslint-disable-next-line no-magic-numbers
const MAX_FILE_SIZE_BYTES = 10 * BYTES_IN_MEGABYTE;

const ALLOWED_MIME_TYPES = [
    "image/jpeg",
    "image/png",
    "image/apng",
    "image/avif",
    "image/gif",
    "image/svg+xml",
    "image/webp",
    "application/pdf",
    "text/plain",
    "application/vnd.oasis.opendocument.text",
    "application/zip",
    "application/x-zip-compressed",
];

interface NewMessageEditorComponentState {
    isAttachmentsControlVisible: boolean;
    isJoinedView: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-new-message-editor",
    templateUrl: "./new-message-editor.component.html",
    styleUrls: ["./new-message-editor.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NewMessageEditorComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => NewMessageEditorComponent),
            multi: true,
        },
    ],
})
export class NewMessageEditorComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() public set isJoinedView(value: BooleanInput) {
        this.localStore.patchState({ isJoinedView: coerceBooleanProperty(value) });
    }
    @Input() public set isAttachmentsControlVisible(value: BooleanInput) {
        this.localStore.patchState({ isAttachmentsControlVisible: coerceBooleanProperty(value) });
    }

    public readonly MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_BYTES;
    public readonly ALLOWED_MIME_TYPES = ALLOWED_MIME_TYPES;
    public readonly isAttachmentsControlVisible$ = this.localStore.selectByKey("isAttachmentsControlVisible");
    public readonly isJoinedView$ = this.localStore.selectByKey("isJoinedView");
    public readonly contentControl: UntypedFormControl = new UntypedFormControl(null, Validators.required);
    public readonly attachmentsControl: UntypedFormControl = new UntypedFormControl(null);
    public readonly newMessageForm: UntypedFormGroup = new UntypedFormGroup({
        content: this.contentControl,
        attachments: this.attachmentsControl,
    });
    private onValidationChange = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<NewMessageEditorComponentState>) {
        localStore.setState({
            isAttachmentsControlVisible: false,
            isJoinedView: false,
        });
    }

    public onTouched = () => {};

    public registerOnChange(fn: (value: NewMessageFormValues) => void): void {
        this.newMessageForm.valueChanges.subscribe(fn);
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: NewMessageFormValues | undefined): void {
        if (value) {
            this.newMessageForm.setValue(value);
        }
    }
    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public ngOnInit() {
        this.watchFormValueChanged();
    }

    public validate(): ValidationErrors | null {
        if (this.attachmentsControl.invalid || this.contentControl.invalid) {
            return {
                required: true,
            };
        }

        return null;
    }

    private markAsTouched() {
        if (!this.newMessageForm.touched) {
            this.onTouched();
        }
    }

    private watchFormValueChanged() {
        this.newMessageForm.valueChanges
            .pipe(
                first((form) => form.content?.length || form.attachments?.length),
                untilDestroyed(this)
            )
            .subscribe(() => this.markAsTouched());
    }
}
