import { Clipboard } from "@angular/cdk/clipboard";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import packageJson from "package.json";
import { VersionData } from "../utils/version-data.model";

@Component({
    selector: "dtm-ui-lib-version-data-dialog",
    templateUrl: "./version-data-dialog.component.html",
    styleUrls: ["./version-data-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionDataDialogComponent {
    public services = JSON.stringify(this.data.versionData, null, 1);
    public frontendVersion = packageJson.version;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { versionData: VersionData }, private clipboard: Clipboard) {}

    public copyToClipboard() {
        return this.clipboard.copy(JSON.stringify(this.data.versionData));
    }
}
