import { Directive, HostListener, Self } from "@angular/core";
import { MatLegacyTooltip as MatTooltip } from "@angular/material/legacy-tooltip";

@Directive({
    selector: "[dtmUiClickActivatedTooltip]",
})
export class ClickActivatedTooltipDirective {
    @HostListener("click") private onClick(): void {
        this.matTooltip.toggle();
    }

    @HostListener("mouseenter", ["$event"]) private onMouseEnter(event: MouseEvent): void {
        event.stopImmediatePropagation();
    }

    @HostListener("mouseleave", ["$event"]) private onMouseLeave(event: MouseEvent): void {
        event.stopImmediatePropagation();
    }

    constructor(@Self() private matTooltip: MatTooltip) {}
}
