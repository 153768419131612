<div class="container">
    <img class="pansa-logo" src="assets/images/pansa.svg" alt="" />

    <div class="links">
        <a class="custom-link" [href]="termsOfUseUrl" rel="noopener norefer" target="_blank">{{
            "dtmUi.footer.termsOfUseLabel" | transloco
        }}</a>
        <a class="custom-link" [href]="accessibilityStatementUrl" rel="noopener norefer" target="_blank">{{
            "dtmUi.footer.accessibilityStatementLabel" | transloco
        }}</a>
        <button
            *ngIf="isCookiesInfoAvailable$ | ngrxPush"
            type="button"
            class="custom-link"
            (click)="cookiesInfoSelect.emit()"
            (keydown.enter)="cookiesInfoSelect.emit()"
        >
            {{ "dtmUi.footer.cookiesInfoLabel" | transloco }}
        </button>
        <span class="mail">
            <span class="mail-label">{{ "dtmUi.footer.contactUsLabel" | transloco }}</span>
            <a class="custom-link" [href]="'mailto:' + CONTACT_MAIL">
                {{ CONTACT_MAIL }}
            </a>
        </span>
    </div>
    <img class="logos" [src]="popcLogoUrl$ | ngrxPush" alt="" />
</div>
