<div class="qualification" *ngrxLet="qualification$ as qualification">
    <ng-container [ngSwitch]="qualification.category">
        <dtm-ui-icon *ngSwitchCase="OperationScenarioCategory.Open" name="shield" class="shield"></dtm-ui-icon>
        <dtm-ui-icon *ngSwitchDefault name="shield-cross" class="shield"></dtm-ui-icon>
    </ng-container>
    <div class="qualification-value">
        <div class="qualification-name">
            <h6 class="header">{{ qualification.name }}</h6>
            <dtm-ui-popover *ngIf="qualification.statusReason" [popoverText]="qualification.statusReason"></dtm-ui-popover>
        </div>
        <div class="details">
            <span class="reason" *ngIf="qualification.status === QualificationStatus.Suspended; else expirationDateTemplate">
                {{ "dtmUi.qualificationBadge.suspendedLabel" | transloco }}
            </span>
            <ng-template #expirationDateTemplate>
                {{ "dtmUi.qualificationBadge.validityTermLabel" | transloco : { date: qualification.expirationDate | localizeDate } }}
            </ng-template>
        </div>
    </div>
</div>
