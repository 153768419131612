import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

interface ObstaclesDtmLegendComponentState {
    isLegendVisible: boolean;
}

const OBSTACLE_LEVELS: { height: number; level: string }[] = [
    { height: 120, level: "level-8" },
    { height: 120, level: "level-7" },
    { height: 100, level: "level-6" },
    { height: 80, level: "level-5" },
    { height: 60, level: "level-4" },
    { height: 50, level: "level-3" },
    { height: 40, level: "level-2" },
    { height: 35, level: "level-1" },
];

@Component({
    selector: "dtm-map-obstacles-dtm-legend",
    templateUrl: "./obstacles-dtm-legend.component.html",
    styleUrls: ["./obstacles-dtm-legend.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class ObstaclesDtmLegendComponent {
    protected readonly isLegendVisible$ = this.localStore.selectByKey("isLegendVisible");

    protected readonly OBSTACLE_LEVELS = OBSTACLE_LEVELS;

    constructor(private readonly localStore: LocalComponentStore<ObstaclesDtmLegendComponentState>) {
        this.localStore.setState({
            isLegendVisible: false,
        });
    }

    protected changeLegendVisibility(): void {
        this.localStore.patchState((state) => ({ isLegendVisible: !state.isLegendVisible }));
    }
}
