<div [class.details-open]="isOpen" *ngrxLet="isOpen$ as isOpen">
    <div class="title">
        {{ title$ | ngrxPush }}
        <button (click)="toggleDetails()" type="button" class="expand-button">
            <ng-container *ngIf="isOpen; else detailsClosedTemplate">
                <span>{{ "dtmUi.details.collapseLabel" | transloco }}</span
                ><dtm-ui-icon name="arrow-up"></dtm-ui-icon>
            </ng-container>
        </button>
    </div>
    <div class="details-content" *ngIf="isOpen" @slideIn>
        <ng-content></ng-content>
    </div>
</div>

<ng-template #detailsClosedTemplate>
    <span>{{ "dtmUi.details.expandLabel" | transloco }}</span> <dtm-ui-icon name="arrow-down"></dtm-ui-icon>
</ng-template>
