<ng-container *ngrxLet="populationDensity$ as populationDensity">
    <p class="title">
        {{ (maxPopulationDensityLabel$ | ngrxPush) ?? ("dtmSharedMission.populationDensity.maxPopulationDensityTitle" | transloco) }}
    </p>
    <p
        *ngIf="populationDensity?.peoplePerSquareKilometerMax !== null; else noDataTemplate"
        class="population-density-value"
        [innerHTML]="
            'dtmSharedMission.populationDensity.populationDensityValueLabel'
                | transloco : { value: populationDensity?.peoplePerSquareKilometerMax ?? 0 }
        "
    ></p>
    <ng-container *ngIf="isAveragePopulationDensityVisible$ | ngrxPush">
        <p class="title">
            {{ (populationDensityLabel$ | ngrxPush) ?? ("dtmSharedMission.populationDensity.populationDensityTitle" | transloco) }}
        </p>
        <p
            *ngIf="populationDensity?.peoplePerSquareKilometerAvg !== null; else noDataTemplate"
            class="population-density-value"
            [innerHTML]="
                'dtmSharedMission.populationDensity.populationDensityValueLabel'
                    | transloco : { value: populationDensity?.peoplePerSquareKilometerAvg ?? 0 }
            "
        ></p>
    </ng-container>
    <p class="title">
        {{ (populationDensityShareLabel$ | ngrxPush) ?? ("dtmSharedMission.populationDensity.populationDensityShareTitle" | transloco) }}
    </p>
    <div class="population-density-share" *ngIf="areaShare$ | ngrxPush as areaShare; else noDataTemplate">
        <dtm-ui-risk-levels [areaShare]="areaShare"></dtm-ui-risk-levels>
    </div>
</ng-container>
<ng-template #noDataTemplate>
    <p class="no-data">{{ "dtmSharedMission.populationDensity.noDataLabel" | transloco }}</p>
</ng-template>
