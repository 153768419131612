<mat-card>
    <dtm-ui-card-header>
        {{ "dtmUi.profileLanguage.languageDataHeader" | transloco }}
    </dtm-ui-card-header>
    <div class="language-tiles" *ngrxLet="currentProfileLang$ as currentLang">
        <div
            class="selectable-tile"
            (click)="updateLanguage.emit(AvailableLanguages.Polish)"
            [class.selected]="currentLang === AvailableLanguages.Polish"
        >
            <div class="selectable-tile-wrapper">
                <dtm-ui-country-flag-display [country]="plCountryCodeItem?.alpha3Code"></dtm-ui-country-flag-display>
                <span>{{ "dtmUi.profileLanguage.polishLanguageLabel" | transloco }}</span>
            </div>
        </div>
        <div
            class="selectable-tile"
            (click)="updateLanguage.emit(AvailableLanguages.English)"
            [class.selected]="currentLang === AvailableLanguages.English"
        >
            <div class="selectable-tile-wrapper">
                <dtm-ui-country-flag-display [country]="enCountryCodeItem?.alpha3Code"></dtm-ui-country-flag-display>
                <span>{{ "dtmUi.profileLanguage.englishLanguageLabel" | transloco }}</span>
            </div>
        </div>
    </div>
</mat-card>
