import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";

interface MissionWizardStepWrapperComponentState {
    stepNumber: number | undefined;
    stepsAmount: number | undefined;
    title: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dss-client-lib-wizard-step-wrapper[stepNumber][title]",
    templateUrl: "./step-wrapper.component.html",
    styleUrls: ["./step-wrapper.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class WizardStepWrapperComponent {
    @Input() public set stepNumber(value: number | undefined) {
        this.localStore.patchState({ stepNumber: value });
    }

    @Input() public set stepsAmount(value: number | undefined) {
        this.localStore.patchState({ stepsAmount: value });
    }

    @Input() public set title(value: string | undefined) {
        this.localStore.patchState({ title: value });
    }

    public readonly stepNumber$ = this.localStore.selectByKey("stepNumber").pipe(RxjsUtils.filterFalsy());
    public readonly stepsAmount$ = this.localStore.selectByKey("stepsAmount").pipe(RxjsUtils.filterFalsy());
    public readonly title$ = this.localStore.selectByKey("title").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<MissionWizardStepWrapperComponentState>) {
        this.localStore.setState({
            stepNumber: undefined,
            stepsAmount: 5,
            title: undefined,
        });
    }
}
