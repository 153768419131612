<mat-chip-list *ngIf="filtersCount$ | ngrxPush as filtersCount">
    <mat-chip class="main-chip">
        {{ "dtmSharedMissionSearch.filterChips.clearAllFiltersLabel" | transloco }} ({{ filtersCount }})
        <dtm-ui-icon name="close-circle-fill" matChipRemove (click)="filterRemove.emit(null)"></dtm-ui-icon>
    </mat-chip>
    <div *ngFor="let item of chipsItems$ | ngrxPush" class="chip-wrapper">
        <span class="chip-label"> {{ "dtmSharedMissionSearch.filterChips.chipLabel" | transloco : { name: item.name } }}: </span>
        <mat-chip>
            <ng-container [ngSwitch]="item.name">
                <ng-container *ngSwitchCase="ChipName.dateRange">
                    {{ $any(item).valueFrom | localizeDate : { dateStyle: "short", timeStyle: "short" } }} -
                    {{ $any(item).valueTo | localizeDate : { dateStyle: "short", timeStyle: "short" } }}
                </ng-container>
                <ng-container *ngSwitchCase="ChipName.area">
                    {{ "dtmSharedMissionSearch.filterConditions.areaValueLabel" | transloco : { value: $any(item).value } }}
                    <ng-container *ngIf="$any(item).value === SearchArea.Zone && $any(item).zone">({{ $any(item).zone }})</ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="ChipName.missionType">
                    {{ "dtmSharedMissionSearch.missionTypeLabel" | transloco : { value: $any(item).value } }}
                </ng-container>
                <ng-container *ngSwitchCase="ChipName.status">
                    {{ "dtmSharedMission.missionStatusBadge.phaseLabel" | transloco : { value: $any(item).value } }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                    {{ $any(item).value }}
                </ng-container>
            </ng-container>
            <dtm-ui-icon name="close-circle-fill" matChipRemove (click)="removeChipsItem(item.name)"></dtm-ui-icon>
        </mat-chip>
    </div>
</mat-chip-list>
