import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Subject, fromEvent } from "rxjs";

const SWIPE_DEFAULT_LENGTH = 30;
const SWIPE_HORIZONTAL_PERCENTAGE_LENGTH = 0.1;
const SWIPE_VERTICAL_PERCENTAGE_LENGTH = 0.15;

export enum SwipeDirection {
    LEFT = "left",
    RIGHT = "right",
    UP = "up",
    DOWN = "down",
}

@Injectable({
    providedIn: "root",
})
export class TouchScreenService {
    private touchStartHorizontalPosition: [number, number] | null = null;
    private horizontalSwipeDetectionLength: number = this.document.defaultView
        ? this.document.defaultView.innerWidth * SWIPE_HORIZONTAL_PERCENTAGE_LENGTH
        : SWIPE_DEFAULT_LENGTH;
    private verticalSwipeDetectionLength: number = this.document.defaultView
        ? this.document.defaultView.innerHeight * SWIPE_VERTICAL_PERCENTAGE_LENGTH
        : SWIPE_DEFAULT_LENGTH;
    private swipeSubject = new Subject<SwipeDirection>();
    public swipe$ = this.swipeSubject.asObservable();

    constructor(@Inject(DOCUMENT) private readonly document: Document) {
        fromEvent<TouchEvent>(this.document, "touchstart").subscribe((event: TouchEvent) => this.touchStart(event));
        fromEvent<TouchEvent>(this.document, "touchend").subscribe((event: TouchEvent) => this.touchEnd(event));
    }

    private touchStart(event: TouchEvent) {
        this.touchStartHorizontalPosition = [event.changedTouches[0].screenX, event.changedTouches[0].screenY];
    }

    private touchEnd(event: TouchEvent) {
        if (this.touchStartHorizontalPosition === null) {
            return;
        }

        const touchEndHorizontalPosition = [event.changedTouches[0].screenX, event.changedTouches[0].screenY];
        const isSwipeLeft = this.isHorizontalSwipe(
            this.touchStartHorizontalPosition[0] - touchEndHorizontalPosition[0],
            Math.abs(touchEndHorizontalPosition[1] - this.touchStartHorizontalPosition[1])
        );
        const isSwipeRight = this.isHorizontalSwipe(
            touchEndHorizontalPosition[0] - this.touchStartHorizontalPosition[0],
            Math.abs(touchEndHorizontalPosition[1] - this.touchStartHorizontalPosition[1])
        );
        const isSwipeUp = this.isVerticalSwipe(
            this.touchStartHorizontalPosition[1] - touchEndHorizontalPosition[1],
            Math.abs(touchEndHorizontalPosition[0] - this.touchStartHorizontalPosition[0])
        );
        const isSwipeDown = this.isVerticalSwipe(
            touchEndHorizontalPosition[1] - this.touchStartHorizontalPosition[1],
            Math.abs(this.touchStartHorizontalPosition[0] - touchEndHorizontalPosition[0])
        );

        if (isSwipeLeft) {
            this.swipeSubject.next(SwipeDirection.LEFT);
        } else if (isSwipeRight) {
            this.swipeSubject.next(SwipeDirection.RIGHT);
        } else if (isSwipeUp) {
            this.swipeSubject.next(SwipeDirection.UP);
        } else if (isSwipeDown) {
            this.swipeSubject.next(SwipeDirection.DOWN);
        }
    }

    private isHorizontalSwipe(horizontalTouchSize: number, verticalTouchSize: number): boolean {
        return horizontalTouchSize > this.horizontalSwipeDetectionLength && horizontalTouchSize > verticalTouchSize;
    }

    private isVerticalSwipe(verticalTouchSize: number, horizontalTouchSize: number): boolean {
        return verticalTouchSize > this.verticalSwipeDetectionLength && verticalTouchSize > horizontalTouchSize;
    }
}
