import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { combineLatestWith, map } from "rxjs/operators";

interface ApplicationWizardStepWrapperComponentState {
    stepNumber: number | undefined;
    stepsAmount: number | undefined;
    title: string | undefined;
    nextButtonLabel: string | undefined;
    isSaveDraftButtonEnabled: boolean;
    isNextButtonEnabled: boolean;
    isBackButtonVisible: boolean;
    isSaveDraftProcessing: boolean;
    isNextStepProcessing: boolean;
    isSaveDraftButtonVisible: boolean;
}

@Component({
    selector: "dtm-ui-wizard-step-wrapper[stepNumber][title][stepsAmount]",
    templateUrl: "./step-wrapper.component.html",
    styleUrls: ["./step-wrapper.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class WizardStepWrapperComponent implements AfterViewInit {
    @Input() public set stepNumber(value: number | undefined) {
        this.localStore.patchState({ stepNumber: value });
    }

    @Input() public set stepsAmount(value: number | undefined) {
        this.localStore.patchState({ stepsAmount: value });
    }

    @Input() public set title(value: string | undefined) {
        this.localStore.patchState({ title: value });
    }

    @Input() public set nextButtonLabel(value: string | undefined) {
        this.localStore.patchState({ nextButtonLabel: value });
    }

    @Input() public set isNextButtonEnabled(value: boolean | undefined) {
        this.localStore.patchState({ isNextButtonEnabled: value ?? true });
    }

    @Input() public set isBackButtonVisible(value: boolean) {
        this.localStore.patchState({ isBackButtonVisible: value });
    }

    @Input() public set isSaveDraftButtonEnabled(value: boolean) {
        this.localStore.patchState({ isSaveDraftButtonEnabled: value });
    }

    @Input() public set isSaveDraftProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isSaveDraftProcessing: !!value });
    }

    @Input() public set isNextStepProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isNextStepProcessing: !!value });
    }

    @Output() public back = new EventEmitter<void>();
    @Output() public next = new EventEmitter<void>();
    @Output() public saveDraft = new EventEmitter<void>();

    @HostBinding("class.card-box") private cardBox = true;

    public readonly stepNumber$ = this.localStore.selectByKey("stepNumber").pipe(RxjsUtils.filterFalsy());
    public readonly stepsAmount$ = this.localStore.selectByKey("stepsAmount").pipe(RxjsUtils.filterFalsy());
    public readonly title$ = this.localStore.selectByKey("title").pipe(RxjsUtils.filterFalsy());
    public readonly nextButtonLabel$ = this.localStore.selectByKey("nextButtonLabel");
    public readonly isSaveDraftProcessing$ = this.localStore.selectByKey("isSaveDraftProcessing");
    public readonly isNextStepProcessing$ = this.localStore.selectByKey("isNextStepProcessing");
    public readonly isSaveDraftButtonEnabled$ = this.localStore.selectByKey("isSaveDraftButtonEnabled").pipe(
        combineLatestWith(this.isSaveDraftProcessing$),
        map(([isSaveDraftButtonEnabled, isSaveDraftProcessing]) => isSaveDraftButtonEnabled && !isSaveDraftProcessing)
    );
    public readonly isNextButtonEnabled$ = this.localStore.selectByKey("isNextButtonEnabled").pipe(
        combineLatestWith(this.isNextStepProcessing$),
        map(([isNextButtonEnabled, isNextStepProcessing]) => isNextButtonEnabled && !isNextStepProcessing)
    );
    public readonly isBackButtonVisible$ = this.localStore.selectByKey("isBackButtonVisible");
    public readonly isSaveDraftButtonVisible$ = this.localStore.selectByKey("isSaveDraftButtonVisible");

    constructor(private readonly localStore: LocalComponentStore<ApplicationWizardStepWrapperComponentState>) {
        this.localStore.setState({
            stepNumber: undefined,
            stepsAmount: 0,
            title: undefined,
            nextButtonLabel: undefined,
            isSaveDraftButtonEnabled: true,
            isNextButtonEnabled: true,
            isBackButtonVisible: true,
            isSaveDraftProcessing: false,
            isNextStepProcessing: false,
            isSaveDraftButtonVisible: false,
        });
    }

    public ngAfterViewInit() {
        this.localStore.patchState({ isSaveDraftButtonVisible: this.saveDraft.observed });
    }
}
