import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Inject, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { FileUploadState, FileUploadUtils, FunctionUtils, LocalComponentStore, RxjsUtils, Upload } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, scan } from "rxjs/operators";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "../../../index-ui";
import { FILES_UPLOAD_API_PROVIDER, FileUploadErrorType, FilesUploadApi, ProcessedFile, UploadedFile } from "./files-upload.models";

interface FilesUploadFieldComponentState {
    hasErrors: boolean;
    isDisabled: boolean;
    value: UploadedFile[];
    processedFiles: ProcessedFile[];
    allowedTypes: string[];
    maxFileSize: number;
    isDownloadAllButtonVisible: boolean;
    isFileListVisible: boolean;
    isMultiple: boolean;
    additionalPathParams: Record<string, string> | undefined;
    shouldEmitChangeAfterAllCompleted: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-files-upload-field",
    templateUrl: "./files-upload-field.component.html",
    styleUrls: ["./files-upload-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FilesUploadFieldComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FilesUploadFieldComponent),
            multi: true,
        },
    ],
})
export class FilesUploadFieldComponent implements ControlValueAccessor, Validator {
    @Input() public set value(value: UploadedFile[] | undefined) {
        value = value ?? [];

        this.localStore.patchState({ value });

        if (!this.validate()) {
            this.propagateChange(value);
        }

        this.onValidationChange();
    }

    @Input() public set allowedTypes(value: string[] | undefined) {
        this.localStore.patchState({ allowedTypes: value ?? [] });
    }

    @Input() public set maxFileSize(value: number | undefined) {
        this.localStore.patchState({ maxFileSize: value ?? 0 });
    }

    @Input() public set isDownloadAllButtonVisible(value: BooleanInput) {
        this.localStore.patchState({ isDownloadAllButtonVisible: coerceBooleanProperty(value) });
    }

    @Input() public set isFileListVisible(value: BooleanInput) {
        this.localStore.patchState({ isFileListVisible: coerceBooleanProperty(value) });
    }

    @Input() public set isMultiple(value: BooleanInput) {
        this.localStore.patchState({ isMultiple: coerceBooleanProperty(value) });
    }

    @Input() public set additionalPathParams(value: Record<string, string> | undefined) {
        this.localStore.patchState({ additionalPathParams: value });
    }

    @Input() public set shouldEmitChangeAfterAllCompleted(value: BooleanInput) {
        this.localStore.patchState({ shouldEmitChangeAfterAllCompleted: coerceBooleanProperty(value) });
    }

    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");
    protected readonly isDownloadAllButtonVisible$ = this.localStore.selectByKey("isDownloadAllButtonVisible");
    protected readonly isFileListVisible$ = this.localStore.selectByKey("isFileListVisible");
    protected readonly uploadedFiles$ = this.localStore.selectByKey("value");
    protected readonly processedFiles$ = this.localStore.selectByKey("processedFiles");
    protected readonly hasErrors$ = this.localStore.selectByKey("hasErrors");
    protected readonly allowedTypes$ = this.localStore.selectByKey("allowedTypes");
    protected readonly isMultiple$ = this.localStore.selectByKey("isMultiple");
    protected readonly additionalPathParams$ = this.localStore.selectByKey("additionalPathParams");

    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: UploadedFile[]) => void = FunctionUtils.noop;

    constructor(
        private readonly transloco: TranslocoService,
        private readonly localStore: LocalComponentStore<FilesUploadFieldComponentState>,
        @Inject(FILES_UPLOAD_API_PROVIDER) private readonly fileUploadApi: FilesUploadApi,
        private readonly dialogService: DialogService
    ) {
        if (fileUploadApi === undefined) {
            throw new Error("Missing FILES_UPLOAD_API_PROVIDER provider");
        }

        this.localStore.setState({
            hasErrors: false,
            isDisabled: false,
            value: [],
            processedFiles: [],
            allowedTypes: [],
            maxFileSize: 0,
            isDownloadAllButtonVisible: true,
            isFileListVisible: true,
            isMultiple: true,
            additionalPathParams: undefined,
            shouldEmitChangeAfterAllCompleted: false,
        });

        this.watchProcessedFiles();
    }

    public registerOnChange(fn: (value: UploadedFile[]) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(): void {}

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        if (this.localStore.selectSnapshotByKey("processedFiles").some((file) => file.error)) {
            return { uploadError: true };
        }

        return null;
    }

    public writeValue(value: UploadedFile[]): void {
        this.value = value;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.localStore.patchState({ isDisabled });
    }

    protected setHasErrors(hasErrors: boolean): void {
        this.localStore.patchState({ hasErrors });
    }

    protected addProcessedFiles(files: ProcessedFile[]): void {
        const processedFiles = [...this.localStore.selectSnapshotByKey("processedFiles")];

        for (const file of files) {
            this.runEarlyValidation(file);

            if (!file.error) {
                this.uploadFile(file);
            }

            processedFiles.push(file);
        }

        this.localStore.patchState({ processedFiles });
        this.onValidationChange();
    }

    protected removeProcessedFile(files: ProcessedFile | ProcessedFile[]): void {
        if (!Array.isArray(files)) {
            files = [files];
        }

        if (!files.length) {
            return;
        }

        const filesIds = files.map((file) => file.id);
        const currentlyProcessedFiles = this.localStore.selectSnapshotByKey("processedFiles");
        const processedFiles = currentlyProcessedFiles.filter((processedFile) => !filesIds.includes(processedFile.id));

        this.localStore.patchState({ processedFiles });
        this.onValidationChange();
    }

    protected removeUploadedFile(file: UploadedFile): void {
        const currentlyUploadedFiles = this.localStore.selectSnapshotByKey("value");
        this.value = currentlyUploadedFiles.filter((uploadedFile) => uploadedFile.id !== file.id);
    }

    protected tryRemoveFile(file: UploadedFile): void {
        if (this.isEditionBlocked()) {
            return;
        }

        this.confirmUploadedFileRemoval(file)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.removeUploadedFile(file);
            });
    }

    private isEditionBlocked(): boolean {
        return this.localStore.selectSnapshotByKey("isDisabled");
    }

    private runEarlyValidation(file: ProcessedFile): void {
        const allowedTypes = this.localStore.selectSnapshotByKey("allowedTypes");
        if (
            allowedTypes.length &&
            !allowedTypes.includes("." + this.getExtension(file.file.name).toLowerCase()) &&
            !allowedTypes.includes(file.file.type)
        ) {
            file.error = this.getInvalidTypeError(file);

            return;
        }

        const maxFileSize = this.localStore.selectSnapshotByKey("maxFileSize");
        if (maxFileSize && file.file.size > maxFileSize) {
            file.error = this.transloco.translate("dtmUi.filesUploadField.invalidFileSizeError");

            return;
        }
    }

    private getExtension(filename: string): string {
        if (filename.indexOf(".") === -1) {
            return "";
        }

        return filename.split(".").pop() ?? "";
    }

    private uploadFile(file: ProcessedFile): void {
        const initialState: Upload = {
            progress: 0,
            name: file.file.name,
            state: FileUploadState.InProgress,
        };
        const additionalPathParams = this.localStore.selectSnapshotByKey("additionalPathParams");

        this.fileUploadApi
            .uploadFile(file.file, additionalPathParams)
            .pipe(
                scan(FileUploadUtils.calculateUploadState, initialState),
                distinctUntilChanged((update1, update2) => update1.state === update2.state && update1.progress === update2.progress),
                untilDestroyed(this)
            )
            .subscribe({
                next: (uploadUpdate) => {
                    if (uploadUpdate.state === FileUploadState.InProgress) {
                        this.updateProcessedFileWithProgress(file, uploadUpdate.progress ?? 0);
                    } else if (uploadUpdate.state === FileUploadState.Done) {
                        this.completeProcessedFile(file, uploadUpdate.id ?? "", uploadUpdate.name);
                    }
                },
                error: (error: { type: FileUploadErrorType }) => {
                    this.updateProcessedFileWithError(file, error.type);
                },
            });
    }

    private confirmUploadedFileRemoval(file: UploadedFile): Observable<boolean> {
        return this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmUi.filesUpload.confirmFileRemovalDialog.titleText", {
                        fileName: file.name,
                    }),
                    confirmationText: this.transloco.translate("dtmUi.filesUpload.confirmFileRemovalDialog.contentText"),
                    declineButtonLabel: this.transloco.translate("dtmUi.filesUpload.confirmFileRemovalDialog.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmUi.filesUpload.confirmFileRemovalDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }

    private updateProcessedFileWithProgress(file: ProcessedFile, progress: number): void {
        const currentlyProcessedFiles = this.localStore.selectSnapshotByKey("processedFiles");
        const processedFiles: ProcessedFile[] = [];

        for (const processedFile of currentlyProcessedFiles) {
            if (processedFile.id === file.id) {
                file.progress = progress;
                processedFiles.push(file);
            } else {
                processedFiles.push(processedFile);
            }
        }

        this.localStore.patchState({ processedFiles });
        this.onValidationChange();
    }

    private completeProcessedFile(file: ProcessedFile, id: string, fileName: string): void {
        const currentlyProcessedFiles = this.localStore.selectSnapshotByKey("processedFiles");
        const processedFiles: ProcessedFile[] = [];

        for (const processedFile of currentlyProcessedFiles) {
            if (processedFile.id === file.id) {
                file.progress = 100;
                file.uploadedFile = {
                    id,
                    name: fileName,
                    size: processedFile.file.size,
                };
                processedFiles.push(file);
            } else {
                processedFiles.push(processedFile);
            }
        }

        this.localStore.patchState({ processedFiles });
        this.onValidationChange();
    }

    private updateProcessedFileWithError(file: ProcessedFile, type: FileUploadErrorType): void {
        const currentlyProcessedFiles = this.localStore.selectSnapshotByKey("processedFiles");
        const processedFiles: ProcessedFile[] = [];

        for (const processedFile of currentlyProcessedFiles) {
            if (processedFile.id !== file.id) {
                processedFiles.push(processedFile);
                continue;
            }

            switch (type) {
                case FileUploadErrorType.InfectedFile:
                    file.error = this.transloco.translate("dtmUi.filesUploadField.infectedFileError");
                    break;
                case FileUploadErrorType.MaxSizeExceeded:
                    file.error = this.transloco.translate("dtmUi.filesUploadField.invalidFileSizeError");
                    break;
                case FileUploadErrorType.InvalidType:
                    file.error = this.getInvalidTypeError(file);
                    break;
                default:
                    file.error = this.transloco.translate("dtmUi.filesUploadField.unknownError");
            }

            processedFiles.push(file);
        }

        this.localStore.patchState({ processedFiles });
        this.onValidationChange();
    }

    private getInvalidTypeError({ file }: ProcessedFile) {
        const invalidExtensionName = file.name.slice(file.name.lastIndexOf("."));

        return this.transloco.translate("dtmUi.filesUploadField.invalidFileTypeError", {
            type: file.type,
            extension: invalidExtensionName,
        });
    }

    private watchProcessedFiles(): void {
        this.localStore
            .selectByKey("processedFiles")
            .pipe(
                filter((processedFiles) => {
                    const shouldEmitChangeAfterAllCompleted = this.localStore.selectSnapshotByKey("shouldEmitChangeAfterAllCompleted");
                    const isEachProcessedFileCompleted = processedFiles.every((file) => file.uploadedFile || file.error);

                    return !shouldEmitChangeAfterAllCompleted || isEachProcessedFileCompleted;
                }),
                untilDestroyed(this)
            )
            .subscribe((processedFiles) => {
                const completedFiles = processedFiles.filter((file) => file.uploadedFile);

                if (!completedFiles.length) {
                    return;
                }

                this.value = [
                    ...this.localStore.selectSnapshotByKey("value"),
                    ...completedFiles.reduce<UploadedFile[]>((acc, file) => (file.uploadedFile ? [...acc, file.uploadedFile] : acc), []),
                ];
                this.removeProcessedFile(completedFiles);
            });
    }
}
