import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "isEmptyArray",
})
export class IsEmptyArrayPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public transform(inputArray: any[] | undefined): boolean {
        return !inputArray || inputArray.length === 0;
    }
}
