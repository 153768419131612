import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { combineLatest, filter, firstValueFrom, switchMap } from "rxjs";
import { map, tap } from "rxjs/operators";
import { NotificationsActions } from "../state/notifications.actions";
import { NotificationsState } from "../state/notifications.state";
import { Notification } from "../utils/notifications.model";

interface NotificationsContainerComponentState {
    userId: string | undefined;
    allowedNotificationTypes: string[];
}

@UntilDestroy()
@Component({
    selector: "dtm-notifications-notifications[userId][allowedNotificationTypes]",
    templateUrl: "./notifications.component.html",
    styleUrls: ["./notifications.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NotificationsComponent implements OnInit {
    @Input() public set userId(value: string | undefined) {
        this.localStore.patchState({ userId: value });
    }

    @Input() public set allowedNotificationTypes(value: string[] | undefined) {
        this.localStore.patchState({ allowedNotificationTypes: value ?? [] });
    }

    @Output() public notificationsListChange = new EventEmitter<Notification[] | undefined>();

    public readonly userId$ = this.localStore.selectByKey("userId");
    public readonly allowedNotificationTypes$ = this.localStore.selectByKey("allowedNotificationTypes");
    public readonly incomingNotifications$ = this.store.select(NotificationsState.incomingNotifications);
    public readonly initialNotificationsCount$ = this.store.select(NotificationsState.initialNotificationsCount);
    public readonly incomingNotificationsLength$ = combineLatest([this.initialNotificationsCount$, this.incomingNotifications$]).pipe(
        map(([initialNotificationsCount, incomingNotifications]) => {
            const initial = initialNotificationsCount ?? 0;
            const incoming = incomingNotifications?.length ?? 0;

            return initial + incoming;
        })
    );
    public readonly notificationsList$ = this.store
        .select(NotificationsState.notificationsList)
        .pipe(tap((list) => this.notificationsListChange.emit(list)));

    public readonly notificationsListError$ = this.store.select(NotificationsState.notificationsListError);

    constructor(private readonly store: Store, private readonly localStore: LocalComponentStore<NotificationsContainerComponentState>) {
        localStore.setState({
            userId: undefined,
            allowedNotificationTypes: [],
        });
    }

    public ngOnInit() {
        this.getInitialNotificationsCount();
        this.allowedNotificationTypes$
            .pipe(
                filter((allowedNotificationTypes) => !!allowedNotificationTypes?.length),
                untilDestroyed(this)
            )
            .subscribe((allowedNotificationTypes) => {
                this.watchIncomingNotifications(allowedNotificationTypes);
            });
    }

    public downloadFirstPageNotifications() {
        this.store
            .dispatch(NotificationsActions.ClearNotificationsList)
            .pipe(
                switchMap(() => this.store.dispatch(new NotificationsActions.GetNotificationsList(0))),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private async watchIncomingNotifications(allowedNotificationTypes: string[]) {
        const userId = await firstValueFrom(this.localStore.selectByKey("userId").pipe(RxjsUtils.filterFalsy()));
        if (!userId) {
            return;
        }

        this.store.dispatch(new NotificationsActions.StartIncomingNotificationsWatch(userId, allowedNotificationTypes));
    }

    public markAllAsRead() {
        this.store.dispatch(new NotificationsActions.MarkAllAsRead());
    }

    private getInitialNotificationsCount() {
        this.store.dispatch(new NotificationsActions.GetInitialNotificationsCount());
    }
}
