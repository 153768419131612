export type FileUploadProgress = number;

interface FileUploadResponseBody {
    id: string;
    name: string;
}

export type UploadedFile = FileUploadResponseBody;

export interface FileUploadError {
    type: FileUploadErrorType;
}
export enum FileUploadErrorType {
    Unknown = "FileUploadErrorUnknown",
}

export enum FileUploadState {
    InProgress = "InProgress",
    Done = "Done",
}

export interface Upload {
    progress?: number;
    state: FileUploadState;
    name: string;
    id?: string;
}
