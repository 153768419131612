import { Injectable } from "@angular/core";
import { MILLISECONDS_IN_SECOND } from "@dtm-frontend/shared/utils";
import { CesiumEvent, CesiumEventBuilder, CesiumEventModifier } from "@pansa/ngx-cesium";
import { ConnectableObservable, throttleTime } from "rxjs";

const MAX_EVENTS_PER_SECOND = 30;
const CESIUM_EVENTS_THROTTLE_TIME_MILLISECONDS = MILLISECONDS_IN_SECOND / MAX_EVENTS_PER_SECOND;

@Injectable()
export class ThrottledCesiumEventBuilder extends CesiumEventBuilder {
    public get(event: CesiumEvent, modifier?: CesiumEventModifier): ConnectableObservable<any> {
        return super.get(event, modifier).pipe(throttleTime(CESIUM_EVENTS_THROTTLE_TIME_MILLISECONDS)) as ConnectableObservable<any>;
    }
}
