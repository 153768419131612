import { Data, Params, Route } from "@angular/router";
import {
    FlightZoneApplication,
    FlightZoneApplicationChatMessage,
    FlightZoneApplicationListItem,
    FlightZoneError,
    HeightReferences,
    HorizontalMeasureUnits,
    NotamData,
    RestrictionsList,
    RestrictionsListItem,
    VerticalMeasureUnits,
} from "@dtm-frontend/dss-shared-lib";
import { FlightZoneListsStateModel } from "../state/flight-zone-lists.state";

export interface FlightZoneStateModel {
    error: FlightZoneError | undefined;
    isProcessing: boolean;
    flightZoneApplicationData: FlightZoneApplication;
    geometryUnits: GeometryUnits;
    notamData: NotamData | undefined;
    chatMessages: FlightZoneApplicationChatMessage[];
}

export interface GeometryUnits {
    horizontalMeasureUnits: HorizontalMeasureUnits[];
    verticalMeasureUnits: VerticalMeasureUnits[];
    heightReferences: HeightReferences[];
}

export enum FlightZoneWizardSteps {
    BasicZoneInfo = "BasicZoneInfo",
    ZoneGeometry = "ZoneGeometry",
    ExclusionsFromRestrictions = "ExclusionsFromRestrictions",
    Summary = "Summary",
}

export enum MTOMInputMode {
    Slider = "Slider",
    Precision = "Precision",
}

export interface ZoneDurationChangeDialogData {
    id: string;
    startAt: Date;
    endAt: Date;
    isStateSecurityRestriction: boolean | undefined;
}

export interface ZoneDurationChangeParams {
    id: string;
    startAt: Date;
    endAt: Date;
    shouldCancelRestriction: boolean;
}

export type FlightZonesTableColumns = Array<keyof FlightZoneApplicationListItem | "actions" | "expandHandle">;

export type RestrictionsTableColumns = Array<keyof RestrictionsListItem | "actions" | "expandHandle">;

export type RestrictionListRoute = Route & {
    data?: RestrictionListRouteData;
};

export interface RestrictionListRouteData extends Data {
    titleTranslationKey: string;
    action: new (filterParams: Params) => unknown;
    selector: (state: FlightZoneListsStateModel) => RestrictionsList | undefined;
}
