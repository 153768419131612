import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FlightZoneApplicationListItem } from "@dtm-frontend/dss-shared-lib";
import { IconName } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs/operators";

interface ModificationStatusBadge {
    iconName: IconName;
    className: string;
    label: string;
}

interface ModificationStatusBadgeComponentState {
    listElement: FlightZoneApplicationListItem | undefined;
}

@Component({
    selector: "dss-client-lib-modification-status-badge[listElement]",
    templateUrl: "./modification-status-badge.component.html",
    styleUrls: ["./modification-status-badge.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModificationStatusBadgeComponent {
    public readonly badge$ = this.localStore.selectByKey("listElement").pipe(
        RxjsUtils.filterFalsy(),
        map((listElement) => this.getBadgeSettings(listElement))
    );

    @Input()
    public set listElement(value: FlightZoneApplicationListItem) {
        this.localStore.patchState({ listElement: value });
    }

    constructor(private readonly localStore: LocalComponentStore<ModificationStatusBadgeComponentState>) {
        this.localStore.setState({
            listElement: undefined,
        });
    }

    private getBadgeSettings(listItem: FlightZoneApplicationListItem): ModificationStatusBadge {
        if (!listItem.isLocked) {
            return {
                iconName: "file-edit",
                className: "warning",
                label: "dssClientLibFlightZone.applicationStatusBadge.Correction",
            };
        }

        return {
            iconName: "file-unknow",
            className: "secondary",
            label: "dssClientLibFlightZone.applicationStatusBadge.WaitingForPublication",
        };
    }
}
