import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatestWith, switchMap } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { OperatorsThread } from "../../../models/conversations.models";
import { DeviceSize, DeviceSizeService } from "../../../services/device-size/device-size.service";

interface OperatorsThreadListComponentState {
    threadsList: OperatorsThread[];
    selectedThread: string | undefined;
    canDisplayAssignment: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-operators-thread-list[threadsList][selectedThread][canDisplayAssignment]",
    templateUrl: "./operators-thread-list.component.html",
    styleUrls: ["./operators-thread-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class OperatorsThreadListComponent {
    @Input() public set threadsList(value: OperatorsThread[]) {
        this.localStore.patchState({ threadsList: value ?? [] });
    }

    @Input() public set selectedThread(value: string | undefined) {
        this.localStore.patchState({ selectedThread: value });
    }

    @Input() public set canDisplayAssignment(value: boolean | undefined) {
        this.localStore.patchState({ canDisplayAssignment: !!value });
    }

    @Output() public threadChange: EventEmitter<string> = new EventEmitter();

    public readonly threadsList$ = this.localStore.selectByKey("threadsList");
    public readonly canDisplayAssignment$ = this.localStore.selectByKey("canDisplayAssignment");
    public readonly selectedThread$ = this.localStore.selectByKey("selectedThread");

    constructor(
        private readonly localStore: LocalComponentStore<OperatorsThreadListComponentState>,
        private readonly deviceSizeService: DeviceSizeService,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        localStore.setState({ threadsList: [], selectedThread: undefined, canDisplayAssignment: false });

        this.deviceSizeService
            .getSizeObservable(DeviceSize.Smartphone, DeviceSize.SmartphoneWide)
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() =>
                    this.threadsList$.pipe(combineLatestWith(this.selectedThread$.pipe(RxjsUtils.filterFalsy(), distinctUntilChanged())))
                ),
                untilDestroyed(this)
            )
            .subscribe(([threadsList, selectedThread]) =>
                this.document.getElementById(selectedThread)?.scrollIntoView({ behavior: "smooth" })
            );
    }
}
