import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import {
    AZURE_MAPS_LAYER_OPTIONS,
    CameraHelperService,
    DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
} from "@dtm-frontend/shared/map/cesium";
import { GeoJSON, RouteAreaTypeId, RouteData } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SceneMode, ViewerConfiguration } from "@pansa/ngx-cesium";
import turfBbox from "@turf/bbox";
import { Polygon, Properties, feature, featureCollection } from "@turf/helpers";
import { Observable, distinctUntilKeyChanged, map, startWith } from "rxjs";
import { first } from "rxjs/operators";
import { PlannedMission } from "../../models/planned-missions.models";

/* eslint-disable @typescript-eslint/no-explicit-any*/
declare const Cesium: any; // TODO: DTM-966

interface MissionMapComponentState {
    routeData: RouteData<PlannedMission> | undefined;
    initialViewbox: GeoJSON | undefined;
}

type DrawingSettingsForm = Partial<Record<RouteAreaTypeId, FormControl<boolean | null>>>;

@UntilDestroy()
@Component({
    selector: "dss-client-lib-mission-map[routeData]",
    templateUrl: "./mission-map.component.html",
    styleUrls: ["./mission-map.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionMapComponent {
    protected readonly AZURE_MAPS_LAYER_OPTIONS = AZURE_MAPS_LAYER_OPTIONS;
    protected readonly drawingSettingsForm = new FormGroup<DrawingSettingsForm>({
        flightArea: new FormControl(true),
        path: new FormControl(true),
        safetyArea: new FormControl(true),
    });

    protected readonly drawableFeatures$ = this.initDrawableFeatures();
    protected readonly initialViewbox$ = this.getInitialViewboxObservable();
    protected readonly routeData$ = this.localStore.selectByKey("routeData");

    @Input()
    public set routeData(value: RouteData<PlannedMission> | undefined) {
        this.localStore.patchState({ routeData: value });
    }

    @Input()
    public set initialViewbox(value: GeoJSON | undefined) {
        this.localStore.patchState({ initialViewbox: value });
    }

    constructor(
        viewerConfiguration: ViewerConfiguration,
        private readonly localStore: LocalComponentStore<MissionMapComponentState>,
        protected readonly cameraHelperService: CameraHelperService
    ) {
        localStore.setState({
            routeData: undefined,
            initialViewbox: undefined,
        });
        viewerConfiguration.viewerOptions = {
            ...DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
            sceneMode: SceneMode.SCENE2D,
        };

        this.handleZoomOnDataChanges();
    }

    private handleZoomOnDataChanges() {
        this.routeData$
            .pipe(RxjsUtils.filterFalsy(), distinctUntilKeyChanged("uniqueRouteId"))
            .pipe(untilDestroyed(this))
            .subscribe((data) => this.zoomToRouteArea(data));
    }

    private zoomToRouteArea(data?: RouteData<PlannedMission>) {
        const zoomArea = data?.route.sections
            .map(({ segment, flightZone }) => flightZone?.safetyArea.volume.area ?? segment?.safetyArea.volume.area)
            .filter(FunctionUtils.isTruthy)
            .map((polygon) => feature(polygon));

        if (!zoomArea) {
            return;
        }

        this.cameraHelperService.flyToGeoJSON(featureCollection<Polygon, Properties>(zoomArea), undefined, 1, 0);
    }

    private getInitialViewboxObservable() {
        return this.localStore.selectByKey("initialViewbox").pipe(
            RxjsUtils.filterFalsy(),
            first(),
            map((viewbox: GeoJSON) => {
                const bbox = turfBbox(viewbox);

                Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
                Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(...bbox);

                return Cesium.Camera.DEFAULT_VIEW_RECTANGLE;
            })
        );
    }

    private initDrawableFeatures(): Observable<RouteAreaTypeId[]> {
        return this.drawingSettingsForm.valueChanges.pipe(
            startWith(null),
            map(() =>
                Object.entries(this.drawingSettingsForm.value).reduce((result: RouteAreaTypeId[], [formKey, formValue]) => {
                    if (formValue) {
                        result.push(formKey as RouteAreaTypeId);
                    }

                    return result;
                }, [])
            )
        );
    }
}
