import { AfterContentInit, ContentChild, Directive } from "@angular/core";
import { NgControl } from "@angular/forms";
import { MatEndDate, MatStartDate } from "@angular/material/datepicker";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { distinctUntilChanged } from "rxjs/operators";

// TODO: Remove this fix after fixing this error in Angular - REJ-3070

@UntilDestroy()
@Directive({
    selector: "mat-date-range-input[dtmUiDateRangeValidatorsFixer]",
})
export class DateRangeValidatorsFixerDirective implements AfterContentInit {
    @ContentChild(MatStartDate, { read: NgControl }) private startFormNgControl: NgControl | undefined;
    @ContentChild(MatEndDate, { read: NgControl }) private endFormNgControl: NgControl | undefined;

    public ngAfterContentInit(): void {
        const startFormControl = this.startFormNgControl?.control;
        const endFormControl = this.endFormNgControl?.control;

        if (!startFormControl || !endFormControl) {
            return;
        }

        endFormControl.valueChanges
            .pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe(() => setTimeout(() => startFormControl.updateValueAndValidity()));
    }
}
