<div class="grid" *ngrxLet="{ restrictionsList: restrictionsList$ } as vm">
    <dss-shared-lib-applications-list-filters
        *ngrxLet="hasSupervisorPermissions$ as hasSupervisorPermissions"
        [initialFilters]="initialFilters"
        [flightZonePurposes]="flightZonePurposes"
        [hasSupervisorPermissions]="hasSupervisorPermissions"
        [shouldDisableAuthorFilter]="!hasSupervisorPermissions"
        [shouldDisableSendDateFilter]="true"
        [searchInputPlaceholder]="'dssClientLibFlightZone.restrictionsList.searchFilterLabel' | transloco"
        (filtersChange)="applyFilters($event)"
    >
        <ng-container totalCount>
            {{
                "dssClientLibFlightZone.applicationListShared.totalRestrictionsCountLabel"
                    | transloco : { totalCount: vm.restrictionsList?.page?.totalElements ?? 0 }
            }}
        </ng-container>
    </dss-shared-lib-applications-list-filters>

    <dss-client-lib-restrictions-list
        [restrictions]="vm.restrictionsList?.content"
        [displayedColumns]="displayedColumns"
        [isProcessing]="isProcessing$ | ngrxPush"
        [hasDataRetrievalError]="hasListDataRetrievalError$ | ngrxPush"
        [page]="vm.restrictionsList?.page"
        (pageChange)="changePage($event)"
        (listRefresh)="refreshList()"
        (openNotamPreview)="openNotamPreview($event)"
        (changeZoneDuration)="changeZoneDuration($event)"
        (kmlDownload)="downloadKml($event)"
    ></dss-client-lib-restrictions-list>
</div>
