import { Pipe, PipeTransform } from "@angular/core";
import { MILLISECONDS_IN_SECOND, SECONDS_IN_HOUR, SECONDS_IN_MINUTE } from "./time";

@Pipe({
    name: "convertToMilliseconds",
})
export class ConvertToMillisecondsPipe implements PipeTransform {
    public transform(value: number | undefined | null, from: "seconds" | "minutes" | "hours" = "seconds"): number | undefined {
        if (value === undefined || value === null) {
            return undefined;
        }

        if (from === "hours") {
            return value * MILLISECONDS_IN_SECOND * SECONDS_IN_HOUR;
        } else if (from === "minutes") {
            return value * MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE;
        }

        return value * MILLISECONDS_IN_SECOND;
    }
}
