import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { MissionPlanRoute, MissionPlanRouteSection } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, RxjsUtils, SpatialUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AcEntity, AcLayerComponent, AcNotification, ActionType, CesiumService } from "@pansa/ngx-cesium";
import { Polygon } from "@turf/helpers";
import { Observable, combineLatest, map, mergeMap, of, switchMap, tap } from "rxjs";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

const GROUND_RISK_BUFFER_OPACITY = 0.4;
const GROUND_RISK_BUFFER_FILL_COLOR = Cesium.Color.fromCssColorString("#df99a6").withAlpha(GROUND_RISK_BUFFER_OPACITY); // $color-error-200
const SAFETY_AREA_OPACITY = 0.5;
const SAFETY_AREA_FILL_COLOR = Cesium.Color.fromCssColorString("#ffd54f").withAlpha(SAFETY_AREA_OPACITY); // $color-primary-300

interface BufferAreaAcEntity extends AcEntity {
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    positions: any;
}

interface BufferAreasLayerComponentState {
    isShown: boolean;
    route: MissionPlanRoute | undefined;
    isProcessing: boolean;
    groundRiskBufferLayer: AcLayerComponent | undefined;
    safetyAreaLayer: AcLayerComponent | undefined;
    isSafetyAreaVisible: boolean;
    isGroundRiskAreaVisible: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-map-lib-buffer-areas-layer[isShown][route][isProcessing]",
    templateUrl: "./buffer-areas-layer.component.html",
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BufferAreasLayerComponent {
    protected readonly Cesium = Cesium;
    protected readonly SAFETY_AREA_FILL_COLOR = SAFETY_AREA_FILL_COLOR;
    protected readonly GROUND_RISK_BUFFER_FILL_COLOR = GROUND_RISK_BUFFER_FILL_COLOR;

    @ViewChild("groundRiskBufferLayer") protected set groundRiskBufferLayer(value: AcLayerComponent) {
        this.localStore.patchState({ groundRiskBufferLayer: value });
    }

    @ViewChild("safetyAreaLayer") protected set safetyAreaLayer(value: AcLayerComponent) {
        this.localStore.patchState({ safetyAreaLayer: value });
    }

    @Input() public set isShown(value: BooleanInput) {
        this.localStore.patchState({ isShown: coerceBooleanProperty(value) });
    }

    @Input() public set route(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ route: value });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set isSafetyAreaVisible(value: BooleanInput) {
        this.localStore.patchState({ isSafetyAreaVisible: coerceBooleanProperty(value) });
    }

    @Input() public set isGroundRiskAreaVisible(value: BooleanInput) {
        this.localStore.patchState({ isGroundRiskAreaVisible: coerceBooleanProperty(value) });
    }

    protected readonly isShown$ = this.localStore.selectByKey("isShown");
    private readonly isSafetyAreaVisible$ = this.localStore.selectByKey("isSafetyAreaVisible");
    private readonly isGroundRiskAreaVisible$ = this.localStore.selectByKey("isGroundRiskAreaVisible");

    private readonly canShowAnyArea$ = combineLatest([
        this.isShown$,
        this.localStore.selectByKey("isProcessing"),
        this.localStore.selectByKey("route"),
    ]).pipe(map(([isShown, isProcessing, route]) => isShown && !isProcessing && !!route));

    protected readonly isSafetyAreaShown$ = combineLatest([this.canShowAnyArea$, this.isSafetyAreaVisible$]).pipe(
        map(([canShowAnyArea, isSafetyAreaShown]) => canShowAnyArea && isSafetyAreaShown),
        tap(() => this.refresh())
    );
    protected readonly isGroundRiskBufferShown$ = combineLatest([this.canShowAnyArea$, this.isGroundRiskAreaVisible$]).pipe(
        map(([canShowAnyArea, isGroundRiskBufferShown]) => canShowAnyArea && isGroundRiskBufferShown),
        tap(() => this.refresh())
    );

    protected readonly safetyAreaEntities$ = this.initSafetyAreaEntities();
    protected readonly groundRiskBufferEntities$ = this.initGroundRiskBufferEntities();

    constructor(
        private readonly localStore: LocalComponentStore<BufferAreasLayerComponentState>,
        private readonly cesiumService: CesiumService
    ) {
        this.localStore.setState({
            isShown: true,
            route: undefined,
            isProcessing: false,
            groundRiskBufferLayer: undefined,
            safetyAreaLayer: undefined,
            isSafetyAreaVisible: false,
            isGroundRiskAreaVisible: false,
        });
    }

    private async getBufferAreaEntitiesFromPolygons(areas: Polygon[], subtractAreas: Polygon[] = []): Promise<BufferAreaAcEntity[]> {
        if (areas.length === 0) {
            return [];
        }

        const combinedAreas = SpatialUtils.union(areas);

        if (!combinedAreas) {
            return [];
        }

        const withSubtractedAreas = SpatialUtils.difference([combinedAreas.geometry, ...subtractAreas]);

        if (!withSubtractedAreas) {
            return [];
        }

        const geoJsonData = await Cesium.GeoJsonDataSource.load(withSubtractedAreas);
        const entities = geoJsonData.entities.values
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .filter((entity: any) => entity?.polygon?.hierarchy)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((entity: any, index: any) => {
                const result: BufferAreaAcEntity = {
                    id: `bufferArea_${index}`,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    positions: entity!.polygon!.hierarchy!.valueOf(),
                };

                return result;
            });

        return entities;
    }

    private initBufferAreaEntities(
        layer: AcLayerComponent,
        areaExtractionPredicate: (section: MissionPlanRouteSection) => (Polygon | undefined)[],
        subtractAreaExtractionPredicate?: (section: MissionPlanRouteSection) => (Polygon | undefined)[]
    ): Observable<AcNotification> {
        return this.localStore.selectByKey("route").pipe(
            mergeMap((route) => {
                if (!route) {
                    return [];
                }

                const areas = route.sections.flatMap(areaExtractionPredicate).filter(FunctionUtils.isTruthy);
                const subtractAreas = route.sections.flatMap(subtractAreaExtractionPredicate ?? (() => [])).filter(FunctionUtils.isTruthy);

                return this.getBufferAreaEntitiesFromPolygons(areas, subtractAreas);
            }),
            map((entities) => this.getBufferAreaEntityNotifications(layer, entities)),
            mergeMap((entities) => of(...entities))
        );
    }

    private initSafetyAreaEntities(): Observable<AcNotification> {
        return this.localStore.selectByKey("safetyAreaLayer").pipe(
            RxjsUtils.filterFalsy(),
            switchMap((safetyAreaLayer) =>
                this.initBufferAreaEntities(
                    safetyAreaLayer,
                    (section) => [section.flightZone?.safetyArea.volume.area, section.segment?.safetyArea.volume.area],
                    (section) => [section.flightZone?.flightArea.volume.area, section.segment?.flightArea.volume.area]
                )
            )
        );
    }

    private initGroundRiskBufferEntities(): Observable<AcNotification> {
        return this.localStore.selectByKey("groundRiskBufferLayer").pipe(
            RxjsUtils.filterFalsy(),
            switchMap((groundRiskBufferLayer) =>
                this.initBufferAreaEntities(
                    groundRiskBufferLayer,
                    (section) => [section.flightZone?.groundArea, section.segment?.groundArea],
                    (section) => [section.flightZone?.safetyArea.volume.area, section.segment?.safetyArea.volume.area]
                )
            )
        );
    }

    private refresh() {
        this.cesiumService.getScene().requestRender();
    }

    private getBufferAreaEntityNotifications(layer: AcLayerComponent, entities: BufferAreaAcEntity[]): AcNotification[] {
        const result: AcNotification[] = [];
        const unusedIds = new Set(Array.from(layer.getStore().keys()));

        entities.forEach((entity) => {
            unusedIds.delete(entity.id);
            result.push({
                entity,
                actionType: ActionType.ADD_UPDATE,
                id: entity.id,
            });
        });

        unusedIds.forEach((entityId) =>
            result.push({
                id: entityId,
                actionType: ActionType.DELETE,
            })
        );

        return result;
    }
}
