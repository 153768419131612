<ul
    *ngrxLet="{
        addButtonLabel: addButtonLabel$,
        uploadFieldLabel: uploadFieldLabel$,
        isDisabled: isDisabled$,
        groups: groups$,
        editMode: editMode$,
        fileUploadControls: fileUploadControls$,
        additionalPathParams: additionalPathParams$
    } as vm"
    class="groups"
>
    <li *ngFor="let group of vm.groups" [class.edit-mode]="!(group | invoke : isEditModeOff : vm.editMode)">
        <ng-container *ngIf="group | invoke : isEditModeOff : vm.editMode; else editModeTemplate">
            <div class="group-header">
                <strong>{{ group.groupLabel }}</strong>
                <button
                    *ngIf="group | invoke : isAddButtonVisible"
                    type="button"
                    class="button-secondary"
                    [disabled]="vm.isDisabled"
                    (click)="setEditMode(group.groupId, true)"
                >
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    {{ vm.addButtonLabel }}
                </button>
            </div>
            <ul class="files">
                <li *ngFor="let file of group.files">
                    <dtm-ui-icon name="attachment"></dtm-ui-icon>
                    <span>
                        {{ file.name }}
                        <span *ngIf="file.size" class="file-size">({{ file.size | formatFileSize }})</span>
                    </span>
                    <div>
                        <button type="button" class="button-icon" (click)="download(file)">
                            <dtm-ui-icon name="download"></dtm-ui-icon>
                        </button>
                        <button
                            *ngIf="file | invoke : isRemoveButtonVisible"
                            type="button"
                            class="button-icon"
                            [disabled]="vm.isDisabled"
                            (click)="tryRemoveFile(file, group)"
                        >
                            <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                        </button>
                    </div>
                </li>
            </ul>
        </ng-container>

        <ng-template #editModeTemplate>
            <section class="add-file">
                <div class="group-header">
                    <strong>{{ group.groupLabel }}</strong>
                    <button type="button" class="button-icon" (click)="setEditMode(group.groupId, false)">
                        <dtm-ui-icon name="close"></dtm-ui-icon>
                    </button>
                </div>
                <div class="form">
                    <label>{{ vm.uploadFieldLabel }}</label>
                    <dtm-ui-files-upload-field
                        [isFileListVisible]="false"
                        [formControl]="vm.fileUploadControls[group.groupId]"
                        [isMultiple]="!group.validators?.singleFile"
                        [allowedTypes]="group.validators?.allowedTypes | invoke : getAllowedTypes"
                        [maxFileSize]="group.validators?.maxSize"
                        [additionalPathParams]="vm.additionalPathParams"
                        shouldEmitChangeAfterAllCompleted
                    >
                        <span class="field-hint">
                            <ng-container *ngIf="group.validators?.singleFile">
                                {{ "dtmUi.filesGroups.upload.hintSingleFileLabel" | transloco }}
                            </ng-container>
                            <ng-container *ngIf="(group.validators?.allowedTypes | invoke : getAllowedTypesNames)?.length">
                                {{
                                    "dtmUi.filesGroups.upload.hintAllowedTypesLabel"
                                        | transloco : { types: group.validators?.allowedTypes | invoke : getAllowedTypesNames | join }
                                }}
                            </ng-container>
                            <ng-container *ngIf="group.validators?.maxSize">
                                {{
                                    "dtmUi.filesGroups.upload.hintFileSizeLabel"
                                        | transloco : { size: group.validators?.maxSize | formatFileSize }
                                }}
                            </ng-container>
                        </span>
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="vm.fileUploadControls[group.groupId]; name: 'maxlength'; error as error"
                        >
                            {{
                                "dtmUi.filesGroups.upload.maximumUploadedFilesAmountError" | transloco : { maxAmount: error.requiredLength }
                            }}
                        </div>
                    </dtm-ui-files-upload-field>
                </div>
            </section>
        </ng-template>
    </li>
</ul>
