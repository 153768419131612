/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line max-len
// NOTE: this is based on https://github.com/pansa-dev/ngx-cesium/blob/HEAD/projects/@pansa/ngx-cesium/src/lib/@pansa/ngx-cesium-widgets/services/entity-editors/polygons-editor/polygons-editor.service.ts#L1-L642
import { Injectable } from "@angular/core";
import { NarrowIndexable, ObjectUtils, RxjsUtils, StringUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
    CameraService,
    Cartesian3,
    CesiumEvent,
    CesiumService,
    ClampTo3DOptions,
    ContextMenuService,
    CoordinateConverter,
    DisposableObservable,
    EditActions,
    EditModes,
    EventResult,
    LabelProps,
    MapEventsManagerService,
    Movement,
    PickOptions,
} from "@pansa/ngx-cesium";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, filter, map, publish, startWith, tap } from "rxjs/operators";
import { CartographicEditPoint, CesiumPointWithProps } from "../../../models/cartographic-edit-point";
import {
    EditablePrism,
    MINIMUM_VERTEX_NUMBER,
    PrismEditorLabelProviders,
    PrismHeightPolyline,
    PrismLabel,
} from "../../../models/prism/editable-prism";
import { PrismContextMenuData } from "../../../models/prism/prism-context-menu.model";
import { PrismEditOptions, PrismProps } from "../../../models/prism/prism-edit-options";
import { PrismEditUpdate } from "../../../models/prism/prism-edit-update";
import { PrismEditorObservable } from "../../../models/prism/prism-editor-observable";
import { DraggableHeightEntity, HeightEntityType, HeightHelperService, ShapeDragActions } from "../../height-helper.service";
import {
    CesiumPointerManagerService,
    CesiumPointerType,
    ViewerContainerEventType,
} from "../../pointer-manager/cesium-pointer-manager.service";
import { PrismsManagerService } from "./prisms-manager.service";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export const DEFAULT_PRISM_OPTIONS: PrismEditOptions = {
    allowDrag: true,
    pointProps: {
        // eslint-disable-next-line no-magic-numbers
        color: Cesium.Color.WHITE.withAlpha(0.95),
        // eslint-disable-next-line no-magic-numbers
        outlineColor: Cesium.Color.BLACK.withAlpha(0.2),
        outlineWidth: 1,
        pixelSize: 13,
        virtualPointPixelSize: 8,
        show: true,
        showVirtual: true,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
    },
    prismProps: {
        // eslint-disable-next-line no-magic-numbers
        material: Cesium.Color.fromCssColorString("rgb(6, 22, 54)").withAlpha(0.4), // $color-gray-900
        // eslint-disable-next-line no-magic-numbers
        shadowMaterial: Cesium.Color.WHITE.withAlpha(0.6),
        // eslint-disable-next-line no-magic-numbers
        invalidMaterial: Cesium.Color.fromCssColorString("#b00020").withAlpha(0.6), // $color-error-400
        fill: true,
        classificationType: Cesium.ClassificationType.BOTH,
        zIndex: 0,
    },
    polylineProps: {
        material: () => Cesium.Color.WHITE,
        width: 3,
        clampToGround: false,
        zIndex: 0,
        classificationType: Cesium.ClassificationType.BOTH,
    },
    clampHeightTo3D: false,
    clampHeightTo3DOptions: {
        clampToTerrain: false,
        clampMostDetailed: true,
        clampToHeightPickWidth: 2,
    },
};

enum PrismActiveEntityTypes {
    Point = "Point",
    HeightOutline = "HeightOutline",
    ShapeOrNone = "ShapeOrNone",
}

const CLAMP_DEBOUNCE_TIME = 300;

@UntilDestroy()
@Injectable()
export class PrismsEditorService {
    private readonly maximumNumberOfPointsReachedSubject = new Subject<number>();
    public readonly maximumNumberOfPointsReached$ = this.maximumNumberOfPointsReachedSubject.asObservable();

    private mapEventsManager!: MapEventsManagerService;
    private heightHelperService!: HeightHelperService;
    private updateSubject = new Subject<PrismEditUpdate>();
    private updatePublisher = publish<PrismEditUpdate>()(this.updateSubject);
    private coordinateConverter!: CoordinateConverter;
    private cameraService!: CameraService;
    private prismsManager!: PrismsManagerService;
    private observablesMap = new Map<string, DisposableObservable<any>[]>();
    private cesiumScene!: any;
    private cesiumViewer!: any;
    private screenSpaceCameraController!: any;
    private activeEntitiesMap = new Map<string, PrismActiveEntityTypes>();
    private cesiumPointerManager!: CesiumPointerManagerService;
    private contextMenuService!: ContextMenuService;

    public init(
        mapEventsManager: MapEventsManagerService,
        coordinateConverter: CoordinateConverter,
        cameraService: CameraService,
        prismsManager: PrismsManagerService,
        cesiumService: CesiumService,
        cesiumPointerManager: CesiumPointerManagerService,
        heightHelperService: HeightHelperService,
        contextMenuService: ContextMenuService
    ) {
        this.mapEventsManager = mapEventsManager;
        this.coordinateConverter = coordinateConverter;
        this.cameraService = cameraService;
        this.prismsManager = prismsManager;
        this.updatePublisher.connect();
        this.cesiumScene = cesiumService.getScene();
        this.cesiumViewer = cesiumService.getViewer();
        this.screenSpaceCameraController = this.cesiumScene.screenSpaceCameraController;
        this.cesiumPointerManager = cesiumPointerManager;
        this.heightHelperService = heightHelperService;
        this.contextMenuService = contextMenuService;
    }

    public onUpdate(): Observable<PrismEditUpdate> {
        return this.updatePublisher.pipe(tap(() => this.cesiumScene.requestRender()));
    }

    private clampPoints(
        id: string,
        clampHeightTo3D: boolean,
        { clampToTerrain, clampMostDetailed, clampToHeightPickWidth }: ClampTo3DOptions
    ) {
        if (clampHeightTo3D && clampMostDetailed) {
            const prism = this.prismsManager.get(id);
            const points = prism?.getPoints();

            if (!points) {
                return;
            }

            if (!clampToTerrain) {
                points.forEach((point) => {
                    point.setPosition(this.cesiumScene.clampToHeight(point.getPosition(), undefined, clampToHeightPickWidth));
                });
            } else {
                const cartographics = points.map((point) => this.coordinateConverter.cartesian3ToCartographic(point.getPosition()));
                // eslint-disable-next-line no-magic-numbers
                const promise = Cesium.sampleTerrain(this.cesiumScene.terrainProvider, 11, cartographics);
                Cesium.when(promise, (updatedPositions: any[]) => {
                    points.forEach((point, index) => {
                        point.setPosition(Cesium.Cartographic.toCartesian(updatedPositions[index]));
                    });
                });
            }
        }
    }

    private screenToPosition(
        cartesian2: { x: number; y: number },
        clampHeightTo3D: boolean,
        { clampToHeightPickWidth, clampToTerrain }: ClampTo3DOptions
    ) {
        const cartesian3 = this.coordinateConverter.screenToCartesian3(cartesian2);

        if (clampHeightTo3D && cartesian3) {
            const globePositionPick = () => {
                const ray = this.cameraService.getCamera().getPickRay(cartesian2);

                return this.cesiumScene.globe.pick(ray, this.cesiumScene);
            };

            if (clampToTerrain) {
                return globePositionPick();
            } else {
                const cartesian3PickPosition = this.cesiumScene.pickPosition(cartesian2);
                const latLon = CoordinateConverter.cartesian3ToLatLon(cartesian3PickPosition);
                if (latLon.height < 0) {
                    return globePositionPick();
                }

                return this.cesiumScene.clampToHeight(cartesian3PickPosition, undefined, clampToHeightPickWidth);
            }
        }

        return cartesian3;
    }

    public create(
        id = StringUtils.generateId(),
        topHeight = 0,
        bottomHeight = 0,
        options: Partial<PrismEditOptions> = DEFAULT_PRISM_OPTIONS,
        initialPositions?: Cartesian3[]
    ): PrismEditorObservable {
        const positions: Cartesian3[] = [];
        const prismOptions = this.setOptions(options);

        const clientEditSubject = new BehaviorSubject<PrismEditUpdate>({
            id,
            editAction: null,
            editMode: EditModes.CREATE,
        });
        let finishedCreate = false;

        this.updateSubject.next({
            id,
            positions,
            topHeight,
            bottomHeight,
            editMode: EditModes.CREATE,
            editAction: EditActions.INIT,
            prismOptions: prismOptions,
        });

        const finishCreation = (position?: Cartesian3) =>
            this.switchToEditMode(
                id,
                position,
                topHeight,
                bottomHeight,
                clientEditSubject,
                prismOptions,
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                editorObservable,
                finishedCreate
            );

        const mouseMoveRegistration = this.cesiumPointerManager.addEventHandler({
            event: CesiumEvent.MOUSE_MOVE,
            pick: PickOptions.NO_PICK,
        });
        const addPointRegistration = this.cesiumPointerManager.addEventHandler({
            event: prismOptions.addPointEvent ?? CesiumEvent.LEFT_CLICK,
            modifier: prismOptions.addPointModifier,
            pick: PickOptions.NO_PICK,
        });
        const addLastPointRegistration = this.cesiumPointerManager.addEventHandler({
            event: prismOptions.addLastPointEvent ?? CesiumEvent.LEFT_DOUBLE_CLICK,
            modifier: prismOptions.addLastPointModifier,
            pick: PickOptions.NO_PICK,
        });
        const contextMenuRegistration = this.cesiumPointerManager.addEventHandler({
            event: CesiumEvent.RIGHT_CLICK,
            pick: PickOptions.NO_PICK,
        });
        const mouseOutRegistration = this.cesiumPointerManager.addViewerContainerEventHandler(ViewerContainerEventType.MouseOut);

        this.observablesMap.set(id, [
            mouseMoveRegistration,
            addPointRegistration,
            addLastPointRegistration,
            contextMenuRegistration,
            mouseOutRegistration,
        ]);
        const editorObservable = this.createEditorObservable(clientEditSubject, id, finishCreation);

        const onMouseMove = (endPosition: Movement["endPosition"]) => {
            if (!prismOptions.clampHeightTo3DOptions) {
                throw new Error("Prisms editor error: clampHeightTo3DOptions is required");
            }

            const position = this.screenToPosition(endPosition, !!prismOptions.clampHeightTo3D, prismOptions.clampHeightTo3DOptions);

            if (position && !finishedCreate) {
                this.updateSubject.next({
                    id,
                    positions: this.getPositions(id),
                    editMode: EditModes.CREATE,
                    updatedPosition: position,
                    editAction: EditActions.MOUSE_MOVE,
                });
            }

            return position;
        };

        mouseMoveRegistration.pipe(untilDestroyed(this)).subscribe(({ movement: { endPosition } }) => onMouseMove(endPosition));

        addPointRegistration.pipe(untilDestroyed(this)).subscribe(({ movement: { endPosition } }) => {
            const position = onMouseMove(endPosition);

            if (!position || finishedCreate || !this.isValidPrism(id)) {
                return;
            }

            const allPositions = this.getPositions(id);
            if (!allPositions || allPositions.find((cartesian) => cartesian.equals(position))) {
                return;
            }

            const updateValue: PrismEditUpdate = {
                id,
                positions: allPositions,
                editMode: EditModes.CREATE,
                updatedPosition: position,
                editAction: EditActions.ADD_POINT,
            };
            this.updateSubject.next(updateValue);
            clientEditSubject.next({
                ...updateValue,
                ...this.getPrismProperties(id),
            });

            if (
                prismOptions.maximumNumberOfPoints &&
                allPositions.length + 1 === prismOptions.maximumNumberOfPoints &&
                this.isValidPrism(id)
            ) {
                this.maximumNumberOfPointsReachedSubject.next(prismOptions.maximumNumberOfPoints);
                finishedCreate = finishCreation(position);
            }

            if (this.contextMenuService.showContextMenu) {
                this.contextMenuService.close();
            }
        });

        addLastPointRegistration.pipe(untilDestroyed(this)).subscribe(({ movement: { endPosition } }) => {
            const position = onMouseMove(endPosition);

            const allPositions = this.getPositions(id);

            if (!allPositions || allPositions.length < MINIMUM_VERTEX_NUMBER || !position || finishedCreate || !this.isValidPrism(id)) {
                return;
            }

            if (!allPositions.find((cartesian) => cartesian.equals(position))) {
                const updateValue: PrismEditUpdate = {
                    id,
                    positions: allPositions,
                    editMode: EditModes.CREATE,
                    updatedPosition: position,
                    editAction: EditActions.ADD_POINT,
                };
                this.updateSubject.next(updateValue);
                clientEditSubject.next({
                    ...updateValue,
                    ...this.getPrismProperties(id),
                });
            }

            finishedCreate = finishCreation(position);
        });

        mouseOutRegistration.pipe(untilDestroyed(this)).subscribe(() => {
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE,
                shouldRemoveMovingPoint: true,
                editAction: EditActions.REMOVE_POINT,
            });
        });

        if (options.contextMenu) {
            contextMenuRegistration.pipe(untilDestroyed(this)).subscribe((event) => {
                const position = this.coordinateConverter.screenToCartesian3(event.movement.endPosition);

                if (!position) {
                    return;
                }

                if (this.contextMenuService.showContextMenu) {
                    this.contextMenuService.close();
                }

                const allPositions = this.getPositions(id);
                const positionsCount = allPositions?.length ?? 0;

                this.contextMenuService.open<PrismContextMenuData>(options.contextMenu, position, {
                    data: {
                        positionsCount,
                        isMinimumVertexNumber: positionsCount >= MINIMUM_VERTEX_NUMBER,
                        onFinishCreate: () => {
                            finishedCreate = finishCreation(position);
                            this.contextMenuService.close();
                        },
                        onRemoveArea: () => {
                            const update: PrismEditUpdate = {
                                id,
                                editMode: EditModes.CREATE,
                                editAction: EditActions.DISPOSE,
                            };
                            this.updateSubject.next(update);
                            clientEditSubject.next(update);

                            this.contextMenuService.close();
                        },
                        onRemoveLastPoint: () => {
                            const allPoints = [...(this.getPoints(id) ?? [])];
                            const update: PrismEditUpdate = {
                                id,
                                positions: allPositions,
                                updatedPoint: allPoints.pop(),
                                editMode: EditModes.CREATE,
                                editAction: EditActions.REMOVE_POINT,
                            };
                            this.updateSubject.next(update);
                            clientEditSubject.next({
                                ...update,
                                ...this.getPrismProperties(id),
                            });

                            if (options.clampHeightTo3D && options.clampHeightTo3DOptions) {
                                this.clampPoints(id, options.clampHeightTo3D, options.clampHeightTo3DOptions);
                            }

                            this.contextMenuService.close();
                        },
                    },
                });
            });
        }

        if (initialPositions) {
            initialPositions.forEach((position) => {
                const updateValue: PrismEditUpdate = {
                    id,
                    positions: this.getPositions(id),
                    editMode: EditModes.CREATE,
                    updatedPosition: position,
                    editAction: EditActions.ADD_POINT,
                };
                this.updateSubject.next(updateValue);
                clientEditSubject.next({
                    ...updateValue,
                    ...this.getPrismProperties(id),
                });
            });
        }

        return editorObservable;
    }

    private switchToEditMode(
        id: string,
        position: Cartesian3 | undefined,
        topHeight: number,
        bottomHeight: number,
        clientEditSubject: BehaviorSubject<PrismEditUpdate>,
        prismOptions: PrismEditOptions,
        editorObservable: PrismEditorObservable,
        finishedCreate: boolean
    ) {
        const updateValue: PrismEditUpdate = {
            id,
            positions: this.getPositions(id),
            topHeight,
            bottomHeight,
            editMode: EditModes.CREATE,
            updatedPosition: position,
            editAction: EditActions.DONE,
        };
        this.updateSubject.next(updateValue);
        clientEditSubject.next({
            ...updateValue,
            ...this.getPrismProperties(id),
        });

        const changeMode: PrismEditUpdate = {
            id,
            editMode: EditModes.CREATE,
            editAction: EditActions.CHANGE_TO_EDIT,
        };
        this.updateSubject.next(changeMode);
        clientEditSubject.next(changeMode);
        if (this.observablesMap.has(id)) {
            this.observablesMap.get(id)?.forEach((registration) => registration.dispose());
        }
        this.observablesMap.delete(id);
        this.editPrism(id, clientEditSubject, prismOptions, editorObservable);
        finishedCreate = true;

        return finishedCreate;
    }

    public edit(
        positions: Cartesian3[],
        topHeight = 0,
        bottomHeight = 0,
        id = StringUtils.generateId(),
        options: Partial<PrismEditOptions> = DEFAULT_PRISM_OPTIONS
    ): PrismEditorObservable {
        if (positions.length < MINIMUM_VERTEX_NUMBER) {
            throw new Error(`Prisms editor error edit(): prism should have at least ${MINIMUM_VERTEX_NUMBER} positions`);
        }
        const prismOptions = this.setOptions(options);
        const editSubject = new BehaviorSubject<PrismEditUpdate>({
            id,
            editAction: null,
            editMode: EditModes.EDIT,
        });
        const update: PrismEditUpdate = {
            id,
            positions: positions,
            topHeight,
            bottomHeight,
            editMode: EditModes.EDIT,
            editAction: EditActions.INIT,
            prismOptions: prismOptions,
        };
        this.updateSubject.next(update);
        editSubject.next({
            ...update,
            ...this.getPrismProperties(id),
        });

        return this.editPrism(id, editSubject, prismOptions);
    }

    private editPrism(
        id: string,
        editSubject: Subject<PrismEditUpdate>,
        options: PrismEditOptions,
        editObservable?: PrismEditorObservable
    ): PrismEditorObservable {
        this.activeEntitiesMap.set(id, PrismActiveEntityTypes.ShapeOrNone);
        const editorObservable = editObservable || this.createEditorObservable(editSubject, id);
        this.clampPoints(id, options.clampHeightTo3D, options.clampHeightTo3DOptions);

        const pointDragRegistration = this.cesiumPointerManager.addEventHandler({
            event: options.dragPointEvent ?? CesiumEvent.LEFT_CLICK_DRAG,
            entityType: CartographicEditPoint,
            pick: PickOptions.PICK_FIRST,
            pickFilter: (entity) => id === entity.editedEntityId,
        });

        const heightPointDragRegistration = this.cesiumPointerManager.addEventHandler({
            event: CesiumEvent.LEFT_CLICK_DRAG,
            entityType: PrismHeightPolyline,
            pick: PickOptions.PICK_ALL,
            pickFilter: (entity: PrismHeightPolyline) => id === entity.entityId,
        });

        let shapeDragRegistration;
        if (options.allowDrag) {
            shapeDragRegistration = this.cesiumPointerManager.addEventHandler({
                event: options.dragShapeEvent ?? CesiumEvent.LEFT_CLICK_DRAG,
                entityType: EditablePrism,
                pick: PickOptions.PICK_FIRST,
                pickFilter: (entity) => id === entity.id,
            });
        }

        const pointRemoveRegistration = this.cesiumPointerManager.addEventHandler({
            event: options.removePointEvent ?? CesiumEvent.RIGHT_CLICK,
            entityType: CartographicEditPoint,
            modifier: options.removePointModifier,
            pick: PickOptions.PICK_FIRST,
            pickFilter: (entity) => id === entity.editedEntityId && entity !== this.getPolygonWaypoint(id),
        });

        const entityMouseHoverRegistration = this.cesiumPointerManager.addEventHandler({
            event: CesiumEvent.MOUSE_MOVE,
            pick: PickOptions.PICK_ALL,
        });

        pointDragRegistration
            .pipe(
                tap(({ movement: { drop } }) => drop !== undefined && this.cameraService.enableInputs(drop)),
                map((event: EventResult) => this.onPointDrag(event, id, options, editSubject)),
                debounceTime(CLAMP_DEBOUNCE_TIME),
                RxjsUtils.filterFalsy(),
                tap(({ prismId, prismOptions }) =>
                    this.clampPoints(prismId, prismOptions.clampHeightTo3D, prismOptions.clampHeightTo3DOptions)
                ),
                untilDestroyed(this)
            )
            .subscribe();

        heightPointDragRegistration
            .pipe(
                tap(({ movement: { drop } }) => drop !== undefined && this.cameraService.enableInputs(drop)),
                untilDestroyed(this)
            )
            .subscribe((event) => this.onHeightDrag(event, id, editSubject));

        shapeDragRegistration
            ?.pipe(
                tap(({ movement: { drop } }) => this.prismsManager.get(id)?.enableEdit && this.cameraService.enableInputs(!!drop)),
                untilDestroyed(this)
            )
            .subscribe((event) => this.onShapeDrag(event, id, options, editSubject));

        pointRemoveRegistration.pipe(untilDestroyed(this)).subscribe((event) => this.onPointRemove(event, id, options, editSubject));
        entityMouseHoverRegistration.pipe(untilDestroyed(this)).subscribe((event) => this.onEntityMouseHover(event, id, editorObservable));

        const observables = [pointDragRegistration, heightPointDragRegistration, entityMouseHoverRegistration, pointRemoveRegistration];

        if (shapeDragRegistration) {
            observables.push(shapeDragRegistration);
        }

        this.observablesMap.set(id, observables);

        return editorObservable;
    }

    private onHeightDrag(event: EventResult, prismId: string, editSubject: Subject<PrismEditUpdate>) {
        if (this.activeEntitiesMap.get(prismId) !== PrismActiveEntityTypes.HeightOutline) {
            return;
        }

        const {
            movement: { startPosition: cursorStartPosition, endPosition: cursorEndPosition, drop: isDrop },
            entities,
        } = event;

        const startDragPosition = this.coordinateConverter.screenToCartesian3(cursorStartPosition);
        const endDragPosition = this.coordinateConverter.screenToCartesian3(cursorEndPosition);
        const firstPosition = this.getPositions(prismId)?.[0];
        const draggedEntity = entities[0] as PrismHeightPolyline;
        const isDraggingTopHeight = draggedEntity.heightEntity.type === HeightEntityType.Top;

        if (!endDragPosition || !startDragPosition || entities.length === 0 || !firstPosition || !draggedEntity) {
            return;
        }

        const newHeight = this.heightHelperService.calculateHeightFromDragEvent(draggedEntity.heightEntity, cursorEndPosition, !!isDrop);

        const update: PrismEditUpdate = {
            id: prismId,
            positions: this.getPositions(prismId),
            points: this.getPoints(prismId),
            topHeight: isDraggingTopHeight ? newHeight : this.getTopHeight(prismId),
            bottomHeight: isDraggingTopHeight ? this.getBottomHeight(prismId) : newHeight,
            updatedPoint: event.entities[0],
            shapeDragAction: isDraggingTopHeight ? ShapeDragActions.ChangeTopHeight : ShapeDragActions.ChangeBottomHeight,
            editMode: EditModes.EDIT,
            editAction: isDrop ? EditActions.DRAG_SHAPE_FINISH : EditActions.DRAG_SHAPE,
        };

        this.updateSubject.next(update);

        editSubject.next({
            ...update,
            ...this.getPrismProperties(prismId),
        });
    }

    private onPointRemove(event: EventResult, prismId: string, options: PrismEditOptions, editSubject: Subject<PrismEditUpdate>) {
        const point: CartographicEditPoint = event.entities[0];
        const positionsToCopy = this.getPositions(prismId);

        if (!positionsToCopy || positionsToCopy.length <= MINIMUM_VERTEX_NUMBER) {
            return;
        }

        const allPositions = [...positionsToCopy];
        const index = allPositions.findIndex((position) => point.getPosition().equals(position as Cartesian3));

        if (index < 0) {
            return;
        }

        const update: PrismEditUpdate = {
            id: prismId,
            positions: allPositions,
            editMode: EditModes.EDIT,
            updatedPoint: point,
            editAction: EditActions.REMOVE_POINT,
        };
        this.updateSubject.next(update);
        editSubject.next({
            ...update,
            ...this.getPrismProperties(prismId),
        });

        this.clampPoints(prismId, options.clampHeightTo3D, options.clampHeightTo3DOptions);
    }

    private onPointDrag(event: EventResult, prismId: string, options: PrismEditOptions, editSubject: Subject<PrismEditUpdate>) {
        if (this.activeEntitiesMap.get(prismId) !== PrismActiveEntityTypes.Point) {
            return;
        }

        const position = this.screenToPosition(event.movement.endPosition, options.clampHeightTo3D, options.clampHeightTo3DOptions);

        if (!position) {
            return;
        }
        const point: CartographicEditPoint = event.entities[0];

        const isPolygonWaypoint = point === this.getPolygonWaypoint(prismId);

        let editAction;
        if (event.movement.drop) {
            editAction = isPolygonWaypoint ? EditActions.DRAG_SHAPE_FINISH : EditActions.DRAG_POINT_FINISH;
        } else {
            editAction = isPolygonWaypoint ? EditActions.DRAG_SHAPE : EditActions.DRAG_POINT;
        }

        if (!options.allowDrag && (editAction === EditActions.DRAG_SHAPE || editAction === EditActions.DRAG_SHAPE_FINISH)) {
            this.cameraService.enableInputs(true);

            return;
        }

        const update: PrismEditUpdate = {
            id: prismId,
            positions: this.getPositions(prismId),
            editMode: EditModes.EDIT,
            updatedPosition: position,
            updatedPoint: point,
            shapeDragAction: isPolygonWaypoint ? ShapeDragActions.MoveEntity : undefined,
            editAction,
        };
        this.updateSubject.next(update);
        editSubject.next({
            ...update,
            ...this.getPrismProperties(prismId),
        });

        return { prismId, prismOptions: options };
    }

    private onShapeDrag(event: EventResult, prismId: string, options: PrismEditOptions, editSubject: Subject<PrismEditUpdate>) {
        if (this.activeEntitiesMap.get(prismId) !== PrismActiveEntityTypes.ShapeOrNone) {
            return;
        }

        const endDragPosition = this.screenToPosition(event.movement.endPosition, false, options.clampHeightTo3DOptions);
        const startDragPosition = this.screenToPosition(event.movement.startPosition, false, options.clampHeightTo3DOptions);

        if (!endDragPosition) {
            return;
        }

        const update: PrismEditUpdate = {
            id: prismId,
            positions: this.getPositions(prismId),
            editMode: EditModes.EDIT,
            updatedPosition: endDragPosition,
            draggedPosition: startDragPosition,
            shapeDragAction: ShapeDragActions.MoveEntity,
            editAction: event.movement.drop ? EditActions.DRAG_SHAPE_FINISH : EditActions.DRAG_SHAPE,
        };
        this.updateSubject.next(update);
        editSubject.next({
            ...update,
            ...this.getPrismProperties(prismId),
        });
    }

    private onEntityMouseHover(event: EventResult, prismId: string, editorObservable: PrismEditorObservable) {
        if (!this.screenSpaceCameraController.enableInputs || !this.prismsManager.get(prismId)?.enableEdit) {
            return;
        }

        const isPointEntity = event.entities?.some((lookupEntity) => lookupEntity instanceof CartographicEditPoint);

        if (isPointEntity) {
            this.activeEntitiesMap.set(prismId, PrismActiveEntityTypes.Point);

            return;
        }

        const heightEntity: DraggableHeightEntity = event.entities?.find(
            (lookupEntity) => lookupEntity instanceof PrismHeightPolyline
        )?.heightEntity;

        if (heightEntity && heightEntity.parentEntityId === prismId && heightEntity.isMovable()) {
            this.cesiumPointerManager.setPointerType(prismId, CesiumPointerType.HeightHandle);
            this.setHoverLabelId(prismId, heightEntity.type, editorObservable);
            this.activeEntitiesMap.set(prismId, PrismActiveEntityTypes.HeightOutline);

            return;
        }

        this.cesiumPointerManager.setPointerType(prismId, CesiumPointerType.None);
        this.setHoverLabelId(prismId, "waypoint", editorObservable);
        this.activeEntitiesMap.set(prismId, PrismActiveEntityTypes.ShapeOrNone);
    }

    private setOptions(options: Partial<PrismEditOptions>) {
        if (options.maximumNumberOfPoints && options.maximumNumberOfPoints < MINIMUM_VERTEX_NUMBER) {
            console.warn(
                `Warn: PrismEditor invalid option. \
                maximumNumberOfPoints smaller than ${MINIMUM_VERTEX_NUMBER}, maximumNumberOfPoints changed to ${MINIMUM_VERTEX_NUMBER}`
            );
            options.maximumNumberOfPoints = MINIMUM_VERTEX_NUMBER;
        }

        const defaultClone = ObjectUtils.cloneDeep(DEFAULT_PRISM_OPTIONS);
        const prismOptions: PrismEditOptions = Object.assign(defaultClone, options);
        prismOptions.pointProps = { ...DEFAULT_PRISM_OPTIONS.pointProps, ...options.pointProps };
        prismOptions.prismProps = { ...DEFAULT_PRISM_OPTIONS.prismProps, ...options.prismProps };
        prismOptions.polylineProps = { ...DEFAULT_PRISM_OPTIONS.polylineProps, ...options.polylineProps };
        prismOptions.clampHeightTo3DOptions = { ...DEFAULT_PRISM_OPTIONS.clampHeightTo3DOptions, ...options.clampHeightTo3DOptions };

        if (options.clampHeightTo3D) {
            if (!this.cesiumScene.pickPositionSupported || !this.cesiumScene.clampToHeightSupported) {
                throw new Error("Cesium pickPosition and clampToHeight must be supported to use clampHeightTo3D");
            }

            if (this.cesiumScene.pickTranslucentDepth) {
                console.warn("Cesium scene.pickTranslucentDepth must be false in order to make the editors work properly on 3D");
            }

            if (prismOptions.pointProps.color.alpha === 1 || prismOptions.pointProps.outlineColor.alpha === 1) {
                console.warn("Point color and outline color must have alpha in order to make the editor work properly on 3D");
            }

            prismOptions.allowDrag = false;
            prismOptions.polylineProps.clampToGround = true;
            prismOptions.pointProps.heightReference = prismOptions.clampHeightTo3DOptions.clampToTerrain
                ? Cesium.HeightReference.CLAMP_TO_GROUND
                : Cesium.HeightReference.RELATIVE_TO_GROUND;
            prismOptions.pointProps.disableDepthTestDistance = Number.POSITIVE_INFINITY;
        }

        return prismOptions;
    }

    private createEditorObservable(
        editorObservable: any,
        id: string,
        finishCreation?: (position?: Cartesian3) => boolean
    ): PrismEditorObservable {
        const observableToExtend = editorObservable as PrismEditorObservable;

        observableToExtend.dispose = () => {
            this.cesiumPointerManager.removePointer(id);
            const observables = this.observablesMap.get(id);

            if (observables) {
                observables.forEach((obs) => obs.dispose());
            }

            this.observablesMap.delete(id);
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE_OR_EDIT,
                editAction: EditActions.DISPOSE,
            });
        };
        observableToExtend.enable = () => {
            this.updateSubject.next({
                id,
                positions: this.getPositions(id),
                topHeight: this.getTopHeight(id),
                bottomHeight: this.getBottomHeight(id),
                editMode: EditModes.EDIT,
                editAction: EditActions.ENABLE,
            });
        };
        observableToExtend.disable = () => {
            this.updateSubject.next({
                id,
                positions: this.getPositions(id),
                topHeight: this.getTopHeight(id),
                bottomHeight: this.getBottomHeight(id),
                editMode: EditModes.EDIT,
                editAction: EditActions.DISABLE,
            });
        };
        observableToExtend.setManually = (
            points: CesiumPointWithProps[] | Cartesian3[],
            topHeight?: number,
            bottomHeight?: number,
            center?: Cartesian3,
            prismProps?: PrismProps
        ) => {
            const prism = this.prismsManager.get(id);

            if (!prism) {
                return;
            }

            prism.setManually(points, topHeight, bottomHeight, center, prismProps);
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE_OR_EDIT,
                editAction: EditActions.SET_MANUALLY,
            });
        };

        observableToExtend.setLabelsRenderFn = (callback: (update: PrismEditUpdate, labels: LabelProps[]) => LabelProps[]) => {
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE_OR_EDIT,
                editAction: EditActions.SET_EDIT_LABELS_RENDER_CALLBACK,
                labelsRenderFn: (update: PrismEditUpdate, labels: PrismLabel[]) => callback(update, labels) as PrismLabel[],
            });
        };

        observableToExtend.updateLabels = (labels: LabelProps[]) => {
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE_OR_EDIT,
                editAction: EditActions.UPDATE_EDIT_LABELS,
                updateLabels: labels as PrismLabel[],
            });
        };

        observableToExtend.refreshLabels = () => {
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE_OR_EDIT,
                editAction: EditActions.UPDATE_EDIT_LABELS,
            });
        };

        observableToExtend.finishCreation = () => {
            if (!finishCreation) {
                throw new Error("Prisms editor error edit(): cannot call finishCreation() on edit");
            }

            return finishCreation(undefined);
        };

        observableToExtend.removeLastPoint = () => {
            const allPoints = [...(this.getPoints(id) ?? [])];
            const update: PrismEditUpdate = {
                id,
                updatedPoint: allPoints.pop(),
                editMode: EditModes.CREATE,
                editAction: EditActions.REMOVE_POINT,
            };
            this.updateSubject.next(update);
        };

        observableToExtend.status$ = this.getStatusObservable(id);
        observableToExtend.getIsValid = () => this.isValidPrism(id);
        observableToExtend.getId = () => id;
        observableToExtend.getCurrentPoints = () => this.getPoints(id) ?? [];
        observableToExtend.getTopHeight = () => this.getTopHeight(id);
        observableToExtend.getBottomHeight = () => this.getBottomHeight(id);
        observableToExtend.getLabels = () => this.prismsManager.get(id)?.labels ?? [];
        observableToExtend.getWaypoint = () => this.getPolygonWaypoint(id)?.getPosition();

        return observableToExtend as PrismEditorObservable;
    }

    private getPositions(id: string) {
        const prism = this.prismsManager.get(id);

        return prism?.getRealPositions();
    }

    private isValidPrism(id: string): boolean {
        const prism = this.prismsManager.get(id);

        return !!prism?.isValid;
    }

    private getPoints(id: string) {
        const prism = this.prismsManager.get(id);

        return prism?.getRealPoints();
    }

    private getTopHeight(id: string): number {
        return this.prismsManager.get(id)?.getTopHeight() ?? 0;
    }

    private getBottomHeight(id: string): number {
        return this.prismsManager.get(id)?.getBottomHeight() ?? 0;
    }

    private getPolygonWaypoint(id: string) {
        return this.prismsManager.get(id)?.getPolygonWaypoint();
    }

    private getStatusObservable(id: string) {
        const prism = this.prismsManager.get(id);

        return this.updateSubject.pipe(
            filter((update) => update.id === id),
            startWith(undefined),
            map(() => prism?.getStatus())
        );
    }

    private setHoverLabelId<LabelKey extends keyof NarrowIndexable<PrismEditorLabelProviders>>(
        prismId: string,
        labelId: LabelKey | undefined,
        editorObservable: PrismEditorObservable
    ) {
        const prism = this.prismsManager.get(prismId);

        if (prism && labelId !== prism.hoverLabelId) {
            prism.hoverLabelId = labelId;
            editorObservable.refreshLabels();
        }
    }

    private getPrismProperties(id: string) {
        const prism = this.prismsManager.get(id);

        if (!prism) {
            return;
        }

        return {
            positions: prism.getRealPositions(),
            points: prism.getPoints(),
            topHeight: prism.getTopHeight(),
            bottomHeight: prism.getBottomHeight(),
        };
    }
}
