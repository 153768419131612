import { Inject, Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { LOCAL_STORAGE } from "@dtm-frontend/shared/utils";
import { StateContext } from "@ngxs/store";
import { EMPTY } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { StorageAuthKeys } from "../../models/storage-auth-keys";
import { AuthStateModel, DEFAULT_AUTH_STATE } from "../state/auth.state";
import { AuthApiService } from "./auth-api.service";
import { AuthService } from "./auth.service";

@Injectable()
export class OfflineCapableAuthService implements AuthService {
    private redirectPath: string | undefined;

    constructor(
        private readonly jwtHelperService: JwtHelperService,
        private readonly authApiService: AuthApiService,
        private readonly router: Router,
        private readonly zone: NgZone,
        @Inject(LOCAL_STORAGE) private readonly localStorage: Storage
    ) {}

    public init(context: StateContext<AuthStateModel>) {
        const token = this.localStorage.getItem(StorageAuthKeys.TOKEN);
        const refreshToken = this.localStorage.getItem(StorageAuthKeys.REFRESH_TOKEN) ?? undefined;

        if (!token) {
            context.patchState({ isLoggedIn: false });

            return;
        }

        context.patchState({
            ...this.getDecodedUserData(token),
            isLoggedIn: true,
            token,
            refreshToken,
        });
    }

    public goToLoginPage(redirectPath?: string) {
        this.redirectPath = redirectPath ?? this.redirectPath ?? this.router.routerState.snapshot.url;

        this.zone.run(() => {
            this.router.navigateByUrl("/login");
        });
    }

    public logIn(context: StateContext<AuthStateModel>, username: string, password: string) {
        return this.authApiService.logIn(username, password).pipe(
            tap((response) => {
                const token = response.access_token;

                context.patchState({
                    ...this.getDecodedUserData(token),
                    isLoggedIn: true,
                    token,
                    refreshToken: response.refresh_token,
                });

                this.localStorage.setItem(StorageAuthKeys.TOKEN, token);
                this.localStorage.setItem(StorageAuthKeys.REFRESH_TOKEN, response.refresh_token);

                this.zone.run(() => {
                    this.router.navigateByUrl(!this.redirectPath || this.redirectPath === "/login" ? "/" : this.redirectPath);
                    this.redirectPath = undefined;
                });
            })
        );
    }

    public logOut(context: StateContext<AuthStateModel>) {
        context.patchState(DEFAULT_AUTH_STATE);

        this.localStorage.removeItem(StorageAuthKeys.TOKEN);
        this.localStorage.removeItem(StorageAuthKeys.REFRESH_TOKEN);

        this.goToLoginPage();
    }

    public updateToken(context: StateContext<AuthStateModel>) {
        const refreshToken = context.getState().refreshToken;

        if (!refreshToken) {
            this.logOut(context);

            return EMPTY;
        }

        return this.authApiService.updateToken(refreshToken).pipe(
            tap(({ access_token, refresh_token }) => {
                this.localStorage.setItem(StorageAuthKeys.TOKEN, access_token);
                this.localStorage.setItem(StorageAuthKeys.REFRESH_TOKEN, refresh_token);

                context.patchState({
                    token: access_token,
                    refreshToken,
                });
            }),
            catchError(() => {
                this.logOut(context);

                return EMPTY;
            })
        );
    }

    private getDecodedUserData(token: string): Partial<AuthStateModel> {
        const decodedToken = this.jwtHelperService.decodeToken(token);

        return {
            userFirstName: decodedToken["given_name"],
            userLastName: decodedToken["family_name"],
            userId: decodedToken["preferred_username"],
            roles: decodedToken["realm_access"]?.roles ?? [],
        };
    }
}
