import { ContentObserver } from "@angular/cdk/observers";
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewChild } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

export enum EmptyStateMode {
    Primary = "PRIMARY",
    Secondary = "SECONDARY",
    Tertiary = "TERTIARY",
    ListView = "LIST_VIEW",
}

interface EmptyStateComponentState {
    mode: EmptyStateMode;
    isTitleEmpty: boolean;
    isMessageEmpty: boolean;
    isImageEmpty: boolean;
    isFooterEmpty: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-empty-state",
    templateUrl: "./empty-state.component.html",
    styleUrls: ["./empty-state.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class EmptyStateComponent implements AfterViewInit {
    @Input() public mode: EmptyStateMode = EmptyStateMode.Primary;

    @ViewChild("container") private containerElement: ElementRef<HTMLElement> | undefined;

    @HostBinding("class") public get modeClass() {
        let classPostfix: string;

        switch (this.mode) {
            case EmptyStateMode.Secondary:
                classPostfix = "secondary";
                break;
            case EmptyStateMode.Tertiary:
                classPostfix = "tertiary";
                break;
            case EmptyStateMode.ListView:
                classPostfix = "list-view";
                break;
            case EmptyStateMode.Primary:
            default:
                classPostfix = "primary";
                break;
        }

        return `empty-state-${classPostfix}`;
    }

    protected readonly isTitleEmpty$ = this.localStore.selectByKey("isTitleEmpty");
    protected readonly isMessageEmpty$ = this.localStore.selectByKey("isMessageEmpty");
    protected readonly isImageEmpty$ = this.localStore.selectByKey("isImageEmpty");
    protected readonly isFooterEmpty$ = this.localStore.selectByKey("isFooterEmpty");

    constructor(
        private readonly localStore: LocalComponentStore<EmptyStateComponentState>,
        private readonly contentObserver: ContentObserver
    ) {
        this.localStore.setState({
            mode: EmptyStateMode.Primary,
            isTitleEmpty: false,
            isMessageEmpty: false,
            isImageEmpty: false,
            isFooterEmpty: false,
        });
    }

    public ngAfterViewInit(): void {
        const containerElement = this.containerElement?.nativeElement;
        if (this.isHasSelectorSupported() || !containerElement) {
            return;
        }

        this.watchContainerContentChange(containerElement);
    }

    private isHasSelectorSupported() {
        return CSS.supports("selector(:has(a, b))");
    }

    private watchContainerContentChange(containerElement: HTMLElement) {
        const titleElement = containerElement.querySelector(":scope > .title");
        const messageElement = containerElement.querySelector(":scope > .message");
        const imageElement = containerElement.querySelector(":scope > .image");
        const footerElement = containerElement.querySelector(":scope > .footer");

        this.contentObserver
            .observe(containerElement)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.localStore.patchState({
                    isTitleEmpty: titleElement?.matches(":empty") ?? false,
                    isMessageEmpty: messageElement?.matches(":empty") ?? false,
                    isImageEmpty: imageElement?.matches(":empty") ?? false,
                    isFooterEmpty: footerElement?.matches(":empty") ?? false,
                });
            });
    }
}
