<dtm-ui-form-field [isDisabled]="disabled" [isActivated]="false" [isFocused]="focused" (contentClick)="toggle()" #trigger>
    <ng-content select="label" ngProjectAs="label"></ng-content>
    <div cdk-overlay-origin #origin="cdkOverlayOrigin"></div>
    <div class="select-field-wrapper">
        <ng-content select=".field-prefix"></ng-content>
        <div class="select-field" [ngSwitch]="empty" [attr.id]="_valueId">
            <span class="select-field-placeholder" *ngSwitchCase="true">{{ placeholder }}</span>
            <ng-container *ngSwitchCase="false" [ngSwitch]="!!customTrigger">
                <ng-container *ngSwitchDefault [ngSwitch]="multiple">
                    <span class="select-field-value-multiple" *ngSwitchCase="true">
                        <span class="select-field-value-size">
                            {{ "dtmUi.selectField.selectedWithAmount" | transloco : { amount: value.length } }}:
                        </span>
                        <span class="select-field-value-labels">{{ selectedValuesLabel }}</span>
                    </span>
                    <ng-container *ngSwitchCase="false">{{ triggerValue }}</ng-container>
                </ng-container>
                <ng-content select="mat-select-trigger" *ngSwitchCase="true"></ng-content>
            </ng-container>
        </div>
        <button
            type="button"
            class="field-suffix clear-button"
            tabindex="-1"
            *ngIf="isClearable && !empty && !multiple"
            (click)="clearValue($event)"
        >
            <dtm-ui-icon name="close-circle-fill"></dtm-ui-icon>
        </button>
        <button type="button" class="field-suffix trigger-button" tabindex="-1">
            <dtm-ui-icon [name]="panelOpen ? 'arrow-up' : 'arrow-down'" class="icon"></dtm-ui-icon>
        </button>
        <ng-content select=".field-suffix"></ng-content>
    </div>

    <ng-template
        cdk-connected-overlay
        cdkConnectedOverlayLockPosition
        cdkConnectedOverlayHasBackdrop
        cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
        [cdkConnectedOverlayPanelClass]="_overlayPanelClass"
        [cdkConnectedOverlayScrollStrategy]="_scrollStrategy"
        [cdkConnectedOverlayOrigin]="origin"
        [cdkConnectedOverlayOpen]="panelOpen"
        [cdkConnectedOverlayPositions]="_positions"
        [cdkConnectedOverlayWidth]="privateHack._triggerRect && privateHack._triggerRect.width!"
        [cdkConnectedOverlayOffsetY]="_offsetY"
        (backdropClick)="close()"
        (attach)="_onAttached()"
        (detach)="detachPanel()"
    >
        <div class="select-field-panel-wrap" [@transformPanelWrap]>
            <div
                #panel
                role="listbox"
                tabindex="-1"
                class="select-field-panel {{ _getPanelTheme() }}"
                [style]="{ maxHeight: maxPanelHeight }"
                [attr.id]="id + '-panel'"
                [attr.aria-multiselectable]="multiple"
                [attr.aria-label]="ariaLabel || null"
                [attr.aria-labelledby]="_getPanelAriaLabelledby()"
                [ngClass]="panelClass"
                [@transformPanel]="multiple ? 'showing-multiple' : 'showing'"
                (@transformPanel.done)="_panelDoneAnimatingStream.next($event.toState)"
                [style.transformOrigin]="_transformOrigin"
                [style.font-size.px]="_triggerFontSize"
                (keydown)="_handleKeydown($event)"
            >
                <div *ngIf="multiple">
                    <button type="button" class="button-tertiary" *ngIf="empty" (click)="selectAll()">
                        {{ "dtmUi.selectField.selectAll" | transloco }}
                    </button>
                    <button type="button" class="button-tertiary" *ngIf="!empty" (click)="clearValue($event)">
                        {{ "dtmUi.selectField.deselectAll" | transloco : { amount: value.length } }}
                    </button>
                </div>
                <ng-content></ng-content>
            </div>
        </div>
    </ng-template>

    <ng-content select=".field-hint" ngProjectAs=".field-hint"></ng-content>
    <ng-content select=".field-error" ngProjectAs=".field-error"></ng-content>
</dtm-ui-form-field>
