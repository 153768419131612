<dtm-ui-expandable-panel [isExpanded]="isPermitDetailsTileExpanded$ | ngrxPush" [hasHeaderSeparator]="false" *ngrxLet="permit$ as permit">
    <div class="header" headerSlot>
        {{ "dtmUi.associationPermitPreview.permitDetailsHeader" | transloco }}
        <button
            (click)="$event.stopPropagation(); edit.emit()"
            *ngIf="canEdit$ | ngrxPush"
            type="button"
            class="button-secondary edit-button"
        >
            {{ "dtmUi.associationPermitPreview.editButtonLabel" | transloco }}
        </button>
    </div>
    <div class="data-preview">
        <dtm-ui-label-value
            [label]="'dtmUi.associationPermitPreview.permitNumberLabel' | transloco"
            [value]="permit.permitNumber"
        ></dtm-ui-label-value>
        <dtm-ui-label-value [label]="'dtmUi.associationPermitPreview.validityPeriodLabel' | transloco">
            {{ permit.expirationDate.dateFrom | localizeDate }} - {{ permit.expirationDate.dateTo | localizeDate }}
        </dtm-ui-label-value>
        <dtm-ui-label-value [label]="'dtmUi.associationPermitPreview.flightPurposeLabel' | transloco" [value]="permit.flightPurpose">
        </dtm-ui-label-value>
        <dtm-ui-label-value [label]="'dtmUi.associationPermitPreview.locationsLabel' | transloco" [value]="permit.locations">
        </dtm-ui-label-value>
        <dtm-ui-label-value [label]="'dtmUi.associationPermitPreview.operationTypeLabel' | transloco">
            <span class="value-with-icon">
                <dtm-ui-icon [name]="permit.operationType === AssociationOperationType.VLOS ? 'eye' : 'google'"></dtm-ui-icon>
                {{ "dtmUi.associationPermitPreview.operationTypeValueLabel" | transloco : { value: permit.operationType } }}
            </span>
        </dtm-ui-label-value>

        <p class="section-title">
            {{ "dtmUi.associationPermitPreview.minimalCompetenciesHeader" | transloco }}
        </p>

        <p class="section-text">
            {{ "dtmUi.associationPermitPreview.minimalCompetenciesText" | transloco }}
        </p>

        <dtm-ui-label-value [label]="'dtmUi.associationPermitPreview.isInsuranceLabel' | transloco">
            <span class="value-with-icon">
                <dtm-ui-icon [name]="permit.isInsurance ? 'checkbox-circle' : 'close-circle'"></dtm-ui-icon>
                {{ "dtmUi.associationPermitPreview.isInsuranceValue" | transloco : { value: permit.isInsurance } }}
            </span>
        </dtm-ui-label-value>
    </div>
</dtm-ui-expandable-panel>

<div class="additional-constraints">
    <p class="section-title">
        {{ "dtmUi.associationPermitPreview.additionalConstraintsHeader" | transloco }}
    </p>

    <p class="section-text">
        {{ "dtmUi.associationPermitPreview.additionalConstraintsText" | transloco }}
    </p>
</div>
