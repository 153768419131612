import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface ShowHidePasswordComponentState {
    inputParent: HTMLInputElement | undefined;
}

const INPUT_PASSWORD_TYPE = "password";
const INPUT_TEXT_TYPE = "text";

@Component({
    selector: "dtm-ui-password-visibility-switch[inputParent]",
    templateUrl: "./password-visibility-switch.component.html",
    styleUrls: ["./password-visibility-switch.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PasswordVisibilitySwitchComponent {
    @Input() public set inputParent(value: HTMLInputElement | undefined) {
        this.localStore.patchState({ inputParent: value });
    }

    protected readonly inputParent$ = this.localStore.selectByKey("inputParent");
    protected readonly INPUT_PASSWORD_TYPE = INPUT_PASSWORD_TYPE;

    constructor(private readonly localStore: LocalComponentStore<ShowHidePasswordComponentState>) {
        this.localStore.setState({
            inputParent: undefined,
        });
    }

    protected togglePassword(): void {
        const inputParent = this.localStore.selectSnapshotByKey("inputParent");

        if (inputParent) {
            const inputType = inputParent.type === INPUT_PASSWORD_TYPE ? INPUT_TEXT_TYPE : INPUT_PASSWORD_TYPE;
            inputParent.type = inputType;
        }
    }
}
