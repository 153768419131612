import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { getDefaultLang } from "@dtm-frontend/shared/ui/i18n";
import { UntilDestroy } from "@ngneat/until-destroy";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class UtmRoleGuard extends KeycloakAuthGuard {
    constructor(
        protected readonly router: Router,
        protected readonly keycloak: KeycloakService,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        super(router, keycloak);
    }

    public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authenticated) {
            await this.keycloak.login({
                locale: getDefaultLang(),
                redirectUri: this.document.location.origin + state.url,
            });

            return false;
        }

        return true;
    }
}
