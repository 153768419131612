import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
    DmsCoordinates,
    DmsCoordinatesUtils,
    GeographicCoordinatesDirection,
    HemisphereSign,
} from "@dtm-frontend/shared/ui/dms-coordinates";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Position } from "@turf/helpers";
import { MapEntityType, MapUtils } from "../../index";
import { MINIMUM_VERTEX_NUMBER } from "../../models/prism/editable-prism";
import { SerializableCartographic } from "../../models/serializable-cartographic";

interface CoordinatesPasteAreaComponentState {
    label: string | undefined;
    tooltipText: string | undefined;
    hintText: string | undefined;
    editorType: MapEntityType | undefined;
    prismPointLimit: number;
    cancelText: string;
    saveText: string | undefined;
    placeholderText: string;
    coordinatesMaxLength: number | undefined;
}

const DEGREES_PATTERN = "\\d{1,3}[°\\s]*";
const MINUTES_PATTERN = "\\d{1,2}['\\s]*";
const SECONDS_PATTERN = '\\d{1,2}[.,]*\\d*["\\s]*'; // eslint-disable-line
const DMS_NUMBERS_PATTERN = DEGREES_PATTERN + MINUTES_PATTERN + SECONDS_PATTERN;
const DECIMAL_NUMBERS_PATTERN = "-?\\d*[.,]\\d+";
export const MINIMUM_POLYLINE3D_VERTEX_NUMBER = 2;

@UntilDestroy()
@Component({
    selector: "dtm-map-coordinates-paste-area[label][editorType]",
    templateUrl: "./coordinates-paste-area.component.html",
    styleUrls: ["./coordinates-paste-area.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoordinatesPasteAreaComponent {
    protected readonly coordinatesPasteArea = new FormControl("");
    protected readonly label$ = this.localStore.selectByKey("label");
    protected readonly tooltipText$ = this.localStore.selectByKey("tooltipText");
    protected readonly hintText$ = this.localStore.selectByKey("hintText");
    protected readonly cancelText$ = this.localStore.selectByKey("cancelText");
    protected readonly saveText$ = this.localStore.selectByKey("saveText");
    protected readonly coordinatesMaxLength$ = this.localStore.selectByKey("coordinatesMaxLength");
    protected readonly placeholderText$ = this.localStore.selectByKey("placeholderText");

    @Input() public set editorType(value: MapEntityType | undefined) {
        this.localStore.patchState({ editorType: value });
    }

    @Input() public set label(value: string) {
        this.localStore.patchState({ label: value });
    }

    @Input() public set tooltipText(value: string) {
        this.localStore.patchState({ tooltipText: value });
    }

    @Input() public set hintText(value: string) {
        this.localStore.patchState({ hintText: value });
    }

    @Input() public set prismPointLimit(value: number | undefined) {
        this.localStore.patchState({ prismPointLimit: value || Number.MAX_VALUE });
    }

    @Input() public set cancelText(value: string | undefined) {
        this.localStore.patchState({ cancelText: value ?? "" });
    }

    @Input() public set saveText(value: string | undefined) {
        this.localStore.patchState({ saveText: value });
    }

    @Input() public set placeholderText(value: string) {
        this.localStore.patchState({ placeholderText: value ?? "" });
    }

    @Input() public set coordinatesMaxLength(value: number | undefined) {
        this.localStore.patchState({ coordinatesMaxLength: value });
    }

    @Output() public readonly coordinatesSubmit = new EventEmitter<SerializableCartographic[]>();
    @Output() public readonly coordinatesCancel = new EventEmitter<void>();

    constructor(private readonly localStore: LocalComponentStore<CoordinatesPasteAreaComponentState>) {
        this.localStore.setState({
            label: undefined,
            tooltipText: undefined,
            hintText: undefined,
            editorType: undefined,
            prismPointLimit: Number.MAX_VALUE,
            cancelText: "",
            saveText: undefined,
            placeholderText: "",
            coordinatesMaxLength: undefined,
        });
    }

    protected submitPastedCoordinates(): void {
        let cartographic: SerializableCartographic[];
        const editorType = this.localStore.selectSnapshotByKey("editorType");
        const value = this.coordinatesPasteArea.value;
        const decimalRegExpMatchValues = value?.match(new RegExp(DECIMAL_NUMBERS_PATTERN, "g"));
        const dmsRegExpMatchValues = value?.match(new RegExp(`${DMS_NUMBERS_PATTERN}[NS]?\\s*${DMS_NUMBERS_PATTERN}[EW]?`, "g"));

        if (dmsRegExpMatchValues) {
            cartographic = this.createCartographicForDmsFormat(dmsRegExpMatchValues);
        } else if (decimalRegExpMatchValues) {
            cartographic = this.createCartographicForDecimalFormat(decimalRegExpMatchValues);
        } else {
            this.coordinatesPasteArea.setErrors({ invalidFormat: true });

            return;
        }

        const clearedCartographic = this.removeAdjacentDuplicatedCoordinates(cartographic);

        if (editorType === MapEntityType.Prism) {
            this.validatePrismCoordinates(clearedCartographic);
        } else if (editorType === MapEntityType.Polyline3D) {
            this.validatePolyline3DCoordinates(clearedCartographic);
        } else {
            this.validateCylinderCoordinates(clearedCartographic);
        }

        if (this.coordinatesPasteArea.invalid) {
            return;
        }

        this.coordinatesSubmit.emit(clearedCartographic);
    }

    private createCartographicForDmsFormat(dmsRegExpMatchValues: RegExpMatchArray): SerializableCartographic[] {
        return dmsRegExpMatchValues
            .map((coordinatesPairMatch) => {
                const directionMatches = coordinatesPairMatch.match(/[NSEW]/g);
                const coordinatesMatch = coordinatesPairMatch.match(new RegExp(DMS_NUMBERS_PATTERN, "g"));

                if (!coordinatesMatch) {
                    return;
                }

                const dmsLatitude = this.createDmsCoordinates(coordinatesMatch[0]);
                const dmsLongitude = this.createDmsCoordinates(coordinatesMatch[1]);

                if (!dmsLatitude || !dmsLongitude) {
                    return;
                }

                if (directionMatches?.includes(GeographicCoordinatesDirection.South)) {
                    dmsLatitude.degrees *= HemisphereSign.Negative;
                }

                if (directionMatches?.includes(GeographicCoordinatesDirection.West)) {
                    dmsLongitude.degrees *= HemisphereSign.Negative;
                }

                return {
                    latitude: DmsCoordinatesUtils.convertDmsCoordinatesToDecimalDegrees(dmsLatitude),
                    longitude: DmsCoordinatesUtils.convertDmsCoordinatesToDecimalDegrees(dmsLongitude),
                };
            })
            .filter(FunctionUtils.isTruthy);
    }

    private createDmsCoordinates(value: string): DmsCoordinates | undefined {
        const [degrees, minutes, seconds] = value
            .replace(/,/g, ".")
            .replace(/[^-\d.]/g, "|")
            .split("|");

        const result = {
            degrees: +degrees,
            minutes: +minutes,
            seconds: +seconds,
        };

        if (isNaN(result.degrees) || isNaN(result.minutes) || isNaN(result.seconds)) {
            return;
        }

        return result;
    }

    private createCartographicForDecimalFormat(decimalRegExpMatchValues: RegExpMatchArray): SerializableCartographic[] {
        const regExpNumericValues = decimalRegExpMatchValues?.map((regExpMatch) => regExpMatch.replace(/,/g, ".")).map((value) => +value);

        return this.parseNumbersToCartographic(regExpNumericValues);
    }

    private parseNumbersToCartographic(regExpNumericValues: number[]): SerializableCartographic[] {
        const pairsOfCoordinates = regExpNumericValues.reduce((result: number[][], coordinate, index, array) => {
            if (index % 2 === 0) {
                result.push(array.slice(index, index + 2));
            }

            return result;
        }, []);

        return pairsOfCoordinates
            .map(([latitude, longitude]) => {
                if (!latitude || !longitude) {
                    return;
                }

                return {
                    latitude,
                    longitude,
                };
            })
            .filter(FunctionUtils.isTruthy);
    }

    private removeAdjacentDuplicatedCoordinates(value: SerializableCartographic[]): SerializableCartographic[] {
        value = value.filter((element, index) => JSON.stringify(value[index - 1]) !== JSON.stringify(element));

        if (value.length > 1 && JSON.stringify(value[0]) === JSON.stringify(value[value.length - 1])) {
            value.pop();
        }

        return value;
    }

    private validatePrismCoordinates(coordinates: SerializableCartographic[]): void {
        const prismPointLimit = this.localStore.selectSnapshotByKey("prismPointLimit");
        const positions: Position[] = coordinates.map((position: SerializableCartographic) => [
            position.latitude,
            position.longitude,
            position.height || 0,
        ]);

        if (positions.length < MINIMUM_VERTEX_NUMBER) {
            this.coordinatesPasteArea.setErrors({ minLength: MINIMUM_VERTEX_NUMBER });

            return;
        }

        if (positions.length > prismPointLimit) {
            this.coordinatesPasteArea.setErrors({ maxLength: prismPointLimit });

            return;
        }

        if (!MapUtils.checkPolygonEdgesIntersections(positions)) {
            this.coordinatesPasteArea.setErrors({ intersections: true });
        }
    }

    private validatePolyline3DCoordinates(coordinates: SerializableCartographic[]): void {
        if (coordinates.length < MINIMUM_POLYLINE3D_VERTEX_NUMBER) {
            this.coordinatesPasteArea.setErrors({ minLength: MINIMUM_POLYLINE3D_VERTEX_NUMBER });

            return;
        }
    }

    private validateCylinderCoordinates(coordinates: SerializableCartographic[]): void {
        if (coordinates.length !== 1) {
            this.coordinatesPasteArea.setErrors({ centerPointFormat: true });
        }
    }
}
