import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
    FlightZoneApplicationBasicData,
    FlightZoneApplicationPurpose,
    MaxZoneDuration,
    PurposeCapability,
    TextEditorSectionDialogComponent,
} from "@dtm-frontend/dss-shared-lib";
import { ConfirmationDialogComponent, DialogService, InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import {
    APPROX_DAYS_IN_YEAR,
    DateUtils,
    FakeUTCDate,
    HOURS_IN_DAY,
    LocalComponentStore,
    MINUTES_IN_HOUR,
    RxjsUtils,
} from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EMPTY, merge, switchMap, tap } from "rxjs";
import { distinctUntilChanged, first, map, startWith, withLatestFrom } from "rxjs/operators";
import { IS_PRE_TACTICAL_APPROVAL_ENABLED } from "../../../../flight-zone.tokens";

interface FlightZoneApplicationBasicDataStepComponentState {
    flightZonePurposes: PurposeCapability[];
    isProcessing: boolean;
    minStartDate: FakeUTCDate;
    maxStartDate: FakeUTCDate;
    minEndDate: FakeUTCDate;
    maxEndDate: FakeUTCDate;
    formData: FlightZoneApplicationBasicData | undefined;
    availableMaxZoneDurations: MaxZoneDuration[];
    maxZoneDurationDays: number;
    isGeometryCreated: boolean;
    savedPurpose: FlightZoneApplicationPurpose | undefined;
}

interface FlightZoneBasicDataForm {
    caseNumber: FormControl<string>;
    flightZonePurpose: FormControl<FlightZoneApplicationPurpose | undefined>;
    title: FormControl<string>;
    startDate: FormControl<Date | undefined>;
    startTime: FormControl<Date | undefined>;
    endDate: FormControl<Date | undefined>;
    endTime: FormControl<Date | undefined>;
    hasStateSecurityRestriction?: FormControl<boolean>;
    isPreTacticalApproval: FormControl<boolean>;
    description: FormControl<string>;
}

const MAX_CUSTOM_SIGNATURE_LENGTH = 20;
const MAX_TITLE_LENGTH = 100;
const MIN_ZONE_DURATION_MINUTES = 5;
const MIN_START_DATE_DELAY_DAYS = 7;
const MIN_START_DATE_DELAY_MINUTES = MIN_START_DATE_DELAY_DAYS * HOURS_IN_DAY * MINUTES_IN_HOUR;
const MIN_START_DATE_DELAY_WITH_STATE_SECURITY_RESTRICTION_MINUTES = 5;
const DESCRIPTION_MAX_LENGTH = 2000;

@UntilDestroy()
@Component({
    selector: "dss-client-lib-basic-zone-info-step[isEditMode][flightZonePurposes][availableMaxZoneDurations][basicZoneInfoValue]",
    templateUrl: "./basic-zone-info-step.component.html",
    styleUrls: ["./basic-zone-info-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class BasicZoneInfoStepComponent implements AfterViewInit {
    public readonly MAX_CUSTOM_SIGNATURE_LENGTH: number = MAX_CUSTOM_SIGNATURE_LENGTH;
    public readonly MAX_TITLE_LENGTH: number = MAX_TITLE_LENGTH;

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set isEditMode(value: BooleanInput) {
        if (coerceBooleanProperty(value)) {
            this.flightZoneBasicDataForm.controls.flightZonePurpose.disable();
        }
    }

    @Input() public set flightZonePurposes(value: PurposeCapability[] | undefined) {
        this.localStore.patchState({ flightZonePurposes: value ?? [] });
    }

    @Input() public set availableMaxZoneDurations(value: MaxZoneDuration[] | undefined) {
        this.localStore.patchState({ availableMaxZoneDurations: value ?? [] });
    }

    @Input() public set basicZoneInfoValue(value: FlightZoneApplicationBasicData | undefined) {
        this.localStore.patchState({ formData: value });

        if (value) {
            this.flightZoneBasicDataForm.patchValue({
                ...value,
                startDate: DateUtils.convertDateToFakeUTC(value.startTime),
                startTime: DateUtils.convertDateToFakeUTC(value.startTime),
                endTime: DateUtils.convertDateToFakeUTC(value.endTime),
                endDate: DateUtils.convertDateToFakeUTC(value.endTime),
            });
            this.stateSecurityRestrictionControl.setValue(value.hasStateSecurityRestriction ?? false);
            this.updateMinimumDatesValidation();
            this.flightZoneBasicDataForm.markAllAsTouched();
        }
    }

    @Input() public set isGeometryCreated(value: BooleanInput) {
        this.localStore.patchState({ isGeometryCreated: coerceBooleanProperty(value) });
    }

    @Output() protected readonly basicDataUpdate = new EventEmitter<FlightZoneApplicationBasicData>();
    @Output() protected readonly geometryClear = new EventEmitter<void>();

    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly flightZonePurposes$ = this.localStore.selectByKey("flightZonePurposes");
    protected readonly minStartDate$ = this.localStore.selectByKey("minStartDate");
    protected readonly maxStartDate$ = this.localStore.selectByKey("maxStartDate");
    protected readonly minEndDate$ = this.localStore.selectByKey("minEndDate");
    protected readonly maxEndDate$ = this.localStore.selectByKey("maxEndDate");
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;

    protected readonly flightZoneBasicDataForm = new FormGroup<FlightZoneBasicDataForm>({
        caseNumber: new FormControl("", { validators: [Validators.maxLength(this.MAX_CUSTOM_SIGNATURE_LENGTH)], nonNullable: true }),
        flightZonePurpose: new FormControl(undefined, { validators: [Validators.required], nonNullable: true }),
        title: new FormControl("", { validators: [Validators.required, Validators.maxLength(this.MAX_TITLE_LENGTH)], nonNullable: true }),
        startDate: new FormControl(undefined, { validators: [Validators.required], nonNullable: true }),
        startTime: new FormControl(undefined, { validators: [Validators.required], nonNullable: true }),
        endDate: new FormControl(undefined, { validators: [Validators.required], nonNullable: true }),
        endTime: new FormControl(undefined, { validators: [Validators.required], nonNullable: true }),
        isPreTacticalApproval: new FormControl(this.isPreTacticalApprovalEnabled, { nonNullable: true }),
        description: new FormControl("", { nonNullable: true }),
    });
    protected readonly stateSecurityRestrictionControl = new FormControl(false, { nonNullable: true });
    protected readonly descriptionValue$ = this.flightZoneBasicDataForm.valueChanges.pipe(
        startWith(this.flightZoneBasicDataForm.value),
        map((value) => value.description),
        distinctUntilChanged()
    );

    constructor(
        @Inject(IS_PRE_TACTICAL_APPROVAL_ENABLED) protected isPreTacticalApprovalEnabled: boolean,
        private readonly localStore: LocalComponentStore<FlightZoneApplicationBasicDataStepComponentState>,
        private readonly dialogService: DialogService,
        private readonly translocoService: TranslocoService,
        private readonly translocoHelper: TranslationHelperService
    ) {
        this.initLocalState();
    }

    public ngAfterViewInit(): void {
        this.updateMaxZoneDurationOnPurposeChanges();
        this.initDateTimeControlsValidation();
        this.showStateSecurityControlBasedOnPurposeChanges();
        this.watchPurposeConfirmationChanges();
    }

    public updateMinimumDatesValidation(): void {
        const dateNow = DateUtils.convertDateToFakeUTC(new Date());
        const isStateSecurityRestrictionChecked = this.flightZoneBasicDataForm.controls.hasStateSecurityRestriction?.value;

        const minStartDateDelay = isStateSecurityRestrictionChecked
            ? MIN_START_DATE_DELAY_WITH_STATE_SECURITY_RESTRICTION_MINUTES
            : MIN_START_DATE_DELAY_MINUTES;

        const minDate = isStateSecurityRestrictionChecked
            ? DateUtils.addMinutes(dateNow, minStartDateDelay)
            : this.resetHoursAndMinutes(DateUtils.addMinutes(dateNow, minStartDateDelay));

        this.localStore.patchState({ minStartDate: minDate });
        this.localStore.patchState({ minEndDate: DateUtils.addMinutes(minDate, MIN_ZONE_DURATION_MINUTES) });
    }

    public goToNextStep(): void {
        const isProcessing = this.localStore.selectSnapshotByKey("isProcessing");

        this.flightZoneBasicDataForm.markAllAsTouched();
        if (isProcessing || this.flightZoneBasicDataForm.invalid) {
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }
        this.basicDataUpdate.emit(this.getParsedFormValue());
    }

    protected openDescriptionDialog(description: string): void {
        const matDialogRef = this.dialogService.open(TextEditorSectionDialogComponent, {
            data: {
                text: description,
                title: this.translocoService.translate("dssClientLibFlightZone.dataFormStep.descriptionLabel"),
                maxLength: DESCRIPTION_MAX_LENGTH,
            },
            disableClose: true,
        });

        matDialogRef.componentInstance.textUpdate$.pipe(first(), untilDestroyed(this)).subscribe((text) => {
            matDialogRef.close();
            this.flightZoneBasicDataForm.patchValue({ description: text ?? "" });
        });
    }

    private initLocalState(): void {
        const dateNow = DateUtils.convertDateToFakeUTC(new Date());
        this.localStore.setState({
            flightZonePurposes: [],
            isProcessing: false,
            minStartDate: this.resetHoursAndMinutes(DateUtils.addDays(dateNow, MIN_START_DATE_DELAY_DAYS)),
            maxStartDate: DateUtils.addDays(dateNow, APPROX_DAYS_IN_YEAR),
            minEndDate: this.resetHoursAndMinutes(DateUtils.addDays(dateNow, MIN_START_DATE_DELAY_DAYS)),
            maxEndDate: DateUtils.addDays(dateNow, APPROX_DAYS_IN_YEAR),
            formData: undefined,
            availableMaxZoneDurations: [],
            maxZoneDurationDays: NaN,
            isGeometryCreated: false,
            savedPurpose: undefined,
        });
    }

    private initDateTimeControlsValidation(): void {
        const startDateWithTimeValue$ = this.flightZoneBasicDataForm.controls.startDate.valueChanges.pipe(
            RxjsUtils.filterFalsy(),
            map((startDateValue: FakeUTCDate) => {
                const startTime: FakeUTCDate | undefined = this.flightZoneBasicDataForm.controls.startTime.value;

                if (!startTime) {
                    return startDateValue;
                }

                const startHours = startTime.getHours();
                const startMinutes = startTime.getMinutes();

                startDateValue.setHours(startHours, startMinutes);

                return startDateValue;
            })
        );

        merge(startDateWithTimeValue$, this.flightZoneBasicDataForm.controls.startTime.valueChanges)
            .pipe(RxjsUtils.filterFalsy(), withLatestFrom(this.localStore.selectByKey("maxZoneDurationDays")), untilDestroyed(this))
            .subscribe(([value, maxZoneDuration]) => {
                this.localStore.patchState({ minEndDate: DateUtils.addMinutes(value, MIN_ZONE_DURATION_MINUTES) });
                this.localStore.patchState({ maxEndDate: DateUtils.addDays(value, maxZoneDuration) });

                this.flightZoneBasicDataForm.controls.endDate.updateValueAndValidity();
            });
    }

    private updateMaxZoneDurationOnPurposeChanges(): void {
        this.flightZoneBasicDataForm.controls.flightZonePurpose.valueChanges
            .pipe(
                // NOTE: When no purpose is selected, ForbiddingUAVAndGeneralAviationFlights is default maxZoneDuration value
                startWith(FlightZoneApplicationPurpose.ForbiddingUAVAndGeneralAviationFlights),
                untilDestroyed(this)
            )
            .subscribe((purpose) => {
                const availableMaxZoneDurations = this.localStore.selectSnapshotByKey("availableMaxZoneDurations");
                const maxZoneDuration = availableMaxZoneDurations.find((maxDuration) => maxDuration.purpose === purpose);

                if (!maxZoneDuration) {
                    return;
                }

                this.localStore.patchState({ maxZoneDurationDays: maxZoneDuration.periodInDays });

                // NOTE: Trigger max date re-validation
                this.flightZoneBasicDataForm.controls.startDate.updateValueAndValidity();
            });
    }

    private showStateSecurityControlBasedOnPurposeChanges(): void {
        const flightZonePurposeControl = this.flightZoneBasicDataForm.controls.flightZonePurpose;

        flightZonePurposeControl.valueChanges
            .pipe(
                startWith(flightZonePurposeControl.value),
                withLatestFrom(this.localStore.selectByKey("flightZonePurposes")),
                untilDestroyed(this)
            )
            .subscribe(([selectedPurpose, purposeCapabilities]) => {
                const hasStateSecurityRestriction = purposeCapabilities.find(
                    ({ purpose }) => purpose === selectedPurpose
                )?.hasStateSecurityRestriction;

                if (hasStateSecurityRestriction) {
                    this.flightZoneBasicDataForm.addControl("hasStateSecurityRestriction", this.stateSecurityRestrictionControl);
                } else {
                    this.flightZoneBasicDataForm.removeControl("hasStateSecurityRestriction");
                }

                this.updateMinimumDatesValidation();
            });
    }

    private watchPurposeConfirmationChanges(): void {
        const flightZonePurposeControl = this.flightZoneBasicDataForm.controls.flightZonePurpose;

        flightZonePurposeControl.valueChanges
            .pipe(
                startWith(flightZonePurposeControl.value),
                distinctUntilChanged(),
                switchMap((value) => {
                    const { savedPurpose, isGeometryCreated } = this.localStore.get();
                    if (!isGeometryCreated || savedPurpose === value) {
                        this.localStore.patchState({ savedPurpose: value });

                        return EMPTY;
                    }

                    return this.dialogService
                        .open(ConfirmationDialogComponent, {
                            data: {
                                titleText: this.translocoService.translate("dssClientLibFlightZone.confirmPurposeChangeDialog.title"),
                                confirmationText: this.translocoService.translate(
                                    "dssClientLibFlightZone.confirmPurposeChangeDialog.confirmationText"
                                ),
                            },
                        })
                        .afterClosed()
                        .pipe(
                            tap((isConfirmed) => {
                                if (isConfirmed) {
                                    this.geometryClear.emit();
                                    this.localStore.patchState({ savedPurpose: value });

                                    return;
                                }

                                this.flightZoneBasicDataForm.controls.flightZonePurpose.setValue(savedPurpose);
                            })
                        );
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private getParsedFormValue(): FlightZoneApplicationBasicData | undefined {
        const formValue = this.flightZoneBasicDataForm.getRawValue();

        if (!formValue.flightZonePurpose || !formValue.startDate || !formValue.startTime || !formValue.endDate || !formValue.endTime) {
            return;
        }

        return {
            caseNumber: formValue.caseNumber,
            flightZonePurpose: formValue.flightZonePurpose,
            title: formValue.title,
            hasStateSecurityRestriction: formValue.hasStateSecurityRestriction,
            startTime: DateUtils.convertFakeUTCToDate(formValue.startDate, formValue.startTime),
            endTime: DateUtils.convertFakeUTCToDate(formValue.endDate, formValue.endTime),
            isPreTacticalApproval: formValue.isPreTacticalApproval,
            description: formValue.description,
        };
    }

    private resetHoursAndMinutes(date: FakeUTCDate): FakeUTCDate {
        date.setHours(0);
        date.setMinutes(0);

        return date;
    }
}
