<dtm-ui-input-field>
    <label *ngIf="isLabelVisible$ | ngrxPush">
        {{ "dtmUi.conversations.searchLabel" | transloco }}
    </label>
    <input matInput type="text" [formControl]="textSearchControl" [placeholder]="'dtmUi.conversations.searchPlaceholder' | transloco" />
    <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
    <div class="field-error" *dtmUiFieldHasError="textSearchControl; name: 'maxlength'; error as error">
        {{ "dtmUi.conversations.textSearchValueToLongError" | transloco : { maxLength: error.requiredLength } }}
    </div>
    <div class="field-error" *dtmUiFieldHasError="textSearchControl; name: 'minlength'; error as error">
        {{ "dtmUi.conversations.textSearchValueToShortError" | transloco : { minLength: error.requiredLength } }}
    </div>
</dtm-ui-input-field>
