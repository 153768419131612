<form class="phone-number-editor" [formGroup]="phoneNumberChangeFormGroup" *ngrxLet="phoneNumber$ | ngrxPush as phoneNumber">
    <ng-container *ngIf="isVerificationRequired$ | ngrxPush">
        <ng-container *ngIf="phoneNumberChangeDescription$ | ngrxPush as phoneNumberChangeDescription">
            {{ phoneNumberChangeDescription }}
        </ng-container>
    </ng-container>
    <dtm-ui-label-value *ngIf="phoneNumber?.number" [label]="'dtmUi.editPhoneNumber.currentPhoneNumberLabel' | transloco">
        <div class="full-phone-number">
            <dtm-ui-country-flag-display [country]="phoneNumber?.countryCode ?? undefined"></dtm-ui-country-flag-display>
            <span>{{ phoneNumber | formatPhoneNumber }}</span>
        </div>
    </dtm-ui-label-value>
    <dtm-ui-phone-number-field *ngIf="!(isRequestedPhoneNumberChange$ | ngrxPush); else verificationCodeTemplate" formControlName="phone">
        <div class="field-error" *dtmUiFieldHasError="phoneNumberChangeFormGroup.controls.phone; name: ['required', 'requiredTouched']">
            {{ "dtmUi.editPhoneNumber.fieldRequiredError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="phoneNumberChangeFormGroup.controls.phone; name: 'invalidNumber'">
            {{ "dtmUi.editPhoneNumber.invalidPhoneNumberError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="phoneNumberChangeFormGroup.controls.phone; name: 'invalidNumberType'">
            {{ "dtmUi.editPhoneNumber.invalidNumberTypeError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="phoneNumberChangeFormGroup.controls.phone; name: 'sameValue'">
            {{ "dtmUi.editPhoneNumber.phoneNumberSameAsCurrentError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="phoneNumberChangeFormGroup.controls.phone; name: 'phoneNumberConflict'">
            {{ "dtmUi.editPhoneNumber.phoneNumberConflict" | transloco }}
        </div>
    </dtm-ui-phone-number-field>
    <!--TODO: [REJ-716] - Waiting for separate verification code component -->
    <ng-template #verificationCodeTemplate>
        <dtm-ui-input-field>
            <label>{{ "dtmUi.editPhoneNumber.verificationCodeLabel" | transloco }}</label>
            <input
                matInput
                formControlName="verificationCode"
                type="text"
                [mask]="verificationCodeMask$ | ngrxPush"
                [showMaskTyped]="true"
            />
            <div class="field-error" *dtmUiFieldHasError="phoneNumberChangeFormGroup.controls.verificationCode; name: 'required'">
                {{ "dtmUi.editPhoneNumber.fieldRequiredError" | transloco }}
            </div>
            <div
                class="field-error"
                *dtmUiFieldHasError="phoneNumberChangeFormGroup.controls.phone; name: 'tooManyRequestErrorDate'; error as error"
            >
                {{
                    "dtmUi.editPhoneNumber.tooManyRequestError"
                        | transloco : { rateLimitDateTimeLeft: error | invoke : getRateLimitDateTimeLeft }
                }}
            </div>
        </dtm-ui-input-field>
        <div class="resend-code">
            <button type="button" class="button-tertiary" (click)="resendVerificationCode.emit()">
                {{ "dtmUi.editPhoneNumber.sendAgain" | transloco }}
            </button>
        </div>
    </ng-template>
</form>
