import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    ApplicationChangesChatComponent,
    DEFAULT_PRISM_POINTS_LIMIT,
    DssUserRoles,
    FORBIDDING_UAV_FLIGHTS_PRISM_POINTS_LIMIT,
    FlightZoneApplicationBasicData,
    FlightZoneApplicationPurpose,
    FlightZoneCapabilitiesState,
    FlightZoneEditorType,
    FlightZoneError,
    FlightZoneErrorType,
    FlightZoneGeometryService,
    RestrictionArea,
    RestrictionAreaUnits,
    RestrictionExclusions,
} from "@dtm-frontend/dss-shared-lib";
import { AuthState } from "@dtm-frontend/shared/auth";
import {
    AZURE_MAPS_LAYER_OPTIONS,
    CylinderEntity,
    DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
    MapEntitiesEditorContent,
    MapEntity,
    MapEntityType,
    MapUtils,
    PrismEntity,
} from "@dtm-frontend/shared/map/cesium";
import { GeoJSON } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { WizardActions, WizardState } from "@dtm-frontend/shared/ui/wizard";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { SceneMode, ViewerConfiguration } from "@pansa/ngx-cesium";
import turfBbox from "@turf/bbox";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom, lastValueFrom, share, tap } from "rxjs";
import { first, map, pluck } from "rxjs/operators";
import { FlightZoneWizardSteps } from "../../../models/flight-zone.models";
import { FLIGHT_ZONE_ID_ROUTE_PARAM_NAME } from "../../../services/flight-zone.resolvers";
import { FlightZoneActions } from "../../../state/flight-zone.actions";
import { FlightZoneState } from "../../../state/flight-zone.state";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export const FLIGHT_ZONE_WIZARD_ID = "flight-zone-wizard";

const ERROR_TOAST_TIMEOUT_MS = 10000;
const CIRCULAR_BOUNDARY_MAX_RADIUS = {
    [FlightZoneApplicationPurpose.ForbiddingUAVFlights]: {
        [FlightZoneEditorType.Cylinder]: 2500,
        [FlightZoneEditorType.Prism]: 2000,
    },
};

interface FlightZoneWizardContentComponentState {
    selectedEditor: FlightZoneEditorType;
    isWizardInEditMode: boolean;
    activeMapEditorToastId: number | undefined;
    isChatEnabled: boolean;
    prismPointsLimit: number | undefined;
    isCircularBoundaryValid: boolean;
}

@UntilDestroy()
@Component({
    selector: "dss-client-lib-flight-zone-wizard-content",
    templateUrl: "./flight-zone-wizard-content.component.html",
    styleUrls: ["./flight-zone-wizard-content.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, FlightZoneGeometryService],
})
export class FlightZoneWizardContentComponent implements OnInit, AfterViewInit, OnDestroy {
    protected readonly AZURE_MAPS_LAYER_OPTIONS = AZURE_MAPS_LAYER_OPTIONS;
    protected readonly FlightZoneErrorType = FlightZoneErrorType;
    protected readonly FlightZoneWizardSteps = FlightZoneWizardSteps;
    protected readonly FLIGHT_ZONE_WIZARD_ID: string = FLIGHT_ZONE_WIZARD_ID;

    protected readonly error$ = this.store.select(FlightZoneState.error);
    protected readonly flightZoneCapabilities$ = this.store.select(FlightZoneCapabilitiesState.capabilities);
    protected readonly isProcessing$ = this.store.select(FlightZoneState.isProcessing);
    protected readonly flightZoneApplicationData$ = this.store.select(FlightZoneState.applicationData);
    protected readonly initialViewbox$ = this.getInitialViewboxObservable();
    protected readonly geometryContent$ = this.flightZoneGeometryService.geometryContent$;
    protected readonly selectedEditor$ = this.localStore.selectByKey("selectedEditor");
    protected readonly geometryUnits$ = this.store.select(FlightZoneState.geometryUnits);
    protected readonly isWizardInEditMode$ = this.localStore.selectByKey("isWizardInEditMode");
    protected readonly hasInstitutionSupervisorRole$ = this.store
        .select(AuthState.roles)
        .pipe(map((roles) => roles?.includes(DssUserRoles.InstitutionSupervisor)));
    protected readonly userId$ = this.store.select(AuthState.userId);
    protected readonly isChatEnabled$ = this.localStore.selectByKey("isChatEnabled");
    protected readonly chatMessages$ = this.store.select(FlightZoneState.chatMessages).pipe(share());
    protected readonly prismPointsLimit$ = this.localStore.selectByKey("prismPointsLimit");
    protected readonly isGeometryCreated$ = this.geometryContent$.pipe(map((geometryContent) => !!geometryContent.length));
    protected readonly isForbiddingUavFlightsPurposeSelected$ = this.store
        .select(FlightZoneState.applicationData)
        .pipe(
            map((applicationData) => applicationData.basicDataForm?.flightZonePurpose === FlightZoneApplicationPurpose.ForbiddingUAVFlights)
        );
    protected readonly isCircularBoundaryValid$ = this.localStore.selectByKey("isCircularBoundaryValid");
    protected readonly maxRadius$ = this.localStore
        .selectByKey("selectedEditor")
        .pipe(map((selectedEditor) => CIRCULAR_BOUNDARY_MAX_RADIUS[FlightZoneApplicationPurpose.ForbiddingUAVFlights][selectedEditor]));

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<FlightZoneWizardContentComponentState>,
        private readonly flightZoneGeometryService: FlightZoneGeometryService,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly translationHelper: TranslationHelperService,
        viewerConfiguration: ViewerConfiguration
    ) {
        this.localStore.setState({
            selectedEditor: FlightZoneEditorType.Cylinder,
            isWizardInEditMode: !!this.route.snapshot.paramMap.get(FLIGHT_ZONE_ID_ROUTE_PARAM_NAME),
            activeMapEditorToastId: undefined,
            isChatEnabled: false,
            prismPointsLimit: undefined,
            isCircularBoundaryValid: false,
        });

        viewerConfiguration.viewerOptions = {
            ...DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
            sceneMode: SceneMode.SCENE3D,
        };
    }

    public ngOnInit(): void {
        this.initFirstStep();
        this.getChatMessages();
        this.toggleChatBasedOnMessagesLength();
    }

    public ngAfterViewInit(): void {
        if (this.localStore.selectSnapshotByKey("isWizardInEditMode")) {
            this.initMapWithEntity();
        }
    }

    public ngOnDestroy(): void {
        this.store.dispatch(new WizardActions.CleanupWizard(this.FLIGHT_ZONE_WIZARD_ID));
        this.flightZoneGeometryService.clearGeometry();

        const activeMapEditorToastId = this.localStore.selectSnapshotByKey("activeMapEditorToastId");
        if (activeMapEditorToastId !== undefined) {
            this.toastService.remove(activeMapEditorToastId);
        }
    }

    protected changeActiveStep(step: FlightZoneWizardSteps): void {
        this.store.dispatch(new WizardActions.SetActiveStep(this.FLIGHT_ZONE_WIZARD_ID, step));
    }

    protected updateBasicData(data: FlightZoneApplicationBasicData): void {
        this.store
            .dispatch(new FlightZoneActions.UpdateBasicData(data))
            .pipe(
                tap(() => this.updatePrismPointsLimitBasedOnApplicationPurpose()),
                untilDestroyed(this)
            )
            .subscribe();
        this.goToZoneGeometryStep();
    }

    protected changeEditorType(newEditorType: FlightZoneEditorType): void {
        this.localStore.patchState({ selectedEditor: newEditorType });
        this.clearGeometry(true);
        this.displayMapEditorHint();
    }

    protected async handleGeometryCompleted(): Promise<void> {
        const geometryContent = await lastValueFrom(this.geometryContent$.pipe(first(), untilDestroyed(this)));
        const selectedEditor = this.localStore.selectSnapshotByKey("selectedEditor");
        let entity: MapEntity | undefined;

        switch (selectedEditor) {
            case FlightZoneEditorType.Cylinder: {
                entity = MapUtils.extractMapEntity<CylinderEntity, MapEntityType.Cylinder>(geometryContent, MapEntityType.Cylinder);

                if (!entity) {
                    return;
                }

                this.store.dispatch(new FlightZoneActions.UpdateActiveCylinderGeometryValues(entity));
                break;
            }
            case FlightZoneEditorType.Prism:
                entity = MapUtils.extractMapEntity<PrismEntity, MapEntityType.Prism>(geometryContent, MapEntityType.Prism);

                if (!entity) {
                    return;
                }

                this.store.dispatch(new FlightZoneActions.UpdateActivePrismGeometryValues(entity));
                break;
        }

        this.flightZoneGeometryService.showEntireContent();
        this.goToExclusionsStep();
    }

    protected updateRestrictionExclusions(exclusions: RestrictionExclusions): void {
        this.store.dispatch(new FlightZoneActions.UpdateRestrictionExclusionsData(exclusions));
        this.saveApplicationDraft();
    }

    protected submitApplication(hasSupervisorRole: boolean): void {
        const flightZoneId = this.store.selectSnapshot(FlightZoneState.flightZoneId);

        if (!flightZoneId) {
            return;
        }

        this.rejectOrSendFlightZoneApplication(
            new FlightZoneActions.SendApplication(flightZoneId, !hasSupervisorRole),
            "dssClientLibFlightZone.flightZoneSubmissionSuccessMessage"
        );
    }

    protected rejectApplication(): void {
        const flightZoneId = this.store.selectSnapshot(FlightZoneState.flightZoneId);

        if (!flightZoneId) {
            return;
        }

        this.rejectOrSendFlightZoneApplication(
            new FlightZoneActions.RejectApplication(flightZoneId),
            "dssClientLibFlightZone.flightZoneRejectionSuccessMessage"
        );
    }

    protected deleteApplication(flightZoneId: string): void {
        this.store
            .dispatch(new FlightZoneActions.DeleteApplication(flightZoneId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.onFlightZoneApplicationSuccessfulAction("dssClientLibFlightZone.flightZoneApplicationDeleteSuccessMessage");
            });
    }

    protected confirmCorrections(flightZoneId: string): void {
        this.store
            .dispatch(new FlightZoneActions.ConfirmCorrections(flightZoneId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.onFlightZoneApplicationSuccessfulAction("dssClientLibFlightZone.confirmCorrectionsSuccessMessage");
            });
    }

    protected updateZoneGeometryContent({
        editorContent,
        units,
    }: {
        editorContent: MapEntitiesEditorContent;
        units: RestrictionAreaUnits;
    }): void {
        this.store.dispatch(new FlightZoneActions.UpdateActiveGeometryUnits(units));
        this.flightZoneGeometryService.update(editorContent);
    }

    public clearGeometry(shouldReloadZoneGeometryStep = false): void {
        this.flightZoneGeometryService.clearGeometry();
        this.store.dispatch(
            new WizardActions.DisableSteps(this.FLIGHT_ZONE_WIZARD_ID, [
                FlightZoneWizardSteps.ExclusionsFromRestrictions,
                FlightZoneWizardSteps.Summary,
            ])
        );

        if (shouldReloadZoneGeometryStep) {
            this.handleActiveStepChanged(FlightZoneWizardSteps.ZoneGeometry);
        }
    }

    protected zoomToGeometry(): void {
        this.flightZoneGeometryService.showEntireContent();
    }

    protected async handleActiveStepChanged(activeStep: string | undefined): Promise<void> {
        if (activeStep !== FlightZoneWizardSteps.ZoneGeometry) {
            await firstValueFrom(this.flightZoneGeometryService.mapReady$.pipe(untilDestroyed(this)));
            this.flightZoneGeometryService.disableEditors();
            this.closeActiveMapEditorToast();

            return;
        }

        const constraints = this.store.selectSnapshot(FlightZoneCapabilitiesState.capabilities)?.geometryConstraints;
        const selectedEditor = this.localStore.selectSnapshotByKey("selectedEditor");

        if (!constraints) {
            return;
        }

        this.flightZoneGeometryService.startOrEnableEditor(selectedEditor, {
            ...constraints,
            maximumNumberOfPoints: this.localStore.selectSnapshotByKey("prismPointsLimit"),
        });
    }

    protected prismPointLimitReached(limit: number): void {
        this.toastService.warning(this.transloco.translate("dssClientLibFlightZone.zoneGeometryStep.prismPointLimitWarning", { limit }));
    }

    protected submitMessage(flightZoneId: string, comment: string, chatComponent: ApplicationChangesChatComponent): void {
        this.store
            .dispatch(new FlightZoneActions.PostChatMessage(flightZoneId, comment))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                chatComponent.resetMessageInput();
                this.toastService.success(this.transloco.translate("dssClientLibFlightZone.chatMessageSubmissionSuccessMessage"));
            });
    }

    private getChatMessages(): void {
        const flightZoneId = this.store.selectSnapshot(FlightZoneState.flightZoneId);

        if (!flightZoneId) {
            return;
        }

        this.store.dispatch(new FlightZoneActions.GetChatMessagesByFlightZoneId(flightZoneId));
    }

    private toggleChatBasedOnMessagesLength(): void {
        this.chatMessages$
            .pipe(
                first((messages) => messages.length > 0),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.localStore.patchState({ isChatEnabled: true });
            });
    }

    private initFirstStep(): void {
        const enabledSteps = this.localStore.selectSnapshotByKey("isWizardInEditMode")
            ? [
                  FlightZoneWizardSteps.BasicZoneInfo,
                  FlightZoneWizardSteps.ZoneGeometry,
                  FlightZoneWizardSteps.ExclusionsFromRestrictions,
                  FlightZoneWizardSteps.Summary,
              ]
            : [FlightZoneWizardSteps.BasicZoneInfo];

        this.changeActiveStep(enabledSteps[enabledSteps.length - 1]);
        this.store.dispatch(new WizardActions.EnableSteps(this.FLIGHT_ZONE_WIZARD_ID, enabledSteps));
    }

    protected submitPasteAreaCoordinates(restrictionAreaGeometry: RestrictionArea): void {
        const constraints = this.store.selectSnapshot(FlightZoneCapabilitiesState.capabilities)?.geometryConstraints;

        if (!constraints) {
            return;
        }

        this.flightZoneGeometryService.createEntity(restrictionAreaGeometry, constraints);
    }

    protected updateCircularBoundary(isValid: boolean): void {
        this.localStore.patchState({ isCircularBoundaryValid: isValid });
    }

    private initMapWithEntity(): void {
        const constraints = this.store.selectSnapshot(FlightZoneCapabilitiesState.capabilities)?.geometryConstraints;
        const restrictionAreaGeometry = this.store.selectSnapshot(FlightZoneState.applicationData).restrictionAreaGeometry;

        if (!restrictionAreaGeometry || !constraints) {
            return;
        }

        this.localStore.patchState({ selectedEditor: restrictionAreaGeometry.editorType });

        this.flightZoneGeometryService.initMapWithEntity(restrictionAreaGeometry, constraints);
    }

    private goToZoneGeometryStep(): void {
        this.changeActiveStep(FlightZoneWizardSteps.ZoneGeometry);
        this.store.dispatch(
            new WizardActions.EnableSteps(this.FLIGHT_ZONE_WIZARD_ID, [
                FlightZoneWizardSteps.BasicZoneInfo,
                FlightZoneWizardSteps.ZoneGeometry,
            ])
        );
        this.displayMapEditorHint();
    }

    private goToExclusionsStep(): void {
        this.changeActiveStep(FlightZoneWizardSteps.ExclusionsFromRestrictions);
        this.store.dispatch(
            new WizardActions.EnableSteps(this.FLIGHT_ZONE_WIZARD_ID, [
                FlightZoneWizardSteps.BasicZoneInfo,
                FlightZoneWizardSteps.ZoneGeometry,
                FlightZoneWizardSteps.ExclusionsFromRestrictions,
            ])
        );
    }

    private goToSummaryStep(): void {
        this.changeActiveStep(FlightZoneWizardSteps.Summary);
        this.store.dispatch(
            new WizardActions.EnableSteps(this.FLIGHT_ZONE_WIZARD_ID, [
                FlightZoneWizardSteps.BasicZoneInfo,
                FlightZoneWizardSteps.ZoneGeometry,
                FlightZoneWizardSteps.ExclusionsFromRestrictions,
                FlightZoneWizardSteps.Summary,
            ])
        );
    }

    private getInitialViewboxObservable() {
        return this.flightZoneCapabilities$.pipe(
            RxjsUtils.filterFalsy(),
            pluck("preferences", "initialViewbox"),
            first(),
            map((viewbox: GeoJSON) => {
                const bbox = turfBbox(viewbox);

                Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
                Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(...bbox);

                return Cesium.Camera.DEFAULT_VIEW_RECTANGLE;
            })
        );
    }

    private saveApplicationDraft(): void {
        const flightZoneId = this.store.selectSnapshot(FlightZoneState.flightZoneId);

        this.store
            .dispatch(new FlightZoneActions.SaveApplicationDraft(flightZoneId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneState.error);

                if (error) {
                    this.displayErrorMessage(error);
                } else {
                    this.toastService.success(this.transloco.translate("dssClientLibFlightZone.submitApplicationDraftSuccessMessage"));
                    this.localStore.patchState({ isWizardInEditMode: true });
                    this.goToSummaryStep();
                }
            });
    }

    private rejectOrSendFlightZoneApplication(
        action: FlightZoneActions.RejectApplication | FlightZoneActions.SendApplication,
        successfulActionMessage: string
    ): void {
        const enabledSteps = this.store.selectSnapshot(WizardState.enabledSteps(this.FLIGHT_ZONE_WIZARD_ID));

        this.store
            .dispatch([new WizardActions.DisableSteps(this.FLIGHT_ZONE_WIZARD_ID, enabledSteps), action])
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneState.error);

                if (error) {
                    this.store.dispatch(new WizardActions.EnableSteps(this.FLIGHT_ZONE_WIZARD_ID, enabledSteps));
                    this.displayErrorMessage(error);
                } else {
                    this.onFlightZoneApplicationSuccessfulAction(successfulActionMessage);
                }
            });
    }

    private onFlightZoneApplicationSuccessfulAction(message: string): void {
        this.toastService.success(this.transloco.translate(message));
        this.store.dispatch(new WizardActions.CleanupWizard(this.FLIGHT_ZONE_WIZARD_ID));
        this.router.navigate(["/dashboard"]);
    }

    private displayErrorMessage(error: FlightZoneError): void {
        let errorMessage: string | undefined;

        switch (error.type) {
            case FlightZoneErrorType.CannotSubmitApplicationDraft:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.cannotSubmitApplicationDraftErrorMessage");
                break;
            case FlightZoneErrorType.CannotSubmitApplication:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.cannotSubmitApplicationErrorMessage");
                break;
            case FlightZoneErrorType.CannotRejectApplication:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.cannotRejectApplicationErrorMessage");
                break;
            case FlightZoneErrorType.NotAuthorized:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.notAuthorizedErrorMessage");
                break;
            case FlightZoneErrorType.InvalidApplicationVersion:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.invalidVersionErrorMessage");
                break;
            case FlightZoneErrorType.CannotDeleteApplication:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.cannotDeleteApplicationErrorMessage");
                break;
            case FlightZoneErrorType.CannotConfirmCorrections:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.cannotConfirmCorrectionsErrorMessage");
                break;
            case FlightZoneErrorType.InvalidApplicationStartDate:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.invalidApplicationStartDateErrorMessage");
                break;
            case FlightZoneErrorType.InvalidZoneBoundaryError:
                errorMessage = error.messageKey && this.translationHelper.selectSystemTranslation(error.messageKey, error.args);
                break;
            default:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.genericErrorMessage");
        }

        this.toastService.error(errorMessage, undefined, { timeOut: ERROR_TOAST_TIMEOUT_MS });
    }

    private displayMapEditorHint(): void {
        this.closeActiveMapEditorToast();

        const editorType = this.localStore.selectSnapshotByKey("selectedEditor");
        const message =
            editorType === FlightZoneEditorType.Cylinder
                ? "dssClientLibFlightZone.zoneGeometryStep.cylinderEditorGuide"
                : "dssClientLibFlightZone.zoneGeometryStep.prismEditorGuide";

        // TODO Use component created in DSS-404 to display message centered to map
        const activeMapEditorToastId = this.toastService.info(
            this.transloco.translate(message, { pointsLimit: this.localStore.selectSnapshotByKey("prismPointsLimit") }),
            "",
            {
                disableTimeOut: true,
                closeButton: true,
                tapToDismiss: false,
            }
        ).toastId;
        this.localStore.patchState({ activeMapEditorToastId });
    }

    public closeActiveMapEditorToast(): void {
        const activeMapEditorToastId = this.localStore.selectSnapshotByKey("activeMapEditorToastId");

        if (activeMapEditorToastId) {
            this.toastService.remove(activeMapEditorToastId);
        }
    }

    private updatePrismPointsLimitBasedOnApplicationPurpose(): void {
        const applicationData = this.store.selectSnapshot(FlightZoneState.applicationData);
        this.localStore.patchState({
            prismPointsLimit:
                applicationData.basicDataForm?.flightZonePurpose === FlightZoneApplicationPurpose.ForbiddingUAVFlights
                    ? FORBIDDING_UAV_FLIGHTS_PRISM_POINTS_LIMIT
                    : DEFAULT_PRISM_POINTS_LIMIT,
        });
    }
}
