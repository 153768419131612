/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line max-len
// NOTE: this is based on https://github.com/pansa-dev/ngx-cesium/blob/HEAD/projects/@pansa/ngx-cesium/src/lib/@pansa/ngx-cesium-widgets/components/polygons-editor/prisms-editor.component.ts

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
    AcLayerComponent,
    AcNotification,
    CameraService,
    Cartesian3,
    CesiumService,
    ContextMenuService,
    CoordinateConverter,
    EditActions,
    EditModes,
    MapEventsManagerService,
} from "@pansa/ngx-cesium";
import { Subject } from "rxjs";
import { CartographicEditPoint } from "../../models/cartographic-edit-point";
import { EditablePrism, PrismLabel } from "../../models/prism/editable-prism";
import { PrismEditUpdate } from "../../models/prism/prism-edit-update";
import { PrismsEditorService } from "../../services/entity-editors/prisms-editor/prisms-editor.service";
import { PrismsManagerService } from "../../services/entity-editors/prisms-editor/prisms-manager.service";
import { HeightHelperService, ShapeDragActions } from "../../services/height-helper.service";
import { CesiumPointerManagerService } from "../../services/pointer-manager/cesium-pointer-manager.service";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface PrismsEditorComponentState {
    isShown: boolean;
    prismLabelsTemplate: TemplateRef<unknown> | null;
}

@UntilDestroy()
@Component({
    selector: "dtm-map-prisms-editor[show]",
    templateUrl: "./prisms-editor.component.html",
    providers: [PrismsManagerService, HeightHelperService, LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrismsEditorComponent implements OnDestroy {
    private editLabelsRenderFunctions = new Map<string, (update: PrismEditUpdate, labels: PrismLabel[]) => PrismLabel[]>();
    private lastValidPointPosition: Cartesian3 | undefined;

    protected readonly Cesium = Cesium;
    protected readonly editPoints$ = new Subject<AcNotification>();
    protected readonly editPolygonWaypoint$ = new Subject<AcNotification>();
    protected readonly editPrisms$ = new Subject<AcNotification>();
    protected readonly editHeightOutlines$ = new Subject<AcNotification>();
    protected readonly prismLabels$ = new Subject<AcNotification>();

    protected readonly isShown$ = this.localStore.selectByKey("isShown");
    protected readonly prismLabelsTemplate$ = this.localStore.selectByKey("prismLabelsTemplate");

    @ViewChild("editPrismsLayer") private editPrismsLayer!: AcLayerComponent;
    @ViewChild("editPointsLayer") private editPointsLayer!: AcLayerComponent;
    @ViewChild("editPolygonWaypointLayer") private editPolygonWaypointLayer!: AcLayerComponent;
    @ViewChild("editHeightOutlinesLayer") private editHeightOutlinesLayer!: AcLayerComponent;
    @ViewChild("prismLabelsLayer") private prismLabelsLayer!: AcLayerComponent;

    @Input() public set show(value: boolean) {
        this.localStore.patchState({ isShown: value });
    }
    @Input()
    public set labelsTemplate(value: TemplateRef<unknown> | null) {
        this.localStore.patchState({ prismLabelsTemplate: value });
    }

    @Output() public pointLimitReached = new EventEmitter<number>();

    constructor(
        private readonly prismsEditor: PrismsEditorService,
        private readonly coordinateConverter: CoordinateConverter,
        private readonly mapEventsManager: MapEventsManagerService,
        private readonly cameraService: CameraService,
        private readonly prismsManager: PrismsManagerService,
        private readonly cesiumService: CesiumService,
        private readonly cesiumPointerManager: CesiumPointerManagerService,
        private readonly localStore: LocalComponentStore<PrismsEditorComponentState>,
        private readonly contextMenuService: ContextMenuService,
        heightHelperService: HeightHelperService
    ) {
        this.prismsEditor.init(
            this.mapEventsManager,
            this.coordinateConverter,
            this.cameraService,
            this.prismsManager,
            this.cesiumService,
            this.cesiumPointerManager,
            heightHelperService,
            this.contextMenuService
        );
        this.startListeningToEditorUpdates();
        this.watchForPrismPointLimitReached();
        this.localStore.setState({
            isShown: true,
            prismLabelsTemplate: null,
        });
    }

    private startListeningToEditorUpdates() {
        this.prismsEditor
            .onUpdate()
            .pipe(untilDestroyed(this))
            .subscribe((update: PrismEditUpdate) => {
                if (update.editMode === EditModes.CREATE || update.editMode === EditModes.CREATE_OR_EDIT) {
                    this.handleCreateUpdates(update);
                } else if (update.editMode === EditModes.EDIT) {
                    this.prismsManager.get(update.id)?.markAsDynamicEntityWhileUpdate();
                    this.handleEditUpdates(update);
                }
            });
    }

    private getLabelId(prism: EditablePrism, label: PrismLabel): string {
        return `${prism.getId()}_${label.id}`;
    }

    private getLabelIdsForPrism(prism: EditablePrism, labels: string[]): Set<string> {
        return new Set(labels.filter((label) => label.startsWith(prism.getId())));
    }

    public renderEditLabels(prism: EditablePrism, update: PrismEditUpdate, labels?: PrismLabel[]) {
        update.positions = prism.getRealPositions();
        update.points = prism.getRealPoints();
        update.topHeight = prism.getTopHeight();
        update.bottomHeight = prism.getBottomHeight();

        if (labels) {
            prism.labels = labels;
            this.synchronizeLabels(prism);

            return;
        }

        const editLabelsRenderFn = this.editLabelsRenderFunctions.get(prism.getId());

        if (!editLabelsRenderFn) {
            return;
        }

        prism.labels = editLabelsRenderFn(update, prism.labels);
        this.synchronizeLabels(prism);
    }

    private synchronizeLabels(prism: EditablePrism) {
        const unusedLabelIds = this.getLabelIdsForPrism(prism, Array.from(this.prismLabelsLayer.getStore().keys()));

        prism.labels.forEach((label) => {
            const labelId = this.getLabelId(prism, label);

            this.prismLabelsLayer.update(label, labelId);
            unusedLabelIds.delete(labelId);
        });

        unusedLabelIds.forEach((labelId) => {
            this.prismLabelsLayer.remove(labelId);
        });
    }

    public removeEditLabels(prism: EditablePrism) {
        prism.labels.forEach((label) => {
            this.prismLabelsLayer.remove(this.getLabelId(prism, label));
        });
        prism.labels = [];
        this.editLabelsRenderFunctions.delete(prism.getId());
    }

    public handleCreateUpdates(update: PrismEditUpdate) {
        switch (update.editAction) {
            case EditActions.INIT: {
                if (update.prismOptions) {
                    this.prismsManager.createEditablePrism(
                        update.id,
                        this.editPrismsLayer,
                        this.editPointsLayer,
                        this.editPolygonWaypointLayer,
                        this.editHeightOutlinesLayer,
                        this.prismLabelsLayer,
                        update.prismOptions,
                        update.topHeight,
                        update.bottomHeight
                    );
                }
                break;
            }
            case EditActions.MOUSE_MOVE: {
                const prism = this.prismsManager.get(update.id);
                if (prism && update.updatedPosition) {
                    prism.moveTempMovingPoint(update.updatedPosition);
                    this.renderEditLabels(prism, update);
                }
                break;
            }
            case EditActions.ADD_POINT: {
                const prism = this.prismsManager.get(update.id);

                if (prism && update.updatedPosition) {
                    prism.moveTempMovingPoint(update.updatedPosition);
                    prism.addPoint(update.updatedPosition);
                    this.renderEditLabels(prism, update);
                }
                break;
            }
            case EditActions.REMOVE_POINT: {
                const prism = this.prismsManager.get(update.id);

                if (!prism) {
                    break;
                }

                if (update.updatedPoint) {
                    prism.tryRemovePoint(update.updatedPoint, false);
                } else if (update.shouldRemoveMovingPoint) {
                    prism.removeTempMovingPoint();
                }

                prism.updateSelfIntersectionsValidity();
                this.renderEditLabels(prism, update);
                break;
            }
            case EditActions.DONE: {
                const prism = this.prismsManager.get(update.id);
                if (prism) {
                    prism.finishCreation();
                    prism.updateSelfIntersectionsValidity();
                    this.renderEditLabels(prism, update);
                }
                break;
            }
            case EditActions.DISPOSE: {
                const prism = this.prismsManager.get(update.id);
                if (prism) {
                    this.removeEditLabels(prism);
                    prism.dispose();
                    prism.updateSelfIntersectionsValidity();
                }
                break;
            }
            case EditActions.SET_EDIT_LABELS_RENDER_CALLBACK: {
                const prism = this.prismsManager.get(update.id);
                if (prism && update.labelsRenderFn) {
                    this.editLabelsRenderFunctions.set(prism.getId(), update.labelsRenderFn);
                    this.renderEditLabels(prism, update);
                }
                break;
            }
            case EditActions.UPDATE_EDIT_LABELS: {
                const prism = this.prismsManager.get(update.id);
                if (prism) {
                    this.renderEditLabels(prism, update, update.updateLabels);
                }
                break;
            }
            case EditActions.SET_MANUALLY: {
                const prism = this.prismsManager.get(update.id);
                if (prism) {
                    this.renderEditLabels(prism, update, update.updateLabels);
                }
                break;
            }
            default: {
                return;
            }
        }
    }

    public handleEditUpdates(update: PrismEditUpdate) {
        switch (update.editAction) {
            case EditActions.INIT: {
                if (update.prismOptions && update.positions) {
                    this.prismsManager.createEditablePrism(
                        update.id,
                        this.editPrismsLayer,
                        this.editPointsLayer,
                        this.editPolygonWaypointLayer,
                        this.editHeightOutlinesLayer,
                        this.prismLabelsLayer,
                        update.prismOptions,
                        update.topHeight,
                        update.bottomHeight,
                        update.positions
                    );
                }
                break;
            }
            case EditActions.DRAG_POINT: {
                const prism = this.prismsManager.get(update.id);
                if (!prism || !prism.enableEdit || !update.updatedPosition || !update.updatedPoint) {
                    break;
                }

                if (!this.lastValidPointPosition) {
                    this.lastValidPointPosition = update.updatedPoint.getPosition();
                }

                prism.movePoint(update.updatedPosition, update.updatedPoint);
                this.renderEditLabels(prism, update);

                break;
            }
            case EditActions.DRAG_POINT_FINISH: {
                const prism = this.prismsManager.get(update.id);
                if (!prism || !prism.enableEdit || !update.updatedPoint) {
                    break;
                }

                if (!prism.isValid && this.lastValidPointPosition) {
                    prism.movePoint(this.lastValidPointPosition, update.updatedPoint);
                }

                this.lastValidPointPosition = undefined;
                prism.movePointFinish(update.updatedPoint);

                if (update.updatedPoint.isVirtualEditPoint()) {
                    prism.changeVirtualPointToRealPoint(update.updatedPoint);
                    this.renderEditLabels(prism, update);
                }

                break;
            }
            case EditActions.REMOVE_POINT: {
                const prism = this.prismsManager.get(update.id);
                if (prism && prism.enableEdit && update.updatedPoint) {
                    prism.tryRemovePoint(update.updatedPoint, true);
                    prism.updateSelfIntersectionsValidity();
                    this.renderEditLabels(prism, update);
                }
                break;
            }
            case EditActions.DISABLE: {
                const prism = this.prismsManager.get(update.id);
                if (prism) {
                    prism.enableEdit = false;
                    this.renderEditLabels(prism, update);
                }
                break;
            }
            case EditActions.ENABLE: {
                const prism = this.prismsManager.get(update.id);
                if (prism) {
                    prism.enableEdit = true;
                    this.renderEditLabels(prism, update);
                }
                break;
            }
            case EditActions.DRAG_SHAPE_FINISH:
            case EditActions.DRAG_SHAPE: {
                const prism = this.prismsManager.get(update.id);

                if (!prism || !prism.enableEdit || update.shapeDragAction === undefined) {
                    break;
                }

                if (update.editAction === EditActions.DRAG_SHAPE_FINISH && update.shapeDragAction === ShapeDragActions.MoveEntity) {
                    prism.endMovePrism();

                    break;
                }

                switch (update.shapeDragAction) {
                    case ShapeDragActions.MoveEntity:
                        if (update.draggedPosition && update.updatedPosition) {
                            prism.movePrism(update.draggedPosition, update.updatedPosition);
                        }
                        break;

                    case ShapeDragActions.ChangeBottomHeight:
                        if (update.bottomHeight !== undefined) {
                            prism.moveBottomHeight(update.bottomHeight);
                        }
                        break;

                    case ShapeDragActions.ChangeTopHeight:
                        if (update.topHeight !== undefined) {
                            prism.moveTopHeight(update.topHeight);
                        }
                        break;
                }

                this.renderEditLabels(prism, update);

                break;
            }
            default: {
                return;
            }
        }
    }

    public ngOnDestroy(): void {
        this.prismsManager.clear();
    }

    public getPointSize(point: CartographicEditPoint) {
        return point.isVirtualEditPoint() ? point.props.virtualPointPixelSize : point.props.pixelSize;
    }

    public getPointShow(point: CartographicEditPoint) {
        return point.show && (point.isVirtualEditPoint() ? point.props.showVirtual : point.props.show);
    }

    private watchForPrismPointLimitReached(): void {
        this.prismsEditor.maximumNumberOfPointsReached$.pipe(untilDestroyed(this)).subscribe((limit) => {
            this.pointLimitReached.emit(limit);
        });
    }
}
