import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatLegacyRadioButton as MatRadioButton } from "@angular/material/legacy-radio";

@Component({
    selector: "dtm-ui-radio-tile",
    templateUrl: "./radio-tile.component.html",
    styleUrls: ["./radio-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        "[class.mat-radio-button]": "false",
        "[class.mat-radio-disabled]": "false",
        "[class.mat-radio-checked]": "false",
        "[class.radio-field-checked]": "checked",
        "[class.radio-field-disabled]": "disabled",
        "[class.mat-primary]": "false",
        "[class.mat-accent]": "false",
        "[class.mat-warn]": "false",
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioTileComponent),
            multi: true,
        },
    ],
})
export class RadioTileComponent extends MatRadioButton {}
