import { MissionSegmentStatus, RouteAreaTypeId } from "@dtm-frontend/shared/ui";
import { AcEntity, Cartesian3 } from "@pansa/ngx-cesium";

export interface RouteAcEntity extends AcEntity {
    id: RouteAreaTypeId;
    hierarchy?: PolygonEntityHierarchy;
    positions?: Cartesian3[];
    segmentStatus?: MissionSegmentStatus;
    isMain?: boolean;
    isSelected?: boolean;
    isCollision?: boolean;
    emergency?: boolean;
    degreesPositions?: number[][];
}

interface PolygonEntityHierarchy {
    positions: Cartesian3[];
    holes?: PolygonEntityHierarchy[];
}
