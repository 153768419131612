import { InjectionToken, PlatformRef, StaticProvider } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

interface DynamicProvider<T> {
    asyncPlatformBrowserDynamic: (extraProviders?: StaticProvider[] | undefined) => Promise<[PlatformRef, T]>;
    injectionToken: InjectionToken<T>;
}

function createDynamicProvider<T>(dynamicProvider: Promise<T>, descriptor: string): DynamicProvider<T> {
    const injectionToken = new InjectionToken<T>(descriptor);

    return {
        asyncPlatformBrowserDynamic: (extraProviders?: StaticProvider[] | undefined): Promise<[PlatformRef, T]> =>
            dynamicProvider.then((dynamicConfiguration) => [
                platformBrowserDynamic([{ provide: injectionToken, useValue: dynamicConfiguration }, ...(extraProviders ?? [])]),
                dynamicConfiguration,
            ]),
        injectionToken,
    };
}

export function createDynamicEnvironment<E, T>(dynamicProvider: Promise<T>, staticProvider: (dynamic: T) => E): DynamicProvider<E> {
    return createDynamicProvider(dynamicProvider.then(staticProvider), "Dynamic environment");
}
