import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ConfirmationDialogComponent, ConfirmationDialogConfig } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { KmlData } from "../kml-data-control.service";

interface KmlControlsComponentState {
    kmlDataSet: KmlData[];
}

@UntilDestroy()
@Component({
    selector: "dtm-map-kml-controls",
    templateUrl: "./kml-controls.component.html",
    styleUrls: ["./kml-controls.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class KmlControlsComponent {
    @Input() public set kmlDataSet(value: KmlData[] | undefined) {
        this.localStore.patchState({ kmlDataSet: value });
    }
    @Output() public kmlImportModalOpen = new EventEmitter();
    @Output() public allKmlDataDelete = new EventEmitter();
    @Output() public viewRefresh = new EventEmitter();
    @Output() public kmlDataZoom = new EventEmitter<KmlData>();
    @Output() public visibilityChange = new EventEmitter<boolean>();

    protected readonly kmlDataSet$ = this.localStore.selectByKey("kmlDataSet");

    constructor(
        private readonly localStore: LocalComponentStore<KmlControlsComponentState>,
        private readonly dialog: MatDialog,
        private readonly transloco: TranslocoService
    ) {
        localStore.setState({ kmlDataSet: [] });
    }

    protected openDeletePrompt() {
        this.dialog
            .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmMapCesium.kmlViewSettings.deleteConfirmationTitle"),
                    confirmationHtml: this.transloco.translate("dtmMapCesium.kmlViewSettings.deleteConfirmationText"),
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.allKmlDataDelete.emit());
    }

    protected handleKmlVisibilityChange(kmlDataSet: KmlData[]) {
        const isAnyKmlDataVisible = kmlDataSet.some((kml) => kml.data.show);
        this.visibilityChange.emit(isAnyKmlDataVisible);
    }
}
