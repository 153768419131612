import { WeatherPayloadData } from "../models/weather.models";
import { WeatherViewMode } from "./weather.state";

export namespace WeatherActions {
    export class GetWeatherConditions {
        public static readonly type = "[Weather] Get weather conditions";
        constructor(public dtmName: string) {}
    }

    export class ChangeWeatherVisibility {
        public static readonly type = "[Weather] Change weather visibility";
        constructor(public viewMode: WeatherViewMode) {}
    }

    export class GetMissionPlanWeatherRange {
        public static readonly type = "[Weather] Get mission plan weather range";
        constructor(public weatherPayloadData: WeatherPayloadData) {}
    }

    export class ChangeWeatherPanelVisibility {
        public static readonly type = "[Weather] Change weather panel visibility";
    }

    export class ResetWeatherState {
        public static readonly type = "[Weather] Reset weather state";
    }
}
