import { Directive, Input, OnInit } from "@angular/core";
import { NgControl } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Directive({
    selector: "[dtmUiDecimalDigitsLimiter]",
})
export class DecimalDigitsLimiterDirective implements OnInit {
    @Input() public dtmUiDecimalDigitsLimiter = 2;

    constructor(private control: NgControl) {}

    public ngOnInit() {
        const abstractControl = this.control.control;

        if (!abstractControl) {
            return;
        }

        abstractControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            if (typeof value !== "number") {
                return;
            }

            const fixedValue: number = +value?.toFixed(this.dtmUiDecimalDigitsLimiter);

            if (fixedValue !== value) {
                abstractControl.setValue(fixedValue);
            }
        });
    }
}
