import { PhoneNumber } from "./phone-number.models";

export enum DataChangeActionType {
    RequestEmailChange = "REQUEST_EMAIL_CHANGE",
    RequestPhoneNumberChange = "REQUEST_PHONE_CHANGE",
    SaveNewEmailAddress = "SAVE_NEW_EMAIL_ADDRESS",
    SaveNewPhoneNumber = "SAVE_NEW_PHONE_NUMBER",
}

export enum UserContactErrorType {
    CannotSendVerificationCodeForEmailChange = "CannotSendVerificationForEmailChangeCode",
    CannotSendVerificationCodeForPhoneChange = "CannotSendVerificationForPhoneChangeCode",
    IncorrectVerificationCode = "IncorrectVerificationCode",
    EmailConflict = "EmailConflict",
    PhoneConflict = "PhoneConflict",
    CannotSaveEmailAddress = "CannotSaveEmailAddress",
    CannotSavePhoneNumber = "CannotSavePhoneNumber",
}

export interface UserContactError {
    type: UserContactErrorType;
}

export interface DataUpdateAction {
    actionType: DataChangeActionType;
    payload: string | PhoneNumber;
}

export interface EmailAddressFormValues {
    email: string;
    verificationCode: string;
}

export interface PhoneNumberFormValues {
    phone: PhoneNumber;
    verificationCode: string;
}
