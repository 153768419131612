import alpha2to3Map from "../../assets/countries/countries2to3.json";

type KeyOf<T> = Extract<keyof T, string>;
type ValueOf<T> = T[KeyOf<T>];

export type Alpha2CountryCode = KeyOf<typeof alpha2to3Map>;
export type Alpha3CountryCode = ValueOf<typeof alpha2to3Map>;

export interface CountryListItem {
    alpha2Code: Alpha2CountryCode;
    alpha3Code: Alpha3CountryCode;
    displayName: string;
}
