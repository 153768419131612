import { Injectable } from "@angular/core";
import { ArrayUtils } from "@dtm-frontend/shared/utils";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, filter } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { NotificationsApiService } from "../services/notifications-api.service";
import { Notification, NotificationsError, PageDetails } from "../utils/notifications.model";
import { NotificationsActions } from "./notifications.actions";

export interface NotificationsStateModel {
    incomingNotifications: Notification[] | undefined;
    notificationsList: Notification[] | undefined;
    notificationsListError: NotificationsError | undefined;
    notificationsListPages: PageDetails | undefined;
    isNotificationsListProcessing: boolean | undefined;
    initialNotificationsCount: number | undefined;
}

@State<NotificationsStateModel>({
    name: "Notifications",
    defaults: {
        incomingNotifications: undefined,
        notificationsList: undefined,
        notificationsListError: undefined,
        notificationsListPages: undefined,
        isNotificationsListProcessing: undefined,
        initialNotificationsCount: undefined,
    },
})
@Injectable()
export class NotificationsState {
    constructor(private readonly notificationsApiService: NotificationsApiService) {}

    @Selector()
    public static notificationsList(state: NotificationsStateModel): Notification[] | undefined {
        return state.notificationsList;
    }

    @Selector()
    public static notificationsListError(state: NotificationsStateModel): NotificationsError | undefined {
        return state.notificationsListError;
    }

    @Selector()
    public static incomingNotifications(state: NotificationsStateModel): Notification[] | undefined {
        return state.incomingNotifications;
    }

    @Selector()
    public static notificationsListPages(state: NotificationsStateModel): PageDetails | undefined {
        return state.notificationsListPages;
    }

    @Selector()
    public static isNotificationsListProcessing(state: NotificationsStateModel): boolean | undefined {
        return state.isNotificationsListProcessing;
    }

    @Selector()
    public static initialNotificationsCount(state: NotificationsStateModel): number | undefined {
        return state.initialNotificationsCount;
    }

    @Action(NotificationsActions.GetNotificationsList)
    public getNotificationsList(context: StateContext<NotificationsStateModel>, action: NotificationsActions.GetNotificationsList) {
        context.patchState({ notificationsListError: undefined, isNotificationsListProcessing: true });

        return this.notificationsApiService.getNotificationsList(action.page).pipe(
            tap((result) => {
                const currentNotificationsList = context.getState().notificationsList ?? [];
                const newNotificationsList = action.page > 0 ? currentNotificationsList.concat(result.content) : result.content;
                const uniqueNotificationsList =
                    action.page > 0 ? ArrayUtils.unique(newNotificationsList, (item) => item.id) : newNotificationsList;

                context.patchState({
                    initialNotificationsCount: 0,
                    notificationsList: uniqueNotificationsList,
                    notificationsListError: undefined,
                    incomingNotifications: undefined,
                    isNotificationsListProcessing: false,
                    notificationsListPages: {
                        totalElements: result.pageDetails.totalElements,
                        number: result.pageDetails.number,
                        size: result.pageDetails.size,
                        isEmpty: result.pageDetails.isEmpty,
                        isLast: result.pageDetails.isLast,
                    },
                });
            }),
            catchError((error) => {
                context.patchState({ notificationsListError: error, notificationsList: undefined, isNotificationsListProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(NotificationsActions.StartIncomingNotificationsWatch)
    public startIncomingNotificationsWatch(
        context: StateContext<NotificationsStateModel>,
        action: NotificationsActions.StartIncomingNotificationsWatch
    ) {
        return this.notificationsApiService.startIncomingNotificationsWatch(action.userId, action.allowedNotificationTypes).pipe(
            filter(Boolean),
            tap((result: Notification) => {
                const incomingNotifications = [...(context.getState().incomingNotifications || [])];
                incomingNotifications.push(result);

                context.patchState({ incomingNotifications });
            })
        );
    }

    @Action(NotificationsActions.MarkNotificationAsRead)
    public markNotificationAsRead(context: StateContext<NotificationsStateModel>, action: NotificationsActions.MarkNotificationAsRead) {
        return this.notificationsApiService
            .markNotificationAsRead(action.notificationId)
            .pipe(tap(() => context.dispatch(NotificationsActions.GetNotificationsList)));
    }

    @Action(NotificationsActions.MarkAllAsRead)
    public markAllAsRead(context: StateContext<NotificationsStateModel>) {
        return this.notificationsApiService.markAllAsRead().pipe(tap(() => context.patchState({ notificationsList: undefined })));
    }

    @Action(NotificationsActions.ClearNotificationsList)
    public clearNotificationsList(context: StateContext<NotificationsStateModel>) {
        context.patchState({ notificationsList: undefined });

        return EMPTY;
    }

    @Action(NotificationsActions.GetInitialNotificationsCount)
    public getInitialNotificationsCount(context: StateContext<NotificationsStateModel>) {
        return this.notificationsApiService
            .getInitialNotificationsCount()
            .pipe(tap((result) => context.patchState({ initialNotificationsCount: result.counter })));
    }
}
