import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { MissionPreviewContainerComponent } from "./components/mission-search-preview/mission-preview-container.component";
import { MissionSearchContainerComponent } from "./components/mission-search/mission-search-container.component";
import { MISSION_ID_ROUTE_PARAM_NAME, MissionResolver } from "./services/mission-search.resolvers";

const routes: Routes = [
    {
        path: "mission-search",
        component: MissionSearchContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dtmSharedMissionSearch.routeTitles.missionSearch",
        },
    },
    {
        path: `mission-search/:${MISSION_ID_ROUTE_PARAM_NAME}`,
        component: MissionPreviewContainerComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            missionId: MissionResolver,
        },
        data: {
            titleTranslationKey: "dtmSharedMissionSearch.routeTitles.missionDetails",
            className: "no-padding",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [MissionResolver],
})
export class MissionSearchRoutingModule {}
