import { Position, polygon as turfPolygon } from "@turf/helpers";
import turfKinks from "@turf/kinks";
import { MINIMUM_VERTEX_NUMBER } from "../models/prism/editable-prism";

export function checkPolygonEdgesIntersections(positions: Position[]): boolean {
    if (positions.length < MINIMUM_VERTEX_NUMBER) {
        return true;
    }

    const polygon = turfPolygon([[...positions, positions[0]]]);

    return turfKinks(polygon).features.length === 0;
}
