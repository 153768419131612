/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

const DEFAULT_TIMEOUT = 300;

export function throttle(miliseconds: number = DEFAULT_TIMEOUT) {
    return function (target: any, key: string, descriptor: PropertyDescriptor) {
        const callback: Function = descriptor.value;

        let isInThrottle = false;
        let hasTrailingExecution = false;
        let trailingExecutionArguments: any;

        descriptor.value = function () {
            if (isInThrottle) {
                hasTrailingExecution = true;
                trailingExecutionArguments = arguments;

                return;
            }

            isInThrottle = true;

            setTimeout(() => {
                isInThrottle = false;

                if (hasTrailingExecution) {
                    descriptor.value.apply(this, trailingExecutionArguments);
                }
            }, miliseconds);

            hasTrailingExecution = false;
            trailingExecutionArguments = undefined;

            callback.apply(this, arguments);
        };
    };
}
