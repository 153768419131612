import { MINUTES_IN_HOUR, SECONDS_IN_MINUTE } from "@dtm-frontend/shared/utils";
import { DmsDirectionCoordinates } from "../models/dms-coordinates-input.models";

function padNumberWithZeros(value: number): string {
    return value.toString().padStart(2, "0");
}

export function convertDmsWithDirectionToString(dms: DmsDirectionCoordinates): string {
    return `${dms.degrees}°${padNumberWithZeros(dms.minutes)}'${padNumberWithZeros(dms.seconds)}"${dms.direction}`;
}

export function convertDmsWithDirectionToStringWithoutSeconds(dms: DmsDirectionCoordinates): string {
    if (dms.seconds >= SECONDS_IN_MINUTE / 2) {
        dms.minutes++;
    }

    // NOTE: After rounding minutes might become 60
    if (dms.minutes === MINUTES_IN_HOUR) {
        dms.degrees++;
        dms.minutes = 0;
    }

    return `${dms.degrees}°${padNumberWithZeros(dms.minutes)}'${dms.direction}`;
}
