import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { NotamPreviewComponent } from "@dtm-frontend/dss-shared-lib";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { switchMap, take } from "rxjs";
import { FlightZoneActions } from "../state/flight-zone.actions";
import { FlightZoneState } from "../state/flight-zone.state";

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class NotamModalService {
    constructor(private readonly store: Store, private readonly matDialog: MatDialog) {}

    public openNotamPreview(flightZoneId: string): void {
        this.store
            .dispatch(new FlightZoneActions.GetNotamData(flightZoneId))
            .pipe(
                switchMap(() => this.store.select(FlightZoneState.notamData)),
                take(1),
                untilDestroyed(this)
            )
            .subscribe((notamData) => {
                this.matDialog.open(NotamPreviewComponent, { data: notamData?.nativeNotam });
            });
    }
}
