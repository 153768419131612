import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DateUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

interface MissionDatesComponentState {
    startDate: Date;
    finishDate: Date;
    isWholeDayMode: boolean;
}

@Component({
    selector: "dtm-mission-mission-dates[startDate][finishDate]",
    templateUrl: "./mission-dates.component.html",
    styleUrls: ["./mission-dates.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionDatesComponent {
    @Input() public set startDate(value: Date) {
        this.localStore.patchState({ startDate: value });
    }

    @Input() public set finishDate(value: Date) {
        this.localStore.patchState({ finishDate: value });
    }

    @Input() public set isWholeDayMode(value: BooleanInput) {
        this.localStore.patchState({ isWholeDayMode: coerceBooleanProperty(value) });
    }

    protected readonly startDate$ = this.localStore.selectByKey("startDate");
    protected readonly finishDate$ = this.localStore.selectByKey("finishDate");
    protected readonly isWholeDayMode$ = this.localStore.selectByKey("isWholeDayMode");

    constructor(private readonly localStore: LocalComponentStore<MissionDatesComponentState>) {
        this.localStore.setState({
            startDate: new Date(),
            finishDate: new Date(),
            isWholeDayMode: false,
        });
    }

    protected getDaysBetween(startDate: Date, endDate: Date): number {
        const daysBetween = DateUtils.daysBetween(startDate, endDate);

        return Math.max(daysBetween + 1, 1);
    }
}
