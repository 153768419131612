<div [class.disabled]="isDisabled" *ngrxLet="isDisabled$; let isDisabled">
    <ng-container *ngrxLet="uploadedFiles$; let uploadedFiles">
        <dtm-ui-uploaded-files-display
            *ngIf="isFileListVisible$ | ngrxPush"
            [uploadedFiles]="uploadedFiles"
            [isDisabled]="isDisabled"
            [isDownloadAllButtonVisible]="isDownloadAllButtonVisible$ | ngrxPush"
            [canRemoveFile]="true"
            [additionalPathParams]="additionalPathParams$ | ngrxPush"
            (tryRemoveFile)="tryRemoveFile($event)"
        >
            <ng-content select="label"></ng-content>
        </dtm-ui-uploaded-files-display>
        <dtm-ui-files-upload
            [processedFiles]="processedFiles$ | ngrxPush"
            [isDisabled]="isDisabled"
            [allowedTypes]="allowedTypes$ | ngrxPush"
            [isMultiple]="isMultiple$ | ngrxPush"
            (processedFilesAdd)="addProcessedFiles($event)"
            (processedFileRemove)="removeProcessedFile($event)"
        >
        </dtm-ui-files-upload>
    </ng-container>
    <div class="hint">
        <ng-content select=".field-hint"></ng-content>
    </div>
    <dtm-ui-form-errors (hasErrors)="setHasErrors($event)">
        <ng-content select=".field-error"></ng-content>
    </dtm-ui-form-errors>
</div>
