import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { DialogService } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatestWith, map } from "rxjs/operators";
import {
    FilterConditionSearchState,
    FilterConditionSearchType,
    FilterConditions,
    Mission,
    MissionWithRoute,
    Sorting,
} from "../../../models/mission-search.models";
import { FilterModalComponent, FilterModalData } from "../filter-modal/filter-modal.component";

interface MissionSearchComponentState {
    maxResultsSize: number | undefined;
    isListFolded: boolean;
    filterConditions: FilterConditions | undefined;
    resultMissions: Mission[];
    isProcessing: boolean;
    operatorSearchState: FilterConditionSearchState;
    pilotSearchState: FilterConditionSearchState;
    missionIdSearchState: FilterConditionSearchState;
    uavSearchState: FilterConditionSearchState;
    zoneSearchState: FilterConditionSearchState;
    areMissionsVisibleOnMap: boolean;
    resultMissionsRoutes: MissionWithRoute[];
    isMapProcessing: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-mission-mission-search",
    templateUrl: "./mission-search.component.html",
    styleUrls: ["./mission-search.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionSearchComponent {
    @HostBinding("class.map-disabled")
    @Input()
    public isMapFeatureDisabled = false;

    @Input() public set filterConditions(value: FilterConditions | undefined) {
        this.localStore.patchState({ filterConditions: value });
    }

    @Input() public set maxResultsSize(value: number | undefined) {
        this.localStore.patchState({ maxResultsSize: value });
    }

    @Input() public set resultMissions(value: Mission[] | undefined) {
        this.localStore.patchState({ resultMissions: value ?? [] });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set operatorSearchState(value: FilterConditionSearchState | undefined) {
        this.localStore.patchState({ operatorSearchState: value ?? { isProcessing: false, options: [] } });
    }

    @Input() public set pilotSearchState(value: FilterConditionSearchState | undefined) {
        this.localStore.patchState({ pilotSearchState: value ?? { isProcessing: false, options: [] } });
    }

    @Input() public set missionIdSearchState(value: FilterConditionSearchState | undefined) {
        this.localStore.patchState({ missionIdSearchState: value ?? { isProcessing: false, options: [] } });
    }

    @Input() public set uavSearchState(value: FilterConditionSearchState | undefined) {
        this.localStore.patchState({ uavSearchState: value ?? { isProcessing: false, options: [] } });
    }

    @Input() public set zoneSearchState(value: FilterConditionSearchState | undefined) {
        this.localStore.patchState({ zoneSearchState: value ?? { isProcessing: false, options: [] } });
    }

    @Input() public set resultMissionsRoutes(value: MissionWithRoute[] | undefined) {
        this.localStore.patchState({ resultMissionsRoutes: value ?? [] });
    }

    @Input() public set isMapProcessing(value: BooleanInput) {
        this.localStore.patchState({ isMapProcessing: coerceBooleanProperty(value) });
    }

    @Output() public readonly orderChange = new EventEmitter<Sorting>();
    @Output() public readonly filterChange = new EventEmitter<FilterConditions | undefined>();
    @Output() public readonly detailsShow = new EventEmitter<Mission>();
    @Output() public readonly operatorSearchTextChange = new EventEmitter<string>();
    @Output() public readonly pilotSearchTextChange = new EventEmitter<string>();
    @Output() public readonly missionIdSearchTextChange = new EventEmitter<string>();
    @Output() public readonly uavSearchTextChange = new EventEmitter<string>();
    @Output() public readonly zoneSearchTextChange = new EventEmitter<string>();
    @Output() public readonly fetchRoutes = new EventEmitter<Mission[]>();

    protected readonly maxResultsSize$ = this.localStore.selectByKey("maxResultsSize");
    protected readonly isListFolded$ = this.localStore.selectByKey("isListFolded");
    protected readonly filterConditions$ = this.localStore.selectByKey("filterConditions");
    protected readonly areFiltersSet$ = this.filterConditions$.pipe(
        map((filterConditions) => Object.values(filterConditions ?? {}).filter(FunctionUtils.isTruthy).length > 0)
    );
    protected readonly resultMissions$ = this.localStore.selectByKey("resultMissions");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly resultMissionsRoutes$ = this.localStore.selectByKey("resultMissionsRoutes").pipe(
        combineLatestWith(this.localStore.selectByKey("areMissionsVisibleOnMap")),
        map(([resultMissionsRoutes, areMissionsVisibleOnMap]) => (areMissionsVisibleOnMap ? resultMissionsRoutes : []))
    );
    protected readonly isMapProcessing$ = this.localStore.selectByKey("isMapProcessing");

    constructor(
        private readonly localStore: LocalComponentStore<MissionSearchComponentState>,
        private readonly dialogService: DialogService
    ) {
        this.localStore.setState({
            maxResultsSize: undefined,
            isListFolded: false,
            filterConditions: undefined,
            resultMissions: [],
            isProcessing: false,
            operatorSearchState: {
                isProcessing: false,
                options: [],
            },
            pilotSearchState: {
                isProcessing: false,
                options: [],
            },
            missionIdSearchState: {
                isProcessing: false,
                options: [],
            },
            uavSearchState: {
                isProcessing: false,
                options: [],
            },
            zoneSearchState: {
                isProcessing: false,
                options: [],
            },
            areMissionsVisibleOnMap: false,
            resultMissionsRoutes: [],
            isMapProcessing: false,
        });
    }

    protected toggleListFold() {
        this.localStore.patchState(({ isListFolded }) => ({ isListFolded: !isListFolded }));
    }

    protected openFilterDialog() {
        const data: FilterModalData = {
            initialFilterConditions: this.localStore.selectSnapshotByKey("filterConditions"),
            operatorSearchState$: this.localStore.selectByKey("operatorSearchState"),
            pilotSearchState$: this.localStore.selectByKey("pilotSearchState"),
            missionIdSearchState$: this.localStore.selectByKey("missionIdSearchState"),
            uavSearchState$: this.localStore.selectByKey("uavSearchState"),
            zoneSearchState$: this.localStore.selectByKey("zoneSearchState"),
        };

        const dialogRef = this.dialogService.open(FilterModalComponent, { data, panelClass: "mission-search-filter" });

        dialogRef.componentInstance.searchTextChange.pipe(untilDestroyed(this)).subscribe(({ type, text }) => {
            switch (type) {
                case FilterConditionSearchType.Operator:
                    this.operatorSearchTextChange.emit(text);
                    break;
                case FilterConditionSearchType.Pilot:
                    this.pilotSearchTextChange.emit(text);
                    break;
                case FilterConditionSearchType.Mission:
                    this.missionIdSearchTextChange.emit(text);
                    break;
                case FilterConditionSearchType.Uav:
                    this.uavSearchTextChange.emit(text);
                    break;
                case FilterConditionSearchType.Zone:
                    this.zoneSearchTextChange.emit(text);
                    break;
            }
        });

        dialogRef
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((filterConditions) => this.applyFilterConditions(filterConditions));
    }

    protected removeFilter(names: Array<keyof FilterConditions> | null): void {
        if (!names) {
            this.applyFilterConditions(undefined);

            return;
        }

        let filterConditions = this.localStore.selectSnapshotByKey("filterConditions");
        if (!filterConditions) {
            return;
        }

        filterConditions = { ...filterConditions };

        for (const name of names) {
            filterConditions[name] = null;
        }

        this.applyFilterConditions(filterConditions);
    }

    protected setMissionsVisibilityOnMap(areMissionsVisibleOnMap: boolean): void {
        this.localStore.patchState({ areMissionsVisibleOnMap });

        if (!areMissionsVisibleOnMap) {
            return;
        }

        this.fetchRoutes.emit(this.localStore.selectSnapshotByKey("resultMissions"));
    }

    private applyFilterConditions(filterConditions: FilterConditions | undefined): void {
        if (filterConditions && Object.values(filterConditions).every((condition) => FunctionUtils.isNullOrUndefined(condition))) {
            filterConditions = undefined;
        }

        this.localStore.patchState({ filterConditions });
        this.filterChange.emit(filterConditions);
    }
}
