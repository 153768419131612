import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AirspaceElement } from "@dtm-frontend/shared/map/geo-zones";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { map } from "rxjs/operators";
import {
    EvaluationIssueStatus,
    FlightRules,
    MissionPlanAnalysisEvaluationIssue,
    MissionPlanEvaluationOption,
    MissionProcessingPhaseExtended,
} from "../../models";

interface AdditionalAnalysisInfoComponentState {
    additionalZones: AirspaceElement[];
    selectedZoneId: string | undefined;
    evaluation: MissionPlanEvaluationOption | undefined;
    flightRules: FlightRules[];
    missionPhase: MissionProcessingPhaseExtended | undefined;
}

@Component({
    selector: "dtm-mission-additional-analysis-info[additionalZones]",
    templateUrl: "./additional-analysis-info.component.html",
    styleUrls: ["./additional-analysis-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AdditionalAnalysisInfoComponent {
    @Input() public set additionalZones(value: AirspaceElement[] | undefined) {
        this.localStore.patchState({ additionalZones: value ?? [] });
    }

    @Input() public set selectedZoneId(value: string | undefined) {
        this.localStore.patchState({ selectedZoneId: value });
    }

    @Input() public set evaluation(value: MissionPlanEvaluationOption | undefined) {
        this.localStore.patchState({ evaluation: value });
    }
    @Input() public set flightRules(value: FlightRules[] | undefined) {
        this.localStore.patchState({ flightRules: value ?? [] });
    }
    @Input() public set missionPhase(value: MissionProcessingPhaseExtended | undefined) {
        this.localStore.patchState({ missionPhase: value });
    }

    @Output() public readonly zoneSelect = new EventEmitter<AirspaceElement>();

    protected readonly additionalZones$ = this.localStore.selectByKey("additionalZones");
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly evaluationIssues$ = this.localStore.selectByKey("evaluation").pipe(map(this.getNonZoneAndNonSuppressedIssues));
    protected readonly flightRules$ = this.localStore.selectByKey("flightRules");
    protected readonly missionPhase$ = this.localStore.selectByKey("missionPhase");

    protected readonly EvaluationIssueStatus = EvaluationIssueStatus;

    constructor(private readonly localStore: LocalComponentStore<AdditionalAnalysisInfoComponentState>) {
        this.localStore.setState({
            additionalZones: [],
            selectedZoneId: undefined,
            evaluation: undefined,
            flightRules: [],
            missionPhase: undefined,
        });
    }
    private getNonZoneAndNonSuppressedIssues(evaluation?: MissionPlanEvaluationOption): MissionPlanAnalysisEvaluationIssue[] {
        return evaluation?.issues.filter((issue) => !issue.designators?.length && issue.status !== EvaluationIssueStatus.Suppressed) ?? [];
    }
}
