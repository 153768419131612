<ng-container *ngIf="!isMapFeatureDisabled; else withoutMapFeatureTemplate">
    <div
        class="list-wrapper"
        *ngrxLet="{
            maxResultsSize: maxResultsSize$,
            isListFolded: isListFolded$,
            areFiltersSet: areFiltersSet$,
            resultMissions: resultMissions$,
            isProcessing: isProcessing$
        } as vm"
        [class.folded]="vm.isListFolded"
    >
        <div class="list">
            <dtm-mission-mission-search-list-container
                [maxResultsSize]="vm.maxResultsSize"
                [areFiltersSet]="vm.areFiltersSet"
                [missions]="vm.resultMissions"
                [isProcessing]="vm.isProcessing"
                (orderChange)="orderChange.emit($event)"
                (detailsShow)="detailsShow.emit($event)"
                (filterConditionsOpen)="openFilterDialog()"
                (missionsVisibilityOnMapChange)="setMissionsVisibilityOnMap($event)"
            ></dtm-mission-mission-search-list-container>
        </div>
        <div class="view-switcher">
            <button type="button" (click)="toggleListFold()">
                <dtm-ui-icon [name]="vm.isListFolded ? 'menu-unfold' : 'menu-fold'"></dtm-ui-icon>
            </button>
        </div>
    </div>
    <div class="chips-map-wrapper">
        <dtm-mission-mission-search-filter-chips
            class="filters-chips"
            [filterConditions]="filterConditions$ | ngrxPush"
            (filterRemove)="removeFilter($event)"
        ></dtm-mission-mission-search-filter-chips>
        <dtm-mission-mission-search-result-map
            class="result-map"
            [missions]="resultMissionsRoutes$ | ngrxPush"
            [isProcessing]="isMapProcessing$ | ngrxPush"
        ></dtm-mission-mission-search-result-map>
    </div>
</ng-container>

<ng-template #withoutMapFeatureTemplate>
    <div
        class="compact-list-wrapper"
        *ngrxLet="{
            maxResultsSize: maxResultsSize$,
            isListFolded: isListFolded$,
            areFiltersSet: areFiltersSet$,
            resultMissions: resultMissions$,
            isProcessing: isProcessing$
        } as vm"
        [class.folded]="vm.isListFolded"
    >
        <dtm-mission-mission-search-filter-chips
            class="filters-chips"
            [filterConditions]="filterConditions$ | ngrxPush"
            (filterRemove)="removeFilter($event)"
        ></dtm-mission-mission-search-filter-chips>
        <dtm-mission-mission-search-list-container
            [maxResultsSize]="vm.maxResultsSize"
            [isMapFeatureDisabled]="isMapFeatureDisabled"
            [areFiltersSet]="vm.areFiltersSet"
            [missions]="vm.resultMissions"
            [isProcessing]="vm.isProcessing"
            (orderChange)="orderChange.emit($event)"
            (detailsShow)="detailsShow.emit($event)"
            (filterConditionsOpen)="openFilterDialog()"
            (missionsVisibilityOnMapChange)="setMissionsVisibilityOnMap($event)"
        ></dtm-mission-mission-search-list-container>
    </div>
</ng-template>
