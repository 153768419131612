import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { RestrictionsListItem } from "@dtm-frontend/dss-shared-lib";
import { Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { DateFormatOptions } from "@jsverse/transloco-locale";
import { RestrictionsTableColumns, ZoneDurationChangeDialogData } from "../../../models/flight-zone.models";

interface ActiveRestrictionsListComponentState {
    isProcessing: boolean;
    hasDataRetrievalError: boolean;
    restrictions: RestrictionsListItem[];
    expandedElement: RestrictionsListItem | null;
    displayedColumns: RestrictionsTableColumns;
    applicationsPage: Page | undefined;
}

@Component({
    selector: "dss-client-lib-restrictions-list[restrictions][displayedColumns]",
    templateUrl: "./restrictions-list.component.html",
    styleUrls: ["./restrictions-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class RestrictionsListComponent {
    protected readonly localizeDateConfig: DateFormatOptions = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC",
        timeZoneName: "short",
    };
    protected readonly restrictions$ = this.localStore.selectByKey("restrictions");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly hasDataRetrievalError$ = this.localStore.selectByKey("hasDataRetrievalError");
    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly displayedColumns$ = this.localStore.selectByKey("displayedColumns");
    protected readonly applicationsPage$ = this.localStore.selectByKey("applicationsPage");

    @Input()
    public set restrictions(value: RestrictionsListItem[] | undefined) {
        this.localStore.patchState({ restrictions: value ?? [] });
    }
    @Input()
    public set isProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isProcessing: !!value });
    }

    @Input()
    public set hasDataRetrievalError(value: boolean | undefined) {
        this.localStore.patchState({ hasDataRetrievalError: !!value });
    }

    @Input()
    public set displayedColumns(value: RestrictionsTableColumns) {
        this.localStore.patchState({ displayedColumns: value });
    }

    @Input()
    public set page(value: Page | undefined) {
        this.localStore.patchState({ applicationsPage: value });
    }

    @Output() protected readonly pageChange = new EventEmitter<PageEvent>();
    @Output() protected readonly listRefresh = new EventEmitter<void>();
    @Output() protected readonly openNotamPreview = new EventEmitter<string>();
    @Output() protected readonly changeZoneDuration = new EventEmitter<ZoneDurationChangeDialogData>();
    @Output() protected readonly kmlDownload = new EventEmitter<RestrictionsListItem>();

    constructor(private readonly localStore: LocalComponentStore<ActiveRestrictionsListComponentState>) {
        this.localStore.setState({
            restrictions: [],
            isProcessing: false,
            hasDataRetrievalError: false,
            expandedElement: null,
            displayedColumns: [],
            applicationsPage: undefined,
        });
    }

    protected toggleExpandableRow(row: RestrictionsListItem): void {
        const currentlyExpandedElement = this.localStore.selectSnapshotByKey("expandedElement");

        this.localStore.patchState({ expandedElement: currentlyExpandedElement === row ? null : row });
    }
}
