import { Injectable } from "@angular/core";
import { Feature, FeatureCollection } from "@turf/helpers";
import { saveAs } from "file-saver";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare function require(moduleName: string): any; // TODO: DTM-5250

const tokml = require("@maphubs/tokml");

interface ToKmlOptions {
    documentName?: string;
    documentDescription?: string;
    /**
     * Name key in GeoJSON properties
     */
    name?: string;
    /**
     * Description key in GeoJSON properties
     */
    description?: string;
    /**
     * Include styles from GeoJSON properties
     */
    simplestyle?: boolean;
}

const defaultOptions: ToKmlOptions = {
    documentName: undefined,
    documentDescription: undefined,
    name: "name",
    description: "description",
    simplestyle: true,
};

@Injectable({
    providedIn: "root",
})
export class KmlExporterService {
    public geoJsonToKml(geoJson: Feature | FeatureCollection, fileName: string, options?: ToKmlOptions): void {
        // NOTE: Need to clone geoJson object, because tokml function deletes some nested properties
        const geoJsonClone = JSON.parse(JSON.stringify(geoJson));

        const kmlObject = tokml(geoJsonClone, {
            ...defaultOptions,
            ...options,
        });

        const kml = new Blob([kmlObject], { type: "application/vnd.google-earth.kml+xml" });

        saveAs(kml, `${fileName}.kml`);
    }
}
