import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { getDefaultLang } from "@dtm-frontend/shared/ui/i18n";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { NOT_AUTHORIZED_ROUTE } from "../interceptors/authentication-http.interceptor";

@Injectable()
export class DtmAuthGuard extends KeycloakAuthGuard {
    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly keycloak: KeycloakService,
        protected readonly router: Router
    ) {
        super(router, keycloak);
    }

    public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authenticated) {
            const redirectPath = state.url === `/${NOT_AUTHORIZED_ROUTE}` ? "" : state.url;

            await this.keycloak.login({
                locale: getDefaultLang(),
                redirectUri: this.document.location.origin + redirectPath,
            });
        }

        return true;
    }
}
