<dtm-ui-expandable-panel
    [isExpanded]="true"
    [hasHeaderSeparator]="false"
    *ngrxLet="{
        zoneTimesSettings: zoneTimesSetting$,
        timeRangeSetting: timeRangeSetting$,
        timeRange: timeRange$,
        aupTimeRangeFormGroupValue: aupTimeRangeFormGroupValue$,
        aupMode: aupMode$,
        availableTimeSettings: availableTimeSettings$,
        doesNextAupExist: doesNextAupExist$,
    } as vm"
>
    <p class="panel-header" headerSlot>{{ "dtmMapCesium.geographicalZones.filtersTitle" | transloco }}</p>
    <p class="field-label">{{ "dtmMapCesium.geographicalZones.showZonesUpToHeightLabel" | transloco }}</p>
    <form [formGroup]="geoZoneFiltersFormGroup">
        <div class="cards heights" *ngrxLet="zoneHeightSetting$ as zoneHeightSetting">
            <div
                class="selectable-tile"
                tabindex="0"
                [class.selected]="zoneHeightSetting === ZoneHeightSetting.H500"
                (click)="setZoneHeightSetting(ZoneHeightSetting.H500)"
                (keyup.enter)="setZoneHeightSetting(ZoneHeightSetting.H500)"
            >
                <span class="selectable-tile-label">{{ ZoneHeightSetting.H500 }}</span>
            </div>
            <div
                class="selectable-tile"
                tabindex="0"
                [class.selected]="zoneHeightSetting === ZoneHeightSetting.H750"
                (click)="setZoneHeightSetting(ZoneHeightSetting.H750)"
                (keyup.enter)="setZoneHeightSetting(ZoneHeightSetting.H750)"
            >
                <span class="selectable-tile-label">{{ ZoneHeightSetting.H750 }}</span>
            </div>
            <div
                class="selectable-tile"
                tabindex="0"
                [class.selected]="zoneHeightSetting === ZoneHeightSetting.H1000"
                (click)="setZoneHeightSetting(ZoneHeightSetting.H1000)"
                (keyup.enter)="setZoneHeightSetting(ZoneHeightSetting.H1000)"
            >
                <span class="selectable-tile-label">{{ ZoneHeightSetting.H1000 }}</span>
            </div>
            <input
                class="selectable-tile"
                [class.selected]="zoneHeightSetting === ZoneHeightSetting.Custom"
                matInput
                type="number"
                [placeholder]="'dtmMapCesium.geographicalZones.customHeightValuePlaceholder' | transloco"
                [matTooltip]="'dtmMapCesium.geographicalZones.customHeightValuePlaceholder' | transloco"
                [formControl]="customHeightSettingFormControl"
            />
        </div>
        <p class="field-label">
            {{ "dtmMapCesium.geographicalZones.displayedZonesRangeLabel" | transloco }}
        </p>
        <!-- TODO:DTM-4373 -  *ngIf="'true' | transloco is workaround for not showing initial value sometimes -->
        <dtm-ui-select-field [formControl]="zoneTimesSettingFormControl" [isClearable]="false" *ngIf="'true' | transloco">
            <dtm-ui-select-option
                [value]="ZoneTimesSetting.MissionTime"
                [disabled]="!(missionTimeRange$ | ngrxPush)"
                *ngIf="vm.availableTimeSettings.includes(ZoneTimesSetting.MissionTime)"
            >
                {{ "dtmMapCesium.geographicalZones.zonesInMissionTimeRangeLabel" | transloco }}
            </dtm-ui-select-option>
            <dtm-ui-select-option [value]="ZoneTimesSetting.Soon" *ngIf="vm.availableTimeSettings.includes(ZoneTimesSetting.Soon)">
                {{ "dtmMapCesium.geographicalZones.plannedActiveSoonZonesLabel" | transloco }}
            </dtm-ui-select-option>
            <dtm-ui-select-option
                [value]="ZoneTimesSetting.CustomTimeRange"
                *ngIf="vm.availableTimeSettings.includes(ZoneTimesSetting.CustomTimeRange)"
            >
                {{ "dtmMapCesium.geographicalZones.allZonesForGivenPeriodLabel" | transloco }}
            </dtm-ui-select-option>
            <dtm-ui-select-option [value]="ZoneTimesSetting.Current" *ngIf="vm.availableTimeSettings.includes(ZoneTimesSetting.Current)">
                <span>{{ "dtmMapCesium.geographicalZones.currentZones" | transloco }}</span>
            </dtm-ui-select-option>
        </dtm-ui-select-field>
        <div class="time-range cards" *ngIf="vm.zoneTimesSettings === ZoneTimesSetting.CustomTimeRange">
            <div
                class="selectable-tile"
                tabindex="0"
                [class.selected]="vm.timeRangeSetting === TimeRangeSetting.OneWeek"
                (click)="setTimeRangeSetting(TimeRangeSetting.OneWeek)"
                (keyup.enter)="setTimeRangeSetting(TimeRangeSetting.OneWeek)"
            >
                <span class="selectable-tile-label">{{ "dtmMapCesium.geographicalZones.timeRangeLabels.oneWeek" | transloco }}</span>
            </div>
            <div
                class="selectable-tile"
                tabindex="0"
                [class.selected]="vm.timeRangeSetting === TimeRangeSetting.TwoWeeks"
                (click)="setTimeRangeSetting(TimeRangeSetting.TwoWeeks)"
                (keyup.enter)="setTimeRangeSetting(TimeRangeSetting.TwoWeeks)"
            >
                <span class="selectable-tile-label">
                    <span class="selectable-tile-label">{{ "dtmMapCesium.geographicalZones.timeRangeLabels.twoWeeks" | transloco }}</span>
                </span>
            </div>
            <div
                class="selectable-tile"
                tabindex="0"
                [class.selected]="vm.timeRangeSetting === TimeRangeSetting.FourWeeks"
                (click)="setTimeRangeSetting(TimeRangeSetting.FourWeeks)"
                (keyup.enter)="setTimeRangeSetting(TimeRangeSetting.FourWeeks)"
            >
                <span class="selectable-tile-label">
                    <span class="selectable-tile-label">{{ "dtmMapCesium.geographicalZones.timeRangeLabels.fourWeeks" | transloco }}</span>
                </span>
            </div>
        </div>
        <div *ngIf="vm.zoneTimesSettings === ZoneTimesSetting.Soon">
            <p class="field-label">{{ "dtmMapCesium.geographicalZones.airspaceUsePlanLabel" | transloco }}</p>
            <div class="cards aup">
                <div
                    class="selectable-tile"
                    tabindex="0"
                    [class.selected]="vm.aupMode === AirspaceUsePlanSettings.Current"
                    (click)="setAupMode(AirspaceUsePlanSettings.Current)"
                    (keyup.enter)="setAupMode(AirspaceUsePlanSettings.Current)"
                >
                    <div class="selectable-tile-label">
                        {{ "dtmMapCesium.geographicalZones.currentAirspaceUsePlanLabel" | transloco }}
                        <dtm-ui-icon
                            name="information-fill"
                            [matTooltip]="'dtmMapCesium.geographicalZones.aupTooltip' | transloco"
                        ></dtm-ui-icon>
                    </div>
                </div>
                <div
                    [matTooltip]="'dtmMapCesium.geographicalZones.noNextAirspaceUsePlanLabel' | transloco"
                    [matTooltipDisabled]="vm.doesNextAupExist"
                >
                    <div
                        class="selectable-tile"
                        tabindex="0"
                        [class.disabled]="!vm.doesNextAupExist"
                        [class.selected]="vm.aupMode === AirspaceUsePlanSettings.Next"
                        (click)="setAupMode(AirspaceUsePlanSettings.Next)"
                        (keyup.enter)="setAupMode(AirspaceUsePlanSettings.Next)"
                    >
                        <div class="selectable-tile-label">
                            {{ "dtmMapCesium.geographicalZones.nextAirspaceUsePlanLabel" | transloco }}
                            <dtm-ui-icon
                                name="information-fill"
                                [matTooltip]="'dtmMapCesium.geographicalZones.aupTooltip' | transloco"
                            ></dtm-ui-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="vm.zoneTimesSettings === ZoneTimesSetting.Soon">
                <ng-container *ngIf="vm.timeRange as timeRange">
                    <dtm-ui-slider
                        [leftValue]="vm.aupTimeRangeFormGroupValue.aupFrom"
                        [rightValue]="vm.aupTimeRangeFormGroupValue.aupTo"
                        [min]="timeRange.min"
                        [max]="timeRange.max"
                        [step]="step"
                        [ticks]="SLIDER_SETTINGS.ticks"
                        [subTicks]="SLIDER_SETTINGS.subTicks"
                        [tickLabelFormatter]="tickLabelFormatter"
                        [thumbLabelFormatter]="tickLabelFormatter"
                        (rangeChange)="aupFromFormControl.setValue($event[0]); aupToFormControl.setValue($event[1])"
                    >
                    </dtm-ui-slider>
                    <div class="time-range-legend">
                        <ng-container *ngIf="vm.aupMode === AirspaceUsePlanSettings.Current">
                            <span>
                                {{ timeRange.min | localizeDate : { day: "numeric", month: "numeric" } }}
                            </span>
                            <span>
                                {{ timeRange.max | localizeDate : { day: "numeric", month: "numeric" } }}
                            </span>
                        </ng-container>

                        <ng-container *ngIf="vm.aupMode === AirspaceUsePlanSettings.Next">
                            <span>
                                {{ timeRange.min | invoke : getNextDay | localizeDate : { day: "numeric", month: "numeric" } }}
                            </span>
                            <span>
                                {{ timeRange.max | invoke : getNextDay | localizeDate : { day: "numeric", month: "numeric" } }}
                            </span>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</dtm-ui-expandable-panel>
