import { Options } from "@angular-slider/ngx-slider";
import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import {
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { UavMtomExclusion } from "@dtm-frontend/dss-shared-lib";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { distinctUntilChanged, startWith } from "rxjs/operators";
import { MTOMInputMode } from "../../../../../models/flight-zone.models";

interface MtomFieldComponentState {
    minValue: number | undefined;
    maxValue: number | undefined;
}

// eslint-disable-next-line no-magic-numbers
const MTOMPredefinedValues = [0, 0.25, 0.9, 2, 4, 25];

@UntilDestroy()
@Component({
    selector: "dss-client-lib-mtom-field[minValue][maxValue]",
    templateUrl: "./mtom-field.component.html",
    styleUrls: ["./mtom-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MtomFieldComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MtomFieldComponent),
            multi: true,
        },
    ],
})
export class MtomFieldComponent implements ControlValueAccessor, Validator, AfterViewInit {
    public readonly MTOMInputMode = MTOMInputMode;
    public readonly rangeSliderOptions: Options = {
        noSwitching: true,
        showTicks: true,
        stepsArray: MTOMPredefinedValues.map((step) => ({ value: step })),
        showTicksValues: true,
        translate: (value: number): string =>
            value === MTOMPredefinedValues[MTOMPredefinedValues.length - 1]
                ? this.transloco.translate("dssClientLibFlightZone.zoneRestrictionExecutionsStep.sliderLastValueLabel", { value })
                : this.transloco.translate("dssClientLibFlightZone.zoneRestrictionExecutionsStep.sliderValueLabel", { value }),
    };

    public readonly uavMtomInputMode = new UntypedFormControl(MTOMInputMode.Slider);
    public readonly lowerUavMtomExclusionFormControl = new UntypedFormControl(null, [Validators.required]);
    public readonly upperUavMtomExclusionFormControl = new UntypedFormControl(null, [Validators.required]);
    public readonly uavMtomExclusionForm = new UntypedFormGroup({
        from: this.lowerUavMtomExclusionFormControl,
        to: this.upperUavMtomExclusionFormControl,
    });

    @Input() public set minValue(value: number | undefined) {
        this.rangeSliderOptions.floor = value;
        this.lowerUavMtomExclusionFormControl.setValue(value);
        this.localStore.patchState({ minValue: value });
    }

    @Input() public set maxValue(value: number | undefined) {
        this.rangeSliderOptions.ceil = value;
        this.upperUavMtomExclusionFormControl.setValue(value);
        this.localStore.patchState({ maxValue: value });
    }

    private onValidationChange = FunctionUtils.noop;
    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: UavMtomExclusion) => void = FunctionUtils.noop;

    constructor(private readonly transloco: TranslocoService, private readonly localStore: LocalComponentStore<MtomFieldComponentState>) {
        this.localStore.setState({
            minValue: undefined,
            maxValue: undefined,
        });
    }

    public ngAfterViewInit(): void {
        this.listenToFormValueChanges();
        this.validateMinMaxValues();
    }

    public registerOnChange(fn: (value: UavMtomExclusion) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public onBlur(): void {
        this.propagateTouch();
    }

    public writeValue(value: UavMtomExclusion): void {
        if (value) {
            const isValueFromPredefinedSteps: boolean =
                MTOMPredefinedValues.includes(value.from) && MTOMPredefinedValues.includes(value.to);
            this.uavMtomInputMode.setValue(isValueFromPredefinedSteps ? MTOMInputMode.Slider : MTOMInputMode.Precision);
        }

        this.uavMtomExclusionForm.patchValue(value);
    }

    public validate(): ValidationErrors | null {
        if (this.uavMtomExclusionForm.invalid) {
            return {
                mtom: true,
            };
        }

        return null;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public updateSliderValues(value: number, sliderControl: UntypedFormControl): void {
        sliderControl.setValue(value);
    }

    private listenToFormValueChanges(): void {
        this.uavMtomExclusionForm.valueChanges
            .pipe(
                startWith(this.uavMtomExclusionForm.value), // emit default values on component init
                distinctUntilChanged<UavMtomExclusion>((oldValue, newValue) => equal(oldValue, newValue)),
                untilDestroyed(this)
            )
            .subscribe((value) => {
                this.propagateChange(value);
                this.onValidationChange();
            });
    }

    private validateMinMaxValues(): void {
        this.lowerUavMtomExclusionFormControl.valueChanges
            .pipe(startWith(this.lowerUavMtomExclusionFormControl.value), untilDestroyed(this))
            .subscribe((value) => {
                const minConstraintsValue = this.localStore.selectSnapshotByKey("minValue") ?? NaN;
                const maxConstraintsValue = this.localStore.selectSnapshotByKey("maxValue") ?? NaN;

                this.upperUavMtomExclusionFormControl.setValidators([
                    Validators.required,
                    Validators.min(Math.max(minConstraintsValue, value)),
                    Validators.max(maxConstraintsValue),
                ]);
                this.upperUavMtomExclusionFormControl.updateValueAndValidity({ emitEvent: false });
            });

        this.upperUavMtomExclusionFormControl.valueChanges
            .pipe(startWith(this.upperUavMtomExclusionFormControl.value), untilDestroyed(this))
            .subscribe((value) => {
                const minConstraintsValue = this.localStore.selectSnapshotByKey("minValue") ?? NaN;
                const maxConstraintsValue = this.localStore.selectSnapshotByKey("maxValue") ?? NaN;

                this.lowerUavMtomExclusionFormControl.setValidators([
                    Validators.required,
                    Validators.min(minConstraintsValue),
                    Validators.max(Math.min(maxConstraintsValue, value)),
                ]);
                this.lowerUavMtomExclusionFormControl.updateValueAndValidity({ emitEvent: false });
            });
    }
}
