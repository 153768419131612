import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DialogService } from "../../services/dialog/dialog.service";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";

@UntilDestroy()
@Component({
    selector: "dtm-ui-reset-password",
    templateUrl: "./reset-password.component.html",
    styleUrls: ["./reset-password.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent {
    @Output() public readonly resetPassword = new EventEmitter<void>();

    constructor(private readonly dialogService: DialogService, private readonly translocoService: TranslocoService) {}

    protected tryResetPassword(): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("dtmUi.resetPassword.resetPasswordConfirmationTitleText"),
                    confirmationText: this.translocoService.translate("dtmUi.resetPassword.resetPasswordConfirmationMessageText"),
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.resetPassword.emit());
    }
}
