import turfBbox from "@turf/bbox";
import turfDistance from "@turf/distance";
import { AllGeoJSON, point } from "@turf/helpers";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export function getCesiumImageScaleForGeometry(geometry: AllGeoJSON, multiplier = 1) {
    const [minX, minY, maxX, maxY] = turfBbox(geometry);
    const topLeftPoint = point([minX, maxY]);
    const topRightPoint = point([maxX, maxY]);
    const bottomLeftPoint = point([minX, minY]);
    const distanceY = turfDistance(topLeftPoint, bottomLeftPoint, { units: "kilometers" });
    const distanceX = turfDistance(topLeftPoint, topRightPoint, { units: "kilometers" });

    return new Cesium.Cartesian2(distanceX * multiplier, distanceY * multiplier);
}
