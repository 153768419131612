// eslint-disable-next-line max-len
// NOTE: this is based on https://github.com/pansa-dev/ngx-cesium/blob/HEAD/projects/@pansa/ngx-cesium/src/lib/@pansa/ngx-cesium-widgets/services/entity-editors/circles-editor/circles-manager.service.ts

import { Injectable } from "@angular/core";
import { AcLayerComponent } from "@pansa/ngx-cesium";
import { CylinderEditOptions } from "../../../models/cylinder/cylinder-edit-options";
import { EditableCylinder } from "../../../models/cylinder/editable-cylinder";

@Injectable()
export class CylindersManagerService {
    private cylinders = new Map<string, EditableCylinder>();

    public createEditableCylinder(
        id: string,
        editCylindersLayer: AcLayerComponent,
        editPointsLayer: AcLayerComponent,
        editHeightOutlinesLayer: AcLayerComponent,
        cylinderLabelsLayer: AcLayerComponent,
        cylinderOptions: CylinderEditOptions
    ): EditableCylinder {
        const editableCylinder = new EditableCylinder(
            id,
            editCylindersLayer,
            editPointsLayer,
            editHeightOutlinesLayer,
            cylinderLabelsLayer,
            cylinderOptions
        );
        this.cylinders.set(id, editableCylinder);

        return editableCylinder;
    }

    public dispose(id: string) {
        this.cylinders.get(id)?.dispose();
        this.cylinders.delete(id);
    }

    public get(id: string): EditableCylinder | undefined {
        return this.cylinders.get(id) as EditableCylinder;
    }

    public clear() {
        this.cylinders.forEach((cylinder) => cylinder.dispose());
        this.cylinders.clear();
    }
}
