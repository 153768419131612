import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { MissionFilters, MissionProcessingPhase, PhasePayloadData, PlannedMission } from "../../models/planned-missions.models";

interface PlannedMissionDetailsComponentState {
    waitingMissions: PlannedMission[];
    acceptedMissions: PlannedMission[];
    rejectedMissions: PlannedMission[];
    selectedMissionRoute: MissionPlanRoute | undefined;
    selectedTileId: string | undefined;
    isProcessing: boolean;
    areNewMissionsAvailable: boolean;
    isPlanRouteProcessing: boolean;
}

@UntilDestroy()
@Component({
    selector: "dss-client-lib-planned-mission-details[waitingMissions][rejectedMissions][acceptedMissions][isProcessing]",
    templateUrl: "./planned-mission-details.component.html",
    styleUrls: ["./planned-mission-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class PlannedMissionDetailsComponent {
    @Input() public set waitingMissions(value: PlannedMission[] | undefined) {
        this.localStore.patchState({ waitingMissions: value ?? [] });
    }
    @Input() public set rejectedMissions(value: PlannedMission[] | undefined) {
        this.localStore.patchState({ rejectedMissions: value ?? [] });
    }
    @Input() public set acceptedMissions(value: PlannedMission[] | undefined) {
        this.localStore.patchState({ acceptedMissions: value ?? [] });
    }
    @Input() public set selectedMissionRoute(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ selectedMissionRoute: value });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set areNewMissionsAvailable(value: BooleanInput) {
        this.localStore.patchState({ areNewMissionsAvailable: coerceBooleanProperty(value) });
    }
    @Input() public set isPlanRouteProcessing(value: BooleanInput) {
        this.localStore.patchState({ isPlanRouteProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set selectedTileId(value: string | undefined) {
        this.localStore.patchState({ selectedTileId: value });
    }

    @Output() public readonly missionSelect = new EventEmitter<PlannedMission>();
    @Output() public readonly filtersChange = new EventEmitter<MissionFilters>();
    @Output() public readonly missionPhaseChange = new EventEmitter<PhasePayloadData>();

    @HostBinding("class.card-box") private cardBox = true;

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly areNewMissionsAvailable$ = this.localStore.selectByKey("areNewMissionsAvailable");
    protected readonly isPlanRouteProcessing$ = this.localStore.selectByKey("isPlanRouteProcessing");
    protected readonly selectedTileId$ = this.localStore.selectByKey("selectedTileId");
    protected readonly waitingMissions$ = this.localStore.selectByKey("waitingMissions");
    protected readonly rejectedMissions$ = this.localStore.selectByKey("rejectedMissions");
    protected readonly acceptedMissions$ = this.localStore.selectByKey("acceptedMissions");
    protected readonly selectedMissionRoute$ = this.localStore.selectByKey("selectedMissionRoute");
    protected readonly MissionProcessingPhase = MissionProcessingPhase;

    constructor(private readonly localStore: LocalComponentStore<PlannedMissionDetailsComponentState>) {
        this.localStore.setState({
            waitingMissions: [],
            acceptedMissions: [],
            rejectedMissions: [],
            selectedMissionRoute: undefined,
            selectedTileId: undefined,
            isProcessing: false,
            areNewMissionsAvailable: false,
            isPlanRouteProcessing: false,
        });
    }

    protected filterMission(filters: MissionFilters, phase: MissionProcessingPhase): void {
        this.filtersChange.emit({ ...filters, phase });
    }
}
