import { MissionPlanRoute } from "@dtm-frontend/shared/ui";

export type WeatherConditionsData = Weather | SimpleWeather;

export enum AnalysisResult {
    None = "NONE",
    Ok = "OK",
    Warning = "WARNING",
    Danger = "DANGER",
}

export enum WeatherErrorType {
    CannotGetWeatherConditions = "CannotGetWeatherConditions",
}

export interface WeatherError {
    type: WeatherErrorType;
}

export interface WeatherCondition {
    min: string;
    max: string;
    avg: string;
    unit: string;
    analysisResult: AnalysisResult;
}

export interface WeatherPayloadData {
    route: MissionPlanRoute;
    hoursRangeBefore: number;
    hoursRangeAfter: number;
    missionStartTime: Date;
    missionEndTime?: Date;
}

export interface WeatherDetails {
    isWithinDtm?: boolean;
    forecastRefTime?: Date;
    weatherConditions: Weather[];
}

export interface Weather {
    time: string;
    temp?: WeatherCondition;
    dewPointTemperature?: WeatherCondition;
    pressureAmsl?: WeatherCondition;
    specHumidity?: WeatherCondition;
    relHumidity?: WeatherCondition;
    totalCloudCover?: WeatherCondition;
    lowCloudCover?: WeatherCondition;
    mediumCloudCover?: WeatherCondition;
    heightCloudCover?: WeatherCondition;
    cloudBase?: WeatherCondition;
    snow?: WeatherCondition;
    rain?: WeatherCondition;
    visibility?: WeatherCondition;
    windSpeed100Agl?: WeatherCondition;
    windDirection100Agl?: WeatherCondition;
    windSpeed10Agl?: WeatherCondition;
    windDirection10Agl?: WeatherCondition;
    kpi?: WeatherCondition;
    windGusts10Agl?: WeatherCondition;
    groundPressure?: WeatherCondition;
}

export interface SimpleWeather {
    windSpeed100Agl?: WeatherCondition;
    windDirection100Agl?: WeatherCondition;
    kpi?: WeatherCondition;
    temp?: WeatherCondition;
    relHumidity?: WeatherCondition;
    groundPressure?: WeatherCondition;
}
