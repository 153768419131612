import { ChangeDetectionStrategy, Component, forwardRef, Inject, Input } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from "@angular/forms";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { saveAs } from "file-saver";
import { ToastrService } from "ngx-toastr";
import { EMPTY, Observable } from "rxjs";
import { catchError, distinctUntilChanged, filter, map, tap } from "rxjs/operators";
import { DialogService } from "../../../services/dialog/dialog.service";
import { ButtonTheme, ConfirmationDialogComponent } from "../../confirmation-dialog/confirmation-dialog.component";
import { FILES_UPLOAD_API_PROVIDER, FilesUploadApi, UploadedFile } from "../../files-upload-field/files-upload.models";
import { FileInGroup, FilesGroup } from "../files-groups.models";

interface FilesGroupsUploadComponentState {
    isDisabled: boolean;
    value: FilesGroup[];
    additionalPathParams: Record<string, string> | undefined;
    addButtonLabel: string | undefined;
    uploadFieldLabel: string | undefined;
    editMode: Set<string>;
    fileUploadControls: Record<string, FormControl<UploadedFile[]>>;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-files-groups-upload",
    templateUrl: "./files-groups-upload.component.html",
    styleUrls: ["./files-groups-upload.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FilesGroupsUploadComponent),
            multi: true,
        },
    ],
})
export class FilesGroupsUploadComponent implements ControlValueAccessor {
    @Input() public set value(value: FilesGroup[] | undefined) {
        value = value ?? [];

        this.localStore.patchState({
            value,
            fileUploadControls: this.prepareFileUploadControls(value),
        });
    }

    @Input() public set additionalPathParams(value: Record<string, string> | undefined) {
        this.localStore.patchState({ additionalPathParams: value });
    }

    @Input() public set addButtonLabel(value: string | undefined) {
        this.localStore.patchState({ addButtonLabel: value ?? this.getDefaultAddButtonLabel() });
    }

    @Input() public set uploadFieldLabel(value: string | undefined) {
        this.localStore.patchState({ uploadFieldLabel: value ?? this.getDefaultUploadFieldLabel() });
    }

    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");
    protected readonly groups$ = this.localStore.selectByKey("value");
    protected readonly addButtonLabel$ = this.localStore.selectByKey("addButtonLabel");
    protected readonly uploadFieldLabel$ = this.localStore.selectByKey("uploadFieldLabel");
    protected readonly editMode$ = this.localStore.selectByKey("editMode").pipe(map((editMode) => [...editMode.values()]));
    protected readonly fileUploadControls$ = this.localStore.selectByKey("fileUploadControls");
    protected readonly additionalPathParams$ = this.localStore.selectByKey("additionalPathParams");

    private propagateChange: (value: FilesGroup[]) => void = FunctionUtils.noop;

    constructor(
        private readonly localStore: LocalComponentStore<FilesGroupsUploadComponentState>,
        private readonly transloco: TranslocoService,
        private readonly toastService: ToastrService,
        private readonly dialogService: DialogService,
        @Inject(FILES_UPLOAD_API_PROVIDER) private readonly fileUploadApi: FilesUploadApi
    ) {
        if (fileUploadApi === undefined) {
            throw new Error("Missing FILES_UPLOAD_API_PROVIDER provider");
        }

        this.localStore.setState({
            isDisabled: false,
            value: [],
            additionalPathParams: undefined,
            addButtonLabel: this.getDefaultAddButtonLabel(),
            uploadFieldLabel: this.getDefaultUploadFieldLabel(),
            editMode: new Set<string>(),
            fileUploadControls: {},
        });
    }

    public registerOnChange(fn: (value: FilesGroup[]) => void) {
        this.propagateChange = fn;
    }

    public registerOnTouched(): void {}

    public writeValue(value: FilesGroup[]) {
        this.value = value;
    }

    public setDisabledState(isDisabled: boolean) {
        this.localStore.patchState({ isDisabled });
    }

    protected isAddButtonVisible(group: FilesGroup): boolean {
        return !group.validators?.singleFile || group.files.length === 0;
    }

    protected isRemoveButtonVisible(file: FileInGroup): boolean {
        return file.isRemovable !== false;
    }

    protected getAllowedTypesNames(allowedTypes: Record<string, string> | string[] = []): string[] {
        if (Array.isArray(allowedTypes)) {
            return allowedTypes;
        }

        return Object.values(allowedTypes ?? {});
    }

    protected getAllowedTypes(allowedTypes: Record<string, string> | string[] = []): string[] {
        if (Array.isArray(allowedTypes)) {
            return allowedTypes;
        }

        return Object.keys(allowedTypes ?? {});
    }

    protected setEditMode(groupId: string, isEditModeOn: boolean) {
        const editMode = this.localStore.selectSnapshotByKey("editMode");
        const newEditMode = new Set<string>(editMode.values());

        if (isEditModeOn) {
            newEditMode.add(groupId);
        } else {
            newEditMode.delete(groupId);
        }

        this.localStore.patchState({
            editMode: newEditMode,
        });
    }

    protected isEditModeOff(group: FilesGroup, editMode: string[]): boolean {
        return !editMode.includes(group.groupId);
    }

    protected download(file: FileInGroup) {
        const additionalPathParams = this.localStore.selectSnapshotByKey("additionalPathParams");

        this.fileUploadApi
            .getFile(file.id, additionalPathParams)
            .pipe(
                catchError(() => {
                    this.toastService.error(this.transloco.translate("dtmUi.filesGroups.downloadFileErrorMessage"));

                    return EMPTY;
                }),
                tap((blob) => saveAs(blob, file.name)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected tryRemoveFile(file: FileInGroup, group: FilesGroup) {
        this.confirmUploadedFileRemoval(file)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.removeFile(file, group);
            });
    }

    private getDefaultAddButtonLabel(): string | undefined {
        return this.transloco.translate("dtmUi.filesGroups.upload.addButtonLabel");
    }

    private getDefaultUploadFieldLabel(): string | undefined {
        return this.transloco.translate("dtmUi.filesGroups.upload.uploadFieldLabel");
    }

    private confirmUploadedFileRemoval(file: FileInGroup): Observable<boolean> {
        return this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmUi.filesGroups.upload.confirmFileRemovalDialog.titleText", {
                        fileName: file.name,
                    }),
                    confirmationText: this.transloco.translate("dtmUi.filesGroups.upload.confirmFileRemovalDialog.contentText"),
                    declineButtonLabel: this.transloco.translate("dtmUi.filesGroups.upload.confirmFileRemovalDialog.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmUi.filesGroups.upload.confirmFileRemovalDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }

    private removeFile(file: FileInGroup, fileGroup: FilesGroup) {
        const newValue: FilesGroup[] = [];
        for (const group of this.localStore.selectSnapshotByKey("value") ?? []) {
            if (group.groupId === fileGroup.groupId) {
                newValue.push({
                    ...group,
                    files: group.files.filter((currentFile) => currentFile.id !== file.id),
                });
            } else {
                newValue.push(group);
            }
        }

        this.value = newValue;
        this.propagateChange(newValue ?? []);
    }

    private prepareFileUploadControls(groups: FilesGroup[]): Record<string, FormControl<UploadedFile[]>> {
        const controls: Record<string, FormControl<UploadedFile[]>> = {};
        const currentFileUploadControls = this.localStore.selectSnapshotByKey("fileUploadControls");

        for (const group of groups) {
            if (currentFileUploadControls[group.groupId]) {
                controls[group.groupId] = currentFileUploadControls[group.groupId];
            } else {
                controls[group.groupId] = this.createFormControl(group);
            }

            controls[group.groupId].setValidators([Validators.required, ...this.getAdditionalValidators(group)]);
        }

        return controls;
    }

    private createFormControl(group: FilesGroup): FormControl<UploadedFile[]> {
        const formControl = new FormControl<UploadedFile[]>([], {
            nonNullable: true,
        });

        formControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                tap(() => {
                    if (!formControl.valid && formControl.errors?.maxlength) {
                        formControl.setValue([], { emitEvent: false });
                    }
                }),
                filter(() => formControl.valid),
                RxjsUtils.filterFalsy(),
                distinctUntilChanged(),
                untilDestroyed(this)
            )
            .subscribe((uploadedFiles) => {
                this.setUploadedFiles(uploadedFiles, group.groupId);
                this.setEditMode(group.groupId, false);
                formControl.setValue([], { emitEvent: false });
            });

        return formControl;
    }

    private getAdditionalValidators(group: FilesGroup): ValidatorFn[] {
        const additionalValidators: ValidatorFn[] = [];

        if (group.validators?.singleFile) {
            additionalValidators.push(Validators.maxLength(1));
        }

        return additionalValidators;
    }

    private setUploadedFiles(files: UploadedFile[], groupId: string) {
        const newValue: FilesGroup[] = [];

        for (const group of this.localStore.selectSnapshotByKey("value") ?? []) {
            if (group.groupId === groupId) {
                newValue.push({
                    ...group,
                    files: [
                        ...group.files,
                        ...files.map((file) => ({
                            id: file.id,
                            name: file.name,
                            size: file.size,
                            isRemovable: true,
                        })),
                    ],
                });
            } else {
                newValue.push(group);
            }
        }

        this.value = newValue;
        this.propagateChange(newValue ?? []);
    }
}
