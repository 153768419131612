import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { SharedToastModule } from "@dtm-frontend/shared/ui/toast";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedMissionModule } from "../shared";
import { MissionPreviewContainerComponent } from "./components/mission-search-preview/mission-preview-container.component";
import { FilterChipsComponent } from "./components/mission-search/filter-chips/filter-chips.component";
import { FilterAreaMapComponent } from "./components/mission-search/filter-conditions/filter-area-map/filter-area-map.component";
import { FilterConditionsComponent } from "./components/mission-search/filter-conditions/filter-conditions.component";
import { FilterModalComponent } from "./components/mission-search/filter-modal/filter-modal.component";
import { ListContainerComponent } from "./components/mission-search/list-container/list-container.component";
import { MissionItemComponent } from "./components/mission-search/mission-item/mission-item.component";
import { MissionSearchContainerComponent } from "./components/mission-search/mission-search-container.component";
import { MissionSearchComponent } from "./components/mission-search/mission-search/mission-search.component";
import { ResultMapComponent } from "./components/mission-search/result-map/result-map.component";
import { MissionSearchApiService } from "./services/mission-search-api.service";
import { MissionSearchState } from "./state/mission-search.state";

@NgModule({
    declarations: [
        MissionSearchComponent,
        ListContainerComponent,
        MissionItemComponent,
        FilterModalComponent,
        FilterConditionsComponent,
        FilterChipsComponent,
        FilterAreaMapComponent,
        ResultMapComponent,
        MissionSearchContainerComponent,
        MissionPreviewContainerComponent,
    ],
    exports: [
        MissionSearchComponent,
        ListContainerComponent,
        MissionItemComponent,
        FilterConditionsComponent,
        FilterChipsComponent,
        ResultMapComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmSharedMissionSearch",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    imports: [
        CommonModule,
        SharedI18nModule,
        LetModule,
        PushModule,
        SharedUiModule,
        SharedMissionModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatCardModule,
        MatDialogModule,
        MatDatepickerModule,
        MatInputModule,
        MatChipsModule,
        CesiumMapModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        NgxsModule.forFeature([MissionSearchState]),
        SharedToastModule.forRoot(),
    ],
})
export class MissionSearchModule {
    public static forRoot(): ModuleWithProviders<MissionSearchModule> {
        return {
            ngModule: MissionSearchModule,
            providers: [
                {
                    provide: MissionSearchApiService,
                    useClass: MissionSearchApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<MissionSearchModule> {
        return {
            ngModule: MissionSearchModule,
            providers: [
                {
                    provide: MissionSearchApiService,
                    useValue: null,
                },
            ],
        };
    }
}
