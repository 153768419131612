import { CylinderEntity } from "../services/entity-editors/map-entities-editor.service";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export function areCylinderEntitiesPositionsEqual(left: CylinderEntity, right: CylinderEntity): boolean {
    if (!Cesium.Cartesian3.equalsEpsilon(left.center, right.center, Cesium.Math.EPSILON6)) {
        return false;
    }

    if (left.radius !== right.radius) {
        return false;
    }

    return true;
}
