import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function isNotSameValueValidator(value: any, propertyName?: string): ValidatorFn {
    return function (control: AbstractControl): ValidationErrors | null {
        let controlValue;
        if (propertyName) {
            controlValue = control.value[propertyName];
        } else {
            controlValue = control.value;
        }

        return controlValue === value ? { sameValue: true } : null;
    };
}
