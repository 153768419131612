import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatLegacyCheckbox as MatCheckbox } from "@angular/material/legacy-checkbox";

@Component({
    selector: "dtm-ui-checkbox-field",
    templateUrl: "./checkbox-field.component.html",
    styleUrls: ["./checkbox-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        "[class.mat-checkbox]": "false",
        "[class.mat-checkbox-indeterminate]": "false",
        "[class.mat-checkbox-checked]": "false",
        "[class.mat-checkbox-disabled]": "false",
        "[class.mat-checkbox-label-before]": "false",
        "[class.checkbox-field-checked]": "checked",
        "[class.checkbox-field-disabled]": "disabled",
        "[class.checkbox-field-indeterminate]": "indeterminate",
        "[class.mat-primary]": "false",
        "[class.mat-accent]": "false",
        "[class.mat-warn]": "false",
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxFieldComponent),
            multi: true,
        },
    ],
})
export class CheckboxFieldComponent extends MatCheckbox {}
