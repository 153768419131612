<ng-container *ngrxLet="{ slides: slides$, currentSlide: currentSlide$ } as vm">
    <div class="slider">
        <ol [style.left.%]="vm.currentSlide * -100" class="slides">
            <li *ngFor="let slide of vm.slides; let slideIndex = index" class="slide" [class.show]="vm.currentSlide === slideIndex">
                <ng-container [ngTemplateOutlet]="slide.template"></ng-container>
            </li>
        </ol>
    </div>
    <div class="bottom-bar" *ngIf="vm.slides.length">
        <p>
            {{
                "dtmUi.carousel.slideOfSlidesLabel"
                    | transloco : { currentSlideNumber: vm.currentSlide + 1, slidesLength: vm.slides.length }
            }}
        </p>
        <div class="actions">
            <button
                type="button"
                (click)="previousSlide()"
                class="button-icon"
                [attr.aria-label]="'dtmUi.carousel.previousSlideAriaLabel' | transloco"
            >
                <dtm-ui-icon name="arrow-left"></dtm-ui-icon>
            </button>
            <button
                type="button"
                (click)="nextSlide()"
                class="button-icon"
                [attr.aria-label]="'dtmUi.carousel.nextSlideAriaLabel' | transloco"
            >
                <dtm-ui-icon name="arrow-right"></dtm-ui-icon>
            </button>
        </div>
    </div>
</ng-container>
