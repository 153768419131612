import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";

/**
 * The `dtmUiScrollHook` directive is used to mark the elements to which we want to be able to scroll.
 * The directive instance has `scrollTo` method which may be used to scroll page to the element.
 *
 * We can use it in one of two ways:
 *
 * 1. via Output
 *
 *      HTML:
 *          <div (dtmUiScrollHook)="setScrollHook($event)"> ... </div>
 *
 *      TS:
 *          public setScrollHook(hook: ScrollHookDirective) {
 *              this.hook = hook;
 *          }
 *
 *          public scrollToHook() {
 *              this.hook.scrollTo();
 *          }
 *
 *
 * 2. via ViewChild
 *
 *      HTML:
 *          <div dtmUiScrollHook> ... </div>
 *
 *      TS:
 *          @ViewChild(ScrollHookDirective) hook!: ScrollHookDirective;
 *
 *          public scrollToHook() {
 *              this.hook.scrollTo();
 *          }
 */

@Directive({
    selector: "[dtmUiScrollHook]",
})
export class ScrollHookDirective implements OnInit {
    @Input("dtmUiScrollHookOptions") public options: ScrollIntoViewOptions = {
        behavior: "smooth",
        block: "center",
    };
    @Output("dtmUiScrollHook") public initialize = new EventEmitter<ScrollHookDirective>();

    constructor(private readonly element: ElementRef) {}

    public ngOnInit(): void {
        this.initialize.emit(this);
    }

    public scrollTo() {
        setTimeout(() => this.element.nativeElement.scrollIntoView(this.options));
    }

    public getElement(): HTMLElement {
        return this.element.nativeElement;
    }
}
