import { AirspaceElementsInfo, GeoZoneType } from "@dtm-frontend/shared/map/geo-zones";
import { MissionPlanRoute, WaypointWithSection } from "@dtm-frontend/shared/ui";
import { ArrayUtils, DateUtils, MILLISECONDS_IN_SECOND } from "@dtm-frontend/shared/utils";
import { PartitionedZones } from "../models/mission-plan-verification.models";

const ADDITIONAL_ZONES_TYPES: GeoZoneType[] = [GeoZoneType.DroneAirspaceInformation, GeoZoneType.Local];

export function convertRouteToWaypoints(route: MissionPlanRoute): WaypointWithSection[] {
    return route.sections.reduce<WaypointWithSection[]>((result, section, sectionIndex) => {
        if (section.flightZone) {
            const estimateArrivalTime = new Date(section.flightZone.center.estimatedArriveAt.max).getTime();
            const durationTime = (DateUtils.convertISO8601DurationToSeconds(section.flightZone.stopover.max) ?? 0) * MILLISECONDS_IN_SECOND;

            const estimatedArriveAtMax = new Date(estimateArrivalTime + durationTime);

            result.push({
                waypoint: {
                    ...section.flightZone.center,
                    estimatedArriveAt: {
                        min: section.flightZone.center.estimatedArriveAt.min,
                        max: estimatedArriveAtMax,
                    },
                },
                parentSection: section,
            });
        } else if (section.segment) {
            if (sectionIndex === 0 || route.sections[sectionIndex - 1].flightZone) {
                result.push({
                    waypoint: section.segment.fromWaypoint,
                    parentSection: section,
                });
            }
            result.push({
                waypoint: section.segment.toWaypoint,
                parentSection: section,
            });
        }

        return result;
    }, []);
}

export function getTimeRangeFromWaypointsWithSection(waypoints: WaypointWithSection[]) {
    const { min } = waypoints[0].waypoint.estimatedArriveAt;
    const { max } = waypoints[waypoints.length - 1].waypoint.estimatedArriveAt;

    return {
        min,
        max,
    };
}

export function partitionCollisionZones(zones?: AirspaceElementsInfo): PartitionedZones {
    const [collisionZones, additionalZones] = ArrayUtils.partition(
        zones?.airspaceElements ?? [],
        (zone) => !ADDITIONAL_ZONES_TYPES.includes(zone.geoZoneType)
    );

    return {
        doesAupExist: !!zones?.doesAupExist,
        collisionZones: collisionZones,
        additionalZones: additionalZones,
        airacEndTime: zones?.airacEndTime,
    };
}
