import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import {
    AIR_RISK_MITIGATIONS_CATEGORIES,
    AirRisk,
    AirRiskMitigations,
    AirRiskMitigationsArc,
    AirRiskMitigationsCategory,
} from "../../models/mission.models";

interface OperationalAreaAirspaceRisk {
    category: AirRiskMitigationsCategory;
    initialArc: AirRiskMitigationsArc;
    designator: string;
}

interface OperationalAreaAirspaceRiskComponentState {
    airRiskMitigations: AirRiskMitigations | undefined;
    airspaceRisks: OperationalAreaAirspaceRisk[];
    isExpanded: boolean;
}

@Component({
    selector: "dtm-mission-operational-area-airspace-risk[airRiskMitigations][airRisk]",
    templateUrl: "./operational-area-airspace-risk.component.html",
    styleUrls: ["./operational-area-airspace-risk.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperationalAreaAirspaceRiskComponent {
    @Input() public set airRiskMitigations(value: AirRiskMitigations | undefined) {
        this.localStore.patchState({ airRiskMitigations: value });
    }

    @Input() public set airRisk(value: AirRisk | undefined) {
        this.setAirspaceRisk(value);
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly airspaceRisk$ = this.localStore.selectByKey("airspaceRisks");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly displayedColumns = ["status", "initialArc"];

    constructor(private readonly localStore: LocalComponentStore<OperationalAreaAirspaceRiskComponentState>) {
        this.localStore.setState({
            airRiskMitigations: undefined,
            airspaceRisks: [],
            isExpanded: true,
        });
    }

    private setAirspaceRisk(airRisk: AirRisk | undefined): void {
        const elements = airRisk?.elements ?? [];
        const initialArcMap = new Map<AirRiskMitigationsCategory, AirRiskMitigationsArc>();
        const designatorArcMap = new Map<AirRiskMitigationsCategory, string[]>();

        elements
            .filter(
                ({ designator, category }) =>
                    (!!designator || category === AirRiskMitigationsCategory.Other) && AIR_RISK_MITIGATIONS_CATEGORIES.includes(category)
            )
            .forEach(({ category, initialArc, designator }) => {
                designatorArcMap.set(category, [...(designatorArcMap.get(category) ?? []), designator]);
                initialArcMap.set(category, initialArc);
            });

        if (!initialArcMap.size) {
            initialArcMap.set(AirRiskMitigationsCategory.EmptyAirspace, airRisk?.operationalEmpty?.initialArc ?? AirRiskMitigationsArc.B);
        }

        const airspaceRisks: OperationalAreaAirspaceRisk[] = Array.from(initialArcMap).map(([category, initialArc]) => ({
            initialArc: initialArc ?? AirRiskMitigationsArc.B,
            category,
            designator: [...new Set(designatorArcMap.get(category) ?? [])].join(", "),
        }));

        this.localStore.patchState({ airspaceRisks });
    }
}
