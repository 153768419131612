import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { saveAs } from "file-saver";

interface GenericFileDisplayComponentState {
    file: File | undefined;
    isDownloadable: boolean;
}

@Component({
    selector: "dtm-ui-generic-file-display",
    templateUrl: "./generic-file-display.component.html",
    styleUrls: ["./generic-file-display.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class GenericFileDisplayComponent {
    public readonly file$ = this.localStore.selectByKey("file");
    public readonly isDownloadable$ = this.localStore.selectByKey("isDownloadable");

    @Input() public set file(value: File) {
        this.localStore.patchState({ file: value });
    }
    @Input() public set isDownloadable(value: boolean) {
        this.localStore.patchState({ isDownloadable: value });
    }

    constructor(private readonly localStore: LocalComponentStore<GenericFileDisplayComponentState>) {
        this.localStore.setState({
            file: undefined,
            isDownloadable: false,
        });
    }

    public startFileDownload(file: File) {
        saveAs(file);
    }
}
