import { MissionSegmentStatus } from "../models/route-area.model";

export function mapSegmentStatusToClassConfig(status?: MissionSegmentStatus) {
    switch (status) {
        case MissionSegmentStatus.ACTIVE_SEGMENT:
            return {
                active: true,
            };
        case MissionSegmentStatus.DEACTIVATED_SEGMENT:
            return {
                deactivated: true,
            };
        case MissionSegmentStatus.NOT_ACTIVE_SEGMENT:
        case MissionSegmentStatus.MISSION_ACTIVATED:
            return {
                waiting: true,
            };
        default:
            return {};
    }
}
