<div class="spatial-data-header">
    <h2>{{ "dtmMapCesium.spatialDataTitle" | transloco }}</h2>
</div>
<div class="main-toggle-container">
    <mat-slide-toggle labelPosition="before" class="allow-wrap" [formControl]="allZonesEnabledControl">
        <div class="toggle-container">
            <dtm-ui-icon name="cylinder-line"></dtm-ui-icon>
            {{ "dtmMapCesium.allZonesLabel" | transloco }}
        </div>
    </mat-slide-toggle>
</div>
<p class="field-label">
    {{ "dtmMapCesium.opacityLabel" | transloco }}
    <dtm-ui-popover [popoverText]="'dtmMapCesium.opacityTooltip' | transloco"></dtm-ui-popover>
</p>
<div class="cards opacity" *ngrxLet="selectedOpacity$ as selectedOpacity">
    <div
        class="selectable-tile"
        tabindex="0"
        *ngFor="let opacity of OPACITY_STEPS"
        [class.selected]="selectedOpacity === opacity"
        (click)="selectOpacity(opacity)"
        (keyup.enter)="selectOpacity(opacity)"
    >
        <span class="selectable-tile-label">{{ opacity | percent }}</span>
    </div>
</div>
<div class="opacity-bar">
    <div class="background"></div>
</div>
<form [formGroup]="geographicalZonesFilterSettingsFormGroup">
    <dtm-map-geographical-zones-filters
        [formControl]="geoZonesFiltersFormControl"
        [missionTimeRange]="missionTimeRange$ | ngrxPush"
        [timeSettingsOptions]="timeSettingsOptions$ | ngrxPush"
        [aupEndTime]="aupEndTime$ | ngrxPush"
        (watchZonesSettingUpdate)="watchZonesSettingUpdate.emit($event)"
        (selectedZoneTimeSettingUpdate)="selectedZoneTimeSettingUpdate.emit($event)"
    ></dtm-map-geographical-zones-filters>
    <dtm-map-geographical-zones-settings [formControl]="geoZonesSettingFormControl"></dtm-map-geographical-zones-settings>
    <dtm-map-global-spatial-info-settings></dtm-map-global-spatial-info-settings>
    <dtm-map-local-spatial-info-for-dtm-settings
        [formControl]="localSpatialInfoForDtmSettingsFormControl"
    ></dtm-map-local-spatial-info-for-dtm-settings>
</form>
