<ng-template #contentTemplate><ng-content></ng-content></ng-template>
<ng-template #hintContentTemplate><ng-content select="[hint]"></ng-content></ng-template>

<div class="wrapper">
    <dtm-ui-radio-field *ngIf="!multiple" [checked]="selected" [disabled]="disabled" [name]="parentId">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        <div radioFieldHint *ngTemplateOutlet="hintContentTemplate"></div>
    </dtm-ui-radio-field>
    <dtm-ui-checkbox-field *ngIf="multiple" [checked]="selected" [disabled]="disabled">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        <div checkboxFieldHint *ngTemplateOutlet="hintContentTemplate"></div>
    </dtm-ui-checkbox-field>
</div>
