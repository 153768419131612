import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FlightZoneErrorType } from "@dtm-frontend/dss-shared-lib";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FlightZoneWizardSteps } from "../../../models/flight-zone.models";
import { FlightZoneState } from "../../../state/flight-zone.state";
import { FLIGHT_ZONE_WIZARD_ID } from "../flight-zone-wizard-content/flight-zone-wizard-content.component";

@Component({
    selector: "dss-client-lib-flight-zone-wizard-header",
    templateUrl: "./flight-zone-wizard-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightZoneWizardHeaderComponent {
    public readonly FLIGHT_ZONE_WIZARD_ID: string = FLIGHT_ZONE_WIZARD_ID;
    public readonly FlightZoneWizardSteps = FlightZoneWizardSteps;

    public readonly shouldShowWizardHeader$: Observable<boolean> = this.initShowWizardHeader();

    constructor(private readonly store: Store) {}

    private initShowWizardHeader(): Observable<boolean> {
        return this.store.select(FlightZoneState.error).pipe(map((error) => error?.type !== FlightZoneErrorType.CannotGetCapabilities));
    }
}
