export interface OverlapOptions {
    buffer?: number;
    svgLineSelector?: string;
}

function updateLineConnectors(elements: HTMLElement[], svgLineSelector = "svg line"): void {
    elements
        .map((element) => {
            const styles = getComputedStyle(element);
            const matrix = new DOMMatrix(styles.transform);
            const x = matrix.m41; // NOTE: reading these m41 and m42 properties forces style recalculation
            const y = matrix.m42;

            return { element, x, y };
        })
        .forEach(({ element, x, y }) => {
            const svgLine: SVGLineElement | null | undefined = element.parentElement?.querySelector(svgLineSelector);
            const svgParent = svgLine?.closest("svg");

            if (!svgLine || !svgParent) {
                return;
            }

            const svgCircle = svgParent?.querySelector("circle");

            const viewBoxX = Math.abs(x);
            const viewBoxHeight = Math.max(
                Math.abs((y + element.clientHeight / 2) * 2),
                parseInt(getComputedStyle(svgLine).strokeWidth, 10),
                +(svgCircle?.getAttribute("r") ?? 0) * 2 + (svgCircle ? parseInt(getComputedStyle(svgCircle).strokeWidth, 10) * 2 : 0)
            );

            const viewBoxY = (viewBoxHeight / 2) * -1;

            svgLine.setAttribute("x2", (x + element.clientWidth / 2).toString());
            svgLine.setAttribute("y2", (y + element.clientHeight / 2).toString());

            svgParent.setAttribute("viewBox", `${-1 * viewBoxX} ${viewBoxY} ${Math.abs(viewBoxX) * 2} ${viewBoxHeight}`);
            svgParent.setAttribute("width", (viewBoxX * 2).toString());
            svgParent.setAttribute("height", viewBoxHeight.toString());
        });
}

export function removeOverlapping(elements: HTMLElement[], options?: OverlapOptions) {
    function innerRemoveOverlapping() {
        if (!elements.length) {
            return;
        }

        const { buffer, svgLineSelector: svgLineChildSelector } = { buffer: 0, ...options };

        elements.forEach((element) => (element.style.transform = ""));

        elements.sort((element, nextElement) => {
            const elementRect = element.getBoundingClientRect();
            const nextElementRect = nextElement.getBoundingClientRect();

            return nextElementRect.left - elementRect.left;
        });

        elements.forEach((element, index) => {
            const siblings = elements.slice(index + 1, elements.length);

            siblings.forEach((sibling) => {
                const elementRect = element.getBoundingClientRect();
                const siblingRect = sibling.getBoundingClientRect();

                elementRect.x -= buffer;
                elementRect.y -= buffer;
                elementRect.width += buffer * 2;
                elementRect.height += buffer * 2;

                siblingRect.x -= buffer;
                siblingRect.y -= buffer;
                siblingRect.width += buffer * 2;
                siblingRect.height += buffer * 2;

                if (
                    elementRect.right > siblingRect.left &&
                    elementRect.left < siblingRect.right &&
                    elementRect.bottom > siblingRect.top &&
                    elementRect.top < siblingRect.bottom
                ) {
                    const styles = getComputedStyle(sibling);
                    const matrix = new DOMMatrixReadOnly(styles.transform);
                    const overlapX = elementRect.left - siblingRect.right;

                    const x = matrix.m41 + overlapX + buffer;

                    sibling.style.transform = `translate(${x}px, ${matrix.m42}px)`;
                }
            });
        });

        if (svgLineChildSelector) {
            updateLineConnectors(elements, svgLineChildSelector);
        }
    }

    requestAnimationFrame(innerRemoveOverlapping);
}
