import { Injectable } from "@angular/core";

import listOfCountryNames from "../../../assets/countries/countries.json";

import alpha2to3Map from "../../../assets/countries/countries2to3.json";

import { Alpha2CountryCode, Alpha3CountryCode, CountryListItem } from "../../models/country.models";

@Injectable({
    providedIn: "root",
})
export class DisplayCountryService {
    private readonly countryList = this.getCountryList();

    public getCountryByCountryCode(code: Alpha2CountryCode | Alpha3CountryCode | undefined): CountryListItem | undefined {
        if (!code) {
            return;
        }

        return this.countryList.get(code.toLowerCase());
    }

    private getCountryList(): Map<string, CountryListItem> {
        return Object.entries(listOfCountryNames).reduce((result, [countryCode, countryNamePair]): Map<string, CountryListItem> => {
            const displayName = countryNamePair[1] ? `${countryNamePair[0]} (${countryNamePair[1]})` : countryNamePair[0];

            const item: CountryListItem = {
                alpha2Code: countryCode as Alpha2CountryCode,
                alpha3Code: alpha2to3Map[countryCode as Alpha2CountryCode],
                displayName,
            };

            result.set(item.alpha2Code.toLowerCase(), item);
            result.set(item.alpha3Code.toLowerCase(), item);

            return result;
        }, new Map<string, CountryListItem>());
    }
}
