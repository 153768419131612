<div
    class="tab-container dtm-tabs card-box"
    *ngrxLet="{
        isProcessing: isProcessing$,
        selectedMissionRoute: selectedMissionRoute$,
        isPlanRouteProcessing: isPlanRouteProcessing$,
        selectedTileId: selectedTileId$,
    } as vm"
>
    <mat-tab-group color="accent">
        <mat-tab
            *ngrxLet="waitingMissions$ as waitingMissions"
            [label]="'dssClientLibPlannedMissions.plannedMissionDetails.waitingTabTitle' | transloco : { count: waitingMissions.length }"
        >
            <div class="tab-content">
                <dss-client-lib-mission-filters
                    (filtersChange)="filterMission($event, MissionProcessingPhase.Waiting)"
                ></dss-client-lib-mission-filters>
                <dss-client-lib-phase-description
                    canEdit
                    [selectedMissionRoute]="vm.selectedMissionRoute"
                    [isPlanRouteProcessing]="vm.isPlanRouteProcessing"
                    [selectedTileId]="vm.selectedTileId"
                    [missions]="waitingMissions"
                    (missionSelect)="missionSelect.emit($event)"
                    (missionPhaseChange)="missionPhaseChange.emit($event)"
                ></dss-client-lib-phase-description>
            </div>
        </mat-tab>
        <mat-tab
            *ngrxLet="acceptedMissions$ as acceptedMissions"
            [label]="'dssClientLibPlannedMissions.plannedMissionDetails.approvedTabTitle' | transloco : { count: acceptedMissions.length }"
        >
            <div class="tab-content">
                <dss-client-lib-mission-filters
                    (filtersChange)="filterMission($event, MissionProcessingPhase.Accepted)"
                ></dss-client-lib-mission-filters>
                <dss-client-lib-phase-description
                    [selectedMissionRoute]="vm.selectedMissionRoute"
                    [isPlanRouteProcessing]="vm.isPlanRouteProcessing"
                    [selectedTileId]="vm.selectedTileId"
                    [missions]="acceptedMissions"
                    (missionSelect)="missionSelect.emit($event)"
                ></dss-client-lib-phase-description>
            </div>
        </mat-tab>
        <mat-tab
            *ngrxLet="rejectedMissions$ as rejectedMissions"
            [label]="'dssClientLibPlannedMissions.plannedMissionDetails.rejectedTabTitle' | transloco : { count: rejectedMissions.length }"
        >
            <div class="tab-content">
                <dss-client-lib-mission-filters
                    (filtersChange)="filterMission($event, MissionProcessingPhase.Rejected)"
                ></dss-client-lib-mission-filters>
                <dss-client-lib-phase-description
                    [selectedMissionRoute]="vm.selectedMissionRoute"
                    [isPlanRouteProcessing]="vm.isPlanRouteProcessing"
                    [selectedTileId]="vm.selectedTileId"
                    [missions]="rejectedMissions"
                    (missionSelect)="missionSelect.emit($event)"
                ></dss-client-lib-phase-description>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
