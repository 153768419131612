import { decodeBase64, encodeBase64 } from "./base64-utils";
import { ellipsifyText } from "./ellipsify-text";
import { generateId } from "./generate-id";
import { replaceInTemplate } from "./replace-in-template";

export const StringUtils = {
    generateId,
    replaceInTemplate,
    ellipsifyText,
    encodeBase64,
    decodeBase64,
};
export { EllipsifyTextPipe } from "./ellipsify-text.pipe";
