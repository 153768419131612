import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from "@angular/core";
import { LocalComponentStore, METERS_IN_KILOMETER } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AcMapComponent, Cartesian3 } from "@pansa/ngx-cesium";
import turfCenter from "@turf/center";
import equal from "fast-deep-equal";
import { pairwise, startWith, tap } from "rxjs";
import { AirspaceElement, GeoZoneType, InfoZoneType } from "../../../../geo-zones/models/geo-zones.models";
import { MapUtils } from "../../../index";
import { createCesiumDashPattern } from "../../../utils/create-cesium-dash-pattern";
import { CUSTOM_OPACITY_SETTINGS_FOR_TYPE, GEO_ZONE_STYLES, GeoZonesStyle, LOCAL_INFO_ICON_CODES } from "./geographical-zones-layer.data";

declare const Cesium: any; // TODO: DTM-966

interface GeographicalZonesLayerComponentState {
    zones: AirspaceElement[];
    shouldShow: boolean;
    selectedZoneId: string | undefined;
    globalZoneOpacity: number;
}

interface ZoneData {
    borderColor: string;
    fillColor: string;
    fillImageScale?: typeof Cesium.Cartesian2;
    fillOpacity: number;
    isActive?: boolean;
    isStatic?: boolean;
    isSelected?: boolean;
    isOrdered?: boolean;
    positions: Cartesian3[];
    iconPosition: Cartesian3[];
    toTime?: Date;
    borderWidth: number;
    isLocal?: boolean;
    localInformationType?: InfoZoneType;
}

interface ZoneCollection {
    data: ZoneData;
    fillEntity: typeof Cesium.Entity;
    outlineEntity: typeof Cesium.Entity;
    zoneIconEntity?: typeof Cesium.Entity;
    zoneAccentIconEntity?: typeof Cesium.Entity;
}

const DEFAULT_OUTLINE_DASH_LENGTH = 15;
const DEFAULT_OUTLINE_DASH_PATTERN = createCesiumDashPattern("-----------");
const STRIPE_IMAGE = "assets/images/stripe.png";
const DEFAULT_ZONE_BORDER_WIDTH = 2;
const SELECTED_ZONE_BORDER_WIDTH = 4;
const SELECTED_ZONE_FILL_OPACITY = 0.5;
const CLICKABLE_TRANSPARENT_FILL_OPACITY = 0.01;
// eslint-disable-next-line no-magic-numbers
const ZONE_ACCENT_ICON_OFFSET = new Cesium.Cartesian2(-4, 3);
const MAIN_ICON_COLOR = "#223d6b"; // $color-gray-500
const ACCENT_ICON_COLOR = "#8d99b1"; // $color-gray-200
const ICON_FONT_SIZE = "14px IconFont";
const ZONE_Z_INDEX = 1;
const ZONE_OUTLINE_Z_INDEX = ZONE_Z_INDEX + 1;
const MAX_LOCAL_ZONES_ICON_VISIBLE_DISTANCE_IN_KILOMETERS = 35;
const LOCAL_ZONES_ICON_DISTANCE_CONDITION = new Cesium.DistanceDisplayCondition(
    0,
    MAX_LOCAL_ZONES_ICON_VISIBLE_DISTANCE_IN_KILOMETERS * METERS_IN_KILOMETER
);

@UntilDestroy()
@Component({
    selector: "dtm-map-geographical-zones-layer",
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class GeographicalZonesLayerComponent implements OnDestroy {
    @Input() public set zones(value: AirspaceElement[] | undefined) {
        this.localStore.patchState({ zones: value ?? [] });
    }

    @Input() public set shouldShow(value: BooleanInput) {
        this.updateEntitiesVisibility(coerceBooleanProperty(value));
    }

    @Input() public set selectedZoneId(value: string | undefined) {
        this.localStore.patchState({ selectedZoneId: value });
        this.updateEntities(value);
    }

    @Input() public set globalZoneOpacity(value: number) {
        this.localStore.patchState({ globalZoneOpacity: value });
        this.updateEntitiesOpacity(value);
    }

    protected readonly Cesium = Cesium;

    protected readonly entitiesCollection = new Map<string, ZoneCollection>();
    private dataSource: typeof Cesium.CustomDataSource;

    constructor(
        private readonly localStore: LocalComponentStore<GeographicalZonesLayerComponentState>,
        private readonly acMap: AcMapComponent
    ) {
        localStore.setState({ zones: [], shouldShow: false, selectedZoneId: undefined, globalZoneOpacity: 1 });
        this.watchZoneEntitiesData();
    }

    public ngOnDestroy(): void {
        this.acMap.getCesiumViewer().dataSource?.remove(this.dataSource);
    }

    protected getZoneBorderMaterial(zoneData: ZoneData) {
        if (zoneData.isStatic) {
            return Cesium.Color.fromCssColorString(zoneData.borderColor);
        }

        return new Cesium.PolylineDashMaterialProperty({
            color: Cesium.Color.fromCssColorString(zoneData.borderColor),
            dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
            dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
        });
    }

    protected getZoneFillMaterial(zoneData: ZoneData, opacity: number = 1) {
        if (zoneData.isSelected) {
            return Cesium.Color.fromCssColorString(zoneData.fillColor).withAlpha(SELECTED_ZONE_FILL_OPACITY);
        }

        if (zoneData.isActive) {
            return Cesium.Color.fromCssColorString(zoneData.fillColor).withAlpha(zoneData.fillOpacity * opacity);
        }

        if (zoneData.isOrdered) {
            return new Cesium.ImageMaterialProperty({
                image: STRIPE_IMAGE,
                transparent: true,
                color: Cesium.Color.fromCssColorString(zoneData.fillColor).withAlpha(zoneData.fillOpacity * opacity),
                repeat: zoneData.fillImageScale,
            });
        }

        return Cesium.Color.fromCssColorString(zoneData.fillColor).withAlpha(CLICKABLE_TRANSPARENT_FILL_OPACITY);
    }

    private getGeoZoneEntityStyles({ type, geoZoneType }: AirspaceElement): GeoZonesStyle {
        const customOpacity = Object.entries(CUSTOM_OPACITY_SETTINGS_FOR_TYPE).find(([, types]) => types.includes(type))?.[0];
        const elementStyle = GEO_ZONE_STYLES[geoZoneType] ?? GEO_ZONE_STYLES.Default;

        if (customOpacity !== undefined) {
            return {
                ...elementStyle,
                fillOpacity: +customOpacity,
            };
        }

        return elementStyle;
    }

    private watchZoneEntitiesData() {
        this.dataSource = new Cesium.CustomDataSource();
        this.acMap.getCesiumViewer().dataSources.add(this.dataSource);

        this.localStore
            .selectByKey("zones")
            .pipe(
                startWith(null),
                pairwise(),
                tap(([previous, current]) => {
                    this.createOrUpdateEntities(previous, current);
                    this.deleteEntities(previous, current);
                }),
                tap(() => this.acMap.getCesiumService().getScene().requestRender())
            )
            .pipe(untilDestroyed(this))
            .subscribe();
    }

    private createOrUpdateEntities(previous: AirspaceElement[] | null, current: AirspaceElement[] | null) {
        return current?.map((item) => {
            const previousItem = previous?.find(({ id }) => id === item.id);

            if (previous && !previousItem?.isSelected && equal(previousItem, item)) {
                return;
            }

            const isSelected = item.id === this.localStore.selectSnapshotByKey("selectedZoneId");

            const { borderColor, fillColor, fillOpacity } = this.getGeoZoneEntityStyles(item);
            const center = turfCenter(item.geometry);

            const zoneData: ZoneData = {
                borderColor,
                fillColor,
                fillImageScale: MapUtils.getCesiumImageScaleForGeometry(item.geometry),
                fillOpacity,
                isActive: item.isActive,
                isStatic: item.isStatic,
                isOrdered: item.isOrdered,
                positions: Cesium.Cartesian3.fromDegreesArray(item.geometry.coordinates.flat(2)),
                iconPosition: Cesium.Cartesian3.fromDegrees(center.geometry.coordinates[0], center.geometry.coordinates[1]),
                borderWidth: isSelected ? SELECTED_ZONE_BORDER_WIDTH : DEFAULT_ZONE_BORDER_WIDTH,
                isSelected,
                isLocal: item.geoZoneType === GeoZoneType.Local,
                localInformationType: item.zoneAttributes?.localInformationType,
            };
            const opacity = this.localStore.selectSnapshotByKey("globalZoneOpacity");

            const entities = this.dataSource.entities;

            const zoneFillEntity = entities.getOrCreateEntity(item.id);
            zoneFillEntity.polygon = {
                hierarchy: new Cesium.PolygonHierarchy(zoneData.positions),
                material: this.getZoneFillMaterial(zoneData, opacity),
                outline: true,
                outlineColor: this.getZoneBorderMaterial(zoneData),
                outlineWidth: zoneData.borderWidth,
                zIndex: ZONE_Z_INDEX,
            };

            zoneFillEntity.addProperty("designator");
            zoneFillEntity.designator = item.designator;

            const zoneOutlineEntity = entities.getOrCreateEntity(`${item.id}-zone-outline`);
            zoneOutlineEntity.polyline = {
                positions: zoneData.positions,
                material: this.getZoneBorderMaterial(zoneData),
                width: zoneData.borderWidth,
                clampToGround: true,
                zIndex: ZONE_OUTLINE_Z_INDEX,
            };

            const zoneCollection: ZoneCollection = {
                data: zoneData,
                fillEntity: zoneFillEntity,
                outlineEntity: zoneOutlineEntity,
            };
            const iconsData = this.addIconEntities(item, entities, zoneData);
            zoneCollection.zoneIconEntity = iconsData?.zoneIconEntity;
            zoneCollection.zoneAccentIconEntity = iconsData?.zoneAccentIconEntity;

            this.entitiesCollection.set(item.id, zoneCollection);
        });
    }

    private addIconEntities(item: AirspaceElement, entities: typeof Cesium.EntityCollection, zoneData: ZoneData) {
        if (item.geoZoneType !== GeoZoneType.Local) {
            return;
        }
        const zoneIconEntity = entities.getOrCreateEntity(`${item.id}-zone-icon`);
        zoneIconEntity.position = zoneData.iconPosition;
        zoneIconEntity.label = {
            text: this.getIcon(zoneData, 0),
            scale: 2,
            font: ICON_FONT_SIZE,
            fillColor: Cesium.Color.fromCssColorString(MAIN_ICON_COLOR),
            outlineColor: Cesium.Color.WHITE,
            distanceDisplayCondition: LOCAL_ZONES_ICON_DISTANCE_CONDITION,
        };
        const accentIcon = this.getIcon(zoneData, 1);

        let zoneAccentIconEntity;

        if (accentIcon) {
            zoneAccentIconEntity = entities.getOrCreateEntity(`${item.id}-zone-accent-icon`);
            zoneAccentIconEntity.position = zoneData.iconPosition;
            zoneAccentIconEntity.label = {
                position: zoneData.iconPosition,
                text: this.getIcon(zoneData, 1),
                scale: 2,
                font: ICON_FONT_SIZE,
                fillColor: Cesium.Color.fromCssColorString(ACCENT_ICON_COLOR),
                outlineColor: Cesium.Color.WHITE,
                pixelOffset: ZONE_ACCENT_ICON_OFFSET,
                distanceDisplayCondition: LOCAL_ZONES_ICON_DISTANCE_CONDITION,
            };
        }

        return { zoneIconEntity, zoneAccentIconEntity };
    }

    protected getIcon(zone: ZoneData, index: number) {
        if (zone.isLocal && zone.localInformationType) {
            return String.fromCharCode(parseInt(LOCAL_INFO_ICON_CODES[zone.localInformationType][index], 16));
        }

        return undefined;
    }

    private deleteEntities(previousElements: AirspaceElement[] | null, newElements: AirspaceElement[] | null) {
        previousElements
            ?.filter((previousItem) => !newElements?.some(({ id }) => previousItem.id === id))
            .forEach(({ id }) => {
                const entityData = this.entitiesCollection.get(id);

                if (!entityData) {
                    return;
                }

                const { data, ...entities } = entityData;

                Object.values(entities).forEach((entity) => this.dataSource.entities.remove(entity));
                this.entitiesCollection.delete(id);
            });
    }

    private updateEntities(id: string | undefined) {
        const opacity = this.localStore.selectSnapshotByKey("globalZoneOpacity");
        this.entitiesCollection.forEach(({ data, fillEntity, outlineEntity }, key) => {
            const isSelected = key === id;
            const shouldUpdate = isSelected || data.isSelected;
            if (!shouldUpdate) {
                return;
            }

            data.isSelected = isSelected;
            data.borderWidth = isSelected ? SELECTED_ZONE_BORDER_WIDTH : DEFAULT_ZONE_BORDER_WIDTH;

            fillEntity.polygon.material = this.getZoneFillMaterial(data, opacity);
            fillEntity.polygon.zIndex = isSelected ? ZONE_OUTLINE_Z_INDEX + 1 : ZONE_Z_INDEX;
            outlineEntity.polyline.width = data.borderWidth;
            outlineEntity.polyline.zIndex = isSelected ? ZONE_OUTLINE_Z_INDEX + 2 : ZONE_OUTLINE_Z_INDEX;
        });

        this.acMap.getCesiumService().getScene().requestRender();
    }

    private updateEntitiesOpacity(opacity: number = 1) {
        this.entitiesCollection.forEach(({ data, fillEntity }) => {
            fillEntity.polygon.material = this.getZoneFillMaterial(data, opacity);
        });
        this.acMap.getCesiumService().getScene().requestRender();
    }

    private updateEntitiesVisibility(shouldShow: boolean) {
        this.dataSource.show = shouldShow;
        this.acMap.getCesiumService().getScene().requestRender();
    }
}
