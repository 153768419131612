import { ContentObserver } from "@angular/cdk/observers";
import { isPlatformBrowser } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    Output,
    PLATFORM_ID,
    ViewChild,
} from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

interface FormErrorsComponentState {
    hasErrors: boolean;
    errorId: string | null;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-form-errors",
    templateUrl: "./form-errors.component.html",
    styleUrls: ["./form-errors.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FormErrorsComponent implements AfterViewInit {
    private static ID_COUNTER = 0;
    @ViewChild("errorsContainer") private errorsContainer!: ElementRef;

    @Output() public hasErrors = new EventEmitter<boolean>();
    @Output() public errorIdChange = this.localStore.selectByKey("errorId");
    @Input() public set formInputId(value: string | undefined) {
        this.localStore.patchState({ errorId: value || null });
    }

    protected readonly hasErrors$ = this.localStore.selectByKey("hasErrors");
    protected readonly errorId$ = this.localStore.selectByKey("errorId");

    constructor(
        private readonly contentObserver: ContentObserver,
        private readonly localStore: LocalComponentStore<FormErrorsComponentState>,
        private readonly cdr: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private platformId: string
    ) {
        this.localStore.setState({
            hasErrors: false,
            errorId: this.generateId(),
        });
    }

    public ngAfterViewInit() {
        this.checkErrorsContent();

        this.contentObserver
            .observe(this.errorsContainer.nativeElement)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.checkErrorsContent();
            });
    }

    private checkErrorsContent(): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        const hasErrors = !!this.errorsContainer.nativeElement.innerText.trim().length;

        this.localStore.patchState({ hasErrors });
        this.hasErrors.emit(hasErrors);

        // TODO REJ-889 check if its possible to work without detect changes.
        this.cdr.detectChanges();
    }

    private generateId(): string {
        return `form-field-error-description-${FormErrorsComponent.ID_COUNTER++}`;
    }
}
