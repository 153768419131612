import { CommonModule, DOCUMENT } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, InjectionToken, ModuleWithProviders, NgModule, Optional } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ObjectUtils } from "@dtm-frontend/shared/utils";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { KeycloakBearerInterceptor, KeycloakEventType, KeycloakOptions, KeycloakService } from "keycloak-angular";
import { KeycloakConfig } from "keycloak-js";
import { DtmAuthGuard } from "./guards/dtm-auth.guard";
import { DtmRoleGuard } from "./guards/dtm-role.guard";
import { AuthService } from "./services/auth.service";
import { KeycloakAuthService } from "./services/keycloak-auth.service";
import { AuthState } from "./state/auth.state";

export interface EnvironmentKeycloakConfig {
    clientId: string;
    realm: string;
    url: string;
}
export const KEYCLOAK_CONFIG = new InjectionToken<EnvironmentKeycloakConfig>("KEYCLOAK_CONFIG");
const TOKEN_UPDATE_INTERVAL_MINUTES = 30;

function initializeKeycloak(config: KeycloakOptions, keycloak: KeycloakService, document: Document) {
    return () => {
        keycloak.keycloakEvents$.subscribe((event) => {
            if (event.type === KeycloakEventType.OnTokenExpired) {
                // TODO: how often it should be done?
                keycloak.updateToken(TOKEN_UPDATE_INTERVAL_MINUTES);
            }
        });

        return keycloak.init({
            initOptions: {
                onLoad: "check-sso",
                // TODO: pass this in options (possible base URI issue)
                silentCheckSsoRedirectUri: document.defaultView?.location.origin + "/assets/sso/silent-check-sso.html",
            },
            ...config,
        });
    };
}

export type SharedAuthModuleConfig = Omit<KeycloakOptions, "config"> & { config?: KeycloakConfig };

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        NgxsModule.forFeature([AuthState]),
        LetModule,
        PushModule,
        HttpClientModule,
    ],
    providers: [
        DtmAuthGuard,
        DtmRoleGuard,
        {
            provide: JwtHelperService,
            useValue: new JwtHelperService(),
        },
        {
            provide: AuthService,
            useClass: KeycloakAuthService,
        },
    ],
})
export class SharedAuthModule {
    public static forRoot(authModuleConfiguration: SharedAuthModuleConfig): ModuleWithProviders<SharedAuthModule> {
        return {
            ngModule: SharedAuthModule,
            providers: [
                {
                    provide: APP_INITIALIZER,
                    useFactory: (service: KeycloakService, document: Document, injectedConfig?: EnvironmentKeycloakConfig) => {
                        const config = {
                            url: "",
                            realm: "",
                            clientId: "",
                            ...authModuleConfiguration.config,
                            ...ObjectUtils.removeNullishProperties(injectedConfig ?? {}),
                        };

                        if (!config.clientId || !config.realm || !config.url) {
                            throw new Error("Keycloak configuration is not provided");
                        }

                        return initializeKeycloak(
                            {
                                ...authModuleConfiguration,
                                config,
                            },
                            service,
                            document
                        );
                    },
                    multi: true,
                    deps: [KeycloakService, DOCUMENT, [new Optional(), KEYCLOAK_CONFIG]],
                },
                KeycloakService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: KeycloakBearerInterceptor,
                    multi: true,
                },
            ],
        };
    }
}
