import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Inject, Input, Optional, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { BehaviorSubject, combineLatest, map } from "rxjs";
import { GeoZonesActions } from "../../../geo-zones/state/geo-zones.actions";
import { GeoZonesState } from "../../../geo-zones/state/geo-zones.state";
import { GeolocationService } from "../../../shared/services/geolocation.service";
import { CameraHelperService } from "../../services/camera-helper.service";

const GEOLOCATION_ZOOM_SCALE = 2;

interface MapControlsComponentState {
    isMeasureToolActive: boolean;
    isMeasureToolEnabled: boolean;
}

@Component({
    selector: "dtm-map-controls",
    templateUrl: "./map-controls.component.html",
    styleUrls: ["./map-controls.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MapControlsComponent {
    @Output() public readonly measureToolStatusChange = this.localStore.selectByKey("isMeasureToolActive");
    @Input() public set isMeasureToolActive(value: BooleanInput) {
        this.localStore.patchState({ isMeasureToolActive: coerceBooleanProperty(value) });
    }
    @Input() public set isMeasureToolEnabled(value: BooleanInput) {
        const measureToolEnabled = coerceBooleanProperty(value);
        this.localStore.patchState({ isMeasureToolEnabled: measureToolEnabled });

        if (!measureToolEnabled) {
            this.localStore.patchState({ isMeasureToolActive: false });
        }
    }

    protected readonly canGetGeolocationSubject = new BehaviorSubject<boolean>(false);
    protected readonly isGeoZonesInfoEnabled$ = this.store.select(GeoZonesState.isGeoZonesInfoEnabled);
    protected readonly isGeoZonesInfoAvailable$ = combineLatest([
        this.store.select(GeoZonesState.geoZonesLayersData),
        this.store.select(GeoZonesState.customZonesLayersData),
    ]).pipe(
        map(([geoZonesLayersData, customZonesLayersData]) => geoZonesLayersData?.length || customZonesLayersData?.airspaceElements.length)
    );
    protected readonly isDefaultView$ = this.cameraHelperService.isDefaultView$;
    protected readonly isMeasureToolActive$ = this.localStore.selectByKey("isMeasureToolActive");
    protected readonly isMeasureToolEnabled$ = this.localStore.selectByKey("isMeasureToolEnabled");

    constructor(
        private readonly cameraHelperService: CameraHelperService,
        @Optional() @Inject(GeolocationService) protected readonly geolocationService: GeolocationService | undefined,
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<MapControlsComponentState>
    ) {
        localStore.setState({ isMeasureToolActive: false, isMeasureToolEnabled: false });

        this.geolocationService
            ?.canTryToGetUserPosition()
            .then((canGetGeolocation) => this.canGetGeolocationSubject.next(canGetGeolocation));
    }

    protected zoomIn() {
        this.cameraHelperService.zoom(-1);
    }

    protected zoomOut() {
        this.cameraHelperService.zoom(1);
    }

    protected async geolocate() {
        try {
            const position = await this.geolocationService?.getUserPosition();
            if (position) {
                this.cameraHelperService.flyToPoint(position.latitude, position.longitude, GEOLOCATION_ZOOM_SCALE);
            }
        } catch {
            this.canGetGeolocationSubject.next(false);
        }
    }

    protected toggleGeoZoneInfo() {
        if (this.localStore.selectSnapshotByKey("isMeasureToolActive")) {
            this.localStore.patchState({ isMeasureToolActive: false });
        }
        this.store.dispatch(GeoZonesActions.ToggleGeoZonesInfo);
    }

    protected setDefaultCamera() {
        this.cameraHelperService.setDefaultCamera();
    }

    protected toggleMeasure() {
        if (this.store.selectSnapshot(GeoZonesState.isGeoZonesInfoEnabled)) {
            this.store.dispatch(GeoZonesActions.ToggleGeoZonesInfo);
        }

        this.localStore.patchState({ isMeasureToolActive: !this.localStore.selectSnapshotByKey("isMeasureToolActive") });
    }
}
