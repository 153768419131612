<dtm-ui-expandable-panel [isExpanded]="isExpanded$ | ngrxPush" [hasHeaderSeparator]="false">
    <ng-content select="[headerSlot]" ngProjectAs="[headerSlot]"></ng-content>

    <div *ngIf="mission$ | ngrxPush as mission; else emptyDataTemplate" class="mission-data-container">
        <ng-container
            *ngTemplateOutlet="
                mission.phase === MissionProcessingPhase.CaaPermitApplication || (mission.category | invoke : isSoraApplication)
                    ? caaPermitTemplate
                    : defaultTemplate
            "
        ></ng-container>
        <ng-template #caaPermitTemplate>
            <div class="mission-field">
                <span class="field-label">{{ "dtmSharedMission.missionDetails.requestedPeriodLabel" | transloco }}</span>
                <span>
                    {{ "dtmSharedMission.missionDetails.missionDateRangeDays" | transloco : { days: mission | invoke : getDaysBetween } }}
                </span>
            </div>
        </ng-template>
        <ng-template #defaultTemplate>
            <div class="mission-field">
                <span class="field-label">{{ "dtmSharedMission.missionDetails.plannedStartTimeLabel" | transloco }}</span>
                <span *ngIf="mission.isRoutePathBased">
                    {{ mission.flightStartAtMin | localizeDate : { timeStyle: "short" } }}
                    -
                    {{ mission.flightStartAtMax | localizeDate : { timeStyle: "short" } }}
                </span>
                <span *ngIf="!mission.isRoutePathBased">
                    {{
                        "dtmSharedMission.missionDetails.plannedStartTimeValueLabel"
                            | transloco : { value: (mission.flightStartAtMin | localizeDate : { timeStyle: "short" }) }
                    }}
                </span>
            </div>
            <div class="mission-field">
                <span class="field-label">{{ "dtmSharedMission.missionDetails.plannedLandingTimeLabel" | transloco }}</span>
                <span *ngIf="mission.isRoutePathBased">
                    {{ mission.flightFinishAtMin | localizeDate : { timeStyle: "short" } }}
                    -
                    {{ mission.flightFinishAtMax | localizeDate : { timeStyle: "short" } }}
                </span>
                <span *ngIf="!mission.isRoutePathBased">
                    {{
                        "dtmSharedMission.missionDetails.plannedLandingTimeValueLabel"
                            | transloco : { value: (mission.flightFinishAtMax | localizeDate : { timeStyle: "short" }) }
                    }}
                </span>
            </div>
            <div class="mission-field">
                <span class="field-label">{{ "dtmSharedMission.missionDetails.fullDurationLabel" | transloco }}</span>
                <span>
                    <dtm-ui-time-diff-display
                        [firstDate]="mission.flightStartAtMin"
                        [secondDate]="mission.flightFinishAtMax"
                    ></dtm-ui-time-diff-display>
                </span>
            </div>
        </ng-template>
        <div class="mission-field">
            <span class="field-label">{{ "dtmSharedMission.missionDetails.distanceLabel" | transloco }}</span>
            <span *ngIf="mission.isRoutePathBased">
                {{
                    mission.distance === undefined
                        ? "-"
                        : ("dtmSharedMission.missionDetails.distanceValueInKilometers"
                          | transloco : { value: mission.distance | convertToKilometers | localizeNumber })
                }}
            </span>
            <span *ngIf="!mission.isRoutePathBased">-</span>
        </div>
        <div class="mission-field">
            <span class="field-label">{{ "dtmSharedMission.missionDetails.operatorLabel" | transloco }}</span>
            <span>{{ mission.operatorName ?? "-" }}</span>
        </div>
        <div class="mission-field">
            <span class="field-label">{{ "dtmSharedMission.missionDetails.pilotLabel" | transloco }}</span>
            <span>{{ mission.pilotName ?? "-" }}</span>
        </div>
        <div class="mission-field">
            <span class="field-label">{{ "dtmSharedMission.missionDetails.uavLabel" | transloco }}</span>
            <span>{{ mission.uavName ?? "-" }}</span>
        </div>
        <div class="mission-field">
            <span class="field-label">{{ "dtmSharedMission.missionDetails.uavSerialNumberLabel" | transloco }}</span>
            <span [matTooltip]="mission.uavSerialNumbers | join" [matTooltipDisabled]="mission.uavSerialNumbers.length < 2">
                <ng-container *ngIf="mission.uavSerialNumbers.length == 1">
                    {{ mission.uavSerialNumbers[0] }}
                </ng-container>
                <ng-container *ngIf="mission.uavSerialNumbers.length > 1">
                    {{ mission.uavSerialNumbers[0] }}, <span class="more-items">+{{ mission.uavSerialNumbers.length - 1 }}</span>
                </ng-container>
            </span>
        </div>
        <div class="mission-field">
            <span class="field-label">{{ "dtmSharedMission.missionDetails.trackerLabel" | transloco }}</span>
            <span>{{ mission.trackersIdentifiers | join : ", " : "-" }}</span>
        </div>
        <div class="mission-field">
            <span class="field-label">
                {{ "dtmSharedMission.missionDetails.authorizationBasis.label" | transloco }}
            </span>
            <ng-container *ngIf="mission.category | invoke : getOpenCategory as openCategory">
                <span>
                    {{ "dtmSharedMission.missionDetails.authorizationBasis.openCategoryLabel" | transloco }}
                    <span *ngIf="openCategory.scenarioName">- {{ openCategory.scenarioName }}</span>
                </span>
            </ng-container>
            <ng-container *ngIf="mission.category | invoke : getSpecificCategory as specificCategory">
                <span>
                    {{ "dtmSharedMission.missionDetails.authorizationBasis.specificPermitLabel" | transloco }}
                    <span [ngSwitch]="specificCategory.specificPermitType">
                        <ng-container *ngSwitchCase="MissionPlanSpecificPermitType.Sts">
                            - {{ (mission.category | invoke : getAsStsCategoryType)?.scenarioName }}
                        </ng-container>
                        <ng-container *ngSwitchCase="MissionPlanSpecificPermitType.Luc">
                            - {{ "dtmSharedMission.missionDetails.authorizationBasis.lucLabel" | transloco }}
                        </ng-container>
                        <ng-container *ngSwitchCase="MissionPlanSpecificPermitType.Individual">
                            - {{ "dtmSharedMission.missionDetails.authorizationBasis.individualLabel" | transloco }}
                        </ng-container>
                    </span>
                </span>
            </ng-container>
        </div>
        <div class="mission-field">
            <span class="field-label">{{ "dtmSharedMission.missionDetails.purposeLabel" | transloco }}</span>
            <span>
                {{ (mission.flightPurpose.nameTranslationKey | systemTranslation) || "-" }}
            </span>
        </div>
        <div class="mission-field mission-field-full-column">
            <span class="field-label">{{ "dtmSharedMission.missionDetails.flightPurposeDescriptionLabel" | transloco }}</span>
            <p>
                {{ mission.flightPurpose.comment ?? "-" }}
            </p>
        </div>
        <div class="mission-field-full-column" *ngIf="mission.additionalCrew.length">
            <h2 class="additional-crew-title">{{ "dtmSharedMission.missionDetails.additionalCrewLabel" | transloco }}</h2>
            <dtm-mission-additional-crew-members-preview
                [additionalCrewMembers]="mission.additionalCrew"
            ></dtm-mission-additional-crew-members-preview>
        </div>
    </div>
    <ng-template #emptyDataTemplate>
        <dtm-ui-empty-state [mode]="EmptyStateMode.Tertiary">
            <p messageSlot>{{ "dtmSharedMission.missionDetails.emptyData" | transloco }}</p>
        </dtm-ui-empty-state>
    </ng-template>
</dtm-ui-expandable-panel>
