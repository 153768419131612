import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { debounceTime, distinctUntilChanged, map, withLatestFrom } from "rxjs/operators";
import { NotificationsActions } from "../../state/notifications.actions";
import { NotificationsState } from "../../state/notifications.state";
import { NotificationDisplay, PageDetails } from "../../utils/notifications.model";

interface ListItemComponentState {
    notificationsList: NotificationDisplay[] | undefined;
}

const LIST_ITEM_SIZE = 90;
const VISIBLE_ITEMS = 5;

@UntilDestroy()
@Component({
    selector: "dtm-notifications-list[notificationsList]",
    templateUrl: "./list.component.html",
    styleUrls: ["./list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ListComponent implements AfterViewInit {
    @Input() public set notificationsList(value: NotificationDisplay[] | undefined) {
        this.localStore.patchState({ notificationsList: value });
    }

    @ViewChild(CdkVirtualScrollViewport) public viewPort!: CdkVirtualScrollViewport;

    public readonly notificationsList$ = this.localStore.selectByKey("notificationsList");
    public readonly isProcessing$ = this.store.select(NotificationsState.isNotificationsListProcessing);
    public readonly notificationsPage$ = this.store.select(NotificationsState.notificationsListPages);
    public readonly LIST_ITEM_SIZE = LIST_ITEM_SIZE;
    public readonly VISIBLE_ITEMS = VISIBLE_ITEMS;
    public readonly containerHeight$ = this.notificationsList$.pipe(
        RxjsUtils.filterFalsy(),
        map((list) => `${this.LIST_ITEM_SIZE * Math.min(list.length, this.VISIBLE_ITEMS)}px`),
        distinctUntilChanged()
    );

    constructor(
        private readonly localStore: LocalComponentStore<ListItemComponentState>,
        private readonly router: Router,
        private readonly store: Store
    ) {
        localStore.setState({ notificationsList: undefined });
    }

    public ngAfterViewInit() {
        const scrollDebounceTime = 300;

        this.viewPort.scrolledIndexChange
            .pipe(debounceTime(scrollDebounceTime), withLatestFrom(this.notificationsPage$), untilDestroyed(this))
            .subscribe(([topElementIndex, page]) => this.nextBatch(topElementIndex, page));

        this.viewPort.renderedRangeStream.pipe(untilDestroyed(this)).subscribe(() => this.viewPort.checkViewportSize());
    }

    public handleRedirectAndMarkAsRead(redirectUrl: string | undefined, isRead: boolean, notificationId: string) {
        if (!isRead) {
            this.store.dispatch(new NotificationsActions.MarkNotificationAsRead(notificationId));
        }

        if (!redirectUrl) {
            return;
        }

        this.router.navigateByUrl(redirectUrl);
    }

    public trackByIndex(index: number) {
        return index;
    }

    private nextBatch(topElementIndex: number, page: PageDetails | undefined) {
        if (page?.isEmpty || page?.isLast) {
            return;
        }

        const initialPageSize = 10;
        const pageSize = page?.size ?? initialPageSize;
        const currentPage = page?.number ?? 0;
        const shouldRequestNextBatch = Math.floor(topElementIndex / pageSize) === currentPage;

        if (shouldRequestNextBatch) {
            const nextPage = currentPage + 1;
            this.store.dispatch(new NotificationsActions.GetNotificationsList(nextPage));
        }
    }
}
