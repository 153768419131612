<mat-card>
    <dtm-ui-card-header>
        {{ "dtmUi.editAvatar.avatarHeader" | transloco }}
    </dtm-ui-card-header>
    <dtm-ui-avatar-editor
        [canRemove]="canRemove$ | ngrxPush"
        [avatar]="avatar$ | ngrxPush"
        [label]="'dtmUi.editAvatar.editAvatarLabel' | transloco"
        [fullName]="fullName$ | ngrxPush"
        (saveAvatar)="saveAvatar.emit($event)"
        (delete)="tryDeleteUserAvatar()"
    ></dtm-ui-avatar-editor>
</mat-card>
