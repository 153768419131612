<ac-layer #checkinAreaLayer acFor="let entity of checkinAreaEntities$" [store]="true" [context]="this" [show]="!(isProcessing$ | ngrxPush)">
    <ac-polygon-desc
        props="{
                hierarchy: entity.positions,
                material: CHECKIN_AREA_FILL_COLOR,
                height: 0
            }"
    >
    </ac-polygon-desc>
    <ac-polyline-desc
        props="{
                    positions: entity.positions,
                    material: DEFAULT_OUTLINE_MATERIAL
                    width: DEFAULT_OUTLINE_WIDTH
                    zIndex: 1
                }"
    >
    </ac-polyline-desc>
    <ac-html-desc
        props="{ position: entity.centerPosition }"
        *ngrxLet="{ areLabelsVisible: areLabelsVisible$, checkinData: checkinData$ } as vm"
    >
        <ng-template let-checkin>
            <ng-container
                *ngIf="(vm.areLabelsVisible || checkin.id === vm.checkinData?.id) && checkin.data | invoke : assertAsCheckin as checkinData"
            >
                <svg width="100" height="100" viewBox="-50 -50 100 100" class="line-connector">
                    <line [attr.x1]="0" [attr.y1]="0" [attr.x2]="0" [attr.y2]="-50" />
                    <circle r="4" cx="0" cy="0" />
                </svg>
                <div
                    class="segment-pin"
                    [ngClass]="{
                    active: checkinData.status === CheckinStatus.InRealization,
                    deactivated: checkinData.status === CheckinStatus.Expired,
                    waiting:
                        checkinData.status === CheckinStatus.Submitted,
                    selected: checkinData.id === vm.checkinData?.id,
                }"
                >
                    <div class="header">
                        <dtm-ui-icon name="map-pin"></dtm-ui-icon>
                        {{ "dtmMapCesium.checkinAreaLayer.checkinLabel" | transloco }}
                    </div>
                    <div class="header sub">
                        {{
                            "dtmMapCesium.checkinAreaLayer.heightAglLabel"
                                | transloco : { height: checkinData.heightAgl | localizeNumber : { maximumFractionDigits: 0 } }
                        }}
                        <p>
                            {{
                                "dtmMapCesium.checkinAreaLayer.heightAmslLabel"
                                    | transloco : { height: checkinData.amslCeil | localizeNumber : { maximumFractionDigits: 0 } }
                            }}
                        </p>
                    </div>
                    <div class="content">
                        <div class="indicator"></div>
                        {{ checkinData.flightStartAt | localizeDate : { timeStyle: "short" } }}
                        -
                        {{ checkinData.flightFinishAt | localizeDate : { timeStyle: "short" } }}
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </ac-html-desc>
</ac-layer>
