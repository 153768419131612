import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs";
import { FilterConditions, MAX_RESULTS_SIZE, Mission, MissionSearchErrorType, Sorting } from "../../models/mission-search.models";
import { MissionSearchActions } from "../../state/mission-search.actions";
import { MissionSearchState } from "../../state/mission-search.state";

@UntilDestroy()
@Component({
    templateUrl: "./mission-search-container.component.html",
    styles: [":host { --mission-search-switcher-folded-right: -17px; }"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionSearchContainerComponent {
    protected readonly missions$ = this.store.select(MissionSearchState.missions).pipe(RxjsUtils.filterFalsy());
    protected readonly initialFilterConditions$ = this.store.select(MissionSearchState.filterConditions).pipe(first());
    protected readonly pagination$ = this.store.select(MissionSearchState.missionsPages).pipe(RxjsUtils.filterFalsy());
    protected readonly isProcessing$ = this.store.select(MissionSearchState.isProcessing);

    protected readonly MAX_RESULTS_SIZE = MAX_RESULTS_SIZE;

    constructor(
        private readonly store: Store,
        private readonly transloco: TranslocoService,
        private readonly toastService: ToastrService,
        private readonly router: Router,
        private readonly route: ActivatedRoute
    ) {}

    protected changeOrder(sorting: Sorting): void {
        this.store
            .dispatch([new MissionSearchActions.SetMissionsOrder(sorting), new MissionSearchActions.FetchMissions(MAX_RESULTS_SIZE + 1)])
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(MissionSearchState.getMissionsError);

                if (error?.type === MissionSearchErrorType.CannotGetMissions) {
                    this.toastService.error(this.transloco.translate("dtmSharedMissionSearch.cannotGetMissionsError"));
                }
            });
    }

    protected changeFilter(filterConditions: FilterConditions | undefined): void {
        this.store
            .dispatch([
                new MissionSearchActions.SetFilterConditions(filterConditions),
                new MissionSearchActions.FetchMissions(MAX_RESULTS_SIZE + 1),
            ])
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(MissionSearchState.getMissionsError);

                if (error?.type === MissionSearchErrorType.CannotGetMissions) {
                    this.toastService.error(this.transloco.translate("dtmSharedMissionSearch.cannotGetMissionsError"));
                }
            });
    }

    protected showDetails(mission: Mission): void {
        this.router.navigate(["/mission-search", mission.id], {
            relativeTo: this.route,
        });
    }
}
