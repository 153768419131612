<button type="button" class="button-icon" [matDialogClose]="undefined">
    <dtm-ui-icon name="close"></dtm-ui-icon>
</button>
<div class="content" [class.no-title]="!data?.titleText">
    <h4 mat-dialog-title *ngIf="data?.titleText">{{ data?.titleText }}</h4>
    <ng-container *ngIf="!data?.shouldHideBody">
        <div *ngIf="data?.confirmationHtml" [innerHtml]="data?.confirmationHtml"></div>
        <p class="confirmation-text" *ngIf="!data?.confirmationHtml">
            {{ data?.confirmationText ?? ("dtmUi.confirmationDialog.defaultConfirmationText" | transloco) }}
        </p>
    </ng-container>
</div>

<mat-dialog-actions align="end">
    <button type="button" [mat-dialog-close]="false" class="button-secondary">
        {{ data?.declineButtonLabel ?? ("dtmUi.confirmationDialog.defaultDeclineButtonLabel" | transloco) }}
    </button>
    <button
        type="button"
        [mat-dialog-close]="true"
        [class.button-primary]="!data?.theme || data?.theme === ButtonTheme.Primary"
        [class.button-warn]="data?.theme === ButtonTheme.Warn"
        cdkFocusInitial
    >
        {{ data?.confirmButtonLabel ?? ("dtmUi.confirmationDialog.defaultConfirmButtonLabel" | transloco) }}
    </button>
</mat-dialog-actions>
