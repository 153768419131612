<input
    #input
    class="cdk-visually-hidden"
    type="radio"
    [id]="inputId"
    [checked]="checked"
    [disabled]="disabled"
    [attr.name]="name"
    [attr.value]="value"
    [required]="required"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby"
    (change)="_onInputInteraction($event)"
/>
<label [for]="inputId" class="selectable-tile" [class.selected]="checked" [class.disabled]="disabled">
    <ng-content></ng-content>
</label>
