import { Directive, ElementRef, OnDestroy, Optional, Self } from "@angular/core";
import { AbstractControl, AbstractControlDirective, ControlContainer, NgControl, UntypedFormGroup } from "@angular/forms";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector:
        "[formControl]:not([dtmUiSkipInvalidFormScroll]), " +
        "[formControlName]:not([dtmUiSkipInvalidFormScroll]), " +
        "[formGroup]:not([dtmUiSkipInvalidFormScroll]), " +
        "[formGroupName]:not([dtmUiSkipInvalidFormScroll])",
})
export class InvalidFormControlScrollHookDirective implements OnDestroy {
    constructor(
        private readonly element: ElementRef,
        @Optional() @Self() private readonly control: NgControl,
        @Optional() @Self() private readonly formGroup: ControlContainer,
        @Optional() private readonly container: InvalidFormScrollableDirective
    ) {
        if (this.container) {
            if (this.control) {
                this.container.register(this.control, this.element);
            }
            if (this.formGroup) {
                this.container.register(this.formGroup, this.element);
            }
        }
    }

    public ngOnDestroy(): void {
        if (this.container && this.control) {
            this.container.unregister(this.control);
        }
        if (this.container && this.formGroup) {
            this.container.unregister(this.formGroup);
        }
    }
}

@Directive({
    selector: "[dtmUiInvalidFormScrollable]",
    exportAs: "invalidFormScrollable",
})
export class InvalidFormScrollableDirective {
    private registry = new Map<AbstractControlDirective, ElementRef>();

    public register(controlDirective: AbstractControlDirective, element: ElementRef) {
        this.registry.set(controlDirective, element);
    }

    public unregister(controlDirective: AbstractControlDirective) {
        this.registry.delete(controlDirective);
    }

    public scrollToFirstInvalidField(scrollOptions?: ScrollIntoViewOptions): void {
        for (const [control, element] of this.registry.entries()) {
            if (this.isControlElementAvailableToScrollTo(control, element)) {
                this.scrollTo(element, scrollOptions);

                break;
            }
        }
    }

    private scrollTo(element: ElementRef, scrollOptions?: ScrollIntoViewOptions) {
        element.nativeElement.scrollIntoView(
            scrollOptions ?? {
                behavior: "smooth",
                block: "center",
                inline: "center",
            }
        );
    }

    private isControlElementAvailableToScrollTo(controlDirective: AbstractControlDirective, element: ElementRef): boolean {
        let isScrollable = !!controlDirective.control?.invalid;

        if (this.isFormGroup(controlDirective.control)) {
            const isAnySubFormControlInvalid = Object.values(controlDirective.control.controls).some((formControl) => formControl.invalid);
            if (isAnySubFormControlInvalid) {
                isScrollable = false;
            }
        }

        return isScrollable && !!element.nativeElement.scrollIntoView;
    }

    private isFormGroup(control: AbstractControl | null): control is UntypedFormGroup {
        return !!(control as UntypedFormGroup)?.controls;
    }
}
