import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";

type InfoMessageType = "error" | "info" | "warning" | "success";

@Component({
    selector: "dtm-ui-info-message",
    templateUrl: "./info-message.component.html",
    styleUrls: ["./info-message.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoMessageComponent {
    @HostBinding("class") private typeClass: string = this.getClassByType("info");
    @Input() public set type(value: InfoMessageType) {
        this.typeClass = this.getClassByType(value);
    }

    private getClassByType(value: InfoMessageType): string {
        return `info-message-${value}`;
    }
}
