<div
    class="grid"
    *ngrxLet="{ hasSupervisorPermissions: hasSupervisorPermissions$, submittedModificationsList: submittedModificationsList$ } as vm"
>
    <dss-shared-lib-applications-list-filters
        [initialFilters]="initialFilters"
        [flightZonePurposes]="flightZonePurposes"
        [hasSupervisorPermissions]="vm.hasSupervisorPermissions"
        [statuses]="statuses"
        [shouldDisableAuthorFilter]="!vm.hasSupervisorPermissions"
        (filtersChange)="applyFilters($event)"
    >
        <ng-container totalCount>
            {{
                "dssClientLibFlightZone.applicationListShared.totalApplicationsCountLabel"
                    | transloco : { totalCount: vm.submittedModificationsList?.page?.totalElements ?? 0 }
            }}
        </ng-container>
    </dss-shared-lib-applications-list-filters>

    <dss-client-lib-submitted-list
        [flightZoneApplications]="vm.submittedModificationsList?.content"
        [isProcessing]="isProcessing$ | ngrxPush"
        [hasDataRetrievalError]="hasListDataRetrievalError$ | ngrxPush"
        [hasSupervisorPermissions]="vm.hasSupervisorPermissions"
        [page]="vm.submittedModificationsList?.page"
        [isModificationsList]="true"
        (pageChange)="changePage($event)"
        (listRefresh)="refreshList()"
        (kmlDownload)="downloadKml($event)"
    ></dss-client-lib-submitted-list>
</div>
