import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ImageConverterErrorType } from "./image-converter.models";

@Injectable({
    providedIn: "root",
})
export class ImageConverterService {
    public convertBlobToBase64(file: Blob): Observable<string> {
        return new Observable((observer) => {
            const reader = new FileReader();

            reader.onload = ({ target }: ProgressEvent<FileReader>) => {
                if (target && target.result) {
                    observer.next(target.result as string);
                }
                observer.complete();
            };

            reader.onerror = () => {
                observer.error({ type: ImageConverterErrorType.CannotReadFile });
                observer.complete();
            };
            reader.readAsDataURL(file);
        });
    }
}
