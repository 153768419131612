import { CdkMenu, CdkMenuGroup, CdkMenuItem } from "@angular/cdk/menu";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { MatBadgeModule } from "@angular/material/badge";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { RouterModule } from "@angular/router";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { SharedWebsocketModule, WebsocketService } from "@dtm-frontend/shared/websocket";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { ListItemComponent } from "./components/list-item/list-item.component";
import { ListComponent } from "./components/list/list.component";
import { NotificationInfoComponent } from "./components/notification-info/notification-info.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { NotificationsApiService } from "./services/notifications-api.service";
import { NotificationsState } from "./state/notifications.state";

@NgModule({
    imports: [
        CommonModule,
        NgxsModule.forFeature([NotificationsState]),
        HttpClientModule,
        MatButtonModule,
        MatIconModule,
        MatBadgeModule,
        MatMenuModule,
        SharedWebsocketModule.forRoot(),
        SharedI18nModule,
        LetModule,
        PushModule,
        MatProgressSpinnerModule,
        SharedUiModule,
        RouterModule,
        ScrollingModule,
        CdkMenu,
        CdkMenuItem,
        CdkMenuGroup,
        MatLegacyDialogModule,
    ],
    declarations: [NotificationsComponent, ListComponent, ListItemComponent, NotificationInfoComponent],
    exports: [NotificationsComponent, ListComponent, ListItemComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmNotifications",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
            },
        },
        {
            provide: WebsocketService,
            useValue: undefined,
        },
        {
            provide: NotificationsApiService,
            useValue: undefined,
        },
    ],
})
export class SharedNotificationsModule {
    public static forRoot(): ModuleWithProviders<SharedNotificationsModule> {
        return {
            ngModule: SharedNotificationsModule,
            providers: [WebsocketService, NotificationsApiService],
        };
    }

    public static forTest(): ModuleWithProviders<SharedNotificationsModule> {
        return {
            ngModule: SharedNotificationsModule,
            providers: [
                {
                    provide: WebsocketService,
                    useValue: null,
                },
                {
                    provide: NotificationsApiService,
                    useValue: null,
                },
            ],
        };
    }
}
