export enum UavType {
    Multirotor = "MR",
    VerticalTakeOffAndLanding = "VTOL",
    Airplane = "A",
    Helicopter = "H",
    Aerostat = "AS",
    Other = "OTHER",
}

export interface UavModel {
    id: string;
    name: string;
    manufacturer: string;
    type: UavType;
    isCustom: boolean;
    imageId?: string;
    imageUrl?: string;
}
