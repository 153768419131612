/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line max-len
// NOTE: this file is based on https://github.com/pansa-dev/ngx-cesium/blob/HEAD/projects/@pansa/ngx-cesium/src/lib/@pansa/ngx-cesium-widgets/models/editable-polyline.ts#L304
import { FunctionUtils, MILLISECONDS_IN_SECOND, SpatialUtils } from "@dtm-frontend/shared/utils";
import {
    AcEntity,
    AcLayerComponent,
    Cartesian3,
    EditActions,
    EditModes,
    EditPolyline,
    GeoUtilsService,
    PointProps,
    PolylineProps,
} from "@pansa/ngx-cesium";
import turfBbox from "@turf/bbox";
import turfBearing from "@turf/bearing";
import turfBuffer from "@turf/buffer";
import turfCircle from "@turf/circle";
import turfDestination from "@turf/destination";
import turfDistance from "@turf/distance";
import {
    Feature,
    Point,
    Polygon,
    Position,
    Properties,
    featureCollection as turfFeatureCollection,
    lineString as turfLineString,
    point as turfPoint,
    polygon as turfPolygon,
} from "@turf/helpers";
import turfLineIntersect from "@turf/line-intersect";
import turfNearestPoint from "@turf/nearest-point";
import turfPointToLineDistance from "@turf/point-to-line-distance";
import turfPolygonTangents from "@turf/polygon-tangents";
import { MapEntityType } from "../../services/entity-editors/map-entities-editor.service";
import { DraggableHeightEntity, HeightEditable, HeightEntityType, HeightPointsProvider } from "../../services/height-helper.service";
import { convertCartesian3ToSerializableCartographic } from "../../utils/convert-cartesian3-to-serializable-cartographic";
import { convertSerializableCartographicToCartesian3 } from "../../utils/convert-serializable-cartographic-to-cartesian3";
import { getElevatedCesiumPoint } from "../../utils/get-elevated-cesium-point";
import { CartographicEditPointParentEntity, CesiumPointWithProps } from "../cartographic-edit-point";
import {
    ChildEntityCollection,
    ChildEntitySubtype,
    EntityParentCartographicEditPoint,
    EntityParentCartographicEditPointType,
} from "../child-entity.model";
import { DEFAULT_LABEL_PROPS, EntityLabel, LabelFn, LabelProviders } from "../entity-label.model";
import { EntityStatus } from "../entity-status.model";
import { Polyline3DEditOptions, Polyline3DProps } from "./polyline3d-edit-options";
import { Polyline3DEditUpdate } from "./polyline3d-edit-update";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export const MINIMUM_WAYPOINTS = 2;

export interface Polyline3DEditorLabelProviders extends LabelProviders {
    pointHeight?: LabelFn<
        { entityId: string; index: number; height: number },
        { height: number; waypointIndex: number; waypointLabel: string }
    >;
    waypoint?: LabelFn<{ entityId: string; index: number }>;
    segment?: LabelFn<
        { entityId: string; index: number },
        { waypointIndex: number; type: SegmentLabelType; topHeight?: number; bottomHeight?: number }
    >;
}

export type Polyline3DLabel = EntityLabel<Polyline3DEditorLabelProviders> & {
    heightPointId?: string;
    pointIndex: number;
};

export enum SegmentLabelType {
    Segment = "segment",
    FirstChild = "firstChild",
    LastChild = "lastChild",
    InternalCylinder = "internalCylinder",
    InternalPrism = "internalPrism",
}

const APPROXIMATED_BUFFER_SIDES = 8;
const APPROXIMATED_TURFED_CIRCLE_SIDES = 128;

class BufferAcEntity extends AcEntity {
    public static readonly ENTITY_ID_PREFIX = "buffer_";

    private readonly bufferSegments: Feature<Polygon, Properties>[] = [];
    public readonly id: string;
    private readonly positionsHierarchy = new Cesium.PolygonHierarchy([]);
    private isPositionHierarchyValid = false;

    constructor(parentId: string, public readonly material: any, private readonly isStaticEntity: boolean) {
        super();
        this.id = BufferAcEntity.ENTITY_ID_PREFIX + parentId;
    }

    public getPositionsHierarchyCallbackProperty() {
        return new Cesium.CallbackProperty(() => {
            if (!this.isPositionHierarchyValid) {
                this.recalculatePositionsHierarchy();
                this.isPositionHierarchyValid = true;
            }

            return this.positionsHierarchy;
        }, this.isStaticEntity);
    }

    public addBufferSegment(
        startPoint: EntityParentCartographicEditPoint,
        endPoint: EntityParentCartographicEditPoint,
        width: number,
        height: number
    ) {
        const startPointCartographic = startPoint.getCartographic();
        const endPointCartographic = endPoint.getCartographic();

        width = Math.max(1, width) / 2; // NOTE: polyline buffer is half of the buffer width

        const lineString = turfLineString([
            [startPointCartographic.longitude, startPointCartographic.latitude],
            [endPointCartographic.longitude, endPointCartographic.latitude],
        ]);

        this.bufferSegments.push(
            turfBuffer(lineString, width, {
                steps: APPROXIMATED_BUFFER_SIDES,
                units: "meters",
            })
        );

        this.isPositionHierarchyValid = false;
    }

    private recalculatePositionsHierarchy() {
        if (this.bufferSegments.length === 0) {
            this.positionsHierarchy.positions = [];
            this.positionsHierarchy.holes = [];

            return;
        }

        const combinedBuffer = SpatialUtils.union(this.bufferSegments);

        const positions = combinedBuffer?.geometry.coordinates[0].map((bufferPoint) =>
            Cesium.Cartesian3.fromDegrees(bufferPoint[0], bufferPoint[1])
        );

        const holes = combinedBuffer?.geometry.coordinates
            .slice(1)
            .map((hole) => hole.map((bufferPoint) => Cesium.Cartesian3.fromDegrees(bufferPoint[0], bufferPoint[1])))
            .map((holePositions) => new Cesium.PolygonHierarchy(holePositions));

        this.positionsHierarchy.positions = positions ?? [];
        this.positionsHierarchy.holes = holes ?? [];
    }
}

export class Polyline3DHeightPoint extends DraggableHeightEntity {
    constructor(
        parent: HeightEditable,
        public readonly boundEditPoint: EntityParentCartographicEditPoint,
        height: number | undefined,
        public bufferWidth: number,
        public bufferHeight: number
    ) {
        super(parent, Polyline3DHeightPoint.getHeightPointsProvider(parent, boundEditPoint), HeightEntityType.Top, height ?? 0, true);
    }

    private static getHeightPointsProvider(parent: HeightEditable, groundPoint: EntityParentCartographicEditPoint): HeightPointsProvider {
        return {
            getBottomHeight: () => 0,
            getTopHeight: () => parent.heightConstraints.maxTopHeight ?? 0,
            getGroundPointForHeight: () => groundPoint.getPosition(),
        };
    }

    public setConstrainedHeight(newHeight: number): number {
        if (
            this.boundEditPoint.type !== EntityParentCartographicEditPointType.Inlet &&
            this.boundEditPoint.type !== EntityParentCartographicEditPointType.Outlet
        ) {
            return super.setConstrainedHeight(newHeight);
        }

        const parentConstrainedHeight = this.getConstrainedHeight(
            (this.boundEditPoint.entityParent?.childEntity?.topHeight ?? Number.MAX_VALUE) - Math.round(this.bufferHeight / 2),
            (this.boundEditPoint.entityParent?.childEntity?.bottomHeight ?? Number.MIN_VALUE) + Math.round(this.bufferHeight / 2),
            newHeight
        );

        return super.setConstrainedHeight(parentConstrainedHeight);
    }

    public get show() {
        return (
            this.boundEditPoint.show &&
            !this.boundEditPoint.isVirtualEditPoint() &&
            this.boundEditPoint.type !== EntityParentCartographicEditPointType.EntityParent
        );
    }

    public getId(): string {
        return this.boundEditPoint.getId();
    }

    public getElevatedPositionCallbackProperty() {
        return new Cesium.CallbackProperty(() => this.elevatedHeightPoint, this.parent.isStaticEntity);
    }
}

class EditPolylineWithHeight extends EditPolyline {
    private _positionsForCallbackProperty: [Cartesian3, Cartesian3] | undefined;

    constructor(
        private readonly entity: CartographicEditPointParentEntity,
        startPosition: Cartesian3,
        endPosition: Cartesian3,
        public readonly startHeight: number,
        public readonly endHeight: number,
        polylineProps?: PolylineProps | undefined
    ) {
        super(entity.getId(), startPosition, endPosition, polylineProps);

        this._positionsForCallbackProperty = [startPosition, endPosition];
    }

    public setStartPosition(position: Cartesian3): void {
        super.setStartPosition(position);
        this._positionsForCallbackProperty = [position, this.getEndPosition()];
    }

    public setEndPosition(position: Cartesian3): void {
        super.setEndPosition(position);
        this._positionsForCallbackProperty = [this.getStartPosition(), position];
    }

    public getPositionsCallbackProperty() {
        return new Cesium.CallbackProperty(() => this._positionsForCallbackProperty, this.entity.isStaticEntity);
    }
}

const RENDER_POLYLINES_THROTTLE = 16;

export class EditablePolyline3D extends AcEntity implements HeightEditable, CartographicEditPointParentEntity {
    private positions: EntityParentCartographicEditPoint[] = [];
    private polylines: EditPolylineWithHeight[] = [];
    private _heightPoints: Polyline3DHeightPoint[] = [];
    private movingPoint: EntityParentCartographicEditPoint | undefined;
    private doneCreation = false;
    private isDisposed = false;
    private _enableEdit = true;
    private _pointProps: PointProps;
    private polylineProps!: Polyline3DProps;
    private lastDraggedToPosition: Cartesian3 | undefined;
    private _labels: Polyline3DLabel[] = [];
    private _hoverHeightPointId: string | undefined;
    private _dynamicEntityTimeoutHandler?: ReturnType<typeof setTimeout>;
    private _cachedTurfedChildEntity?: Feature<Polygon, Properties>;

    constructor(
        private id: string,
        private pointsLayer: AcLayerComponent,
        private heightPointsLayer: AcLayerComponent,
        private polylinesLayer: AcLayerComponent,
        private buffersLayer: AcLayerComponent,
        private editOptions: Polyline3DEditOptions,
        positions?: Cartesian3[],
        heights?: (number | undefined)[],
        bufferWidths?: number[],
        bufferHeights?: number[]
    ) {
        super();
        this._pointProps = { ...editOptions.pointProps };
        this.props = { ...editOptions.polylineProps };

        if (
            positions &&
            heights &&
            bufferWidths &&
            bufferHeights &&
            positions.length >= MINIMUM_WAYPOINTS &&
            positions.length === heights.length &&
            bufferWidths.length === heights.length &&
            bufferHeights.length === heights.length
        ) {
            this.createFromExisting(positions, heights, bufferWidths, bufferHeights);
        }
    }

    public get isStaticEntity() {
        return this._dynamicEntityTimeoutHandler === undefined && this.doneCreation;
    }

    /**
     * Marks this entity as dynamic for up to one second since latest update for rendering performance reasons
     */
    public markAsDynamicEntityWhileUpdate() {
        if (this._dynamicEntityTimeoutHandler !== undefined) {
            clearTimeout(this._dynamicEntityTimeoutHandler);
        }

        this._dynamicEntityTimeoutHandler = setTimeout(() => {
            this._dynamicEntityTimeoutHandler = undefined;

            if (this.isDisposed) {
                return;
            }

            this.updatePointsLayer(true, ...this.positions);
        }, MILLISECONDS_IN_SECOND);
    }

    public get heightConstraints() {
        return this.props;
    }

    public shouldShowLabel(label: Polyline3DLabel): boolean {
        return !!(
            ((this._hoverHeightPointId === label.heightPointId && label.id !== "waypoint") ||
                (this._hoverHeightPointId !== label.heightPointId && label.id === "waypoint") ||
                label.id === "segment") &&
            label.show &&
            label.position
        );
    }

    public set hoverHeightPointId(value: string | undefined) {
        this._hoverHeightPointId = value;
    }

    public get hoverHeightPointId() {
        return this._hoverHeightPointId;
    }

    public get labels(): Polyline3DLabel[] {
        return this._labels;
    }

    public set labels(value: Polyline3DLabel[]) {
        if (value && value.length === 0) {
            this._labels = [];

            return;
        }

        const realHeightPoints = this.getRealHeightPoints(false);
        const virtualPoints = this.getVirtualPoints();

        if (!value || !realHeightPoints?.length) {
            return;
        }

        this._labels = value.map((label, index) => {
            // NOTE: labels are array of [pointHeight(0), waypoint(0), segment(0), pointHeight(1), waypoint(1), segment(1), ...]
            // eslint-disable-next-line no-magic-numbers
            const pointIndex = Math.floor(index / 3);
            const heightPointAtIndex = realHeightPoints[pointIndex];

            switch (label.id) {
                case "pointHeight": {
                    label.heightPointId = heightPointAtIndex.id;
                    label.position = heightPointAtIndex.elevatedHeightPoint;
                    break;
                }
                case "waypoint": {
                    label.heightPointId = heightPointAtIndex.id;
                    label.position = heightPointAtIndex.elevatedHeightPoint;
                    label.show =
                        label.show && heightPointAtIndex.boundEditPoint.type !== EntityParentCartographicEditPointType.EntityParent;
                    break;
                }
                case "segment": {
                    if (heightPointAtIndex.boundEditPoint.type === EntityParentCartographicEditPointType.Inlet) {
                        label.show = false;
                        label.position = Cesium.Cartesian3.ZERO;

                        break;
                    }

                    if (
                        heightPointAtIndex.boundEditPoint.type === EntityParentCartographicEditPointType.Outlet ||
                        heightPointAtIndex.boundEditPoint.type === EntityParentCartographicEditPointType.Waypoint
                    ) {
                        label.position = virtualPoints[pointIndex]?.getPosition();
                    } else if (heightPointAtIndex.boundEditPoint.type === EntityParentCartographicEditPointType.EntityParent) {
                        const inletPoint = pointIndex - 1 >= 0 ? realHeightPoints[pointIndex - 1] : undefined;
                        const outletPoint = pointIndex + 1 < realHeightPoints.length ? realHeightPoints[pointIndex + 1] : undefined;

                        label.position = this.calculateChildEntityLabelPoint(
                            heightPointAtIndex,
                            inletPoint?.groundPoint,
                            outletPoint?.groundPoint
                        );

                        label.position = getElevatedCesiumPoint(
                            label.position,
                            heightPointAtIndex.boundEditPoint.childEntity?.topHeight ?? 0
                        );
                    }

                    break;
                }
            }

            label.pointIndex = pointIndex;
            label.show = this.shouldShowLabel(label);
            label.position ??= Cesium.Cartesian3.ZERO;

            return { ...DEFAULT_LABEL_PROPS, ...label };
        });
    }

    private calculateChildEntityLabelPoint(
        centerPoint: Polyline3DHeightPoint,
        inletPoint?: Cartesian3,
        outletPoint?: Cartesian3
    ): Cartesian3 {
        if ((!inletPoint && !outletPoint) || centerPoint.boundEditPoint.childEntity?.type !== MapEntityType.Cylinder) {
            return centerPoint.elevatedHeightPoint;
        }

        const result = new Cesium.Cartesian3();
        let inletToOutletMidpoint: Cartesian3;

        if (!inletPoint) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            inletToOutletMidpoint = outletPoint!;
        } else if (!outletPoint) {
            inletToOutletMidpoint = inletPoint;
        } else {
            inletToOutletMidpoint = Cesium.Cartesian3.midpoint(inletPoint, outletPoint, result);
        }

        // NOTE: Calculate the vector from center to radius point
        const midpointToCenterVector = Cesium.Cartesian3.subtract(centerPoint.groundPoint, inletToOutletMidpoint, result);
        const normalizedMidpointToCenterVector = Cesium.Cartesian3.normalize(midpointToCenterVector, result);

        // NOTE: Distance from inlet/outlet to center
        const distance = Cesium.Cartesian3.distance(centerPoint.groundPoint, inletPoint ?? outletPoint);
        const furthestPointVector = Cesium.Cartesian3.multiplyByScalar(normalizedMidpointToCenterVector, distance, result);

        return Cesium.Cartesian3.add(centerPoint.groundPoint, furthestPointVector, result);
    }

    public get props(): Polyline3DProps {
        return this.polylineProps;
    }

    public set props(value: Polyline3DProps) {
        this.polylineProps = value;
    }

    public get pointProps(): PointProps {
        return this._pointProps;
    }

    public set pointProps(value: PointProps) {
        this._pointProps = value;
    }

    public get enableEdit() {
        return this._enableEdit;
    }

    public set enableEdit(value: boolean) {
        this._enableEdit = value;
        this.positions.forEach((point) => {
            point.show = value;
            this.updatePointsLayer(false, point);
        });
    }

    private createFromExisting(positions: Cartesian3[], heights: (number | undefined)[], bufferWidths: number[], bufferHeights: number[]) {
        const points = positions.map((position, index) =>
            this.addPointFromExisting(position, heights[index], bufferWidths[index], bufferHeights[index])
        );
        this.updatePointsLayer(true, ...points);
        this.regenerateVirtualEditPoints();
        this.updateHeightPointsLayer();
        this.doneCreation = true;
        this.markAsDynamicEntityWhileUpdate();
    }

    private isPoint(value: Cartesian3 | CesiumPointWithProps): value is CesiumPointWithProps {
        return !!(
            value as {
                pointProps: PointProps;
            }
        ).pointProps;
    }

    public setManually(
        points: CesiumPointWithProps[] | Cartesian3[],
        heights: number[],
        bufferWidths: number[],
        bufferHeights: number[],
        childEntities: ChildEntityCollection,
        polylineProps: Polyline3DProps = this.polylineProps
    ) {
        if (!this.doneCreation) {
            throw new Error("Update manually only in edit mode, after polyline is created");
        }

        if (points.length !== heights.length || points.length !== bufferWidths.length || points.length !== bufferHeights.length) {
            throw new Error("Heights array must be the same length as points array, bufferWidths array and bufferHeights array");
        }

        this.markAsDynamicEntityWhileUpdate();

        this.polylineProps = { ...this.polylineProps, ...polylineProps };

        this.positions.forEach((point) => {
            this.pointsLayer.remove(point.getId());
            this.heightPointsLayer.remove(point.getId());
        });

        const newPoints: EntityParentCartographicEditPoint[] = [];

        for (let i = 0; i < points.length; i++) {
            const pointOrCartesian = points[i];
            let newPoint = null;

            if (this.isPoint(pointOrCartesian)) {
                newPoint = new EntityParentCartographicEditPoint(this, pointOrCartesian.position, pointOrCartesian.pointProps);
            } else {
                newPoint = new EntityParentCartographicEditPoint(this, pointOrCartesian, this._pointProps);
            }

            newPoints.push(newPoint);
        }

        this.positions = newPoints;
        this._heightPoints = newPoints.map(
            (point, index) => new Polyline3DHeightPoint(this, point, heights[index], bufferWidths[index], bufferHeights[index])
        );

        Object.values(childEntities).forEach(({ entity, waypointIndex }) => {
            newPoints[waypointIndex].childEntity = entity;

            if (waypointIndex > 0) {
                newPoints[waypointIndex - 1].type = EntityParentCartographicEditPointType.Inlet;
                newPoints[waypointIndex - 1].entityParent = newPoints[waypointIndex];
            }

            if (waypointIndex > 2) {
                this._heightPoints[waypointIndex - 1].bufferHeight = this._heightPoints[waypointIndex - 2].bufferHeight;
            }

            if (waypointIndex < newPoints.length - 1) {
                newPoints[waypointIndex + 1].type = EntityParentCartographicEditPointType.Outlet;
                newPoints[waypointIndex + 1].entityParent = newPoints[waypointIndex];
            }
        });

        this.regenerateVirtualEditPoints();
        this.updatePointsLayer(true, ...this.positions);
    }

    private regenerateVirtualEditPoints() {
        this.positions.filter((point) => point.isVirtualEditPoint()).forEach((point) => this.removePosition(point));

        const currentPoints = [...this.positions];

        currentPoints.forEach((point, index) => {
            if (index !== currentPoints.length - 1 && this.movingPoint !== point) {
                const currentPoint = point;
                const nextIndex = (index + 1) % currentPoints.length;
                const nextPoint = currentPoints[nextIndex];

                const midPoint = this.setMiddleVirtualPoint(currentPoint, nextPoint);

                if (currentPoint.type === EntityParentCartographicEditPointType.Inlet) {
                    midPoint.type = EntityParentCartographicEditPointType.Inlet;
                } else if (currentPoint.type === EntityParentCartographicEditPointType.EntityParent) {
                    midPoint.type = EntityParentCartographicEditPointType.Outlet;
                }

                this.updatePointsLayer(false, midPoint);
            }
        });
    }

    private setMiddleVirtualPoint(
        firstPoint: EntityParentCartographicEditPoint,
        secondPoint: EntityParentCartographicEditPoint
    ): EntityParentCartographicEditPoint {
        const midPointCartesian3 = Cesium.Cartesian3.lerp(
            firstPoint.getPosition(),
            secondPoint.getPosition(),
            // eslint-disable-next-line no-magic-numbers
            0.5,
            new Cesium.Cartesian3()
        );
        const midPoint = new EntityParentCartographicEditPoint(this, midPointCartesian3, this._pointProps);
        midPoint.setVirtualEditPoint(true);

        const firstIndex = this.positions.indexOf(firstPoint);
        const newPointHeight = (this._heightPoints[firstIndex].height + this._heightPoints[firstIndex + 1].height) / 2;
        const newPointBufferWidth = this._heightPoints[firstIndex].bufferWidth;
        const newPointBufferHeight = this._heightPoints[firstIndex].bufferHeight;
        this.positions.splice(firstIndex + 1, 0, midPoint);
        this._heightPoints.splice(
            firstIndex + 1,
            0,
            new Polyline3DHeightPoint(this, midPoint, newPointHeight, newPointBufferWidth, newPointBufferHeight)
        );

        return midPoint;
    }

    private updateMiddleVirtualPoint(
        virtualEditPoint: Polyline3DHeightPoint,
        prevPoint: Polyline3DHeightPoint,
        nextPoint: Polyline3DHeightPoint
    ) {
        const midPointCartesian3 = Cesium.Cartesian3.lerp(
            prevPoint.boundEditPoint.getPosition(),
            nextPoint.boundEditPoint.getPosition(),
            // eslint-disable-next-line no-magic-numbers
            0.5,
            new Cesium.Cartesian3()
        );
        virtualEditPoint.boundEditPoint.setPosition(midPointCartesian3);
        virtualEditPoint.setConstrainedHeight((prevPoint.height + nextPoint.height) / 2);
    }

    public changeVirtualPointToRealPoint(point: EntityParentCartographicEditPoint) {
        point.setVirtualEditPoint(false);
        const pointsCount = this.positions.length;
        const pointIndex = this.positions.indexOf(point);
        const nextIndex = (pointIndex + 1) % pointsCount;
        const preIndex = (pointIndex - 1 + pointsCount) % pointsCount;

        const nextPoint = this.positions[nextIndex];
        const prePoint = this.positions[preIndex];

        const firstMidPoint = this.setMiddleVirtualPoint(prePoint, point);
        const secondMidPoint = this.setMiddleVirtualPoint(point, nextPoint);

        this.updatePointsLayer(false, firstMidPoint, secondMidPoint, point);
    }

    @FunctionUtils.throttle(RENDER_POLYLINES_THROTTLE)
    private renderPolylines() {
        this.polylines.forEach((polyline) => this.polylinesLayer.remove(polyline.getId()));
        this.buffersLayer.remove(BufferAcEntity.ENTITY_ID_PREFIX + this.id);

        this.polylines = [];
        const realPoints = this.getRealPoints(true);
        const realHeights = this.getRealHeights(true);
        const realBufferWidths = this.getRealBufferWidths(true);
        const realBufferHeights = this.getRealBufferHeights(true);

        if (realPoints.length < 2) {
            return;
        }

        const bufferEntity = new BufferAcEntity(this.id, this.props.shadowMaterial, this.isStaticEntity);

        realPoints.forEach((point, index) => {
            if (index === realPoints.length - 1) {
                return;
            }

            const nextIndex = index + 1;
            const nextPoint = realPoints[nextIndex];
            const isInvisiblePolyline =
                point.type === EntityParentCartographicEditPointType.Inlet ||
                nextPoint.type === EntityParentCartographicEditPointType.Outlet;

            if (isInvisiblePolyline) {
                bufferEntity.addBufferSegment(point, nextPoint, 1, 1);

                return;
            }

            const polyline = new EditPolylineWithHeight(
                this,
                point.getPosition(),
                nextPoint.getPosition(),
                realHeights[index],
                realHeights[nextIndex] ?? 0,
                this.polylineProps
            );
            this.polylines.push(polyline);
            this.polylinesLayer.update(polyline, polyline.getId());

            bufferEntity.addBufferSegment(point, nextPoint, realBufferWidths[index], realBufferHeights[index]);
        });

        this.buffersLayer.update(bufferEntity, BufferAcEntity.ENTITY_ID_PREFIX + this.id);
    }

    public addPointFromExisting(
        position: Cartesian3,
        height: number | undefined,
        bufferWidth: number,
        bufferHeight: number
    ): EntityParentCartographicEditPoint {
        const newPoint = new EntityParentCartographicEditPoint(this, position, this._pointProps);
        this.positions.push(newPoint);
        this._heightPoints.push(new Polyline3DHeightPoint(this, newPoint, height, bufferWidth, bufferHeight));

        return newPoint;
    }

    public addPoint(position: Cartesian3, height?: number, bufferWidth?: number, bufferHeight?: number) {
        if (this.doneCreation) {
            return;
        }

        height = height ?? this.props.defaultTopHeight ?? 1;
        bufferWidth = bufferWidth ?? this.props.defaultBufferWidth ?? 1;
        bufferHeight = bufferHeight ?? this.props.defaultBufferHeight ?? 1;

        const isFirstPoint = !this.positions.length;

        if (isFirstPoint) {
            const firstPoint = new EntityParentCartographicEditPoint(this, position, this._pointProps);
            this.positions.push(firstPoint);
            this._heightPoints.push(new Polyline3DHeightPoint(this, firstPoint, height, bufferWidth, bufferHeight));
            this.updatePointsLayer(true, firstPoint);
        }

        this.movingPoint = new EntityParentCartographicEditPoint(this, position, this._pointProps);
        this.positions.push(this.movingPoint);
        this._heightPoints.push(new Polyline3DHeightPoint(this, this.movingPoint, height, bufferWidth, bufferHeight));

        this.updatePointsLayer(true, this.movingPoint);
        this.regenerateVirtualEditPoints();
    }

    public finishCreation() {
        this.doneCreation = true;
        this.removePosition(this.movingPoint);
        this.movingPoint = undefined;

        this.regenerateVirtualEditPoints();
        this.markAsDynamicEntityWhileUpdate();
    }

    public movePointFinish(editPoint: EntityParentCartographicEditPoint) {
        if (this.editOptions.clampHeightTo3D) {
            editPoint.props.disableDepthTestDistance = Number.POSITIVE_INFINITY;
            this.updatePointsLayer(false, editPoint);
        }

        this._cachedTurfedChildEntity = undefined;
    }

    public movePoint(toPosition: Cartesian3, editPoint: EntityParentCartographicEditPoint) {
        editPoint.setPosition(toPosition);

        if (!this.doneCreation) {
            this.updatePointsLayer(true, editPoint);

            return;
        }

        if (editPoint.props.disableDepthTestDistance && this.editOptions.clampHeightTo3D) {
            editPoint.props.disableDepthTestDistance = undefined;

            return;
        }

        if (editPoint.isVirtualEditPoint()) {
            this.changeVirtualPointToRealPoint(editPoint);
        }

        const pointsCount = this.positions.length;
        const pointIndex = this.positions.indexOf(editPoint);

        if (pointIndex === -1) {
            console.error("Point not found in positions array"); // TODO: DTM-5026

            return;
        }

        if (pointIndex < this.positions.length - 1) {
            const nextVirtualPoint = this._heightPoints[(pointIndex + 1) % pointsCount];
            const nextRealPoint = this._heightPoints[(pointIndex + 2) % pointsCount];
            this.updateMiddleVirtualPoint(nextVirtualPoint, this._heightPoints[pointIndex], nextRealPoint);
        }

        if (pointIndex > 0) {
            const prevVirtualPoint = this._heightPoints[(pointIndex - 1 + pointsCount) % pointsCount];
            const prevRealPoint = this._heightPoints[(pointIndex - 2 + pointsCount) % pointsCount];
            this.updateMiddleVirtualPoint(prevVirtualPoint, this._heightPoints[pointIndex], prevRealPoint);
        }

        this.updatePointsLayer(true, editPoint);
    }

    public moveTempMovingPoint(toPosition: Cartesian3) {
        if (this.movingPoint) {
            this.movePoint(toPosition, this.movingPoint);
        } else if (this.positions.length > 0) {
            this.movingPoint = new EntityParentCartographicEditPoint(this, toPosition, this._pointProps);
            this.positions.push(this.movingPoint);
            this._heightPoints.push(
                new Polyline3DHeightPoint(
                    this,
                    this.movingPoint,
                    this.props.defaultTopHeight ?? 1,
                    this.props.defaultBufferWidth ?? 1,
                    this.props.defaultBufferHeight ?? 1
                )
            );
        }
    }

    public removeTempMovingPoint() {
        if (this.movingPoint) {
            this.removePosition(this.movingPoint);
            this.movingPoint = undefined;
            this.updatePointsLayer(true);
        }
    }

    public moveShape(startMovingPosition: Cartesian3, draggedToPosition: Cartesian3) {
        if (!this.doneCreation) {
            return;
        }

        if (!this.lastDraggedToPosition) {
            this.lastDraggedToPosition = startMovingPosition;
        }

        const delta = GeoUtilsService.getPositionsDelta(this.lastDraggedToPosition, draggedToPosition);
        this.positions.forEach((point) => {
            const newPos = GeoUtilsService.addDeltaToPosition(point.getPosition(), delta, true);
            point.setPosition(newPos);
        });
        this.updatePointsLayer(true, ...this.positions);
        this.lastDraggedToPosition = draggedToPosition;
    }

    public endMoveShape() {
        this.lastDraggedToPosition = undefined;
        this.updatePointsLayer(true, ...this.positions);
    }

    public moveHeight(update: Polyline3DEditUpdate, skipUpdate = false) {
        if (!update.updatedHeightPoint || update.height === undefined) {
            return;
        }

        update.height = update.updatedHeightPoint.setConstrainedHeight(update.height);

        if (!skipUpdate) {
            this.updatePointsLayer(true, ...this.positions);
        }
    }

    public removePoint(pointToRemove: EntityParentCartographicEditPoint) {
        if (pointToRemove.type !== EntityParentCartographicEditPointType.Waypoint) {
            return;
        }

        this.removePosition(pointToRemove);
        this.regenerateVirtualEditPoints();

        this.renderPolylines();
    }

    public setChildEntityManually(entity: ChildEntitySubtype, waypointIndex: number) {
        this.markAsDynamicEntityWhileUpdate();

        const realPoints = this.getRealPoints(false);
        const point = realPoints[waypointIndex];

        point.childEntity = entity;
        const parentIndex = this.positions.findIndex((position) => position === point);

        const inletPoint = parentIndex > 1 ? this.positions[parentIndex - 2] : undefined;
        const outletPoint = parentIndex < this.positions.length - 2 ? this.positions[parentIndex + 2] : undefined;

        if (inletPoint) {
            this.positions[parentIndex - 1].type = EntityParentCartographicEditPointType.Inlet; // hide virtual point inside child
            inletPoint.type = EntityParentCartographicEditPointType.Inlet;
            inletPoint.entityParent = point;
        }

        if (outletPoint) {
            this.positions[parentIndex + 1].type = EntityParentCartographicEditPointType.Outlet; // hide virtual point inside child
            outletPoint.type = EntityParentCartographicEditPointType.Outlet;
            outletPoint.entityParent = point;
        }
    }

    public addOrUpdateChildEntity(entity: ChildEntitySubtype, waypointIndex?: number) {
        this.markAsDynamicEntityWhileUpdate();

        let parentIndex = this.positions.findIndex((position) => position.childEntity?.id === entity.id);
        let point =
            parentIndex === -1 && waypointIndex !== undefined ? this.getVirtualPoints()[waypointIndex] : this.positions[parentIndex];

        const isAddingFirstOrLastPoint = parentIndex === -1 && !point;

        if (waypointIndex === -1) {
            point = this.positions[0];
        } else if (waypointIndex === this.getRealPoints(false).length) {
            point = this.positions[this.positions.length - 1];
        }

        point.childEntity = entity;
        parentIndex = this.positions.findIndex((position) => position === point);

        let inletPoint = parentIndex > 1 ? this.positions[parentIndex - 2] : undefined;
        let outletPoint = parentIndex < this.positions.length - 2 ? this.positions[parentIndex + 2] : undefined;

        // NOTE: Only invoked when ADDING child entity
        if (point.isVirtualEditPoint() || isAddingFirstOrLastPoint) {
            if (point.isVirtualEditPoint()) {
                // NOTE: promotes virtual point in the middle of the segment to become a parent point
                this.changeVirtualPointToRealPoint(point);
                parentIndex = this.positions.findIndex((position) => position === point);
            }

            inletPoint = parentIndex > 0 ? this.positions[parentIndex - 1] : undefined;

            if (inletPoint) {
                this.changeVirtualPointToRealPoint(inletPoint); // NOTE: promotes new virtual point to become child entity's Inlet point
                this.positions[parentIndex + 1].type = EntityParentCartographicEditPointType.Inlet; // hide virtual point inside child
                inletPoint.type = EntityParentCartographicEditPointType.Inlet;
                inletPoint.entityParent = point;
            }

            parentIndex = this.positions.findIndex((position) => position === point); // NOTE: recalculate index
            outletPoint = parentIndex < this.positions.length - 1 ? this.positions[parentIndex + 1] : undefined;

            if (outletPoint) {
                this.changeVirtualPointToRealPoint(outletPoint); // NOTE: promotes new virtual point to become child entity's Outlet point
                this.positions[parentIndex + 1].type = EntityParentCartographicEditPointType.Outlet; // hide virtual point inside child
                outletPoint.type = EntityParentCartographicEditPointType.Outlet;
                outletPoint.entityParent = point;
            }
        }

        const heightPoints = this._heightPoints.filter(
            (lookup) => lookup.boundEditPoint === point || lookup.boundEditPoint === inletPoint || lookup.boundEditPoint === outletPoint
        );
        heightPoints.forEach((heightPoint) => {
            this.moveHeight(
                {
                    updatedHeightPoint: heightPoint,
                    // NOTE: set height again to update with constraints
                    height: heightPoint.boundEditPoint === point ? entity.topHeight : heightPoint.height,
                    editAction: EditActions.DRAG_POINT,
                    editMode: EditModes.CREATE_OR_EDIT,
                    id: this.id,
                },
                true
            );
        });
        this.updatePointsLayer(true, ...this.positions);

        if (entity.center) {
            this.movePoint(entity.center, point);

            if (inletPoint) {
                const toPosition = this.getStickedPointOnChildEntityOutline(inletPoint, inletPoint.getPosition());

                if (!Cesium.Cartesian3.equalsEpsilon(inletPoint.getPosition(), toPosition, Cesium.Math.EPSILON6)) {
                    this.movePoint(toPosition, inletPoint);
                }
            }

            if (outletPoint) {
                const toPosition = this.getStickedPointOnChildEntityOutline(outletPoint, outletPoint.getPosition());

                if (!Cesium.Cartesian3.equalsEpsilon(outletPoint.getPosition(), toPosition, Cesium.Math.EPSILON6)) {
                    this.movePoint(toPosition, outletPoint);
                }
            }

            this.movePointFinish(point);
        }
    }

    public deleteChildEntity(entityId: string) {
        this.markAsDynamicEntityWhileUpdate();

        const realPoints = this.getRealPoints(false);
        const childEntityParentIndex = realPoints.findIndex((point) => point.childEntity?.id === entityId);

        this.removePosition(realPoints[childEntityParentIndex + 1]);
        this.removePosition(realPoints[childEntityParentIndex]);
        this.removePosition(realPoints[childEntityParentIndex - 1]);

        this.regenerateVirtualEditPoints();
        this.renderPolylines();
    }

    public getStatus(): EntityStatus {
        const realWaypoints = this.getRealWaypoints();

        return {
            isFinished: this.doneCreation,
            canFinish: realWaypoints.length >= MINIMUM_WAYPOINTS,
            canRemovePreviousPoint: realWaypoints.length >= 1,
        };
    }

    public getStickedPointOnChildEntityOutline(point: EntityParentCartographicEditPoint, cursorPosition: Cartesian3): Cartesian3 {
        const realPoints = this.getRealPoints(false);
        const index = realPoints.findIndex((realPoint) => realPoint === point);
        const childEntity = (point.type === EntityParentCartographicEditPointType.Inlet ? realPoints[index + 1] : realPoints[index - 1])
            ?.childEntity;
        const nearestPointCartographic = (
            point.type === EntityParentCartographicEditPointType.Inlet ? realPoints[index - 1] : realPoints[index + 1]
        )?.getCartographic();

        if (!childEntity || !nearestPointCartographic) {
            point.setPosition(cursorPosition);

            return cursorPosition;
        }

        if (!this._cachedTurfedChildEntity || this._cachedTurfedChildEntity.properties?.id !== childEntity.id) {
            // NOTE: caches child entity's polygon as cartographic turf object for performance optimization
            this._cachedTurfedChildEntity =
                childEntity.type === MapEntityType.Prism
                    ? turfPolygon(
                          [
                              [
                                  ...childEntity.positions.map((cartesian) => this.cartesian3ToTurfCoordinates(cartesian)),
                                  this.cartesian3ToTurfCoordinates(childEntity.positions[0]),
                              ],
                          ],
                          { id: childEntity.id }
                      )
                    : turfCircle(this.cartesian3ToTurfCoordinates(childEntity.center), childEntity.radius, {
                          properties: { id: childEntity.id },
                          steps: APPROXIMATED_TURFED_CIRCLE_SIDES,
                          units: "meters",
                      });
        }

        // NOTE: nearest static point - for Inlet it's one before and for Outlet it's one after moved point
        const turfedNearestStaticPoint = [nearestPointCartographic.longitude, nearestPointCartographic.latitude];
        const turfedCursorPoint = this.cartesian3ToTurfCoordinates(cursorPosition);

        // NOTE: calculates maximum distance between nearest static point and furthest polygon's point using length of bbox diagonal
        const [minX, minY, maxX, maxY] = turfBbox(
            turfFeatureCollection<Polygon | Point>([turfPoint(turfedNearestStaticPoint), this._cachedTurfedChildEntity])
        );
        const maxDistance = turfDistance([minX, minY], [maxX, maxY], { units: "meters" });

        const nearestToCursorBearing = turfBearing(turfedNearestStaticPoint, turfedCursorPoint);
        const destinationPoint = turfDestination(turfedNearestStaticPoint, maxDistance + 1, nearestToCursorBearing, {
            units: "meters",
        });
        // NOTE: calculates extended line from nearest point to furthest polygon's point going through current cursor point
        const nearestToCursorExtendedLineString = turfLineString([turfedNearestStaticPoint, destinationPoint.geometry.coordinates]);

        const intersections = turfLineIntersect(nearestToCursorExtendedLineString, this._cachedTurfedChildEntity);
        let targetPoint: Position;

        if (intersections.features.length > 0) {
            // NOTE: if there are intersection between the child entity (polygon) and extended line then use nearest point as output
            targetPoint = turfNearestPoint(turfedNearestStaticPoint, intersections).geometry.coordinates;
        } else {
            // NOTE: if there are no intersections then use tangent point on child entity that is nearest the extended line
            const tangents = turfPolygonTangents(turfedNearestStaticPoint, this._cachedTurfedChildEntity);
            targetPoint =
                turfPointToLineDistance(tangents.features[0], nearestToCursorExtendedLineString) <
                turfPointToLineDistance(tangents.features[1], nearestToCursorExtendedLineString)
                    ? tangents.features[0].geometry.coordinates
                    : tangents.features[1].geometry.coordinates;
        }

        return convertSerializableCartographicToCartesian3({
            longitude: targetPoint[0],
            latitude: targetPoint[1],
            height: 0,
        });
    }

    private cartesian3ToTurfCoordinates(input: Cartesian3): Position {
        const { longitude, latitude } = convertCartesian3ToSerializableCartographic(input);

        return [longitude, latitude];
    }

    public getRealPositions(includeMovingPoint: boolean): Cartesian3[] {
        return this.getRealPoints(includeMovingPoint).map((position) => position.getPosition());
    }

    public getRealHeights(includeMovingPoint: boolean): number[] {
        return this.getRealHeightPoints(includeMovingPoint).map((point) => point.height);
    }

    public getRealBufferWidths(includeMovingPoint: boolean): number[] {
        return this.getRealHeightPoints(includeMovingPoint).map((point) => point.bufferWidth);
    }

    public getRealBufferHeights(includeMovingPoint: boolean): number[] {
        return this.getRealHeightPoints(includeMovingPoint).map((point) => point.bufferHeight);
    }

    public getRealHeightPoints(includeMovingPoint: boolean): Polyline3DHeightPoint[] {
        return this._heightPoints.filter(
            (point) => !point.boundEditPoint.isVirtualEditPoint() && (point.boundEditPoint !== this.movingPoint || includeMovingPoint)
        );
    }

    public getRealPoints(includeMovingPoint: boolean): EntityParentCartographicEditPoint[] {
        return this.positions.filter((position) => !position.isVirtualEditPoint() && (position !== this.movingPoint || includeMovingPoint));
    }

    public getRealWaypoints(): EntityParentCartographicEditPoint[] {
        return this.positions.filter(
            (position) =>
                !position.isVirtualEditPoint() &&
                position !== this.movingPoint &&
                position.type === EntityParentCartographicEditPointType.Waypoint
        );
    }
    public getVirtualPoints(): EntityParentCartographicEditPoint[] {
        return this.positions.filter((position) => position.isVirtualEditPoint());
    }

    public getChildEntities(): ChildEntityCollection {
        return this.getRealPoints(false).reduce((result: ChildEntityCollection, point, index) => {
            if (point.childEntity) {
                result[point.childEntity.id] = {
                    entity: point.childEntity,
                    waypointIndex: index,
                };
            }

            return result;
        }, {});
    }

    public getPoints(): EntityParentCartographicEditPoint[] {
        return this.positions.filter((position) => position !== this.movingPoint);
    }

    public getPositions(): Cartesian3[] {
        return this.positions.map((position) => position.getPosition().clone());
    }

    private removePosition(point?: EntityParentCartographicEditPoint) {
        const index = this.positions.findIndex((position) => position === point);
        if (!point || index < 0) {
            return;
        }

        this.positions.splice(index, 1);
        this._heightPoints.splice(index, 1);
        this.pointsLayer.remove(point.getId());
        this.heightPointsLayer.remove(point.getId());
    }

    private updatePointsLayer(renderPolylines = true, ...points: EntityParentCartographicEditPoint[]) {
        if (renderPolylines) {
            this.renderPolylines();
        }

        points.forEach((point) => this.pointsLayer.update(point, point.getId()));

        this.updateHeightPointsLayer();
    }

    @FunctionUtils.throttle(RENDER_POLYLINES_THROTTLE)
    private updateHeightPointsLayer() {
        this._heightPoints.forEach((point) => this.heightPointsLayer.update(point, point.getId()));
    }

    public update() {
        this.updatePointsLayer();
    }

    public dispose() {
        this.isDisposed = true;

        this.positions.forEach((editPoint) => {
            this.pointsLayer.remove(editPoint.getId());
            this.heightPointsLayer.remove(editPoint.getId());
        });

        this.polylines.forEach((line) => this.polylinesLayer.remove(line.getId()));
        this.buffersLayer.remove(BufferAcEntity.ENTITY_ID_PREFIX + this.id);
        if (this.movingPoint) {
            this.pointsLayer.remove(this.movingPoint.getId());
            this.heightPointsLayer.remove(this.movingPoint.getId());
            this.movingPoint = undefined;
        }
        this.positions.length = 0;
        this._heightPoints.length = 0;
    }

    public getPointsCount(): number {
        return this.positions.length;
    }

    public getId() {
        return this.id;
    }
}
