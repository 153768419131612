import { areCylinderEntitiesPositionsEqual } from "./utils/are-cylinder-entities-equal";
import { arePolyline3DEntitiesPositionsEqual } from "./utils/are-polyline3d-entities-equal";
import { arePrismEntitiesPositionsEqual } from "./utils/are-prism-entities-equal";
import { checkPolygonEdgesIntersections } from "./utils/check-polygon-edges-intersections";
import { convertCartesian3ToSerializableCartographic } from "./utils/convert-cartesian3-to-serializable-cartographic";
import { convertCylinderEntityToGeoJSONFeature } from "./utils/convert-cylinder-entity-to-geojson-feature";
import { convertGeoJsonPolygonGeometryToCartesian3 } from "./utils/convert-geojson-polygon-geometry-to-cartesian3";
import {
    convertPolyline3DEntityToGeoJSONFeature,
    convertPolyline3DEntityToGeoJSONFeatures,
} from "./utils/convert-polyline3d-entity-to-geojson-feature";
import { convertPrismEntityToGeoJSONFeature } from "./utils/convert-prism-entity-to-geojson-feature";
import { convertSerializableCartographicToCartesian3 } from "./utils/convert-serializable-cartographic-to-cartesian3";
import { createCesiumDashPattern } from "./utils/create-cesium-dash-pattern";
import { extractMapEntity } from "./utils/extract-map-entity.pipe";
import { getCesiumImageScaleForGeometry } from "./utils/get-cesium-image-scale-for-geometry";
import { getElevatedCesiumPoint } from "./utils/get-elevated-cesium-point";
import { getPolygonVisualCenter } from "./utils/get-polygon-visual-center";
import { getSmallestEnclosingCircle } from "./utils/get-smallest-enclosing-circle";
import { removeOverlapping } from "./utils/overlap";

export * from "./cesium-map.module";
export { AzureMapsImageryProvider } from "./components/azure-maps-layer/azure-maps-imagery.provider";
export * from "./components/manual-coordinates-input-dialog/manual-coordinates-input-dialog.component";
export * from "./components/manual-width-input-dialog/manual-width-input-dialog.component";
export { MapActionWithPayload, MapActionsPanelMode } from "./components/map-actions-panel/map-actions-panel.component";
export { ViewControl } from "./components/map-layers-with-controls/map-controls.models";
export * from "./models/child-entity.model";
export * from "./models/cylinder/cylinder-edit-options";
export * from "./models/cylinder/cylinder-edit-update";
export * from "./models/cylinder/cylinder-editor-observable";
export * from "./models/cylinder/editable-cylinder";
export * from "./models/default-ac-layer-options";
export * from "./models/entity-status.model";
export * from "./models/polyline3d/editable-polyline3d";
export * from "./models/prism/editable-prism";
export * from "./models/prism/prism-context-menu.model";
export * from "./models/route.models";
export { SerializableCartographic } from "./models/serializable-cartographic";
export * from "./services/camera-helper.service";
export * from "./services/entity-editors/cylinders-editor/cylinders-editor.service";
export * from "./services/entity-editors/cylinders-editor/cylinders-manager.service";
export * from "./services/entity-editors/map-entities-editor.service";
export * from "./services/flight-position-updater/flight-position-updater.service";
export * from "./services/geometry-debugger.service";
export * from "./services/height-helper.service";
export { HeightEntityType } from "./services/height-helper.service";
export * from "./services/pointer-manager/cesium-pointer-manager.service";
export { CylinderGeoJSONProperties } from "./utils/convert-cylinder-entity-to-geojson-feature";
export { Polyline3DGeoJSONProperties } from "./utils/convert-polyline3d-entity-to-geojson-feature";
export { PrismGeoJSONProperties } from "./utils/convert-prism-entity-to-geojson-feature";

export const MapUtils = {
    convertCartesian3ToSerializableCartographic,
    convertSerializableCartographicToCartesian3,
    extractMapEntity,
    getPolygonVisualCenter,
    convertPrismEntityToGeoJSONFeature,
    convertCylinderEntityToGeoJSONFeature,
    convertPolyline3DEntityToGeoJSONFeature,
    convertPolyline3DEntityToGeoJSONFeatures,
    checkPolygonEdgesIntersections,
    getElevatedCesiumPoint,
    getCesiumImageScaleForGeometry,
    areCylinderEntitiesPositionsEqual,
    arePrismEntitiesPositionsEqual,
    arePolyline3DEntitiesPositionsEqual,
    getSmallestEnclosingCircle,
    createCesiumDashPattern,
    convertGeoJsonPolygonGeometryToCartesian3,
};

export const MapLabelsUtils = {
    removeOverlapping,
};
