import { Injectable } from "@angular/core";
import { StateContext } from "@ngxs/store";
import { Observable } from "rxjs";
import { KeycloakTokenResponseBody } from "../../models/keycloak-response.model";
import { AuthStateModel } from "../state/auth.state";

@Injectable()
export abstract class AuthService {
    // TODO DTM-3408
    public abstract init(context: StateContext<AuthStateModel>): void;
    public abstract goToLoginPage(redirectPath?: string): void;
    public abstract logIn(context: StateContext<AuthStateModel>, username: string, password: string): Observable<KeycloakTokenResponseBody>;
    public abstract updateToken(context: StateContext<AuthStateModel>): Observable<KeycloakTokenResponseBody>;
    public abstract logOut(context: StateContext<AuthStateModel>): void;
}
