<div *ngIf="notificationsList$ | ngrxPush as notificationsList" [style.height]="containerHeight$ | ngrxPush">
    <cdk-virtual-scroll-viewport class="container" [itemSize]="LIST_ITEM_SIZE" cdkMenu>
        <dtm-notifications-list-item
            *cdkVirtualFor="let notification of notificationsList; let index = index; trackBy: trackByIndex"
            (click)="handleRedirectAndMarkAsRead(notification.redirectUrl, notification.isRead, notification.id)"
            [class.clickable]="notification.redirectUrl || !notification.isRead"
            [notification]="notification"
            (keyup.enter)="handleRedirectAndMarkAsRead(notification.redirectUrl, notification.isRead, notification.id)"
            cdkMenuItem
        >
        </dtm-notifications-list-item>

        <div *ngIf="isProcessing$ | ngrxPush" class="notifications-spinner">
            <mat-spinner diameter="30" color="accent"></mat-spinner>
        </div>
    </cdk-virtual-scroll-viewport>
</div>
