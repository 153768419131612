<ng-container *ngrxLet="{ imageSrc: imageSrc$, errorMessage: errorMessage$, isRefreshButtonVisible: isRefreshButtonVisible$ } as vm">
    <img [src]="vm.imageSrc" alt="" />
    <div class="content">
        <p *ngIf="vm.errorMessage; else defaultErrorMessageTemplate">{{ vm.errorMessage }}</p>
        <button *ngIf="vm.isRefreshButtonVisible" class="button-primary" type="button" (click)="reload.emit()">
            <dtm-ui-icon name="refresh"></dtm-ui-icon>
            {{ "dtmUi.error.refreshButtonLabel" | transloco }}
        </button>
    </div>

    <ng-template #defaultErrorMessageTemplate>
        <p class="message">
            <span>{{ "dtmUi.error.message" | transloco }}</span>
            <span *ngIf="vm.isRefreshButtonVisible"> {{ "dtmUi.error.refreshMessage" | transloco }}</span>
        </p>
    </ng-template>
</ng-container>
