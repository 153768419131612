<ac-map *ngIf="initialViewbox$ | ngrxPush" dtmMapPointerManager dtmMapCesiumCleanupOnDestroy>
    <dtm-map-azure-maps-layer [options]="AZURE_MAPS_LAYER_OPTIONS"></dtm-map-azure-maps-layer>
    <dss-shared-lib-active-restrictions-map-layer></dss-shared-lib-active-restrictions-map-layer>

    <dtm-map-route-viewer-layer
        [routeData]="routeData$ | ngrxPush"
        [drawableFeatures]="drawableFeatures$ | ngrxPush"
        [isShown]="true"
        (entityZoom)="cameraHelperService.flyToGeoJSON($event)"
    ></dtm-map-route-viewer-layer>
</ac-map>

<div class="zone-settings" [formGroup]="drawingSettingsForm">
    <mat-slide-toggle formControlName="safetyArea" labelPosition="before">
        {{ "dssClientLibPlannedMissions.mapControls.safetyAreaLabel" | transloco }}
    </mat-slide-toggle>
</div>
<dtm-map-controls></dtm-map-controls>
