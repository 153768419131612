import { ApplicationType, FlightZoneApplicationBasicData, RestrictionAreaUnits, RestrictionExclusions } from "@dtm-frontend/dss-shared-lib";
import { CylinderEntity, PrismEntity } from "@dtm-frontend/shared/map/cesium";
import { ZoneDurationChangeDialogData, ZoneDurationChangeParams } from "../models/flight-zone.models";

export namespace FlightZoneActions {
    export class GetApplicationData {
        public static readonly type = "[Flight Zone] Get flight zone application data";

        constructor(public flightZoneId: string, public applicationType: ApplicationType) {}
    }

    export class ClearApplicationData {
        public static readonly type = "[Flight Zone] Clear flight zone application data";

        constructor() {}
    }

    export class UpdateBasicData {
        public static readonly type = "[Flight Zone] Update application basic data form";

        constructor(public formData: FlightZoneApplicationBasicData) {}
    }

    export class UpdateActiveGeometryUnits {
        public static readonly type = "[Flight Zone] Update active geometry units";

        constructor(public data: RestrictionAreaUnits) {}
    }

    export class UpdateActiveCylinderGeometryValues {
        public static readonly type = "[Flight Zone] Update active cylinder geometry values";

        constructor(public cylinder: CylinderEntity) {}
    }

    export class UpdateActivePrismGeometryValues {
        public static readonly type = "[Flight Zone] Update active prism geometry values";

        constructor(public prism: PrismEntity) {}
    }

    export class UpdateRestrictionExclusionsData {
        public static readonly type = "[Flight Zone] Update restriction exclusions data";

        constructor(public data: RestrictionExclusions) {}
    }

    export class SaveApplicationDraft {
        public static readonly type = "[Flight Zone] Save application draft";

        constructor(public flightZoneId?: string) {}
    }

    export class DeleteApplication {
        public static readonly type = "[Flight Zone] Delete application";

        constructor(public flightZoneId: string) {}
    }

    export class ConfirmCorrections {
        public static readonly type = "[Flight Zone] Confirm application corrections";

        constructor(public flightZoneId: string) {}
    }

    export class SendApplication {
        public static readonly type = "[Flight Zone] Send application";

        constructor(public flightZoneId: string, public isAcceptanceRequired: boolean) {}
    }

    export class RejectApplication {
        public static readonly type = "[Flight Zone] Reject application";

        constructor(public flightZoneId: string) {}
    }

    export class GetNotamData {
        public static readonly type = "[Flight Zone] Get notam data";

        constructor(public flightZoneId: string) {}
    }

    export class GetChatMessagesByFlightZoneId {
        public static readonly type = "[Flight Zone] Get chat messages for application changes";

        constructor(public flightZoneId: string) {}
    }

    export class PostChatMessage {
        public static readonly type = "[Flight Zone] Post chat message to application changes";

        constructor(public flightZoneId: string, public comment: string) {}
    }

    export class ChangeZoneDuration {
        public static readonly type = "[Flight Zone Management] Change zone duration time";

        constructor(public zoneDurationData: ZoneDurationChangeParams) {}
    }

    export class EditRestrictionModification {
        public static readonly type = "[Flight Zone Management] Edit restriction modification";

        constructor(public zoneDurationData: ZoneDurationChangeDialogData) {}
    }
}
