import { HttpContextToken, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslocoService } from "@jsverse/transloco";
import { ToastrService } from "ngx-toastr";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpErrorResponseBody } from "../models/http-error.models";

export const ERROR_PAGE_ROUTE = "error";
export const SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR = new HttpContextToken(() => true);
const SERVER_ERROR_REGEX = /^5\d{2}$/;
const TOO_MANY_REQUESTS_STATUS_CODE = 429;
const OFFLINE_STATUS_CODE = 0;

@Injectable()
export class GlobalHttpErrorsInterceptor implements HttpInterceptor {
    constructor(private readonly router: Router, private readonly toastr: ToastrService, private readonly transloco: TranslocoService) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler) {
        if (request.context.has(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR)) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponseBody) => {
                if (error.status.toString().match(SERVER_ERROR_REGEX)) {
                    this.router.navigate([ERROR_PAGE_ROUTE], {
                        state: { status: error.status },
                        skipLocationChange: true,
                    });
                }

                switch (error.status) {
                    case TOO_MANY_REQUESTS_STATUS_CODE:
                        this.toastr.error(this.transloco.translate("dtmUi.tooManyRequestsError"));
                        break;
                    case OFFLINE_STATUS_CODE: {
                        const message = this.transloco.translate("dtmUi.offlineError");
                        if (!this.toastr.findDuplicate("", message, true, false)) {
                            this.toastr.error(message, "");
                        }
                        break;
                    }
                }

                return throwError(() => error);
            })
        );
    }
}
