import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface FiltersContainerComponentState {
    isExpanded: boolean;
    filtersCount: number;
}

@Component({
    selector: "dtm-ui-filters-container",
    templateUrl: "./filters-container.component.html",
    styleUrls: ["./filters-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FiltersContainerComponent {
    @Input() public set isExpanded(value: boolean | undefined) {
        this.localStore.patchState({ isExpanded: !!value });
    }
    @Input() public set filtersCount(value: number | undefined) {
        this.localStore.patchState({ filtersCount: value ?? 0 });
    }

    public readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    public readonly filtersCount$ = this.localStore.selectByKey("filtersCount");

    constructor(private readonly localStore: LocalComponentStore<FiltersContainerComponentState>) {
        localStore.setState({ isExpanded: true, filtersCount: 0 });
    }
}
