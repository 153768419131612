<ac-map dtmMapPointerManager>
    <dtm-map-azure-maps-layer [options]="AZURE_MAPS_LAYER_OPTIONS"></dtm-map-azure-maps-layer>
    <ac-map-layer-provider
        [provider]="MapLayerProviderOptions.WebMapService"
        [options]="
            $any({
                url: sharedMapEndpoints.geoServerEndpoint,
                layers: sharedMapEndpoints.layersPrefix + 'mission-planner',
                enablePickFeatures: false,
                parameters: {
                    transparent: true,
                    format: 'image/png'
                }
            })
        "
    >
    </ac-map-layer-provider>
    <dtm-map-cylinders-editor [show]="true" [labelsTemplate]="labelsTemplate"></dtm-map-cylinders-editor>
    <dtm-map-prisms-editor [show]="true" [labelsTemplate]="labelsTemplate"></dtm-map-prisms-editor>
</ac-map>

<dtm-map-controls></dtm-map-controls>

<ng-template #labelsTemplate let-label>
    <div class="label-pin default-label" *ngIf="label.show && label.text">
        {{
            "dtmSharedMissionSearch.filterConditions.radiusLabel"
                | transloco : { radius: label.text | localizeNumber : { maximumFractionDigits: 0 } }
        }}
    </div>
</ng-template>
