export function compareDates(left?: Date, right?: Date, epsilon?: "hours" | "minutes" | "seconds" | "milliseconds"): boolean {
    if (!left || !right) {
        return false;
    }
    const leftDate = new Date(left);
    const rightDate = new Date(right);

    if (epsilon === "seconds") {
        leftDate.setSeconds(0, 0);
        rightDate.setSeconds(0, 0);
    }

    if (epsilon === "milliseconds") {
        leftDate.setMilliseconds(0);
        rightDate.setMilliseconds(0);
    }

    if (epsilon === "minutes") {
        leftDate.setMinutes(0, 0, 0);
        rightDate.setMinutes(0, 0, 0);
    }

    if (epsilon === "hours") {
        leftDate.setHours(0, 0, 0, 0);
        rightDate.setHours(0, 0, 0, 0);
    }

    return leftDate.getTime() === rightDate.getTime();
}
