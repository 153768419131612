import { HttpErrorResponse, HttpEvent, HttpStatusCode } from "@angular/common/http";
import { InjectionToken } from "@angular/core";
import { BYTES_IN_MEGABYTE, UploadedFile as UploadedFileBody } from "@dtm-frontend/shared/utils";
import { Observable } from "rxjs";

export interface UploadedFile {
    id: string;
    name: string;
    size?: number;
}

export interface ProcessedFile {
    id: string;
    file: File;
    progress: number;
    error?: string;
    uploadedFile?: UploadedFile;
}

export enum FileUploadErrorType {
    MaxSizeExceeded = "FileUploadErrorMaxSizeExceeded",
    InfectedFile = "FileUploadErrorInfectedFile",
    InvalidType = "FileUploadErrorInvalidType",
    Unknown = "FileUploadErrorUnknown",
}

const INFECTED_FILE_GENERAL_MESSAGE = "user_directory.infected_file";
export const isUploadedFileInfectedError = (error: HttpErrorResponse): boolean =>
    error.status === HttpStatusCode.UnprocessableEntity && error.error.generalMessage.includes(INFECTED_FILE_GENERAL_MESSAGE);

export interface FilesUploadApi {
    uploadFile(file: File, additionalPathParams?: Record<string, string>): Observable<HttpEvent<UploadedFileBody>>;
    getFile(fileId: string, additionalPathParams?: Record<string, string>): Observable<Blob>;
    getFilesCompressed?(fileIds: string[], fileName: string, additionalPathParams?: Record<string, string>): Observable<Blob>;
    manageUploadError(error: HttpErrorResponse): { type: FileUploadErrorType };
}

// eslint-disable-next-line no-magic-numbers
export const MAX_UPLOADED_FILE_SIZE_BYTES = 10 * BYTES_IN_MEGABYTE;

export const FILES_UPLOAD_API_PROVIDER = new InjectionToken<FilesUploadApi>("Files Upload Api Provider");
