import { ChangeDetectionStrategy, Component, forwardRef, Input, Output } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    UntypedFormGroup,
    Validator,
    ValidatorFn,
} from "@angular/forms";
import { FunctionUtils, HOURS_IN_DAY, LocalComponentStore, MINUTES_IN_HOUR } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

interface TimeSetterComponentState {
    initialValue: Date | undefined;
    minutes: number | undefined;
    hours: number | undefined;
    minTime: Date | undefined;
    maxTime: Date | undefined;
}

export type TimeSetterErrors = { notInRange?: boolean } | null;

@UntilDestroy()
@Component({
    selector: "dtm-ui-time-setter",
    templateUrl: "./time-setter.component.html",
    styleUrls: ["./time-setter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimeSetterComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TimeSetterComponent),
            multi: true,
        },
    ],
})
export class TimeSetterComponent implements ControlValueAccessor, Validator {
    @Input()
    public set minTime(value: Date) {
        this.localStore.patchState({ minTime: value });
    }

    @Input()
    public set maxTime(value: Date) {
        this.localStore.patchState({ maxTime: value });
    }

    public readonly minutesFormControl = new UntypedFormControl();
    public readonly hoursFormControl = new UntypedFormControl();
    public readonly timeFormGroup: UntypedFormGroup = new UntypedFormGroup({
        minutes: this.minutesFormControl,
        hours: this.hoursFormControl,
    });

    @Output()
    public readonly errors: Observable<TimeSetterErrors> = this.timeFormGroup.statusChanges.pipe(map(() => this.timeFormGroup.errors));

    private onTouched = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<TimeSetterComponentState>) {
        this.localStore.setState({
            initialValue: undefined,
            minTime: undefined,
            hours: undefined,
            minutes: undefined,
            maxTime: undefined,
        });

        this.timeFormGroup.setValidators(this.validateTimeRange());

        this.watchMinutesFormChanges();
        this.watchHoursFormChanges();
    }

    private watchMinutesFormChanges() {
        this.minutesFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((minutes) => {
            const lastHoursValue = this.localStore.selectSnapshotByKey("hours");
            if (!lastHoursValue) {
                return;
            }

            if (minutes === MINUTES_IN_HOUR) {
                this.hoursFormControl.setValue(+lastHoursValue + 1);
                this.minutesFormControl.setValue(0, { emitEvent: false });
            }
            if (minutes > MINUTES_IN_HOUR) {
                this.minutesFormControl.setValue(MINUTES_IN_HOUR - 1, { emitEvent: false });
            }
            if (minutes === -1) {
                this.hoursFormControl.setValue(lastHoursValue - 1 > 0 ? lastHoursValue - 1 : HOURS_IN_DAY - 1);
                this.minutesFormControl.setValue(MINUTES_IN_HOUR - 1, { emitEvent: false });
            }
            if (minutes < -1) {
                this.minutesFormControl.setValue(0, { emitEvent: false });
            }

            this.minutesFormControl.setValue(this.formatNumberValue(this.minutesFormControl.value), { emitEvent: false });

            this.localStore.patchState({
                hours: this.hoursFormControl.value,
                minutes: this.minutesFormControl.value,
            });
        });
    }

    private watchHoursFormChanges() {
        this.hoursFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((hours) => {
            if (hours === HOURS_IN_DAY) {
                this.hoursFormControl.setValue(0, { emitEvent: false });
            }
            if (hours > HOURS_IN_DAY) {
                this.hoursFormControl.setValue(HOURS_IN_DAY - 1, { emitEvent: false });
            }
            if (hours === -1) {
                this.hoursFormControl.setValue(HOURS_IN_DAY - 1, { emitEvent: false });
            }
            if (hours < -1) {
                this.hoursFormControl.setValue(0, { emitEvent: false });
            }

            this.hoursFormControl.setValue(this.formatNumberValue(this.hoursFormControl.value), { emitEvent: false });

            this.localStore.patchState({
                hours: this.hoursFormControl.value,
                minutes: this.minutesFormControl.value,
            });
        });
    }

    private validateTimeRange(): ValidatorFn {
        return ({ value: { hours, minutes } }: AbstractControl) => {
            const maxTime = this.localStore.selectSnapshotByKey("maxTime");
            const minTime = this.localStore.selectSnapshotByKey("minTime");

            if (!maxTime || !minTime) {
                return null;
            }

            if (this.getSelectedTime(minTime, maxTime, minutes, hours)) {
                return null;
            }

            return { notInRange: true };
        };
    }

    private initValueOutput(): Observable<Date | undefined> {
        return this.localStore
            .select((state) => state)
            .pipe(map(({ maxTime, minTime, hours, minutes }) => this.getSelectedTime(minTime, maxTime, minutes, hours)));
    }

    private isTimeInRange(time: Date, minTime: Date, maxTime: Date): boolean {
        return minTime.getTime() <= time.getTime() && time.getTime() <= maxTime.getTime();
    }

    private getSelectedTime(
        minTime: Date | undefined,
        maxTime: Date | undefined,
        minutes: number | undefined,
        hours: number | undefined
    ): Date | undefined {
        if (hours === undefined || minutes === undefined) {
            return undefined;
        }

        if (!maxTime || !minTime) {
            const initialDate = this.localStore.selectSnapshotByKey("initialValue");
            initialDate?.setMinutes(minutes);
            initialDate?.setHours(hours);

            return initialDate ? new Date(initialDate) : undefined;
        }

        const selectedTimeDateBasedOnMinTime = new Date(minTime);
        selectedTimeDateBasedOnMinTime.setMinutes(minutes);
        selectedTimeDateBasedOnMinTime.setHours(hours);

        if (this.isTimeInRange(selectedTimeDateBasedOnMinTime, minTime, maxTime)) {
            return selectedTimeDateBasedOnMinTime;
        }

        const selectedTimeDateBasedOnMaxTime = new Date(maxTime);
        selectedTimeDateBasedOnMaxTime.setMinutes(minutes);
        selectedTimeDateBasedOnMaxTime.setHours(hours);

        if (this.isTimeInRange(selectedTimeDateBasedOnMaxTime, minTime, maxTime)) {
            return selectedTimeDateBasedOnMaxTime;
        }

        return undefined;
    }

    private formatNumberValue(value: number): string {
        return `${value}`.padStart(2, "0");
    }

    public registerOnChange(fn: (value: Date | undefined) => void): void {
        this.initValueOutput().pipe(untilDestroyed(this)).subscribe(fn);
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: Date | undefined): void {
        const minutes = value?.getMinutes() ?? 0;
        const hours = value?.getHours() ?? 0;

        this.timeFormGroup.setValue({
            minutes: this.formatNumberValue(minutes),
            hours: this.formatNumberValue(hours),
        });

        this.localStore.patchState({
            initialValue: value,
            hours,
            minutes,
        });
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): TimeSetterErrors {
        if (this.timeFormGroup.invalid) {
            return { notInRange: true };
        }

        return null;
    }
}
