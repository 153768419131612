import { AirspaceElementsInfo } from "../models/geo-zones.models";
import { AirspaceElementsSearchOptions } from "../services/geo-zones-api.service";

export namespace GeoZonesActions {
    export class SearchAirspaceElements {
        public static readonly type = "[Geo Zones] Search airspace elements";

        constructor(public options: AirspaceElementsSearchOptions, public shouldClearInfo = false) {}
    }

    export class SetCustomElements {
        public static readonly type = "[Geo Zones] Set custom elements";

        constructor(public elements?: AirspaceElementsInfo) {}
    }

    export class SetCustomZonesVisibility {
        public static readonly type = "[Geo Zones] Set custom zones visibility";

        constructor(public isVisible: boolean) {}
    }

    export class SetSelectedZoneId {
        public static readonly type = "[Geo Zones] Set selected zone id";

        constructor(public id: string | undefined) {}
    }

    export class Cleanup {
        public static readonly type = "[Geo Zones] Cleanup";
    }

    export class GetAirspaceElementsWithInfo {
        public static readonly type = "[Geo Zones] Get airspace elements with info";

        constructor(public options: AirspaceElementsSearchOptions) {}
    }

    export class StartAirspaceUpdatesWatch {
        public static readonly type = "[Geo Zones] Start airspace updates watch";
    }

    export class EnableAllGeoZones {
        public static readonly type = "[Geo Zones] Enable all geo zones";
    }

    export class ToggleGeoZonesInfo {
        public static readonly type = "[Geo Zones] Toggle geo zones info";
    }
}
