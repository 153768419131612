<mat-card>
    <dtm-ui-card-header>
        {{ "dtmUi.userContact.contactDataHeader" | transloco }}
    </dtm-ui-card-header>
    <div class="user-data-edit-container" *ngrxLet="isVerificationRequired$ as isVerificationRequired">
        <dtm-ui-edit-email-address
            [email]="email$ | ngrxPush"
            [isRequestedEmailChange]="isRequestedEmailChange$ | ngrxPush"
            [hasEmailAddressConflictError]="hasRequestEmailChangeConflictError$ | ngrxPush"
            [isVerificationRequired]="isVerificationRequired"
            [isEditMode]="isEmailAddressEditMode$ | ngrxPush"
            [saveButtonLabel]="saveButtonLabel$ | ngrxPush"
            (requestDataChange)="userProfileDataUpdate($event)"
            (closePanel)="clearEmailAddressChange.emit()"
        ></dtm-ui-edit-email-address>
        <dtm-ui-edit-phone-number
            [phoneNumber]="phoneNumber$ | ngrxPush"
            [isRequestedPhoneChange]="isRequestedPhoneChange$ | ngrxPush"
            [hasPhoneNumberConflictError]="hasRequestPhoneChangeConflictError$ | ngrxPush"
            [isVerificationRequired]="isVerificationRequired"
            [isEditMode]="isPhoneNumberEditMode$ | ngrxPush"
            [saveButtonLabel]="saveButtonLabel$ | ngrxPush"
            (requestDataChange)="userProfileDataUpdate($event)"
            (closePanel)="clearPhoneNumberChange.emit()"
        ></dtm-ui-edit-phone-number>
    </div>
</mat-card>
