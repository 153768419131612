import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TrafficMissionData } from "../../models/mission-plan-verification.models";
import { MissionType } from "../../models/mission.models";

interface TrafficAnalysisComponentState {
    collisionMissions: TrafficMissionData[];
    nearbyMissions: TrafficMissionData[];
    isProcessing: boolean;
    isExpanded: boolean;
    selectedOtherMissionId: string | undefined;
}

@Component({
    selector: "dtm-mission-traffic-analysis",
    templateUrl: "./traffic-analysis.component.html",
    styleUrls: ["./traffic-analysis.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrafficAnalysisComponent {
    @Input() public set collisionMissions(value: TrafficMissionData[] | undefined) {
        this.localStore.patchState({ collisionMissions: value ?? [] });
    }

    @Input() public set nearbyMissions(value: TrafficMissionData[] | undefined) {
        this.localStore.patchState({ nearbyMissions: value ?? [] });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    @Output() public readonly flyToMainMissionRoute = new EventEmitter<void>();
    @Output() public readonly flyToRoute = new EventEmitter<string>();
    @Output() public readonly otherMissionSelectedId = new EventEmitter<string>();

    protected readonly collisionMissions$ = this.localStore.selectByKey("collisionMissions");
    protected readonly nearbyMissions$ = this.localStore.selectByKey("nearbyMissions");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly selectedOtherMissionId$ = this.localStore.selectByKey("selectedOtherMissionId");

    protected readonly MissionType = MissionType;

    private cameraStateId: string | undefined;

    constructor(private readonly localStore: LocalComponentStore<TrafficAnalysisComponentState>) {
        localStore.setState({
            collisionMissions: [],
            nearbyMissions: [],
            isProcessing: false,
            isExpanded: true,
            selectedOtherMissionId: undefined,
        });
    }

    protected toggleZoom(routeId: string) {
        if (this.cameraStateId === routeId) {
            this.flyToMainMissionRoute.emit();
            this.cameraStateId = undefined;

            return;
        }

        this.cameraStateId = routeId;
        this.flyToRoute.emit(routeId);
    }

    protected toggleSelection(missionId: string) {
        this.localStore.patchState(({ selectedOtherMissionId }) => ({
            selectedOtherMissionId: selectedOtherMissionId === missionId ? undefined : missionId,
        }));

        this.otherMissionSelectedId.emit(this.localStore.selectSnapshotByKey("selectedOtherMissionId"));
    }
}
