import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
    AbstractControl,
    FormControl,
    FormGroup,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import { MatLegacySelectChange as MatSelectChange } from "@angular/material/legacy-select";
import {
    FlightZoneGeometryConstraints,
    FlightZoneMapBaseGeometry,
    FlightZoneUtils,
    HeightReferences,
    RestrictionAreaPrismUnits,
    RestrictionAreaPrismValues,
    VerticalMeasureUnits,
} from "@dtm-frontend/dss-shared-lib";
import { MINIMUM_VERTEX_NUMBER, MapUtils, PrismEntity, SerializableCartographic } from "@dtm-frontend/shared/map/cesium";
import { GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { DEFAULT_DEBOUNCE_TIME, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Position } from "@turf/helpers";
import { Observable, debounceTime } from "rxjs";
import { distinctUntilChanged, map, withLatestFrom } from "rxjs/operators";
import { GeometryHeightLimiterService } from "../../../../services/geometry-height-limiter.service";

interface ZoneGeometryStepPrismEditorComponentState extends FlightZoneMapBaseGeometry {
    isProcessing: boolean;
    prismEntity: PrismEntity | undefined;
    prismPointLimit: number;
    hasBeenUpdatedByMapActions: boolean;
}

interface PrismPointAdditionProperties {
    longitude: number;
    latitude: number;
    newPointIndex?: number;
}

@UntilDestroy()
@Component({
    selector: "dss-client-lib-zone-geometry-step-prism-editor[prismEntity][constraints][verticalMeasureUnits][heightReferences]",
    templateUrl: "./zone-geometry-step-prism-editor.component.html",
    styleUrls: ["./zone-geometry-step-prism-editor.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ZoneGeometryStepPrismEditorComponent implements OnInit {
    protected readonly GeographicCoordinatesType = GeographicCoordinatesType;
    protected readonly MINIMUM_VERTEX_NUMBER = MINIMUM_VERTEX_NUMBER;

    protected readonly lowerHeightFormControl = new UntypedFormControl(null);
    protected readonly lowerHeightUnitFormControl = new UntypedFormControl(null);
    protected readonly lowerHeightReferenceFormControl = new UntypedFormControl(null);
    protected readonly upperHeightFormControl = new UntypedFormControl(null);
    protected readonly upperHeightUnitFormControl = new UntypedFormControl(null);
    protected readonly upperHeightReferenceFormControl = new UntypedFormControl(null);
    protected readonly positionsArray = new UntypedFormArray([], {
        validators: [this.positionsSelfIntersectionsValidator.bind(this), Validators.required],
    });
    protected readonly prismEditorForm = this.initPrismEditorForm();

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly prismEntity$ = this.localStore.selectByKey("prismEntity");
    protected readonly constraints$ = this.localStore.selectByKey("constraints");
    protected readonly verticalMeasureUnits$ = this.localStore.selectByKey("verticalMeasureUnits");
    protected readonly heightReferences$ = this.localStore.selectByKey("heightReferences");
    protected readonly prismPointLimit$ = this.localStore.selectByKey("prismPointLimit");
    protected readonly maxLowerHeight$ = this.geometryHeightLimiterService.getMaxLowerHeightObservable(
        this.prismEditorForm,
        this.localStore.selectByKey("constraints")
    );
    protected readonly minUpperHeight$ = this.geometryHeightLimiterService.getMinUpperHeightObservable(
        this.prismEditorForm,
        this.localStore.selectByKey("constraints")
    );

    @Input()
    public set isProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isProcessing: !!value });
    }

    @Input()
    public set constraints(value: FlightZoneGeometryConstraints) {
        this.localStore.patchState({ constraints: value });
    }

    @Input()
    public set prismEntity(value: PrismEntity | undefined) {
        this.positionsArray.clear();

        if (!value) {
            this.localStore.patchState({ prismEntity: value });

            return;
        }

        const upperHeightRounded: number = FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(
            value.topHeight ?? 0,
            this.upperHeightUnitFormControl.value,
            0
        );

        const lowerHeightRounded: number = FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(
            value.bottomHeight,
            this.lowerHeightUnitFormControl.value,
            0
        );

        this.upperHeightFormControl.setValue(upperHeightRounded);
        this.lowerHeightFormControl.setValue(lowerHeightRounded);

        value.positions.forEach((prismPointPosition) => {
            const cartographic = MapUtils.convertCartesian3ToSerializableCartographic(prismPointPosition);
            this.addPrismPoint({ longitude: cartographic.longitude, latitude: cartographic.latitude });
        });

        this.prismEditorForm.markAllAsTouched();

        this.localStore.patchState({
            prismEntity: {
                ...value,
                topHeight: upperHeightRounded,
                bottomHeight: lowerHeightRounded,
            },
            hasBeenUpdatedByMapActions: true,
        });
    }

    @Input()
    public set verticalMeasureUnits(value: VerticalMeasureUnits[] | undefined) {
        this.localStore.patchState({ verticalMeasureUnits: value ?? [] });
    }

    @Input()
    public set heightReferences(value: HeightReferences[] | undefined) {
        this.localStore.patchState({ heightReferences: value ?? [] });
    }

    @Input()
    public set activeGeometryUnits(value: RestrictionAreaPrismUnits | undefined) {
        if (!value) {
            return;
        }

        this.prismEditorForm.patchValue({ ...value });
    }

    @Input()
    public set prismPointLimit(value: number | undefined) {
        if (value) {
            this.localStore.patchState({ prismPointLimit: value });
        }
    }

    @Output() public prismEntityUpdate = new EventEmitter<{ entity: PrismEntity; units: RestrictionAreaPrismUnits }>();
    @Output() public statusChange: Observable<boolean> = this.prismEditorForm.statusChanges.pipe(
        distinctUntilChanged(),
        map((status) => status === "VALID")
    );

    constructor(
        private readonly localStore: LocalComponentStore<ZoneGeometryStepPrismEditorComponentState>,
        private readonly geometryHeightLimiterService: GeometryHeightLimiterService
    ) {
        this.localStore.setState({
            isProcessing: false,
            prismEntity: undefined,
            constraints: undefined,
            horizontalMeasureUnits: [],
            verticalMeasureUnits: [],
            heightReferences: [],
            prismPointLimit: Number.MAX_VALUE,
            hasBeenUpdatedByMapActions: false,
        });
    }

    public ngOnInit(): void {
        this.listenToPrismEntityChanges();
    }

    protected updateHeightValue(unit: MatSelectChange, heightFormControl: FormControl): void {
        const convertMethod =
            unit.value === VerticalMeasureUnits.Feet
                ? FlightZoneUtils.metersToOtherUnitsOfMeasureRounded
                : FlightZoneUtils.otherUnitsOfMeasureToMetersRounded;
        heightFormControl.setValue(convertMethod(heightFormControl.value, VerticalMeasureUnits.Feet, 0));
    }

    protected insertNewPrismPoint(newPointIndex: number): void {
        const isLastPoint = this.positionsArray.length <= newPointIndex;
        const nextPointIndex = isLastPoint ? 0 : newPointIndex;
        const nextPointCoordinates = this.positionsArray.at(nextPointIndex).value;
        const previousPointCoordinates = this.positionsArray.at(newPointIndex - 1).value;

        const halfwayLongitude = (previousPointCoordinates.longitude + nextPointCoordinates.longitude) / 2;
        const halfwayLatitude = (previousPointCoordinates.latitude + nextPointCoordinates.latitude) / 2;

        this.addPrismPoint({ longitude: halfwayLongitude, latitude: halfwayLatitude, newPointIndex });
    }

    protected removePrismPoint(pointIndex: number): void {
        this.positionsArray.removeAt(pointIndex);
    }

    private addPrismPoint({ newPointIndex, longitude, latitude }: PrismPointAdditionProperties): void {
        const prismPointLimit = this.localStore.selectSnapshotByKey("prismPointLimit");

        if (this.positionsArray.length >= prismPointLimit) {
            return;
        }

        const prismPoint = new FormGroup({
            longitude: new FormControl<number | null>(longitude),
            latitude: new FormControl<number | null>(latitude),
        });

        if (newPointIndex) {
            this.positionsArray.insert(newPointIndex, prismPoint);
        } else {
            this.positionsArray.push(prismPoint);
        }
    }

    private initPrismEditorForm(): UntypedFormGroup {
        const formBody: Record<keyof RestrictionAreaPrismValues & RestrictionAreaPrismUnits, UntypedFormControl> = {
            lowerHeight: this.lowerHeightFormControl,
            lowerHeightUnit: this.lowerHeightUnitFormControl,
            lowerHeightReference: this.lowerHeightReferenceFormControl,
            upperHeight: this.upperHeightFormControl,
            upperHeightUnit: this.upperHeightUnitFormControl,
            upperHeightReference: this.upperHeightReferenceFormControl,
            positions: this.positionsArray,
        };

        return new UntypedFormGroup(formBody);
    }

    private positionsSelfIntersectionsValidator(control: AbstractControl): ValidationErrors | null {
        const positions: Position[] = control.value.map((position: SerializableCartographic) => [
            position.latitude,
            position.longitude,
            position.height || 0,
        ]);
        const arePositionsValid = MapUtils.checkPolygonEdgesIntersections(positions);

        return arePositionsValid ? null : { intersections: true };
    }

    private listenToPrismEntityChanges(): void {
        this.prismEditorForm.valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), withLatestFrom(this.prismEntity$), untilDestroyed(this))
            .subscribe(([formValue, currentPrismEntity]) => {
                if (!currentPrismEntity) {
                    return;
                }

                // TODO: Temporary fix (DSS-1047) - Prevent emit if update came from map actions via input
                const hasBeenUpdatedByMapActions = this.localStore.selectSnapshotByKey("hasBeenUpdatedByMapActions");
                if (hasBeenUpdatedByMapActions) {
                    this.localStore.patchState({ hasBeenUpdatedByMapActions: false });

                    return;
                }

                const cartesianPrismPointPositions = formValue.positions.map((position: SerializableCartographic) =>
                    MapUtils.convertSerializableCartographicToCartesian3({
                        latitude: position.latitude,
                        longitude: position.longitude,
                        height: position.height,
                    })
                );

                this.prismEntityUpdate.emit({
                    entity: {
                        ...currentPrismEntity,
                        center: undefined,
                        positions: cartesianPrismPointPositions,
                        bottomHeight: FlightZoneUtils.otherUnitsOfMeasureToMeters(formValue.lowerHeight, formValue.lowerHeightUnit),
                        topHeight: FlightZoneUtils.otherUnitsOfMeasureToMeters(formValue.upperHeight, formValue.upperHeightUnit),
                    },
                    units: {
                        lowerHeightUnit: formValue.lowerHeightUnit,
                        lowerHeightReference: formValue.lowerHeightReference,
                        upperHeightUnit: formValue.upperHeightUnit,
                        upperHeightReference: formValue.upperHeightReference,
                    },
                });
            });
    }
}
