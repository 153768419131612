<ng-container *ngIf="uavMtomInputMode.value === MTOMInputMode.Precision">
    <div class="slider-inputs">
        <dtm-ui-input-field>
            <label for="mtomFromControl">{{
                "dssClientLibFlightZone.zoneRestrictionExecutionsStep.uavMtomExclusionLowerLabel" | transloco
            }}</label>
            <input
                id="mtomFromControl"
                matInput
                type="number"
                inputmode="decimal"
                autocomplete="off"
                [formControl]="lowerUavMtomExclusionFormControl"
                [dtmUiDecimalDigitsLimiter]="2"
                (blur)="onBlur()"
            />
            <div class="field-suffix">{{ "dssClientLibFlightZone.zoneRestrictionExecutionsStep.mtomUnit" | transloco }}</div>
            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="formErrorsTemplate"
                    [ngTemplateOutletContext]="{ formControl: lowerUavMtomExclusionFormControl }"
                ></ng-container>
            </div>
        </dtm-ui-input-field>
        <dtm-ui-input-field>
            <label for="mtomToControl">{{
                "dssClientLibFlightZone.zoneRestrictionExecutionsStep.uavMtomExclusionUpperLabel" | transloco
            }}</label>
            <input
                id="mtomToControl"
                matInput
                type="number"
                inputmode="decimal"
                autocomplete="off"
                [formControl]="upperUavMtomExclusionFormControl"
                [dtmUiDecimalDigitsLimiter]="2"
                (blur)="onBlur()"
            />
            <div class="field-suffix">{{ "dssClientLibFlightZone.zoneRestrictionExecutionsStep.mtomUnit" | transloco }}</div>
            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="formErrorsTemplate"
                    [ngTemplateOutletContext]="{ formControl: upperUavMtomExclusionFormControl }"
                ></ng-container>
            </div>
        </dtm-ui-input-field>
    </div>
    <button class="button-secondary" (click)="uavMtomInputMode.setValue(MTOMInputMode.Slider)" type="button">
        {{ "dssClientLibFlightZone.zoneRestrictionExecutionsStep.MTOMSliderModeLabel" | transloco }}
    </button>
</ng-container>

<ng-container *ngIf="uavMtomInputMode.value === MTOMInputMode.Slider">
    <div class="slider">
        <!-- TODO: DTM-2117 last usage of ngx-slider -->
        <ngx-slider
            class="dtm-slider"
            [value]="lowerUavMtomExclusionFormControl.value"
            [highValue]="upperUavMtomExclusionFormControl.value"
            [options]="rangeSliderOptions"
            (valueChange)="updateSliderValues($event, lowerUavMtomExclusionFormControl)"
            (highValueChange)="updateSliderValues($event, upperUavMtomExclusionFormControl)"
        ></ngx-slider>
    </div>

    <button class="button-secondary" (click)="uavMtomInputMode.setValue(MTOMInputMode.Precision)" type="button">
        {{ "dssClientLibFlightZone.zoneRestrictionExecutionsStep.MTOMInputModeLabel" | transloco }}
    </button>
</ng-container>

<ng-template #formErrorsTemplate let-formControl="formControl">
    <ng-container *ngIf="formControl.errors?.required">
        {{ "dssClientLibFlightZone.requiredFieldErrorHint" | transloco }}
    </ng-container>

    <ng-container *ngIf="formControl.errors?.min">
        {{
            "dssClientLibFlightZone.minValueErrorHint"
                | transloco
                    : {
                          min: formControl.errors?.min.min | localizeNumber,
                          unit: "dssClientLibFlightZone.weightUnitLabel" | transloco
                      }
        }}
    </ng-container>
    <ng-container *ngIf="formControl.errors?.max">
        {{
            "dssClientLibFlightZone.maxValueErrorHint"
                | transloco
                    : {
                          max: formControl.errors?.max.max | localizeNumber,
                          unit: "dssClientLibFlightZone.weightUnitLabel" | transloco
                      }
        }}
    </ng-container>
</ng-template>
