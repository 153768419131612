import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ConversationCategoryCode, MAX_THREAD_NAME_LENGTH, MIN_THREAD_NAME_LENGTH } from "../../../models/conversations.models";

interface NewThreadCategorySubjectFormComponentState {
    categories: ConversationCategoryCode[] | undefined;
}

interface ThreadCategoryAndSubject {
    category: ConversationCategoryCode | null;
    subject: string | null;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-new-thread-category-subject-form[categories]",
    templateUrl: "./new-thread-category-subject-form.component.html",
    styleUrls: ["./new-thread-category-subject-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NewThreadCategorySubjectFormComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => NewThreadCategorySubjectFormComponent),
            multi: true,
        },
    ],
})
export class NewThreadCategorySubjectFormComponent implements OnInit, ControlValueAccessor, Validators {
    @Input() public set categories(value: ConversationCategoryCode[] | undefined) {
        this.localStore.patchState({ categories: value });
    }

    @Input() public set categoryAndSubject(value: ThreadCategoryAndSubject) {
        this.categoryAndSubjectForm.setValue(value);

        if (!this.validate()) {
            this.propagateChange(value);
        }

        this.onValidationChange();
    }

    protected readonly categoryControl = new FormControl<ConversationCategoryCode | null>(null, Validators.required);
    protected readonly subjectControl = new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(MAX_THREAD_NAME_LENGTH),
        Validators.minLength(MIN_THREAD_NAME_LENGTH),
    ]);

    protected readonly categoryAndSubjectForm = new FormGroup({
        category: this.categoryControl,
        subject: this.subjectControl,
    });
    protected readonly conversationCategories$ = this.localStore.selectByKey("categories").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<NewThreadCategorySubjectFormComponentState>) {
        localStore.setState({ categories: undefined });
    }

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: ThreadCategoryAndSubject) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    public ngOnInit() {
        this.categoryAndSubjectForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.categoryAndSubjectForm.getRawValue());
            this.propagateTouch();
        });
    }

    public validate(): ValidationErrors | null {
        if (this.categoryAndSubjectForm.invalid) {
            return { categoryOrSubjectInvalid: true };
        }

        return null;
    }

    public registerOnChange(fn: (value: ThreadCategoryAndSubject) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: ThreadCategoryAndSubject): void {
        this.categoryAndSubject = value ?? { category: null, subject: null };
    }
}
