import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ReceivedMessage, StoredAvatarPictures } from "../../../models/conversations.models";

interface MessagesInThreadComponentState {
    messages: ReceivedMessage[] | undefined;
    storedAvatarPictures: StoredAvatarPictures;
}

@Component({
    selector: "dtm-ui-messages-in-thread[messages]",
    templateUrl: "./messages-in-thread.component.html",
    styleUrls: ["./messages-in-thread.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MessagesInThreadComponent {
    @Input() public set messages(value: ReceivedMessage[] | undefined) {
        this.localStore.patchState({ messages: value });
    }
    @Input() public set storedAvatarPictures(value: StoredAvatarPictures | undefined) {
        this.localStore.patchState({ storedAvatarPictures: value ?? {} });
    }

    public readonly messages$ = this.localStore.selectByKey("messages");
    public readonly storedAvatarPictures$ = this.localStore.selectByKey("storedAvatarPictures");

    constructor(private readonly localStore: LocalComponentStore<MessagesInThreadComponentState>) {
        localStore.setState({ messages: undefined, storedAvatarPictures: {} });
    }
}
