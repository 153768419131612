import { Directive, Input, TemplateRef } from "@angular/core";

@Directive({
    selector: "[dtmUiLibWizardStepContent]",
})
export class WizardStepContentDirective {
    @Input() public dtmUiLibWizardStepContent: string | undefined;

    constructor(private elementRef: TemplateRef<unknown>) {}

    public get stepId(): string | undefined {
        return this.dtmUiLibWizardStepContent;
    }

    public get template(): TemplateRef<unknown> {
        return this.elementRef;
    }
}
