import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MissionType } from "@dtm-frontend/shared/mission";
import { ButtonTheme, ConfirmationDialogComponent } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { map } from "rxjs";
import { PlannedMission } from "../../models/planned-missions.models";

enum FlightDirection {
    Start = "Start",
    Finish = "Finish",
}

interface MissionInfoPanelComponentState {
    isExpanded: boolean;
    isSelected: boolean;
    mission: PlannedMission | undefined;
    isPlanRouteProcessing: boolean;
    canEdit: boolean;
}

@UntilDestroy()
@Component({
    selector: "dss-client-lib-mission-info-panel[mission]",
    templateUrl: "./mission-info-panel.component.html",
    styleUrls: ["./mission-info-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionInfoPanelComponent {
    @Input() public set mission(value: PlannedMission | undefined) {
        this.localStore.patchState({ mission: value });
    }

    @Input() public set isSelected(value: BooleanInput) {
        this.localStore.patchState({ isSelected: coerceBooleanProperty(value), isExpanded: coerceBooleanProperty(value) });
    }

    @Input() public set isPlanRouteProcessing(value: BooleanInput) {
        this.localStore.patchState({ isPlanRouteProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set canEdit(value: BooleanInput) {
        this.localStore.patchState({ canEdit: coerceBooleanProperty(value) });
    }

    @Output() public readonly rejectMission = new EventEmitter<void>();
    @Output() public readonly acceptMission = new EventEmitter<void>();

    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isSelected$ = this.localStore.selectByKey("isSelected");
    protected readonly canEdit$ = this.localStore.selectByKey("canEdit");
    protected readonly isPlanRouteProcessing$ = this.localStore.selectByKey("isPlanRouteProcessing");
    protected readonly mission$ = this.localStore.selectByKey("mission").pipe(RxjsUtils.filterFalsy());
    protected readonly isStartTimeExceeded$ = this.localStore.selectByKey("mission").pipe(
        RxjsUtils.filterFalsy(),
        map(({ flightStartAt }) => new Date(flightStartAt) < new Date())
    );
    protected readonly MissionType = MissionType;
    protected readonly FlightDirection = FlightDirection;

    constructor(
        private readonly localStore: LocalComponentStore<MissionInfoPanelComponentState>,
        private readonly matDialog: MatDialog,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            isExpanded: false,
            isSelected: false,
            mission: undefined,
            isPlanRouteProcessing: false,
            canEdit: false,
        });
    }

    protected toggle(): void {
        this.localStore.patchState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
    }

    protected confirmAcceptation(): void {
        this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dssClientLibPlannedMissions.missionInfoPanel.dialogAcceptedTitleText"),
                    confirmationText: this.transloco.translate("dssClientLibPlannedMissions.missionInfoPanel.dialogSubtitleText"),
                    declineButtonLabel: this.transloco.translate("dssClientLibPlannedMissions.missionInfoPanel.dialogCancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate(
                        "dssClientLibPlannedMissions.missionInfoPanel.dialogAcceptedConfirmButtonLabel"
                    ),
                    theme: ButtonTheme.Primary,
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.acceptMission.emit();
            });
    }

    protected confirmRejection(): void {
        this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dssClientLibPlannedMissions.missionInfoPanel.dialogRejectedTitleText"),
                    confirmationText: this.transloco.translate("dssClientLibPlannedMissions.missionInfoPanel.dialogSubtitleText"),
                    declineButtonLabel: this.transloco.translate("dssClientLibPlannedMissions.missionInfoPanel.dialogCancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate(
                        "dssClientLibPlannedMissions.missionInfoPanel.dialogRejectedConfirmButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.rejectMission.emit();
            });
    }
}
