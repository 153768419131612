<div class="dialog-header">
    <h2 mat-dialog-title>{{ "dtmMapCesium.geographicalZones.geographicalZonesTitle" | transloco }}</h2>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    <div class="content">
        <h3 class="section-title">{{ "dtmMapCesium.zonesLegend.drap.title" | transloco }}</h3>
        <p class="section-description">{{ "dtmMapCesium.zonesLegend.drap.descriptionText" | transloco }}</p>
        <div class="zones-container">
            <ng-template
                [ngTemplateOutlet]="zoneInfoTemplate"
                [ngTemplateOutletContext]="{
                    class: 'elastic prohibited',
                    icon: 'approve',
                    title: 'dtmMapCesium.zonesLegend.inactiveNotOrderedLabel' | transloco,
                    text: 'dtmMapCesium.zonesLegend.drap.inactiveNotOrderedDescription' | transloco,
            }"
            ></ng-template>
            <ng-template
                [ngTemplateOutlet]="zoneInfoTemplate"
                [ngTemplateOutletContext]="{
                    class: 'elastic prohibited not-active-ordered',
                    icon: 'wait-orange',
                    title: 'dtmMapCesium.zonesLegend.inactiveOrderedLabel' | transloco,
                    text: 'dtmMapCesium.zonesLegend.drap.inactiveOrderedDescription' | transloco,
            }"
            ></ng-template>
            <ng-template
                [ngTemplateOutlet]="zoneInfoTemplate"
                [ngTemplateOutletContext]="{
                    class: 'elastic prohibited active',
                    icon: 'wait-red',
                    title: 'dtmMapCesium.zonesLegend.activeOrderedLabel' | transloco,
                    text: 'dtmMapCesium.zonesLegend.drap.activeDescription' | transloco,
            }"
            ></ng-template>
            <ng-template
                [ngTemplateOutlet]="zoneInfoTemplate"
                [ngTemplateOutletContext]="{
                    class: 'prohibited active',
                    icon: 'prohibited',
                    title: 'dtmMapCesium.zonesLegend.active' | transloco,
                    text: 'dtmMapCesium.zonesLegend.drap.activeDescription' | transloco,
            }"
            ></ng-template>
        </div>

        <h3 class="section-title">{{ "dtmMapCesium.zonesLegend.drar.title" | transloco }}</h3>
        <p class="section-description">{{ "dtmMapCesium.zonesLegend.drar.descriptionText" | transloco }}</p>
        <div class="zones-container">
            <ng-template
                [ngTemplateOutlet]="zoneInfoTemplate"
                [ngTemplateOutletContext]="{
                    class: 'approve restricted elastic',
                    icon: 'approve',
                    title: 'dtmMapCesium.zonesLegend.inactiveNotOrderedLabel' | transloco,
                    text: 'dtmMapCesium.zonesLegend.drar.inactiveNotOrderedDescription' | transloco,
            }"
            ></ng-template>
            <ng-template
                [ngTemplateOutlet]="zoneInfoTemplate"
                [ngTemplateOutletContext]="{
                    class: 'wait restricted elastic not-active-ordered',
                    icon: 'edit',
                    title: 'dtmMapCesium.zonesLegend.inactiveOrderedLabel' | transloco,
                    text: 'dtmMapCesium.zonesLegend.drar.inactiveOrderedDescription' | transloco,
            }"
            ></ng-template>
            <ng-template
                [ngTemplateOutlet]="zoneInfoTemplate"
                [ngTemplateOutletContext]="{
                    class: 'wait restricted elastic active',
                    icon: 'edit',
                    title: 'dtmMapCesium.zonesLegend.activeOrderedLabel' | transloco,
                    text: 'dtmMapCesium.zonesLegend.drar.activeDescription' | transloco,
            }"
            ></ng-template>
            <ng-template
                [ngTemplateOutlet]="zoneInfoTemplate"
                [ngTemplateOutletContext]="{
                    class: 'wait restricted active',
                    icon: 'edit',
                    title: 'dtmMapCesium.zonesLegend.active' | transloco,
                    text: 'dtmMapCesium.zonesLegend.drar.activeDescription' | transloco,
            }"
            ></ng-template>
        </div>

        <div class="dra-i-container">
            <div>
                <h3 class="section-title">{{ "dtmMapCesium.zonesLegend.drai.title" | transloco }}</h3>
                <p class="section-description">{{ "dtmMapCesium.zonesLegend.drai.descriptionText" | transloco }}</p>
            </div>
            <div class="zone">
                <p class="title">{{ "dtmMapCesium.zonesLegend.drai.title" | transloco }}</p>
            </div>
        </div>

        <h3 class="section-title">{{ "dtmMapCesium.zonesLegend.drars.title" | transloco }}</h3>
        <p class="section-description">{{ "dtmMapCesium.zonesLegend.drars.descriptionText" | transloco }}</p>
        <div class="zones-container">
            <ng-template
                [ngTemplateOutlet]="zoneInfoTemplate"
                [ngTemplateOutletContext]="{
                    class: 'rh6km',
                    icon: 'priority-high',
                    title: 'dtmMapCesium.zonesLegend.drars.drarHigh6km.title' | transloco,
                    text: 'dtmMapCesium.zonesLegend.drars.drarHigh6km.descriptionText' | transloco,
            }"
            ></ng-template>
            <ng-template
                [ngTemplateOutlet]="zoneInfoTemplate"
                [ngTemplateOutletContext]="{
                    class: 'rh',
                    icon: 'priority-high',
                    title: 'dtmMapCesium.zonesLegend.drars.drarHigh.title' | transloco,
                    text: 'dtmMapCesium.zonesLegend.drars.drarHigh.descriptionText' | transloco,
            }"
            ></ng-template>
            <ng-template
                [ngTemplateOutlet]="zoneInfoTemplate"
                [ngTemplateOutletContext]="{
                    class: 'rm',
                    icon: 'priority-medium',
                    title: 'dtmMapCesium.zonesLegend.drars.drarMedium.title' | transloco,
                    text: 'dtmMapCesium.zonesLegend.drars.drarMedium.descriptionText' | transloco,
            }"
            ></ng-template>
            <ng-template
                [ngTemplateOutlet]="zoneInfoTemplate"
                [ngTemplateOutletContext]="{
                    class: 'rl',
                    icon: 'priority-low',
                    title: 'dtmMapCesium.zonesLegend.drars.drarLow.title' | transloco,
                    text: 'dtmMapCesium.zonesLegend.drars.drarLow.descriptionText' | transloco,
            }"
            ></ng-template>
        </div>

        <span class="additional-info">{{ "dtmMapCesium.zonesLegend.infoText" | transloco }}</span>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button type="button" class="button-primary" [mat-dialog-close]="true">
        {{ "dtmMapCesium.zonesLegend.closeButtonLabel" | transloco }}
    </button>
</div>

<ng-template #zoneInfoTemplate let-class="class" let-icon="icon" let-title="title" let-text="text">
    <div class="zone {{ class }}">
        <img class="zone-sign" [src]="'assets/images/' + icon + '.svg'" alt="" />
        <p class="title">{{ title }}</p>
        <p>{{ text }}</p>
    </div>
</ng-template>
