<div class="zoom-controls" *ngrxLet="{ min: min$, max: max$ } as vm">
    <button type="button" class="button button-icon subtract" (click)="decrement()" [disabled]="vm.min >= zoomFormControl.value">
        <dtm-ui-icon name="subtract"></dtm-ui-icon>
    </button>
    <div class="input-wrapper">
        <input matInput type="number" [formControl]="zoomFormControl" (blur)="updateOnBlur()" />
        <span class="suffix">%</span>
    </div>
    <button type="button" class="button button-icon add" (click)="increment()" [disabled]="vm.max <= zoomFormControl.value">
        <dtm-ui-icon name="add"></dtm-ui-icon>
    </button>
</div>
