// NOTE: opaque/nominal typing in TypeScript discussion https://github.com/microsoft/TypeScript/issues/202#issuecomment-1139208512
declare const TimeDurationTypeBrand: unique symbol;

export type ISO8601TimeDuration = string & { [TimeDurationTypeBrand]: true };

export interface TimeDurationItems {
    years?: number;
    months?: number;
    weeks?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
}
