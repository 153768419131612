<span class="field-label">
    {{ (label$ | ngrxPush) ?? ("dtmUi.additionalCompetences.label" | transloco) }}
</span>
<ul class="competencies">
    <li *ngFor="let competency of competenciesControls; index as competencyIndex">
        <dtm-ui-input-field class="competency-field">
            <input matInput [formControl]="competency" autocomplete="off" type="text" />
            <div class="field-error" *dtmUiFieldHasError="competency; name: 'maxlength'; error as error">
                {{ "dtmUi.additionalCompetences.maxLengthError" | transloco : { maxLength: error.requiredLength } }}
            </div>
        </dtm-ui-input-field>
        <button
            *ngIf="competenciesControls.length > 1"
            type="button"
            class="button-icon remove-competency-button"
            (click)="removeCompetency(competencyIndex)"
        >
            <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
        </button>
    </li>
</ul>
<button type="button" class="button-secondary add-competency-button" (click)="addCompetency()">
    <dtm-ui-icon name="add"></dtm-ui-icon>
    {{ "dtmUi.additionalCompetences.addAdditionalCompetencyButton" | transloco }}
</button>
