<div class="grid" *ngrxLet="{ applicationDraftList: applicationDraftList$ } as vm">
    <dss-shared-lib-applications-list-filters
        [initialFilters]="initialFilters"
        [flightZonePurposes]="flightZonePurposes"
        [hasSupervisorPermissions]="hasSupervisorPermissions$ | ngrxPush"
        [shouldDisableAuthorFilter]="true"
        [shouldDisableSendDateFilter]="true"
        (filtersChange)="applyFilters($event)"
    >
        <ng-container totalCount>
            {{
                "dssClientLibFlightZone.applicationListShared.totalApplicationsCountLabel"
                    | transloco : { totalCount: vm.applicationDraftList?.page?.totalElements ?? 0 }
            }}
        </ng-container>
    </dss-shared-lib-applications-list-filters>

    <dss-client-lib-application-draft-list
        [flightZoneApplications]="vm.applicationDraftList?.content"
        [isProcessing]="isProcessing$ | ngrxPush"
        [hasDataRetrievalError]="hasListDataRetrievalError$ | ngrxPush"
        [page]="vm.applicationDraftList?.page"
        (pageChange)="changePage($event)"
        (listRefresh)="refreshList()"
        (applicationDelete)="deleteApplication($event)"
        (kmlDownload)="downloadKml($event)"
    ></dss-client-lib-application-draft-list>
</div>
