<div class="thread-container">
    <dtm-ui-select-field [formControl]="categoryControl" [isClearable]="false">
        <label>{{ "dtmUi.conversations.categorySelectLabel" | transloco }}</label>
        <dtm-ui-select-option *ngFor="let category of conversationCategories$ | ngrxPush" [value]="category">
            {{ "dtmUi.conversations.categoryLabel" | transloco : { value: category } }}
        </dtm-ui-select-option>
        <div class="field-error" *dtmUiFieldHasError="categoryControl; name: 'required'">
            {{ "dtmUi.conversations.newThread.categorySelectionIsRequiredHint" | transloco }}
        </div>
    </dtm-ui-select-field>
    <dtm-ui-input-field>
        <label>{{ "dtmUi.conversations.newThread.conversationThreadInputLabel" | transloco }}</label>
        <input matInput type="text" [formControl]="subjectControl" />
        <div class="field-error" *dtmUiFieldHasError="subjectControl; name: 'maxlength'; error as error">
            {{ "dtmUi.conversations.newThread.valueLongerThanMaxLengthErrorHint" | transloco : { max: error.requiredLength } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="subjectControl; name: 'minlength'; error as error">
            {{ "dtmUi.conversations.newThread.valueShorterThanMaxLengthErrorHint" | transloco : { min: error.requiredLength } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="subjectControl; name: 'required'">
            {{ "dtmUi.conversations.newThread.threadFieldIsRequiredHint" | transloco }}
        </div>
    </dtm-ui-input-field>
</div>
