import { IconName } from "@dtm-frontend/shared/ui";
import {
    AirspaceElement,
    AirspaceElementStatus,
    CTR_EP_1KM_PATTERN,
    CTR_EP_PATTERN,
    CTR_PATTERN,
    GeoZoneType,
    InfoZoneIcons,
    MCTR_PATTERN,
    RPA_ZONE_TYPE,
} from "./models/geo-zones.models";

export function sortZones(zones: AirspaceElement[]): AirspaceElement[] {
    const rpaZoneTypePriority: { [key in GeoZoneType]?: number } = {
        [GeoZoneType.DroneAirspaceRestrictedLow]: 1,
        [GeoZoneType.DroneAirspaceRestrictedMedium]: 2,
        [GeoZoneType.DroneAirspaceRestrictedHigh]: 3,
        [GeoZoneType.DroneAirspaceRestrictedHigh6Km]: 3,
    };

    const parentZones = new Set(zones.map((zone) => zone.parent));

    return [...zones].sort((left, right) => {
        if (left.geoZoneType === GeoZoneType.DroneAirspaceProhibited) {
            return -1;
        }

        if (right.geoZoneType === GeoZoneType.DroneAirspaceProhibited) {
            return 1;
        }

        const leftDesignator = left.designator || "";
        const rightDesignator = right.designator || "";

        if (left.parent && parentZones.has(left.parent) && right.designator !== left.parent && left.parent !== right.parent) {
            return -1;
        }

        if (right.parent && parentZones.has(right.parent) && left.designator !== right.parent && left.parent !== right.parent) {
            return 1;
        }

        if (CTR_EP_PATTERN.test(leftDesignator)) {
            return -1;
        }
        if (CTR_EP_PATTERN.test(rightDesignator)) {
            return 1;
        }
        if (CTR_EP_1KM_PATTERN.test(leftDesignator)) {
            return -1;
        }
        if (CTR_EP_1KM_PATTERN.test(rightDesignator)) {
            return 1;
        }
        if (CTR_PATTERN.test(leftDesignator)) {
            return -1;
        }

        if (CTR_PATTERN.test(rightDesignator)) {
            return 1;
        }

        if (left.type === RPA_ZONE_TYPE) {
            return (rpaZoneTypePriority[left.geoZoneType] || 0) - (rpaZoneTypePriority[right.geoZoneType] || 0);
        }

        if (MCTR_PATTERN.test(leftDesignator)) {
            return -1;
        }

        if (MCTR_PATTERN.test(rightDesignator)) {
            return 1;
        }

        if (left.geoZoneType === GeoZoneType.DroneAirspaceRestricted) {
            return -1;
        }

        if (right.geoZoneType === GeoZoneType.DroneAirspaceRestricted) {
            return 1;
        }

        if (left.geoZoneType === GeoZoneType.DroneAirspaceInformation) {
            return -1;
        }

        if (right.geoZoneType === GeoZoneType.DroneAirspaceInformation) {
            return 1;
        }

        return 0;
    });
}

export function getZoneIconName(zone: AirspaceElement): IconName {
    if (zone.geoZoneType === GeoZoneType.Local && zone.zoneAttributes?.localInformationType) {
        return InfoZoneIcons[zone.zoneAttributes?.localInformationType] ?? "";
    }

    if (zone.geoZoneType === GeoZoneType.DroneAirspaceInformation) {
        return "cylinder-line";
    }

    if (zone.isStatic) {
        return "complex-cylinder-fill";
    }

    if (!zone.isStatic && zone.reservations.some((reservation) => reservation.status === AirspaceElementStatus.Active)) {
        return "complex-cylinder-dash-fill-top-down";
    }

    return "cylinder-dash";
}
