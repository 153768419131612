<ng-container *ngIf="formGroup">
    <mat-chip-list *ngIf="filtersCount$ | ngrxPush as filtersCount">
        <mat-chip class="main-chip" *ngIf="allFiltersReset.observed">
            {{ "dtmUi.filterChips.clearAllFiltersLabel" | transloco }} ({{ filtersCount }})
            <dtm-ui-icon name="close-circle-fill" matChipRemove (click)="allFiltersReset.emit()"></dtm-ui-icon>
        </mat-chip>
        <ng-container *ngrxLet="dictionaryMap$ as dictionaryMap">
            <ng-container *ngrxLet="valueMap$ as valueMap">
                <ng-container *ngFor="let filter of filtersMap$ | ngrxPush">
                    <ng-container *ngIf="filtersValue$ | ngrxPush | invoke : getFiltersChips : filter.key as values">
                        <ng-container *ngIf="formGroup.get(filter.key)?.valid && values.length">
                            <span class="chip-group-label">{{ filter.filterLabel | transloco }}:</span>
                            <mat-chip class="filter-chip" *ngFor="let value of values">
                                <ng-container [ngSwitch]="filter.type">
                                    <ng-container *ngSwitchCase="FilterType.Date">
                                        {{ value | localizeDate }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="FilterType.Dictionary">
                                        {{ dictionaryMap?.[value] | systemTranslation }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="FilterType.ValueMap">
                                        {{ valueMap?.[value] }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="FilterType.TranslatedValueMap">
                                        {{ valueMap?.[value] | transloco }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="FilterType.TextEllipsis">
                                        {{ value | slice : 0 : 25 }}<ng-container *ngIf="value.length > 25">...</ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <ng-container *ngIf="filter.filterValueLabel; else plainValueTemplate">
                                            <ng-container *ngIf="filter.filterValueLabelParam; else defaultFilterValueLabelTemplate">
                                                {{
                                                    filter.filterValueLabel
                                                        | transloco : { value: value, filterValueLabelParam: filter.filterValueLabelParam }
                                                }}
                                            </ng-container>
                                            <ng-template #defaultFilterValueLabelTemplate>
                                                {{ filter.filterValueLabel | transloco : { value: value } }}
                                            </ng-template>
                                        </ng-container>

                                        <ng-template #plainValueTemplate> {{ value }} </ng-template>
                                    </ng-container>
                                </ng-container>
                                <dtm-ui-icon
                                    name="close-circle-fill"
                                    matChipRemove
                                    (click)="removeFilterValue(filter.key, value)"
                                ></dtm-ui-icon>
                            </mat-chip>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </mat-chip-list>
</ng-container>
