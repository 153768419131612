import { DEFAULT_LANG, LOCALE_MAPPING } from "@dtm-frontend/shared/ui/i18n";
import { AzureMapsOptions, AzureMapsTileset } from "../models/azure-maps.models";

export const RENDER_V2_VERSION = "2022-08-01";
export const DEFAULT_DOMAIN = "atlas.microsoft.com";
export const DEFAULT_TILE_SIZE = 256;
export const DEFAULT_MAX_ZOOM_LEVEL = 22;
// eslint-disable-next-line max-len
export const RENDER_V2_TILE_URL = `https://{{azMapsDomain}}/map/tile?api-version=${RENDER_V2_VERSION}&tilesetId={{tilesetId}}&zoom={{z}}&x={{x}}&y={{y}}&tileSize={{tileSize}}&language={{language}}&view={{view}}`;
export const TRAFFIC_FLOW_TILE_URL =
    "https://{{azMapsDomain}}/traffic/flow/tile/png?api-version=1.0&style={{style}}&zoom={{z}}&x={{x}}&y={{y}}";
export const TRAFFIC_INCIDENT_TILE_URL =
    "https://{{azMapsDomain}}/traffic/incident/tile/png?api-version=1.0&style={{style}}&zoom={{z}}&x={{x}}&y={{y}}";

export const DEFAULT_AZURE_MAPS_OPTIONS: AzureMapsOptions = {
    tilesetId: AzureMapsTileset.BaseRoad,
    view: "auto",
    language: LOCALE_MAPPING[DEFAULT_LANG],
    trafficFlowThickness: 5,
    url: DEFAULT_DOMAIN,
    tileSize: DEFAULT_TILE_SIZE,
    timeStamp: new Date(),
};
