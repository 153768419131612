import { animate, AnimationTriggerMetadata, style, transition, trigger } from "@angular/animations";

const DEFAULT_ANIMATION_TIME_MS = 300;
const DEFAULT_ANIMATION_DELAY_MS = 0;

export function fadeAnimation(
    duration: number = DEFAULT_ANIMATION_TIME_MS,
    delay: number = DEFAULT_ANIMATION_DELAY_MS
): AnimationTriggerMetadata[] {
    return [
        trigger("fade", [
            transition(":leave", [style({ opacity: "*" }), animate(`${duration}ms ${delay}ms`, style({ opacity: 0 }))]),
            transition(":enter", [style({ opacity: "0" }), animate(`${duration}ms ${delay}ms`, style({ opacity: "*" }))]),
        ]),
    ];
}
