import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { IconName } from "../icon/icon.directive";

interface EditableCardComponentState {
    label: string | undefined;
    editionLabel: string | undefined;
    editButtonLabel: string | undefined;
    editButtonIcon: IconName | undefined;
    saveButtonLabel: string | undefined;
    isEditMode: boolean;
    isSaveButtonDisabled: boolean;
    areActionsAvailable: boolean;
    isOnlyCancelButtonVisible: boolean;
    isEditButtonVisible: boolean;
    canLeaveEditModeOnSave: boolean;
}

@Component({
    selector: "dtm-ui-editable-card",
    templateUrl: "./editable-card.component.html",
    styleUrls: ["./editable-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class EditableCardComponent {
    @Input() public set label(value: string | undefined) {
        this.localStore.patchState({ label: value });
    }
    @Input() public set editionLabel(value: string | undefined) {
        this.localStore.patchState({ editionLabel: value });
    }
    @Input() public set editButtonLabel(value: string | undefined) {
        this.localStore.patchState({ editButtonLabel: value });
    }
    @Input() public set editButtonIcon(value: string | undefined) {
        this.localStore.patchState({ editButtonIcon: value });
    }
    @Input() public set saveButtonLabel(value: string | undefined) {
        this.localStore.patchState({ saveButtonLabel: value });
    }
    @Input() public set isSaveButtonDisabled(value: boolean | undefined) {
        this.localStore.patchState({ isSaveButtonDisabled: coerceBooleanProperty(value) });
    }
    @Input() public set isEditButtonVisible(value: boolean | undefined) {
        this.localStore.patchState({ isEditButtonVisible: coerceBooleanProperty(value) });
    }
    @Input() public set isEditModeOn(value: boolean | undefined) {
        this.localStore.patchState({ isEditMode: coerceBooleanProperty(value) });
    }
    @Input() public set areActionsAvailable(value: boolean | undefined) {
        this.localStore.patchState({ areActionsAvailable: coerceBooleanProperty(value) });
    }
    @Input() public set isOnlyCancelButtonVisible(value: boolean | undefined) {
        this.localStore.patchState({ isOnlyCancelButtonVisible: coerceBooleanProperty(value) });
    }
    @Input() public set canLeaveEditModeOnSave(value: boolean | undefined) {
        this.localStore.patchState({ canLeaveEditModeOnSave: coerceBooleanProperty(value) });
    }

    @Output() public actionSave = new EventEmitter<void>();
    @Output() public editModeChange = new EventEmitter<boolean>();

    protected readonly label$ = this.localStore.selectByKey("label").pipe(RxjsUtils.filterFalsy());
    protected readonly editionLabel$ = this.localStore.selectByKey("editionLabel").pipe(RxjsUtils.filterFalsy());
    protected readonly editButtonLabel$ = this.localStore.selectByKey("editButtonLabel").pipe(RxjsUtils.filterFalsy());
    protected readonly editButtonIcon$ = this.localStore.selectByKey("editButtonIcon").pipe(RxjsUtils.filterFalsy());
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");
    protected readonly saveButtonLabel$ = this.localStore.selectByKey("saveButtonLabel");
    protected readonly isSaveButtonDisabled$ = this.localStore.selectByKey("isSaveButtonDisabled");
    protected readonly areActionsAvailable$ = this.localStore.selectByKey("areActionsAvailable");
    protected readonly isOnlyCancelButtonVisible$ = this.localStore.selectByKey("isOnlyCancelButtonVisible");
    protected readonly isEditButtonVisible$ = this.localStore.selectByKey("isEditButtonVisible");

    constructor(private readonly localStore: LocalComponentStore<EditableCardComponentState>) {
        this.localStore.setState({
            isEditMode: false,
            label: undefined,
            editionLabel: undefined,
            editButtonLabel: undefined,
            editButtonIcon: undefined,
            saveButtonLabel: undefined,
            isSaveButtonDisabled: false,
            areActionsAvailable: true,
            isOnlyCancelButtonVisible: false,
            isEditButtonVisible: true,
            canLeaveEditModeOnSave: true,
        });
    }

    protected setEditMode(value: boolean): void {
        this.localStore.patchState({ isEditMode: value });
        this.editModeChange.emit(value);
    }

    protected save(): void {
        if (this.localStore.selectSnapshotByKey("canLeaveEditModeOnSave")) {
            this.localStore.patchState({ isEditMode: false });
        }
        this.actionSave.emit();
    }
}
