import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { FlightZoneApplicationDraftListItem, FlightZoneApplicationsListComponentState } from "@dtm-frontend/dss-shared-lib";
import { ConfirmationDialogComponent, Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, filter } from "rxjs";

interface ApplicationDraftListComponentState extends FlightZoneApplicationsListComponentState<FlightZoneApplicationDraftListItem> {
    applicationsPage: Page | undefined;
    expandedElement: FlightZoneApplicationDraftListItem | null;
}

@UntilDestroy()
@Component({
    selector: "dss-client-lib-application-draft-list[flightZoneApplications]",
    templateUrl: "./application-draft-list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class ApplicationDraftListComponent {
    protected readonly displayedColumns: Array<keyof FlightZoneApplicationDraftListItem | "actions" | "expandHandle"> = [
        "expandHandle",
        "startAt",
        "endAt",
        "author",
        "applicationPurpose",
        "title",
        "actions",
    ];

    protected readonly flightZoneApplications$ = this.localStore.selectByKey("flightZoneApplications");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly hasDataRetrievalError$ = this.localStore.selectByKey("hasDataRetrievalError");
    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly applicationsPage$ = this.localStore.selectByKey("applicationsPage");

    @Input() public set flightZoneApplications(value: FlightZoneApplicationDraftListItem[] | undefined) {
        this.localStore.patchState({ flightZoneApplications: value ?? [] });
    }
    @Input() public set isProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isProcessing: !!value });
    }
    @Input() public set hasDataRetrievalError(value: boolean | undefined) {
        this.localStore.patchState({ hasDataRetrievalError: !!value });
    }
    @Input() public set page(value: Page | undefined) {
        this.localStore.patchState({ applicationsPage: value });
    }

    @Output() protected readonly pageChange = new EventEmitter<PageEvent>();
    @Output() protected readonly listRefresh = new EventEmitter<void>();
    @Output() protected readonly applicationDelete = new EventEmitter<string>();
    @Output() protected readonly kmlDownload = new EventEmitter<FlightZoneApplicationDraftListItem>();

    constructor(
        private readonly localStore: LocalComponentStore<ApplicationDraftListComponentState>,
        private readonly matDialog: MatDialog,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            flightZoneApplications: [],
            isProcessing: false,
            hasDataRetrievalError: false,
            expandedElement: null,
            applicationsPage: undefined,
        });
    }

    protected toggleExpandableRow(row: FlightZoneApplicationDraftListItem): void {
        const currentlyExpandedElement = this.localStore.selectSnapshotByKey("expandedElement");
        this.localStore.patchState({ expandedElement: currentlyExpandedElement === row ? null : row });
    }

    protected deleteApplication(flightZoneId: string): void {
        this.getDeleteApplicationConfirmation()
            .pipe(filter(Boolean), untilDestroyed(this))
            .subscribe(() => {
                this.applicationDelete.emit(flightZoneId);
            });
    }

    private getDeleteApplicationConfirmation(): Observable<boolean> {
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                confirmationText: this.transloco.translate("dssClientLibFlightZone.deleteApplicationDraftConfirmDialog.deleteDraftText"),
                declineButtonLabel: this.transloco.translate(
                    "dssClientLibFlightZone.deleteApplicationDraftConfirmDialog.deleteDraftCancelLabel"
                ),
                confirmButtonLabel: this.transloco.translate(
                    "dssClientLibFlightZone.deleteApplicationDraftConfirmDialog.deleteDraftConfirmLabel"
                ),
            },
        });

        return dialogRef.afterClosed();
    }
}
