<dtm-ui-editable-box
    *ngrxLet="canRemove$ as canRemove"
    [label]="(isLabelDisplayed$ | ngrxPush) ? (label$ | ngrxPush) : ' '"
    [saveButtonLabel]="'dtmUi.avatarEditor.uploadImageButtonLabel' | transloco"
    [isSaveButtonDisabled]="fileUploadControl.invalid"
    [isEditButtonVisible]="!canRemove"
    [isEditModeOpen]="isEditModeOn$ | ngrxPush"
    (save)="cropImage()"
    (closeEdition)="clearState()"
    (editModeStateChanged)="setLabelVisibility($event)"
>
    <ng-container valueLabelSlot>
        <ng-template [ngTemplateOutlet]="avatarTemplate"></ng-template>
    </ng-container>
    <ng-container additionalActions *ngIf="canRemove">
        <dtm-ui-multiple-actions (edit)="setEditModeOn()" (delete)="delete.emit()"></dtm-ui-multiple-actions>
    </ng-container>
    <ng-container editableContentSlot *ngrxLet="isInputFileVisible$ as isInputVisible">
        <ng-template [ngTemplateOutlet]="avatarTemplate"></ng-template>
        <div class="cropper-section">
            <ng-container *ngIf="isInputVisible; else cropperTemplate">
                <label>{{ "dtmUi.avatarEditor.attachFileLabel" | transloco }}</label>
                <dtm-ui-image-picker
                    [isDisabled]="false"
                    [initialPreviewImageUrl]="uploadedImage$ | ngrxPush"
                    (file)="setFile($event)"
                    [maxFileSize]="MAX_ATTACHMENT_SIZE_BYTES"
                >
                    <span class="field-hint">
                        {{
                            "dtmUi.avatarEditor.authorizationImageHint"
                                | transloco : { maxFileSize: MAX_ATTACHMENT_SIZE_BYTES | formatFileSize }
                        }}
                    </span>
                    <div class="field-error" *dtmUiFieldHasError="fileUploadControl; name: 'required'">
                        {{ "dtmUi.avatarEditor.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-image-picker>
            </ng-container>
        </div>
    </ng-container>
</dtm-ui-editable-box>
<dtm-ui-loader [shouldSetParentPositionRelative]="true" [isShown]="isProcessing$ | ngrxPush" [diameter]="20"></dtm-ui-loader>
<ng-template #avatarTemplate>
    <dtm-ui-avatar [picture]="avatar$ | ngrxPush" [fullName]="fullName$ | ngrxPush" [label]="context$ | ngrxPush"></dtm-ui-avatar>
</ng-template>
<ng-template #cropperTemplate>
    <image-cropper
        *ngrxLet="{ uploadedImage: uploadedImage$, resultFileFormat: resultFileFormat$ } as vm"
        [imageBase64]="vm.uploadedImage"
        [maintainAspectRatio]="true"
        [roundCropper]="true"
        [aspectRatio]="1"
        [resizeToWidth]="MAX_AVATAR_WIDTH"
        [format]="vm.resultFileFormat"
    ></image-cropper>
</ng-template>
