import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Store } from "@ngxs/store";
import { AuthActions } from "../state/auth.actions";
import { AuthState } from "../state/auth.state";

@Injectable()
export class OfflineCapableDtmAuthGuard {
    constructor(private readonly router: Router, private readonly store: Store) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (!this.store.selectSnapshot(AuthState.isLoggedIn)) {
            this.store.dispatch(new AuthActions.GoToLoginPage(state.url));
        }

        return Promise.resolve(true);
    }
}
