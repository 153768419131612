import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";

interface ZoomImageComponentState {
    imgSrc: string | undefined;
    altText: string;
}

@Component({
    selector: "dtm-ui-zoom-image",
    templateUrl: "./zoom-image.component.html",
    styleUrls: ["./zoom-image.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ZoomImageComponent {
    @Input() public set imageSrc(value: string | undefined) {
        this.localStore.patchState({ imgSrc: value });
    }
    @Input() public set altText(value: string | undefined) {
        this.localStore.patchState({ altText: value ?? "" });
    }
    @Output() public readonly closeZoom = new EventEmitter<void>();
    @ViewChild("button") protected readonly button!: ElementRef;

    protected readonly imgSrc$ = this.localStore.selectByKey("imgSrc").pipe(RxjsUtils.filterFalsy());
    protected readonly altText$ = this.localStore.selectByKey("altText");

    // NOTE it allows to focus only the component button. Somehow focusTrap is not working properly without it.
    @HostListener("document:keydown.tab", ["$event"]) public handleTabPress(event: KeyboardEvent) {
        if (event.key === "Tab" && event.target !== this.button.nativeElement) {
            event.preventDefault();
            this.button.nativeElement.focus();
        }
    }
    constructor(private readonly localStore: LocalComponentStore<ZoomImageComponentState>) {
        localStore.setState({ imgSrc: undefined, altText: "" });
    }
}
