import { MissionSegmentStatus, RouteAreaTypeId } from "@dtm-frontend/shared/ui";
import { createCesiumDashPattern } from "../../utils/create-cesium-dash-pattern";

/* eslint-disable @typescript-eslint/no-explicit-any*/
declare const Cesium: any; // TODO: DTM-966

type RoutesVisualData = {
    [key in RouteAreaTypeId]?: {
        [status in MissionSegmentStatus]?: VisualSettings & VisualDataOverride;
    };
};

export interface VisualDataOverride {
    nearbyMissionOverride?: VisualSettings;
    selectedNearbyMissionOverride?: VisualSettings;
    collisionMissionOverride?: VisualSettings;
    emergencyOverride?: VisualSettings;
}

export interface VisualSettings {
    fill?: unknown;
    outlineWidth?: number;
    outlineMaterial?: any;
}

const DEFAULT_FILL_OPACITY = 0.6;
const DEFAULT_FILL_COLOR = "#007544"; // $color-status-success
const DEFAULT_TACTICAL_BORDER_COLOR = "#1D88EE"; // $color-secondary-600;
const DEFAULT_BORDER_COLOR = "#007544"; // $color-status-success
const NOT_ACTIVE_SEGMENT_FILL_COLOR = "#90CBFE"; // $color-secondary-200
const NEARBY_MISSION_NOT_ACTIVE_SEGMENT_FILL_COLOR = "#B9C1D0";
const ACTIVE_SEGMENT_FILL_COLOR = "#3FA6FD"; // $color-secondary-400
const NEARBY_MISSION_ACTIVE_SEGMENT_FILL_COLOR = "#8D99B1"; // $color-gray-200
const DEFAULT_OUTLINE_WIDTH = 3;
const DEFAULT_OUTLINE_DASH_LENGTH = 15;
const DEFAULT_OUTLINE_DASH_PATTERN = createCesiumDashPattern("------------");
const SAFETY_AREA_FILL_COLOR = "#E3F2FE"; // $color-secondary-50
const PATH_COLOR = "#1D88EE"; // $color-secondary-600
const WAYPOINT_COLOR = "#061636"; // $color-gray-900
const NEARBY_MISSION_DEFAULT_COLOR = "#43577E"; // $color-gray-400:
// TODO: DTM-2478 - temporary colours for nearby missions
const SELECTED_NEARBY_MISSION_PATH_COLOR = "#800080";
const SELECTED_NEARBY_MISSION_DEFAULT_COLOR = "#8A2BE2";
const SELECTED_NEARBY_MISSION_ACTIVE_SEGMENT_FILL_COLOR = "#EE82EE";
const SELECTED_NEARBY_MISSION_NOT_ACTIVE_SEGMENT_FILL_COLOR = "#D8BFD8";
const TRAJECTORY_COLOR = "#061636"; // $color-gray-900
const TRAJECTORY_WARNING_COLOR = "#B00020"; // $color-error-400
const TRAJECTORY_WIDTH = 4;
const COLLISION_BORDER_COLOR = "#B00020"; // $color-error-400
const EMERGENCY_BORDER_COLOR = "#B00020"; // $color-warning-400
const ACTIVATED_MISSION_FILL_IMAGE_PATH = "assets/images/dash.png";

export class CesiumImageObjectSettings {
    constructor(public image: string, public isTransparent = true, public color: string) {}

    public createImageMaterialProperty(scale: typeof Cesium.Cartesian2) {
        return new Cesium.ImageMaterialProperty({
            image: this.image,
            transparent: this.isTransparent,
            color: Cesium.Color.fromCssColorString(this.color),
            repeat: scale,
        });
    }
}

export const ROUTES_VISUAL_DATA: RoutesVisualData = {
    safetyArea: {
        [MissionSegmentStatus.DEFAULT]: {
            fill: Cesium.Color.fromCssColorString(SAFETY_AREA_FILL_COLOR).withAlpha(DEFAULT_FILL_OPACITY),
        },
    },
    flightArea: {
        [MissionSegmentStatus.DEFAULT]: {
            emergencyOverride: {
                outlineMaterial: Cesium.Color.fromCssColorString(EMERGENCY_BORDER_COLOR),
            },
            outlineMaterial: Cesium.Color.fromCssColorString(DEFAULT_BORDER_COLOR),
            outlineWidth: DEFAULT_OUTLINE_WIDTH,
            fill: Cesium.Color.fromCssColorString(DEFAULT_FILL_COLOR).withAlpha(DEFAULT_FILL_OPACITY),
            nearbyMissionOverride: {
                fill: Cesium.Color.TRANSPARENT,
                outlineMaterial: Cesium.Color.fromCssColorString(NEARBY_MISSION_DEFAULT_COLOR),
            },
            selectedNearbyMissionOverride: {
                outlineMaterial: Cesium.Color.fromCssColorString(SELECTED_NEARBY_MISSION_DEFAULT_COLOR),
            },
            collisionMissionOverride: {
                outlineMaterial: Cesium.Color.fromCssColorString(COLLISION_BORDER_COLOR),
            },
        },
        [MissionSegmentStatus.MISSION_NOT_ACTIVATED]: {
            outlineWidth: DEFAULT_OUTLINE_WIDTH,
            fill: Cesium.Color.TRANSPARENT,
            outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.fromCssColorString(DEFAULT_TACTICAL_BORDER_COLOR),
                dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
            }),
            nearbyMissionOverride: {
                outlineWidth: DEFAULT_OUTLINE_WIDTH,
                fill: Cesium.Color.TRANSPARENT,
                outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                    color: Cesium.Color.fromCssColorString(NEARBY_MISSION_DEFAULT_COLOR),
                    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
                }),
            },
            selectedNearbyMissionOverride: {
                outlineWidth: DEFAULT_OUTLINE_WIDTH,
                outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                    color: Cesium.Color.fromCssColorString(SELECTED_NEARBY_MISSION_DEFAULT_COLOR),
                    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
                }),
            },
            collisionMissionOverride: {
                outlineWidth: DEFAULT_OUTLINE_WIDTH,
                outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                    color: Cesium.Color.fromCssColorString(COLLISION_BORDER_COLOR),
                    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
                }),
            },
            emergencyOverride: {
                outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                    color: Cesium.Color.fromCssColorString(EMERGENCY_BORDER_COLOR),
                    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
                }),
            },
        },
        [MissionSegmentStatus.MISSION_ACTIVATED]: {
            outlineWidth: DEFAULT_OUTLINE_WIDTH,
            outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.fromCssColorString(DEFAULT_TACTICAL_BORDER_COLOR),
                dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
            }),
            fill: new CesiumImageObjectSettings(ACTIVATED_MISSION_FILL_IMAGE_PATH, true, DEFAULT_TACTICAL_BORDER_COLOR),
            nearbyMissionOverride: {
                outlineWidth: DEFAULT_OUTLINE_WIDTH,
                outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                    color: Cesium.Color.fromCssColorString(NEARBY_MISSION_DEFAULT_COLOR),
                    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
                }),
                fill: new CesiumImageObjectSettings(ACTIVATED_MISSION_FILL_IMAGE_PATH, true, NEARBY_MISSION_DEFAULT_COLOR),
            },
            selectedNearbyMissionOverride: {
                outlineWidth: DEFAULT_OUTLINE_WIDTH,
                outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                    color: Cesium.Color.fromCssColorString(SELECTED_NEARBY_MISSION_DEFAULT_COLOR),
                    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
                }),
                fill: new CesiumImageObjectSettings(ACTIVATED_MISSION_FILL_IMAGE_PATH, true, SELECTED_NEARBY_MISSION_DEFAULT_COLOR),
            },
            emergencyOverride: {
                outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                    color: Cesium.Color.fromCssColorString(EMERGENCY_BORDER_COLOR),
                    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
                }),
                fill: new CesiumImageObjectSettings(ACTIVATED_MISSION_FILL_IMAGE_PATH, true, EMERGENCY_BORDER_COLOR),
            },
        },
        [MissionSegmentStatus.ACTIVE_SEGMENT]: {
            outlineMaterial: Cesium.Color.fromCssColorString(DEFAULT_TACTICAL_BORDER_COLOR),
            outlineWidth: DEFAULT_OUTLINE_WIDTH,
            fill: Cesium.Color.fromCssColorString(ACTIVE_SEGMENT_FILL_COLOR).withAlpha(DEFAULT_FILL_OPACITY),
            nearbyMissionOverride: {
                outlineMaterial: Cesium.Color.fromCssColorString(NEARBY_MISSION_DEFAULT_COLOR),
                outlineWidth: DEFAULT_OUTLINE_WIDTH,
                fill: Cesium.Color.fromCssColorString(NEARBY_MISSION_ACTIVE_SEGMENT_FILL_COLOR).withAlpha(DEFAULT_FILL_OPACITY),
            },
            selectedNearbyMissionOverride: {
                outlineMaterial: Cesium.Color.fromCssColorString(SELECTED_NEARBY_MISSION_DEFAULT_COLOR),
                outlineWidth: DEFAULT_OUTLINE_WIDTH,
                fill: Cesium.Color.fromCssColorString(SELECTED_NEARBY_MISSION_ACTIVE_SEGMENT_FILL_COLOR).withAlpha(DEFAULT_FILL_OPACITY),
            },
            emergencyOverride: {
                outlineMaterial: Cesium.Color.fromCssColorString(EMERGENCY_BORDER_COLOR),
            },
        },
        [MissionSegmentStatus.NOT_ACTIVE_SEGMENT]: {
            outlineWidth: DEFAULT_OUTLINE_WIDTH,
            outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.fromCssColorString(DEFAULT_TACTICAL_BORDER_COLOR),
                dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
            }),
            fill: Cesium.Color.fromCssColorString(NOT_ACTIVE_SEGMENT_FILL_COLOR).withAlpha(DEFAULT_FILL_OPACITY),
            nearbyMissionOverride: {
                outlineWidth: DEFAULT_OUTLINE_WIDTH,
                outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                    color: Cesium.Color.fromCssColorString(NEARBY_MISSION_DEFAULT_COLOR),
                    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
                }),
                fill: Cesium.Color.fromCssColorString(NEARBY_MISSION_NOT_ACTIVE_SEGMENT_FILL_COLOR).withAlpha(DEFAULT_FILL_OPACITY),
            },
            selectedNearbyMissionOverride: {
                outlineWidth: DEFAULT_OUTLINE_WIDTH,
                outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                    color: Cesium.Color.fromCssColorString(SELECTED_NEARBY_MISSION_DEFAULT_COLOR),
                    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
                }),
                fill: Cesium.Color.fromCssColorString(SELECTED_NEARBY_MISSION_NOT_ACTIVE_SEGMENT_FILL_COLOR).withAlpha(
                    DEFAULT_FILL_OPACITY
                ),
            },
            emergencyOverride: {
                outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                    color: Cesium.Color.fromCssColorString(EMERGENCY_BORDER_COLOR),
                    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
                }),
            },
        },
        [MissionSegmentStatus.DEACTIVATED_SEGMENT]: {
            outlineWidth: DEFAULT_OUTLINE_WIDTH,
            fill: Cesium.Color.TRANSPARENT,
            outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.fromCssColorString(DEFAULT_TACTICAL_BORDER_COLOR),
                dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
            }),
            nearbyMissionOverride: {
                outlineWidth: DEFAULT_OUTLINE_WIDTH,
                outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                    color: Cesium.Color.fromCssColorString(NEARBY_MISSION_DEFAULT_COLOR),
                    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
                }),
            },
            selectedNearbyMissionOverride: {
                outlineWidth: DEFAULT_OUTLINE_WIDTH,
                outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                    color: Cesium.Color.fromCssColorString(SELECTED_NEARBY_MISSION_DEFAULT_COLOR),
                    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
                }),
            },
            collisionMissionOverride: {
                outlineWidth: DEFAULT_OUTLINE_WIDTH,
                outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                    color: Cesium.Color.fromCssColorString(COLLISION_BORDER_COLOR),
                    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
                }),
            },
        },
    },
    path: {
        [MissionSegmentStatus.DEFAULT]: {
            outlineWidth: DEFAULT_OUTLINE_WIDTH,
            outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.fromCssColorString(DEFAULT_TACTICAL_BORDER_COLOR),
                dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
            }),
            fill: Cesium.Color.fromCssColorString(PATH_COLOR),
            selectedNearbyMissionOverride: {
                outlineWidth: DEFAULT_OUTLINE_WIDTH,
                outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                    color: Cesium.Color.fromCssColorString(SELECTED_NEARBY_MISSION_DEFAULT_COLOR),
                    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
                }),
                fill: Cesium.Color.fromCssColorString(SELECTED_NEARBY_MISSION_PATH_COLOR),
            },
        },
    },
    waypoint: {
        [MissionSegmentStatus.DEFAULT]: {
            outlineMaterial: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.fromCssColorString(DEFAULT_TACTICAL_BORDER_COLOR),
                dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
                dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
            }),
            outlineWidth: DEFAULT_OUTLINE_WIDTH,
            fill: Cesium.Color.fromCssColorString(WAYPOINT_COLOR),
        },
    },
};

export const TRAJECTORY_VISUAL_DATA: VisualSettings = {
    outlineMaterial: Cesium.Color.fromCssColorString(TRAJECTORY_COLOR),
    outlineWidth: TRAJECTORY_WIDTH,
};

export const MARKED_TRAJECTORY_VISUAL_DATA: VisualSettings = {
    outlineMaterial: Cesium.Color.fromCssColorString(TRAJECTORY_WARNING_COLOR),
    outlineWidth: TRAJECTORY_WIDTH,
};
