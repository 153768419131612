import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import {
    FlightZoneApplicationPurpose,
    FlightZoneCapabilities,
    FlightZoneExclusionsConstraints,
    FlightZoneSelectOption,
    RestrictionExclusions,
    SecuringOwnFlightsRestrictionExclusions,
    UavMtomExclusion,
} from "@dtm-frontend/dss-shared-lib";
import { PhoneNumber, phoneNumberAndCountryCodeRequired, validPhoneNumberWithCountryCode } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

interface SecuringOwnFlightsRestrictionExclusionsComponentState {
    isProcessing: boolean;
    capabilities: FlightZoneCapabilities | undefined;
    constraints: FlightZoneExclusionsConstraints | undefined;
}

interface SecuringOwnFlightsRestrictionExclusionsForm {
    isManagedByGovernment: FormControl<boolean | null>;
    agreedExclusion: FormGroup<{
        phoneNumber: FormControl<PhoneNumber | null>;
        email: FormControl<string | null>;
    }>;
    areOwnedFlights: FormControl<boolean>;
    uavMtomExclusion: FormControl<UavMtomExclusion | null>;
    courses: FormControl<FlightZoneSelectOption<string>[] | null>;
    otherExclusion: FormControl<string | null>;
    purpose: FormControl<FlightZoneApplicationPurpose.SecuringOwnFlights>;
}

@Component({
    selector: "dss-client-lib-securing-own-flights-restriction-exclusions[capabilities][constraints][exclusions]",
    templateUrl: "./securing-own-flights-restriction-exclusions.component.html",
    styleUrls: ["../zone-restriction-exclusions-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
    providers: [LocalComponentStore],
})
export class SecuringOwnFlightsRestrictionExclusionsComponent {
    protected readonly agreedExclusionEnabledFormControl = new FormControl<boolean>(false);
    protected readonly uavMtomExclusionEnabledFormControl = new FormControl<boolean>(false);
    protected readonly coursesEnabledFormControl = new FormControl<boolean>(false);
    protected readonly otherExclusionEnabledFormControl = new FormControl<boolean>(false);

    protected readonly exclusionsForm = new FormGroup<SecuringOwnFlightsRestrictionExclusionsForm>({
        purpose: new FormControl(FlightZoneApplicationPurpose.SecuringOwnFlights, { nonNullable: true }),
        isManagedByGovernment: new FormControl(false),
        agreedExclusion: new FormGroup({
            phoneNumber: new FormControl<PhoneNumber>(
                { value: { number: "", countryCode: null }, disabled: true },
                {
                    validators: [phoneNumberAndCountryCodeRequired, validPhoneNumberWithCountryCode],
                }
            ),
            email: new FormControl(
                { value: "", disabled: true },
                {
                    validators: [Validators.required, Validators.email],
                }
            ),
        }),
        areOwnedFlights: new FormControl({ value: true, disabled: true }, { nonNullable: true }),
        uavMtomExclusion: new FormControl({ value: null, disabled: true }),
        courses: new FormControl(
            { value: [], disabled: true },
            {
                validators: [Validators.required],
            }
        ),
        otherExclusion: new FormControl(
            { value: "", disabled: true },
            {
                validators: [Validators.required],
            }
        ),
    });

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly capabilities$ = this.localStore.selectByKey("capabilities");
    protected readonly constraints$ = this.localStore.selectByKey("constraints");

    @Input()
    public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input()
    public set capabilities(value: FlightZoneCapabilities | undefined) {
        this.localStore.patchState({ capabilities: value });

        if (!value) {
            return;
        }

        this.exclusionsForm.controls.agreedExclusion.patchValue(value.defaultInstitutionContact, { emitEvent: false });
    }

    @Input()
    public set constraints(value: FlightZoneExclusionsConstraints | undefined) {
        this.localStore.patchState({ constraints: value });

        if (!value) {
            return;
        }

        this.exclusionsForm.controls.otherExclusion.addValidators(Validators.maxLength(value.otherExclusionMaxFieldLength));
    }

    @Input()
    public set exclusions(value: RestrictionExclusions | undefined) {
        if (!value || value.purpose !== FlightZoneApplicationPurpose.SecuringOwnFlights) {
            return;
        }

        this.updateHiddenControlsStatus(value);
        this.exclusionsForm.patchValue({ ...value, agreedExclusion: value.agreedExclusion ?? { email: "", phoneNumber: null } });
        this.exclusionsForm.markAllAsTouched();
    }

    @Output() protected readonly restrictionExclusionsUpdate = new EventEmitter<SecuringOwnFlightsRestrictionExclusions>();
    @Output() protected readonly back = new EventEmitter<void>();

    constructor(private readonly localStore: LocalComponentStore<SecuringOwnFlightsRestrictionExclusionsComponentState>) {
        this.localStore.setState({
            isProcessing: false,
            capabilities: undefined,
            constraints: undefined,
        });
    }

    protected changeControlValidation(checkboxValue: boolean, control: AbstractControl): void {
        if (checkboxValue) {
            control.enable();
        } else {
            control.disable();
            control.updateValueAndValidity();
        }
    }

    protected submitExclusions(): void {
        const disabledControlsDefaultValues: Partial<SecuringOwnFlightsRestrictionExclusions> = {
            courses: [],
            uavMtomExclusion: null,
            agreedExclusion: null,
            otherExclusion: "",
            areOwnedFlights: true,
        };

        this.restrictionExclusionsUpdate.emit({
            ...disabledControlsDefaultValues,
            ...(this.exclusionsForm.value as SecuringOwnFlightsRestrictionExclusions),
        });
    }

    private updateHiddenControlsStatus(value: SecuringOwnFlightsRestrictionExclusions): void {
        this.agreedExclusionEnabledFormControl.setValue(!!value.agreedExclusion);
        this.changeControlValidation(!!value.agreedExclusion, this.exclusionsForm.controls.agreedExclusion);

        this.uavMtomExclusionEnabledFormControl.setValue(!!value.uavMtomExclusion);
        this.changeControlValidation(!!value.uavMtomExclusion, this.exclusionsForm.controls.uavMtomExclusion);

        this.coursesEnabledFormControl.setValue(!!value.courses.length);
        this.changeControlValidation(!!value.courses.length, this.exclusionsForm.controls.courses);

        this.otherExclusionEnabledFormControl.setValue(!!value.otherExclusion?.length);
        this.changeControlValidation(!!value.otherExclusion?.length, this.exclusionsForm.controls.otherExclusion);
    }
}
