import { Injectable, OnDestroy } from "@angular/core";
import { GeoJSON } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { Cartesian3, SceneMode, ViewerConfiguration } from "@pansa/ngx-cesium";
import turfBbox from "@turf/bbox";
import turfBboxPolygon from "@turf/bbox-polygon";
import turfCircle from "@turf/circle";
import {
    BBox,
    FeatureCollection,
    Geometry,
    Position,
    Properties,
    featureCollection as turfFeatureCollection,
    lineString as turfLineString,
    polygon as turfPolygon,
} from "@turf/helpers";
import turfTransformRotate from "@turf/transform-rotate";
import turfTransformScale from "@turf/transform-scale";
import { BehaviorSubject, Observable, Subject, forkJoin, map, merge, startWith } from "rxjs";
import { defaultIfEmpty, filter, first } from "rxjs/operators";
import { convertCartesian3ToSerializableCartographic } from "../utils/convert-cartesian3-to-serializable-cartographic";
import { MapEntitiesEditorContent, MapEntity, MapEntityType, Polyline3DEntity } from "./entity-editors/map-entities-editor.service";

interface CameraControlSettings {
    isZoomEnabled: boolean;
    isTranslateEnabled: boolean;
    isLookEnabled: boolean;
    isRotateEnabled: boolean;
    isTiltEnabled: boolean;
}

const FLY_ANIMATION_DURATION = 1.5;
const ZOOM_ANIMATION_DURATION = 0.5;
const FLY_TO_CONTENT_SCALE_2D = 2;
const ZOOM_OUT_SCALAR_3D = -2.0;
// eslint-disable-next-line no-magic-numbers
const ZOOM_IN_SCALAR_3D = 2.0 / 3.0;
const ZOOM_IN_SCALE = 0.5;
const ZOOM_OUT_SCALE = 2;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966
// TODO: DTM-966
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CesiumViewer = any;

@Injectable()
export class CameraHelperService implements OnDestroy {
    private viewer?: CesiumViewer;
    private readonly mapEntitiesReadySubject = new BehaviorSubject(false);
    private readonly cameraMovingSubject = new BehaviorSubject(true);
    private readonly cameraChangedSubject = new Subject();
    private readonly cesiumEventHelper = new Cesium.EventHelper();

    public readonly mapEntitiesReady$ = this.mapEntitiesReadySubject.pipe(RxjsUtils.filterFalsy(), defaultIfEmpty(null), first());
    public readonly cameraChanged$ = this.cameraChangedSubject.asObservable();
    public readonly cameraMoving$ = this.cameraMovingSubject.asObservable();
    public readonly postRender$ = this.mapEntitiesReadySubject.asObservable();
    private readonly forceRecalculatingDefaultView = new Subject();

    public readonly isDefaultView$ = merge(this.cameraChanged$, this.cameraMoving$, this.forceRecalculatingDefaultView).pipe(
        startWith(null),
        map(() => {
            const camera = this.viewer?.camera;
            if (!camera) {
                return false;
            }

            const { heading, pitch, roll } = camera;

            return this.isHeadingDefault(heading) && this.isPitchDefault(pitch) && roll === 0;
        })
    );

    constructor(private viewerConfiguration: ViewerConfiguration) {
        this.initViewerModifier();
    }

    public ngOnDestroy(): void {
        this.mapEntitiesReadySubject.complete();
        this.cameraMovingSubject.complete();
        this.cesiumEventHelper.removeAll();
    }

    public takeScreenshotWhenStable(): Observable<Blob> {
        return new Observable((subscriber) => {
            // NOTE: if scene or map changes were triggered it will make sure we will wait for listeners to update subjects
            setTimeout(() => {
                const cameraNotMoving$ = this.cameraMovingSubject.pipe(
                    filter((moving) => !moving),
                    defaultIfEmpty(null),
                    first()
                );

                forkJoin([this.mapEntitiesReady$, cameraNotMoving$]).subscribe(([mapEntitiesReady, cameraNotMoving]) => {
                    if (mapEntitiesReady === null || cameraNotMoving === null || !this.viewer?.canvas) {
                        subscriber.complete();

                        return;
                    }

                    this.viewer.canvas.toBlob((file: Blob) => {
                        subscriber.next(file);
                        subscriber.complete();
                    }, "image/jpeg");
                });
            });
        });
    }

    public freezeView(): CameraControlSettings {
        const cache: CameraControlSettings = {
            isRotateEnabled: this.viewer.scene.screenSpaceCameraController.enableRotate,
            isTranslateEnabled: this.viewer.scene.screenSpaceCameraController.enableTranslate,
            isZoomEnabled: this.viewer.scene.screenSpaceCameraController.enableZoom,
            isTiltEnabled: this.viewer.scene.screenSpaceCameraController.enableTilt,
            isLookEnabled: this.viewer.scene.screenSpaceCameraController.enableLook,
        };

        this.viewer.scene.screenSpaceCameraController.enableRotate = false;
        this.viewer.scene.screenSpaceCameraController.enableTranslate = false;
        this.viewer.scene.screenSpaceCameraController.enableZoom = false;
        this.viewer.scene.screenSpaceCameraController.enableTilt = false;
        this.viewer.scene.screenSpaceCameraController.enableLook = false;

        return cache;
    }

    public restoreView(cache: CameraControlSettings): void {
        if (!this.viewer?.scene?.screenSpaceCameraController) {
            return;
        }

        this.viewer.scene.screenSpaceCameraController.enableRotate = cache.isRotateEnabled;
        this.viewer.scene.screenSpaceCameraController.enableTranslate = cache.isTranslateEnabled;
        this.viewer.scene.screenSpaceCameraController.enableZoom = cache.isZoomEnabled;
        this.viewer.scene.screenSpaceCameraController.enableTilt = cache.isTiltEnabled;
        this.viewer.scene.screenSpaceCameraController.enableLook = cache.isLookEnabled;
    }

    private convertMapEntitiesToGeoJsonCollection(content: MapEntity[]): FeatureCollection<Geometry, Properties> {
        return turfFeatureCollection<Geometry, Properties>(
            content.flatMap((entity) => {
                switch (entity.type) {
                    case MapEntityType.Cylinder:
                        return turfCircle(
                            this.cartesian3ToTurfCoordinates(entity.center),
                            Math.max(entity.radius, (entity.topHeight ?? 0) + entity.bottomHeight),
                            {
                                units: "meters",
                            }
                        );
                    case MapEntityType.Prism:
                        return turfPolygon([
                            [
                                ...entity.positions.map((cartesian) => this.cartesian3ToTurfCoordinates(cartesian)),
                                this.cartesian3ToTurfCoordinates(entity.positions[0]),
                            ],
                        ]);
                    case MapEntityType.Polyline3D:
                        return [
                            turfLineString([
                                ...entity.positions.map((cartesian) => this.cartesian3ToTurfCoordinates(cartesian)),
                                this.cartesian3ToTurfCoordinates(entity.positions[0]),
                            ]),
                            ...this.convertMapEntitiesToGeoJsonCollection(
                                Object.values(entity.childEntities).map((childEntity) => childEntity.entity)
                            ).features,
                        ];
                }
            })
        );
    }

    public flyToContent(content: MapEntitiesEditorContent, scale = 1, rightOffset = 0) {
        if (content.length === 0) {
            return;
        }

        const features = this.convertMapEntitiesToGeoJsonCollection(content);

        this.flyToGeoJSON(features, FLY_ANIMATION_DURATION, scale, rightOffset);
    }

    public flyToPoint(latitude: number, longitude: number, zoomScale = 1, rightOffset = 0) {
        this.flyToGeoJSON(turfCircle([longitude, latitude], 1, { steps: 4 }), FLY_ANIMATION_DURATION, zoomScale, rightOffset);
    }

    public flyToSegment(polyline3DEntity: Polyline3DEntity, waypointIndex: number) {
        const features = turfLineString([
            this.cartesian3ToTurfCoordinates(polyline3DEntity.positions[waypointIndex]),
            this.cartesian3ToTurfCoordinates(polyline3DEntity.positions[waypointIndex + 1]),
            this.cartesian3ToTurfCoordinates(polyline3DEntity.positions[waypointIndex]),
        ]);

        this.flyToGeoJSON(features);
    }

    public flyToGeoJSON(
        features: GeoJSON | FeatureCollection<Geometry, Properties>,
        animationDuration = FLY_ANIMATION_DURATION,
        zoomScale = 1,
        rightOffset = 0,
        leftOffset = 0
    ) {
        if (!this.viewer) {
            return;
        }

        const featuresBbox = turfBbox(features);
        const featuresBboxPolygon = turfBboxPolygon(featuresBbox);

        const contentBboxPolygon = turfTransformScale(
            featuresBboxPolygon,
            this.viewer.scene.mode === SceneMode.SCENE2D ? FLY_TO_CONTENT_SCALE_2D * zoomScale : zoomScale
        );
        const contentBbox = turfBbox(contentBboxPolygon);

        const rightRotationAngle = 90;
        const rotatedBboxPolygon = turfTransformRotate(contentBboxPolygon, rightRotationAngle);

        const [minX, , maxX, maxY] = contentBbox;
        const [, , fullBoxMaxX] = turfBbox(turfFeatureCollection([contentBboxPolygon, rotatedBboxPolygon]));

        const mapClientWidth = this.viewer.canvas.clientWidth;
        const destinationMaxX =
            mapClientWidth > 0 && rightOffset !== 0 ? (Math.abs(maxX - minX) / mapClientWidth) * rightOffset + fullBoxMaxX : maxX;

        const destinationMinX =
            mapClientWidth > 0 && leftOffset !== 0 ? (Math.abs(maxX - minX) / mapClientWidth) * leftOffset * -1 + minX : minX;

        const destination = Cesium.Rectangle.fromDegrees(destinationMinX, contentBbox[1], destinationMaxX, maxY);

        const northwest: Cartesian3 = Cesium.Cartographic.toCartesian(Cesium.Rectangle.northwest(destination));
        const southeast: Cartesian3 = Cesium.Cartographic.toCartesian(Cesium.Rectangle.southeast(destination));
        const boundingSphere = Cesium.BoundingSphere.fromCornerPoints(northwest, southeast);

        this.viewer.camera.flyToBoundingSphere(boundingSphere, {
            duration: animationDuration,
        });
    }

    private cartesian3ToTurfCoordinates(input: Cartesian3): Position {
        const { longitude, latitude } = convertCartesian3ToSerializableCartographic(input);

        return [longitude, latitude];
    }

    public getViewBox(bufferSizeScale = 0): BBox | undefined {
        const ellipsoid = this.viewer?.scene.globe.ellipsoid;
        const canvasHeight = this.viewer.scene.canvas.height;
        const canvasWidth = this.viewer.scene.canvas.width;
        const horizontalBuffer = canvasWidth * bufferSizeScale;
        const verticalBuffer = canvasHeight * bufferSizeScale;

        const cartesianLeftBottom = this.viewer.scene.camera.pickEllipsoid(
            new Cesium.Cartesian2(-verticalBuffer, canvasHeight + verticalBuffer),
            ellipsoid
        );
        const cartesianRightTop = this.viewer.scene.camera.pickEllipsoid(
            new Cesium.Cartesian2(canvasWidth + horizontalBuffer, -horizontalBuffer),
            ellipsoid
        );
        if (!cartesianLeftBottom || !cartesianRightTop) {
            return;
        }

        const leftBottom = convertCartesian3ToSerializableCartographic(cartesianLeftBottom);
        const rightTop = convertCartesian3ToSerializableCartographic(cartesianRightTop);

        return [leftBottom.longitude, leftBottom.latitude, rightTop.longitude, rightTop.latitude];
    }

    public zoom(step = -1) {
        const camera = this.viewer?.camera;
        const minimumZoomDistance = this.viewer?.scene.screenSpaceCameraController.minimumZoomDistance;

        if (!camera) {
            return;
        }

        let destination;
        let currentPosition;
        const zoomIn = step < 0;

        if ([SceneMode.SCENE2D, SceneMode.COLUMBUS_VIEW].includes(this.viewer.scene.mode)) {
            const positionCartographic = { ...camera.positionCartographic };
            const zoomScale = zoomIn ? ZOOM_IN_SCALE : ZOOM_OUT_SCALE;

            currentPosition = Cesium.Cartographic.toCartesian(positionCartographic);
            positionCartographic.height *= Math.pow(zoomScale, Math.abs(step));
            destination = Cesium.Cartographic.toCartesian(positionCartographic);
        } else {
            const cameraFocus = this.getCameraFocus();
            const direction = Cesium.Cartesian3.subtract(cameraFocus ?? camera.direction, camera.position, new Cesium.Cartesian3());
            const zoomScalar = this.calculateScalar(step);
            const movementVector = Cesium.Cartesian3.multiplyByScalar(direction, zoomScalar, new Cesium.Cartesian3());

            currentPosition = camera.position;

            destination = Cesium.Cartesian3.add(camera.position, movementVector, new Cesium.Cartesian3());
        }

        const magnitudeDestination = Cesium.Cartesian3.magnitude(destination);
        const magnitudeCurrent = Cesium.Cartesian3.magnitude(currentPosition);
        const diff = magnitudeCurrent - magnitudeDestination;

        if (zoomIn && diff < minimumZoomDistance) {
            return;
        }

        this.viewer?.camera.flyTo({
            destination,
            orientation: {
                heading: camera.heading,
                pitch: camera.pitch,
                roll: 0,
            },
            duration: ZOOM_ANIMATION_DURATION,
        });
    }

    public getCameraRestoreState(id?: string) {
        if (!this.viewer) {
            return;
        }
        const destination = Cesium.Cartesian3.clone(this.viewer.camera.positionWC, new Cesium.Cartesian3());
        const heading = this.viewer?.camera.heading;
        const pitch = this.viewer?.camera.pitch;

        return {
            id,
            restore: () =>
                this.viewer?.camera.flyTo({
                    destination,
                    orientation: {
                        heading,
                        pitch,
                        roll: 0,
                    },
                    duration: FLY_ANIMATION_DURATION,
                }),
        };
    }

    public setDefaultCamera() {
        const camera = this.viewer?.camera;
        if (!camera) {
            return;
        }

        let newCameraPosition;

        if ([SceneMode.SCENE2D, SceneMode.COLUMBUS_VIEW].includes(this.viewer.scene.mode)) {
            const currentCameraCartographicPosition = camera.positionCartographic;

            newCameraPosition = Cesium.Cartesian3.fromRadians(
                currentCameraCartographicPosition.longitude,
                currentCameraCartographicPosition.latitude,
                currentCameraCartographicPosition.height
            );
        } else {
            const cameraFocusPoint = this.getCameraFocus();
            const ellipsoid = this.viewer.scene.globe.ellipsoid;
            const cameraFocusPointCartographic = ellipsoid.cartesianToCartographic(cameraFocusPoint);
            const distanceFromFocusPoint = Cesium.Cartesian3.distance(camera.position, cameraFocusPoint);

            cameraFocusPointCartographic.height += distanceFromFocusPoint;
            newCameraPosition = ellipsoid.cartographicToCartesian(cameraFocusPointCartographic);
        }

        this.viewer.camera.flyTo({
            destination: newCameraPosition,
            orientation: {
                heading: 0,
                pitch: -Cesium.Math.PI_OVER_TWO,
                roll: 0,
            },
            duration: ZOOM_ANIMATION_DURATION,
            complete: () => this.forceRecalculatingDefaultView.next(undefined),
        });
    }

    public getDistanceFromFocusPoint() {
        const camera = this.viewer?.camera;
        if (!camera) {
            return;
        }

        return Cesium.Cartesian3.distance(camera.position, this.getCameraFocus());
    }

    private initViewerModifier() {
        this.viewerConfiguration.viewerModifier = (viewer: CesiumViewer) => {
            this.viewer = viewer;
            this.cesiumEventHelper.add(viewer.scene.postRender, () => this.mapEntitiesReadySubject.next(viewer.dataSourceDisplay.ready));
            this.cesiumEventHelper.add(viewer.camera.moveStart, () => this.cameraMovingSubject.next(true));
            this.cesiumEventHelper.add(viewer.camera.moveEnd, () => this.cameraMovingSubject.next(false));
            this.cesiumEventHelper.add(viewer.camera.changed, () => this.cameraChangedSubject.next(false));
        };
    }

    private getCameraFocus() {
        const camera = this.viewer.scene.camera;
        const ellipsoid = this.viewer.scene.globe.ellipsoid;
        const ray = new Cesium.Ray(camera.positionWC, camera.directionWC);
        const intersections = Cesium.IntersectionTests.rayEllipsoid(ray, ellipsoid);

        if (intersections) {
            return Cesium.Ray.getPoint(ray, intersections.start);
        }

        // NOTE: Provides the point along the ray which is nearest to the ellipsoid.
        return Cesium.IntersectionTests.grazingAltitudeLocation(ray, ellipsoid);
    }

    private calculateScalar(step: number) {
        if (step < 0) {
            return Math.pow(ZOOM_IN_SCALAR_3D, 1 / Math.abs(step));
        }

        if (step < 1) {
            return -Math.pow(-ZOOM_OUT_SCALAR_3D, step) * step;
        }

        return -Math.pow(-ZOOM_OUT_SCALAR_3D, step);
    }

    private isHeadingDefault(heading: number) {
        const twoPi = Cesium.Math.TWO_PI;
        const normalizedHeading = ((heading % twoPi) + twoPi) % twoPi;
        const tolerance = Cesium.Math.EPSILON6;

        return Math.abs(normalizedHeading) < tolerance || Math.abs(normalizedHeading - 2 * Math.PI) < tolerance;
    }

    private isPitchDefault(pitch: number) {
        const defaultPitch = -Cesium.Math.PI_OVER_TWO;
        const tolerance = Cesium.Math.EPSILON3;

        return Math.abs(pitch - defaultPitch) < tolerance;
    }
}
