import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { PhoneNumber } from "../../models/phone-number.models";
import { DataChangeActionType, DataUpdateAction } from "../../models/user-contact.models";

interface UserContactComponentState {
    isRequestedEmailChange: boolean;
    hasRequestEmailChangeConflictError: boolean;
    hasRequestPhoneChangeConflictError: boolean;
    isRequestedPhoneChange: boolean;
    isVerificationRequired: boolean;
    email: string | undefined;
    phoneNumber: PhoneNumber | undefined;
    isPhoneNumberEditMode: boolean;
    isEmailAddressEditMode: boolean;
    saveButtonLabel: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-user-contact",
    templateUrl: "./user-contact.component.html",
    styleUrls: ["./user-contact.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UserContactComponent implements OnDestroy {
    @Input() public set isRequestedEmailChange(value: BooleanInput) {
        this.localStore.patchState({ isRequestedEmailChange: coerceBooleanProperty(value) });
    }

    @Input() public set hasRequestEmailChangeConflictError(value: BooleanInput) {
        this.localStore.patchState({ hasRequestEmailChangeConflictError: coerceBooleanProperty(value) });
    }

    @Input() public set hasRequestPhoneChangeConflictError(value: BooleanInput) {
        this.localStore.patchState({ hasRequestPhoneChangeConflictError: coerceBooleanProperty(value) });
    }

    @Input() public set isRequestedPhoneChange(value: BooleanInput) {
        this.localStore.patchState({ isRequestedPhoneChange: coerceBooleanProperty(value) });
    }

    @Input() public set email(value: string | undefined) {
        this.localStore.patchState({ email: value });
    }

    @Input() public set phoneNumber(value: PhoneNumber | undefined) {
        this.localStore.patchState({ phoneNumber: value });
    }

    @Input() public set isVerificationRequired(value: boolean) {
        this.localStore.patchState({ isVerificationRequired: value });
    }

    @Input() public set isPhoneNumberEditMode(value: BooleanInput) {
        this.localStore.patchState({ isPhoneNumberEditMode: coerceBooleanProperty(value) });
    }

    @Input() public set isEmailAddressEditMode(value: BooleanInput) {
        this.localStore.patchState({ isEmailAddressEditMode: coerceBooleanProperty(value) });
    }

    @Input() public set saveButtonLabel(value: string | undefined) {
        this.localStore.patchState({ saveButtonLabel: value });
    }

    constructor(private readonly localStore: LocalComponentStore<UserContactComponentState>) {
        this.localStore.setState({
            isRequestedEmailChange: false,
            hasRequestEmailChangeConflictError: false,
            hasRequestPhoneChangeConflictError: false,
            isRequestedPhoneChange: false,
            isVerificationRequired: true,
            email: undefined,
            phoneNumber: undefined,
            isPhoneNumberEditMode: false,
            isEmailAddressEditMode: false,
            saveButtonLabel: undefined,
        });
    }

    protected readonly isRequestedEmailChange$ = this.localStore.selectByKey("isRequestedEmailChange");
    protected readonly hasRequestEmailChangeConflictError$ = this.localStore.selectByKey("hasRequestEmailChangeConflictError");
    protected readonly hasRequestPhoneChangeConflictError$ = this.localStore.selectByKey("hasRequestPhoneChangeConflictError");
    protected readonly isRequestedPhoneChange$ = this.localStore.selectByKey("isRequestedPhoneChange");
    protected readonly email$ = this.localStore.selectByKey("email");
    protected readonly phoneNumber$ = this.localStore.selectByKey("phoneNumber");
    protected readonly isVerificationRequired$ = this.localStore.selectByKey("isVerificationRequired");
    protected readonly isPhoneNumberEditMode$ = this.localStore.selectByKey("isPhoneNumberEditMode");
    protected readonly isEmailAddressEditMode$ = this.localStore.selectByKey("isEmailAddressEditMode");
    protected readonly saveButtonLabel$ = this.localStore.selectByKey("saveButtonLabel");

    @Output() public readonly requestEmailAddressChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() public readonly requestPhoneNumberChange: EventEmitter<PhoneNumber> = new EventEmitter<PhoneNumber>();
    @Output() public readonly saveNewEmailAddress: EventEmitter<string> = new EventEmitter<string>();
    @Output() public readonly saveNewPhoneNumber: EventEmitter<string> = new EventEmitter<string>();
    @Output() public readonly clearEmailAddressChange: EventEmitter<void> = new EventEmitter<void>();
    @Output() public readonly clearPhoneNumberChange: EventEmitter<void> = new EventEmitter<void>();

    protected userProfileDataUpdate({ payload, actionType }: DataUpdateAction): void {
        switch (actionType) {
            case DataChangeActionType.RequestEmailChange:
                this.requestEmailAddressChange.emit(payload as string);
                break;
            case DataChangeActionType.RequestPhoneNumberChange:
                this.requestPhoneNumberChange.emit(payload as PhoneNumber);
                break;
            case DataChangeActionType.SaveNewEmailAddress:
                this.saveNewEmailAddress.emit(payload as string);
                break;
            case DataChangeActionType.SaveNewPhoneNumber:
                this.saveNewPhoneNumber.emit(payload as string);
                break;
        }
    }

    public ngOnDestroy(): void {
        this.clearEmailAddressChange.emit();
        this.clearPhoneNumberChange.emit();
    }
}
