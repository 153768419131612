export interface Page {
    pageNumber: number;
    pageSize: number;
    totalElements: number;
}

export interface PageResponseBody<T> {
    content: T[];
    first: boolean;
    hasContent: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

export const MIN_PAGE_SIZE_VALUE = 10;
export const MIDDLE_PAGE_SIZE_VALUE = 20;
export const MAX_PAGE_SIZE_VALUE = 30;
export const PAGE_SIZE_OPTIONS: number[] = [MIN_PAGE_SIZE_VALUE, MIDDLE_PAGE_SIZE_VALUE, MAX_PAGE_SIZE_VALUE];
export const PAGE_NUMBER_QUERY_PARAM = "page";
export const PAGE_SIZE_QUERY_PARAM = "size";
