import { Injectable, Type } from "@angular/core";
import { ArrayUtils, FunctionUtils, Logger, NarrowIndexable, StringUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Cartesian2, Cartesian3, EditActions, PointProps } from "@pansa/ngx-cesium";
import { BehaviorSubject, EMPTY, Observable, distinctUntilChanged, throwError } from "rxjs";
import { filter, first, startWith, switchMap, tap } from "rxjs/operators";
import { ChildEntityCollection, ChildEntitySubtype } from "../../models/child-entity.model";
import { CylinderEditOptions, CylinderPointProps, CylinderProps } from "../../models/cylinder/cylinder-edit-options";
import { CylinderEditUpdate } from "../../models/cylinder/cylinder-edit-update";
import { CylinderEditorObservable } from "../../models/cylinder/cylinder-editor-observable";
import { CylinderEditorLabelProviders } from "../../models/cylinder/editable-cylinder";
import { EntityLabel, LabelFn, LabelProviders } from "../../models/entity-label.model";
import { Polyline3DEditorLabelProviders } from "../../models/polyline3d/editable-polyline3d";
import { Polyline3DProps } from "../../models/polyline3d/polyline3d-edit-options";
import { Polyline3DEditUpdate } from "../../models/polyline3d/polyline3d-edit-update";
import { PrismEditorLabelProviders } from "../../models/prism/editable-prism";
import { PrismContextMenu } from "../../models/prism/prism-context-menu.model";
import { PrismProps } from "../../models/prism/prism-edit-options";
import { PrismEditUpdate } from "../../models/prism/prism-edit-update";
import { PrismEditorObservable } from "../../models/prism/prism-editor-observable";
import { Polyline3DEditorObservable } from "./../../models/polyline3d/polyline3d-editor-observable";
import { CylindersEditorService } from "./cylinders-editor/cylinders-editor.service";
import { Polylines3DEditorService } from "./polylines3d-editor/polylines3d-editor.service";
import { MapActionType } from "./prisms-editor/map-actions";
import { PrismsEditorService } from "./prisms-editor/prisms-editor.service";

// NOTE: this fix storybook error, directly exporting from this file causes webpack error
export { MapActionType } from "./prisms-editor/map-actions";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export enum MapEntityType {
    Cylinder = "cylinder",
    Prism = "prism",
    Polyline3D = "polyline3D",
}
export interface CylinderEntity {
    id: string;
    type: MapEntityType.Cylinder;
    radius: number;
    topHeight?: number;
    bottomHeight: number;
    center: Cartesian3;
    centerPointHeight?: number;
    inletPointHeight?: number;
    outletPointHeight?: number;
}
export interface PrismEntity {
    id: string;
    type: MapEntityType.Prism;
    topHeight?: number;
    bottomHeight: number;
    positions: Cartesian3[];
    center?: Cartesian3;
}
export interface Polyline3DEntity {
    id: string;
    type: MapEntityType.Polyline3D;
    positions: Cartesian3[];
    heights: (number | undefined)[];
    bufferWidths: number[];
    bufferHeights: number[];
    childEntities: ChildEntityCollection;
}
export type MapEntity = CylinderEntity | PrismEntity | Polyline3DEntity;
interface MapEntityConstraints<T> {
    default: T;
    min: T;
    max: T;
    maximumNumberOfPoints?: number;
}
export type EntityEditorConstraints = MapEntityConstraints<{
    bottomHeight: number;
    topHeight: number;
    radius: number;
    startDelay: number;
    horizontalNavigationAccuracy: number;
    verticalNavigationAccuracy: number;
    runwayVerticalNavigationAccuracy?: number;
    runwayHorizontalNavigationAccuracy?: number;
    largeZoneRadius?: number;
    regularZoneRadius?: number;
}>;
export type MapEntitiesEditorContent = MapEntity[];
type MapEntityEditorObservable = CylinderEditorObservable | PrismEditorObservable | Polyline3DEditorObservable;

// TODO: DTM-3352 use this prefix with enum literals on TypeScript update: `${DRAW_ACTIONS_PREFIX}Cylinder`,
// https://github.com/microsoft/TypeScript/issues/40793
export const DRAW_ACTIONS_PREFIX = "draw";

export interface EntityManualUpdateOptions {
    centerPointProps?: PointProps;
    radiusPointProps?: PointProps;
    cylinderProps?: CylinderProps;
    polylineProps?: Polyline3DProps;
    prismProps?: PrismProps;
}

class EditorObservableEntitiesMap extends Map<string, MapEntityEditorObservable> {
    private areEditorsDisabledSubject = new BehaviorSubject<boolean>(false);

    public get areEditorsDisabled$() {
        return this.areEditorsDisabledSubject.asObservable().pipe(distinctUntilChanged());
    }

    public get areEditorsDisabled() {
        return this.areEditorsDisabledSubject.value;
    }

    public override set(entityId: string, editorObservable: MapEntityEditorObservable): this {
        if (this.areEditorsDisabledSubject.value) {
            editorObservable.disable();
        }

        return super.set(entityId, editorObservable);
    }

    public disableEditors() {
        for (const entityObservable of this.values()) {
            entityObservable.disable();
        }

        this.areEditorsDisabledSubject.next(true);
    }

    public enableEditors() {
        for (const entityObservable of this.values()) {
            entityObservable.enable();
        }

        this.areEditorsDisabledSubject.next(false);
    }
}

@UntilDestroy()
@Injectable()
export class MapEntitiesEditorService {
    private editorContentSubject = new BehaviorSubject<MapEntitiesEditorContent>([]);
    private editorObservableEntitiesMap = new EditorObservableEntitiesMap();
    private activeMapActionSubject = new BehaviorSubject<MapActionType>(MapActionType.None);
    private activeEntitiesSubject = new BehaviorSubject<MapEntityEditorObservable[] | undefined>(undefined);

    private lastActiveMapAction: MapActionType = MapActionType.None;

    public get areEditorsDisabled() {
        return this.editorObservableEntitiesMap.areEditorsDisabled;
    }
    public get editorContentValue() {
        return this.editorContentSubject.value;
    }
    public get activeMapActionValue() {
        return this.activeMapActionSubject.value;
    }

    public readonly areEditorsDisabled$ = this.editorObservableEntitiesMap.areEditorsDisabled$;
    public readonly editorContent$ = this.editorContentSubject.asObservable();
    public readonly activeMapAction$ = this.activeMapActionSubject.asObservable().pipe(distinctUntilChanged());
    public readonly activeEntityStatus$ = this.activeEntitiesSubject.asObservable().pipe(
        switchMap((activeEntities) => activeEntities?.[0]?.status$ ?? EMPTY),
        startWith(undefined)
    );

    constructor(
        private readonly cylindersEditor: CylindersEditorService,
        private readonly prismsEditor: PrismsEditorService,
        private readonly polylines3DEditor: Polylines3DEditorService
    ) {}

    public startPrismEditor(
        entityId: string,
        constraints: EntityEditorConstraints,
        labelProviders: PrismEditorLabelProviders,
        options?: {
            parentEntityId?: string;
            parentWaypointIndex?: number;
            pointProps?: PointProps;
            prismProps?: PrismProps;
            contextMenu?: Type<PrismContextMenu>;
            topHeight?: number;
            bottomHeight?: number;
        }
    ): void {
        this.activeMapActionSubject.next(MapActionType.DrawPrism);
        this.editorObservableEntitiesMap.get(entityId)?.dispose();

        let parentObservable;
        let initialPoints;

        if (
            options?.parentEntityId &&
            options.parentWaypointIndex !== undefined &&
            this.editorObservableEntitiesMap.get(options.parentEntityId)
        ) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            parentObservable = this.editorObservableEntitiesMap.get(options.parentEntityId)! as Polyline3DEditorObservable;
            initialPoints = [parentObservable.getVirtualPoints()[options.parentWaypointIndex].getPosition()];
        }

        const editorObservable = this.prismsEditor.create(
            entityId,
            options?.topHeight ?? constraints.default.topHeight,
            options?.bottomHeight ?? constraints.default.bottomHeight,
            {
                prismProps: {
                    ...options?.prismProps,
                    maxTopHeight: constraints.max.topHeight,
                    maxBottomHeight: constraints.max.bottomHeight,
                    minTopHeight: constraints.min.topHeight,
                    minBottomHeight: constraints.min.bottomHeight,
                    minHeight: 1,
                },
                pointProps: options?.pointProps,
                maximumNumberOfPoints: constraints.maximumNumberOfPoints,
                contextMenu: options?.contextMenu,
            },
            initialPoints
        );
        this.activeEntitiesSubject.next([editorObservable]);

        const significantEditActions = new Set<EditActions | null>([
            EditActions.DONE,
            EditActions.DRAG_POINT,
            EditActions.DRAG_POINT_FINISH,
            EditActions.DRAG_SHAPE,
            EditActions.DRAG_SHAPE_FINISH,
        ]);

        this.observePrism(
            editorObservable,
            significantEditActions,
            entityId,
            labelProviders,
            parentObservable,
            options?.parentWaypointIndex
        );
    }

    public setChildEntityPrismForPolyline3D(
        positions: Cartesian3[],
        topHeight: number,
        bottomHeight: number,
        entityId: string,
        constraints: EntityEditorConstraints,
        labelProviders: PrismEditorLabelProviders,
        parentEntityId: string,
        parentWaypointIndex: number,
        options?: {
            prismProps?: PrismProps;
            pointProps?: PointProps;
        }
    ) {
        this.editorObservableEntitiesMap.get(entityId)?.dispose();

        const editorObservable = this.prismsEditor.edit(positions, topHeight, bottomHeight, entityId, {
            prismProps: {
                ...options?.prismProps,
                minTopHeight: constraints.min.topHeight,
                maxTopHeight: constraints.max.topHeight,
                minBottomHeight: constraints.min.bottomHeight,
                maxBottomHeight: constraints.max.bottomHeight,
                minHeight: 1,
            },
            pointProps: options?.pointProps,
        });

        const significantEditActions = new Set<EditActions | null>([
            EditActions.INIT,
            EditActions.DONE,
            EditActions.DRAG_POINT,
            EditActions.DRAG_POINT_FINISH,
            EditActions.DRAG_SHAPE,
            EditActions.DRAG_SHAPE_FINISH,
            EditActions.REMOVE_POINT,
        ]);

        this.editorObservableEntitiesMap.set(entityId, editorObservable);

        const generateLabel = this.createLabelsGenerator(labelProviders);
        editorObservable.setLabelsRenderFn((update) => {
            const labels = [generateLabel("topHeight", update.topHeight), generateLabel("bottomHeight", update.bottomHeight)];

            return labels;
        });

        const parentObservable = this.editorObservableEntitiesMap.get(parentEntityId) as Polyline3DEditorObservable;

        parentObservable.setChildEntityManually(
            {
                id: entityId,
                type: MapEntityType.Prism,
                positions,
                topHeight,
                bottomHeight,
            },
            parentWaypointIndex
        );

        this.startWatchingForPrismUpdates(editorObservable, significantEditActions, entityId, parentObservable);
    }

    public createEditablePrism(
        positions: Cartesian3[],
        entityId: string,
        constraints: EntityEditorConstraints,
        labelProviders: PrismEditorLabelProviders,
        options?: {
            parentEntityId?: string;
            parentWaypointIndex?: number;
            prismProps?: PrismProps;
            topHeight?: number;
            bottomHeight?: number;
        }
    ) {
        this.editorObservableEntitiesMap.get(entityId)?.dispose();

        const editorObservable = this.prismsEditor.edit(
            positions,
            options?.topHeight ?? constraints.default.topHeight,
            options?.bottomHeight ?? constraints.default.bottomHeight,
            entityId,
            {
                prismProps: {
                    ...options?.prismProps,
                    minTopHeight: constraints.min.topHeight,
                    maxTopHeight: constraints.max.topHeight,
                    minBottomHeight: constraints.min.bottomHeight,
                    maxBottomHeight: constraints.max.bottomHeight,
                    minHeight: 1,
                },
            }
        );

        const significantEditActions = new Set<EditActions | null>([
            EditActions.INIT,
            EditActions.DONE,
            EditActions.DRAG_POINT,
            EditActions.DRAG_POINT_FINISH,
            EditActions.DRAG_SHAPE,
            EditActions.DRAG_SHAPE_FINISH,
            EditActions.REMOVE_POINT,
        ]);

        const parentObservable = options?.parentEntityId
            ? (this.editorObservableEntitiesMap.get(options.parentEntityId) as Polyline3DEditorObservable)
            : undefined;

        this.observePrism(
            editorObservable,
            significantEditActions,
            entityId,
            labelProviders,
            parentObservable,
            options?.parentWaypointIndex
        );
    }

    private observePrism(
        editorObservable: PrismEditorObservable,
        significantEditActions: Set<EditActions | null>,
        entityId: string,
        labelProviders: PrismEditorLabelProviders,
        parentObservable?: Polyline3DEditorObservable,
        parentWaypointIndex?: number
    ) {
        this.editorObservableEntitiesMap.set(entityId, editorObservable);

        const generateLabel = this.createLabelsGenerator(labelProviders);
        editorObservable.setLabelsRenderFn((update) => {
            const labels = [generateLabel("topHeight", update.topHeight), generateLabel("bottomHeight", update.bottomHeight)];

            if (!parentObservable) {
                labels.push(generateLabel("waypoint", entityId));
            }

            return labels;
        });

        this.startWatchingForPrismUpdates(editorObservable, significantEditActions, entityId, parentObservable, parentWaypointIndex);
    }

    private startWatchingForPrismUpdates(
        editorObservable: PrismEditorObservable,
        significantEditActions: Set<EditActions | null>,
        entityId: string,
        parentObservable?: Polyline3DEditorObservable,
        parentWaypointIndex?: number
    ): void {
        editorObservable
            .pipe(
                filter((editUpdate: PrismEditUpdate) => significantEditActions.has(editUpdate.editAction)),
                tap((editUpdate: PrismEditUpdate) => {
                    if (!editorObservable.getIsValid()) {
                        return;
                    }

                    if (editUpdate.editAction === EditActions.DONE) {
                        significantEditActions.add(EditActions.REMOVE_POINT);
                        this.activeMapActionSubject.next(MapActionType.None);
                    }

                    if (!editUpdate.positions || editUpdate.topHeight === undefined || editUpdate.bottomHeight === undefined) {
                        return;
                    }

                    const prismEntity: PrismEntity = {
                        id: entityId,
                        topHeight: editUpdate.topHeight,
                        bottomHeight: editUpdate.bottomHeight,
                        type: MapEntityType.Prism,
                        positions: editUpdate.positions,
                        center: editorObservable.getWaypoint(),
                    };

                    if (parentObservable) {
                        this.updateChildEntity(prismEntity, parentObservable, parentWaypointIndex);
                    } else {
                        this.updateEntity(prismEntity);
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private startPolylines3DEditorForTakeoffRunway(
        takeoffRunway: CylinderEditUpdate,
        runwayEditorObservable: CylinderEditorObservable,
        takeoffRunwayProxyId: string,
        constraints: EntityEditorConstraints,
        entityId: string,
        significantEditActions: Set<EditActions | null>,
        labelProviders: Polyline3DEditorLabelProviders,
        runwayLabelProviders: CylinderEditorLabelProviders,
        options?: {
            runwayPointProps?: CylinderPointProps;
            pointProps?: CylinderPointProps;
        }
    ) {
        if (!takeoffRunway.center || !takeoffRunway.radiusPoint) {
            runwayEditorObservable.dispose();
            this.editorObservableEntitiesMap.delete(takeoffRunwayProxyId);

            return throwError(() => "Invalid edit action");
        }

        const takeoffRunwayId = `${entityId}-takeoff`;
        const center = takeoffRunway.center;
        const radius = Math.max(
            Math.min(takeoffRunway.radius ?? constraints.default.radius, constraints.max.radius),
            constraints.min.radius
        );

        const polylines3DEditorObservable = this.polylines3DEditor.create(
            entityId,
            {
                polylineProps: {
                    maxTopHeight: constraints.max.topHeight,
                    maxBottomHeight: constraints.max.bottomHeight,
                    minTopHeight: constraints.min.topHeight,
                    minBottomHeight: constraints.min.bottomHeight,
                    defaultTopHeight: constraints.default.topHeight,
                    defaultBufferWidth: constraints.default.horizontalNavigationAccuracy * 2,
                    defaultBufferHeight: constraints.default.verticalNavigationAccuracy * 2,
                },
                minimumNumberOfPoints: 3,
                pointProps: options?.pointProps,
            },
            [takeoffRunway.center, takeoffRunway.radiusPoint]
        );
        this.observePolyline3D(polylines3DEditorObservable, significantEditActions, entityId, labelProviders);

        this.setChildEntityCylinderForPolyline3D(
            center,
            radius,
            constraints.default.topHeight +
                (constraints.default.runwayVerticalNavigationAccuracy ?? constraints.default.verticalNavigationAccuracy),
            0,
            takeoffRunwayId,
            this.getRunwayConstraints(constraints),
            runwayLabelProviders,
            entityId,
            0,
            {
                pointProps: options?.runwayPointProps,
            }
        );
        runwayEditorObservable.dispose();
        this.editorObservableEntitiesMap.delete(takeoffRunwayProxyId);
        this.activeEntitiesSubject.next([polylines3DEditorObservable, ...(this.activeEntitiesSubject.value ?? [])]);

        return polylines3DEditorObservable;
    }

    public startPolylines3DEditorWithRunways(
        entityId: string,
        constraints: EntityEditorConstraints,
        labelProviders: Polyline3DEditorLabelProviders,
        runwayLabelProviders: CylinderEditorLabelProviders,
        pointProps: CylinderPointProps,
        runwayPointProps: CylinderPointProps
    ): void {
        this.editorObservableEntitiesMap.get(entityId)?.dispose();

        const takeoffRunwayProxyId = `${entityId}-takeoff-proxy`;
        const landingRunwayId = `${entityId}-landing`;
        const landingRunwayProxyId = `${entityId}-landing-proxy`;
        const runwayEditOptions: CylinderEditOptions = {
            cylinderProps: {
                areHeightsDraggable: false,
                maxRadius: constraints.max.radius,
                minRadius: constraints.min.runwayHorizontalNavigationAccuracy ?? constraints.min.radius,
                minTopHeight: constraints.min.topHeight,
                maxTopHeight: constraints.max.topHeight,
                minBottomHeight: 0,
                maxBottomHeight: 0,
                minHeight: 1,
            },
            pointProps: runwayPointProps,
        };
        const runwayLabelsGenerator = this.createLabelsGenerator(runwayLabelProviders);
        const significantEditActions = new Set<EditActions | null>([
            EditActions.DRAG_POINT,
            EditActions.DRAG_POINT_FINISH,
            EditActions.DRAG_SHAPE,
            EditActions.DRAG_SHAPE_FINISH,
            EditActions.REMOVE_POINT,
        ]);

        let polylines3DEditorObservable: Polyline3DEditorObservable | Observable<never>;
        let runwayEditorObservable = this.cylindersEditor.create(takeoffRunwayProxyId, runwayEditOptions);
        this.activeEntitiesSubject.next([runwayEditorObservable]);
        this.editorObservableEntitiesMap.set(takeoffRunwayProxyId, runwayEditorObservable);
        runwayEditorObservable.setLabelsRenderFn((update) => [runwayLabelsGenerator("radius", update.radius)]);

        this.activeMapActionSubject.next(MapActionType.DrawTakeoffRunway);

        runwayEditorObservable
            .pipe(
                first((editUpdate) => editUpdate.editAction === EditActions.DONE),
                switchMap((takeoffRunway) => {
                    this.activeMapActionSubject.next(MapActionType.DrawPolyline);

                    polylines3DEditorObservable = this.startPolylines3DEditorForTakeoffRunway(
                        takeoffRunway,
                        runwayEditorObservable,
                        takeoffRunwayProxyId,
                        constraints,
                        entityId,
                        significantEditActions,
                        labelProviders,
                        runwayLabelProviders,
                        {
                            runwayPointProps,
                            pointProps,
                        }
                    );

                    return polylines3DEditorObservable;
                }),
                first((editUpdate) => editUpdate.editAction === EditActions.DONE),
                switchMap((editUpdate) => {
                    const positions = editUpdate.positions;

                    if (!positions || positions.length < 1) {
                        return throwError(() => "Invalid edit action");
                    }

                    this.activeMapActionSubject.next(MapActionType.DrawLandingRunway);

                    runwayEditorObservable = this.cylindersEditor.create(
                        landingRunwayProxyId,
                        runwayEditOptions,
                        positions[positions.length - 1]
                    );
                    this.activeEntitiesSubject.next([runwayEditorObservable, ...(this.activeEntitiesSubject.value ?? [])]);

                    this.editorObservableEntitiesMap.set(landingRunwayProxyId, runwayEditorObservable);
                    runwayEditorObservable.setLabelsRenderFn((update) => [runwayLabelsGenerator("radius", update.radius)]);

                    return runwayEditorObservable;
                }),
                first((editUpdate) => editUpdate.editAction === EditActions.DONE),
                tap((editUpdate) => {
                    if (editUpdate.center === undefined || editUpdate.radius === undefined) {
                        return;
                    }

                    significantEditActions.add(EditActions.DONE);

                    this.startCylinderEditor(landingRunwayId, this.getRunwayConstraints(constraints), runwayLabelProviders, {
                        radius: editUpdate.radius,
                        topHeight:
                            constraints.default.topHeight +
                            (constraints.default.runwayVerticalNavigationAccuracy ?? constraints.default.verticalNavigationAccuracy),
                        parentEntityId: entityId,
                        parentWaypointIndex: (polylines3DEditorObservable as Polyline3DEditorObservable).getCurrentPoints().length,
                        pointProps: runwayPointProps,
                    });
                    (polylines3DEditorObservable as Polyline3DEditorObservable).finishCreation();
                    runwayEditorObservable.dispose();
                    this.editorObservableEntitiesMap.delete(landingRunwayProxyId);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private getRunwayConstraints(constraints: EntityEditorConstraints): EntityEditorConstraints {
        return {
            ...constraints,
            default: {
                ...constraints.default,
                bottomHeight: 0,
            },
            max: {
                ...constraints.max,
                bottomHeight: 0,
            },
            min: {
                ...constraints.min,
                radius: constraints.min.runwayHorizontalNavigationAccuracy ?? constraints.min.radius,
                topHeight: (constraints.min.runwayVerticalNavigationAccuracy ?? constraints.min.topHeight / 2) * 2,
                bottomHeight: 0,
            },
        };
    }

    public startPolylines3DEditor(
        entityId: string,
        constraints: EntityEditorConstraints,
        labelProviders: Polyline3DEditorLabelProviders,
        options?: {
            customMapAction?: MapActionType;
            polylineProps?: Partial<Polyline3DProps>;
        }
    ): void {
        this.editorObservableEntitiesMap.get(entityId)?.dispose();
        this.activeMapActionSubject.next(options?.customMapAction ?? MapActionType.DrawPolyline);

        const editorObservable = this.polylines3DEditor.create(entityId, {
            polylineProps: {
                maxTopHeight: constraints.max.topHeight,
                maxBottomHeight: constraints.max.bottomHeight,
                minTopHeight: constraints.min.topHeight,
                minBottomHeight: constraints.min.bottomHeight,
                defaultTopHeight: constraints.default.topHeight,
                defaultBufferWidth: constraints.default.horizontalNavigationAccuracy * 2,
                defaultBufferHeight: constraints.default.verticalNavigationAccuracy * 2,
                ...options?.polylineProps,
            },
        });
        this.activeEntitiesSubject.next([editorObservable]);

        const significantEditActions = new Set<EditActions | null>([
            EditActions.DONE,
            EditActions.DRAG_POINT,
            EditActions.DRAG_POINT_FINISH,
            EditActions.DRAG_SHAPE,
            EditActions.DRAG_SHAPE_FINISH,
            EditActions.REMOVE_POINT,
        ]);

        this.observePolyline3D(editorObservable, significantEditActions, entityId, labelProviders);
    }

    public createEditablePolyline3D(
        positions: Cartesian3[],
        heights: (number | undefined)[],
        bufferWidths: number[],
        bufferHeights: number[],
        entityId: string,
        constraints: EntityEditorConstraints,
        labelProviders: Polyline3DEditorLabelProviders
    ) {
        this.editorObservableEntitiesMap.get(entityId)?.dispose();

        const editorObservable = this.polylines3DEditor.edit(positions, heights, bufferWidths, bufferHeights, entityId, {
            polylineProps: {
                minTopHeight: constraints.min.topHeight,
                maxTopHeight: constraints.max.topHeight,
                minBottomHeight: constraints.min.bottomHeight,
                maxBottomHeight: constraints.max.bottomHeight,
            },
        });

        const significantEditActions = new Set<EditActions | null>([
            EditActions.INIT,
            EditActions.DONE,
            EditActions.DRAG_POINT,
            EditActions.DRAG_POINT_FINISH,
            EditActions.DRAG_SHAPE,
            EditActions.DRAG_SHAPE_FINISH,
            EditActions.REMOVE_POINT,
        ]);

        this.observePolyline3D(editorObservable, significantEditActions, entityId, labelProviders);
    }

    private observePolyline3D(
        editorObservable: Polyline3DEditorObservable,
        significantEditActions: Set<EditActions | null>,
        entityId: string,
        labelProviders: Polyline3DEditorLabelProviders
    ) {
        this.editorObservableEntitiesMap.set(entityId, editorObservable);

        const generateLabel = this.createLabelsGenerator(labelProviders);
        editorObservable.setLabelsRenderFn((update) =>
            (update.heights ?? [])
                .map((height, index) => [
                    generateLabel("pointHeight", { entityId, index, height: height ?? 0 }),
                    generateLabel("waypoint", { entityId, index }),
                    generateLabel("segment", { entityId, index }),
                ])
                .flat()
        );

        editorObservable
            .pipe(
                filter((editUpdate: Polyline3DEditUpdate) => significantEditActions.has(editUpdate.editAction)),
                tap((editUpdate: Polyline3DEditUpdate) => {
                    if (editUpdate.editAction === EditActions.DONE) {
                        this.activeMapActionSubject.next(MapActionType.None);
                    }

                    if (
                        !editUpdate.positions ||
                        !editUpdate.heights ||
                        !editUpdate.bufferWidths ||
                        !editUpdate.bufferHeights ||
                        !editUpdate.childEntities
                    ) {
                        return;
                    }

                    const polyline3DEntity: Polyline3DEntity = {
                        id: entityId,
                        type: MapEntityType.Polyline3D,
                        positions: editUpdate.positions,
                        heights: editUpdate.heights,
                        bufferWidths: editUpdate.bufferWidths,
                        bufferHeights: editUpdate.bufferHeights,
                        childEntities: editUpdate.childEntities,
                    };

                    this.updateEntity(polyline3DEntity);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public startCylinderEditor(
        entityId: string,
        constraints: EntityEditorConstraints,
        labelProviders: CylinderEditorLabelProviders,
        options?: {
            radius?: number;
            topHeight?: number;
            bottomHeight?: number;
            parentEntityId?: string;
            parentWaypointIndex?: number;
            customMapAction?: MapActionType;
            pointProps?: CylinderPointProps;
            cylinderProps?: CylinderProps;
        }
    ): void {
        this.editorObservableEntitiesMap.get(entityId)?.dispose();
        this.activeMapActionSubject.next(options?.customMapAction ?? MapActionType.DrawCylinder);

        if (
            options?.parentEntityId &&
            options.parentWaypointIndex !== undefined &&
            this.editorObservableEntitiesMap.get(options.parentEntityId)
        ) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const parentObservable = this.editorObservableEntitiesMap.get(options.parentEntityId)! as Polyline3DEditorObservable;
            const realPoints = parentObservable.getCurrentPoints();

            let center;

            if (options.parentWaypointIndex === -1) {
                center = realPoints[0].getPosition();
            } else if (options.parentWaypointIndex === realPoints.length) {
                center = realPoints[realPoints.length - 1].getPosition();
            } else {
                center = parentObservable.getVirtualPoints()[options.parentWaypointIndex].getPosition();
            }

            this.createEditableCylinder(
                center,
                entityId,
                constraints,
                labelProviders,
                options.radius ?? constraints.default.radius,
                options.topHeight ?? constraints.default.topHeight,
                {
                    parentEntityId: options.parentEntityId,
                    parentWaypointIndex: options.parentWaypointIndex,
                    pointProps: options.pointProps,
                    cylinderProps: options.cylinderProps,
                    bottomHeight: options.bottomHeight,
                }
            );

            return;
        }

        const cylinderEditOptions: CylinderEditOptions = {
            cylinderProps: {
                ...options?.cylinderProps,
                maxRadius: constraints.max.radius,
                minRadius: constraints.min.radius,
                minTopHeight: constraints.min.topHeight,
                maxTopHeight: constraints.max.topHeight,
                minBottomHeight: constraints.min.bottomHeight,
                maxBottomHeight: constraints.max.bottomHeight,
                minHeight: 1,
            },
            pointProps: options?.pointProps,
        };
        const firstClickEntityId = StringUtils.generateId();
        const firstClickEditorObservable = this.cylindersEditor.create(firstClickEntityId, cylinderEditOptions);
        this.activeEntitiesSubject.next([firstClickEditorObservable]);
        this.editorObservableEntitiesMap.set(entityId, firstClickEditorObservable);

        if (options?.radius === undefined) {
            firstClickEditorObservable.setLabelsRenderFn((update) => [this.createLabelsGenerator(labelProviders)("radius", update.radius)]);
        }

        firstClickEditorObservable
            .pipe(
                first(
                    (editUpdate: CylinderEditUpdate) =>
                        editUpdate.editAction === (options?.radius !== undefined ? EditActions.ADD_POINT : EditActions.DONE)
                ),
                tap((firstClick) => {
                    if (!firstClick.center) {
                        return;
                    }

                    const radius = Math.max(
                        Math.min(firstClick.radius || constraints.default.radius, constraints.max.radius),
                        constraints.min.radius
                    );

                    firstClickEditorObservable.dispose();
                    this.editorObservableEntitiesMap.delete(firstClickEntityId);

                    this.createEditableCylinder(
                        firstClick.center,
                        entityId,
                        constraints,
                        labelProviders,
                        radius,
                        options?.topHeight || constraints.default.topHeight,
                        {
                            pointProps: options?.pointProps,
                            cylinderProps: options?.cylinderProps,
                        }
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public createEditableCylinder(
        cylinderCenter: Cartesian3,
        entityId: string,
        constraints: EntityEditorConstraints,
        labelProviders: CylinderEditorLabelProviders,
        radius: number,
        topHeight?: number,
        options?: {
            bottomHeight?: number;
            parentEntityId?: string;
            parentWaypointIndex?: number;
            pointProps?: CylinderPointProps;
            cylinderProps?: CylinderProps;
        }
    ): CylinderEditorObservable {
        this.editorObservableEntitiesMap.get(entityId)?.dispose();

        const editorObservable = this.cylindersEditor.edit(
            cylinderCenter,
            radius,
            topHeight ?? constraints.default.topHeight,
            options?.bottomHeight ?? constraints.default.bottomHeight,
            entityId,
            {
                cylinderProps: {
                    ...options?.cylinderProps,
                    maxRadius: constraints.max.radius,
                    minRadius: constraints.min.radius,
                    minTopHeight: constraints.min.topHeight,
                    maxTopHeight: constraints.max.topHeight,
                    minBottomHeight: constraints.min.bottomHeight,
                    maxBottomHeight: constraints.max.bottomHeight,
                    minHeight: 1,
                },
                pointProps: options?.pointProps,
            }
        );
        this.editorObservableEntitiesMap.set(entityId, editorObservable);

        const generateLabel = this.createLabelsGenerator(labelProviders);
        editorObservable.setLabelsRenderFn((update) => {
            const labels = [
                generateLabel("radius", update.radius),
                generateLabel("topHeight", update.topHeight),
                generateLabel("bottomHeight", update.bottomHeight),
                generateLabel("center", null),
            ];

            if (!options?.parentEntityId) {
                labels.push(generateLabel("waypoint", entityId));
            }

            return labels;
        });

        const parentObservable = options?.parentEntityId
            ? (this.editorObservableEntitiesMap.get(options.parentEntityId) as Polyline3DEditorObservable)
            : undefined;

        this.activeMapActionSubject.next(MapActionType.None);

        this.startWatchingForCylinderUpdates(entityId, editorObservable, parentObservable, options?.parentWaypointIndex);

        return editorObservable;
    }

    public setChildEntityCylinderForPolyline3D(
        center: Cartesian3,
        radius: number,
        topHeight: number,
        bottomHeight: number,
        entityId: string,
        constraints: EntityEditorConstraints,
        labelProviders: CylinderEditorLabelProviders,
        parentEntityId: string,
        parentWaypointIndex: number,
        options?: {
            pointProps?: CylinderPointProps;
            cylinderProps?: CylinderProps;
        }
    ) {
        this.editorObservableEntitiesMap.get(entityId)?.dispose();

        const editorObservable = this.cylindersEditor.edit(center, radius, topHeight, bottomHeight, entityId, {
            cylinderProps: {
                ...options?.cylinderProps,
                maxRadius: constraints.max.radius,
                minRadius: constraints.min.radius,
                minTopHeight: constraints.min.topHeight,
                maxTopHeight: constraints.max.topHeight,
                minBottomHeight: constraints.min.bottomHeight,
                maxBottomHeight: constraints.max.bottomHeight,
                minHeight: 1,
            },
            pointProps: options?.pointProps,
        });
        this.editorObservableEntitiesMap.set(entityId, editorObservable);
        this.activeEntitiesSubject.next([editorObservable, ...(this.activeEntitiesSubject.value ?? [])]);

        const generateLabel = this.createLabelsGenerator(labelProviders);
        editorObservable.setLabelsRenderFn((update) => {
            const labels = [
                generateLabel("radius", update.radius),
                generateLabel("topHeight", update.topHeight),
                generateLabel("bottomHeight", update.bottomHeight),
            ];

            return labels;
        });

        const parentObservable = this.editorObservableEntitiesMap.get(parentEntityId) as Polyline3DEditorObservable;

        parentObservable.setChildEntityManually(
            {
                id: entityId,
                type: MapEntityType.Cylinder,
                center,
                topHeight,
                bottomHeight,
                radius,
            },
            parentWaypointIndex
        );

        this.startWatchingForCylinderUpdates(entityId, editorObservable, parentObservable);

        return editorObservable;
    }

    private startWatchingForCylinderUpdates(
        entityId: string,
        editorObservable: CylinderEditorObservable,
        parentObservable?: Polyline3DEditorObservable,
        parentWaypointIndex?: number
    ): void {
        editorObservable
            .pipe(
                tap((editUpdate: CylinderEditUpdate) => {
                    if (
                        !editUpdate.center ||
                        editUpdate.topHeight === undefined ||
                        editUpdate.bottomHeight === undefined ||
                        editUpdate.radius === undefined
                    ) {
                        return;
                    }

                    const cylinderEntity: CylinderEntity = {
                        id: entityId,
                        type: MapEntityType.Cylinder,
                        center: editUpdate.center,
                        topHeight: editUpdate.topHeight,
                        bottomHeight: editUpdate.bottomHeight,
                        radius: editUpdate.radius,
                    };

                    if (parentObservable) {
                        this.updateChildEntity(cylinderEntity, parentObservable, parentWaypointIndex);
                    } else {
                        this.updateEntity(cylinderEntity);
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private createLabelsGenerator<LabelProvidersType extends LabelProviders>(labelProviders: LabelProvidersType) {
        const LABEL_PIXEL_OFFSET_X = 12;
        const LABEL_PIXEL_OFFSET_Y = 5;
        const labelPixelOffset: Cartesian2 = Cesium.Cartesian2.fromElements(LABEL_PIXEL_OFFSET_X, LABEL_PIXEL_OFFSET_Y);

        return <LabelProviderKey extends keyof NarrowIndexable<LabelProvidersType>>(
            providerKey: LabelProviderKey,
            value: NonNullable<NarrowIndexable<LabelProvidersType>[LabelProviderKey]> extends LabelFn<infer R, unknown>
                ? R | undefined
                : never,
            entityId?: string
        ): EntityLabel<LabelProvidersType> => {
            const label = labelProviders[providerKey]?.(value);

            return {
                pixelOffset: labelPixelOffset,
                show: !!label,
                text: label ?? "",
                id: providerKey,
                entityId,
            };
        };
    }

    private updateChildEntity(entity: ChildEntitySubtype, parentObservable: Polyline3DEditorObservable, waypointIndex?: number) {
        const currentState = this.editorContentSubject.getValue();
        const editUpdate = parentObservable.addOrUpdateChildEntity(entity, waypointIndex);

        if (
            !editUpdate ||
            !editUpdate.positions ||
            !editUpdate.heights ||
            !editUpdate.bufferWidths ||
            !editUpdate.bufferHeights ||
            !editUpdate.childEntities
        ) {
            return;
        }

        const polyline3DEntity: Polyline3DEntity = {
            id: parentObservable.getEntityId(),
            type: MapEntityType.Polyline3D,
            positions: editUpdate.positions,
            heights: editUpdate.heights,
            bufferWidths: editUpdate.bufferWidths,
            bufferHeights: editUpdate.bufferHeights,
            childEntities: editUpdate.childEntities,
        };

        this.editorContentSubject.next(
            ArrayUtils.replace(currentState, polyline3DEntity, (lookup: MapEntity) => lookup.id === polyline3DEntity.id)
        );

        parentObservable.refreshLabels();
    }

    private updateEntity(entity: MapEntity) {
        const currentState = this.editorContentSubject.getValue();
        const parentEntity = currentState.find(
            (mapEntity) => mapEntity.type === MapEntityType.Polyline3D && !!mapEntity.childEntities[entity.id]
        ) as Polyline3DEntity;

        if (!parentEntity || entity.type === MapEntityType.Polyline3D) {
            this.editorContentSubject.next(ArrayUtils.addOrReplace(currentState, entity, (lookup: MapEntity) => lookup.id === entity.id));

            return;
        }

        const parentObservable = this.editorObservableEntitiesMap.get(parentEntity.id) as Polyline3DEditorObservable;
        this.updateChildEntity(entity, parentObservable);
    }

    public stopEditors() {
        this.activeMapActionSubject.next(MapActionType.None);
        for (const entityObservable of this.editorObservableEntitiesMap.values()) {
            entityObservable.dispose();
        }

        this.editorObservableEntitiesMap.clear();
        this.editorContentSubject.next([]);
    }

    public finishActiveEntityDrawing() {
        this.activeEntitiesSubject.value?.[0]?.finishCreation();
    }

    public removeLastPointFromActiveEntity() {
        (this.activeEntitiesSubject.value as [Polyline3DEditorObservable] | [PrismEditorObservable] | undefined)?.[0].removeLastPoint?.();
    }

    public cancelActiveEntityDrawing() {
        const activeEntities = this.activeEntitiesSubject.value;

        if (!activeEntities) {
            return;
        }

        for (const entityObservable of activeEntities) {
            this.editorObservableEntitiesMap.delete(entityObservable.getId());
            entityObservable.dispose();
        }

        this.activeEntitiesSubject.next(undefined);
        this.activeMapActionSubject.next(MapActionType.None);
    }

    public disableEditors() {
        this.editorObservableEntitiesMap.disableEditors();
        this.lastActiveMapAction = this.activeMapActionValue;

        this.activeMapActionSubject.next(MapActionType.None);
    }

    public enableEditors() {
        this.editorObservableEntitiesMap.enableEditors();
        this.activeMapActionSubject.next(this.lastActiveMapAction);
    }

    public refreshLabels() {
        for (const entityObservable of this.editorObservableEntitiesMap.values()) {
            entityObservable.refreshLabels();
        }
    }

    public update(updatedEntity: MapEntity, options?: EntityManualUpdateOptions) {
        const editorObservable = this.editorObservableEntitiesMap.get(updatedEntity.id);

        if (!editorObservable) {
            Logger.captureException("MapEntitiesEditor.update: Entity doesn't exist", { extra: { entity: updatedEntity } });

            return;
        }

        switch (updatedEntity.type) {
            case MapEntityType.Cylinder: {
                const cylinderEditorObservable = editorObservable as CylinderEditorObservable;

                cylinderEditorObservable.setManually(
                    updatedEntity.center,
                    updatedEntity.radius,
                    updatedEntity.topHeight,
                    updatedEntity.bottomHeight,
                    options?.centerPointProps,
                    options?.radiusPointProps,
                    options?.cylinderProps
                );

                updatedEntity = {
                    ...updatedEntity,
                    center: cylinderEditorObservable.getCenter() ?? updatedEntity.center,
                } satisfies CylinderEntity; // eslint-disable-line no-undef
                // TODO: Remove above comment after typesript upgrade DTM-4657

                break;
            }
            case MapEntityType.Prism: {
                const prismEditorObservable = editorObservable as PrismEditorObservable;

                prismEditorObservable.setManually(
                    updatedEntity.positions,
                    updatedEntity.topHeight,
                    updatedEntity.bottomHeight,
                    updatedEntity.center,
                    options?.prismProps
                );

                updatedEntity = {
                    ...updatedEntity,
                    positions: prismEditorObservable.getCurrentPoints().map((point) => point.getPosition()),
                    center: prismEditorObservable.getWaypoint() ?? updatedEntity.center,
                } satisfies PrismEntity; // eslint-disable-line no-undef
                // TODO: Remove above comment after typesript upgrade DTM-4657

                break;
            }
            case MapEntityType.Polyline3D: {
                const polyline3DEditorObservable = editorObservable as Polyline3DEditorObservable;

                polyline3DEditorObservable.setManually(
                    updatedEntity.positions,
                    updatedEntity.heights.filter(FunctionUtils.isTruthy),
                    updatedEntity.bufferWidths,
                    updatedEntity.bufferHeights,
                    updatedEntity.childEntities,
                    options?.polylineProps
                );

                updatedEntity = {
                    ...updatedEntity,
                    positions: polyline3DEditorObservable.getCurrentPoints().map((point) => point.getPosition()),
                } satisfies Polyline3DEntity; // eslint-disable-line no-undef
                // TODO: Remove above comment after typesript upgrade DTM-4657

                break;
            }
        }

        this.updateEntity(updatedEntity);
    }

    public deletePolyline3DWaypoint(entity: Polyline3DEntity, waypointIndex: number) {
        const observableEntity = this.editorObservableEntitiesMap.get(entity.id ?? "");

        if (!observableEntity) {
            Logger.captureMessage("MapEntitiesEditor.deletePolyline3DWaypoint: Entity doesn't exist", { extra: { entity } });

            return;
        }

        const parentObservable = observableEntity as Polyline3DEditorObservable;
        const currentState = this.editorContentSubject.getValue();
        const editUpdate = parentObservable.deleteWaypoint(waypointIndex);

        if (
            !editUpdate ||
            !editUpdate.positions ||
            !editUpdate.heights ||
            !editUpdate.bufferWidths ||
            !editUpdate.bufferHeights ||
            !editUpdate.childEntities
        ) {
            return;
        }

        const polyline3DEntity: Polyline3DEntity = {
            id: parentObservable.getEntityId(),
            type: MapEntityType.Polyline3D,
            positions: editUpdate.positions,
            heights: editUpdate.heights,
            bufferWidths: editUpdate.bufferWidths,
            bufferHeights: editUpdate.bufferHeights,
            childEntities: editUpdate.childEntities,
        };

        this.editorContentSubject.next(
            ArrayUtils.replace(currentState, polyline3DEntity, (lookup: MapEntity) => lookup.id === polyline3DEntity.id)
        );

        this.refreshLabels();
    }

    public delete(entityToRemoveOrParent: MapEntity, childEntityIndex: number) {
        const isDeletingPolyline3DChildEntity = entityToRemoveOrParent.type === MapEntityType.Polyline3D && childEntityIndex !== -1;
        const entityId = !isDeletingPolyline3DChildEntity
            ? entityToRemoveOrParent.id
            : Object.values(entityToRemoveOrParent.childEntities).find((entity) => entity.waypointIndex === childEntityIndex)?.entity.id;
        const observableEntity = this.editorObservableEntitiesMap.get(entityId ?? "");

        if (!entityId || !observableEntity) {
            Logger.captureMessage("MapEntitiesEditor.delete: Entity doesn't exist", { extra: { entity: entityToRemoveOrParent } });

            return;
        }

        observableEntity.dispose();
        this.editorObservableEntitiesMap.delete(entityId);

        if (!isDeletingPolyline3DChildEntity) {
            this.editorContentSubject.next(this.editorContentSubject.getValue().filter((mapEntity) => mapEntity.id !== entityId));

            this.refreshLabels();

            return;
        }

        const parentObservable = this.editorObservableEntitiesMap.get(entityToRemoveOrParent.id) as Polyline3DEditorObservable;
        const update = parentObservable?.deleteChildEntity(entityId);

        if (!update || !update.positions || !update.heights || !update.bufferWidths || !update.bufferHeights || !update.childEntities) {
            return;
        }

        const polyline3DEntity: Polyline3DEntity = {
            id: parentObservable.getEntityId(),
            type: MapEntityType.Polyline3D,
            positions: update.positions,
            heights: update.heights,
            bufferWidths: update.bufferWidths,
            bufferHeights: update.bufferHeights,
            childEntities: update.childEntities,
        };

        this.editorContentSubject.next(
            ArrayUtils.replace(this.editorContentValue, polyline3DEntity, (lookup: MapEntity) => lookup.id === polyline3DEntity.id)
        );

        this.refreshLabels();
    }
}
