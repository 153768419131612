import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
    DssUserRoles,
    FlightZoneApplicationPurpose,
    FlightZoneError,
    FlightZoneErrorType,
    FlightZoneListFilters,
    RestrictionsListItem,
} from "@dtm-frontend/dss-shared-lib";
import { AuthState } from "@dtm-frontend/shared/auth";
import { KmlExporterService } from "@dtm-frontend/shared/map";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, switchMap, tap } from "rxjs";
import { map } from "rxjs/operators";
import { RestrictionListRouteData, RestrictionsTableColumns, ZoneDurationChangeDialogData } from "../../../models/flight-zone.models";
import { NotamModalService } from "../../../services/notam-modal.service";
import { FlightZoneListsActions } from "../../../state/flight-zone-lists.actions";
import { FlightZoneListsState } from "../../../state/flight-zone-lists.state";
import { FlightZoneActions } from "../../../state/flight-zone.actions";
import { FlightZoneState } from "../../../state/flight-zone.state";
import { ZoneDurationChangeComponent } from "../../zone-duration-change/zone-duration-change.component";

@UntilDestroy()
@Component({
    selector: "dss-client-lib-restrictions-list-container",
    templateUrl: "./restrictions-list-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestrictionsListContainerComponent implements OnInit {
    protected readonly initialFilters: FlightZoneListFilters;
    protected readonly flightZonePurposes: FlightZoneApplicationPurpose[] = Object.values(FlightZoneApplicationPurpose);
    protected readonly displayedColumns: RestrictionsTableColumns = [
        "expandHandle",
        "notam",
        "zoneNumber",
        "startAt",
        "endAt",
        "applicationPurpose",
        "title",
        "actions",
    ];
    protected readonly isProcessing$ = this.store.select(FlightZoneListsState.isProcessing);
    protected readonly restrictionsList$ = this.store.select(this.routeData.selector);
    protected readonly hasListDataRetrievalError$ = this.store.select(FlightZoneListsState.hasListDataRetrievalError);
    protected readonly hasSupervisorPermissions$ = this.store
        .select(AuthState.roles)
        .pipe(map((roles) => roles?.includes(DssUserRoles.InstitutionSupervisor)));

    private get routeData(): RestrictionListRouteData {
        return this.route.snapshot.data as RestrictionListRouteData;
    }

    constructor(
        private readonly store: Store,
        private readonly notamModalService: NotamModalService,
        private readonly matDialog: MatDialog,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly kmlExporterService: KmlExporterService
    ) {
        this.initialFilters = this.route.snapshot.queryParams;
    }

    public ngOnInit(): void {
        this.updateListOnQueryParamsChange();
    }

    protected applyFilters(filters: FlightZoneListFilters): void {
        this.updateQueryParams(filters);
    }

    protected refreshList(): void {
        this.store.dispatch(new this.routeData.action(this.route.snapshot.queryParams));
    }

    protected changePage(event: PageEvent): void {
        const queryParams = { [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event.pageSize };

        this.updateQueryParams(queryParams);
    }

    public openNotamPreview(flightZoneId: string): void {
        this.notamModalService.openNotamPreview(flightZoneId);
    }

    protected changeZoneDuration(data: ZoneDurationChangeDialogData): void {
        this.matDialog
            .open(ZoneDurationChangeComponent, { data })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap((zoneDurationData) => this.store.dispatch(new FlightZoneActions.ChangeZoneDuration(zoneDurationData))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.store.dispatch(new this.routeData.action(this.route.snapshot.queryParams));
                this.toastService.success(this.transloco.translate("dssClientLibFlightZone.changeZoneDurationSuccessMessage"));
            });
    }

    protected downloadKml(restriction: RestrictionsListItem): void {
        this.store
            .dispatch(new FlightZoneListsActions.GetRestrictionGeoJson(restriction))
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(FlightZoneListsState.error);

                    if (error?.type === FlightZoneErrorType.CannotGetZoneGeoJson) {
                        this.toastService.error(
                            this.transloco.translate("dssClientLibFlightZone.applicationListShared.getZoneGeoJsonErrorMessage")
                        );
                    }
                }),
                switchMap(() => this.store.select(FlightZoneListsState.zoneGeoJson)),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe((geoJson) => {
                this.kmlExporterService.geoJsonToKml(geoJson, restriction.zoneNumber);
            });
    }

    private updateQueryParams(queryParams: Params): void {
        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    private updateListOnQueryParamsChange(): void {
        this.route.queryParams.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((filterParams) => {
            this.store.dispatch(new this.routeData.action(filterParams));
        });
    }

    private displayErrorMessage(error: FlightZoneError): void {
        let errorMessage: string | undefined;

        switch (error.type) {
            case FlightZoneErrorType.NotAuthorized:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.notAuthorizedErrorMessage");
                break;
            case FlightZoneErrorType.InvalidApplicationVersion:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.invalidVersionErrorMessage");
                break;
            case FlightZoneErrorType.CannotChangeZoneDuration:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.cannotChangeZoneDurationErrorMessage");
                break;
            default:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.genericErrorMessage");
        }

        this.toastService.error(errorMessage);
    }
}
