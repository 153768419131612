import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { map } from "rxjs/operators";
import { FilterConditions, SearchArea } from "../../../models/mission-search.models";

// TODO: DTM-4821 Use ui/filter-chips-display

interface FilterChipsComponentState {
    filterConditions: FilterConditions;
}

enum ChipName {
    operator = "operator",
    pilot = "pilot",
    missionId = "missionId",
    status = "status",
    uav = "uav",
    missionType = "missionType",
    dateRange = "dateRange",
    area = "area",
}

type ChipItem =
    | {
          name: Exclude<ChipName, ChipName.dateRange | ChipName.area>;
          value: string;
      }
    | {
          name: ChipName.dateRange;
          valueFrom: Date;
          valueTo: Date;
      }
    | {
          name: ChipName.area;
          value: string;
          zone: string | null;
      };

@UntilDestroy()
@Component({
    selector: "dtm-mission-mission-search-filter-chips[filterConditions]",
    templateUrl: "./filter-chips.component.html",
    styleUrls: ["./filter-chips.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FilterChipsComponent {
    @Input() public set filterConditions(value: FilterConditions | undefined) {
        this.localStore.patchState({ filterConditions: value ?? this.getEmptyFilterConditions() });
    }

    @Output() public readonly filterRemove = new EventEmitter<Array<keyof FilterConditions> | null>();

    protected readonly ChipName = ChipName;
    protected readonly SearchArea = SearchArea;
    protected readonly chipsItems$ = this.localStore
        .selectByKey("filterConditions")
        .pipe(map((filterConditions) => this.prepareChipsItems(filterConditions)));
    protected readonly filtersCount$ = this.chipsItems$.pipe(map((chipsItems) => chipsItems.length));

    constructor(private readonly localStore: LocalComponentStore<FilterChipsComponentState>) {
        this.localStore.setState({
            filterConditions: this.getEmptyFilterConditions(),
        });
    }

    protected removeChipsItem(name: ChipName): void {
        switch (name) {
            case ChipName.area:
                this.filterRemove.emit(["area", "zone"]);
                break;
            case ChipName.dateRange:
                this.filterRemove.emit(["fromDate", "toDate"]);
                break;
            default:
                this.filterRemove.emit([name as keyof FilterConditions]);
        }
    }

    private getEmptyFilterConditions(): FilterConditions {
        return {
            operator: null,
            pilot: null,
            missionId: null,
            status: null,
            uav: null,
            missionType: null,
            fromDate: null,
            toDate: null,
            area: null,
            zone: null,
            customArea: null,
        };
    }

    private prepareChipsItems(filterConditions: FilterConditions): ChipItem[] {
        const items: ChipItem[] = [];

        if (filterConditions.operator) {
            items.push({
                name: ChipName.operator,
                value: filterConditions.operator.name,
            });
        }

        if (filterConditions.pilot) {
            items.push({
                name: ChipName.pilot,
                value: filterConditions.pilot.name,
            });
        }

        if (filterConditions.missionId) {
            items.push({
                name: ChipName.missionId,
                value: filterConditions.missionId.name,
            });
        }

        if (filterConditions.status) {
            items.push({
                name: ChipName.status,
                value: filterConditions.status,
            });
        }

        if (filterConditions.uav) {
            items.push({
                name: ChipName.uav,
                value: filterConditions.uav.name,
            });
        }

        if (filterConditions.missionType) {
            items.push({
                name: ChipName.missionType,
                value: filterConditions.missionType,
            });
        }

        if (filterConditions.fromDate && filterConditions.toDate) {
            items.push({
                name: ChipName.dateRange,
                valueFrom: filterConditions.fromDate,
                valueTo: filterConditions.toDate,
            });
        }

        if (filterConditions.area) {
            items.push({
                name: ChipName.area,
                value: filterConditions.area,
                zone: filterConditions.zone?.name ?? null,
            });
        }

        return items;
    }
}
