import { Cartesian3 } from "@pansa/ngx-cesium";
import { SerializableCartographic } from "../models/serializable-cartographic";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export function convertSerializableCartographicToCartesian3(cartographicDegrees: SerializableCartographic): Cartesian3 {
    const cartographic = Cesium.Cartographic.fromDegrees(
        cartographicDegrees.longitude,
        cartographicDegrees.latitude,
        cartographicDegrees.height
    );

    return Cesium.Cartographic.toCartesian(cartographic);
}
