<div class="planned-mission" *ngrxLet="isPlannedMissionFolded$ as isPlannedMissionFolded">
    <div class="planned-mission-panel" [class.folded]="isPlannedMissionFolded">
        <div class="mission-details" *ngrxLet="isProcessing$ as isProcessing">
            <dtm-ui-loader-container [isShown]="isProcessing">
                <dss-client-lib-planned-mission-details
                    [waitingMissions]="waitingMissions$ | ngrxPush"
                    [acceptedMissions]="acceptedMissions$ | ngrxPush"
                    [rejectedMissions]="rejectedMissions$ | ngrxPush"
                    [isProcessing]="isProcessing"
                    [isPlanRouteProcessing]="isPlanRouteProcessing$ | ngrxPush"
                    [selectedMissionRoute]="selectedMissionPlanRoute$ | ngrxPush"
                    [selectedTileId]="selectedMissionPlanId$ | ngrxPush"
                    [areNewMissionsAvailable]="areNewMissionsAvailable$ | ngrxPush"
                    (missionSelect)="selectMission($event)"
                    (filtersChange)="filterMissions($event)"
                    (missionPhaseChange)="changeMissionPhase($event)"
                ></dss-client-lib-planned-mission-details
            ></dtm-ui-loader-container>
        </div>
        <div class="view-switcher">
            <button type="button" (click)="togglePanelFold()">
                <dtm-ui-icon [name]="isPlannedMissionFolded ? 'menu-unfold' : 'menu-fold'"></dtm-ui-icon>
            </button>
        </div>
    </div>

    <div class="planned-mission-map">
        <dss-client-lib-mission-map
            [routeData]="routeData$ | ngrxPush"
            [initialViewbox]="initialViewbox$ | ngrxPush"
        ></dss-client-lib-mission-map>
    </div>
</div>
