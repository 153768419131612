import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DateUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";

export interface MissionTimeRange {
    flightStartAt: Date | undefined;
    flightFinishAt: Date | undefined;
    flightStartAtMin?: Date | undefined;
    flightStartAtMax?: Date | undefined;
    isPathBased: boolean;
    flightFinishAtMin?: Date | undefined;
    flightFinishAtMax?: Date | undefined;
    plannedStartAt?: Date | undefined;
    plannedFinishAt?: Date | undefined;
}

interface MissionTimeRangeComponentState {
    missionTimeRange: MissionTimeRange | undefined;
    shouldDisplayDate: boolean;
}

@Component({
    selector: "dtm-mission-mission-time-range[missionTimeRange]",
    templateUrl: "./mission-time-range.component.html",
    styleUrls: ["./mission-time-range.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionTimeRangeComponent {
    @Input() public set missionTimeRange(value: MissionTimeRange | undefined) {
        this.localStore.patchState({ missionTimeRange: value });
    }
    @Input() public set shouldDisplayDate(value: BooleanInput) {
        this.localStore.patchState({ shouldDisplayDate: coerceBooleanProperty(value) });
    }

    protected readonly missionTimeRange$ = this.localStore.selectByKey("missionTimeRange").pipe(RxjsUtils.filterFalsy());
    protected readonly shouldDisplayDate$ = this.localStore.selectByKey("shouldDisplayDate");

    constructor(private readonly localStore: LocalComponentStore<MissionTimeRangeComponentState>) {
        this.localStore.setState({ missionTimeRange: undefined, shouldDisplayDate: false });
    }

    protected compareDatesWithMinutesPrecision(left?: Date, right?: Date): boolean {
        return DateUtils.compareDates(left, right, "seconds");
    }
}
