<ng-container *ngrxLet="{ missions: missions$, canEdit: canEdit$ } as vm">
    <ng-container *ngIf="vm.missions.length; else emptyListTemplate">
        <dss-client-lib-mission-info-panel
            (click)="missionSelect.emit(vm.missions[index])"
            [isSelected]="(selectedTileId$ | ngrxPush) === vm.missions[index].id"
            [isPlanRouteProcessing]="isPlanRouteProcessing$ | ngrxPush"
            [canEdit]="vm.canEdit"
            (rejectMission)="updateMissionPhase(MissionProcessingPhase.Rejected, vm.missions[index].id, index)"
            (acceptMission)="updateMissionPhase(MissionProcessingPhase.Accepted, vm.missions[index].id, index)"
            *ngFor="let missionForm of plannedMissionFormArray.controls; let index = index"
            [mission]="vm.missions[index]"
        >
            <dtm-ui-expandable-panel>
                <h2 class="section-title" headerSlot>
                    {{ "dssClientLibPlannedMissions.phaseDescription.routeDetailsLabel" | transloco }}
                </h2>
                <dtm-ui-route-details [route]="selectedMissionRoute$ | ngrxPush"></dtm-ui-route-details>
            </dtm-ui-expandable-panel>

            <div class="planned-mission-form" [formGroup]="missionForm" *ngIf="vm.canEdit">
                <dtm-ui-checkbox-field formControlName="shouldAddOperatorDetails" (change)="showCommentForm($event, index)">
                    {{ "dssClientLibPlannedMissions.phaseDescription.addInformationCheckboxLabel" | transloco }}
                </dtm-ui-checkbox-field>
                <dtm-ui-textarea-field
                    [maxLength]="MAX_OPERATOR_DETAILS_LENGTH"
                    *ngIf="missionForm.controls.shouldAddOperatorDetails.value"
                >
                    <label>{{ "dssClientLibPlannedMissions.phaseDescription.addInformationLabel" | transloco }}</label>
                    <textarea formControlName="operatorDetails" matInput></textarea>
                    <div class="field-error" *dtmUiFieldHasError="missionForm.controls.operatorDetails; name: 'maxlength'; error as error">
                        {{
                            "dssClientLibPlannedMissions.phaseDescription.operatorDetailsMaxLengthError"
                                | transloco : { maxLength: error.requiredLength }
                        }}
                    </div>
                </dtm-ui-textarea-field>
            </div>
        </dss-client-lib-mission-info-panel>
    </ng-container>
</ng-container>

<ng-template #emptyListTemplate>
    <dtm-ui-no-results></dtm-ui-no-results>
</ng-template>
