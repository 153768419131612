export interface FiltersMap {
    key: string;
    filterLabel: string;
    filterValueLabel?: string;
    filterValueLabelParam?: string;
    type?: FilterType;
}

export enum FilterType {
    Text = "Text",
    TextEllipsis = "TextEllipsis",
    Date = "Date",
    Dictionary = "Dictionary",
    ValueMap = "ValueMap",
    TranslatedValueMap = "TranslatedValueMap",
}
