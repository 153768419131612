import { WeatherStatus } from "../../cesium/models/weather.models";
import { AnalysisResult, WeatherCondition, WeatherConditionsData } from "../../weather/models/weather.models";

const weatherAnalysisToStatusMapper = new Map([
    [AnalysisResult.Danger, WeatherStatus.Danger],
    [AnalysisResult.Warning, WeatherStatus.Warning],
    [AnalysisResult.Ok, WeatherStatus.Approved],
    [AnalysisResult.None, WeatherStatus.None],
]);

export function getWeatherStatus(range: WeatherConditionsData | undefined): string | undefined {
    if (!range) {
        return weatherAnalysisToStatusMapper.get(AnalysisResult.None);
    }

    const analysisResultList: AnalysisResult[] = Object.keys(range)
        .map((key) => range[key as keyof WeatherConditionsData])
        .filter(
            (value: WeatherCondition | string | undefined): value is WeatherCondition => typeof value !== "string" && value !== undefined
        )
        .reduce<AnalysisResult[]>((acc: AnalysisResult[], value: WeatherCondition) => [...acc, value?.analysisResult], []);

    if (analysisResultList.some((result) => result === AnalysisResult.Danger)) {
        return weatherAnalysisToStatusMapper.get(AnalysisResult.Danger);
    }

    if (analysisResultList.some((result) => result === AnalysisResult.Warning)) {
        return weatherAnalysisToStatusMapper.get(AnalysisResult.Warning);
    }

    return weatherAnalysisToStatusMapper.get(AnalysisResult.Ok);
}
