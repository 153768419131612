<div class="main-container">
    <div class="content">
        <img src="assets/images/cookie.svg" alt="" />
        <!--TODO add proper translation with REJ-2412 -->
        <p>{{ "dtmUi.cookies.information" | transloco }}</p>
    </div>
    <button
        type="button"
        class="button-icon custom"
        (click)="cookiesHide.emit()"
        [attr.aria-label]="'dtmUi.cookies.closeButtonAriaLabel' | transloco"
    >
        <span class="iconfont icon-close"></span>
    </button>
</div>
