import { Notification, NotificationsListWithPages } from "../utils/notifications.model";

export interface GetNotificationsListResponseBody {
    content: Notification[];
    pageable: string;
    totalPages: number;
    totalElements: number;
    last: boolean;
    size: number;
    number: number;
    sort: string[];
    numberOfElements: number;
    first: boolean;
    empty: boolean;
}

export function convertGetNotificationsListResponseBodyToNotificationsListWithPageable(
    response: GetNotificationsListResponseBody
): NotificationsListWithPages {
    return {
        content: response.content,
        pageDetails: {
            totalElements: response.totalElements,
            size: response.size,
            number: response.number,
            isEmpty: response.empty,
            isLast: response.last,
        },
    };
}
