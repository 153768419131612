import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";
import { first } from "rxjs/operators";
import { AuthActions } from "../state/auth.actions";
import { AuthState } from "../state/auth.state";
import { DTM_AUTH_GUARD_REQUIRED_ROLES } from "./dtm-role.guard";

@UntilDestroy()
@Injectable()
export class OfflineCapableDtmRoleGuard {
    constructor(
        private readonly router: Router,
        private readonly store: Store,
        @Inject(DTM_AUTH_GUARD_REQUIRED_ROLES) private readonly requiredRoles: string[]
    ) {}

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (!this.store.selectSnapshot(AuthState.isLoggedIn)) {
            this.store.dispatch(new AuthActions.GoToLoginPage(state.url));
        }

        const routeRoles: string[] = route.data.roles;
        const roles = await lastValueFrom(this.store.select(AuthState.roles).pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this)));

        if (routeRoles?.length > 0) {
            if (routeRoles.find((role) => roles.includes(role))) {
                return true;
            }

            this.store.dispatch(AuthActions.GoToNotAuthorizedPage);

            return false;
        }

        if (this.requiredRoles.length === 0 || this.requiredRoles.find((role) => roles.includes(role))) {
            return true;
        }

        this.store.dispatch(AuthActions.GoToNotAuthorizedPage);

        return false;
    }
}
