import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { LanguageCode } from "../../modules/i18n/i18n.models";

@Injectable()
export class UIStateSyncService {
    constructor(private readonly translocoService: TranslocoService, @Inject(DOCUMENT) private readonly document: Document) {}

    public setActiveLanguage(language: LanguageCode) {
        this.translocoService.setActiveLang(language);
    }

    public setHighContrastMode(isHighContrastModeEnabled: boolean) {
        this.document.body.setAttribute("data-a11y-theme", isHighContrastModeEnabled ? "high-contrast" : "default");
    }
}
