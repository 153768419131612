import { Injectable, Injector } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ProcessedFile } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AcMapComponent } from "@pansa/ngx-cesium";
import { BehaviorSubject } from "rxjs";
import { KmlImportModalComponent } from "./kml-import-modal/kml-import-modal.component";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export interface KmlData {
    dataSource: unknown;
    fileInfo: ProcessedFile;
    data: CesiumKmlEntityData;
}

interface CesiumKmlEntityData {
    show: boolean;
    [key: string]: unknown;
}

const ZOOM_ANIMATION_DURATION = 0.5;

@UntilDestroy()
@Injectable()
export class KmlDataControlService {
    private readonly kmlDataSetSubject = new BehaviorSubject<KmlData[]>([]);
    public readonly kmlDataSet$ = this.kmlDataSetSubject.asObservable();

    public get kmlFiles(): ProcessedFile[] {
        return this.kmlDataSetSubject.value.map((kmlData) => kmlData.fileInfo);
    }

    constructor(private readonly acMap: AcMapComponent, private readonly dialog: MatDialog, private readonly injector: Injector) {}

    public setKmlData(files: ProcessedFile[]): void {
        const kmlDataSet = this.kmlDataSetSubject.value;

        kmlDataSet.forEach((kmlData) => {
            if (files.some((file) => kmlData.fileInfo.id === file.id)) {
                return;
            }

            this.acMap.getCesiumViewer().dataSources.remove(kmlData.dataSource);

            this.kmlDataSetSubject.next(
                this.kmlDataSetSubject.value.filter((kmlDataItem) => kmlDataItem.fileInfo.id !== kmlData.fileInfo.id)
            );
        });

        if (!files.length) {
            this.kmlDataSetSubject.next([]);
        }

        files?.forEach((file) => {
            if (kmlDataSet.some((kmlData) => kmlData.fileInfo.id === file.id)) {
                return;
            }

            const kmlDataSource = new Cesium.KmlDataSource();
            kmlDataSource.load(file.file, { clampToGround: true });

            this.acMap
                .getCesiumViewer()
                .dataSources.add(kmlDataSource)
                .then((data: CesiumKmlEntityData) => {
                    this.kmlDataSetSubject.next([
                        ...this.kmlDataSetSubject.value,
                        {
                            dataSource: kmlDataSource,
                            fileInfo: file,
                            data,
                        },
                    ]);
                });
        });

        this.refreshView();
    }

    public refreshView() {
        this.acMap.getCesiumViewer().scene.requestRender();
    }

    public zoomToKmlData(kmlData: KmlData) {
        this.acMap.getCesiumViewer().flyTo(kmlData.data, { duration: ZOOM_ANIMATION_DURATION });
    }

    public deleteAllKmlData() {
        this.kmlDataSetSubject.value.forEach((kmlData) => {
            this.acMap.getCesiumViewer().dataSources.remove(kmlData.dataSource);
        });
        this.kmlDataSetSubject.next([]);
        this.refreshView();
    }

    public openKmlImportModal() {
        this.dialog
            .open(KmlImportModalComponent, { data: this.kmlFiles })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((files) => {
                this.setKmlData(files);
            });
    }
}
