<dtm-ui-select-field
    maxPanelHeight="320px"
    [isClearable]="false"
    [formControl]="countryFormControl"
    [placeholder]="(searchPlaceholder$ | ngrxPush) ?? 'dtmUi.countrySelect.placeholder' | transloco"
    (panelClose)="searchControl.reset('')"
    (keydown)="keyDown($event)"
    (openedChange)="search?.focus()"
    [shouldApplyOffsetTop]="true"
    #selectTemplate
>
    <ng-content select="label" ngProjectAs="label"></ng-content>
    <mat-select-trigger *ngIf="selectedCountry$ | ngrxPush as selectedCountry">
        <dtm-ui-country-flag-display [country]="selectedCountry.alpha3Code"></dtm-ui-country-flag-display>
        <span class="country-name">{{ selectedCountry.displayName }}</span>
    </mat-select-trigger>
    <div class="container">
        <dtm-ui-input-field class="search">
            <input
                #search
                matInput
                [formControl]="searchControl"
                [placeholder]="'dtmUi.countrySelect.search' | transloco"
                (keydown.space)="$event.stopPropagation()"
                (keydown.enter)="setControlValueFromSearch()"
        /></dtm-ui-input-field>
        <dtm-ui-custom-option [value]="country.alpha3Code" *ngFor="let country of listOfCountriesDisplay; trackBy: countryListItemTrack">
            <dtm-ui-country-display [country]="country.alpha3Code"></dtm-ui-country-display>
        </dtm-ui-custom-option>
        <p class="empty-list" *ngIf="!listOfCountriesDisplay.length">{{ "dtmUi.countrySelect.emptyListInfo" | transloco }}</p>
    </div>
    <ng-content select=".field-hint" ngProjectAs=".field-hint"></ng-content>
    <ng-content select=".field-error" ngProjectAs=".field-error"></ng-content>
</dtm-ui-select-field>
