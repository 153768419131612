import { Inject, Injectable, InjectionToken } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ActionType, NgxsNextPluginFn, NgxsPlugin, getActionTypeFromInstance } from "@ngxs/store";
import { Observable, Subject, concatMap, from } from "rxjs";
import { finalize, first, tap, timeout } from "rxjs/operators";
import { MILLISECONDS_IN_MINUTE } from "../unit-utils";

/* eslint-disable @typescript-eslint/no-explicit-any */

export const NGXS_CONCURRENCY_PLUGIN_OPTIONS = new InjectionToken("NGXS_CONCURRENCY_PLUGIN_OPTIONS");

export interface NgxsConcurrencyPluginOptions {
    mutexActionTypes: ActionType[];
}

const COMPLETION_TIMEOUT = MILLISECONDS_IN_MINUTE;

@UntilDestroy()
@Injectable()
export class NgxsConcurrencyPlugin implements NgxsPlugin {
    private queue = new Subject<{ state: any; action: any; next: NgxsNextPluginFn; resolveFn: (nextResult: any) => void }>();
    private mutexActionTypesMap: Map<string, ActionType>;

    constructor(@Inject(NGXS_CONCURRENCY_PLUGIN_OPTIONS) options: NgxsConcurrencyPluginOptions) {
        this.mutexActionTypesMap = new Map(options.mutexActionTypes.map((actionType) => [actionType.type, actionType]));

        this.queue
            .pipe(
                concatMap(({ state, action, next, resolveFn }) =>
                    next(state, action).pipe(
                        timeout(COMPLETION_TIMEOUT),
                        first(),
                        tap((nextResult) => resolveFn(nextResult)),
                        finalize(() => resolveFn(state))
                    )
                ),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public handle(state: any, action: any, next: NgxsNextPluginFn) {
        const actionType = getActionTypeFromInstance(action);

        if (actionType && this.mutexActionTypesMap.has(actionType)) {
            const resultPromise = new Promise<Observable<any>>((resolveFn) => {
                this.queue.next({ state, action, next, resolveFn });
            });

            return from(resultPromise);
        }

        return next(state, action);
    }
}
