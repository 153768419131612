<div [class.editor-joined-view]="isJoinedView$ | ngrxPush">
    <form [formGroup]="newMessageForm">
        <div class="editor-container">
            <dtm-ui-quill-editor
                [formControl]="contentControl"
                [hasError]="!!contentControl.errors"
                [placeholder]="'dtmUi.conversations.newMessagePlaceholder' | transloco"
                dtmUiMarkValueAccessorControlsAsTouched
            >
                <div *ngIf="contentControl.errors?.required" class="field-error">
                    {{ "dtmUi.conversations.emptyMessageError" | transloco }}
                </div>
            </dtm-ui-quill-editor>
        </div>

        <ng-container *ngrxLet="isAttachmentsControlVisible$; let isAttachmentsControlVisible">
            <dtm-ui-files-upload-field
                dtmUiScrollToAfterInit
                *ngIf="isAttachmentsControlVisible"
                [formControl]="attachmentsControl"
                [maxFileSize]="MAX_FILE_SIZE_BYTES"
                [allowedTypes]="ALLOWED_MIME_TYPES"
            >
                <label>{{ "dtmUi.conversations.addAttachmentLabel" | transloco }}</label>
                <div class="field-hint">
                    {{ "dtmUi.conversations.allowedMimeTypesHint" | transloco : { maxFileSize: MAX_FILE_SIZE_BYTES | formatFileSize } }}
                </div>
            </dtm-ui-files-upload-field>
            <ng-content></ng-content>
        </ng-container>
    </form>
</div>
