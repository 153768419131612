<ng-container *ngrxLet="constraints$ as constraints">
    <ng-container *ngrxLet="capabilities$ as capabilities">
        <ng-container *ngrxLet="exclusions$ as exclusions">
            <ng-container *ngrxLet="isProcessing$ as isProcessing">
                <ng-container [ngSwitch]="applicationPurpose$ | ngrxPush">
                    <dss-client-lib-uav-restriction-exclusions
                        *ngSwitchCase="FlightZoneApplicationPurpose.ForbiddingUAVFlights"
                        [isProcessing]="isProcessing"
                        [capabilities]="capabilities"
                        [constraints]="constraints"
                        [exclusions]="exclusions"
                        (back)="back.emit()"
                        (restrictionExclusionsUpdate)="restrictionExclusionsUpdate.emit($event)"
                    ></dss-client-lib-uav-restriction-exclusions>

                    <dss-client-lib-general-aviation-restriction-exclusions
                        *ngSwitchCase="FlightZoneApplicationPurpose.ForbiddingUAVAndGeneralAviationFlights"
                        [isProcessing]="isProcessing"
                        [capabilities]="capabilities"
                        [constraints]="constraints"
                        [exclusions]="exclusions"
                        (back)="back.emit()"
                        (restrictionExclusionsUpdate)="restrictionExclusionsUpdate.emit($event)"
                    >
                    </dss-client-lib-general-aviation-restriction-exclusions>

                    <dss-client-lib-securing-own-flights-restriction-exclusions
                        *ngSwitchCase="FlightZoneApplicationPurpose.SecuringOwnFlights"
                        [isProcessing]="isProcessing"
                        [capabilities]="capabilities"
                        [constraints]="constraints"
                        [exclusions]="exclusions"
                        (back)="back.emit()"
                        (restrictionExclusionsUpdate)="restrictionExclusionsUpdate.emit($event)"
                    >
                    </dss-client-lib-securing-own-flights-restriction-exclusions>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
