<div *ngrxLet="status$ as status" [ngSwitch]="status">
    <span *ngSwitchCase="IdentityDocumentStatus.Active" class="chip success">
        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
        {{ "dtmUi.identityDocumentStatusBadge.activeLabel" | transloco }}
    </span>
    <span *ngSwitchCase="IdentityDocumentStatus.Waiting" class="chip secondary">
        <dtm-ui-icon name="markup"></dtm-ui-icon>
        {{ "dtmUi.identityDocumentStatusBadge.waitingLabel" | transloco }}
    </span>
    <span *ngSwitchCase="IdentityDocumentStatus.Rejected" class="chip error">
        <dtm-ui-icon name="markup"></dtm-ui-icon>
        {{ "dtmUi.identityDocumentStatusBadge.rejectedLabel" | transloco }}
    </span>
</div>
