import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface ThreadListItemIconComponentState {
    isRead: boolean;
    isAssigned: boolean;
    isClosed: boolean;
}

@Component({
    selector: "dtm-ui-thread-list-item-icon[isRead][isAssigned][isClosed]",
    templateUrl: "./thread-list-item-icon.component.html",
    styleUrls: ["./thread-list-item-icon.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ThreadListItemIconComponent {
    @Input() public set isRead(value: boolean) {
        this.localStore.patchState({ isRead: value });
    }

    @Input() public set isAssigned(value: boolean) {
        this.localStore.patchState({ isAssigned: value });
    }

    @Input() public set isClosed(value: boolean) {
        this.localStore.patchState({ isClosed: value });
    }

    public readonly isRead$ = this.localStore.selectByKey("isRead");
    public readonly isAssigned$ = this.localStore.selectByKey("isAssigned");
    public readonly isClosed$ = this.localStore.selectByKey("isClosed");

    constructor(private readonly localStore: LocalComponentStore<ThreadListItemIconComponentState>) {
        localStore.setState({ isRead: false, isAssigned: false, isClosed: false });
    }
}
