/* eslint-disable @typescript-eslint/no-explicit-any */
export function convertStringPropertiesToDate<T extends Record<string, any>>(object: T, keys: (keyof T)[]): T {
    keys.forEach((key) => {
        if (!object[key] || typeof object[key] !== "string") {
            return;
        }

        object[key] = new Date(object[key]) as T[keyof T];
    });

    return object;
}
