<div class="label-wrapper">
    <p class="label-text">{{ label$ | ngrxPush }}</p>
    <dtm-ui-popover *ngrxLet="labelHint$ as labelHint" [popoverText]="labelHint"></dtm-ui-popover>
</div>
<div class="value-container" *ngIf="value$ | ngrxPush as value; else contentTemplate">
    <p>{{ value }}</p>
    <button
        class="button-icon"
        [cdkCopyToClipboard]="value"
        (cdkCopyToClipboardCopied)="handleValueCopy($event)"
        type="button"
        *ngIf="canCopy$ | ngrxPush"
        [matTooltip]="'dtmUi.copyToClipboard.hint' | transloco"
    >
        <dtm-ui-icon name="file-copy"> </dtm-ui-icon>
    </button>
</div>
<ng-template #contentTemplate>
    <ng-content></ng-content>
</ng-template>
