import { InjectionToken } from "@angular/core";

export interface NotificationEndpoints {
    notificationsList: string;
    notificationsCount: string;
    getNotifications: string;
    markNotificationAsRead: string;
    markAllAsRead: string;
}

export const NOTIFICATIONS_ENDPOINTS = new InjectionToken<NotificationEndpoints>("Notifications endpoints");
