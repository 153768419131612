type ArrayElementType<T> = T extends (infer U)[] ? U : never;
type ArrayElementPropertyType<T> = ArrayElementType<T>[keyof ArrayElementType<T>];
type ReturnType<T> = Record<string, ArrayElementPropertyType<T>> | undefined;

export function convertToMap<T extends ArrayElementType<T>[]>(
    collection: T | undefined,
    keyAttrName: keyof ArrayElementType<T>,
    extractFn: (el: ArrayElementType<T>) => ArrayElementPropertyType<T>
): ReturnType<T> {
    const result: ReturnType<T> = {};

    return collection?.reduce((resultMap, element: ArrayElementType<T>) => {
        const key = String(element[keyAttrName]);
        if (key) {
            resultMap[key] = extractFn(element);
        }

        return resultMap;
    }, result);
}
