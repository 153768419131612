import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ContentObserver } from "@angular/cdk/observers";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    forwardRef,
} from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import {
    MatLegacyAutocomplete as MatAutocomplete,
    MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
} from "@angular/material/legacy-autocomplete";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime } from "rxjs";
import { tap } from "rxjs/operators";

export interface SearchControlOption {
    id: string;
    name: string;
}

interface SearchControlComponentState {
    options: SearchControlOption[];
    isProcessing: boolean;
    placeholder: string;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-search-control[options]",
    templateUrl: "./search-control.component.html",
    styleUrls: ["./search-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchControlComponent),
            multi: true,
        },
    ],
})
export class SearchControlComponent implements AfterViewInit, ControlValueAccessor {
    @Input() public set options(value: SearchControlOption[] | undefined) {
        this.localStore.patchState({ options: value ?? [] });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set placeholder(value: string | undefined) {
        this.localStore.patchState({ placeholder: value ?? "" });
    }

    @Output() public readonly searchTextChange = new EventEmitter<string>();

    @ViewChild("autocomplete") private autocomplete!: MatAutocomplete;

    protected readonly options$ = this.localStore.selectByKey("options");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly placeholder$ = this.localStore.selectByKey("placeholder");

    protected readonly searchControl = new FormControl<SearchControlOption | string>("", {
        nonNullable: true,
    });

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: SearchControlOption | null) => void = FunctionUtils.noop;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly localStore: LocalComponentStore<SearchControlComponentState>,
        private readonly contentObserver: ContentObserver
    ) {
        this.localStore.setState({
            options: [],
            isProcessing: false,
            placeholder: "",
        });

        this.searchControl.valueChanges
            .pipe(
                tap((value) => {
                    if (!value || this.isSearchQueryValue(value)) {
                        this.propagateChange(null);
                    }
                }),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                tap((value) => {
                    if (this.isSearchQueryValue(value)) {
                        this.searchTextChange.next(value);
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public ngAfterViewInit(): void {
        this.contentObserver
            .observe(this.elementRef.nativeElement)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const elementHeight = this.elementRef.nativeElement.getBoundingClientRect().height;
                const inputWrapperElement = this.elementRef.nativeElement.querySelector(".input-wrapper");
                const inputWrapperYPosition = (inputWrapperElement?.offsetTop ?? 0) + (inputWrapperElement?.offsetHeight ?? 0);

                if (this.autocomplete.panel) {
                    this.autocomplete.panel.nativeElement.style.top = `-${elementHeight - inputWrapperYPosition}px`;
                }
            });
    }

    public registerOnChange(fn: (value: SearchControlOption | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: SearchControlOption | null): void {
        if (value) {
            this.searchControl.reset(value, { emitEvent: false });
        } else {
            this.searchControl.reset();
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.searchControl.disable();
        } else {
            this.searchControl.enable();
        }
    }

    protected displayAutocompleteValueFunction(option: SearchControlOption): string {
        return option.name;
    }

    protected selectValue({ option: { value } }: MatAutocompleteSelectedEvent): void {
        this.propagateChange(value);
    }

    private isSearchQueryValue(value: SearchControlOption | string): value is string {
        return typeof value === "string";
    }
}
