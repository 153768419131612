import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { OperatorStatus } from "../../models/operator.models";

interface OperatorStatusComponentState {
    status: OperatorStatus | undefined;
}

@Component({
    selector: "dtm-ui-operator-status-badge[status]",
    templateUrl: "./operator-status-badge.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorStatusBadgeComponent {
    @Input() public set status(value: OperatorStatus | undefined) {
        this.localStore.patchState({ status: value });
    }

    protected readonly OperatorStatus = OperatorStatus;
    protected readonly status$ = this.localStore.selectByKey("status");

    constructor(private readonly localStore: LocalComponentStore<OperatorStatusComponentState>) {
        localStore.setState({ status: undefined });
    }
}
