export enum AzureMapsTileset {
    /**
     * A standard map that displays roads, natural and artificial features along with the labels for those features in a vector tile.
     */
    Base = "Base",
    /**
     * All layers with our dark grey style.
     */
    BaseDarkGrey = "BaseDarkGrey",
    /**
     * Displays road, boundary and label data in a vector tile.
     */
    BaseHybrid = "BaseHybrid",
    /**
     * Road, boundary and label data in our dark grey style.
     */
    BaseHybridDarkGrey = "BaseHybridDarkGrey",
    /**
     * Road, boundary and label data in our main style.
     */
    BaseHybridRoad = "BaseHybridRoad",
    /**
     * Displays labels for roads, natural and artificial features in a vector tile.
     */
    BaseLabels = "BaseLabels",
    /**
     * Label data in our dark grey style.
     */
    BaseLabelsDarkGrey = "BaseLabelsDarkGrey",
    /**
     * Label data in our main style.
     */
    BaseLabelsRoad = "BaseLabelsRoad",
    /**
     * All layers with our main style.
     */
    BaseRoad = "BaseRoad",
    /**
     * A combination of satellite and aerial imagery. Only available in S1 pricing SKU.
     */
    Imagery = "Imagery",
    /**
     * Shaded relief and terra layers.
     */
    TerraMain = "TerraMain",
    /**
     * Absolute traffic tiles in vector.
     */
    TrafficAbsolute = "TrafficAbsolute",
    /**
     * Absolute traffic tiles in raster in our main style.
     */
    TrafficAbsoluteMain = "TrafficAbsoluteMain",
    /**
     * Traffic tiles in vector.
     */
    TrafficDelay = "TrafficDelay",
    /**
     * Traffic tiles in raster in our main style.
     */
    TrafficDelayMain = "TrafficDelayMain",
    /**
     * Incident tiles in vector.
     */
    TrafficIncident = "TrafficIncident",
    /**
     * Reduced traffic tiles in raster in our main style.
     */
    TrafficReducedMain = "TrafficReducedMain",
    /**
     * Relative traffic tiles in vector.
     */
    TrafficRelative = "TrafficRelative",
    /**
     * Relative traffic tiles in raster in our dark style.
     */
    TrafficRelativeDark = "TrafficRelativeDark",
    /**
     * Relative traffic tiles in raster in our main style.
     */
    TrafficRelativeMain = "TrafficRelativeMain",
    /**
     * Weather infrared tiles. Latest Infrared Satellite images shows clouds by their temperature.
     */
    WeatherInfraredMain = "WeatherInfraredMain",
    /**
     * Weather radar tiles. Latest weather radar images including areas of rain, snow, ice and mixed conditions.
     */
    WeatherRadarMain = "WeatherRadarMain",
}

export const AzureMapsTilesetOptions: Record<
    AzureMapsTileset,
    {
        name: string;
        tilesetId: string;
        format: "jpeg" | "png" | "pbf";
        minZoomLevel?: number;
        maxZoomLevel?: number;
        partnerCredits?: string;
    }
> = {
    [AzureMapsTileset.Base]: {
        name: "Base",
        tilesetId: "microsoft.base",
        format: "pbf",
    },
    [AzureMapsTileset.BaseDarkGrey]: {
        name: "Dark Grey",
        tilesetId: "microsoft.base.darkgrey",
        format: "png",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.BaseHybrid]: {
        name: "Hybrid",
        tilesetId: "microsoft.base.hybrid",
        format: "pbf",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.BaseHybridDarkGrey]: {
        name: "Hybrid Dark Grey",
        tilesetId: "microsoft.base.hybrid.darkgrey",
        format: "png",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.BaseHybridRoad]: {
        name: "Hybrid Road",
        tilesetId: "microsoft.base.hybrid.road",
        format: "png",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.BaseLabels]: {
        name: "Labels",
        tilesetId: "microsoft.base.labels",
        format: "pbf",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.BaseLabelsDarkGrey]: {
        name: "Labels Dark Grey",
        tilesetId: "microsoft.base.labels.darkgrey",
        format: "png",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.BaseLabelsRoad]: {
        name: "Labels Road",
        tilesetId: "microsoft.base.labels.road",
        format: "png",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.BaseRoad]: {
        name: "Road",
        tilesetId: "microsoft.base.road",
        format: "png",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.Imagery]: {
        name: "Imagery",
        tilesetId: "microsoft.imagery",
        maxZoomLevel: 19,
        minZoomLevel: 1,
        format: "jpeg",
        partnerCredits: "Airbus",
    },
    [AzureMapsTileset.TerraMain]: {
        name: "Terra",
        tilesetId: "microsoft.terra.main",
        maxZoomLevel: 6,
        format: "png",
    },
    [AzureMapsTileset.TrafficAbsolute]: {
        name: "Absolute Traffic",
        tilesetId: "microsoft.traffic.absolute",
        format: "pbf",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.TrafficAbsoluteMain]: {
        name: "Absolute Traffic Main",
        tilesetId: "microsoft.traffic.absolute.main",
        format: "png",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.TrafficDelay]: {
        name: "Delay Traffic",
        tilesetId: "microsoft.traffic.delay",
        format: "pbf",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.TrafficDelayMain]: {
        name: "Delay Traffic Main",
        tilesetId: "microsoft.traffic.delay.main",
        format: "png",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.TrafficIncident]: {
        name: "Traffic Incident",
        tilesetId: "microsoft.traffic.incident",
        format: "pbf",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.TrafficReducedMain]: {
        name: "Reduced Traffic Main",
        tilesetId: "microsoft.traffic.reduced.main",
        format: "png",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.TrafficRelative]: {
        name: "Relative Traffic",
        tilesetId: "microsoft.traffic.relative",
        format: "pbf",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.TrafficRelativeDark]: {
        name: "Relative Traffic Dark",
        tilesetId: "microsoft.traffic.relative.dark",
        format: "png",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.TrafficRelativeMain]: {
        name: "Relative Traffic Main",
        tilesetId: "microsoft.traffic.relative.main",
        format: "png",
        partnerCredits: "TomTom",
    },
    [AzureMapsTileset.WeatherInfraredMain]: {
        name: "Weather Infrared",
        tilesetId: "microsoft.weather.infrared.main",
        maxZoomLevel: 15,
        format: "png",
        partnerCredits: "AccuWeather",
    },
    [AzureMapsTileset.WeatherRadarMain]: {
        name: "Weather Radar",
        tilesetId: "microsoft.weather.radar.main",
        maxZoomLevel: 15,
        format: "png",
        partnerCredits: "AccuWeather",
    },
};

export interface AzureMapsOptions {
    tilesetId: AzureMapsTileset;
    view: "auto";
    language: string;
    timeStamp?: Date;
    trafficFlowThickness: number;
    url: string;
    tileSize: number;
    subscriptionKey?: string;
}
