import { Pipe, PipeTransform } from "@angular/core";
import { DateFormatOptions, Locale, TranslocoDatePipe, ValidDate } from "@jsverse/transloco-locale";

@Pipe({
    name: "localizeDate",
    pure: false,
})
export class LocalizeDatePipe extends TranslocoDatePipe implements PipeTransform {
    public transform(date?: ValidDate | null, options?: DateFormatOptions, locale?: Locale): string {
        if (!date) {
            return "";
        }

        // TODO: DTM-3987 remove forcing en-GB when backend will change it's locale
        if (!locale && this.localeService.getLocale() === "en-US") {
            locale = "en-GB";
        }

        return super.transform(date, options, locale);
    }
}

@Pipe({
    name: "localizeDatePure",
})
export class LocalizeDatePurePipe extends LocalizeDatePipe implements PipeTransform {}
