<ng-container *ngrxLet="isCollapsed$; let isCollapsed">
    <ng-template #menuItemTemplate let-label="label" let-icon="icon" let-link="link">
        <a
            [routerLink]="link"
            routerLinkActive="active-route"
            [matTooltip]="label"
            matTooltipPosition="right"
            [matTooltipDisabled]="!isCollapsed"
            (click)="collapseMenu()"
        >
            <dtm-ui-icon [name]="icon"></dtm-ui-icon>
            <span class="label">{{ label }}</span>
        </a>
    </ng-template>

    <ul class="menu-list">
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'dssClient.menu.dashboardMenuItemLabel' | transloco,
                    icon: 'dashboard',
                    link: '/dashboard'
                }"
            ></ng-template>
        </li>
    </ul>
    <h4
        class="menu-section-header"
        [matTooltip]="'dssClient.menu.applicationsHeader' | transloco"
        matTooltipPosition="right"
        [matTooltipDisabled]="!isCollapsed"
    >
        {{ "dssClient.menu.applicationsHeader" | transloco }}
    </h4>
    <ul class="menu-list">
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'dssClient.menu.newFlightZoneApplicationMenuItemLabel' | transloco,
                    icon: 'add',
                    link: '/flight-zone/new'
                }"
            ></ng-template>
        </li>
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'dssClient.menu.submittedApplicationsListMenuItemLabel' | transloco,
                    icon: 'file-text',
                    link: '/flight-zone/submitted-applications'
                }"
            ></ng-template>
        </li>
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'dssClient.menu.flightZoneApplicationDraftListMenuItemLabel' | transloco,
                    icon: 'draft',
                    link: '/flight-zone/application-draft-list'
                }"
            ></ng-template>
        </li>
    </ul>

    <h4
        class="menu-section-header"
        [matTooltip]="'dssClient.menu.modificationsHeader' | transloco"
        matTooltipPosition="right"
        [matTooltipDisabled]="!isCollapsed"
    >
        {{ "dssClient.menu.modificationsHeader" | transloco }}
    </h4>
    <ul class="menu-list">
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'dssClient.menu.submittedApplicationsListMenuItemLabel' | transloco,
                    icon: 'file-text',
                    link: '/modifications/submitted-list'
                }"
            ></ng-template>
        </li>
    </ul>
    <h4
        class="menu-section-header"
        [matTooltip]="'dssClient.menu.restrictionsHeader' | transloco"
        matTooltipPosition="right"
        [matTooltipDisabled]="!isCollapsed"
    >
        {{ "dssClient.menu.restrictionsHeader" | transloco }}
    </h4>
    <ul class="menu-list">
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'dssClient.menu.activeRestrictionsListMenuItemLabel' | transloco,
                    icon: 'treasure-map',
                    link: '/flight-zone/active-restrictions'
                }"
            ></ng-template>
        </li>
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'dssClient.menu.endedRestrictionsListMenuItemLabel' | transloco,
                    icon: 'close-circle',
                    link: '/flight-zone/ended-restrictions'
                }"
            ></ng-template>
        </li>
    </ul>
    <ng-container *ngIf="isPreTacticalApprovalEnabled">
        <h4
            class="menu-section-header"
            [matTooltip]="'dssClient.menu.plannedMissionsHeader' | transloco"
            matTooltipPosition="right"
            [matTooltipDisabled]="!isCollapsed"
        >
            {{ "dssClient.menu.plannedMissionsHeader" | transloco }}
        </h4>
        <ul class="menu-list">
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dssClient.menu.plannedMissionsMenuItemLabel' | transloco,
                        icon: 'route',
                        link: '/planned-missions'
                    }"
                ></ng-template>
            </li>
        </ul>
    </ng-container>
</ng-container>
