import { Injectable } from "@angular/core";
import { CesiumPointerManagerService } from "@dtm-frontend/shared/map/cesium";
import { CameraService, CesiumEvent, CesiumService, PickOptions } from "@pansa/ngx-cesium";
import { Feature, Geometry } from "@turf/helpers";
import { Subject, finalize, from, map, switchMap } from "rxjs";
import { ActiveRestrictionsLayerFeatureData } from "../../models/active-restrictions-map-layer.models";
import { RestrictionType } from "../../models/flight-zone-shared.models";

type ActiveRestrictionsLayerPickFeatureResponse = Feature<
    Geometry,
    {
        color: string;
        created: string;
        designator: string;
        end_time: string;
        geozone: string;
        is_static: boolean;
        lower_limit: number;
        start_time: string;
        type: RestrictionType;
        upper_limit: number;
        version: number;
    }
>[];

@Injectable()
export class ActiveRestrictionsLayerFeaturePickerService {
    private readonly isFeaturesDataLoadingSubject = new Subject<boolean>();
    public readonly isFeaturesDataLoading$ = this.isFeaturesDataLoadingSubject.asObservable();
    public readonly pickedFeaturesData$ = this.cesiumPointerManager
        .addEventHandler({
            event: CesiumEvent.LEFT_CLICK,
            pick: PickOptions.NO_PICK,
        })
        .pipe(
            switchMap(({ movement: { endPosition } }) => {
                const viewer = this.cesiumService.getViewer();
                const ray = this.cameraService.getCamera().getPickRay(endPosition);

                this.isFeaturesDataLoadingSubject.next(true);

                return from<[ActiveRestrictionsLayerPickFeatureResponse]>(
                    viewer.imageryLayers.pickImageryLayerFeatures(ray, viewer.scene)
                ).pipe(
                    finalize(() => {
                        this.isFeaturesDataLoadingSubject.next(false);
                    })
                );
            }),
            map((features) => this.mapFeaturesResponseToFeatureData(features))
        );

    constructor(
        private readonly cesiumService: CesiumService,
        private readonly cameraService: CameraService,
        private readonly cesiumPointerManager: CesiumPointerManagerService
    ) {}

    private mapFeaturesResponseToFeatureData(response: ActiveRestrictionsLayerPickFeatureResponse): ActiveRestrictionsLayerFeatureData[] {
        return response.map((feature) => ({
            type: feature.properties.type,
            geozone: feature.properties.geozone,
            designator: feature.properties.designator,
            created: new Date(feature.properties.created),
            startTime: new Date(feature.properties.start_time),
            endTime: new Date(feature.properties.end_time),
            isStatic: feature.properties.is_static,
            lowerLimit: feature.properties.lower_limit,
            upperLimit: feature.properties.upper_limit,
        }));
    }
}
