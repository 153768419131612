<div class="dialog-header">
    <h4 mat-dialog-title>{{ "dssClientLibFlightZone.zoneDurationChange.header" | transloco }}</h4>
    <button type="button" class="button-icon" [matDialogClose]="null">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    <p class="dialog-description">{{ "dssClientLibFlightZone.zoneDurationChange.description" | transloco }}</p>
    <mat-slide-toggle [formControl]="cancelRestrictionControl" labelPosition="before">
        {{ "dssClientLibFlightZone.zoneDurationChange.cancelRestrictionLabel" | transloco }}
    </mat-slide-toggle>
    <form
        [formGroup]="zoneDurationForm"
        *ngrxLet="{
            maxStartDate: maxStartDate$,
            minStartDate: minStartDate$,
            maxEndDate: maxEndDate$,
            minEndDate: minEndDate$,
            datePickerPlaceholder: datePickerPlaceholder$
        } as vm"
    >
        <dtm-ui-date-field [isClearable]="false">
            <label for="startDateControl">{{ "dssClientLibFlightZone.zoneDurationChange.startDateLabel" | transloco }} </label>
            <input
                id="startDateControl"
                matInput
                formControlName="startDate"
                [matDatepicker]="startDateTimePicker"
                [min]="vm.minStartDate"
                [max]="vm.maxStartDate"
                [placeholder]="vm.datePickerPlaceholder"
            />
            <mat-datepicker #startDateTimePicker></mat-datepicker>
            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="datePickerErrorsTemplate"
                    [ngTemplateOutletContext]="{ dateControl: zoneDurationForm.controls.startDate }"
                ></ng-container>
            </div>
        </dtm-ui-date-field>

        <dtm-ui-date-field [isClearable]="false">
            <label for="endDateControl">
                {{ "dssClientLibFlightZone.zoneDurationChange.endDateLabel" | transloco }}
            </label>
            <input
                id="endDateControl"
                matInput
                formControlName="endDate"
                [matDatepicker]="endDatePicker"
                [min]="vm.minEndDate"
                [max]="vm.maxEndDate"
                [placeholder]="vm.datePickerPlaceholder"
            />
            <mat-datepicker #endDatePicker></mat-datepicker>
            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="datePickerErrorsTemplate"
                    [ngTemplateOutletContext]="{ dateControl: zoneDurationForm.controls.endDate }"
                ></ng-container>
            </div>
        </dtm-ui-date-field>

        <dtm-ui-time-field
            formControlName="startTime"
            [minTime]="vm.minStartDate"
            [maxTime]="vm.maxStartDate"
            [referenceDate]="zoneDurationForm.controls.startDate.value"
        >
            <label>{{ "dssClientLibFlightZone.zoneDurationChange.startTimeLabel" | transloco }} </label>
            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="datePickerErrorsTemplate"
                    [ngTemplateOutletContext]="{ dateControl: zoneDurationForm.controls.startTime }"
                ></ng-container>
            </div>
        </dtm-ui-time-field>

        <dtm-ui-time-field
            formControlName="endTime"
            [minTime]="vm.minEndDate"
            [maxTime]="vm.maxEndDate"
            [referenceDate]="zoneDurationForm.controls.endDate.value"
        >
            <label>{{ "dssClientLibFlightZone.zoneDurationChange.endTimeLabel" | transloco }} </label>
            <div class="field-error">
                <ng-container
                    [ngTemplateOutlet]="datePickerErrorsTemplate"
                    [ngTemplateOutletContext]="{ dateControl: zoneDurationForm.controls.endTime }"
                ></ng-container>
            </div>
        </dtm-ui-time-field>
    </form>
</div>

<div mat-dialog-actions align="end">
    <button type="button" class="button-secondary" cdkFocusInitial [mat-dialog-close]="null">
        {{ "dssClientLibFlightZone.zoneDurationChange.cancelButtonLabel" | transloco }}
    </button>
    <button type="button" class="button-primary" (click)="confirm()">
        {{ "dssClientLibFlightZone.zoneDurationChange.confirmButtonLabel" | transloco }}
    </button>
</div>

<ng-template #datePickerErrorsTemplate let-dateControl="dateControl">
    <div *dtmUiFieldHasError="dateControl; name: 'min'; error as error">
        {{
            "dssClientLibFlightZone.zoneDurationChange.minDateTimeHint"
                | transloco
                    : {
                          minDate: error.min | localizeDate : { dateStyle: "short", timeStyle: "short" }
                      }
        }}
    </div>
    <div *dtmUiFieldHasError="dateControl; name: 'matDatepickerMin'; error as error">
        {{
            "dssClientLibFlightZone.zoneDurationChange.minDateHint"
                | transloco
                    : {
                          minDate: error.min | localizeDate : { dateStyle: "short" }
                      }
        }}
    </div>
    <ng-container *dtmUiFieldHasError="dateControl; name: 'max'; error as error">
        {{
            "dssClientLibFlightZone.zoneDurationChange.maxDateTimeHint"
                | transloco
                    : {
                          maxDate: error.max | localizeDate : { dateStyle: "short", timeStyle: "short" }
                      }
        }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="dateControl; name: 'matDatepickerMax'; error as error">
        {{
            "dssClientLibFlightZone.zoneDurationChange.maxDateHint"
                | transloco
                    : {
                          maxDate: error.max | localizeDate : { dateStyle: "short" }
                      }
        }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="dateControl; name: 'requiredTouched'">
        {{ "dssClientLibFlightZone.requiredFieldErrorHint" | transloco }}
    </ng-container>
</ng-template>
