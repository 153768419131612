<div class="toast-icon" [ngSwitch]="toastPackage.toastType">
    <dtm-ui-icon name="information-fill" *ngSwitchCase="'toast-info'"></dtm-ui-icon>
    <dtm-ui-icon name="error-warning-fill" *ngSwitchCase="'toast-error'"></dtm-ui-icon>
    <dtm-ui-icon name="checkbox-circle-fill" *ngSwitchCase="'toast-success'"></dtm-ui-icon>
    <dtm-ui-icon name="error-warning-fill" *ngSwitchCase="'toast-warning'"></dtm-ui-icon>
</div>
<section class="text-container">
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">{{ title }}</div>
    <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass" [innerHTML]="message"></div>
    <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
    </div>
</section>
<button *ngIf="options.closeButton" type="button" class="toast-close-button" (click)="remove()">
    <dtm-ui-icon name="close"></dtm-ui-icon>
</button>
