<ng-container *ngrxLet="{ selectedLanguageDefinition: selectedLanguageDefinition$ } as vm">
    <button
        [matMenuTriggerFor]="languageSelectMenu"
        type="button"
        class="trigger-button"
        *ngIf="vm.selectedLanguageDefinition"
        #trigger="matMenuTrigger"
    >
        <dtm-ui-country-flag-display [country]="vm.selectedLanguageDefinition.flagName"></dtm-ui-country-flag-display>
        {{ vm.selectedLanguageDefinition.alpha2Code | uppercase }}
        <dtm-ui-icon [name]="trigger.menuOpen ? 'arrow-up-single' : 'arrow-down'"></dtm-ui-icon>
    </button>
    <mat-menu #languageSelectMenu="matMenu" xPosition="before">
        <button
            *ngFor="let entry of languageDefinitions$ | ngrxPush"
            mat-menu-item
            type="button"
            class="option-button"
            [class.selected-option]="entry.alpha2Code === vm.selectedLanguageDefinition?.alpha2Code"
            (click)="changeLanguage(entry.alpha2Code)"
        >
            <dtm-ui-country-flag-display [country]="entry.flagName"></dtm-ui-country-flag-display>
            {{ entry.displayName }}
        </button>
    </mat-menu>
</ng-container>
