<dtm-ui-expandable-panel
    *ngrxLet="{ soraResult: soraResult$, isExpanded: isExpanded$ } as vm"
    [isExpanded]="vm.isExpanded"
    [hasHeaderSeparator]="false"
>
    <h2 headerSlot>
        {{ "dtmSharedMission.adjacentAreaAndSpace.header" | transloco }}
    </h2>
    <dtm-ui-label-value [label]="'dtmSharedMission.adjacentAreaAndSpace.averagePeopleDensityInAdjacentAreaLabel' | transloco">
        <div
            [innerHTML]="
                vm.soraResult?.adjacentArea?.populationDensity?.peoplePerSquareKilometerAvg
                    ? ('dtmSharedMission.adjacentAreaAndSpace.averagePeopleDensityInAdjacentAreaValue'
                      | transloco
                          : {
                                value: vm.soraResult?.adjacentArea?.populationDensity?.peoplePerSquareKilometerAvg
                            })
                    : ('dtmSharedMission.adjacentAreaAndSpace.noDataLabel' | transloco)
            "
        ></div>
    </dtm-ui-label-value>

    <dtm-ui-label-value
        [label]="'dtmSharedMission.adjacentAreaAndSpace.adjacentAreaTypeLabel' | transloco"
        [value]="
            vm.soraResult?.adjacentArea?.peopleAssembly
                ? ('dtmSharedMission.adjacentAreaAndSpace.peopleGatheringLabel' | transloco)
                : (vm.soraResult?.adjacentArea?.intrinsicGrl | grlLevel) ??
                  ('dtmSharedMission.adjacentAreaAndSpace.noDataLabel' | transloco)
        "
    ></dtm-ui-label-value>

    <dtm-ui-label-value
        [label]="'dtmSharedMission.adjacentAreaAndSpace.groundRiskClassInAdjacentAreaLabel' | transloco"
        [value]="
            (vm.soraResult?.adjacentArea?.intrinsicGrc | grcLevel) ?? ('dtmSharedMission.adjacentAreaAndSpace.noDataLabel' | transloco)
        "
    ></dtm-ui-label-value>

    <dtm-ui-label-value
        [label]="'dtmSharedMission.adjacentAreaAndSpace.adjacentAirspaceLabel' | transloco"
        [value]="
            'dtmSharedMission.adjacentAreaAndSpace.airspaceCharacteristicLabelText'
                | transloco : { characteristics: airspaceCharacteristics$ | ngrxPush }
        "
    ></dtm-ui-label-value>

    <dtm-ui-label-value
        [label]="'dtmSharedMission.adjacentAreaAndSpace.airspaceCharacteristicsLabel' | transloco"
        [value]="(vm.soraResult?.adjacentArea?.initialArc | arcLabel) ?? ('dtmSharedMission.adjacentAreaAndSpace.noDataLabel' | transloco)"
    ></dtm-ui-label-value>

    <ng-container [ngSwitch]="true" *ngIf="vm.soraResult?.flightTerminationSystem as flightTerminationSystem">
        <dtm-ui-label-value
            *ngSwitchCase="flightTerminationSystem.isAvailable || flightTerminationSystem.isRequired"
            [label]="'dtmSharedMission.adjacentAreaAndSpace.technicalRequirementsLabel' | transloco"
            [value]="'dtmSharedMission.adjacentAreaAndSpace.reinforcedLabel' | transloco"
        ></dtm-ui-label-value>

        <dtm-ui-label-value
            *ngSwitchCase="!flightTerminationSystem.isAvailable && !flightTerminationSystem.isRequired"
            [label]="'dtmSharedMission.adjacentAreaAndSpace.technicalRequirementsLabel' | transloco"
            [value]="'dtmSharedMission.adjacentAreaAndSpace.basicLabel' | transloco"
        >
        </dtm-ui-label-value>

        <dtm-ui-label-value
            *ngSwitchCase="!flightTerminationSystem.isAvailable && flightTerminationSystem.isRequired"
            [label]="'dtmSharedMission.adjacentAreaAndSpace.securityTypeLabel' | transloco"
            [value]="'dtmSharedMission.adjacentAreaAndSpace.noRequiredSecurityLabel' | transloco"
        ></dtm-ui-label-value>

        <dtm-ui-label-value
            *ngSwitchCase="flightTerminationSystem.isAvailable || !flightTerminationSystem.isRequired"
            [label]="'dtmSharedMission.adjacentAreaAndSpace.securityTypeLabel' | transloco"
        >
            <ng-container *ngIf="technicalRequirementsText$ | ngrxPush as text; else noDataTemplate">
                {{ text }}
            </ng-container>
        </dtm-ui-label-value>
    </ng-container>
</dtm-ui-expandable-panel>

<ng-template #noDataTemplate>{{ "dtmSharedMission.adjacentAreaAndSpace.noDataLabel" | transloco }}</ng-template>
