import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AdditionalCrewMember, AdditionalCrewMemberWatcher, WATCHER_ID } from "../../models";

interface AdditionalCrewMembersPreviewComponentState {
    additionalCrewMembers: AdditionalCrewMember[];
    watchers: AdditionalCrewMemberWatcher[];
}

@UntilDestroy()
@Component({
    selector: "dtm-mission-additional-crew-members-preview[additionalCrewMembers]",
    templateUrl: "./additional-crew-members-preview.component.html",
    styleUrls: ["./additional-crew-members-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AdditionalCrewMembersPreviewComponent {
    @Input() public set additionalCrewMembers(value: AdditionalCrewMember[] | undefined) {
        const parsedValue = !value ? [] : value;
        const additionalCrewMembers: AdditionalCrewMember[] = [];
        const watchers: AdditionalCrewMember[] = [];
        parsedValue.forEach((data) => (data.type === WATCHER_ID ? watchers.push(data) : additionalCrewMembers.push(data)));

        this.localStore.patchState({ additionalCrewMembers });
        this.localStore.patchState({ watchers });
    }

    protected readonly additionalCrewMembers$ = this.localStore.selectByKey("additionalCrewMembers");
    protected readonly watchers$ = this.localStore.selectByKey("watchers");

    protected readonly WATCHER_ID = WATCHER_ID;

    constructor(private readonly localStore: LocalComponentStore<AdditionalCrewMembersPreviewComponentState>) {
        this.localStore.setState({
            additionalCrewMembers: [],
            watchers: [],
        });
    }
}
