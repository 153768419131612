import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { TimeRange } from "@dtm-frontend/shared/ui";
import { AnimationUtils, DEFAULT_DEBOUNCE_TIME, FormType, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionDispatched } from "@ngxs/store";
import { debounceTime } from "rxjs/operators";
import { TimeSettingOptions, ZoneTimesSetting } from "../../../../geo-zones/models/geo-zones.models";
import { GeoZonesActions } from "../../../../geo-zones/state/geo-zones.actions";
import { GeoZonesControls } from "../map-layers-with-controls.component";
import { GeoZonesFilters } from "./filters/geographical-zones-filters.component";
import { LocalSpatialInfoForDtmSettings } from "./local-spatial-info-for-dtm/local-spatial-info-for-dtm-settings.component";
import { GeoZonesSettings } from "./zone-display-settings/geographical-zones-settings.component";

interface GeographicalZonesControlsComponentState {
    missionTimeRange: TimeRange | undefined;
    timeSettingsOptions: TimeSettingOptions | undefined;
    aupEndTime: Date | undefined;
    selectedOpacity: number;
}

// eslint-disable-next-line no-magic-numbers
const OPACITY_STEPS = [0.2, 0.4, 0.6, 0.8, 1];

@UntilDestroy()
@Component({
    selector: "dtm-map-geographical-zones-controls",
    templateUrl: "./geographical-zones-controls.component.html",
    styleUrls: ["./geographical-zones-controls.component.scss", "./geographical-zones-common.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class GeographicalZonesControlsComponent {
    @Input() public set missionTimeRange(value: TimeRange | undefined) {
        this.localStore.patchState({ missionTimeRange: value });
    }
    @Input() public set timeSettingsOptions(value: TimeSettingOptions | undefined) {
        this.localStore.patchState({ timeSettingsOptions: value });
    }
    @Input() public set aupEndTime(value: Date | undefined) {
        this.localStore.patchState({ aupEndTime: value });
    }

    @Output() public watchZonesSettingUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public opacitySettingUpdate = this.localStore.selectByKey("selectedOpacity").pipe(debounceTime(DEFAULT_DEBOUNCE_TIME));
    @Output() public selectedZoneTimeSettingUpdate = new EventEmitter<ZoneTimesSetting | null>();

    protected readonly missionTimeRange$ = this.localStore.selectByKey("missionTimeRange");
    protected readonly aupEndTime$ = this.localStore.selectByKey("aupEndTime");

    protected readonly allZonesEnabledControl = new FormControl(false);
    protected readonly geoZonesFiltersFormControl = new FormControl<GeoZonesFilters | null>(null);
    protected readonly geoZonesSettingFormControl = new FormControl<GeoZonesSettings | null>(null);
    protected readonly timeSettingsOptions$ = this.localStore.selectByKey("timeSettingsOptions");
    protected readonly localSpatialInfoForDtmSettingsFormControl = new FormControl<LocalSpatialInfoForDtmSettings | null>(null);
    protected readonly selectedOpacity$ = this.localStore.selectByKey("selectedOpacity");

    protected readonly OPACITY_STEPS = OPACITY_STEPS;

    protected readonly geographicalZonesFilterSettingsFormGroup = new FormGroup<FormType<GeoZonesControls>>({
        geoZonesFilters: this.geoZonesFiltersFormControl,
        geoZonesSettings: this.geoZonesSettingFormControl,
        localSpatialInfoForDtmSettings: this.localSpatialInfoForDtmSettingsFormControl,
    });

    @Output() public readonly geoZonesFilterSettingsUpdate = this.geographicalZonesFilterSettingsFormGroup.valueChanges;

    constructor(
        private readonly localStore: LocalComponentStore<GeographicalZonesControlsComponentState>,
        private readonly actions$: Actions
    ) {
        localStore.setState({
            missionTimeRange: undefined,
            timeSettingsOptions: undefined,
            aupEndTime: undefined,
            selectedOpacity: 1,
        });
        this.geographicalZonesFilterSettingsFormGroup.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(({ geoZonesSettings, localSpatialInfoForDtmSettings }) => {
                if (!geoZonesSettings?.areGeoZonesEnabled || !localSpatialInfoForDtmSettings?.isLocalSpatialInfoForDtmEnabled) {
                    this.allZonesEnabledControl.setValue(false, { emitEvent: false });
                }
                if (geoZonesSettings?.areGeoZonesEnabled && localSpatialInfoForDtmSettings?.isLocalSpatialInfoForDtmEnabled) {
                    this.allZonesEnabledControl.setValue(true, { emitEvent: false });
                }
            });

        this.allZonesEnabledControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            const geoZonesSettings = this.geoZonesSettingFormControl.value;
            if (!geoZonesSettings) {
                return;
            }

            this.geoZonesSettingFormControl.patchValue({ ...geoZonesSettings, areGeoZonesEnabled: !!value });

            const localSpatialInfoForDtmSettings = this.localSpatialInfoForDtmSettingsFormControl.value;
            if (!localSpatialInfoForDtmSettings) {
                return;
            }

            this.localSpatialInfoForDtmSettingsFormControl.patchValue({
                ...localSpatialInfoForDtmSettings,
                isLocalSpatialInfoForDtmEnabled: !!value,
            });
        });

        this.actions$.pipe(ofActionDispatched(GeoZonesActions.EnableAllGeoZones), untilDestroyed(this)).subscribe(() => {
            const geoZonesSettings = this.geoZonesSettingFormControl.value;
            if (!geoZonesSettings) {
                return;
            }

            this.geoZonesSettingFormControl.patchValue({ ...geoZonesSettings, areGeoZonesEnabled: true });
        });
    }

    protected selectOpacity(opacity: number): void {
        this.localStore.patchState({ selectedOpacity: opacity });
    }
}
