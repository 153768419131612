import { ConfigurableFocusTrapFactory } from "@angular/cdk/a11y";

import { Overlay, OverlayRef } from "@angular/cdk/overlay";

import { ComponentPortal } from "@angular/cdk/portal";

import { DOCUMENT } from "@angular/common";

import { Directive, ElementRef, HostBinding, HostListener, Inject, OnDestroy } from "@angular/core";

import { LocalComponentStore } from "@dtm-frontend/shared/utils";

import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { firstValueFrom } from "rxjs";

import { distinctUntilChanged } from "rxjs/operators";

import { DeviceSize, DeviceSizeService } from "../../services/device-size/device-size.service";

import { ZoomImageOverlayService } from "./zoom-image-overlay.service";

import { ZoomImageComponent } from "./zoom-image/zoom-image.component";

@UntilDestroy()
@Directive({
    selector: "[dtmUiPageZoomImage]",
    providers: [LocalComponentStore],
})
export class ZoomImageDirective implements OnDestroy {
    @HostBinding("class") private className = "clickable-image";
    @HostBinding("role") private role = "button";
    @HostListener("click", ["$event"]) private imageClick(event: PointerEvent): void {
        this.zoomImage((event.target as HTMLImageElement).src, (event.target as HTMLImageElement).alt);
    }

    @HostListener("document:keydown.enter", ["$event"]) private imageEnter(event: KeyboardEvent): void {
        this.zoomImage((event.target as HTMLImageElement).src, (event.target as HTMLImageElement).alt);
    }

    public ngOnDestroy() {
        this.zoomImageOverlayService.removeCreatedOverlay();
    }

    constructor(
        private readonly overlay: Overlay,
        private readonly zoomImageOverlayService: ZoomImageOverlayService,
        private readonly focusTrapFactory: ConfigurableFocusTrapFactory,
        private readonly elementRef: ElementRef,
        private readonly deviceSizeService: DeviceSizeService,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        this.deviceSizeService
            .getSizeObservable(DeviceSize.Tablet, DeviceSize.Desktop, DeviceSize.DesktopWide)
            .pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe((isWideScreen) => {
                if (isWideScreen) {
                    elementRef.nativeElement.tabIndex = 0;

                    return;
                }

                elementRef.nativeElement.tabIndex = -1;
            });
    }

    protected async zoomImage(imgSrc: string, altText?: string): Promise<void> {
        if (this.deviceSizeService.isSize(DeviceSize.Smartphone, DeviceSize.SmartphoneWide)) {
            return;
        }

        const isOverlayCreated = await firstValueFrom(this.zoomImageOverlayService.createdOverlay$);
        if (isOverlayCreated) {
            return;
        }

        if (!imgSrc) {
            console.error("Missing imgSrc");

            return;
        }

        const lastFocusedElement = this.getLastFocusedElement();
        const overlayRef = this.overlay.create({
            hasBackdrop: true,
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            scrollStrategy: this.overlay.scrollStrategies.block(),
        });
        const component = new ComponentPortal(ZoomImageComponent);
        const componentRef = overlayRef.attach(component);
        componentRef.instance.imageSrc = imgSrc;
        componentRef.instance.altText = altText;

        // NOTE it keeps track of open image overlay
        this.zoomImageOverlayService.addCreatedOverlaySubject(overlayRef);

        // NOTE it prevents from focusing elements outside the zoom-image-component
        const zoomComponent = componentRef.location.nativeElement;
        this.focusTrapFactory.create(zoomComponent);

        componentRef.instance.closeZoom.pipe(untilDestroyed(this)).subscribe(() => this.detachOverlay(overlayRef, lastFocusedElement));

        overlayRef
            .backdropClick()
            .pipe(untilDestroyed(this))
            .subscribe(() => this.detachOverlay(overlayRef, lastFocusedElement));
    }

    private detachOverlay(overlayRef: OverlayRef, lastFocusedElement: HTMLElement | null) {
        overlayRef.dispose();
        lastFocusedElement?.focus();
        this.zoomImageOverlayService.removeCreatedOverlay();
    }

    private getLastFocusedElement(): HTMLElement | null {
        // NOTE this is a copy from _getFocusedElementPierceShadowDom in shadow-dom.ts from cdk/platform
        let activeElement =
            typeof this.document !== "undefined" && this.document ? (this.document.activeElement as HTMLElement | null) : null;

        while (activeElement && activeElement.shadowRoot) {
            const newActiveElement = activeElement.shadowRoot.activeElement as HTMLElement | null;
            if (newActiveElement === activeElement) {
                break;
            } else {
                activeElement = newActiveElement;
            }
        }

        return activeElement;
    }
}
