<div class="avatar" *ngrxLet="{ picture: picture$, label: label$, fullName: fullName$ } as vm">
    <img
        *ngIf="vm.picture; else sourceTemplate"
        class="picture"
        [src]="vm.picture"
        [alt]="'dtmUi.avatar.avatarAltText' | transloco"
        (error)="setDefaultPicture(vm.picture)"
    />
    <ng-template #sourceTemplate>
        <img
            *ngIf="sourceUrl$ | ngrxPush as source; else noImageTemplate"
            class="picture"
            [dtmUiImgSecuredSrc]="source"
            (error)="setDefaultSourceUrl(source)"
        />
    </ng-template>
    <ng-template #noImageTemplate>
        <span class="no-avatar">
            <dtm-ui-icon name="user"></dtm-ui-icon>
        </span>
    </ng-template>
    <section class="context" *ngIf="vm.label || vm.fullName">
        <span class="context-label">{{ vm.label }}</span>
        <span class="context-full-name">{{ vm.fullName }}</span>
    </section>
</div>
