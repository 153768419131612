const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
const DEFAULT_ID_LENGTH = 12;

export function generateId(length = DEFAULT_ID_LENGTH): string {
    let id = "";

    for (let index = 0; index < length; index++) {
        id += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return id;
}
