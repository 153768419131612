import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { ProcessedFile, UploadedFile } from "../files-upload.models";

interface FilesUploadComponentState {
    uploadedFiles: UploadedFile[];
    processedFiles: ProcessedFile[];
    isDisabled: boolean;
    isDownloadAllButtonVisible: boolean;
    isDraggedOver: boolean;
    allowedTypes: string[];
    isMultiple: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-files-upload",
    templateUrl: "./files-upload.component.html",
    styleUrls: ["./files-upload.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FilesUploadComponent {
    private static nextId = 1;

    @ViewChild("fileInput") private fileInput!: ElementRef<HTMLInputElement>;

    @Input() public set processedFiles(value: ProcessedFile[] | undefined) {
        if (!value?.length && this.fileInput) {
            this.fileInput.nativeElement.value = "";
        }
        this.localStore.patchState({ processedFiles: value ?? [] });
    }

    @Input() public set isDisabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }

    @Input() public set allowedTypes(value: string[] | undefined) {
        this.localStore.patchState({ allowedTypes: value ?? [] });
    }

    @Input() public set isMultiple(value: BooleanInput) {
        this.localStore.patchState({ isMultiple: coerceBooleanProperty(value) });
    }

    @Output() public processedFilesAdd = new EventEmitter<ProcessedFile[]>();
    @Output() public processedFileRemove = new EventEmitter<ProcessedFile>();

    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");
    protected readonly processedFiles$ = this.localStore.selectByKey("processedFiles");

    protected readonly isDraggedOver$ = this.localStore.selectByKey("isDraggedOver");
    protected readonly allowedTypes$ = this.localStore.selectByKey("allowedTypes");
    protected readonly isMultiple$ = this.localStore.selectByKey("isMultiple");

    constructor(private readonly localStore: LocalComponentStore<FilesUploadComponentState>) {
        this.localStore.setState({
            uploadedFiles: [],
            processedFiles: [],
            isDisabled: false,
            isDownloadAllButtonVisible: true,
            isDraggedOver: false,
            allowedTypes: [],
            isMultiple: true,
        });
    }

    protected addProcessedFiles(files: FileList): void {
        if (this.isEditionBlocked()) {
            return;
        }

        const fileItems: File[] = [];
        if (this.localStore.selectSnapshotByKey("isMultiple")) {
            fileItems.push(...files);
        } else {
            const file = files.item(0);
            if (file) {
                fileItems.push(file);
            }
        }

        const newProcessedFiles: ProcessedFile[] = [];

        for (const file of fileItems) {
            newProcessedFiles.push({
                id: this.getNextFileId(),
                file,
                progress: 0,
            });
        }

        this.processedFilesAdd.emit(newProcessedFiles);
        this.fileInput.nativeElement.value = "";
    }

    protected removeProcessedFile(file: ProcessedFile): void {
        if (this.isEditionBlocked()) {
            return;
        }

        this.processedFileRemove.emit(file);
    }

    protected draggedOverStatusChanged(isDraggedOver: boolean): void {
        if (this.isEditionBlocked()) {
            return;
        }

        this.localStore.patchState({ isDraggedOver });
    }

    private isEditionBlocked(): boolean {
        return this.localStore.selectSnapshotByKey("isDisabled");
    }

    private getNextFileId(): string {
        return `file-${FilesUploadComponent.nextId++}`;
    }
}
