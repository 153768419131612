import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Alpha3CountryCode, CountryListItem } from "../../models/country.models";
import { DisplayCountryService } from "../../services/display-country/display-country.service";

interface CountryDisplayComponentState {
    country: CountryListItem | undefined | null;
}

@Component({
    selector: "dtm-ui-country-display[country]",
    templateUrl: "./country-display.component.html",
    styleUrls: ["./country-display.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CountryDisplayComponent {
    protected readonly country$ = this.localStore.selectByKey("country");

    @Input() public set country(value: Alpha3CountryCode | undefined) {
        if (!value) {
            this.localStore.patchState({ country: undefined });

            return;
        }

        const countryListItem = this.countryDetailsService.getCountryByCountryCode(value);

        this.localStore.patchState({ country: countryListItem ? countryListItem : null });
    }

    constructor(
        private readonly countryDetailsService: DisplayCountryService,
        private readonly localStore: LocalComponentStore<CountryDisplayComponentState>
    ) {
        this.localStore.setState({
            country: undefined,
        });
    }
}
