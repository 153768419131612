import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { saveAs } from "file-saver";
import { ToastrService } from "ngx-toastr";
import { EMPTY, Observable, combineLatestWith } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { FILES_UPLOAD_API_PROVIDER, FilesUploadApi, UploadedFile } from "../files-upload-field/files-upload.models";

const DEFAULT_COMPRESSED_FILE_NAME = "files";

interface UploadedFilesDisplayComponentState {
    uploadedFiles: UploadedFile[];
    compressedFileName: string;
    isDisabled: boolean;
    isDownloadAllButtonVisible: boolean;
    canRemoveFile: boolean;
    shouldHideDownloadButtons: boolean;
    isDisplayFileVisible: boolean;
    additionalPathParams: Record<string, string> | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-uploaded-files-display[uploadedFiles]",
    templateUrl: "./uploaded-files-display.component.html",
    styleUrls: ["./uploaded-files-display.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UploadedFilesDisplayComponent {
    @Input() public set uploadedFiles(value: UploadedFile[] | undefined) {
        this.localStore.patchState({ uploadedFiles: value ?? [] });
    }
    @Input() public set compressedFileName(value: string | undefined) {
        this.localStore.patchState({ compressedFileName: value ?? DEFAULT_COMPRESSED_FILE_NAME });
    }
    @Input() public set isDisabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }
    @Input() public set isDownloadAllButtonVisible(value: BooleanInput) {
        this.localStore.patchState({ isDownloadAllButtonVisible: coerceBooleanProperty(value) });
    }
    @Input() public set canRemoveFile(value: BooleanInput) {
        this.localStore.patchState({ canRemoveFile: coerceBooleanProperty(value) });
    }
    @Input() public set isDisplayFileVisible(value: BooleanInput) {
        this.localStore.patchState({ isDisplayFileVisible: coerceBooleanProperty(value) });
    }
    @Input() public set additionalPathParams(value: Record<string, string> | undefined) {
        this.localStore.patchState({ additionalPathParams: value });
    }
    @Input() public set shouldHideDownloadButtons(value: BooleanInput) {
        this.localStore.patchState({ shouldHideDownloadButtons: coerceBooleanProperty(value) });
    }

    @Output() public readonly tryRemoveFile = new EventEmitter<UploadedFile>();
    @Output() public readonly displayFile = new EventEmitter<void>();

    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");
    protected readonly uploadedFiles$ = this.localStore.selectByKey("uploadedFiles");
    protected readonly canRemoveFile$ = this.localStore.selectByKey("canRemoveFile");
    protected readonly isDisplayFileVisible$ = this.localStore.selectByKey("isDisplayFileVisible");
    protected readonly isDownloadAllButtonVisible$ = this.uploadedFiles$.pipe(
        map((files) => files.length > 0),
        combineLatestWith(this.localStore.selectByKey("isDownloadAllButtonVisible")),
        map(([isValueNotEmpty, isDownloadAllButtonVisible]) => isValueNotEmpty && isDownloadAllButtonVisible)
    );
    protected readonly shouldHideDownloadButtons$ = this.localStore.selectByKey("shouldHideDownloadButtons");

    constructor(
        private readonly localStore: LocalComponentStore<UploadedFilesDisplayComponentState>,
        @Inject(FILES_UPLOAD_API_PROVIDER) private readonly fileUploadApi: FilesUploadApi,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService
    ) {
        if (fileUploadApi === undefined) {
            throw new Error("Missing FILES_UPLOAD_API_PROVIDER provider");
        }
        this.localStore.setState({
            uploadedFiles: [],
            compressedFileName: DEFAULT_COMPRESSED_FILE_NAME,
            isDisabled: false,
            isDownloadAllButtonVisible: false,
            canRemoveFile: false,
            isDisplayFileVisible: false,
            additionalPathParams: undefined,
            shouldHideDownloadButtons: false,
        });
    }

    protected downloadFile(file: UploadedFile): void {
        this.download([file]);
    }

    protected downloadAll(): void {
        const files = this.localStore.selectSnapshotByKey("uploadedFiles");

        if (files.length) {
            this.download(files);
        }
    }

    private download(files: UploadedFile[]): void {
        let resultFile$: Observable<Blob> | undefined;
        const additionalPathParams = this.localStore.selectSnapshotByKey("additionalPathParams");

        if (files.length === 1) {
            const file = files[0];
            resultFile$ = this.fileUploadApi.getFile(file.id, additionalPathParams).pipe(
                catchError(() => {
                    this.toastService.error(this.transloco.translate("dtmUi.filesUpload.downloadFileErrorMessage"));

                    return EMPTY;
                }),
                tap((blob) => saveAs(blob, file.name))
            );
        } else {
            const fileName = `${this.localStore.selectSnapshotByKey("compressedFileName")}.zip`;
            resultFile$ = this.fileUploadApi
                .getFilesCompressed?.(
                    files.map((file) => file.id),
                    fileName,
                    additionalPathParams
                )
                .pipe(
                    catchError(() => {
                        this.toastService.error(this.transloco.translate("dtmUi.filesUpload.downloadFileErrorMessage"));

                        return EMPTY;
                    }),
                    tap((blob) => saveAs(blob, fileName))
                );
        }

        resultFile$?.pipe(untilDestroyed(this)).subscribe();
    }
}
