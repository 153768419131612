import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { FlightZoneCapabilitiesState } from "@dtm-frontend/dss-shared-lib";
import { RouteData } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, map } from "rxjs";
import { MissionFilters, PhasePayloadData, PlannedMission } from "../../models/planned-missions.models";
import { PlannedMissionsActions } from "../../state/planned-missions.actions";
import { PlannedMissionsState } from "../../state/planned-missions.state";

interface PlannedMissionContainerComponentState {
    isPlannedMissionFolded: boolean;
    selectedMissionPlanId: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dss-client-lib-planned-mission-container",
    templateUrl: "./planned-mission-container.component.html",
    styleUrls: ["./planned-mission-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PlannedMissionContainerComponent implements OnDestroy {
    protected readonly isPlannedMissionFolded$ = this.localStore.selectByKey("isPlannedMissionFolded");
    protected readonly selectedMissionPlanId$ = this.localStore.selectByKey("selectedMissionPlanId");
    protected readonly waitingMissions$ = this.store.select(PlannedMissionsState.waitingMissions);
    protected readonly rejectedMissions$ = this.store.select(PlannedMissionsState.rejectedMissions);
    protected readonly acceptedMissions$ = this.store.select(PlannedMissionsState.acceptedMissions);
    protected readonly areNewMissionsAvailable$ = this.store.select(PlannedMissionsState.areNewMissionsAvailable);
    protected readonly isProcessing$ = this.store.select(PlannedMissionsState.isProcessing);
    protected readonly isPlanRouteProcessing$ = this.store.select(PlannedMissionsState.isPlanRouteProcessing);
    protected readonly selectedMissionPlanRoute$ = this.store.select(PlannedMissionsState.selectedMissionPlanRoute);
    protected readonly routeData$ = this.initRouteData();
    protected readonly initialViewbox$ = this.store
        .select(FlightZoneCapabilitiesState.capabilities)
        .pipe(map((capabilities) => capabilities?.preferences?.initialViewbox));

    constructor(
        private readonly localStore: LocalComponentStore<PlannedMissionContainerComponentState>,
        private readonly store: Store,
        private readonly transloco: TranslocoService,
        private readonly toastr: ToastrService
    ) {
        this.localStore.setState({
            isPlannedMissionFolded: false,
            selectedMissionPlanId: undefined,
        });

        this.store.dispatch([new PlannedMissionsActions.GetMissionList(), new PlannedMissionsActions.PlannedMissionsWatch()]);
    }

    public ngOnDestroy(): void {
        this.store.dispatch([PlannedMissionsActions.ClearMissionData, PlannedMissionsActions.StopPlannedMissionsWatch]);
    }

    protected togglePanelFold(): void {
        this.localStore.patchState((state) => ({ isPlannedMissionFolded: !state.isPlannedMissionFolded }));
    }

    protected selectMission(mission: PlannedMission): void {
        const previousSelectedPlanId = this.localStore.selectSnapshotByKey("selectedMissionPlanId");

        if (previousSelectedPlanId === mission.id) {
            return;
        }

        this.localStore.patchState({ selectedMissionPlanId: mission.id });
        this.getMissionRoute(mission.routeId);
    }

    protected filterMissions(filters: MissionFilters): void {
        this.store.dispatch(new PlannedMissionsActions.GetMissionList(filters, true));
        this.localStore.patchState({ selectedMissionPlanId: undefined });
    }

    protected changeMissionPhase(payloadData: PhasePayloadData): void {
        this.store
            .dispatch(new PlannedMissionsActions.ChangeMissionPhase(payloadData))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (this.store.selectSnapshot(PlannedMissionsState.phaseChangeMissionError)) {
                    this.toastr.error(
                        this.transloco.translate("dssClientLibPlannedMissions.plannedMissionContainer.missionPhaseChangeErrorMessage", {
                            value: payloadData.missionPhase,
                        })
                    );

                    return;
                }

                this.toastr.success(
                    this.transloco.translate("dssClientLibPlannedMissions.plannedMissionContainer.missionPhaseChangeSuccessMessage", {
                        value: payloadData.missionPhase,
                    })
                );

                this.store.dispatch(new PlannedMissionsActions.GetMissionList());
                this.localStore.patchState({ selectedMissionPlanId: undefined });
            });
    }

    private getMissionRoute(routeId: string): void {
        this.store
            .dispatch(new PlannedMissionsActions.GetMissionRoute(routeId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(PlannedMissionsState.missionPlanRouteError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dssClientLibPlannedMissions.plannedMissionContainer.missionRouteErrorMessage")
                    );
                }
            });
    }

    private initRouteData(): Observable<RouteData<PlannedMission> | undefined> {
        return this.selectedMissionPlanRoute$.pipe(
            RxjsUtils.filterFalsy(),
            map((data, uniqueRouteId) => ({
                route: data,
                isMain: true,
                uniqueRouteId,
            }))
        );
    }
}
