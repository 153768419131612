import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { SerializableCartographic } from "../../models/serializable-cartographic";
import { MapEntityType } from "../../services/entity-editors/map-entities-editor.service";

export type ManualCoordinatesInputEntityType = MapEntityType.Cylinder | MapEntityType.Polyline3D;

export interface ManualCoordinatesInputDialogConfig {
    type: ManualCoordinatesInputEntityType;
    maxRadius: number | undefined;
    maxHorizontalAccuracy: number | undefined;
    defaultHorizontalAccuracy: number | undefined;
}

const COORDINATES_MAX_LENGTH = 200;
const DEFAULT_CYLINDER_RADIUS = 250;

@Component({
    templateUrl: "./manual-coordinates-input-dialog.component.html",
    styleUrls: ["./manual-coordinates-input-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManualCoordinatesInputDialogComponent {
    protected readonly formGroup = new FormGroup({
        cylinderRadius: new FormControl<number | undefined>(DEFAULT_CYLINDER_RADIUS, { nonNullable: true }),
        corridorWidth: new FormControl<number | undefined>(0, { nonNullable: true }),
    });

    protected readonly MapEntityType = MapEntityType;
    protected readonly COORDINATES_MAX_LENGTH = COORDINATES_MAX_LENGTH;

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: ManualCoordinatesInputDialogConfig,
        private readonly dialogRef: MatDialogRef<ManualCoordinatesInputDialogComponent>
    ) {
        this.formGroup.controls.corridorWidth.setValue(data.defaultHorizontalAccuracy);
    }

    protected closeDialog(coordinates?: SerializableCartographic[]) {
        if (!coordinates) {
            this.dialogRef.close(false);

            return;
        }

        const size =
            (this.data.type === MapEntityType.Cylinder
                ? this.formGroup.controls.cylinderRadius.value
                : this.formGroup.controls.corridorWidth.value) ?? 1;
        this.dialogRef.close({ coordinates, size });
    }
}
