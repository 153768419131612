<dtm-ui-input-field
    matAutocompleteOrigin
    #autocompleteOrigin="matAutocompleteOrigin"
    [isClearable]="false"
    *ngrxLet="{ options: options$, isProcessing: isProcessing$, placeholder: placeholder$ } as vm"
>
    <ng-content select="label" ngProjectAs="label"></ng-content>
    <input
        matInput
        [formControl]="searchControl"
        [placeholder]="vm.placeholder"
        [matAutocomplete]="autocomplete"
        [matAutocompleteConnectedTo]="autocompleteOrigin"
        (focusout)="propagateTouch()"
    />
    <dtm-ui-icon name="search" class="field-suffix"></dtm-ui-icon>
    <ng-content select=".field-hint" ngProjectAs=".field-hint"></ng-content>
    <ng-content select=".field-error" ngProjectAs=".field-error"></ng-content>
    <mat-autocomplete
        (optionSelected)="selectValue($event)"
        [displayWith]="displayAutocompleteValueFunction"
        #autocomplete="matAutocomplete"
    >
        <ng-container *ngIf="!vm.isProcessing; else loaderTemplate">
            <mat-option *ngIf="!vm.options.length" class="empty-options" disabled>
                <ng-content select="[noValueLabelSlot]"></ng-content>
            </mat-option>
            <mat-option *ngFor="let option of vm.options" [value]="option">
                {{ option.name }}
            </mat-option>
        </ng-container>
        <ng-template #loaderTemplate>
            <mat-option class="options-loader" disabled>
                <mat-spinner diameter="20" color="accent"></mat-spinner>
            </mat-option>
        </ng-template>
    </mat-autocomplete>
</dtm-ui-input-field>
