import { Injectable } from "@angular/core";
import {
    BaseNotificationEventsService,
    DssNotamNotificationType,
    DssNotification,
    DssNotificationsService,
} from "@dtm-frontend/dss-shared-lib";
import { Notification, NotificationDisplay, SharedNotificationDisplayProps } from "@dtm-frontend/shared/notifications";
import { IconName } from "@dtm-frontend/shared/ui";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";

@Injectable({
    providedIn: "root",
})
export class InstitutionNotamNotificationEventsService implements BaseNotificationEventsService {
    constructor(private readonly translocoService: TranslocoService, private readonly dssNotificationsService: DssNotificationsService) {}

    public getEventsList(notificationsToFormat: DssNotification[]): NotificationDisplay[] {
        return notificationsToFormat
            .filter((notification) =>
                this.dssNotificationsService.getDssNotamNotificationTypes().some((type) => notification.payload.type === type)
            )
            .map((notification) => {
                const sharedProps = this.getSharedProps(notification, "eye");

                switch (notification.payload.type) {
                    case DssNotamNotificationType.PublishedNotamEvent:
                        return this.getPublishedNotamEvent(sharedProps, notification);

                    case DssNotamNotificationType.PublishedNotamReplacementEvent:
                        return this.getPublishedNotamReplacementEvent(sharedProps, notification);

                    default:
                        return null;
                }
            })
            .filter(FunctionUtils.isTruthy);
    }

    private getPublishedNotamEvent(sharedProps: SharedNotificationDisplayProps, notification: DssNotification): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssClient.notifications.notamLabel"),
            text: this.translocoService.translate("dssClient.notifications.notamPublishedText"),
            redirectUrl: `restriction/${notification.payload.id}`,
        };
    }

    private getPublishedNotamReplacementEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: DssNotification
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dssClient.notifications.notamLabel"),
            text: this.translocoService.translate("dssClient.notifications.notamModifiedText"),
            redirectUrl: `restriction/${notification.payload.id}`,
        };
    }

    private getSharedProps(notification: Notification, icon: IconName): SharedNotificationDisplayProps {
        return { icon, date: notification.createdAt, id: notification.id, isRead: notification.read };
    }
}
