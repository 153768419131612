import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from "@angular/core";

const enum Cursor {
    Grabbing = "grabbing",
    Grab = "grab",
}

@Directive({
    selector: "[dtmUiHorizontalScroll]",
})
export class HorizontalScrollDirective implements OnDestroy {
    @Input() public set dtmUiHorizontalScroll(value: BooleanInput) {
        this.isDisabled = !coerceBooleanProperty(value);

        if (this.isDisabled) {
            this.renderer.removeStyle(this.el.nativeElement, "cursor");
        } else {
            this.renderer.setStyle(this.el.nativeElement, "cursor", Cursor.Grab);
        }
    }

    private isDragging = false;
    private isDisabled = false;
    private startX = 0;
    private scrollLeft = 0;

    private mouseMoveListener?: () => void;
    private mouseUpListener?: () => void;
    private mouseLeaveListener?: () => void;

    constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {
        this.renderer.setStyle(this.el.nativeElement, "cursor", Cursor.Grab);
    }

    @HostListener("mousedown", ["$event"])
    private onMouseDown(event: MouseEvent): void {
        if (this.isDisabled) {
            return;
        }

        this.isDragging = true;
        this.startX = event instanceof MouseEvent ? event.clientX : (event as TouchEvent).touches[0].clientX;
        this.scrollLeft = this.el.nativeElement.scrollLeft;

        this.renderer.setStyle(this.el.nativeElement, "cursor", Cursor.Grabbing);

        this.mouseMoveListener = this.renderer.listen(this.el.nativeElement, "mousemove", this.onMove);
        this.mouseUpListener = this.renderer.listen(this.el.nativeElement, "mouseup", this.onEnd);
        this.mouseLeaveListener = this.renderer.listen(this.el.nativeElement, "mouseleave", this.onEnd);
    }

    private onMove = (event: MouseEvent): void => {
        if (!this.isDragging) {
            return;
        }

        const deltaX = event.clientX - this.startX;
        this.el.nativeElement.scrollLeft = this.scrollLeft - deltaX;
    };

    private onEnd = (): void => {
        this.isDragging = false;
        this.renderer.setStyle(this.el.nativeElement, "cursor", Cursor.Grab);

        this.mouseMoveListener?.();
        this.mouseUpListener?.();
        this.mouseLeaveListener?.();
    };

    public ngOnDestroy(): void {
        this.onEnd();
    }
}
