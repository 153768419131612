<section class="container scroll-shadows">
    <div class="header">
        <span class="active-step">
            {{
                "dtmUi.applicationWizardSteps.activeStepLabel"
                    | transloco : { stepNumber: stepNumber$ | ngrxPush, stepsAmount: stepsAmount$ | ngrxPush }
            }}
        </span>
        <h3 class="step-title">{{ title$ | ngrxPush }}</h3>
        <div class="header-slot">
            <ng-content select="[headerSlot]"></ng-content>
        </div>
    </div>
    <ng-content></ng-content>
</section>
<footer class="actions" *ngrxLet="isSaveDraftProcessing$; let isSaveDraftProcessing">
    <div class="secondary-actions">
        <button *ngIf="isBackButtonVisible$ | ngrxPush" class="button-secondary" type="button" (click)="back.emit()">
            <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
            {{ "dtmUi.applicationWizardSteps.backButtonLabel" | transloco }}
        </button>
        <button
            *ngIf="isSaveDraftButtonVisible$ | ngrxPush"
            [disabled]="!(isSaveDraftButtonEnabled$ | ngrxPush)"
            class="button-secondary"
            type="button"
            (click)="saveDraft.emit()"
        >
            <mat-spinner diameter="20" *ngIf="isSaveDraftProcessing" color="accent"></mat-spinner>
            <dtm-ui-icon name="save" *ngIf="!isSaveDraftProcessing"></dtm-ui-icon>
            {{ "dtmUi.applicationWizardSteps.saveDraftButtonLabel" | transloco }}
        </button>
    </div>
    <button [disabled]="!(isNextButtonEnabled$ | ngrxPush)" class="button-primary next-button" type="button" (click)="next.emit()">
        <mat-spinner diameter="20" *ngIf="isNextStepProcessing$ | ngrxPush" color="accent"></mat-spinner>
        {{ (nextButtonLabel$ | ngrxPush) ?? ("dtmUi.applicationWizardSteps.nextButtonLabel" | transloco) }}
    </button>
</footer>
