<div *ngrxLet="status$ as status" [ngSwitch]="status">
    <span *ngSwitchCase="OperatorStatus.Approved" class="chip success">
        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
        {{ "dtmUi.operatorStatusBadge.approvedLabel" | transloco }}
    </span>
    <span *ngSwitchCase="OperatorStatus.Suspended" class="chip primary">
        <dtm-ui-icon name="pause-circle"></dtm-ui-icon>
        {{ "dtmUi.operatorStatusBadge.suspendedLabel" | transloco }}
    </span>
    <span *ngSwitchCase="OperatorStatus.PendingApproval" class="chip warning">
        <dtm-ui-icon name="rest-time"></dtm-ui-icon>
        {{ "dtmUi.operatorStatusBadge.pendingApprovalLabel" | transloco }}
    </span>
    <span *ngSwitchCase="OperatorStatus.ApprovalInProgress" class="chip secondary">
        <dtm-ui-icon name="time"></dtm-ui-icon>
        {{ "dtmUi.operatorStatusBadge.approvalInProgressLabel" | transloco }}
    </span>
</div>
