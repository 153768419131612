import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Directive, EventEmitter, HostListener, Input, Output } from "@angular/core";

@Directive({
    selector: "[dtmUiFileDropzone]",
})
export class FileDropzoneDirective {
    private enterCounter = 0;
    private isDisabled = false;

    @Input("dtmUiFileDropzone") public set isEnabled(value: BooleanInput) {
        this.isDisabled = !coerceBooleanProperty(value);
    }

    @Output() public filesDrop = new EventEmitter<FileList>();
    @Output() public isFileDraggedOverChange = new EventEmitter<boolean>();

    @HostListener("dragenter", ["$event"]) public onDragEnter(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.isDisabled) {
            return;
        }

        this.enterCounter++;

        this.isFileDraggedOverChange.emit(true);
    }

    @HostListener("dragover", ["$event"]) public onDragOver(event: Event) {
        event.preventDefault();
    }

    @HostListener("dragleave", ["$event"]) public onDragLeave(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.isDisabled) {
            return;
        }

        this.enterCounter--;

        if (this.enterCounter <= 0) {
            this.isFileDraggedOverChange.emit(false);
        }
    }

    @HostListener("drop", ["$event"]) public onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.isDisabled) {
            return;
        }

        this.enterCounter = 0;
        this.isFileDraggedOverChange.emit(false);

        const files = event.dataTransfer?.files;

        if (files && files.length > 0) {
            this.filesDrop.emit(files);
        }
    }
}
