import { Directive, OnDestroy } from "@angular/core";
import { AcMapComponent } from "@pansa/ngx-cesium";

@Directive({
    selector: "ac-map[dtmMapCesiumCleanupOnDestroy]",
})
export class CesiumCleanupOnDestroyDirective implements OnDestroy {
    constructor(private readonly acMap: AcMapComponent) {}

    public ngOnDestroy(): void {
        this.acMap.getCesiumViewer().destroy();
    }
}
