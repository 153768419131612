import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { SharedToastModule } from "@dtm-frontend/shared/ui/toast";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { AngularCesiumModule, AngularCesiumWidgetsModule, CoordinateConverter, ViewerConfiguration } from "@pansa/ngx-cesium";
import { ToastContainerModule } from "ngx-toastr";
import { CesiumCleanupOnDestroyDirective } from "./cesium-cleanup-on-destroy.directive";
import { AzureMapsLayerComponent } from "./components/azure-maps-layer/azure-maps-layer.component";
import { BufferAreasLayerComponent } from "./components/buffer-areas-layer/buffer-areas-layer.component";
import { CheckinAreaLayerComponent } from "./components/checkin-area-layer/checkin-area-layer.component";
import { CircularBoundaryLayerComponent } from "./components/circular-boundary-layer/circular-boundary-layer.component";
import { CoordinatesPasteAreaComponent } from "./components/coordinates-paste-area/coordinates-paste-area.component";
import { CylindersEditorComponent } from "./components/cylinders-editor/cylinders-editor.component";
import { ManualCoordinatesInputDialogComponent } from "./components/manual-coordinates-input-dialog/manual-coordinates-input-dialog.component";
import { ManualWidthInputDialogComponent } from "./components/manual-width-input-dialog/manual-width-input-dialog.component";
import { MapActionsPanelComponent } from "./components/map-actions-panel/map-actions-panel.component";
import { MapControlsComponent } from "./components/map-controls/map-controls.component";
import { MeasuresCesiumComponent } from "./components/map-controls/measures-cesium/measures-cesium.component";
import { GeographicalZonesFiltersComponent } from "./components/map-layers-with-controls/controls/filters/geographical-zones-filters.component";
import { GeographicalZonesControlsComponent } from "./components/map-layers-with-controls/controls/geographical-zones-controls.component";
import { GlobalSpatialInfoSettingsComponent } from "./components/map-layers-with-controls/controls/global-spatial-info-settings/global-spatial-info-settings.component";
import { LocalSpatialInfoForDtmSettingsComponent } from "./components/map-layers-with-controls/controls/local-spatial-info-for-dtm/local-spatial-info-for-dtm-settings.component";
import { GeographicalZonesSettingsComponent } from "./components/map-layers-with-controls/controls/zone-display-settings/geographical-zones-settings.component";
import { ZoneLegendComponent } from "./components/map-layers-with-controls/controls/zone-legend/zone-legend.component";
import { KmlControlsComponent } from "./components/map-layers-with-controls/kml/kml-controls/kml-controls.component";
import { KmlImportModalComponent } from "./components/map-layers-with-controls/kml/kml-import-modal/kml-import-modal.component";
import { GeographicalZonesLayerComponent } from "./components/map-layers-with-controls/layer/geographical-zones-layer.component";
import { MapLayersSelectorComponent } from "./components/map-layers-with-controls/map-layers-selector/map-layers-selector.component";
import { MapLayersWithControlsComponent } from "./components/map-layers-with-controls/map-layers-with-controls.component";
import { MissionViewSettingsComponent } from "./components/map-layers-with-controls/mission-view-settings/mission-view-settings.component";
import { GeographicalZonesInfoDetailsComponent } from "./components/map-zone-info/geographical-zones-info-details/geographical-zones-info-details.component";
import { GeographicalZonesInfoComponent } from "./components/map-zone-info/geographical-zones-info.component";
import { ObstaclesDtmLegendComponent } from "./components/obstacles-dtm-legend/obstacles-dtm-legend.component";
import { Polylines3DEditorComponent } from "./components/polylines3d-editor/polylines3d-editor.component";
import { PrismsEditorComponent } from "./components/prisms-editor/prisms-editor.component";
import { RiskLegendComponent } from "./components/risk-legend/risk-legend.component";
import { RouteViewLayerComponent } from "./components/route-view-layer/route-view-layer.component";
import { FlightPinComponent } from "./components/route-viewer/flight-pin/flight-pin.component";
import { HemsLayerComponent } from "./components/route-viewer/hems-layer.component";
import { RouteViewerComponent } from "./components/route-viewer/route-viewer.component";
import { TimeRangeSliderComponent } from "./components/weather/time-range-slider/time-range-slider.component";
import { WeatherConditionBoxComponent } from "./components/weather/weather-condition-box/weather-condition-box.component";
import { WeatherConditionsGridComponent } from "./components/weather/weather-conditions-grid/weather-conditions-grid.component";
import { WeatherComponent } from "./components/weather/weather.component";
import { CameraHelperService } from "./services/camera-helper.service";
import { CylindersEditorService } from "./services/entity-editors/cylinders-editor/cylinders-editor.service";
import { MapEntitiesEditorService } from "./services/entity-editors/map-entities-editor.service";
import { Polylines3DEditorService } from "./services/entity-editors/polylines3d-editor/polylines3d-editor.service";
import { PrismsEditorService } from "./services/entity-editors/prisms-editor/prisms-editor.service";
import { HeightHelperService } from "./services/height-helper.service";
import { CesiumPointerManagerDirective } from "./services/pointer-manager/cesium-pointer-manager.directive";
import { ExtractMapEntityPipe } from "./utils/extract-map-entity.pipe";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export const DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS = {
    selectionIndicator: false,
    timeline: false,
    infoBox: false,
    fullscreenButton: false,
    baseLayerPicker: false,
    animation: false,
    shouldAnimate: false,
    imageryProvider: false,
    skyBox: false,
    homeButton: false,
    geocoder: false,
    navigationHelpButton: false,
    terrainProvider: null,
    navigationInstructionsInitiallyVisible: false,
    mapMode2D: 0,
    mapProjection: new Cesium.WebMercatorProjection(),
    requestRenderMode: true,
    maximumRenderTimeChange: Infinity,
    sceneModePicker: false,
};

@NgModule({
    declarations: [
        CylindersEditorComponent,
        PrismsEditorComponent,
        Polylines3DEditorComponent,
        ExtractMapEntityPipe,
        CesiumPointerManagerDirective,
        RouteViewerComponent,
        MapControlsComponent,
        HemsLayerComponent,
        RouteViewLayerComponent,
        AzureMapsLayerComponent,
        MapLayersWithControlsComponent,
        GeographicalZonesLayerComponent,
        GeographicalZonesControlsComponent,
        GeographicalZonesFiltersComponent,
        GeographicalZonesSettingsComponent,
        FlightPinComponent,
        CoordinatesPasteAreaComponent,
        WeatherComponent,
        TimeRangeSliderComponent,
        WeatherConditionBoxComponent,
        WeatherConditionsGridComponent,
        MissionViewSettingsComponent,
        BufferAreasLayerComponent,
        MapActionsPanelComponent,
        ManualCoordinatesInputDialogComponent,
        GeographicalZonesInfoComponent,
        GeographicalZonesInfoDetailsComponent,
        ZoneLegendComponent,
        GlobalSpatialInfoSettingsComponent,
        LocalSpatialInfoForDtmSettingsComponent,
        CircularBoundaryLayerComponent,
        ManualWidthInputDialogComponent,
        MapLayersSelectorComponent,
        CheckinAreaLayerComponent,
        RiskLegendComponent,
        ObstaclesDtmLegendComponent,
        KmlImportModalComponent,
        KmlControlsComponent,
        CesiumCleanupOnDestroyDirective,
        MeasuresCesiumComponent,
    ],
    imports: [
        CommonModule,
        AngularCesiumModule.forRoot({
            // NOTE: this turns off @pansa/ngx-cesium patching cesium methods to fix https://github.com/CesiumGS/cesium/issues/1825
            // however causing errors with showing/hiding entities
            fixEntitiesShadows: false,
            customPipes: [],
        }),
        AngularCesiumWidgetsModule,
        LetModule,
        PushModule,
        SharedI18nModule,
        SharedUiModule,
        NgOptimizedImage,
        MatSlideToggleModule,
        MatInputModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatTabsModule,
        MatProgressBarModule,
        MatMenuModule,
        SharedToastModule.forRoot(),
        ToastContainerModule,
        MatDialogModule,
        MatTableModule,
        MatSelectModule,
        FormsModule,
    ],
    exports: [
        CylindersEditorComponent,
        PrismsEditorComponent,
        Polylines3DEditorComponent,
        AngularCesiumModule,
        AngularCesiumWidgetsModule,
        ExtractMapEntityPipe,
        RouteViewerComponent,
        CesiumPointerManagerDirective,
        MapControlsComponent,
        RouteViewLayerComponent,
        AzureMapsLayerComponent,
        MapLayersWithControlsComponent,
        GeographicalZonesLayerComponent,
        GeographicalZonesControlsComponent,
        HemsLayerComponent,
        FlightPinComponent,
        CoordinatesPasteAreaComponent,
        WeatherComponent,
        TimeRangeSliderComponent,
        WeatherConditionBoxComponent,
        WeatherConditionsGridComponent,
        BufferAreasLayerComponent,
        MapActionsPanelComponent,
        ManualCoordinatesInputDialogComponent,
        GeographicalZonesInfoDetailsComponent,
        ZoneLegendComponent,
        GlobalSpatialInfoSettingsComponent,
        LocalSpatialInfoForDtmSettingsComponent,
        CircularBoundaryLayerComponent,
        ManualWidthInputDialogComponent,
        CheckinAreaLayerComponent,
        RiskLegendComponent,
        ObstaclesDtmLegendComponent,
        KmlImportModalComponent,
        KmlControlsComponent,
        CesiumCleanupOnDestroyDirective,
        MeasuresCesiumComponent,
    ],
    providers: [
        HeightHelperService,
        MapEntitiesEditorService,
        CameraHelperService,
        ViewerConfiguration,
        CylindersEditorService,
        PrismsEditorService,
        Polylines3DEditorService,
        ExtractMapEntityPipe,
        CoordinateConverter,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmMapCesium",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class CesiumMapModule {}
