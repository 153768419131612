import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    forwardRef,
} from "@angular/core";
import {
    AbstractControl,
    AsyncValidatorFn,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    UntypedFormControl,
    ValidationErrors,
    Validator,
} from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { map, take } from "rxjs/operators";

interface TimeFieldComponentState {
    referenceDate: Date;
    minTime: Date | undefined | null;
    maxTime: Date | undefined | null;
    isClearable: boolean;
    isRequired: boolean;
    isUtcTime: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-time-field",
    templateUrl: "./time-field.component.html",
    styleUrls: ["./time-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimeFieldComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TimeFieldComponent),
            multi: true,
        },
    ],
})
export class TimeFieldComponent implements ControlValueAccessor, Validator, OnInit, AfterViewInit {
    public readonly timeFormControl = new UntypedFormControl("");
    public readonly isClearable$ = this.localStore.selectByKey("isClearable");
    public readonly isRequired$ = this.localStore.selectByKey("isRequired");
    public readonly isUtcTime$ = this.localStore.selectByKey("isUtcTime");

    @Input()
    public set minTime(value: Date | undefined | null) {
        this.localStore.patchState({ minTime: value });
        this.updateValidity();
    }

    @Input()
    public set maxTime(value: Date | undefined | null) {
        this.localStore.patchState({ maxTime: value });
        this.updateValidity();
    }

    /**
     * Reference date is used to create date object for comparisons of min/max time values.
     * If not provided current date will be used.
     */
    @Input()
    public set referenceDate(value: Date | undefined | null) {
        if (!value) {
            return;
        }

        this.localStore.patchState({ referenceDate: value });
        this.updateValidity();
    }

    @Input()
    public set isClearable(value: BooleanInput) {
        this.localStore.patchState({ isClearable: coerceBooleanProperty(value) });
    }

    @Input()
    public set required(value: BooleanInput) {
        this.localStore.patchState({ isRequired: coerceBooleanProperty(value) });
    }

    @Input()
    public set isUtcTime(value: BooleanInput) {
        this.localStore.patchState({ isUtcTime: coerceBooleanProperty(value) });
    }

    @Output() public readonly valueChange = new EventEmitter<Date | null>();

    private propagateChange: (value: Date | null) => void = FunctionUtils.noop;
    private onTouched = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(private readonly injector: Injector, private readonly localStore: LocalComponentStore<TimeFieldComponentState>) {
        this.localStore.setState({
            referenceDate: new Date(),
            minTime: undefined,
            maxTime: undefined,
            isClearable: false,
            isRequired: false,
            isUtcTime: false,
        });
    }

    public ngOnInit(): void {
        this.timeFormControl.setAsyncValidators(this.validateTime());
        this.watchTimeValueChanges();
    }

    public ngAfterViewInit() {
        const parentControl = this.injector.get(NgControl, null)?.control;
        parentControl?.statusChanges.pipe(untilDestroyed(this)).subscribe((status) => {
            this.timeFormControl.setErrors(status === "INVALID" ? { ...this.timeFormControl.errors, wrongTime: true } : null);
        });
    }

    private updateValidity(): void {
        if (!this.timeFormControl.disabled) {
            this.timeFormControl.updateValueAndValidity();
        }
    }

    private watchTimeValueChanges(): void {
        this.timeFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            const selectedDateTime = this.createDateFromTime(value, this.localStore.selectSnapshotByKey("isUtcTime")) ?? null;

            this.propagateChange(selectedDateTime);
            this.onValidationChange();
            this.valueChange.emit(selectedDateTime);
        });
    }

    private createDateFromTime(timeControlValue: string, isUtcTime: boolean): Date | undefined {
        const [hours, minutes] = timeControlValue.split(":");

        if (!hours || !minutes) {
            return;
        }

        const referenceDate = this.localStore.selectSnapshotByKey("referenceDate");

        if (isUtcTime) {
            referenceDate.setUTCHours(+hours, +minutes);
        } else {
            referenceDate.setHours(+hours, +minutes);
        }

        return referenceDate;
    }

    private createNewDateWithoutSeconds(date: Date, isUtcTime: boolean): Date {
        date = new Date(date);

        if (isUtcTime) {
            date.setUTCSeconds(0, 0);
        } else {
            date.setSeconds(0, 0);
        }

        return date;
    }

    private validateTime(): AsyncValidatorFn {
        return (timeControl: AbstractControl) =>
            combineLatest([
                this.localStore.selectByKey("minTime"),
                this.localStore.selectByKey("maxTime"),
                this.localStore.selectByKey("isUtcTime"),
            ]).pipe(
                map(([minTime, maxTime, isUtcTime]) => {
                    if (minTime) {
                        minTime = this.createNewDateWithoutSeconds(minTime, isUtcTime);
                    }

                    if (maxTime) {
                        maxTime = this.createNewDateWithoutSeconds(maxTime, isUtcTime);
                    }

                    const selectedDateTime = this.createDateFromTime(timeControl.value, isUtcTime);

                    if (!selectedDateTime) {
                        return null;
                    }

                    if (minTime && selectedDateTime < minTime) {
                        return { min: { min: minTime, actual: selectedDateTime } };
                    }

                    if (maxTime && selectedDateTime > maxTime) {
                        return { max: { max: maxTime, actual: selectedDateTime } };
                    }

                    return null;
                }),
                take(1),
                untilDestroyed(this)
            );
    }

    private formatNumberValue(value: number): string {
        return `${value}`.padStart(2, "0");
    }

    public registerOnChange(fn: (value: Date | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: Date | undefined): void {
        if (!value) {
            this.timeFormControl.setValue("");

            return;
        }

        this.referenceDate = value;
        const isUtcTime = this.localStore.selectSnapshotByKey("isUtcTime");

        const hours = isUtcTime ? value.getUTCHours() : value.getHours();
        const minutes = isUtcTime ? value.getUTCMinutes() : value.getMinutes();

        this.timeFormControl.setValue(`${this.formatNumberValue(hours)}:${this.formatNumberValue(minutes)}`);
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.timeFormControl.disable();
        } else {
            this.timeFormControl.enable();
        }
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        if (this.timeFormControl.invalid) {
            return this.timeFormControl.errors;
        }

        return null;
    }
}
