import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import {
    FlightZoneApplicationPurpose,
    FlightZoneCapabilities,
    FlightZoneExclusionsConstraints,
    FlightZoneSelectOption,
    RestrictionExclusions,
} from "@dtm-frontend/dss-shared-lib";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface ZoneRestrictionExclusionsStepComponentState {
    isProcessing: boolean;
    capabilities: FlightZoneCapabilities | undefined;
    constraints: FlightZoneExclusionsConstraints | undefined;
    exclusions: RestrictionExclusions | undefined;
    applicationPurpose: FlightZoneApplicationPurpose | undefined;
}

@Component({
    selector: "dss-client-lib-zone-restriction-exclusions-step[capabilities][constraints][exclusions][applicationPurpose]",
    templateUrl: "./zone-restriction-exclusions-step.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ZoneRestrictionExclusionsStepComponent {
    public readonly FlightZoneApplicationPurpose = FlightZoneApplicationPurpose;

    public readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    public readonly capabilities$ = this.localStore.selectByKey("capabilities");
    public readonly constraints$ = this.localStore.selectByKey("constraints");
    public readonly exclusions$ = this.localStore.selectByKey("exclusions");
    public readonly applicationPurpose$ = this.localStore.selectByKey("applicationPurpose");

    @Input() public set isProcessing(value: boolean) {
        this.localStore.patchState({ isProcessing: value });
    }
    @Input() public set capabilities(value: FlightZoneCapabilities) {
        this.localStore.patchState({ capabilities: value });
    }
    @Input() public set constraints(value: FlightZoneExclusionsConstraints | undefined) {
        this.localStore.patchState({ constraints: value });
    }
    @Input() public set exclusions(value: RestrictionExclusions | undefined) {
        if (value) {
            value = {
                ...value,
                courses: this.getCoursesMatchingCapabilities(value.courses),
            };
        }
        this.localStore.patchState({ exclusions: value });
    }
    @Input() public set applicationPurpose(value: FlightZoneApplicationPurpose | undefined) {
        this.localStore.patchState({ applicationPurpose: value });
    }

    @Output() public readonly restrictionExclusionsUpdate = new EventEmitter<RestrictionExclusions>();
    @Output() public readonly back = new EventEmitter<void>();

    constructor(private readonly localStore: LocalComponentStore<ZoneRestrictionExclusionsStepComponentState>) {
        this.localStore.setState({
            isProcessing: false,
            capabilities: undefined,
            constraints: undefined,
            exclusions: undefined,
            applicationPurpose: undefined,
        });
    }

    private getCoursesMatchingCapabilities(value: FlightZoneSelectOption<string>[]): FlightZoneSelectOption<string>[] | [] {
        const courseCapabilities = this.localStore.selectSnapshotByKey("capabilities")?.eLearningCourses ?? [];

        return courseCapabilities.filter(({ codeName: capabilitiesCourseCode }) =>
            value.some(({ codeName: valueCourseCode }) => capabilitiesCourseCode === valueCourseCode)
        );
    }
}
