/* eslint-disable no-console */
import * as Sentry from "@sentry/angular-ivy";
import { Extras, SeverityLevel } from "@sentry/types";

export interface CaptureContext {
    level?: SeverityLevel;
    extra?: Extras;
}

export class Logger {
    private static isConsoleCaptureEnabled = false;

    public static initialize(key: string): void {
        Sentry.init({
            dsn: key,
            environment: "unknown",
        });
    }

    public static enableConsoleCapture(): void {
        Logger.isConsoleCaptureEnabled = true;
    }

    public static captureMessage(message: string, context?: CaptureContext): void {
        if (Logger.isConsoleCaptureEnabled) {
            console.info(message);
        }

        Sentry.captureMessage(message, context);
    }

    public static captureException(error: Error | unknown, context?: CaptureContext): void {
        if (Logger.isConsoleCaptureEnabled) {
            console.error(error);
        }

        Sentry.captureException(error, context);
    }
}
