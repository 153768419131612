export interface VersionData {
    services: Service[];
}

export interface VersionDataError {
    type: VersionDataErrorType;
}

export enum VersionDataErrorType {
    GetVersionDataError = "GetVersionDataError",
}

export interface Service {
    serviceName: ServiceName;
    buildInfo: BuildInfo;
}

enum ServiceName {
    BffWebui = "bff-webui",
    MissionPlaner = "mission-planner",
    UserDirectory = "user-directory",
}

export interface BuildInfo {
    commitMessage: string;
    branch: string;
    commitId: string;
    commitTime: string;
    buildTime: string;
}
