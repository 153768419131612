<mat-card>
    <dtm-ui-card-header
        [isEditButtonVisible]="true"
        (edit)="tryResetPassword()"
        [buttonLabel]="'dtmUi.resetPassword.resetPasswordLabel' | transloco"
    >
        {{ "dtmUi.resetPassword.resetPasswordHeader" | transloco }}
    </dtm-ui-card-header>
    <span class="reset-password-description">
        {{ "dtmUi.resetPassword.resetPasswordDescription" | transloco }}
    </span>
</mat-card>
