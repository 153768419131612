import { HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpErrorResponseBody, SKIP_AUTHENTICATION_HTTP_INTERCEPTOR } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthActions } from "../state/auth.actions";

export const NOT_AUTHORIZED_ROUTE = "not-authorized";

@Injectable()
export class AuthenticationHttpInterceptor implements HttpInterceptor {
    constructor(private readonly store: Store, private readonly router: Router) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler) {
        if (request.context.has(SKIP_AUTHENTICATION_HTTP_INTERCEPTOR)) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponseBody) => {
                switch (error.status) {
                    case HttpStatusCode.Unauthorized:
                        this.store.dispatch(AuthActions.GoToNotAuthorizedPage);
                        break;
                    case HttpStatusCode.Forbidden:
                        this.router.navigate([NOT_AUTHORIZED_ROUTE]);
                        break;
                    default:
                }

                return throwError(() => error);
            })
        );
    }
}
