export enum UavClass {
    C0 = "C0",
    C1 = "C1",
    C2 = "C2",
    C3 = "C3",
    C4 = "C4",
    C5 = "C5",
    C6 = "C6",
}

export enum FailSafe {
    ReturnToHome = "RETURN_TO_HOME",
    ReturnToLaunch = "RETURN_TO_LAUNCH",
    AutoLand = "AUTO_LAND",
    Hover = "HOVER",
}
