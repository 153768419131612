// eslint-disable-next-line max-len
// NOTE: this is based on https://github.com/pansa-dev/ngx-cesium/blob/HEAD/projects/@pansa/ngx-cesium/src/lib/@pansa/ngx-cesium-widgets/services/entity-editors/polygons-editor/polygons-manager.service.ts#L7-L41

import { Injectable } from "@angular/core";
import { AcLayerComponent, Cartesian3 } from "@pansa/ngx-cesium";
import { EditablePrism } from "../../../models/prism/editable-prism";
import { PrismEditOptions } from "../../../models/prism/prism-edit-options";

@Injectable()
export class PrismsManagerService {
    private readonly prisms: Map<string, EditablePrism> = new Map<string, EditablePrism>();

    public createEditablePrism(
        id: string,
        editPrismsLayer: AcLayerComponent,
        editPointsLayer: AcLayerComponent,
        editPolygonWaypointLayer: AcLayerComponent,
        editHeightOutlinesLayer: AcLayerComponent,
        prismLabelsLayer: AcLayerComponent,
        prismOptions: PrismEditOptions,
        topHeight = 0,
        bottomHeight = 0,
        positions?: Cartesian3[]
    ) {
        const editablePrism = new EditablePrism(
            id,
            editPrismsLayer,
            editPointsLayer,
            editPolygonWaypointLayer,
            editHeightOutlinesLayer,
            prismLabelsLayer,
            prismOptions,
            topHeight,
            bottomHeight,
            positions
        );
        this.prisms.set(id, editablePrism);

        return editablePrism;
    }

    public get(id: string): EditablePrism | undefined {
        return this.prisms.get(id);
    }

    public clear() {
        this.prisms.forEach((prism) => prism.dispose());
        this.prisms.clear();
    }
}
