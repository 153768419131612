import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface RiskLevelsComponentState {
    areaShare: Partial<Record<RiskLevels, number>> | undefined;
}

export type RiskLevels = "LOWEST" | "LOW" | "MEDIUM" | "HIGH" | "HIGHEST" | "UNKNOWN";

@Component({
    selector: "dtm-ui-risk-levels",
    templateUrl: "./risk-levels.component.html",
    styleUrls: ["./risk-levels.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class RiskLevelsComponent {
    @Input() public set areaShare(value: Partial<Record<RiskLevels, number>> | undefined) {
        this.localStore.patchState({ areaShare: value });
    }

    protected readonly areaShare$ = this.localStore.selectByKey("areaShare");

    constructor(private readonly localStore: LocalComponentStore<RiskLevelsComponentState>) {
        this.localStore.setState({
            areaShare: undefined,
        });
    }
}
