import { Injectable } from "@angular/core";
import {
    ApplicationType,
    CylinderRestrictionArea,
    FlightZoneApiService,
    FlightZoneApplication,
    FlightZoneApplicationChatMessage,
    FlightZoneCapabilitiesState,
    FlightZoneError,
    FlightZoneRestrictionState,
    FlightZoneUtils,
    HeightReferences,
    HorizontalMeasureUnits,
    NotamData,
    PrismRestrictionArea,
    RestrictionAreaUnits,
    VerticalMeasureUnits,
} from "@dtm-frontend/dss-shared-lib";
import { MapUtils } from "@dtm-frontend/shared/map/cesium";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, finalize, tap } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { FlightZoneStateModel, GeometryUnits } from "../models/flight-zone.models";
import { FlightZoneListsState } from "./flight-zone-lists.state";
import { FlightZoneActions } from "./flight-zone.actions";

const defaultRestrictionAreaUnits: RestrictionAreaUnits = {
    radiusUnit: HorizontalMeasureUnits.NauticalMiles,
    lowerHeightUnit: VerticalMeasureUnits.Feet,
    lowerHeightReference: HeightReferences.AboveGroundLevel,
    upperHeightUnit: VerticalMeasureUnits.Feet,
    upperHeightReference: HeightReferences.AboveMeanSeaLevel,
};

const defaultState: FlightZoneStateModel = {
    error: undefined,
    isProcessing: false,
    flightZoneApplicationData: {
        basicDataForm: undefined,
        restrictionAreaGeometry: {
            areaValues: undefined,
            areaUnits: defaultRestrictionAreaUnits,
            editorType: undefined,
        },
        restrictionExclusions: undefined,
        analysisStatus: undefined,
        flightZoneId: undefined,
        status: undefined,
        note: undefined,
        location: undefined,
        anspCaseNumber: undefined,
        restrictionModification: undefined,
        applicationType: ApplicationType.RestrictionApplication,
        additionalReceivers: undefined,
        detailedDuration: undefined,
        airspaceClassification: undefined,
    },
    geometryUnits: {
        horizontalMeasureUnits: [HorizontalMeasureUnits.NauticalMiles, HorizontalMeasureUnits.Kilometers],
        verticalMeasureUnits: [VerticalMeasureUnits.Feet, VerticalMeasureUnits.Meters],
        heightReferences: [HeightReferences.AboveGroundLevel, HeightReferences.AboveMeanSeaLevel],
    },
    notamData: undefined,
    chatMessages: [],
};

@State<FlightZoneStateModel>({
    name: "flightZone",
    defaults: defaultState,
    children: [FlightZoneRestrictionState, FlightZoneListsState, FlightZoneCapabilitiesState],
})
@Injectable()
export class FlightZoneState {
    @Selector([FlightZoneCapabilitiesState.error])
    public static error(state: FlightZoneStateModel, capabilitiesError: FlightZoneError): FlightZoneError | undefined {
        return state.error || capabilitiesError;
    }

    @Selector([FlightZoneRestrictionState.isProcessing, FlightZoneListsState.isProcessing, FlightZoneCapabilitiesState.isProcessing])
    public static isProcessing(
        state: FlightZoneStateModel,
        isFlightZoneRestrictionStateProcessing: boolean,
        isFlightZoneListsStateProcessing: boolean,
        isFlightZoneCapabilitiesProcessing: boolean
    ): boolean {
        return (
            state.isProcessing ||
            isFlightZoneRestrictionStateProcessing ||
            isFlightZoneListsStateProcessing ||
            isFlightZoneCapabilitiesProcessing
        );
    }

    @Selector()
    public static applicationData(state: FlightZoneStateModel): FlightZoneApplication {
        return state.flightZoneApplicationData;
    }

    @Selector()
    public static flightZoneId(state: FlightZoneStateModel): string | undefined {
        return state.flightZoneApplicationData.flightZoneId;
    }

    @Selector()
    public static geometryUnits(state: FlightZoneStateModel): GeometryUnits {
        return state.geometryUnits;
    }

    @Selector()
    public static notamData(state: FlightZoneStateModel): NotamData | undefined {
        return state.notamData;
    }

    @Selector()
    public static chatMessages(state: FlightZoneStateModel): FlightZoneApplicationChatMessage[] {
        return state.chatMessages;
    }

    constructor(private readonly flightZoneApi: FlightZoneApiService) {
        if (flightZoneApi === undefined) {
            throw new Error("Initialize FlightZoneModule with .forRoot()");
        }
    }

    @Action(FlightZoneActions.GetApplicationData)
    public getFlightZoneApplicationData(context: StateContext<FlightZoneStateModel>, action: FlightZoneActions.GetApplicationData) {
        context.patchState({ isProcessing: true, flightZoneApplicationData: undefined });

        return this.flightZoneApi.getFlightZoneApplicationData(action.flightZoneId, action.applicationType, false).pipe(
            map((flightZoneApplicationData: FlightZoneApplication) => {
                context.patchState({
                    flightZoneApplicationData,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneActions.ClearApplicationData)
    public clearFlightZoneApplicationData(context: StateContext<FlightZoneStateModel>) {
        context.patchState({
            flightZoneApplicationData: {
                basicDataForm: undefined,
                restrictionAreaGeometry: {
                    areaValues: undefined,
                    areaUnits: defaultRestrictionAreaUnits,
                    editorType: undefined,
                },
                restrictionExclusions: undefined,
                analysisStatus: undefined,
                flightZoneId: undefined,
                status: undefined,
                note: undefined,
                location: undefined,
                anspCaseNumber: undefined,
                restrictionModification: undefined,
                applicationType: ApplicationType.RestrictionApplication,
                additionalReceivers: undefined,
                detailedDuration: undefined,
                airspaceClassification: undefined,
            },
            chatMessages: [],
            notamData: undefined,
        });
    }

    @Action(FlightZoneActions.UpdateBasicData)
    public updateFlightZoneBasicData(context: StateContext<FlightZoneStateModel>, action: FlightZoneActions.UpdateBasicData) {
        const { flightZoneApplicationData } = context.getState();

        context.patchState({
            flightZoneApplicationData: {
                ...flightZoneApplicationData,
                basicDataForm: { ...flightZoneApplicationData.basicDataForm, ...action.formData },
            },
        });
    }

    @Action(FlightZoneActions.UpdateActiveGeometryUnits)
    public updateActiveGeometryUnits(context: StateContext<FlightZoneStateModel>, action: FlightZoneActions.UpdateActiveGeometryUnits) {
        const restrictionAreaGeometry = { ...context.getState().flightZoneApplicationData.restrictionAreaGeometry };

        restrictionAreaGeometry.areaUnits = {
            ...defaultRestrictionAreaUnits,
            ...action.data,
        };

        context.patchState({
            flightZoneApplicationData: {
                ...context.getState().flightZoneApplicationData,
                restrictionAreaGeometry,
            },
        });
    }

    @Action(FlightZoneActions.UpdateActiveCylinderGeometryValues)
    public updateActiveGeometryCylinderValues(
        context: StateContext<FlightZoneStateModel>,
        action: FlightZoneActions.UpdateActiveCylinderGeometryValues
    ) {
        const currentRestrictionAreaGeometry = context.getState().flightZoneApplicationData
            .restrictionAreaGeometry as CylinderRestrictionArea;
        const centerCartographic = MapUtils.convertCartesian3ToSerializableCartographic(action.cylinder.center);

        context.patchState({
            flightZoneApplicationData: {
                ...context.getState().flightZoneApplicationData,
                restrictionAreaGeometry: {
                    ...currentRestrictionAreaGeometry,
                    areaValues: {
                        centerPointLongitude: centerCartographic.longitude,
                        centerPointLatitude: centerCartographic.latitude,
                        radius: FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(
                            action.cylinder.radius,
                            currentRestrictionAreaGeometry.areaUnits.radiusUnit,
                            1
                        ),
                        lowerHeight: FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(
                            action.cylinder.bottomHeight,
                            currentRestrictionAreaGeometry.areaUnits.lowerHeightUnit,
                            0
                        ),
                        upperHeight: FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(
                            action.cylinder.topHeight ?? 0,
                            currentRestrictionAreaGeometry.areaUnits.upperHeightUnit,
                            0
                        ),
                    },
                },
            },
        });
    }

    @Action(FlightZoneActions.UpdateActivePrismGeometryValues)
    public updateActivePrismGeometryValues(
        context: StateContext<FlightZoneStateModel>,
        action: FlightZoneActions.UpdateActivePrismGeometryValues
    ) {
        const currentRestrictionAreaGeometry = context.getState().flightZoneApplicationData.restrictionAreaGeometry as PrismRestrictionArea;
        const updatedPositions = action.prism.positions.map((position) => MapUtils.convertCartesian3ToSerializableCartographic(position));
        const updatedLowerHeight = FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(
            action.prism.bottomHeight,
            currentRestrictionAreaGeometry.areaUnits.lowerHeightUnit,
            0
        );
        const updatedUpperHeight = FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(
            action.prism.topHeight ?? 0,
            currentRestrictionAreaGeometry.areaUnits.upperHeightUnit,
            0
        );

        context.patchState({
            flightZoneApplicationData: {
                ...context.getState().flightZoneApplicationData,
                restrictionAreaGeometry: {
                    ...currentRestrictionAreaGeometry,
                    areaValues: {
                        positions: updatedPositions,
                        lowerHeight: updatedLowerHeight,
                        upperHeight: updatedUpperHeight,
                    },
                },
            },
        });
    }

    @Action(FlightZoneActions.UpdateRestrictionExclusionsData)
    public updateFlightZoneRestrictionExclusionsData(
        context: StateContext<FlightZoneStateModel>,
        action: FlightZoneActions.UpdateRestrictionExclusionsData
    ) {
        context.patchState({
            flightZoneApplicationData: {
                ...context.getState().flightZoneApplicationData,
                restrictionExclusions: action.data,
            },
        });
    }

    @Action(FlightZoneActions.SaveApplicationDraft)
    public saveFlightZoneApplicationDraft(context: StateContext<FlightZoneStateModel>, action: FlightZoneActions.SaveApplicationDraft) {
        const state = context.getState();
        const version = state.flightZoneApplicationData.analysisStatus?.version;

        const flightZoneApplicationDraft$ = action.flightZoneId
            ? this.flightZoneApi.updateFlightZoneApplicationDraft(state.flightZoneApplicationData, action.flightZoneId, version)
            : this.flightZoneApi.saveNewFlightZoneApplicationDraft(state.flightZoneApplicationData);

        context.patchState({
            isProcessing: true,
            flightZoneApplicationData: {
                ...state.flightZoneApplicationData,
                analysisStatus: undefined,
            },
        });

        return flightZoneApplicationDraft$.pipe(
            map((flightZoneApplication) => {
                context.patchState({
                    flightZoneApplicationData: {
                        ...state.flightZoneApplicationData,
                        analysisStatus: flightZoneApplication.analysisStatus,
                        flightZoneId: flightZoneApplication.flightZoneId,
                    },
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneActions.DeleteApplication)
    public deleteApplication(context: StateContext<FlightZoneStateModel>, action: FlightZoneActions.DeleteApplication) {
        context.patchState({ isProcessing: true });

        return this.flightZoneApi.deleteFlightZoneApplication(action.flightZoneId).pipe(
            map(() => {
                context.patchState({
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneActions.ConfirmCorrections)
    public confirmCorrections(context: StateContext<FlightZoneStateModel>, action: FlightZoneActions.ConfirmCorrections) {
        const version = context.getState().flightZoneApplicationData.analysisStatus?.version;

        context.patchState({ isProcessing: true, error: undefined });

        return this.flightZoneApi.confirmCorrections(action.flightZoneId, version).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(FlightZoneActions.SendApplication)
    public sendFlightZoneApplication(context: StateContext<FlightZoneStateModel>, action: FlightZoneActions.SendApplication) {
        const version = context.getState().flightZoneApplicationData.analysisStatus?.version;
        const sendMethod$ = action.isAcceptanceRequired
            ? this.flightZoneApi.sendInstitutionAcceptanceRequest(action.flightZoneId, version)
            : this.flightZoneApi.sendApplicationToAnsp(action.flightZoneId, version);

        context.patchState({ isProcessing: true });

        return sendMethod$.pipe(
            map(() => {
                context.patchState({
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneActions.RejectApplication)
    public rejectFlightZoneApplication(context: StateContext<FlightZoneStateModel>, action: FlightZoneActions.RejectApplication) {
        const version = context.getState().flightZoneApplicationData.analysisStatus?.version;

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.rejectFlightZoneApplication(action.flightZoneId, version).pipe(
            map(() => {
                context.patchState({
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneActions.GetNotamData)
    public getNotam(context: StateContext<FlightZoneStateModel>, action: FlightZoneActions.GetNotamData) {
        context.patchState({ isProcessing: true, notamData: undefined });

        return this.flightZoneApi.getNotamsNotice(action.flightZoneId).pipe(
            tap((notamData) => {
                context.patchState({
                    notamData,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneActions.GetChatMessagesByFlightZoneId)
    public getChatMessagesByFlightZoneId(
        context: StateContext<FlightZoneStateModel>,
        action: FlightZoneActions.GetChatMessagesByFlightZoneId
    ) {
        context.patchState({ isProcessing: true, chatMessages: [] });

        return this.flightZoneApi.getChatMessages(action.flightZoneId).pipe(
            tap((chatMessages) => {
                context.patchState({
                    chatMessages,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneActions.PostChatMessage)
    public postChatMessage(context: StateContext<FlightZoneStateModel>, action: FlightZoneActions.PostChatMessage) {
        const applicationType = context.getState().flightZoneApplicationData.applicationType;

        if (!applicationType) {
            return;
        }

        context.patchState({ isProcessing: true });

        return this.flightZoneApi.postChatMessage(action.flightZoneId, applicationType, action.comment).pipe(
            tap((newMessage) => {
                context.patchState({
                    chatMessages: [...context.getState().chatMessages, newMessage],
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneActions.ChangeZoneDuration)
    public changeZoneDuration(context: StateContext<FlightZoneStateModel>, action: FlightZoneActions.ChangeZoneDuration) {
        context.patchState({ isProcessing: true, error: undefined });

        const changeZoneDuration$ = action.zoneDurationData.shouldCancelRestriction
            ? this.flightZoneApi.cancelZoneDuration(action.zoneDurationData.id)
            : this.flightZoneApi.changeZoneDuration(
                  action.zoneDurationData.id,
                  action.zoneDurationData.startAt,
                  action.zoneDurationData.endAt
              );

        return changeZoneDuration$.pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isProcessing: false,
                })
            )
        );
    }

    @Action(FlightZoneActions.EditRestrictionModification)
    public editRestrictionModification(context: StateContext<FlightZoneStateModel>, action: FlightZoneActions.EditRestrictionModification) {
        const state = context.getState();
        const version = state.flightZoneApplicationData.analysisStatus?.version ?? 0;

        context.patchState({ isProcessing: true, error: undefined });

        return this.flightZoneApi
            .editRestrictionModification(
                action.zoneDurationData.id,
                action.zoneDurationData.startAt,
                action.zoneDurationData.endAt,
                version
            )
            .pipe(
                map((flightZoneApplicationData) => {
                    context.patchState({
                        flightZoneApplicationData,
                        error: undefined,
                        isProcessing: false,
                    });
                }),
                catchError((error) => {
                    context.patchState({
                        error,
                        isProcessing: false,
                    });

                    return EMPTY;
                })
            );
    }
}
