<ng-container *ngrxLet="isDesktop$ as isDesktop">
    <ng-container *ngIf="isDesktop">
        <ng-content select="[desktopTable]"> </ng-content>
    </ng-container>

    <ng-container *ngIf="!isDesktop">
        <ng-container *ngIf="data$ | ngrxPush as data">
            <ng-container *ngIf="data.length; else noDataTemplate">
                <div *ngFor="let row of data" class="mobile-table-row">
                    <ng-container
                        [ngTemplateOutlet]="mobileTableTemplate.templateRef"
                        [ngTemplateOutletContext]="{ $implicit: row }"
                    ></ng-container>
                </div>
                <ng-content select="[mobileTablePagination]"></ng-content>
            </ng-container>
            <ng-template #noDataTemplate>
                <ng-content select="[mobileTableNoData]"></ng-content>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-container>
