import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

/* eslint-disable no-magic-numbers */
enum Priority {
    Warning = 1,
    Error = 2,
    Info = 3,
}
/* eslint-enable */

interface WarningData {
    priority: Priority;
    tooltip: string;
}

const GRC_HIGH_NUMBER = 8;
const GRC_5 = 5;
const GRC_6 = 6;
const GRC_7 = 7;
const ARC_3 = 3;
const ARC_4 = 4;
const SAIL_4 = 4;
const SAIL_5 = 5;
const SAIL_6 = 6;

const GRC_ERRORS: Record<number, WarningData> = {
    [GRC_5]: { priority: Priority.Warning, tooltip: "dtmSharedMission.soraIssuesWarning.GRC.firstText" },
    [GRC_6]: { priority: Priority.Error, tooltip: "dtmSharedMission.soraIssuesWarning.GRC.secondText" },
    [GRC_7]: { priority: Priority.Error, tooltip: "dtmSharedMission.soraIssuesWarning.GRC.secondText" },
    [GRC_HIGH_NUMBER]: { priority: Priority.Info, tooltip: "dtmSharedMission.soraIssuesWarning.GRC.thirdText" },
};

const ARC_ERRORS: Record<number, WarningData> = {
    [ARC_3]: { priority: Priority.Warning, tooltip: "dtmSharedMission.soraIssuesWarning.ARC.firstText" },
    [ARC_4]: { priority: Priority.Error, tooltip: "dtmSharedMission.soraIssuesWarning.ARC.secondText" },
};

const SAIL_ERRORS: Record<number | string, WarningData> = {
    [SAIL_4]: { priority: Priority.Warning, tooltip: "dtmSharedMission.soraIssuesWarning.SAIL.firstText" },
    [SAIL_5]: { priority: Priority.Error, tooltip: "dtmSharedMission.soraIssuesWarning.SAIL.secondText" },
    [SAIL_6]: { priority: Priority.Error, tooltip: "dtmSharedMission.soraIssuesWarning.SAIL.secondText" },
    sailCertifiedCategory: { priority: Priority.Info, tooltip: "dtmSharedMission.soraIssuesWarning.SAIL.thirdText" },
};

interface SoraIssuesWarningComponentState {
    grc: number | undefined;
    arc: number | undefined;
    sail: number | undefined;
    isCertifiedCategory: boolean;
}

@Component({
    selector: "dtm-mission-sora-issues-warning",
    templateUrl: "./sora-issues-warning.component.html",
    styleUrls: ["./sora-issues-warning.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SoraIssuesWarningComponent {
    @Input() public set grc(value: number | undefined) {
        this.localStore.patchState({ grc: value });
    }

    @Input() public set arc(value: number | undefined) {
        this.localStore.patchState({ arc: value });
    }

    @Input() public set sail(value: number | undefined) {
        this.localStore.patchState({ sail: value });
    }

    @Input() public set isCertifiedCategory(value: BooleanInput) {
        this.localStore.patchState({ isCertifiedCategory: coerceBooleanProperty(value) });
    }

    protected readonly error$ = combineLatest([
        this.localStore.selectByKey("grc"),
        this.localStore.selectByKey("arc"),
        this.localStore.selectByKey("sail"),
        this.localStore.selectByKey("isCertifiedCategory"),
    ]).pipe(map(([grc, arc, sail, isCertifiedCategory]) => this.getError(grc, arc, sail, isCertifiedCategory)));

    constructor(private readonly localStore: LocalComponentStore<SoraIssuesWarningComponentState>) {
        this.localStore.setState({
            grc: undefined,
            arc: undefined,
            sail: undefined,
            isCertifiedCategory: false,
        });
    }

    private getError(
        grc: number | undefined,
        arc: number | undefined,
        sail: number | undefined,
        isCertifiedCategory: boolean
    ): { color: string; tooltip: string } | undefined {
        const error = [this.getGrcError(grc), this.getArcError(arc), this.getSailError(sail, isCertifiedCategory)]
            .filter((data) => data?.priority)
            .sort((left, right) => (right?.priority ?? 0) - (left?.priority ?? 0))?.[0];

        if (!error) {
            return undefined;
        }

        return {
            tooltip: error.tooltip,
            color: error.priority === Priority.Warning ? "warning" : error.priority === Priority.Error ? "error" : "info",
        };
    }

    private getGrcError(grc: number | undefined) {
        if (grc && grc >= GRC_HIGH_NUMBER) {
            return GRC_ERRORS[GRC_HIGH_NUMBER];
        }

        return grc ? GRC_ERRORS[grc] : undefined;
    }

    private getArcError(arc: number | undefined) {
        return arc ? ARC_ERRORS[arc] : undefined;
    }

    private getSailError(sail: number | undefined, isCertifiedCategory: boolean) {
        if (isCertifiedCategory) {
            return SAIL_ERRORS.sailCertifiedCategory;
        }

        return sail ? SAIL_ERRORS[sail] : undefined;
    }
}
