<ng-container *ngrxLet="{ areaShare: areaShare$ } as vm">
    <ng-container
        [ngTemplateOutlet]="shareInfoTemplate"
        [ngTemplateOutletContext]="{
            area: 'highest',
            text: 'dtmUi.riskLevels.riskHighest.textLabel' | transloco,
            tooltipText: 'dtmUi.riskLevels.riskHighest.tooltipText' | transloco,
            value: vm.areaShare?.HIGHEST
        }"
    ></ng-container>

    <ng-container
        [ngTemplateOutlet]="shareInfoTemplate"
        [ngTemplateOutletContext]="{
            area: 'high',
            text: 'dtmUi.riskLevels.riskHigh.textLabel' | transloco,
            tooltipText: 'dtmUi.riskLevels.riskHigh.tooltipText' | transloco,
            value: vm.areaShare?.HIGH
        }"
    ></ng-container>

    <ng-container
        [ngTemplateOutlet]="shareInfoTemplate"
        [ngTemplateOutletContext]="{
            area: 'medium',
            text: 'dtmUi.riskLevels.riskMedium.textLabel' | transloco,
            tooltipText: 'dtmUi.riskLevels.riskMedium.tooltipText' | transloco,
            value: vm.areaShare?.MEDIUM
        }"
    ></ng-container>

    <ng-container
        [ngTemplateOutlet]="shareInfoTemplate"
        [ngTemplateOutletContext]="{
            area: 'low',
            text: 'dtmUi.riskLevels.riskLow.textLabel' | transloco,
            tooltipText: 'dtmUi.riskLevels.riskLow.tooltipText' | transloco,
            value: vm.areaShare?.LOW
        }"
    ></ng-container>

    <ng-container
        [ngTemplateOutlet]="shareInfoTemplate"
        [ngTemplateOutletContext]="{
            area: 'lowest',
            text: 'dtmUi.riskLevels.riskLowest.textLabel' | transloco,
            tooltipText: 'dtmUi.riskLevels.riskLowest.tooltipText' | transloco,
            value: vm.areaShare?.LOWEST
        }"
    ></ng-container>
</ng-container>
<ng-template #shareInfoTemplate let-area="area" let-text="text" let-value="value" let-tooltipText="tooltipText">
    <div class="area-levels">
        <div class="risk-level" [ngClass]="area"></div>
        <span class="share-text"> {{ text }}</span>
        <span class="share-value" *ngIf="value !== undefined && value !== null && value >= 0"> &approx;{{ value | percent }}</span>
        <dtm-ui-popover>
            <div class="tooltip-text" [innerHTML]="tooltipText"></div>
        </dtm-ui-popover>
    </div>
</ng-template>
