import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { DEFAULT_PHONE_COUNTRY_CODE, FunctionUtils, LocalComponentStore, VERIFICATION_CODE_MASK } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { PhoneNumber } from "../../models/phone-number.models";
import { PhoneNumberFormValues } from "../../models/user-contact.models";
import { isNotSameValueValidator } from "../../validators/is-value-differ.validator";
import { requiredValidForSmsPhoneNumberValidator } from "../phone-number-field/phone-number-field.component";

interface EditPhoneNumberFormComponentState {
    isRequestedPhoneNumberChange: boolean;
    phoneNumber: PhoneNumber | undefined;
    isVerificationRequired: boolean;
    phoneNumberChangeDescription: string | undefined;
    verificationCodeMask: string | undefined;
    shouldCheckIfPhoneNumberSameValue: boolean;
}

interface EditPhoneNumberForm {
    phone: FormControl<PhoneNumber>;
    verificationCode: FormControl<string>;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-edit-phone-number-form[currentPhoneNumber][isRequestedPhoneChange]",
    templateUrl: "./edit-phone-number-form.component.html",
    styleUrls: ["./edit-phone-number-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EditPhoneNumberFormComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EditPhoneNumberFormComponent), multi: true },
    ],
})
export class EditPhoneNumberFormComponent implements ControlValueAccessor, Validator {
    @Input() public set currentPhoneNumber(value: PhoneNumber | undefined) {
        this.localStore.patchState({ phoneNumber: value });
        this.setPhoneNumberValidators();
    }
    @Input() public set isRequestedPhoneChange(value: BooleanInput) {
        if (!value) {
            this.phoneNumberChangeFormGroup.reset();
        }

        this.localStore.patchState({ isRequestedPhoneNumberChange: coerceBooleanProperty(value) });
    }
    @Input() public set hasPhoneNumberConflictError(value: BooleanInput) {
        if (value) {
            this.phoneNumberChangeFormGroup.controls.phone.setErrors({
                phoneNumberConflict: true,
            });
        }
    }
    @Input() public set tooManySmsCodeVerificationRequestDate(value: Date | undefined) {
        const phoneControl = this.phoneNumberChangeFormGroup.controls.phone;

        if (value) {
            phoneControl.setErrors({
                tooManyRequestErrorDate: value,
            });
        } else {
            delete phoneControl.errors?.tooManyRequestErrorDate;
            if (phoneControl?.errors && !Object.values(phoneControl.errors)?.length) {
                phoneControl.setErrors(null);
            }
        }
    }
    @Input() public set shouldCheckIfPhoneNumberSameValue(value: BooleanInput) {
        this.localStore.patchState({ shouldCheckIfPhoneNumberSameValue: coerceBooleanProperty(value) });
        this.setPhoneNumberValidators();
    }
    @Input() public set isVerificationRequired(value: BooleanInput) {
        this.localStore.patchState({ isVerificationRequired: coerceBooleanProperty(value) });
    }
    @Input() public set phoneNumberChangeDescription(value: string | undefined) {
        this.localStore.patchState({ phoneNumberChangeDescription: value });
    }
    @Input() public set verificationCodeMask(value: string | undefined) {
        this.localStore.patchState({ verificationCodeMask: value });
    }

    @Output() public resendVerificationCode = new EventEmitter<void>();

    protected readonly phoneNumber$ = this.localStore.selectByKey("phoneNumber");
    protected readonly isRequestedPhoneNumberChange$ = this.localStore.selectByKey("isRequestedPhoneNumberChange");
    protected readonly isVerificationRequired$ = this.localStore.selectByKey("isVerificationRequired");
    protected readonly phoneNumberChangeDescription$ = this.localStore.selectByKey("phoneNumberChangeDescription");
    protected readonly verificationCodeMask$ = this.localStore.selectByKey("verificationCodeMask");
    protected readonly phoneNumberChangeFormGroup = new FormGroup<EditPhoneNumberForm>({
        phone: new FormControl(
            { number: "", countryCode: DEFAULT_PHONE_COUNTRY_CODE },
            { validators: requiredValidForSmsPhoneNumberValidator, nonNullable: true }
        ),
        verificationCode: new FormControl("", { validators: Validators.required, nonNullable: true }),
    });

    private propagateChange: (value: PhoneNumberFormValues) => void = FunctionUtils.noop;
    private propagateTouch = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<EditPhoneNumberFormComponentState>) {
        this.localStore.setState({
            phoneNumber: undefined,
            isRequestedPhoneNumberChange: false,
            isVerificationRequired: true,
            phoneNumberChangeDescription: undefined,
            verificationCodeMask: VERIFICATION_CODE_MASK,
            shouldCheckIfPhoneNumberSameValue: true,
        });

        this.phoneNumberChangeFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            const phoneControl = this.phoneNumberChangeFormGroup.controls.phone;
            const phone: PhoneNumber = phoneControl.valid ? phoneControl.value : { number: "", countryCode: DEFAULT_PHONE_COUNTRY_CODE };

            const verificationCodeControl = this.phoneNumberChangeFormGroup.controls.verificationCode;
            const verificationCode = verificationCodeControl.valid ? verificationCodeControl.value : "";

            this.propagateChange({ phone, verificationCode });
        });
    }

    public registerOnChange(fn: (value: PhoneNumberFormValues) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: PhoneNumberFormValues): void {
        if (value) {
            this.phoneNumberChangeFormGroup.reset({
                phone: value.phone,
                verificationCode: value.verificationCode,
            });
        } else {
            this.phoneNumberChangeFormGroup.reset();
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.phoneNumberChangeFormGroup.disable();

            return;
        }
        this.phoneNumberChangeFormGroup.enable();
    }

    public validate(): ValidationErrors | null {
        return this.phoneNumberChangeFormGroup.invalid ? this.phoneNumberChangeFormGroup.errors : null;
    }

    protected getRateLimitDateTimeLeft(date: Date | undefined): number | undefined {
        if (!date) {
            return;
        }

        return Math.round(Math.max(date.getTime() - Date.now(), 0) / 1000);
    }

    private setPhoneNumberValidators(): void {
        const validators = [requiredValidForSmsPhoneNumberValidator as ValidatorFn];

        if (this.localStore.selectSnapshotByKey("shouldCheckIfPhoneNumberSameValue")) {
            validators.push(isNotSameValueValidator(this.localStore.selectSnapshotByKey("phoneNumber")?.number, "number"));
        }
        this.phoneNumberChangeFormGroup.controls.phone.setValidators(validators);
    }
}
