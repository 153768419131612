// eslint-disable-next-line max-len
// NOTE: this is based on https://github.com/pansa-dev/ngx-cesium/blob/HEAD/projects/@pansa/ngx-cesium/src/lib/@pansa/ngx-cesium-widgets/components/circles-editor/circles-editor.component.ts

import { ChangeDetectionStrategy, Component, Input, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
    AcLayerComponent,
    AcNotification,
    CameraService,
    CesiumService,
    CoordinateConverter,
    EditActions,
    EditModes,
    MapEventsManagerService,
} from "@pansa/ngx-cesium";
import { Subject } from "rxjs";
import { CylinderEditUpdate } from "../../models/cylinder/cylinder-edit-update";
import { CylinderEditPoint, CylinderLabel, EditableCylinder } from "../../models/cylinder/editable-cylinder";
import { CylindersEditorService } from "../../services/entity-editors/cylinders-editor/cylinders-editor.service";
import { CylindersManagerService } from "../../services/entity-editors/cylinders-editor/cylinders-manager.service";
import { HeightHelperService, ShapeDragActions } from "../../services/height-helper.service";
import { CesiumPointerManagerService } from "../../services/pointer-manager/cesium-pointer-manager.service";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface CylindersEditorComponentState {
    isShown: boolean;
    cylinderLabelsTemplate: TemplateRef<unknown> | null;
}

@UntilDestroy()
@Component({
    selector: "dtm-map-cylinders-editor[show]",
    templateUrl: "./cylinders-editor.component.html",
    providers: [CylindersManagerService, HeightHelperService, LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CylindersEditorComponent implements OnDestroy {
    private editLabelsRenderFunctions = new Map<string, (update: CylinderEditUpdate, labels: CylinderLabel[]) => CylinderLabel[]>();

    protected Cesium = Cesium;
    protected editPoints$ = new Subject<AcNotification>();
    protected editCylinders$ = new Subject<AcNotification>();
    protected editHeightOutlines$ = new Subject<AcNotification>();
    protected cylinderLabels$ = new Subject<AcNotification>();
    protected cylinderCentralIcons$ = new Subject<AcNotification>();

    protected readonly isShown$ = this.localStore.selectByKey("isShown");
    protected readonly cylinderLabelsTemplate$ = this.localStore.selectByKey("cylinderLabelsTemplate");

    @ViewChild("editCylindersLayer") private editCylindersLayer!: AcLayerComponent;
    @ViewChild("editPointsLayer") private editPointsLayer!: AcLayerComponent;
    @ViewChild("cylinderLabelsLayer") private cylinderLabelsLayer!: AcLayerComponent;
    @ViewChild("editHeightOutlinesLayer") private editHeightOutlinesLayer!: AcLayerComponent;

    @Input() public set show(value: boolean) {
        this.localStore.patchState({ isShown: value });
    }
    @Input()
    public set labelsTemplate(value: TemplateRef<unknown> | null) {
        this.localStore.patchState({ cylinderLabelsTemplate: value });
    }

    constructor(
        private readonly cylindersEditor: CylindersEditorService,
        private readonly coordinateConverter: CoordinateConverter,
        private readonly mapEventsManager: MapEventsManagerService,
        private readonly cameraService: CameraService,
        private readonly cylindersManager: CylindersManagerService,
        private readonly cesiumService: CesiumService,
        private readonly cesiumPointerManager: CesiumPointerManagerService,
        private readonly localStore: LocalComponentStore<CylindersEditorComponentState>,
        heightHelperService: HeightHelperService
    ) {
        this.cylindersEditor.init(
            this.mapEventsManager,
            this.coordinateConverter,
            this.cameraService,
            this.cylindersManager,
            this.cesiumService,
            this.cesiumPointerManager,
            heightHelperService
        );
        this.startListeningToEditorUpdates();
        this.localStore.setState({
            isShown: true,
            cylinderLabelsTemplate: null,
        });
    }

    private startListeningToEditorUpdates() {
        this.cylindersEditor
            .onUpdate()
            .pipe(untilDestroyed(this))
            .subscribe((update) => {
                if (update.editMode === EditModes.CREATE || update.editMode === EditModes.CREATE_OR_EDIT) {
                    this.handleCreateUpdates(update);
                } else if (update.editMode === EditModes.EDIT) {
                    this.cylindersManager.get(update.id)?.markAsDynamicEntityWhileUpdate();
                    this.handleEditUpdates(update);
                }
            });
    }

    private getLabelId(cylinder: EditableCylinder, label: CylinderLabel): string {
        return `${cylinder.getId()}_${label.id}`;
    }

    private getLabelIdsForCylinder(cylinder: EditableCylinder, labels: string[]): Set<string> {
        return new Set(labels.filter((label) => label.startsWith(cylinder.getId())));
    }

    public renderEditLabels(cylinder: EditableCylinder, update: CylinderEditUpdate, labels?: CylinderLabel[]) {
        update.center = cylinder.getCenter();
        update.radiusPoint = cylinder.radiusPoint?.getPosition();
        update.radius = cylinder.getRadius();
        update.topHeight = cylinder.getTopHeight();
        update.bottomHeight = cylinder.getBottomHeight();

        if (labels) {
            cylinder.labels = labels;
            this.synchronizeLabels(cylinder);

            return;
        }

        const editLabelsRenderFn = this.editLabelsRenderFunctions.get(cylinder.getId());

        if (!editLabelsRenderFn) {
            return;
        }

        cylinder.labels = editLabelsRenderFn(update, cylinder.labels);
        this.synchronizeLabels(cylinder);
    }

    private synchronizeLabels(cylinder: EditableCylinder) {
        const unusedLabelIds = this.getLabelIdsForCylinder(cylinder, Array.from(this.cylinderLabelsLayer.getStore().keys()));

        cylinder.labels.forEach((label) => {
            const labelId = this.getLabelId(cylinder, label);

            this.cylinderLabelsLayer.update(label, labelId);
            unusedLabelIds.delete(labelId);
        });

        unusedLabelIds.forEach((labelId) => {
            this.cylinderLabelsLayer.remove(labelId);
        });
    }

    public removeEditLabels(cylinder: EditableCylinder) {
        cylinder.labels.forEach((label) => {
            this.cylinderLabelsLayer.remove(this.getLabelId(cylinder, label));
        });
        cylinder.labels = [];
        this.editLabelsRenderFunctions.delete(cylinder.getId());
    }

    public handleCreateUpdates(update: CylinderEditUpdate) {
        switch (update.editAction) {
            case EditActions.INIT: {
                if (update.cylinderOptions) {
                    const cylinder = this.cylindersManager.createEditableCylinder(
                        update.id,
                        this.editCylindersLayer,
                        this.editPointsLayer,
                        this.editHeightOutlinesLayer,
                        this.cylinderLabelsLayer,
                        update.cylinderOptions
                    );
                    this.renderEditLabels(cylinder, update);
                }
                break;
            }
            case EditActions.MOUSE_MOVE: {
                const cylinder = this.cylindersManager.get(update.id);

                if (cylinder && update.radiusPoint) {
                    cylinder.moveRadiusPoint(update.radiusPoint);
                    this.renderEditLabels(cylinder, update);
                }

                break;
            }
            case EditActions.ADD_POINT: {
                const cylinder = this.cylindersManager.get(update.id);
                if (cylinder && update.center) {
                    cylinder.addPoint(update.center);
                    this.renderEditLabels(cylinder, update);
                }
                break;
            }
            case EditActions.DONE: {
                const cylinder = this.cylindersManager.get(update.id);
                if (cylinder) {
                    cylinder.finishCreation();
                    this.renderEditLabels(cylinder, update);
                }
                break;
            }
            case EditActions.DISPOSE: {
                const cylinder = this.cylindersManager.get(update.id);
                if (cylinder) {
                    this.removeEditLabels(cylinder);
                    this.cylindersManager.dispose(update.id);
                }
                break;
            }
            case EditActions.SET_EDIT_LABELS_RENDER_CALLBACK: {
                const cylinder = this.cylindersManager.get(update.id);
                if (cylinder && update.labelsRenderFn) {
                    this.editLabelsRenderFunctions.set(cylinder.getId(), update.labelsRenderFn);
                    this.renderEditLabels(cylinder, update);
                }
                break;
            }
            case EditActions.UPDATE_EDIT_LABELS: {
                const cylinder = this.cylindersManager.get(update.id);
                if (cylinder) {
                    this.renderEditLabels(cylinder, update, update.updateLabels);
                }
                break;
            }
            case EditActions.SET_MANUALLY: {
                const cylinder = this.cylindersManager.get(update.id);
                if (cylinder) {
                    this.renderEditLabels(cylinder, update, update.updateLabels);
                }
                break;
            }
            default: {
                return;
            }
        }
    }

    public handleEditUpdates(update: CylinderEditUpdate) {
        switch (update.editAction) {
            case EditActions.INIT: {
                if (update.cylinderOptions && update.center && update.radiusPoint) {
                    const cylinder = this.cylindersManager.createEditableCylinder(
                        update.id,
                        this.editCylindersLayer,
                        this.editPointsLayer,
                        this.editHeightOutlinesLayer,
                        this.cylinderLabelsLayer,
                        update.cylinderOptions
                    );
                    cylinder.setManually(update.center.clone(), update.radiusPoint.clone(), update.topHeight, update.bottomHeight);
                    this.renderEditLabels(cylinder, update);
                }
                break;
            }
            case EditActions.DRAG_POINT_FINISH:
            case EditActions.DRAG_POINT: {
                const cylinder = this.cylindersManager.get(update.id);
                if (cylinder && cylinder.enableEdit && update.endDragPosition) {
                    cylinder.moveRadiusPoint(update.endDragPosition);
                    this.renderEditLabels(cylinder, update);
                }
                break;
            }
            case EditActions.DRAG_SHAPE_FINISH:
            case EditActions.DRAG_SHAPE: {
                const cylinder = this.cylindersManager.get(update.id);
                if (!cylinder || !cylinder.enableEdit || update.shapeDragAction === undefined) {
                    break;
                }

                if (update.editAction === EditActions.DRAG_SHAPE_FINISH && update.shapeDragAction === ShapeDragActions.MoveEntity) {
                    cylinder.endMoveShape();
                    this.renderEditLabels(cylinder, update);
                    break;
                }

                switch (update.shapeDragAction) {
                    case ShapeDragActions.MoveEntity:
                        if (update.startDragPosition && update.endDragPosition) {
                            cylinder.moveCylinder(update.startDragPosition, update.endDragPosition);
                        }
                        break;

                    case ShapeDragActions.ChangeBottomHeight:
                        if (update.bottomHeight !== undefined) {
                            cylinder.moveBottomHeight(update.bottomHeight);
                        }
                        break;

                    case ShapeDragActions.ChangeTopHeight:
                        if (update.topHeight !== undefined) {
                            cylinder.moveTopHeight(update.topHeight);
                        }
                        break;
                }

                this.renderEditLabels(cylinder, update);
                break;
            }
            case EditActions.DISABLE: {
                const cylinder = this.cylindersManager.get(update.id);
                if (cylinder) {
                    cylinder.enableEdit = false;
                    this.renderEditLabels(cylinder, update);
                }
                break;
            }
            case EditActions.ENABLE: {
                const cylinder = this.cylindersManager.get(update.id);
                if (cylinder) {
                    cylinder.enableEdit = true;
                    this.renderEditLabels(cylinder, update);
                }
                break;
            }
            default: {
                return;
            }
        }
    }

    public ngOnDestroy(): void {
        this.cylindersManager.clear();
    }

    public getPointSize(point: CylinderEditPoint) {
        if (point.isVirtualEditPoint()) {
            return point.props.virtualPointPixelSize;
        }

        return point.isRadiusPoint ? point.props.radiusPixelSize : point.props.pixelSize;
    }

    public getPointColor(point: CylinderEditPoint) {
        return point.isRadiusPoint ? point.props.radiusColor : point.props.color;
    }

    public getPointOutlineColor(point: CylinderEditPoint) {
        return point.isRadiusPoint ? point.props.radiusOutlineColor : point.props.outlineColor;
    }

    public getPointOutlineWidth(point: CylinderEditPoint) {
        return point.isRadiusPoint ? point.props.radiusOutlineWidth : point.props.outlineWidth;
    }

    public getPointShow(point: CylinderEditPoint) {
        return point.show && (point.isVirtualEditPoint() ? point.props.showVirtual : point.props.show);
    }
}
