<ng-container *ngrxLet="isShown$ as isShown">
    <ac-layer #editPolylinesLayer acFor="let polyline of editPolylines$" [context]="this" [show]="isShown" [zIndex]="0">
        <ac-polyline-desc
            props="{
                positions: polyline.getPositionsCallbackProperty(),
                width: polyline.props.width,
                material: polyline.props.shadowMaterial,
                clampToGround: polyline.props.clampToGround,
                zIndex: polyline.props.zIndex,
                classificationType: polyline.props.classificationType
            }"
        >
        </ac-polyline-desc>

        <ac-wall-desc
            props="{
                positions: polyline.getPositionsCallbackProperty(),
                minimumHeights: [0, 0],
                maximumHeights: [polyline.startHeight, polyline.endHeight],
                material: polyline.props.wallMaterial,
                outline: true,
                outlineColor: polyline.props.material
            }"
        ></ac-wall-desc>
    </ac-layer>

    <ac-layer #editPointsLayer acFor="let point of editPoints$" [context]="this" [show]="isShown" [zIndex]="2">
        <ac-point-desc
            props="{
                position: point.getPositionCallbackProperty(),
                pixelSize: getPointSize(point),
                color: point.props.color,
                outlineColor: point.props.outlineColor,
                outlineWidth: point.props.outlineWidth,
                show: getPointShow(point),
                disableDepthTestDistance: point.props.disableDepthTestDistance,
                heightReference: point.props.heightReference,
                zIndex: 2
            }"
        ></ac-point-desc>
    </ac-layer>

    <ac-layer #editHeightPointsLayer acFor="let point of editHeightPoints$" [context]="this" [show]="isShown" [zIndex]="1">
        <ac-point-desc
            props="{
                position: point.getElevatedPositionCallbackProperty(),
                pixelSize:  point.boundEditPoint.props.pixelSize,
                color: point.boundEditPoint.props.color,
                outlineColor: point.boundEditPoint.props.outlineColor,
                outlineWidth: point.boundEditPoint.props.outlineWidth,
                show: point.show,
                disableDepthTestDistance: point.boundEditPoint.props.disableDepthTestDistance,
                heightReference: point.boundEditPoint.props.heightReference,
                zIndex: 1
            }"
        ></ac-point-desc>
    </ac-layer>

    <ac-layer
        #polylineLabelsLayer
        acFor="let label of polylineLabels$"
        [context]="this"
        [show]="isShown"
        [zIndex]="2"
        [store]="true"
        *ngrxLet="labelTemplate$ as labelTemplate"
    >
        <ac-html-desc
            *ngIf="labelTemplate !== null; else simpleLabelsTemplate"
            props="{
                    position: label.position,
                    show: label.show
                }"
        >
            <ng-template let-label>
                <ng-container *ngIf="isShown">
                    <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: label }"></ng-container>
                </ng-container>
            </ng-template>
        </ac-html-desc>

        <ng-template #simpleLabelsTemplate>
            <ac-label-primitive-desc
                props="{
                        position: label.position,
                        backgroundColor: label.backgroundColor,
                        backgroundPadding: label.backgroundPadding,
                        distanceDisplayCondition: label.distanceDisplayCondition,
                        eyeOffset: label.eyeOffset,
                        fillColor: label.fillColor,
                        font: label.font,
                        heightReference: label.heightReference,
                        horizontalOrigin: label.horizontalOrigin,
                        outlineColor: label.outlineColor,
                        outlineWidth: label.outlineWidth,
                        pixelOffset: label.pixelOffset,
                        pixelOffsetScaleByDistance: label.pixelOffsetScaleByDistance,
                        scale: label.scale,
                        scaleByDistance: label.scaleByDistance,
                        show: label.show,
                        showBackground: label.showBackground,
                        style: label.style,
                        text: label.text,
                        translucencyByDistance: label.translucencyByDistance,
                        verticalOrigin: label.verticalOrigin,
                        disableDepthTestDistance: label.disableDepthTestDistance
                    }"
            >
            </ac-label-primitive-desc>
        </ng-template>
    </ac-layer>

    <ac-layer #editBuffersLayer acFor="let buffer of editBuffers$" [context]="this" [show]="isShown" [zIndex]="0">
        <ac-polygon-desc
            props="{
                hierarchy: buffer.getPositionsHierarchyCallbackProperty(),
                material: buffer.material,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                height: 0,
                zIndex: 0
            }"
        >
        </ac-polygon-desc>
    </ac-layer>
</ng-container>
