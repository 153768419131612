import { CountryCode, NationalNumber } from "libphonenumber-js/max";
import { CountryListItem } from "./country.models";

export interface PhoneNumber {
    countryCode: CountryCode | null;
    number: NationalNumber;
}

export interface PhoneNumberCountryListItem extends CountryListItem {
    countryNumber: string;
}
