import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DialogService } from "../../services/dialog/dialog.service";
import { ButtonTheme, ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";

interface EditAvatarComponentState {
    avatar: string | undefined;
    fullName: string | undefined;
    canRemove: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-profile-avatar[fullName][avatar]",
    templateUrl: "./profile-avatar.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ProfileAvatarComponent {
    @Input()
    public set avatar(value: string | undefined) {
        this.localStore.patchState({ avatar: value });
    }
    @Input() public set fullName(value: string | undefined) {
        this.localStore.patchState({ fullName: value });
    }
    @Input() public set canRemove(value: BooleanInput) {
        this.localStore.patchState({ canRemove: coerceBooleanProperty(value) });
    }

    @Output() public saveAvatar = new EventEmitter<string>();
    @Output() public delete = new EventEmitter<void>();

    protected readonly avatar$ = this.localStore.selectByKey("avatar");
    protected readonly fullName$ = this.localStore.selectByKey("fullName").pipe(RxjsUtils.filterFalsy());
    protected readonly canRemove$ = this.localStore.selectByKey("canRemove");

    constructor(
        private readonly localStore: LocalComponentStore<EditAvatarComponentState>,
        private readonly transloco: TranslocoService,
        private readonly dialogService: DialogService
    ) {
        this.localStore.setState({
            avatar: undefined,
            fullName: undefined,
            canRemove: false,
        });
    }

    protected tryDeleteUserAvatar(): void {
        this.dialogService
            .open(ConfirmationDialogComponent, this.getDeleteConfirmationDialogConfig())
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.delete.emit());
    }

    private getDeleteConfirmationDialogConfig(): MatDialogConfig {
        return {
            data: {
                titleText: this.transloco.translate("dtmUi.editAvatar.dialogTitleText"),
                confirmationText: this.transloco.translate("dtmUi.editAvatar.dialogConfirmationText"),
                confirmButtonLabel: this.transloco.translate("dtmUi.editAvatar.dialogConfirmationButtonLabel"),
                theme: ButtonTheme.Warn,
            },
        };
    }
}
