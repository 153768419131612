import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface PresentationTileWithActionComponentState {
    titleTile: string | undefined;
}

@Component({
    selector: "dtm-ui-presentation-tile-with-action",
    templateUrl: "./presentation-tile-with-action.component.html",
    styleUrls: ["./presentation-tile-with-action.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PresentationTileWithActionComponent {
    @Input() public set titleTile(value: string | undefined) {
        this.localStore.patchState({ titleTile: value });
    }

    public readonly titleTile$ = this.localStore.selectByKey("titleTile");

    constructor(private readonly localStore: LocalComponentStore<PresentationTileWithActionComponentState>) {
        localStore.setState({ titleTile: undefined });
    }
}
