<ng-container *ngrxLet="{ groups: groups$, additionalPathParams: additionalPathParams$ } as vm">
    <p *ngIf="!vm.groups.length" class="empty-state">
        {{ "dtmUi.filesGroups.display.emptyStateLabel" | transloco }}
    </p>
    <ng-container *ngFor="let group of vm.groups">
        <dtm-ui-uploaded-files-display
            *ngIf="group.files.length"
            [uploadedFiles]="group.files"
            [additionalPathParams]="vm.additionalPathParams"
        >
            {{ group.groupLabel }}
        </dtm-ui-uploaded-files-display>
    </ng-container>
</ng-container>
