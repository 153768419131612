import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

const RESIZE_PROPORTION_STEP = 0.5;

@Injectable({
    providedIn: "root",
})
export class ImageHelperService {
    constructor(@Inject(DOCUMENT) public document: Document) {}

    public async resizeImageFile(file: Blob, width: number, height?: number): Promise<Blob | null> {
        const canvas = this.document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const image = new Image();

        return new Promise((resolve) => {
            image.src = URL.createObjectURL(file);
            image.onload = () => {
                URL.revokeObjectURL(image.src);

                canvas.width = width;
                // NOTE: if no height argument is passed, calculate it proportionally
                if (!height) {
                    height = image.height * (width / image.width);
                }
                canvas.height = height;

                const resizeCanvas = document.createElement("canvas");
                const resizeCtx = resizeCanvas.getContext("2d");

                if (!ctx || !resizeCtx) {
                    resolve(null);

                    return;
                }

                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = "high";

                let currentDimensions = {
                    width: Math.floor(image.width * RESIZE_PROPORTION_STEP),
                    height: Math.floor(image.height * RESIZE_PROPORTION_STEP),
                };

                resizeCanvas.width = currentDimensions.width;
                resizeCanvas.height = currentDimensions.height;
                resizeCtx.imageSmoothingEnabled = true;
                resizeCtx.imageSmoothingQuality = "high";

                resizeCtx?.drawImage(image, 0, 0, currentDimensions.width, currentDimensions.height);

                // NOTE: resizing divided into smaller parts will give better results in some browser (i.e. Firefox)
                while (currentDimensions.width * RESIZE_PROPORTION_STEP > width) {
                    currentDimensions = {
                        width: Math.floor(currentDimensions.width * RESIZE_PROPORTION_STEP),
                        height: Math.floor(currentDimensions.height * RESIZE_PROPORTION_STEP),
                    };

                    resizeCtx?.drawImage(
                        resizeCanvas,
                        0,
                        0,
                        currentDimensions.width * 2,
                        currentDimensions.height * 2,
                        0,
                        0,
                        currentDimensions.width,
                        currentDimensions.height
                    );
                }

                ctx.drawImage(resizeCanvas, 0, 0, currentDimensions.width, currentDimensions.height, 0, 0, canvas.width, canvas.height);

                canvas.toBlob(resolve, "image/jpeg");
            };
        });
    }

    public async cropImageCentrally(file: Blob, proportion: number): Promise<Blob | null> {
        const canvas = this.document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const image = new Image();

        return new Promise((resolve) => {
            image.src = URL.createObjectURL(file);
            image.onload = () => {
                let width = image.width;
                let height = image.height;

                if (width > height) {
                    width = height * proportion;
                } else {
                    height = width / proportion;
                }

                // NOTE: handle input with lesser proportions that targeted
                if (width > image.width) {
                    width = image.width;
                    height = width / proportion;
                }

                if (height > image.height) {
                    height = image.height;
                    width = height * proportion;
                }

                const xTransition = (image.width - width) / 2;
                const yTransition = (image.height - height) / 2;

                URL.revokeObjectURL(image.src);

                canvas.width = width;
                canvas.height = height;
                ctx?.drawImage(image, xTransition, yTransition, width, height, 0, 0, width, height);

                canvas.toBlob(resolve, "image/jpeg");
            };
        });
    }
}
