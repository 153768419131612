<input
    #input
    class="cdk-visually-hidden"
    type="checkbox"
    [id]="inputId"
    [required]="required"
    [checked]="checked"
    [attr.value]="value"
    [disabled]="disabled"
    [attr.name]="name"
    [tabIndex]="tabIndex"
    [attr.aria-label]="ariaLabel || null"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby"
    (change)="_onInteractionEvent($event)"
    (click)="_onInputClick($event)"
/>
<label [for]="inputId">
    <span class="icon" [class.selected]="checked || indeterminate">
        <dtm-ui-icon *ngIf="indeterminate" name="checkbox-indeterminate"></dtm-ui-icon>
        <dtm-ui-icon *ngIf="!indeterminate && !checked" name="checkbox-blank"></dtm-ui-icon>
        <dtm-ui-icon *ngIf="!indeterminate && checked" name="checkbox"></dtm-ui-icon>
    </span>
    <span class="label"><ng-content></ng-content></span>
    <span class="checkbox-field-hint"><ng-content select="[checkboxFieldHint]"></ng-content></span>
</label>
