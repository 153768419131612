<ng-container *ngrxLet="hasSupervisorRole$; let hasSupervisorRole">
    <dss-client-lib-wizard-step-wrapper
        [stepNumber]="4"
        [stepsAmount]="4"
        [title]="'dssClientLibFlightZone.flightZoneWizardSteps.summary.header' | transloco"
        *ngIf="flightZoneApplicationData$ | ngrxPush; let flightZoneApplicationData"
    >
        <dss-shared-lib-application-summary
            [basicDataForm]="flightZoneApplicationData.basicDataForm"
            [restrictionAreaGeometry]="flightZoneApplicationData.restrictionAreaGeometry"
            [restrictionExclusions]="flightZoneApplicationData.restrictionExclusions"
            [analysisStatus]="flightZoneApplicationData.analysisStatus"
            [capabilities]="capabilities$ | ngrxPush"
            (editBasicDataSection)="backToStep.emit(FlightZoneWizardSteps.BasicZoneInfo)"
            (editGeometrySection)="backToStep.emit(FlightZoneWizardSteps.ZoneGeometry)"
            (editExclusionsSection)="backToStep.emit(FlightZoneWizardSteps.ExclusionsFromRestrictions)"
        >
        </dss-shared-lib-application-summary>

        <ng-container actions *ngrxLet="isProcessing$; let isProcessing">
            <button
                class="button-secondary actions-button"
                (click)="backToStep.emit(FlightZoneWizardSteps.ExclusionsFromRestrictions)"
                [disabled]="isProcessing"
                type="button"
            >
                <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                {{ "dssClientLibFlightZone.summaryStep.backButtonLabel" | transloco }}
            </button>

            <button class="button-secondary actions-button" (click)="zoomToGeometry.emit()" type="button">
                <dtm-ui-icon name="focus-2"></dtm-ui-icon>
                {{ "dssClientLibFlightZone.summaryStep.zoomToZoneButtonLabel" | transloco }}
            </button>

            <button
                class="button-warn actions-button full-width-button"
                *ngIf="flightZoneApplicationData.status === FlightZoneApplicationStatus.Draft"
                (click)="deleteApplication()"
                [disabled]="isProcessing"
                type="button"
            >
                {{ "dssClientLibFlightZone.summaryStep.deleteApplicationDraftButtonLabel" | transloco }}
            </button>

            <button
                class="button-primary actions-button"
                *ngIf="
                    !flightZoneApplicationData.analysisStatus?.isLocked &&
                    flightZoneApplicationData.status &&
                    flightZoneApplicationData.status !== FlightZoneApplicationStatus.New &&
                    flightZoneApplicationData.status !== FlightZoneApplicationStatus.Draft
                "
                (click)="acceptCorrections(flightZoneApplicationData.flightZoneId!)"
                [disabled]="isProcessing"
                type="button"
            >
                {{ "dssClientLibFlightZone.summaryStep.acceptCorrectionsButtonLabel" | transloco }}
            </button>

            <ng-container [ngSwitch]="hasSupervisorRole">
                <ng-container *ngSwitchCase="true">
                    <button
                        class="button-warn actions-button full-width-button"
                        *ngIf="flightZoneApplicationData.status === FlightZoneApplicationStatus.New"
                        (click)="rejectApplication()"
                        [disabled]="isProcessing"
                        type="button"
                    >
                        {{ "dssClientLibFlightZone.summaryStep.rejectButtonLabel" | transloco }}
                    </button>

                    <button
                        *ngIf="
                            !flightZoneApplicationData.status ||
                            flightZoneApplicationData.status === FlightZoneApplicationStatus.New ||
                            flightZoneApplicationData.status === FlightZoneApplicationStatus.Draft
                        "
                        class="button-primary actions-button"
                        [disabled]="isProcessing"
                        type="button"
                        (click)="submitApplication.emit(hasSupervisorRole)"
                    >
                        {{ "dssClientLibFlightZone.summaryStep.submitButtonLabel" | transloco }}
                    </button>
                </ng-container>

                <ng-container *ngSwitchCase="false">
                    <button
                        *ngIf="!flightZoneApplicationData.status || flightZoneApplicationData.status === FlightZoneApplicationStatus.Draft"
                        class="button-primary actions-button"
                        [disabled]="isProcessing"
                        type="button"
                        (click)="submitApplication.emit(hasSupervisorRole)"
                    >
                        {{ "dssClientLibFlightZone.summaryStep.sendToSupervisorButtonLabel" | transloco }}
                    </button>

                    <a
                        *ngIf="flightZoneApplicationData.status === FlightZoneApplicationStatus.New"
                        class="button-primary actions-button"
                        routerLink="/flight-zone/submitted-applications"
                    >
                        {{ "dssClientLibFlightZone.summaryStep.backToListButtonLabel" | transloco }}
                    </a>
                </ng-container>
            </ng-container>
        </ng-container>
    </dss-client-lib-wizard-step-wrapper>
</ng-container>
