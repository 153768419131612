<ng-container *ngrxLet="isShown$ as isShown">
    <ac-layer #editPointsLayer acFor="let point of editPoints$" [context]="this" [show]="isShown" [zIndex]="2">
        <ac-point-desc
            props="{
                position: point.getPositionCallbackProperty(),
                pixelSize: getPointSize(point),
                color: point.props.color,
                outlineColor: point.props.outlineColor,
                outlineWidth: point.props.outlineWidth,
                show: getPointShow(point),
                heightReference: point.props.heightReference,
                disableDepthTestDistance: point.props.disableDepthTestDistance
            }"
        >
        </ac-point-desc>
    </ac-layer>

    <ac-layer #editPolygonWaypointLayer acFor="let polygonWaypoint of editPolygonWaypoint$" [context]="this" [show]="isShown" [zIndex]="1">
        <ac-point-desc
            props="{
                position: polygonWaypoint.getPositionCallbackProperty(),
                pixelSize: getPointSize(polygonWaypoint),
                color: polygonWaypoint.props.color,
                outlineColor: polygonWaypoint.props.outlineColor,
                outlineWidth: polygonWaypoint.props.outlineWidth,
                show: getPointShow(polygonWaypoint),
                disableDepthTestDistance: polygonWaypoint.props.disableDepthTestDistance,
                heightReference: Cesium.HeightReference.NONE
            }"
        >
        </ac-point-desc>
    </ac-layer>

    <ac-layer #editPrismsLayer acFor="let prism of editPrisms$" [context]="this" [show]="isShown" [zIndex]="0">
        <ac-polygon-desc
            props="{
                hierarchy: prism.getPositionsHierarchyCallbackProperty(),
                material: prism.getMaterial(),
                fill: prism.prismProps.fill,
                classificationType: prism.prismProps.classificationType,
                heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                height: prism.getBottomHeightCallbackProperty(),
                show: prism.isValidPolygon,
                extrudedHeight: prism.getTopHeightCallbackProperty()
            }"
        >
        </ac-polygon-desc>

        <ac-polygon-desc
            props="{
                hierarchy: prism.getPositionsHierarchyCallbackProperty(),
                material: prism.prismProps.shadowMaterial,
                outline: true,
                outlineColor: Cesium.Color.WHITE,
                fill: prism.prismProps.fill,
                show: prism.isValidPolygon,
                classificationType: prism.prismProps.classificationType,
                heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                height: 0
            }"
        >
        </ac-polygon-desc>
    </ac-layer>

    <ac-layer
        #prismLabelsLayer
        acFor="let label of prismLabels$"
        [context]="this"
        [show]="isShown"
        [zIndex]="2"
        [store]="true"
        *ngrxLet="prismLabelsTemplate$ as labelTemplate"
    >
        <ac-html-desc
            *ngIf="labelTemplate; else simpleLabelsTemplate"
            props="{
                    position: label.position,
                    show: label.show
                }"
        >
            <ng-template let-label>
                <ng-container *ngIf="isShown">
                    <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: label }"></ng-container>
                </ng-container>
            </ng-template>
        </ac-html-desc>
        <ng-template #simpleLabelsTemplate>
            <ac-label-primitive-desc
                props="{
                    position: label.position,
                    backgroundColor: label.backgroundColor,
                    backgroundPadding: label.backgroundPadding,
                    distanceDisplayCondition: label.distanceDisplayCondition,
                    eyeOffset: label.eyeOffset,
                    fillColor: label.fillColor,
                    font: label.font,
                    heightReference: label.heightReference,
                    horizontalOrigin: label.horizontalOrigin,
                    outlineColor: label.outlineColor,
                    outlineWidth: label.outlineWidth,
                    pixelOffset: label.pixelOffset,
                    pixelOffsetScaleByDistance: label.pixelOffsetScaleByDistance,
                    scale: label.scale,
                    scaleByDistance: label.scaleByDistance,
                    show: prism.shouldShowLabel(label),
                    showBackground: label.showBackground,
                    style: label.style,
                    text: label.text,
                    translucencyByDistance: label.translucencyByDistance,
                    verticalOrigin: label.verticalOrigin,
                    disableDepthTestDistance: label.disableDepthTestDistance
                }"
            >
            </ac-label-primitive-desc>
        </ng-template>
    </ac-layer>

    <ac-layer #editHeightOutlinesLayer acFor="let outline of editHeightOutlines$" [context]="this" [show]="isShown" [zIndex]="1">
        <ac-polyline-desc
            props="{
                positions: outline.getElevatedPositionsCallbackProperty(),
                width: outline.props.width,
                material: outline.props.material(),
                classificationType: outline.props.classificationType,
                heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND
            }"
        >
        </ac-polyline-desc>
    </ac-layer>
</ng-container>
