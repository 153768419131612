/* eslint-disable @typescript-eslint/ban-types */

import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";

type StateSelectorsMap<StateType> = {
    [selectorKey in keyof StateType]?: (state: StateType) => StateType[selectorKey];
};

@Injectable()
export class LocalComponentStore<StateType extends object> extends ComponentStore<StateType> {
    private memoizedSelectors: StateSelectorsMap<StateType> = {};

    public selectSnapshot<R>(projector: (state: StateType) => R): R {
        return super.get(projector);
    }

    public selectSnapshotByKey<StateKey extends keyof StateType>(selectedKey: StateKey): StateType[StateKey] {
        return super.get(this.getSelector(selectedKey));
    }

    public selectByKey<StateKey extends keyof StateType>(selectedKey: StateKey): Observable<StateType[StateKey]> {
        return super.select(this.getSelector(selectedKey));
    }

    public get(): StateType {
        return super.get();
    }

    /**
     * Returns **memoized** selector for specific state key
     * @param selectedKey - state key to select
     * @returns memoized selector for specific state key
     */
    public getSelector<StateKey extends keyof StateType>(selectedKey: StateKey): (state: StateType) => StateType[StateKey] {
        if (!this.memoizedSelectors[selectedKey]) {
            this.memoizedSelectors[selectedKey] = (state: StateType) => state[selectedKey];
        }

        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        return this.memoizedSelectors[selectedKey]!;
    }
}
