import { ENTER } from "@angular/cdk/keycodes";
import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, ValidationErrors, Validator } from "@angular/forms";
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from "@angular/material/legacy-autocomplete";
import { MatLegacyChip as MatChip, MatLegacyChipInputEvent as MatChipInputEvent } from "@angular/material/legacy-chips";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { combineLatest, Observable } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";

export interface ChipOption {
    value: string;
    label: string;
    isDisabled: boolean;
}

export enum ChipType {
    Selected = "SELECTED",
    Custom = "CUSTOM",
}

export interface ChipValue {
    value: string;
    label: string;
    type: ChipType;
    isRemovable?: boolean;
}

interface ChipsFieldComponentState {
    isDisabled: boolean;
    isActivated: boolean;
    value: ChipValue[];
    options: ChipOption[];
    placeholder: string;
}

@Component({
    selector: "dtm-ui-chips-field",
    templateUrl: "./chips-field.component.html",
    styleUrls: ["./chips-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChipsFieldComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ChipsFieldComponent),
            multi: true,
        },
    ],
})
export class ChipsFieldComponent implements ControlValueAccessor, Validator {
    @Input() public set options(value: ChipOption[] | Record<string, string> | undefined) {
        let options: ChipOption[];
        if (!value) {
            options = [];
        } else if (Array.isArray(value)) {
            options = [...value];
        } else {
            options = Object.entries(value).map(([optionValue, optionLabel]) => ({
                value: optionValue,
                label: optionLabel,
                isDisabled: false,
            }));
        }

        this.localStore.patchState({ options });
    }

    @Input() public set placeholder(value: string | undefined) {
        this.localStore.patchState({ placeholder: value ?? "" });
    }

    @Input() public set value(value: ChipValue[] | undefined) {
        value = value ?? [];

        this.localStore.patchState({ value });

        if (!this.validate()) {
            this.propagateChange(value);
        }

        this.onValidationChange();
    }

    @ViewChild("chipsInput") private chipsInput!: ElementRef<HTMLInputElement>;
    @ViewChildren(MatChip, { read: ElementRef }) private readonly chips!: QueryList<ElementRef<HTMLElement>>;

    public customChipFormControl = new UntypedFormControl();
    public isDisabled$ = this.localStore.selectByKey("isDisabled");
    public isActivated$ = this.localStore.selectByKey("isActivated");
    public value$ = this.localStore.selectByKey("value");
    public filteredOptions$ = this.initFilteredOptions();
    public placeholder$ = this.localStore.selectByKey("placeholder");

    public separatorKeysCodes = [ENTER];

    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: ChipValue[]) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<ChipsFieldComponentState>) {
        this.localStore.setState({
            isDisabled: false,
            isActivated: false,
            value: [],
            options: [],
            placeholder: "",
        });
    }

    public registerOnChange(fn: (value: ChipValue[]) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(): void {}

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        return null;
    }

    public writeValue(value: ChipValue[]): void {
        this.value = value;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.localStore.patchState({ isDisabled });

        if (isDisabled) {
            this.customChipFormControl.disable();
        } else {
            this.customChipFormControl.enable();
        }
    }

    public selectChip({ option }: MatAutocompleteSelectedEvent): void {
        this.addValue({
            type: ChipType.Selected,
            ...option.value,
        });

        this.chipsInput.nativeElement.value = "";
        this.customChipFormControl.setValue("");
    }

    public addCustomChip({ value }: MatChipInputEvent): void {
        value = (value ?? "").trim();

        if (value) {
            const currentValue = this.getCurrentValueByLabel(value);

            if (currentValue) {
                this.highlightValue(currentValue);
            } else {
                const option = this.getOptionByLabel(value);

                if (option && !option.isDisabled) {
                    this.addValue({
                        type: ChipType.Selected,
                        isRemovable: true,
                        value: option.value,
                        label: option.label,
                    });
                } else {
                    this.addValue({
                        type: ChipType.Custom,
                        isRemovable: true,
                        value,
                        label: value,
                    });
                }
            }
        }

        this.customChipFormControl.setValue("");
    }

    public removeChip(chipValue: ChipValue): void {
        this.value = this.localStore.selectSnapshotByKey("value").filter((chipItem) => chipItem.value !== chipValue.value);
    }

    private initFilteredOptions(): Observable<ChipOption[]> {
        const allOptions$ = this.localStore.selectByKey("options").pipe(startWith([]));
        const filterValue$ = this.customChipFormControl.valueChanges.pipe(
            filter((value) => typeof value === "string"),
            startWith(""),
            map((filterValue) => (filterValue ?? "").toLocaleLowerCase())
        );
        const currentSelectedValues$ = this.value$.pipe(
            startWith([]),
            map((value) => value.filter((item) => item.type === ChipType.Selected).map((item) => item.value))
        );

        return combineLatest([allOptions$, filterValue$, currentSelectedValues$]).pipe(
            map(([options, filterValue, currentSelectedValues]) =>
                options.filter((option) => {
                    if (currentSelectedValues.includes(option.value)) {
                        return false;
                    }

                    if (filterValue) {
                        return option.label.toLocaleLowerCase().includes(filterValue);
                    }

                    return true;
                })
            )
        );
    }

    private addValue(value: ChipValue): void {
        this.value = [...(this.localStore.selectSnapshotByKey("value") ?? []), value];
    }

    private getCurrentValueByLabel(label: string): ChipValue | null {
        const currentValues = this.localStore.selectSnapshotByKey("value");

        return currentValues.find((currentValue) => currentValue.label === label) ?? null;
    }

    private highlightValue(value: ChipValue): void {
        const chip = this.chips.find((chipItem) => chipItem.nativeElement.textContent?.trim() === value.label);

        if (chip) {
            chip.nativeElement.classList.add("highlight");
            setTimeout(() => chip.nativeElement.classList.remove("highlight"), 1000);
        }
    }

    private getOptionByLabel(label: string): ChipOption | null {
        const options = this.localStore.selectSnapshotByKey("options");

        return options.find((option) => option.label === label) ?? null;
    }
}
