import { DateUtils, MILLISECONDS_IN_DAY, MILLISECONDS_IN_SECOND, ObjectUtils } from "@dtm-frontend/shared/utils";
import { untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable, Subscription, combineLatestWith, first, map, of, switchMap, tap, timer } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { MissionPlanRoute, MissionPlanRouteSection, MissionSegmentStatus, RouteData } from "../../models/route-area.model";
import { DeactivatedSectionsInfo, EmergencyType, MissionData, MissionStatus } from "../../models/tactical.models";

interface TacticalServiceOptions {
    caller: unknown;
    missionDataStream: Observable<MissionData | undefined>;
    missionEmergencyStream?: Observable<EmergencyType | undefined>;
    otherMissionsStream: Observable<MissionData[] | undefined>;
    refreshNearbyMissionListCallback: () => void;
    sectionDeactivationEventsStream?: Observable<DeactivatedSectionsInfo[]> | undefined;
    selectedNearbyMissionStream: Observable<MissionData | undefined>;
}

export class TacticalService {
    private missionData$: Observable<MissionData | undefined>;
    private otherMissionsStream$: Observable<MissionData[] | undefined>;
    private selectedNearbyMission$: Observable<MissionData | undefined>;
    private refreshNearbyMissionList: () => void;
    private caller: unknown;
    private refreshNearbyMissionDataTimerSubscription: Subscription | undefined;
    private refreshTimerSubscription: Subscription | undefined;
    private refreshRouteData$ = new BehaviorSubject(null);
    private uniqueRouteId = 0;
    private otherMissions$: Observable<MissionData[] | undefined>;
    private deactivatedSectionsInfo: DeactivatedSectionsInfo[] = [];
    private emergencyType$: Observable<EmergencyType | undefined> | undefined;
    private missionDataSnapshot: MissionData | undefined;

    constructor(options: TacticalServiceOptions) {
        this.missionData$ = options.missionDataStream;
        this.otherMissionsStream$ = options.otherMissionsStream;
        this.selectedNearbyMission$ = options.selectedNearbyMissionStream;
        this.refreshNearbyMissionList = options.refreshNearbyMissionListCallback;
        this.caller = options.caller;
        this.otherMissions$ = this.otherMissionsStream$.pipe(
            map((list) => list?.filter((mission) => mission.missionId !== this.missionDataSnapshot?.missionId)),
            map((list) => list?.map(this.updateMissionStatus))
        );
        this.emergencyType$ = options.missionEmergencyStream;

        options.sectionDeactivationEventsStream?.pipe(untilDestroyed(this.caller)).subscribe((info) => {
            this.deactivatedSectionsInfo = info;
            this.refreshRouteData$.next(null);
        });
    }
    public initRouteData(): Observable<RouteData<MissionData>> {
        return this.missionData$.pipe(
            tap((missionData) => {
                this.missionDataSnapshot = missionData;
                this.uniqueRouteId++;
                this.refreshNearbyMissionDataTimerSubscription?.unsubscribe();
                this.refreshTimerSubscription?.unsubscribe();
                this.refreshNearbyMissionList();
            }),
            combineLatestWith(this.refreshRouteData$, this.emergencyType$ ?? of(undefined)),
            map(([missionData, , emergency]) => {
                if (!missionData) {
                    return;
                }

                const updatedMissionData: MissionData = { ...missionData, emergency };

                return updatedMissionData;
            }),
            switchMap((missionData) =>
                this.otherMissions$.pipe(
                    tap((list) => {
                        const maximumCheckTime = new Date(new Date().getTime() + MILLISECONDS_IN_DAY);

                        if (missionData && missionData.startTime.min > maximumCheckTime) {
                            return;
                        }

                        const combinedMissionsData = [...(list ?? [])];
                        if (missionData) {
                            combinedMissionsData.push(missionData);
                        }
                        this.checkSegmentsTimesAndScheduledUpdate(combinedMissionsData);
                        this.checkNearbyMissionTimesAndScheduleRefresh(list);
                    }),
                    map((nearbyMissionsData, uniqueNearbyRoutesId) => ({ nearbyMissionsData, uniqueNearbyRoutesId })),
                    combineLatestWith(this.selectedNearbyMission$),
                    map(([{ nearbyMissionsData, uniqueNearbyRoutesId }, selectedNearbyMission]) =>
                        this.getRouteData(missionData, uniqueNearbyRoutesId, nearbyMissionsData, selectedNearbyMission)
                    )
                )
            ),
            shareReplay({ bufferSize: 1, refCount: true })
        );
    }

    private getRouteData(
        missionData: MissionData | undefined,
        uniqueNearbyRoutesId: number,
        nearbyMissionsData: MissionData[] | undefined,
        selectedNearbyMission: MissionData | undefined
    ): RouteData<MissionData> {
        return {
            route: this.getUpdatedRoute(missionData),
            data: missionData ? { ...missionData, isMain: true } : undefined,
            isPathBased: !!missionData?.isPathBased,
            sectionStatuses: missionData?.route.sections.map((section, sectionIndex) =>
                this.getSegmentStatus(missionData, section, sectionIndex)
            ),
            uniqueRouteId: this.uniqueRouteId,
            uniqueNearbyRoutesId,
            isMain: true,
            isOutsideDtm: missionData?.dtmNames && missionData.dtmNames.length === 0,
            emergency: missionData?.emergency,
            nearbyMissionsData: nearbyMissionsData
                ?.filter((data) => data.missionId !== missionData?.missionId)
                .map((data) => ({
                    data: { ...data, isSelected: data.missionId === selectedNearbyMission?.missionId },
                    route: data.route,
                    isPathBased: data.isPathBased,
                    sectionStatuses: data.route.sections.map((section, sectionIndex) => this.getSegmentStatus(data, section, sectionIndex)),
                    isSelected: data.missionId === selectedNearbyMission?.missionId,
                })),
        };
    }

    private getUpdatedRoute(missionData?: MissionData): MissionPlanRoute {
        const route = missionData?.route;

        if (!route) {
            return { routeId: "", sections: [], isPathBased: false };
        }

        if (route.isPathBased) {
            return route;
        }

        const updatedRoute = ObjectUtils.cloneDeep(route);

        updatedRoute.sections.forEach((section) => {
            if (section.flightZone) {
                const stopoverDurationSeconds = section.flightZone
                    ? DateUtils.convertISO8601DurationToSeconds(section.flightZone.stopover.max)
                    : undefined;
                const durationTime = (stopoverDurationSeconds ?? 0) * MILLISECONDS_IN_SECOND;

                section.flightZone.center.estimatedArriveAt.min = missionData.startTime.min;
                section.flightZone.center.estimatedArriveAt.max = new Date(missionData.endTime.max.getTime() - durationTime);
            }
        });

        return updatedRoute;
    }

    private getSegmentStatus(data: MissionData, section: MissionPlanRouteSection, sectionIndex: number): MissionSegmentStatus {
        if (data?.status === MissionStatus.Accepted || !section) {
            return MissionSegmentStatus.MISSION_NOT_ACTIVATED;
        }

        const deactivatedLastSection = this.deactivatedSectionsInfo?.find((item) =>
            data?.uav?.trackersIdentifiers?.find((trackerIdentifier) => trackerIdentifier === item.trackerId)
        );

        if (section.isActive === false || (deactivatedLastSection && deactivatedLastSection.sectionIndex >= sectionIndex)) {
            return MissionSegmentStatus.DEACTIVATED_SEGMENT;
        }

        if (data?.status === MissionStatus.Activated) {
            return MissionSegmentStatus.MISSION_ACTIVATED;
        }

        if ([MissionStatus.Finished, MissionStatus.Canceled].includes(data?.status)) {
            return MissionSegmentStatus.DEACTIVATED_SEGMENT;
        }

        let estimatedArriveAtFrom;
        let estimatedArriveAtTo;

        if (section.segment) {
            estimatedArriveAtFrom = section.segment.fromWaypoint.estimatedArriveAt.min;
            estimatedArriveAtTo = section.segment.toWaypoint.estimatedArriveAt.max;
        }

        if (section.flightZone) {
            estimatedArriveAtFrom = section.flightZone.center.estimatedArriveAt.min;
            estimatedArriveAtTo =
                new Date(section.flightZone.center.estimatedArriveAt.max).getTime() +
                (DateUtils.convertISO8601DurationToSeconds(section.flightZone.stopover.max) ?? 0) * MILLISECONDS_IN_SECOND;
        }

        // TODO: DTM-1659 - time should be synchronized with backend
        const currentTime = new Date().getTime();

        if (estimatedArriveAtTo && currentTime > new Date(estimatedArriveAtTo).getTime()) {
            return MissionSegmentStatus.DEACTIVATED_SEGMENT;
        }

        if (
            estimatedArriveAtFrom &&
            new Date(estimatedArriveAtFrom).getTime() <= currentTime &&
            estimatedArriveAtTo &&
            currentTime <= new Date(estimatedArriveAtTo).getTime()
        ) {
            return MissionSegmentStatus.ACTIVE_SEGMENT;
        }

        return MissionSegmentStatus.NOT_ACTIVE_SEGMENT;
    }

    private updateMissionStatus(mission: MissionData) {
        const updated = { ...mission };

        if (mission.status === MissionStatus.Activated && mission.realizationTime && mission.realizationTime <= new Date()) {
            updated.status = MissionStatus.Started;
        }

        if (mission.endTime.max < new Date()) {
            updated.status = MissionStatus.Finished;
        }

        return updated;
    }

    private checkSegmentsTimesAndScheduledUpdate(missionData: MissionData[]) {
        this.refreshTimerSubscription?.unsubscribe();
        const flattenSections = missionData
            .filter(({ status }) => status === MissionStatus.Started)
            .map(({ route: { sections } }) => sections)
            .flat();

        const timeToNextUpdate = flattenSections.reduce((previousUpdateTime, currentSection) => {
            const estimatedArriveAt = (currentSection.segment?.fromWaypoint ?? currentSection?.flightZone?.center)?.estimatedArriveAt;

            if (!estimatedArriveAt) {
                return previousUpdateTime;
            }

            // TODO: DTM-1659 - time should be synchronized with backend
            const minTimeDiff = new Date(estimatedArriveAt.min).getTime() - new Date().getTime();
            const stoopoverMaxTime = currentSection.flightZone?.stopover?.max
                ? (DateUtils.convertISO8601DurationToSeconds(currentSection.flightZone?.stopover?.max) ?? 0) * MILLISECONDS_IN_SECOND
                : 0;
            // TODO: DTM-1659 - time should be synchronized with backend
            const maxTimeDiff = new Date(estimatedArriveAt.max).getTime() + stoopoverMaxTime - new Date().getTime();

            if (minTimeDiff > 0 && minTimeDiff < previousUpdateTime) {
                return minTimeDiff;
            }

            if (maxTimeDiff > 0 && maxTimeDiff < previousUpdateTime) {
                return maxTimeDiff;
            }

            return previousUpdateTime;
        }, Infinity);

        if (timeToNextUpdate === Infinity || !timeToNextUpdate) {
            return;
        }

        this.refreshTimerSubscription = timer(timeToNextUpdate)
            .pipe(first(), untilDestroyed(this.caller))
            .subscribe(() => this.refreshRouteData$.next(null));
    }

    private checkNearbyMissionTimesAndScheduleRefresh(missions?: MissionData[]) {
        this.refreshNearbyMissionDataTimerSubscription?.unsubscribe();
        const timeToNextUpdate = missions
            ?.filter((mission) => [MissionStatus.Activated, MissionStatus.Accepted].includes(mission.status))
            .reduce((previousUpdateTime, currentMission) => {
                const {
                    startTime: { min },
                    endTime: { max },
                } = currentMission;

                if (!min) {
                    return previousUpdateTime;
                }

                // TODO: DTM-1659 - time should be synchronized with backend
                const timeDiffMin = min.getTime() - new Date().getTime();
                const timeDiffMax = max.getTime() - new Date().getTime();

                if (0 < timeDiffMin && timeDiffMin < previousUpdateTime) {
                    return timeDiffMin;
                }

                if (0 < timeDiffMax && timeDiffMax < previousUpdateTime) {
                    return timeDiffMax;
                }

                return previousUpdateTime;
            }, Infinity);

        if (timeToNextUpdate === Infinity || !timeToNextUpdate) {
            return;
        }

        this.refreshNearbyMissionDataTimerSubscription = timer(timeToNextUpdate)
            .pipe(first(), untilDestroyed(this.caller))
            .subscribe(() => this.refreshNearbyMissionList());
    }
}
