import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { BYTES_IN_MEGABYTE, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { ImageCropperComponent } from "ngx-image-cropper";
import { lastValueFrom } from "rxjs";
import { ImageConverterService } from "../../services/image-converter/image-converter.service";

type CroppedImageFormat = "jpeg" | "png";

interface AvatarEditorComponentState {
    avatar: string | undefined;
    fullName: string | undefined;
    label: string | undefined;
    context: string | undefined;
    uploadedImage: string | undefined;
    isInputFileVisible: boolean;
    isProcessing: boolean;
    isEditModeOn: boolean;
    isLabelDisplayed: boolean;
    canRemove: boolean;
    resultFileFormat: CroppedImageFormat;
}

const MAX_ATTACHMENT_SIZE_MEGABYTES = 5;
const MAX_ATTACHMENT_SIZE_BYTES = MAX_ATTACHMENT_SIZE_MEGABYTES * BYTES_IN_MEGABYTE;
const MAX_AVATAR_WIDTH = 80;

@UntilDestroy()
@Component({
    selector: "dtm-ui-avatar-editor[fullName][avatar][label]",
    templateUrl: "./avatar-editor.component.html",
    styleUrls: ["./avatar-editor.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AvatarEditorComponent {
    @ViewChild(ImageCropperComponent) private readonly imageCropperComponent!: ImageCropperComponent;

    @Input() public set avatar(value: string | undefined) {
        this.localStore.patchState({ avatar: value });
    }
    @Input() public set label(value: string | undefined) {
        this.localStore.patchState({ label: value });
    }
    @Input() public set fullName(value: string | undefined) {
        this.localStore.patchState({ fullName: value });
    }
    @Input() public set context(value: string | undefined) {
        this.localStore.patchState({ context: value });
    }
    @Input() public set canRemove(value: BooleanInput) {
        this.localStore.patchState({ canRemove: coerceBooleanProperty(value) });
    }

    @Output() public saveAvatar = new EventEmitter<string>();
    @Output() public delete = new EventEmitter<void>();

    protected readonly fullName$ = this.localStore.selectByKey("fullName");
    protected readonly label$ = this.localStore.selectByKey("label");
    protected readonly avatar$ = this.localStore.selectByKey("avatar");
    protected readonly canRemove$ = this.localStore.selectByKey("canRemove");
    protected readonly context$ = this.localStore.selectByKey("context");
    protected readonly uploadedImage$ = this.localStore.selectByKey("uploadedImage").pipe(RxjsUtils.filterFalsy());
    protected readonly isInputFileVisible$ = this.localStore.selectByKey("isInputFileVisible");
    protected readonly isEditModeOn$ = this.localStore.selectByKey("isEditModeOn");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isLabelDisplayed$ = this.localStore.selectByKey("isLabelDisplayed");
    protected readonly resultFileFormat$ = this.localStore.selectByKey("resultFileFormat");

    protected readonly fileUploadControl = new UntypedFormControl(undefined, Validators.required);

    protected readonly MAX_ATTACHMENT_SIZE_BYTES = MAX_ATTACHMENT_SIZE_BYTES;
    protected readonly MAX_AVATAR_WIDTH = MAX_AVATAR_WIDTH;

    constructor(
        private readonly localStore: LocalComponentStore<AvatarEditorComponentState>,
        private readonly imageConverter: ImageConverterService
    ) {
        this.localStore.setState({
            avatar: undefined,
            label: undefined,
            fullName: undefined,
            context: undefined,
            uploadedImage: undefined,
            isInputFileVisible: true,
            isProcessing: false,
            isLabelDisplayed: false,
            isEditModeOn: false,
            canRemove: false,
            resultFileFormat: "jpeg",
        });
    }

    protected clearState(): void {
        this.localStore.patchState({ isInputFileVisible: true, uploadedImage: undefined, isProcessing: false, isEditModeOn: false });
        this.fileUploadControl.reset(undefined);
    }

    protected setEditModeOn(): void {
        this.localStore.patchState({ isEditModeOn: true });
        this.setLabelVisibility(true);
    }

    protected cropImage(): void {
        const croppedImage = this.imageCropperComponent.crop()?.base64;

        if (!croppedImage) {
            return;
        }

        this.saveAvatar.emit(croppedImage);
    }

    protected setLabelVisibility(isEditModeOpen: boolean): void {
        this.localStore.patchState({ isLabelDisplayed: isEditModeOpen });
    }

    protected async setFile(file: File | null) {
        if (!file) {
            return;
        }

        this.localStore.patchState({ isProcessing: true });

        const convertedFile = await lastValueFrom(this.imageConverter.convertBlobToBase64(file));
        this.fileUploadControl.setValue(convertedFile);
        this.setStateAfterImageConversion(convertedFile, this.getResultFileFormat(file));
    }

    private setStateAfterImageConversion(base64Image: string, resultFileFormat: CroppedImageFormat): void {
        this.localStore.patchState({ uploadedImage: base64Image, resultFileFormat, isProcessing: false, isInputFileVisible: false });
    }

    private getResultFileFormat(file: File): CroppedImageFormat {
        return file.type === "image/png" ? "png" : "jpeg";
    }
}
