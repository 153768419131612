import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import {
    BasicQualification,
    OperationScenarioCategory,
    OperatorAndPilotQualification,
    QualificationStatus,
} from "../../models/operator.models";

interface QualificationBadgeComponentState {
    qualification: BasicQualification | OperatorAndPilotQualification | undefined;
}

@Component({
    selector: "dtm-ui-qualification-badge[qualification]",
    templateUrl: "./qualification-badge.component.html",
    styleUrls: ["./qualification-badge.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class QualificationBadgeComponent {
    @Input() public set qualification(value: BasicQualification | OperatorAndPilotQualification | undefined) {
        this.localStore.patchState({ qualification: value });
    }

    protected readonly qualification$ = this.localStore.selectByKey("qualification").pipe(RxjsUtils.filterFalsy());
    protected readonly OperationScenarioCategory = OperationScenarioCategory;
    protected readonly QualificationStatus = QualificationStatus;

    constructor(private readonly localStore: LocalComponentStore<QualificationBadgeComponentState>) {
        this.localStore.setState({ qualification: undefined });
    }
}
