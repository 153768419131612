<div class="dialog-header">
    <h2 mat-dialog-title>
        {{ "dtmMapCesium.manualWidthInput.widthLabel" | transloco }}
    </h2>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<form>
    <dtm-ui-expanded-number-input
        [formControl]="corridorWidthFormControl"
        [minValue]="data.minWidth"
        [maxValue]="data.maxWidth"
        [stepValue]="data.step"
        [valueSuffix]="'dtmMapCesium.manualWidthInput.widthUnitLabel' | transloco"
    >
        <div class="field-error" *dtmUiFieldHasError="corridorWidthFormControl">
            {{
                "dtmMapCesium.manualWidthInput.widthConstraintsError"
                    | transloco : { min: data.minWidth | localizeNumber, max: data.maxWidth | localizeNumber }
            }}
        </div>
    </dtm-ui-expanded-number-input>
</form>
<mat-dialog-actions align="end">
    <button class="button-secondary" type="button" [matDialogClose]="false">
        {{ "dtmMapCesium.manualWidthInput.cancelButtonLabel" | transloco }}
    </button>
    <button class="button-primary" type="button" (click)="confirm()" [disabled]="corridorWidthFormControl.invalid">
        {{ "dtmMapCesium.manualWidthInput.saveButtonLabel" | transloco }}
    </button>
</mat-dialog-actions>
