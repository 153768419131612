import { CdkAccordionModule } from "@angular/cdk/accordion";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { APP_INITIALIZER, EnvironmentProviders, ModuleWithProviders, NgModule, Provider } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import {
    LegacyDateAdapter as DateAdapter,
    MAT_LEGACY_RIPPLE_GLOBAL_OPTIONS as MAT_RIPPLE_GLOBAL_OPTIONS,
    MatLegacyPseudoCheckboxModule as MatPseudoCheckboxModule,
} from "@angular/material/legacy-core";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSliderModule } from "@angular/material/slider";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterLink } from "@angular/router";
import {
    DatesDiffPipe,
    EllipsifyTextPipe,
    FormatFileSizePipe,
    FunctionUtils,
    InvokePipe,
    InvokeThisPipe,
    SafeHtmlPipe,
} from "@dtm-frontend/shared/utils";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { DigitOnlyModule } from "@uiowa/digit-only";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxMaskModule } from "ngx-mask";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { QuillModule } from "ngx-quill";
import { EditEmailAddressFormComponent } from "./components/./edit-email-address-form/edit-email-address-form.component";
import { AccordionComponent } from "./components/accordion/accordion.component";
import { AdditionalCompetenciesComponent } from "./components/additional-competencies/additional-competencies.component";
import { AddressFormComponent } from "./components/address-form/address-form.component";
import { AddressLabelValueComponent } from "./components/address-label-value/address-label-value.component";
import { AssociationPermitBasicInfoPreviewComponent } from "./components/association-permit/association-permit-basic-info-previev/association-permit-basic-info-preview.component";
import { AssociationPermitDetailsPreviewComponent } from "./components/association-permit/association-permit-details-preview/association-permit-details-preview.component";
import { AssociationPermitPreviewComponent } from "./components/association-permit/association-permit-preview.component";
import { AvatarEditorComponent } from "./components/avatar-editor/avatar-editor.component";
import { AvatarComponent } from "./components/avatar/avatar.component";
import { CardHeaderComponent } from "./components/card-header/card-header.component";
import { CarouselSlidesDirective } from "./components/carousel/carousel-slides.directive";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { CheckboxFieldComponent } from "./components/checkbox-field/checkbox-field.component";
import { CheckboxGroupComponent } from "./components/checkbox-group/checkbox-group.component";
import { ChipsFieldComponent } from "./components/chips-field/chips-field.component";
import { ContentPopoverComponent } from "./components/chips-popover/content-popover.component";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { ConversationsCategoryMultiSelectComponent } from "./components/conversations/conversation-category-multi-select/conversations-category-multi-select.component";
import { MessagesInThreadComponent } from "./components/conversations/messages-in-thread/messages-in-thread.component";
import { NewMessageActionsComponent } from "./components/conversations/new-message-actions/new-message-actions.component";
import { NewMessageEditorComponent } from "./components/conversations/new-message-editor/new-message-editor.component";
import { NewThreadCategorySubjectFormComponent } from "./components/conversations/new-thread-category-subject-form/new-thread-category-subject-form.component";
import { OperatorsThreadListComponent } from "./components/conversations/operators-thread-list/operators-thread-list.component";
import { SearchThreadComponent } from "./components/conversations/search-thread/search-thread.component";
import { ThreadListItemIconComponent } from "./components/conversations/thread-list-item-icon/thread-list-item-icon.component";
import { CookiesPopupComponent } from "./components/cookies-popup/cookies-popup.component";
import { CountryDisplayComponent } from "./components/country-display/country-display.component";
import { CountryFlagDisplayComponent } from "./components/country-flag-display/country-flag-display.component";
import { CountrySelectFieldComponent } from "./components/country-select-field/country-select-field.component";
import { DarkModeToggleComponent } from "./components/dark-mode-toggle/dark-mode-toggle.component";
import { CustomDateAdapter } from "./components/date-field/custom-date-adapter.service";
import { DateFieldComponent } from "./components/date-field/date-field.component";
import { DateRangeDisplayComponent } from "./components/date-range-display/date-range-display.component";
import { DetailsComponent } from "./components/details/details.component";
import { EditEmailAddressComponent } from "./components/edit-email-address/edit-email-address.component";
import { EditPhoneNumberFormComponent } from "./components/edit-phone-number-form/edit-phone-number-form.component";
import { EditPhoneNumberComponent } from "./components/edit-phone-number/edit-phone-number.component";
import { EditableBoxComponent } from "./components/editable-box/editable-box.component";
import { EditableCardComponent } from "./components/editable-card/editable-card.component";
import { EmptyStateComponent } from "./components/empty-state/empty-state.component";
import { ErrorPageComponent } from "./components/error-page/error-page.component";
import { ErrorComponent } from "./components/error/error.component";
import { ExpandablePanelComponent } from "./components/expandable-panel/expandable-panel.component";
import { ExpandableSimpleHeaderComponent } from "./components/expandable-panel/expandable-simple-header/expandable-simple-header.component";
import { ExpandableExtendedHeaderComponent } from "./components/expandable-panel/extended-expandable-header/expandable-extended-header.component";
import { ExpandedNumberInputComponent } from "./components/expanded-number-input/expanded-number-input.component";
import { FilesGroupsDisplayComponent } from "./components/files-groups/files-groups-display/files-groups-display.component";
import { FilesGroupsUploadComponent } from "./components/files-groups/files-groups-upload/files-groups-upload.component";
import { FilesUploadFieldComponent } from "./components/files-upload-field/files-upload-field.component";
import { FILES_UPLOAD_API_PROVIDER } from "./components/files-upload-field/files-upload.models";
import { FilesUploadComponent } from "./components/files-upload-field/files-upload/files-upload.component";
import { FilterChipsDisplayComponent } from "./components/filter-chips-display/filter-chips-display.component";
import { FiltersContainerComponent } from "./components/filters-container/filters-container.component";
import { FormErrorsComponent } from "./components/form-errors/form-errors.component";
import { FormFieldComponent } from "./components/form-field/form-field.component";
import { GenericFileDisplayComponent } from "./components/generic-file-display/generic-file-display.component";
import { HeaderUserButtonComponent } from "./components/header-user-button/header-user-button.component";
import { HeaderComponent } from "./components/header/header.component";
import { IconDirective } from "./components/icon/icon.directive";
import { IdentityDocumentStatusBadgeComponent } from "./components/identity-document-status-badge/identity-document-status-badge.component";
import { ImagePickerComponent } from "./components/image-picker/image-picker.component";
import { InfoMessageComponent } from "./components/info-message/info-message.component";
import { AutoInputIdDirective } from "./components/input-field/auto-input-id.directive";
import { DisablableInputFieldDirective } from "./components/input-field/disablable-input-field.directive";
import { InputFieldComponent } from "./components/input-field/input-field.component";
import { LabelValueComponent } from "./components/label-value/label-value.component";
import { LanguageSelectComponent } from "./components/language-select/language-select.component";
import { LoaderContainerComponent } from "./components/loader-container/loader-container.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { MissionTypeFormControlComponent } from "./components/mission-type-form-control/mission-type-form-control.component";
import { MultipleActionsComponent } from "./components/multiple-actions/multiple-actions.component";
import { NoResultsComponent } from "./components/no-results-template/no-results.component";
import { NotAuthorizedComponent } from "./components/not-authorized/not-authorized.component";
import { OperatorStatusBadgeComponent } from "./components/operator-status-badge/operator-status-badge.component";
import { OutsideDtmPanelComponent } from "./components/outside-dtm-info-panel/outside-dtm-info-panel.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { PasswordVisibilitySwitchComponent } from "./components/password-visibility-switch/password-visibility-switch.component";
import { PermitBasicDataPreviewComponent } from "./components/permit-details-preview/permit-basic-data-preview/permit-basic-data-preview.component";
import { PermitContactPersonPreviewComponent } from "./components/permit-details-preview/permit-contact-person-preview/permit-contact-person-preview.component";
import { PermitDetailsPreviewComponent } from "./components/permit-details-preview/permit-details-preview.component";
import { PermitUavDetailsPreviewComponent } from "./components/permit-details-preview/permit-uav-details-preview/permit-uav-details-preview.component";
import { PhoneNumberFieldComponent } from "./components/phone-number-field/phone-number-field.component";
import { PopoverComponent } from "./components/popover/popover.component";
import { PresentationTileWithActionComponent } from "./components/presentation-tile-with-action/presentation-tile-with-action.component";
import { ProfileAvatarComponent } from "./components/profile-avatar/profile-avatar.component";
import { ProfileLanguageComponent } from "./components/profile-language/profile-language.component";
import { QualificationBadgeComponent } from "./components/qualification-badge/qualification-badge.component";
import { QuillEditorComponent } from "./components/quill-editor/quill-editor.component";
import { QuillEditorToolbarComponent } from "./components/quill-toolbar/quill-toolbar.component";
import { RadioFieldComponent } from "./components/radio-field/radio-field.component";
import { RadioGroupDirective } from "./components/radio-field/radio-group.directive";
import { RadioTileGroupComponent } from "./components/radio-tile-group/radio-tile-group.component";
import { RadioTileComponent } from "./components/radio-tile/radio-tile.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { MobileTableDirective } from "./components/responsive-table/mobile-table.directive";
import { ResponsiveTableComponent } from "./components/responsive-table/responsive-table.component";
import { RiskLevelsComponent } from "./components/risk-levels/risk-levels.component";
import { RouteDetailsComponent } from "./components/route-details/route-details.component";
import { SearchControlComponent } from "./components/search-control/search-control.component";
import { CustomOptionComponent } from "./components/select-field/custom-option/custom-option.component";
import { SelectFieldComponent } from "./components/select-field/select-field.component";
import { SelectOptionComponent } from "./components/select-field/select-option/select-option.component";
import { SerialNumbersControlComponent } from "./components/serial-numbers-control/serial-numbers-control.component";
import { SliderComponent } from "./components/slider/slider.component";
import { WizardStepWrapperComponent } from "./components/step-wrapper/step-wrapper.component";
import { TextareaFieldComponent } from "./components/textarea-field/textarea-field.component";
import { TimeDiffDisplayComponent } from "./components/time-display/time-diff-display.component";
import { TimeFieldComponent } from "./components/time-field/time-field.component";
import { TimeRangeDisplayComponent } from "./components/time-range-display/time-range-display.component";
import { TimeSetterComponent } from "./components/time-setter/time-setter.component";
import { TrianglePointerSvgComponent } from "./components/triangle-pointer-svg/triangle-pointer-svg.component";
import { UploadedFilesDisplayComponent } from "./components/uploaded-files-display/uploaded-files-display.component";
import { UserContactComponent } from "./components/user-contact/user-contact.component";
import { VerificationCodeComponent } from "./components/verification-code/verification-code.component";
import { WebpageFooterComponent } from "./components/webpage-footer/webpage-footer.component";
import { ZoomImageDirective } from "./components/zoom-image/zoom-image.directive";
import { ZoomImageComponent } from "./components/zoom-image/zoom-image/zoom-image.component";
import { ZoomInputComponent } from "./components/zoom-input/zoom-input.component";
import { ClampInputDirective } from "./directives/clamp-input.directive";
import { ClickActivatedTooltipDirective } from "./directives/click-activated-tooltip.directive";
import { DateRangeValidatorsFixerDirective } from "./directives/date-range-validators-fixer.directive";
import { DecimalDigitsLimiterDirective } from "./directives/decimal-digits-limiter.directive";
import { ElementObserverDirective } from "./directives/element-observer.directive";
import { FieldHasErrorDirective } from "./directives/field-has-error.directive";
import { FileDropzoneDirective } from "./directives/file-dropzone.directive";
import { HorizontalScrollDirective } from "./directives/horizontal-scroll-directive.directive";
import { ImgSecuredSrcDirective } from "./directives/img-secured-src.directive";
import { InvalidFormControlScrollHookDirective, InvalidFormScrollableDirective } from "./directives/invalid-form-scrollable.directive";
import { MarkValueAccessorControlsAsTouchedDirective } from "./directives/mark-value-accessor-controls-as-touched.directive";
import { ScrollHookDirective } from "./directives/scroll-hook.directive";
import { ScrollToAfterInitDirective } from "./directives/scroll-to-after-init.directive";
import { ScrollTrackerDirective } from "./directives/scroll-tracker.directive";
import { SoundDefinition } from "./models/sounds.models";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "./modules/i18n";
import { SharedToastModule } from "./modules/toast/shared-toast.module";
import { ArcLabelPipe } from "./pipes/arc-label.pipe";
import { ConvertToKilometersPipe } from "./pipes/convert-to-kilometers.pipe";
import { FormatPhoneNumberPipe } from "./pipes/display-phone-number.pipe";
import { IdentityDocumentTypePipe } from "./pipes/document-type.pipe";
import { DurationPipe } from "./pipes/duration.pipe";
import { EmailsToClickablePipe } from "./pipes/emails-to-clickable.pipe";
import { FinalRiskPipe } from "./pipes/final-risk.pipe";
import { DistancePipe } from "./pipes/format-distance.pipe";
import { GrcLevelPipe } from "./pipes/grc-level.pipe";
import { ControlledAreaGrlLevelPipe, GrlLevelPipe } from "./pipes/grl-level.pipe";
import { IsEmptyArrayPipe } from "./pipes/is-empty-array.pipe";
import { IsEmptyElementPipe } from "./pipes/is-empty-element.pipe";
import { JoinPipe } from "./pipes/join.pipe";
import { MathPipe } from "./pipes/math.pipe";
import { MessageDateDisplayPipe } from "./pipes/message-date-display";
import { PhoneNumbersToClickablePipe } from "./pipes/phone-numbers-to-clickable.pipe";
import { PostCodePipe } from "./pipes/postcode.pipe";
import { RoundNumberPipe } from "./pipes/round-number.pipe";
import { SailLevelPipe } from "./pipes/sail-level.pipe";
import { ToTypePipe } from "./pipes/to-type.pipe";
import { UrlsToClickablePipe } from "./pipes/urls-to-clickable.pipe";
import { SOUND_DEFINITIONS_TOKEN, SoundService } from "./services/sounds/sound.service";
import { UIStateSyncService } from "./services/ui-state-sync/ui-state-sync.service";
import { UIState } from "./state/ui.state";
import { quillConfig } from "./utils/quill-config";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatTooltipModule,
        NgxMatSelectSearchModule,
        NgxsModule.forFeature([UIState]),
        LetModule,
        PushModule,
        ReactiveFormsModule,
        SharedI18nModule,
        SharedToastModule.forRoot(),
        MatDialogModule,
        MatSliderModule,
        OverlayModule,
        MatPseudoCheckboxModule,
        PortalModule,
        MatMenuModule,
        MatChipsModule,
        MatListModule,
        QuillModule.forRoot(quillConfig),
        ClipboardModule,
        MatCardModule,
        IconDirective,
        RouterLink,
        NgxMaskModule.forRoot(),
        ImageCropperModule,
        MatSlideToggleModule,
        CdkAccordionModule,
        DigitOnlyModule,
        EmailsToClickablePipe,
        PhoneNumbersToClickablePipe,
        UrlsToClickablePipe,
    ],
    declarations: [
        FileDropzoneDirective,
        FormatFileSizePipe,
        GenericFileDisplayComponent,
        CountryDisplayComponent,
        DateRangeDisplayComponent,
        FormatPhoneNumberPipe,
        CountryFlagDisplayComponent,
        DurationPipe,
        InvokePipe,
        ClickActivatedTooltipDirective,
        TimeDiffDisplayComponent,
        ConfirmationDialogComponent,
        DecimalDigitsLimiterDirective,
        NoResultsComponent,
        MessageDateDisplayPipe,
        FormFieldComponent,
        InputFieldComponent,
        AutoInputIdDirective,
        TextareaFieldComponent,
        RadioFieldComponent,
        RadioGroupDirective,
        CheckboxFieldComponent,
        SelectFieldComponent,
        SelectOptionComponent,
        DateFieldComponent,
        SafeHtmlPipe,
        InfoMessageComponent,
        ExpandablePanelComponent,
        HeaderComponent,
        TimeRangeDisplayComponent,
        ExpandableSimpleHeaderComponent,
        ExpandableExtendedHeaderComponent,
        FieldHasErrorDirective,
        TimeSetterComponent,
        TimeFieldComponent,
        DatesDiffPipe,
        ScrollHookDirective,
        InvalidFormControlScrollHookDirective,
        InvalidFormScrollableDirective,
        FormErrorsComponent,
        CountrySelectFieldComponent,
        CustomOptionComponent,
        ChipsFieldComponent,
        CheckboxGroupComponent,
        HeaderUserButtonComponent,
        FilesUploadComponent,
        FilesUploadFieldComponent,
        ImagePickerComponent,
        OperatorsThreadListComponent,
        ThreadListItemIconComponent,
        UploadedFilesDisplayComponent,
        MessagesInThreadComponent,
        PhoneNumberFieldComponent,
        NewMessageEditorComponent,
        ScrollToAfterInitDirective,
        NewThreadCategorySubjectFormComponent,
        FilterChipsDisplayComponent,
        SearchThreadComponent,
        SliderComponent,
        IsEmptyElementPipe,
        IsEmptyArrayPipe,
        ConversationsCategoryMultiSelectComponent,
        PaginationComponent,
        PopoverComponent,
        FiltersContainerComponent,
        ErrorComponent,
        AddressLabelValueComponent,
        LabelValueComponent,
        LoaderComponent,
        PresentationTileWithActionComponent,
        QualificationBadgeComponent,
        PageNotFoundComponent,
        ClampInputDirective,
        LoaderContainerComponent,
        DisablableInputFieldDirective,
        ResponsiveTableComponent,
        MobileTableDirective,
        EditableBoxComponent,
        EditEmailAddressComponent,
        EditPhoneNumberComponent,
        AvatarComponent,
        MarkValueAccessorControlsAsTouchedDirective,
        UserContactComponent,
        CardHeaderComponent,
        EditableCardComponent,
        WizardStepWrapperComponent,
        MissionTypeFormControlComponent,
        LanguageSelectComponent,
        ProfileLanguageComponent,
        ResetPasswordComponent,
        ProfileAvatarComponent,
        AvatarEditorComponent,
        MultipleActionsComponent,
        QuillEditorToolbarComponent,
        AdditionalCompetenciesComponent,
        QuillEditorComponent,
        PasswordVisibilitySwitchComponent,
        DetailsComponent,
        ImgSecuredSrcDirective,
        EmptyStateComponent,
        RouteDetailsComponent,
        PermitBasicDataPreviewComponent,
        PermitDetailsPreviewComponent,
        PermitUavDetailsPreviewComponent,
        PostCodePipe,
        ArcLabelPipe,
        SailLevelPipe,
        FinalRiskPipe,
        GrcLevelPipe,
        GrlLevelPipe,
        ControlledAreaGrlLevelPipe,
        PermitContactPersonPreviewComponent,
        IdentityDocumentTypePipe,
        AddressFormComponent,
        IdentityDocumentStatusBadgeComponent,
        OperatorStatusBadgeComponent,
        FilesGroupsDisplayComponent,
        FilesGroupsUploadComponent,
        AssociationPermitPreviewComponent,
        AssociationPermitBasicInfoPreviewComponent,
        AssociationPermitDetailsPreviewComponent,
        AssociationPermitDetailsPreviewComponent,
        SerialNumbersControlComponent,
        ZoomInputComponent,
        ExpandedNumberInputComponent,
        TrianglePointerSvgComponent,
        CarouselComponent,
        CarouselSlidesDirective,
        EditEmailAddressFormComponent,
        EditPhoneNumberFormComponent,
        AccordionComponent,
        ScrollTrackerDirective,
        HorizontalScrollDirective,
        NewMessageActionsComponent,
        EllipsifyTextPipe,
        RoundNumberPipe,
        ConvertToKilometersPipe,
        RadioTileComponent,
        RadioTileGroupComponent,
        WebpageFooterComponent,
        ElementObserverDirective,
        DateRangeValidatorsFixerDirective,
        CookiesPopupComponent,
        ZoomImageDirective,
        ZoomImageComponent,
        SearchControlComponent,
        ErrorPageComponent,
        ContentPopoverComponent,
        NotAuthorizedComponent,
        DistancePipe,
        VerificationCodeComponent,
        OutsideDtmPanelComponent,
        RiskLevelsComponent,
        DarkModeToggleComponent,
        JoinPipe,
        MathPipe,
        ToTypePipe,
        InvokeThisPipe,
    ],
    providers: [
        {
            provide: MAT_RIPPLE_GLOBAL_OPTIONS,
            useValue: { disabled: true },
        },
        UIStateSyncService,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmUi",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
            },
        },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
        },
        {
            provide: FILES_UPLOAD_API_PROVIDER,
            useValue: undefined,
        },
        {
            provide: ErrorStateMatcher,
            useClass: ShowOnDirtyErrorStateMatcher,
        },
    ],
    exports: [
        FileDropzoneDirective,
        FormatFileSizePipe,
        DurationPipe,
        GenericFileDisplayComponent,
        CountryDisplayComponent,
        DateRangeDisplayComponent,
        FormatPhoneNumberPipe,
        CountryFlagDisplayComponent,
        InvokePipe,
        InvokeThisPipe,
        ClickActivatedTooltipDirective,
        TimeDiffDisplayComponent,
        ConfirmationDialogComponent,
        DecimalDigitsLimiterDirective,
        NoResultsComponent,
        MessageDateDisplayPipe,
        FormFieldComponent,
        InputFieldComponent,
        AutoInputIdDirective,
        TextareaFieldComponent,
        RadioFieldComponent,
        RadioGroupDirective,
        CheckboxFieldComponent,
        SelectFieldComponent,
        SelectOptionComponent,
        DateFieldComponent,
        SafeHtmlPipe,
        InfoMessageComponent,
        ExpandablePanelComponent,
        HeaderComponent,
        TimeRangeDisplayComponent,
        ExpandableSimpleHeaderComponent,
        ExpandableExtendedHeaderComponent,
        FieldHasErrorDirective,
        TimeSetterComponent,
        TimeFieldComponent,
        DatesDiffPipe,
        ScrollHookDirective,
        InvalidFormControlScrollHookDirective,
        InvalidFormScrollableDirective,
        FormErrorsComponent,
        CountrySelectFieldComponent,
        CustomOptionComponent,
        ChipsFieldComponent,
        CheckboxGroupComponent,
        HeaderUserButtonComponent,
        FilesUploadComponent,
        FilesUploadFieldComponent,
        ImagePickerComponent,
        ThreadListItemIconComponent,
        OperatorsThreadListComponent,
        UploadedFilesDisplayComponent,
        MessagesInThreadComponent,
        PhoneNumberFieldComponent,
        NewMessageEditorComponent,
        ScrollToAfterInitDirective,
        NewThreadCategorySubjectFormComponent,
        FilterChipsDisplayComponent,
        SearchThreadComponent,
        IsEmptyElementPipe,
        IsEmptyArrayPipe,
        ConversationsCategoryMultiSelectComponent,
        SliderComponent,
        IconDirective,
        PaginationComponent,
        PopoverComponent,
        FiltersContainerComponent,
        ErrorComponent,
        AddressLabelValueComponent,
        LabelValueComponent,
        LoaderComponent,
        PresentationTileWithActionComponent,
        QualificationBadgeComponent,
        PageNotFoundComponent,
        ClampInputDirective,
        LoaderContainerComponent,
        DisablableInputFieldDirective,
        ResponsiveTableComponent,
        MobileTableDirective,
        EditableBoxComponent,
        EditEmailAddressComponent,
        EditPhoneNumberComponent,
        AvatarComponent,
        MarkValueAccessorControlsAsTouchedDirective,
        UserContactComponent,
        CardHeaderComponent,
        EditableCardComponent,
        LanguageSelectComponent,
        ProfileLanguageComponent,
        ResetPasswordComponent,
        ProfileAvatarComponent,
        AvatarEditorComponent,
        MultipleActionsComponent,
        QuillEditorToolbarComponent,
        WizardStepWrapperComponent,
        MissionTypeFormControlComponent,
        AdditionalCompetenciesComponent,
        QuillEditorComponent,
        PasswordVisibilitySwitchComponent,
        DetailsComponent,
        ImgSecuredSrcDirective,
        RouteDetailsComponent,
        EmptyStateComponent,
        PermitBasicDataPreviewComponent,
        PermitDetailsPreviewComponent,
        PermitUavDetailsPreviewComponent,
        ArcLabelPipe,
        SailLevelPipe,
        FinalRiskPipe,
        GrcLevelPipe,
        GrlLevelPipe,
        ControlledAreaGrlLevelPipe,
        PermitContactPersonPreviewComponent,
        IdentityDocumentTypePipe,
        AddressFormComponent,
        IdentityDocumentStatusBadgeComponent,
        PostCodePipe,
        OperatorStatusBadgeComponent,
        FilesGroupsDisplayComponent,
        FilesGroupsUploadComponent,
        AssociationPermitPreviewComponent,
        AssociationPermitBasicInfoPreviewComponent,
        AssociationPermitDetailsPreviewComponent,
        SerialNumbersControlComponent,
        ZoomInputComponent,
        ExpandedNumberInputComponent,
        TrianglePointerSvgComponent,
        CarouselComponent,
        CarouselSlidesDirective,
        EditEmailAddressFormComponent,
        EditPhoneNumberFormComponent,
        AccordionComponent,
        ScrollTrackerDirective,
        HorizontalScrollDirective,
        NewMessageActionsComponent,
        EllipsifyTextPipe,
        RoundNumberPipe,
        ConvertToKilometersPipe,
        RadioTileComponent,
        RadioTileGroupComponent,
        WebpageFooterComponent,
        ElementObserverDirective,
        DateRangeValidatorsFixerDirective,
        CookiesPopupComponent,
        ZoomImageDirective,
        ZoomImageComponent,
        EmailsToClickablePipe,
        PhoneNumbersToClickablePipe,
        UrlsToClickablePipe,
        SearchControlComponent,
        ContentPopoverComponent,
        NotAuthorizedComponent,
        DistancePipe,
        VerificationCodeComponent,
        OutsideDtmPanelComponent,
        RiskLevelsComponent,
        DarkModeToggleComponent,
        JoinPipe,
        MathPipe,
        ToTypePipe,
    ],
})
export class SharedUiModule {
    public static forRoot({ soundDefinitions }: { soundDefinitions: SoundDefinition }): ModuleWithProviders<SharedUiModule> {
        const providers: (Provider | EnvironmentProviders)[] = [];
        if (soundDefinitions) {
            providers.push(
                {
                    provide: APP_INITIALIZER,
                    useFactory: () => FunctionUtils.noop,
                    deps: [SoundService],
                    multi: true,
                },
                {
                    provide: SOUND_DEFINITIONS_TOKEN,
                    useValue: soundDefinitions,
                }
            );
        }

        return {
            ngModule: SharedUiModule,
            providers,
        };
    }
}
