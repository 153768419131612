import { Injectable } from "@angular/core";
import { Action, NgxsOnInit, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, finalize } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "../services/auth.service";
import { AuthActions } from "./auth.actions";

export interface AuthStateModel {
    isLoggedIn: boolean | undefined;
    userFirstName: string;
    userLastName: string;
    userId: string;
    roles: string[] | undefined;
    token: string | undefined;
    refreshToken: string | undefined;
    isProcessing: boolean;
    hasLoginError: boolean;
}

export const DEFAULT_AUTH_STATE = {
    isLoggedIn: undefined,
    userFirstName: "",
    userLastName: "",
    userId: "",
    roles: undefined,
    token: undefined,
    refreshToken: undefined,
    isProcessing: false,
    hasLoginError: false,
};

@State<AuthStateModel>({
    name: "auth",
    defaults: DEFAULT_AUTH_STATE,
})
@Injectable()
export class AuthState implements NgxsOnInit {
    constructor(private readonly authService: AuthService) {}

    @Selector()
    public static isLoggedIn(state: AuthStateModel): boolean | undefined {
        return state.isLoggedIn;
    }

    @Selector()
    public static userFirstName(state: AuthStateModel): string {
        return state.userFirstName;
    }

    @Selector()
    public static userLastName(state: AuthStateModel): string {
        return state.userLastName;
    }

    @Selector()
    public static userId(state: AuthStateModel): string {
        return state.userId;
    }

    @Selector()
    public static roles(state: AuthStateModel): string[] | undefined {
        return state.roles;
    }

    @Selector()
    public static token(state: AuthStateModel): string | undefined {
        return state.token;
    }

    @Selector()
    public static isProcessing(state: AuthStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static hasLoginError(state: AuthStateModel): boolean {
        return state.hasLoginError;
    }

    public async ngxsOnInit(context: StateContext<AuthStateModel>) {
        this.authService.init(context);
    }

    @Action(AuthActions.GoToLoginPage)
    public goToLoginPage(context: StateContext<AuthStateModel>, action: AuthActions.GoToLoginPage): void {
        this.authService.goToLoginPage(action.redirectPath);
    }

    @Action(AuthActions.LogIn, { cancelUncompleted: true })
    public logIn(context: StateContext<AuthStateModel>, action: AuthActions.LogIn) {
        context.patchState({ isProcessing: true, hasLoginError: false });

        return this.authService.logIn(context, action.username, action.password).pipe(
            catchError(() => {
                context.patchState({ hasLoginError: true });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(AuthActions.UpdateToken)
    public updateToken(context: StateContext<AuthStateModel>) {
        return this.authService.updateToken(context);
    }

    @Action(AuthActions.Logout)
    public logout(context: StateContext<AuthStateModel>): void {
        this.authService.logOut(context);
    }
}
