/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from "@angular/core";
import { CesiumService } from "@pansa/ngx-cesium";

declare const Cesium: any; // TODO: DTM-966;

@Injectable()
export class GeometryDebuggerService {
    private debuggingViewer: any | undefined = undefined;

    constructor(private readonly cesiumService: CesiumService) {}

    public beginDebugging() {
        if (this.debuggingViewer !== undefined) {
            console.warn("Already debugging - may cause issues when used in multiple places");
        } else {
            this.debuggingViewer = this.cesiumService.getViewer();
        }

        if (this.debuggingViewer === undefined) {
            throw new Error("No viewer found");
        }

        this.debuggingViewer.entities.suspendEvents();
        this.debuggingViewer.entities.removeAll();
    }

    public drawCircle(center: any, color?: string, radius?: number) {
        if (this.debuggingViewer === undefined) {
            throw new Error("Debugging not started");
        }

        this.debuggingViewer.entities.add({
            position: center,
            ellipse: {
                semiMinorAxis: radius,
                semiMajorAxis: radius,
                material: color ? Cesium.Color.fromCssColorString(color) : Cesium.Color.fromRandom(),
            },
        });
    }

    public endDebugging() {
        this.debuggingViewer?.entities.resumeEvents();
        this.debuggingViewer = undefined;
    }
}

/** USAGE:
    @Component({
        ...
        providers: [LocalComponentStore, GeometryDebuggerService],
        ...
    })

    ...

        this.geometryDebuggerService.beginDebugging();

        allPoints.forEach((point) => {
            this.geometryDebuggerService.drawCircle(point, "rgba(0, 0, 255, 0.5)", 5);
        });

        this.geometryDebuggerService.drawCircle(center, "rgba(255, 0, 0, 0.5)", Math.max(enclosingCircle.radius, 10));
        this.geometryDebuggerService.drawCircle(
            Cesium.Cartesian3.fromDegrees(
                enclosingCircle.center[0] + enclosingCircle.radius,
                enclosingCircle.center[1] + enclosingCircle.radius
            ),
            "rgba(0, 255, 0, 0.5)",
            10
        );

        this.geometryDebuggerService.endDebugging();
*/
