<dtm-ui-form-field [isDisabled]="isDisabled$ | ngrxPush" [isActivated]="isActivated" *ngrxLet="isActivated$; let isActivated">
    <ng-content select="label" ngProjectAs="label"></ng-content>
    <div class="date-field-wrapper">
        <ng-content select=".field-prefix"></ng-content>
        <div class="date-field" [class.activated]="isActivated">
            <ng-content></ng-content>
        </div>
        <button
            type="button"
            tabindex="-1"
            class="field-suffix clear-button"
            *ngIf="isClearButtonVisible$ | ngrxPush"
            (click)="clearValue()"
        >
            <dtm-ui-icon name="close-circle-fill"></dtm-ui-icon>
        </button>
        <button type="button" class="field-suffix trigger-button" (click)="datepicker.open()">
            <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
        </button>
        <ng-content select=".field-suffix"></ng-content>
    </div>

    <ng-content select=".field-hint" ngProjectAs=".field-hint"></ng-content>
    <ng-content select=".field-error" ngProjectAs=".field-error"></ng-content>
</dtm-ui-form-field>
