<ng-container *ngrxLet="flightZonePurposes$; let flightZonePurposes">
    <ng-container *ngIf="!!flightZonePurposes.length; else cannotGetDataErrorTemplate">
        <dss-client-lib-wizard-step-wrapper
            [stepNumber]="1"
            [stepsAmount]="4"
            [title]="'dssClientLibFlightZone.flightZoneWizardSteps.basicZoneInfo.header' | transloco"
            *ngrxLet="{ isProcessing: isProcessing$, datePickerPlaceholder: datePickerPlaceholder$ } as vm"
        >
            <form [formGroup]="flightZoneBasicDataForm" dtmUiInvalidFormScrollable>
                <dtm-ui-input-field>
                    <label for="caseNumberControl"
                        >{{ "dssSharedLibFlightZone.applicationSummary.caseNumberLabel" | transloco }}
                        <dtm-ui-popover
                            [popoverText]="'dssClientLibFlightZone.dataFormStep.caseNumberHint' | transloco"
                            (click)="$event.preventDefault()"
                        ></dtm-ui-popover>
                    </label>
                    <input
                        matInput
                        type="text"
                        autocomplete="off"
                        [maxlength]="MAX_CUSTOM_SIGNATURE_LENGTH"
                        name="caseNumber"
                        formControlName="caseNumber"
                        id="caseNumberControl"
                    />
                </dtm-ui-input-field>

                <dtm-ui-select-field formControlName="flightZonePurpose" id="flightZonePurposeControl" [isClearable]="false">
                    <label for="flightZonePurposeControl"
                        >{{ "dssSharedLibFlightZone.applicationSummary.flightZonePurposeLabel" | transloco }}
                        <dtm-ui-popover
                            [popoverText]="'dssClientLibFlightZone.dataFormStep.flightZonePurposeHint' | transloco"
                            (click)="$event.preventDefault()"
                        ></dtm-ui-popover>
                    </label>
                    <dtm-ui-select-option [value]="purpose.purpose" *ngFor="let purpose of flightZonePurposes">
                        {{ "dssSharedLibFlightZone.flightZoneApplicationPurposes.purposeLabel" | transloco : { value: purpose.purpose } }}
                    </dtm-ui-select-option>
                    <div class="field-error" *dtmUiFieldHasError="flightZoneBasicDataForm.controls.flightZonePurpose; name: 'required'">
                        {{ "dssClientLibFlightZone.requiredFieldErrorHint" | transloco }}
                    </div>
                </dtm-ui-select-field>

                <dtm-ui-textarea-field [maxLength]="MAX_TITLE_LENGTH">
                    <label for="titleControl"
                        >{{ "dssSharedLibFlightZone.applicationSummary.titleLabel" | transloco }}
                        <dtm-ui-popover
                            [popoverText]="'dssClientLibFlightZone.dataFormStep.titleHint' | transloco"
                            (click)="$event.preventDefault()"
                        ></dtm-ui-popover>
                    </label>
                    <textarea
                        class="title-textarea"
                        matInput
                        required
                        [maxlength]="MAX_TITLE_LENGTH"
                        [rows]="3"
                        name="title"
                        formControlName="title"
                        id="titleControl"
                    ></textarea>
                    <div class="field-error" *dtmUiFieldHasError="flightZoneBasicDataForm.controls.title; name: 'required'">
                        {{ "dssClientLibFlightZone.requiredFieldErrorHint" | transloco }}
                    </div>
                </dtm-ui-textarea-field>

                <dtm-ui-checkbox-field
                    *ngIf="!!flightZoneBasicDataForm.controls.hasStateSecurityRestriction"
                    formControlName="hasStateSecurityRestriction"
                    (change)="updateMinimumDatesValidation()"
                >
                    <span class="long-label">
                        {{ "dssClientLibFlightZone.dataFormStep.stateSecurityRestrictionLabel" | transloco }}
                    </span>
                    <dtm-ui-popover
                        [popoverText]="'dssClientLibFlightZone.dataFormStep.stateSecurityRestrictionHint' | transloco"
                        (click)="$event.preventDefault()"
                    ></dtm-ui-popover>
                </dtm-ui-checkbox-field>

                <ng-container *ngIf="maxStartDate$ | ngrxPush; let maxStartDate">
                    <ng-container *ngIf="minStartDate$ | ngrxPush; let minStartDate">
                        <dtm-ui-date-field [isClearable]="false">
                            <label for="startDateControl">{{ "dssClientLibFlightZone.dataFormStep.startDateLabel" | transloco }} </label>
                            <input
                                id="startDateControl"
                                matInput
                                formControlName="startDate"
                                [matDatepicker]="startDateTimePicker"
                                [min]="minStartDate"
                                [max]="maxStartDate"
                                [placeholder]="vm.datePickerPlaceholder"
                            />
                            <mat-datepicker #startDateTimePicker></mat-datepicker>
                            <div class="field-error">
                                <ng-container
                                    [ngTemplateOutlet]="datePickerErrorsTemplate"
                                    [ngTemplateOutletContext]="{ dateControl: flightZoneBasicDataForm.controls.startDate }"
                                ></ng-container>
                            </div>
                        </dtm-ui-date-field>

                        <dtm-ui-time-field
                            formControlName="startTime"
                            [minTime]="minStartDate"
                            [maxTime]="maxStartDate"
                            [referenceDate]="flightZoneBasicDataForm.controls.startDate.value"
                        >
                            <label
                                >{{ "dssClientLibFlightZone.dataFormStep.startTimeLabel" | transloco }}
                                <dtm-ui-popover [popoverText]="'dssClientLibFlightZone.dataFormStep.timeHint' | transloco"></dtm-ui-popover>
                            </label>
                            <div class="field-error">
                                <ng-container
                                    [ngTemplateOutlet]="datePickerErrorsTemplate"
                                    [ngTemplateOutletContext]="{ dateControl: flightZoneBasicDataForm.controls.startTime }"
                                ></ng-container>
                            </div>
                        </dtm-ui-time-field>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="maxEndDate$ | ngrxPush; let maxEndDate">
                    <ng-container *ngIf="minEndDate$ | ngrxPush; let minEndDate">
                        <dtm-ui-date-field [isClearable]="false">
                            <label for="endDateControl">
                                {{ "dssClientLibFlightZone.dataFormStep.endDateLabel" | transloco }}
                            </label>
                            <input
                                id="endDateControl"
                                matInput
                                formControlName="endDate"
                                [matDatepicker]="endDatePicker"
                                [min]="minEndDate"
                                [max]="maxEndDate"
                                [placeholder]="vm.datePickerPlaceholder"
                            />
                            <mat-datepicker #endDatePicker></mat-datepicker>
                            <div class="field-error">
                                <ng-container
                                    [ngTemplateOutlet]="datePickerErrorsTemplate"
                                    [ngTemplateOutletContext]="{ dateControl: flightZoneBasicDataForm.controls.endDate }"
                                ></ng-container>
                            </div>
                        </dtm-ui-date-field>

                        <dtm-ui-time-field
                            formControlName="endTime"
                            [minTime]="minEndDate"
                            [maxTime]="maxEndDate"
                            [referenceDate]="flightZoneBasicDataForm.controls.endDate.value"
                        >
                            <label
                                >{{ "dssClientLibFlightZone.dataFormStep.endTimeLabel" | transloco }}
                                <dtm-ui-popover [popoverText]="'dssClientLibFlightZone.dataFormStep.timeHint' | transloco"></dtm-ui-popover>
                            </label>
                            <div class="field-error">
                                <ng-container
                                    [ngTemplateOutlet]="datePickerErrorsTemplate"
                                    [ngTemplateOutletContext]="{ dateControl: flightZoneBasicDataForm.controls.endTime }"
                                ></ng-container>
                            </div>
                        </dtm-ui-time-field>
                    </ng-container>
                </ng-container>

                <dtm-ui-checkbox-field *ngIf="isPreTacticalApprovalEnabled" formControlName="isPreTacticalApproval">
                    {{ "dssClientLibFlightZone.dataFormStep.preTacticalApprovalLabel" | transloco }}
                    <dtm-ui-popover
                        [popoverText]="'dssClientLibFlightZone.dataFormStep.preTacticalApprovalHint' | transloco"
                        (click)="$event.preventDefault()"
                    ></dtm-ui-popover>
                </dtm-ui-checkbox-field>

                <dss-shared-lib-text-editor-section [text]="descriptionValue$ | ngrxPush" (dialogOpen)="openDescriptionDialog($event)">
                    <h4 sectionTitle>
                        {{ "dssClientLibFlightZone.dataFormStep.descriptionLabel" | transloco }}
                        <dtm-ui-popover
                            [popoverText]="'dssClientLibFlightZone.dataFormStep.descriptionTooltip' | transloco"
                        ></dtm-ui-popover>
                    </h4>
                </dss-shared-lib-text-editor-section>
            </form>

            <ng-container actions>
                <button [disabled]="vm.isProcessing" type="button" class="button-primary" (click)="goToNextStep()">
                    {{ "dssClientLibFlightZone.dataFormStep.nextButtonLabel" | transloco }}
                </button>
            </ng-container>
        </dss-client-lib-wizard-step-wrapper>
    </ng-container>
</ng-container>

<ng-template #datePickerErrorsTemplate let-dateControl="dateControl">
    <div *dtmUiFieldHasError="dateControl; name: 'min'; error as error">
        {{
            "dssClientLibFlightZone.dataFormStep.minDateTimeHint"
                | transloco
                    : {
                          minDate: error.min | localizeDate : { dateStyle: "short", timeStyle: "short" }
                      }
        }}
    </div>
    <div *dtmUiFieldHasError="dateControl; name: 'matDatepickerMin'; error as error">
        {{
            "dssClientLibFlightZone.dataFormStep.minDateHint"
                | transloco
                    : {
                          minDate: error.min | localizeDate : { dateStyle: "short" }
                      }
        }}
    </div>
    <ng-container *dtmUiFieldHasError="dateControl; name: 'max'; error as error">
        {{
            "dssClientLibFlightZone.dataFormStep.maxDateTimeHint"
                | transloco
                    : {
                          maxDate: error.max | localizeDate : { dateStyle: "short", timeStyle: "short" }
                      }
        }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="dateControl; name: 'matDatepickerMax'; error as error">
        {{
            "dssClientLibFlightZone.dataFormStep.maxDateHint"
                | transloco
                    : {
                          maxDate: error.max | localizeDate : { dateStyle: "short" }
                      }
        }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="dateControl; name: 'requiredTouched'">
        {{ "dssClientLibFlightZone.requiredFieldErrorHint" | transloco }}
    </ng-container>
</ng-template>

<ng-template #cannotGetDataErrorTemplate>
    {{ "dssClientLibFlightZone.cannotGetDataErrorMessage" | transloco }}
</ng-template>
