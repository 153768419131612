<button class="button-icon" type="button" [matMenuTriggerFor]="menu">
    <dtm-ui-icon name="more"></dtm-ui-icon>
</button>
<mat-menu #menu="matMenu">
    <button type="button" mat-menu-item (click)="edit.emit()">
        <dtm-ui-icon name="edit"></dtm-ui-icon>
        {{ "dtmUi.multipleActions.editButtonLabel" | transloco }}
    </button>
    <button type="button" mat-menu-item (click)="delete.emit()">
        <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
        {{ "dtmUi.multipleActions.deleteButtonLabel" | transloco }}
    </button>
</mat-menu>
