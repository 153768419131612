import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Weather } from "../../../../weather/models/weather.models";

interface WeatherConditionsGridComponentState {
    selectedRange: Weather | undefined;
    isWithinDtm: boolean;
}

const CARDINAL_DIRECTIONS = 8;
const DIRECTION_DEGREES = 45;
const FULL_ANGLE = 360;
const WIND_DIRECTIONS = ["↓ N", "↙ NE", "← E", "↖ SE", "↑ S", "↗ SW", "→ W", "↘ NW"];
const CEIL_PRECISION = 10;

@Component({
    selector: "dtm-map-weather-conditions-grid[selectedRange]",
    templateUrl: "./weather-conditions-grid.component.html",
    styleUrls: ["./weather-conditions-grid.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class WeatherConditionsGridComponent {
    @Input() public set selectedRange(value: Weather | undefined) {
        this.localStore.patchState({ selectedRange: value });
    }
    @Input() public set isWithinDtm(value: BooleanInput) {
        this.localStore.patchState({ isWithinDtm: coerceBooleanProperty(value) });
    }

    protected readonly selectedRange$ = this.localStore.selectByKey("selectedRange").pipe(RxjsUtils.filterFalsy());
    protected readonly isWithinDtm$ = this.localStore.selectByKey("isWithinDtm");

    constructor(private readonly localStore: LocalComponentStore<WeatherConditionsGridComponentState>) {
        this.localStore.setState({
            selectedRange: undefined,
            isWithinDtm: false,
        });
    }

    protected getCardinalDirection(angle: number): string {
        const index = Math.round(((angle %= FULL_ANGLE) < 0 ? angle + FULL_ANGLE : angle) / DIRECTION_DEGREES) % CARDINAL_DIRECTIONS;

        return WIND_DIRECTIONS[index];
    }

    protected isLackOfRain(minRain: string, maxRain: string): boolean {
        return +minRain === 0 && +maxRain === 0;
    }

    protected roundUp(value: string): string {
        return Math.ceil(+value / CEIL_PRECISION) * CEIL_PRECISION + "";
    }
}
