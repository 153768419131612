import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ActivatedRoute } from "@angular/router";
import {
    ApplicationChangesChatComponent,
    ApplicationType,
    FlightZoneApplication,
    FlightZoneApplicationStatus,
    FlightZoneCapabilitiesState,
    FlightZoneError,
    FlightZoneErrorType,
    FlightZoneGeometryService,
    FlightZoneRestrictionActions,
    FlightZoneRestrictionState,
} from "@dtm-frontend/dss-shared-lib";
import { AuthState } from "@dtm-frontend/shared/auth";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { share, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { ZoneDurationChangeDialogData } from "../../models/flight-zone.models";
import { FLIGHT_ZONE_ID_ROUTE_PARAM_NAME } from "../../services/flight-zone.resolvers";
import { NotamModalService } from "../../services/notam-modal.service";
import { FlightZoneActions } from "../../state/flight-zone.actions";
import { FlightZoneState } from "../../state/flight-zone.state";
import { ZoneDurationChangeComponent } from "../zone-duration-change/zone-duration-change.component";

interface ApplicationPreviewComponentState {
    isChatEnabled: boolean;
}

@UntilDestroy()
@Component({
    selector: "dss-client-lib-application-preview",
    templateUrl: "./application-preview.component.html",
    styleUrls: ["./application-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, FlightZoneGeometryService],
})
export class ApplicationPreviewComponent implements OnInit, AfterViewInit, OnDestroy {
    protected readonly FlightZoneErrorType = FlightZoneErrorType;
    protected readonly FlightZoneApplicationStatus = FlightZoneApplicationStatus;
    protected readonly ApplicationType = ApplicationType;

    protected readonly userId$ = this.store.select(AuthState.userId);
    protected readonly error$ = this.store.select(FlightZoneState.error);
    protected readonly flightZoneApplicationData$ = this.store.select(FlightZoneState.applicationData);
    protected readonly isProcessing$ = this.store.select(FlightZoneState.isProcessing);
    protected readonly isChatEnabled$ = this.localStore.selectByKey("isChatEnabled");
    protected readonly chatMessages$ = this.store.select(FlightZoneState.chatMessages).pipe(share());
    protected readonly applicationType$ = this.route.data.pipe(map((data) => data.applicationType));
    protected readonly capabilities$ = this.store.select(FlightZoneCapabilitiesState.capabilities);

    constructor(
        private readonly store: Store,
        private readonly flightZoneGeometryService: FlightZoneGeometryService,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly localStore: LocalComponentStore<ApplicationPreviewComponentState>,
        private readonly route: ActivatedRoute,
        private readonly notamModalService: NotamModalService,
        private readonly matDialog: MatDialog
    ) {
        this.localStore.setState({
            isChatEnabled: false,
        });
    }

    public ngOnInit(): void {
        this.getChatMessages();
        this.toggleChatBasedOnMessagesLength();
    }

    public ngAfterViewInit(): void {
        this.initMapWithEntity();
    }

    public ngOnDestroy(): void {
        this.flightZoneGeometryService.clearGeometry();
    }

    protected zoomToGeometry(): void {
        this.flightZoneGeometryService.showEntireContent();
    }

    protected submitMessage(flightZoneId: string, comment: string, chatComponent: ApplicationChangesChatComponent): void {
        this.store
            .dispatch(new FlightZoneActions.PostChatMessage(flightZoneId, comment))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneState.error);

                if (error) {
                    this.displayErrorMessage(error);
                } else {
                    chatComponent.resetMessageInput();
                    this.toastService.success(this.transloco.translate("dssClientLibFlightZone.chatMessageSubmissionSuccessMessage"));
                }
            });
    }

    protected openNotamPreview(flightZoneId: string): void {
        this.notamModalService.openNotamPreview(flightZoneId);
    }

    protected editModificationDates(application: FlightZoneApplication): void {
        if (!application.restrictionModification || !application.flightZoneId) {
            return;
        }

        const data: ZoneDurationChangeDialogData = {
            id: application.flightZoneId,
            startAt: application.restrictionModification.startAt,
            endAt: application.restrictionModification.endAt,
            isStateSecurityRestriction: application.basicDataForm?.hasStateSecurityRestriction,
        };
        this.matDialog
            .open(ZoneDurationChangeComponent, { data })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap((zoneDurationData) => this.store.dispatch(new FlightZoneActions.EditRestrictionModification(zoneDurationData))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.toastService.success(this.transloco.translate("dssClientLibFlightZone.changeZoneDurationSuccessMessage"));
            });
    }

    protected downloadConfirmationOfApplicationSubmissionPdf(application: FlightZoneApplication): void {
        const fileName = application.basicDataForm?.title;
        const flightZoneId = application.flightZoneId;

        if (!fileName || !flightZoneId) {
            return;
        }

        this.store
            .dispatch(new FlightZoneRestrictionActions.DownloadConfirmationOfApplicationSubmissionPdf(flightZoneId, fileName))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneRestrictionState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }
            });
    }

    private initMapWithEntity(): void {
        const constraints = this.store.selectSnapshot(FlightZoneCapabilitiesState.capabilities)?.geometryConstraints;
        const restrictionAreaGeometry = this.store.selectSnapshot(FlightZoneState.applicationData).restrictionAreaGeometry;

        if (!restrictionAreaGeometry || !constraints) {
            return;
        }

        this.flightZoneGeometryService.initMapWithEntity(restrictionAreaGeometry, constraints);
    }

    private getChatMessages(): void {
        const flightZoneId: string = this.route.snapshot.paramMap.get(FLIGHT_ZONE_ID_ROUTE_PARAM_NAME) ?? "";

        this.store.dispatch(new FlightZoneActions.GetChatMessagesByFlightZoneId(flightZoneId));
    }

    private toggleChatBasedOnMessagesLength(): void {
        this.chatMessages$.pipe(untilDestroyed(this)).subscribe((messages) => {
            this.localStore.patchState({ isChatEnabled: messages.length > 0 });
        });
    }

    private displayErrorMessage(error: FlightZoneError): void {
        let errorMessage: string | undefined;

        switch (error.type) {
            case FlightZoneErrorType.NotAuthorized:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.notAuthorizedErrorMessage");
                break;
            case FlightZoneErrorType.InvalidApplicationVersion:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.invalidVersionErrorMessage");
                break;
            case FlightZoneErrorType.CannotPostChatMessage:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.cannotPostChatMessageErrorMessage");
                break;
            case FlightZoneErrorType.CannotDownloadConfirmationOfApplicationSubmissionPdf:
                errorMessage = this.transloco.translate(
                    "dssClientLibFlightZone.cannotDownloadConfirmationOfApplicationSubmissionPdfErrorMessage"
                );
                break;
            default:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.genericErrorMessage");
        }

        this.toastService.error(errorMessage);
    }
}
