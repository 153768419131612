import { isPlatformBrowser } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    Output,
    PLATFORM_ID,
} from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";

interface FormFieldComponentState {
    hasErrors: boolean;
    isDisabled: boolean;
    isFocused: boolean;
    isActivated: boolean;
    errorId: string | null;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-form-field",
    templateUrl: "./form-field.component.html",
    styleUrls: ["./form-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FormFieldComponent implements AfterViewInit {
    @Input() public set isDisabled(value: boolean | undefined) {
        this.localStore.patchState({ isDisabled: !!value });
    }

    @Input() public set isFocused(value: boolean | undefined) {
        this.localStore.patchState({ isFocused: !!value });
    }

    @Input() public set isActivated(value: boolean | undefined) {
        this.localStore.patchState({ isActivated: !!value });
    }

    @Input() public set hasErrors(value: boolean | undefined) {
        this.localStore.patchState({ hasErrors: !!value });
    }

    @Output() public contentClick = new EventEmitter<void>();

    protected hasErrors$ = this.localStore.selectByKey("hasErrors");
    protected isDisabled$ = this.localStore.selectByKey("isDisabled");
    protected isFocused$ = this.localStore.selectByKey("isFocused");
    protected isActivated$ = this.localStore.selectByKey("isActivated");

    constructor(
        private readonly localStore: LocalComponentStore<FormFieldComponentState>,
        private readonly element: ElementRef<HTMLHtmlElement>,
        @Inject(PLATFORM_ID) private platformId: string
    ) {
        this.localStore.setState({
            hasErrors: false,
            isDisabled: false,
            isFocused: false,
            isActivated: false,
            errorId: null,
        });
    }

    public ngAfterViewInit(): void {
        this.updateAriaDescribedBy();
    }

    protected setHasErrors(hasErrors: boolean) {
        this.localStore.patchState({ hasErrors });
    }

    protected handleErrorIdChange(errorId: string | null) {
        this.localStore.patchState({ errorId });
        this.updateAriaDescribedBy();
    }

    private updateAriaDescribedBy(): void {
        const errorId = this.localStore.selectSnapshotByKey("errorId");
        if (!isPlatformBrowser(this.platformId) || !errorId) {
            return;
        }
        const inputElement = this.element.nativeElement.querySelector("input, textarea");
        if (!inputElement) {
            return;
        }
        inputElement.setAttribute("aria-describedby", errorId);
    }
}
