<form [formGroup]="filtersForm" class="filter-form" *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder">
    <dtm-ui-input-field *ngIf="isPartialSearchFeatureDisabled">
        <label>{{ "dtmSharedMissionSearch.filterConditions.operatorLabel" | transloco }}</label>
        <input
            matInput
            type="text"
            [formControl]="filtersForm.controls.operator"
            [placeholder]="'dtmSharedMissionSearch.filterConditions.operatorPlaceholder' | transloco"
        />
        <div class="field-error" *dtmUiFieldHasError="filtersForm.controls.operator; name: 'minlength'; error as error">
            {{ "dtmSharedMissionSearch.filterConditions.minLengthError" | transloco : { min: error.requiredLength } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-search-control
        *ngIf="!isPartialSearchFeatureDisabled"
        [formControl]="filtersForm.controls.operator"
        (searchTextChange)="changeSearchText(FilterConditionSearchType.Operator, $event)"
        [options]="operatorOptions$ | ngrxPush"
        [placeholder]="'dtmSharedMissionSearch.filterConditions.searchControlPlaceholder' | transloco"
        [isProcessing]="isProcessingOperator$ | ngrxPush"
    >
        <label>{{ "dtmSharedMissionSearch.filterConditions.operatorLabel" | transloco }}</label>
        <ng-container noValueLabelSlot>
            {{ "dtmSharedMissionSearch.filterConditions.operatorEmptyOptionsLabel" | transloco }}
        </ng-container>
    </dtm-ui-search-control>
    <dtm-ui-input-field *ngIf="isPartialSearchFeatureDisabled">
        <label>{{ "dtmSharedMissionSearch.filterConditions.pilotLabel" | transloco }}</label>
        <input
            matInput
            type="text"
            [formControl]="filtersForm.controls.pilot"
            [placeholder]="'dtmSharedMissionSearch.filterConditions.pilotPlaceholder' | transloco"
        />
        <div class="field-error" *dtmUiFieldHasError="filtersForm.controls.pilot; name: 'minlength'; error as error">
            {{ "dtmSharedMissionSearch.filterConditions.minLengthError" | transloco : { min: error.requiredLength } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-search-control
        *ngIf="!isPartialSearchFeatureDisabled"
        [formControl]="filtersForm.controls.pilot"
        (searchTextChange)="changeSearchText(FilterConditionSearchType.Pilot, $event)"
        [options]="pilotOptions$ | ngrxPush"
        [placeholder]="'dtmSharedMissionSearch.filterConditions.searchControlPlaceholder' | transloco"
        [isProcessing]="isProcessingPilot$ | ngrxPush"
    >
        <label>{{ "dtmSharedMissionSearch.filterConditions.pilotLabel" | transloco }}</label>
        <ng-container noValueLabelSlot>
            {{ "dtmSharedMissionSearch.filterConditions.pilotEmptyOptionsLabel" | transloco }}
        </ng-container>
    </dtm-ui-search-control>
    <dtm-ui-input-field *ngIf="isPartialSearchFeatureDisabled">
        <label>{{ "dtmSharedMissionSearch.filterConditions.missionIdLabel" | transloco }}</label>
        <input
            matInput
            type="text"
            [formControl]="filtersForm.controls.missionId"
            [placeholder]="'dtmSharedMissionSearch.filterConditions.missionIdPlaceholder' | transloco"
        />
        <div class="field-error" *dtmUiFieldHasError="filtersForm.controls.missionId; name: 'pattern'">
            {{ "dtmSharedMissionSearch.filterConditions.missionIdPatternError" | transloco }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-search-control
        *ngIf="!isPartialSearchFeatureDisabled"
        [formControl]="filtersForm.controls.missionId"
        (searchTextChange)="changeSearchText(FilterConditionSearchType.Mission, $event)"
        [options]="missionIdOptions$ | ngrxPush"
        [placeholder]="'dtmSharedMissionSearch.filterConditions.searchControlPlaceholder' | transloco"
        [isProcessing]="isProcessingMissionId$ | ngrxPush"
    >
        <label>{{ "dtmSharedMissionSearch.filterConditions.missionIdLabel" | transloco }}</label>
        <ng-container noValueLabelSlot>
            {{ "dtmSharedMissionSearch.filterConditions.missionIdEmptyOptionsLabel" | transloco }}
        </ng-container>
    </dtm-ui-search-control>
    <dtm-ui-select-field
        [formControl]="filtersForm.controls.status"
        [placeholder]="'dtmSharedMissionSearch.filterConditions.statusPlaceholder' | transloco"
    >
        <label>{{ "dtmSharedMissionSearch.filterConditions.statusLabel" | transloco }}</label>
        <dtm-ui-select-option *ngFor="let status of missionStatuses" [value]="status">
            {{ "dtmSharedMission.missionStatusBadge.phaseLabel" | transloco : { value: status } }}
        </dtm-ui-select-option>
    </dtm-ui-select-field>
    <dtm-ui-input-field *ngIf="isPartialSearchFeatureDisabled">
        <label>{{ "dtmSharedMissionSearch.filterConditions.uavLabel" | transloco }}</label>
        <input
            matInput
            type="text"
            [formControl]="filtersForm.controls.uav"
            [placeholder]="'dtmSharedMissionSearch.filterConditions.uavPlaceholder' | transloco"
        />
        <div class="field-error" *dtmUiFieldHasError="filtersForm.controls.uav; name: 'minlength'; error as error">
            {{ "dtmSharedMissionSearch.filterConditions.minLengthError" | transloco : { min: error.requiredLength } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-search-control
        *ngIf="!isPartialSearchFeatureDisabled"
        [formControl]="filtersForm.controls.uav"
        (searchTextChange)="changeSearchText(FilterConditionSearchType.Uav, $event)"
        [options]="uavOptions$ | ngrxPush"
        [placeholder]="'dtmSharedMissionSearch.filterConditions.searchControlPlaceholder' | transloco"
        [isProcessing]="isProcessingUav$ | ngrxPush"
    >
        <label>{{ "dtmSharedMissionSearch.filterConditions.uavLabel" | transloco }}</label>
        <ng-container noValueLabelSlot>
            {{ "dtmSharedMissionSearch.filterConditions.uavEmptyOptionsLabel" | transloco }}
        </ng-container>
    </dtm-ui-search-control>
    <dtm-ui-select-field
        [formControl]="filtersForm.controls.missionType"
        [placeholder]="'dtmSharedMissionSearch.filterConditions.missionTypePlaceholder' | transloco"
    >
        <label>{{ "dtmSharedMissionSearch.filterConditions.missionTypeLabel" | transloco }}</label>
        <dtm-ui-select-option *ngFor="let missionType of missionTypes" [value]="missionType">
            {{ "dtmSharedMissionSearch.missionTypeLabel" | transloco : { value: missionType } }}
        </dtm-ui-select-option>
    </dtm-ui-select-field>
    <div class="datetime-field">
        <div class="fields-container">
            <dtm-ui-date-field [isClearable]="false">
                <label class="datetime-label">
                    {{ "dtmSharedMissionSearch.filterConditions.dateFrom.label" | transloco }}
                </label>
                <input
                    matInput
                    [formControl]="datesForm.controls.fromDate"
                    [matDatepicker]="fromDatePicker"
                    [placeholder]="datePickerPlaceholder"
                />
                <mat-datepicker #fromDatePicker></mat-datepicker>
            </dtm-ui-date-field>
            <dtm-ui-time-field [formControl]="datesForm.controls.fromTime"></dtm-ui-time-field>
        </div>
        <dtm-ui-form-errors class="preserve-error-space">
            <ng-container *ngIf="datesForm.errors?.fromDateRequired">
                {{ "dtmSharedMissionSearch.filterConditions.dateFrom.dateRequiredErrorLabel" | transloco }}
            </ng-container>
            <ng-container *ngIf="datesForm.errors?.fromTimeRequired">
                {{ "dtmSharedMissionSearch.filterConditions.dateFrom.timeRequiredErrorLabel" | transloco }}
            </ng-container>
            <ng-container *ngIf="datesForm.errors?.range">
                {{ "dtmSharedMissionSearch.filterConditions.dateRangeErrorLabel" | transloco }}
            </ng-container>
        </dtm-ui-form-errors>
    </div>
    <div class="datetime-field">
        <div class="fields-container">
            <dtm-ui-date-field [isClearable]="false">
                <label class="datetime-label">
                    {{ "dtmSharedMissionSearch.filterConditions.dateTo.label" | transloco }}
                </label>
                <input
                    matInput
                    [formControl]="datesForm.controls.toDate"
                    [matDatepicker]="toDatePicker"
                    [placeholder]="datePickerPlaceholder"
                />
                <mat-datepicker #toDatePicker></mat-datepicker>
            </dtm-ui-date-field>
            <dtm-ui-time-field [formControl]="datesForm.controls.toTime"></dtm-ui-time-field>
        </div>
        <dtm-ui-form-errors class="preserve-error-space">
            <ng-container *ngIf="datesForm.errors?.toDateRequired">
                {{ "dtmSharedMissionSearch.filterConditions.dateTo.dateRequiredErrorLabel" | transloco }}
            </ng-container>
            <ng-container *ngIf="datesForm.errors?.toTimeRequired">
                {{ "dtmSharedMissionSearch.filterConditions.dateTo.timeRequiredErrorLabel" | transloco }}
            </ng-container>
            <ng-container *ngIf="datesForm.errors?.range">
                {{ "dtmSharedMissionSearch.filterConditions.dateRangeErrorLabel" | transloco }}
            </ng-container>
        </dtm-ui-form-errors>
    </div>
    <dtm-ui-select-field
        [formControl]="filtersForm.controls.area"
        [placeholder]="'dtmSharedMissionSearch.filterConditions.areaPlaceholder' | transloco"
    >
        <label>{{ "dtmSharedMissionSearch.filterConditions.areaLabel" | transloco }}</label>
        <dtm-ui-select-option *ngFor="let area of areas" [value]="area">
            {{ "dtmSharedMissionSearch.filterConditions.areaValueLabel" | transloco : { value: area } }}
        </dtm-ui-select-option>
        <div class="field-error" *ngIf="filtersForm.errors?.invalidZoneArea">
            {{ "dtmSharedMissionSearch.filterConditions.invalidZoneAreaErrorLabel" | transloco }}
        </div>
        <div class="field-error" *ngIf="filtersForm.errors?.invalidCustomArea">
            {{ "dtmSharedMissionSearch.filterConditions.invalidCustomAreaErrorLabel" | transloco }}
        </div>
    </dtm-ui-select-field>
    <dtm-ui-search-control
        *ngIf="filtersForm.controls.area.value === SearchArea.Zone"
        [formControl]="filtersForm.controls.zone"
        (searchTextChange)="changeSearchText(FilterConditionSearchType.Zone, $event)"
        [options]="zoneOptions$ | ngrxPush"
        [placeholder]="'dtmSharedMissionSearch.filterConditions.searchControlPlaceholder' | transloco"
        [isProcessing]="isProcessingZone$ | ngrxPush"
    >
        <label>{{ "dtmSharedMissionSearch.filterConditions.zoneLabel" | transloco }}</label>
        <ng-container noValueLabelSlot>
            {{ "dtmSharedMissionSearch.filterConditions.zoneEmptyOptionsLabel" | transloco }}
        </ng-container>
    </dtm-ui-search-control>
</form>

<section class="custom-area" *ngIf="filtersForm.controls.area.value === SearchArea.Custom">
    <div class="toolbox">
        <dtm-map-actions-panel
            [mode]="MapActionsPanelMode.CylinderAndPrism"
            [activeMapAction]="activeMapAction$ | ngrxPush"
            [entitiesCount]="drawnEntitiesCount$ | ngrxPush"
            [maxEntities]="1"
            [activeEntityStatus]="activeEntityStatus$ | ngrxPush"
            (selectMapAction)="processMapActionChange($event)"
        >
        </dtm-map-actions-panel>
    </div>
    <div class="map">
        <dtm-mission-mission-search-filter-area-map [initialViewbox]="initialViewbox"></dtm-mission-mission-search-filter-area-map>
    </div>
</section>
