import { Cartesian3 } from "@pansa/ngx-cesium";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export function getElevatedCesiumPoint(point: Cartesian3, newHeight: number): Cartesian3 {
    const cartographic = Cesium.Cartographic.fromCartesian(point);
    cartographic.height = newHeight;

    return Cesium.Cartographic.toCartesian(cartographic);
}
