import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { DmsCoordinatesUtils } from "../..";
import { GeographicCoordinatesType } from "../../models/dms-coordinates-input.models";

interface DmsDisplayComponentState {
    latitude: string | undefined;
    longitude: string | undefined;
}

@Component({
    selector: "dtm-ui-dms-display[decimalLatitude][decimalLongitude]",
    templateUrl: "./dms-display.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class DmsDisplayComponent {
    @Input() public set decimalLatitude(value: number | undefined) {
        if (!value) {
            return;
        }

        const dmsLatitudeWithDirectionString = DmsCoordinatesUtils.convertDmsWithDirectionToString(
            DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(value, GeographicCoordinatesType.Latitude)
        );

        this.localStore.patchState({ latitude: dmsLatitudeWithDirectionString });
    }

    @Input() public set decimalLongitude(value: number | undefined) {
        if (!value) {
            return;
        }

        const dmsLongitudeWithDirectionString = DmsCoordinatesUtils.convertDmsWithDirectionToString(
            DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(value, GeographicCoordinatesType.Longitude)
        );

        this.localStore.patchState({ longitude: dmsLongitudeWithDirectionString });
    }

    protected readonly latitude$ = this.localStore.selectByKey("latitude");
    protected readonly longitude$ = this.localStore.selectByKey("longitude");

    constructor(private readonly localStore: LocalComponentStore<DmsDisplayComponentState>) {
        this.localStore.setState({
            latitude: undefined,
            longitude: undefined,
        });
    }
}
