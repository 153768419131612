<div>
    <button
        *ngIf="isAttachmentsButtonVisible$ | ngrxPush"
        type="button"
        (click)="attachmentsButtonVisibilityChange.emit()"
        class="button-secondary"
    >
        <dtm-ui-icon name="attachment"></dtm-ui-icon>
        {{ "dtmUi.conversations.addAttachmentLabel" | transloco }}
    </button>
</div>
<div class="main-actions">
    <button type="button" class="button-icon bin-button" (click)="messageDecline.emit()">
        <dtm-ui-icon name="delete-bin" class="bin-icon"></dtm-ui-icon>
    </button>
    <button type="submit" class="button-primary" (click)="messageSend.emit()">
        {{ "dtmUi.conversations.sendMessageButtonLabel" | transloco }}
    </button>
</div>
