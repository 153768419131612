import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { FilesGroup } from "../files-groups.models";

interface FilesGroupsDisplayComponentState {
    groups: FilesGroup[];
    additionalPathParams: Record<string, string> | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-files-groups-display[groups]",
    templateUrl: "./files-groups-display.component.html",
    styleUrls: ["./files-groups-display.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FilesGroupsDisplayComponent {
    @Input() public set groups(value: FilesGroup[] | undefined) {
        this.localStore.patchState({ groups: value ?? [] });
    }

    @Input() public set additionalPathParams(value: Record<string, string> | undefined) {
        this.localStore.patchState({ additionalPathParams: value });
    }

    protected readonly groups$ = this.localStore.selectByKey("groups");
    protected readonly additionalPathParams$ = this.localStore.selectByKey("additionalPathParams");

    constructor(private readonly localStore: LocalComponentStore<FilesGroupsDisplayComponentState>) {
        this.localStore.setState({
            groups: [],
            additionalPathParams: undefined,
        });
    }
}
