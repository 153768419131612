import { ChangeDetectionStrategy, Component, ContentChildren, Input, Output, QueryList } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { switchMap } from "rxjs/operators";
import { WizardState } from "../../state/wizard.state";
import { WizardStepContentComponent } from "../wizard-step-content/wizard-step-content.component";
import { WizardStepContentDirective } from "../wizard-step-content/wizard-step-content.directive";

interface WizardContentComponentState {
    wizardId: string;
}

@Component({
    selector: "dtm-ui-lib-wizard-content[wizardId]",
    templateUrl: "./wizard-content.component.html",
    styleUrls: ["./wizard-content.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class WizardContentComponent {
    public activeStepId$ = this.localStore
        .selectByKey("wizardId")
        .pipe(switchMap((wizardId) => this.store.select(WizardState.activeStepId(wizardId))));

    /**
     * @deprecated Use WizardStepContentDirective instead
     */
    @ContentChildren(WizardStepContentComponent) public stepsFromComponent: QueryList<WizardStepContentComponent> | undefined;
    @ContentChildren(WizardStepContentDirective) public steps: QueryList<WizardStepContentDirective> | undefined;

    @Input() public set wizardId(value: string) {
        this.localStore.patchState({ wizardId: value });
    }
    @Output() public stepChangeActivate = this.activeStepId$;

    constructor(private readonly store: Store, private readonly localStore: LocalComponentStore<WizardContentComponentState>) {
        this.localStore.setState({ wizardId: "" });
    }

    protected getStepContentTemplate = (stepId?: string) =>
        (this.steps?.length ? this.steps : this.stepsFromComponent)?.find((step) => step.stepId === stepId)?.template ?? null;
}
