import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { MissionProcessingPhase, PhasePayloadData, PlannedMission } from "../../models/planned-missions.models";

interface PhaseDescriptionComponentState {
    missions: PlannedMission[] | undefined;
    selectedMissionRoute: MissionPlanRoute | undefined;
    selectedTileId: string | undefined;
    isPlanRouteProcessing: boolean;
    canEdit: boolean;
}

interface PlannedMissionForm {
    shouldAddOperatorDetails: FormControl<boolean>;
    operatorDetails: FormControl<string>;
}

export const MAX_OPERATOR_DETAILS_LENGTH = 200;

@Component({
    selector: "dss-client-lib-phase-description[missions]",
    templateUrl: "./phase-description.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PhaseDescriptionComponent {
    @Input() public set missions(value: PlannedMission[] | undefined) {
        this.localStore.patchState({ missions: value });

        this.plannedMissionFormArray.clear({ emitEvent: false });

        if (!value || this.plannedMissionFormArray.length) {
            return;
        }

        value.forEach(() => {
            this.plannedMissionFormArray.push(this.getFormGroup());
        });
    }

    @Input() public set selectedTileId(value: string | undefined) {
        this.localStore.patchState({ selectedTileId: value });
    }

    @Input() public set selectedMissionRoute(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ selectedMissionRoute: value });
    }

    @Input() public set isPlanRouteProcessing(value: BooleanInput) {
        this.localStore.patchState({ isPlanRouteProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set canEdit(value: BooleanInput) {
        this.localStore.patchState({ canEdit: coerceBooleanProperty(value) });
    }

    protected readonly canEdit$ = this.localStore.selectByKey("canEdit");
    protected readonly selectedTileId$ = this.localStore.selectByKey("selectedTileId");
    protected readonly isPlanRouteProcessing$ = this.localStore.selectByKey("isPlanRouteProcessing");
    protected readonly missions$ = this.localStore.selectByKey("missions").pipe(RxjsUtils.filterFalsy());
    protected readonly selectedMissionRoute$ = this.localStore.selectByKey("selectedMissionRoute").pipe(RxjsUtils.filterFalsy());
    protected readonly MissionProcessingPhase = MissionProcessingPhase;
    protected readonly MAX_OPERATOR_DETAILS_LENGTH = MAX_OPERATOR_DETAILS_LENGTH;

    protected readonly plannedMissionFormArray = new FormArray<FormGroup<PlannedMissionForm>>([]);

    @Output() public readonly missionSelect = new EventEmitter<PlannedMission>();
    @Output() public readonly missionPhaseChange = new EventEmitter<PhasePayloadData>();

    constructor(private readonly localStore: LocalComponentStore<PhaseDescriptionComponentState>) {
        this.localStore.setState({
            missions: undefined,
            selectedMissionRoute: undefined,
            selectedTileId: undefined,
            isPlanRouteProcessing: false,
            canEdit: false,
        });
    }

    protected getFormGroup(): FormGroup<PlannedMissionForm> {
        return new FormGroup<PlannedMissionForm>({
            shouldAddOperatorDetails: new FormControl(false, { nonNullable: true }),
            operatorDetails: new FormControl(
                { value: "", disabled: true },
                { nonNullable: true, validators: Validators.maxLength(MAX_OPERATOR_DETAILS_LENGTH) }
            ),
        });
    }

    protected updateMissionPhase(missionPhase: MissionProcessingPhase, id: string, index: number): void {
        const note = this.plannedMissionFormArray.controls[index].controls.operatorDetails.value;

        this.missionPhaseChange.emit({ id, missionPhase, note });
    }

    protected showCommentForm({ checked }: MatCheckboxChange, index: number): void {
        const operatorDetails = this.plannedMissionFormArray.controls[index].controls.operatorDetails;

        if (checked) {
            operatorDetails.enable();

            return;
        }

        operatorDetails.disable();
        operatorDetails.reset();
    }
}
