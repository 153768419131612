import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Alpha2CountryCode, Alpha3CountryCode } from "../../models/country.models";
import { DisplayCountryService } from "../../services/display-country/display-country.service";

interface CountryFlagDisplayComponentState {
    country: Lowercase<Alpha2CountryCode> | undefined;
    countryName: string | undefined;
}

@Component({
    selector: "dtm-ui-country-flag-display[country]",
    templateUrl: "./country-flag-display.component.html",
    styleUrls: ["./country-flag-display.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CountryFlagDisplayComponent {
    protected readonly country$ = this.localStore.selectByKey("country");
    protected readonly countryName$ = this.localStore.selectByKey("countryName");

    @Input() public set country(value: Alpha2CountryCode | Alpha3CountryCode | undefined) {
        const country = this.displayCountryService.getCountryByCountryCode(value);
        this.localStore.patchState({
            country: country?.alpha2Code.toLowerCase() as Lowercase<Alpha2CountryCode>,
            countryName: country?.displayName,
        });
    }

    constructor(
        private readonly localStore: LocalComponentStore<CountryFlagDisplayComponentState>,
        private readonly displayCountryService: DisplayCountryService
    ) {
        this.localStore.setState({
            country: undefined,
            countryName: undefined,
        });
    }
}
