import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, Subscription, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import { AirspaceElement, AirspaceElementsInfo, GesZonesError } from "../models/geo-zones.models";
import { AirspaceElementsSearchOptions, GeoZonesApiService } from "../services/geo-zones-api.service";
import { GeoZonesActions } from "./geo-zones.actions";

export interface MapLayerWithControlsModel {
    geoZonesLayersData: AirspaceElementsInfo | undefined;
    customZonesLayersData: AirspaceElementsInfo | undefined;
    zonesWithInfo: AirspaceElementsInfo | undefined;
    areCustomZoneEnabled: boolean;
    isGeoZonesDataLoading: boolean;
    selectedZoneId: string | undefined;
    aupEndTime: Date | undefined;
    currentGeoZonesOptions: AirspaceElementsSearchOptions | undefined;
    isGeoZonesInfoEnabled: boolean;
    geoZonesError: GesZonesError | undefined;
}

const defaultState: MapLayerWithControlsModel = {
    geoZonesLayersData: undefined,
    customZonesLayersData: undefined,
    zonesWithInfo: undefined,
    isGeoZonesDataLoading: false,
    areCustomZoneEnabled: false,
    selectedZoneId: undefined,
    aupEndTime: undefined,
    currentGeoZonesOptions: undefined,
    isGeoZonesInfoEnabled: false,
    geoZonesError: undefined,
};

@State<MapLayerWithControlsModel>({
    name: "geoZones",
    defaults: defaultState,
})
@Injectable()
export class GeoZonesState {
    private airspaceUpdateSubscription: Subscription | undefined;

    @Selector()
    public static geoZonesLayersData(state: MapLayerWithControlsModel): AirspaceElement[] {
        return (
            state.geoZonesLayersData?.airspaceElements.filter(
                (element) =>
                    !state.areCustomZoneEnabled ||
                    !state.customZonesLayersData?.airspaceElements.some((customElement) => customElement.designator === element.designator)
            ) ?? []
        );
    }

    @Selector()
    public static isGeoZonesDataLoading(state: MapLayerWithControlsModel): boolean {
        return state.isGeoZonesDataLoading;
    }

    @Selector()
    public static customZonesLayersData(state: MapLayerWithControlsModel): AirspaceElementsInfo | undefined {
        return state.areCustomZoneEnabled ? state.customZonesLayersData : undefined;
    }

    @Selector()
    public static areCustomZoneEnabled(state: MapLayerWithControlsModel): boolean {
        return state.areCustomZoneEnabled;
    }

    @Selector()
    public static selectedZoneId(state: MapLayerWithControlsModel): string | undefined {
        return state.selectedZoneId;
    }

    @Selector()
    public static aupEndTime(state: MapLayerWithControlsModel): Date | undefined {
        return state.aupEndTime;
    }

    @Selector()
    public static zonesWithInfo(state: MapLayerWithControlsModel): AirspaceElementsInfo | undefined {
        return state.zonesWithInfo;
    }

    @Selector()
    public static isGeoZonesInfoEnabled(state: MapLayerWithControlsModel): boolean {
        return state.isGeoZonesInfoEnabled;
    }

    @Selector()
    public static geoZonesError(state: MapLayerWithControlsModel): GesZonesError | undefined {
        return state.geoZonesError;
    }

    constructor(private readonly geoZonesApiService: GeoZonesApiService) {}

    @Action(GeoZonesActions.SearchAirspaceElements, { cancelUncompleted: true })
    public searchAirspaceElements(
        state: StateContext<MapLayerWithControlsModel>,
        { options, shouldClearInfo }: GeoZonesActions.SearchAirspaceElements
    ) {
        if (!options.geozones?.length) {
            state.patchState({
                geoZonesLayersData: undefined,
                currentGeoZonesOptions: undefined,
                isGeoZonesDataLoading: false,
            });

            return;
        }

        state.patchState({ isGeoZonesDataLoading: true, currentGeoZonesOptions: options });

        if (shouldClearInfo) {
            state.patchState({
                zonesWithInfo: undefined,
            });
        }

        state.patchState({
            geoZonesError: undefined,
        });

        return this.geoZonesApiService.searchAirspaceElements(options).pipe(
            tap((geoZonesLayersData) =>
                state.patchState({ geoZonesLayersData, isGeoZonesDataLoading: false, aupEndTime: geoZonesLayersData.aupEndTime })
            ),
            catchError(() => {
                state.patchState({
                    isGeoZonesDataLoading: false,
                    geoZonesError: GesZonesError.DataLoadingError,
                    geoZonesLayersData: undefined,
                    selectedZoneId: undefined,
                    zonesWithInfo: undefined,
                    isGeoZonesInfoEnabled: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(GeoZonesActions.SetCustomElements)
    public setCustomElements(state: StateContext<MapLayerWithControlsModel>, { elements }: GeoZonesActions.SetCustomElements) {
        state.patchState({
            customZonesLayersData: elements,
        });
    }

    @Action(GeoZonesActions.SetCustomZonesVisibility)
    public setCustomZonesVisibility(
        state: StateContext<MapLayerWithControlsModel>,
        { isVisible }: GeoZonesActions.SetCustomZonesVisibility
    ) {
        state.patchState({
            areCustomZoneEnabled: isVisible,
        });
    }

    @Action(GeoZonesActions.SetSelectedZoneId)
    public setSelectedZoneId(state: StateContext<MapLayerWithControlsModel>, { id }: GeoZonesActions.SetSelectedZoneId) {
        state.patchState({
            selectedZoneId: id,
        });
    }

    @Action(GeoZonesActions.Cleanup)
    public cleanup(state: StateContext<MapLayerWithControlsModel>) {
        state.patchState(defaultState);
        this.airspaceUpdateSubscription?.unsubscribe();
    }

    @Action(GeoZonesActions.GetAirspaceElementsWithInfo, { cancelUncompleted: true })
    public getAirspaceElementsWithInfo(
        state: StateContext<MapLayerWithControlsModel>,
        { options }: GeoZonesActions.GetAirspaceElementsWithInfo
    ) {
        state.patchState({
            geoZonesError: undefined,
        });

        return this.geoZonesApiService
            .searchAirspaceElements({ ...options, includeInformation: true, includeLocal: true, includeTemporary: true })
            .pipe(
                tap((zonesWithInfo) => {
                    state.patchState({
                        zonesWithInfo,
                        aupEndTime: zonesWithInfo.aupEndTime,
                    });
                }),
                catchError(() => {
                    state.patchState({
                        geoZonesError: GesZonesError.ZonesWithInfoLoadingError,
                        zonesWithInfo: undefined,
                    });

                    return EMPTY;
                })
            );
    }

    @Action(GeoZonesActions.StartAirspaceUpdatesWatch)
    public startAirspaceUpdatesWatch(state: StateContext<MapLayerWithControlsModel>) {
        this.airspaceUpdateSubscription?.unsubscribe();
        this.airspaceUpdateSubscription = this.geoZonesApiService.startAirspaceUpdatesWatch().subscribe(() => {
            const { geoZonesLayersData, currentGeoZonesOptions } = state.getState();
            if (geoZonesLayersData && currentGeoZonesOptions) {
                state.dispatch(new GeoZonesActions.SearchAirspaceElements(currentGeoZonesOptions));
            }
        });
    }

    @Action(GeoZonesActions.ToggleGeoZonesInfo)
    public toggleGeoZonesInfo(state: StateContext<MapLayerWithControlsModel>) {
        state.patchState({
            isGeoZonesInfoEnabled: !state.getState().isGeoZonesInfoEnabled,
        });
    }
}
