import { Injectable } from "@angular/core";
import {
    AircraftEntity,
    FlightPositionUpdate,
    FlightPositionUpdateType,
    MissionPlanRouteSection,
    PinAcEntity,
    PinData,
    RouteAreaTypeId,
    RouteData,
    Trajectory,
    Waypoint,
} from "@dtm-frontend/shared/ui";
import { DateUtils, MILLISECONDS_IN_SECOND, ObjectUtils, RxjsUtils } from "@dtm-frontend/shared/utils";
import { AcEntity, AcNotification, ActionType, CoordinateConverter } from "@pansa/ngx-cesium";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import turfDistance from "@turf/distance";
import { Feature, LineString, MultiPolygon, Point, Polygon, featureCollection, lineString, point } from "@turf/helpers";
import lineIntersect from "@turf/line-intersect";
import lineSlice from "@turf/line-slice";
import midpoint from "@turf/midpoint";
import nearestPointOnLine from "@turf/nearest-point-on-line";
import { Observable, from, map, mergeMap, of, tap } from "rxjs";
import { filter } from "rxjs/operators";
import { combineFlightArea } from "../../../shared/utils/combine-flight-area";
import { MapUtils, RouteAcEntity } from "../../index";

/* eslint-disable @typescript-eslint/no-explicit-any*/
declare const Cesium: any; // TODO: DTM-966

@Injectable({
    providedIn: "root",
})
export class RouteViewerService<T> {
    constructor(private readonly coordinateConverter: CoordinateConverter) {}

    public transformRouteDataToFlightAreaEntities(
        { route, sectionStatuses }: RouteData<T>,
        drawableFeatures: RouteAreaTypeId[] = ["flightArea"],
        itemIndex: number = 0
    ): Observable<AcNotification> {
        return from(route.sections).pipe(
            mergeMap((section, sectionIndex) => {
                const flightZoneArea = section.flightZone?.flightArea.volume.area.coordinates ?? [];
                const segmentArea = section.segment?.flightArea.volume.area.coordinates ?? [];
                const coordinates = [...flightZoneArea, ...segmentArea].map((positions) => positions.map(([x, y]) => [x, y]));

                return from(coordinates).pipe(
                    map((degreesPositions, entityIndex) => {
                        const positions = Cesium.Cartesian3.fromDegreesArray(degreesPositions.flat());
                        const entity: RouteAcEntity = {
                            id: "flightArea",
                            positions,
                            degreesPositions,
                            segmentStatus: sectionStatuses?.[sectionIndex],
                        };

                        return {
                            entity,
                            actionType: drawableFeatures.includes("flightArea") ? ActionType.ADD_UPDATE : ActionType.DELETE,
                            id: "flightArea" + sectionIndex + entityIndex + itemIndex,
                        };
                    })
                );
            })
        );
    }

    public transformRouteDataToSafetyAreaEntities(
        { route, sectionStatuses }: RouteData<T>,
        drawableFeatures: RouteAreaTypeId[] = ["safetyArea"],
        itemIndex = 0
    ): Observable<AcNotification> {
        return from(route.sections).pipe(
            mergeMap((sections) =>
                from([sections.flightZone?.safetyArea.volume.area, sections.segment?.safetyArea.volume.area].filter(Boolean))
            ),
            mergeMap((area) => Cesium.GeoJsonDataSource.load(area) as Promise<any>),
            filter((geoJsonData) => geoJsonData?.entities?.values?.length),
            mergeMap((geoJsonData) => of(...geoJsonData.entities.values)),
            map((geoJsonFeatureEntity: any, index) => {
                const entity: AcEntity = {
                    id: "safetyArea",
                    positions: geoJsonFeatureEntity.polygon.hierarchy.valueOf(),
                    segmentStatus: sectionStatuses?.[index],
                };

                return {
                    entity,
                    actionType: drawableFeatures.includes("safetyArea") ? ActionType.ADD_UPDATE : ActionType.DELETE,
                    id: "safetyArea" + index + itemIndex,
                };
            })
        );
    }

    public transformRouteDataToPathEntities(
        { route, sectionStatuses }: RouteData<T>,
        drawableFeatures: RouteAreaTypeId[] = ["path"],
        itemIndex = 0
    ): Observable<AcNotification> {
        return from(route.sections).pipe(
            map(({ segment }, index) => {
                if (!segment) {
                    return;
                }

                const positions = Cesium.Cartesian3.fromDegreesArray([
                    segment.fromWaypoint.point.longitude,
                    segment.fromWaypoint.point.latitude,
                    segment.toWaypoint.point.longitude,
                    segment.toWaypoint.point.latitude,
                ]);

                const entity: AcEntity = {
                    id: "path",
                    positions,
                    segmentStatus: sectionStatuses?.[index],
                };

                return {
                    entity,
                    actionType: drawableFeatures.includes("path") ? ActionType.ADD_UPDATE : ActionType.DELETE,
                    id: "path" + index + itemIndex,
                };
            }),
            RxjsUtils.filterFalsy()
        );
    }

    public transformRouteDataToWaypointEntities(
        { route, isPathBased, sectionStatuses }: RouteData<T>,
        drawableFeatures: RouteAreaTypeId[] = ["path", "flightArea"],
        itemIndex = 0
    ): Observable<AcNotification> {
        const waypoints = route.sections.reduce<Map<string, { waypoint: Waypoint; show: boolean }>>((output, section) => {
            const centerPoint = section.flightZone?.center;
            const fromPoint = section.segment?.fromWaypoint;
            const toPoint = section.segment?.toWaypoint;

            if (centerPoint) {
                output.set(centerPoint.name, { waypoint: centerPoint, show: !!section.segment });
            }
            if (fromPoint) {
                output.set(fromPoint.name, { waypoint: fromPoint, show: !!section.segment });
            }
            if (toPoint) {
                output.set(toPoint.name, { waypoint: toPoint, show: !!section.segment });
            }

            return output;
        }, new Map<string, { waypoint: Waypoint; show: boolean }>());

        return from(waypoints.values()).pipe(
            RxjsUtils.filterFalsy(),
            map(({ waypoint, show }, index) => {
                if (!isPathBased) {
                    return {
                        actionType: ActionType.DELETE,
                        id: "waypoint" + index + itemIndex,
                    };
                }

                const pointEntity = {
                    id: "waypoint",
                    position: Cesium.Cartesian3.fromDegrees(waypoint.point.longitude, waypoint.point.latitude),
                    start: index === 0,
                    landing: index === waypoints.size - 1,
                    show,
                    segmentStatus: sectionStatuses?.[index],
                };

                let actionType;

                if (
                    drawableFeatures.includes("path") ||
                    ((pointEntity.start || pointEntity.landing) && drawableFeatures.includes("flightArea"))
                ) {
                    actionType = ActionType.ADD_UPDATE;
                } else {
                    actionType = ActionType.DELETE;
                }

                return {
                    entity: pointEntity,
                    actionType,
                    id: "waypoint" + index + itemIndex,
                };
            })
        );
    }

    public mapRouteToPinEntities(
        { route: { sections }, isSelected, isMain, isCollision, sectionStatuses }: RouteData<T>,
        drawableFeatures: RouteAreaTypeId[],
        itemIndex = 0
    ) {
        const pinsData = sections.reduce<PinData<T>[]>((previousValue, section) => {
            if (section.flightZone) {
                previousValue.push({
                    flightArea: section.flightZone.flightArea,
                    waypoints: [section.flightZone.center],
                });
            }

            if (section.segment) {
                previousValue.push({
                    flightArea: section.segment.flightArea,
                    waypoints: [section.segment.fromWaypoint, section.segment.toWaypoint],
                });
            }

            return previousValue;
        }, []);

        return from(pinsData).pipe(
            map((data, index) => {
                const pinData = ObjectUtils.cloneDeep(data);

                if (!drawableFeatures.includes("flightArea")) {
                    return {
                        actionType: ActionType.DELETE,
                        id: "pin" + index + itemIndex,
                    };
                }

                let pinXPosition;
                let pinYPosition;

                if (pinData.waypoints.length > 1) {
                    const waypointFrom = pinData.waypoints[0].point;
                    const waypointTo = pinData.waypoints[1].point;

                    const pointPositions = midpoint(
                        [waypointFrom.longitude, waypointFrom.latitude],
                        [waypointTo.longitude, waypointTo.latitude]
                    ).geometry.coordinates;
                    pinXPosition = pointPositions[0];
                    pinYPosition = pointPositions[1];
                } else {
                    pinXPosition = pinData.waypoints[0].point.longitude;
                    pinYPosition = pinData.waypoints[0].point.latitude;

                    const estimateArrivalTime = new Date(pinData.waypoints[0].estimatedArriveAt.max).getTime();
                    const stopoverDurationSeconds = sections[0].flightZone
                        ? DateUtils.convertISO8601DurationToSeconds(sections[0].flightZone.stopover.max)
                        : undefined;
                    const durationTime = (stopoverDurationSeconds ?? 0) * MILLISECONDS_IN_SECOND;

                    pinData.waypoints[0].estimatedArriveAt.max = new Date(estimateArrivalTime + durationTime);
                }

                const position = Cesium.Cartographic.toCartesian(
                    this.coordinateConverter.degreesToCartographic(pinXPosition, pinYPosition)
                );

                const pinEntity: PinAcEntity<T> = {
                    id: "pin",
                    show: true,
                    data: { ...pinData, isMain, isSelected, isCollision, status: sectionStatuses?.[index], index },
                    position,
                };

                return {
                    id: "pin" + index + itemIndex,
                    actionType: ActionType.ADD_UPDATE,
                    entity: pinEntity,
                };
            })
        );
    }

    public transformFlightUpdateToFlightEntity(update: FlightPositionUpdate): AcEntity {
        const position = Cesium.Cartesian3.fromDegrees(update.longitude, update.latitude, 0);

        const aircraftEntity: AircraftEntity = {
            name: update.callSign,
            orientation: update.track,
            position,
            height: update.height,
            aircraftType: update.aircraftType,
            altitude: update.altitude,
            originalAltitudeBaro: update.originalAltitudeBaro,
            originalAltitudeGeo: update.originalAltitudeGeo,
            updateTime: new Date().getTime(),
            isConnectionLost: update.isConnectionLost,
            isFaradaSource: update.isFaradaSource,
            trackerIdentifier: update.trackerIdentifier,
        };

        return {
            entity: aircraftEntity,
            actionType: update.updateType === FlightPositionUpdateType.End ? ActionType.DELETE : ActionType.ADD_UPDATE,
            id: update.id,
        };
    }

    public transformRouteDataToBlockPinEntity(
        { route: { sections }, isPathBased }: RouteData<T>,
        drawableFeatures: RouteAreaTypeId[] = ["blockers"],
        itemIndex = 0
    ): Observable<AcNotification> {
        if (!isPathBased) {
            return of({
                id: "blockPin" + itemIndex,
                actionType: ActionType.DELETE,
            });
        }

        const blockPoint = this.getBlockPoint(sections);

        if (!blockPoint || !drawableFeatures.includes("blockers")) {
            return of({
                id: "blockPin" + itemIndex,
                actionType: ActionType.DELETE,
            });
        }

        const position = Cesium.Cartographic.toCartesian(
            this.coordinateConverter.degreesToCartographic(blockPoint.geometry.coordinates[0], blockPoint.geometry.coordinates[1])
        );

        const pinEntity: PinAcEntity<T> = {
            id: "blockPin",
            position,
        };

        return of({
            id: "blockPin" + itemIndex,
            actionType: ActionType.ADD_UPDATE,
            entity: pinEntity,
        });
    }

    private getBlockPoint([firstSection, nextSection]: MissionPlanRouteSection[]) {
        if (nextSection?.segment) {
            return point([nextSection.segment.fromWaypoint.point.longitude, nextSection.segment.fromWaypoint.point.latitude]);
        }

        if (!nextSection || !nextSection.flightZone) {
            return;
        }

        const nextSectionAreaLine = lineString(nextSection.flightZone.flightArea.volume.area.coordinates.flat());

        let currentPoint: Feature<Point>;

        if (firstSection.segment) {
            currentPoint = point([firstSection.segment.toWaypoint.point.longitude, firstSection.segment.toWaypoint.point.latitude]);
        } else if (firstSection.flightZone) {
            currentPoint = point([firstSection.flightZone.center.point.longitude, firstSection.flightZone.center.point.latitude]);
        } else {
            return;
        }

        return nearestPointOnLine(nextSectionAreaLine, currentPoint);
    }

    public clearTrajectories(activeEntitiesIds: Map<string, Set<string>> = new Map()) {
        const obs = from([...activeEntitiesIds.values()].map((set) => [...set]).flat()).pipe(
            map((id) => ({ id, actionType: ActionType.DELETE }))
        );
        activeEntitiesIds.clear();

        return obs;
    }

    public transformRouteDataToTrajectoryEntities(
        trajectories: Trajectory[],
        drawableFeatures: RouteAreaTypeId[] = ["trajectories"],
        activeEntitiesIds: Map<string, Set<string>> = new Map(),
        identifier: string
    ): Observable<AcNotification> {
        if (!drawableFeatures.includes("trajectories") || !trajectories?.length) {
            return from([...(activeEntitiesIds.get(identifier) ?? [])]).pipe(
                map((id) => ({ id, actionType: ActionType.DELETE })),
                tap(() => activeEntitiesIds.get(identifier)?.clear())
            );
        }

        return from(trajectories).pipe(
            map((trajectory, index) => {
                const trajectoryEntity = {
                    positions: trajectory.positions.map(({ longitude, latitude }) =>
                        MapUtils.convertSerializableCartographicToCartesian3({ longitude, latitude })
                    ),
                };

                const id = "trajectories" + index + identifier;
                const activeEntities = activeEntitiesIds.get(identifier);

                if (activeEntities) {
                    activeEntities.add(id);
                } else {
                    activeEntitiesIds.set(identifier, new Set([id]));
                }

                return {
                    id,
                    actionType: ActionType.ADD_UPDATE,
                    entity: trajectoryEntity,
                };
            })
        );
    }

    public transformRouteDataToMarkedTrajectoryEntities(
        identifier: string,
        trajectories?: Trajectory[],
        routeData?: RouteData<T>,
        drawableFeatures: RouteAreaTypeId[] = ["trajectories"],
        activeEntitiesIds: Map<string, Set<string>> = new Map()
    ): Observable<AcNotification> {
        if (!drawableFeatures.includes("trajectories") || !trajectories?.length) {
            return from([...(activeEntitiesIds.get(identifier) ?? [])]).pipe(
                map((id) => ({ id, actionType: ActionType.DELETE })),
                tap(() => activeEntitiesIds.get(identifier)?.clear())
            );
        }

        return from(trajectories).pipe(
            mergeMap((trajectory, index) => {
                const sections = routeData?.route.sections;

                if (!sections?.length || trajectory.positions.length < 2) {
                    return of(undefined);
                }

                const combinedFlightArea = combineFlightArea(sections);

                if (!combinedFlightArea) {
                    return of(undefined);
                }

                const trajectoryLine = lineString(trajectory.positions.map(({ longitude, latitude }) => [longitude, latitude]));
                const orderedIntersectionPoints = this.findOrderedIntersectionPoints(trajectory, combinedFlightArea);
                const firstPoint = trajectoryLine.geometry.coordinates[0];
                const lastPoint = trajectoryLine.geometry.coordinates[trajectoryLine.geometry.coordinates.length - 1];
                const isFirstPointInArea = booleanPointInPolygon(point(firstPoint), combinedFlightArea);
                const combinedIntersectionPoints = [point(firstPoint), ...orderedIntersectionPoints.features, point(lastPoint)];

                const markedLines = combinedIntersectionPoints
                    .reduce<Feature<LineString>[]>((acc, value, pointIndex, array) => {
                        const nextPoint = array[pointIndex + 1];

                        return !nextPoint ? acc : [...acc, lineSlice(value, nextPoint, trajectoryLine)];
                    }, [])
                    .filter((line, lineIndex) => (lineIndex % 2 === 0 ? !isFirstPointInArea : isFirstPointInArea));

                return from(markedLines).pipe(
                    map((linePoint, lineIndex) => {
                        const trajectoryEntity = {
                            positions: linePoint.geometry.coordinates.map(([longitude, latitude]) =>
                                MapUtils.convertSerializableCartographicToCartesian3({ longitude, latitude })
                            ),
                        };

                        const id = "trajectories" + index + lineIndex + identifier;

                        const activeEntities = activeEntitiesIds.get(identifier);

                        if (activeEntities) {
                            activeEntities.add(id);
                        } else {
                            activeEntitiesIds.set(identifier, new Set([id]));
                        }

                        return {
                            id,
                            actionType: ActionType.ADD_UPDATE,
                            entity: trajectoryEntity,
                        };
                    })
                );
            }),
            RxjsUtils.filterFalsy()
        );
    }

    private findOrderedIntersectionPoints(trajectory: Trajectory, area: Feature<Polygon | MultiPolygon>) {
        const orderedIntersectionPoints = featureCollection<Point>([]);

        trajectory.positions.forEach((position, positionIndex) => {
            const nextPosition = trajectory.positions[positionIndex + 1];
            if (!nextPosition) {
                return;
            }

            const currentPointCoords = [position.longitude, position.latitude];
            const nextPointCoords = [nextPosition.longitude, nextPosition.latitude];

            const lineSection = lineString([currentPointCoords, nextPointCoords]);
            const intersectionPoints = lineIntersect(area.geometry, lineSection);

            if (intersectionPoints.features.length > 1) {
                const currentPoint = point(currentPointCoords);
                intersectionPoints.features.sort((left, right) => turfDistance(left, currentPoint) - turfDistance(right, currentPoint));
            }

            orderedIntersectionPoints.features.push(...intersectionPoints.features);
        });

        return orderedIntersectionPoints;
    }
}
