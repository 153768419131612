import { Polyline3DEntity } from "../services/entity-editors/map-entities-editor.service";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export function arePolyline3DEntitiesPositionsEqual(left: Polyline3DEntity, right: Polyline3DEntity): boolean {
    if (left.positions.length !== right.positions.length) {
        return false;
    }

    if (left.bufferWidths.some((bufferWidth, index) => bufferWidth !== right.bufferWidths[index])) {
        return false;
    }

    for (let index = 0; index < left.positions.length; index++) {
        if (!Cesium.Cartesian3.equalsEpsilon(left.positions[index], right.positions[index], Cesium.Math.EPSILON6)) {
            return false;
        }
    }

    return true;
}
