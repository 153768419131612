import {
    APPROX_DAYS_IN_MONTH,
    APPROX_DAYS_IN_YEAR,
    DAYS_IN_WEEK,
    HOURS_IN_DAY,
    MINUTES_IN_HOUR,
    SECONDS_IN_MINUTE,
} from "../../unit-utils/time";
import { extract } from "./extract";
import { ISO8601TimeDuration } from "./models";

export function convertToSeconds(duration: ISO8601TimeDuration): number | null {
    const durationItems = extract(duration);

    if (durationItems) {
        const secondsInYears = (durationItems.years ?? 0) * APPROX_DAYS_IN_YEAR * HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE;
        const secondsInMonths = (durationItems.months ?? 0) * APPROX_DAYS_IN_MONTH * HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE;
        const secondsInWeeks = (durationItems.weeks ?? 0) * DAYS_IN_WEEK * HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE;
        const secondsInDays = (durationItems.days ?? 0) * HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE;
        const secondsInHours = (durationItems.hours ?? 0) * MINUTES_IN_HOUR * SECONDS_IN_MINUTE;
        const secondsInMinutes = (durationItems.minutes ?? 0) * SECONDS_IN_MINUTE;

        return (
            secondsInYears +
            secondsInMonths +
            secondsInWeeks +
            secondsInDays +
            secondsInHours +
            secondsInMinutes +
            (durationItems.seconds ?? 0)
        );
    }

    return null;
}
