<ng-container *ngrxLet="incomingNotificationsLength$; let incomingNotificationsLength">
    <button
        type="button"
        class="button-icon notifications-button"
        [matMenuTriggerFor]="notificationsDropdown"
        (click)="downloadFirstPageNotifications()"
        [attr.aria-label]="'dtmNotifications.notificationButtonAriaLabel' | transloco"
    >
        <dtm-ui-icon
            *ngIf="incomingNotificationsLength; else noNewNotificationsTemplate"
            name="notification"
            [matBadge]="incomingNotificationsLength"
            matBadgeColor="warn"
            matBadgeSize="small"
        ></dtm-ui-icon>
        <ng-template #noNewNotificationsTemplate>
            <dtm-ui-icon name="notification"></dtm-ui-icon>
        </ng-template>
    </button>
    <mat-menu #notificationsDropdown="matMenu" xPosition="before" class="notifications-menu" cdkMenuGroup>
        <div class="notifications-info" *ngIf="notificationsListError$ | ngrxPush; else notificationsListTemplate">
            {{ "dtmNotifications.getNotificationsErrorHint" | transloco }}
        </div>
    </mat-menu>

    <ng-template #notificationsListTemplate>
        <ng-container *ngIf="notificationsList$ | ngrxPush as notificationsList; else loaderTemplate">
            <ng-container *ngIf="notificationsList?.length; else emptyNotificationsListTemplate">
                <div *ngIf="incomingNotificationsLength" class="new-notifications">
                    {{ "dtmNotifications.newNotificationsNumberLabel" | transloco : { notificationsNumber: incomingNotificationsLength } }}
                </div>
                <div class="notifications-list">
                    <ng-content></ng-content>
                </div>

                <!-- TODO REJ-2261 enable focus for this button-->
                <button type="button" (click)="markAllAsRead()" tabindex="0" class="read-all-notifications button-secondary">
                    {{ "dtmNotifications.markAllNotificationsAsRead" | transloco }}
                </button>
            </ng-container>
        </ng-container>
    </ng-template>
</ng-container>

<ng-template #loaderTemplate>
    <div class="notifications-spinner"><mat-spinner diameter="30" color="accent"></mat-spinner></div>
</ng-template>

<ng-template #emptyNotificationsListTemplate>
    <div class="notifications-info" *ngIf="notificationsList$ | ngrxPush as notificationsList; else loaderTemplate">
        {{ "dtmNotifications.noNotificationsLabel" | transloco }}
    </div>
</ng-template>
