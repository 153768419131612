import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
    ApplicationType,
    DssUserRoles,
    FlightZoneApplicationDraftListItem,
    FlightZoneApplicationPurpose,
    FlightZoneError,
    FlightZoneErrorType,
    FlightZoneListFilters,
} from "@dtm-frontend/dss-shared-lib";
import { AuthState } from "@dtm-frontend/shared/auth";
import { KmlExporterService } from "@dtm-frontend/shared/map";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, switchMap, tap } from "rxjs";
import { map } from "rxjs/operators";
import { FlightZoneListsActions } from "../../../state/flight-zone-lists.actions";
import { FlightZoneListsState } from "../../../state/flight-zone-lists.state";
import { FlightZoneActions } from "../../../state/flight-zone.actions";
import { FlightZoneState } from "../../../state/flight-zone.state";

@UntilDestroy()
@Component({
    selector: "dss-client-lib-application-draft-list-container",
    templateUrl: "./application-draft-list-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationDraftListContainerComponent implements OnInit {
    protected readonly initialFilters: FlightZoneListFilters;
    protected readonly flightZonePurposes: FlightZoneApplicationPurpose[] = Object.values(FlightZoneApplicationPurpose);

    protected readonly isProcessing$ = this.store.select(FlightZoneState.isProcessing);
    protected readonly applicationDraftList$ = this.store.select(FlightZoneListsState.applicationDraftList);
    protected readonly hasListDataRetrievalError$ = this.store.select(FlightZoneListsState.hasListDataRetrievalError);
    protected readonly hasSupervisorPermissions$ = this.store
        .select(AuthState.roles)
        .pipe(map((roles) => roles?.includes(DssUserRoles.InstitutionSupervisor)));

    constructor(
        private readonly store: Store,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly kmlExporterService: KmlExporterService
    ) {
        this.initialFilters = this.route.snapshot.queryParams;
    }

    public ngOnInit(): void {
        this.updateListOnQueryParamsChange();
    }

    protected deleteApplication(flightZoneId: string): void {
        this.store
            .dispatch(new FlightZoneActions.DeleteApplication(flightZoneId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightZoneState.error);

                if (error) {
                    this.displayErrorMessage(error);

                    return;
                }

                this.store.dispatch(new FlightZoneListsActions.GetApplicationDraftList(this.route.snapshot.queryParams));
                this.toastService.success(this.transloco.translate("dssClientLibFlightZone.flightZoneApplicationDeleteSuccessMessage"));
            });
    }

    protected applyFilters(filters: FlightZoneListFilters): void {
        this.updateQueryParams(filters);
    }

    protected changePage(event: PageEvent): void {
        const queryParams = { [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event.pageSize };

        this.updateQueryParams(queryParams);
    }

    protected refreshList(): void {
        this.store.dispatch(new FlightZoneListsActions.GetApplicationDraftList(this.route.snapshot.queryParams));
    }

    protected downloadKml(flightZone: FlightZoneApplicationDraftListItem): void {
        this.store
            .dispatch(new FlightZoneListsActions.GetApplicationGeoJson(flightZone, ApplicationType.RestrictionApplication))
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(FlightZoneListsState.error);

                    if (error?.type === FlightZoneErrorType.CannotGetZoneGeoJson) {
                        this.toastService.error(
                            this.transloco.translate("dssClientLibFlightZone.applicationListShared.getZoneGeoJsonErrorMessage")
                        );
                    }
                }),
                switchMap(() => this.store.select(FlightZoneListsState.zoneGeoJson)),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe((geoJson) => {
                this.kmlExporterService.geoJsonToKml(geoJson, flightZone.title);
            });
    }

    private updateQueryParams(queryParams: Params): void {
        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    private updateListOnQueryParamsChange(): void {
        this.route.queryParams.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((filterParams) => {
            this.store.dispatch(new FlightZoneListsActions.GetApplicationDraftList(filterParams));
        });
    }

    private displayErrorMessage(error: FlightZoneError): void {
        let errorMessage: string | undefined;

        switch (error.type) {
            case FlightZoneErrorType.NotAuthorized:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.notAuthorizedErrorMessage");
                break;
            case FlightZoneErrorType.InvalidApplicationVersion:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.invalidVersionErrorMessage");
                break;
            case FlightZoneErrorType.CannotDeleteApplication:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.cannotDeleteApplicationErrorMessage");
                break;
            default:
                errorMessage = this.transloco.translate("dssClientLibFlightZone.genericErrorMessage");
        }

        this.toastService.error(errorMessage);
    }
}
