<mat-card [class.edit-mode]="isEditMode" *ngrxLet="isEditMode$ as isEditMode">
    <ng-container *ngIf="!isEditMode; else editModeTemplate">
        <dtm-ui-card-header
            [buttonLabel]="editButtonLabel$ | ngrxPush"
            [isEditButtonVisible]="isEditButtonVisible$ | ngrxPush"
            (edit)="setEditMode(true)"
            [icon]="editButtonIcon$ | ngrxPush"
        >
            <ng-template [ngTemplateOutlet]="labelTemplate"></ng-template>
            <ng-container additionalActions>
                <ng-content select="[additionalActions]"></ng-content>
            </ng-container>
        </dtm-ui-card-header>
        <div class="preview-content">
            <ng-content select="[previewContent]"></ng-content>
        </div>
    </ng-container>
</mat-card>
<ng-template #editModeTemplate>
    <section class="header">
        <dtm-ui-card-header>
            <ng-container *ngIf="editionLabel$ | ngrxPush as editionLabel; else labelTemplate">
                {{ editionLabel }}
            </ng-container>
        </dtm-ui-card-header>
        <button type="button" class="button-icon" (click)="setEditMode(false)">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </section>
    <div class="content">
        <ng-content select="[editableContent]"></ng-content>
    </div>
    <div class="actions">
        <ng-container *ngIf="areActionsAvailable$ | ngrxPush">
            <button type="button" class="button-secondary" (click)="setEditMode(false)">
                {{ "dtmUi.editableCard.cancelButtonLabel" | transloco }}
            </button>
            <button type="button" class="button-primary" [disabled]="isSaveButtonDisabled$ | ngrxPush" (click)="save()">
                {{ (saveButtonLabel$ | ngrxPush) ?? "dtmUi.editableCard.saveButtonLabel" | transloco }}
            </button>
        </ng-container>
        <button
            type="button"
            *ngIf="isOnlyCancelButtonVisible$ | ngrxPush"
            class="button-secondary cancel-button"
            (click)="setEditMode(false)"
        >
            {{ "dtmUi.editableCard.cancelButtonLabel" | transloco }}
        </button>
    </div>
</ng-template>

<ng-template #labelTemplate>
    <span #labelContent>
        <ng-content select="[labelSlot]"></ng-content>
    </span>
    <ng-container *ngIf="labelContent | isEmptyElement">
        {{ label$ | ngrxPush }}
    </ng-container>
</ng-template>
