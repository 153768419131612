<ng-container *ngrxLet="{ shouldOpenOnHover: shouldOpenOnHover$, isOpen: isOpen$, popoverText: popoverText$ } as vm">
    <div
        class="trigger"
        cdkOverlayOrigin
        #popoverTrigger="cdkOverlayOrigin"
        (click)="!vm.shouldOpenOnHover && toggleOverlay()"
        (keydown.enter)="toggleOverlay()"
        (keydown.space)="toggleOverlay()"
        (keydown.tab)="hidePopover()"
        (keydown.shift.tab)="hidePopover()"
        (mouseenter)="vm.shouldOpenOnHover && showPopover()"
        (mouseleave)="vm.shouldOpenOnHover && hidePopover()"
        tabindex="0"
    >
        <div class="pointer" #pointerHost></div>
        <ng-content></ng-content>
    </div>

    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="popoverTrigger"
        (positionChange)="positionChange($event, pointerHost)"
        (detach)="removePointerClasses(pointerHost)"
        (overlayOutsideClick)="hidePopover()"
        [cdkConnectedOverlayOpen]="vm.isOpen"
    >
        <div class="popover-tooltip">
            <div class="popover-body">
                <ng-container *ngIf="vm.popoverText; else customTooltip">{{ vm.popoverText }}</ng-container>
            </div>
        </div>
    </ng-template>

    <ng-template #customTooltip>
        <ng-content select="[popoverContent]"></ng-content>
    </ng-template>
</ng-container>
