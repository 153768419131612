import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { distinctUntilChanged } from "rxjs";

@Directive({
    selector: "input[type=number][dtmUiClampInput]",
})
export class ClampInputDirective implements OnChanges {
    @Input() public min!: string | number | null;
    @Input() public max!: string | number | null;
    @Input() public step!: string | number | null;

    private minAsNumber: number | undefined;
    private maxAsNumber: number | undefined;
    private stepAsNumber: number | undefined;

    private clampedInputEmmiter = new EventEmitter<number>();
    @Output() public clampedInput = this.clampedInputEmmiter.pipe(distinctUntilChanged());

    constructor(private element: ElementRef<HTMLInputElement>) {
        this.setNativeMinMaxAttributes();
    }

    private setNativeMinMaxAttributes() {
        this.element.nativeElement.min = this.min?.toString() ?? "";
        this.element.nativeElement.max = this.max?.toString() ?? "";

        this.minAsNumber = FunctionUtils.isNullOrUndefined(this.min) ? undefined : parseFloat(this.min.toString());
        this.maxAsNumber = FunctionUtils.isNullOrUndefined(this.max) ? undefined : parseFloat(this.max.toString());
        this.stepAsNumber = FunctionUtils.isNullOrUndefined(this.step) ? undefined : parseFloat(this.step.toString());
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.min || changes.max || changes.step) {
            this.setNativeMinMaxAttributes();
            this.clampValue();
        }
    }

    private roundToStep(value: number): string {
        if (this.stepAsNumber === undefined) {
            return value.toString();
        }

        const roundedValue = Math.round(value / this.stepAsNumber) * this.stepAsNumber;

        return roundedValue.toString();
    }

    @HostListener("change")
    public clampValue() {
        const value = this.element.nativeElement.valueAsNumber;

        if ((value === undefined || isNaN(value)) && (this.minAsNumber !== undefined || this.maxAsNumber !== undefined)) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.element.nativeElement.value = this.roundToStep((this.minAsNumber ?? this.maxAsNumber)!);
            this.element.nativeElement.dispatchEvent(new Event("input"));
            this.clampedInputEmmiter.emit(this.element.nativeElement.valueAsNumber);

            return;
        }

        let clampedValue = value;

        if (this.minAsNumber !== undefined && clampedValue < this.minAsNumber) {
            clampedValue = this.minAsNumber;
        }

        if (this.maxAsNumber !== undefined && clampedValue > this.maxAsNumber) {
            clampedValue = this.maxAsNumber;
        }

        if (clampedValue !== value || this.roundToStep(value) !== this.element.nativeElement.value) {
            this.element.nativeElement.value = this.roundToStep(clampedValue);
            this.element.nativeElement.dispatchEvent(new Event("input"));
        }

        this.clampedInputEmmiter.emit(this.element.nativeElement.valueAsNumber);
    }
}
