import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { NotificationDisplay } from "../../utils/notifications.model";

interface NotificationInfoComponentState {
    notification: NotificationDisplay;
}

@Component({
    selector: "dtm-notifications-notification-info",
    templateUrl: "./notification-info.component.html",
    styleUrls: ["./notification-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NotificationInfoComponent {
    protected readonly notification$ = this.localStore.selectByKey("notification").pipe(RxjsUtils.filterFalsy());

    constructor(
        private readonly localStore: LocalComponentStore<NotificationInfoComponentState>,
        @Inject(MAT_DIALOG_DATA) private readonly notification: NotificationDisplay
    ) {
        localStore.setState({ notification });
    }
}
