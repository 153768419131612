<dtm-ui-expandable-panel
    *ngrxLet="{ soraResult: soraResult$, isExpanded: isExpanded$ } as vm"
    [isExpanded]="vm.isExpanded"
    [hasHeaderSeparator]="false"
>
    <h2 headerSlot>{{ "dtmSharedMission.finalSoraIssues.header" | transloco }}</h2>

    <dtm-ui-label-value [label]="'dtmSharedMission.finalSoraIssues.finalGrcLabel' | transloco">
        {{ (vm.soraResult?.finalGrc | grcLevel) ?? ("dtmSharedMission.finalSoraIssues.noDataLabel" | transloco) }}
        <dtm-mission-sora-issues-warning [grc]="vm.soraResult?.finalGrc"></dtm-mission-sora-issues-warning>
    </dtm-ui-label-value>

    <dtm-ui-label-value [label]="'dtmSharedMission.finalSoraIssues.residualArcLabel' | transloco">
        {{
            (vm.soraResult?.residualArc ?? vm.soraResult?.initialArc | arcLabel) ??
                ("dtmSharedMission.finalSoraIssues.noDataLabel" | transloco)
        }}
        <dtm-mission-sora-issues-warning [arc]="vm.soraResult?.residualArc ?? vm.soraResult?.initialArc"></dtm-mission-sora-issues-warning>
    </dtm-ui-label-value>

    <dtm-ui-label-value [label]="'dtmSharedMission.finalSoraIssues.sailLabel' | transloco">
        <span [class.sail-info]="vm.soraResult?.sail">
            {{
                (vm.soraResult?.finalGrc | invoke : isCertifiedCategory)
                    ? ("dtmSharedMission.finalSoraIssues.certifiedCategoryLabel" | transloco)
                    : (vm.soraResult?.sail | sailLevel) ?? ("dtmSharedMission.finalSoraIssues.noDataLabel" | transloco)
            }}
        </span>
        <dtm-mission-sora-issues-warning
            [sail]="vm.soraResult?.sail"
            [isCertifiedCategory]="vm.soraResult?.finalGrc | invoke : isCertifiedCategory"
        ></dtm-mission-sora-issues-warning>
    </dtm-ui-label-value>
</dtm-ui-expandable-panel>
