<section
    #container
    class="container"
    [ngClass]="{
        'no-title': isTitleEmpty$ | ngrxPush,
        'no-message': isMessageEmpty$ | ngrxPush,
        'no-image': isImageEmpty$ | ngrxPush,
        'no-footer': isFooterEmpty$ | ngrxPush
    }"
>
    <h2 class="title">
        <ng-content select="[titleSlot]"></ng-content>
    </h2>
    <div class="message">
        <ng-content select="[messageSlot]"></ng-content>
    </div>
    <div class="image">
        <ng-content select="[imageSlot]"></ng-content>
    </div>
    <div class="footer">
        <ng-content select="[footerSlot]"></ng-content>
    </div>
</section>
