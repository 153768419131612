import { PortalModule } from "@angular/cdk/portal";
import { DOCUMENT } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, Inject, NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
    DashboardModule,
    DssClientLibModule,
    PLANNED_MISSION_ENDPOINTS,
    PlannedMissionsModule,
    UserProfileModule,
} from "@dtm-frontend/dss-client-lib";
import { FlightZoneModule, IS_PRE_TACTICAL_APPROVAL_ENABLED } from "@dtm-frontend/dss-client-lib/flight-zone";
import {
    BaseNotificationEventsService,
    FLIGHT_ZONE_ENDPOINTS,
    FlightZoneSharedModule,
    USER_PROFILE_ENDPOINTS,
} from "@dtm-frontend/dss-shared-lib";
import { AuthState, KEYCLOAK_CONFIG, SharedAuthModule } from "@dtm-frontend/shared/auth";
import { AZURE_MAPS_SUBSCRIPTION_KEY, SHARED_MAP_ENDPOINTS } from "@dtm-frontend/shared/map";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { NOTIFICATIONS_ENDPOINTS, SharedNotificationsModule } from "@dtm-frontend/shared/notifications";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import {
    I18nRootModule,
    LANGUAGE_CONFIGURATION,
    LanguageCode,
    TRANSLATION_ENDPOINTS,
    TranslationHelperService,
    getTranslocoInlineLoader,
} from "@dtm-frontend/shared/ui/i18n";
import { VERSION_DATA_ENDPOINTS, VersionModule } from "@dtm-frontend/shared/ui/version";
import { FunctionUtils, Logger, LoggerModule, RxjsUtils } from "@dtm-frontend/shared/utils";
import { SharedWebsocketModule, SharedWebsocketModuleConfig, WEBSOCKET_CONFIGURATION } from "@dtm-frontend/shared/websocket";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsModule, Store } from "@ngxs/store";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { forkJoin, lastValueFrom } from "rxjs";
import { filter, first, tap } from "rxjs/operators";
import { DssClientEnvironment } from "../environments/environment.model";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { LazyRouterOutletComponent } from "./components/lazy-router-outlet/lazy-router-outlet.component";
import { MenuComponent } from "./components/menu/menu.component";
import { ActionsHandler } from "./services/actions.handler";
import { InstitutionNotificationEventsService } from "./services/institution-notification-events.service";

Logger.initialize("https://2c869d2da8efcc753581d89a2f5d0002@sentry.pansa.cloud/4");

export function createAppModule(environment: DssClientEnvironment) {
    @NgModule({
        declarations: [AppComponent, LazyRouterOutletComponent, HeaderComponent, MenuComponent],
        imports: [
            NgxsModule.forRoot([], {
                // NOTE: must be first because of https://github.com/ngxs/store/issues/375
                developmentMode: !environment.production,
            }),
            AppRoutingModule,
            BrowserAnimationsModule,
            BrowserModule,
            HttpClientModule,
            DssClientLibModule,
            MatButtonModule,
            MatIconModule,
            MatMenuModule,
            NgxsStoragePluginModule.forRoot({
                key: ["ui"],
                storage: StorageOption.LocalStorage,
            }),
            NgxsReduxDevtoolsPluginModule.forRoot({
                disabled: environment.production,
                name: "DssClient",
            }),
            FlightZoneModule.forRoot(),
            PlannedMissionsModule.forRoot(),
            DashboardModule,
            UserProfileModule,
            FlightZoneSharedModule,
            PortalModule,
            LetModule,
            PushModule,
            LoggerModule.forRoot(environment.name, !environment.production),
            SharedAuthModule.forRoot({
                bearerExcludedUrls: [...environment.bearerExcludedUrls],
            }),
            I18nRootModule.forRoot({
                developmentMode: !environment.production,
                ...LANGUAGE_CONFIGURATION,
            }),
            SharedUiModule,
            SharedWebsocketModule.forRoot(),
            SharedNotificationsModule.forRoot(),
            VersionModule.forRoot(),
            CesiumMapModule,
            MatTooltipModule,
        ],
        providers: [
            {
                provide: FLIGHT_ZONE_ENDPOINTS,
                useValue: environment.flightZoneEndpoints,
            },
            {
                provide: PLANNED_MISSION_ENDPOINTS,
                useValue: environment.plannedMissionsEndpoints,
            },
            {
                provide: VERSION_DATA_ENDPOINTS,
                useValue: environment.versionDataEndpoints,
            },
            {
                provide: NOTIFICATIONS_ENDPOINTS,
                useValue: environment.notificationEndpoints,
            },
            {
                provide: USER_PROFILE_ENDPOINTS,
                useValue: environment.userProfileEndpoints,
            },
            {
                provide: TRANSLATION_ENDPOINTS,
                useValue: environment.translationEndpoints,
            },
            {
                provide: SHARED_MAP_ENDPOINTS,
                useValue: environment.sharedMapEndpoints,
            },
            {
                provide: AZURE_MAPS_SUBSCRIPTION_KEY,
                useValue: environment.azureMapsSubscriptionKey,
            },
            {
                provide: WEBSOCKET_CONFIGURATION,
                useFactory: (store: Store): SharedWebsocketModuleConfig => ({
                    endpoint: environment.webSocketEndpoint,
                    authorizationTokenProvider: async () =>
                        await lastValueFrom(store.select(AuthState.token).pipe(RxjsUtils.filterFalsy(), first())),
                }),
                deps: [Store],
            },
            {
                provide: KEYCLOAK_CONFIG,
                useValue: environment.keycloakConfig,
            },
            {
                provide: APP_INITIALIZER,
                useFactory: () => FunctionUtils.noop,
                deps: [ActionsHandler],
                multi: true,
            },
            {
                provide: TRANSLOCO_SCOPE,
                multi: true,
                useValue: {
                    scope: "dssClient",
                    loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
                },
            },
            {
                provide: IS_PRE_TACTICAL_APPROVAL_ENABLED,
                useValue: environment.isPreTacticalApprovalEnabled,
            },
            {
                provide: BaseNotificationEventsService,
                useClass: InstitutionNotificationEventsService,
            },
        ],
        bootstrap: [AppComponent],
    })
    class AppModule {
        constructor(
            keycloakService: KeycloakService,
            store: Store,
            translocoHelper: TranslationHelperService,
            titleService: Title,
            @Inject(DOCUMENT) document: Document
        ) {
            // TODO: is this a good place for some initializing tasks? Maybe it should be extracted somewhere, use APP_INITIALIZER?
            const gotUserData$ = keycloakService.keycloakEvents$.pipe(
                filter((event) => event?.type === KeycloakEventType.OnReady),
                first()
            );

            const gotTitle$ = translocoHelper
                .waitForTranslation("dssClient.appTitle")
                .pipe(first(), tap(titleService.setTitle.bind(titleService)));

            forkJoin([gotUserData$, gotTitle$])
                .pipe(
                    first(),
                    tap(() => {
                        document.getElementById("loader")?.remove();
                    })
                )
                .subscribe();
        }
    }

    return AppModule;
}
