// eslint-disable-next-line max-len
// NOTE: this is based on https://github.com/pansa-dev/ngx-cesium/blob/HEAD/projects/@pansa/ngx-cesium/src/lib/@pansa/ngx-cesium-widgets/services/entity-editors/polyline-editor/polylines-manager.service.ts

import { Injectable } from "@angular/core";
import { AcLayerComponent, Cartesian3 } from "@pansa/ngx-cesium";
import { EditablePolyline3D } from "../../../models/polyline3d/editable-polyline3d";
import { Polyline3DEditOptions } from "../../../models/polyline3d/polyline3d-edit-options";

@Injectable()
export class Polylines3DManagerService {
    public readonly polylines: Map<string, EditablePolyline3D> = new Map<string, EditablePolyline3D>();

    public createEditablePolyline3D(
        id: string,
        editPointsLayer: AcLayerComponent,
        editHeightPointsLayer: AcLayerComponent,
        editPolylinesLayer: AcLayerComponent,
        editBuffersLayer: AcLayerComponent,
        polylineOptions: Polyline3DEditOptions,
        positions?: Cartesian3[],
        heights?: (number | undefined)[],
        bufferWidths?: number[],
        bufferHeights?: number[]
    ) {
        const editablePolyline = new EditablePolyline3D(
            id,
            editPointsLayer,
            editHeightPointsLayer,
            editPolylinesLayer,
            editBuffersLayer,
            polylineOptions,
            positions,
            heights,
            bufferWidths,
            bufferHeights
        );
        this.polylines.set(id, editablePolyline);
    }

    public get(id: string): EditablePolyline3D | undefined {
        return this.polylines.get(id);
    }

    public clear() {
        this.polylines.forEach((polyline) => polyline.dispose());
        this.polylines.clear();
    }
}
