<div class="dialog-header">
    <div mat-dialog-title>
        <div class="header">
            <dtm-ui-icon name="filter"></dtm-ui-icon>
            {{ "dtmSharedMissionSearch.filterModal.header" | transloco }}
        </div>
    </div>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    <dtm-mission-mission-search-filter-conditions
        #filterConditions
        [isPartialSearchFeatureDisabled]="true"
        [initialFilterConditions]="data.initialFilterConditions"
        [operatorSearchState]="data.operatorSearchState$ | ngrxPush"
        [pilotSearchState]="data.pilotSearchState$ | ngrxPush"
        [missionIdSearchState]="data.missionIdSearchState$ | ngrxPush"
        [uavSearchState]="data.uavSearchState$ | ngrxPush"
        [zoneSearchState]="data.zoneSearchState$ | ngrxPush"
        (searchTextChange)="searchTextChange.emit($event)"
    ></dtm-mission-mission-search-filter-conditions>
</div>
<div mat-dialog-actions align="end">
    <button type="button" class="button-secondary" [matDialogClose]="false">
        {{ "dtmSharedMissionSearch.filterModal.cancelButtonLabel" | transloco }}
    </button>
    <button type="button" class="button-primary" (click)="applyFilter()" [disabled]="filterConditions.isFilterFormInvalid$ | ngrxPush">
        {{ "dtmSharedMissionSearch.filterModal.searchButtonLabel" | transloco }}
    </button>
</div>
