// eslint-disable-next-line max-len
// NOTE: this is based on https://github1s.com/pansa-dev/ngx-cesium/blob/HEAD/projects/@pansa/ngx-cesium/src/lib/@pansa/ngx-cesium-widgets/components/polylines-editor/polylines-editor.component.ts#L1-L305

import { ChangeDetectionStrategy, Component, Input, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
    AcLayerComponent,
    AcNotification,
    CameraService,
    CesiumService,
    CoordinateConverter,
    EditActions,
    EditModes,
    MapEventsManagerService,
} from "@pansa/ngx-cesium";
import { Subject } from "rxjs";
import { EntityParentCartographicEditPoint, EntityParentCartographicEditPointType } from "../../models/child-entity.model";
import { EditablePolyline3D, Polyline3DLabel } from "../../models/polyline3d/editable-polyline3d";
import { Polyline3DEditUpdate } from "../../models/polyline3d/polyline3d-edit-update";
import { Polylines3DEditorService } from "../../services/entity-editors/polylines3d-editor/polylines3d-editor.service";
import { Polylines3DManagerService } from "../../services/entity-editors/polylines3d-editor/polylines3d-manager.service";
import { HeightHelperService, ShapeDragActions } from "../../services/height-helper.service";
import { CesiumPointerManagerService } from "../../services/pointer-manager/cesium-pointer-manager.service";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface Polylines3DEditorComponentState {
    isShown: boolean;
    labelTemplate: TemplateRef<any> | null;
}

@UntilDestroy()
@Component({
    selector: "dtm-map-polylines3d-editor[show]",
    templateUrl: "./polylines3d-editor.component.html",
    providers: [Polylines3DManagerService, HeightHelperService, LocalComponentStore, CoordinateConverter],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Polylines3DEditorComponent implements OnDestroy {
    private editLabelsRenderFunctions = new Map<string, (update: Polyline3DEditUpdate, labels: Polyline3DLabel[]) => Polyline3DLabel[]>();

    protected readonly Cesium = Cesium;
    protected readonly editPoints$ = new Subject<AcNotification>();
    protected readonly editHeightPoints$ = new Subject<AcNotification>();
    protected readonly editPolylines$ = new Subject<AcNotification>();
    protected readonly editBuffers$ = new Subject<AcNotification>();
    protected readonly polylineLabels$ = new Subject<AcNotification>();

    protected readonly isShown$ = this.localStore.selectByKey("isShown");
    protected readonly labelTemplate$ = this.localStore.selectByKey("labelTemplate");

    @ViewChild("editPointsLayer") private editPointsLayer!: AcLayerComponent;
    @ViewChild("editHeightPointsLayer") private editHeightPointsLayer!: AcLayerComponent;
    @ViewChild("editPolylinesLayer") private editPolylinesLayer!: AcLayerComponent;
    @ViewChild("editBuffersLayer") private editBuffersLayer!: AcLayerComponent;
    @ViewChild("polylineLabelsLayer") private polylineLabelsLayer!: AcLayerComponent;

    @Input() public set show(value: boolean) {
        this.localStore.patchState({ isShown: value });
    }

    @Input() public set labelTemplate(value: TemplateRef<any> | null) {
        this.localStore.patchState({ labelTemplate: value });
    }

    constructor(
        private readonly polylinesEditor: Polylines3DEditorService,
        private readonly coordinateConverter: CoordinateConverter,
        private readonly mapEventsManager: MapEventsManagerService,
        private readonly cameraService: CameraService,
        private readonly polylinesManager: Polylines3DManagerService,
        private readonly cesiumService: CesiumService,
        private readonly cesiumPointerManager: CesiumPointerManagerService,
        private readonly localStore: LocalComponentStore<Polylines3DEditorComponentState>,
        heightHelperService: HeightHelperService
    ) {
        this.polylinesEditor.init(
            this.mapEventsManager,
            this.coordinateConverter,
            this.cameraService,
            this.polylinesManager,
            this.cesiumService,
            this.cesiumPointerManager,
            heightHelperService
        );
        this.startListeningToEditorUpdates();
        this.localStore.setState({
            isShown: true,
            labelTemplate: null,
        });
    }

    private startListeningToEditorUpdates() {
        this.polylinesEditor
            .onUpdate()
            .pipe(untilDestroyed(this))
            .subscribe((update: Polyline3DEditUpdate) => {
                if (update.editMode === EditModes.CREATE || update.editMode === EditModes.CREATE_OR_EDIT) {
                    this.handleCreateUpdates(update);
                } else if (update.editMode === EditModes.EDIT) {
                    this.polylinesManager.get(update.id)?.markAsDynamicEntityWhileUpdate();
                    this.handleEditUpdates(update);
                }
            });
    }

    private getLabelId(polyline: EditablePolyline3D, label: Polyline3DLabel): string {
        return `${polyline.getId()}_${label.pointIndex}_${label.id}`;
    }

    private getLabelIdsForPolyline(polyline: EditablePolyline3D, labels: string[]): Set<string> {
        return new Set(labels.filter((label) => label.startsWith(polyline.getId())));
    }

    public renderEditLabels(polyline: EditablePolyline3D, update: Polyline3DEditUpdate, labels?: Polyline3DLabel[]) {
        update.positions = polyline.getRealPositions(false);
        update.points = polyline.getRealPoints(false);
        update.heights = polyline.getRealHeights(false);
        update.bufferWidths = polyline.getRealBufferWidths(false);
        update.bufferHeights = polyline.getRealBufferHeights(false);
        update.childEntities = polyline.getChildEntities();

        if (labels) {
            polyline.labels = labels;
            this.synchronizeLabels(polyline);

            return;
        }

        const editLabelsRenderFn = this.editLabelsRenderFunctions.get(polyline.getId());

        if (!editLabelsRenderFn) {
            return;
        }

        polyline.labels = editLabelsRenderFn(update, polyline.labels);
        this.synchronizeLabels(polyline);
    }

    private synchronizeLabels(polyline: EditablePolyline3D) {
        const unusedLabelIds = this.getLabelIdsForPolyline(polyline, Array.from(this.polylineLabelsLayer.getStore().keys()));

        polyline.labels.forEach((label) => {
            const labelId = this.getLabelId(polyline, label);

            this.polylineLabelsLayer.update(label, labelId);
            unusedLabelIds.delete(labelId);
        });

        unusedLabelIds.forEach((labelId) => {
            this.polylineLabelsLayer.remove(labelId);
        });
    }

    public removeEditLabels(polyline: EditablePolyline3D) {
        polyline.labels.forEach((label) => {
            this.polylineLabelsLayer.remove(this.getLabelId(polyline, label));
        });
        polyline.labels = [];
        this.editLabelsRenderFunctions.delete(polyline.getId());
    }

    public handleCreateUpdates(update: Polyline3DEditUpdate) {
        switch (update.editAction) {
            case EditActions.INIT: {
                if (update.polylineOptions) {
                    this.polylinesManager.createEditablePolyline3D(
                        update.id,
                        this.editPointsLayer,
                        this.editHeightPointsLayer,
                        this.editPolylinesLayer,
                        this.editBuffersLayer,
                        update.polylineOptions
                    );
                }
                break;
            }
            case EditActions.MOUSE_MOVE: {
                const polyline = this.polylinesManager.get(update.id);
                if (polyline && update.updatedPosition) {
                    polyline.moveTempMovingPoint(update.updatedPosition);
                    this.renderEditLabels(polyline, update);
                }
                break;
            }
            case EditActions.ADD_POINT: {
                const polyline = this.polylinesManager.get(update.id);
                if (polyline && update.updatedPosition) {
                    polyline.moveTempMovingPoint(update.updatedPosition);
                    polyline.addPoint(update.updatedPosition);
                    this.renderEditLabels(polyline, update);
                }
                break;
            }
            case EditActions.DONE: {
                const polyline = this.polylinesManager.get(update.id);
                if (polyline) {
                    polyline.finishCreation();
                    this.renderEditLabels(polyline, update);
                }
                break;
            }
            case EditActions.REMOVE_POINT: {
                const polyline = this.polylinesManager.get(update.id);
                if (polyline && polyline.enableEdit && update.updatedPoint) {
                    polyline.removePoint(update.updatedPoint);
                    this.renderEditLabels(polyline, update);
                }

                if (polyline && update.shouldRemoveMovingPoint) {
                    polyline.removeTempMovingPoint();
                    this.renderEditLabels(polyline, update);
                }
                break;
            }
            case EditActions.DISPOSE: {
                const polyline = this.polylinesManager.get(update.id);
                if (polyline) {
                    polyline.dispose();
                    this.removeEditLabels(polyline);
                }
                break;
            }
            case EditActions.SET_EDIT_LABELS_RENDER_CALLBACK: {
                const polyline = this.polylinesManager.get(update.id);
                if (polyline && update.labelsRenderFn) {
                    this.editLabelsRenderFunctions.set(polyline.getId(), update.labelsRenderFn);
                    this.renderEditLabels(polyline, update);
                }
                break;
            }
            case EditActions.UPDATE_EDIT_LABELS: {
                const polyline = this.polylinesManager.get(update.id);
                if (polyline) {
                    this.renderEditLabels(polyline, update, update.updateLabels);
                }
                break;
            }
            case EditActions.SET_MANUALLY: {
                const polyline = this.polylinesManager.get(update.id);
                if (polyline) {
                    this.renderEditLabels(polyline, update, update.updateLabels);
                }
                break;
            }
            default: {
                return;
            }
        }
    }

    public handleEditUpdates(update: Polyline3DEditUpdate) {
        switch (update.editAction) {
            case EditActions.INIT: {
                if (update.polylineOptions) {
                    this.polylinesManager.createEditablePolyline3D(
                        update.id,
                        this.editPointsLayer,
                        this.editHeightPointsLayer,
                        this.editPolylinesLayer,
                        this.editBuffersLayer,
                        update.polylineOptions,
                        update.positions,
                        update.heights,
                        update.bufferWidths,
                        update.bufferHeights
                    );
                }
                break;
            }
            case EditActions.DRAG_POINT: {
                const polyline = this.polylinesManager.get(update.id);
                if (polyline && polyline.enableEdit && update.updatedPosition && update.updatedPoint) {
                    if (
                        update.updatedPoint.type === EntityParentCartographicEditPointType.Inlet ||
                        update.updatedPoint.type === EntityParentCartographicEditPointType.Outlet
                    ) {
                        const toPosition = polyline.getStickedPointOnChildEntityOutline(update.updatedPoint, update.updatedPosition);
                        polyline.movePoint(toPosition, update.updatedPoint);
                    } else {
                        polyline.movePoint(update.updatedPosition, update.updatedPoint);
                    }

                    this.renderEditLabels(polyline, update);
                }
                break;
            }
            case EditActions.DRAG_POINT_FINISH: {
                const polyline = this.polylinesManager.get(update.id);
                if (polyline && polyline.enableEdit && update.updatedPoint) {
                    polyline.movePointFinish(update.updatedPoint);

                    if (update.updatedPoint.isVirtualEditPoint()) {
                        polyline.changeVirtualPointToRealPoint(update.updatedPoint);
                        this.renderEditLabels(polyline, update);
                    }
                }
                break;
            }
            case EditActions.REMOVE_POINT: {
                const polyline = this.polylinesManager.get(update.id);
                if (polyline && polyline.enableEdit && update.updatedPoint) {
                    polyline.removePoint(update.updatedPoint);
                    this.renderEditLabels(polyline, update);
                }
                break;
            }
            case EditActions.DISABLE: {
                const polyline = this.polylinesManager.get(update.id);
                if (polyline) {
                    polyline.enableEdit = false;
                    this.renderEditLabels(polyline, update);
                }
                break;
            }
            case EditActions.ENABLE: {
                const polyline = this.polylinesManager.get(update.id);
                if (polyline) {
                    polyline.enableEdit = true;
                    this.renderEditLabels(polyline, update);
                }
                break;
            }
            case EditActions.DRAG_SHAPE_FINISH:
            case EditActions.DRAG_SHAPE: {
                const polyline = this.polylinesManager.get(update.id);

                if (!polyline || !polyline.enableEdit || update.shapeDragAction === undefined) {
                    break;
                }

                if (update.editAction === EditActions.DRAG_SHAPE_FINISH && update.shapeDragAction === ShapeDragActions.MoveEntity) {
                    polyline.endMoveShape();

                    break;
                }

                if (polyline && polyline.enableEdit && update.draggedPosition && update.updatedPosition) {
                    polyline.moveShape(update.draggedPosition, update.updatedPosition);
                    this.renderEditLabels(polyline, update);
                }

                switch (update.shapeDragAction) {
                    case ShapeDragActions.MoveEntity:
                        if (update.draggedPosition && update.updatedPosition) {
                            polyline.moveShape(update.draggedPosition, update.updatedPosition);
                        }
                        break;

                    case ShapeDragActions.ChangeTopHeight:
                        if (update.height !== undefined && update.updatedHeightPoint !== undefined) {
                            polyline.moveHeight(update);
                        }
                        break;

                    case ShapeDragActions.ChangeBottomHeight:
                        throw new Error("Invalid shapeDragAction");
                }

                this.renderEditLabels(polyline, update);

                break;
            }
            default: {
                return;
            }
        }
    }

    public ngOnDestroy(): void {
        this.polylinesManager.clear();
    }

    public getPointSize(point: EntityParentCartographicEditPoint) {
        return point.isVirtualEditPoint() ? point.props.virtualPointPixelSize : point.props.pixelSize;
    }

    public getPointShow(point: EntityParentCartographicEditPoint) {
        return (
            point.show &&
            (point.isVirtualEditPoint()
                ? point.props.showVirtual && point.type === EntityParentCartographicEditPointType.Waypoint
                : point.props.show) &&
            !point.childEntity
        );
    }
}
