import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { VersionData, VersionDataErrorType } from "./utils/version-data.model";
import { VERSION_DATA_ENDPOINTS, VersionDataEndpoints } from "./version.tokens";

@Injectable({
    providedIn: "root",
})
export class DisplayVersionApiService {
    constructor(@Inject(VERSION_DATA_ENDPOINTS) private versionEndpoints: VersionDataEndpoints, private httpClient: HttpClient) {}

    public getVersionData(): Observable<VersionData> {
        return this.httpClient
            .get<VersionData>(this.versionEndpoints.getVersionDetails)
            .pipe(catchError(() => throwError({ type: VersionDataErrorType.GetVersionDataError })));
    }
}
