import { Injectable } from "@angular/core";
import { ArrayUtils } from "@dtm-frontend/shared/utils";
import { Action, createSelector, State, StateContext } from "@ngxs/store";
import { WizardActions } from "./wizard.actions";

export interface WizardStateModel {
    [wizardId: string]: {
        activeStepId: string | undefined;
        enabledSteps: string[];
        dirtyStep: string | undefined;
    };
}

const defaultState: WizardStateModel = {};

@State<WizardStateModel>({
    name: "wizard",
    defaults: defaultState,
})
@Injectable()
export class WizardState {
    public static activeStepId<T extends string = string>(wizardId: string) {
        return createSelector([WizardState], (state: WizardStateModel): T | undefined => state[wizardId]?.activeStepId as T);
    }

    public static enabledSteps(wizardId: string) {
        return createSelector([WizardState], (state: WizardStateModel): string[] => state[wizardId]?.enabledSteps ?? []);
    }

    @Action(WizardActions.SetActiveStep)
    public setActiveStep(context: StateContext<WizardStateModel>, action: WizardActions.SetActiveStep) {
        const state: WizardStateModel[number] = context.getState()[action.wizardId];

        if (state?.dirtyStep && state.dirtyStep !== action.stepId) {
            context.dispatch(new WizardActions.TryChangeDirtyStep(action.wizardId, action.stepId));

            return;
        }

        context.patchState({
            [action.wizardId]: {
                ...state,
                activeStepId: action.stepId,
            },
        });
    }

    @Action(WizardActions.EnableSteps)
    public enableSteps(context: StateContext<WizardStateModel>, action: WizardActions.EnableSteps) {
        const currentWizardState = context.getState()[action.wizardId];

        context.patchState({
            [action.wizardId]: {
                ...currentWizardState,
                enabledSteps: ArrayUtils.unique([...(currentWizardState.enabledSteps ?? []), ...action.stepIds]),
            },
        });
    }

    @Action(WizardActions.DisableSteps)
    public disableSteps(context: StateContext<WizardStateModel>, action: WizardActions.DisableSteps) {
        const currentWizardState = context.getState()[action.wizardId];

        context.patchState({
            [action.wizardId]: {
                ...currentWizardState,
                enabledSteps: (currentWizardState.enabledSteps ?? []).filter((stepId) => !action.stepIds.includes(stepId)),
            },
        });
    }

    @Action(WizardActions.CleanupWizard)
    public cleanupWizard(context: StateContext<WizardStateModel>, action: WizardActions.CleanupWizard) {
        const newState = { ...context.getState() };
        delete newState[action.wizardId];

        context.setState(newState);
    }

    @Action(WizardActions.UpdateDirtyStep)
    public updateDirtyStep(context: StateContext<WizardStateModel>, { wizardId, dirtyStep }: WizardActions.UpdateDirtyStep) {
        const currentWizardState = context.getState()[wizardId];

        context.patchState({
            [wizardId]: {
                ...currentWizardState,
                dirtyStep,
            },
        });
    }
}
