import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs/operators";
import { Address } from "../../models/address.models";
import { DisplayCountryService } from "../../services/display-country/display-country.service";

export interface AddressLabelValueComponentState {
    address: Address | undefined;
    isCountryFlagVisible: boolean;
    label: string | undefined;
}

@Component({
    selector: "dtm-ui-address-label-value[address]",
    templateUrl: "./address-label-value.component.html",
    styleUrls: ["./address-label-value.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AddressLabelValueComponent {
    @Input()
    public set address(value: Address | undefined) {
        this.localStore.patchState({ address: value });
    }
    @Input()
    public set isCountryFlagVisible(value: BooleanInput) {
        this.localStore.patchState({ isCountryFlagVisible: coerceBooleanProperty(value) });
    }
    @Input()
    public set label(value: string | undefined) {
        this.localStore.patchState({ label: value });
    }

    protected readonly address$ = this.localStore.selectByKey("address").pipe(RxjsUtils.filterFalsy());
    protected readonly label$ = this.localStore.selectByKey("label");
    protected readonly country$ = this.address$.pipe(map((address) => this.countryDetailsService.getCountryByCountryCode(address.country)));
    protected readonly isCountryFlagVisible$ = this.localStore.selectByKey("isCountryFlagVisible");

    constructor(
        private readonly localStore: LocalComponentStore<AddressLabelValueComponentState>,
        private readonly countryDetailsService: DisplayCountryService
    ) {
        this.localStore.setState({
            address: undefined,
            isCountryFlagVisible: false,
            label: undefined,
        });
    }
}
