<div class="form-container">
    <dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
        <form class="form-card" [formGroup]="loginForm" (ngSubmit)="submit()">
            <div class="header">{{ "dtmAuth.login.header" | transloco }}</div>

            <dtm-ui-input-field>
                <label> {{ "dtmAuth.login.usernameLabel" | transloco }}</label>
                <input matInput type="text" formControlName="username" />
                <div class="field-error" *dtmUiFieldHasError="loginForm.controls.username; name: 'requiredTouched'">
                    {{ "dtmAuth.login.requiredFieldErrorHint" | transloco }}
                </div>
                <div *ngIf="hasLoginError$ | ngrxPush" class="field-error">
                    {{ "dtmAuth.login.invalidUsernameOrPasswordErrorHint" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field>
                <label> {{ "dtmAuth.login.passwordLabel" | transloco }}</label>
                <input #passwordInput matInput type="password" formControlName="password" />
                <dtm-ui-password-visibility-switch [inputParent]="passwordInput" class="field-suffix"></dtm-ui-password-visibility-switch>
                <div class="field-error" *dtmUiFieldHasError="loginForm.controls.password; name: 'requiredTouched'">
                    {{ "dtmAuth.login.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-input-field>
            <button *ngIf="passwordReset.observed" type="button" class="button-tertiary reset-button" (click)="passwordReset.emit()">
                {{ "dtmAuth.login.resetPasswordButtonLabel" | transloco }}
            </button>

            <button type="submit" class="button-primary">{{ "dtmAuth.login.submitButtonLabel" | transloco }}</button>

            <div *ngIf="register.observed" class="register-section">
                <span>{{ "dtmAuth.login.notRegisteredMessage" | transloco }}</span>
                <button type="button" class="link" (click)="register.emit()">{{ "dtmAuth.login.registerButtonLabel" | transloco }}</button>
            </div>
        </form>
    </dtm-ui-loader-container>
</div>
