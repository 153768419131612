import { combineFlightArea } from "../../shared/utils/combine-flight-area";
import { AnalysisResult, SimpleWeather, Weather, WeatherCondition, WeatherDetails, WeatherPayloadData } from "../models/weather.models";

export interface WeatherConditionResponseBody {
    min: number;
    max: number;
    avg: number;
    unit: string;
    analysisResult: AnalysisResult;
}

export interface WeatherConditionsResponseBody {
    forDtm: boolean;
    forecastRefTime: string;
    snapshots: Record<string, WeatherResponseBody>;
}

export interface WeatherResponseBody {
    temp: WeatherConditionResponseBody;
    dewPointTemperature: WeatherConditionResponseBody;
    pressureAmsl: WeatherConditionResponseBody;
    specHumidity: WeatherConditionResponseBody;
    relHumidity: WeatherConditionResponseBody;
    totalCloudCover: WeatherConditionResponseBody;
    lowCloudCover: WeatherConditionResponseBody;
    mediumCloudCover: WeatherConditionResponseBody;
    heightCloudCover: WeatherConditionResponseBody;
    cloudBase: WeatherConditionResponseBody;
    snow: WeatherConditionResponseBody;
    rain: WeatherConditionResponseBody;
    visibility: WeatherConditionResponseBody;
    windSpeed100Agl: WeatherConditionResponseBody;
    windDirection100Agl: WeatherConditionResponseBody;
    windSpeed10Agl: WeatherConditionResponseBody;
    windDirection10Agl: WeatherConditionResponseBody;
    kpi: WeatherConditionResponseBody;
    windGusts10Agl: WeatherConditionResponseBody;
    groundPressure: WeatherConditionResponseBody;
}

const omitNegativeZero = (value: number, precision: number): string =>
    !precision && value > -1 && value < 1 ? 0 + "" : value.toFixed(precision);

const convertDataToFixed = (condition: WeatherConditionResponseBody, precision: number = 0): WeatherCondition =>
    condition && {
        ...condition,
        min: omitNegativeZero(condition.min, precision),
        max: omitNegativeZero(condition.max, precision),
        avg: omitNegativeZero(condition.avg, precision),
    };

export const convertWeatherResponseBodyMapToWeather = ({
    snapshots,
    forecastRefTime,
    forDtm,
}: WeatherConditionsResponseBody): WeatherDetails => ({
    forecastRefTime: new Date(forecastRefTime),
    isWithinDtm: forDtm,
    weatherConditions: Object.keys(snapshots).reduce(
        (acc: Weather[], curr: string) => [
            ...acc,
            {
                time: curr,
                temp: convertDataToFixed(snapshots[curr].temp),
                dewPointTemperature: convertDataToFixed(snapshots[curr].dewPointTemperature),
                pressureAmsl: convertDataToFixed(snapshots[curr].pressureAmsl, 1),
                specHumidity: convertDataToFixed(snapshots[curr].specHumidity),
                relHumidity: convertDataToFixed(snapshots[curr].relHumidity),
                totalCloudCover: convertDataToFixed(snapshots[curr].totalCloudCover),
                lowCloudCover: convertDataToFixed(snapshots[curr].lowCloudCover),
                mediumCloudCover: convertDataToFixed(snapshots[curr].mediumCloudCover),
                heightCloudCover: convertDataToFixed(snapshots[curr].heightCloudCover),
                cloudBase: convertDataToFixed(snapshots[curr].cloudBase),
                snow: convertDataToFixed(snapshots[curr].snow),
                rain: convertDataToFixed(snapshots[curr].rain, 1),
                visibility: convertDataToFixed(snapshots[curr].visibility),
                windSpeed100Agl: convertDataToFixed(snapshots[curr].windSpeed100Agl),
                windDirection100Agl: convertDataToFixed(snapshots[curr].windDirection100Agl, 1),
                windSpeed10Agl: convertDataToFixed(snapshots[curr].windSpeed10Agl),
                windDirection10Agl: convertDataToFixed(snapshots[curr].windDirection10Agl, 1),
                kpi: convertDataToFixed(snapshots[curr].kpi, 1),
                windGusts10Agl: convertDataToFixed(snapshots[curr].windGusts10Agl),
                groundPressure: convertDataToFixed(snapshots[curr].groundPressure, 1),
            },
        ],
        []
    ),
});

export const payloadFactoryConfig = (weatherPayloadData: WeatherPayloadData) => ({
    geometry: combineFlightArea(weatherPayloadData.route.sections)?.geometry,
    startTime: weatherPayloadData.missionStartTime && weatherPayloadData.missionStartTime.toISOString(),
    endTime: weatherPayloadData.missionEndTime && weatherPayloadData.missionEndTime.toISOString(),
    forecastHoursBefore: weatherPayloadData.hoursRangeBefore,
    forecastHoursAfter: weatherPayloadData.hoursRangeAfter,
});

export const convertWeatherToSimpleWeather = (weather: Weather): SimpleWeather => ({
    windSpeed100Agl: weather.windSpeed100Agl,
    windDirection100Agl: weather.windDirection100Agl,
    kpi: weather.kpi,
    temp: weather.temp,
    relHumidity: weather.relHumidity,
    groundPressure: weather.groundPressure,
});
