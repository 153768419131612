import { Directive, HostListener } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { EMPTY, combineLatest, throwError } from "rxjs";
import { first, switchMap } from "rxjs/operators";
import { VersionActions } from "../state/version.actions";
import { VersionState } from "../state/version.state";
import { VersionData, VersionDataError } from "../utils/version-data.model";
import { VersionDataDialogComponent } from "../version-data-modal/version-data-dialog.component";

const KEY_TO_SHOW_DATA = "v";

@UntilDestroy()
@Directive({
    selector: "[dtmUiDisplayVersion]",
})
export class DisplayVersionDirective {
    @HostListener("document:keydown", ["$event"]) private handleKeyPressEvent(event: KeyboardEvent) {
        const key = event.key?.toLowerCase();

        if (key !== KEY_TO_SHOW_DATA || !event.shiftKey || !event.altKey) {
            return;
        }

        this.getDataAndOpenDialog();
    }

    constructor(private store: Store, private dialog: MatDialog) {}

    private getDataAndOpenDialog() {
        if (!this.store.selectSnapshot(VersionState.versionData)) {
            this.store
                .dispatch(new VersionActions.GetVersionData())
                .pipe(
                    switchMap(() => this.openVersionDataDialog()),
                    untilDestroyed(this)
                )
                .subscribe();
        } else {
            this.openVersionDataDialog().subscribe();
        }
    }

    private openVersionDataDialog() {
        return combineLatest([this.store.select(VersionState.versionData), this.store.select(VersionState.versionDataError)]).pipe(
            first(),
            switchMap(([versionDataToDisplay, error]: [VersionData | undefined, VersionDataError | undefined]) => {
                if (this.dialog.openDialogs.length) {
                    return EMPTY;
                }

                if (error || !versionDataToDisplay) {
                    return throwError(error?.type);
                }

                const dialogRef = this.dialog.open(VersionDataDialogComponent, {
                    width: "800px",
                    height: "700px",
                    data: { versionData: versionDataToDisplay },
                });

                return dialogRef.afterClosed();
            }),
            untilDestroyed(this)
        );
    }
}
