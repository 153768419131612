import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IconName } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { MissionPhaseExtensions, MissionProcessingPhase, MissionProcessingPhaseExtended } from "../../models/mission.models";

interface DisplaySettings {
    iconName: IconName | undefined;
    className: string;
}

interface StatusBadgeComponentState {
    badge:
        | ({
              status: MissionProcessingPhaseExtended;
          } & DisplaySettings)
        | undefined;
    isAcceptedConditionally: boolean;
}

@Component({
    selector: "dtm-mission-mission-status-badge[status]",
    templateUrl: "./mission-status-badge.component.html",
    styleUrls: ["./mission-status-badge.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionStatusBadgeComponent {
    @Input() public set status(value: MissionProcessingPhaseExtended | undefined) {
        if (!value) {
            return;
        }

        this.localStore.patchState({ badge: { status: value, ...this.getDisplaySettings(value) } });
    }

    public readonly badge$ = this.localStore.selectByKey("badge").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<StatusBadgeComponentState>) {
        this.localStore.setState({
            badge: undefined,
            isAcceptedConditionally: false,
        });
    }

    private getDisplaySettings(status: MissionProcessingPhaseExtended): DisplaySettings {
        switch (status) {
            case MissionProcessingPhase.Unsubmitted:
                return { iconName: "markup", className: "edit" };
            case MissionProcessingPhase.Waiting:
                return { iconName: "rest-time", className: "waiting" };
            case MissionProcessingPhase.Accepted:
                return { iconName: "checkbox-circle", className: "accepted" };
            case MissionProcessingPhase.MissionRealized:
                return { iconName: "focus", className: "realized" };
            case MissionProcessingPhase.MissionAbandoned:
                return { iconName: "close-circle", className: "canceled" };
            case MissionProcessingPhase.Rejected:
                return { iconName: "error-warning", className: "rejected" };
            case MissionPhaseExtensions.AcceptedConditionally:
                return { iconName: "checkbox-circle", className: "waiting" };
            case MissionProcessingPhase.CaaPermitApplication:
                return { iconName: "file-list", className: "caa-permit" };
        }

        return { iconName: undefined, className: "" };
    }
}
