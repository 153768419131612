import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DateUtils, LocalComponentStore, MILLISECONDS_IN_SECOND, RxjsUtils } from "@dtm-frontend/shared/utils";
import { combineLatestWith } from "rxjs";
import { map } from "rxjs/operators";
import { MissionPlanRoute, MissionPlanRouteFlightZone, MissionSegmentStatus } from "../../models/route-area.model";
import { mapSegmentStatusToClassConfig } from "../../utils/route-utils";
import { EmptyStateMode } from "../empty-state/empty-state.component";
import { Area, MissionPlanRouteSegment, Waypoint } from "./../../models/route-area.model";

interface RouteDetailsComponentState {
    route: MissionPlanRoute | undefined;
    isOnlyHeightEnabled: boolean;
    sectionStatuses: MissionSegmentStatus[];
}

enum SectionType {
    RouteZone = "RouteZone",
    RoutePoint = "RoutePoint",
    RouteSegment = "RouteSegment",
}

type SectionData = { name: string } & (CylinderSection | PointSection | SegmentSection);

interface CylinderSection extends MissionPlanRouteFlightZone {
    type: SectionType.RouteZone;
    isTakeoff?: boolean;
    isLanding?: boolean;
    minTime: Date;
    maxTime: Date;
    status?: MissionSegmentStatus;
}

interface PointSection extends Waypoint {
    type: SectionType.RoutePoint;
    isExitPoint: boolean;
    isEntryPoint: boolean;
}

interface SegmentSection extends MissionPlanRouteSegment {
    type: SectionType.RouteSegment;
    status?: MissionSegmentStatus;
}

@Component({
    selector: "dtm-ui-route-details[route]",
    templateUrl: "./route-details.component.html",
    styleUrls: ["./route-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class RouteDetailsComponent {
    @Input() public set route(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ route: value });
    }
    @Input() public set sectionStatuses(value: MissionSegmentStatus[] | undefined) {
        this.localStore.patchState({ sectionStatuses: value ?? [] });
    }

    protected readonly route$ = this.localStore.selectByKey("route");
    protected readonly isPathBased$ = this.route$.pipe(map((route) => route?.isPathBased));

    protected readonly sections$ = this.route$.pipe(
        RxjsUtils.filterFalsy(),
        combineLatestWith(this.localStore.selectByKey("sectionStatuses")),
        map(([route, sectionStatuses]) => this.convertRouteToSectionData(route, sectionStatuses))
    );
    protected readonly isOnlyHeightEnabled$ = this.localStore.selectByKey("isOnlyHeightEnabled");

    protected SectionType = SectionType;
    protected readonly EmptyStateMode = EmptyStateMode;
    protected mapSegmentStatusToClassConfig = mapSegmentStatusToClassConfig;

    constructor(private readonly localStore: LocalComponentStore<RouteDetailsComponentState>) {
        localStore.setState({
            route: undefined,
            isOnlyHeightEnabled: false,
            sectionStatuses: [],
        });
    }

    protected getHeightRangeValues(flightArea: Area): { minHeight: number; maxHeight: number } {
        const elevationMin = flightArea.elevationMin ?? 0;
        const isOnTheGround = flightArea.volume.floor <= elevationMin;
        const minHeight = isOnTheGround ? 0 : Math.round(flightArea.volume.floor);
        const maxHeight = Math.round(flightArea.volume.ceiling);

        return {
            minHeight,
            maxHeight,
        };
    }

    protected getHeightRangeAglValues(flightArea: Area): { minHeight: number; maxHeight: number } {
        const elevationMin = flightArea.elevationMin ?? 0;
        const isOnTheGround = flightArea.volume.floor <= elevationMin;
        const minHeight = isOnTheGround ? 0 : Math.round(flightArea.volume.floor - (flightArea.elevationMin ?? 0));
        const maxHeight = Math.round(flightArea.volume.ceiling - (flightArea.elevationMax ?? 0));

        return {
            minHeight,
            maxHeight,
        };
    }

    protected getZoneHeightValue(flightArea: Area): number {
        return flightArea.volume.ceiling - (flightArea.elevationMax ?? 0);
    }

    protected toggleOnlyHeight() {
        this.localStore.patchState(({ isOnlyHeightEnabled }) => ({ isOnlyHeightEnabled: !isOnlyHeightEnabled }));
    }

    private convertRouteToSectionData(route: MissionPlanRoute, sectionStatuses: MissionSegmentStatus[] = []): SectionData[] {
        let startZoneFound = false;

        return route.sections.reduce<SectionData[]>((sections, section, index) => {
            if (section.flightZone) {
                const isTakeoff = !startZoneFound && !!section.flightZone.isRunway;
                const isLanding = startZoneFound && !!section.flightZone.isRunway;
                startZoneFound = startZoneFound || isTakeoff;
                const stopoverMaxTime = section.flightZone?.stopover?.max
                    ? (DateUtils.convertISO8601DurationToSeconds(section.flightZone?.stopover?.max) ?? 0) * MILLISECONDS_IN_SECOND
                    : 0;

                const minTime = section.flightZone.center.estimatedArriveAt.min;
                const maxTime = new Date(section.flightZone.center.estimatedArriveAt.max.getTime() + stopoverMaxTime);
                sections.push({
                    type: SectionType.RouteZone,
                    name: section.flightZone.center.name,
                    ...section.flightZone,
                    isTakeoff,
                    isLanding,
                    minTime,
                    maxTime,
                    status: sectionStatuses[index],
                });
            }
            if (section.segment) {
                const isEntryPoint = !!route.sections?.[index + 1]?.flightZone;
                const isExitPoint = !!route.sections?.[index - 1]?.flightZone;
                sections.push(
                    {
                        type: SectionType.RoutePoint,
                        ...section.segment.fromWaypoint,
                        isEntryPoint: false,
                        isExitPoint,
                    },
                    {
                        type: SectionType.RouteSegment,
                        name: `${section.segment.fromWaypoint.name} - ${section.segment.toWaypoint.name}`,
                        ...section.segment,
                        status: sectionStatuses[index],
                    }
                );

                if (isEntryPoint) {
                    sections.push({
                        type: SectionType.RoutePoint,
                        ...section.segment.toWaypoint,
                        isEntryPoint: true,
                        isExitPoint: false,
                    });
                }
            }

            return sections;
        }, []);
    }
}
