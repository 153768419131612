import { GeoZoneType, InfoZoneType } from "../../../../geo-zones/models/geo-zones.models";

export interface GeoZonesStyle {
    borderColor: string;
    fillColor: string;
    fillOpacity: number;
}

// TODO: UX-215 - use colors from pallet when added
export const GEO_ZONE_STYLES: { [key in GeoZoneType]?: GeoZonesStyle } & { Default: GeoZonesStyle } = {
    [GeoZoneType.DroneAirspaceProhibited]: {
        borderColor: "#ce0000",
        fillColor: "#ff3318",
        fillOpacity: 0.5,
    },
    [GeoZoneType.DroneAirspaceRestricted]: {
        borderColor: "#ffa000", // $color-primary-700
        fillColor: "#ffca28", // $color-primary-400
        fillOpacity: 0.5,
    },
    [GeoZoneType.DroneAirspaceRestrictedHigh6Km]: {
        borderColor: "#1067c3", // $color-secondary-800
        fillColor: "#3fa6fd",
        fillOpacity: 0.2,
    },
    [GeoZoneType.DroneAirspaceRestrictedHigh]: {
        borderColor: "#007544", // $color-status-success
        fillColor: "#17a766",
        fillOpacity: 0.2,
    },
    [GeoZoneType.DroneAirspaceRestrictedMedium]: {
        borderColor: "#e65000",
        fillColor: "#f57c00",
        fillOpacity: 0.25,
    },
    [GeoZoneType.DroneAirspaceRestrictedLow]: {
        borderColor: "#720015",
        fillColor: "#8f0c23",
        fillOpacity: 0.3,
    },
    [GeoZoneType.DroneTrafficManagement]: {
        borderColor: "#015b2a",
        fillColor: "#015b2a",
        fillOpacity: 0.1,
    },
    [GeoZoneType.DroneAirspaceInformation]: {
        borderColor: "#8d99b1", // $color-gray-200
        fillColor: "#d9d9d9",
        fillOpacity: 0.01,
    },
    Default: {
        borderColor: "#8d99b1", // $color-gray-200
        fillColor: "#fff", // $color-white
        fillOpacity: 0.01,
    },
};

export const CUSTOM_OPACITY_SETTINGS_FOR_TYPE: Record<number, string[]> = {
    0.01: ["CTR6KM", "CTR1KM", "CTR"],
    0.1: ["TMA", "ATZ", "MCTR"],
    0.2: ["ATZ6KM"],
    0.3: ["ATZ1KM", "MCTR2KM", "MCTR1KM"],
};

// NOTE: code values are taken from the IconFont.css file
export const LOCAL_INFO_ICON_CODES: { [key in InfoZoneType]: string[] } = {
    [InfoZoneType.Landings]: ["ea00"], // hems-emr
    [InfoZoneType.PermanentGathering]: ["ea09"], // team-fill-complex-1b
    [InfoZoneType.TemporaryGathering]: ["ea09", "ea0c"], // team-fill-complex-1b, team-fill-complex-1a
    [InfoZoneType.Obstacles]: ["ea04"], // barricade-fill:
    [InfoZoneType.AdsbReceivers]: ["e9c6"], // base-station-fill:
    [InfoZoneType.AdsbCoverMap]: ["e9ff"], // signal-tower-fill
    [InfoZoneType.OtherInformation]: ["ea05"], // stack-fill
};
