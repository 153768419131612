import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { DefaultGlobalConfig, GlobalConfig, TOAST_CONFIG, ToastrModule } from "ngx-toastr";
import { IconDirective } from "../../components/icon/icon.directive";
import { DtmToastComponent } from "./components/dtm-toast/dtm-toast.component";

@NgModule({
    imports: [CommonModule, IconDirective],
    declarations: [DtmToastComponent],
    exports: [DtmToastComponent],
})
export class SharedToastModule {
    public static forRoot(config?: Partial<GlobalConfig>): ModuleWithProviders<ToastrModule> {
        return {
            ngModule: ToastrModule,
            providers: [
                {
                    provide: TOAST_CONFIG,
                    useValue: {
                        default: DefaultGlobalConfig,
                        config: { toastComponent: DtmToastComponent, ...config },
                    },
                },
            ],
        };
    }
}
