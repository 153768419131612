export function replaceInTemplate(template: string, values: { [key: string]: string }) {
    let result = template;

    Object.keys(values).forEach((key) => {
        const regex = new RegExp(`{{\\s*${key}\\s*}}`, "gi");

        result = result.replace(regex, values[key]);
    });

    return result;
}
