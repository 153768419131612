import { Injectable } from "@angular/core";
import { ApplicationListItemStatus, ListStatusTranslationService } from "@dtm-frontend/dss-shared-lib";

const APPLICATION_STATUS_BADGE_KEYS = {
    [ApplicationListItemStatus.WaitingForPublication]: "dssClientLibFlightZone.applicationStatusBadge.WaitingForPublication",
    [ApplicationListItemStatus.Published]: "dssClientLibFlightZone.applicationStatusBadge.Published",
    [ApplicationListItemStatus.Rejected]: "dssClientLibFlightZone.applicationStatusBadge.Rejected",
    [ApplicationListItemStatus.Correction]: "dssClientLibFlightZone.applicationStatusBadge.Correction",
    [ApplicationListItemStatus.ForApproval]: "dssClientLibFlightZone.applicationStatusBadge.ForApproval",
};

@Injectable({
    providedIn: "root",
})
export class ApplicationStatusTranslationService implements ListStatusTranslationService {
    public getStatusTranslationsMap(hasSupervisorPermissions: boolean): Record<string, string> {
        const statuses = Object.values(ApplicationListItemStatus);

        return statuses.reduce(
            (result, status) => ({
                ...result,
                [status]:
                    status === ApplicationListItemStatus.ForApproval && !hasSupervisorPermissions
                        ? "dssClientLibFlightZone.applicationStatusBadge.WaitingForSupervisorApproval"
                        : APPLICATION_STATUS_BADGE_KEYS[status],
            }),
            {}
        );
    }
}
