<ac-layer acFor="let entity of hemsAreaEntities$" [context]="this">
    <ac-circle-desc
        props="{
            position: entity.position,
            radius: entity.radius,
            material: getStartAreaMaterial(entity.type)
        }"
    ></ac-circle-desc>
    <ac-polyline-desc
        props="{
            show: entity.type === 'landing',
            positions: entity.areaBuffer,
            material: landingBufferAreaLineMaterial
        }"
    ></ac-polyline-desc>
    <ac-billboard-desc
        props="{
            show: entity.type === 'takeOff',
            position: entity.position,
            image : 'assets/images/hems-bases.svg',
            scale: 1
        }"
    >
    </ac-billboard-desc>
    <ac-billboard-desc
        props="{
            show: entity.type === 'landing',
            position: entity.position,
            image : 'assets/images/landing.svg',
            scale: 1
        }"
    >
    </ac-billboard-desc>
</ac-layer>
<ac-layer acFor="let entity of areaActivationEntities$" [context]="this">
    <ac-billboard-desc
        props="{
            position: entity.position,
            image : entity.image,
            scale: 1,
            horizontalOrigin: 1,
            distanceDisplayCondition: activationLabelDistanceCondition
        }"
    >
    </ac-billboard-desc>
</ac-layer>
<ac-layer acFor="let entity of hemsLineEntities$" [context]="this">
    <ac-polyline-desc
        props="{
            positions: entity.positions,
            material: routeLineMaterial,
            width: 3
        }"
    ></ac-polyline-desc>
    <ac-polyline-desc
        props="{
            positions: entity.takeOff,
            material: routeLineMaterial,
            width: 3
        }"
    ></ac-polyline-desc>
    <ac-polyline-desc
        props="{
            positions: entity.landing,
            material: routeLineMaterial,
            width: 3
        }"
    ></ac-polyline-desc>
</ac-layer>
<ac-layer acFor="let entity of predictedPositions$" [context]="this">
    <ac-point-desc
        props="{
            show: entity.type === EntityType.PredictedPosition,
            pixelSize : 8,
            position : entity.position,
            color: LABEL_STYLES.predictedPositionLabelBackgroundColor,
            distanceDisplayCondition: labelDistanceCondition
          }"
    ></ac-point-desc>
    <ac-billboard-desc
        props="{
            show: entity.type === EntityType.PredictedPosition,
            position: entity.position,
            image : entity.lineImage,
            pixelOffset : [0, 32] | pixelOffset,
            scale: 1,
            distanceDisplayCondition: labelDistanceCondition
        }"
    >
    </ac-billboard-desc>
</ac-layer>
<ac-layer acFor="let entity of lastPositions$" [context]="this">
    <ac-billboard-desc
        props="{
            show: entity.type === EntityType.Position,
            position: entity.position,
            image : 'assets/images/hems.svg',
            scale: 1,
            distanceDisplayCondition: labelDistanceCondition
        }"
    >
    </ac-billboard-desc>
    <ac-billboard-desc
        props="{
            show: entity.type === EntityType.Position,
            position: entity.position,
            image : entity.realLabelImage,
            horizontalOrigin: 1,
            pixelOffset : [20, 0] | pixelOffset,
            scale: 1,
            distanceDisplayCondition: labelDistanceCondition
      }"
    >
    </ac-billboard-desc>
</ac-layer>
