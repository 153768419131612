<ng-container *ngIf="constraints$ | ngrxPush; let constraints; else: cannotGetDataErrorTemplate">
    <dss-client-lib-wizard-step-wrapper
        [stepNumber]="3"
        [stepsAmount]="4"
        [title]="'dssClientLibFlightZone.flightZoneWizardSteps.exclusionsFromRestrictions.header' | transloco"
        *ngIf="capabilities$ | ngrxPush; let capabilities; else: cannotGetDataErrorTemplate"
    >
        <form [formGroup]="exclusionsForm">
            <div class="form-title">
                {{ "dssClientLibFlightZone.zoneRestrictionExecutionsStep.generalAviationExclusions.formTitle" | transloco }}
            </div>

            <dtm-ui-checkbox-field formControlName="isManagedByGovernment">
                {{ "dssSharedLibFlightZone.applicationSummary.generalAviationPurpose.managedByGovernmentLabel" | transloco }}
            </dtm-ui-checkbox-field>

            <ng-container formGroupName="agreedExclusion">
                <dtm-ui-checkbox-field
                    [formControl]="agreedExclusionEnabledFormControl"
                    (change)="changeControlValidation($event.checked, agreedExclusionFormGroup)"
                >
                    {{ "dssClientLibFlightZone.zoneRestrictionExecutionsStep.generalAviationExclusions.agreedExclusionLabel" | transloco }}
                </dtm-ui-checkbox-field>

                <div class="slide-container" *ngIf="agreedExclusionEnabledFormControl.value" @slideIn>
                    <dtm-ui-phone-number-field id="phoneNumberControl" formControlName="phoneNumber">
                        <label for="phoneNumberControl">{{
                            "dssClientLibFlightZone.zoneRestrictionExecutionsStep.agreedExclusionPhoneLabel" | transloco
                        }}</label>
                        <div class="field-error">
                            <ng-container
                                [ngTemplateOutlet]="formErrorsTemplate"
                                [ngTemplateOutletContext]="{ formControl: agreedExclusionPhoneFormControl }"
                            ></ng-container>
                        </div>
                    </dtm-ui-phone-number-field>

                    <dtm-ui-input-field>
                        <label for="emailControl">{{
                            "dssClientLibFlightZone.zoneRestrictionExecutionsStep.agreedExclusionEmailLabel" | transloco
                        }}</label>
                        <input
                            id="emailControl"
                            matInput
                            type="email"
                            autocomplete="email"
                            formControlName="email"
                            required
                            inputmode="email"
                        />
                        <div class="field-error">
                            <ng-container
                                [ngTemplateOutlet]="formErrorsTemplate"
                                [ngTemplateOutletContext]="{ formControl: agreedExclusionEmailFormControl }"
                            ></ng-container>
                        </div>
                    </dtm-ui-input-field>
                </div>
            </ng-container>

            <dtm-ui-checkbox-field formControlName="areOwnedFlights">
                {{ "dssSharedLibFlightZone.applicationSummary.generalAviationPurpose.ownedFlightsLabel" | transloco }}
            </dtm-ui-checkbox-field>

            <dtm-ui-checkbox-field
                [formControl]="uavMtomExclusionEnabledFormControl"
                (change)="changeControlValidation($event.checked, uavMtomExclusionFormControl)"
            >
                {{ "dssClientLibFlightZone.zoneRestrictionExecutionsStep.uavMtomExclusionLabel" | transloco }}
            </dtm-ui-checkbox-field>

            <dss-client-lib-mtom-field
                *ngIf="uavMtomExclusionEnabledFormControl.value"
                @slideIn
                [minValue]="constraints.uavMtomExclusion.minValue"
                [maxValue]="constraints.uavMtomExclusion.maxValue"
                formControlName="uavMtomExclusion"
            ></dss-client-lib-mtom-field>

            <dtm-ui-checkbox-field
                *ngIf="capabilities.eLearningCourses.length"
                [formControl]="coursesEnabledFormControl"
                (change)="changeControlValidation($event.checked, coursesFormControl)"
            >
                {{ "dssSharedLibFlightZone.applicationSummary.coursesLabel" | transloco }}
            </dtm-ui-checkbox-field>
            <div class="slide-container" *ngIf="coursesEnabledFormControl.value" @slideIn>
                <dtm-ui-select-field id="coursesControl" formControlName="courses" [multiple]="true">
                    <label for="coursesControl">{{
                        "dssClientLibFlightZone.zoneRestrictionExecutionsStep.coursesPlaceholder" | transloco
                    }}</label>
                    <dtm-ui-select-option *ngFor="let course of capabilities.eLearningCourses" [value]="course">
                        {{ course.title }}
                    </dtm-ui-select-option>
                    <div class="field-error">
                        <ng-container
                            [ngTemplateOutlet]="formErrorsTemplate"
                            [ngTemplateOutletContext]="{ formControl: coursesFormControl }"
                        ></ng-container>
                    </div>
                </dtm-ui-select-field>
            </div>

            <dtm-ui-checkbox-field formControlName="isWithoutEngine">
                {{ "dssSharedLibFlightZone.applicationSummary.generalAviationPurpose.withoutEngineLabel" | transloco }}
            </dtm-ui-checkbox-field>

            <dtm-ui-checkbox-field formControlName="isWithInstrumentalProcedures">
                {{ "dssSharedLibFlightZone.applicationSummary.generalAviationPurpose.withInstrumentalProceduresLabel" | transloco }}
            </dtm-ui-checkbox-field>

            <dtm-ui-checkbox-field formControlName="isSpecialStatusFlights">
                {{ "dssSharedLibFlightZone.applicationSummary.generalAviationPurpose.specialStatusFlightsLabel" | transloco }}
            </dtm-ui-checkbox-field>

            <dtm-ui-checkbox-field
                [formControl]="otherExclusionEnabledFormControl"
                (change)="changeControlValidation($event.checked, otherExclusionFormControl)"
            >
                {{ "dssSharedLibFlightZone.applicationSummary.otherExclusionLabel" | transloco }}
            </dtm-ui-checkbox-field>
            <div class="slide-container" *ngIf="otherExclusionEnabledFormControl.value" @slideIn>
                <dtm-ui-textarea-field [maxLength]="constraints.otherExclusionMaxFieldLength">
                    <textarea
                        matInput
                        [rows]="7"
                        [placeholder]="'dssClientLibFlightZone.zoneRestrictionExecutionsStep.otherExclusionTextareaLabel' | transloco"
                        name="otherExclusion"
                        formControlName="otherExclusion"
                    ></textarea>

                    <div class="field-error">
                        <ng-container
                            [ngTemplateOutlet]="formErrorsTemplate"
                            [ngTemplateOutletContext]="{ formControl: otherExclusionFormControl }"
                        ></ng-container>
                    </div>
                </dtm-ui-textarea-field>
            </div>
        </form>

        <ng-container actions *ngrxLet="isProcessing$; let isProcessing">
            <button class="button-secondary" (click)="back.emit()" [disabled]="isProcessing" type="button">
                <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                {{ "dssClientLibFlightZone.zoneRestrictionExecutionsStep.backButtonLabel" | transloco }}
            </button>
            <button class="button-primary" [disabled]="isProcessing || exclusionsForm.invalid" type="button" (click)="submitExclusions()">
                {{ "dssClientLibFlightZone.zoneRestrictionExecutionsStep.nextButtonLabel" | transloco }}
            </button>
        </ng-container>
    </dss-client-lib-wizard-step-wrapper>
</ng-container>

<ng-template #formErrorsTemplate let-formControl="formControl">
    <ng-container *dtmUiFieldHasError="formControl; name: 'required'">
        {{ "dssClientLibFlightZone.requiredFieldErrorHint" | transloco }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="formControl; name: 'invalidNumber'">
        {{ "dssClientLibFlightZone.zoneRestrictionExecutionsStep.invalidPhoneNumberErrorHint" | transloco }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="formControl; name: 'email'">
        {{ "dssClientLibFlightZone.zoneRestrictionExecutionsStep.invalidEmailErrorHint" | transloco }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="formControl; name: 'min'">
        {{
            "dssClientLibFlightZone.minValueErrorHint"
                | transloco
                    : {
                          min: formControl.errors?.min.min | localizeNumber,
                          unit: "dssClientLibFlightZone.weightUnitLabel" | transloco
                      }
        }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="formControl; name: 'max'">
        {{
            "dssClientLibFlightZone.maxValueErrorHint"
                | transloco
                    : {
                          max: formControl.errors?.max.max | localizeNumber,
                          unit: "dssClientLibFlightZone.weightUnitLabel" | transloco
                      }
        }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="formControl; name: 'maxlength'">
        {{ "dssClientLibFlightZone.maxLengthErrorHint" | transloco : { maxLength: formControl.errors?.maxlength.requiredLength } }}
    </ng-container>
</ng-template>

<ng-template #cannotGetDataErrorTemplate>
    <div>{{ "dssClientLibFlightZone.cannotGetDataErrorMessage" | transloco }}</div>
</ng-template>
