<ng-container *ngrxLet="{ startDate: startDate$, endDate: endDate$, dateStyle: dateStyle$, isSameDay: isSameDay$ } as vm">
    <ng-container *ngIf="vm.isSameDay; else differentDayTemplate">
        {{ vm.startDate | localizeDate : { dateStyle: vm.dateStyle, timeStyle: "short" } }} -
        {{ vm.endDate | localizeDate : { hour: "numeric", minute: "numeric" } }}
    </ng-container>
    <ng-template #differentDayTemplate>
        {{ vm.startDate | localizeDate : { dateStyle: vm.dateStyle, timeStyle: "short" } }} -
        {{ vm.endDate | localizeDate : { dateStyle: vm.dateStyle, timeStyle: "short" } }}
    </ng-template>
</ng-container>
