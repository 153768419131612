import { Cartesian3 } from "@pansa/ngx-cesium";
import { SerializableCartographic } from "../models/serializable-cartographic";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export function convertCartesian3ToSerializableCartographic(cartesian: Cartesian3): SerializableCartographic {
    const cartographic = Cesium.Cartographic.fromCartesian(cartesian);

    return {
        latitude: Cesium.Math.toDegrees(cartographic.latitude),
        longitude: Cesium.Math.toDegrees(cartographic.longitude),
        height: cartographic.height,
    };
}
