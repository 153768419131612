<ol class="steps-container" [class.hidden]="shouldHide$ | ngrxPush" #stepsContainer *ngrxLet="activeStepId$ as activeStepId">
    <ng-container *ngrxLet="activeStepIndex$ as activeStepIndex">
        <ng-container
            *ngFor="let step of steps$ | async; let index = index"
            [ngTemplateOutlet]="step.template"
            [ngTemplateOutletContext]="{
                active: step.stepId === activeStepId,
                enabledByState: activeStepIndex && activeStepIndex >= index
            }"
        ></ng-container>
    </ng-container>
</ol>
