import { AfterViewInit, Directive, Optional } from "@angular/core";
import { NgControl } from "@angular/forms";
import { MatLegacyInput as MatInput } from "@angular/material/legacy-input";

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: "[matInput]" })
export class AutoInputIdDirective implements AfterViewInit {
    constructor(private input: MatInput, @Optional() private inputControl?: NgControl) {}

    public ngAfterViewInit() {
        const isCustomId = !this.input.id.startsWith("mat-input-");
        const controlName = this.getControlName();

        if (!isCustomId && controlName) {
            this.input.id = `auto_${controlName}`;
        }
    }

    private getControlName(): string | undefined {
        const inputControl = this.inputControl?.control;

        for (const [controlName, control] of Object.entries(inputControl?.parent?.controls ?? {})) {
            if (control === inputControl) {
                return controlName;
            }
        }

        return undefined;
    }
}
