<ng-container *ngrxLet="label$ as label">
    <div class="wrapper" [class.edit-mode]="isEditMode" *ngrxLet="isEditMode$ as isEditMode">
        <div *ngIf="!isEditMode" class="view-panel">
            <section class="header" [class.label-empty]="!label">
                <span class="label">
                    <span #labelContent><ng-content select="[labelSlot]"></ng-content></span>
                    <ng-container *ngIf="labelContent | isEmptyElement">
                        {{ label }}
                    </ng-container>
                </span>
                <div class="value-label">
                    <ng-content select="[valueLabelSlot]"></ng-content>
                </div>
                <div class="buttons">
                    <button *ngIf="isRemovable$ | ngrxPush" type="button" class="button-secondary" (click)="tryRemove()">
                        <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                        {{ "dtmUi.editableBox.removeButtonLabel" | transloco }}
                    </button>
                    <button *ngIf="isEditButtonVisible$ | ngrxPush" type="button" class="button-secondary" (click)="setEditMode(true)">
                        <dtm-ui-icon name="edit"></dtm-ui-icon>
                        {{ "dtmUi.editableBox.editButtonLabel" | transloco }}
                    </button>
                    <ng-content select="[additionalActions]"></ng-content>
                </div>
            </section>
            <div class="value-content">
                <ng-content select="[valueContentSlot]"></ng-content>
            </div>
        </div>
        <div *ngIf="isEditMode" class="edit-panel">
            <section class="header">
                <span class="label">{{ label }}</span>
                <button type="button" class="button-icon" (click)="setEditMode(false)">
                    <dtm-ui-icon name="close"></dtm-ui-icon>
                </button>
            </section>
            <div class="editable-content">
                <ng-content select="[editableContentSlot]"></ng-content>
            </div>
            <div class="buttons">
                <button type="button" [disabled]="isSaveButtonDisabled$ | ngrxPush" class="button-primary" (click)="saveChanges()">
                    {{ (saveButtonLabel$ | ngrxPush) ?? ("dtmUi.editableBox.saveChangesButtonLabel" | transloco) }}
                </button>
                <button type="button" class="button-secondary" (click)="setEditMode(false)">
                    {{ "dtmUi.editableBox.cancelChangesButtonLabel" | transloco }}
                </button>
            </div>
        </div>
    </div>
</ng-container>
