<div class="dialog-header">
    <h2 mat-dialog-title>{{ "dtmSharedMission.uavClassesAndCategoriesInfo.title" | transloco }}</h2>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    <div class="content">
        <div class="table-wrapper">
            <div
                class="table-shadow"
                #tableShadow
                [style.height]="tableScrollContainer.getBoundingClientRect().height | invoke : getShadowHeight"
            ></div>
            <div
                class="table-scroll-container"
                dtmUiScrollTracker
                (scrollTrack)="tableShadow.classList.toggle('show', $event.scrollLeft > 0)"
            >
                <table class="categories" #tableScrollContainer>
                    <thead>
                        <tr class="header">
                            <th></th>
                            <th *ngFor="let group of UAV_CATEGORIES_GROUPS" [colSpan]="group.categories.length">
                                <h2>{{ group.tileKey | transloco : { categories: group.categories | join } }}</h2>
                                <p class="category-description">
                                    {{ group.descriptionKey | transloco }}
                                </p>
                            </th>
                        </tr>

                        <tr class="sub-header">
                            <th>{{ "dtmSharedMission.uavClassesAndCategoriesInfo.uavClassHeader" | transloco }}*</th>
                            <th
                                *ngFor="let category of UavCategories | keyvalue"
                                [class.first-in-category]="category.value | invoke : isFirstInCategory"
                            >
                                {{ category.value }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let uavInfo of UAV_CLASSES_AND_CATEGORIES">
                            <th>
                                <div class="mat-calendar-body cell-content">
                                    {{ uavInfo.className ?? ("dtmSharedMission.uavClassesAndCategoriesInfo.noClass" | transloco) + "**" }}
                                </div>
                            </th>
                            <td
                                *ngFor="let info of uavInfo | invokeThis : getUavClassInfo : this"
                                [class.first-in-category]="info.isFirstInCategory"
                            >
                                <div class="cell-content">
                                    <dtm-ui-icon
                                        [name]="info.isChecked ? 'checkbox-circle-fill' : 'close-circle-fill'"
                                        class="check-icon"
                                        [class.checked]="info.isChecked"
                                    ></dtm-ui-icon>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <p class="description" [innerHTML]="'dtmSharedMission.uavClassesAndCategoriesInfo.description' | transloco"></p>
        <dl class="note">
            <dt>*</dt>
            <dd>{{ "dtmSharedMission.uavClassesAndCategoriesInfo.uavClassHeaderNote" | transloco }}</dd>
            <dt>**</dt>
            <dd>{{ "dtmSharedMission.uavClassesAndCategoriesInfo.noClassNote" | transloco }}</dd>
        </dl>
    </div>
</div>
