import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";
import { Toast, ToastPackage, ToastrService } from "ngx-toastr";

@Component({
    selector: "dtm-ui-toast",
    templateUrl: "dtm-toast.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DtmToastComponent extends Toast {
    @HostBinding("class.tap-to-dismiss") public tapToDismiss = this.options.tapToDismiss;

    constructor(protected readonly toastrService: ToastrService, public readonly toastPackage: ToastPackage) {
        super(toastrService, toastPackage);
    }
}
