/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line max-len
// NOTE: this is based on https://github.com/pansa-dev/ngx-cesium/blob/HEAD/projects/@pansa/ngx-cesium/src/lib/@pansa/ngx-cesium-widgets/services/entity-editors/polyline-editor/polylines-editor.service.ts#L1-L621
import { Injectable } from "@angular/core";
import { RxjsUtils, StringUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
    CameraService,
    Cartesian2,
    Cartesian3,
    CesiumEvent,
    CesiumService,
    ClampTo3DOptions,
    CoordinateConverter,
    DisposableObservable,
    EditActions,
    EditModes,
    EditPoint,
    EditPolyline,
    EventResult,
    LabelProps,
    MapEventsManagerService,
    PickOptions,
} from "@pansa/ngx-cesium";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, filter, map, publish, startWith, tap } from "rxjs/operators";
import { CesiumPointWithProps } from "../../../models/cartographic-edit-point";
import { ChildEntityCollection, ChildEntitySubtype, EntityParentCartographicEditPoint } from "../../../models/child-entity.model";
import { MINIMUM_WAYPOINTS, Polyline3DHeightPoint, Polyline3DLabel } from "../../../models/polyline3d/editable-polyline3d";
import { Polyline3DEditOptions, Polyline3DProps } from "../../../models/polyline3d/polyline3d-edit-options";
import { Polyline3DEditUpdate } from "../../../models/polyline3d/polyline3d-edit-update";
import { Polyline3DEditorObservable } from "../../../models/polyline3d/polyline3d-editor-observable";
import { DraggableHeightEntity, HeightHelperService, ShapeDragActions } from "../../height-helper.service";
import {
    CesiumPointerManagerService,
    CesiumPointerType,
    ViewerContainerEventType,
} from "../../pointer-manager/cesium-pointer-manager.service";
import { Polylines3DManagerService } from "./polylines3d-manager.service";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export const DEFAULT_POLYLINE_OPTIONS: Polyline3DEditOptions = {
    addPointEvent: CesiumEvent.LEFT_CLICK,
    addLastPointEvent: CesiumEvent.LEFT_DOUBLE_CLICK,
    removePointEvent: CesiumEvent.RIGHT_CLICK,
    dragPointEvent: CesiumEvent.LEFT_CLICK_DRAG,
    dragShapeEvent: CesiumEvent.LEFT_CLICK_DRAG,
    allowDrag: false,
    pointProps: {
        // eslint-disable-next-line no-magic-numbers
        color: Cesium.Color.WHITE.withAlpha(0.65),
        // eslint-disable-next-line no-magic-numbers
        outlineColor: Cesium.Color.BLACK.withAlpha(0.5),
        outlineWidth: 1,
        pixelSize: 15,
        virtualPointPixelSize: 8,
        show: true,
        showVirtual: true,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
    },
    polylineProps: {
        material: Cesium.Color.WHITE,
        width: 3,
        clampToGround: false,
        classificationType: Cesium.ClassificationType.BOTH,
        // eslint-disable-next-line no-magic-numbers
        wallMaterial: Cesium.Color.fromCssColorString("rgb(6, 22, 54)").withAlpha(0.4), // $color-gray-900
        // eslint-disable-next-line no-magic-numbers
        shadowMaterial: Cesium.Color.WHITE.withAlpha(0.6),
    },
    clampHeightTo3D: false,
    clampHeightTo3DOptions: {
        clampToTerrain: false,
        clampMostDetailed: true,
        clampToHeightPickWidth: 2,
    },
};

enum Polyline3DActiveEntityTypes {
    Point = "Point",
    HeightPoint = "HeightPoint",
    ShapeOrNone = "ShapeOrNone",
}

const CLAMP_DEBOUNCE_TIME = 300;

@UntilDestroy()
@Injectable()
export class Polylines3DEditorService {
    private mapEventsManager!: MapEventsManagerService;
    private heightHelperService!: HeightHelperService;
    private updateSubject = new Subject<Polyline3DEditUpdate>();
    private updatePublisher = publish<Polyline3DEditUpdate>()(this.updateSubject);
    private coordinateConverter!: CoordinateConverter;
    private cameraService!: CameraService;
    private polylinesManager!: Polylines3DManagerService;
    private screenSpaceCameraController!: any;
    private observablesMap = new Map<string, DisposableObservable<any>[]>();
    private cesiumScene!: any;
    private cesiumViewer!: any;
    private activeEntitiesMap = new Map<string, Polyline3DActiveEntityTypes>();
    private cesiumPointerManager!: CesiumPointerManagerService;

    public init(
        mapEventsManager: MapEventsManagerService,
        coordinateConverter: CoordinateConverter,
        cameraService: CameraService,
        polylinesManager: Polylines3DManagerService,
        cesiumService: CesiumService,
        cesiumPointerManager: CesiumPointerManagerService,
        heightHelperService: HeightHelperService
    ) {
        this.mapEventsManager = mapEventsManager;
        this.coordinateConverter = coordinateConverter;
        this.cameraService = cameraService;
        this.polylinesManager = polylinesManager;
        this.updatePublisher.connect();
        this.cesiumScene = cesiumService.getScene();
        this.cesiumViewer = cesiumService.getViewer();
        this.screenSpaceCameraController = this.cesiumScene.screenSpaceCameraController;
        this.cesiumPointerManager = cesiumPointerManager;
        this.heightHelperService = heightHelperService;
    }

    public onUpdate(): Observable<Polyline3DEditUpdate> {
        return this.updatePublisher.pipe(tap(() => this.cesiumScene.requestRender()));
    }

    private clampPoints(
        id: string,
        clampHeightTo3D: boolean,
        { clampToTerrain, clampMostDetailed, clampToHeightPickWidth }: ClampTo3DOptions
    ) {
        if (clampHeightTo3D && clampMostDetailed) {
            const polyline = this.polylinesManager.get(id);
            const points = polyline?.getPoints();

            if (!points) {
                return;
            }

            if (!clampToTerrain) {
                // 3dTiles
                points.forEach((point) => {
                    point.setPosition(this.cesiumScene.clampToHeight(point.getPosition(), undefined, clampToHeightPickWidth));
                });
            } else {
                const cartographics = points.map((point) => this.coordinateConverter.cartesian3ToCartographic(point.getPosition()));
                // eslint-disable-next-line no-magic-numbers
                const promise = Cesium.sampleTerrain(this.cesiumScene.terrainProvider, 11, cartographics);
                Cesium.when(promise, (updatedPositions: any[]) => {
                    points.forEach((point, index) => {
                        point.setPosition(Cesium.Cartographic.toCartesian(updatedPositions[index]));
                    });
                });
            }
        }
    }

    private screenToPosition(
        cartesian2: { x: number; y: number },
        clampHeightTo3D: boolean,
        { clampToHeightPickWidth, clampToTerrain }: ClampTo3DOptions
    ) {
        const cartesian3 = this.coordinateConverter.screenToCartesian3(cartesian2);

        if (clampHeightTo3D && cartesian3) {
            const globePositionPick = () => {
                const ray = this.cameraService.getCamera().getPickRay(cartesian2);

                return this.cesiumScene.globe.pick(ray, this.cesiumScene);
            };

            if (clampToTerrain) {
                return globePositionPick();
            } else {
                const cartesian3PickPosition = this.cesiumScene.pickPosition(cartesian2);
                const latLon = CoordinateConverter.cartesian3ToLatLon(cartesian3PickPosition);
                if (latLon.height < 0) {
                    return globePositionPick();
                }

                return this.cesiumScene.clampToHeight(cartesian3PickPosition, undefined, clampToHeightPickWidth);
            }
        }

        return cartesian3;
    }

    public create(
        id = StringUtils.generateId(),
        options: Partial<Polyline3DEditOptions> = DEFAULT_POLYLINE_OPTIONS,
        initialPositions?: Cartesian3[]
    ): Polyline3DEditorObservable {
        const positions: Cartesian3[] = [];
        const heights: number[] = [];
        const bufferWidths: number[] = [];
        const bufferHeights: number[] = [];
        const polylineOptions = this.setOptions(options);

        const clientEditSubject = new BehaviorSubject<Polyline3DEditUpdate>({
            id,
            editAction: null,
            editMode: EditModes.CREATE,
        });
        let finishedCreate = false;

        this.updateSubject.next({
            id,
            positions,
            heights,
            bufferWidths,
            bufferHeights,
            editMode: EditModes.CREATE,
            editAction: EditActions.INIT,
            polylineOptions: polylineOptions,
        });

        const finishCreation = (position?: Cartesian3) =>
            this.switchToEditMode(
                id,
                position,
                clientEditSubject,
                polylineOptions,
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                editorObservable,
                finishedCreate
            );

        const mouseMoveRegistration = this.cesiumPointerManager.addEventHandler({
            event: CesiumEvent.MOUSE_MOVE,
            pick: PickOptions.NO_PICK,
        });
        const addPointRegistration = this.cesiumPointerManager.addEventHandler({
            event: polylineOptions.addPointEvent ?? CesiumEvent.LEFT_CLICK,
            modifier: polylineOptions.addPointModifier,
            pick: PickOptions.NO_PICK,
        });
        const addLastPointRegistration = this.cesiumPointerManager.addEventHandler({
            event: polylineOptions.addLastPointEvent ?? CesiumEvent.LEFT_DOUBLE_CLICK,
            modifier: polylineOptions.addLastPointModifier,
            pick: PickOptions.NO_PICK,
        });
        const mouseOutRegistration = this.cesiumPointerManager.addViewerContainerEventHandler(ViewerContainerEventType.MouseOut);

        this.observablesMap.set(id, [mouseMoveRegistration, addPointRegistration, addLastPointRegistration, mouseOutRegistration]);
        const editorObservable = this.createEditorObservable(clientEditSubject, id, finishCreation);

        this.addChildEntitiesSupport(editorObservable, id, clientEditSubject);

        mouseMoveRegistration.subscribe(({ movement: { endPosition } }) => {
            if (!polylineOptions.clampHeightTo3DOptions) {
                throw new Error("Polylines3D editor error: clampHeightTo3DOptions is required");
            }

            const position = this.screenToPosition(endPosition, polylineOptions.clampHeightTo3D, polylineOptions.clampHeightTo3DOptions);

            if (position) {
                this.updateSubject.next({
                    id,
                    positions: this.getPositions(id),
                    heights: this.getHeights(id),
                    bufferWidths: this.getBufferWidths(id),
                    bufferHeights: this.getBufferHeights(id),
                    childEntities: this.getChildEntities(id),
                    editMode: EditModes.CREATE,
                    updatedPosition: position,
                    editAction: EditActions.MOUSE_MOVE,
                });
            }
        });

        addPointRegistration.subscribe(({ movement: { endPosition } }) => {
            if (finishedCreate || !polylineOptions.clampHeightTo3DOptions) {
                return;
            }

            const position = this.screenToPosition(endPosition, polylineOptions.clampHeightTo3D, polylineOptions.clampHeightTo3DOptions);
            const allPositions = this.getPositions(id);

            if (!position || !allPositions) {
                return;
            }

            const previousPosition = allPositions[allPositions.length - 1];

            if (
                allPositions.length > 0 &&
                !this.checkPointsScreenSpaceMinimumDistance(previousPosition, position, polylineOptions.pointProps?.pixelSize ?? 1)
            ) {
                return;
            }

            const updateValue: Polyline3DEditUpdate = {
                id,
                positions: allPositions,
                heights: this.getHeights(id),
                bufferWidths: this.getBufferWidths(id),
                bufferHeights: this.getBufferHeights(id),
                childEntities: this.getChildEntities(id),
                editMode: EditModes.CREATE,
                updatedPosition: position,
                editAction: EditActions.ADD_POINT,
            };
            this.updateSubject.next(updateValue);
            clientEditSubject.next({
                ...updateValue,
                positions: this.getPositions(id),
                points: this.getPoints(id),
            });

            if (polylineOptions.maximumNumberOfPoints && allPositions.length + 1 === polylineOptions.maximumNumberOfPoints) {
                finishedCreate = finishCreation(position);
            }
        });

        addLastPointRegistration.subscribe(({ movement: { endPosition } }) => {
            if (!polylineOptions.clampHeightTo3DOptions) {
                return;
            }

            const position = this.screenToPosition(endPosition, polylineOptions.clampHeightTo3D, polylineOptions.clampHeightTo3DOptions);
            const allPositions = this.getPositions(id);

            if (!position || !allPositions || allPositions?.length === 0) {
                return;
            }

            const previousPosition = allPositions[allPositions.length - 1];
            const minimumDistancePreserved = this.checkPointsScreenSpaceMinimumDistance(
                previousPosition,
                position,
                polylineOptions.pointProps?.pixelSize ?? 1
            );

            if (minimumDistancePreserved) {
                const updateValue: Polyline3DEditUpdate = {
                    id,
                    positions: allPositions,
                    heights: this.getHeights(id),
                    bufferWidths: this.getBufferWidths(id),
                    bufferHeights: this.getBufferHeights(id),
                    childEntities: this.getChildEntities(id),
                    editMode: EditModes.CREATE,
                    updatedPosition: position,
                    editAction: EditActions.ADD_POINT,
                };
                allPositions.push(position);
                this.updateSubject.next(updateValue);
                clientEditSubject.next({
                    ...updateValue,
                    positions: this.getPositions(id),
                    points: this.getPoints(id),
                });
            } else {
                this.updateSubject.next({
                    id,
                    positions: this.getPositions(id),
                    heights: this.getHeights(id),
                    bufferWidths: this.getBufferWidths(id),
                    bufferHeights: this.getBufferHeights(id),
                    childEntities: this.getChildEntities(id),
                    editMode: EditModes.CREATE,
                    updatedPosition: previousPosition,
                    editAction: EditActions.MOUSE_MOVE,
                });
            }

            if (allPositions.length >= (options.minimumNumberOfPoints ?? MINIMUM_WAYPOINTS)) {
                finishedCreate = finishCreation(position);
            }
        });

        mouseOutRegistration.pipe(untilDestroyed(this)).subscribe(() => {
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE,
                shouldRemoveMovingPoint: true,
                editAction: EditActions.REMOVE_POINT,
            });
        });

        if (initialPositions) {
            initialPositions.forEach((position) => {
                const updateValue: Polyline3DEditUpdate = {
                    id,
                    positions: this.getPositions(id),
                    heights: this.getHeights(id),
                    bufferWidths: this.getBufferWidths(id),
                    bufferHeights: this.getBufferHeights(id),
                    childEntities: this.getChildEntities(id),
                    editMode: EditModes.CREATE,
                    updatedPosition: position,
                    editAction: EditActions.ADD_POINT,
                };
                this.updateSubject.next(updateValue);
                clientEditSubject.next({
                    ...updateValue,
                    positions: this.getPositions(id),
                    points: this.getPoints(id),
                });
            });
        }

        return editorObservable;
    }

    private checkPointsScreenSpaceMinimumDistance(firstPoint: Cartesian2, secondPoint: Cartesian2, minimumDistance: number): boolean {
        if (firstPoint.equals(secondPoint) && minimumDistance > 0) {
            return false;
        }

        const lastPointScreenCoords = Cesium.Cartesian2.ZERO.clone();
        const currentPointScreenCoords = Cesium.Cartesian2.ZERO.clone();

        Cesium.SceneTransforms.wgs84ToWindowCoordinates(this.cesiumScene, firstPoint, lastPointScreenCoords);
        Cesium.SceneTransforms.wgs84ToWindowCoordinates(this.cesiumScene, secondPoint, currentPointScreenCoords);

        return Cesium.Cartesian2.distance(lastPointScreenCoords, currentPointScreenCoords) >= minimumDistance;
    }

    private switchToEditMode(
        id: string,
        position: Cartesian3 | undefined,
        clientEditSubject: BehaviorSubject<Polyline3DEditUpdate>,
        polylineOptions: Polyline3DEditOptions,
        editorObservable: Polyline3DEditorObservable,
        finishedCreate: boolean
    ) {
        if (this.observablesMap.has(id)) {
            this.observablesMap.get(id)?.forEach((registration) => registration.dispose());
        }
        this.observablesMap.delete(id);
        this.editPolyline3D(id, clientEditSubject, polylineOptions, editorObservable);
        finishedCreate = true;

        const update: Polyline3DEditUpdate = {
            id,
            positions: this.getPositions(id),
            heights: this.getHeights(id),
            bufferWidths: this.getBufferWidths(id),
            bufferHeights: this.getBufferHeights(id),
            childEntities: this.getChildEntities(id),
            editMode: EditModes.CREATE,
            updatedPosition: position,
            editAction: EditActions.DONE,
        };
        this.updateSubject.next(update);
        clientEditSubject.next({
            ...update,
            points: this.getPoints(id),
        });

        const changeMode: Polyline3DEditUpdate = {
            id,
            editMode: EditModes.CREATE,
            editAction: EditActions.CHANGE_TO_EDIT,
        };
        this.updateSubject.next(changeMode);
        clientEditSubject.next(changeMode);

        return finishedCreate;
    }

    public edit(
        positions: Cartesian3[],
        heights: (number | undefined)[],
        bufferWidths: number[],
        bufferHeights: number[],
        id = StringUtils.generateId(),
        options: Partial<Polyline3DEditOptions> = DEFAULT_POLYLINE_OPTIONS
    ): Polyline3DEditorObservable {
        if (positions.length < MINIMUM_WAYPOINTS || positions.length !== heights.length) {
            throw new Error(`Polylines editor error edit(): polyline should have at least ${MINIMUM_WAYPOINTS} positions`);
        }

        const polylineOptions = this.setOptions(options);
        const editSubject = new BehaviorSubject<Polyline3DEditUpdate>({
            id,
            editAction: null,
            editMode: EditModes.EDIT,
        });
        const update: Polyline3DEditUpdate = {
            id,
            heights,
            bufferWidths,
            bufferHeights,
            positions,
            editMode: EditModes.EDIT,
            editAction: EditActions.INIT,
            polylineOptions: polylineOptions,
        };
        this.updateSubject.next(update);
        editSubject.next({
            ...update,
            positions: this.getPositions(id),
            heights: this.getHeights(id),
            bufferWidths: this.getBufferWidths(id),
            bufferHeights: this.getBufferHeights(id),
            childEntities: this.getChildEntities(id),
            points: this.getPoints(id),
        });

        const editorObservable = this.createEditorObservable(editSubject, id);

        this.addChildEntitiesSupport(editorObservable, id, editSubject);

        return this.editPolyline3D(id, editSubject, polylineOptions, editorObservable);
    }

    private editPolyline3D(
        id: string,
        editSubject: Subject<Polyline3DEditUpdate>,
        options: Polyline3DEditOptions,
        editorObservable: Polyline3DEditorObservable
    ) {
        this.activeEntitiesMap.set(id, Polyline3DActiveEntityTypes.ShapeOrNone);
        this.clampPoints(id, options.clampHeightTo3D, options.clampHeightTo3DOptions);

        const pointDragRegistration = this.cesiumPointerManager.addEventHandler({
            event: options.dragPointEvent ?? CesiumEvent.LEFT_CLICK_DRAG,
            entityType: EntityParentCartographicEditPoint,
            pick: PickOptions.PICK_FIRST,
            pickFilter: (entity) => id === entity.editedEntityId,
        });

        const heightPointDragRegistration = this.cesiumPointerManager.addEventHandler({
            event: CesiumEvent.LEFT_CLICK_DRAG,
            entityType: Polyline3DHeightPoint,
            pick: PickOptions.PICK_ALL,
            pickFilter: (entity: Polyline3DHeightPoint) => id === entity.parentEntityId,
        });

        let shapeDragRegistration;
        if (options.allowDrag) {
            shapeDragRegistration = this.cesiumPointerManager.addEventHandler({
                event: options.dragShapeEvent ?? CesiumEvent.LEFT_CLICK_DRAG,
                entityType: EditPolyline,
                pick: PickOptions.PICK_FIRST,
                pickFilter: (entity) => id === entity.editedEntityId,
            });
        }

        const pointRemoveRegistration = this.cesiumPointerManager.addEventHandler({
            event: options.removePointEvent ?? CesiumEvent.RIGHT_CLICK,
            modifier: options.removePointModifier,
            entityType: EntityParentCartographicEditPoint,
            pick: PickOptions.PICK_FIRST,
            pickFilter: (entity) => id === entity.editedEntityId,
        });

        const entityMouseHoverRegistration = this.cesiumPointerManager.addEventHandler({
            event: CesiumEvent.MOUSE_MOVE,
            pick: PickOptions.PICK_ALL,
        });

        pointDragRegistration
            .pipe(
                tap(({ movement: { drop } }) => this.polylinesManager.get(id)?.enableEdit && this.cameraService.enableInputs(!!drop)),
                map((event: EventResult) => this.onPointDrag(event, id, options, editSubject)),
                debounceTime(CLAMP_DEBOUNCE_TIME),
                RxjsUtils.filterFalsy(),
                tap(({ polyline3dId, polyline3dOptions }) =>
                    this.clampPoints(polyline3dId, polyline3dOptions.clampHeightTo3D, polyline3dOptions.clampHeightTo3DOptions)
                ),
                untilDestroyed(this)
            )
            .subscribe();

        heightPointDragRegistration
            .pipe(
                tap(({ movement: { drop } }) => drop !== undefined && this.cameraService.enableInputs(drop)),
                untilDestroyed(this)
            )
            .subscribe((event) => this.onHeightDrag(event, id, editSubject));

        shapeDragRegistration
            ?.pipe(
                tap(({ movement: { drop } }) => this.polylinesManager.get(id)?.enableEdit && this.cameraService.enableInputs(!!drop)),
                untilDestroyed(this)
            )
            .subscribe((event) => this.onShapeDrag(event, id, options, editSubject));

        pointRemoveRegistration.pipe(untilDestroyed(this)).subscribe((event) => this.onPointRemove(event, id, options, editSubject));
        entityMouseHoverRegistration.pipe(untilDestroyed(this)).subscribe((event) => this.onEntityMouseHover(event, id, editorObservable));

        const observables = [pointDragRegistration, heightPointDragRegistration, entityMouseHoverRegistration, pointRemoveRegistration];

        if (shapeDragRegistration) {
            observables.push(shapeDragRegistration);
        }

        this.observablesMap.set(id, observables);

        return editorObservable;
    }

    private getUpdateEvent(id: string, editSubject?: Subject<Polyline3DEditUpdate>): Polyline3DEditUpdate | undefined {
        const allPositions = this.getPositions(id);

        if (!allPositions) {
            return;
        }

        const update: Polyline3DEditUpdate = {
            id,
            heights: this.getHeights(id),
            bufferWidths: this.getBufferWidths(id),
            bufferHeights: this.getBufferHeights(id),
            childEntities: this.getChildEntities(id),
            positions: allPositions,
            editMode: EditModes.CREATE_OR_EDIT,
            editAction: EditActions.MOUSE_MOVE,
        };
        this.updateSubject.next(update);
        editSubject?.next(update);

        return update;
    }

    private addChildEntitiesSupport(editorObservable: Polyline3DEditorObservable, id: string, editSubject: Subject<Polyline3DEditUpdate>) {
        editorObservable.addOrUpdateChildEntity = (entity: ChildEntitySubtype, waypointIndex?: number) => {
            this.addOrUpdateChildEntity(id, entity, waypointIndex);

            return this.getUpdateEvent(id, editSubject);
        };

        editorObservable.deleteChildEntity = (entityId: string) => {
            this.deleteChildEntity(id, entityId);

            return this.getUpdateEvent(id, editSubject);
        };

        editorObservable.setChildEntityManually = (entity: ChildEntitySubtype, waypointIndex: number) => {
            this.setChildEntityManually(id, entity, waypointIndex);

            return editorObservable.addOrUpdateChildEntity(entity, waypointIndex);
        };
    }

    private onHeightDrag(event: EventResult, polyline3dId: string, editSubject: Subject<Polyline3DEditUpdate>) {
        if (this.activeEntitiesMap.get(polyline3dId) !== Polyline3DActiveEntityTypes.HeightPoint) {
            return;
        }

        const {
            movement: { startPosition: cursorStartPosition, endPosition: cursorEndPosition, drop: isDrop },
            entities,
        } = event;

        const startDragPosition = this.coordinateConverter.screenToCartesian3(cursorStartPosition);
        const endDragPosition = this.coordinateConverter.screenToCartesian3(cursorEndPosition);
        const firstPosition = this.getPositions(polyline3dId)?.[0];
        const draggedEntity = entities[0] as Polyline3DHeightPoint;

        if (!endDragPosition || !startDragPosition || entities.length === 0 || !firstPosition || !draggedEntity) {
            return;
        }

        const newHeight = this.heightHelperService.calculateHeightFromDragEvent(draggedEntity, cursorEndPosition, !!isDrop);

        const update: Polyline3DEditUpdate = {
            id: polyline3dId,
            positions: this.getPositions(polyline3dId),
            points: this.getPoints(polyline3dId),
            updatedHeightPoint: event.entities[0],
            height: newHeight,
            heights: this.getHeights(polyline3dId),
            bufferWidths: this.getBufferWidths(polyline3dId),
            bufferHeights: this.getBufferHeights(polyline3dId),
            childEntities: this.getChildEntities(polyline3dId),
            shapeDragAction: ShapeDragActions.ChangeTopHeight,
            editMode: EditModes.EDIT,
            editAction: isDrop ? EditActions.DRAG_SHAPE_FINISH : EditActions.DRAG_SHAPE,
        };

        this.updateSubject.next(update);

        editSubject.next({
            ...update,
            positions: this.getPositions(polyline3dId),
            heights: this.getHeights(polyline3dId),
            bufferWidths: this.getBufferWidths(polyline3dId),
            bufferHeights: this.getBufferHeights(polyline3dId),
            childEntities: this.getChildEntities(polyline3dId),
            points: this.getPoints(polyline3dId),
        });
    }

    private onPointRemove(
        event: EventResult,
        polyline3dId: string,
        options: Polyline3DEditOptions,
        editSubject: Subject<Polyline3DEditUpdate>
    ) {
        const point: EntityParentCartographicEditPoint = event.entities[0];
        const positionsToCopy = this.getPositions(polyline3dId);

        if (!positionsToCopy || positionsToCopy.length <= MINIMUM_WAYPOINTS) {
            return;
        }

        const allPositions = [...positionsToCopy];
        const index = allPositions.findIndex((position) => point.getPosition().equals(position as Cartesian3));

        if (index < 0) {
            return;
        }

        const update: Polyline3DEditUpdate = {
            id: polyline3dId,
            positions: allPositions,
            heights: this.getHeights(polyline3dId),
            bufferWidths: this.getBufferWidths(polyline3dId),
            bufferHeights: this.getBufferHeights(polyline3dId),
            childEntities: this.getChildEntities(polyline3dId),
            editMode: EditModes.EDIT,
            updatedPoint: point,
            editAction: EditActions.REMOVE_POINT,
        };
        this.updateSubject.next(update);
        editSubject.next({
            ...update,
            positions: this.getPositions(polyline3dId),
            points: this.getPoints(polyline3dId),
        });

        this.clampPoints(polyline3dId, options.clampHeightTo3D, options.clampHeightTo3DOptions);
    }

    private onPointDrag(
        event: EventResult,
        polyline3dId: string,
        options: Polyline3DEditOptions,
        editSubject: Subject<Polyline3DEditUpdate>
    ) {
        if (this.activeEntitiesMap.get(polyline3dId) !== Polyline3DActiveEntityTypes.Point) {
            return;
        }

        const position = this.screenToPosition(event.movement.endPosition, options.clampHeightTo3D, options.clampHeightTo3DOptions);

        if (!position) {
            return;
        }
        const point: EntityParentCartographicEditPoint = event.entities[0];

        const update: Polyline3DEditUpdate = {
            id: polyline3dId,
            positions: this.getPositions(polyline3dId),
            heights: this.getHeights(polyline3dId),
            bufferWidths: this.getBufferWidths(polyline3dId),
            bufferHeights: this.getBufferHeights(polyline3dId),
            childEntities: this.getChildEntities(polyline3dId),
            editMode: EditModes.EDIT,
            updatedPosition: position,
            updatedPoint: point,
            editAction: event.movement.drop ? EditActions.DRAG_POINT_FINISH : EditActions.DRAG_POINT,
        };
        this.updateSubject.next(update);
        editSubject.next({
            ...update,
            positions: this.getPositions(polyline3dId),
            heights: this.getHeights(polyline3dId),
            bufferWidths: this.getBufferWidths(polyline3dId),
            bufferHeights: this.getBufferHeights(polyline3dId),
            childEntities: this.getChildEntities(polyline3dId),
            points: this.getPoints(polyline3dId),
        });

        return { polyline3dId: polyline3dId, polyline3dOptions: options };
    }

    private onShapeDrag(
        event: EventResult,
        polyline3dId: string,
        options: Polyline3DEditOptions,
        editSubject: Subject<Polyline3DEditUpdate>
    ) {
        if (this.activeEntitiesMap.get(polyline3dId) !== Polyline3DActiveEntityTypes.ShapeOrNone) {
            return;
        }

        const endDragPosition = this.screenToPosition(event.movement.endPosition, false, options.clampHeightTo3DOptions);
        const startDragPosition = this.screenToPosition(event.movement.startPosition, false, options.clampHeightTo3DOptions);

        if (!endDragPosition) {
            return;
        }

        const update: Polyline3DEditUpdate = {
            id: polyline3dId,
            positions: this.getPositions(polyline3dId),
            heights: this.getHeights(polyline3dId),
            bufferWidths: this.getBufferWidths(polyline3dId),
            bufferHeights: this.getBufferHeights(polyline3dId),
            childEntities: this.getChildEntities(polyline3dId),
            editMode: EditModes.EDIT,
            updatedPosition: endDragPosition,
            draggedPosition: startDragPosition,
            shapeDragAction: ShapeDragActions.MoveEntity,
            editAction: event.movement.drop ? EditActions.DRAG_SHAPE_FINISH : EditActions.DRAG_SHAPE,
        };
        this.updateSubject.next(update);
        editSubject.next({
            ...update,
            positions: this.getPositions(polyline3dId),
            heights: this.getHeights(polyline3dId),
            bufferWidths: this.getBufferWidths(polyline3dId),
            bufferHeights: this.getBufferHeights(polyline3dId),
            childEntities: this.getChildEntities(polyline3dId),
            points: this.getPoints(polyline3dId),
        });
    }

    private onEntityMouseHover(event: EventResult, polyline3dId: string, editorObservable: Polyline3DEditorObservable) {
        if (!this.screenSpaceCameraController.enableInputs || !this.polylinesManager.get(polyline3dId)?.enableEdit) {
            return;
        }

        const heightEntity: DraggableHeightEntity = event.entities?.find((lookupEntity) => lookupEntity instanceof Polyline3DHeightPoint);

        if (heightEntity && heightEntity.parentEntityId === polyline3dId && heightEntity.isMovable()) {
            this.cesiumPointerManager.setPointerType(polyline3dId, CesiumPointerType.HeightHandle);
            this.setHoverHeightPointId(polyline3dId, heightEntity.id, editorObservable);
            this.activeEntitiesMap.set(polyline3dId, Polyline3DActiveEntityTypes.HeightPoint);

            return;
        }

        const isPointEntity = event.entities?.some((lookupEntity) => lookupEntity instanceof EditPoint);

        if (isPointEntity) {
            this.cesiumPointerManager.setPointerType(polyline3dId, CesiumPointerType.Move);
            this.activeEntitiesMap.set(polyline3dId, Polyline3DActiveEntityTypes.Point);

            return;
        }

        this.cesiumPointerManager.setPointerType(polyline3dId, CesiumPointerType.None);
        this.setHoverHeightPointId(polyline3dId, undefined, editorObservable);
        this.activeEntitiesMap.set(polyline3dId, Polyline3DActiveEntityTypes.ShapeOrNone);
    }

    private setOptions(options: Partial<Polyline3DEditOptions>) {
        if (options.maximumNumberOfPoints && options.maximumNumberOfPoints < MINIMUM_WAYPOINTS) {
            console.warn(
                `Warn: Polylines3DEditor invalid option. \
                maximumNumberOfPoints smaller than ${MINIMUM_WAYPOINTS}, maximumNumberOfPoints changed to ${MINIMUM_WAYPOINTS}`
            );
            options.maximumNumberOfPoints = MINIMUM_WAYPOINTS;
        }

        const defaultClone = JSON.parse(JSON.stringify(DEFAULT_POLYLINE_OPTIONS));
        const polylineOptions: Polyline3DEditOptions = Object.assign(defaultClone, options);

        polylineOptions.pointProps = { ...DEFAULT_POLYLINE_OPTIONS.pointProps, ...options.pointProps };
        polylineOptions.polylineProps = { ...DEFAULT_POLYLINE_OPTIONS.polylineProps, ...options.polylineProps };
        polylineOptions.clampHeightTo3DOptions = { ...DEFAULT_POLYLINE_OPTIONS.clampHeightTo3DOptions, ...options.clampHeightTo3DOptions };

        if (options.clampHeightTo3D) {
            if (!this.cesiumScene.pickPositionSupported || !this.cesiumScene.clampToHeightSupported) {
                throw new Error("Cesium pickPosition and clampToHeight must be supported to use clampHeightTo3D");
            }

            if (this.cesiumScene.pickTranslucentDepth) {
                console.warn("Cesium scene.pickTranslucentDepth must be false in order to make the editors work properly on 3D");
            }

            if (polylineOptions.pointProps.color.alpha === 1 || polylineOptions.pointProps.outlineColor.alpha === 1) {
                console.warn("Point color and outline color must have alpha in order to make the editor work properly on 3D");
            }

            polylineOptions.allowDrag = false;
            polylineOptions.polylineProps.clampToGround = true;
            polylineOptions.pointProps.heightReference = polylineOptions.clampHeightTo3DOptions.clampToTerrain
                ? Cesium.HeightReference.CLAMP_TO_GROUND
                : Cesium.HeightReference.RELATIVE_TO_GROUND;
            polylineOptions.pointProps.disableDepthTestDistance = Number.POSITIVE_INFINITY;
        }

        return polylineOptions;
    }

    private createEditorObservable(
        editorObservable: any,
        id: string,
        finishCreation?: (position?: Cartesian3) => boolean
    ): Polyline3DEditorObservable {
        const observableToExtend = editorObservable as Polyline3DEditorObservable;

        observableToExtend.dispose = () => {
            this.cesiumPointerManager.removePointer(id);
            const observables = this.observablesMap.get(id);

            if (observables) {
                observables.forEach((obs) => obs.dispose());
            }

            this.observablesMap.delete(id);
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE_OR_EDIT,
                editAction: EditActions.DISPOSE,
            });
        };

        observableToExtend.enable = () => {
            this.updateSubject.next({
                id,
                heights: this.getHeights(id),
                bufferWidths: this.getBufferWidths(id),
                bufferHeights: this.getBufferHeights(id),
                childEntities: this.getChildEntities(id),
                positions: this.getPositions(id),
                editMode: EditModes.EDIT,
                editAction: EditActions.ENABLE,
            });
        };

        observableToExtend.disable = () => {
            this.updateSubject.next({
                id,
                heights: this.getHeights(id),
                bufferWidths: this.getBufferWidths(id),
                bufferHeights: this.getBufferHeights(id),
                childEntities: this.getChildEntities(id),
                positions: this.getPositions(id),
                editMode: EditModes.EDIT,
                editAction: EditActions.DISABLE,
            });
        };

        observableToExtend.setManually = (
            points: CesiumPointWithProps[] | Cartesian3[],
            heights: number[],
            bufferWidths: number[],
            bufferHeights: number[],
            childEntities: ChildEntityCollection,
            polylineProps?: Polyline3DProps
        ) => {
            const polyline = this.polylinesManager.get(id);
            polyline?.setManually(points, heights, bufferWidths, bufferHeights, childEntities, polylineProps);
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE_OR_EDIT,
                editAction: EditActions.SET_MANUALLY,
            });
        };

        observableToExtend.setLabelsRenderFn = (callback: any) => {
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE_OR_EDIT,
                editAction: EditActions.SET_EDIT_LABELS_RENDER_CALLBACK,
                labelsRenderFn: callback,
            });
        };

        observableToExtend.updateLabels = (labels: LabelProps[]) => {
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE_OR_EDIT,
                editAction: EditActions.UPDATE_EDIT_LABELS,
                updateLabels: labels as Polyline3DLabel[],
            });
        };

        observableToExtend.refreshLabels = () => {
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE_OR_EDIT,
                editAction: EditActions.UPDATE_EDIT_LABELS,
            });
        };

        observableToExtend.deleteWaypoint = (waypointIndex: number) => {
            this.updateSubject.next({
                id,
                editMode: EditModes.EDIT,
                editAction: EditActions.REMOVE_POINT,
                updatedPoint: this.getPoints(id)?.[waypointIndex],
            });

            return this.getUpdateEvent(id);
        };

        observableToExtend.finishCreation = () => {
            if (!finishCreation) {
                throw new Error("Polylines editor error edit(): cannot call finishCreation() on edit");
            }

            return finishCreation(undefined);
        };

        observableToExtend.removeLastPoint = () => {
            const allPoints = [...(this.getPoints(id) ?? [])];
            const update: Polyline3DEditUpdate = {
                id,
                updatedPoint: allPoints.pop(),
                editMode: EditModes.CREATE,
                editAction: EditActions.REMOVE_POINT,
            };
            this.updateSubject.next(update);
        };

        observableToExtend.status$ = this.getStatusObservable(id);
        observableToExtend.getId = () => id;
        observableToExtend.getCurrentPoints = () => this.getPoints(id) ?? [];
        observableToExtend.getVirtualPoints = () => this.getVirtualPoints(id) ?? [];
        observableToExtend.getChildEntities = () => this.getChildEntities(id) ?? {};
        observableToExtend.getEntityId = () => id;
        observableToExtend.getHeights = () => this.getHeights(id) ?? [];
        observableToExtend.getLabels = (): Polyline3DLabel[] => this.polylinesManager.get(id)?.labels ?? [];

        return observableToExtend as Polyline3DEditorObservable;
    }

    private getPositions(id: string) {
        const polyline = this.polylinesManager.get(id);

        return polyline?.getRealPositions(false);
    }

    private getHeights(id: string) {
        const polyline = this.polylinesManager.get(id);

        return polyline?.getRealHeights(false);
    }

    private getBufferWidths(id: string) {
        const polyline = this.polylinesManager.get(id);

        return polyline?.getRealBufferWidths(false);
    }

    private getBufferHeights(id: string) {
        const polyline = this.polylinesManager.get(id);

        return polyline?.getRealBufferHeights(false);
    }

    private getPoints(id: string) {
        const polyline = this.polylinesManager.get(id);

        return polyline?.getRealPoints(false);
    }

    private getVirtualPoints(id: string) {
        const polyline = this.polylinesManager.get(id);

        return polyline?.getVirtualPoints();
    }

    private addOrUpdateChildEntity(id: string, entity: ChildEntitySubtype, waypointIndex?: number) {
        const polyline = this.polylinesManager.get(id);

        return polyline?.addOrUpdateChildEntity(entity, waypointIndex);
    }

    private deleteChildEntity(id: string, childEntityId: string) {
        const polyline = this.polylinesManager.get(id);

        return polyline?.deleteChildEntity(childEntityId);
    }

    private getStatusObservable(id: string) {
        const polyline = this.polylinesManager.get(id);

        return this.updateSubject.pipe(
            filter((update) => update.id === id),
            startWith(undefined),
            map(() => polyline?.getStatus())
        );
    }

    private setChildEntityManually(id: string, entity: ChildEntitySubtype, waypointIndex: number) {
        const polyline = this.polylinesManager.get(id);

        return polyline?.setChildEntityManually(entity, waypointIndex);
    }

    private getChildEntities(id: string) {
        const polyline = this.polylinesManager.get(id);

        return polyline?.getChildEntities();
    }

    private setHoverHeightPointId(
        polylineId: string,
        hoverHeightPointId: string | undefined,
        editorObservable: Polyline3DEditorObservable
    ) {
        const polyline3d = this.polylinesManager.get(polylineId);

        if (polyline3d && hoverHeightPointId !== polyline3d.hoverHeightPointId) {
            polyline3d.hoverHeightPointId = hoverHeightPointId as string;
            editorObservable.refreshLabels();
        }
    }
}
