import { MINUTES_IN_HOUR, SECONDS_IN_MINUTE } from "@dtm-frontend/shared/utils";
import { DmsCoordinates, DmsDirectionCoordinates, GeographicCoordinatesType, HemisphereSign } from "../models/dms-coordinates-input.models";
import { addDirectionToDmsCoordinates } from "./add-direction-to-dms-coordinates";

export function convertDecimalDegreesToDmsCoordinates(decimalDegrees: number): DmsCoordinates {
    const sign: HemisphereSign = Math.sign(decimalDegrees);
    const absoluteDecimalDegrees: number = Math.abs(decimalDegrees);

    let coordinateDegrees: number = Math.floor(absoluteDecimalDegrees);

    const minutesFloat: number = (absoluteDecimalDegrees - coordinateDegrees) * MINUTES_IN_HOUR;
    let coordinateMinutes = Math.floor(minutesFloat);

    const secondsFloat = (minutesFloat - coordinateMinutes) * SECONDS_IN_MINUTE;
    let coordinateSeconds = Math.round(secondsFloat);

    // NOTE: After rounding seconds might become 60
    if (coordinateSeconds === SECONDS_IN_MINUTE) {
        coordinateMinutes++;
        coordinateSeconds = 0;
    }
    if (coordinateMinutes === MINUTES_IN_HOUR) {
        coordinateDegrees++;
        coordinateMinutes = 0;
    }

    return {
        degrees: coordinateDegrees * sign,
        minutes: coordinateMinutes,
        seconds: coordinateSeconds,
    };
}

export function convertDecimalDegreesToDmsCoordinatesWithDirection(
    decimalDegrees: number,
    coordinatesType: GeographicCoordinatesType
): DmsDirectionCoordinates {
    return addDirectionToDmsCoordinates(convertDecimalDegreesToDmsCoordinates(decimalDegrees), coordinatesType);
}
