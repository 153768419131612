import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Optional } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { AcMapLayerProviderComponent, CesiumService } from "@pansa/ngx-cesium";
import { AzureMapsOptions } from "../../../shared/models/azure-maps.models";
import { AZURE_MAPS_SUBSCRIPTION_KEY } from "../../../shared/shared-map.tokens";
import { AzureMapsImageryProvider } from "./azure-maps-imagery.provider";

@Component({
    selector: "dtm-map-azure-maps-layer:not([provider])",
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AzureMapsLayerComponent extends AcMapLayerProviderComponent implements OnInit {
    constructor(
        cesiumService: CesiumService,
        @Optional() @Inject(AZURE_MAPS_SUBSCRIPTION_KEY) private readonly azureMapsSubscriptionKey: string | undefined
    ) {
        super(cesiumService);
    }

    @Input() public options: Partial<AzureMapsOptions> = {};

    public ngOnInit(): void {
        if (this.provider) {
            throw new Error("AzureMapsLayerComponent: provider input is not supported");
        }

        if (!this.azureMapsSubscriptionKey) {
            throw new Error("AzureMapsLayerComponent: AZURE_MAPS_SUBSCRIPTION_KEY is not provided");
        }

        this.layerProvider = new AzureMapsImageryProvider({
            subscriptionKey: this.azureMapsSubscriptionKey,
            ...this.options,
        });

        if (this.show) {
            this.imageryLayer = this.imageryLayersCollection.addImageryProvider(this.layerProvider, this.index);
            this.imageryLayer.alpha = this.alpha;
            this.imageryLayer.contrast = this.contrast;
            this.imageryLayer.brightness = this.brightness;
        }
    }
}
