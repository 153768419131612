import { Feature as GeoJSONFeature, Position, polygon as turfPolygon } from "@turf/helpers";
import { MapEntityType, PrismEntity } from "../services/entity-editors/map-entities-editor.service";
import { convertCartesian3ToSerializableCartographic } from "./convert-cartesian3-to-serializable-cartographic";

export interface PrismGeoJSONProperties {
    type: MapEntityType.Prism;
}

export function convertPrismEntityToGeoJSONFeature(entity: PrismEntity): GeoJSONFeature {
    const positions = entity.positions.map((position): Position => {
        const { latitude, longitude } = convertCartesian3ToSerializableCartographic(position);

        return [longitude, latitude];
    });

    const properties: PrismGeoJSONProperties = { type: MapEntityType.Prism };
    const polygon = turfPolygon([[...positions, positions[0]]], properties);

    return polygon;
}
