import { ComponentPortal } from "@angular/cdk/portal";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { DssNotificationsService } from "@dtm-frontend/dss-shared-lib";
import { AuthActions, AuthState } from "@dtm-frontend/shared/auth";
import { Notification } from "@dtm-frontend/shared/notifications";
import { DeviceSize, DeviceSizeService, RouteDataService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { combineLatest } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

interface HeaderComponentState {
    notificationsList: Notification[] | undefined;
}

@UntilDestroy()
@Component({
    selector: "dss-client-header",
    templateUrl: "./header.component.html",
    styles: ["dtm-ui-header-user-button { margin-right: -12px; }"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HeaderComponent {
    protected readonly routeTitle$ = this.routeDataService.routeTitle$;
    protected readonly isLoggedIn$ = this.store.select(AuthState.isLoggedIn);
    protected readonly userName$ = combineLatest([
        this.store.select(AuthState.userFirstName),
        this.store.select(AuthState.userLastName),
    ]).pipe(map(([firstName, lastName]) => `${firstName} ${lastName}`));
    protected readonly userId$ = this.store.select(AuthState.userId);
    protected readonly componentPortalItem$ = this.routeDataService.routeData$.pipe(
        map((data) => data?.headerComponent),
        distinctUntilChanged(),
        map((headerComponent) => {
            if (!headerComponent) {
                return;
            }

            return new ComponentPortal(headerComponent);
        })
    );
    protected readonly allowedNotificationTypes = this.dssNotificationsService.getAllowedNotifications();
    protected readonly notificationsList$ = this.localStore.selectByKey("notificationsList");
    protected readonly isHeaderUserButtonShortMode$ = this.deviceSizeService.getSizeObservable(
        DeviceSize.Smartphone,
        DeviceSize.SmartphoneWide
    );

    constructor(
        private readonly router: Router,
        private readonly store: Store,
        private readonly routeDataService: RouteDataService,
        private readonly localStore: LocalComponentStore<HeaderComponentState>,
        private readonly dssNotificationsService: DssNotificationsService,
        private readonly deviceSizeService: DeviceSizeService
    ) {
        localStore.setState({
            notificationsList: undefined,
        });
    }

    protected logout() {
        this.store.dispatch(new AuthActions.Logout());
    }

    protected goToUserProfilePage() {
        this.router.navigateByUrl("/user");
    }

    protected changeNotificationsList(notificationsList: Notification[] | undefined) {
        this.localStore.patchState({ notificationsList });
    }
}
