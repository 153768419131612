import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { reloadComponent } from "../../utils/reload-component";

@Component({
    selector: "dtm-ui-error-page",
    templateUrl: "./error-page.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent {
    protected readonly state = this.router.getCurrentNavigation()?.extras.state;

    constructor(private readonly router: Router, @Inject(DOCUMENT) private readonly document: Document) {}

    protected reloadPage(): void {
        reloadComponent(this.router, this.document.location.pathname);
    }
}
