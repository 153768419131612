import { inject, Injectable } from "@angular/core";
import { Geolocation } from "@capacitor/geolocation";
import { Platform } from "@ionic/angular";
import { GeolocationService, Position } from "./geolocation.service";

@Injectable()
export class CapacitorGeolocationService implements GeolocationService {
    private readonly platform = inject(Platform);

    public async getUserPosition(): Promise<Position> {
        if (this.platform.is("ios")) {
            return this.getUserPositionByWatch();
        }

        return Geolocation.getCurrentPosition({ enableHighAccuracy: true }).then(({ coords: { accuracy, latitude, longitude } }) => ({
            accuracy,
            latitude,
            longitude,
        }));
    }

    public async canTryToGetUserPosition(): Promise<boolean> {
        return Geolocation.checkPermissions().then(({ location }) => location !== "denied");
    }

    // NOTE: on ios high accuracy getUserPosition takes up to 10s, watchPosition work much faster
    // with better results than enableHighAccuracy false
    private async getUserPositionByWatch(): Promise<Position> {
        return new Promise<Position>((resolve, reject) => {
            const id = Geolocation.watchPosition({ enableHighAccuracy: true }, async (position, error) => {
                Geolocation.clearWatch({ id: await id });
                if (error) {
                    reject(error);

                    return;
                }
                if (!position) {
                    reject(new Error("Position is null"));

                    return;
                }
                resolve({
                    accuracy: position.coords.accuracy,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            });
        });
    }
}
