import { Pipe, PipeTransform } from "@angular/core";
import { CENTIMETERS_IN_METER, METERS_IN_KILOMETER } from "./length";

@Pipe({
    name: "convertToCentimeters",
})
export class ConvertToCentimetersPipe implements PipeTransform {
    public transform(value: number | undefined | null, from: "meters" | "kilometers" = "meters"): number | undefined {
        if (value === undefined || value === null) {
            return undefined;
        }

        if (from === "kilometers") {
            return value * CENTIMETERS_IN_METER * METERS_IN_KILOMETER;
        }

        return value * CENTIMETERS_IN_METER;
    }
}
