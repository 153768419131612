import { Attribute, Directive, ElementRef, OnDestroy, Output } from "@angular/core";
import { DEFAULT_DEBOUNCE_TIME } from "@dtm-frontend/shared/utils";
import { Subject, debounceTime } from "rxjs";

@Directive({
    selector: "[dtmUiElementObserver]",
})
export class ElementObserverDirective implements OnDestroy {
    private domChangeSubject = new Subject<MutationRecord>();
    private elementResizeSubject = new Subject<ResizeObserverEntry>();

    @Output()
    public domChange = this.domChangeSubject.pipe(debounceTime(+this.debounceTimeValue));

    @Output()
    public elementResize = this.elementResizeSubject.pipe(debounceTime(+this.debounceTimeValue));

    private changes: MutationObserver;
    private resize: ResizeObserver;

    constructor(private elementRef: ElementRef, @Attribute("debounceTime") private readonly debounceTimeValue: number | string) {
        this.debounceTimeValue = +(this.debounceTimeValue ?? DEFAULT_DEBOUNCE_TIME);
        const element = this.elementRef.nativeElement;

        this.changes = new MutationObserver((mutations: MutationRecord[]) => {
            mutations.forEach((mutation: MutationRecord) => this.domChangeSubject.next(mutation));
        });

        this.changes.observe(element, {
            attributes: true,
            childList: true,
            characterData: true,
        });

        this.resize = new ResizeObserver((entries) => {
            for (const entry of entries) {
                this.elementResizeSubject.next(entry);
            }
        });

        this.resize.observe(element);
    }

    public ngOnDestroy(): void {
        this.changes.disconnect();
        this.resize.disconnect();
    }
}
