<ac-layer
    #groundRiskBufferLayer
    acFor="let entity of groundRiskBufferEntities$"
    [store]="true"
    [context]="this"
    [zIndex]="1"
    [show]="(isGroundRiskBufferShown$ | ngrxPush) ?? false"
>
    <ac-polygon-desc
        props="{
                hierarchy: entity.positions,
                material: GROUND_RISK_BUFFER_FILL_COLOR,
                height: 0
            }"
    >
    </ac-polygon-desc>
</ac-layer>

<ac-layer
    #safetyAreaLayer
    acFor="let entity of safetyAreaEntities$"
    [store]="true"
    [context]="this"
    [zIndex]="2"
    [show]="(isSafetyAreaShown$ | ngrxPush) ?? false"
>
    <ac-polygon-desc
        props="{
                hierarchy: entity.positions,
                material: SAFETY_AREA_FILL_COLOR,
                height: 0
            }"
    >
    </ac-polygon-desc>
</ac-layer>
