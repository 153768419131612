import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { JwtHelperService } from "@auth0/angular-jwt";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { LOCAL_STORAGE } from "@dtm-frontend/shared/utils";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { LoginComponent } from "./components/login/login.component";
import { DtmAuthGuard } from "./guards/dtm-auth.guard";
import { DtmRoleGuard } from "./guards/dtm-role.guard";
import { OfflineCapableDtmAuthGuard } from "./guards/offline-capable-dtm-auth.guard";
import { OfflineCapableDtmRoleGuard } from "./guards/offline-capable-dtm-role.guard";
import { AuthenticationOfflineCapableInterceptor } from "./interceptors/authentication-offline-capable.interceptor";
import { AuthService } from "./services/auth.service";
import { OfflineCapableAuthService } from "./services/offline-capable-auth.service";
import { AuthState } from "./state/auth.state";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        SharedUiModule,
        NgxsModule.forFeature([AuthState]),
        LetModule,
        PushModule,
        HttpClientModule,
        SharedI18nModule,
    ],
    providers: [
        {
            provide: JwtHelperService,
            useValue: new JwtHelperService(),
        },
        { provide: DtmRoleGuard, useClass: OfflineCapableDtmRoleGuard },
        { provide: DtmAuthGuard, useClass: OfflineCapableDtmAuthGuard },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationOfflineCapableInterceptor,
            multi: true,
        },
        {
            provide: AuthService,
            useClass: OfflineCapableAuthService,
        },
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmAuth",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
            },
        },
        { provide: LOCAL_STORAGE, useValue: localStorage },
    ],
    declarations: [LoginComponent],
    exports: [LoginComponent],
})
export class OfflineCapableAuthModule {}
