import { FormControl, FormGroup } from "@angular/forms";

/**
 * removes indexes other than actual keys of T, even when it extends { [key: string]: <any> }
 */
export type NarrowIndexable<T> = {
    [K in keyof T as string extends K ? never : number extends K ? never : K]: T[K];
};

/**
 * get the type of elements inside of an array
 */
export type ArrayElementType<T> = T extends (infer U)[] ? U : never;

/**
 * creates form type from object interface
 */
export type FormType<T extends object> = { [key in keyof T]: FormControl<T[key]> };

/**
 * creates form type from deep object interface - only works for string, number, boolean, Date and symbol control values
 */

export type DeepFormType<T extends object> = {
    [key in keyof T]: T[key] extends string | number | boolean | Date | symbol
        ? FormControl<T[key]>
        : T[key] extends object
        ? FormGroup<DeepFormType<Required<T[key]>>>
        : never;
};

/**
 * creates object interface from other object interface but with NonNullable properties
 */
export type DeepNonNullable<T extends object, KeysToOmit extends keyof T = never> = {
    [key in keyof T]: key extends KeysToOmit ? T[key] : NonNullable<T[key]>;
};
