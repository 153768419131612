import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, finalize } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { WeatherStatus } from "../../cesium/models/weather.models";
import { getWeatherStatus } from "../../shared/utils/weather-status";
import { Weather, WeatherError, WeatherPayloadData } from "../models/weather.models";
import { WeatherApiService } from "../services/weather-api.service";
import { convertWeatherToSimpleWeather } from "../services/weather.converters";
import { WeatherActions } from "./weather.actions";

export enum WeatherViewMode {
    Basic = "Normal",
    Tabs = "Tabs",
    Hide = "Hide",
}

interface WeatherStateModel {
    weatherRangeList: Weather[] | undefined;
    weatherByMissionTime: Weather | undefined;
    isProcessing: boolean;
    weatherConditionsError: WeatherError | undefined;
    viewMode: WeatherViewMode;
    weatherPayloadData: WeatherPayloadData | undefined;
    selectedRangeIndex: number;
    weatherMissionStatus: string | undefined;
    isWeatherPanelOpen: boolean;
    isWithinDtm: boolean;
    forecastRefTime: Date | undefined;
}

const defaultState: WeatherStateModel = {
    weatherRangeList: undefined,
    weatherByMissionTime: undefined,
    isProcessing: false,
    weatherConditionsError: undefined,
    viewMode: WeatherViewMode.Hide,
    weatherPayloadData: undefined,
    selectedRangeIndex: 0,
    weatherMissionStatus: undefined,
    isWeatherPanelOpen: false,
    isWithinDtm: false,
    forecastRefTime: undefined,
};

@State({
    name: "weather",
    defaults: defaultState,
})
@Injectable()
export class WeatherState {
    @Selector()
    public static weatherRangeList(state: WeatherStateModel): Weather[] | undefined {
        return state.weatherRangeList;
    }

    @Selector()
    public static weatherByMissionTime(state: WeatherStateModel): Weather | undefined {
        return state.weatherByMissionTime;
    }

    @Selector()
    public static selectedWeatherRangeIndex(state: WeatherStateModel): number {
        return state.selectedRangeIndex;
    }

    @Selector()
    public static isProcessing(state: WeatherStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static weatherViewMode(state: WeatherStateModel): WeatherViewMode {
        return state.viewMode;
    }

    @Selector()
    public static weatherMissionStatus(state: WeatherStateModel): string | undefined {
        return state.weatherMissionStatus;
    }

    @Selector()
    public static isWeatherPanelOpen(state: WeatherStateModel): boolean {
        return state.isWeatherPanelOpen;
    }

    @Selector()
    public static weatherConditionsError(state: WeatherStateModel): WeatherError | undefined {
        return state.weatherConditionsError;
    }

    @Selector()
    public static isWithinDtm(state: WeatherStateModel): boolean {
        return state.isWithinDtm;
    }

    @Selector()
    public static forecastRefTime(state: WeatherStateModel): Date | undefined {
        return state.forecastRefTime;
    }

    constructor(private readonly weatherApi: WeatherApiService) {}

    @Action(WeatherActions.GetWeatherConditions)
    public getWeather(context: StateContext<WeatherStateModel>, action: WeatherActions.GetWeatherConditions) {
        context.patchState({ weatherRangeList: undefined, isProcessing: true, weatherConditionsError: undefined });

        return this.weatherApi.getWeather(action.dtmName).pipe(
            tap(({ weatherConditions, forecastRefTime }) => {
                context.patchState({
                    weatherRangeList: weatherConditions,
                    forecastRefTime: forecastRefTime,
                    selectedRangeIndex: 0,
                });
            }),
            catchError((weatherConditionsError) => {
                context.patchState({ weatherConditionsError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(WeatherActions.ChangeWeatherVisibility)
    public changeWeatherVisibility(context: StateContext<WeatherStateModel>, action: WeatherActions.ChangeWeatherVisibility) {
        context.patchState({
            viewMode: action.viewMode,
            weatherRangeList: undefined,
        });
    }

    @Action(WeatherActions.GetMissionPlanWeatherRange)
    public getMissionPlanWeather(
        context: StateContext<WeatherStateModel>,
        { weatherPayloadData }: WeatherActions.GetMissionPlanWeatherRange
    ) {
        context.patchState({
            isProcessing: true,
            weatherRangeList: undefined,
            weatherByMissionTime: undefined,
            weatherConditionsError: undefined,
        });

        return this.weatherApi.getMissionPlanWeather(weatherPayloadData).pipe(
            tap(({ weatherConditions, isWithinDtm, forecastRefTime }) => {
                if (!weatherConditions.length) {
                    context.patchState({
                        weatherMissionStatus: WeatherStatus.None,
                    });

                    return;
                }

                const weatherMissionByTime = this.filterWeatherConditionByHour(weatherConditions, weatherPayloadData.missionStartTime)[0];

                context.patchState({
                    isWithinDtm,
                    forecastRefTime,
                    weatherRangeList: weatherConditions,
                    weatherByMissionTime: weatherMissionByTime,
                    selectedRangeIndex: weatherConditions.findIndex((element) =>
                        this.areHoursEqual(new Date(element.time), weatherPayloadData.missionStartTime)
                    ),
                    weatherMissionStatus: isWithinDtm
                        ? getWeatherStatus(weatherMissionByTime)
                        : getWeatherStatus(convertWeatherToSimpleWeather(weatherMissionByTime)),
                    viewMode: WeatherViewMode.Tabs,
                });
            }),
            catchError((weatherConditionsError) => {
                context.patchState({ weatherConditionsError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(WeatherActions.ResetWeatherState)
    public resetWeatherState(context: StateContext<WeatherStateModel>) {
        context.patchState({
            ...defaultState,
        });
    }

    @Action(WeatherActions.ChangeWeatherPanelVisibility)
    public changePanelVisibility(context: StateContext<WeatherStateModel>) {
        context.patchState({
            isWeatherPanelOpen: !context.getState().isWeatherPanelOpen,
        });
    }

    private filterWeatherConditionByHour(weatherRangeList: Weather[], currentPlanTime: Date): Weather[] {
        return weatherRangeList.filter((element) => this.areHoursEqual(new Date(element.time), currentPlanTime));
    }

    private areHoursEqual(leftTime: Date, rightTime: Date): boolean {
        return leftTime.getHours() === rightTime.getHours();
    }
}
