import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "isEmptyElement",
    pure: false,
})
export class IsEmptyElementPipe implements PipeTransform {
    public transform(element: HTMLElement): boolean {
        return element.innerText.trim() === "";
    }
}
