import { ErrorHandler, ModuleWithProviders, NgModule } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import packageJson from "package.json";
import { Logger } from "./logger.service";

@NgModule()
export class LoggerModule {
    public static forRoot(environment: string, shouldEnableConsoleCapture = false): ModuleWithProviders<LoggerModule> {
        if (!Sentry.isInitialized()) {
            throw new Error("Sentry has not been initialized. Please call `Logger.initialize` before using this module.");
        }

        if (shouldEnableConsoleCapture) {
            Logger.enableConsoleCapture();
        }

        Sentry.getCurrentScope().addEventProcessor((event) => {
            event.environment = environment;
            event.release = packageJson.version; // TODO: IN-318 embed build version from CI/CD

            return event;
        });

        return {
            ngModule: LoggerModule,
            providers: [
                {
                    provide: ErrorHandler,
                    useValue: Sentry.createErrorHandler({
                        showDialog: false,
                    }),
                },
            ],
        };
    }
}
