<ng-container
    *ngrxLet="{
        zone: zone$,
        selectedZoneId: selectedZoneId$,
        activeLanguageCode: activeLanguageCode$,
        isOpened: isOpened$,
        aupEndTime: aupEndTime$,
        airacEndTime: airacEndTime$,
        isInformationExpanded: isInformationExpanded$,
        timeRange: timeRange$,
    } as vm"
>
    <dtm-ui-expandable-panel [hasHeaderSeparator]="false" [expanded]="vm.isOpened" *ngIf="vm.zone as zone">
        <div headerSlot class="header">
            <dtm-ui-icon [name]="zone | invoke : getZoneIconName" class="zone-icon {{ zone.geoZoneType | lowercase }}"></dtm-ui-icon>
            <h2>
                <ng-container *ngIf="zone.geoZoneType !== GeoZoneType.Local; else LocalZoneHeaderTemplate">
                    {{ zone.geoZoneType }} ({{ zone.designator }})
                </ng-container>
                <ng-template #LocalZoneHeaderTemplate>
                    {{ zone.zoneAttributes?.name }}
                    <span>
                        {{
                            "dtmMapCesium.localSpatialInfoForDTM.infoZoneType"
                                | transloco : { value: zone.zoneAttributes?.localInformationType }
                        }}
                    </span>
                </ng-template>
            </h2>

            <button
                type="button"
                class="button-icon map-icon"
                (click)="$event.stopPropagation(); selectZone(zone)"
                [class.active]="vm.selectedZoneId === zone.id"
            >
                <dtm-ui-icon name="road-map"></dtm-ui-icon>
            </button>
        </div>
        <div class="content" *ngIf="zone.information as information">
            <div class="zone-details">
                <div class="full-column">
                    <p class="label">{{ "dtmMapCesium.zoneInfo.zoneTimeScopeLabel" | transloco }}</p>
                    <span *ngIf="zone.scope as scope" class="value">
                        <ng-container
                            *ngTemplateOutlet="activationRange; context: { $implicit: vm.zone.scope | invoke : scopeToTimeRange }"
                        ></ng-container>
                    </span>
                </div>
                <div>
                    <p class="label">{{ "dtmMapCesium.zoneInfo.zoneBordersLabel" | transloco }}</p>
                    <span *ngIf="zone.scope as scope" class="value">
                        {{
                            "dtmMapCesium.zoneInfo.heightLimitsLabel"
                                | transloco
                                    : {
                                          min: scope.lowerLimit,
                                          max: scope.upperLimit
                                      }
                        }}
                    </span>
                </div>
                <div *ngIf="zone.isH24 || !zone.isStatic || zone.parent">
                    <p class="label">{{ "dtmMapCesium.zoneInfo.activityTimeLabel" | transloco }}</p>
                    <span class="value">
                        <ng-container *ngIf="zone.isH24; else nonH24Template">
                            {{ "dtmMapCesium.zoneInfo.h24Label" | transloco }}
                        </ng-container>
                        <ng-template #nonH24Template>
                            <ng-container *ngIf="zone.parent">
                                {{ "dtmMapCesium.zoneInfo.basedOnZoneActivityTimeLabel" | transloco : { name: zone.parent } }}
                            </ng-container>
                            <ng-container *ngIf="!zone.isStatic && !zone.parent">
                                {{ "dtmMapCesium.zoneInfo.elasticActivityTimeLabel" | transloco }}
                                <dtm-ui-popover>
                                    {{ "dtmMapCesium.zoneInfo.elasticActivityHint" | transloco }}
                                </dtm-ui-popover>
                            </ng-container>
                        </ng-template>
                    </span>
                </div>
            </div>
            <div class="reservations" *ngIf="!zone.isH24">
                <ng-container *ngIf="zone.isStatic; else elasticZoneTemplate">
                    <ng-container *ngIf="zone.reservations.length; else noReservationsTemplate">
                        <p class="activity-title">{{ "dtmMapCesium.zoneInfo.reservationTimesLabel" | transloco }}</p>
                        <table class="reservations-table specific" #specificReservationsTable>
                            <thead>
                                <tr>
                                    <th>{{ "dtmMapCesium.zoneInfo.reservationsTableHeaders.date" | transloco }}</th>
                                    <th>{{ "dtmMapCesium.zoneInfo.reservationsTableHeaders.start" | transloco }}</th>
                                    <th>{{ "dtmMapCesium.zoneInfo.reservationsTableHeaders.end" | transloco }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let item of zone.reservations | invoke : getGroupedReservations; let index = index"
                                    [class.even]="item.isEven"
                                >
                                    <td>{{ item.reservation.scope.startTime | localizeDate : { dateStyle: "short" } }}</td>
                                    <td>{{ item.reservation.scope.startTime | localizeDate : { timeStyle: "short" } }}</td>
                                    <td>{{ item.reservation.scope.endTime | localizeDate : { timeStyle: "short" } }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <ng-container
                            *ngTemplateOutlet="
                                expandButtonTemplate;
                                context: { $implicit: specificReservationsTable, size: zone.reservations.length }
                            "
                        ></ng-container>
                    </ng-container>
                    <ng-template #noReservationsTemplate>
                        <div class="reservation-info">{{ "dtmMapCesium.zoneInfo.noStaticReservationsLabel" | transloco }}</div>
                    </ng-template>
                </ng-container>

                <ng-template #elasticZoneTemplate>
                    <ng-container *ngIf="zone.reservations && (zone.reservations | invoke : partitionReservation) as reservations">
                        <ng-container *ngIf="!(vm.aupEndTime | invoke : isBefore : vm.timeRange?.min)">
                            <h2 class="activity-title">{{ "dtmMapCesium.zoneInfo.orderedActivityLabel" | transloco }}</h2>
                            <p class="activation-range">
                                <span>
                                    {{ "dtmMapCesium.zoneInfo.fromLabel" | transloco }}
                                    {{ vm.timeRange?.min | localizeDate : { dateStyle: "short" } }}
                                    <dtm-ui-icon name="time"></dtm-ui-icon>
                                    {{ vm.timeRange?.min | localizeDate : { timeStyle: "short" } }}
                                </span>
                                <span>
                                    {{ "dtmMapCesium.zoneInfo.toLabel" | transloco }}
                                    {{ vm.aupEndTime | localizeDate : { dateStyle: "short" } }}
                                    <dtm-ui-icon name="time"></dtm-ui-icon>
                                    {{ vm.aupEndTime | localizeDate : { timeStyle: "short" } }}
                                </span>
                            </p>
                            <ng-container *ngIf="reservations.current.length; else noCurrentReservationsTemplate">
                                <ng-container
                                    *ngTemplateOutlet="reservationsTableTemplate; context: { $implicit: reservations.current }"
                                ></ng-container>
                            </ng-container>
                            <ng-template #noCurrentReservationsTemplate>
                                <div class="reservation-info">
                                    {{ "dtmMapCesium.zoneInfo.noReservationsLabel" | transloco }}
                                </div>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngIf="reservations.next.length">
                            <h2 class="activity-title">
                                {{ "dtmMapCesium.zoneInfo.possibleMaxActivityRangeLabel" | transloco }}
                                <dtm-ui-popover>
                                    <div class="activity-hint">
                                        {{ "dtmMapCesium.zoneInfo.activityHintText" | transloco }}
                                        <ul [innerHTML]="'dtmMapCesium.zoneInfo.activityHintTextList' | transloco"></ul>
                                    </div>
                                </dtm-ui-popover>
                            </h2>
                            <p class="activation-range">
                                <span *ngIf="vm.aupEndTime | invoke : selectEarlierDate : vm.timeRange?.min as fromDate">
                                    {{ "dtmMapCesium.zoneInfo.afterLabel" | transloco }}
                                    {{ fromDate | localizeDate : { dateStyle: "short" } }}
                                    <dtm-ui-icon name="time"></dtm-ui-icon>
                                    {{ fromDate | localizeDate : { timeStyle: "short" } }}
                                    <dtm-ui-popover
                                        [popoverText]="'dtmMapCesium.zoneInfo.activityTimeHintText' | transloco"
                                    ></dtm-ui-popover>
                                </span>
                            </p>
                            <ng-container
                                *ngTemplateOutlet="reservationsTableTemplate; context: { $implicit: reservations.next, isWaiting: true }"
                            ></ng-container>
                        </ng-container>
                        <ng-template #reservationsTableTemplate let-reservations let-isWaiting="isWaiting">
                            <table class="reservations-table" [class.waiting]="isWaiting" #reservationTable>
                                <thead>
                                    <tr>
                                        <th>{{ "dtmMapCesium.zoneInfo.reservationsTableHeaders.start" | transloco }}</th>
                                        <th>{{ "dtmMapCesium.zoneInfo.reservationsTableHeaders.end" | transloco }}</th>
                                        <th>{{ "dtmMapCesium.zoneInfo.reservationsTableHeaders.heightRangeInAmsl" | transloco }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let reservation of reservations">
                                        <td>
                                            <p>
                                                {{
                                                  reservation.scope.startTime
                                                    | localizeDate
                                                    : {
                                                      dateStyle: "short",
                                                    }
                                                }}
                                                <span class="day-text">
                                                    {{
                                                        reservation.scope.startTime
                                                            | localizeDate
                                                                : {
                                                                      weekday: "short"
                                                                  }
                                                    }}
                                                </span>
                                            </p>
                                            <p>
                                                {{
                                                    reservation.scope.startTime
                                                        | localizeDate
                                                            : {
                                                                  timeStyle: "short"
                                                              }
                                                }}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {{
                                                  reservation.scope.endTime
                                                    | localizeDate
                                                    : {
                                                      dateStyle: "short",
                                                    }
                                                }}
                                                <span class="day-text">
                                                    {{
                                                        reservation.scope.endTime
                                                            | localizeDate
                                                                : {
                                                                      weekday: "short"
                                                                  }
                                                    }}
                                                </span>
                                            </p>
                                            <p>
                                                {{
                                                    reservation.scope.endTime
                                                        | localizeDate
                                                            : {
                                                                  timeStyle: "short"
                                                              }
                                                }}
                                            </p>
                                        </td>
                                        <td class="data-with-tooltip">
                                            <p>
                                                {{
                                                    "dtmMapCesium.zoneInfo.upperLimitLabel"
                                                        | transloco : { value: reservation.scope.upperLimit }
                                                }}
                                            </p>
                                            <p>
                                                {{
                                                    "dtmMapCesium.zoneInfo.lowerLimitLabel"
                                                        | transloco : { value: reservation.scope.lowerLimit }
                                                }}
                                            </p>
                                            <dtm-ui-popover
                                                *ngIf="reservation | invoke : isPredictedReservation : vm.airacEndTime"
                                                [popoverText]="'dtmMapCesium.zoneInfo.predictedActivityHint' | transloco"
                                            ></dtm-ui-popover>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <ng-container
                                *ngTemplateOutlet="
                                    expandButtonTemplate;
                                    context: { $implicit: reservationTable, size: reservations.length }
                                "
                            ></ng-container>
                        </ng-template>
                    </ng-container>
                    <p class="label">{{ "dtmMapCesium.zoneInfo.nextTimeRangeInfoMissingLabel" | transloco }}</p>
                </ng-template>
            </div>
            <ng-container *ngIf="information?.[vm.activeLanguageCode] as information">
                <div class="information-container" [class.expanded]="vm.isInformationExpanded">
                    <div class="information" *ngIf="information.description as description">
                        <p class="label">
                            {{ "dtmMapCesium.zoneInfo.descriptionLabel" | transloco }}
                        </p>
                        <p>
                            <span [innerHTML]="description.text | safeHtml" class="description"></span>
                            <dtm-ui-popover *ngIf="description.tooltip">
                                <p [innerHTML]="description.tooltip | safeHtml"></p>
                            </dtm-ui-popover>
                        </p>
                    </div>
                    <div class="information" *ngIf="information?.contact as contact">
                        <p class="label">
                            {{ "dtmMapCesium.zoneInfo.contactLabel" | transloco }}
                        </p>
                        <p>{{ contact }}</p>
                    </div>
                </div>
                <p class="expand-button-container">
                    <button type="button" class="button-tertiary" (click)="toggleInformation()">
                        <span>
                            {{
                                (vm.isInformationExpanded ? "dtmMapCesium.zoneInfo.collapseLabel" : "dtmMapCesium.zoneInfo.extendLabel")
                                    | transloco
                            }}
                        </span>
                        <dtm-ui-icon [name]="vm.isInformationExpanded ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
                    </button>
                </p>
            </ng-container>
        </div>
    </dtm-ui-expandable-panel>
    <ng-template #activationRange let-timeRange>
        <p class="activation-range">
            <span>
                {{ "dtmMapCesium.zoneInfo.fromLabel" | transloco }}
                <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
                {{ timeRange?.min | localizeDate : { dateStyle: "short" } }}
                <dtm-ui-icon name="time"></dtm-ui-icon>
                {{ timeRange?.min | localizeDate : { timeStyle: "short" } }}
            </span>
            <span>
                {{ "dtmMapCesium.zoneInfo.toLabel" | transloco }}
                <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
                {{ timeRange?.max | localizeDate : { dateStyle: "short" } }}
                <dtm-ui-icon name="time"></dtm-ui-icon>
                {{ timeRange?.max | localizeDate : { timeStyle: "short" } }}
            </span>
        </p>
    </ng-template>
</ng-container>
<ng-template #expandButtonTemplate let-tableElement let-size="size">
    <p class="expand-button-container" *ngIf="size > DEFAULT_TABLE_VISIBLE_ROWS">
        <button type="button" class="button-tertiary" (click)="toggleTableMode(tableElement)">
            <span>{{
                (tableElement.classList.contains("extended") ? "dtmMapCesium.zoneInfo.collapseLabel" : "dtmMapCesium.zoneInfo.extendLabel")
                    | transloco
            }}</span>
            <dtm-ui-icon [name]="tableElement.classList.contains('extended') ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
        </button>
    </p>
</ng-template>
