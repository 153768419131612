import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, inject, Injector, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";
import {
    FunctionUtils,
    LocalComponentStore,
    MILLISECONDS_IN_MINUTE,
    MILLISECONDS_IN_SECOND,
    VERIFICATION_CODE_MASK,
} from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";

interface VerificationCodeComponentState {
    verificationCodeMask: string | undefined;
    timeout: number;
    isResendDisabled: boolean;
    countdown: number | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-verification-code",
    templateUrl: "./verification-code.component.html",
    styleUrls: ["./verification-code.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => VerificationCodeComponent), multi: true }],
})
export class VerificationCodeComponent implements OnInit, ControlValueAccessor {
    private readonly injector = inject(Injector);

    @Input() public set verificationCodeMask(value: string | undefined) {
        this.localStore.patchState({ verificationCodeMask: value });
    }
    @Input() public set timeout(value: number | undefined) {
        this.localStore.patchState({ timeout: value ?? MILLISECONDS_IN_MINUTE });
    }

    @Output() public readonly verificationCodeResend = new EventEmitter<void>();

    private propagateChange: () => void = FunctionUtils.noop;
    private propagateTouch = FunctionUtils.noop;

    protected verificationCodeFormControl?: FormControl;
    protected readonly verificationCodeMask$ = this.localStore.selectByKey("verificationCodeMask");
    protected readonly isResendDisabled$ = this.localStore.selectByKey("isResendDisabled");
    protected readonly countdown$ = this.localStore.selectByKey("countdown");

    constructor(private readonly localStore: LocalComponentStore<VerificationCodeComponentState>) {
        this.localStore.setState({
            verificationCodeMask: VERIFICATION_CODE_MASK,
            timeout: MILLISECONDS_IN_MINUTE,
            isResendDisabled: false,
            countdown: undefined,
        });
    }

    public ngOnInit() {
        const ngControl = this.injector.get(NgControl);
        this.verificationCodeFormControl = ngControl.control as FormControl;
    }

    public registerOnChange(fn: () => void) {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void) {
        this.propagateTouch = fn;
    }

    public writeValue() {}

    protected onSubmit() {
        const isResendDisabled = this.localStore.selectSnapshotByKey("isResendDisabled");
        if (isResendDisabled) {
            return;
        }

        this.localStore.patchState({
            isResendDisabled: true,
        });

        const timeout = this.localStore.selectSnapshotByKey("timeout");
        this.localStore.patchState({ countdown: timeout / 1000 });

        const interval = setInterval(() => {
            const countdown = this.localStore.selectSnapshotByKey("countdown");
            if (countdown && countdown > 0) {
                this.localStore.patchState({ countdown: countdown - 1 });
            } else {
                clearInterval(interval);
                this.localStore.patchState({
                    isResendDisabled: false,
                    countdown: undefined,
                });
            }
        }, MILLISECONDS_IN_SECOND);

        this.verificationCodeResend.emit();
    }
}
