import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FlightZoneSharedModule } from "@dtm-frontend/dss-shared-lib";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";

@NgModule({
    declarations: [DashboardComponent],
    imports: [CommonModule, LetModule, PushModule, RouterModule, SharedUiModule, FlightZoneSharedModule],
    exports: [DashboardComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dssClientLibDashboard",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class DashboardModule {}
