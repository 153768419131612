import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { FlightZoneApplicationListItem, FlightZoneApplicationsListWithExpandableRowComponentState } from "@dtm-frontend/dss-shared-lib";
import { Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { FlightZonesTableColumns } from "../../../models/flight-zone.models";

interface SubmittedApplicationsListComponentState
    extends FlightZoneApplicationsListWithExpandableRowComponentState<FlightZoneApplicationListItem> {
    hasSupervisorPermissions: boolean;
    applicationsPage: Page | undefined;
    isModificationsList: boolean;
}

@Component({
    selector: "dss-client-lib-submitted-list[flightZoneApplications][hasDataRetrievalError][hasSupervisorPermissions]",
    templateUrl: "./submitted-list.component.html",
    styleUrls: ["./submitted-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class SubmittedListComponent {
    protected readonly displayedColumns: FlightZonesTableColumns = [
        "expandHandle",
        "status",
        "date",
        "startAt",
        "endAt",
        "author",
        "applicationPurpose",
        "title",
        "actions",
    ];

    protected readonly flightZoneApplications$ = this.localStore.selectByKey("flightZoneApplications");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly hasDataRetrievalError$ = this.localStore.selectByKey("hasDataRetrievalError");
    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly hasSupervisorPermissions$ = this.localStore.selectByKey("hasSupervisorPermissions");
    protected readonly applicationsPage$ = this.localStore.selectByKey("applicationsPage");
    protected readonly isModificationsList$ = this.localStore.selectByKey("isModificationsList");

    @Input() public set flightZoneApplications(value: FlightZoneApplicationListItem[] | undefined) {
        this.localStore.patchState({ flightZoneApplications: value ?? [] });
    }
    @Input() public set isProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isProcessing: !!value });
    }
    @Input() public set hasDataRetrievalError(value: boolean | undefined) {
        this.localStore.patchState({ hasDataRetrievalError: !!value });
    }
    @Input() public set hasSupervisorPermissions(value: boolean | undefined) {
        this.localStore.patchState({ hasSupervisorPermissions: value });
    }
    @Input() public set page(value: Page | undefined) {
        this.localStore.patchState({ applicationsPage: value });
    }
    @Input() public set isModificationsList(value: boolean) {
        this.localStore.patchState({ isModificationsList: value });
    }

    @Output() protected readonly pageChange = new EventEmitter<PageEvent>();
    @Output() protected readonly listRefresh = new EventEmitter<void>();
    @Output() protected readonly kmlDownload = new EventEmitter<FlightZoneApplicationListItem>();

    constructor(private readonly localStore: LocalComponentStore<SubmittedApplicationsListComponentState>) {
        this.localStore.setState({
            flightZoneApplications: [],
            isProcessing: false,
            hasDataRetrievalError: false,
            expandedElement: null,
            hasSupervisorPermissions: false,
            applicationsPage: undefined,
            isModificationsList: false,
        });
    }

    protected toggleExpandableRow(row: FlightZoneApplicationListItem): void {
        const currentlyExpandedElement = this.localStore.selectSnapshotByKey("expandedElement");

        this.localStore.patchState({ expandedElement: currentlyExpandedElement === row ? null : row });
    }
}
