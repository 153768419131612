import { ChangeDetectorRef, Inject, Optional, Pipe, PipeTransform } from "@angular/core";
import { TRANSLOCO_LANG, TRANSLOCO_SCOPE, TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { HashMap, OrArray, TranslocoScope } from "@jsverse/transloco/lib/types";
import { SYSTEM_TRANSLATION_SCOPE } from "../shared-i18n.tokens";

@Pipe({
    name: "hasTranslation",
    pure: false,
})
export class HasTranslationPipe implements PipeTransform {
    protected translocoPipe;

    constructor(
        translocoService: TranslocoService,
        @Optional() @Inject(TRANSLOCO_SCOPE) providerScope: OrArray<TranslocoScope> | undefined,
        @Optional() @Inject(TRANSLOCO_LANG) providerLang: string | undefined,
        cdr: ChangeDetectorRef
    ) {
        this.translocoPipe = new TranslocoPipe(translocoService, providerScope, providerLang, cdr);
    }

    public transform(key?: string | null, params?: HashMap, inlineLang?: string): boolean {
        const translation = this.translocoPipe.transform(key, params, inlineLang);

        return translation !== key;
    }
}

@Pipe({
    name: "hasSystemTranslation",
    pure: false,
})
export class HasSystemTranslation extends HasTranslationPipe implements PipeTransform {
    public transform(key?: string | null, params?: HashMap, inlineLang?: string): boolean {
        return super.transform(`${SYSTEM_TRANSLATION_SCOPE}.${key}`, params, inlineLang);
    }
}
