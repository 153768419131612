import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { DataChangeActionType, DataUpdateAction, EmailAddressFormValues } from "../../models/user-contact.models";

interface EditEmailAddressComponentState {
    isRequestedEmailChange: boolean;
    email: string | undefined;
    isVerificationRequired: boolean;
    isEditMode: boolean;
    hasEmailAddressConflictError: boolean;
    saveButtonLabel: string | undefined;
}

@Component({
    selector: "dtm-ui-edit-email-address[email]",
    templateUrl: "./edit-email-address.component.html",
    styles: [".email-value { overflow-wrap: anywhere; }"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class EditEmailAddressComponent {
    @Input() public set email(value: string | undefined) {
        this.localStore.patchState({ email: value });
    }
    @Input() public set isRequestedEmailChange(value: BooleanInput) {
        this.localStore.patchState({ isRequestedEmailChange: coerceBooleanProperty(value) });
    }
    @Input() public set hasEmailAddressConflictError(value: BooleanInput) {
        this.localStore.patchState({ hasEmailAddressConflictError: coerceBooleanProperty(value) });
    }
    @Input() public set isVerificationRequired(value: BooleanInput) {
        this.localStore.patchState({ isVerificationRequired: coerceBooleanProperty(value) });
    }
    @Input() public set isEditMode(value: BooleanInput) {
        this.localStore.patchState({ isEditMode: coerceBooleanProperty(value) });
    }
    @Input() public set saveButtonLabel(value: string | undefined) {
        this.localStore.patchState({ saveButtonLabel: value });
    }

    @Output() public readonly requestDataChange: EventEmitter<DataUpdateAction> = new EventEmitter<DataUpdateAction>();
    @Output() public readonly closePanel: EventEmitter<void> = new EventEmitter<void>();

    protected readonly isRequestedEmailChange$ = this.localStore.selectByKey("isRequestedEmailChange");
    protected readonly hasEmailAddressConflictError$ = this.localStore.selectByKey("hasEmailAddressConflictError");
    protected readonly email$ = this.localStore.selectByKey("email");
    protected readonly isVerificationRequired$ = this.localStore.selectByKey("isVerificationRequired");
    protected readonly isEditMode$ = combineLatest([
        this.localStore.selectByKey("isEditMode"),
        this.isRequestedEmailChange$,
        this.isVerificationRequired$,
    ]).pipe(
        map(([isEditMode, isRequestedEmailChange, isVerificationRequired]) =>
            isVerificationRequired ? isRequestedEmailChange : isEditMode
        )
    );
    protected readonly emailAddressFormControl = new FormControl<EmailAddressFormValues>(
        {
            email: "",
            verificationCode: "",
        },
        { nonNullable: true }
    );
    protected readonly saveButtonLabel$ = combineLatest([
        this.localStore.selectByKey("saveButtonLabel"),
        this.isRequestedEmailChange$,
    ]).pipe(
        map(([saveButtonLabel, isRequestedEmailChange]) => {
            if (saveButtonLabel) {
                return saveButtonLabel;
            } else if (isRequestedEmailChange) {
                return this.translocoService.translate("dtmUi.editEmailAddress.verifyButtonLabel");
            }

            return this.translocoService.translate("dtmUi.editEmailAddress.sendCodeButtonLabel");
        })
    );

    constructor(
        private readonly localStore: LocalComponentStore<EditEmailAddressComponentState>,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({
            email: undefined,
            isRequestedEmailChange: false,
            isVerificationRequired: true,
            isEditMode: false,
            hasEmailAddressConflictError: false,
            saveButtonLabel: undefined,
        });
    }

    protected requestUserEmailChange(isRequestedEmailChange: boolean): void {
        const verificationCode = this.emailAddressFormControl.value.verificationCode;
        const email = this.emailAddressFormControl.value.email;
        if (isRequestedEmailChange && verificationCode) {
            this.requestDataChange.emit({
                payload: verificationCode,
                actionType: DataChangeActionType.SaveNewEmailAddress,
            });
        } else if (!isRequestedEmailChange && email) {
            this.sendEmailVerifyCode();
        } else {
            this.emailAddressFormControl.markAllAsTouched();
        }
    }

    protected sendEmailVerifyCode(): void {
        this.requestDataChange.emit({
            payload: this.emailAddressFormControl.value.email,
            actionType: DataChangeActionType.RequestEmailChange,
        });
    }

    protected closeModifyEmailPanel(): void {
        this.emailAddressFormControl.reset();
        this.closePanel.emit();
    }
}
