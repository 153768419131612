<ng-container *ngrxLet="hasErrors$ as hasErrors">
    <quill-editor
        [class.error]="control.touched && hasErrors"
        [formControl]="control"
        [placeholder]="placeholder$ | ngrxPush"
        [sanitize]="true"
        (onBlur)="markAsTouched()"
        (onEditorCreated)="handleEditorCreation($event)"
        (onContentChanged)="updateControl($event)"
    >
        <ng-container
            [ngTemplateOutlet]="customToolbarTemplate || defaultToolbarTemplate"
            ngProjectAs="[quill-editor-toolbar]"
        ></ng-container>
    </quill-editor>
    <input type="file" class="file-input" #fileInputField [accept]="ACCEPTED_IMAGE_TYPES | join" />

    <div class="hint" *ngIf="!hasErrors || !control.touched">
        <ng-content select=".editor-hint" ngProjectAs=".field-hint"></ng-content>
    </div>

    <dtm-ui-form-errors *ngIf="control.touched && hasErrors">
        <ng-content select=".field-error" ngProjectAs=".field-error"></ng-content>
    </dtm-ui-form-errors>
</ng-container>

<ng-template #defaultToolbarTemplate>
    <dtm-ui-quill-editor-toolbar
        quill-editor-toolbar
        [isExtendedQuillToolbar]="isQuillToolbarExtended$ | ngrxPush"
    ></dtm-ui-quill-editor-toolbar>
</ng-template>
