import { HttpClient, HttpContext } from "@angular/common/http";
import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, SKIP_NOT_FOUND_HTTP_INTERCEPTOR } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { map } from "rxjs/operators";

@UntilDestroy()
@Directive({
    selector: "img[dtmUiImgSecuredSrc]",
})
export class ImgSecuredSrcDirective {
    @HostBinding("class.loading-image") public isLoadingInProgress = false;

    @Input("dtmUiImgSecuredSrc") public set sourceToSecure(value: string | undefined) {
        if (!value) {
            return;
        }

        this.initImageLoadingProcess(value);
    }
    @Output() public loadingChange = new EventEmitter<boolean>();

    constructor(
        private readonly cdRef: ChangeDetectorRef,
        private readonly imgElement: ElementRef<HTMLImageElement>,
        private readonly httpClient: HttpClient
    ) {}

    private initImageLoadingProcess(sourceToSecure: string) {
        this.setLoadingImage();

        this.getSecuredImageSource(sourceToSecure)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (securedImageSource) => this.setFinalImage(securedImageSource),
                error: () => this.setFinalImage(sourceToSecure),
            });
    }

    private setLoadingImage(): void {
        this.setImageSource(this.getDefaultImageSource());

        this.isLoadingInProgress = true;
        this.loadingChange.emit(this.isLoadingInProgress);

        this.cdRef.markForCheck();
    }

    private setFinalImage(source: string): void {
        this.setImageSource(source, () => URL.revokeObjectURL(source));

        this.isLoadingInProgress = false;
        this.loadingChange.emit(this.isLoadingInProgress);

        this.cdRef.markForCheck();
    }

    private setImageSource(source: string, onloadCallback?: () => unknown): void {
        this.imgElement.nativeElement.src = source;

        if (onloadCallback) {
            this.imgElement.nativeElement.addEventListener("load", onloadCallback);
        }
    }

    private getDefaultImageSource(): string {
        // eslint-disable-next-line max-len
        return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfmBgcHCTt72NvOAAAAGXRFWHRDb21tZW50AENyZWF0ZWQgd2l0aCBHSU1QV4EOFwAAAAtJREFUCNdjYGAAAAADAAEg1ZTHAAAAAElFTkSuQmCC";
    }

    public getSecuredImageSource(source: string) {
        return this.httpClient
            .get(source, {
                responseType: "blob",
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true).set(SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, true),
            })
            .pipe(map((response) => URL.createObjectURL(response)));
    }
}
