import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { Observable, map, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import {
    MissionFilters,
    MissionProcessingPhase,
    MissionsEvents,
    PhasePayloadData,
    PlannedMission,
    PlannedMissionErrorType,
} from "../models/planned-missions.models";
import { PLANNED_MISSION_ENDPOINTS, PlannedMissionEndpoints } from "../planned-missions.tokens";
import {
    MissionPlanListResponseBody,
    MissionPlanRouteResponseBody,
    convertMissionFiltersToHttpParams,
    convertMissionPlanResponseBodyToPlannedMissionList,
    convertMissionPlanRouteResponseBodyToMissionPlanRoute,
} from "./planned-missions.converters";

@Injectable()
export class PlannedMissionApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(PLANNED_MISSION_ENDPOINTS) private readonly endpoints: PlannedMissionEndpoints,
        private readonly websocketService: WebsocketService
    ) {}

    public startPlannedMissionsWatch(): Observable<unknown> {
        return this.websocketService.watchTopic(this.endpoints.missionPlansWatch, [MissionsEvents.ManualPlanVerificationSubmittedEvent]);
    }

    public getMissionList(query: MissionFilters | undefined): Observable<PlannedMission[]> {
        const params: HttpParams = convertMissionFiltersToHttpParams(query);

        return this.httpClient.get<MissionPlanListResponseBody>(this.endpoints.getPlannedMission, { params }).pipe(
            map((response) => convertMissionPlanResponseBodyToPlannedMissionList(response)),
            catchError(() => throwError(() => ({ type: PlannedMissionErrorType.CannotGetMissionList })))
        );
    }

    public getMissionRoute(routeId: string): Observable<MissionPlanRoute> {
        return this.httpClient
            .get<MissionPlanRouteResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getMissionRoute, { routeId }))
            .pipe(
                map((response) => convertMissionPlanRouteResponseBodyToMissionPlanRoute(response)),
                catchError(() => throwError(() => ({ type: PlannedMissionErrorType.CannotGetMissionRoute })))
            );
    }

    public changeMissionPhase({ id, missionPhase, note }: PhasePayloadData): Observable<MissionProcessingPhase> {
        const endpoint: string =
            missionPhase === MissionProcessingPhase.Accepted ? this.endpoints.missionAcceptance : this.endpoints.missionRejection;

        return this.httpClient
            .put<MissionProcessingPhase>(StringUtils.replaceInTemplate(endpoint, { id }), { note })
            .pipe(catchError(() => throwError(() => ({ type: PlannedMissionErrorType.CannotChangeMissionPhase }))));
    }
}
