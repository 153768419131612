import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { FlightZoneApplication, FlightZoneApplicationStatus, FlightZoneCapabilities } from "@dtm-frontend/dss-shared-lib";
import { ButtonTheme, ConfirmationDialogComponent } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, filter, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { FlightZoneWizardSteps } from "../../../../models/flight-zone.models";

interface ZoneSummaryStepComponentState {
    flightZoneApplicationData: FlightZoneApplication | undefined;
    isProcessing: boolean;
    hasSupervisorRole: boolean | undefined;
    capabilities: FlightZoneCapabilities | undefined;
}

@UntilDestroy()
@Component({
    selector: "dss-client-lib-zone-summary-step[flightZoneApplicationData][hasSupervisorRole][capabilities]",
    templateUrl: "./zone-summary-step.component.html",
    styleUrls: ["./zone-summary-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ZoneSummaryStepComponent {
    protected readonly FlightZoneApplicationStatus = FlightZoneApplicationStatus;
    protected readonly FlightZoneWizardSteps = FlightZoneWizardSteps;

    protected readonly flightZoneApplicationData$ = this.localStore.selectByKey("flightZoneApplicationData");
    protected readonly capabilities$ = this.localStore.selectByKey("capabilities");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly hasSupervisorRole$ = this.localStore.selectByKey("hasSupervisorRole");

    @Input()
    public set flightZoneApplicationData(value: FlightZoneApplication | undefined) {
        this.localStore.patchState({ flightZoneApplicationData: value });
    }

    @Input()
    public set capabilities(value: FlightZoneCapabilities | undefined) {
        this.localStore.patchState({ capabilities: value });
    }

    @Input()
    public set isProcessing(value: boolean) {
        this.localStore.patchState({ isProcessing: value });
    }

    @Input()
    public set hasSupervisorRole(value: boolean | undefined) {
        this.localStore.patchState({ hasSupervisorRole: value });
    }

    @Output() public backToStep = new EventEmitter<FlightZoneWizardSteps>();
    @Output() public zoomToGeometry = new EventEmitter<void>();
    @Output() public submitApplication = new EventEmitter<boolean>();
    @Output() public applicationRejection = new EventEmitter<void>();
    @Output() public applicationDelete = new EventEmitter<string>();
    @Output() public confirmCorrections = new EventEmitter<string>();

    constructor(
        private readonly localStore: LocalComponentStore<ZoneSummaryStepComponentState>,
        private readonly matDialog: MatDialog,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            flightZoneApplicationData: undefined,
            capabilities: undefined,
            isProcessing: false,
            hasSupervisorRole: false,
        });
    }

    protected rejectApplication(): void {
        this.getRejectApplicationConfirmation()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.applicationRejection.emit();
            });
    }

    protected deleteApplication(): void {
        this.getDeleteApplicationConfirmation()
            .pipe(
                filter(Boolean),
                switchMap(() => this.localStore.selectByKey("flightZoneApplicationData")),
                map((applicationData) => applicationData?.flightZoneId),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe((deletionParams) => {
                this.applicationDelete.emit(deletionParams);
            });
    }

    protected acceptCorrections(flightZoneId: string): void {
        this.getAcceptCorrectionsConfirmation()
            .pipe(filter(FunctionUtils.isTruthy), untilDestroyed(this))
            .subscribe(() => {
                this.confirmCorrections.emit(flightZoneId);
            });
    }

    private getRejectApplicationConfirmation(): Observable<boolean> {
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                confirmationText: this.transloco.translate("dssClientLibFlightZone.summaryStep.rejectApplicationConfirmDialog.dialogText"),
                declineButtonLabel: this.transloco.translate(
                    "dssClientLibFlightZone.summaryStep.rejectApplicationConfirmDialog.cancelButtonLabel"
                ),
                confirmButtonLabel: this.transloco.translate(
                    "dssClientLibFlightZone.summaryStep.rejectApplicationConfirmDialog.confirmButtonLabel"
                ),
                theme: ButtonTheme.Warn,
            },
        });

        return dialogRef.afterClosed();
    }

    private getDeleteApplicationConfirmation(): Observable<boolean> {
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                confirmationText: this.transloco.translate("dssClientLibFlightZone.deleteApplicationDraftConfirmDialog.deleteDraftText"),
                declineButtonLabel: this.transloco.translate(
                    "dssClientLibFlightZone.deleteApplicationDraftConfirmDialog.deleteDraftCancelLabel"
                ),
                confirmButtonLabel: this.transloco.translate(
                    "dssClientLibFlightZone.deleteApplicationDraftConfirmDialog.deleteDraftConfirmLabel"
                ),
            },
        });

        return dialogRef.afterClosed();
    }

    private getAcceptCorrectionsConfirmation(): Observable<boolean> {
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                confirmationText: this.transloco.translate("dssClientLibFlightZone.summaryStep.acceptCorrectionsConfirmDialog.dialogText"),
            },
        });

        return dialogRef.afterClosed();
    }
}
