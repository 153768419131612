import { groupByCount } from "./group-by";
import { partition } from "./partition";
import { addOrReplace, replace } from "./replace";
import { toObject } from "./to-object";
import { unique } from "./unique";

export const ArrayUtils = {
    partition,
    toObject,
    unique,
    replace,
    addOrReplace,
    groupByCount,
};
