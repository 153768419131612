import { SECONDS_IN_HOUR, SECONDS_IN_MINUTE } from "../../unit-utils/time";
import { ISO8601TimeDuration } from "./models";

export function convertFromSeconds(seconds: number): ISO8601TimeDuration {
    seconds = Math.round(seconds);

    const result = ["PT"];

    const hours = Math.floor(seconds / SECONDS_IN_HOUR);
    if (hours > 0) {
        seconds -= hours * SECONDS_IN_HOUR;
        result.push(`${hours}H`);
    }

    const minutes = Math.floor(seconds / SECONDS_IN_MINUTE);
    if (minutes > 0) {
        seconds -= minutes * SECONDS_IN_MINUTE;
        result.push(`${minutes}M`);
    }

    if (seconds > 0) {
        result.push(`${seconds}S`);
    }

    const duration = result.join("") as ISO8601TimeDuration;

    return duration === "PT" ? ("PT0S" as ISO8601TimeDuration) : duration;
}
