import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { tap } from "rxjs";
import { Weather } from "../../../weather/models/weather.models";
import { WeatherActions } from "../../../weather/state/weather.actions";
import { WeatherState, WeatherViewMode } from "../../../weather/state/weather.state";

interface WeatherComponentState {
    isProcessing: boolean;
    weatherList: Weather[];
    weatherByMissionTime: Weather | undefined;
    selectedRange: Weather | undefined;
    weatherViewMode: WeatherViewMode | undefined;
    selectedWeatherRangeIndex: number | undefined;
    selectedDtm: string | undefined;
    isWithinDtm: boolean;
    forecastRefTime: Date | undefined;
}

export enum DtmName {
    Nadarzyn = "DTM Nadarzyn",
    Gliwice = "DTM Gliwice",
    Lublin = "DTM Lublin",
}

@UntilDestroy()
@Component({
    selector: "dtm-map-weather",
    templateUrl: "./weather.component.html",
    styleUrls: ["./weather.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class WeatherComponent {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set weatherList(value: Weather[] | undefined) {
        if (value?.length) {
            this.localStore.patchState({ weatherList: value });
        }
    }
    @Input() public set weatherByMissionTime(value: Weather | undefined) {
        this.localStore.patchState({ weatherByMissionTime: value });
    }
    @Input() public set selectedWeatherRangeIndex(value: number | undefined) {
        const weatherList = this.localStore.selectSnapshotByKey("weatherList");

        if (value || value === 0) {
            this.localStore.patchState({ selectedWeatherRangeIndex: value });

            return;
        }

        if (!value && weatherList.length) {
            this.localStore.patchState({ selectedWeatherRangeIndex: 0 });
        }
    }
    @Input() public set weatherViewMode(value: WeatherViewMode | undefined) {
        this.localStore.patchState({ weatherViewMode: value });
    }
    @Input() public set isWithinDtm(value: BooleanInput) {
        this.localStore.patchState({ isWithinDtm: coerceBooleanProperty(value) });
    }
    @Input() public set forecastRefTime(value: Date | undefined) {
        this.localStore.patchState({ forecastRefTime: value });
    }

    protected readonly weatherList$ = this.localStore.selectByKey("weatherList").pipe(
        tap((weatherList) => {
            const selectedWeatherRangeIndex = this.localStore.selectSnapshotByKey("selectedWeatherRangeIndex");
            if (weatherList.length && selectedWeatherRangeIndex !== undefined) {
                this.localStore.patchState({ selectedRange: weatherList[selectedWeatherRangeIndex] });
            }
        })
    );
    protected readonly weatherByMissionTime$ = this.localStore.selectByKey("weatherByMissionTime");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly weatherViewMode$ = this.localStore.selectByKey("weatherViewMode");
    protected readonly selectedDtm$ = this.localStore.selectByKey("selectedDtm");
    protected readonly isWithinDtm$ = this.localStore.selectByKey("isWithinDtm");
    protected readonly forecastRefTime$ = this.localStore.selectByKey("forecastRefTime");
    protected readonly selectedRange$ = this.localStore.selectByKey("selectedRange").pipe(RxjsUtils.filterFalsy());
    protected readonly selectedWeatherRangeIndex$ = this.localStore.selectByKey("selectedWeatherRangeIndex");

    protected readonly WeatherViewMode = WeatherViewMode;
    protected readonly DtmName = DtmName;

    constructor(
        private readonly localStore: LocalComponentStore<WeatherComponentState>,
        private readonly store: Store,
        private readonly transloco: TranslocoService,
        private readonly toastr: ToastrService
    ) {
        this.localStore.setState({
            isProcessing: false,
            weatherList: [],
            weatherByMissionTime: undefined,
            selectedRange: undefined,
            weatherViewMode: undefined,
            selectedWeatherRangeIndex: undefined,
            selectedDtm: undefined,
            isWithinDtm: false,
            forecastRefTime: undefined,
        });
    }

    protected changeWeatherConditions(index: number): void {
        const weatherList = this.localStore.selectSnapshotByKey("weatherList");
        this.localStore.patchState({ selectedRange: weatherList[index] });
    }

    protected selectDtm(dtmName: DtmName): void {
        this.localStore.patchState({ selectedDtm: dtmName });

        this.store
            .dispatch(new WeatherActions.GetWeatherConditions(dtmName))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(WeatherState.weatherConditionsError);

                if (error) {
                    this.toastr.error(this.transloco.translate("dtmMapCesium.weather.weatherConditionsErrorMessage"));
                }
            });
    }
}
