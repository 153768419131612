/* eslint-disable no-underscore-dangle */

import { Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { AcEntity, Cartesian3, CesiumService } from "@pansa/ngx-cesium";
import { CartographicEditPointParentEntity } from "../models/cartographic-edit-point";
import { getDistanceToLine2D } from "../utils/get-distance-to-line-2d";
import { getElevatedCesiumPoint } from "../utils/get-elevated-cesium-point";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

const HALF_PI = Math.PI / 2;

export enum ShapeDragActions {
    MoveEntity = "MoveEntity",
    ChangeTopHeight = "ChangeTopHeight",
    ChangeBottomHeight = "ChangeBottomHeight",
}

export enum HeightEntityType {
    Top = "topHeight",
    Bottom = "bottomHeight",
}

export interface HeightEditable extends CartographicEditPointParentEntity {
    enableEdit: boolean;
    heightConstraints: {
        minTopHeight?: number;
        maxTopHeight?: number;
        minBottomHeight?: number;
        maxBottomHeight?: number;
        minHeight?: number;
        maxHeight?: number;
    };
}

export interface HeightPointsProvider {
    getTopHeight: () => number;
    getBottomHeight: () => number;
    getGroundPointForHeight: () => Cartesian3;
}

export interface DraggableHeightEntityBase {
    elevatedHeightPoint: Cartesian3;
    groundPoint: Cartesian3;
    height: number;
}

export class DraggableHeightEntity extends AcEntity implements DraggableHeightEntityBase {
    public readonly id: string;
    private _elevatedHeightPoint: Cartesian3 | undefined;
    private _groundPointForElevatedHeightPoint: Cartesian3 | undefined;
    private _heightForElevatedHeightPoint: number | undefined;

    constructor(
        public readonly parent: HeightEditable,
        protected readonly heightPointsProvider: HeightPointsProvider,
        public readonly type: HeightEntityType,
        private _height: number,
        private readonly isEnabled?: boolean
    ) {
        super();
        this.id = `${this.parent.getId()}_${StringUtils.generateId()}`;
    }

    public get height() {
        return this._height;
    }

    public get parentEntityId(): string {
        return this.parent.getId();
    }

    public isMovable(): boolean {
        if (!this.parent.enableEdit || !this.isEnabled) {
            return false;
        }

        switch (this.type) {
            case HeightEntityType.Bottom:
                return this.parent.heightConstraints.maxBottomHeight !== this.parent.heightConstraints.minBottomHeight;

            case HeightEntityType.Top:
                return this.parent.heightConstraints.maxTopHeight !== this.parent.heightConstraints.minTopHeight;
        }
    }

    public getHeightCallbackProperty() {
        return new Cesium.CallbackProperty(() => this.height, this.parent.isStaticEntity);
    }

    public setConstrainedHeight(newHeight: number): number {
        if (this.type === HeightEntityType.Bottom) {
            const topHeight = this.heightPointsProvider.getTopHeight();
            const shapeBottomMaxHeight =
                this.parent.heightConstraints.minHeight && topHeight - newHeight < this.parent.heightConstraints.minHeight
                    ? topHeight - this.parent.heightConstraints.minHeight
                    : Number.MAX_VALUE;
            const shapeBottomMinHeight =
                this.parent.heightConstraints.maxHeight && topHeight - newHeight > this.parent.heightConstraints.maxHeight
                    ? topHeight - this.parent.heightConstraints.maxHeight
                    : Number.MIN_VALUE;
            this._height = this.getConstrainedHeight(
                Math.min(this.parent.heightConstraints.maxBottomHeight ?? topHeight, topHeight, shapeBottomMaxHeight),
                Math.max(this.parent.heightConstraints.minBottomHeight ?? Number.MIN_VALUE, shapeBottomMinHeight),
                newHeight
            );
        } else {
            const bottomHeight = this.heightPointsProvider.getBottomHeight();
            const shapeTopMaxHeight =
                this.parent.heightConstraints.minHeight && newHeight - bottomHeight < this.parent.heightConstraints.minHeight
                    ? bottomHeight + this.parent.heightConstraints.minHeight
                    : Number.MAX_VALUE;
            const shapeTopMinHeight =
                this.parent.heightConstraints.maxHeight && newHeight - bottomHeight > this.parent.heightConstraints.maxHeight
                    ? bottomHeight + this.parent.heightConstraints.maxHeight
                    : Number.MIN_VALUE;
            this._height = this.getConstrainedHeight(
                Math.min(this.parent.heightConstraints.maxTopHeight ?? Number.MAX_VALUE, shapeTopMaxHeight),
                Math.max(this.parent.heightConstraints.minTopHeight ?? bottomHeight, bottomHeight, shapeTopMinHeight),
                newHeight
            );
        }

        return this._height;
    }

    public get elevatedHeightPoint() {
        if (
            this._elevatedHeightPoint &&
            this._heightForElevatedHeightPoint === this._height &&
            this._groundPointForElevatedHeightPoint?.equals(this.groundPoint)
        ) {
            return this._elevatedHeightPoint;
        }

        this._groundPointForElevatedHeightPoint = this.groundPoint;
        this._heightForElevatedHeightPoint = this._height;
        this._elevatedHeightPoint = getElevatedCesiumPoint(this.groundPoint, this._height);

        return this._elevatedHeightPoint;
    }

    public get groundPoint() {
        return this.heightPointsProvider.getGroundPointForHeight();
    }

    protected getConstrainedHeight(maxHeight: number | undefined, minHeight: number | undefined, height: number): number {
        return Math.max(minHeight ?? Number.MIN_VALUE, Math.min(maxHeight ?? Number.MAX_VALUE, height));
    }
}

interface SerializableCartesian2 {
    x: number;
    y: number;
}

@Injectable()
export class HeightHelperService {
    // NOTE: this is used for performance improvement by leveraging Cesium's "out" params
    private groundPointScreenCoords = Cesium.Cartesian2.ZERO.clone();
    private heightPointScreenCoords = Cesium.Cartesian2.ZERO.clone();
    private groundPointToCursorVector = Cesium.Cartesian2.ZERO.clone();
    private groundPointToHeightVector = Cesium.Cartesian2.ZERO.clone();
    private dragStartCursorToHeightVector = Cesium.Cartesian2.ZERO.clone();
    private translatedCursorScreenCoords = Cesium.Cartesian2.ZERO.clone();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private cesiumScene: any;

    constructor(cesiumService: CesiumService) {
        this.cesiumScene = cesiumService.getScene();
    }

    public calculateHeightFromDragEvent(
        draggedEntity: DraggableHeightEntityBase,
        cursorEndPosition: SerializableCartesian2,
        isDrop: boolean
    ): number {
        let newHeight = draggedEntity.height;

        // NOTE: getting screen space coords of ground point and elevated top/bottom ground point (height point)
        Cesium.SceneTransforms.wgs84ToWindowCoordinates(this.cesiumScene, draggedEntity.groundPoint, this.groundPointScreenCoords);
        Cesium.SceneTransforms.wgs84ToWindowCoordinates(this.cesiumScene, draggedEntity.elevatedHeightPoint, this.heightPointScreenCoords);

        // NOTE: setting of dragStartCursorToHeightVector on drag start
        if (this.dragStartCursorToHeightVector.equals(Cesium.Cartesian2.ZERO)) {
            Cesium.Cartesian2.subtract(this.heightPointScreenCoords, cursorEndPosition, this.dragStartCursorToHeightVector);
        }

        // NOTE: translating current cursor position by dragStartCursorToHeightVector
        Cesium.Cartesian2.add(cursorEndPosition, this.dragStartCursorToHeightVector, this.translatedCursorScreenCoords);

        // NOTE: calculating vectors from ground point to current cursor position and ground point to height point
        Cesium.Cartesian2.subtract(this.translatedCursorScreenCoords, this.groundPointScreenCoords, this.groundPointToCursorVector);
        Cesium.Cartesian2.subtract(this.heightPointScreenCoords, this.groundPointScreenCoords, this.groundPointToHeightVector);

        // NOTE: calculating angle between these vectors
        const groundPointToCursorDistance: number = Cesium.Cartesian2.magnitude(this.groundPointToCursorVector);
        const groundPointToHeightDistance: number = Cesium.Cartesian2.magnitude(this.groundPointToHeightVector);
        const angleBetweenCenterHeightAndCenterCursor = Cesium.Cartesian2.angleBetween(
            this.groundPointToHeightVector,
            this.groundPointToCursorVector
        );

        if (angleBetweenCenterHeightAndCenterCursor < HALF_PI) {
            // NOTE: calculating distance between ground point -> height vector and cursor position (in pixels)
            const cursorDistanceToCenterHeightVector = getDistanceToLine2D(
                this.groundPointScreenCoords,
                this.heightPointScreenCoords,
                this.translatedCursorScreenCoords
            );

            // NOTE: calculating distance between ground point and virtual point laying on ground -> height ray at cursor's vertical axis
            const newDistanceFromCenterToHeight = Math.sqrt(
                Math.abs(groundPointToCursorDistance ** 2 - cursorDistanceToCenterHeightVector ** 2)
            );

            // NOTE: calculating new height by scaling distances to current height
            newHeight = (draggedEntity.height / groundPointToHeightDistance) * newDistanceFromCenterToHeight;
        }

        // NOTE: resetting of dragStartCursorToHeightVector on drag end
        if (isDrop) {
            this.dragStartCursorToHeightVector = Cesium.Cartesian2.ZERO.clone();
        }

        return newHeight;
    }
}
