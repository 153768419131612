import { Injectable } from "@angular/core";

export interface Position {
    accuracy?: number;
    latitude: number;
    longitude: number;
}

@Injectable()
export abstract class GeolocationService {
    public abstract getUserPosition(): Promise<Position>;
    public abstract canTryToGetUserPosition(): Promise<boolean>;
}

@Injectable()
export class WebGeolocationService implements GeolocationService {
    public getUserPosition(): Promise<Position> {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject();

                return;
            }

            navigator.geolocation.getCurrentPosition(
                ({ coords: { accuracy, latitude, longitude } }: GeolocationPosition) => {
                    resolve({ accuracy, latitude, longitude });
                },
                (error: GeolocationPositionError) => reject(error),
                { enableHighAccuracy: true }
            );
        });
    }

    public async canTryToGetUserPosition(): Promise<boolean> {
        return navigator.geolocation && (await navigator.permissions.query({ name: "geolocation" })).state !== "denied";
    }
}
