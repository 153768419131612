import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { AvailableLanguages } from "../../models/language.models";
import { LanguageCode } from "../../modules/i18n/i18n.models";
import { DisplayCountryService } from "../../services/display-country/display-country.service";

const mapLanguageToCountry = {
    pl: "pl",
    en: "gb",
};

interface LanguageComponentState {
    currentProfileLang: LanguageCode | undefined;
}

@Component({
    selector: "dtm-ui-profile-language[currentLanguage]",
    templateUrl: "./profile-language.component.html",
    styleUrls: ["./profile-language.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileLanguageComponent {
    @Input() public set currentLanguage(value: LanguageCode | undefined) {
        this.localStore.patchState({ currentProfileLang: value });
    }

    @Output() public readonly updateLanguage: EventEmitter<LanguageCode> = new EventEmitter<LanguageCode>();

    protected readonly currentProfileLang$ = this.localStore.selectByKey("currentProfileLang").pipe(RxjsUtils.filterFalsy());
    protected readonly AvailableLanguages = AvailableLanguages;
    protected readonly plCountryCodeItem = this.displayCountryService.getCountryByCountryCode(
        mapLanguageToCountry[AvailableLanguages.Polish]
    );
    protected readonly enCountryCodeItem = this.displayCountryService.getCountryByCountryCode(
        mapLanguageToCountry[AvailableLanguages.English]
    );

    constructor(
        private readonly displayCountryService: DisplayCountryService,
        private readonly localStore: LocalComponentStore<LanguageComponentState>
    ) {
        this.localStore.setState({
            currentProfileLang: undefined,
        });
    }
}
