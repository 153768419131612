import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { Checkin, CheckinStatus } from "@dtm-frontend/shared/ui/tactical";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AcEntity, AcLayerComponent, AcNotification, ActionType, Cartesian3, CesiumService, CoordinateConverter } from "@pansa/ngx-cesium";
import turfCenter from "@turf/center";
import { Polygon, points as turfPoints } from "@turf/helpers";
import { Observable, distinctUntilChanged, from, map, shareReplay, switchMap, tap } from "rxjs";
import { CameraHelperService } from "../../services/camera-helper.service";
import { createCesiumDashPattern } from "../../utils/create-cesium-dash-pattern";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

const DEFAULT_FILL_OPACITY = 0.5;
const DEFAULT_AREA_COLOR = "#1477d5"; // $color-secondary-700
const DEFAULT_OUTLINE_DASH_LENGTH = 20;
const DEFAULT_OUTLINE_DASH_PATTERN = createCesiumDashPattern("------------");
const DEFAULT_OUTLINE_WIDTH = 3;
const CHECKIN_AREA_FILL_COLOR = Cesium.Color.fromCssColorString(DEFAULT_AREA_COLOR).withAlpha(DEFAULT_FILL_OPACITY); // $color-secondary-700
const LABELS_SHOWING_DISTANCE_IN_METERS = 20000;

const DEFAULT_OUTLINE_MATERIAL = new Cesium.PolylineDashMaterialProperty({
    color: Cesium.Color.fromCssColorString(DEFAULT_AREA_COLOR),
    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
});

interface CheckinAreaAcEntity extends AcEntity {
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    positions: any;
    centerPosition: Cartesian3 | undefined;
    data: Checkin;
}

interface CheckinAreaLayerComponentState {
    isProcessing: boolean;
    checkinAreaLayer: AcLayerComponent | undefined;
    checkinData: Checkin | undefined;
    checkinsData: Checkin[] | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-map-lib-checkin-area-layer",
    templateUrl: "./checkin-area-layer.component.html",
    styleUrls: ["../../../shared/styles/map-segment-pin.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckinAreaLayerComponent {
    protected readonly Cesium = Cesium;
    protected readonly CHECKIN_AREA_FILL_COLOR = CHECKIN_AREA_FILL_COLOR;
    protected readonly DEFAULT_OUTLINE_MATERIAL = DEFAULT_OUTLINE_MATERIAL;
    protected readonly DEFAULT_OUTLINE_WIDTH = DEFAULT_OUTLINE_WIDTH;

    @ViewChild("checkinAreaLayer") private checkinAreaLayer: AcLayerComponent | undefined;

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set checkin(value: Checkin | undefined) {
        this.localStore.patchState({ checkinData: value });
    }
    @Input() public set checkins(value: Checkin[] | undefined) {
        this.localStore.patchState({ checkinsData: value });
    }

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly checkinData$ = this.localStore.selectByKey("checkinData");
    protected readonly checkinAreaEntities$ = this.initCheckinsAreaEntities();
    protected readonly areLabelsVisible$ = this.cameraHelperService.postRender$.pipe(
        map(() => this.cesiumService.getViewer().camera.positionCartographic.height <= LABELS_SHOWING_DISTANCE_IN_METERS),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    protected CheckinStatus = CheckinStatus;

    constructor(
        private readonly localStore: LocalComponentStore<CheckinAreaLayerComponentState>,
        private readonly cesiumService: CesiumService,
        private readonly coordinateConverter: CoordinateConverter,
        private readonly cameraHelperService: CameraHelperService
    ) {
        this.localStore.setState({
            isProcessing: true,
            checkinAreaLayer: undefined,
            checkinData: undefined,
            checkinsData: undefined,
        });
    }

    protected assertAsCheckin(data: unknown): Checkin {
        return data as Checkin;
    }

    private initCheckinsAreaEntities(): Observable<AcNotification> {
        return this.localStore.selectByKey("checkinsData").pipe(
            RxjsUtils.filterFalsy(),
            tap((checkins) => {
                this.checkinAreaLayer?.getStore().forEach((entity, id) => {
                    if (!checkins.some((checkin) => checkin.id === id)) {
                        this.checkinAreaLayer?.remove(id);
                    }
                });
                this.cesiumService.getScene().requestRender();
            }),
            switchMap((checkins) =>
                from(checkins).pipe(
                    map((checkin) => {
                        const area = checkin.geometry as Polygon;
                        if (!area) {
                            return;
                        }

                        const areaPoints = turfPoints(area.coordinates[0]);
                        const center = turfCenter(areaPoints);
                        const centerCartesian: Cartesian3 = Cesium.Cartographic.toCartesian(
                            this.coordinateConverter.degreesToCartographic(center.geometry.coordinates[0], center.geometry.coordinates[1])
                        );

                        const entity: CheckinAreaAcEntity = {
                            id: checkin.id,
                            positions: Cesium.Cartesian3.fromDegreesArray(area.coordinates[0].flat()),
                            centerPosition: centerCartesian,
                            data: checkin,
                        };

                        return {
                            entity,
                            actionType: ActionType.ADD_UPDATE,
                            id: entity.id,
                        };
                    })
                )
            ),
            RxjsUtils.filterFalsy(),
            tap(() => this.cesiumService.getScene().requestRender())
        );
    }
}
