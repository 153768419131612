import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";

export interface HttpErrorResponseBody extends HttpErrorResponse {
    error: ErrorResponseBody;
}

export interface ErrorResponseBody {
    args?: Record<string, string>;
    error: string;
    fieldErrors?: {
        args?: Record<string, string>;
        code?: string;
        fieldName: string;
        fieldValue: string | number;
    }[];
    generalMessage?: string;
    status: HttpStatusCode;
    timestamp: string;
}
