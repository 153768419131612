import { IconName } from "@dtm-frontend/shared/ui";

export interface NotificationsError {
    type: NotificationsErrorType;
}

export enum NotificationsErrorType {
    Unknown = "Unknown",
}

export interface Notification<Type = string, Payload = unknown> {
    createdAt: string;
    id: string;
    type: Type;
    read: boolean;
    userId: string;
    payload: Payload;
}

export interface NotificationDisplay extends SharedNotificationDisplayProps {
    image?: string;
    text: string;
    title: string;
    redirectUrl?: string;
    contextId?: string;
}

export interface SharedNotificationDisplayProps {
    id: string;
    icon?: IconName;
    date: string;
    isRead: boolean;
}

export interface NotificationsListWithPages {
    content: Notification[];
    pageDetails: PageDetails;
}

export interface PageDetails {
    totalElements: number;
    size: number;
    number: number;
    isEmpty: boolean;
    isLast: boolean;
}
