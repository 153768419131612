export enum MapActionType {
    None = "none",
    ManualCoordinatesInputCylinder = "manualCoordinatesInputCylinder",
    ManualCoordinatesInputPolylineCorridor = "manualCoordinatesInputPolylineCorridor",
    ManualWidthInputPolylineCorridor = "ManualWidthInputPolylineCorridor",
    DrawCylinder = "drawCylinder",
    DrawTakeoffRunway = "drawTakeoffRunway",
    DrawLandingRunway = "drawLandingRunway",
    DrawPolyline = "drawPolyline",
    DrawPolylineCorridor = "drawPolylineCorridor",
    DrawPrism = "drawPrism",
    DrawAssistedTakeoffRunway = "drawAssistedTakeoffRunway",
    DrawAssistedLandingRunway = "drawAssistedLandingRunway",
    RemoveContent = "removeContent",
    ShowEntireContent = "showEntireContent",
    RemoveLastPoint = "removeLastPoint",
    CancelDrawing = "cancelDrawing",
    FinishDrawing = "finishDrawing",
}
