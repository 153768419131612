import { InjectionToken } from "@angular/core";

export enum MapLayer {
    Default = "default",
    SoraBoxes = "soraBoxes",
    Obstacles = "obstacles",
}

export interface SharedMapEndpoints {
    geoServerEndpoint: string;
    layersPrefix?: string;
    layers?: {
        [key in MapLayer]: string[];
    };
}

export const SHARED_MAP_ENDPOINTS = new InjectionToken<SharedMapEndpoints>("Shared map endpoints");
export const AZURE_MAPS_SUBSCRIPTION_KEY = new InjectionToken<string>("Azure Maps subscription key");
