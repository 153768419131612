import { defer } from "rxjs";

export const quillConfig = {
    modules: {
        syntax: false,
        blotFormatter: {},
        keyboard: { bindings: { tab: false } },
    },
    customModules: [
        {
            path: "modules/blotFormatter",
            implementation: defer(() => import("quill-blot-formatter").then((module) => module.default)),
        },
    ],
};
