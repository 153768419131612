<ng-container
    *ngrxLet="{
        isAuthorityAcceptationConfirmed: isAuthorityAcceptationConfirmed$,
        missionType: missionType$,
        phase: phase$
    } as vm"
>
    <dtm-mission-mission-status-badge class="status-badge" *ngIf="vm.phase" [status]="vm.phase"></dtm-mission-mission-status-badge>

    <div class="chip light message" *ngIf="vm.isAuthorityAcceptationConfirmed !== undefined">
        <dtm-ui-icon [name]="vm.isAuthorityAcceptationConfirmed ? 'mail-open' : 'complex-mail-notification'"></dtm-ui-icon>
    </div>

    <div class="chip light mission-type" *ngIf="vm.missionType">
        <dtm-ui-icon [name]="vm.missionType === MissionType.BVLOS ? 'eye-off' : 'eye'"></dtm-ui-icon>
        {{ "dtmSharedMission.badgeGroup.missionTypeLabel" | transloco : { value: vm.missionType } }}
    </div>
</ng-container>
