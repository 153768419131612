<div class="outer-container">
    <div class="container">
        <div class="page-size" *ngIf="!hidePageSize">
            <div class="page-size-label">{{ "dtmUi.pagination.pageScopeLabel" | transloco }}</div>

            <dtm-ui-select-field [value]="pageSize" (selectionChange)="_changePageSize($event.value)" [isClearable]="false">
                <dtm-ui-select-option *ngFor="let pageSizeOption of pageSizeOptions" [value]="pageSizeOption">
                    {{ pageSizeOption }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>
        </div>

        <div class="range-actions">
            <div *ngrxLet="pageRange$ as pageRange" class="range-label" aria-live="polite">
                <ng-container *ngIf="areResultsAvailable$ | ngrxPush; else noResultsTemplate">
                    {{
                        "dtmUi.pagination.rangeLabel"
                            | transloco : { startIndex: pageRange?.startIndex, endIndex: pageRange?.endIndex, length: pageRange?.length }
                    }}
                </ng-container>

                <ng-template #noResultsTemplate>
                    {{ "dtmUi.pagination.zeroRangeLabel" | transloco }}
                </ng-template>
            </div>

            <button
                type="button"
                class="button-icon"
                (click)="previousPage()"
                [attr.aria-label]="'dtmUi.pagination.previousPageLabel' | transloco"
                [matTooltip]="'dtmUi.pagination.previousPageLabel' | transloco"
                [matTooltipDisabled]="_previousButtonsDisabled()"
                [matTooltipPosition]="'above'"
                [disabled]="_previousButtonsDisabled()"
            >
                <dtm-ui-icon name="arrow-left"></dtm-ui-icon>
            </button>
            <button
                type="button"
                class="button-icon"
                (click)="nextPage()"
                [attr.aria-label]="'dtmUi.pagination.nextPageLabel' | transloco"
                [matTooltip]="'dtmUi.pagination.nextPageLabel' | transloco"
                [matTooltipDisabled]="_nextButtonsDisabled()"
                [matTooltipPosition]="'above'"
                [disabled]="_nextButtonsDisabled()"
            >
                <dtm-ui-icon name="arrow-right"></dtm-ui-icon>
            </button>
        </div>
    </div>
</div>
