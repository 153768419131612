import { Pipe, PipeTransform } from "@angular/core";
import { ellipsifyText } from "./ellipsify-text";

/**
 * Shortens a given text to a maximum length and adds an ellipsis at the end of it if the text exceeds the maximum length.
 * @param textToCut - The text to cut.
 * @param maxLength - The maximum length of the text.
 * @param ending - The string to add at the end of the text if it exceeds the maximum length. Default: "..."
 * @returns The shortened text with an ellipsis at the end if it exceeds the maximum length, otherwise the original text.
 */
@Pipe({ name: "ellipsifyText" })
export class EllipsifyTextPipe implements PipeTransform {
    public transform(textToCut: string = "", maxLength: number = 100, ending: string = "..."): string {
        return ellipsifyText(textToCut, maxLength, ending);
    }
}
