import { Injectable } from "@angular/core";
import { ArrayUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
    CesiumEvent,
    CesiumService,
    DisposableObservable,
    EventRegistrationInput,
    EventResult,
    MapEventsManagerService,
    PickOptions,
} from "@pansa/ngx-cesium";
import { BehaviorSubject, Observable, Subject, fromEvent } from "rxjs";
import { filter, map, shareReplay, takeUntil, tap, withLatestFrom } from "rxjs/operators";
import { MapActionType, MapEntitiesEditorService } from "../entity-editors/map-entities-editor.service";

export enum CesiumPointerType {
    None = "unset",
    HeightHandle = "n-resize",
    Move = "url(/assets/cursors/drag-move.svg) 9.5 9.5, move",
    Select = "url(/assets/cursors/hand.svg) 0 7, pointer",
    DrawCylinder = "url(/assets/cursors/pen-nib-oval.svg), auto",
    DrawTakeoffRunway = "url(/assets/cursors/pen-nib-takeoff.svg), auto",
    DrawLandingRunway = "url(/assets/cursors/pen-nib-land.svg), auto",
    DrawPolyline = "url(/assets/cursors/pen-nib-line.svg), auto",
    DrawPrism = "url(/assets/cursors/pen-nib-prism.svg), auto",
    Info = "help",
}

const DRILL_PICK_LIMIT = 100; // NOTE: this is needed to prevent infinite loop due to Cesium bug when drillpicking outside of viewport
const DRILL_PICK_BUFFER_SIZE = 5;
const WHOLE_MAP_ID = "MAP";
const EVENT_PRIORITY = 100;

export enum ViewerContainerEventType {
    MouseOut = "mouseout",
}

@UntilDestroy()
@Injectable()
export class CesiumPointerManagerService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private cesiumViewer: any;
    private pickEvents = new Map<CesiumEvent, Observable<EventResult>>();
    private pointerType = new Map<string, CesiumPointerType>();
    // ...

    private pickEventsReferenceCounterSubject = new BehaviorSubject<Record<CesiumEvent, number | undefined>>({
        [CesiumEvent.LONG_LEFT_PRESS]: undefined,
        [CesiumEvent.LONG_RIGHT_PRESS]: undefined,
        [CesiumEvent.LONG_MIDDLE_PRESS]: undefined,
        [CesiumEvent.LEFT_CLICK_DRAG]: undefined,
        [CesiumEvent.RIGHT_CLICK_DRAG]: undefined,
        [CesiumEvent.MIDDLE_CLICK_DRAG]: undefined,
    });

    constructor(
        cesiumService: CesiumService,
        private readonly mapEventsManager: MapEventsManagerService,
        private readonly mapEntitiesEditorService: MapEntitiesEditorService
    ) {
        this.cesiumViewer = cesiumService.getViewer();

        this.watchForMapActionsChange();
    }

    private watchForMapActionsChange() {
        // NOTE: Do not extract this const outside of this class as it will cause unreasonable errors :)
        // TODO: check if this is still needed in angular 16 with new TypeScript version DTM-3352
        const mapActionToCesiumPointerMap = new Map<MapActionType, CesiumPointerType>([
            [MapActionType.DrawCylinder, CesiumPointerType.DrawCylinder],
            [MapActionType.DrawTakeoffRunway, CesiumPointerType.DrawTakeoffRunway],
            [MapActionType.DrawLandingRunway, CesiumPointerType.DrawLandingRunway],
            [MapActionType.DrawPolyline, CesiumPointerType.DrawPolyline],
            [MapActionType.DrawPolylineCorridor, CesiumPointerType.DrawPolyline],
            [MapActionType.DrawPrism, CesiumPointerType.DrawPrism],
            [MapActionType.DrawAssistedLandingRunway, CesiumPointerType.DrawLandingRunway],
            [MapActionType.DrawAssistedTakeoffRunway, CesiumPointerType.DrawTakeoffRunway],
        ]);

        this.mapEntitiesEditorService.activeMapAction$.pipe(untilDestroyed(this)).subscribe((action) => {
            const pointerType = mapActionToCesiumPointerMap.get(action);

            this.setPointerType(WHOLE_MAP_ID, pointerType ?? CesiumPointerType.None);
        });
    }

    public setPointerType(entityId: string, pointerType: CesiumPointerType) {
        this.pointerType.set(entityId, pointerType);
        this.refreshCursor();
    }

    public removePointer(entityId: string) {
        this.pointerType.delete(entityId);
        this.refreshCursor();
    }

    private refreshCursor() {
        for (const pointer of this.pointerType.values()) {
            if (pointer !== CesiumPointerType.None) {
                this.cesiumViewer.container.style.cursor = pointer;

                return;
            }
        }

        this.cesiumViewer.container.style.cursor = CesiumPointerType.None;
    }

    public addViewerContainerEventHandler(input: ViewerContainerEventType): DisposableObservable<void> {
        const disposableSubject = new Subject<void>();
        const observable = fromEvent(this.cesiumViewer.container, input).pipe(
            takeUntil(disposableSubject),
            map(() => undefined)
        ) as DisposableObservable<void>;

        observable.dispose = () => {
            disposableSubject.next();
            disposableSubject.complete();
        };

        return observable;
    }

    /**
     * This method wraps mapEventsManager.register method to ensure optimal usage of map features picking
     */
    public addEventHandler(input: EventRegistrationInput): DisposableObservable<EventResult> {
        if (input.pick === undefined || input.pick === PickOptions.NO_PICK || input.pick === PickOptions.PICK_ONE) {
            return this.mapEventsManager.register({ ...input, priority: EVENT_PRIORITY });
        }

        const originalEvent = this.mapEventsManager.register({
            ...input,
            pick: PickOptions.NO_PICK,
            entityType: undefined,
            priority: EVENT_PRIORITY,
        });
        const pickEvent = this.getPickEvent(input);
        const result = originalEvent.pipe(
            withLatestFrom(pickEvent),
            filter(([, pickResult]) => pickResult.cesiumEntities !== null || input.entityType === undefined),
            map(
                ([eventResult, pickResult]): EventResult => ({
                    ...eventResult,
                    cesiumEntities:
                        input.pick === PickOptions.PICK_FIRST && pickResult.cesiumEntities?.length > 0
                            ? [pickResult.cesiumEntities[0]]
                            : pickResult.cesiumEntities,
                    entities:
                        input.pick === PickOptions.PICK_FIRST && pickResult.entities?.length > 0
                            ? this.filterEntities([pickResult.entities[0]], input.entityType, input.pickFilter)
                            : this.filterEntities(pickResult.entities, input.entityType, input.pickFilter),
                })
            ),
            filter((eventResult) => eventResult.entities.length > 0 || (input.entityType === undefined && !input.pickFilter))
        ) as DisposableObservable<EventResult>;

        result.dispose = () => {
            originalEvent.dispose();
            this.pickEventsReferenceCounterSubject.next({
                ...this.pickEventsReferenceCounterSubject.value,
                [input.event]: (this.pickEventsReferenceCounterSubject.value[input.event] ?? 0) - 1,
            });
        };

        return result;
    }

    private getPickEvent(input: EventRegistrationInput): Observable<EventResult> {
        if (this.pickEvents.has(input.event) && this.pickEventsReferenceCounterSubject.value[input.event]) {
            this.pickEventsReferenceCounterSubject.next({
                ...this.pickEventsReferenceCounterSubject.value,
                [input.event]: (this.pickEventsReferenceCounterSubject.value[input.event] ?? 0) + 1,
            });

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return this.pickEvents.get(input.event)!;
        }

        this.pickEventsReferenceCounterSubject.next({
            ...this.pickEventsReferenceCounterSubject.value,
            [input.event]: 1,
        });

        const event = this.mapEventsManager.register({
            event: input.event,
            pick: PickOptions.PICK_ALL,
            pickConfig: {
                pickHeight: DRILL_PICK_BUFFER_SIZE,
                pickWidth: DRILL_PICK_BUFFER_SIZE,
                drillPickLimit: DRILL_PICK_LIMIT,
            },
            priority: EVENT_PRIORITY,
        });

        this.pickEvents.set(
            input.event,
            event.pipe(
                untilDestroyed(this),
                takeUntil(
                    this.pickEventsReferenceCounterSubject.pipe(filter((referencesCount) => (referencesCount[input.event] ?? 0) < 1))
                ),
                tap({
                    complete: () => {
                        event.dispose();
                        this.pickEvents.delete(input.event);
                        this.pickEventsReferenceCounterSubject.next({
                            ...this.pickEventsReferenceCounterSubject.value,
                            [input.event]: undefined,
                        });
                    },
                }),
                shareReplay({ refCount: true, bufferSize: 1 })
            )
        );

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.pickEvents.get(input.event)!;
    }

    private filterEntities<T>(
        entities: T[] | null,
        entityType: EventRegistrationInput["entityType"],
        predicate: EventRegistrationInput["pickFilter"]
    ): T[] {
        if (!entities) {
            return [];
        }

        let result = [...entities];

        if (entityType) {
            result = result.filter((entity) => entity instanceof entityType);
        }

        if (predicate) {
            result = result.filter((entity) => predicate(entity));
        }

        return ArrayUtils.unique(result);
    }
}
