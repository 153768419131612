import { Injectable } from "@angular/core";
import { MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, Subject, finalize, takeUntil } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { MissionFilters, MissionProcessingPhase, PlannedMission, PlannedMissionError } from "../models/planned-missions.models";
import { PlannedMissionApiService } from "../services/planned-mission-api.service";
import { PlannedMissionsActions } from "./planned-missions.actions";

const filterByPhase = (missionList: PlannedMission[], phase: MissionProcessingPhase): PlannedMission[] =>
    missionList.filter((mission) => mission.phase === phase);

interface PlannedMissionsStateModel {
    acceptedMissions: PlannedMission[] | undefined;
    rejectedMissions: PlannedMission[] | undefined;
    waitingMissions: PlannedMission[] | undefined;
    missionPlanRoute: MissionPlanRoute | undefined;
    missionListError: PlannedMissionError | undefined;
    isProcessing: boolean;
    isPlanRouteProcessing: boolean;
    missionPlanRouteId: string | undefined;
    missionPlanRouteError: PlannedMissionError | undefined;
    missionFilters: MissionFilters | undefined;
    areNewMissionsAvailable: boolean;
    phaseChangeMissionError: PlannedMissionError | undefined;
}

const defaultState: PlannedMissionsStateModel = {
    missionListError: undefined,
    waitingMissions: undefined,
    rejectedMissions: undefined,
    missionPlanRoute: undefined,
    acceptedMissions: undefined,
    isProcessing: false,
    missionPlanRouteId: undefined,
    isPlanRouteProcessing: false,
    missionPlanRouteError: undefined,
    missionFilters: undefined,
    areNewMissionsAvailable: false,
    phaseChangeMissionError: undefined,
};

@State<PlannedMissionsStateModel>({
    name: "plannedMissions",
    defaults: defaultState,
})
@Injectable()
export class PlannedMissionsState {
    private readonly stopPlannedMission$ = new Subject<void>();

    constructor(private readonly missionApi: PlannedMissionApiService) {}

    @Selector()
    public static waitingMissions(state: PlannedMissionsStateModel): PlannedMission[] | undefined {
        return state.waitingMissions;
    }

    @Selector()
    public static rejectedMissions(state: PlannedMissionsStateModel): PlannedMission[] | undefined {
        return state.rejectedMissions;
    }

    @Selector()
    public static acceptedMissions(state: PlannedMissionsStateModel): PlannedMission[] | undefined {
        return state.acceptedMissions;
    }

    @Selector()
    public static isProcessing(state: PlannedMissionsStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static isPlanRouteProcessing(state: PlannedMissionsStateModel): boolean {
        return state.isPlanRouteProcessing;
    }

    @Selector()
    public static selectedMissionPlanRoute(state: PlannedMissionsStateModel): MissionPlanRoute | undefined {
        return state.missionPlanRoute;
    }

    @Selector()
    public static missionPlanRouteError(state: PlannedMissionsStateModel): PlannedMissionError | undefined {
        return state.missionPlanRouteError;
    }

    @Selector()
    public static areNewMissionsAvailable(state: PlannedMissionsStateModel): boolean {
        return state.areNewMissionsAvailable;
    }

    @Selector()
    public static phaseChangeMissionError(state: PlannedMissionsStateModel): PlannedMissionError | undefined {
        return state.phaseChangeMissionError;
    }

    @Action(PlannedMissionsActions.PlannedMissionsWatch)
    public plannedMissionWatch(context: StateContext<PlannedMissionsStateModel>) {
        return this.missionApi.startPlannedMissionsWatch().pipe(
            tap(() => {
                const filters = context.getState().missionFilters;

                context.patchState({ areNewMissionsAvailable: true });
                context.dispatch(new PlannedMissionsActions.GetMissionList(filters));
            }),
            takeUntil(this.stopPlannedMission$)
        );
    }

    @Action(PlannedMissionsActions.StopPlannedMissionsWatch)
    public stopPlannedMissionsWatch() {
        this.stopPlannedMission$.next();
        this.stopPlannedMission$.complete();
    }

    @Action(PlannedMissionsActions.UpdateMissionsByPhase)
    public updateMissionsByPhase(
        context: StateContext<PlannedMissionsStateModel>,
        { missionList, phase }: PlannedMissionsActions.UpdateMissionsByPhase
    ) {
        switch (phase) {
            case MissionProcessingPhase.Waiting:
                context.patchState({ waitingMissions: filterByPhase(missionList, MissionProcessingPhase.Waiting) });
                break;
            case MissionProcessingPhase.Rejected:
                context.patchState({ rejectedMissions: filterByPhase(missionList, MissionProcessingPhase.Rejected) });
                break;
            case MissionProcessingPhase.Accepted:
                context.patchState({ acceptedMissions: filterByPhase(missionList, MissionProcessingPhase.Accepted) });
                break;
        }
    }

    @Action(PlannedMissionsActions.GetMissionList, { cancelUncompleted: true })
    public getMissionPlansList(context: StateContext<PlannedMissionsStateModel>, action: PlannedMissionsActions.GetMissionList) {
        context.patchState({ isProcessing: true, missionListError: undefined, missionPlanRoute: undefined, missionPlanRouteId: undefined });

        if (action.missionFilters) {
            context.patchState({ missionFilters: action.missionFilters });
        }

        return this.missionApi.getMissionList(action.missionFilters).pipe(
            tap((missionList) => {
                if (!action.shouldFilterMission) {
                    context.patchState({
                        waitingMissions: filterByPhase(missionList, MissionProcessingPhase.Waiting),
                        rejectedMissions: filterByPhase(missionList, MissionProcessingPhase.Rejected),
                        acceptedMissions: filterByPhase(missionList, MissionProcessingPhase.Accepted),
                    });

                    return;
                }

                const filterMissionPhase = action.missionFilters?.phase;

                if (!filterMissionPhase) {
                    return;
                }

                context.dispatch(new PlannedMissionsActions.UpdateMissionsByPhase(missionList, filterMissionPhase));
            }),
            catchError((missionListError) => {
                context.patchState({
                    missionListError,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(PlannedMissionsActions.ClearMissionData)
    public clearMissionData(context: StateContext<PlannedMissionsStateModel>) {
        context.patchState({
            missionPlanRoute: undefined,
            missionPlanRouteId: undefined,
        });
    }

    @Action(PlannedMissionsActions.GetMissionRoute)
    public getMissionRoute(context: StateContext<PlannedMissionsStateModel>, action: PlannedMissionsActions.GetMissionRoute) {
        const previousRouteId = context.getState().missionPlanRouteId;

        context.patchState({ missionPlanRoute: undefined });

        if (previousRouteId === action.routeId) {
            return;
        }

        context.patchState({ isPlanRouteProcessing: true, missionPlanRouteId: action.routeId });

        return this.missionApi.getMissionRoute(action.routeId).pipe(
            tap((route) => {
                context.patchState({ missionPlanRoute: route });
            }),
            catchError((missionPlanRouteError) => {
                context.patchState({ missionPlanRouteError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isPlanRouteProcessing: false }))
        );
    }

    @Action(PlannedMissionsActions.ChangeMissionPhase)
    public changeMissionPhase(
        context: StateContext<PlannedMissionsStateModel>,
        { phasePayloadData }: PlannedMissionsActions.ChangeMissionPhase
    ) {
        context.patchState({ isProcessing: true, phaseChangeMissionError: undefined });

        return this.missionApi.changeMissionPhase(phasePayloadData).pipe(
            catchError((changePhaseError) => {
                context.patchState({ phaseChangeMissionError: changePhaseError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
