import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function mustMatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        const control = formGroup.get(controlName);
        const matchingControl = formGroup.get(matchingControlName);

        if (!control || !matchingControl || (control?.errors && !matchingControl.errors?.mustMatch)) {
            return null;
        }

        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });

            return { mustMatch: true };
        }
        matchingControl.setErrors(null);

        return null;
    };
}
