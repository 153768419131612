import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { AircraftEntity, AircraftType } from "@dtm-frontend/shared/ui";
import { EmergencyType } from "@dtm-frontend/shared/ui/tactical";
import { LocalComponentStore, MILLISECONDS_IN_MINUTE, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";

interface FlightPinComponentState {
    aircraftEntity: AircraftEntity | undefined;
    isMainAircraft: boolean;
    isInVerificationMode: boolean;
    violationClass: NgClass["ngClass"];
    name: string | undefined;
    emergency: EmergencyType | undefined;
    isHeightVisible: boolean;
    isInfoVisible: boolean;
    selectedTrackerId: string | undefined;
    isTrackerSelectionEnabled: boolean;
}

// NOTE: Backend will calculate height only for 10 minutes and after that user needs to ask again
const HEIGHT_ACTIVITY_TIME_IN_MINUTES = 10;

@UntilDestroy()
@Component({
    selector: "dtm-map-flight-pin[aircraftEntity]",
    templateUrl: "./flight-pin.component.html",
    styleUrls: ["./flight-pin.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightPinComponent implements OnDestroy {
    @Input()
    public set aircraftEntity(value: AircraftEntity | undefined) {
        this.localStore.patchState({ aircraftEntity: value });
    }
    @Input()
    public set isMainAircraft(value: BooleanInput) {
        const isMainAircraft = coerceBooleanProperty(value);
        this.localStore.patchState({ isMainAircraft, isInfoVisible: isMainAircraft });
    }
    @Input()
    public set isInVerificationMode(value: BooleanInput) {
        this.localStore.patchState({ isInVerificationMode: coerceBooleanProperty(value) });
    }
    @Input()
    public set violationClass(value: NgClass["ngClass"]) {
        this.localStore.patchState({ violationClass: value });
    }
    @Input()
    public set name(value: string | undefined) {
        this.localStore.patchState({ name: value });
    }
    @Input()
    public set emergency(value: EmergencyType | undefined) {
        this.localStore.patchState({ emergency: value });
    }
    @Input()
    public set selectedTrackerId(value: string | undefined) {
        this.localStore.patchState({ selectedTrackerId: value });
    }
    @Input()
    public set isTrackerSelectionEnabled(value: BooleanInput) {
        this.localStore.patchState({ isTrackerSelectionEnabled: coerceBooleanProperty(value) });
    }
    @Output() public flightPositionUpdatesEnrich = this.localStore.selectByKey("isHeightVisible").pipe(RxjsUtils.filterFalsy());
    @Output() public trackerSelected = new EventEmitter<string | undefined>();

    protected readonly AircraftType = AircraftType;
    protected readonly EmergencyType = EmergencyType;

    protected readonly aircraftEntity$ = this.localStore.selectByKey("aircraftEntity");
    protected readonly isMainAircraft$ = this.localStore.selectByKey("isMainAircraft");
    protected readonly isInVerificationMode$ = this.localStore.selectByKey("isInVerificationMode");
    protected readonly violationClass$ = this.localStore.selectByKey("violationClass");
    protected readonly name$ = this.localStore.selectByKey("name");
    protected readonly emergency$ = this.localStore.selectByKey("emergency");
    protected readonly isHeightVisible$ = this.localStore.selectByKey("isHeightVisible");
    protected readonly isInfoVisible$ = this.localStore.selectByKey("isInfoVisible");
    protected readonly selectedTrackerId$ = this.localStore.selectByKey("selectedTrackerId");
    protected readonly isTrackerSelectionEnabled$ = this.localStore.selectByKey("isTrackerSelectionEnabled");

    private heightVisibilityTimer: number | undefined;

    constructor(protected readonly localStore: LocalComponentStore<FlightPinComponentState>) {
        localStore.setState({
            aircraftEntity: undefined,
            isMainAircraft: false,
            isInVerificationMode: false,
            violationClass: "",
            name: undefined,
            emergency: undefined,
            isHeightVisible: false,
            isInfoVisible: false,
            selectedTrackerId: undefined,
            isTrackerSelectionEnabled: false,
        });
    }

    public ngOnDestroy(): void {
        clearTimeout(this.heightVisibilityTimer);
    }

    protected toggleHeightVisibility(): void {
        const isHeightVisible = this.localStore.selectSnapshotByKey("isHeightVisible");

        if (!isHeightVisible) {
            clearTimeout(this.heightVisibilityTimer);
            this.heightVisibilityTimer = setTimeout(
                () => this.localStore.patchState({ isHeightVisible: false }),
                HEIGHT_ACTIVITY_TIME_IN_MINUTES * MILLISECONDS_IN_MINUTE
            ) as unknown as number;
        }

        this.localStore.patchState({ isHeightVisible: !isHeightVisible });
    }

    protected toggleInfoVisibility(): void {
        this.localStore.patchState(({ isInfoVisible }) => ({
            isInfoVisible: !isInfoVisible,
        }));
    }

    protected selectTracker(): void {
        const trackerId = this.localStore.selectSnapshotByKey("aircraftEntity")?.trackerIdentifier;

        this.trackerSelected.emit(trackerId);
    }
}
