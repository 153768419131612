export namespace AuthActions {
    export class LogIn {
        public static readonly type = "[Auth] LogIn";

        constructor(public username: string, public password: string) {}
    }

    export class UpdateToken {
        public static readonly type = "[Auth] Update token";
    }

    export class Logout {
        public static readonly type = "[Auth] Logout";
    }

    export class GoToLoginPage {
        public static readonly type = "[Auth] Go to login page";

        constructor(public redirectPath?: string) {}
    }

    export class GoToNotAuthorizedPage {
        public static readonly type = "[Auth] Go to not authorized page";
    }
}
