import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { DisplayVersionApiService } from "../display-version-api.service";
import { VersionData, VersionDataError } from "../utils/version-data.model";
import { VersionActions } from "./version.actions";

export interface VersionStateModel {
    versionData: VersionData | undefined;
    versionDataError: VersionDataError | undefined;
}

@State<VersionStateModel>({
    name: "versionState",
    defaults: {
        versionData: undefined,
        versionDataError: undefined,
    },
})
@Injectable()
export class VersionState {
    constructor(private readonly displayVersionApiService: DisplayVersionApiService) {}

    @Selector()
    public static versionData(state: VersionStateModel): VersionData | undefined {
        return state.versionData;
    }

    @Selector()
    public static versionDataError(state: VersionStateModel): VersionDataError | undefined {
        return state.versionDataError;
    }

    @Action(VersionActions.GetVersionData)
    public getVersionData(context: StateContext<VersionStateModel>) {
        return this.displayVersionApiService.getVersionData().pipe(
            tap((result) => {
                context.patchState({ versionData: result, versionDataError: undefined });
            }),
            catchError((error) => {
                context.patchState({ versionDataError: error });

                return EMPTY;
            })
        );
    }
}
