import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { MissionProcessingPhaseExtended, MissionType } from "../../models/mission.models";

interface BadgeGroupComponentState {
    missionType: MissionType | undefined;
    phase: MissionProcessingPhaseExtended | undefined;
    isAuthorityAcceptationConfirmed: boolean | undefined;
}

@Component({
    selector: "dtm-mission-badge-group",
    templateUrl: "./badge-group.component.html",
    styleUrls: ["./badge-group.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class BadgeGroupComponent {
    @Input() public set missionType(value: MissionType | undefined) {
        this.localStore.patchState({ missionType: value });
    }

    @Input() public set phase(value: MissionProcessingPhaseExtended | undefined) {
        this.localStore.patchState({ phase: value });
    }

    @Input() public set isAuthorityAcceptationConfirmed(value: BooleanInput | undefined) {
        this.localStore.patchState({ isAuthorityAcceptationConfirmed: value === undefined ? undefined : coerceBooleanProperty(value) });
    }

    protected readonly missionType$ = this.localStore.selectByKey("missionType");
    protected readonly phase$ = this.localStore.selectByKey("phase");
    protected readonly isAuthorityAcceptationConfirmed$ = this.localStore.selectByKey("isAuthorityAcceptationConfirmed");

    protected readonly MissionType = MissionType;

    constructor(private readonly localStore: LocalComponentStore<BadgeGroupComponentState>) {
        this.localStore.setState({
            missionType: undefined,
            phase: undefined,
            isAuthorityAcceptationConfirmed: undefined,
        });
    }
}
