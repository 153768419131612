<div class="main-container" *ngrxLet="filtersCount$; let filtersCount">
    <dtm-ui-expandable-panel [isExpanded]="!!(isExpanded$ | ngrxPush)" [hasHeaderSeparator]="false">
        <div headerSlot class="title">
            <dtm-ui-icon name="filter"></dtm-ui-icon>
            <h2>
                {{ "dtmUi.filters.filtersHeader" | transloco }}
                <ng-container *ngIf="filtersCount">({{ filtersCount }})</ng-container>
            </h2>
        </div>
        <ng-content></ng-content>
    </dtm-ui-expandable-panel>

    <ng-content select="[selectedFiltersDisplaySlot]"></ng-content>
</div>
