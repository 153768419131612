import { convertStringPropertiesToDate } from "./convert-string-properties-to-date";
import { convertToMap } from "./convert-to-map";
import { deepClone } from "./deep-clone";
import { mapObjectValues } from "./map-object-values";
import { removeNullishProperties } from "./remove-nullish-properties";

export const ObjectUtils = {
    mapObjectValues,
    cloneDeep: deepClone,
    removeNullishProperties,
    convertToMap,
    convertStringPropertiesToDate,
};
