import { Pipe, PipeTransform } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { GrlProperty } from "../models/mission.models";

function convertGrl(finalGrl: number) {
    return {
        1: GrlProperty.RuralArea,
        2: GrlProperty.SparselyPopulatedArea,
        3: GrlProperty.SuburbanArea,
        4: GrlProperty.UrbanArea,
        5: GrlProperty.DenselyPopulatedUrbanArea,
        6: GrlProperty.PeopleGathering,
        [-1]: GrlProperty.PeopleGathering,
    }[finalGrl];
}

@Pipe({
    name: "grlLevel",
})
export class GrlLevelPipe implements PipeTransform {
    constructor(private readonly translocoService: TranslocoService) {}

    public transform(grl: number | undefined): string | undefined {
        if (grl === undefined || grl === null) {
            return undefined;
        }

        return this.translocoService.translate("dtmUi.grlLabel", { grl: convertGrl(grl) });
    }
}

@Pipe({
    name: "controlledAreaGrlLevel",
})
export class ControlledAreaGrlLevelPipe implements PipeTransform {
    constructor(private readonly translocoService: TranslocoService) {}

    public transform(grl: number | undefined): string | undefined {
        if (grl === undefined || grl === null) {
            return undefined;
        }

        return this.translocoService.translate("dtmUi.controlledAreaGrlLabel", { grl: convertGrl(grl) });
    }
}
