import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
    ApplicationListItemStatus,
    ApplicationType,
    DssUserRoles,
    FlightZoneApplicationListItem,
    FlightZoneApplicationPurpose,
    FlightZoneErrorType,
    FlightZoneListFilters,
} from "@dtm-frontend/dss-shared-lib";
import { AuthState } from "@dtm-frontend/shared/auth";
import { KmlExporterService } from "@dtm-frontend/shared/map";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, switchMap, tap } from "rxjs";
import { map } from "rxjs/operators";
import { FlightZoneListsActions } from "../../../state/flight-zone-lists.actions";
import { FlightZoneListsState } from "../../../state/flight-zone-lists.state";
import { FlightZoneState } from "../../../state/flight-zone.state";

@UntilDestroy()
@Component({
    selector: "dss-client-lib-submitted-applications-list-container",
    templateUrl: "./submitted-applications-list-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmittedApplicationsListContainerComponent implements OnInit {
    protected readonly initialFilters: FlightZoneListFilters;
    protected readonly flightZonePurposes: FlightZoneApplicationPurpose[] = Object.values(FlightZoneApplicationPurpose);
    protected readonly statuses: ApplicationListItemStatus[] = [
        ApplicationListItemStatus.ForApproval,
        ApplicationListItemStatus.WaitingForPublication,
        ApplicationListItemStatus.Correction,
        ApplicationListItemStatus.Rejected,
    ];

    protected readonly isProcessing$ = this.store.select(FlightZoneState.isProcessing);
    protected readonly submittedApplicationsList$ = this.store.select(FlightZoneListsState.submittedApplicationsList);
    protected readonly hasListDataRetrievalError$ = this.store.select(FlightZoneListsState.hasListDataRetrievalError);
    protected readonly hasSupervisorPermissions$ = this.store
        .select(AuthState.roles)
        .pipe(map((roles) => !!roles?.find((role) => role === DssUserRoles.InstitutionSupervisor)));

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly kmlExporterService: KmlExporterService,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService
    ) {
        this.initialFilters = this.route.snapshot.queryParams;
    }

    public ngOnInit(): void {
        this.updateListOnQueryParamsChange();
    }

    protected applyFilters(filters: FlightZoneListFilters): void {
        this.updateQueryParams(filters);
    }

    protected changePage(event: PageEvent): void {
        const queryParams = { [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event.pageSize };

        this.updateQueryParams(queryParams);
    }

    protected refreshList(): void {
        this.store.dispatch(new FlightZoneListsActions.GetSubmittedApplicationsList(this.route.snapshot.queryParams));
    }

    protected downloadKml(flightZone: FlightZoneApplicationListItem): void {
        this.store
            .dispatch(new FlightZoneListsActions.GetApplicationGeoJson(flightZone, ApplicationType.RestrictionApplication))
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(FlightZoneListsState.error);

                    if (error?.type === FlightZoneErrorType.CannotGetZoneGeoJson) {
                        this.toastService.error(
                            this.transloco.translate("dssClientLibFlightZone.applicationListShared.getZoneGeoJsonErrorMessage")
                        );
                    }
                }),
                switchMap(() => this.store.select(FlightZoneListsState.zoneGeoJson)),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe((geoJson) => {
                this.kmlExporterService.geoJsonToKml(geoJson, flightZone.title);
            });
    }

    private updateQueryParams(queryParams: Params): void {
        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    private updateListOnQueryParamsChange(): void {
        this.route.queryParams.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((filterParams) => {
            this.store.dispatch(new FlightZoneListsActions.GetSubmittedApplicationsList(filterParams));
        });
    }
}
