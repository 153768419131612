<ng-container *ngrxLet="{ permit: permit$, areActionButtonsVisible: areActionButtonsVisible$ } as vm">
    <dtm-ui-permit-basic-data-preview
        [areActionButtonsVisible]="vm.areActionButtonsVisible"
        [basicData]="vm.permit.basicData"
        (editStep)="basicDataEdit.emit()"
        (kmlFilePreview)="kmlFilePreview.emit($event)"
        [isOperatorDetailsTileExpanded]="isOperatorDetailsTileExpanded$ | ngrxPush"
        [isContactPersonTileExpanded]="isContactPersonTileExpanded$ | ngrxPush"
        [isRestrictionsTileExpanded]="isRestrictionsTileExpanded$ | ngrxPush"
        [isOperatorContactPersonVisible]="isOperatorContactPersonVisible$ | ngrxPush"
    ></dtm-ui-permit-basic-data-preview>
    <dtm-ui-uav-details-preview
        [isDetailsExpanded]="isPermitDetailsExpanded$ | ngrxPush"
        [isActionButtonVisible]="vm.areActionButtonsVisible"
        [uavDetails]="vm.permit.uavDetails"
        (editStep)="uavDetailsEdit.emit()"
    ></dtm-ui-uav-details-preview>
</ng-container>
