import { BooleanInput, coerceBooleanProperty, coerceNumberProperty, NumberInput } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { combineLatest, map, merge, Observable } from "rxjs";

interface SliderComponentState {
    thumbLabelFormatter: (value: number) => string;
    tickLabelFormatter: (value: number) => string;
    isDisabled: boolean;
    showThumbLabel: boolean;
    maxValue: number | undefined;
    minValue: number | undefined;
    step: number;
    suffix: string | undefined;
    ticks: number;
    subTicks: number | undefined;
    value: number | undefined;
    leftValue: number | undefined;
    rightValue: number | undefined;
}

enum TickType {
    Main = "main",
    Small = "small",
    Large = "large",
}

interface Tick {
    type: TickType;
    value: number;
    label?: string | number;
}

@Component({
    selector: `dtm-ui-slider[min][max],
               dtm-ui-slider[min][max][leftValue][rightValue]:not([value]),
               dtm-ui-slider[min][max][value]:not([leftValue]):not([rightValue])`,
    templateUrl: "./slider.component.html",
    styleUrls: ["./slider.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SliderComponent {
    protected TickType = TickType;

    @Input() public set thumbLabelFormatter(value: undefined | ((value: number) => string)) {
        if (value) {
            this.localStore.patchState({ thumbLabelFormatter: value });
        }
    }
    @Input() public set tickLabelFormatter(value: undefined | ((value: number) => string)) {
        if (value) {
            this.localStore.patchState({ tickLabelFormatter: value });
        }
    }
    @Input() public set disabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }
    @Input() public set showThumbLabel(value: BooleanInput) {
        this.localStore.patchState({ showThumbLabel: coerceBooleanProperty(value) });
    }
    @Input() public set min(value: NumberInput) {
        const parsedValue = value === 0 || !!value ? coerceNumberProperty(value) : undefined;
        this.localStore.patchState({ minValue: parsedValue });
    }
    @Input() public set max(value: NumberInput) {
        const parsedValue = value === 0 || !!value ? coerceNumberProperty(value) : undefined;
        this.localStore.patchState({ maxValue: parsedValue });
    }
    @Input() public set step(value: NumberInput) {
        this.localStore.patchState({ step: coerceNumberProperty(value) });
    }
    @Input() public set suffix(value: string) {
        this.localStore.patchState({ suffix: value });
    }
    @Input() public set ticks(value: NumberInput) {
        const numberOfTicks = coerceNumberProperty(value);

        if (numberOfTicks < 2 && numberOfTicks !== 0) {
            throw new Error("Ticks number must not be equal to 1");
        }

        this.localStore.patchState({ ticks: numberOfTicks });
    }
    @Input() public set subTicks(value: number | undefined) {
        if (value && value < 0) {
            throw new Error("Subticks number must not be less than 0");
        }

        this.localStore.patchState({ subTicks: value });
    }
    @Input() public set value(value: NumberInput) {
        const parsedValue = value === "" || value === undefined ? undefined : coerceNumberProperty(value);
        this.localStore.patchState({ value: parsedValue, leftValue: undefined, rightValue: undefined });
    }
    @Input() public set leftValue(value: NumberInput) {
        const parsedValue = value === "" || value === undefined ? undefined : coerceNumberProperty(value);
        this.localStore.patchState({ leftValue: parsedValue, value: undefined });
    }
    @Input() public set rightValue(value: NumberInput) {
        const parsedValue = value === "" || value === undefined ? undefined : coerceNumberProperty(value);
        this.localStore.patchState({ rightValue: parsedValue, value: undefined });
    }

    @Output() public readonly valueChange = new EventEmitter<number>();
    @Output() public readonly rangeChange = new EventEmitter<[number, number]>();
    @Output() public readonly valueInput = new EventEmitter<number>();
    @Output() public readonly rangeInput = new EventEmitter<[number, number]>();

    public readonly thumbLabelFormatter$ = this.localStore.selectByKey("thumbLabelFormatter");
    public readonly isDisabled$ = this.localStore.selectByKey("isDisabled");
    public readonly showThumbLabel$ = this.localStore.selectByKey("showThumbLabel");
    public readonly maxValue$ = this.localStore.selectByKey("maxValue");
    public readonly minValue$ = this.localStore.selectByKey("minValue");
    public readonly step$ = this.localStore.selectByKey("step");
    public readonly suffix$ = this.localStore.selectByKey("suffix");
    public readonly value$ = this.localStore.selectByKey("value");
    public readonly leftValue$ = this.localStore.selectByKey("leftValue");
    public readonly rightValue$ = this.localStore.selectByKey("rightValue");
    public readonly isRangeSlider$ = combineLatest([this.leftValue$, this.rightValue$]).pipe(
        map(([left, right]) => left !== undefined && right !== undefined)
    );
    public readonly currentValue$ = this.initCurrentValueObservable();

    public readonly ticks$ = combineLatest([this.localStore.selectByKey("ticks"), this.minValue$, this.maxValue$, this.step$]).pipe(
        map(([ticks, min, max, step]): Tick[] | undefined => {
            if (ticks < 2 || min === undefined || max === undefined || min >= max) {
                return undefined;
            }

            return this.generateTicks(ticks, min, max, step);
        })
    );

    public readonly subTicks$ = combineLatest([this.localStore.selectByKey("subTicks"), this.ticks$]).pipe(
        map(([subTicks, ticks]): Tick[] | undefined => {
            if (!ticks || subTicks === undefined) {
                return undefined;
            }

            return this.generateSubTicks(subTicks, ticks);
        })
    );

    constructor(private readonly localStore: LocalComponentStore<SliderComponentState>) {
        localStore.setState({
            thumbLabelFormatter: (value: number) => value.toLocaleString(),
            tickLabelFormatter: (value: number) => value.toLocaleString(),
            isDisabled: false,
            showThumbLabel: true,
            minValue: undefined,
            maxValue: undefined,
            step: 1,
            suffix: undefined,
            ticks: 0,
            value: undefined,
            subTicks: undefined,
            leftValue: undefined,
            rightValue: undefined,
        });
    }

    private generateSubTicks(subTicks: number, ticks: Tick[]) {
        return ticks.reduce<Tick[]>((result, tick, index) => {
            const nextTick = index === ticks.length - 1 ? undefined : ticks[index + 1];

            result.push({
                type: TickType.Large,
                value: tick.value,
            });

            if (!nextTick) {
                return result;
            }

            const valueOffset = nextTick ? (nextTick.value - tick.value) / (subTicks + 1) : 0;

            for (let smallTickIndex = 0; smallTickIndex < subTicks; smallTickIndex++) {
                result.push({
                    type: TickType.Small,
                    value: tick.value + valueOffset * (smallTickIndex + 1),
                });
            }

            return result;
        }, []);
    }

    private generateTicks(ticks: number, min: number, max: number, step: number): Tick[] {
        const result: Tick[] = [];
        const maxGaps = (max - min) / step;

        let greatestDivisor = maxGaps;

        for (let divisor = Math.ceil(maxGaps / (ticks - 1)); divisor <= Math.ceil(maxGaps / 2); divisor++) {
            if (maxGaps % divisor === 0) {
                greatestDivisor = divisor;

                break;
            }
        }

        const tickValueGap = greatestDivisor * step;

        for (
            let currentTickValue = min;
            currentTickValue <= max;
            currentTickValue = Math.round((currentTickValue + tickValueGap) / step) * step
        ) {
            result.push({
                value: currentTickValue,
                label: this.localStore.selectSnapshotByKey("tickLabelFormatter")(currentTickValue),
                type: TickType.Main,
            });
        }

        return result;
    }

    private initCurrentValueObservable(): Observable<[number, number]> {
        return combineLatest([
            merge(this.value$, this.valueInput),
            merge(combineLatest([this.leftValue$, this.rightValue$]), this.rangeInput),
            this.minValue$,
            this.maxValue$,
        ]).pipe(
            map(([value, range, minValue, maxValue]) => {
                const leftValue = range[0] ?? minValue ?? 0;
                const rightValue = range[1] ?? value ?? maxValue ?? 0;

                return [leftValue, rightValue];
            })
        );
    }
}
