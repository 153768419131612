import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs/operators";
import { PopulationDensity } from "../../models/mission.models";

interface PopulationDensityComponentState {
    populationDensity: PopulationDensity | undefined;
    maxPopulationDensityLabel: string | undefined;
    populationDensityLabel: string | undefined;
    populationDensityShareLabel: string | undefined;
    isAveragePopulationDensityVisible: boolean;
}

@Component({
    selector: "dtm-mission-population-density[populationDensity]",
    templateUrl: "./population-density.component.html",
    styleUrls: ["./population-density.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PopulationDensityComponent {
    @Input()
    public set populationDensity(value: PopulationDensity | undefined) {
        this.localStore.patchState({ populationDensity: value });
    }

    @Input()
    public set maxPopulationDensityLabel(value: string | undefined) {
        this.localStore.patchState({ maxPopulationDensityLabel: value });
    }

    @Input()
    public set populationDensityLabel(value: string | undefined) {
        this.localStore.patchState({ populationDensityLabel: value });
    }

    @Input()
    public set populationDensityShareLabel(value: string | undefined) {
        this.localStore.patchState({ populationDensityShareLabel: value });
    }

    @Input()
    public set isAveragePopulationDensityVisible(value: BooleanInput) {
        this.localStore.patchState({ isAveragePopulationDensityVisible: coerceBooleanProperty(value) });
    }

    protected readonly populationDensity$ = this.localStore.selectByKey("populationDensity");
    protected readonly areaShare$ = this.populationDensity$.pipe(
        map((populationDensity) => this.mapDensityBoxesValuesToFractionOfTotal(populationDensity?.boxes)),
        RxjsUtils.filterFalsy()
    );
    protected readonly maxPopulationDensityLabel$ = this.localStore.selectByKey("maxPopulationDensityLabel");
    protected readonly populationDensityLabel$ = this.localStore.selectByKey("populationDensityLabel");
    protected readonly populationDensityShareLabel$ = this.localStore.selectByKey("populationDensityShareLabel");
    protected readonly isAveragePopulationDensityVisible$ = this.localStore.selectByKey("isAveragePopulationDensityVisible");

    constructor(private readonly localStore: LocalComponentStore<PopulationDensityComponentState>) {
        this.localStore.setState({
            populationDensity: undefined,
            maxPopulationDensityLabel: undefined,
            populationDensityLabel: undefined,
            populationDensityShareLabel: undefined,
            isAveragePopulationDensityVisible: true,
        });
    }

    private mapDensityBoxesValuesToFractionOfTotal(boxes?: PopulationDensity["boxes"]): PopulationDensity["boxes"] | undefined {
        if (!boxes) {
            return;
        }

        const totalValue = Object.values(boxes).reduce((left, right) => left + right, 0);

        if (totalValue === 0) {
            return;
        }

        return Object.entries(boxes).reduce((left, [key, value]) => ({ ...left, [key]: value / totalValue }), {});
    }
}
