<div class="container" *ngrxLet="{ selectedRange: selectedWeatherRangeIndex$, weatherRange: weatherRange$ } as vm">
    <div>
        <button class="button-icon" type="button" [disabled]="isLeftScrollButtonDisabled$ | ngrxPush" (click)="scrollLeft()">
            <dtm-ui-icon name="arrow-left"></dtm-ui-icon>
        </button>
    </div>
    <div class="time-range-elements" #timeRangeContent dtmUiScrollTracker (scrollTrack)="changeScrollButtonState($event)">
        <div
            class="range"
            [class.selected]="vm.selectedRange === index"
            *ngFor="let range of vm.weatherRange; let index = index"
            (click)="selectRange(index)"
        >
            <div [ngClass]="['status', range | invoke : getRangeStatus]"></div>
            <div class="hours-range">{{ range.time | invoke : formatRange }}</div>
        </div>
    </div>
    <div>
        <button class="button-icon" type="button" [disabled]="isRightScrollButtonDisabled$ | ngrxPush" (click)="scrollRight()">
            <dtm-ui-icon name="arrow-right"></dtm-ui-icon>
        </button>
    </div>
</div>
