import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface QuillEditorToolbarComponentState {
    isDropdownVisible: boolean;
    isExtendedToolbar: boolean;
}

@Component({
    selector: "dtm-ui-quill-editor-toolbar",
    templateUrl: "./quill-toolbar.component.html",
    styleUrls: ["./quill-toolbar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class QuillEditorToolbarComponent {
    @Input() public set isExtendedQuillToolbar(value: BooleanInput) {
        this.localStore.patchState({ isExtendedToolbar: coerceBooleanProperty(value) });
    }

    protected isDropdownVisible$ = this.localStore.selectByKey("isDropdownVisible");
    protected isExtendedToolbar$ = this.localStore.selectByKey("isExtendedToolbar");

    @HostListener("document:click", ["$event"])
    private onClickOutside(event: MouseEvent): void {
        const isDropdownVisible = this.localStore.selectSnapshotByKey("isDropdownVisible");
        if (isDropdownVisible && !this.element.nativeElement.contains(event.target)) {
            this.localStore.patchState({
                isDropdownVisible: false,
            });
        }
    }
    constructor(private element: ElementRef, private readonly localStore: LocalComponentStore<QuillEditorToolbarComponentState>) {
        this.localStore.setState({
            isExtendedToolbar: false,
            isDropdownVisible: false,
        });
    }

    protected toggleDropdown(): void {
        this.localStore.patchState((state) => ({
            isDropdownVisible: !state.isDropdownVisible,
        }));
    }
}
