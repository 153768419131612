export * from "./geo-zones.module";
export * from "./geo-zones.tokens";
export * from "./models/geo-zones.models";
export * from "./services/geo-zones.converter";
export * from "./state/geo-zones.actions";
export * from "./state/geo-zones.state";
import { getZoneIconName, sortZones } from "./geo-zones.utils";

export const GeoZonesUtils = {
    sortZones: sortZones,
    getZoneIconName: getZoneIconName,
};
