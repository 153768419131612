<dtm-ui-expandable-panel
    *ngrxLet="{
        soraResult: soraResult$,
        isControlledGroundArea: isControlledGroundArea$,
        airspaceCharacteristics: airspaceCharacteristics$,
        isExpanded: isExpanded$
    } as vm"
    [isExpanded]="vm.isExpanded"
    [hasHeaderSeparator]="false"
>
    <h2 headerSlot>
        {{ "dtmSharedMission.initialSoraIssues.header" | transloco }}
    </h2>
    <ul class="list">
        <li>
            <p class="title">{{ "dtmSharedMission.initialSoraIssues.finalGrlLabel" | transloco }}</p>
            <ng-container *ngIf="vm.soraResult?.finalGrl as finalGrl; else noGrlData">
                {{ vm.isControlledGroundArea ? (finalGrl | controlledAreaGrlLevel) : (vm.soraResult.effectiveGrl | grlLevel) }}
            </ng-container>
            <ng-template #noGrlData>
                {{ "dtmSharedMission.initialSoraIssues.noDataLabel" | transloco }}
            </ng-template>
        </li>
        <li>
            <p class="title">{{ "dtmSharedMission.initialSoraIssues.intrinsicGrcLabel" | transloco }}</p>
            {{ (vm.soraResult.intrinsicGrc | grcLevel : true) ?? ("dtmSharedMission.initialSoraIssues.noDataLabel" | transloco) }}
            <dtm-mission-sora-issues-warning [grc]="vm.soraResult.finalGrc"></dtm-mission-sora-issues-warning>
        </li>
        <li>
            <p class="title">{{ "dtmSharedMission.initialSoraIssues.airspaceCharacteristicLabel" | transloco }}</p>
            {{
                "dtmSharedMission.initialSoraIssues.airspaceCharacteristicLabelText"
                    | transloco : { characteristics: vm.airspaceCharacteristics }
            }}
        </li>
        <li>
            <p class="title">{{ "dtmSharedMission.initialSoraIssues.initialResidualArcLabel" | transloco }}</p>
            {{ (vm.soraResult.initialArc | arcLabel : true) ?? ("dtmSharedMission.initialSoraIssues.noDataLabel" | transloco) }}
            <dtm-mission-sora-issues-warning
                [arc]="vm.soraResult.residualArc ?? vm.soraResult.initialArc"
            ></dtm-mission-sora-issues-warning>
        </li>
    </ul>
</dtm-ui-expandable-panel>
