import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterContentInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ArrayUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AcEntity, AcLayerComponent, AcNotification, Cartesian3 } from "@pansa/ngx-cesium";
import buffer from "@turf/buffer";
import { Feature, featureCollection as turfFeatureCollection, point as turfPoint } from "@turf/helpers";
import { Observable, Subject, combineLatest, distinctUntilChanged, map, pairwise, shareReplay, startWith } from "rxjs";
import {
    CylinderEntity,
    MapEntitiesEditorContent,
    MapEntitiesEditorService,
    MapEntity,
    MapEntityType,
    Polyline3DEntity,
    PrismEntity,
} from "../../services/entity-editors/map-entities-editor.service";
import { areCylinderEntitiesPositionsEqual } from "../../utils/are-cylinder-entities-equal";
import { arePolyline3DEntitiesPositionsEqual } from "../../utils/are-polyline3d-entities-equal";
import { arePrismEntitiesPositionsEqual } from "../../utils/are-prism-entities-equal";
import { convertCylinderEntityToGeoJSONFeature } from "../../utils/convert-cylinder-entity-to-geojson-feature";
import { convertPolyline3DEntityToGeoJSONFeatures } from "../../utils/convert-polyline3d-entity-to-geojson-feature";
import { convertPrismEntityToGeoJSONFeature } from "../../utils/convert-prism-entity-to-geojson-feature";
import { createCesiumDashPattern } from "../../utils/create-cesium-dash-pattern";
import { getSmallestEnclosingCircle } from "../../utils/get-smallest-enclosing-circle";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

const DEFAULT_FILL_OPACITY = 0.2;
const DEFAULT_OUTLINE_WIDTH = 2;
const MAX_RADIUS_EPSILON_RATIO = 0.004;
const BOUNDARY_NORMAL_OUTLINE_COLOR = Cesium.Color.fromCssColorString("#007544"); // $color-status-success
const BOUNDARY_NORMAL_FILL_COLOR = BOUNDARY_NORMAL_OUTLINE_COLOR.withAlpha(DEFAULT_FILL_OPACITY);
const BOUNDARY_ERROR_OUTLINE_COLOR = Cesium.Color.fromCssColorString("#ff6f00"); // $color-primary-900
const BOUNDARY_ERROR_FILL_COLOR = BOUNDARY_ERROR_OUTLINE_COLOR.withAlpha(DEFAULT_FILL_OPACITY);
const DEFAULT_OUTLINE_DASH_LENGTH = 20;
const DEFAULT_OUTLINE_DASH_PATTERN = createCesiumDashPattern("---------  --");
const BOUNDARY_ENTITY_ID = "circular-boundary";
const NO_LIMIT_RADIUS = 40000000;

interface BoundaryAcEntityOptions {
    id: string;
    position: Cartesian3;
    radius: number;
    outline: Cartesian3[];
    isAreaTooLarge: boolean;
}

class BoundaryAcEntity extends AcEntity implements BoundaryAcEntityOptions {
    public readonly id!: string;
    public readonly position!: Cartesian3;
    public readonly radius!: number;
    public readonly outline!: Cartesian3[];
    public readonly isAreaTooLarge!: boolean;

    public readonly fillMaterial = new Cesium.ImageMaterialProperty({
        transparent: false,
        image: "/assets/images/boundary_fill_pattern.png",
        color: new Cesium.CallbackProperty(() => (this.isAreaTooLarge ? BOUNDARY_ERROR_FILL_COLOR : BOUNDARY_NORMAL_FILL_COLOR), false),
    });
    public readonly outlineMaterial = new Cesium.PolylineDashMaterialProperty({
        color: new Cesium.CallbackProperty(
            () => (this.isAreaTooLarge ? BOUNDARY_ERROR_OUTLINE_COLOR : BOUNDARY_NORMAL_OUTLINE_COLOR),
            false
        ),
        dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
        dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
    });

    public get outlineWidth() {
        return DEFAULT_OUTLINE_WIDTH;
    }

    public getCenterCallbackProperty() {
        return new Cesium.CallbackProperty(() => this.position, false);
    }

    public getOutlineCallbackProperty() {
        return new Cesium.CallbackProperty(() => this.outline, false);
    }

    public getRadiusCallbackProperty() {
        return new Cesium.CallbackProperty(() => this.radius, false);
    }

    public update(updateOptions: Partial<BoundaryAcEntityOptions>): void {
        Object.assign(this, updateOptions);
    }
}

interface CircularBoundaryLayerComponentState {
    isShown: boolean;
    boundaryLayer: AcLayerComponent | undefined;
    maxRadius: number | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-map-lib-circular-boundary-layer[maxRadius]",
    templateUrl: "./circular-boundary-layer.component.html",
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircularBoundaryLayerComponent implements AfterContentInit {
    protected readonly Cesium = Cesium;

    @ViewChild("boundaryLayer") protected set boundaryLayer(value: AcLayerComponent) {
        this.localStore.patchState({ boundaryLayer: value });
    }

    @Input() public set isShown(value: BooleanInput) {
        this.localStore.patchState({ isShown: coerceBooleanProperty(value) });
    }

    @Input() public set maxRadius(value: number | undefined) {
        this.localStore.patchState({ maxRadius: value });
    }

    @Output() protected readonly violationChange = new EventEmitter<boolean>();

    protected readonly isShown$ = this.getIsShownObservable();
    protected readonly boundaryEntities$ = new Subject<AcNotification>();

    private readonly geometryCachedFeatures = new Map<string, Feature[]>();
    private readonly boundaryAcEntity$ = this.prepareBoundaryAcEntity();

    constructor(
        private readonly localStore: LocalComponentStore<CircularBoundaryLayerComponentState>,
        private readonly mapEntitiesEditorService: MapEntitiesEditorService
    ) {
        this.localStore.setState({
            isShown: true,
            boundaryLayer: undefined,
            maxRadius: undefined,
        });
    }

    public ngAfterContentInit(): void {
        this.watchForGeometryContentChanges();
    }

    private getIsShownObservable(): Observable<boolean> {
        return combineLatest([this.localStore.selectByKey("isShown"), this.localStore.selectByKey("maxRadius")]).pipe(
            map(([isShown, maxRadius]) => isShown && maxRadius !== undefined && maxRadius !== NO_LIMIT_RADIUS),
            distinctUntilChanged()
        );
    }

    private prepareBoundaryAcEntity(): Observable<BoundaryAcEntity | undefined> {
        return combineLatest([
            this.mapEntitiesEditorService.editorContent$.pipe(RxjsUtils.filterFalsy(), startWith([]), pairwise()),
            this.localStore.selectByKey("maxRadius").pipe(RxjsUtils.filterFalsy()),
            this.isShown$,
        ]).pipe(
            map(([[previousGeometryContent, geometryContent], maxRadius, isShown]) => {
                if (!isShown) {
                    return undefined;
                }

                const geometry = this.getCombinedGeometry(previousGeometryContent, geometryContent);

                if (!geometry) {
                    return undefined;
                }

                const enclosingCircle = getSmallestEnclosingCircle(geometry);

                if (!enclosingCircle) {
                    return undefined;
                }

                const centerPoint = Cesium.Cartesian3.fromDegrees(enclosingCircle.centerPoint[0], enclosingCircle.centerPoint[1]);
                const radiusPoint = Cesium.Cartesian3.fromDegrees(enclosingCircle.radiusPoint[0], enclosingCircle.radiusPoint[1]);
                const radius = Cesium.Cartesian3.distance(centerPoint, radiusPoint);
                const isAreaTooLarge: boolean = Cesium.Math.greaterThan(radius, maxRadius, maxRadius * MAX_RADIUS_EPSILON_RATIO);
                const outline = buffer(turfPoint(enclosingCircle.centerPoint), maxRadius, { units: "meters" });

                return new BoundaryAcEntity({
                    id: BOUNDARY_ENTITY_ID,
                    position: centerPoint,
                    radius: maxRadius,
                    isAreaTooLarge,
                    outline: Cesium.Cartesian3.fromDegreesArray(outline.geometry.coordinates.flat(2)),
                });
            }),
            shareReplay({ refCount: true, bufferSize: 1 })
        );
    }

    private getCombinedGeometry(previousGeometryContent: MapEntitiesEditorContent, geometryContent: MapEntitiesEditorContent) {
        if (this.geometryCachedFeatures.size === 0) {
            previousGeometryContent = [];
        }

        const geometryMap = new Map<string, MapEntity>(geometryContent.map((entity) => [entity.id, entity]));
        const previousGeometryMap = new Map<string, MapEntity>(previousGeometryContent.map((entity) => [entity.id, entity]));

        geometryContent
            .filter((entity) => entity.type === MapEntityType.Polyline3D && Object.keys(entity.childEntities))
            .forEach((entity) => {
                Object.values((entity as Polyline3DEntity).childEntities).forEach((childEntity) => {
                    geometryMap.set(childEntity.entity.id, childEntity.entity);
                });
            });
        previousGeometryContent
            .filter((entity) => entity.type === MapEntityType.Polyline3D && Object.keys(entity.childEntities))
            .forEach((entity) => {
                Object.values((entity as Polyline3DEntity).childEntities).forEach((childEntity) => {
                    previousGeometryMap.set(childEntity.entity.id, childEntity.entity);
                });
            });

        const [existingElements, newElements] = ArrayUtils.partition([...geometryMap.values()], (entity) =>
            previousGeometryMap.has(entity.id)
        );
        const [, removedElements] = ArrayUtils.partition([...previousGeometryMap.values()], (entity) => geometryMap.has(entity.id));

        removedElements.forEach((entity) => this.geometryCachedFeatures.delete(entity.id));
        newElements.forEach((entity) => this.geometryCachedFeatures.set(entity.id, this.convertMapEntityToFeature(entity)));
        existingElements.forEach(({ id }) => {
            const newEntity = geometryMap.get(id);
            const previousEntity = previousGeometryMap.get(id);

            if (!newEntity || !previousEntity || newEntity.type !== previousEntity.type) {
                return;
            }

            let shouldUpdateCachedFeature = false;

            switch (newEntity.type) {
                case MapEntityType.Cylinder:
                    shouldUpdateCachedFeature = !areCylinderEntitiesPositionsEqual(newEntity, previousEntity as CylinderEntity);
                    break;
                case MapEntityType.Prism:
                    shouldUpdateCachedFeature = !arePrismEntitiesPositionsEqual(newEntity, previousEntity as PrismEntity);
                    break;
                case MapEntityType.Polyline3D:
                    shouldUpdateCachedFeature = !arePolyline3DEntitiesPositionsEqual(newEntity, previousEntity as Polyline3DEntity);
                    break;
            }

            if (shouldUpdateCachedFeature) {
                this.geometryCachedFeatures.set(newEntity.id, this.convertMapEntityToFeature(newEntity));
            }
        });

        const cachedFeatures = Array.from(this.geometryCachedFeatures.values()).flat();

        if (cachedFeatures.length === 0) {
            return undefined;
        }

        return turfFeatureCollection(cachedFeatures);
    }

    private convertMapEntityToFeature(entity: MapEntity): Feature[] {
        switch (entity.type) {
            case MapEntityType.Cylinder:
                return [convertCylinderEntityToGeoJSONFeature(entity)];
            case MapEntityType.Prism:
                return [convertPrismEntityToGeoJSONFeature(entity)];
            case MapEntityType.Polyline3D:
                return convertPolyline3DEntityToGeoJSONFeatures(entity).features;
        }
    }

    private watchForGeometryContentChanges() {
        combineLatest([this.boundaryAcEntity$, this.localStore.selectByKey("boundaryLayer").pipe(RxjsUtils.filterFalsy())])
            .pipe(untilDestroyed(this))
            .subscribe(([entity, boundaryLayer]) => {
                const existingEntity: BoundaryAcEntity = boundaryLayer.getStore().get(BOUNDARY_ENTITY_ID);

                if (existingEntity && entity) {
                    existingEntity.update(entity);
                } else if (!entity) {
                    boundaryLayer.remove(BOUNDARY_ENTITY_ID);
                } else {
                    boundaryLayer.update(entity, BOUNDARY_ENTITY_ID);
                }
            });

        this.boundaryAcEntity$
            .pipe(
                map((entity) => !!entity?.isAreaTooLarge),
                distinctUntilChanged(),
                untilDestroyed(this)
            )
            .subscribe((isAreaTooLarge: boolean) => this.violationChange.emit(!isAreaTooLarge));
    }
}
