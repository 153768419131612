import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "emailToClickable",
    standalone: true,
})
export class EmailsToClickablePipe implements PipeTransform {
    public transform(value: string, linkClass = "link"): string {
        if (!value) {
            return "";
        }

        const emailRegex = /\b[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}\b/gi;

        return value.replace(emailRegex, (email) => `<a class="${linkClass}" href="mailto:${email}">${email}</a>`);
    }
}
