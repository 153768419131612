<ac-layer [context]="this" acFor="let entity of mapPinEntity$">
    <ac-billboard-desc
        props="{
                position: entity.position,
                image : 'assets/images/map-pin.png',
                scale: 0.3,
                show: true,
                verticalOrigin: 1,
            }"
    >
    </ac-billboard-desc>
</ac-layer>
<ng-container
    *ngrxLet="{
        selectedZoneId: selectedZoneId$,
        isInfoShown: isInfoShown$,
        isEnabled: isEnabled$,
        zonesWithInfo: zonesWithInfo$,
        isProcessing: isProcessing$,
        aupEndTime: aupEndTime$,
        airacEndTime: airacEndTime$,
        timeRange: timeRange$,
        isError: isError$,
    } as vm"
>
    <div class="zones-info-container" *ngIf="vm.isEnabled && vm.isInfoShown && !vm.isError">
        <div class="header">
            <h2 class="title">{{ "dtmMapCesium.zoneInfo.titleLabel" | transloco }}</h2>
            <button type="button" class="button-icon" (click)="closeInfo()">
                <dtm-ui-icon name="close"></dtm-ui-icon>
            </button>
        </div>
        <div class="zone-data-container">
            <dtm-ui-loader-container [isShown]="vm.isProcessing">
                <dtm-map-geographical-zones-info-details
                    [zone]="zone"
                    [selectedZoneId]="vm.selectedZoneId"
                    [isOpened]="index === 0"
                    [aupEndTime]="vm.aupEndTime"
                    [airacEndTime]="vm.airacEndTime"
                    [timeRange]="vm.timeRange"
                    (zoneSelectionChange)="zoneSelectionChange.emit($event)"
                    *ngFor="let zone of vm.zonesWithInfo; let index = index"
                ></dtm-map-geographical-zones-info-details>
            </dtm-ui-loader-container>
        </div>
    </div>
</ng-container>
