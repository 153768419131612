import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output, ViewChild } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Observable } from "rxjs";
import { FilterConditionSearchState, FilterConditionSearchText, FilterConditions } from "../../../models/mission-search.models";
import { FilterConditionsComponent } from "../filter-conditions/filter-conditions.component";

export interface FilterModalData {
    initialFilterConditions: FilterConditions | undefined;
    operatorSearchState$: Observable<FilterConditionSearchState>;
    pilotSearchState$: Observable<FilterConditionSearchState>;
    missionIdSearchState$: Observable<FilterConditionSearchState>;
    uavSearchState$: Observable<FilterConditionSearchState>;
    zoneSearchState$: Observable<FilterConditionSearchState>;
}

@Component({
    selector: "dtm-mission-mission-search-filter-modal",
    templateUrl: "./filter-modal.component.html",
    styleUrls: ["./filter-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterModalComponent {
    @Output() public readonly searchTextChange = new EventEmitter<FilterConditionSearchText>();

    @ViewChild(FilterConditionsComponent) private filterConditionsComponent!: FilterConditionsComponent;

    constructor(@Inject(MAT_DIALOG_DATA) protected data: FilterModalData, private readonly dialog: MatDialogRef<FilterModalComponent>) {}

    protected applyFilter() {
        this.dialog.close(this.filterConditionsComponent.getFiltersFormValue());
    }
}
