<ng-container *ngrxLet="notification$ as notification">
    <h2 mat-dialog-title class="title">{{ notification.title }}</h2>
    <div mat-dialog-content class="content">
        <p class="text" [innerHTML]="notification.text"></p>

        <div class="note" *ngIf="notification.note">
            <h3 class="note-title" *ngIf="notification.noteTitle">{{ notification.noteTitle }}</h3>
            <p>{{ notification.note }}</p>
        </div>
    </div>
    <mat-dialog-actions align="end">
        <button type="button" class="button-primary" [mat-dialog-close]="false" cdkFocusInitial>
            {{ "dtmNotifications.closeButtonLabel" | transloco }}
        </button>
    </mat-dialog-actions>
</ng-container>
