<ng-container
    *ngrxLet="{
        isShown: isShown$,
        areLabelsVisible: areLabelsVisible$,
        isPathBased: isPathBased$,
        routeData: routeData$,
        shouldDisplayTimeRange: shouldDisplayTimeRange$
    } as vm"
>
    <ac-layer acFor="let entity of flightAreaEntities$" [context]="this" [show]="vm.isShown">
        <ac-polygon-desc
            props="{
                hierarchy: entity.positions,
                material: getRouteEntityFill(entity)
            }"
        >
        </ac-polygon-desc>
        <ac-polyline-desc
            props="{
                positions: entity.positions,
                width: getRouteEntityOutlineWidth(entity),
                material: getRouteEntityMaterial(entity)
            }"
        >
        </ac-polyline-desc>
    </ac-layer>

    <ac-layer acFor="let entity of safetyAreaEntities$" [context]="this" [show]="vm.isShown">
        <ac-polygon-desc
            props="{
                hierarchy: entity.positions,
                material: getRouteEntityFill(entity),
                height: 0
            }"
        >
        </ac-polygon-desc>
    </ac-layer>
    <ac-layer acFor="let entity of pathEntities$" [context]="this" [show]="vm.isShown">
        <ac-polyline-desc
            props="{
                positions: entity.positions,
                width: getRouteEntityOutlineWidth(entity),
                material: getRouteEntityMaterial(entity)
            }"
        >
        </ac-polyline-desc>
    </ac-layer>
    <ac-layer acFor="let entity of waypointEntities$" [context]="this" [show]="vm.isShown">
        <ac-point-desc
            props="{
                position: entity.position,
                pixelSize: 8,
                color: getRouteEntityFill(entity),
                show: entity.show
            }"
        >
        </ac-point-desc>
        <ac-html-desc props="{ position: entity.position }">
            <ng-template let-pin>
                <dtm-ui-icon name="flight-takeoff" class="pin" *ngIf="vm.isShown && pin.start && vm.areLabelsVisible"></dtm-ui-icon>
                <dtm-ui-icon name="flight-land" class="pin" *ngIf="vm.isShown && pin.landing && vm.areLabelsVisible"></dtm-ui-icon>
            </ng-template>
        </ac-html-desc>
    </ac-layer>
    <ac-layer acFor="let entity of pinEntities$" [context]="this" *ngrxLet="pinTemplate$ as pinTemplate" [show]="vm.isShown">
        <ac-html-desc props="{ position: entity.position, show: entity.show }">
            <ng-template let-pin>
                <div class="mission-pin" *ngIf="vm.isShown && !vm.areLabelsVisible && pin.data.isMain && pin.data.index === 0">
                    <dtm-ui-icon name="map-pin-fill" class="pin-icon"></dtm-ui-icon>
                    <button
                        type="button"
                        class="button-icon map-icon"
                        *ngIf="vm.routeData | invoke : getZoomArea as zoomArea"
                        (click)="entityZoom.emit(zoomArea)"
                    >
                        <dtm-ui-icon name="road-map"></dtm-ui-icon>
                    </button>
                </div>
                <ng-container *ngIf="vm.areLabelsVisible && vm.isShown">
                    <ng-container *ngTemplateOutlet="pinTemplate ?? defaultPinTemplate; context: { $implicit: pin.data }"></ng-container>
                </ng-container>
            </ng-template>
        </ac-html-desc>
    </ac-layer>
    <ac-layer acFor="let entity of blockEntities$" [context]="this" [show]="vm.isShown">
        <ac-html-desc props="{ position: entity.position }">
            <ng-template>
                <dtm-ui-icon *ngIf="vm.areLabelsVisible" name="sign-prohibition" class="pin block-pin"></dtm-ui-icon>
            </ng-template>
        </ac-html-desc>
    </ac-layer>
    <ng-template #defaultPinTemplate let-pinData>
        <ng-container *ngIf="vm.areLabelsVisible">
            <svg width="100" height="100" viewBox="-50 -50 100 100" class="line-connector">
                <line [attr.x1]="0" [attr.y1]="0" [attr.x2]="0" [attr.y2]="-50" />
                <circle r="4" cx="0" cy="0" />
            </svg>
            <div
                class="segment-pin"
                *ngIf="vm.isShown"
                [ngClass]="pinData | invoke : getPinClassConfig"
                [class.outside-dtm-segment-pin]="vm.routeData?.isOutsideDtm"
            >
                <div class="header" *ngIf="vm.isPathBased">
                    <ng-container *ngIf="pinData.waypoints.length === 1">
                        {{ pinData.waypoints[0].name }}
                    </ng-container>
                    <ng-container *ngIf="pinData.waypoints.length === 2">
                        {{ pinData.waypoints[0].name }} - {{ pinData.waypoints[1].name }}
                    </ng-container>
                </div>
                <ng-container *ngIf="!vm.isPathBased && pinData.flightArea | invoke : getHeightRangeValues as heightRangeValues">
                    <div class="header">
                        {{
                            "dtmMapCesium.aglLabel"
                                | transloco : { height: heightRangeValues.maxHeightAgl | localizeNumber : { maximumFractionDigits: 0 } }
                        }}

                        <p>
                            ({{
                                "dtmMapCesium.amslLabel"
                                    | transloco
                                        : { height: heightRangeValues.maxHeightAmsl | localizeNumber : { maximumFractionDigits: 0 } }
                            }})
                        </p>
                    </div>
                    <div class="sub header" *ngIf="heightRangeValues.isOnTheGround">
                        {{ "dtmMapCesium.groundLabel" | transloco }}
                    </div>
                    <div class="sub header" *ngIf="!heightRangeValues.isOnTheGround">
                        {{
                            "dtmMapCesium.aglLabel"
                                | transloco : { height: heightRangeValues.minHeightAgl | localizeNumber : { maximumFractionDigits: 0 } }
                        }}
                        <p>
                            ({{
                                "dtmMapCesium.amslLabel"
                                    | transloco
                                        : { height: heightRangeValues.minHeightAmsl | localizeNumber : { maximumFractionDigits: 0 } }
                            }})
                        </p>
                    </div>
                </ng-container>
                <div class="content" *ngIf="vm.shouldDisplayTimeRange">
                    <div class="indicator"></div>
                    <p>
                        <!-- TODO: DTM-2460 -  localizeDate (not pure) has performance issues when used within @pansa/ngx-cesium -->
                        {{ pinData.waypoints[0].estimatedArriveAt.min | localizeDatePure : { timeStyle: "short" } }}
                        -
                        {{
                            pinData.waypoints[1]?.estimatedArriveAt.max ?? pinData.waypoints[0].estimatedArriveAt.max
                                | localizeDatePure : { timeStyle: "short" }
                        }}
                    </p>
                </div>
            </div>
        </ng-container>
    </ng-template>
</ng-container>
