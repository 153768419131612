import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { IdentityDocumentStatus } from "../../models/identity-document.models";

interface IdentityDocumentBadgeStatusComponentState {
    status: IdentityDocumentStatus | undefined;
}

@Component({
    selector: "dtm-ui-identity-document-status-badge[status]",
    templateUrl: "./identity-document-status-badge.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class IdentityDocumentStatusBadgeComponent {
    @Input() public set status(value: IdentityDocumentStatus | undefined) {
        this.localStore.patchState({ status: value });
    }

    protected readonly IdentityDocumentStatus = IdentityDocumentStatus;
    protected readonly status$ = this.localStore.selectByKey("status");

    constructor(private readonly localStore: LocalComponentStore<IdentityDocumentBadgeStatusComponentState>) {
        localStore.setState({ status: undefined });
    }
}
