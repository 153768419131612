<div
    class="wrapper"
    *ngrxLet="hasErrors$; let hasErrors"
    [ngClass]="{
        error: hasErrors,
        disabled: isDisabled$ | ngrxPush,
        focused: isFocused$ | ngrxPush,
        activated: isActivated$ | ngrxPush
    }"
>
    <div class="label">
        <ng-content select="label"></ng-content>
    </div>
    <div class="input-wrapper">
        <div class="input-prefix">
            <ng-content select="input-prefix"></ng-content>
        </div>
        <div class="form-field" (click)="contentClick.emit()" [class.clickable]="contentClick.observed">
            <ng-content></ng-content>
        </div>
        <div class="input-suffix">
            <ng-content select="input-suffix"></ng-content>
        </div>
    </div>
    <div class="hint" *ngIf="!hasErrors">
        <ng-content select=".field-hint"></ng-content>
    </div>
    <dtm-ui-form-errors (hasErrors)="setHasErrors($event)" (errorIdChange)="handleErrorIdChange($event)">
        <ng-content select=".field-error"></ng-content>
    </dtm-ui-form-errors>
</div>
