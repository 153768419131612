import { createAppModule } from "./app/app.module";
import { dynamicEnvironment } from "./environments/environment";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

Cesium.buildModuleUrl.setBaseUrl("/assets/cesium/");

dynamicEnvironment
    .asyncPlatformBrowserDynamic()
    .then(([platform, environment]) => platform.bootstrapModule(createAppModule(environment)).catch((error) => console.error(error)));
