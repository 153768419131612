import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { FlightZoneSharedModule } from "@dtm-frontend/dss-shared-lib";
import { GeolocationService, WebGeolocationService } from "@dtm-frontend/shared/map";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { MissionFiltersComponent } from "./components/mission-filters/mission-filters.component";
import { MissionInfoPanelComponent } from "./components/mission-info-panel/mission-info-panel.component";
import { MissionMapComponent } from "./components/mission-map/mission-map.component";
import { PhaseDescriptionComponent } from "./components/phase-description/phase-description.component";
import { PlannedMissionContainerComponent } from "./components/planned-mission-container/planned-mission-container.component";
import { PlannedMissionDetailsComponent } from "./components/planned-mission-details/planned-mission-details.component";
import { PlannedMissionApiService } from "./services/planned-mission-api.service";
import { PlannedMissionsState } from "./state/planned-missions.state";

@NgModule({
    imports: [
        CommonModule,
        SharedUiModule,
        LetModule,
        PushModule,
        SharedI18nModule,
        NgxsModule.forFeature([PlannedMissionsState]),
        ReactiveFormsModule,
        RouterModule,
        MatInputModule,
        MatTabsModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        FlightZoneSharedModule,
        CesiumMapModule,
    ],
    declarations: [
        PlannedMissionContainerComponent,
        PlannedMissionDetailsComponent,
        PhaseDescriptionComponent,
        MissionInfoPanelComponent,
        MissionMapComponent,
        MissionFiltersComponent,
    ],
    exports: [MissionInfoPanelComponent, MissionFiltersComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dssClientLibPlannedMissions",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: GeolocationService,
            useClass: WebGeolocationService,
        },
    ],
})
export class PlannedMissionsModule {
    public static forRoot(): ModuleWithProviders<PlannedMissionsModule> {
        return {
            ngModule: PlannedMissionsModule,
            providers: [
                {
                    provide: PlannedMissionApiService,
                    useClass: PlannedMissionApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<PlannedMissionsModule> {
        return {
            ngModule: PlannedMissionsModule,
            providers: [
                {
                    provide: PlannedMissionApiService,
                    useValue: null,
                },
            ],
        };
    }
}
