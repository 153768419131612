import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { ProcessedFile, UploadedFile } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Observable, map } from "rxjs";

interface KmlImportModalComponentState {
    kmlFiles: ProcessedFile[];
    filesWithError: ProcessedFile[];
}

const ALLOWED_EXTENSIONS = [".kml", ".kmz"];

@Component({
    templateUrl: "./kml-import-modal.component.html",
    styleUrls: ["./kml-import-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class KmlImportModalComponent {
    protected readonly kmlFiles$ = this.localStore.selectByKey("kmlFiles");
    protected readonly filesWithError$ = this.localStore.selectByKey("filesWithError");

    protected readonly uploadedFiles$: Observable<UploadedFile[]> = this.kmlFiles$.pipe(
        map((kmlFiles) => kmlFiles.map((kmlFile) => ({ name: kmlFile.file.name, id: kmlFile.id })))
    );

    protected readonly ALLOWED_EXTENSIONS = ALLOWED_EXTENSIONS;

    constructor(
        private readonly localStore: LocalComponentStore<KmlImportModalComponentState>,
        @Inject(MAT_DIALOG_DATA)
        private data: ProcessedFile[]
    ) {
        this.localStore.setState({ kmlFiles: data, filesWithError: [] });
    }

    protected addKmlFiles(newKmlFiles: ProcessedFile[]): void {
        newKmlFiles.forEach((kmlFile) => {
            if (ALLOWED_EXTENSIONS.some((extension) => kmlFile.file.name.endsWith(extension))) {
                this.localStore.patchState(({ kmlFiles }) => ({ kmlFiles: [...kmlFiles, kmlFile] }));

                return;
            }
            kmlFile.error = "Wrong file extension";
            this.localStore.patchState(({ filesWithError }) => ({ filesWithError: [...filesWithError, kmlFile] }));
        });
    }

    protected removeKmlFile(file: UploadedFile) {
        this.localStore.patchState(({ kmlFiles }) => ({ kmlFiles: kmlFiles.filter((kmlFile) => kmlFile.id !== file.id) }));
    }

    protected removeProcessedFile(file: ProcessedFile) {
        this.localStore.patchState(({ filesWithError }) => ({
            filesWithError: filesWithError.filter((processedFile) => processedFile.id !== file.id),
        }));
    }
}
