<form [formGroup]="addressFormGroup">
    <div class="form-section">
        <dtm-ui-input-field>
            <label>
                {{ "dtmUi.addressDetails.streetLabel" | transloco }}
            </label>
            <input matInput [formControl]="streetControl" />
            <div class="field-error" *dtmUiFieldHasError="streetControl; name: ['required', 'pattern', 'requiredTouched']">
                {{ "dtmUi.addressDetails.requiredFieldError" | transloco }}
            </div>

            <div class="field-error" *dtmUiFieldHasError="streetControl; name: 'maxlength'; error as error">
                {{ "dtmUi.addressDetails.streetMaxLengthError" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-input-field>
        <div class="row">
            <dtm-ui-input-field>
                <label>
                    {{ "dtmUi.addressDetails.houseNumberLabel" | transloco }}
                </label>
                <input matInput [formControl]="houseNumberControl" />
                <div class="field-error" *dtmUiFieldHasError="houseNumberControl; name: ['required', 'pattern', 'requiredTouched']">
                    {{ "dtmUi.addressDetails.requiredFieldError" | transloco }}
                </div>

                <div class="field-error" *dtmUiFieldHasError="houseNumberControl; name: 'maxlength'; error as error">
                    {{ "dtmUi.addressDetails.houseNumberLengthError" | transloco : { max: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field>
                <label>
                    {{ "dtmUi.addressDetails.apartmentNumberLabel" | transloco }}
                </label>
                <input matInput [formControl]="apartmentNumberControl" />
                <div class="field-error" *dtmUiFieldHasError="apartmentNumberControl; name: 'maxlength'; error as error">
                    {{ "dtmUi.addressDetails.apartmentNumberLengthError" | transloco : { max: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>
        </div>

        <dtm-ui-input-field *ngrxLet="isSystemDefaultCountrySelected$ as isDefaultSystemCountrySelected">
            <label>
                {{ "dtmUi.addressDetails.postCodeLabel" | transloco }}
            </label>
            <input
                *ngIf="isDefaultSystemCountrySelected; else foreignPostCodeInputTemplate"
                matInput
                [formControl]="postCodeControl"
                [mask]="POLISH_POST_CODE_MASK"
                [showMaskTyped]="true"
            />
            <ng-template #foreignPostCodeInputTemplate>
                <input matInput [formControl]="postCodeControl" />
            </ng-template>
            <div class="field-error" *dtmUiFieldHasError="postCodeControl; name: ['required', 'requiredTouched']">
                {{ "dtmUi.addressDetails.requiredFieldError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="postCodeControl; name: 'maxlength'; error as error">
                {{ "dtmUi.addressDetails.postCodeMaxLengthError" | transloco : { max: error.requiredLength } }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="postCodeControl; name: 'pattern'; error as error">
                {{ "dtmUi.addressDetails.postCodePatternError" | transloco }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-input-field>
            <label>{{ "dtmUi.addressDetails.cityLabel" | transloco }}</label>
            <input matInput type="text" [formControl]="cityControl" />
            <div class="field-error" *dtmUiFieldHasError="cityControl; name: ['required', 'pattern', 'requiredTouched']">
                {{ "dtmUi.addressDetails.requiredFieldError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="cityControl; name: 'maxlength'; error as error">
                {{ "dtmUi.addressDetails.cityLengthError" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-input-field>
    </div>
</form>
