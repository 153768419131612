import { Directive } from "@angular/core";
import { CesiumEventBuilder } from "@pansa/ngx-cesium";
import { CesiumPointerManagerService } from "./cesium-pointer-manager.service";
import { ThrottledCesiumEventBuilder } from "./throttled-cesium-event-builder";

@Directive({
    selector: "[dtmMapPointerManager]",
    providers: [CesiumPointerManagerService, { provide: CesiumEventBuilder, useClass: ThrottledCesiumEventBuilder }],
})
export class CesiumPointerManagerDirective {}
