import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "urlToClickable",
    standalone: true,
})
export class UrlsToClickablePipe implements PipeTransform {
    public transform(value: string, linkClass = "link"): string {
        if (!value) {
            return "";
        }

        const urlRegex = /(https?:\/\/[^\s]+)/g;

        return value.replace(urlRegex, (url) => `<a target="_blank" class="${linkClass}" href="${url}">${url}</a>`);
    }
}
