import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

interface LoaderContainerComponentState {
    isShown: boolean;
    diameter: number;
}

@Component({
    selector: "dtm-ui-loader-container[isShown]",
    templateUrl: "./loader-container.component.html",
    styleUrls: ["./loader-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.fadeAnimation()],
})
export class LoaderContainerComponent {
    public readonly isShown$ = this.localStore.selectByKey("isShown");
    public readonly diameter$ = this.localStore.selectByKey("diameter");

    @Input()
    public set isShown(value: boolean | undefined) {
        this.localStore.patchState({ isShown: !!value });
    }

    @Input()
    public set diameter(value: number) {
        this.localStore.patchState({ diameter: value });
    }

    constructor(private readonly localStore: LocalComponentStore<LoaderContainerComponentState>) {
        localStore.setState({
            isShown: false,
            diameter: 60,
        });
    }
}
