<div class="filters-container" [formGroup]="filtersForm">
    <dtm-ui-input-field class="control-designator">
        <input
            formControlName="designator"
            matInput
            type="text"
            [placeholder]="'dssClientLibPlannedMissions.missionFilters.designatorPlaceholder' | transloco"
        />
    </dtm-ui-input-field>

    <dtm-ui-select-field
        formControlName="sort"
        class="control-sort-by-label"
        [isClearable]="false"
        [placeholder]="'dssClientLibPlannedMissions.missionFilters.sortPlaceholder' | transloco"
    >
        <dtm-ui-select-option *ngFor="let option of SORT_OPTIONS_MAP | keyvalue" [value]="option.key">
            {{ "dssClientLibPlannedMissions.missionFilters.sortByLabel" | transloco : { value: option.value } }}
        </dtm-ui-select-option>
    </dtm-ui-select-field>
    <dtm-ui-date-field class="control-date" [formGroup]="filtersForm.controls.missionDates">
        <mat-date-range-input [rangePicker]="rangeDatePicker" *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder">
            <input matStartDate formControlName="flightDateFrom" [placeholder]="datePickerPlaceholder" />
            <input matEndDate formControlName="flightDateTo" [placeholder]="datePickerPlaceholder" />
        </mat-date-range-input>
        <mat-date-range-picker #rangeDatePicker (opened)="appendFooter()"></mat-date-range-picker>
    </dtm-ui-date-field>
    <!-- NOTE: We cannot use MatDatepickerActions here because it changes the datepicker behavior and forces the use of -->
    <!-- action buttons to select dates. This is a hack that appends HTML content with action buttons to the range datepicker -->
    <!-- without change datepicker behaviour. -->
    <div class="not-visible">
        <div class="tile-wrapper" #datepickerFooter *ngrxLet="calendarRange$ as calendarRange">
            <div
                class="time-tile"
                [class.selected]="calendarRange === CalendarDateRange.Today"
                (click)="selectPredefinedDate(CalendarDateRange.Today)"
            >
                {{ "dssClientLibPlannedMissions.missionFilters.todayLabel" | transloco }}
            </div>
            <div
                class="time-tile"
                [class.selected]="calendarRange === CalendarDateRange.OneDayAhead"
                (click)="selectPredefinedDate(CalendarDateRange.OneDayAhead)"
            >
                {{ "dssClientLibPlannedMissions.missionFilters.addOneDayLabel" | transloco }}
            </div>
        </div>
    </div>
</div>
