<div class="form-entry" *ngrxLet="{ isDisabled: isDisabled$, disableMissionType: disableMissionType$ } as vm">
    <p class="field-label">
        {{ "dtmUi.missionType.label" | transloco }}
        <dtm-ui-icon
            *ngIf="isTooltipVisible$ | ngrxPush"
            name="information-fill"
            [matTooltip]="'dtmUi.missionType.tooltipText' | transloco"
        ></dtm-ui-icon>
        <!-- TODO: dtmUi.missionType.tooltipText is empty -->
    </p>
    <div class="cards" *ngrxLet="selectedMissionType$; let selectedMissionType">
        <div
            class="selectable-tile"
            (click)="setMissionType(MissionType.VLOS)"
            [class.selected]="selectedMissionType === MissionType.VLOS"
            [class.disabled]="vm.isDisabled || vm.disableMissionType === MissionType.VLOS"
            data-testid="mission-type-vlos-tile"
        >
            <h6 class="selectable-tile-label">
                <dtm-ui-icon name="eye"></dtm-ui-icon>
                {{ "dtmUi.missionType.vlosLabel" | transloco }}
            </h6>
        </div>
        <div
            class="selectable-tile"
            (click)="setMissionType(MissionType.BVLOS)"
            [class.selected]="selectedMissionType === MissionType.BVLOS"
            [class.disabled]="vm.isDisabled || vm.disableMissionType === MissionType.BVLOS"
        >
            <h6 class="selectable-tile-label">
                <dtm-ui-icon name="eye-off"></dtm-ui-icon>
                {{ "dtmUi.missionType.bvlosLabel" | transloco }}
            </h6>
        </div>
    </div>
</div>
