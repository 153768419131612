/* eslint-disable @typescript-eslint/no-explicit-any */

export function deepClone<T>(source: T): T {
    if (source === null) {
        return source;
    }

    if (source instanceof Date) {
        return new Date(source.getTime()) as any;
    }

    if (Array.isArray(source)) {
        return source.map((item) => deepClone(item)) as any;
    }

    if (typeof source === "object") {
        const copy: any = {};

        Object.entries(source).forEach(([key, value]: [string, unknown]) => {
            copy[key] = deepClone(value);
        });

        return copy;
    }

    return source;
}
