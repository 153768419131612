import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { DefaultValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";
import { FormType } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { startWith } from "rxjs/operators";
import { InfoZoneType } from "../../../../../geo-zones/models/geo-zones.models";

export type LocalSpatialInfoForDtmSettings = {
    isLocalSpatialInfoForDtmEnabled: boolean;
} & { [key in InfoZoneType]?: boolean };

@UntilDestroy()
@Component({
    selector: "dtm-map-local-spatial-info-for-dtm-settings",
    templateUrl: "./local-spatial-info-for-dtm-settings.component.html",
    styleUrls: ["../geographical-zones-common.scss", "./local-spatial-info-for-dtm-settings.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useClass: DefaultValueAccessor,
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalSpatialInfoForDtmSettingsComponent implements OnInit {
    protected isLocalSpatialInfoForDtmEnabledZoneControl = new FormControl(false, { nonNullable: true });
    protected localSpatialInfoFormGroup = new FormGroup<FormType<LocalSpatialInfoForDtmSettings>>({
        isLocalSpatialInfoForDtmEnabled: this.isLocalSpatialInfoForDtmEnabledZoneControl,
        // NOTE: The following field is disabled because there is no data for it yet - more info: DTM-4949
        [InfoZoneType.AdsbCoverMap]: new FormControl({ value: false, disabled: true }, { nonNullable: true }),
        [InfoZoneType.Landings]: new FormControl(true, { nonNullable: true }),
        [InfoZoneType.Obstacles]: new FormControl(true, { nonNullable: true }),
        [InfoZoneType.OtherInformation]: new FormControl(true, { nonNullable: true }),
        [InfoZoneType.PermanentGathering]: new FormControl(true, { nonNullable: true }),
        [InfoZoneType.TemporaryGathering]: new FormControl(true, { nonNullable: true }),
    });

    protected InfoZoneType = InfoZoneType;

    constructor(@Inject(NgControl) private readonly ngControl: NgControl) {}

    public ngOnInit(): void {
        this.localSpatialInfoFormGroup.valueChanges
            .pipe(startWith(null), untilDestroyed(this))
            .subscribe(() => this.ngControl.control?.setValue(this.localSpatialInfoFormGroup.getRawValue()));

        this.ngControl.valueChanges
            ?.pipe(untilDestroyed(this))
            .subscribe((value) => this.localSpatialInfoFormGroup.setValue(value, { emitEvent: false }));
    }
}
