import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { IconName } from "../icon/icon.directive";

interface EditableHeaderComponentState {
    icon: IconName | undefined;
    buttonLabel: string | undefined;
    isEditButtonVisible: boolean;
}

@Component({
    selector: "dtm-ui-card-header",
    templateUrl: "./card-header.component.html",
    styleUrls: ["./card-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CardHeaderComponent {
    @Input() public set icon(value: IconName | undefined) {
        this.localStore.patchState({ icon: value });
    }
    @Input() public set buttonLabel(value: string | undefined) {
        this.localStore.patchState({ buttonLabel: value });
    }
    @Input() public set isEditButtonVisible(value: boolean | undefined) {
        this.localStore.patchState({ isEditButtonVisible: !!value });
    }

    @Output() public readonly edit: EventEmitter<void> = new EventEmitter<void>();

    protected readonly buttonLabel$ = this.localStore.selectByKey("buttonLabel");
    protected readonly icon$ = this.localStore.selectByKey("icon");
    protected readonly isEditButtonVisible$ = this.localStore.selectByKey("isEditButtonVisible");

    constructor(private readonly localStore: LocalComponentStore<EditableHeaderComponentState>) {
        this.localStore.setState({
            icon: undefined,
            buttonLabel: undefined,
            isEditButtonVisible: false,
        });
    }
}
