import { HttpClient, HttpContext } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { TimeRange } from "@dtm-frontend/shared/ui";
import { MILLISECONDS_IN_MINUTE, SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR } from "@dtm-frontend/shared/utils";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { map } from "rxjs";
import { GEO_ZONES_ENDPOINTS, GeoZonesEndpoints } from "../geo-zones.tokens";
import { AirspaceElementResponseBody, SearchAirspaceElementsRequestBody } from "../models/geo-zones.models";
import { convertAirspaceElementResponseBodyToAirspaceElement } from "./geo-zones.converter";

const DEFAULT_SPAN_PERIOD_IN_MINUTES = 30;
const DEFAULT_UPPER_LIMIT = 500;
const DEFAULT_AIRSPACE_SEARCH_OPTIONS: SearchAirspaceElementsRequestBody = {
    scope: {
        lowerLimit: 0,
        upperLimit: DEFAULT_UPPER_LIMIT,
        startTime: new Date(),
        endTime: new Date(new Date().getTime() + DEFAULT_SPAN_PERIOD_IN_MINUTES * MILLISECONDS_IN_MINUTE),
    },
    geozones: null,
    includeInformation: false,
    flatten: true,
};

export type AirspaceElementsSearchOptions = Partial<Omit<SearchAirspaceElementsRequestBody, "scope">> & {
    scope?: Partial<SearchAirspaceElementsRequestBody["scope"]>;
};

@Injectable({
    providedIn: "root",
})
export class GeoZonesApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(GEO_ZONES_ENDPOINTS) private readonly endpoints: GeoZonesEndpoints,
        private readonly websocketService: WebsocketService
    ) {}

    public searchAirspaceElements(options: AirspaceElementsSearchOptions) {
        const endTime = options.scope?.endTime ?? DEFAULT_AIRSPACE_SEARCH_OPTIONS.scope.endTime;

        const body: SearchAirspaceElementsRequestBody = {
            ...DEFAULT_AIRSPACE_SEARCH_OPTIONS,
            ...options,
            scope: options.scope
                ? {
                      ...DEFAULT_AIRSPACE_SEARCH_OPTIONS.scope,
                      ...options.scope,
                      startTime: options.scope.startTime ?? DEFAULT_AIRSPACE_SEARCH_OPTIONS.scope.startTime,
                      endTime,
                  }
                : DEFAULT_AIRSPACE_SEARCH_OPTIONS.scope,
        };

        const timeRange: TimeRange | undefined =
            options.scope?.startTime && options.scope?.endTime ? { min: options.scope.startTime, max: options.scope.endTime } : undefined;

        return this.httpClient
            .post<AirspaceElementResponseBody>(this.endpoints.searchAirspaceElements, body, {
                context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
            })
            .pipe(map((response) => convertAirspaceElementResponseBodyToAirspaceElement(response, endTime, timeRange)));
    }

    public startAirspaceUpdatesWatch() {
        return this.websocketService.watchTopic(this.endpoints.wsAirspaceTopicName);
    }
}
