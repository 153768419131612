import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, HttpErrorResponse } from "@angular/common/http";
import { APP_INITIALIZER, Inject, Injectable, ModuleWithProviders, NgModule, Optional, Provider, SkipSelf } from "@angular/core";
import { MAT_LEGACY_DATE_LOCALE as MAT_DATE_LOCALE } from "@angular/material/legacy-core";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { FunctionUtils, Logger, StringUtils } from "@dtm-frontend/shared/utils";
import {
    TRANSLOCO_LOADER,
    TRANSLOCO_SCOPE,
    Translation,
    TranslocoLoader,
    TranslocoModule,
    TranslocoOptions,
    TranslocoService,
    getLangFromScope,
    getScopeFromLang,
    provideTransloco,
} from "@jsverse/transloco";
import { TranslocoLocaleModule, provideTranslocoLocale } from "@jsverse/transloco-locale";
import { provideTranslocoMessageformat } from "@jsverse/transloco-messageformat";
import { LetModule, PushModule } from "@ngrx/component";
import { lastValueFrom, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { DtmLanguageInterceptor } from "./dtm-language.interceptor";
import { LANGUAGE_CONFIGURATION, LanguageCode, LanguageCodeList } from "./i18n.models";
import { HasSystemTranslation, HasTranslationPipe } from "./pipes/hasTranslation.pipe";
import { LocalizeDatePipe, LocalizeDatePurePipe } from "./pipes/localize-date.pipe";
import { LocalizeFriendlyDatePipe } from "./pipes/localize-friendly-date.pipe";
import { LocalizeNumberPipe } from "./pipes/localize-number.pipe";
import { SystemTranslationPipe } from "./pipes/system-translation.pipe";
import { I18nService } from "./services/i18n.service";
import { TranslationHelperService } from "./services/translation-helper.service";
import { SYSTEM_TRANSLATION_SCOPE, TRANSLATION_ENDPOINTS, TranslationEndpoints } from "./shared-i18n.tokens";

class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private readonly http: HttpClient, private readonly assetPathProviderFn: TranslationAssetPathProviderFn) {}

    public getTranslation(language: LanguageCode) {
        return this.http.get<Translation>(this.assetPathProviderFn(language));
    }
}

@Injectable()
class TranslocoFixService extends TranslocoService {
    public setActiveLang(lang: string) {
        this["parser"].onLangChanged?.(lang);
        this["events"].next({
            type: "langChanged",
            payload: {
                scope: getScopeFromLang(lang) || null,
                langName: getLangFromScope(lang),
            },
        });
        // NOTE: pipes need to wait for new instance of MessageFormatter
        setTimeout(() => this["lang"].next(lang));

        return this;
    }
}

export type TranslocoInlineLoaderPredicate<T> = (language: LanguageCode) => T;

export type TranslationAssetPathProviderFn = (language: LanguageCode) => string;

export interface SharedI18nModuleConfig {
    developmentMode: boolean;
    availableLanguages: LanguageCodeList;
    defaultLanguage: LanguageCode;
    fallbackLanguage: LanguageCode;
    assetPathProviderFn?: TranslationAssetPathProviderFn;
}

export function getTranslocoInlineLoader<T>(predicate: TranslocoInlineLoaderPredicate<T>) {
    return LANGUAGE_CONFIGURATION.availableLanguages.reduce((result: Record<string, () => T>, language: LanguageCode) => {
        result[language] = () => predicate(language);

        return result;
    }, {});
}

function systemTranslationScopeFactory(http: HttpClient, endpoints: TranslationEndpoints) {
    if (!endpoints) {
        return;
    }

    return {
        scope: SYSTEM_TRANSLATION_SCOPE,
        loader: getTranslocoInlineLoader((language: LanguageCode) => {
            const locale = LANGUAGE_CONFIGURATION.localeMapping[language];

            return lastValueFrom(
                // TODO: REJ-1879 remove when backend will be ready to get locale using Accept-Language header
                http.get<Translation>(StringUtils.replaceInTemplate(endpoints.getSystemTranslations, { locale })).pipe(
                    catchError((error: HttpErrorResponse) => {
                        Logger.captureException(error);

                        return of({});
                    })
                )
            );
        }),
    };
}

@NgModule({
    imports: [CommonModule, MatSelectModule, LetModule, PushModule, TranslocoModule, TranslocoLocaleModule],
    declarations: [
        LocalizeDatePipe,
        LocalizeNumberPipe,
        SystemTranslationPipe,
        HasTranslationPipe,
        HasSystemTranslation,
        LocalizeDatePurePipe,
        LocalizeFriendlyDatePipe,
    ],
    exports: [
        LocalizeDatePipe,
        LocalizeNumberPipe,
        SystemTranslationPipe,
        HasTranslationPipe,
        HasSystemTranslation,
        LocalizeDatePurePipe,
        LocalizeFriendlyDatePipe,
        TranslocoModule,
        TranslocoLocaleModule,
    ],
    providers: [
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        provideTransloco({} as TranslocoOptions),
        provideTranslocoMessageformat({
            locales: Object.values(LANGUAGE_CONFIGURATION.localeMapping),
        }),
        provideTranslocoLocale({
            langToLocaleMapping: LANGUAGE_CONFIGURATION.localeMapping,
        }),
    ],
})
export class SharedI18nModule {}

@NgModule({
    imports: [CommonModule, MatSelectModule, LetModule, PushModule, SharedI18nModule],
    exports: [SharedI18nModule],
    providers: [I18nService, { provide: HTTP_INTERCEPTORS, useClass: DtmLanguageInterceptor, multi: true }],
})
export class I18nRootModule {
    constructor(@Optional() @SkipSelf() parentModule?: I18nRootModule) {
        if (parentModule) {
            throw new Error("I18RootModule is already loaded. Import it in the AppModule only");
        }
    }

    public static forRoot(config: SharedI18nModuleConfig): ModuleWithProviders<I18nRootModule> {
        const providers: Provider[] = [];
        const assetPathProviderFn = config.assetPathProviderFn;

        if (FunctionUtils.isFunction(assetPathProviderFn)) {
            providers.push({
                provide: TRANSLOCO_LOADER,
                useFactory: (http: HttpClient) => new TranslocoHttpLoader(http, assetPathProviderFn),
                deps: [HttpClient],
            });
        }

        return {
            ngModule: I18nRootModule,
            providers: [
                ...providers,
                {
                    provide: APP_INITIALIZER,
                    useFactory: (i18nService: I18nService) => () => i18nService.initialize(),
                    deps: [I18nService],
                    multi: true,
                },
                provideTransloco({
                    config: {
                        availableLangs: [...config.availableLanguages],
                        defaultLang: config.defaultLanguage,
                        fallbackLang: config.fallbackLanguage,
                        reRenderOnLangChange: true,
                        prodMode: !config.developmentMode,
                    },
                }),
                provideTranslocoMessageformat({
                    locales: Object.values(LANGUAGE_CONFIGURATION.localeMapping),
                }),
                provideTranslocoLocale({
                    langToLocaleMapping: LANGUAGE_CONFIGURATION.localeMapping,
                }),
                {
                    provide: MAT_DATE_LOCALE,
                    // TODO: DTM-3987 remove forcing en-GB when backend will change it's locale
                    useValue: config.defaultLanguage === "en" ? "en-GB" : LANGUAGE_CONFIGURATION.localeMapping[config.defaultLanguage],
                },
                {
                    provide: TRANSLOCO_SCOPE,
                    multi: true,
                    useValue: {
                        scope: "dtmUiI18n",
                        loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
                    },
                },
                {
                    provide: TRANSLOCO_SCOPE,
                    multi: true,
                    useFactory: systemTranslationScopeFactory,
                    deps: [HttpClient, [new Optional(), new Inject(TRANSLATION_ENDPOINTS)], [new Optional(), Logger]],
                },
                TranslationHelperService,
                // Message format plurals are throwing error when switching to language with different arguments supported ex. EN -> PL when using 'few'
                // TODO: this ugly workaround should be removed when fixed in lib https://github.com/ngneat/transloco/issues/528
                {
                    provide: TranslocoService,
                    useClass: TranslocoFixService,
                },
            ],
        };
    }
}
