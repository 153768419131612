import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from "@angular/core";
import { DistancePipe } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, METERS_IN_KILOMETER } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CameraService, CesiumEvent, CesiumService, CoordinateConverter, PickOptions, SceneMode } from "@pansa/ngx-cesium";
import angle from "@turf/angle";
import turfBearing from "@turf/bearing";
import turfDistance from "@turf/distance";
import { lineString, point as turfPoint } from "@turf/helpers";
import lineChunk from "@turf/line-chunk";
import midpoint from "@turf/midpoint";
import transformTranslate from "@turf/transform-translate";
import { filter } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { CameraHelperService, MapUtils } from "../../..";
import { CesiumPointerManagerService } from "../../../services/pointer-manager/cesium-pointer-manager.service";

declare const Cesium: any; // TODO: DTM-966

interface PointEntity {
    id: string;
    position: typeof Cesium.Cartesian3;
    distance: number;
    distanceLabel: string;
    labelPosition?: typeof Cesium.Cartesian3;
    pixelOffset?: typeof Cesium.Cartesian2;
}

interface MeasuresCesiumComponentState {
    isMeasureEnabled: boolean;
}

const MEASURE_DEFAULT_COLOR = "#223d6b"; // $color-gray-500
const LABEL_FONT = "16px Manrope, Arial, sans-serif"; // $typography-font-family
const SEGMENT_LENGTH_SCALE_FACTOR = 20;
const DEFAULT_PIXEL_OFFSET_DISTANCE = 50;
const DEFAULT_VERTICAL_PIXEL_OFFSET_DISTANCE = DEFAULT_PIXEL_OFFSET_DISTANCE / 2;
const FIRST_POINT_HORIZONTAL_PIXEL_OFFSET = 30;
const RIGHT_ANGLE_DEG = 90;
const HALF_ANGLE_DEG = 180;
const MAX_SEGMENT_NUMBER = 30;
const LINE_BEARING_DEAD_ZONE_THRESHOLDS_MIN = 160;
const LINE_BEARING_DEAD_ZONE_THRESHOLDS_MAX = 200;

const LINE_ENTITY_ID = "line";
const POINTS_ENTITY_ID = "points";

@UntilDestroy()
@Component({
    selector: "dtm-map-measures-cesium",
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MeasuresCesiumComponent implements OnDestroy {
    @Input() public set isMeasureEnabled(value: BooleanInput) {
        this.localStore.patchState({ isMeasureEnabled: coerceBooleanProperty(value) });
    }

    private readonly measureDataSource = new Cesium.CustomDataSource();
    private measurePoints: PointEntity[] = [];
    private linePoints: (typeof Cesium.Cartesian3)[] = [];
    private movingPoint: typeof Cesium.Entity | undefined;
    private distancePipe: DistancePipe;
    public isMeasureEnabled$ = this.localStore.selectByKey("isMeasureEnabled");

    constructor(
        private readonly cesiumService: CesiumService,
        private readonly coordinateConverter: CoordinateConverter,
        private readonly eventManager: CesiumPointerManagerService,
        private readonly localStore: LocalComponentStore<MeasuresCesiumComponentState>,
        private readonly cameraService: CameraService,
        private readonly cameraHelperService: CameraHelperService,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({ isMeasureEnabled: false });
        this.cesiumService?.getViewer().dataSources.add(this.measureDataSource);

        this.watchMapClickEventsAndHandleMeasure();

        this.cameraHelperService.cameraChanged$.pipe(untilDestroyed(this)).subscribe(() => {
            this.createAndUpdateChunkLines();
        });

        this.distancePipe = new DistancePipe(this.translocoService);
    }

    public ngOnDestroy(): void {
        this.measureDataSource.entities.removeAll();
        this.cesiumService?.getViewer().dataSources.remove(this.measureDataSource);
    }

    public toggleMeasure() {
        this.localStore.patchState(({ isMeasureEnabled }) => ({ isMeasureEnabled: !isMeasureEnabled }));
    }

    private watchMapClickEventsAndHandleMeasure() {
        // NOTE: We need to remove the default double click action to prevent "focus mode" on entities
        this.cesiumService?.getViewer().screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
        this.handleLeftClickAndAddEntities();
        this.handleRightClickAndRemoveEntities();
        this.handleEntityDrag();

        this.isMeasureEnabled$.pipe(untilDestroyed(this)).subscribe((isEnabled) => {
            if (!isEnabled) {
                this.measurePoints = [];
                this.linePoints = [];
                this.refreshPointsOnMap();
                this.cesiumService?.getScene().requestRender();
            } else {
                this.createMainEntities();
            }
        });
    }

    private handleEntityDrag() {
        this.eventManager
            ?.addEventHandler({
                event: CesiumEvent.MOUSE_MOVE,
                pick: PickOptions.PICK_FIRST,
            })
            .pipe(
                withLatestFrom(this.isMeasureEnabled$),
                filter(([, isEnabled]) => isEnabled),
                untilDestroyed(this)
            )
            .subscribe(([event]) => {
                if (!this.movingPoint) {
                    return;
                }
                const position = this.coordinateConverter?.screenToCartesian3(event.movement.endPosition);
                const existingPoint = this.measurePoints.find((point) => point.id === this.movingPoint?.id);
                if (!existingPoint || !position) {
                    return;
                }

                existingPoint.position = position;
                this.recalculateDistances();
                this.refreshLines();
                this.recalculateLabelsOffset();
                this.cesiumService?.getScene().requestRender();
            });

        this.eventManager
            ?.addEventHandler({
                event: CesiumEvent.LEFT_DOWN,
                pick: PickOptions.PICK_ALL,
            })
            .pipe(
                withLatestFrom(this.isMeasureEnabled$),
                filter(([, isEnabled]) => isEnabled),
                untilDestroyed(this)
            )
            .subscribe(([event]) => {
                this.movingPoint = event.cesiumEntities?.find(
                    (entity) => entity.point && this.measurePoints.some((point) => point.id === entity.id)
                );
                if (this.movingPoint) {
                    this.cameraService.enableInputs(false);
                }
            });

        this.eventManager
            ?.addEventHandler({
                event: CesiumEvent.LEFT_UP,
                pick: PickOptions.NO_PICK,
            })
            .pipe(
                withLatestFrom(this.isMeasureEnabled$),
                filter(([, isEnabled]) => isEnabled),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.movingPoint = undefined;
                this.cameraService.enableInputs(true);
            });
    }

    private handleRightClickAndRemoveEntities() {
        this.eventManager
            ?.addEventHandler({
                event: CesiumEvent.RIGHT_CLICK,
                pick: PickOptions.NO_PICK,
            })
            .pipe(
                withLatestFrom(this.isMeasureEnabled$),
                filter(([, isEnabled]) => isEnabled),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.measurePoints.pop();

                this.refreshPointsOnMap();
            });
    }

    private handleLeftClickAndAddEntities() {
        this.eventManager
            ?.addEventHandler({
                event: CesiumEvent.LEFT_CLICK,
                pick: PickOptions.PICK_FIRST,
            })
            .pipe(
                withLatestFrom(this.isMeasureEnabled$),
                filter(([, isEnabled]) => isEnabled),
                untilDestroyed(this)
            )
            .subscribe(([event]) => {
                if (event.cesiumEntities?.some((entity) => entity.parent?.id === POINTS_ENTITY_ID)) {
                    return;
                }
                const position = this.coordinateConverter?.screenToCartesian3(event.movement.endPosition);

                if (!position) {
                    return;
                }
                const pointId = `point-${this.measurePoints.length}`;

                this.measurePoints.push({
                    id: pointId,
                    position,
                    distance: 0,
                    distanceLabel: this.distancePipe.transform(0) ?? "",
                });

                this.refreshPointsOnMap();
            });
    }

    private createMainEntities() {
        const lineEntity = this.measureDataSource.entities.getOrCreateEntity(LINE_ENTITY_ID);
        lineEntity.polyline = {
            positions: new Cesium.CallbackProperty(() => this.linePoints, false),
            width: 4,
            material: Cesium.Color.fromCssColorString(MEASURE_DEFAULT_COLOR),
            clampToGround: false,
        };
        this.measureDataSource.entities.getOrCreateEntity(POINTS_ENTITY_ID);
    }

    private refreshPointsOnMap() {
        this.measurePoints.forEach((point) => {
            if (!this.measureDataSource.entities.getById(point.id)) {
                this.measureDataSource.entities.add({
                    id: point.id,
                    position: new Cesium.CallbackProperty(() => point.position, false),
                    parent: this.measureDataSource.entities.getById(POINTS_ENTITY_ID),
                    point: {
                        pixelSize: 20,
                        color: Cesium.Color.WHITE,
                        outlineColor: Cesium.Color.fromCssColorString(MEASURE_DEFAULT_COLOR),
                        outlineWidth: 2,
                        disableDepthTestDistance: Number.POSITIVE_INFINITY,
                    },
                    label: {
                        text: new Cesium.CallbackProperty(() => point.distanceLabel, false),
                        pixelOffset: new Cesium.CallbackProperty(() => point.pixelOffset, false),
                        fillColor: Cesium.Color.fromCssColorString(MEASURE_DEFAULT_COLOR),
                        font: LABEL_FONT,
                    },
                });
            }

            if (!this.measureDataSource.entities.getById(`${point.id}-2`)) {
                this.measureDataSource.entities.add({
                    id: `${point.id}-2`,
                    position: new Cesium.CallbackProperty(() => point.position, false),
                    parent: this.measureDataSource.entities.getById(POINTS_ENTITY_ID),
                    point: {
                        pixelSize: 6,
                        color: Cesium.Color.fromCssColorString(MEASURE_DEFAULT_COLOR),
                        disableDepthTestDistance: Number.POSITIVE_INFINITY,
                    },
                });
            }
        });
        [...this.measureDataSource.entities.values].forEach((entity: typeof Cesium.Entity) => {
            if (!entity.point) {
                return;
            }

            if (
                !this.measurePoints.find(
                    (point) => point.id === entity.id || entity.id === `${point.id}-2` || entity.parent?.id === LINE_ENTITY_ID
                )
            ) {
                this.measureDataSource.entities.remove(entity);
            }
        });

        this.recalculateDistances();
        this.refreshLines();
        this.recalculateLabelsOffset();

        this.cesiumService?.getScene().requestRender();
    }

    private refreshLines() {
        this.linePoints = this.measurePoints.map((point) => point.position);
        this.createAndUpdateChunkLines();
    }

    private recalculateDistances() {
        this.measurePoints.forEach((currentPoint, index) => {
            const previousPoint = this.measurePoints[index - 1];
            if (!currentPoint.position || !previousPoint?.position) {
                return;
            }
            const cartesianStartPoint = MapUtils.convertCartesian3ToSerializableCartographic(previousPoint.position);
            const cartesianEndPoint = MapUtils.convertCartesian3ToSerializableCartographic(currentPoint.position);

            const startPoint = turfPoint([cartesianStartPoint.longitude, cartesianStartPoint.latitude]);
            const endPoint = turfPoint([cartesianEndPoint.longitude, cartesianEndPoint.latitude]);

            const distance = turfDistance(startPoint, endPoint, {
                units: "meters",
            });

            currentPoint.distance = previousPoint.distance + distance;
            currentPoint.distanceLabel = this.distancePipe.transform(currentPoint.distance) ?? "";
        });
    }

    private createAndUpdateChunkLines() {
        const firstPoint = this.measurePoints[0];
        const secondPoint = this.measurePoints[1];
        const parent = this.measureDataSource.entities.getById(LINE_ENTITY_ID);

        if (!firstPoint || !secondPoint) {
            this.measureDataSource.entities.values
                .filter((entity: typeof Cesium.Entity) => entity.parent === parent)
                .forEach((entity: typeof Cesium.Entity) => this.measureDataSource.entities.remove(entity));

            return;
        }

        const line = lineString(
            this.measurePoints.map(({ position }) => {
                const cartographicPoint = MapUtils.convertCartesian3ToSerializableCartographic(position);

                return [cartographicPoint.longitude, cartographicPoint.latitude];
            })
        );

        const distanceFromFocusPoint = [SceneMode.SCENE2D, SceneMode.COLUMBUS_VIEW].includes(this.cesiumService.getScene().mode)
            ? this.cameraService.getCamera().positionCartographic.height
            : this.cameraHelperService.getDistanceFromFocusPoint();

        const distance = turfDistance(line.geometry.coordinates[0], line.geometry.coordinates[line.geometry.coordinates.length - 1], {
            units: "kilometers",
        });

        let initialSegmentLength = distanceFromFocusPoint / SEGMENT_LENGTH_SCALE_FACTOR / METERS_IN_KILOMETER;

        if (distance / initialSegmentLength > MAX_SEGMENT_NUMBER) {
            initialSegmentLength = distance / MAX_SEGMENT_NUMBER;
        }

        if (initialSegmentLength < 1) {
            initialSegmentLength = 1;
        }

        const segmentLength = this.roundToNearestPowerOfTen(initialSegmentLength);

        const lineChunks = lineChunk(line, segmentLength, { units: "kilometers" });

        const chunksOffset = lineChunks.features.map((feature) => {
            const bearing = turfBearing(feature.geometry.coordinates[0], feature.geometry.coordinates[1]);
            const perpendicularBearing = bearing + RIGHT_ANGLE_DEG;
            const MEASURE_LINES_SCALE_FACTOR = 150;
            const measureScaleLineLength = distanceFromFocusPoint / MEASURE_LINES_SCALE_FACTOR / METERS_IN_KILOMETER;

            return transformTranslate(feature, measureScaleLineLength, perpendicularBearing, { units: "kilometers" });
        });

        lineChunks.features.forEach((feature, index) => {
            const entity = this.measureDataSource.entities.getById(`chunk-${index}`);

            const positions = Cesium.Cartesian3.fromDegreesArray([
                feature.geometry.coordinates[0][0],
                feature.geometry.coordinates[0][1],
                chunksOffset[index].geometry.coordinates[0][0],
                chunksOffset[index].geometry.coordinates[0][1],
            ]);
            if (!entity) {
                this.measureDataSource.entities.add({
                    id: `chunk-${index}`,
                    parent,
                    polyline: {
                        positions: new Cesium.CallbackProperty(() => positions, false),
                        width: 2,
                        material: Cesium.Color.fromCssColorString(MEASURE_DEFAULT_COLOR),
                    },
                });
            } else {
                entity.polyline.positions = new Cesium.CallbackProperty(() => positions, false);
            }
        });

        this.measureDataSource.entities.values
            .filter((entity: typeof Cesium.Entity) => entity.parent === parent)
            .splice(lineChunks.features.length)
            .forEach((entity: typeof Cesium.Entity) => this.measureDataSource.entities.remove(entity));
    }

    private roundToNearestPowerOfTen(number: number) {
        if (number === 0) {
            return 0;
        }
        const power = Math.round(Math.log10(Math.abs(number)));

        const ROUND_TO_VALUE = 10;

        return Math.sign(number) * Math.pow(ROUND_TO_VALUE, power);
    }

    private recalculateLabelsOffset() {
        if (this.measurePoints.length === 1) {
            this.measurePoints[0].pixelOffset = new Cesium.Cartesian2(0, DEFAULT_VERTICAL_PIXEL_OFFSET_DISTANCE);

            return;
        }

        if (this.measurePoints.length < 2) {
            return;
        }
        this.measurePoints.forEach((point, index) => {
            const previousPoint = this.measurePoints[index - 1];
            const nextPoint = this.measurePoints[index + 1];
            const currentPoint = MapUtils.convertCartesian3ToSerializableCartographic(point.position);

            const cartesianPreviousPoint = previousPoint
                ? MapUtils.convertCartesian3ToSerializableCartographic(previousPoint.position)
                : undefined;
            const cartesianNextPoint = nextPoint ? MapUtils.convertCartesian3ToSerializableCartographic(nextPoint.position) : undefined;

            const currentPointGeoJson = turfPoint([currentPoint.longitude, currentPoint.latitude]);
            const previousPointGeoJson = cartesianPreviousPoint
                ? turfPoint([cartesianPreviousPoint.longitude, cartesianPreviousPoint.latitude])
                : undefined;
            const nextPointGeoJson = cartesianNextPoint
                ? turfPoint([cartesianNextPoint.longitude, cartesianNextPoint.latitude])
                : undefined;

            const isAnyPreviousPointMissing = !cartesianPreviousPoint || !previousPointGeoJson;
            const isAnyNextPointMissing = !cartesianNextPoint || !nextPointGeoJson;

            if (isAnyNextPointMissing && isAnyPreviousPointMissing) {
                return;
            }

            if (isAnyPreviousPointMissing && !isAnyNextPointMissing) {
                const firstLineBearing = turfBearing(currentPointGeoJson, nextPointGeoJson, { final: true }) + RIGHT_ANGLE_DEG;
                const firstPointPixelOffset = new Cesium.Cartesian2(0, 0);

                firstPointPixelOffset.x = FIRST_POINT_HORIZONTAL_PIXEL_OFFSET * Math.cos((firstLineBearing * Math.PI) / HALF_ANGLE_DEG);
                firstPointPixelOffset.y = DEFAULT_VERTICAL_PIXEL_OFFSET_DISTANCE * Math.sin((firstLineBearing * Math.PI) / HALF_ANGLE_DEG);

                point.pixelOffset = firstPointPixelOffset;

                return;
            }

            if (isAnyNextPointMissing && !isAnyPreviousPointMissing) {
                const lastLineBearing = turfBearing(previousPointGeoJson, currentPointGeoJson, { final: true }) - RIGHT_ANGLE_DEG;
                const lastPointPixelOffset = new Cesium.Cartesian2(0, 0);

                lastPointPixelOffset.x =
                    (point.distanceLabel.length + DEFAULT_PIXEL_OFFSET_DISTANCE) * Math.cos((lastLineBearing * Math.PI) / HALF_ANGLE_DEG);
                lastPointPixelOffset.y = DEFAULT_VERTICAL_PIXEL_OFFSET_DISTANCE * Math.sin((lastLineBearing * Math.PI) / HALF_ANGLE_DEG);

                point.pixelOffset = lastPointPixelOffset;

                return;
            }

            if (isAnyNextPointMissing || isAnyPreviousPointMissing) {
                return;
            }

            const midPoint = midpoint(
                [cartesianPreviousPoint.longitude, cartesianPreviousPoint.latitude],
                [cartesianNextPoint.longitude, cartesianNextPoint.latitude]
            );

            let bearing = turfBearing(currentPointGeoJson, midPoint, { final: true });
            bearing -= RIGHT_ANGLE_DEG + HALF_ANGLE_DEG;

            const linesAngle = angle(previousPointGeoJson, currentPointGeoJson, nextPointGeoJson);

            if (linesAngle > LINE_BEARING_DEAD_ZONE_THRESHOLDS_MIN && linesAngle < LINE_BEARING_DEAD_ZONE_THRESHOLDS_MAX) {
                bearing = turfBearing(previousPointGeoJson, nextPointGeoJson, { final: true });
            }

            const pixelOffset = new Cesium.Cartesian2(0, 0);

            pixelOffset.x = (point.distanceLabel.length + DEFAULT_PIXEL_OFFSET_DISTANCE) * Math.cos((bearing * Math.PI) / HALF_ANGLE_DEG);
            pixelOffset.y = DEFAULT_VERTICAL_PIXEL_OFFSET_DISTANCE * Math.sin((bearing * Math.PI) / HALF_ANGLE_DEG);

            point.pixelOffset = pixelOffset;
        });
    }
}
