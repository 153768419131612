import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { AssociationPermit } from "../../models/permits.models";

interface AssociationPermitComponentState {
    permit: AssociationPermit | undefined;
    isCaaContactTileExpanded: boolean;
    isOperatorInfoTileExpanded: boolean;
    isPermitDetailsTileExpanded: boolean;
    isOperatorContactPersonVisible: boolean;
}

@Component({
    selector: "dtm-ui-association-permit-preview[permit]",
    templateUrl: "./association-permit-preview.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AssociationPermitPreviewComponent {
    @Input() public set permit(value: AssociationPermit | undefined) {
        this.localStore.patchState({ permit: value });
    }
    @Input() public set isCaaContactTileExpanded(value: BooleanInput) {
        this.localStore.patchState({ isCaaContactTileExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set isOperatorInfoTileExpanded(value: BooleanInput) {
        this.localStore.patchState({ isOperatorInfoTileExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set isPermitDetailsTileExpanded(value: BooleanInput) {
        this.localStore.patchState({ isPermitDetailsTileExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set isOperatorContactPersonVisible(value: BooleanInput) {
        this.localStore.patchState({ isOperatorContactPersonVisible: coerceBooleanProperty(value) });
    }

    protected readonly permit$ = this.localStore.selectByKey("permit");
    protected readonly isCaaContactTileExpanded$ = this.localStore.selectByKey("isCaaContactTileExpanded");
    protected readonly isOperatorInfoTileExpanded$ = this.localStore.selectByKey("isOperatorInfoTileExpanded");
    protected readonly isOperatorContactPersonVisible$ = this.localStore.selectByKey("isOperatorContactPersonVisible");
    protected readonly isPermitDetailsTileExpanded$ = this.localStore.selectByKey("isPermitDetailsTileExpanded");

    constructor(private readonly localStore: LocalComponentStore<AssociationPermitComponentState>) {
        localStore.setState({
            isCaaContactTileExpanded: false,
            isOperatorInfoTileExpanded: false,
            isPermitDetailsTileExpanded: false,
            isOperatorContactPersonVisible: false,
            permit: undefined,
        });
    }
}
