import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

interface DetailsComponentState {
    isOpen: boolean;
    title: string;
}

@Component({
    selector: "dtm-ui-details[title]",
    templateUrl: "./details.component.html",
    styleUrls: ["./details.component.scss"],
    animations: [AnimationUtils.slideInAnimation()],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class DetailsComponent {
    @Input() public set title(value: string | undefined) {
        this.localStore.patchState({ title: value ?? "" });
    }
    @Input() public set isOpen(value: BooleanInput) {
        this.localStore.patchState({ isOpen: coerceBooleanProperty(value) });
    }

    protected readonly isOpen$ = this.localStore.selectByKey("isOpen");
    protected readonly title$ = this.localStore.selectByKey("title");

    constructor(private readonly localStore: LocalComponentStore<DetailsComponentState>) {
        this.localStore.setState({
            isOpen: false,
            title: "",
        });
    }

    protected toggleDetails(): void {
        this.localStore.patchState(({ isOpen }) => ({ isOpen: !isOpen }));
    }
}
