import { TimeRange } from "@dtm-frontend/shared/ui";
import { ArrayElementType } from "@dtm-frontend/shared/utils";
import {
    AirspaceElement,
    AirspaceElementResponseBody,
    AirspaceElementsInfo,
    AirspaceElementStatus,
    AirspaceScope,
    GeoZoneType,
    ScopeResponseElement,
} from "../models/geo-zones.models";

const TOOLTIP_REGEX = /(.+?)<tooltip>(.+?)<\/tooltip>/;

function parseTextWithTooltip(string: string): { text: string; tooltip?: string } {
    const matches = TOOLTIP_REGEX.exec(string);

    if (!matches) {
        return { text: string };
    }

    return {
        text: matches[1],
        tooltip: matches[2],
    };
}

function convertScope(scope: ScopeResponseElement): AirspaceScope {
    return {
        startTime: new Date(scope.startTime),
        endTime: new Date(scope.endTime),
        lowerLimit: Math.ceil(scope.lowerLimit), // NOTE: Backend rounds up in analysis messages
        upperLimit: Math.ceil(scope.upperLimit),
    };
}

const DTM_TAG_NAME = "DTM";

function isDtm(element: ArrayElementType<AirspaceElementResponseBody["airspaceElements"]>) {
    return element.tag?.includes(DTM_TAG_NAME);
}

export function convertAirspaceElementResponseBodyToAirspaceElement(
    response: AirspaceElementResponseBody,
    endTime?: Date | null,
    scopeTimeRange?: TimeRange
): AirspaceElementsInfo {
    const aupEndTime = response.aupEndTime ? new Date(response.aupEndTime) : undefined;
    const airacEndTime = response.airacEndTime ? new Date(response.airacEndTime) : undefined;
    const doesAupExist = !!(aupEndTime && endTime && aupEndTime.getTime() >= endTime.getTime());

    return {
        airspaceElements: response.airspaceElements
            .filter(({ geozone }) => geozone)
            .map((element) => ({
                activeTime: {
                    from: new Date(element.scope.startTime),
                    to: new Date(element.scope.startTime),
                },
                geometry: element.geometry,
                id: element.airspaceElementId ?? element.airspaceElementIds[0],
                ids: element.airspaceElementIds,
                isActive: element.status === AirspaceElementStatus.Active,
                isStatic: element.staticElement,
                geoZoneType: isDtm(element) ? GeoZoneType.DroneTrafficManagement : element.geozone,
                type: element.type,
                data: element, // NOTE: Debugging purpose
                designator: element.designator,
                reservations: element.reservations
                    .map((reservation) => ({
                        id: reservation.id,
                        reservedBy: reservation.reservedBy,
                        scope: convertScope(reservation.scope),
                        status: reservation.status,
                    }))
                    .sort((left, right) => left.scope.startTime.getTime() - right.scope.startTime.getTime()),
                scope: convertScope(element.scope),
                information: element.information?.reduce<AirspaceElement["information"]>(
                    (information, currentInformation) => ({
                        ...information,
                        [currentInformation.languageTag]: {
                            flightConditions:
                                currentInformation.flightConditions && currentInformation.flightConditions.length > 1
                                    ? [parseTextWithTooltip(currentInformation.flightConditions)]
                                    : undefined,
                            description: parseTextWithTooltip(currentInformation.description),
                            contact: currentInformation.contact,
                        },
                    }),
                    {}
                ),
                isH24: !!element.h24,
                parent: element.parent,
                supervisor: element.supervisor,
                isOrdered: element.reservations.some((reservation) => reservation.status === AirspaceElementStatus.Active),
                zoneAttributes: element.zoneAttributes
                    ? {
                          isAdsbForBvlosRequired: element.zoneAttributes.adsbForBvlosRequired,
                          isBvlosFlightsForbidden: element.zoneAttributes.bvlosFlightsForbidden,
                          isBvlosPathFlightsForbidden: element.zoneAttributes.bvlosPathFlightsForbidden,
                          isVlosFlightsForbidden: element.zoneAttributes.vlosFlightsForbidden,
                          localInformationType: element.zoneAttributes.localInformationType,
                          name: element.zoneAttributes.name,
                      }
                    : undefined,
            })),
        doesAupExist,
        aupEndTime,
        scopeTimeRange: scopeTimeRange,
        airacEndTime,
    };
}
