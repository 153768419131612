<dtm-ui-lib-wizard-header [wizardId]="FLIGHT_ZONE_WIZARD_ID" *ngIf="shouldShowWizardHeader$ | ngrxPush">
    <dtm-ui-lib-wizard-step-header [stepId]="FlightZoneWizardSteps.BasicZoneInfo">
        {{ "dssClientLibFlightZone.flightZoneWizardSteps.basicZoneInfo.header" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="FlightZoneWizardSteps.ZoneGeometry">
        {{ "dssClientLibFlightZone.flightZoneWizardSteps.zoneGeometry.header" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="FlightZoneWizardSteps.ExclusionsFromRestrictions">
        {{ "dssClientLibFlightZone.flightZoneWizardSteps.exclusionsFromRestrictions.header" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="FlightZoneWizardSteps.Summary">
        {{ "dssClientLibFlightZone.flightZoneWizardSteps.summary.header" | transloco }}
    </dtm-ui-lib-wizard-step-header>
</dtm-ui-lib-wizard-header>
