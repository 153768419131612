import { ISO8601TimeDuration, TimeDurationItems } from "./models";

const NUMBER_RE = "(\\d+(?:\\.\\d+)?)";
const DURATION_REGEXP = new RegExp(
    `^P(?!$)(${NUMBER_RE}Y)?(${NUMBER_RE}M)?(${NUMBER_RE}W)?(${NUMBER_RE}D)?(T(?=\\d)(${NUMBER_RE}H)?(${NUMBER_RE}M)?(${NUMBER_RE}S)?)?$`
);

export function extract(duration: ISO8601TimeDuration): TimeDurationItems | null {
    const durationParts = duration.match(DURATION_REGEXP);

    if (durationParts === null) {
        return null;
    }

    const [, , years, , months, , weeks, , days, , , hours, , minutes, , seconds] = [...durationParts];

    const result: TimeDurationItems = {};

    if (years) {
        result.years = +years;
    }

    if (months) {
        result.months = +months;
    }

    if (weeks) {
        result.weeks = +weeks;
    }

    if (days) {
        result.days = +days;
    }

    if (hours) {
        result.hours = +hours;
    }

    if (minutes) {
        result.minutes = +minutes;
    }

    if (seconds) {
        result.seconds = +seconds;
    }

    return result;
}
