import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

interface LoginComponentState {
    hasLoginError: boolean;
    isProcessing: boolean;
}

interface LoginCredentials {
    username: string;
    password: string;
}

@UntilDestroy()
@Component({
    selector: "dtm-auth-login",
    templateUrl: "./login.component.html",
    styleUrls: ["../../../../authorization-form.scss", "./login.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LoginComponent {
    @Input() public set hasLoginError(value: BooleanInput) {
        const hasLoginError = coerceBooleanProperty(value);
        this.localStore.patchState({ hasLoginError });
        if (hasLoginError) {
            this.loginForm.controls.password.reset("", { emitEvent: false });
        }
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Output() protected readonly passwordReset = new EventEmitter<void>();
    @Output() protected readonly register = new EventEmitter<void>();
    @Output() protected readonly login = new EventEmitter<LoginCredentials>();

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly hasLoginError$ = this.localStore.selectByKey("hasLoginError");

    protected readonly loginForm = new FormGroup({
        username: new FormControl<string>("", { validators: [Validators.required], nonNullable: true }),
        password: new FormControl<string>("", { validators: [Validators.required], nonNullable: true }),
    });

    constructor(private readonly localStore: LocalComponentStore<LoginComponentState>) {
        this.localStore.setState({
            hasLoginError: false,
            isProcessing: false,
        });

        this.watchFormValueChanges();
    }

    protected submit() {
        this.loginForm.markAllAsTouched();

        if (this.loginForm.invalid) {
            return;
        }

        const { username, password } = this.loginForm.getRawValue();

        this.login.emit({ username, password });
    }

    private watchFormValueChanges(): void {
        this.loginForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.localStore.patchState({ hasLoginError: false }));
    }
}
