<ng-container *ngrxLet="isShown$ as isShown">
    <ac-layer #editPointsLayer acFor="let point of editPoints$" [context]="this" [show]="isShown" [zIndex]="2">
        <ac-point-desc
            props="{
                position: point.getPositionCallbackProperty(),
                pixelSize: getPointSize(point),
                color: getPointColor(point),
                outlineColor: getPointOutlineColor(point),
                outlineWidth: getPointOutlineWidth(point),
                show: getPointShow(point),
                disableDepthTestDistance: point.props.disableDepthTestDistance,
                heightReference: Cesium.HeightReference.NONE
            }"
        >
        </ac-point-desc>
    </ac-layer>

    <ac-layer #editCylindersLayer acFor="let cylinder of editCylinders$" [context]="this" [zIndex]="0" [show]="isShown">
        <ac-circle-desc
            props="{
                position: cylinder.getCenterCallbackProperty(),
                radius: cylinder.getRadiusCallbackProperty(),
                granularity: 0.01,
                fill: cylinder.cylinderProps.fill,
                material: cylinder.cylinderProps.material,
                outline: true,
                outlineColor: cylinder.cylinderProps.outlineColor,
                classificationType: cylinder.cylinderProps.classificationType,
                heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                height: cylinder.getBottomHeightCallbackProperty(),
                shadows: cylinder.cylinderProps.shadows,
                extrudedHeight: cylinder.getTopHeightCallbackProperty()
            }"
        >
        </ac-circle-desc>

        <ac-circle-desc
            props="{
                position: cylinder.getCenterCallbackProperty(),
                radius: cylinder.getRadiusCallbackProperty(),
                granularity: 0.01,
                material: cylinder.cylinderProps.shadowMaterial,
                outline: true,
                outlineColor: cylinder.cylinderProps.outlineColor,
                heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                height: 0
            }"
        >
        </ac-circle-desc>
    </ac-layer>

    <ac-layer
        #cylinderLabelsLayer
        acFor="let label of cylinderLabels$"
        [context]="this"
        [show]="isShown"
        [zIndex]="2"
        [store]="true"
        *ngrxLet="cylinderLabelsTemplate$ as labelTemplate"
    >
        <ac-html-desc
            *ngIf="labelTemplate; else simpleLabelsTemplate"
            props="{
                    position: label.position,
                    show: label.show
                }"
        >
            <ng-template let-label>
                <ng-container *ngIf="isShown">
                    <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: label }"></ng-container>
                </ng-container>
            </ng-template>
        </ac-html-desc>
        <ng-template #simpleLabelsTemplate>
            <ac-label-primitive-desc
                props="{
                    position: label.position,
                    backgroundColor: label.backgroundColor,
                    backgroundPadding: label.backgroundPadding,
                    distanceDisplayCondition: label.distanceDisplayCondition,
                    eyeOffset: label.eyeOffset,
                    fillColor: label.fillColor,
                    font: label.font,
                    heightReference: label.heightReference,
                    horizontalOrigin: label.horizontalOrigin,
                    outlineColor: label.outlineColor,
                    outlineWidth: label.outlineWidth,
                    pixelOffset: label.pixelOffset,
                    pixelOffsetScaleByDistance: label.pixelOffsetScaleByDistance,
                    scale: label.scale,
                    scaleByDistance: label.scaleByDistance,
                    show: cylinder.shouldShowLabel(label),
                    showBackground: label.showBackground,
                    style: label.style,
                    text: label.text,
                    translucencyByDistance: label.translucencyByDistance,
                    verticalOrigin: label.verticalOrigin,
                    disableDepthTestDistance: label.disableDepthTestDistance
                }"
            >
            </ac-label-primitive-desc>
        </ng-template>
    </ac-layer>

    <ac-layer #editHeightOutlinesLayer acFor="let outline of editHeightOutlines$" [context]="this" [zIndex]="1" [show]="isShown">
        <ac-circle-desc
            props="{
                position: outline.parent.getCenterCallbackProperty(),
                radius: outline.parent.getRadiusCallbackProperty(),
                granularity: 0.01,
                material: Cesium.Color.TRANSPARENT,
                outline: true,
                outlineColor: outline.parent.cylinderProps.outlineColor,
                heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                height: outline.getHeightCallbackProperty()
            }"
        >
        </ac-circle-desc>
    </ac-layer>
</ng-container>
