import { MissionType } from "@dtm-frontend/shared/mission";

export interface MissionFilters {
    designator?: string | null;
    sort?: string | null;
    flightDateFrom?: Date | null;
    flightDateTo?: Date | null;
    phase?: MissionProcessingPhase | null;
}

export interface PlannedMission {
    id: string;
    name: string;
    phase: MissionProcessingPhase;
    flightStartAt: Date;
    flightFinishAt: Date;
    flightStartAtMax: Date;
    flightStartAtMin: Date;
    flightFinishAtMin: Date;
    flightFinishAtMax: Date;
    flightType: MissionType;
    designator: string;
    routeId: string;
    pilotName: string;
    uavName: string;
}

export enum MissionProcessingPhase {
    Accepted = "ACCEPTED",
    Rejected = "REJECTED",
    Waiting = "NOT_CONSIDERED",
}

export enum PlannedMissionErrorType {
    Unknown = "Unknown",
    CannotGetMissionList = "CannotGetMissionList",
    CannotGetMissionRoute = "CannotGetMissionRoute",
    CannotChangeMissionPhase = "CannotChangeMissionPhase",
}

export interface PlannedMissionError {
    type: PlannedMissionErrorType;
}

export enum MissionsEvents {
    ManualPlanVerificationSubmittedEvent = "ManualPlanVerificationSubmittedEvent",
    MissionAcceptedEvent = "MissionAcceptedEvent",
}

export interface PhasePayloadData {
    id: string;
    missionPhase: MissionProcessingPhase;
    note?: string;
}
