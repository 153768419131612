import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AirspaceElement, AirspaceElementStatus, GeoZoneType, GeoZonesUtils, Reservation } from "@dtm-frontend/shared/map/geo-zones";
import { IconName } from "@dtm-frontend/shared/ui";
import { DEFAULT_LANG, I18nService, LOCALE_MAPPING } from "@dtm-frontend/shared/ui/i18n";
import { ArrayUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { map } from "rxjs/operators";
import {
    EvaluationIssueStatus,
    FlightRules,
    MissionPlanAnalysisEvaluationIssue,
    MissionPlanAnalysisIssue,
    MissionProcessingPhaseExtended,
    Requirement,
} from "../../models";

interface ZoneInfoComponentState {
    zone: AirspaceElement | undefined;
    isZoneSelected: boolean;
    analysisIssues: MissionPlanAnalysisIssue[];
    evaluationIssues: MissionPlanAnalysisEvaluationIssue[];
    doesAupExist: boolean;
    shouldHideRequirements: boolean;
    additionalRequirements: Requirement[];
    flightRules: FlightRules[];
    missionPhase: MissionProcessingPhaseExtended | undefined;
    airacEndTime: Date | undefined;
}

@Component({
    selector: "dtm-mission-zone-info[zone]",
    templateUrl: "./zone-info.component.html",
    styleUrls: ["./zone-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ZoneInfoComponent {
    @Input() public set zone(value: AirspaceElement | undefined) {
        this.localStore.patchState({ zone: value });
    }
    @Input() public set isZoneSelected(value: boolean) {
        this.localStore.patchState({ isZoneSelected: value });
    }
    @Input() public set analysisIssues(value: MissionPlanAnalysisIssue[]) {
        this.localStore.patchState({ analysisIssues: value ?? [] });
    }
    @Input() public set evaluationIssues(value: MissionPlanAnalysisEvaluationIssue[]) {
        this.localStore.patchState({ evaluationIssues: value ?? [] });
    }
    @Input() public set doesAupExist(value: boolean) {
        this.localStore.patchState({ doesAupExist: value });
    }
    @Input() public set shouldHideRequirements(value: BooleanInput) {
        this.localStore.patchState({ shouldHideRequirements: coerceBooleanProperty(value) });
    }
    @Input() public set additionalRequirements(value: { text: string; className: string; iconName: IconName }[]) {
        this.localStore.patchState({ additionalRequirements: value });
    }
    @Input() public set flightRules(value: FlightRules[] | undefined) {
        this.localStore.patchState({ flightRules: value ?? [] });
    }
    @Input() public set missionPhase(value: MissionProcessingPhaseExtended | undefined) {
        this.localStore.patchState({ missionPhase: value });
    }
    @Input() public set airacEndTime(value: Date | undefined) {
        this.localStore.patchState({ airacEndTime: value });
    }

    @Output() public readonly zoneSelectionChange = new EventEmitter<AirspaceElement>();

    protected readonly zone$ = this.localStore.selectByKey("zone");
    protected readonly isZoneSelected$ = this.localStore.selectByKey("isZoneSelected");
    protected readonly activeLanguageCode$ = this.i18nService.currentLanguage$.pipe(
        map((language) => LOCALE_MAPPING[language] ?? LOCALE_MAPPING[DEFAULT_LANG])
    );
    protected readonly analysisIssues$ = this.localStore.selectByKey("analysisIssues");
    protected readonly evaluationIssues$ = this.localStore.selectByKey("evaluationIssues");
    protected readonly doesAupExist$ = this.localStore.selectByKey("doesAupExist");
    protected readonly shouldHideRequirements$ = this.localStore.selectByKey("shouldHideRequirements");
    protected readonly additionalRequirements$ = this.localStore.selectByKey("additionalRequirements");
    protected readonly flightRules$ = this.localStore.selectByKey("flightRules");
    protected readonly missionPhase$ = this.localStore.selectByKey("missionPhase");
    protected readonly airacEndTime$ = this.localStore.selectByKey("airacEndTime");

    protected readonly EvaluationIssueStatus = EvaluationIssueStatus;
    protected readonly GeoZoneType = GeoZoneType;

    protected readonly getZoneIconName = GeoZonesUtils.getZoneIconName;

    constructor(private readonly localStore: LocalComponentStore<ZoneInfoComponentState>, private readonly i18nService: I18nService) {
        localStore.setState({
            zone: undefined,
            isZoneSelected: false,
            analysisIssues: [],
            evaluationIssues: [],
            doesAupExist: false,
            shouldHideRequirements: false,
            additionalRequirements: [],
            flightRules: [],
            missionPhase: undefined,
            airacEndTime: undefined,
        });
    }

    protected partitionAndGroupReservationsByScope(reservations: Reservation[]) {
        return ArrayUtils.partition(reservations, (reservation) => reservation.status === AirspaceElementStatus.Active).map(
            (partitionedReservations) => {
                const sortedReservations = partitionedReservations.sort((left, right) => {
                    const {
                        startTime: startTimeLeft,
                        endTime: endTimeLeft,
                        lowerLimit: lowerLimitLeft,
                        upperLimit: upperLimitLeft,
                    } = left.scope;
                    const {
                        startTime: startTimeRight,
                        endTime: endTimeRight,
                        lowerLimit: lowerLimitRight,
                        upperLimit: upperLimitRight,
                    } = right.scope;

                    if (startTimeLeft.getTime() !== startTimeRight.getTime()) {
                        return startTimeLeft.getTime() - startTimeRight.getTime();
                    }

                    if (endTimeLeft.getTime() !== endTimeRight.getTime()) {
                        return endTimeLeft.getTime() - endTimeRight.getTime();
                    }

                    if (lowerLimitLeft !== lowerLimitRight) {
                        return lowerLimitLeft - lowerLimitRight;
                    }

                    return upperLimitLeft - upperLimitRight;
                });

                // group sibling reservations with the same limits
                return sortedReservations.reduce<Reservation[][]>((result, reservation, index) => {
                    if (
                        index !== 0 &&
                        sortedReservations[index - 1].scope.lowerLimit === reservation.scope.lowerLimit &&
                        sortedReservations[index - 1].scope.upperLimit === reservation.scope.upperLimit
                    ) {
                        result[result.length - 1].push(reservation);
                    } else {
                        result.push([reservation]);
                    }

                    return result;
                }, []);
            }
        );
    }

    protected isPredictedReservation(reservation: Reservation, airacEndTime?: Date): boolean {
        if (!airacEndTime) {
            return false;
        }

        return reservation.scope.startTime.getTime() > airacEndTime.getTime();
    }
}
