<form class="email-editor" [formGroup]="emailChangeFormGroup">
    <ng-container *ngIf="isVerificationRequired$ | ngrxPush">
        <ng-container *ngIf="emailConfirmationDescription$ | ngrxPush as emailConfirmationDescription">
            {{ emailConfirmationDescription }}
        </ng-container>
    </ng-container>
    <dtm-ui-label-value *ngIf="email$ | ngrxPush as email" [label]="'dtmUi.editEmailAddress.currentEmailAddressLabel' | transloco">
        <span class="current-email">{{ email }}</span>
    </dtm-ui-label-value>
    <dtm-ui-input-field *ngIf="!(isRequestedEmailChange$ | ngrxPush); else verificationCodeTemplate">
        <label>{{ "dtmUi.editEmailAddress.newEmailAddressLabel" | transloco }}</label>
        <input matInput formControlName="email" type="text" />
        <div class="field-error" *dtmUiFieldHasError="emailChangeFormGroup.controls.email; name: 'email'">
            {{ "dtmUi.editEmailAddress.invalidEmailError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="emailChangeFormGroup.controls.email; name: ['required', 'requiredTouched']">
            {{ "dtmUi.editEmailAddress.fieldRequiredError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="emailChangeFormGroup.controls.email; name: 'sameValue'">
            {{ "dtmUi.editEmailAddress.emailValueSameAsCurrentError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="emailChangeFormGroup.controls.email; name: 'emailAddressConflict'">
            {{ "dtmUi.editEmailAddress.emailConflict" | transloco }}
        </div>
    </dtm-ui-input-field>
    <!--TODO: [REJ-716] - Waiting for separate verification code component -->
    <ng-template #verificationCodeTemplate>
        <dtm-ui-input-field>
            <label>{{ "dtmUi.editEmailAddress.verificationCodeLabel" | transloco }}</label>
            <input matInput formControlName="verificationCode" type="text" [mask]="VERIFICATION_CODE_MASK" [showMaskTyped]="true" />
            <div class="field-error" *dtmUiFieldHasError="emailChangeFormGroup.controls.verificationCode; name: 'required'">
                {{ "dtmUi.editEmailAddress.fieldRequiredError" | transloco }}
            </div>
        </dtm-ui-input-field>
        <div class="resend-code">
            <button type="button" class="button-tertiary" (click)="resendVerificationCode.emit()">
                {{ "dtmUi.editEmailAddress.sendAgain" | transloco }}
            </button>
        </div>
    </ng-template>
</form>
