import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

export enum ErrorMode {
    Primary = "PRIMARY",
    Secondary = "SECONDARY",
}

interface DownloadErrorComponentState {
    mode: ErrorMode;
    errorMessage: string | undefined;
    isRefreshButtonVisible: boolean;
    imageSrc: string;
}

const DEFAULT_ERROR_IMAGE_SRC = "assets/images/download-error.svg";

@Component({
    selector: "dtm-ui-error",
    templateUrl: "./error.component.html",
    styleUrls: ["./error.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ErrorComponent implements OnInit {
    @Input() public set errorMessage(value: string | undefined) {
        this.localStore.patchState({ errorMessage: value });
    }
    @Input() public set imageSrc(value: string | undefined) {
        this.localStore.patchState({ imageSrc: value ?? DEFAULT_ERROR_IMAGE_SRC });
    }
    @Input() public mode: ErrorMode = ErrorMode.Primary;

    @Output() public reload = new EventEmitter<void>();

    @HostBinding("class") public get modeClass() {
        let classPostfix: string;

        switch (this.mode) {
            case ErrorMode.Secondary:
                classPostfix = "secondary";
                break;
            case ErrorMode.Primary:
            default:
                classPostfix = "primary";
                break;
        }

        return `error-${classPostfix}`;
    }

    public readonly errorMessage$ = this.localStore.selectByKey("errorMessage");
    public readonly imageSrc$ = this.localStore.selectByKey("imageSrc");
    public readonly isRefreshButtonVisible$ = this.localStore.selectByKey("isRefreshButtonVisible");

    constructor(private localStore: LocalComponentStore<DownloadErrorComponentState>) {
        localStore.setState({
            mode: ErrorMode.Primary,
            errorMessage: undefined,
            isRefreshButtonVisible: false,
            imageSrc: DEFAULT_ERROR_IMAGE_SRC,
        });
    }

    public ngOnInit() {
        this.localStore.patchState({ isRefreshButtonVisible: this.reload.observed });
    }
}
