<ng-container *ngrxLet="maxLength$; let maxLength">
    <dtm-ui-form-field [isDisabled]="isDisabled$ | ngrxPush" [isActivated]="isActivated" *ngrxLet="isActivated$; let isActivated">
        <ng-content select="label" ngProjectAs="label"></ng-content>
        <div class="textarea-field" [class.activated]="isActivated">
            <ng-content></ng-content>
        </div>
        <div class="field-hint value-length-counter" *ngIf="maxLength">{{ currentLength$ | ngrxPush }} / {{ maxLength }}</div>
        <ng-content select=".field-hint" ngProjectAs=".field-hint"></ng-content>
        <ng-content select=".field-error" ngProjectAs=".field-error"></ng-content>
    </dtm-ui-form-field>
</ng-container>
