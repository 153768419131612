import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Permit } from "../../models/permits.models";
import { UploadedFile } from "../files-upload-field/files-upload.models";

interface PermitDetailsPreviewComponentState {
    permit: Permit | undefined;
    areActionButtonsVisible: boolean;
    isOperatorDetailsTileExpanded: boolean;
    isContactPersonTileExpanded: boolean;
    isRestrictionsTileExpanded: boolean;
    isPermitDetailsExpanded: boolean;
    isOperatorContactPersonVisible: boolean;
}

@Component({
    selector: "dtm-ui-permit-details-preview[permit]",
    templateUrl: "./permit-details-preview.component.html",
    styleUrls: ["./permit-details-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PermitDetailsPreviewComponent {
    @Input() public set permit(value: Permit | undefined) {
        this.localStore.patchState({ permit: value });
    }
    @Input() public set areActionButtonsVisible(value: BooleanInput) {
        this.localStore.patchState({ areActionButtonsVisible: coerceBooleanProperty(value) });
    }
    @Input() public set isOperatorDetailsTileExpanded(value: BooleanInput) {
        this.localStore.patchState({ isOperatorDetailsTileExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set isContactPersonTileExpanded(value: BooleanInput) {
        this.localStore.patchState({ isContactPersonTileExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set isRestrictionsTileExpanded(value: BooleanInput) {
        this.localStore.patchState({ isRestrictionsTileExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set isPermitDetailsExpanded(value: BooleanInput) {
        this.localStore.patchState({ isPermitDetailsExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set isOperatorContactPersonVisible(value: BooleanInput) {
        this.localStore.patchState({ isOperatorContactPersonVisible: coerceBooleanProperty(value) });
    }

    @Output() public readonly uavDetailsEdit = new EventEmitter<void>();
    @Output() public readonly basicDataEdit = new EventEmitter<void>();
    @Output() public readonly kmlFilePreview = new EventEmitter<UploadedFile>();

    protected readonly permit$ = this.localStore.selectByKey("permit").pipe(RxjsUtils.filterFalsy());
    protected readonly areActionButtonsVisible$ = this.localStore.selectByKey("areActionButtonsVisible");
    protected readonly isOperatorDetailsTileExpanded$ = this.localStore.selectByKey("isOperatorDetailsTileExpanded");
    protected readonly isContactPersonTileExpanded$ = this.localStore.selectByKey("isContactPersonTileExpanded");
    protected readonly isRestrictionsTileExpanded$ = this.localStore.selectByKey("isRestrictionsTileExpanded");
    protected readonly isPermitDetailsExpanded$ = this.localStore.selectByKey("isPermitDetailsExpanded");
    protected readonly isOperatorContactPersonVisible$ = this.localStore.selectByKey("isOperatorContactPersonVisible");

    constructor(private localStore: LocalComponentStore<PermitDetailsPreviewComponentState>) {
        this.localStore.setState({
            permit: undefined,
            areActionButtonsVisible: false,
            isOperatorDetailsTileExpanded: false,
            isContactPersonTileExpanded: false,
            isRestrictionsTileExpanded: false,
            isPermitDetailsExpanded: false,
            isOperatorContactPersonVisible: false,
        });
    }
}
