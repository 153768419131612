import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { ApplicationType, FlightZoneRestrictionActions } from "@dtm-frontend/dss-shared-lib";
import { Store } from "@ngxs/store";
import { mergeMap, of } from "rxjs";
import { FlightZoneActions } from "../state/flight-zone.actions";
import { FlightZoneState } from "../state/flight-zone.state";

export const FLIGHT_ZONE_ID_ROUTE_PARAM_NAME = "id";
export const FLIGHT_ZONE_PREVIEW_ROUTE_SEGMENT = "preview";
export const FLIGHT_ZONE_APPLICATION_ROUTE_SEGMENT = "flight-zone";
export const FLIGHT_ZONE_MODIFICATION_ROUTE_SEGMENT = "modification";

@Injectable()
export class FlightZoneApplicationDataResolver {
    constructor(private readonly store: Store, private readonly router: Router) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const flightZoneId: string = route.paramMap.get(FLIGHT_ZONE_ID_ROUTE_PARAM_NAME) ?? "";
        const applicationType: ApplicationType = route.data.applicationType;
        const shouldSkipDataFetch = this.router.getCurrentNavigation()?.extras.state?.shouldSkipDataFetch;
        const isPreviewPathActivated = route.url.some(({ path }) => path === FLIGHT_ZONE_PREVIEW_ROUTE_SEGMENT);

        if (shouldSkipDataFetch) {
            return this.router.navigate([state.url]);
        }

        return this.store.dispatch(new FlightZoneActions.GetApplicationData(flightZoneId, applicationType)).pipe(
            mergeMap(() => {
                const isApplicationLocked = this.store.selectSnapshot(FlightZoneState.applicationData).analysisStatus?.isLocked;
                const baseRouteSegment =
                    applicationType === ApplicationType.RestrictionModificationApplication
                        ? FLIGHT_ZONE_MODIFICATION_ROUTE_SEGMENT
                        : FLIGHT_ZONE_APPLICATION_ROUTE_SEGMENT;

                if (isApplicationLocked && !isPreviewPathActivated) {
                    return this.router.navigate([baseRouteSegment, FLIGHT_ZONE_PREVIEW_ROUTE_SEGMENT, flightZoneId], {
                        state: { shouldSkipDataFetch: true },
                    });
                } else if (!isApplicationLocked && isPreviewPathActivated) {
                    return this.router.navigate([baseRouteSegment, flightZoneId], {
                        state: { shouldSkipDataFetch: true },
                    });
                }

                return of(true);
            })
        );
    }
}

@Injectable()
export class FlightZoneApplicationDataClearResolver {
    constructor(private readonly store: Store) {}

    public resolve() {
        return this.store.dispatch(new FlightZoneActions.ClearApplicationData());
    }
}

@Injectable()
export class FlightZoneRestrictionDataResolver {
    constructor(private readonly store: Store) {}

    public resolve(route: ActivatedRouteSnapshot) {
        const restrictionId: string = route.paramMap.get(FLIGHT_ZONE_ID_ROUTE_PARAM_NAME) ?? "";

        return this.store.dispatch(new FlightZoneRestrictionActions.GetRestrictionData(restrictionId));
    }
}
