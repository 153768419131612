// eslint-disable-next-line max-len
// NOTE: this file is based on https://github.com/pansa-dev/ngx-cesium/blob/HEAD/projects/@pansa/ngx-cesium/src/lib/@pansa/ngx-cesium-widgets/models/circle-editor-observable.ts

import { Cartesian3, EditorObservable, PointProps } from "@pansa/ngx-cesium";
import { Observable } from "rxjs";
import { EntityStatus } from "../entity-status.model";
import { CylinderProps } from "./cylinder-edit-options";
import { CylinderEditUpdate } from "./cylinder-edit-update";

export interface CylinderEditorObservable extends EditorObservable<CylinderEditUpdate> {
    setManually: (
        center: Cartesian3,
        radius: number,
        topHeight?: number,
        bottomHeight?: number,
        centerPointProps?: PointProps,
        radiusPointProps?: PointProps,
        cylinderProps?: CylinderProps
    ) => void;
    getCenter: () => Cartesian3 | undefined;
    getRadius: () => number;
    getTopHeight: () => number;
    getBottomHeight: () => number;
    refreshLabels: () => void;
    getId: () => string;
    status$: Observable<EntityStatus | undefined>;
}
