import { HttpClient, HttpContext } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
    AirspaceElementResponseBody,
    GEO_ZONES_ENDPOINTS,
    GeoZonesEndpoints,
    SearchAirspaceElementsRequestBody,
    convertAirspaceElementResponseBodyToAirspaceElement,
} from "@dtm-frontend/shared/map/geo-zones";
import { MissionPlanRoute, PageResponseBody } from "@dtm-frontend/shared/ui";
import { Logger, SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, combineLatest, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { MISSION_SEARCH_ENDPOINTS, MissionSearchEndpoints } from "../mission-search.tokens";
import { FilterConditions, MissionSearchErrorType, MissionsListWithPages, Paging, Sorting } from "../models/mission-search.models";
import {
    MissionApiData,
    MissionPlanCaaPermitDetailsResponseBody,
    MissionPlanDataResponseBody,
    MissionPlanItineraryResponseBody,
    MissionPlanPublicDetailsResponseBody,
    MissionPlanRouteResponseBody,
    MissionPlanVerificationResponseBody,
    MissionPlansResponseBody,
    PublicMissionPlanData,
} from "./mission-search-api.models";
import {
    convertFilterConditionsAndSortingToMissionPlanRequestPayload,
    convertMissionPlanPublicDetailsResponseBodyToRouteData,
    convertMissionPlanRouteResponseBodyToMissionPlanRoute,
    convertMissionPlansResponseBodyToMissions,
    convertPlanDataResponsesToAnalysisStatus,
    convertPlanDataResponsesToMissionDetails,
    convertPlanDataResponsesToOperationalGeometry,
    convertPlanDataResponsesToSoraSettings,
} from "./mission-search.converters";

@Injectable({
    providedIn: "root",
})
export class MissionSearchApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(MISSION_SEARCH_ENDPOINTS) private readonly endpoints: MissionSearchEndpoints,
        @Inject(GEO_ZONES_ENDPOINTS) private readonly geoZonesEndpoints: GeoZonesEndpoints
    ) {}

    public fetchMissions(
        filterConditions: FilterConditions | undefined,
        sorting: Sorting,
        paging: Paging
    ): Observable<MissionsListWithPages> {
        const payload = convertFilterConditionsAndSortingToMissionPlanRequestPayload(filterConditions, sorting, paging);

        return this.httpClient.post<PageResponseBody<MissionPlansResponseBody>>(this.endpoints.getPlans, payload).pipe(
            map((response: PageResponseBody<MissionPlansResponseBody>) => ({
                pages: {
                    pageSize: response.size,
                    pageNumber: response.number,
                    totalElements: response.totalElements,
                },
                content: convertMissionPlansResponseBodyToMissions(response.content),
            })),
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => ({ type: MissionSearchErrorType.CannotGetMissions }));
            })
        );
    }

    public getMission(missionId: string): Observable<MissionApiData> {
        return combineLatest([
            this.httpClient.get<MissionPlanDataResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getPlanData, { planId: missionId })
            ),
            this.httpClient.get<MissionPlanVerificationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getPlanVerification, { planId: missionId }),
                { context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true) }
            ),
            this.httpClient.get<MissionPlanCaaPermitDetailsResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getPlanCaaPermitDetails, { planId: missionId }),
                { context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true) }
            ),
            this.httpClient.get<MissionPlanItineraryResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getPlanItinerary, { planId: missionId }),
                { context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true) }
            ),
        ]).pipe(
            map(
                ([planData, planVerificationResponse, caaPermitDetailsResponse, itineraryResponse]): MissionApiData => ({
                    flightPurposes: planData.flightPurposes?.reduce(
                        (result, flightPurpose) => ({ ...result, [flightPurpose.id]: flightPurpose.name }),
                        {}
                    ),
                    missionDetails: convertPlanDataResponsesToMissionDetails(planData, caaPermitDetailsResponse),
                    operationalGeometry: convertPlanDataResponsesToOperationalGeometry(caaPermitDetailsResponse),
                    analysisStatus: convertPlanDataResponsesToAnalysisStatus(planVerificationResponse),
                    soraSettings: convertPlanDataResponsesToSoraSettings(itineraryResponse),
                })
            ),
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => ({ type: MissionSearchErrorType.CannotGetMission }));
            })
        );
    }

    public getMissionRoute(routeId: string): Observable<MissionPlanRoute> {
        return this.httpClient.get<MissionPlanRouteResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getRoute, { routeId })).pipe(
            map((route) => convertMissionPlanRouteResponseBodyToMissionPlanRoute(route)),
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => ({ type: MissionSearchErrorType.CannotGetMissionRoute }));
            })
        );
    }

    public getPublicMissionData(missionId: string): Observable<PublicMissionPlanData> {
        return this.httpClient
            .get<MissionPlanPublicDetailsResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getPublicDetails, { planId: missionId }),
                {
                    context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
                }
            )
            .pipe(
                map((response) => convertMissionPlanPublicDetailsResponseBodyToRouteData(response)),
                catchError((error) => {
                    Logger.captureException(error);

                    return throwError(() => ({ type: MissionSearchErrorType.CannotGetPublicMissionData }));
                })
            );
    }

    public searchAirspaceElements(options: Partial<SearchAirspaceElementsRequestBody>) {
        return this.httpClient
            .post<AirspaceElementResponseBody>(
                this.geoZonesEndpoints.searchAirspaceElements,
                {
                    ...options,
                    includeLocal: true,
                    includeTemporary: true,
                },
                { context: new HttpContext().set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true) }
            )
            .pipe(map((response) => convertAirspaceElementResponseBodyToAirspaceElement(response, options.scope?.endTime)));
    }
}
