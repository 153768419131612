import { DOCUMENT } from "@angular/common";
import { inject, Injectable } from "@angular/core";
import { LegacyDateAdapter as DateAdapter } from "@angular/material/legacy-core";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { distinctUntilChanged, filter, map, shareReplay, startWith } from "rxjs/operators";
import { LOCALE_MAPPING } from "../i18n.models";

@UntilDestroy()
@Injectable()
export class I18nService {
    private readonly transloco = inject(TranslocoService);
    private readonly document = inject(DOCUMENT);
    private readonly dateAdapter = inject(DateAdapter<Date>, { optional: true });

    public readonly currentLanguage$ = this.transloco.events$.pipe(
        filter(({ type }) => type === "langChanged"),
        map((event) => event.payload.langName),
        startWith(this.transloco.getActiveLang()),
        distinctUntilChanged(),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    public initialize() {
        this.setDocumentLanguage(this.transloco.getActiveLang());

        this.currentLanguage$.pipe(untilDestroyed(this)).subscribe((language) => {
            // TODO: DTM-3987 remove forcing en-GB when backend will change it's locale
            this.dateAdapter?.setLocale(language === "en" ? "en-GB" : LOCALE_MAPPING[language]);
            this.setDocumentLanguage(language);
        });
    }

    private setDocumentLanguage(languageName: string) {
        this.document.documentElement.lang = languageName;
    }
}
