import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

export interface ManualWidthInputDialogConfig {
    minWidth: number;
    maxWidth: number;
    step: number;
}

@Component({
    templateUrl: "./manual-width-input-dialog.component.html",
    styleUrls: ["./manual-width-input-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManualWidthInputDialogComponent {
    protected readonly corridorWidthFormControl: FormControl<number>;

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: ManualWidthInputDialogConfig,
        private readonly dialogRef: MatDialogRef<ManualWidthInputDialogComponent>
    ) {
        this.corridorWidthFormControl = new FormControl<number>(data.minWidth, { nonNullable: true });
    }

    protected confirm() {
        if (!this.corridorWidthFormControl.value) {
            this.dialogRef.close(false);

            return;
        }

        this.dialogRef.close({ width: this.corridorWidthFormControl.value });
    }
}
