/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from "@angular/core";

type FnRest<FunctionType extends (...args: any) => any> = FunctionType extends (first: any, ...args: infer ParametersType) => any
    ? ParametersType
    : never;

@Pipe({
    name: "invoke",
})
export class InvokePipe implements PipeTransform {
    public transform<FirstArgumentType, FunctionType extends (first: FirstArgumentType, ...args: any[]) => any>(
        value: FirstArgumentType,
        fn: FunctionType,
        ...rest: FnRest<FunctionType>
    ): ReturnType<FunctionType> {
        return fn(value, ...rest);
    }
}
