// eslint-disable-next-line max-len
// NOTE: this is based on https://github.com/pansa-dev/ngx-cesium/blob/HEAD/projects/@pansa/ngx-cesium/src/lib/@pansa/ngx-cesium-widgets/models/circle-edit-update.ts#L1-L13

import { BasicEditUpdate, Cartesian3, EditActions } from "@pansa/ngx-cesium";
import { ShapeDragActions } from "../../services/height-helper.service";
import { CylinderEditOptions } from "./cylinder-edit-options";
import { CylinderLabel } from "./editable-cylinder";

export interface CylinderEditUpdate extends Omit<BasicEditUpdate<CylinderEditUpdate>, "editAction" | "labelsRenderFn"> {
    startDragPosition?: Cartesian3;
    endDragPosition?: Cartesian3;
    center?: Cartesian3;
    radiusPoint?: Cartesian3;
    radius?: number;
    topHeight?: number;
    bottomHeight?: number;
    cylinderOptions?: CylinderEditOptions;
    shapeDragAction?: ShapeDragActions;
    editAction: EditActions | null;
    labelsRenderFn?: (update: CylinderEditUpdate, labels: CylinderLabel[]) => CylinderLabel[];
    updateLabels?: CylinderLabel[];
}
