/**
 * Shortens a given text to a maximum length and adds an ellipsis at the end of it if the text exceeds the maximum length.
 * @param textToCut - The text to cut.
 * @param maxLength - The maximum length of the text.
 * @param ending - The string to add at the end of the text if it exceeds the maximum length. Default: "..."
 * @returns The shortened text with an ellipsis at the end if it exceeds the maximum length, otherwise the original text.
 */
export function ellipsifyText(textToCut: string = "", maxLength: number = 100, ending: string = "..."): string {
    const endingLength = ending.length;
    const maxTextLengthWithEnding = maxLength - endingLength;

    if (textToCut && textToCut.length > maxLength) {
        const cutText = textToCut?.slice(0, maxTextLengthWithEnding);

        return `${cutText}${ending}`;
    }

    return textToCut;
}
