import { SpatialUtils } from "@dtm-frontend/shared/utils";
import turfBuffer from "@turf/buffer";
import {
    FeatureCollection,
    Feature as GeoJSONFeature,
    Polygon,
    Properties,
    featureCollection as turfFeatureCollection,
    lineString as turfLineString,
} from "@turf/helpers";
import { MapEntityType, Polyline3DEntity } from "../services/entity-editors/map-entities-editor.service";
import { convertCartesian3ToSerializableCartographic } from "./convert-cartesian3-to-serializable-cartographic";

const APPROXIMATED_BUFFER_SIDES = 8;
const MEMOIZED_BUFFERS_CACHE_SIZE = 256;
const WGS_84_COORDINATES_PRECISION = 8; // NOTE: 1.1112 mm precision

const memoizedBuffersCache = new Map<string, GeoJSONFeature<Polygon, Properties>>();

export interface Polyline3DGeoJSONProperties {
    type: MapEntityType.Polyline3D;
    bufferWidths: number[];
    points: [number, number][];
}

function getMemoizedBuffer(
    width: number,
    lineStringStart: [number, number],
    lineStringEnd: [number, number]
): GeoJSONFeature<Polygon, Properties> {
    const key = `${width}_${lineStringStart[0].toFixed(WGS_84_COORDINATES_PRECISION)}_${lineStringStart[1].toFixed(
        WGS_84_COORDINATES_PRECISION
    )}_${lineStringEnd[0].toFixed(WGS_84_COORDINATES_PRECISION)}_${lineStringEnd[1].toFixed(WGS_84_COORDINATES_PRECISION)}`;

    if (memoizedBuffersCache.has(key)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return memoizedBuffersCache.get(key)!;
    }

    const lineString = turfLineString([lineStringStart, lineStringEnd]);
    const buffer = turfBuffer(lineString, width, {
        steps: APPROXIMATED_BUFFER_SIDES,
        units: "meters",
    });

    if (memoizedBuffersCache.size > MEMOIZED_BUFFERS_CACHE_SIZE) {
        memoizedBuffersCache.delete(memoizedBuffersCache.keys().next().value);
    }

    memoizedBuffersCache.set(key, buffer);

    return buffer;
}

function convertPolyline3DEntityToBufferSegments(entity: Polyline3DEntity) {
    const positions = entity.positions.map((position): [number, number] => {
        const { latitude, longitude } = convertCartesian3ToSerializableCartographic(position);

        return [longitude, latitude];
    });

    const bufferSegments: GeoJSONFeature<Polygon, Properties>[] = [];

    for (let index = 0; index < positions.length - 1; index++) {
        const element = positions[index];
        const nextElement = positions[index + 1];
        const bufferWidth = entity.bufferWidths[index];

        if (bufferWidth === 0) {
            continue;
        }

        const width = Math.max(1, entity.bufferWidths[index]) / 2; // NOTE: polyline buffer is half of the buffer width

        bufferSegments.push(getMemoizedBuffer(width, element, nextElement));
    }

    return { bufferSegments, positions };
}

export function convertPolyline3DEntityToGeoJSONFeature(entity: Polyline3DEntity): GeoJSONFeature<Polygon, Properties> {
    const { bufferSegments, positions } = convertPolyline3DEntityToBufferSegments(entity);
    const combinedBuffer = SpatialUtils.union(bufferSegments) as GeoJSONFeature<Polygon, Properties>;

    const properties: Polyline3DGeoJSONProperties = {
        type: MapEntityType.Polyline3D,
        bufferWidths: entity.bufferWidths,
        points: positions,
    };
    combinedBuffer.properties = properties;

    return combinedBuffer;
}

export function convertPolyline3DEntityToGeoJSONFeatures(entity: Polyline3DEntity): FeatureCollection<Polygon, Properties> {
    const { bufferSegments } = convertPolyline3DEntityToBufferSegments(entity);

    return turfFeatureCollection(bufferSegments);
}
