import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: "dtm-ui-cookies-popup",
    templateUrl: "./cookies-popup.component.html",
    styleUrls: ["./cookies-popup.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesPopupComponent {
    @Output() public readonly cookiesHide = new EventEmitter<void>();
}
