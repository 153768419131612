import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "toType",
})
export class ToTypePipe implements PipeTransform {
    public transform<T>(value: string, type: { [key: string]: T }): T | undefined {
        if (!value) {
            return undefined;
        }

        return type[value as keyof typeof type];
    }
}
