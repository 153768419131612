import { LangToLocaleMapping } from "@jsverse/transloco-locale";

export const DEFAULT_LANG = "pl";
const DATE_PARTS_EXPRESSION = /^([0-9]{1,2})[.\-/]([0-9]{1,2})[.\-/]([0-9]{1,4})$/;

// TODO: DTM-3987 change to "en-GB" when backend will change it's locale
export const LOCALE_MAPPING: LangToLocaleMapping = {
    pl: "pl-PL",
    en: "en-US",
};

const isLanguageStored = () => !!localStorage.getItem("ui");

export const mapLocaleToLang = (locale: string | undefined) => Object.keys(LOCALE_MAPPING).find((key) => LOCALE_MAPPING[key] === locale);
export const getDefaultLang = () => (isLanguageStored() && JSON.parse(localStorage.getItem("ui") || "")?.activeLanguage) || DEFAULT_LANG;

const englishDateParser = (value: string | number) => {
    if (typeof value === "number") {
        return new Date(value);
    }

    if (value) {
        const dateParts = value.match(DATE_PARTS_EXPRESSION);

        if (dateParts !== null) {
            const [, day, month, year] = dateParts;

            return new Date(+year, +month - 1, +day);
        }
    }

    return value ? new Date(Date.parse(value)) : null;
};

const polishDateParser = (value: string | number) => {
    if (typeof value === "string") {
        const dateParts = value.match(DATE_PARTS_EXPRESSION);

        if (dateParts !== null) {
            const [, day, month, year] = dateParts;

            return new Date(+year, +month - 1, +day);
        }
    }

    return englishDateParser(value);
};

export const LANGUAGE_CONFIGURATION = {
    availableLanguages: ["en", "pl"],
    localeMapping: LOCALE_MAPPING,
    defaultLanguage: getDefaultLang(),
    fallbackLanguage: "pl",
    languageDefinitions: [
        {
            alpha2Code: "en",
            displayName: "English",
            flagName: "gb",
            firstDayOfWeek: 0,
            dateParser: englishDateParser,
            dateFormatHint: "DD/MM/YYYY",
        },
        {
            alpha2Code: "pl",
            displayName: "Polski",
            flagName: "pl",
            firstDayOfWeek: 1,
            dateParser: polishDateParser,
            dateFormatHint: "DD.MM.RRRR",
        },
    ],
} as const;

export type LanguageCode = (typeof LANGUAGE_CONFIGURATION.availableLanguages)[number];
export type LanguageCodeList = Readonly<(typeof LANGUAGE_CONFIGURATION.availableLanguages)[number][]>;

export interface LanguageDefinition {
    alpha2Code: LanguageCode;
    displayName: string;
    flagName: string;
    firstDayOfWeek: number;
    dateParser: (value: string | number) => Date | null;
    dateFormatHint: string;
}
