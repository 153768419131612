import { Injectable } from "@angular/core";
import { FlightPositionUpdate } from "@dtm-frontend/shared/ui";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class FlightPositionUpdaterService {
    private flightPositionUpdateSubject: Subject<FlightPositionUpdate> = new Subject<FlightPositionUpdate>();
    public flightPositionUpdate$ = this.flightPositionUpdateSubject.asObservable();

    public addFlightPositionUpdate(flightPositionUpdate: FlightPositionUpdate): void {
        this.flightPositionUpdateSubject.next(flightPositionUpdate);
    }
}
