<div class="panel" *ngrxLet="{ isExpanded: isExpanded$, mission: mission$, isSelected: isSelected$ } as vm" [class.focused]="vm.isSelected">
    <div class="chip-list">
        <span class="chip default">{{ vm.mission.designator }}</span>
        <span class="chip disabled">
            <dtm-ui-icon [name]="vm.mission.flightType === MissionType.BVLOS ? 'eye-off' : 'eye'"></dtm-ui-icon>
            {{ "dssClientLibPlannedMissions.missionInfoPanel.missionTypeLabel" | transloco : { value: vm.mission.flightType } }}
        </span>
    </div>

    <div class="flight-basic-info">
        <span class="pilot-name">{{ vm.mission.pilotName }}, {{ vm.mission.uavName }}</span>
    </div>
    <div class="flight-basic-info">
        <div class="mission-start-date">
            <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
            {{ vm.mission.flightStartAt | localizeDate : { dateStyle: "short" } }}
        </div>

        <div class="detail">
            <dtm-ui-icon name="flight-takeoff"></dtm-ui-icon>
            <ng-container *ngIf="vm.mission.flightType === MissionType.BVLOS">
                {{ vm.mission.flightStartAtMin | localizeDate : { hour: "numeric", minute: "numeric" } }}
                - {{ vm.mission.flightStartAtMax | localizeDate : { hour: "numeric", minute: "numeric" } }}
            </ng-container>
            <ng-container *ngIf="vm.mission.flightType === MissionType.VLOS">
                {{ "dssClientLibPlannedMissions.missionInfoPanel.flightDirectionPrefix" | transloco : { value: FlightDirection.Start } }}
                {{ vm.mission.flightStartAt | localizeDate : { hour: "numeric", minute: "numeric" } }}
            </ng-container>
        </div>

        <div class="detail">
            <dtm-ui-icon name="flight-land"></dtm-ui-icon>
            <ng-container *ngIf="vm.mission.flightType === MissionType.BVLOS">
                {{ vm.mission.flightFinishAtMin | localizeDate : { hour: "numeric", minute: "numeric" } }}
                - {{ vm.mission.flightFinishAtMax | localizeDate : { hour: "numeric", minute: "numeric" } }}
            </ng-container>
            <ng-container *ngIf="vm.mission.flightType === MissionType.VLOS">
                {{ "dssClientLibPlannedMissions.missionInfoPanel.flightDirectionPrefix" | transloco : { value: FlightDirection.Finish } }}
                {{ vm.mission.flightFinishAt | localizeDate : { hour: "numeric", minute: "numeric" } }}
            </ng-container>
        </div>
    </div>

    <div class="content" *ngIf="vm.isExpanded">
        <dtm-ui-loader-container [isShown]="isPlanRouteProcessing$ | ngrxPush">
            <ng-content></ng-content>
            <div class="actions" *ngIf="canEdit$ | ngrxPush">
                <button type="button" (click)="confirmRejection()" class="button-secondary remove-button">
                    <dtm-ui-icon name="close-circle"></dtm-ui-icon>
                    {{ "dssClientLibPlannedMissions.missionInfoPanel.rejectButtonLabel" | transloco }}
                </button>
                <div
                    *ngrxLet="isStartTimeExceeded$ as isStartTimeExceeded"
                    [matTooltip]="'dssClientLibPlannedMissions.missionInfoPanel.tooltipText' | transloco"
                    [matTooltipDisabled]="!isStartTimeExceeded"
                >
                    <button [disabled]="isStartTimeExceeded" type="button" (click)="confirmAcceptation()" class="button-primary">
                        {{ "dssClientLibPlannedMissions.missionInfoPanel.approveButtonLabel" | transloco }}
                    </button>
                </div>
            </div>
        </dtm-ui-loader-container>
    </div>
    <div class="expand">
        <button type="button" class="button-tertiary" (click)="toggle()">
            <span>{{ "dssClientLibPlannedMissions.missionInfoPanel.expandLabel" | transloco : { value: vm.isExpanded } }}</span>
        </button>
        <dtm-ui-icon [name]="vm.isExpanded ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
    </div>
</div>
