import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { DefaultValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { AnimationUtils, DeepFormType } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { startWith } from "rxjs";
import { ZoneLegendComponent } from "../zone-legend/zone-legend.component";

export interface ZoneControl<T> {
    isEnabled: boolean;
    settings: T;
}

interface StaticElasticZoneSettings {
    shouldShowStatic: boolean;
    shouldShowElastic: boolean;
}
interface DraRAirportZoneSettings {
    shouldShowDraRh6km: boolean;
    shouldShowDraRh: boolean;
    shouldShowDraRm: boolean;
    shouldShowDraRl: boolean;
}
export interface GeoZonesSettings {
    areGeoZonesEnabled: boolean;
    draP: ZoneControl<StaticElasticZoneSettings>;
    draR: ZoneControl<StaticElasticZoneSettings>;
    draRAirport: ZoneControl<DraRAirportZoneSettings>;
    shouldShowDraI: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-map-geographical-zones-settings",
    templateUrl: "./geographical-zones-settings.component.html",
    styleUrls: ["./geographical-zones-settings.component.scss", "../geographical-zones-common.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useClass: DefaultValueAccessor,
            multi: true,
        },
    ],
    animations: [AnimationUtils.slideInAnimation()],
})
export class GeographicalZonesSettingsComponent implements OnInit {
    protected readonly areGeoZonesEnabledControl = new FormControl(false, { nonNullable: true });

    protected isDraPEnabledZoneControl = this.createBooleanControl();
    protected isDraPStaticEnabledZoneControl = this.createBooleanControl();
    protected isDraPElasticEnabledZoneControl = this.createBooleanControl();

    protected isDraREnabledZoneControl = this.createBooleanControl();
    protected isDraRStaticEnabledZoneControl = this.createBooleanControl();
    protected isDraRElasticEnabledZoneControl = this.createBooleanControl();

    protected isDraRAirportEnabledZoneControl = this.createBooleanControl();
    protected isDraRh6kmEnabledZoneControl = this.createBooleanControl();
    protected isDraRhEnabledZoneControl = this.createBooleanControl();
    protected isDraRmEnabledZoneControl = this.createBooleanControl();
    protected isDraRlEnabledZoneControl = this.createBooleanControl();

    protected isDraIEnabledZoneControl = this.createBooleanControl();

    protected draPFormGroup = new FormGroup<DeepFormType<ZoneControl<StaticElasticZoneSettings>>>({
        isEnabled: this.isDraPEnabledZoneControl,
        settings: new FormGroup({
            shouldShowStatic: this.isDraPStaticEnabledZoneControl,
            shouldShowElastic: this.isDraPElasticEnabledZoneControl,
        }),
    });

    protected draRFormGroup = new FormGroup<DeepFormType<ZoneControl<StaticElasticZoneSettings>>>({
        isEnabled: this.isDraREnabledZoneControl,
        settings: new FormGroup({
            shouldShowStatic: this.isDraRStaticEnabledZoneControl,
            shouldShowElastic: this.isDraRElasticEnabledZoneControl,
        }),
    });

    protected draRAirportFormGroup = new FormGroup<DeepFormType<ZoneControl<DraRAirportZoneSettings>>>({
        isEnabled: this.isDraRAirportEnabledZoneControl,
        settings: new FormGroup({
            shouldShowDraRh6km: this.isDraRh6kmEnabledZoneControl,
            shouldShowDraRh: this.isDraRhEnabledZoneControl,
            shouldShowDraRm: this.isDraRmEnabledZoneControl,
            shouldShowDraRl: this.isDraRlEnabledZoneControl,
        }),
    });

    protected geoZoneSettingFormGroup = new FormGroup<DeepFormType<GeoZonesSettings>>({
        areGeoZonesEnabled: this.areGeoZonesEnabledControl,
        draP: this.draPFormGroup,
        draR: this.draRFormGroup,
        draRAirport: this.draRAirportFormGroup,
        shouldShowDraI: this.isDraIEnabledZoneControl,
    });

    constructor(@Inject(NgControl) private readonly ngControl: NgControl, private readonly dialog: MatDialog) {
        this.setupControlWatchers();
    }

    private createBooleanControl() {
        return new FormControl(true, { nonNullable: true });
    }

    private setupControlWatchers() {
        this.watchControlAndUpdateDisabledStateForChildren(this.isDraPEnabledZoneControl, [
            this.isDraPStaticEnabledZoneControl,
            this.isDraPElasticEnabledZoneControl,
        ]);

        this.watchControlAndUpdateDisabledStateForChildren(this.isDraREnabledZoneControl, [
            this.isDraRStaticEnabledZoneControl,
            this.isDraRElasticEnabledZoneControl,
        ]);

        this.watchControlAndUpdateDisabledStateForChildren(this.isDraRAirportEnabledZoneControl, [
            this.isDraRh6kmEnabledZoneControl,
            this.isDraRhEnabledZoneControl,
            this.isDraRmEnabledZoneControl,
            this.isDraRlEnabledZoneControl,
        ]);
    }

    private watchControlAndUpdateDisabledStateForChildren(parent: FormControl, children: FormControl[]) {
        parent.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            children.forEach((control) => (value ? control.enable() : control.disable()));
        });
    }

    public ngOnInit(): void {
        this.geoZoneSettingFormGroup.valueChanges
            .pipe(startWith(this.geoZoneSettingFormGroup.getRawValue()), untilDestroyed(this))
            .subscribe(() => this.ngControl?.control?.setValue(this.geoZoneSettingFormGroup.getRawValue()));

        this.ngControl.valueChanges
            ?.pipe(untilDestroyed(this))
            .subscribe((value) => this.geoZoneSettingFormGroup.setValue(value, { emitEvent: false }));
    }

    protected openZoneLegendDialog(): void {
        this.dialog.open(ZoneLegendComponent);
    }
}
