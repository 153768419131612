<dtm-ui-expandable-panel [isExpanded]="isExpanded$ | ngrxPush" [hasHeaderSeparator]="false" *ngrxLet="airspaceRisk$ as airspaceRisk">
    <h2 class="title" headerSlot>
        {{ "dtmSharedMission.operationalAreaAirspaceRisk.header" | transloco }}
    </h2>

    <table class="dtm-table" multiTemplateDataRows desktopTable mat-table [dataSource]="airspaceRisk">
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmSharedMission.operationalAreaAirspaceRisk.statusLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element" class="status">
                {{ "dtmSharedMission.operationalAreaAirspaceRisk.airRiskMitigationStatements" | transloco : { value: element.category } }}
                <ng-container *ngIf="element.designator">: {{ element.designator }} </ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="initialArc">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmSharedMission.operationalAreaAirspaceRisk.initialArcLabel" | transloco }}
                <dtm-ui-popover
                    (click)="$event.preventDefault()"
                    [popoverText]="'dtmSharedMission.operationalAreaAirspaceRisk.initialArcTooltip' | transloco"
                ></dtm-ui-popover>
            </th>
            <td mat-cell *matCellDef="let element" class="initial-arc">
                <div class="initial-arc-container">
                    {{ element.initialArc | airRiskMitigationsArcAsNumber | arcLabel : true }}
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</dtm-ui-expandable-panel>
