<div class="main-content">
    <p>{{ "dtmVersion.versionData.applicationVersionLabel" | transloco }}: {{ frontendVersion }}</p>
    <div *ngIf="data.versionData">
        <textarea matInput [value]="services" [disabled]="true"></textarea>
    </div>
</div>
<div mat-dialog-actions align="end" class="actions">
    <button mat-button type="button" mat-dialog-close>{{ "dtmVersion.versionData.closeLabel" | transloco }}</button>
    <button mat-button type="button" (click)="copyToClipboard()">
        {{ "dtmVersion.versionData.copyToClipboardLabel" | transloco }}
    </button>
</div>
