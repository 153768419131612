import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ApplicationListItemStatus, FlightZoneApplicationListItem, FlightZoneApplicationStatus } from "@dtm-frontend/dss-shared-lib";
import { IconName } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map, withLatestFrom } from "rxjs/operators";
import { ApplicationStatusTranslationService } from "../../services/application-status-translation.service";

interface ApplicationStatusBadge {
    iconName: IconName;
    className: string;
    type: ApplicationListItemStatus;
    label: string;
}

interface ApplicationStatusBadgeComponentState {
    listElement: FlightZoneApplicationListItem | undefined;
    hasSupervisorPermissions: boolean;
}

@Component({
    selector: "dss-client-lib-application-status-badge[listElement]",
    templateUrl: "./application-status-badge.component.html",
    styleUrls: ["./application-status-badge.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationStatusBadgeComponent {
    public readonly badge$ = this.localStore.selectByKey("listElement").pipe(
        RxjsUtils.filterFalsy(),
        withLatestFrom(this.localStore.selectByKey("hasSupervisorPermissions")),
        map(([listElement, hasSupervisorPermissions]) => this.getBadgeSettings(listElement, hasSupervisorPermissions)),
        RxjsUtils.filterFalsy()
    );

    @Input()
    public set hasSupervisorPermissions(value: boolean) {
        this.localStore.patchState({ hasSupervisorPermissions: value });
    }

    @Input()
    public set listElement(value: FlightZoneApplicationListItem) {
        this.localStore.patchState({ listElement: value });
    }

    constructor(
        private readonly localStore: LocalComponentStore<ApplicationStatusBadgeComponentState>,
        private readonly statusTranslationService: ApplicationStatusTranslationService
    ) {
        this.localStore.setState({
            listElement: undefined,
            hasSupervisorPermissions: false,
        });
    }

    private getBadgeSettings(
        listItem: FlightZoneApplicationListItem,
        hasSupervisorPermissions: boolean
    ): ApplicationStatusBadge | undefined {
        const statusLabels = this.statusTranslationService.getStatusTranslationsMap(hasSupervisorPermissions);

        if (
            !listItem.isLocked &&
            listItem.status !== FlightZoneApplicationStatus.New &&
            listItem.status !== FlightZoneApplicationStatus.Draft
        ) {
            return {
                iconName: "file-edit",
                className: "warning",
                type: ApplicationListItemStatus.Correction,
                label: statusLabels[ApplicationListItemStatus.Correction],
            };
        }

        switch (listItem.status) {
            case FlightZoneApplicationStatus.Published:
                return {
                    iconName: "file-mark",
                    className: "success",
                    type: ApplicationListItemStatus.Published,
                    label: statusLabels[ApplicationListItemStatus.Published],
                };
            case FlightZoneApplicationStatus.Rejected:
                return {
                    iconName: "file-forbid",
                    className: "error",
                    type: ApplicationListItemStatus.Rejected,
                    label: statusLabels[ApplicationListItemStatus.Rejected],
                };
            case FlightZoneApplicationStatus.SentToANSP:
            case FlightZoneApplicationStatus.Assigned:
            case FlightZoneApplicationStatus.Reviewed:
            case FlightZoneApplicationStatus.Accepted:
                return {
                    iconName: "file-unknow",
                    className: "secondary",
                    type: ApplicationListItemStatus.WaitingForPublication,
                    label: statusLabels[ApplicationListItemStatus.WaitingForPublication],
                };
            case FlightZoneApplicationStatus.New:
                return {
                    iconName: "file-user",
                    className: "disabled",
                    type: ApplicationListItemStatus.ForApproval,
                    label: statusLabels[ApplicationListItemStatus.ForApproval],
                };
            default:
                return;
        }
    }
}
