import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { SpecificPermitFlightWizardSteps, UavDetailsModel } from "../../../models/permits.models";

interface UavDetailsPreviewComponentState {
    uavDetails: UavDetailsModel | undefined;
    isActionButtonVisible: boolean;
    isDetailsExpanded: boolean;
}

@Component({
    selector: "dtm-ui-uav-details-preview[uavDetails]",
    templateUrl: "./permit-uav-details-preview.component.html",
    styleUrls: ["../permit-details-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimationWithMargin()],
})
export class PermitUavDetailsPreviewComponent {
    @Input() public set uavDetails(value: UavDetailsModel | undefined) {
        this.localStore.patchState({ uavDetails: value });
    }
    @Input() public set isActionButtonVisible(value: BooleanInput) {
        this.localStore.patchState({ isActionButtonVisible: coerceBooleanProperty(value) });
    }
    @Input() public set isDetailsExpanded(value: BooleanInput) {
        this.localStore.patchState({ isDetailsExpanded: coerceBooleanProperty(value) });
    }

    @Output() public readonly editStep = new EventEmitter<SpecificPermitFlightWizardSteps>();

    protected readonly uavDetails$ = this.localStore.selectByKey("uavDetails").pipe(RxjsUtils.filterFalsy());
    protected readonly isActionButtonVisible$ = this.localStore.selectByKey("isActionButtonVisible");
    protected readonly isDetailsExpanded$ = this.localStore.selectByKey("isDetailsExpanded");
    protected readonly SpecificPermitFLightWizardSteps = SpecificPermitFlightWizardSteps;

    constructor(private readonly localStore: LocalComponentStore<UavDetailsPreviewComponentState>) {
        this.localStore.setState({
            uavDetails: undefined,
            isActionButtonVisible: false,
            isDetailsExpanded: false,
        });
    }
}
