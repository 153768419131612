import { Params } from "@angular/router";
import { ApplicationType, FlightZoneApplicationDraftListItem, RestrictionsListItem } from "@dtm-frontend/dss-shared-lib";

export namespace FlightZoneListsActions {
    export class GetSubmittedApplicationsList {
        public static readonly type = "[Flight Zone Lists] Get submitted applications list";

        constructor(public filterParams: Params) {}
    }

    export class GetSubmittedModificationsList {
        public static readonly type = "[Flight Zone Lists] Get submitted restrictions modification list";

        constructor(public filterParams: Params) {}
    }

    export class GetActiveRestrictionsList {
        public static readonly type = "[Flight Zone Lists] Get active restrictions list";

        constructor(public filterParams: Params) {}
    }

    export class GetEndedRestrictionsList {
        public static readonly type = "[Flight Zone Lists] Get ended restrictions list";

        constructor(public filterParams: Params) {}
    }

    export class GetApplicationDraftList {
        public static readonly type = "[Flight Zone Lists] Get application draft list";

        constructor(public filterParams: Params) {}
    }

    export class GetApplicationGeoJson {
        public static readonly type = "[Flight Zone Lists] Get application GeoJSON data";

        constructor(public flightZone: FlightZoneApplicationDraftListItem, public applicationType: ApplicationType) {}
    }

    export class GetRestrictionGeoJson {
        public static readonly type = "[Flight Zone Lists] Get flight zone restriction GeoJSON data";

        constructor(public restriction: RestrictionsListItem) {}
    }
}
