<ng-container [formGroup]="timeFormGroup">
    <dtm-ui-input-field [isClearable]="false" class="time-field">
        <label>{{ "dtmUi.timeSetter.hourLabel" | transloco }}</label>
        <input type="number" matInput formControlName="hours" autocomplete="off" [maxlength]="2" inputmode="numeric" />
    </dtm-ui-input-field>
    <div class="separator"></div>
    <dtm-ui-input-field [isClearable]="false" class="time-field">
        <label>{{ "dtmUi.timeSetter.minuteLabel" | transloco }}</label>
        <input type="number" matInput formControlName="minutes" autocomplete="off" [maxlength]="2" inputmode="numeric" />
    </dtm-ui-input-field>
</ng-container>
