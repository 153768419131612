import { Pipe, PipeTransform } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { HashMap } from "@jsverse/transloco/lib/types";
import { SYSTEM_TRANSLATION_SCOPE } from "../shared-i18n.tokens";

@Pipe({
    name: "systemTranslation",
    pure: false,
})
export class SystemTranslationPipe extends TranslocoPipe implements PipeTransform {
    public transform(key?: string | null, params?: HashMap, inlineLang?: string): string {
        if (!key) {
            return "";
        }

        return super.transform(`${SYSTEM_TRANSLATION_SCOPE}.${key}`, params, inlineLang);
    }
}
