/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditorEditOptions, EllipseProps, PointProps } from "@pansa/ngx-cesium";

export interface CylinderEditOptions extends EditorEditOptions {
    cylinderProps?: CylinderProps;
    pointProps?: CylinderPointProps;
}

export interface CylinderProps extends Partial<EllipseProps> {
    areHeightsDraggable?: boolean;
    shadowMaterial?: any;
    maxRadius?: number;
    maxTopHeight?: number;
    maxBottomHeight?: number;
    minRadius?: number;
    minTopHeight?: number;
    minBottomHeight?: number;
    minHeight?: number;
    maxHeight?: number;
}

export interface CylinderPointProps extends Partial<PointProps> {
    radiusColor?: any;
    radiusPixelSize?: number;
    radiusOutlineColor?: any;
    radiusOutlineWidth?: number;
}
