<ng-container *ngrxLet="value$; let valueItems">
    <dtm-ui-form-field
        [class.empty]="valueItems.length === 0"
        [isDisabled]="isDisabled$ | ngrxPush"
        [isActivated]="isActivated"
        *ngrxLet="isActivated$; let isActivated"
        matAutocompleteOrigin
        #autocompleteOrigin="matAutocompleteOrigin"
    >
        <ng-content select="label" ngProjectAs="label"></ng-content>
        <mat-chip-list #chipList [selectable]="false">
            <mat-chip
                *ngFor="let value of valueItems"
                (removed)="removeChip(value)"
                [selectable]="false"
                [removable]="value.isRemovable !== false"
            >
                <span class="chip-label">{{ value.label }}</span>
                <button matChipRemove type="button" *ngIf="value.isRemovable !== false">
                    <dtm-ui-icon name="close-circle-fill"></dtm-ui-icon>
                </button>
            </mat-chip>
            <input
                #chipsInput
                [placeholder]="(placeholder$ | ngrxPush) ?? ''"
                [formControl]="customChipFormControl"
                [matAutocomplete]="auto"
                [matAutocompleteConnectedTo]="autocompleteOrigin"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addCustomChip($event)"
            />
        </mat-chip-list>
        <mat-autocomplete class="options-list" #auto="matAutocomplete" (optionSelected)="selectChip($event)">
            <mat-option *ngFor="let option of filteredOptions$ | ngrxPush" [value]="option" [disabled]="option.isDisabled">
                {{ option.label }}
            </mat-option>
        </mat-autocomplete>
        <ng-content select=".field-hint" ngProjectAs=".field-hint"></ng-content>
        <ng-content select=".field-error" ngProjectAs=".field-error"></ng-content>
    </dtm-ui-form-field>
</ng-container>
