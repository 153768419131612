/**
 * Creates a version of an array, in which only unique values are kept
 * @param {string} iterable - Array to filter unique values
 * @param {string} iteratee - Function invoked for each element to generate the criterion by which uniqueness is computed
 */
export function unique<T>(iterable: T[], iteratee?: (item: T) => string): T[] {
    if (!iteratee) {
        return Array.from(new Set(iterable));
    }

    const valuesToFilter = new Map();
    iterable.forEach((item) => valuesToFilter.set(iteratee(item), item));

    return Array.from(valuesToFilter.values());
}
