import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { AssociationPermit } from "../../../models/permits.models";

interface AssociationPermitBasicInfoPreviewComponentState {
    permit: AssociationPermit | undefined;
    canEdit: boolean;
    isCaaContactTileExpanded: boolean;
    isOperatorInfoTileExpanded: boolean;
    isOperatorContactPersonVisible: boolean;
}

@Component({
    selector: "dtm-ui-association-permit-basic-info-preview[permit]",
    templateUrl: "./association-permit-basic-info-preview.component.html",
    styleUrls: ["./association-permit-basic-info-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AssociationPermitBasicInfoPreviewComponent {
    @Input() public set permit(value: AssociationPermit | undefined) {
        this.localStore.patchState({ permit: value });
    }
    @Input() public set canEdit(value: BooleanInput) {
        this.localStore.patchState({ canEdit: coerceBooleanProperty(value) });
    }
    @Input() public set isCaaContactTileExpanded(value: BooleanInput) {
        this.localStore.patchState({ isCaaContactTileExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set isOperatorInfoTileExpanded(value: BooleanInput) {
        this.localStore.patchState({ isOperatorInfoTileExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set isOperatorContactPersonVisible(value: BooleanInput) {
        this.localStore.patchState({ isOperatorContactPersonVisible: coerceBooleanProperty(value) });
    }

    @Output() public readonly edit = new EventEmitter<void>();

    protected readonly permit$ = this.localStore.selectByKey("permit").pipe(RxjsUtils.filterFalsy());
    protected readonly canEdit$ = this.localStore.selectByKey("canEdit");
    protected readonly isCaaContactTileExpanded$ = this.localStore.selectByKey("isCaaContactTileExpanded");
    protected readonly isOperatorInfoTileExpanded$ = this.localStore.selectByKey("isOperatorInfoTileExpanded");
    protected readonly isOperatorContactPersonVisible$ = this.localStore.selectByKey("isOperatorContactPersonVisible");

    constructor(private readonly localStore: LocalComponentStore<AssociationPermitBasicInfoPreviewComponentState>) {
        localStore.setState({
            permit: undefined,
            canEdit: false,
            isCaaContactTileExpanded: false,
            isOperatorInfoTileExpanded: false,
            isOperatorContactPersonVisible: false,
        });
    }
}
