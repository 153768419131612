import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { LanguageCode, LanguageDefinition } from "../../modules/i18n/i18n.models";

interface LanguageSelectComponentState {
    languageDefinitions: Readonly<LanguageDefinition[]>;
    selectedLanguageDefinition: LanguageDefinition | undefined;
}

@Component({
    selector: "dtm-ui-language-select",
    templateUrl: "./language-select.component.html",
    styleUrls: ["./language-select.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LanguageSelectComponent {
    protected readonly selectedLanguageDefinition$ = this.localStore.selectByKey("selectedLanguageDefinition");
    protected readonly languageDefinitions$ = this.localStore.selectByKey("languageDefinitions");

    @Input() public set languages(value: Readonly<LanguageDefinition[]>) {
        this.localStore.patchState({ languageDefinitions: value });
    }
    @Input() public set selectedLanguage(value: LanguageCode | undefined) {
        this.localStore.patchState((state: LanguageSelectComponentState) => ({
            selectedLanguageDefinition: state.languageDefinitions.find((entry: LanguageDefinition) => entry.alpha2Code === value),
        }));
    }

    @Output() public languageChange = new EventEmitter<LanguageCode>();

    constructor(private readonly localStore: LocalComponentStore<LanguageSelectComponentState>) {
        this.localStore.setState({
            languageDefinitions: [],
            selectedLanguageDefinition: undefined,
        });
    }

    public changeLanguage(newLanguageAlpha2Code: LanguageCode) {
        this.languageChange.emit(newLanguageAlpha2Code);
    }
}
