export interface IdentityDocument {
    id: string;
    type: IdentityDocumentType;
    filename: string;
    isApproved: boolean;
    expirationDate: string;
    imageMediaType: string;
    status: IdentityDocumentStatus;
    statusReason?: string;
}

export enum IdentityDocumentType {
    Passport = "PASSPORT",
    IdCard = "ID_CARD",
}

export enum IdentityDocumentStatus {
    Active = "ACTIVE",
    Waiting = "WAITING",
    Rejected = "REJECTED",
}
