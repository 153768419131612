import { MissionPlanRouteSection } from "@dtm-frontend/shared/ui";
import { FunctionUtils, SpatialUtils } from "@dtm-frontend/shared/utils";
import { Feature, MultiPolygon, Polygon, Properties, feature as turfFeature } from "@turf/helpers";

export function combineFlightArea(sections: MissionPlanRouteSection[]): Feature<Polygon | MultiPolygon, Properties> | null {
    const sectionFeatures = sections
        .map((section) => {
            const flightZoneArea = section.flightZone?.flightArea.volume.area;
            const segmentArea = section.segment?.flightArea.volume.area;

            const flightZoneAreaFeature = flightZoneArea ? turfFeature(flightZoneArea) : null;
            const segmentAreaFeature = segmentArea ? turfFeature(segmentArea) : null;

            return [flightZoneAreaFeature, segmentAreaFeature];
        })
        .flat()
        .filter(FunctionUtils.isTruthy);

    return SpatialUtils.union(sectionFeatures);
}
