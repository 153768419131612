import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { getDefaultLang } from "@dtm-frontend/shared/ui/i18n";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { lastValueFrom } from "rxjs";
import { first } from "rxjs/operators";
import { AuthActions } from "../state/auth.actions";
import { AuthState } from "../state/auth.state";

export const DTM_AUTH_GUARD_REQUIRED_ROLES = new InjectionToken("DTM_AUTH_GUARD_REQUIRED_ROLES");

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class DtmRoleGuard extends KeycloakAuthGuard {
    constructor(
        protected readonly router: Router,
        protected readonly keycloak: KeycloakService,
        private readonly store: Store,
        @Inject(DOCUMENT) private readonly document: Document,
        @Inject(DTM_AUTH_GUARD_REQUIRED_ROLES) private readonly requiredRoles: string[]
    ) {
        super(router, keycloak);
    }

    public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authenticated) {
            await this.keycloak.login({
                locale: getDefaultLang(),
                redirectUri: this.document.location.origin + state.url,
            });
        }

        const routeRoles: string[] = route.data.roles;
        const roles = await lastValueFrom(this.store.select(AuthState.roles).pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this)));

        if (routeRoles?.length > 0) {
            if (routeRoles.find((role) => roles.includes(role))) {
                return true;
            }

            this.store.dispatch(AuthActions.GoToNotAuthorizedPage);

            return false;
        }

        if (this.requiredRoles.length === 0 || this.requiredRoles.find((role) => roles.includes(role))) {
            return true;
        }

        this.store.dispatch(AuthActions.GoToNotAuthorizedPage);

        return false;
    }
}
