<ng-container *ngrxLet="flightZoneApplications$; let flightZoneApplications">
    <dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
        <table
            class="dtm-table"
            mat-table
            multiTemplateDataRows
            [dataSource]="flightZoneApplications"
            *ngrxLet="expandedElement$; let expandedElement"
        >
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "dssClientLibFlightZone.applicationListShared.titleHeader" | transloco }}
                </th>
                <td mat-cell *matCellDef="let element">{{ element.title }}</td>
            </ng-container>

            <ng-container matColumnDef="applicationPurpose">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "dssClientLibFlightZone.applicationListShared.purposeHeader" | transloco }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <dtm-ui-popover
                        *ngIf="element.stateSecurityRestriction"
                        [popoverText]="'dssClientLibFlightZone.applicationListShared.stateSecurityRestrictionHint' | transloco"
                        (click)="$event.stopPropagation()"
                    ></dtm-ui-popover>
                    {{
                        "dssSharedLibFlightZone.flightZoneApplicationPurposes.purposeLabel"
                            | transloco : { value: element.applicationPurpose }
                    }}
                </td>
            </ng-container>

            <ng-container matColumnDef="author">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "dssClientLibFlightZone.applicationListShared.authorHeader" | transloco }}
                </th>
                <td mat-cell *matCellDef="let element">{{ element.author.fullName }}</td>
            </ng-container>

            <ng-container matColumnDef="startAt">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "dssClientLibFlightZone.applicationListShared.startAtHeader" | transloco }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <dss-shared-lib-utc-date-display [date]="element.startAt"></dss-shared-lib-utc-date-display>
                </td>
            </ng-container>

            <ng-container matColumnDef="endAt">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "dssClientLibFlightZone.applicationListShared.endAtHeader" | transloco }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <dss-shared-lib-utc-date-display [date]="element.endAt"></dss-shared-lib-utc-date-display>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandHandle">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button
                        *ngIf="element.caseNumber"
                        type="button"
                        class="button-icon"
                        (click)="$event.stopPropagation(); toggleExpandableRow(element)"
                    >
                        <dtm-ui-icon name="arrow-right" class="expand-handle" [class.expanded]="element === expandedElement"></dtm-ui-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="expanded-row-content" *ngIf="element === expandedElement" @slideIn>
                        <dtm-ui-label-value
                            class="sub-cell"
                            *ngIf="!!element.caseNumber"
                            [label]="'dssClientLibFlightZone.applicationListShared.caseNumberHeader' | transloco"
                            [value]="element.caseNumber"
                        >
                        </dtm-ui-label-value>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "dssClientLibFlightZone.applicationListShared.actionsHeader" | transloco }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <button
                        type="button"
                        [matMenuTriggerFor]="rowActionsMenu"
                        [matMenuTriggerData]="{ $implicit: element }"
                        class="button-icon"
                        (click)="$event.stopPropagation()"
                    >
                        <dtm-ui-icon name="more"></dtm-ui-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="noResults">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                    <dtm-ui-error
                        *ngIf="hasDataRetrievalError$ | ngrxPush; else emptyListTemplate"
                        (reload)="listRefresh.emit()"
                    ></dtm-ui-error>

                    <ng-template #emptyListTemplate>
                        <dtm-ui-no-results></dtm-ui-no-results>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="pagination">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                    <dtm-ui-pagination *ngIf="applicationsPage$ | ngrxPush as page" [pagination]="page" (page)="pageChange.emit($event)">
                    </dtm-ui-pagination>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [class.expanded]="expandedElement === row"
                (click)="row.caseNumber && toggleExpandableRow(row)"
            ></tr>
            <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
            <tr
                mat-footer-row
                *matFooterRowDef="['noResults']"
                [class.hide-footer]="flightZoneApplications.length || isProcessing"
                class="no-results-row"
            ></tr>
            <tr
                mat-footer-row
                *matFooterRowDef="['pagination']"
                [class.hide-footer]="!flightZoneApplications.length"
                class="pagination-row"
            ></tr>
        </table>
    </dtm-ui-loader-container>
</ng-container>

<mat-menu #rowActionsMenu="matMenu">
    <ng-template matMenuContent let-element>
        <a [routerLink]="['/flight-zone', element.id]" mat-menu-item>
            <dtm-ui-icon name="file-list"></dtm-ui-icon>
            <span>{{ "dssClientLibFlightZone.applicationListShared.editActionLabel" | transloco }}</span>
        </a>

        <button mat-menu-item type="button" (click)="deleteApplication(element.id)">
            <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
            <span>{{ "dssClientLibFlightZone.applicationDraftList.deleteApplicationActionLabel" | transloco }}</span>
        </button>

        <button mat-menu-item type="button" (click)="kmlDownload.emit(element)">
            <dtm-ui-icon name="download"></dtm-ui-icon>
            <span>{{ "dssClientLibFlightZone.applicationListShared.downloadKMLActionLabel" | transloco }}</span>
        </button>
    </ng-template>
</mat-menu>
