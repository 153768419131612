import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { distinctUntilChanged } from "rxjs/operators";

interface ZoomInputComponentState {
    max: number;
    min: number;
    step: number;
}

const DEFAULT_STEP = 10;
const DEFAULT_VALUE = 100;

@UntilDestroy()
@Component({
    selector: "dtm-ui-zoom-input[formControl]",
    templateUrl: "./zoom-input.component.html",
    styleUrls: ["./zoom-input.component.scss"],
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ZoomInputComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoomInputComponent implements ControlValueAccessor {
    protected readonly zoomFormControl = new FormControl<number>(DEFAULT_VALUE, {
        nonNullable: true,
        validators: Validators.required,
    });

    @Input() public set min(value: number) {
        this.zoomFormControl.addValidators(Validators.min(value));
        this.localStore.patchState({ min: value });
    }
    @Input() public set max(value: number) {
        this.zoomFormControl.addValidators(Validators.max(value));
        this.localStore.patchState({ max: value });
    }
    @Input() public set step(value: number) {
        this.localStore.patchState({ step: value });
    }

    protected readonly min$ = this.localStore.selectByKey("min");
    protected readonly max$ = this.localStore.selectByKey("max");

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: number) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<ZoomInputComponentState>) {
        localStore.setState({
            max: Infinity,
            min: -Infinity,
            step: DEFAULT_STEP,
        });

        this.zoomFormControl.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((zoomValue) => {
            if (!this.zoomFormControl.valid) {
                return;
            }

            this.propagateChange(zoomValue);
            this.propagateTouch();
        });
    }

    public writeValue(value: number) {
        this.zoomFormControl.setValue(value);
    }

    public registerOnChange(fn: (value: number) => void) {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    protected increment() {
        this.zoomFormControl.setValue(this.zoomFormControl.value + this.localStore.selectSnapshotByKey("step"));
    }

    protected decrement() {
        this.zoomFormControl.setValue(this.zoomFormControl.value - this.localStore.selectSnapshotByKey("step"));
    }

    protected updateOnBlur() {
        if (this.zoomFormControl.valid) {
            return;
        }

        const min = this.localStore.selectSnapshotByKey("min");
        const max = this.localStore.selectSnapshotByKey("max");
        const zoomValue = this.zoomFormControl.value;

        if (zoomValue < min) {
            this.zoomFormControl.setValue(min);
        }
        if (zoomValue > max) {
            this.zoomFormControl.setValue(max);
        }
    }
}
