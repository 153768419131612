/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line max-len
// NOTE: this file is based on https://github.com/pansa-dev/ngx-cesium/blob/HEAD/projects/@pansa/ngx-cesium/src/lib/@pansa/ngx-cesium-widgets/models/editable-circle.ts
import { MILLISECONDS_IN_SECOND, NarrowIndexable } from "@dtm-frontend/shared/utils";
import { AcEntity, AcLayerComponent, Cartesian3, GeoUtilsService, PointProps } from "@pansa/ngx-cesium";
import { DraggableHeightEntity, HeightEditable, HeightEntityType, HeightPointsProvider } from "../../services/height-helper.service";
import { getElevatedCesiumPoint } from "../../utils/get-elevated-cesium-point";
import { CartographicEditPoint, CartographicEditPointParentEntity } from "../cartographic-edit-point";
import { DEFAULT_LABEL_PROPS, EntityLabel, LabelFn, LabelProviders } from "../entity-label.model";
import { EntityStatus } from "../entity-status.model";
import { CylinderEditOptions, CylinderPointProps, CylinderProps } from "./cylinder-edit-options";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export interface CylinderEditorLabelProviders extends LabelProviders {
    radius?: LabelFn<number>;
    topHeight?: LabelFn<number>;
    bottomHeight?: LabelFn<number>;
    waypoint?: LabelFn<string>;
    center?: LabelFn<null>;
}
export type CylinderLabel = EntityLabel<CylinderEditorLabelProviders>;
type CylinderLabelKey = keyof NarrowIndexable<CylinderEditorLabelProviders>;

export class CylinderEditPoint extends CartographicEditPoint {
    constructor(
        entity: CartographicEditPointParentEntity,
        position: Cartesian3,
        public readonly isRadiusPoint: boolean,
        pointProps?: CylinderPointProps,
        isVirtualPoint?: boolean
    ) {
        super(entity, position, pointProps, isVirtualPoint);
    }

    public get props(): CylinderPointProps {
        return super.props as CylinderPointProps;
    }

    public set props(value: CylinderPointProps) {
        super.props = value;
    }
}

export class EditableCylinder extends AcEntity implements HeightEditable, HeightPointsProvider, CartographicEditPointParentEntity {
    private _center!: CylinderEditPoint;
    private _radiusPoint!: CylinderEditPoint;
    private _radius = 0;
    private _topHeightOutline!: DraggableHeightEntity;
    private _bottomHeightOutline!: DraggableHeightEntity;
    private doneCreation = false;
    private isDisposed = false;
    private _enableEdit = true;
    private lastDraggedToPosition?: Cartesian3;
    private _cylinderProps!: CylinderProps;
    private _pointProps: PointProps;
    private _labels: CylinderLabel[] = [];
    private _hoverLabelId: CylinderLabelKey | undefined = "waypoint";
    private _dynamicEntityTimeoutHandler?: ReturnType<typeof setTimeout>;

    constructor(
        private id: string,
        private cylindersLayer: AcLayerComponent,
        private pointsLayer: AcLayerComponent,
        private heightOutlinesLayer: AcLayerComponent,
        private cylinderLabelsLayer: AcLayerComponent,
        options: CylinderEditOptions
    ) {
        super();
        if (options.cylinderProps) {
            this._cylinderProps = { ...options.cylinderProps };
        }
        this._pointProps = { ...options.pointProps };
    }

    public get isStaticEntity() {
        return this._dynamicEntityTimeoutHandler === undefined && this.doneCreation;
    }

    /**
     * Marks this entity as dynamic for up to one second since latest update for rendering performance reasons
     */
    public markAsDynamicEntityWhileUpdate() {
        if (this._dynamicEntityTimeoutHandler !== undefined) {
            clearTimeout(this._dynamicEntityTimeoutHandler);
        }

        this._dynamicEntityTimeoutHandler = setTimeout(() => {
            this._dynamicEntityTimeoutHandler = undefined;

            if (this.isDisposed) {
                return;
            }

            this.updateCylindersLayer();
            this.updateHeightOutlinesLayer();
            this.updatePointsLayer();
        }, MILLISECONDS_IN_SECOND);
    }

    public get heightConstraints() {
        return this.cylinderProps;
    }

    public shouldShowLabel(label: CylinderLabel) {
        return label.show && (this._hoverLabelId === label.id || (!this.doneCreation && label.id === "radius") || label.id === "center");
    }

    public set hoverLabelId(value: CylinderLabelKey | undefined) {
        this._hoverLabelId = value;
    }

    public get hoverLabelId() {
        return this._hoverLabelId;
    }

    public get labels(): CylinderLabel[] {
        return this._labels;
    }

    public set labels(value: CylinderLabel[]) {
        if (value && value.length === 0) {
            this._labels = [];

            return;
        }

        if (!value || !this._center || !this._radiusPoint || !this._bottomHeightOutline || !this._topHeightOutline) {
            return;
        }

        this._labels = value.map((label) => {
            switch (label.id) {
                case "waypoint":
                    label.position = this._center.getPosition();
                    break;
                case "radius":
                    label.position = this._radiusPoint.getPosition();
                    break;
                case "topHeight":
                    label.position = getElevatedCesiumPoint(this._radiusPoint.getPosition(), this._topHeightOutline.height);
                    break;
                case "bottomHeight":
                    label.position = getElevatedCesiumPoint(this._radiusPoint.getPosition(), this._bottomHeightOutline.height);
                    break;
                case "center":
                    label.position = this._center.getPosition();
                    label.entityId = this.id;
                    label.show = true;
            }

            label.show = this.shouldShowLabel(label);
            label.position ??= Cesium.Cartesian3.ZERO;

            return { ...DEFAULT_LABEL_PROPS, ...label };
        });
    }

    public get pointProps(): PointProps {
        return this._pointProps;
    }

    public set pointProps(value: PointProps) {
        this._pointProps = value;
    }

    public get cylinderProps(): CylinderProps {
        return this._cylinderProps;
    }

    public set cylinderProps(value: CylinderProps) {
        this._cylinderProps = value;
    }

    public get center(): CylinderEditPoint {
        return this._center;
    }

    public get radiusPoint(): CylinderEditPoint {
        return this._radiusPoint;
    }

    public get enableEdit() {
        return this._enableEdit;
    }

    public set enableEdit(value: boolean) {
        this._enableEdit = value;

        if (this._center && this._radiusPoint) {
            this._center.show = value;
            this._radiusPoint.show = value;
        }

        this.updatePointsLayer();
    }

    public setManually(
        center: Cartesian3,
        radiusPosition: Cartesian3,
        topHeight = 0,
        bottomHeight = 0,
        centerPointProps = this.pointProps,
        radiusPointProps = this.pointProps,
        cylinderProps = this.cylinderProps
    ) {
        this.markAsDynamicEntityWhileUpdate();

        this.cylinderProps = { ...this.cylinderProps, ...cylinderProps };

        if (!this._center) {
            this._center = new CylinderEditPoint(this, center, false, centerPointProps);
        } else {
            this._center.props = { ...this._center.props, ...centerPointProps };
            this._center.setPosition(center);
        }

        if (!this._radiusPoint) {
            this._radiusPoint = new CylinderEditPoint(this, radiusPosition, true, radiusPointProps);
        } else {
            this._radiusPoint.props = { ...this._radiusPoint.props, ...radiusPointProps };
            this._radiusPoint.setPosition(radiusPosition);
        }

        this.recalculateRadius();

        if (!this._topHeightOutline) {
            this._topHeightOutline = new DraggableHeightEntity(
                this,
                this,
                HeightEntityType.Top,
                topHeight,
                cylinderProps.areHeightsDraggable
            );
        } else {
            this._topHeightOutline.setConstrainedHeight(topHeight);
        }

        if (!this._bottomHeightOutline) {
            this._bottomHeightOutline = new DraggableHeightEntity(
                this,
                this,
                HeightEntityType.Bottom,
                bottomHeight,
                cylinderProps.areHeightsDraggable
            );
        } else {
            this._bottomHeightOutline.setConstrainedHeight(bottomHeight);
        }

        this.doneCreation = true;
        this.updatePointsLayer();
        this.updateCylindersLayer();
        this.updateHeightOutlinesLayer();
    }

    public getGroundPointForHeight() {
        return this._center.getPosition();
    }

    public addPoint(position: Cartesian3) {
        if (this.doneCreation) {
            return;
        }

        if (!this._center) {
            this._center = new CylinderEditPoint(this, position, false, this.pointProps);
            this._radiusPoint = new CylinderEditPoint(this, position, true, this.pointProps);
            this._topHeightOutline = new DraggableHeightEntity(
                this,
                this,
                HeightEntityType.Top,
                0,
                this._cylinderProps.areHeightsDraggable
            );
            this._bottomHeightOutline = new DraggableHeightEntity(
                this,
                this,
                HeightEntityType.Bottom,
                0,
                this._cylinderProps.areHeightsDraggable
            );

            this.recalculateRadius();
        }

        this.updatePointsLayer();
        this.updateCylindersLayer();
        this.updateHeightOutlinesLayer();
    }

    public finishCreation() {
        if (this.doneCreation || !this._center || !this._radiusPoint) {
            return;
        }

        this.doneCreation = true;

        this.updatePointsLayer();
        this.updateCylindersLayer();
        this.updateHeightOutlinesLayer();
    }

    public moveRadiusPoint(toPosition: Cartesian3) {
        if (!this._center || !this._radiusPoint) {
            return;
        }

        const centerPosition = this._center.getPosition();
        if (
            this._cylinderProps.maxRadius !== undefined &&
            GeoUtilsService.distance(centerPosition, toPosition) > this._cylinderProps.maxRadius
        ) {
            toPosition = GeoUtilsService.pointByLocationDistanceAndAzimuth(
                centerPosition,
                this._cylinderProps.maxRadius,
                this.getAzimuthBetweenTwoPositions(centerPosition, toPosition)
            );
        } else if (
            this._cylinderProps.minRadius !== undefined &&
            GeoUtilsService.distance(centerPosition, toPosition) < this._cylinderProps.minRadius
        ) {
            toPosition = GeoUtilsService.pointByLocationDistanceAndAzimuth(
                centerPosition,
                this._cylinderProps.minRadius,
                this.getAzimuthBetweenTwoPositions(centerPosition, toPosition)
            );
        }

        this._radiusPoint.setPosition(toPosition);

        this.recalculateRadius();
        this.updatePointsLayer();
        this.updateCylindersLayer();
        this.updateHeightOutlinesLayer();
    }

    public moveTopHeight(newHeight: number) {
        this._topHeightOutline.setConstrainedHeight(newHeight);

        this.updatePointsLayer();
        this.updateCylindersLayer();
        this.updateHeightOutlinesLayer();
    }

    public moveBottomHeight(newHeight: number) {
        this._bottomHeightOutline.setConstrainedHeight(newHeight);

        this.updatePointsLayer();
        this.updateCylindersLayer();
        this.updateHeightOutlinesLayer();
    }

    public moveCylinder(dragStartPosition: Cartesian3, dragEndPosition: Cartesian3) {
        if (!this.doneCreation) {
            return;
        }
        if (!this.lastDraggedToPosition) {
            this.lastDraggedToPosition = dragStartPosition;
        }

        const delta = GeoUtilsService.getPositionsDelta(this.lastDraggedToPosition, dragEndPosition);
        const currentCenterPosition = this.getCenter();

        if (currentCenterPosition) {
            this._center.setPosition(GeoUtilsService.addDeltaToPosition(currentCenterPosition, delta, false));
            this.radiusPoint.setPosition(GeoUtilsService.addDeltaToPosition(this.radiusPoint.getPosition(), delta, false));
        }

        this.recalculateRadius();
        this.updatePointsLayer();
        this.updateCylindersLayer();
        this.updateHeightOutlinesLayer();
        this.lastDraggedToPosition = dragEndPosition;
    }

    public endMoveShape() {
        this.lastDraggedToPosition = undefined;
    }

    public recalculateRadius(): void {
        if (!this._center || !this._radiusPoint) {
            this._radius = 0;

            return;
        }

        this._radius = GeoUtilsService.distance(this._center.getPosition(), this._radiusPoint.getPosition());
    }

    public getStatus(): EntityStatus {
        return {
            isFinished: this.doneCreation,
        };
    }

    public getTopHeight(): number {
        return this._topHeightOutline?.height ?? 0;
    }

    public getBottomHeight(): number {
        return this._bottomHeightOutline?.height ?? 0;
    }

    public getRadiusCallbackProperty() {
        return new Cesium.CallbackProperty(() => this._radius, this.isStaticEntity);
    }

    public getTopHeightCallbackProperty() {
        return new Cesium.CallbackProperty(this.getTopHeight.bind(this), this.isStaticEntity);
    }

    public getBottomHeightCallbackProperty() {
        return new Cesium.CallbackProperty(this.getBottomHeight.bind(this), this.isStaticEntity);
    }

    public getCenter(): Cartesian3 | undefined {
        return this._center ? this._center.getPosition() : undefined;
    }

    public getRadius(): number {
        return this._radius;
    }

    public getCenterCallbackProperty() {
        return this._center?.getPositionCallbackProperty();
    }

    public dispose() {
        this.isDisposed = true;

        if (this._center) {
            this.pointsLayer.remove(this._center.getId());
        }

        if (this._radiusPoint) {
            this.pointsLayer.remove(this._radiusPoint.getId());
        }

        if (this._topHeightOutline) {
            this.heightOutlinesLayer.remove(this._topHeightOutline.id);
        }

        if (this._bottomHeightOutline) {
            this.heightOutlinesLayer.remove(this._bottomHeightOutline.id);
        }

        this.cylindersLayer.remove(this.id);
        this.cylinderLabelsLayer.remove(this.id);
    }

    public getId() {
        return this.id;
    }

    private getAzimuthBetweenTwoPositions(startPosition: Cartesian3, endPosition: Cartesian3): number {
        const ellipsoidGeodesic = new Cesium.EllipsoidGeodesic(
            Cesium.Cartographic.fromCartesian(startPosition),
            Cesium.Cartographic.fromCartesian(endPosition)
        );

        return ellipsoidGeodesic.startHeading;
    }

    private updateCylindersLayer() {
        this.cylindersLayer.update(this, this.id);
    }

    private updateHeightOutlinesLayer() {
        if (this._topHeightOutline) {
            this.heightOutlinesLayer.update(this._topHeightOutline, this._topHeightOutline.id);
        }

        if (this._bottomHeightOutline) {
            this.heightOutlinesLayer.update(this._bottomHeightOutline, this._bottomHeightOutline.id);
        }
    }

    private updatePointsLayer() {
        if (this._center) {
            this.pointsLayer.update(this._center, this._center.getId());
        }

        if (this._radiusPoint) {
            this.pointsLayer.update(this._radiusPoint, this._radiusPoint.getId());
        }
    }
}
