<div *ngrxLet="{ threadsList: threadsList$, selectedThread: selectedThread$ } as vm" class="list">
    <div *ngFor="let thread of vm.threadsList">
        <div
            tabindex="0"
            (click)="threadChange.emit(thread.id)"
            (keyup.enter)="threadChange.emit(thread.id)"
            [class.unread]="!thread.isRead"
            [class.selected-thread]="thread.id === vm.selectedThread"
            class="list-item"
            [id]="thread.id"
        >
            <div class="thread-container">
                <div class="thread-name" *ngrxLet="canDisplayAssignment$; let canDisplayAssignment">
                    <dtm-ui-thread-list-item-icon
                        [isRead]="thread.isRead"
                        [isAssigned]="!canDisplayAssignment || !!thread.assignee"
                        [isClosed]="thread.isClosed"
                    ></dtm-ui-thread-list-item-icon>
                    <p>{{ thread.name }}</p>
                </div>
                <span class="date">{{ thread.modifiedAt | messageDateDisplay }}</span>
            </div>
        </div>
        <ng-container *ngIf="vm.selectedThread === thread.id">
            <ng-content></ng-content>
        </ng-container>
    </div>
</div>
