<ng-container *ngIf="isClosed$ | ngrxPush; else openThreadTemplate">
    <dtm-ui-icon name="mail-lock" [matTooltip]="'dtmUi.conversations.closedThreadHint' | transloco"></dtm-ui-icon>
</ng-container>

<ng-template #openThreadTemplate>
    <ng-container *ngrxLet="isAssigned$; let isAssigned">
        <ng-container *ngrxLet="isRead$; let isRead">
            <ng-container [ngSwitch]="true">
                <dtm-ui-icon
                    name="mail-unread"
                    class="unread"
                    *ngSwitchCase="!isRead"
                    [matTooltip]="'dtmUi.conversations.unreadMessageInThreadHint' | transloco"
                ></dtm-ui-icon>
                <dtm-ui-icon
                    name="mail-close"
                    *ngSwitchCase="isRead && !isAssigned"
                    [matTooltip]="'dtmUi.conversations.noAssignmentHint' | transloco"
                ></dtm-ui-icon>
                <dtm-ui-icon name="mail-open" class="icon" *ngSwitchDefault></dtm-ui-icon>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>
