import { animate, AnimationTriggerMetadata, keyframes, style, transition, trigger } from "@angular/animations";

const DEFAULT_ANIMATION_TIME_MS = 300;
const DEFAULT_ANIMATION_TIME_WITH_EASE_MS = 500;
const DEFAULT_ANIMATION_DELAY_MS = 0;

export function slideInAnimation(
    duration: number = DEFAULT_ANIMATION_TIME_MS,
    delay: number = DEFAULT_ANIMATION_DELAY_MS
): AnimationTriggerMetadata[] {
    return [
        trigger("slideIn", [
            transition(":leave", [
                style({ overflow: "hidden", height: "*", paddingTop: "*", paddingBottom: "*" }),
                animate(`${duration}ms ${delay}ms`, style({ height: 0, paddingTop: 0, paddingBottom: 0 })),
            ]),
            transition(":enter", [
                style({ overflow: "hidden", height: "0", paddingTop: "0", paddingBottom: "0" }),
                animate(`${duration}ms ${delay}ms`, style({ height: "*", paddingTop: "*", paddingBottom: "*" })),
            ]),
        ]),
        trigger("slideInSide", [
            transition(":leave", [
                style({ overflow: "hidden", width: "*", paddingLeft: "*", paddingRight: "*" }),
                animate(`${duration}ms ${delay}ms`, style({ width: 0, paddingLeft: 0, paddingRight: 0 })),
            ]),
            transition(":enter", [
                style({ overflow: "hidden", width: "0", paddingLeft: "0", paddingRight: "0" }),
                animate(`${duration}ms ${delay}ms`, style({ width: "*", paddingLeft: "*", paddingRight: "*" })),
            ]),
        ]),
    ];
}

export function slideInAnimationWithMargin(
    duration: number = DEFAULT_ANIMATION_TIME_MS,
    delay: number = DEFAULT_ANIMATION_DELAY_MS
): AnimationTriggerMetadata[] {
    return [
        trigger("blockInitialRenderAnimation", [transition(":enter", [])]),
        trigger("slideInWithMargin", [
            transition(":leave", [
                style({
                    overflow: "hidden",
                    height: "*",
                    paddingTop: "*",
                    paddingBottom: "*",
                    marginTop: "*",
                    marginBottom: "*",
                    borderTopWidth: "*",
                    borderBottomWidth: "*",
                }),
                animate(`${Math.max(duration - 100, 0)}ms ${delay}ms`, style({ height: 0, paddingTop: 0, paddingBottom: 0 })),
                style({ borderTopWidth: 0, borderBottomWidth: 0 }),
                animate("100ms", style({ marginTop: 0, marginBottom: 0 })),
            ]),
            transition(":enter", [
                style({
                    overflow: "hidden",
                    height: "0",
                    paddingTop: "0",
                    paddingBottom: "0",
                    marginTop: "0",
                    marginBottom: "0",
                    borderTopWidth: "0",
                    borderBottomWidth: "0",
                }),
                animate(`100ms ${delay}ms`, style({ marginTop: "*", marginBottom: "*" })),
                style({ borderTopWidth: "*", borderBottomWidth: "*" }),
                animate(`${Math.max(duration - 100, 0)}ms`, style({ height: "*", paddingTop: "*", paddingBottom: "*" })),
            ]),
            transition("void => *", animate(0)),
        ]),
    ];
}

export function slideInSideOffScreenRight(
    duration: number = DEFAULT_ANIMATION_TIME_WITH_EASE_MS,
    delay: number = DEFAULT_ANIMATION_DELAY_MS
): AnimationTriggerMetadata {
    return trigger("slideInSideOffScreenRight", [
        transition(":enter", [
            animate(
                `${duration}ms ${delay}ms ease`,
                keyframes([style({ transform: "translateX(100%)" }), style({ transform: "translateX(0)" })])
            ),
        ]),
        transition(":leave", [
            animate(
                `${duration}ms ${delay}ms ease`,
                keyframes([style({ transform: "translateX(0)" }), style({ transform: "translateX(100%)" })])
            ),
        ]),
    ]);
}

export function slideInSideOffScreenDown(
    duration: number = DEFAULT_ANIMATION_TIME_WITH_EASE_MS,
    delay: number = DEFAULT_ANIMATION_DELAY_MS
): AnimationTriggerMetadata {
    return trigger("slideInSideOffScreenDown", [
        transition(":enter", [
            animate(
                `${duration}ms ${delay}ms ease`,
                keyframes([style({ transform: "translateY(100%)" }), style({ transform: "translateY(0)" })])
            ),
        ]),
        transition(":leave", [
            animate(
                `${duration}ms ${delay}ms ease`,
                keyframes([style({ transform: "translateY(0)" }), style({ transform: "translateY(100%)" })])
            ),
        ]),
    ]);
}

export function slideInSideOffScreenUp(
    duration: number = DEFAULT_ANIMATION_TIME_WITH_EASE_MS,
    delay: number = DEFAULT_ANIMATION_DELAY_MS
): AnimationTriggerMetadata {
    return trigger("slideInSideOffScreenUp", [
        transition(":enter", [
            animate(
                `${duration}ms ${delay}ms ease`,
                keyframes([style({ transform: "translateY(-100%)" }), style({ transform: "translateY(0)" })])
            ),
        ]),
        transition(":leave", [
            animate(
                `${duration}ms ${delay}ms ease`,
                keyframes([style({ transform: "translateY(0)" }), style({ transform: "translateY(-100%)" })])
            ),
        ]),
    ]);
}
