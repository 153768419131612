/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line max-len
// NOTE: this is based on https://github.com/pansa-dev/ngx-cesium/blob/HEAD/projects/@pansa/ngx-cesium/src/lib/@pansa/ngx-cesium-widgets/services/entity-editors/circles-editor/circles-editor.service.ts

import { Injectable } from "@angular/core";
import { NarrowIndexable, StringUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
    CameraService,
    Cartesian3,
    CesiumEvent,
    CesiumService,
    CoordinateConverter,
    DisposableObservable,
    EditActions,
    EditModes,
    EditPoint,
    EventResult,
    GeoUtilsService,
    LabelProps,
    MapEventsManagerService,
    PickOptions,
    PointProps,
} from "@pansa/ngx-cesium";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter, map, publish, startWith, tap } from "rxjs/operators";
import { CartographicEditPoint } from "../../../models/cartographic-edit-point";
import { CylinderEditOptions, CylinderProps } from "../../../models/cylinder/cylinder-edit-options";
import { CylinderEditUpdate } from "../../../models/cylinder/cylinder-edit-update";
import { CylinderEditorObservable } from "../../../models/cylinder/cylinder-editor-observable";
import { CylinderEditorLabelProviders, CylinderLabel, EditableCylinder } from "../../../models/cylinder/editable-cylinder";
import { DraggableHeightEntity, HeightEntityType, HeightHelperService, ShapeDragActions } from "../../height-helper.service";
import { CesiumPointerManagerService, CesiumPointerType } from "../../pointer-manager/cesium-pointer-manager.service";
import { CylindersManagerService } from "./cylinders-manager.service";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export const DEFAULT_CYLINDER_OPTIONS: CylinderEditOptions = {
    addPointEvent: CesiumEvent.LEFT_CLICK,
    dragPointEvent: CesiumEvent.LEFT_CLICK_DRAG,
    dragShapeEvent: CesiumEvent.LEFT_CLICK_DRAG,
    allowDrag: true,
    cylinderProps: {
        // eslint-disable-next-line no-magic-numbers
        material: Cesium.Color.fromCssColorString("rgb(6, 22, 54)").withAlpha(0.4), // $color-gray-900
        // eslint-disable-next-line no-magic-numbers
        shadowMaterial: Cesium.Color.WHITE.withAlpha(0.6),
        fill: true,
        outline: false,
        // eslint-disable-next-line no-magic-numbers
        outlineColor: Cesium.Color.WHITE.withAlpha(0.8),
        classificationType: Cesium.ClassificationType.BOTH,
        zIndex: 0,
        shadows: Cesium.ShadowMode.DISABLED,
    },
    pointProps: {
        color: Cesium.Color.WHITE,
        // eslint-disable-next-line no-magic-numbers
        outlineColor: Cesium.Color.BLACK.withAlpha(0.2),
        outlineWidth: 1,
        pixelSize: 13,
        virtualPointPixelSize: 8,
        show: true,
        showVirtual: true,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        radiusColor: Cesium.Color.WHITE,
        radiusOutlineColor: Cesium.Color.BLACK,
        radiusPixelSize: 10,
        radiusOutlineWidth: 1,
    },
};

enum CylinderActiveEntityTypes {
    Point = "Point",
    HeightOutline = "HeightOutline",
    ShapeOrNone = "ShapeOrNone",
}

@UntilDestroy()
@Injectable()
export class CylindersEditorService {
    private mapEventsManager!: MapEventsManagerService;
    private heightHelperService!: HeightHelperService;
    private updateSubject = new Subject<CylinderEditUpdate>();
    private updatePublisher = publish<CylinderEditUpdate>()(this.updateSubject);
    private coordinateConverter!: CoordinateConverter;
    private cameraService!: CameraService;
    private cylindersManager!: CylindersManagerService;
    private observablesMap = new Map<string, DisposableObservable<any>[]>();
    private cesiumViewer!: any;
    private cesiumScene!: any;
    private screenSpaceCameraController!: any;
    private activeEntitiesMap = new Map<string, CylinderActiveEntityTypes>();
    private cesiumPointerManager!: CesiumPointerManagerService;

    public init(
        mapEventsManager: MapEventsManagerService,
        coordinateConverter: CoordinateConverter,
        cameraService: CameraService,
        cylindersManager: CylindersManagerService,
        cesiumService: CesiumService,
        cesiumPointerManager: CesiumPointerManagerService,
        heightHelperService: HeightHelperService
    ) {
        this.mapEventsManager = mapEventsManager;
        this.coordinateConverter = coordinateConverter;
        this.cameraService = cameraService;
        this.cylindersManager = cylindersManager;
        this.updatePublisher.connect();
        this.cesiumScene = cesiumService.getScene();
        this.cesiumViewer = cesiumService.getViewer();
        this.screenSpaceCameraController = this.cesiumScene.screenSpaceCameraController;
        this.cesiumPointerManager = cesiumPointerManager;
        this.heightHelperService = heightHelperService;
    }

    public onUpdate(): Observable<CylinderEditUpdate> {
        return this.updatePublisher.pipe(tap(() => this.cesiumScene.requestRender()));
    }

    public create(id = StringUtils.generateId(), options = DEFAULT_CYLINDER_OPTIONS, initialCenter?: Cartesian3): CylinderEditorObservable {
        let center: Cartesian3;
        const cylinderOptions = this.setOptions(options);
        const clientEditSubject = new BehaviorSubject<CylinderEditUpdate>({
            id,
            editAction: null,
            editMode: EditModes.CREATE,
        });
        let finishedCreate = false;

        this.updateSubject.next({
            id,
            editMode: EditModes.CREATE,
            editAction: EditActions.INIT,
            cylinderOptions,
        });

        const finishCreation = (position?: Cartesian3) => {
            const cylinderProps = this.cylindersManager.get(id)?.cylinderProps;

            if (cylinderProps?.minRadius !== undefined && cylinderProps.minRadius > this.getRadius(id)) {
                return false;
            }

            const update: CylinderEditUpdate = {
                id,
                center,
                radiusPoint: position,
                editMode: EditModes.CREATE,
                editAction: EditActions.DONE,
            };
            this.updateSubject.next(update);
            clientEditSubject.next({
                ...update,
                ...this.getCylinderProperties(id),
            });

            const changeMode: CylinderEditUpdate = {
                id,
                center,
                radiusPoint: position,
                editMode: EditModes.CREATE,
                editAction: EditActions.CHANGE_TO_EDIT,
            };

            this.updateSubject.next(changeMode);
            clientEditSubject.next({
                ...update,
                ...this.getCylinderProperties(id),
            });
            if (this.observablesMap.has(id)) {
                this.observablesMap.get(id)?.forEach((registration) => registration.dispose());
            }
            this.observablesMap.delete(id);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            this.editCylinder(id, clientEditSubject, cylinderOptions, editorObservable);
            finishedCreate = true;

            return finishedCreate;
        };

        const mouseMoveRegistration = this.cesiumPointerManager.addEventHandler({
            event: CesiumEvent.MOUSE_MOVE,
            pick: PickOptions.NO_PICK,
        });
        const addPointRegistration = this.cesiumPointerManager.addEventHandler({
            event: CesiumEvent.LEFT_CLICK,
            pick: PickOptions.NO_PICK,
        });

        this.observablesMap.set(id, [mouseMoveRegistration, addPointRegistration]);
        const editorObservable = this.createEditorObservable(clientEditSubject, id, finishCreation);

        addPointRegistration.pipe(untilDestroyed(this)).subscribe(({ movement: { endPosition } }) => {
            if (finishedCreate) {
                return;
            }
            const position = this.coordinateConverter.screenToCartesian3(endPosition);
            if (!position) {
                return;
            }

            if (!center) {
                const update: CylinderEditUpdate = {
                    id,
                    center: position,
                    editMode: EditModes.CREATE,
                    editAction: EditActions.ADD_POINT,
                };
                this.updateSubject.next(update);
                clientEditSubject.next({
                    ...update,
                    ...this.getCylinderProperties(id),
                });
                center = position;
            } else {
                finishedCreate = finishCreation(position);
            }
        });

        mouseMoveRegistration.pipe(untilDestroyed(this)).subscribe(({ movement: { endPosition } }) => {
            if (!center) {
                return;
            }

            const position = this.coordinateConverter.screenToCartesian3(endPosition);

            if (position) {
                const update: CylinderEditUpdate = {
                    id,
                    center,
                    radiusPoint: position,
                    editMode: EditModes.CREATE,
                    editAction: EditActions.MOUSE_MOVE,
                };
                this.updateSubject.next(update);
                clientEditSubject.next({
                    ...update,
                    ...this.getCylinderProperties(id),
                });
            }
        });

        if (initialCenter) {
            const updateValue: CylinderEditUpdate = {
                id,
                center: initialCenter,
                editMode: EditModes.CREATE,
                editAction: EditActions.ADD_POINT,
            };
            this.updateSubject.next(updateValue);
            clientEditSubject.next({
                ...updateValue,
                ...this.getCylinderProperties(id),
            });
            center = initialCenter;
        }

        return editorObservable;
    }

    public edit(
        center: Cartesian3,
        radius: number,
        topHeight = 0,
        bottomHeight = 0,
        id = StringUtils.generateId(),
        options = DEFAULT_CYLINDER_OPTIONS
    ): CylinderEditorObservable {
        const cylinderOptions = this.setOptions(options);
        const editSubject = new BehaviorSubject<CylinderEditUpdate>({
            id,
            editAction: null,
            editMode: EditModes.EDIT,
        });

        const radiusPoint: Cartesian3 = GeoUtilsService.pointByLocationDistanceAndAzimuth(center, radius, Math.PI / 2, true);

        const update: CylinderEditUpdate = {
            id,
            center,
            radiusPoint,
            topHeight,
            bottomHeight,
            editMode: EditModes.EDIT,
            editAction: EditActions.INIT,
            cylinderOptions,
        };
        this.updateSubject.next(update);
        editSubject.next({
            ...update,
        });

        return this.editCylinder(id, editSubject, cylinderOptions);
    }

    private editCylinder(
        id: string,
        editSubject: Subject<CylinderEditUpdate>,
        options: CylinderEditOptions,
        editObservable?: CylinderEditorObservable
    ): CylinderEditorObservable {
        this.activeEntitiesMap.set(id, CylinderActiveEntityTypes.ShapeOrNone);
        const editorObservable = editObservable || this.createEditorObservable(editSubject, id);

        const pointDragRegistration = this.cesiumPointerManager.addEventHandler({
            event: CesiumEvent.LEFT_CLICK_DRAG,
            entityType: CartographicEditPoint,
            pick: PickOptions.PICK_FIRST,
            pickFilter: (entity) => id === entity.editedEntityId,
        });

        const heightPointDragRegistration = this.cesiumPointerManager.addEventHandler({
            event: CesiumEvent.LEFT_CLICK_DRAG,
            entityType: DraggableHeightEntity,
            pick: PickOptions.PICK_ALL,
            pickFilter: (entity: DraggableHeightEntity) => id === entity.parentEntityId,
        });

        let shapeDragRegistration;

        if (options.allowDrag) {
            shapeDragRegistration = this.cesiumPointerManager.addEventHandler({
                event: CesiumEvent.LEFT_CLICK_DRAG,
                entityType: EditableCylinder,
                pick: PickOptions.PICK_FIRST,
                pickFilter: (entity) => id === entity.id,
            });
        }

        const entityMouseHoverRegistration = this.cesiumPointerManager.addEventHandler({
            event: CesiumEvent.MOUSE_MOVE,
            pick: PickOptions.PICK_ALL,
        });

        pointDragRegistration
            .pipe(
                tap(({ movement: { drop } }) => drop !== undefined && this.cameraService.enableInputs(drop)),
                untilDestroyed(this)
            )
            .subscribe((event) => this.onPointDrag(event, id, options, editSubject));

        heightPointDragRegistration
            .pipe(
                tap(({ movement: { drop } }) => drop !== undefined && this.cameraService.enableInputs(drop)),
                untilDestroyed(this)
            )
            .subscribe((event) => this.onHeightDrag(event, id, editSubject));

        shapeDragRegistration
            ?.pipe(
                tap(({ movement: { drop } }) => drop !== undefined && this.cameraService.enableInputs(drop)),
                untilDestroyed(this)
            )
            .subscribe((event) => this.onShapeDrag(event, id, editSubject));

        entityMouseHoverRegistration.pipe(untilDestroyed(this)).subscribe((event) => this.onEntityMouseHover(event, id, editorObservable));

        const observables = [pointDragRegistration, heightPointDragRegistration, entityMouseHoverRegistration];

        if (shapeDragRegistration) {
            observables.push(shapeDragRegistration);
        }

        this.observablesMap.set(id, observables);

        return editorObservable;
    }

    private onHeightDrag(event: EventResult, cylinderId: string, editSubject: Subject<CylinderEditUpdate>) {
        if (this.activeEntitiesMap.get(cylinderId) !== CylinderActiveEntityTypes.HeightOutline) {
            return;
        }

        const {
            movement: { startPosition: cursorStartPosition, endPosition: cursorEndPosition, drop: isDrop },
            entities,
        } = event;
        const startDragPosition = this.coordinateConverter.screenToCartesian3(cursorStartPosition);
        const endDragPosition = this.coordinateConverter.screenToCartesian3(cursorEndPosition);
        const centerPosition = this.getCenterPosition(cylinderId);
        const draggedEntity = entities[0];

        if (!endDragPosition || !startDragPosition || entities.length === 0 || !centerPosition || !draggedEntity) {
            return;
        }

        const isDraggingTopHeight = draggedEntity.type === HeightEntityType.Top;

        const newHeight = this.heightHelperService.calculateHeightFromDragEvent(draggedEntity, cursorEndPosition, !!isDrop);

        const update: CylinderEditUpdate = {
            id: cylinderId,
            center: this.getCenterPosition(cylinderId),
            radiusPoint: this.getRadiusPosition(cylinderId),
            topHeight: isDraggingTopHeight ? newHeight : this.getTopHeight(cylinderId),
            bottomHeight: isDraggingTopHeight ? this.getBottomHeight(cylinderId) : newHeight,
            startDragPosition,
            endDragPosition,
            shapeDragAction: isDraggingTopHeight ? ShapeDragActions.ChangeTopHeight : ShapeDragActions.ChangeBottomHeight,
            editMode: EditModes.EDIT,
            editAction: isDrop ? EditActions.DRAG_SHAPE_FINISH : EditActions.DRAG_SHAPE,
        };
        this.updateSubject.next(update);
        editSubject.next({
            ...update,
            ...this.getCylinderProperties(cylinderId),
        });
    }

    private onShapeDrag(event: EventResult, cylinderId: string, editSubject: Subject<CylinderEditUpdate>) {
        if (this.activeEntitiesMap.get(cylinderId) !== CylinderActiveEntityTypes.ShapeOrNone) {
            return;
        }

        const {
            movement: { startPosition, endPosition, drop },
        } = event;
        const startDragPosition = this.coordinateConverter.screenToCartesian3(startPosition);
        const endDragPosition = this.coordinateConverter.screenToCartesian3(endPosition);

        if (!endDragPosition || !startDragPosition) {
            return;
        }

        const update: CylinderEditUpdate = {
            id: cylinderId,
            center: this.getCenterPosition(cylinderId),
            radiusPoint: this.getRadiusPosition(cylinderId),
            topHeight: this.getTopHeight(cylinderId),
            bottomHeight: this.getBottomHeight(cylinderId),
            startDragPosition,
            endDragPosition,
            editMode: EditModes.EDIT,
            shapeDragAction: ShapeDragActions.MoveEntity,
            editAction: drop ? EditActions.DRAG_SHAPE_FINISH : EditActions.DRAG_SHAPE,
        };
        this.updateSubject.next(update);
        editSubject.next({
            ...update,
            ...this.getCylinderProperties(cylinderId),
        });
    }

    private onPointDrag(event: EventResult, cylinderId: string, options: CylinderEditOptions, editSubject: Subject<CylinderEditUpdate>) {
        if (this.activeEntitiesMap.get(cylinderId) !== CylinderActiveEntityTypes.Point) {
            return;
        }

        const {
            movement: { endPosition, startPosition, drop },
            entities,
        } = event;
        const endDragPosition = this.coordinateConverter.screenToCartesian3(endPosition);

        if (!endDragPosition) {
            return;
        }

        const point: CartographicEditPoint = entities[0];

        const isCenterPoint = point === this.getCenterPoint(cylinderId);

        let editAction;
        if (drop) {
            editAction = isCenterPoint ? EditActions.DRAG_SHAPE_FINISH : EditActions.DRAG_POINT_FINISH;
        } else {
            editAction = isCenterPoint ? EditActions.DRAG_SHAPE : EditActions.DRAG_POINT;
        }

        if (!options.allowDrag && (editAction === EditActions.DRAG_SHAPE || editAction === EditActions.DRAG_SHAPE_FINISH)) {
            this.cameraService.enableInputs(true);

            return;
        }

        const startDragPosition = this.coordinateConverter.screenToCartesian3(startPosition);

        const update: CylinderEditUpdate = {
            id: cylinderId,
            center: this.getCenterPosition(cylinderId),
            radiusPoint: this.getRadiusPosition(cylinderId),
            topHeight: this.getTopHeight(cylinderId),
            bottomHeight: this.getBottomHeight(cylinderId),
            startDragPosition,
            endDragPosition,
            shapeDragAction: isCenterPoint ? ShapeDragActions.MoveEntity : undefined,
            editMode: EditModes.EDIT,
            editAction,
        };
        this.updateSubject.next(update);
        editSubject.next({
            ...update,
            ...this.getCylinderProperties(cylinderId),
        });
    }

    private onEntityMouseHover(event: EventResult, cylinderId: string, editorObservable: CylinderEditorObservable) {
        if (!this.screenSpaceCameraController.enableInputs || !this.cylindersManager.get(cylinderId)?.enableEdit) {
            return;
        }

        const isRadiusEntity = event.entities?.some(
            (lookupEntity) => lookupEntity instanceof EditPoint && lookupEntity.getId() === this.getRadiusPoint(cylinderId)?.getId()
        );
        if (isRadiusEntity) {
            this.setHoverLabelId(cylinderId, "radius", editorObservable);
            this.activeEntitiesMap.set(cylinderId, CylinderActiveEntityTypes.Point);

            return;
        }

        const isCenterEntity = event.entities?.some(
            (lookupEntity) => lookupEntity instanceof EditPoint && lookupEntity.getId() === this.getCenterPoint(cylinderId)?.getId()
        );
        if (isCenterEntity) {
            this.setHoverLabelId(cylinderId, "waypoint", editorObservable);
            this.activeEntitiesMap.set(cylinderId, CylinderActiveEntityTypes.Point);

            return;
        }

        const heightEntity: DraggableHeightEntity = event.entities?.find((lookupEntity) => lookupEntity instanceof DraggableHeightEntity);

        if (heightEntity && heightEntity.parentEntityId === cylinderId && heightEntity.isMovable()) {
            this.cesiumPointerManager.setPointerType(cylinderId, CesiumPointerType.HeightHandle);
            this.setHoverLabelId(cylinderId, heightEntity.type, editorObservable);
            this.activeEntitiesMap.set(cylinderId, CylinderActiveEntityTypes.HeightOutline);

            return;
        }

        this.cesiumPointerManager.setPointerType(cylinderId, CesiumPointerType.None);
        this.setHoverLabelId(cylinderId, "waypoint", editorObservable);
        this.activeEntitiesMap.set(cylinderId, CylinderActiveEntityTypes.ShapeOrNone);
    }

    private createEditorObservable(
        editorObservable: any,
        id: string,
        finishCreation?: (position?: Cartesian3) => boolean
    ): CylinderEditorObservable {
        const observableToExtend = editorObservable as CylinderEditorObservable;

        observableToExtend.dispose = () => {
            this.cesiumPointerManager.removePointer(id);
            const observables = this.observablesMap.get(id);
            if (observables) {
                observables.forEach((obs) => obs.dispose());
            }
            this.observablesMap.delete(id);
            this.activeEntitiesMap.delete(id);
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE_OR_EDIT,
                editAction: EditActions.DISPOSE,
            });
        };

        observableToExtend.enable = () => {
            this.updateSubject.next({
                id,
                center: this.getCenterPosition(id),
                radiusPoint: this.getRadiusPosition(id),
                topHeight: this.getTopHeight(id),
                bottomHeight: this.getBottomHeight(id),
                editMode: EditModes.EDIT,
                editAction: EditActions.ENABLE,
            });
        };

        observableToExtend.disable = () => {
            this.updateSubject.next({
                id,
                center: this.getCenterPosition(id),
                radiusPoint: this.getRadiusPosition(id),
                topHeight: this.getTopHeight(id),
                bottomHeight: this.getBottomHeight(id),
                editMode: EditModes.EDIT,
                editAction: EditActions.DISABLE,
            });
        };

        observableToExtend.setManually = (
            center: Cartesian3,
            radius: number,
            topHeight?: number,
            bottomHeight?: number,
            centerPointProp?: PointProps,
            radiusPointProp?: PointProps,
            cylinderProp?: CylinderProps
        ) => {
            const radiusPoint = GeoUtilsService.pointByLocationDistanceAndAzimuth(center, radius, Math.PI / 2, true);
            const cylinder = this.cylindersManager.get(id);
            cylinder?.setManually(center, radiusPoint, topHeight, bottomHeight, centerPointProp, radiusPointProp, cylinderProp);

            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE_OR_EDIT,
                editAction: EditActions.SET_MANUALLY,
            });
        };

        observableToExtend.setLabelsRenderFn = (callback: (update: CylinderEditUpdate, labels: LabelProps[]) => LabelProps[]) => {
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE_OR_EDIT,
                editAction: EditActions.SET_EDIT_LABELS_RENDER_CALLBACK,
                labelsRenderFn: (update: CylinderEditUpdate, labels: CylinderLabel[]) => callback(update, labels) as CylinderLabel[],
            });
        };

        observableToExtend.updateLabels = (labels: LabelProps[]) => {
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE_OR_EDIT,
                editAction: EditActions.UPDATE_EDIT_LABELS,
                updateLabels: labels as CylinderLabel[],
            });
        };

        observableToExtend.refreshLabels = () => {
            this.updateSubject.next({
                id,
                editMode: EditModes.CREATE_OR_EDIT,
                editAction: EditActions.UPDATE_EDIT_LABELS,
            });
        };

        observableToExtend.finishCreation = () => {
            if (!finishCreation) {
                throw new Error("Cylinders editor error edit(): cannot call finishCreation() on edit");
            }

            return finishCreation(undefined);
        };

        observableToExtend.status$ = this.getStatusObservable(id);
        observableToExtend.getId = () => id;
        observableToExtend.getLabels = () => this.cylindersManager.get(id)?.labels ?? [];
        observableToExtend.getCenter = () => this.getCenterPosition(id);
        observableToExtend.getRadius = () => this.getRadius(id);
        observableToExtend.getTopHeight = () => this.getTopHeight(id);
        observableToExtend.getBottomHeight = () => this.getBottomHeight(id);

        return observableToExtend as CylinderEditorObservable;
    }

    private setOptions(options: CylinderEditOptions): CylinderEditOptions {
        const defaultClone = JSON.parse(JSON.stringify(DEFAULT_CYLINDER_OPTIONS));
        const cylinderOptions = Object.assign(defaultClone, options);
        cylinderOptions.pointProps = Object.assign({}, DEFAULT_CYLINDER_OPTIONS.pointProps, options.pointProps);
        cylinderOptions.cylinderProps = Object.assign({}, DEFAULT_CYLINDER_OPTIONS.cylinderProps, options.cylinderProps);

        return cylinderOptions;
    }

    private getTopHeight(id: string): number {
        return this.cylindersManager.get(id)?.getTopHeight() ?? 0;
    }

    private getBottomHeight(id: string): number {
        return this.cylindersManager.get(id)?.getBottomHeight() ?? 0;
    }

    private getCenterPoint(id: string): CartographicEditPoint | undefined {
        return this.cylindersManager.get(id)?.center;
    }

    private getCenterPosition(id: string): Cartesian3 | undefined {
        return this.cylindersManager.get(id)?.getCenter();
    }

    private getRadiusPoint(id: string): CartographicEditPoint | undefined {
        return this.cylindersManager.get(id)?.radiusPoint;
    }

    private getRadiusPosition(id: string): Cartesian3 | undefined {
        return this.getRadiusPoint(id)?.getPosition();
    }

    private getRadius(id: string): number {
        return this.cylindersManager.get(id)?.getRadius() ?? 0;
    }

    private getStatusObservable(id: string) {
        const cylinder = this.cylindersManager.get(id);

        return this.updateSubject.pipe(
            filter((update) => update.id === id),
            startWith(undefined),
            map(() => cylinder?.getStatus())
        );
    }

    private setHoverLabelId<LabelKey extends keyof NarrowIndexable<CylinderEditorLabelProviders>>(
        cylinderId: string,
        labelId: LabelKey | undefined,
        editorObservable: CylinderEditorObservable
    ) {
        const cylinder = this.cylindersManager.get(cylinderId);

        if (cylinder && labelId !== cylinder.hoverLabelId) {
            cylinder.hoverLabelId = labelId;
            editorObservable.refreshLabels();
        }
    }

    private getCylinderProperties(id: string) {
        const cylinder = this.cylindersManager.get(id);

        if (!cylinder) {
            return;
        }

        return {
            center: cylinder.getCenter(),
            radiusPoint: cylinder.radiusPoint?.getPosition(),
            radius: cylinder.getRadius(),
            topHeight: cylinder.getTopHeight(),
            bottomHeight: cylinder.getBottomHeight(),
        };
    }
}
