import { Pipe, PipeTransform } from "@angular/core";
import { DEFAULT_COUNTRY_CODE } from "@dtm-frontend/shared/utils";

const defaultCountryPostCodeRegexp = /[0-9]{5}/;

@Pipe({
    name: "postCode",
})
export class PostCodePipe implements PipeTransform {
    public transform(value: string | undefined | null, countryCode: string): string | undefined {
        if (!value) {
            return undefined;
        }

        if (defaultCountryPostCodeRegexp.test(value) && countryCode === DEFAULT_COUNTRY_CODE) {
            // eslint-disable-next-line no-magic-numbers
            return `${value.slice(0, 2)} - ${value.slice(2, 5)}`;
        }

        return value;
    }
}
