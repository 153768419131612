<div [class.disabled]="isDisabled" *ngrxLet="isDisabled$ as isDisabled">
    <ng-container *ngrxLet="isFileSelected$ as isFileSelected">
        <div
            class="dropzone"
            *ngrxLet="isDraggedOver$ as isDraggedOver"
            [class.highlighted]="isDraggedOver"
            [dtmUiFileDropzone]="!isDisabled && !isFileSelected"
            (filesDrop)="addFile($event.item(0))"
            (isFileDraggedOverChange)="draggedOverStatusChanged($event)"
        >
            <ng-container *ngIf="isFileSelected; else noFileSelectedTemplate">
                <h6 class="label">
                    <span #labelContent><ng-content select="label"></ng-content></span>
                    <ng-container *ngIf="labelContent | isEmptyElement">
                        {{ "dtmUi.imagePickerField.imageAdditionHeader" | transloco }}
                    </ng-container>
                </h6>
                <div class="selected-file" *ngrxLet="processingProgress$ as processingProgress">
                    <span class="image-preview" [class.portrait]="isPreviewPortraitOrientation$ | ngrxPush">
                        <img #imagePreview [dtmUiImgSecuredSrc]="previewImageUrl$ | ngrxPush" alt="" />
                    </span>
                    <button
                        *ngIf="processingProgress === undefined; else processingTemplate"
                        type="button"
                        class="button-icon"
                        (click)="removeFile()"
                    >
                        <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    </button>
                    <ng-template #processingTemplate>
                        <mat-progress-spinner diameter="20" color="accent" mode="determinate" [value]="processingProgress ?? 0">
                        </mat-progress-spinner>
                    </ng-template>
                </div>
            </ng-container>
            <ng-template #noFileSelectedTemplate>
                <p class="prompt" *ngIf="!isDraggedOver">
                    {{ "dtmUi.imagePickerField.dragAndDropOrLabel" | transloco }}
                    <a (click)="fileInput.click()">{{ "dtmUi.imagePickerField.browseLabel" | transloco }}</a>
                </p>
                <p class="prompt" *ngIf="isDraggedOver">
                    {{ "dtmUi.imagePickerField.dropHereLabel" | transloco }}
                </p>
            </ng-template>
            <input
                hidden
                [disabled]="isDisabled || isFileSelected"
                (change)="addFile(fileInput.files?.item(0) ?? null)"
                #fileInput
                type="file"
                [attr.accept]="ALLOWED_IMAGE_UPLOAD_MIME_TYPES | join : ','"
            />
        </div>
        <div class="hint" *ngIf="!(hasErrors$ | ngrxPush)">
            <ng-content select=".field-hint"></ng-content>
        </div>
        <dtm-ui-form-errors (hasErrors)="setHasErrors($event)">
            <div class="field-error" *ngIf="error$ | ngrxPush as error">
                {{ error }}
            </div>
            <ng-content select=".field-error"></ng-content>
        </dtm-ui-form-errors>
    </ng-container>
</div>
