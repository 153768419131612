export interface FileInGroup {
    id: string;
    name: string;
    size?: number;
    isRemovable?: boolean;
}

export interface FilesGroupValidators {
    singleFile?: boolean;
    maxSize?: number;
    allowedTypes?: Record<string, string> | string[];
}

export interface FilesGroup<T = string> {
    groupId: T;
    groupLabel: string;
    files: FileInGroup[];
    validators?: FilesGroupValidators;
}
