import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatLegacySelectChange as MatSelectChange } from "@angular/material/legacy-select";
import {
    FlightZoneGeometryConstraints,
    FlightZoneMapBaseGeometry,
    FlightZoneUtils,
    HeightReferences,
    HorizontalMeasureUnits,
    RestrictionAreaCylinderUnits,
    RestrictionAreaCylinderValues,
    RestrictionAreaUnits,
    VerticalMeasureUnits,
} from "@dtm-frontend/dss-shared-lib";
import { CylinderEntity, MapUtils } from "@dtm-frontend/shared/map/cesium";
import { GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { DEFAULT_DEBOUNCE_TIME, KILOMETERS_IN_NAUTICAL_MILE, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { Observable, debounceTime } from "rxjs";
import { distinctUntilChanged, map, withLatestFrom } from "rxjs/operators";
import { GeometryHeightLimiterService } from "../../../../services/geometry-height-limiter.service";

interface ZoneGeometryStepCylinderEditorComponentState extends FlightZoneMapBaseGeometry {
    isProcessing: boolean;
    cylinderEntity: CylinderEntity | undefined;
}

@UntilDestroy()
@Component({
    selector: "dss-client-lib-zone-geometry-step-cylinder-editor[horizontalMeasureUnits][verticalMeasureUnits][heightReferences]",
    templateUrl: "./zone-geometry-step-cylinder-editor.component.html",
    styleUrls: ["./zone-geometry-step-cylinder-editor.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ZoneGeometryStepCylinderEditorComponent implements OnInit {
    protected readonly GeographicCoordinatesType = GeographicCoordinatesType;

    protected readonly radiusFormControl = new UntypedFormControl(null);
    protected readonly centerPointLongitudeFormControl = new FormControl<number | null>(null, {
        validators: [Validators.required],
    });
    protected readonly centerPointLatitudeFormControl = new FormControl<number | null>(null, {
        validators: [Validators.required],
    });
    protected readonly radiusUnitFormControl = new UntypedFormControl(null);
    protected readonly lowerHeightFormControl = new UntypedFormControl(null);
    protected readonly lowerHeightUnitFormControl = new UntypedFormControl(null);
    protected readonly lowerHeightReferenceFormControl = new UntypedFormControl(null);
    protected readonly upperHeightFormControl = new UntypedFormControl(null);
    protected readonly upperHeightUnitFormControl = new UntypedFormControl(null);
    protected readonly upperHeightReferenceFormControl = new UntypedFormControl(null);
    protected readonly cylinderEditorForm = this.initCylinderEditorForm();

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly constraints$ = this.localStore.selectByKey("constraints");
    protected readonly cylinderEntity$ = this.localStore.selectByKey("cylinderEntity");
    protected readonly horizontalMeasureUnits$ = this.localStore.selectByKey("horizontalMeasureUnits");
    protected readonly verticalMeasureUnits$ = this.localStore.selectByKey("verticalMeasureUnits");
    protected readonly heightReferences$ = this.localStore.selectByKey("heightReferences");
    protected readonly maxLowerHeight$ = this.geometryHeightLimiterService.getMaxLowerHeightObservable(
        this.cylinderEditorForm,
        this.localStore.selectByKey("constraints")
    );
    protected readonly minUpperHeight$ = this.geometryHeightLimiterService.getMinUpperHeightObservable(
        this.cylinderEditorForm,
        this.localStore.selectByKey("constraints")
    );

    @Input()
    public set isProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isProcessing: !!value });
    }

    @Input()
    public set constraints(value: FlightZoneGeometryConstraints) {
        this.localStore.patchState({ constraints: value });
    }

    @Input()
    public set horizontalMeasureUnits(value: HorizontalMeasureUnits[] | undefined) {
        this.localStore.patchState({ horizontalMeasureUnits: value ?? [] });
    }

    @Input()
    public set verticalMeasureUnits(value: VerticalMeasureUnits[] | undefined) {
        this.localStore.patchState({ verticalMeasureUnits: value ?? [] });
    }

    @Input()
    public set heightReferences(value: HeightReferences[] | undefined) {
        this.localStore.patchState({ heightReferences: value ?? [] });
    }

    @Input()
    public set activeGeometryUnits(value: RestrictionAreaUnits | undefined) {
        if (!value) {
            return;
        }

        this.cylinderEditorForm.patchValue({ ...value });
    }

    @Input()
    public set cylinderEntity(value: CylinderEntity | undefined) {
        if (!value) {
            this.localStore.patchState({ cylinderEntity: value });
            this.centerPointLongitudeFormControl.reset();
            this.centerPointLatitudeFormControl.reset();

            return;
        }

        const cartographic = MapUtils.convertCartesian3ToSerializableCartographic(value.center);
        const radiusRounded: number = FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(value.radius, this.radiusUnitFormControl.value, 1);
        const upperHeightRounded: number = FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(
            value.topHeight ?? 0,
            this.upperHeightUnitFormControl.value,
            0
        );
        const lowerHeightRounded: number = FlightZoneUtils.metersToOtherUnitsOfMeasureRounded(
            value.bottomHeight,
            this.lowerHeightUnitFormControl.value,
            0
        );

        this.radiusFormControl.setValue(radiusRounded);
        this.upperHeightFormControl.setValue(upperHeightRounded);
        this.lowerHeightFormControl.setValue(lowerHeightRounded);
        this.centerPointLongitudeFormControl.setValue(cartographic.longitude);
        this.centerPointLatitudeFormControl.setValue(cartographic.latitude);
        this.cylinderEditorForm.markAllAsTouched();

        this.localStore.patchState({
            cylinderEntity: {
                ...value,
                radius: radiusRounded,
                topHeight: upperHeightRounded,
                bottomHeight: lowerHeightRounded,
            },
        });
    }

    @Output() public cylinderEntityUpdate = new EventEmitter<{ entity: CylinderEntity; units: RestrictionAreaUnits }>();
    @Output() public statusChange: Observable<boolean> = this.cylinderEditorForm.statusChanges.pipe(
        distinctUntilChanged(),
        map((status) => status === "VALID")
    );

    constructor(
        private readonly localStore: LocalComponentStore<ZoneGeometryStepCylinderEditorComponentState>,
        private readonly geometryHeightLimiterService: GeometryHeightLimiterService
    ) {
        this.localStore.setState({
            isProcessing: false,
            cylinderEntity: undefined,
            constraints: undefined,
            horizontalMeasureUnits: [],
            verticalMeasureUnits: [],
            heightReferences: [],
        });
    }

    public ngOnInit(): void {
        this.listenToCylinderEntityChanges();
    }

    protected updateRadiusValue(unit: MatSelectChange): void {
        const originalValue: number = this.radiusFormControl.value;
        const convertedValue: number =
            unit.value === HorizontalMeasureUnits.Kilometers
                ? originalValue * KILOMETERS_IN_NAUTICAL_MILE
                : originalValue / KILOMETERS_IN_NAUTICAL_MILE;

        this.radiusFormControl.setValue(convertedValue);
    }

    protected updateHeightValue(unit: MatSelectChange, heightFormControl: FormControl): void {
        const convertMethod =
            unit.value === VerticalMeasureUnits.Feet
                ? FlightZoneUtils.metersToOtherUnitsOfMeasureRounded
                : FlightZoneUtils.otherUnitsOfMeasureToMetersRounded;
        heightFormControl.setValue(convertMethod(heightFormControl.value, VerticalMeasureUnits.Feet, 0));
    }

    private initCylinderEditorForm(): UntypedFormGroup {
        const formBody: Record<keyof RestrictionAreaCylinderValues & RestrictionAreaCylinderUnits, UntypedFormControl> = {
            centerPointLongitude: this.centerPointLongitudeFormControl,
            centerPointLatitude: this.centerPointLatitudeFormControl,
            radius: this.radiusFormControl,
            radiusUnit: this.radiusUnitFormControl,
            lowerHeight: this.lowerHeightFormControl,
            lowerHeightUnit: this.lowerHeightUnitFormControl,
            lowerHeightReference: this.lowerHeightReferenceFormControl,
            upperHeight: this.upperHeightFormControl,
            upperHeightUnit: this.upperHeightUnitFormControl,
            upperHeightReference: this.upperHeightReferenceFormControl,
        };

        return new UntypedFormGroup(formBody);
    }

    private listenToCylinderEntityChanges(): void {
        this.cylinderEditorForm.valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                distinctUntilChanged(equal),
                withLatestFrom(this.cylinderEntity$),
                untilDestroyed(this)
            )
            .subscribe(([formValue, currentCylinderEntity]) => {
                if (!currentCylinderEntity || this.cylinderEditorForm.invalid) {
                    return;
                }

                const cartesianCenterPosition = MapUtils.convertSerializableCartographicToCartesian3({
                    latitude: formValue.centerPointLatitude,
                    longitude: formValue.centerPointLongitude,
                    height: 0,
                });

                this.cylinderEntityUpdate.emit({
                    entity: {
                        ...currentCylinderEntity,
                        center: cartesianCenterPosition,
                        radius: FlightZoneUtils.otherUnitsOfMeasureToMeters(formValue.radius, formValue.radiusUnit),
                        bottomHeight: FlightZoneUtils.otherUnitsOfMeasureToMeters(formValue.lowerHeight, formValue.lowerHeightUnit),
                        topHeight: FlightZoneUtils.otherUnitsOfMeasureToMeters(formValue.upperHeight, formValue.upperHeightUnit),
                    },
                    units: {
                        radiusUnit: formValue.radiusUnit,
                        lowerHeightUnit: formValue.lowerHeightUnit,
                        lowerHeightReference: formValue.lowerHeightReference,
                        upperHeightUnit: formValue.upperHeightUnit,
                        upperHeightReference: formValue.upperHeightReference,
                    },
                });
            });
    }
}
