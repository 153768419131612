<dtm-ui-editable-box
    *ngrxLet="{
        phoneNumber: phoneNumber$,
        isEditMode: isEditMode$,
        isRequestedPhoneChange: isRequestedPhoneChange$,
        phoneNumberChangeDescription: phoneNumberChangeDescription$,
        saveButtonLabel: saveButtonLabel$,
        isDisabled: isDisabled$
    } as vm"
    [label]="'dtmUi.editPhoneNumber.phoneInputLabel' | transloco"
    [saveButtonLabel]="vm.saveButtonLabel"
    [canLeaveEditModeOnSave]="false"
    [isEditModeOpen]="vm.isEditMode"
    (save)="requestUserPhoneNumberChange(vm.isRequestedPhoneChange)"
    (closeEdition)="closeModifyPhoneNumberPanel()"
    [isEditButtonVisible]="!vm.isDisabled"
    [ngClass]="{ disabled: vm.isDisabled }"
>
    <div valueLabelSlot class="full-phone-number">
        <dtm-ui-country-flag-display [country]="vm.phoneNumber.countryCode ?? undefined"></dtm-ui-country-flag-display>
        <span>{{ vm.phoneNumber | formatPhoneNumber }}</span>
    </div>
    <ng-container editableContentSlot>
        <dtm-ui-edit-phone-number-form
            [isVerificationRequired]="isVerificationRequired$ | ngrxPush"
            [currentPhoneNumber]="vm.phoneNumber"
            [phoneNumberChangeDescription]="
                vm.phoneNumberChangeDescription
                    ? vm.phoneNumberChangeDescription
                    : ('dtmUi.editPhoneNumber.phoneNumberChangeDescription' | transloco)
            "
            [isRequestedPhoneChange]="vm.isRequestedPhoneChange"
            [hasPhoneNumberConflictError]="hasPhoneNumberConflictError$ | ngrxPush"
            [formControl]="phoneNumberFormControl"
            [verificationCodeMask]="verificationCodeMask$ | ngrxPush"
            (resendVerificationCode)="sendPhoneVerificationCode()"
            [shouldCheckIfPhoneNumberSameValue]="shouldCheckIfPhoneNumberSameValue$ | ngrxPush"
            dtmUiMarkValueAccessorControlsAsTouched
        ></dtm-ui-edit-phone-number-form>
    </ng-container>
</dtm-ui-editable-box>
