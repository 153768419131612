<ng-container *ngrxLet="watchers$ as watchers">
    <div class="crew-member" *ngIf="watchers?.length as watchersLength">
        <div class="header">
            <dtm-ui-icon name="check"></dtm-ui-icon>
            <div class="name">
                <span>{{ "mission.additional_crew_members." + WATCHER_ID + ".name" | systemTranslation }}</span>
                <span> ({{ watchersLength }})</span>
            </div>
        </div>

        <div class="watcher" *ngFor="let watcher of watchers">
            <dtm-ui-label-value
                [label]="'dtmSharedMission.crew.nameAndSurnameLabel' | transloco"
                [value]="watcher.name"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmSharedMission.crew.phoneLabel' | transloco"
                [value]="watcher.phoneNumber | formatPhoneNumber"
            ></dtm-ui-label-value>
        </div>
    </div>

    <div class="crew-member" *ngFor="let member of additionalCrewMembers$ | ngrxPush">
        <div class="header">
            <dtm-ui-icon name="check"></dtm-ui-icon>
            <div class="name">
                <span>{{ "mission.additional_crew_members." + member.type + ".name" | systemTranslation }}</span>
            </div>
        </div>
    </div>
</ng-container>
