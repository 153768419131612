import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { TimeRange } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ActionType, CesiumEvent, CesiumService, CoordinateConverter, EventResult, PickOptions } from "@pansa/ngx-cesium";
import { Subject, filter, first, map, switchMap } from "rxjs";
import { AirspaceElement, AirspaceElementsInfo } from "../../../geo-zones/models/geo-zones.models";
import { MapActionType, MapEntitiesEditorService } from "../../services/entity-editors/map-entities-editor.service";
import { CesiumPointerManagerService } from "../../services/pointer-manager/cesium-pointer-manager.service";

interface GeographicalZonesInfoComponentState {
    zonesWithInfo: AirspaceElement[];
    isInfoShown: boolean;
    selectedZoneId: string | undefined;
    isEnabled: boolean;
    isProcessing: boolean;
    aupEndTime: Date | undefined;
    timeRange: TimeRange | undefined;
    airacEndTime: Date | undefined;
    isError: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-map-geographical-zones-info",
    templateUrl: "./geographical-zones-info.component.html",
    styleUrls: ["./geographical-zones-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class GeographicalZonesInfoComponent implements AfterViewInit {
    @Output() public readonly zoneSelectionChange = new EventEmitter<AirspaceElement | undefined>();
    @Output() public readonly getZonesInfo = new EventEmitter<string[]>();

    @Input() public set selectedZoneId(value: string | undefined) {
        this.localStore.patchState({ selectedZoneId: value });
    }
    @Input() public set isEnabled(value: BooleanInput) {
        this.localStore.patchState({ isEnabled: coerceBooleanProperty(value) });
        if (!coerceBooleanProperty(value)) {
            this.localStore.patchState({ zonesWithInfo: [], isInfoShown: false });
            this.removePinFromMap();
        }
    }
    @Input() public set zonesWithInfo(value: AirspaceElementsInfo | undefined) {
        this.localStore.patchState({
            zonesWithInfo: value?.airspaceElements ?? [],
            isProcessing: false,
            aupEndTime: value?.aupEndTime,
            timeRange: value?.scopeTimeRange,
            airacEndTime: value?.airacEndTime,
        });

        if (!value?.airspaceElements.length) {
            this.closeInfo();
        }
    }
    @Input() public set isError(value: BooleanInput) {
        this.localStore.patchState({ isError: coerceBooleanProperty(value) });
    }

    protected readonly mapPinEntity$ = new Subject();
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly isInfoShown$ = this.localStore.selectByKey("isInfoShown");
    protected readonly isEnabled$ = this.localStore.selectByKey("isEnabled");
    protected readonly zonesWithInfo$ = this.localStore.selectByKey("zonesWithInfo");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly aupEndTime$ = this.localStore.selectByKey("aupEndTime");
    protected readonly timeRange$ = this.localStore.selectByKey("timeRange");
    protected readonly airacEndTime$ = this.localStore.selectByKey("airacEndTime");
    protected readonly isError$ = this.localStore.selectByKey("isError");

    constructor(
        private readonly eventManager: CesiumPointerManagerService,
        private readonly coordinateConverter: CoordinateConverter,
        private readonly cesiumService: CesiumService,
        private readonly localStore: LocalComponentStore<GeographicalZonesInfoComponentState>,
        private readonly mapEntitiesEditorService: MapEntitiesEditorService
    ) {
        this.localStore.setState({
            isInfoShown: false,
            selectedZoneId: undefined,
            isEnabled: false,
            zonesWithInfo: [],
            isProcessing: true,
            aupEndTime: undefined,
            timeRange: undefined,
            airacEndTime: undefined,
            isError: false,
        });
    }

    public ngAfterViewInit(): void {
        this.watchMapClickEventAndUpdatesZonesInfo();
    }

    protected closeInfo() {
        this.localStore.patchState({ zonesWithInfo: [], isInfoShown: false });
        this.removePinFromMap();
        this.zoneSelectionChange.emit(undefined);
    }

    private watchMapClickEventAndUpdatesZonesInfo() {
        this.eventManager
            .addEventHandler({
                event: CesiumEvent.LEFT_CLICK,
                pick: PickOptions.PICK_ALL,
            })
            .pipe(
                switchMap((event) =>
                    this.mapEntitiesEditorService.activeMapAction$.pipe(
                        first(),
                        filter((action) => action === MapActionType.None),
                        map(() => event)
                    )
                ),
                untilDestroyed(this)
            )
            .subscribe((event) => {
                if (!this.localStore.selectSnapshotByKey("isEnabled")) {
                    return;
                }
                const designators = event.cesiumEntities?.map(({ designator }) => designator).filter(FunctionUtils.isTruthy) ?? [];
                const isInfoShown = designators.length > 0;
                this.localStore.patchState({
                    isInfoShown,
                    isProcessing: true,
                });

                if (isInfoShown) {
                    this.getZonesInfo.emit(designators);
                    this.zoneSelectionChange.emit(undefined);
                    this.setPinOnMap(event);
                } else {
                    this.removePinFromMap();
                    this.localStore.patchState({ zonesWithInfo: [] });
                }
            });
    }

    private setPinOnMap(event: EventResult) {
        this.mapPinEntity$.next({
            actionType: ActionType.ADD_UPDATE,
            entity: { position: this.coordinateConverter.screenToCartesian3(event.movement.endPosition) },
            id: "pin",
        });
        this.cesiumService.getScene().requestRender();
    }

    private removePinFromMap() {
        this.mapPinEntity$.next({
            actionType: ActionType.DELETE,
            id: "pin",
        });
        this.cesiumService.getScene().requestRender();
    }
}
