import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { TimeRange } from "@dtm-frontend/shared/ui";
import { DEFAULT_LANG, I18nService, LOCALE_MAPPING } from "@dtm-frontend/shared/ui/i18n";
import { DateUtils, LocalComponentStore, ObjectUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import { GeoZonesUtils } from "../../../../geo-zones/index";
import {
    AirspaceElement,
    AirspaceElementStatus,
    AirspaceScope,
    GeoZoneType,
    Reservation,
} from "../../../../geo-zones/models/geo-zones.models";
import { CameraHelperService } from "../../../services/camera-helper.service";

interface GeographicalZonesInfoDetailsComponentState {
    zone: AirspaceElement | undefined;
    selectedZoneId: string | undefined;
    isOpened: boolean;
    aupEndTime: Date | undefined;
    isInformationExpanded: boolean;
    timeRange: TimeRange | undefined;
    airacEndTime: Date | undefined;
}

const DEFAULT_TABLE_VISIBLE_ROWS = 3;

@Component({
    selector: "dtm-map-geographical-zones-info-details[zone]",
    templateUrl: "./geographical-zones-info-details.component.html",
    styleUrls: ["./geographical-zones-info-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class GeographicalZonesInfoDetailsComponent {
    @Output() public readonly zoneSelectionChange = new EventEmitter<AirspaceElement | undefined>();

    @Input() public set zone(value: AirspaceElement) {
        this.localStore.patchState({ zone: value });
    }
    @Input() public set selectedZoneId(value: string | undefined) {
        this.localStore.patchState({ selectedZoneId: value });
    }
    @Input() public set isOpened(value: BooleanInput) {
        this.localStore.patchState({ isOpened: coerceBooleanProperty(value) });
    }
    @Input() public set aupEndTime(value: Date | undefined) {
        this.localStore.patchState({ aupEndTime: value });
    }
    @Input() public set timeRange(value: TimeRange | undefined) {
        this.localStore.patchState({ timeRange: value });
    }
    @Input() public set airacEndTime(value: Date | undefined) {
        this.localStore.patchState({ airacEndTime: value });
    }

    protected readonly zone$ = this.localStore.selectByKey("zone");
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly activeLanguageCode$ = this.i18nService.currentLanguage$.pipe(
        map((language) => LOCALE_MAPPING[language] ?? LOCALE_MAPPING[DEFAULT_LANG])
    );
    protected readonly isOpened$ = this.localStore.selectByKey("isOpened");
    protected readonly aupEndTime$ = this.localStore.selectByKey("aupEndTime");
    protected readonly isInformationExpanded$ = this.localStore.selectByKey("isInformationExpanded");
    protected readonly timeRange$ = this.localStore.selectByKey("timeRange");
    protected readonly airacEndTime$ = this.localStore.selectByKey("airacEndTime");

    protected readonly GeoZoneType = GeoZoneType;
    protected readonly DEFAULT_TABLE_VISIBLE_ROWS = DEFAULT_TABLE_VISIBLE_ROWS;

    protected readonly getZoneIconName = GeoZonesUtils.getZoneIconName;
    protected readonly selectEarlierDate = DateUtils.selectEarlierDate;

    constructor(
        private readonly localStore: LocalComponentStore<GeographicalZonesInfoDetailsComponentState>,
        private readonly i18nService: I18nService,
        private readonly cameraHelperService: CameraHelperService,
        private readonly elementRef: ElementRef
    ) {
        this.localStore.setState({
            zone: undefined,
            selectedZoneId: undefined,
            isOpened: false,
            aupEndTime: undefined,
            isInformationExpanded: false,
            timeRange: undefined,
            airacEndTime: undefined,
        });
    }

    private static mergeContinuesReservations(reservations: Reservation[]): Reservation[] {
        return ObjectUtils.cloneDeep(reservations).reduce<Reservation[]>((mergedReservations, reservation, index) => {
            const previousReservation = mergedReservations[index - 1];

            if (
                previousReservation &&
                previousReservation.status === reservation.status &&
                previousReservation.scope.endTime.getTime() === reservation.scope.startTime.getTime() &&
                previousReservation.scope.lowerLimit === reservation.scope.lowerLimit &&
                previousReservation.scope.upperLimit === reservation.scope.upperLimit
            ) {
                previousReservation.scope.endTime = reservation.scope.endTime;

                return mergedReservations;
            }

            return [...mergedReservations, reservation];
        }, []);
    }

    protected selectZone(zone: AirspaceElement) {
        if (zone.id === this.localStore.selectSnapshotByKey("selectedZoneId")) {
            this.localStore.patchState({ selectedZoneId: undefined });
            this.zoneSelectionChange.emit(undefined);

            return;
        }

        this.localStore.patchState({ selectedZoneId: zone.id });
        this.zoneSelectionChange.emit(zone);
        this.cameraHelperService.flyToGeoJSON(
            zone.geometry,
            undefined,
            undefined,
            undefined,
            this.elementRef.nativeElement.offsetWidth ?? 0
        );
    }

    protected getGroupedReservations(reservations: Reservation[]): { reservation: Reservation; isEven: boolean }[] {
        return reservations.reduce<{ reservation: Reservation; isEven: boolean }[]>((grouped, reservation, index) => {
            const currentReservationDay = reservation.scope.startTime.getDate();
            const previousReservationDay = reservations[index - 1]?.scope.startTime.getDate();
            const isSameDay = currentReservationDay === previousReservationDay;
            const previousIsEven = !!grouped[index - 1]?.isEven;

            return [...grouped, { reservation, isEven: isSameDay ? previousIsEven : !previousIsEven }];
        }, []);
    }

    protected toggleInformation() {
        this.localStore.patchState(({ isInformationExpanded }) => ({
            isInformationExpanded: !isInformationExpanded,
        }));
    }

    protected partitionReservation(reservations: Reservation[]): { current: Reservation[]; next: Reservation[] } {
        const mergedReservations = GeographicalZonesInfoDetailsComponent.mergeContinuesReservations(reservations);
        const currentReservations = mergedReservations.filter((reservation) => reservation.status === AirspaceElementStatus.Active);
        const nextReservations = mergedReservations.filter((reservation) => reservation.status === AirspaceElementStatus.Waiting);

        return { current: currentReservations, next: nextReservations };
    }

    protected toggleTableMode(element: HTMLElement) {
        element.classList.toggle("extended");
    }

    protected scopeToTimeRange(scope?: AirspaceScope): TimeRange | undefined {
        if (!scope) {
            return;
        }

        return {
            min: scope.startTime,
            max: scope.endTime,
        };
    }

    protected isPredictedReservation(reservation: Reservation, airacEndTime?: Date): boolean {
        if (!airacEndTime) {
            return false;
        }

        return reservation.scope.startTime.getTime() > airacEndTime.getTime();
    }

    protected isBefore(date?: Date, compareDate?: Date): boolean {
        if (!compareDate || !date) {
            return false;
        }

        return date.getTime() < compareDate.getTime();
    }
}
