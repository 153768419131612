<div
    class="weather-conditions"
    *ngrxLet="{ selectedRange: selectedRange$, isWithinDtm: isWithinDtm$ } as vm"
    [class.basic-grid]="!vm.isWithinDtm"
>
    <ng-container
        [ngTemplateOutlet]="vm.isWithinDtm ? extendedWeatherViewTemplate : simpleWeatherViewTemplate"
        [ngTemplateOutletContext]="{ $implicit: vm.selectedRange }"
    ></ng-container>
</div>
<ng-template #extendedWeatherViewTemplate let-selectedRange>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.windSpeed100Agl?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.windSpeedLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.windSpeed10Agl"
    >
        <ng-container
            *ngIf="selectedRange.windSpeed10Agl && selectedRange.windSpeed100Agl"
            [ngTemplateOutlet]="extendedConditionTemplate"
            [ngTemplateOutletContext]="{
                min: selectedRange.windSpeed10Agl.min,
                max: selectedRange.windSpeed10Agl.max,
                minHigher: selectedRange.windSpeed100Agl.min,
                maxHigher: selectedRange.windSpeed100Agl.max,
                unit: selectedRange.windSpeed100Agl.unit,
            }"
        >
        </ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.windGusts10Agl?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.windGustLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.windGusts10Agl"
    >
        <ng-container
            *ngIf="selectedRange.windGusts10Agl"
            [ngTemplateOutlet]="baseConditionTemplate"
            [ngTemplateOutletContext]="{
                min: selectedRange.windGusts10Agl.min,
                max: selectedRange.windGusts10Agl.max,
                unit: selectedRange.windGusts10Agl.unit,
            }"
        >
        </ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.windDirection100Agl?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.windDirectionLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.windDirection10Agl"
    >
        <ng-container
            *ngIf="selectedRange.windDirection10Agl && selectedRange.windDirection100Agl"
            [ngTemplateOutlet]="extendedConditionTemplate"
            [ngTemplateOutletContext]="{
                min: selectedRange.windDirection10Agl.min | invoke : roundUp,
                max: selectedRange.windDirection10Agl.max | invoke : roundUp,
                minHigher: selectedRange.windDirection100Agl.min | invoke : roundUp,
                maxHigher: selectedRange.windDirection100Agl.max | invoke : roundUp,
                unit: selectedRange.windDirection10Agl.unit,
                windDirection: +selectedRange.windDirection10Agl.avg | invoke : getCardinalDirection
            }"
        ></ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.kpi?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.kpiLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.kpi"
    >
        <ng-container
            *ngIf="selectedRange.kpi"
            [ngTemplateOutlet]="baseConditionTemplate"
            [ngTemplateOutletContext]="{
                avg: selectedRange.kpi.avg,
                unit: selectedRange.kpi.unit
            }"
        >
        </ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.temp?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.temperatureLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.temp"
    >
        <ng-container
            *ngIf="selectedRange.temp"
            [ngTemplateOutlet]="baseConditionTemplate"
            [ngTemplateOutletContext]="{
                min: selectedRange.temp.min,
                max: selectedRange.temp.max,
                unit: selectedRange.temp.unit
            }"
        >
        </ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.pressureAmsl?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.pressureLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.pressureAmsl"
    >
        <ng-container
            *ngIf="selectedRange.pressureAmsl"
            [ngTemplateOutlet]="baseConditionTemplate"
            [ngTemplateOutletContext]="{
                min: selectedRange.pressureAmsl.min,
                max: selectedRange.pressureAmsl.max,
                unit: selectedRange.pressureAmsl.unit
            }"
        ></ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.rain?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.rainLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.rain"
    >
        <ng-container
            *ngIf="selectedRange.rain"
            [ngTemplateOutlet]="rainTemplate"
            [ngTemplateOutletContext]="{
                min: selectedRange.rain.min,
                max: selectedRange.rain.max,
                unit: selectedRange.rain.unit
            }"
        >
        </ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.relHumidity?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.humidityLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.relHumidity"
    >
        <ng-container
            *ngIf="selectedRange.relHumidity"
            [ngTemplateOutlet]="baseConditionTemplate"
            [ngTemplateOutletContext]="{
                min: selectedRange.relHumidity.min,
                max: selectedRange.relHumidity.max,
                unit: selectedRange.relHumidity.unit
            }"
        >
        </ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.dewPointTemperature?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.dewPointTemperatureLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.dewPointTemperature"
    >
        <ng-container
            *ngIf="selectedRange.dewPointTemperature"
            [ngTemplateOutlet]="baseConditionTemplate"
            [ngTemplateOutletContext]="{
                min: selectedRange.dewPointTemperature.min,
                max: selectedRange.dewPointTemperature.max,
                unit: selectedRange.dewPointTemperature.unit
            }"
        >
        </ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.visibility?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.visibilityLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.visibility"
    >
        <ng-container
            *ngIf="selectedRange.visibility"
            [ngTemplateOutlet]="baseConditionTemplate"
            [ngTemplateOutletContext]="{
                avg: selectedRange.visibility.min,
                unit: selectedRange.visibility.unit
            }"
        >
        </ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.cloudBase?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.cloudBaseLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.cloudBase"
    >
        <ng-container
            *ngIf="selectedRange.cloudBase"
            [ngTemplateOutlet]="baseConditionTemplate"
            [ngTemplateOutletContext]="{
                            avg: selectedRange.cloudBase.avg,
                            unit: selectedRange.cloudBase.unit,
                        }"
        >
        </ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.totalCloudCover?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.totalCloudCoverLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.totalCloudCover"
    >
        <ng-container
            *ngIf="selectedRange.totalCloudCover"
            [ngTemplateOutlet]="cloudCoverTemplate"
            [ngTemplateOutletContext]="{
                avg: selectedRange.totalCloudCover.avg,
                unit: selectedRange.totalCloudCover.unit
            }"
        >
        </ng-container>
    </dtm-map-weather-condition-box>
</ng-template>
<ng-template #simpleWeatherViewTemplate let-selectedRange>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.windSpeed100Agl?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.windSpeedLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.windSpeed10Agl"
    >
        <ng-container
            *ngIf="selectedRange.windSpeed100Agl"
            [ngTemplateOutlet]="baseConditionTemplate"
            [ngTemplateOutletContext]="{
                min: selectedRange.windSpeed100Agl.min,
                max: selectedRange.windSpeed100Agl.max,
                unit: selectedRange.windSpeed100Agl.unit,
            }"
        >
        </ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.windDirection100Agl?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.windDirectionLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.windDirection100Agl"
    >
        <ng-container
            *ngIf="selectedRange.windDirection100Agl"
            [ngTemplateOutlet]="extendedConditionTemplate"
            [ngTemplateOutletContext]="{
                min: selectedRange.windDirection100Agl.min | invoke : roundUp,
                max: selectedRange.windDirection100Agl.max | invoke : roundUp,
                unit: selectedRange.windDirection100Agl.unit,
                windDirection: +selectedRange.windDirection100Agl.avg | invoke : getCardinalDirection
            }"
        ></ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.kpi?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.kpiLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.kpi"
    >
        <ng-container
            *ngIf="selectedRange.kpi"
            [ngTemplateOutlet]="baseConditionTemplate"
            [ngTemplateOutletContext]="{
                avg: selectedRange.kpi.avg,
                unit: selectedRange.kpi.unit
            }"
        >
        </ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.temp?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.temperatureLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.temp"
    >
        <ng-container
            *ngIf="selectedRange.temp"
            [ngTemplateOutlet]="baseConditionTemplate"
            [ngTemplateOutletContext]="{
                min: selectedRange.temp.min,
                max: selectedRange.temp.max,
                unit: selectedRange.temp.unit
            }"
        >
        </ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.relHumidity?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.humidityLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.relHumidity"
    >
        <ng-container
            *ngIf="selectedRange.relHumidity"
            [ngTemplateOutlet]="baseConditionTemplate"
            [ngTemplateOutletContext]="{
                min: selectedRange.relHumidity.min,
                max: selectedRange.relHumidity.max,
                unit: selectedRange.relHumidity.unit
            }"
        >
        </ng-container>
    </dtm-map-weather-condition-box>
    <dtm-map-weather-condition-box
        [analysisResult]="selectedRange.groundPressure?.analysisResult"
        [title]="'dtmMapCesium.weatherConditionGrid.pressureLabel' | transloco"
        [shouldDisplayValue]="!!selectedRange.groundPressure"
    >
        <ng-container
            *ngIf="selectedRange.groundPressure"
            [ngTemplateOutlet]="baseConditionTemplate"
            [ngTemplateOutletContext]="{
                min: selectedRange.groundPressure.min,
                max: selectedRange.groundPressure.max,
                unit: selectedRange.groundPressure.unit
            }"
        ></ng-container>
    </dtm-map-weather-condition-box>
</ng-template>
<ng-template #cloudCoverTemplate let-avg="avg">
    <span class="condition-value">{{ "dtmMapCesium.weatherConditionGrid.cloudCoverValue" | transloco : { value: avg } }}</span>
</ng-template>
<ng-template #rainTemplate let-min="min" let-max="max" let-unit="unit">
    <div class="condition-value" *ngIf="min | invoke : isLackOfRain : max">
        {{ "dtmMapCesium.weatherConditionGrid.noRainfall" | transloco }}
    </div>
    <div class="condition-value" *ngIf="!(min | invoke : isLackOfRain : max)">
        <ng-container [ngTemplateOutlet]="isEqualTemplate" [ngTemplateOutletContext]="{ min, max, unit }"></ng-container>
    </div>
</ng-template>
<ng-template
    #extendedConditionTemplate
    let-min="min"
    let-max="max"
    let-minHigher="minHigher"
    let-maxHigher="maxHigher"
    let-unit="unit"
    let-windDirection="windDirection"
>
    <div class="condition-value range-details">
        <div *ngIf="minHigher && maxHigher">
            (<span *ngIf="windDirection">{{ windDirection }}, </span>
            <ng-container [ngTemplateOutlet]="isEqualTemplate" [ngTemplateOutletContext]="{ min: minHigher, max: maxHigher, unit }">
            </ng-container
            >)*
        </div>
        <div>
            <span *ngIf="windDirection">{{ windDirection }} </span>
            <ng-container [ngTemplateOutlet]="isEqualTemplate" [ngTemplateOutletContext]="{ min, max, unit }"></ng-container>
        </div>
    </div>
</ng-template>
<ng-template #baseConditionTemplate let-min="min" let-max="max" let-unit="unit" let-avg="avg">
    <div *ngIf="avg">
        <div class="condition-value">{{ avg }} {{ unit }}</div>
    </div>
    <div *ngIf="!avg" class="condition-value">
        <ng-container [ngTemplateOutlet]="isEqualTemplate" [ngTemplateOutletContext]="{ min, max, unit }"></ng-container>
    </div>
</ng-template>
<ng-template #isEqualTemplate let-min="min" let-max="max" let-unit="unit">
    <ng-container *ngIf="min === max">{{ min }} {{ unit }}</ng-container>
    <ng-container *ngIf="min !== max">{{ min }} - {{ max }} {{ unit }}</ng-container>
</ng-template>
