<ng-container *ngrxLet="error$; let error">
    <ng-container [ngSwitch]="error?.type">
        <div *ngSwitchCase="FlightZoneErrorType.CannotGetApplication" class="error">
            {{ "dssClientLibFlightZone.cannotGetDataErrorMessage" | transloco }}
        </div>
        <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="noErrorTemplate"></ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #noErrorTemplate>
    <div class="application-preview" *ngIf="flightZoneApplicationData$ | ngrxPush as flightZoneApplicationData">
        <div class="card-box" *ngIf="flightZoneApplicationData.analysisStatus as analysisStatus">
            <div *ngrxLet="{ applicationType: applicationType$ } as vm" class="overflow-container">
                <dss-shared-lib-application-preview-header
                    [previewTitle]="'dssClientLibFlightZone.applicationPreview.title' | transloco"
                    [isApplicationLocked]="analysisStatus.isLocked"
                    [applicationStatus]="flightZoneApplicationData.status"
                    [applicationType]="vm.applicationType"
                    (confirmationOfApplicationSubmissionPdfDownload)="
                        downloadConfirmationOfApplicationSubmissionPdf(flightZoneApplicationData)
                    "
                ></dss-shared-lib-application-preview-header>
                <dss-shared-lib-application-summary
                    [basicDataForm]="flightZoneApplicationData.basicDataForm"
                    [restrictionAreaGeometry]="flightZoneApplicationData.restrictionAreaGeometry"
                    [restrictionExclusions]="flightZoneApplicationData.restrictionExclusions"
                    [analysisStatus]="analysisStatus"
                    [restrictionModificationData]="flightZoneApplicationData.restrictionModification"
                    [shouldShowModificationEditButton]="
                        vm.applicationType === ApplicationType.RestrictionModificationApplication &&
                        !flightZoneApplicationData.analysisStatus.isLocked
                    "
                    [capabilities]="capabilities$ | ngrxPush"
                    (editRestrictionModification)="editModificationDates(flightZoneApplicationData)"
                >
                </dss-shared-lib-application-summary>
                <dss-shared-lib-application-note
                    *ngIf="
                        flightZoneApplicationData.status === FlightZoneApplicationStatus.Accepted ||
                        flightZoneApplicationData.status === FlightZoneApplicationStatus.Published ||
                        flightZoneApplicationData.status === FlightZoneApplicationStatus.Rejected
                    "
                    [note]="flightZoneApplicationData.note"
                    [isReadOnly]="true"
                ></dss-shared-lib-application-note>
            </div>
            <div class="actions">
                <button
                    type="button"
                    *ngIf="analysisStatus.notamNumber"
                    class="button-primary full-width-button"
                    (click)="openNotamPreview(flightZoneApplicationData.notamPreviewId!)"
                >
                    {{ "dssClientLibFlightZone.summaryStep.openNotamButtonLabel" | transloco }}
                </button>

                <button class="button-secondary" (click)="zoomToGeometry()" type="button">
                    <dtm-ui-icon name="focus-2"></dtm-ui-icon>
                    {{ "dssClientLibFlightZone.summaryStep.zoomToZoneButtonLabel" | transloco }}
                </button>
            </div>
        </div>

        <dss-shared-lib-map-container [shouldShowActiveRestrictionsLayer]="true"></dss-shared-lib-map-container>

        <ng-container *ngrxLet="isProcessing$; let isProcessing">
            <ng-container *ngrxLet="chatMessages$; let chatMessages">
                <dss-shared-lib-application-changes-chat
                    *ngIf="flightZoneApplicationData.flightZoneId && (isChatEnabled$ | ngrxPush)"
                    [messages]="chatMessages"
                    [userId]="userId$ | ngrxPush"
                    [isProcessing]="isProcessing"
                    #chatComponent
                    (messageSubmit)="submitMessage(flightZoneApplicationData.flightZoneId, $event, chatComponent)"
                ></dss-shared-lib-application-changes-chat>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
