<ng-container *ngIf="constraints$ | ngrxPush as constraints; else cannotGetDataErrorTemplate">
    <ng-container *ngrxLet="isProcessing$; let isProcessing">
        <form [formGroup]="prismEditorForm" *ngIf="prismEntity$ | ngrxPush">
            <ng-container *ngrxLet="verticalMeasureUnits$; let verticalMeasureUnits">
                <ng-container *ngrxLet="heightReferences$; let heightReferences">
                    <div class="multi-input-field">
                        <label for="upperHeightControl">{{
                            "dssSharedLibFlightZone.applicationSummary.upperHeightLabel" | transloco
                        }}</label>
                        <dtm-ui-input-field>
                            <input
                                *ngrxLet="minUpperHeight$ as minUpperHeight"
                                id="upperHeightControl"
                                matInput
                                [dtmUiDecimalDigitsLimiter]="0"
                                type="number"
                                inputmode="numeric"
                                autocomplete="off"
                                formControlName="upperHeight"
                                required
                                [min]="minUpperHeight"
                                [max]="
                                    constraints.max.upperHeight
                                        | metersToOtherUnitsOfMeasureConverter : upperHeightUnitFormControl.value : 0
                                "
                            />
                            <div class="field-error">
                                <ng-container
                                    [ngTemplateOutlet]="formErrorsTemplate"
                                    [ngTemplateOutletContext]="{
                                        formControl: upperHeightFormControl,
                                        unit: upperHeightUnitFormControl.value
                                    }"
                                ></ng-container>
                            </div>
                        </dtm-ui-input-field>
                        <dtm-ui-select-field
                            formControlName="upperHeightUnit"
                            (selectionChange)="updateHeightValue($event, upperHeightFormControl)"
                            [isClearable]="false"
                        >
                            <dtm-ui-select-option *ngFor="let unit of verticalMeasureUnits" [value]="unit">
                                {{ unit }}
                            </dtm-ui-select-option>
                        </dtm-ui-select-field>
                        <dtm-ui-select-field formControlName="upperHeightReference" [isClearable]="false">
                            <dtm-ui-select-option *ngFor="let reference of heightReferences" [value]="reference">
                                {{ reference }}
                            </dtm-ui-select-option>
                        </dtm-ui-select-field>
                    </div>

                    <div class="multi-input-field">
                        <label for="lowerHeightControl">
                            {{ "dssSharedLibFlightZone.applicationSummary.lowerHeightLabel" | transloco }}
                            <dtm-ui-popover (click)="$event.preventDefault()">
                                {{ "dssClientLibFlightZone.zoneGeometryStep.groundLevelInfoMessage" | transloco }}
                            </dtm-ui-popover>
                        </label>
                        <dtm-ui-input-field>
                            <input
                                *ngrxLet="maxLowerHeight$ as maxLowerHeight"
                                id="lowerHeightControl"
                                matInput
                                [dtmUiDecimalDigitsLimiter]="0"
                                type="number"
                                inputmode="numeric"
                                autocomplete="off"
                                formControlName="lowerHeight"
                                required
                                [min]="
                                    constraints.min.lowerHeight
                                        | metersToOtherUnitsOfMeasureConverter : lowerHeightUnitFormControl.value : 0
                                "
                                [max]="maxLowerHeight"
                            />
                            <div class="field-error">
                                <ng-container
                                    [ngTemplateOutlet]="formErrorsTemplate"
                                    [ngTemplateOutletContext]="{
                                        formControl: lowerHeightFormControl,
                                        unit: lowerHeightUnitFormControl.value
                                    }"
                                ></ng-container>
                            </div>
                        </dtm-ui-input-field>
                        <dtm-ui-select-field
                            formControlName="lowerHeightUnit"
                            (selectionChange)="updateHeightValue($event, lowerHeightFormControl)"
                            [isClearable]="false"
                        >
                            <dtm-ui-select-option *ngFor="let unit of verticalMeasureUnits" [value]="unit">
                                {{ unit }}
                            </dtm-ui-select-option>
                        </dtm-ui-select-field>
                        <dtm-ui-select-field formControlName="lowerHeightReference" [isClearable]="false">
                            <dtm-ui-select-option *ngFor="let reference of heightReferences" [value]="reference">
                                {{ reference }}
                            </dtm-ui-select-option>
                        </dtm-ui-select-field>
                    </div>

                    <ul formArrayName="positions" *ngIf="positionsArray.controls.length > 0">
                        <li
                            *ngFor="let prismPoint of positionsArray.controls; index as prismPointIndex"
                            [formGroupName]="prismPointIndex"
                            [class.error]="positionsArray.errors?.intersections"
                        >
                            <div class="point-label">
                                {{ "dssClientLibFlightZone.zoneGeometryStep.prismPointLabel" | transloco : { value: prismPointIndex + 1 } }}
                                <button
                                    type="button"
                                    [matMenuTriggerFor]="pointActionsMenu"
                                    [matMenuTriggerData]="{ $implicit: prismPointIndex, isProcessing: isProcessing }"
                                >
                                    <dtm-ui-icon name="more"></dtm-ui-icon>
                                </button>
                            </div>

                            <dtm-ui-dms-coordinates-input
                                [inputLabel]="'dssClientLibFlightZone.zoneGeometryStep.prismPointLatitude' | transloco"
                                formControlName="latitude"
                                [geographicCoordinatesType]="GeographicCoordinatesType.Latitude"
                                [isClearable]="true"
                            ></dtm-ui-dms-coordinates-input>

                            <dtm-ui-dms-coordinates-input
                                [inputLabel]="'dssClientLibFlightZone.zoneGeometryStep.prismPointLongitude' | transloco"
                                formControlName="longitude"
                                [geographicCoordinatesType]="GeographicCoordinatesType.Longitude"
                                [isClearable]="true"
                            ></dtm-ui-dms-coordinates-input>
                            <dtm-ui-form-errors>
                                <ng-container *ngIf="positionsArray.errors?.intersections">
                                    {{ "dssClientLibFlightZone.zoneGeometryStep.prismPointSelfIntersectionErrorHint" | transloco }}
                                </ng-container>
                            </dtm-ui-form-errors>
                        </li>
                    </ul>
                </ng-container>
            </ng-container>
        </form>
    </ng-container>
</ng-container>

<ng-template #formErrorsTemplate let-formControl="formControl" let-unit="unit">
    <ng-container *dtmUiFieldHasError="formControl; name: 'required'">
        {{ "dssClientLibFlightZone.requiredFieldErrorHint" | transloco }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="formControl; name: 'min'; error as error">
        {{
            "dssClientLibFlightZone.minValueErrorHint"
                | transloco
                    : {
                          min: error.min | localizeNumber,
                          unit: unit
                      }
        }}
    </ng-container>
    <ng-container *dtmUiFieldHasError="formControl; name: 'max'; error as error">
        {{
            "dssClientLibFlightZone.maxValueErrorHint"
                | transloco
                    : {
                          max: error.max | localizeNumber,
                          unit: unit
                      }
        }}
    </ng-container>
</ng-template>

<ng-template #cannotGetDataErrorTemplate>
    <div>
        {{ "dssClientLibFlightZone.cannotGetDataErrorMessage" | transloco }}
    </div>
</ng-template>

<mat-menu #pointActionsMenu="matMenu">
    <ng-template matMenuContent let-prismPointIndex let-isProcessing="isProcessing">
        <button
            mat-menu-item
            type="button"
            *ngrxLet="prismPointLimit$; let prismPointLimit"
            [disabled]="isProcessing || positionsArray.length >= prismPointLimit"
            (click)="insertNewPrismPoint(prismPointIndex + 1)"
        >
            <dtm-ui-icon name="add-circle"></dtm-ui-icon>
            <span>{{ "dssClientLibFlightZone.zoneGeometryStep.addPrismPointButtonLabel" | transloco }}</span>
        </button>
        <button
            mat-menu-item
            type="button"
            [disabled]="isProcessing || positionsArray.length <= MINIMUM_VERTEX_NUMBER"
            (click)="removePrismPoint(prismPointIndex)"
        >
            <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
            <span>{{ "dssClientLibFlightZone.zoneGeometryStep.removePrismPointButtonLabel" | transloco }}</span>
        </button>
    </ng-template>
</mat-menu>
