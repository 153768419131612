<span class="ql-formats">
    <button type="button" class="ql-bold"></button>
    <button type="button" class="ql-italic"></button>
    <button type="button" class="ql-underline"></button>
    <button type="button" class="ql-strike"></button>

    <select class="ql-align" [title]="'dtmUi.quillEditorToolbar.alignmentTitle' | transloco">
        <option selected></option>
        <option value="center"></option>
        <option value="right"></option>
        <option value="justify"></option>
    </select>

    <select class="ql-size">
        <option value="small">12px</option>
        <option selected>14px</option>
        <option value="large">20px</option>
        <option value="huge">36px</option>
    </select>

    <button type="button" class="ql-list" value="ordered"></button>
    <button type="button" class="ql-list" value="bullet"></button>
    <button type="button" class="ql-indent" value="-1"></button>
    <button type="button" class="ql-indent" value="+1"></button>
    <button type="button" class="ql-image"></button>

    <ng-container *ngIf="isExtendedToolbar$ | ngrxPush">
        <select class="ql-color"></select>
        <button type="button" class="ql-link"></button>
        <button type="button" class="box-button" (click)="toggleDropdown()">Box</button>
        <div class="ql-custom-dropdown" [class.flex]="isDropdownVisible$ | ngrxPush">
            <button class="ql-infobox-red" type="button">{{ "dtmUi.quillEditorToolbar.warningButtonLabel" | transloco }}</button>
            <button class="ql-infobox-blue" type="button">{{ "dtmUi.quillEditorToolbar.triviaButtonLabel" | transloco }}</button>
            <button class="ql-infobox-yellow" type="button">{{ "dtmUi.quillEditorToolbar.rememberButtonLabel" | transloco }}</button>
        </div>
    </ng-container>
</span>
