import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, OnInit, forwardRef } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import {
    FunctionUtils,
    LocalComponentStore,
    ONLY_WHITE_SPACES_VALIDATION_PATTERN,
    POST_CODE_MASK,
    systemDefaultCountryPostcodeValidator,
} from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { distinctUntilChanged } from "rxjs/operators";
import { BasicAddress } from "../../models/address.models";

const STREET_MAX_LENGTH = 100;
const HOUSE_APARTMENT_NUMBER_MAX_LENGTH = 20;
const CITY_MAX_LENGTH = 100;
const DEFAULT_POST_CODE_LENGTH = 5;
const FOREIGN_POST_CODE_MAX_LENGTH = 15;

export function defaultCountryAddressFormValidator(control: AbstractControl): ValidationErrors | null {
    if (
        control.value &&
        control.value.streetName &&
        control.value.houseNumber &&
        control.value.city &&
        control.value.postCode &&
        control.value.postCode.length === DEFAULT_POST_CODE_LENGTH
    ) {
        return null;
    }

    return { invalidAddress: true };
}

export function foreignAddressFormValidator(control: AbstractControl): ValidationErrors | null {
    if (
        control.value &&
        control.value.streetName &&
        control.value.houseNumber &&
        control.value.city &&
        control.value.postCode &&
        control.value.postCode.length <= FOREIGN_POST_CODE_MAX_LENGTH
    ) {
        return null;
    }

    return { invalidAddress: true };
}

interface PilotOperatorRegistrationAddressFormComponentState {
    isSystemDefaultCountrySelected: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-address-form",
    templateUrl: "./address-form.component.html",
    styleUrls: ["./address-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AddressFormComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AddressFormComponent),
            multi: true,
        },
    ],
})
export class AddressFormComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() public set isSystemDefaultCountrySelected(value: BooleanInput) {
        if (value === this.localStore.selectSnapshotByKey("isSystemDefaultCountrySelected")) {
            return;
        }

        this.localStore.patchState({ isSystemDefaultCountrySelected: coerceBooleanProperty(value) });

        if (value) {
            this.postCodeControl.removeValidators(this.maxForeignPostcodeValidator);
            this.postCodeControl.addValidators(systemDefaultCountryPostcodeValidator);
        } else {
            this.postCodeControl.removeValidators(systemDefaultCountryPostcodeValidator);
            this.postCodeControl.addValidators(this.maxForeignPostcodeValidator);
        }

        this.postCodeControl.updateValueAndValidity();
        if (this.postCodeControl.invalid) {
            this.postCodeControl.reset("");
        }
        this.onValidationChange();
        this.propagateChange(this.addressFormGroup.getRawValue());
    }

    private readonly maxForeignPostcodeValidator = Validators.maxLength(FOREIGN_POST_CODE_MAX_LENGTH);

    protected readonly streetControl = new FormControl<string>("", {
        validators: [
            Validators.required,
            Validators.maxLength(STREET_MAX_LENGTH),
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
        ],
        nonNullable: true,
    });
    protected readonly houseNumberControl = new FormControl<string>("", {
        validators: [
            Validators.required,
            Validators.maxLength(HOUSE_APARTMENT_NUMBER_MAX_LENGTH),
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
        ],
        nonNullable: true,
    });

    protected readonly apartmentNumberControl = new FormControl<string>("", {
        validators: [Validators.maxLength(HOUSE_APARTMENT_NUMBER_MAX_LENGTH), Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)],
        nonNullable: true,
    });
    protected readonly cityControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.maxLength(CITY_MAX_LENGTH), Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)],
        nonNullable: true,
    });
    protected readonly postCodeControl = new FormControl<string>("", {
        validators: [Validators.required, this.maxForeignPostcodeValidator],
        nonNullable: true,
    });
    protected readonly POLISH_POST_CODE_MASK = POST_CODE_MASK;
    protected readonly isSystemDefaultCountrySelected$ = this.localStore.selectByKey("isSystemDefaultCountrySelected");

    protected readonly addressFormGroup = new FormGroup({
        postCode: this.postCodeControl,
        houseNumber: this.houseNumberControl,
        apartmentNumber: this.apartmentNumberControl,
        streetName: this.streetControl,
        city: this.cityControl,
    });

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: BasicAddress) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<PilotOperatorRegistrationAddressFormComponentState>) {
        localStore.setState({ isSystemDefaultCountrySelected: false });
    }

    public ngOnInit() {
        this.addressFormGroup.valueChanges.pipe(distinctUntilChanged(equal), untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.addressFormGroup.getRawValue());
        });
    }

    public registerOnChange(fn: (value: BasicAddress) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        if (this.addressFormGroup.valid) {
            return null;
        }

        return this.addressFormGroup.invalid ? { invalidAddress: true } : null;
    }

    public writeValue(value: BasicAddress | null): void {
        if (value) {
            this.addressFormGroup.reset({
                streetName: value.streetName,
                houseNumber: value.houseNumber,
                apartmentNumber: value.apartmentNumber ?? "",
                city: value.city,
                postCode: value.postCode,
            });
        } else {
            this.addressFormGroup.reset();
        }
    }
}
