/* eslint-disable @typescript-eslint/no-explicit-any */

export function removeNullishProperties<T extends Record<string, any>>(object: T): Record<string, any> {
    const resultObject: Record<string, any> = {};

    Object.entries(object).reduce((result, [key, value]) => {
        if (value !== undefined && value !== null) {
            result[key] = value;
        }

        return result;
    }, resultObject);

    return resultObject;
}
