import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { MissionType } from "../../models/tactical.models";

interface MissionTypeFormControlComponentState {
    missionType: MissionType | undefined;
    isDisabled: boolean;
    isTooltipVisible: boolean;
    disableMissionType: MissionType | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-mission-type-form-control",
    templateUrl: "./mission-type-form-control.component.html",
    styleUrls: ["./mission-type-form-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => MissionTypeFormControlComponent),
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MissionTypeFormControlComponent),
            multi: true,
        },
    ],
})
export class MissionTypeFormControlComponent implements ControlValueAccessor, Validator {
    @Input() public set isTooltipVisible(value: boolean | undefined) {
        this.localStore.patchState({ isTooltipVisible: !!value });
    }
    @Input() public set disableMissionType(value: MissionType | undefined) {
        this.localStore.patchState({ disableMissionType: value });
    }

    protected readonly selectedMissionType$ = this.localStore.selectByKey("missionType");
    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");
    protected readonly isTooltipVisible$ = this.localStore.selectByKey("isTooltipVisible");
    protected readonly disableMissionType$ = this.localStore.selectByKey("disableMissionType");

    protected MissionType = MissionType;

    constructor(private readonly localStore: LocalComponentStore<MissionTypeFormControlComponentState>) {
        this.localStore.setState({ missionType: undefined, isDisabled: false, isTooltipVisible: true, disableMissionType: undefined });
    }

    protected onChange: (missionType: MissionType) => void = FunctionUtils.noop;
    protected onTouched: () => void = FunctionUtils.noop;

    protected setMissionType(type: MissionType) {
        if (this.localStore.selectSnapshotByKey("missionType") === type) {
            return;
        }

        this.localStore.patchState({ missionType: type });
        this.onChange(type);
    }

    public registerOnChange(onChange: (value: MissionType) => void) {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void) {
        this.onTouched = onTouched;
    }

    public writeValue(missionType: MissionType) {
        this.localStore.patchState({ missionType });
    }

    public validate(control: AbstractControl) {
        if (!control.value) {
            return {
                required: true,
            };
        }

        return null;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.localStore.patchState({ isDisabled });
    }
}
