import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime } from "rxjs";
import { MAX_THREAD_NAME_LENGTH, MIN_THREAD_NAME_LENGTH } from "../../../models/conversations.models";

interface SearchThreadComponentState {
    isLabelVisible: boolean;
}

const SEARCH_DEBOUNCE_TIME = 300;

@UntilDestroy()
@Component({
    selector: "dtm-ui-search-thread",
    templateUrl: "./search-thread.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SearchThreadComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SearchThreadComponent),
            multi: true,
        },
    ],
})
export class SearchThreadComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() public set isLabelVisible(value: boolean | undefined) {
        this.localStore.patchState({ isLabelVisible: !!value });
    }

    @Input() public set searchByText(value: string) {
        this.textSearchControl.setValue(value);

        if (!this.validate()) {
            this.propagateChange(value);
        }
        this.onValidationChange();
    }

    protected readonly textSearchControl = new FormControl<string>("", {
        validators: [Validators.maxLength(MAX_THREAD_NAME_LENGTH), Validators.minLength(MIN_THREAD_NAME_LENGTH)],
        nonNullable: true,
    });
    protected readonly isLabelVisible$ = this.localStore.selectByKey("isLabelVisible");

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: string) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<SearchThreadComponentState>) {
        localStore.setState({
            isLabelVisible: true,
        });
    }

    public ngOnInit() {
        this.textSearchControl.valueChanges
            .pipe(debounceTime(SEARCH_DEBOUNCE_TIME), untilDestroyed(this))
            .subscribe((value) => this.propagateChange(value));
    }

    public writeValue(value: string | null): void {
        this.searchByText = value ?? "";
    }

    public validate(): ValidationErrors | null {
        if (this.textSearchControl.invalid) {
            return { invalidSearchPhrase: true };
        }

        return null;
    }

    public registerOnChange(fn: (value: string) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }
}
