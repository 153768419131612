import { Injectable } from "@angular/core";
import { BaseNotificationEventsService, DssNotification } from "@dtm-frontend/dss-shared-lib";
import { NotificationDisplay } from "@dtm-frontend/shared/notifications";
import { InstitutionApplicationNotificationEventsService } from "./institution-application-notification-events.service";
import { InstitutionCommentNotificationEventsService } from "./institution-comment-notification-events.service";
import { InstitutionModificationNotificationEventsService } from "./institution-modification-notification-events.service";
import { InstitutionNotamNotificationEventsService } from "./institution-notam-notification-events.service";

@Injectable({
    providedIn: "root",
})
export class InstitutionNotificationEventsService implements BaseNotificationEventsService {
    constructor(
        private readonly institutionApplicationNotificationEventsService: InstitutionApplicationNotificationEventsService,
        private readonly institutionCommentNotificationEventsService: InstitutionCommentNotificationEventsService,
        private readonly institutionNotamNotificationEventsService: InstitutionNotamNotificationEventsService,
        private readonly institutionModificationNotificationEventsService: InstitutionModificationNotificationEventsService
    ) {}

    public getEventsList(notificationsToFormat: DssNotification[]): NotificationDisplay[] {
        return [
            ...this.institutionApplicationNotificationEventsService.getEventsList(notificationsToFormat),
            ...this.institutionCommentNotificationEventsService.getEventsList(notificationsToFormat),
            ...this.institutionNotamNotificationEventsService.getEventsList(notificationsToFormat),
            ...this.institutionModificationNotificationEventsService.getEventsList(notificationsToFormat),
        ];
    }
}
