import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { NgxsModule } from "@ngxs/store";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "../i18n";
import { DisplayVersionDirective } from "./directive/display-version.directive";
import { DisplayVersionApiService } from "./display-version-api.service";
import { VersionState } from "./state/version.state";
import { VersionDataDialogComponent } from "./version-data-modal/version-data-dialog.component";

@NgModule({
    declarations: [DisplayVersionDirective, VersionDataDialogComponent],
    imports: [CommonModule, NgxsModule.forFeature([VersionState]), ClipboardModule, MatButtonModule, MatDialogModule, SharedI18nModule],
    providers: [
        {
            provide: DisplayVersionApiService,
            useValue: undefined,
        },
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmVersion",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [DisplayVersionDirective, VersionDataDialogComponent],
})
export class VersionModule {
    public static forRoot(): ModuleWithProviders<VersionModule> {
        return {
            ngModule: VersionModule,
            providers: [
                {
                    provide: DisplayVersionApiService,
                    useClass: DisplayVersionApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<VersionModule> {
        return {
            ngModule: VersionModule,
            providers: [
                {
                    provide: DisplayVersionApiService,
                    useValue: null,
                },
            ],
        };
    }
}
