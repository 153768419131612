<ng-container *ngrxLet="isShown$ as isShown">
    <ac-layer #boundaryLayer acFor="let entity of boundaryEntities$" [store]="true" [context]="this" [show]="isShown" [zIndex]="0">
        <ac-circle-desc
            props="{
                position: entity.getCenterCallbackProperty(),
                radius: entity.getRadiusCallbackProperty(),
                granularity: 0.01,
                material: entity.fillMaterial
            }"
        >
        </ac-circle-desc>
        <ac-polyline-desc
            props="{
                positions: entity.getOutlineCallbackProperty(),
                material: entity.outlineMaterial,
                width: entity.outlineWidth
            }"
        ></ac-polyline-desc>
    </ac-layer>
</ng-container>
