export enum GeographicCoordinatesType {
    Latitude = "Latitude",
    Longitude = "Longitude",
}

export enum GeographicCoordinatesDirection {
    North = "N",
    South = "S",
    East = "E",
    West = "W",
}

export interface DmsCoordinates {
    degrees: number;
    minutes: number;
    seconds: number;
}

export interface DmsDirectionCoordinates extends DmsCoordinates {
    direction: GeographicCoordinatesDirection;
}

export enum HemisphereSign {
    Negative = -1,
    Positive = 1,
}
