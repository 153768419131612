import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function multipleEmailsValidator(emailSplitSeparator: string | RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const separatedEmails: string[] = control.value.split(emailSplitSeparator) ?? [];
        const validEmailPattern = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$");

        if (!separatedEmails.length) {
            return null;
        }

        const isEveryEmailValid = separatedEmails.every((email) => !email || validEmailPattern.test(email));

        return isEveryEmailValid ? null : { email: true };
    };
}
