import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ConnectedOverlayPositionChange } from "@angular/cdk/overlay";
import { ChangeDetectionStrategy, Component, Input, Renderer2 } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface ContentPopoverComponentState {
    popoverText: string;
    isOpen: boolean;
    shouldOpenOnHover: boolean;
}

@Component({
    selector: "dtm-ui-content-popover",
    templateUrl: "./content-popover.component.html",
    styleUrls: ["./content-popover.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ContentPopoverComponent {
    public readonly popoverText$ = this.localStore.selectByKey("popoverText");
    public readonly isOpen$ = this.localStore.selectByKey("isOpen");
    public readonly shouldOpenOnHover$ = this.localStore.selectByKey("shouldOpenOnHover");

    @Input() public set popoverText(value: string) {
        this.localStore.patchState({ popoverText: value });
    }
    @Input() public set shouldOpenOnHover(value: BooleanInput) {
        this.localStore.patchState({ shouldOpenOnHover: coerceBooleanProperty(value) });
    }

    constructor(private readonly localStore: LocalComponentStore<ContentPopoverComponentState>, private renderer: Renderer2) {
        this.localStore.setState({
            popoverText: "",
            isOpen: false,
            shouldOpenOnHover: false,
        });
    }

    public toggleOverlay(): void {
        this.localStore.patchState((state: ContentPopoverComponentState) => ({ isOpen: !state.isOpen }));
    }

    public positionChange(event: ConnectedOverlayPositionChange, host: HTMLElement): void {
        this.removePointerClasses(host);

        this.renderer.addClass(host, event.connectionPair.originY);
    }

    public removePointerClasses(host: HTMLElement): void {
        this.renderer.removeClass(host, "top");
        this.renderer.removeClass(host, "bottom");
    }

    public hidePopover(): void {
        this.localStore.patchState({ isOpen: false });
    }

    public showPopover(): void {
        this.localStore.patchState({ isOpen: true });
    }
}
