import { AuthorityAcceptationItem, MissionPlanRemarks } from "../models";

export function isAuthorityAcceptationConfirmed(remarks?: MissionPlanRemarks): boolean | undefined {
    const authorityAcceptation = remarks?.authorityAcceptation;
    const partialAcceptations = remarks?.partialAcceptations;

    if (!authorityAcceptation && !partialAcceptations?.length) {
        return;
    }

    const acceptationWithComments = authorityAcceptation
        ? Object.values(authorityAcceptation).find((acceptation: AuthorityAcceptationItem | undefined) => acceptation?.comment)
        : undefined;

    const isAnyPartialAcceptationWithComments = partialAcceptations?.some((acceptation) => acceptation.comment);
    const isAnyPartialAcceptationConfirmedByUser = partialAcceptations?.some((acceptation) => acceptation.acknowledgedByUserAt);

    if (!acceptationWithComments && !isAnyPartialAcceptationWithComments) {
        return;
    }

    return acceptationWithComments?.isConfirmedByUser || isAnyPartialAcceptationConfirmedByUser;
}
