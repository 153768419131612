import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { APPROX_DAYS_IN_YEAR, DateUtils, FakeUTCDate, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { merge } from "rxjs";
import { map } from "rxjs/operators";
import { ZoneDurationChangeDialogData } from "../../models/flight-zone.models";

interface ZoneDurationChangeComponentState {
    minStartDate: FakeUTCDate;
    maxStartDate: FakeUTCDate;
    minEndDate: FakeUTCDate;
    maxEndDate: FakeUTCDate;
}

const MIN_ZONE_DURATION_MINUTES = 5;
const MAX_ZONE_DURATION_DAYS = 90;

@UntilDestroy()
@Component({
    selector: "dss-client-lib-zone-duration-change",
    templateUrl: "./zone-duration-change.component.html",
    styleUrls: ["./zone-duration-change.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ZoneDurationChangeComponent {
    protected readonly cancelRestrictionControl = new FormControl<boolean>(false);
    protected readonly zoneDurationForm = new FormGroup({
        startDate: new FormControl<FakeUTCDate | undefined>(undefined, { validators: [Validators.required], nonNullable: true }),
        startTime: new FormControl<FakeUTCDate | undefined>(undefined, { validators: [Validators.required], nonNullable: true }),
        endDate: new FormControl<FakeUTCDate | undefined>(undefined, { validators: [Validators.required], nonNullable: true }),
        endTime: new FormControl<FakeUTCDate | undefined>(undefined, { validators: [Validators.required], nonNullable: true }),
    });

    protected readonly minStartDate$ = this.localStore.selectByKey("minStartDate");
    protected readonly maxStartDate$ = this.localStore.selectByKey("maxStartDate");
    protected readonly minEndDate$ = this.localStore.selectByKey("minEndDate");
    protected readonly maxEndDate$ = this.localStore.selectByKey("maxEndDate");
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: ZoneDurationChangeDialogData,
        private readonly dialogRef: MatDialogRef<ZoneDurationChangeComponent>,
        private readonly localStore: LocalComponentStore<ZoneDurationChangeComponentState>,
        private readonly translocoHelper: TranslationHelperService
    ) {
        const dateNow = DateUtils.convertDateToFakeUTC(new Date());
        const startAt = DateUtils.convertDateToFakeUTC(data.startAt);
        const endAt = DateUtils.convertDateToFakeUTC(data.endAt);
        const isStartTimeBackdated = startAt < dateNow;

        this.localStore.setState({
            minStartDate: dateNow,
            maxStartDate: DateUtils.addDays(dateNow, APPROX_DAYS_IN_YEAR),
            minEndDate: new Date(Math.max(startAt.getTime(), dateNow.getTime())),
            maxEndDate: DateUtils.addDays(startAt, MAX_ZONE_DURATION_DAYS),
        });

        this.initFormWithData({ dateNow, startAt, endAt });
        this.toggleZoneDurationFormStateByCancelRestriction(isStartTimeBackdated);
    }

    protected confirm(): void {
        this.zoneDurationForm.markAllAsTouched();

        if (this.zoneDurationForm.invalid) {
            return;
        }

        const formValue = this.zoneDurationForm.getRawValue() as Required<typeof this.zoneDurationForm.value>;

        this.dialogRef.close({
            id: this.data.id,
            startAt: DateUtils.convertFakeUTCToDate(formValue.startDate, formValue.startTime),
            endAt: DateUtils.convertFakeUTCToDate(formValue.endDate, formValue.endTime),
            shouldCancelRestriction: this.cancelRestrictionControl.value,
        });
    }

    private initFormWithData({ dateNow, startAt, endAt }: { dateNow: FakeUTCDate; startAt: FakeUTCDate; endAt: FakeUTCDate }): void {
        const isStartTimeBackdated = startAt < dateNow;

        this.zoneDurationForm.patchValue({
            startDate: startAt,
            startTime: startAt,
            endTime: endAt,
            endDate: endAt,
        });

        if (isStartTimeBackdated) {
            this.zoneDurationForm.controls.startDate.disable();
            this.zoneDurationForm.controls.startTime.disable();

            return;
        }

        this.initDateTimeControlsValidation();
    }

    private initDateTimeControlsValidation(): void {
        const startDateWithTimeValue$ = this.zoneDurationForm.controls.startDate.valueChanges.pipe(
            RxjsUtils.filterFalsy(),
            map((startDateValue) => {
                const startTime = this.zoneDurationForm.controls.startTime.value;

                if (!startTime) {
                    return startDateValue;
                }

                const startHours = startTime.getHours();
                const startMinutes = startTime.getMinutes();

                startDateValue.setHours(startHours, startMinutes);

                return startDateValue;
            })
        );

        merge(startDateWithTimeValue$, this.zoneDurationForm.controls.startTime.valueChanges)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((value) => {
                this.localStore.patchState({ minEndDate: DateUtils.addMinutes(value, MIN_ZONE_DURATION_MINUTES) });
                this.localStore.patchState({ maxEndDate: DateUtils.addDays(value, MAX_ZONE_DURATION_DAYS) });

                this.zoneDurationForm.controls.endDate.updateValueAndValidity();
            });
    }

    private toggleZoneDurationFormStateByCancelRestriction(isStartTimeBackdated: boolean): void {
        this.cancelRestrictionControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            if (value) {
                this.zoneDurationForm.disable();
            } else {
                this.zoneDurationForm.enable();

                if (isStartTimeBackdated) {
                    this.zoneDurationForm.controls.startDate.disable();
                    this.zoneDurationForm.controls.startTime.disable();
                }
            }
        });
    }
}
