import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { DEFAULT_DEBOUNCE_TIME, DateUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime } from "rxjs/operators";
import { MissionFilters } from "../../models/planned-missions.models";

interface MissionFilterComponentState {
    calendarRange: number | undefined;
}

enum CalendarDateRange {
    Today = 0,
    OneDayAhead = 1,
}

const SORT_OPTIONS_MAP = {
    "missionPlan.mission.startAtMin,asc": "flightStartAtAsc",
    "missionPlan.submittedAt,asc": "submittedAtAsc",
    "missionPlan.submittedAt,desc": "submittedAtDesc",
};

const DEFAULT_MISSION_SORT = "missionPlan.mission.startAtMin,asc";

interface MissionDatesFormGroup {
    flightDateFrom: FormControl<Date | null>;
    flightDateTo: FormControl<Date | null>;
}

interface MissionFilterForm {
    designator: FormControl<string>;
    sort: FormControl<string | null>;
    missionDates: FormGroup<MissionDatesFormGroup>;
}

@UntilDestroy()
@Component({
    selector: "dss-client-lib-mission-filters",
    templateUrl: "./mission-filters.component.html",
    styleUrls: ["./mission-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionFiltersComponent {
    @Output() public readonly filtersChange = new EventEmitter<MissionFilters>();

    @ViewChild("datepickerFooter", { static: false }) private readonly datepickerFooter: ElementRef | undefined;
    @ViewChild("rangeDatePicker", { static: false }) private readonly datepicker: MatDatepicker<unknown> | undefined;

    protected readonly calendarRange$ = this.localStore.selectByKey("calendarRange");
    protected readonly SORT_OPTIONS_MAP = SORT_OPTIONS_MAP;
    protected readonly CalendarDateRange = CalendarDateRange;
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly filtersForm = new FormGroup<MissionFilterForm>({
        designator: new FormControl("", { nonNullable: true }),
        sort: new FormControl(DEFAULT_MISSION_SORT),
        missionDates: new FormGroup<MissionDatesFormGroup>({
            flightDateFrom: new FormControl(null),
            flightDateTo: new FormControl(null),
        }),
    });

    constructor(
        private readonly translocoHelper: TranslationHelperService,
        private readonly localStore: LocalComponentStore<MissionFilterComponentState>,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        this.localStore.setState({
            calendarRange: undefined,
        });

        this.watchFormValueChanges();
    }

    protected appendFooter(): void {
        /* NOTE: We cannot use MatDatepickerActions here because it changes the datepicker behavior and forces the use of
           action buttons to select dates. This is a hack that appends HTML content with action buttons to the range datepicker
           without change datepicker behaviour. */

        const matCalendar = this.document.getElementsByClassName("mat-datepicker-content")[0] as HTMLElement;

        if (this.datepickerFooter) {
            matCalendar.appendChild(this.datepickerFooter.nativeElement);
        }
    }

    protected selectPredefinedDate(calendarDateRange: CalendarDateRange): void {
        const startDate = new Date(new Date().setHours(0, 0, 0, 0));
        // eslint-disable-next-line no-magic-numbers
        const lastMillisecondOfTodayDate = new Date(new Date().setHours(23, 59, 59, 999));
        const endDate = DateUtils.addDays(lastMillisecondOfTodayDate, calendarDateRange);

        this.filtersForm.controls.missionDates.controls.flightDateFrom.patchValue(startDate);
        this.filtersForm.controls.missionDates.controls.flightDateTo.patchValue(endDate);
        this.datepicker?.close();
    }

    private watchFormValueChanges(): void {
        this.filtersForm.valueChanges.pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), untilDestroyed(this)).subscribe(() => {
            const formValue = this.filtersForm.getRawValue();
            const missionFlightFrom = formValue.missionDates.flightDateFrom;
            const missionFlightTo = formValue.missionDates.flightDateTo;

            this.filtersChange.emit({
                designator: formValue.designator,
                sort: formValue.sort,
                flightDateFrom: missionFlightFrom,
                flightDateTo: missionFlightTo,
            });

            this.updateCalendarRange(missionFlightFrom, missionFlightTo);
        });
    }

    private updateCalendarRange(missionFlightFrom: Date | null, missionFlightTo: Date | null): void {
        const updatedCalendarDateRange = this.getCalendarDateRange(missionFlightFrom, missionFlightTo);

        this.localStore.patchState({ calendarRange: updatedCalendarDateRange });
    }

    private getCalendarDateRange(firstDate: Date | null, secondDate: Date | null): CalendarDateRange | undefined {
        if (!firstDate || !secondDate || !this.isToday(firstDate)) {
            return undefined;
        }

        switch (DateUtils.calculateDiffDate(firstDate, secondDate).days) {
            case CalendarDateRange.Today:
                return CalendarDateRange.Today;

            case CalendarDateRange.OneDayAhead:
                return CalendarDateRange.OneDayAhead;

            default:
                return undefined;
        }
    }

    private isToday(date: Date): boolean {
        const today = new Date();

        return today.toDateString() === date.toDateString();
    }
}
