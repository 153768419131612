import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, NgZone, Output, TemplateRef } from "@angular/core";
import { Area, MissionSegmentStatus, PinData, RouteAreaTypeId, RouteData, mapSegmentStatusToClassConfig } from "@dtm-frontend/shared/ui";
import { ArrayUtils, DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore, RxjsUtils, SpatialUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AcNotification, CesiumService } from "@pansa/ngx-cesium";
import { AllGeoJSON, Polygon, feature as turfFeature } from "@turf/helpers";
import {
    Observable,
    combineLatestWith,
    distinctUntilChanged,
    filter,
    from,
    map,
    mergeMap,
    of,
    shareReplay,
    switchMap,
    tap,
    throttleTime,
} from "rxjs";
import { MapLabelsUtils, MapUtils } from "../../index";
import { RouteAcEntity } from "../../models/route.models";
import { CameraHelperService } from "../../services/camera-helper.service";
import { RouteViewerService } from "../../services/route-viewer/route-viewer.service";
import { CesiumImageObjectSettings, ROUTES_VISUAL_DATA, VisualDataOverride, VisualSettings } from "../route-viewer/route-viewer.data";

/* eslint-disable @typescript-eslint/no-explicit-any*/
declare const Cesium: any; // TODO: DTM-966

interface RouteViewLayerComponentState<T> {
    routeData: RouteData<T> | undefined;
    drawableFeatures: RouteAreaTypeId[];
    nearbyMissionsDrawableFeatures: RouteAreaTypeId[];
    activeEntitiesId: Set<string>;
    pinTemplate: TemplateRef<any> | null;
    isShown: boolean;
    shouldDisplayTimeRange: boolean;
}

const LABELS_SHOWING_DISTANCE_IN_METERS = 20000;

const FILL_IMAGE_SCALE = 15;

@UntilDestroy()
@Component({
    selector: "dtm-map-route-viewer-layer[routeData]",
    templateUrl: "./route-view-layer.component.html",
    styleUrls: ["./route-view-layer.component.scss", "../../../shared/styles/map-segment-pin.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class RouteViewLayerComponent<T> {
    @Input()
    public set routeData(value: RouteData<T> | undefined) {
        this.localStore.patchState({ routeData: value });
    }

    @Input()
    public set drawableFeatures(value: RouteAreaTypeId[] | undefined) {
        this.localStore.patchState({ drawableFeatures: [...new Set(value)] });
    }

    @Input()
    public set nearbyMissionsDrawableFeatures(value: RouteAreaTypeId[] | undefined) {
        this.localStore.patchState({ nearbyMissionsDrawableFeatures: value ? ArrayUtils.unique(value) : undefined });
    }

    @Input()
    public set pinTemplate(value: TemplateRef<any> | null | undefined) {
        this.localStore.patchState({ pinTemplate: value });
    }

    @Input() public set isShown(value: BooleanInput) {
        this.localStore.patchState({ isShown: coerceBooleanProperty(value) });
    }

    @Input() public set shouldDisplayTimeRange(value: BooleanInput) {
        this.localStore.patchState({ shouldDisplayTimeRange: coerceBooleanProperty(value) });
    }

    @Output()
    public readonly entityZoom = new EventEmitter<AllGeoJSON>();

    protected readonly flightAreaEntities$ = this.initFlightAreaData();
    protected readonly safetyAreaEntities$ = this.initSafetyAreaData();
    protected readonly pathEntities$ = this.initPathData();
    protected readonly waypointEntities$ = this.initWaypointData();
    protected readonly pinEntities$ = this.initPinData();
    protected readonly blockEntities$ = this.initBlockPinData();

    protected readonly pinTemplate$ = this.localStore.selectByKey("pinTemplate");
    protected readonly isShown$ = this.localStore.selectByKey("isShown");
    protected readonly shouldDisplayTimeRange$ = this.localStore.selectByKey("shouldDisplayTimeRange");
    protected readonly areLabelsVisible$ = this.cameraHelperService.postRender$.pipe(
        map(() => this.cesiumService.getViewer().camera.positionCartographic.height <= LABELS_SHOWING_DISTANCE_IN_METERS),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
    );
    protected readonly isPathBased$ = this.localStore.selectByKey("routeData").pipe(map((routeData) => !!routeData?.isPathBased));
    protected readonly routeData$ = this.localStore.selectByKey("routeData");

    protected readonly MissionSegmentStatus = MissionSegmentStatus;

    constructor(
        private readonly localStore: LocalComponentStore<RouteViewLayerComponentState<T>>,
        private readonly routeViewerService: RouteViewerService<T>,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly cameraHelperService: CameraHelperService,
        private readonly cesiumService: CesiumService,
        private readonly zone: NgZone
    ) {
        this.localStore.setState({
            routeData: undefined,
            drawableFeatures: [],
            nearbyMissionsDrawableFeatures: [],
            activeEntitiesId: new Set(),
            pinTemplate: null,
            isShown: false,
            shouldDisplayTimeRange: true,
        });

        this.watchLabelsOverlapping();
    }

    protected getPinClassConfig(pinData: PinData<T>) {
        return {
            ...mapSegmentStatusToClassConfig(pinData.status),
            main: pinData.isMain,
            selected: pinData.isSelected,
            collision: pinData.isCollision,
        };
    }

    private initFlightAreaData() {
        return this.prepareInit((routeData, drawableFeatures, index) =>
            this.routeViewerService.transformRouteDataToFlightAreaEntities(routeData, drawableFeatures, index)
        );
    }

    private initSafetyAreaData() {
        return this.prepareInit((routeData, drawableFeatures, index) =>
            this.routeViewerService.transformRouteDataToSafetyAreaEntities(routeData, drawableFeatures, index)
        );
    }

    private initPathData() {
        return this.prepareInit(this.routeViewerService.transformRouteDataToPathEntities);
    }

    private initWaypointData() {
        return this.prepareInit(this.routeViewerService.transformRouteDataToWaypointEntities);
    }

    private initPinData() {
        return this.prepareInit((routeData, drawableFeatures, index) =>
            this.routeViewerService.mapRouteToPinEntities(routeData, drawableFeatures, index)
        );
    }

    private initBlockPinData() {
        return this.prepareInit((routeData, drawableFeatures, index) =>
            this.routeViewerService.transformRouteDataToBlockPinEntity(routeData, drawableFeatures, index)
        );
    }

    private prepareInit(
        fn: (routeData: RouteData<T>, drawableFeatures: RouteAreaTypeId[], index: number) => Observable<any>
    ): Observable<AcNotification> {
        const buffer: { data?: RouteData<T> } = {};

        return this.localStore.selectByKey("routeData").pipe(
            switchMap((routeData) => this.resetAllFeaturesOnChange(buffer, routeData)),
            RxjsUtils.filterFalsy(),
            filter(({ routes }) => !!routes),
            mergeMap(({ routes, drawableFeatures, index }) =>
                // NOTE: routes can't be undefined thanks to filter above
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                fn(routes!, drawableFeatures, index).pipe(
                    map((data: AcNotification) => {
                        const entity: RouteAcEntity = data.entity as RouteAcEntity;

                        return {
                            ...data,
                            entity: {
                                ...entity,
                                isMain: routes?.isMain,
                                isSelected: routes?.isSelected,
                                isCollision: routes?.isCollision,
                                emergency: routes?.emergency,
                            },
                        };
                    })
                )
            ),
            tap(() => {
                this.cesiumService.getScene().requestRender();
            })
        );
    }

    private resetAllFeaturesOnChange(
        dataBuffer: { data?: RouteData<T> },
        currentData?: RouteData<T>
    ): Observable<{ routes?: RouteData<T>; drawableFeatures: RouteAreaTypeId[]; index: number } | undefined> {
        const concatRouteDataItems = ({
            routes,
            drawableFeatures,
            nearbyMissionsDrawableFeatures,
        }: {
            routes?: RouteData<T>;
            drawableFeatures: RouteAreaTypeId[];
            nearbyMissionsDrawableFeatures?: RouteAreaTypeId[];
        }) =>
            from([
                { routes, drawableFeatures, index: 0 },
                ...(routes?.nearbyMissionsData?.map((data, index) => ({
                    routes: data,
                    drawableFeatures: nearbyMissionsDrawableFeatures ?? [],
                    index: index + 1,
                })) ?? []),
            ]);

        return from([true, false]).pipe(
            mergeMap((shouldClear) => {
                if (!shouldClear) {
                    dataBuffer.data = currentData;

                    return this.localStore.selectByKey("drawableFeatures").pipe(
                        combineLatestWith(this.localStore.selectByKey("nearbyMissionsDrawableFeatures")),
                        map(([drawableFeatures, nearbyMissionsDrawableFeatures]) => ({
                            routes: currentData,
                            drawableFeatures,
                            nearbyMissionsDrawableFeatures,
                        })),
                        mergeMap(concatRouteDataItems)
                    );
                }

                const shouldClearMissionData = shouldClear && dataBuffer.data?.uniqueRouteId !== currentData?.uniqueRouteId;
                const shouldClearNearbyMissionData =
                    shouldClear && dataBuffer.data?.uniqueNearbyRoutesId !== currentData?.uniqueNearbyRoutesId;

                if (shouldClearMissionData) {
                    return concatRouteDataItems({ routes: dataBuffer.data, drawableFeatures: [] });
                }

                if (shouldClearNearbyMissionData) {
                    const drawableFeatures = this.localStore.selectSnapshotByKey("drawableFeatures") ?? [];

                    return concatRouteDataItems({
                        routes: dataBuffer.data,
                        drawableFeatures,
                        nearbyMissionsDrawableFeatures: [],
                    });
                }

                return of(undefined);
            })
        );
    }

    protected getRouteEntityFill(entity: RouteAcEntity) {
        const visualData = this.getEntityVisualData(entity);

        if (visualData?.fill instanceof CesiumImageObjectSettings && entity.degreesPositions) {
            const geometry: Polygon = {
                type: "Polygon",
                coordinates: [entity.degreesPositions],
            };

            return visualData.fill.createImageMaterialProperty(MapUtils.getCesiumImageScaleForGeometry(geometry, FILL_IMAGE_SCALE));
        }

        return visualData?.fill ?? Cesium.Color.TRANSPARENT;
    }

    protected getRouteEntityOutlineWidth(entity: RouteAcEntity) {
        return this.getEntityVisualData(entity)?.outlineWidth ?? 0;
    }

    protected getRouteEntityMaterial(entity: RouteAcEntity) {
        return this.getEntityVisualData(entity)?.outlineMaterial;
    }

    protected getHeightRangeValues(flightArea?: Area) {
        if (!flightArea) {
            return;
        }

        const elevationMin = flightArea.elevationMin ?? 0;
        const elevationMax = flightArea.elevationMax ?? 0;
        const isOnTheGround = flightArea.volume.floor <= elevationMin;
        const minHeightAmsl = flightArea.volume.floor;
        const maxHeightAmsl = flightArea.volume.ceiling;
        const maxHeightAgl = flightArea.volume.ceiling - elevationMax;
        const minHeightAgl = flightArea.volume.floor - elevationMin;

        return {
            minHeightAmsl,
            maxHeightAmsl,
            isOnTheGround,
            maxHeightAgl,
            minHeightAgl,
        };
    }

    protected getZoomArea(routeData?: RouteData<T>) {
        if (!routeData?.route.sections[0].flightZone?.flightArea.volume.area) {
            return;
        }

        const features = routeData?.route.sections
            .map((section) => {
                const area = section.flightZone?.flightArea.volume.area ?? section.segment?.flightArea.volume.area;
                if (!area) {
                    return;
                }

                return turfFeature(area);
            })
            .filter(FunctionUtils.isTruthy);

        return SpatialUtils.union(features);
    }

    private getEntityVisualData(entity: RouteAcEntity): VisualSettings | undefined {
        let visualData = ROUTES_VISUAL_DATA[entity.id]?.[MissionSegmentStatus.DEFAULT];

        if (!entity.isMain) {
            visualData = { ...visualData, ...this.findVisualDataOverride(entity, "nearbyMissionOverride") };
        }

        if (entity.isCollision) {
            visualData = { ...visualData, ...this.findVisualDataOverride(entity, "collisionMissionOverride") };
        }

        if (entity.isSelected) {
            visualData = { ...visualData, ...this.findVisualDataOverride(entity, "selectedNearbyMissionOverride") };
        }

        if (entity.isMain) {
            visualData = { ...visualData, ...this.findVisualDataOverride(entity) };
        }

        if (entity.emergency) {
            visualData = { ...visualData, ...this.findVisualDataOverride(entity, "emergencyOverride") };
        }

        return visualData;
    }

    private findVisualDataOverride(entity: RouteAcEntity, overrideKey?: keyof VisualDataOverride) {
        const segmentStatus = entity.segmentStatus ?? MissionSegmentStatus.DEFAULT;

        if (!overrideKey) {
            return ROUTES_VISUAL_DATA[entity.id]?.[segmentStatus] ?? ROUTES_VISUAL_DATA[entity.id]?.[MissionSegmentStatus.DEFAULT];
        }

        return (
            ROUTES_VISUAL_DATA[entity.id]?.[segmentStatus]?.[overrideKey] ??
            ROUTES_VISUAL_DATA[entity.id]?.[MissionSegmentStatus.DEFAULT]?.[overrideKey]
        );
    }

    private watchLabelsOverlapping() {
        this.isShown$
            .pipe(
                switchMap((isShown) => {
                    if (!isShown) {
                        return of(undefined);
                    }

                    return this.cameraHelperService.postRender$.pipe(
                        throttleTime(DEFAULT_DEBOUNCE_TIME, undefined, { leading: true, trailing: true }),
                        tap(() => this.zone.runOutsideAngular(() => this.removeOverlapping()))
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private removeOverlapping() {
        const elements = [...(this.document?.querySelectorAll(".segment-pin").values() ?? [])] as HTMLDivElement[];

        MapLabelsUtils.removeOverlapping(elements, {
            svgLineSelector: ".line-connector line",
            buffer: 4,
        });
    }
}
