<ng-container *ngrxLet="{ isGeoZonesInfoEnabled: isGeoZonesInfoEnabled$, isMeasureToolActive: isMeasureToolActive$ } as vm">
    <div class="control-buttons" *ngIf="isGeoZonesInfoAvailable$ | ngrxPush">
        <button
            type="button"
            (click)="toggleGeoZoneInfo()"
            [class.active]="vm.isGeoZonesInfoEnabled"
            [matTooltip]="'dtmMapCesium.zoneInfoToggleButtonTooltip' | transloco"
            [attr.aria-label]="'dtmMapCesium.zoneInfoToggleButtonTooltip' | transloco"
            role="switch"
            [attr.aria-checked]="vm.isGeoZonesInfoEnabled"
            matTooltipPosition="left"
        >
            <dtm-ui-icon name="cylinder-question"></dtm-ui-icon>
        </button>
    </div>
    <div class="control-buttons">
        <button
            type="button"
            [class.active]="vm.isMeasureToolActive"
            (click)="toggleMeasure()"
            [disabled]="!(isMeasureToolEnabled$ | ngrxPush)"
            [attr.aria-label]="'dtmMapCesium.measureToolButtonAriaLabel' | transloco"
            [attr.aria-checked]="vm.isMeasureToolActive"
        >
            <dtm-ui-icon name="ruler"></dtm-ui-icon>
        </button>
    </div>
    <div class="control-buttons">
        <button
            type="button"
            (click)="setDefaultCamera()"
            [disabled]="isDefaultView$ | ngrxPush"
            [attr.aria-label]="'dtmMapCesium.resetViewButtonAriaLabel' | transloco"
        >
            <dtm-ui-icon name="north"></dtm-ui-icon>
        </button>
    </div>
    <div class="control-buttons">
        <button
            type="button"
            (click)="geolocate()"
            *ngIf="geolocationService"
            [disabled]="!(canGetGeolocationSubject | ngrxPush)"
            [attr.aria-label]="'dtmMapCesium.locationButtonAriaLabel' | transloco"
        >
            <dtm-ui-icon name="focus-2"></dtm-ui-icon>
        </button>
    </div>
    <div class="control-buttons">
        <button type="button" (click)="zoomIn()" [attr.aria-label]="'dtmMapCesium.zoomInButtonAriaLabel' | transloco">
            <dtm-ui-icon name="add"></dtm-ui-icon>
        </button>
        <button type="button" (click)="zoomOut()" [attr.aria-label]="'dtmMapCesium.zoomOutButtonAriaLabel' | transloco">
            <dtm-ui-icon name="subtract"></dtm-ui-icon>
        </button>
    </div>
</ng-container>
