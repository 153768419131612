<label for="degreesInput" [ngClass]="{ disabled: disabled }" *ngIf="inputLabel$ | ngrxPush as inputLabel">{{ inputLabel }}</label>
<form
    [formGroup]="dmsFormGroup"
    class="dms-coordinates-container"
    *ngrxLet="{ isActivated: isActivated$, isLongitudeType: isLongitudeType$, fixedDirection: fixedDirection$ } as vm"
>
    <dtm-ui-form-field [isDisabled]="dmsFormGroup.disabled" [isActivated]="vm.isActivated">
        <div class="input-field">
            <input
                id="degreesInput"
                #degreesInput
                class="three-digits"
                matInput
                formControlName="degrees"
                [mask]="vm.isLongitudeType ? '099' : '09'"
                [placeholder]="vm.isLongitudeType ? '_ _ _' : '_ _'"
                [maxLength]="vm.isLongitudeType ? 3 : 2"
                (blur)="propagateTouch()"
                (change)="tryEmitCoordinatesChange()"
                (paste)="pasteCoordinates($event)"
                (keyup)="tryChangeFocus($event, { nextInput: minutesInput })"
            />
            <div class="field-suffix">&#176;</div>
            <input
                matInput
                formControlName="minutes"
                #minutesInput
                mask="09"
                placeholder="_ _"
                maxlength="2"
                (blur)="reformatControlValue(dmsFormGroup.controls.minutes); propagateTouch()"
                (change)="tryEmitCoordinatesChange()"
                (paste)="pasteCoordinates($event)"
                (keyup)="tryChangeFocus($event, { previousInput: degreesInput, nextInput: secondsInput })"
            />
            <div class="field-suffix">&lsquo;</div>
            <input
                matInput
                formControlName="seconds"
                #secondsInput
                mask="09"
                placeholder="_ _"
                (blur)="reformatControlValue(dmsFormGroup.controls.seconds); propagateTouch()"
                (change)="tryEmitCoordinatesChange()"
                (paste)="pasteCoordinates($event)"
                (keyup)="tryChangeFocus($event, { previousInput: minutesInput })"
            />
            <div class="field-suffix">
                &ldquo;
                <span *ngIf="vm.fixedDirection">&nbsp;{{ vm.fixedDirection }} </span>
            </div>
        </div>

        <div class="field-error" *ngIf="dmsFormGroup.touched">
            <div
                *ngIf="
                    dmsFormGroup.controls.seconds.errors?.required ||
                    dmsFormGroup.controls.minutes.errors?.required ||
                    dmsFormGroup.controls.degrees.errors?.required ||
                    dmsFormGroup.controls.direction.errors?.required
                "
            >
                {{ "dtmUiDmsCoordinatesInput.requiredHint" | transloco }}
            </div>
            <div *ngIf="dmsFormGroup.controls.seconds.errors?.min">
                {{ "dtmUiDmsCoordinatesInput.minSecondsHint" | transloco : { min: dmsFormGroup.controls.seconds.errors?.min.min } }}
            </div>
            <div *ngIf="dmsFormGroup.controls.seconds.errors?.max">
                {{ "dtmUiDmsCoordinatesInput.maxSecondsHint" | transloco : { max: dmsFormGroup.controls.seconds.errors?.max.max } }}
            </div>
            <div *ngIf="dmsFormGroup.controls.minutes.errors?.min">
                {{ "dtmUiDmsCoordinatesInput.minMinutesHint" | transloco : { min: dmsFormGroup.controls.minutes.errors?.min.min } }}
            </div>
            <div *ngIf="dmsFormGroup.controls.minutes.errors?.max">
                {{ "dtmUiDmsCoordinatesInput.maxMinutesHint" | transloco : { max: dmsFormGroup.controls.minutes.errors?.max.max } }}
            </div>
            <div *ngIf="dmsFormGroup.controls.degrees.errors?.min">
                {{ "dtmUiDmsCoordinatesInput.minDegreesHint" | transloco : { min: dmsFormGroup.controls.degrees.errors?.min.min } }}
            </div>
            <div *ngIf="dmsFormGroup.controls.degrees.errors?.max">
                {{ "dtmUiDmsCoordinatesInput.maxDegreesHint" | transloco : { max: dmsFormGroup.controls.degrees.errors?.max.max } }}
            </div>
        </div>

        <button
            type="button"
            class="clear-button"
            *ngIf="(isClearable$ | ngrxPush) && (dmsFormGroup.value.degrees || dmsFormGroup.value.minutes || dmsFormGroup.value.seconds)"
            (click)="clearCoordinates()"
            tabindex="-1"
        >
            <dtm-ui-icon name="close-circle-fill"></dtm-ui-icon>
        </button>
    </dtm-ui-form-field>

    <dtm-ui-select-field
        formControlName="direction"
        (valueChange)="updateDirection($event); propagateTouch()"
        [isClearable]="false"
        *ngIf="!vm.fixedDirection"
    >
        <dtm-ui-select-option *ngFor="let direction of geographicalDirections$ | ngrxPush" [value]="direction">
            {{ direction }}
        </dtm-ui-select-option>
    </dtm-ui-select-field>
</form>
